import { formatDateToDDMMYY } from 'utils/helpers/DateUtils'
import { applyMultipleFundReportPAITableHeaderStyles, formatData, getEmptyRows, setRowHeight } from 'utils/helpers/ExcelUtils'
import { generatePrincipleMetricsRow, getPrincipleMetricsTableHeader, getfundReportSheetHeaderInformation } from './fundReportPAIExcelExport'

export const createFundReportPAIBiodiversitySheet = (
  fundName,
  reportingYear,
  biodiversityBreakdown,
  assets,
  localize,
  selectedLanguage,
  excelSheet
) => {
  let excelDataArray = []
  excelDataArray.push(...getfundReportSheetHeaderInformation('t_biodiversity_impact_report', fundName, reportingYear, localize))
  excelDataArray.push(...getEmptyRows(2))
  excelDataArray = getBiodiversityPrincipleMetricsTable(excelDataArray, biodiversityBreakdown.principleMetrics, reportingYear, localize)
  excelDataArray.push(...getEmptyRows(1))
  excelDataArray = getAssetInformationTable(excelDataArray, assets, localize, selectedLanguage, excelSheet)
  return excelDataArray
}

const getBiodiversityPrincipleMetricsTable = (currentExcelData, principleMetricsData, reportingYear, localize) => {
  const {
    artificialHorizontalArea,
    totalHorizontalArea, 
    horizontalArtificialisation, 
    artificialVerticalArea, 
    totalVerticalArea, 
    verticalArtificialisation
  } = principleMetricsData
  const data = [...currentExcelData]
  data.push(...getPrincipleMetricsTableHeader(localize, reportingYear))
  
  data.push(...[generatePrincipleMetricsRow('t_artificial_horizontal_area', artificialHorizontalArea.currentYear, artificialHorizontalArea.previousYear, localize),
    generatePrincipleMetricsRow('t_total_horizontal_area', totalHorizontalArea.currentYear, totalHorizontalArea.previousYear, localize),
    generatePrincipleMetricsRow('t_horizontal_artificialization', horizontalArtificialisation.currentYear, horizontalArtificialisation.previousYear, localize),
    generatePrincipleMetricsRow('t_artificial_vertical_area', artificialVerticalArea.currentYear, artificialVerticalArea.previousYear, localize),
    generatePrincipleMetricsRow('t_total_vertical_area', totalVerticalArea.currentYear, totalVerticalArea.previousYear, localize),
    generatePrincipleMetricsRow('t_vertical_artificialization', verticalArtificialisation.currentYear, verticalArtificialisation.previousYear, localize)
  ])
  
  return data
}

const getAssetInformationTable = (currentExcelData, assetsArray, localize, selectedLanguage, excelSheet) => {
  const data = [...currentExcelData]
  data.push(...getAssetInformationTableHeader(localize))
  assetsArray.forEach(asset => {
    const { 
      assetReference, 
      assetName, 
      location, 
      constructionYear, 
      purchaseDate,
      saleDate, 
      Biodiversity
    } = asset
    data.push(...[{
      A: assetReference,
      B: assetName,
      C: location,
      D: formatDateToDDMMYY(purchaseDate, selectedLanguage),
      E: formatDateToDDMMYY(saleDate, selectedLanguage),
      F: constructionYear,
      G: formatData(Biodiversity.totalHorizontalArea),
      H: formatData(Biodiversity.artificialHorizontalArea),
      I: formatData(Biodiversity.totalVerticalArea),
      J: formatData(Biodiversity.artificialVerticalArea),
    }])
    excelSheet.row(data.length).height(31)
  })

  return data
}

const getAssetInformationTableHeader = (localize) => {
  return [{
    A: localize('t_asset_information'),
  },
  {
    A: localize('t_asset_reference'),
    B: localize('t_asset_name'),
    C: localize('t_country'),
    D: localize('t_purchase_date'),
    E: localize('t_sale_date'),
    F: localize('t_construction_year'),
    G: `${localize('t_total_horizontal_area')} (m²)`,
    H: `${localize('t_artificial_horizontal_area')} (m²)`,
    I: `${localize('t_total_vertical_area')} (m²)`,
    J: `${localize('t_artificial_vertical_area')} (m²)`,
  }]
}

export const applyStylingToBiodiversitySheet = (excelSheet) => {
  excelSheet.usedRange().forEach(cell => {
    if (cell.address() !== 'A1') {
      cell.style({fontSize: 12,  horizontalAlignment: 'left', verticalAlignment: 'center'})
    }

    if(cell.address() === 'A8') {
      cell.style({topBorder: false})
    }
  })
  applyMultipleFundReportPAITableHeaderStyles(['A17:J17', 'B8:C8'], excelSheet)
  setRowHeight(excelSheet, 17, 31)
}