import {
  getLocalizedValue,
  getLocalizedValueUsingIntl,
  getLocalStorageItem,
  UNIT_IMPERIAL_AREA, UNIT_IMPERIAL_CARBON_INTENSITY,
  UNIT_IMPERIAL_ENERGY,
  UNIT_IMPERIAL_ENERGY_INTENSITY,
  UNIT_IMPERIAL_WATER,
  UNIT_IMPERIAL_WATER_INTENSITY,
  UNIT_METRIC_AREA,
  UNIT_METRIC_CARBON_INTENSITY, UNIT_METRIC_ENERGY,
  UNIT_METRIC_ENERGY_INTENSITY, UNIT_METRIC_ENERGY_MEGA,
  UNIT_METRIC_WATER,
  UNIT_IMPERIAL_ENERGY_MEGA,
  UNIT_METRIC_WATER_INTENSITY,
  UNIT_EMISSION, UNIT_KILO_GRAMS, getDivisor
} from '../helpers/Helper'
import CSS_VARIABLES from '../../resources/css/_variables.scss'
import {formatNumWOneDecimal} from '../../services/common/formatter'
import {
  currentMonth,
  DD_MM_YYYY,
  monthListShort,
  PERIOD_TYPES,
  UTILITY_LABELS_OBJECT_TITLE_CASE,
  MM_DD_YYYY,
} from '../helpers/Constants'
import {saveAs} from 'file-saver'
import I18n from '../i18n/I18n'
import {UNITS} from '../i18n/constants'
import { 
  getTitleObject as getTitleObjectNew,
  getEmptyRows as getEmptyRowsNew, 
  getValidData as getValidDataNew,
  splitStringOnCapitalization as splitStringOnCapitalizationNew
} from '../helpers/ExcelUtils'
import moment from 'moment/moment'

//Obsolete, please use the new function in ExcelUtils.js
export const getEmptyRows = (numberOfLines) => {
  return getEmptyRowsNew(numberOfLines)
}

export const getTableHeadersAfterLocalizationArray = (tableHeadersToBeLocalized, selectedLanguage) => {
  return tableHeadersToBeLocalized.map(title => getLocalizedValue(selectedLanguage, title))
}

export const getTableHeadersForExcelObject = (tableHeadersWithLocalization) => {
  let tableHeaderForExcelInformationObject = {}
  for (let i = 0; i < tableHeadersWithLocalization?.length; i++) {
    tableHeaderForExcelInformationObject[String.fromCharCode(65 + i)] = tableHeadersWithLocalization[i]
  }
  return tableHeaderForExcelInformationObject
}
//Obsolete, please use the new function in ExcelUtils.js
export const getTitleObject = (tableTitle, selectedLanguage) => {
  return getTitleObjectNew(tableTitle, selectedLanguage)
}

export const saveAsExcelFile = (buffer, fileName) => {
  let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const data = new Blob([buffer], {type: EXCEL_TYPE})
  saveAs(data, fileName)
}

export const generateExcel = async (xlsx, new_workbook, selectedLanguage, fileName) => {
  const excelBuffer = xlsx.write(new_workbook, {bookType: 'xlsx', type: 'array', cellStyles: true})
  saveAsExcelFile(excelBuffer, getLocalizedValue(selectedLanguage, fileName))
}

/*
  *  applyFontStylesForTitlesAndTableHeader() updates font size and bold character to the required cell data ('assetsData') stored in 'fontStylesForTitlesAndTableHeader'
*/
export const applyFontStylesForTitlesAndTableHeader = (fontStylesForTitlesAndTableHeader, assetsData) => {
  fontStylesForTitlesAndTableHeader.forEach(item => {
    if(assetsData[item.cell]){
      assetsData[item.cell].s = {
        font: {
          sz: item.size,
          bold: true,
        },
      }
    }
  })
  return assetsData
}

/*
  * getAlignmentAndBorderStyle() adds alignment and border color to required cells (based on i,j)
  * since the cells are identified as A,B,C,D.... we use String.fromCharCode()
*/
const getAlignmentAndBorderStyle = (assetsData, i, j, leftAlign) => {
  const horizontalAlignment = leftAlign && i === 0 ? 'left' : 'center'
  let column = `${getAlphabetsForColumnRepresentation()[i]}${j}`
  if(assetsData[column]){
    return assetsData[column].s = {
      alignment: {
        wrapText: true,
        vertical: 'bottom',
        horizontal: horizontalAlignment,
      },
      border: {
        right: {
          style: 'thin',
          color: CSS_VARIABLES.forest,
        },
        left: {
          style: 'thin',
          color: CSS_VARIABLES.forest,
        },
        top: {
          style: 'thin',
          color: CSS_VARIABLES.forest,
        },
        bottom: {
          style: 'thin',
          color: CSS_VARIABLES.forest,
        },
      }
    }
  }
}

export const applyAlignmentAndBorderStylesForExcelTable = (assetsData, numOfAssetRows, table1ColLength, table2ColLength) => {
  /*
    * the loop applies styles to the cell.
    * i => iter through the table1 columns
    * j => number of rows
    * basically a 2D matrix
  */
  for (let i = 0; i < table1ColLength; i++) {
    for (let j = 6; j < 8; j++) { // Note: point to be updated when the rows change
      getAlignmentAndBorderStyle(assetsData, i, j)
    }
  }
  for (let i = 0; i < table2ColLength; i++) {
    for (let j = 11; j < 12 + numOfAssetRows; j++) { // Note: point to be updated when the rows change
      getAlignmentAndBorderStyle(assetsData, i, j)
    }
  }
  return assetsData
}

/*
   * getValidValue() is to decide what to display in excel by standardizing different types of data coming from the backend
*/
export function getValidValue(selectedLanguage, value, convertToPercentageFlag = false) {

  switch (value) {
  case null:
  case undefined:
    return '-'
  case 0:
    return 0
  default:
    return formatNumWOneDecimal(selectedLanguage, convertToPercentageFlag ? value * 100 : value)
  }
}

/*
  * in getTable1HeadersObject(), 'table1Headers' headers holds the fund performance summary table header names
  * this function makes the object of the form, {A:'data1',B:'data2',C:'data3'}
  * this is how data is populated to for excel
  * the same principle is applied for getTable1HeadersObject(), getAssetTableRowValues()
*/
export function getTableHeadersObject(tableHeaders) {
  let generatedTableHeaders = {}
  for (let i = 0; i < tableHeaders?.length; i++) {
    generatedTableHeaders[String.fromCharCode(65 + i)] = tableHeaders[i]
  }
  return generatedTableHeaders
}

const momentDateForExport = (date, format, selectedLanguage) => {
  let requiredDate = moment(date).locale(selectedLanguage).format(format)
  return requiredDate !== 'Invalid date' ? requiredDate : 'null'
}
export function formatDateToDDMMYY(value,selectedLanguage){
  if(!value){
    return '-'
  }
  const dateFormatType = getLocalStorageItem('unitSystem') === UNITS.IMPERIAL ? MM_DD_YYYY : DD_MM_YYYY
  return momentDateForExport(value,dateFormatType,selectedLanguage)
}
function splitStringOnCapitalization(value){
  return splitStringOnCapitalizationNew(value)
}
export function checkExcelCellData(cellValue,selectedLanguage, useSplitStringCapitalization = true){
  const value = typeof cellValue !== 'number' ? String(cellValue): cellValue
  const checkCases = typeof value !== 'number' ? value.toLocaleLowerCase().trim() : value
  switch(checkCases){
  case 'true':
    return getLocalizedValue(selectedLanguage, 't_yes')
  case 'false':
    return getLocalizedValue(selectedLanguage, 't_no')
  case 'null':
  case 'undefined':
  case 'nan':
  case 'notspecified':
  case 'unknown':
  case '':
  case 'none':
    return '-'
  default:
    return useSplitStringCapitalization ? splitStringOnCapitalization(value) : value
  }
}

export function applyStylesForSummaryTable(rowStartNumber,numberOfRows,numberOfColumns,exportingData, leftAlign = false){
  for (let i = 0; i < numberOfColumns; i++) {
    for (let j = rowStartNumber; j <= rowStartNumber + numberOfRows; j++) { // Note: point to be updated when the rows change
      getAlignmentAndBorderStyle(exportingData, i, j, leftAlign)
    }
  }
  return exportingData
}

export const getFundUtilitiesList = (selectedLanguage)  => [
  { label: getLocalizedValue(selectedLanguage, 't_carbon'), value: UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON },
  { label: getLocalizedValue(selectedLanguage, 't_electricity'), value: UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY },
  { label: getLocalizedValue(selectedLanguage, 't_gas_fuel_thermal'), value: UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL },
  { label: getLocalizedValue(selectedLanguage, 't_water'), value: UTILITY_LABELS_OBJECT_TITLE_CASE.WATER },
  { label: getLocalizedValue(selectedLanguage, 't_waste_utility'), value: UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE }
]

export const utilitiesListForPerformanceExport = (selectedLanguage)  => [
  { label: getLocalizedValue(selectedLanguage, 't_electricity'), value: UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY },
  { label: getLocalizedValue(selectedLanguage, 't_gas_fuel_thermal'), value: UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL },
  { label: getLocalizedValue(selectedLanguage, 't_water'), value: UTILITY_LABELS_OBJECT_TITLE_CASE.WATER },
  { label: getLocalizedValue(selectedLanguage, 't_waste_utility'), value: UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE },
  { label: getLocalizedValue(selectedLanguage, 't_carbon'), value: UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON },
]

export function getPerformanceSheetTitle(utility){
  switch (utility){
  case UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON: return 't_carbon_performance_report'
  case UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY: return 't_electricity_performance_report'
  case UTILITY_LABELS_OBJECT_TITLE_CASE.WATER: return 't_water_performance_report'
  case UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE: return 't_waste_performance_report'
  case UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY: return  't_energy_performance_report'
  case UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL: return 't_gas_fuels_thermals_report'
  }
}

export function getFundExcelTitleName(utility){
  switch (utility){
  case UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON: return 't_fund_carbon_performance_report'
  case UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY: return 't_fund_electricity_performance_report'
  case UTILITY_LABELS_OBJECT_TITLE_CASE.WATER: return 't_fund_water_performance_report'
  case UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE: return 't_fund_waste_performance_report'
  case UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY: return  't_fund_performance_report'
  case UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL: return 't_fund_thermals_performance_report'
  }
}

export function getDateRangeAndTimePeriodInfo(timePeriod, pickerValue, selectedLanguage) {
  if (timePeriod !== PERIOD_TYPES.CUSTOM) {
    return {'dateRange': timePeriod, 'timePeriod': timePeriod}
  } else {
    let dateRange = pickerValue.pickerValueMonths > 1 ? `${pickerValue.pickerValueMonths} ${getLocalizedValue(selectedLanguage, 't_months_to_end_of')} ${monthListShort[pickerValue.pickerValueEndMonth]} ${pickerValue.pickerValueEndYear}` :
      `${pickerValue.pickerValueMonths} ${getLocalizedValue(selectedLanguage, 't_month_to_end_of')} ${monthListShort[pickerValue.pickerValueEndMonth]} ${pickerValue.pickerValueEndYear}`
    return {'dateRange': dateRange, 'timePeriod': timePeriod}
  }
}

export const UTILITY_MAP_FOR_PERFORMANCE_EXPORT = Object.freeze({
  'Carbon':'carbon',
  'Electricity':'electricity',
  'Water':'water',
  'Waste':'waste',
  'Energy':'energy',
  'GasFuelsAndThermals':'thermals',
  'districtCooling':'districtCooling',
  'districtHeating':'districtHeating',
  'gas':'gas',
  'oil':'oil',
})


export const getSheetName = (utilityName, selectedLanguage) => {
  switch (utilityName) {
  case UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON:return getLocalizedValue(selectedLanguage, 't_carbon')
  case UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY:return getLocalizedValue(selectedLanguage, 't_electricity')
  case UTILITY_LABELS_OBJECT_TITLE_CASE.WATER:return getLocalizedValue(selectedLanguage, 't_water')
  case UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE:return getLocalizedValue(selectedLanguage, 't_waste_utility')
  case UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY:
    return getLocalizedValue(selectedLanguage, 't_energy_summary')
  case UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL:
    return getLocalizedValue(selectedLanguage, 't_gasFuelsAndThermals')
  }
}

export const getDataLocalizedAndConvertToExcelExportFormat = (dataArray, selectedLanguage) => {
  let localizedData = dataArray.map(data => getLocalizedValue(selectedLanguage, data))
  let excelDataAsArrayOfObjects = {}

  // data to be exported should be in array of objects format
  // object keys are in A,B,C,... format

  for (let i = 0; i < dataArray?.length; i++) {
    excelDataAsArrayOfObjects[String.fromCharCode(65 + i)] = localizedData[i]
  }
  return excelDataAsArrayOfObjects
}

// this should be a replacement for
// 1. getTableHeadersObject()
// 2. getTableHeadersForExcelObject()
export const convertToExcelExportFormat = (dataArray) =>{
  let excelDataAsArrayOfObjects = {}
  for (let i = 0; i < dataArray?.length; i++) {
    excelDataAsArrayOfObjects[String.fromCharCode(65 + i)] = dataArray[i]
  }
  return excelDataAsArrayOfObjects
}

//TO-DO: splitStringOnCapitalization doesnt always work as expected. For now used a flag as fix.
//Obsolete, please use the new function in ExcelUtils.js
export const getValidData = (value, selectedLanguage, multiplyByHundred = false, useSplitStringCapitalization = true) => {
  return getValidDataNew(value, selectedLanguage, multiplyByHundred, useSplitStringCapitalization)
}

export const EXPORT_TYPES = Object.freeze({
  FUND : {
    ALL_UTILITY_EXPORT: 'all_utility_export',
    SELECTED_UTILITY_EXPORT: 'selected_utility_export',
    ACTIONS_BY_IMPACT: 'actions_by_impact',
    ACTIONS_BY_STATUS: 'actions_by_status',
    FUND_DATA_QUALITY: 'FUND_DATA_QUALITY',
    FUND_PERFORMANCE: 'FUND_PERFORMANCE',
  },
  ASSET : {
    PDF_EXPORT : 'pdf_export',
    EXCEL_EXPORT : 'excel_export',
    JPG_EXPORT : 'jpg_export',
    ASSET_PERFORMANCE_SINGLE_UTILITY_EXCEL_EXPORT:'asset_performance_single_utility_excel_export',
    ASSET_PERFORMANCE_ALL_UTILITY_EXCEL_EXPORT:'asset_performance_all_utility_excel_export',
    ASSET_HOME_PERFORMANCE_EXCEL_EXPORT : 'asset_home_performance_excel_export',
    ASSET_HOME_DATA_QUALITY_EXCEL_EXPORT : 'asset_home_data_quality_excel_export',
    ASSET_HOME_DATA_QUALITY_EXCEL_EXPORT_V2 : 'asset_home_data_quality_excel_export_v2',
    ASSET_HOME_ACTION_PLAN_EXCEL_EXPORT : 'asset_home_action_plan_excel_export',
  }
})

export function mappedExcelItem(val, selectedLanguage){
  if(typeof val === 'boolean'){
    if(val === true){
      return getLocalizedValue(selectedLanguage, 't_yes')
    }
    return getLocalizedValue(selectedLanguage, 't_no')
  }
  if(!val){
    return ' - '
  }
  return val
}


export const LEVEL_OF_DETAILS = {
  asset:'asset',
  meter:'meter'
}
export const LEVEL_OF_DETAILS_EXCEL_VALUE = {
  asset : 'By asset',
  meter : 'By meter'
}

export const LEVEL_OF_DETAILS_FOR_EXPORT = [
  {label: I18n('t_level_of_details_by_asset'), value: LEVEL_OF_DETAILS.asset, excelValue : LEVEL_OF_DETAILS_EXCEL_VALUE.asset},
  {label: I18n('t_level_of_details_by_meter'), value: LEVEL_OF_DETAILS.meter, excelValue : LEVEL_OF_DETAILS_EXCEL_VALUE.meter},
]

export const TIME_PERIOD_GRANULARITY = Object.freeze({
  ANNUAL:'annual',
  MONTHLY:'monthly'
})
export const TIME_PERIOD_GRANULARITY_EXCEL_VALUE = {
  ANNUAL : 'Annual',
  MONTHLY : 'Monthly'
}


export const TIME_PERIOD_GRANULARITY_FOR_EXPORT = [
  {label: I18n('t_time_period_granularity_annual'), value: TIME_PERIOD_GRANULARITY.ANNUAL, excelValue: TIME_PERIOD_GRANULARITY_EXCEL_VALUE.ANNUAL},
  {label: I18n('t_time_period_granularity_monthly'), value: TIME_PERIOD_GRANULARITY.MONTHLY, excelValue: TIME_PERIOD_GRANULARITY_EXCEL_VALUE.MONTHLY},
]

export  function getAlphabetsForColumnRepresentation() {
  const alphabetsArray = []
  for (let i = 0; i < 26; i++) {
    alphabetsArray.push( String.fromCharCode(65 + i))
  }
  for (let j = 0; j < 26; j++) {
    alphabetsArray.push(String.fromCharCode(65) + alphabetsArray[j])
  }
  return alphabetsArray
}
export const LIST_OF_TYPES_FOR_THERMAL_UTILITY = [
  UTILITY_LABELS_OBJECT_TITLE_CASE?.DISTRICT_COOLING,
  UTILITY_LABELS_OBJECT_TITLE_CASE?.DISTRICT_HEATING,
  UTILITY_LABELS_OBJECT_TITLE_CASE?.GAS_TYPE,
  UTILITY_LABELS_OBJECT_TITLE_CASE?.OIL,
  UTILITY_LABELS_OBJECT_TITLE_CASE?.THERMALS
]

export function addAdditionalStylesAndData(utility, exportingData,selectedLanguage,selectedTimePeriodGranularity,selectedLevelOfDetail,rowAndColumnInfoToApplyAdditionalStyles){
  function applyBorder(cellArray,position) {
    function updateStyle(cell){
      exportingData[cell].s['border'][position] = {
        style: 'thin',
        color: CSS_VARIABLES.forest,
      }
      exportingData[cell].t = 's'
    }

    cellArray.forEach(cell => {
      if(exportingData[cell]){
        updateStyle(cell)
      }else{
        exportingData[cell]={}
        exportingData[cell]['s']={}
        exportingData[cell]['v']=''
        exportingData[cell].s['border']={}
        updateStyle(cell)
      }
    })
  }

  function getCellArray(){
    let cellArray=[]
    let numberOfColumns =  rowAndColumnInfoToApplyAdditionalStyles[selectedLevelOfDetail][selectedTimePeriodGranularity].numberOfColumns
    let startColumnASCIINumber = rowAndColumnInfoToApplyAdditionalStyles[selectedLevelOfDetail][selectedTimePeriodGranularity].startColumnASCIINumber
    let rowNumber = rowAndColumnInfoToApplyAdditionalStyles[selectedLevelOfDetail][selectedTimePeriodGranularity].rowNumber
    for ( let i = startColumnASCIINumber; i < startColumnASCIINumber+numberOfColumns; i++){
      cellArray.push(String.fromCharCode(i)+rowNumber)
    }
    return cellArray
  }

  if(utility === UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE){
    const wastGenerated = getLocalizedValue(selectedLanguage,'t_waste_generated')
    const destination = getLocalizedValue(selectedLanguage,'t_destination')
    let cellArray = getCellArray()
    applyBorder(cellArray, 'top')
    applyBorder([cellArray[0]], 'left')
    exportingData[cellArray[0]].v = wastGenerated
    applyBorder([cellArray[3]], 'left')
    exportingData[cellArray[3]].v = destination
    applyBorder([cellArray[cellArray.length-1]], 'right')
  }
  return exportingData
}

export const UNIT_TYPE_FOR_EXPORT = {
  'AREA':'AREA',
  'ENERGY_CONSUMPTION':'ENERGY_CONSUMPTION',
  'WATER_CONSUMPTION': 'WATER_CONSUMPTION',
  'ENERGY_INTENSITY' : 'ENERGY_INTENSITY',
  'CARBON_INTENSITY' : 'CARBON_INTENSITY',
  'WATER_INTENSITY' : 'WATER_INTENSITY',
  'EMISSION' : 'EMISSION',
  'EMISSION_KG' : 'EMISSION_KG'
}

export function getNextCellName(currentCellName) {
  if (currentCellName.length === 1) {
    const nextCharCode = currentCellName.charCodeAt(0) + 1
    return String.fromCharCode(nextCharCode)
  } else {
    return currentCellName
  }
}

export const CELLS_TO_MERGE_FUND_PERFORMANCE =  (rowNumber) => ({
  'Energy': { start_range_1: 11, end_range_1: 14, start_range_2: 15, end_range_2: 18, row: rowNumber, mergeColumn: 4, cell_range_1: 'L', cell_range_2: 'P'},
  'Carbon': { start_range_1: 12, end_range_1: 15, start_range_2: 16, end_range_2: 19, row: rowNumber, mergeColumn: 4, cell_range_1: 'M', cell_range_2: 'Q'},
  'Electricity': { start_range_1: 11, end_range_1: 14, start_range_2: 15, end_range_2: 18, row: rowNumber, mergeColumn: 4, cell_range_1: 'L', cell_range_2: 'P'},
  'GasFuelsAndThermals': { start_range_1: 12, end_range_1: 15, start_range_2: 16, end_range_2: 19, row: rowNumber, mergeColumn: 4, cell_range_1: 'M', cell_range_2: 'Q'},
  'Water': { start_range_1: 11, end_range_1: 12, start_range_2: 13, end_range_2: 14, row: rowNumber, mergeColumn: 2, cell_range_1: 'L', cell_range_2: 'N'}
})

export const addBorderToSingleCell = (cell, borderStyle) => {
  cell.s = cell.s || {}
  cell.s.border = {
    top: { style: borderStyle, color: CSS_VARIABLES.forest },
    bottom: { style: borderStyle, color: CSS_VARIABLES.forest },
    left: { style: borderStyle, color: CSS_VARIABLES.forest },
    right: { style: borderStyle, color: CSS_VARIABLES.forest },
  }
}

export const addValueAndBorderToMergedCell = (worksheet, cell, row, borderlength, borderStyle, value) => {
  let currentCell = cell
  for (let i = 0; i < borderlength; i++) {
    currentCell = i === 0 ? currentCell : getNextCellName(currentCell)
    worksheet[`${currentCell}${row}`] = i === 0 ? { t: 's', v: value, s: { alignment: { horizontal: 'center', vertical: 'center' } } } :
      { t: 's', v: '', s: {} }
    addBorderToSingleCell(worksheet[`${currentCell}${row}`], borderStyle)
  }
}
export const getUnitRequiredForAssetSummary = (data) => {
  const unitSystem = getLocalStorageItem('unitSystem')
  const IMPERIAL_AND_METRIC = {
    [UNITS.METRIC]: {
      UNIT_ENERGY: UNIT_METRIC_ENERGY,
      UNIT_ENERGY_MEGA: UNIT_METRIC_ENERGY_MEGA,
    },
    [UNITS.IMPERIAL]: {
      UNIT_ENERGY: UNIT_IMPERIAL_ENERGY,
      UNIT_ENERGY_MEGA: UNIT_IMPERIAL_ENERGY_MEGA,
    }
  }
  const getUnit = data >= 1000000 ? 'UNIT_ENERGY_MEGA': 'UNIT_ENERGY'
  return IMPERIAL_AND_METRIC[unitSystem][getUnit]
}
export const getRequiredUnit = (unitFor, data, utility, isSummaryTable=false) =>{

  function getConsumptionUnitForMetricUnit(){
    if(isSummaryTable){
      return getDivisor(utility, data) === 1000 ? UNIT_METRIC_ENERGY_MEGA: UNIT_METRIC_ENERGY
    }else{
      return UNIT_METRIC_ENERGY
    }
  }

  const getConsumptionUnitForImperialUnit = () => {
    if(isSummaryTable){
      return getDivisor(utility, data) === 1000 ? UNIT_IMPERIAL_ENERGY_MEGA : UNIT_IMPERIAL_ENERGY
    }else{
      return UNIT_IMPERIAL_ENERGY
    }
  }

  const IMPERIAL_AND_METRIC_UNITS_FOR_EXPORT = {
    [UNITS.METRIC]: {
      [UNIT_TYPE_FOR_EXPORT.AREA]: UNIT_METRIC_AREA,
      [UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION]: getConsumptionUnitForMetricUnit(),
      [UNIT_TYPE_FOR_EXPORT.WATER_CONSUMPTION]: UNIT_METRIC_WATER,
      [UNIT_TYPE_FOR_EXPORT.ENERGY_INTENSITY]: UNIT_METRIC_ENERGY_INTENSITY,
      [UNIT_TYPE_FOR_EXPORT.CARBON_INTENSITY]: `CO₂e ${UNIT_METRIC_CARBON_INTENSITY}`,
      [UNIT_TYPE_FOR_EXPORT.WATER_INTENSITY]: UNIT_METRIC_WATER_INTENSITY,
      [UNIT_TYPE_FOR_EXPORT.EMISSION]: UNIT_EMISSION,
      [UNIT_TYPE_FOR_EXPORT.EMISSION_KG]: UNIT_KILO_GRAMS,
    },
    [UNITS.IMPERIAL]: {
      [UNIT_TYPE_FOR_EXPORT.AREA]: UNIT_IMPERIAL_AREA,
      [UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION]: getConsumptionUnitForImperialUnit(),
      [UNIT_TYPE_FOR_EXPORT.WATER_CONSUMPTION]: UNIT_IMPERIAL_WATER,
      [UNIT_TYPE_FOR_EXPORT.ENERGY_INTENSITY]: UNIT_IMPERIAL_ENERGY_INTENSITY,
      [UNIT_TYPE_FOR_EXPORT.CARBON_INTENSITY]: `CO₂e ${UNIT_IMPERIAL_CARBON_INTENSITY}`,
      [UNIT_TYPE_FOR_EXPORT.WATER_INTENSITY]: UNIT_IMPERIAL_WATER_INTENSITY,
      [UNIT_TYPE_FOR_EXPORT.EMISSION]: UNIT_EMISSION,
      [UNIT_TYPE_FOR_EXPORT.EMISSION_KG]: UNIT_KILO_GRAMS,
    }
  }
  let unitSystem = getLocalStorageItem('unitSystem')
  return IMPERIAL_AND_METRIC_UNITS_FOR_EXPORT[unitSystem][unitFor]
}

export function getLocalizedColumnNamesWithUnits(columnNamesArray, intl){
  let updatedColumnNamesWithUnit =[]
  columnNamesArray.forEach(columnName => {
    updatedColumnNamesWithUnit.push(getLocalizedValueUsingIntl(intl,columnName.key,  {0: columnName?.unit}))
  })
  return updatedColumnNamesWithUnit
}

export function getNumberOfMonthsAndEndMonth(timePeriod,pickerValue) {
  switch (timePeriod) {
  case PERIOD_TYPES.CUSTOM:
    return {
      numberOfMonths: parseInt(pickerValue.pickerValueMonths),
      endMonth: parseInt(pickerValue.pickerValueEndMonth) - 1
    }
  case '6M':
  case '3M':
    return {
      numberOfMonths: parseInt(timePeriod, 10),
      endMonth: currentMonth - 2
    }
  case '1Y':
    return {
      numberOfMonths: 12,
      endMonth: currentMonth - 2
    }
  case PERIOD_TYPES.YTD: return {
    numberOfMonths : 11,
    endMonth : currentMonth - 2
  }
  }
}

export function getExcelCellName(number) {
  let start = 1
  let end = 26
  let result = ''
  while ((number -= start) >= 0) {
    result = String.fromCharCode(parseInt((number % end) / start) + 65) + result
    start = end
    end *= 26
  }
  return result
}

export function excelSummaryTableData(consumption, selectedUtility){
  if(typeof (consumption) === 'number') {
    if(selectedUtility !== UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON){
      return consumption / getDivisor(selectedUtility, consumption)
    }
  }
  return !consumption ? '-' : consumption
}