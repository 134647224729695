import './FundReportSummaries.scss'
import { Button, Icon, Tooltip, TooltipTwoColumnsLayout, Text } from 'components/atomic'
import DQProgressBar from '../dq-progress-bar/DQProgressBar'
import PropTypes from 'prop-types'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Skeleton } from 'primereact/skeleton'
import { REPORT_STATUS } from '../mappings'
import { LEGEND_TEMPLATE } from 'utils/helpers/Constants'

const FundReportSummariesContent = (props) => {
  const {
    downloadYear,
    fundReportSummariesData,
    fundReportSummariesLoading,
    onSummaryClick,
    onDownloadReportClick,
    onCreateReportClick,
  } = props

  const gridValue = Array.from({ length: 10 })

  const skeletonCellTemplate = (width) => {
    return <Skeleton width={width} />
  }

  function yearTemplate(rowData) {
    return (
      <Text content={rowData.year} />
    )
  }

  function dataQualityTemplate(rowData, rowIndex) {
    const tooltipItems = [
      {
        label: 't_actual',
        labelIcon: <div className='legend-item-square legend-actual'></div>,
        value: `${(rowData?.dataQualityPercentages?.actual*100).toFixed(1)}%`,
      },
      {
        label: 't_estimated',
        labelIcon: <div className='legend-item-square legend-estimated'></div>,
        value: `${(rowData?.dataQualityPercentages?.estimated*100).toFixed(1)}%`,
      },
      {
        label: 't_missing',
        labelIcon: <div className='legend-item-square legend-incomplete'></div>,
        value: `${(rowData?.dataQualityPercentages?.incomplete*100).toFixed(1)}%`,
      },
      {
        label: 't_not_covered',
        labelIcon: <div className='legend-item-square legend-not-covered'></div>,
        value: `${(rowData?.dataQualityPercentages?.notCovered*100).toFixed(1)}%`,
      }
    ]

    return (
      <div>
        {rowData.reportStatus !== REPORT_STATUS.NoAssetOwned && (
          <>
            <Tooltip
              target={`.dq-fund-summary-tooltip-trigger-${rowIndex}`}
              padding='large'
            >
              <TooltipTwoColumnsLayout
                titleLeft='t_data_quality'
                items={tooltipItems} />
            </Tooltip>
            <DQProgressBar className={`dq-fund-summary-tooltip-trigger-${rowIndex}`} value={rowData.dataQualityPercentages} />
          </>
        )}
      </div>
    )
  }

  function reportCompletenessTemplate(rowData) {
    if (rowData.reportStatus === REPORT_STATUS.Incomplete) {
      return (<div className='flex-row'>
        <Icon id="warning_outline_circle" colour="red" />
        <p>
          <Text content='t_reports_report_completeness_missing' contentDynamic={{count: rowData.assetsMissingData}} testId={'fund-report-summary-status-' + rowData.year}/>
        </p>
      </div>)
    }
    if (rowData.reportStatus === REPORT_STATUS.CanCreateReport) {
      return (<div className='flex-row'>
        <Icon id="check_outline_circle" colour="green" />
        <p>
          <Text content='t_completed_data_requirements' testId={'fund-report-summary-status-' + rowData.year}/>
        </p>
      </div>)
    }
    if (rowData.reportStatus === REPORT_STATUS.NoAssetOwned) {
      return (<div className='flex-row'>
        <Icon id="info_outline" />
        <p>
          <Text content='t_reports_no_assets' testId={'fund-report-summary-status-' + rowData.year}/>
        </p>
      </div>)
    }
  }

  function dataQualityHeaderTemplate() {
    return (
      <div className='flex-row'>
        <Text content='t_data_quality' weight='bold' />
        <Icon id='info_solid' size={17} className='data-quality-summaries-info ml-2' testId='dq-asset-summaries-header-tooltip-test'/>
        <Tooltip target='.data-quality-summaries-info' width={350}>
          <Text content='t_data_quality_info' />
        </Tooltip>
      </div>
    )
  }

  function buttonTemplate(rowData) {
    return (
      <>
        {rowData.reportStatus === REPORT_STATUS.CanCreateReport && (
          <div className='report-button-flex'>
            <Button
              size='small'
              icon={< Icon id='download' />}
              loading={rowData.year === downloadYear}
              disabled={downloadYear && rowData.year !== downloadYear}
              onClick={(event) => {
                onDownloadReportClick(rowData.year)
                event.stopPropagation()
              }}
              testId={'fund-report-download-report-btn-' + rowData.year}
            />
            <Button
              content='t_add_details'
              size='small'
              outlined={true}
              onClick={() => onCreateReportClick(rowData.year)}
              testId={'fund-report-add-details-btn-' + rowData.year}
            />
          </div>
        )}
      </>
    )
  }

  const header = (
    <div className='table-cell-wide'>
      <Text content='t_reporting_years' weight='bold' className='py-3 pl-4' />
    </div>
  )

  return (
    <div className="fund-report-table" data-testid="fund-reports">
      <div className="fund-report-table__container">
        {fundReportSummariesLoading === true ? (
          <DataTable value={gridValue} data-testid={'fund-report-skeleton'}>
            <Column field="sk_t_year" header={skeletonCellTemplate('5rem')} body={skeletonCellTemplate('90%')} />
            <Column field="sk_t_data_quality" header={skeletonCellTemplate('5rem')} body={skeletonCellTemplate('90%')} />
            <Column field="sk_t_data_readiness" header={skeletonCellTemplate('5rem')} body={skeletonCellTemplate('90%')} />
            <Column field="sk_t_button" header={skeletonCellTemplate('5rem')} body={skeletonCellTemplate('90%')} />
          </DataTable>
        ) : (
          <DataTable
            value={fundReportSummariesData?.summaries}
            selectionMode="single"
            header={header}
            footer={LEGEND_TEMPLATE}
            onRowClick={(e) => {
              onSummaryClick(e.data.year)
            }}
          >
            <Column field="t_year" header={<Text content='t_year' weight='bold'/>} body={yearTemplate} />
            <Column field="t_data_quality" header={dataQualityHeaderTemplate} body={(rowData, rowProps) => dataQualityTemplate(rowData, rowProps.rowIndex)} />
            <Column field="t_data_readiness" header={<Text content='t_data_readiness' weight='bold'/>} body={reportCompletenessTemplate} />
            <Column field="t_button" body={buttonTemplate} />
          </DataTable>
        )}
      </div>
    </div>
  )
}

FundReportSummariesContent.propTypes = {
  fundReportSummariesData: PropTypes.object,
  fundReportSummariesLoading: PropTypes.bool,
  onSummaryClick: PropTypes.func,
  onCreateReportClick: PropTypes.func,
  onDownloadReportClick: PropTypes.func,
  downloadYear: PropTypes.number,
}

export default FundReportSummariesContent
FundReportSummariesContent.displayName = 'FundReportSummariesContent'