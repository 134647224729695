import PropTypes from 'prop-types'
import {Text, Tooltip} from '../../../../atomic'
import {FUND_VIEWS_PAGES} from '../../../../../utils/helpers/Constants'
import {getFillerImage} from '../../../../../utils/helpers/Helper'
import './TableCellImageText.scss'
import {toggleTooltip} from '../hooks-and-helpers/PortfolioHelper'

function TableCellImageText({rowData, colData, gotoPage}) {
  const {assetReference, assetAddress} = rowData
  const propertyImage = getFillerImage(rowData, true)
  const nameTooltipClassName = `asset-${rowData.assetId}-name-tooltip-${colData.rowIndex + 1}`
  const locationTooltipClassName = `asset-${rowData.assetId}-location-tooltip-${colData.rowIndex + 1}`

  return (
    <>
      <Tooltip target={`.${nameTooltipClassName}`} onBeforeShow={(e)=>toggleTooltip(e)} showDelay={100}><Text content={assetReference}/></Tooltip>
      <Tooltip target={`.${locationTooltipClassName}`} onBeforeShow={(e)=>toggleTooltip(e)} showDelay={100}><Text content={assetAddress}/></Tooltip>
      <div className="flex cursor-pointer table-cell-image-text" onClick={() => gotoPage(rowData, colData, FUND_VIEWS_PAGES.dashboard)} role="presentation">
        <img src={propertyImage} alt='Property' className={rowData.assetImageThumbnailUri ? 'table-cell-image-text__img-actual' : 'table-cell-image-text__img-filler'}/>
        <div className="table-cell-image-text__labels-wrapper">
          <div className={`${nameTooltipClassName} table-cell-image-text__labels-wrapper__name`}>{assetReference}</div>
          <div className={`${locationTooltipClassName} table-cell-image-text__labels-wrapper__location`}>{assetAddress}</div>
        </div>
      </div>
    </>
  )
}


TableCellImageText.propTypes = {
  rowData: PropTypes.object,
  colData: PropTypes.object,
  gotoPage: PropTypes.func
}

TableCellImageText.displayName = 'TableCellImageText'

export default TableCellImageText