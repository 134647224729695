import I18n from '../../../../../utils/i18n/I18n'
import {AddEditConsumption} from './add-edit/AddEditConsumption'
import {dateFormatter, dateRangeFormatter} from '../../../../../utils/helpers/Helper'
import {
  MeterViewAddEditConsumption
} from '../../performance/individual-meter-indicator/consumption/MeterViewAddEditConsumption'
import '../../../../common/custom-accordion/CustomAccordion.scss'

export function prepareDataForAddEdit(selectedData) {
  return selectedData?.map((data) => {
    return { ...data,
      fromDate: dateFormatter(data, 'START'),
      toDate: dateFormatter(data, 'END'),
      consumption: data?.consumption ? Number(data.consumption).toFixed(0) : '',
      unitOfMeasurement: '',
      estimate: data.estimate === 'Missing' ? '' : data.estimate,
      source: data.source,
      comment: data.comment,
      meterId: data.meterId,
      utility: data.utility,
      electricityMPAN: data.electricityMPAN,
      rangeId: data.rangeId,
      isDirty:data?.isDirty||false,
      isErrors:data?.isErrors||false,
      isWarnings:data?.isWarnings||false,
      unEditedFromDate: dateFormatter(data, 'START'),
      unEditedToDate: dateFormatter(data, 'END')
    }
  })
}

export function addEditConsumptionAccordionTemplate(selectedData, getDetailsOnSubmit, isSubmitClicked,activeIndex) {
  return selectedData?.map((data, index) => {
    return <AddEditConsumption
      selectedData={data}
      key={data.meterId}
      index={index}
      onNextClick={(data)=>getDetailsOnSubmit(data)}
      isSubmitClicked={isSubmitClicked}
      totalAccordian = {selectedData.length}
      activeIndex={activeIndex === 0 ? selectedData.length+2 : activeIndex}
    />
  })
}

export const requestConsumptionAccordionTemplate = (selectedData) => {
  const generateAccordionData = (name, value) => {
    return <div className={'accordion-content__row'}>
      <span>{name}:</span>
      <span className={'accordion-content__row-value comment__value'}>{value}</span>
    </div>
  }

  return selectedData?.map((data) => {
    return (<div className={'accordion-content mt-4'} role={'accordion-content'} key={data.meterId}>
      {generateAccordionData(I18n('t_additional_information'), data.comment?.length ? data.comment : '-')}
      {generateAccordionData(I18n('t_serial_number_consumption'), data.meterId)}
      {generateAccordionData(I18n('t_utility_type_consumption'), data.utility)}
      {generateAccordionData(I18n('t_area_covered_consumption'), data.location ? I18n('t_' + data.location.toLowerCase()) : '-')}
      {generateAccordionData(I18n('t_meter_status'), data.meterStatus ?? '-')}
      {generateAccordionData(I18n('t_submeter'), data.isSubMeter ? I18n('t_yes') : I18n('t_no'))}
    </div>)
  })
}

export const requestConsumptionMeterTemplate = (selectedData) => {
  return selectedData?.map((data, index) => {
    return <div className={'meter-request'} key={data.meterId}>
      <div className={'accordion-title-section p-4'}>
        <span className="p-badge p-badge-info perf-col-text5">{index + 1}</span>
        <span className={'medium-text-3'}>{dateRangeFormatter(data)}</span>
      </div>
    </div>

  })
}

export const dqMeterAccordionTemplate = (gapsPerMonth,dqMeterData,selectedLanguage, unitSystem,colorLabel,refetch,allData) => {
  return <MeterViewAddEditConsumption unitSystem={unitSystem} colorLabel={colorLabel} gapsPerMonth={gapsPerMonth}
    dqMeterData={dqMeterData} selectedLanguage={selectedLanguage} refetch={refetch} allData={allData}/>
}

