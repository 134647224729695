import {useContext, useState} from 'react'
import {Dropdown} from 'primereact/dropdown'
import {CLASS_MENU_TRACK, CLASS_SDROPDOWN, DROP_DOWN_HEIGHT, LANDLORD, TENANT} from '../../../../../../utils/helpers/Constants'
import I18n from '../../../../../../utils/i18n/I18n'
import {FeatureFlagContext} from 'Contexts'
import {CATEGORIES_LIST, createDataGapsWithFiltersObject, filterMeterDataGaps, getDataGapLandlord, getDataGapTenant, isMissingDataLoading, isOverviewGraphDataLoading} from '../../data-quality-hooks-&-helpers/dataQualityHelper'
import DataQualityCardOverviewSkeleton from '../../../../../common/skeletons/data-quality-card-overview-skeleton/DataQualityCardOverviewSkeleton'
import DataQualityOverviewGraph from '../../landlord-overview/DataQualityOverviewGraph'
import MissingDataTable from '../../missing-data/table/MissingDataTable'
import PropTypes from 'prop-types'
import SearchField from '../../../../../common/search/SearchField'
import {getLocalizedValue} from '../../../../../../utils/helpers/Helper'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const SummaryViewLandlordTenant = (props) => {
    
  const {
    dataQualityHomeState,
    updateDataQualityHomeState,
    missingData,
    summaryType,
    dqOverviewDataForLandlord,
    dqOverviewDataForTenant
  } = props
  const featureContext = useContext(FeatureFlagContext)
  const featureState = featureContext.featureState?.sieraplus_consumptionOperations
  
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const [searchText, setSearchText] = useState('')

  const missingDataPdf = (data,type,qtrTextLabel) => {
    if(type){
      updateDataQualityHomeState({ qtrTextLabelLandlord: qtrTextLabel })
    }
    else {
      updateDataQualityHomeState({ qtrTextLabelTenant: qtrTextLabel })
    }
  }

  const utilityChangeHandler = (e, type) => {
    if (type === LANDLORD) {
      const dataGapsLandlord = getDataGapLandlord(missingData)
      if (dataGapsLandlord) {
        const dataGapsWithFiltersUtil = {
          filterEstimate: dataQualityHomeState.selectedCategoryLandlord.value,
          filterSelectedUtil: e.value,
          filterSelectedArea: dataQualityHomeState.selectedAreaLandlord.value,
          meterDataGaps: dataGapsLandlord,
          searchText
        }
        let meterGapsFiltered = filterMeterDataGaps({...dataGapsWithFiltersUtil})
        updateDataQualityHomeState({
          dataGapLandlordSorted: meterGapsFiltered || [],
          selectedUtilityLandlord: {value: e.value},
        })
      }
    } else if (type === TENANT) {
      const dataGapsTenant = getDataGapTenant(missingData)
      if (dataGapsTenant) {
        const dataGapsWithFiltersUtil = {
          filterEstimate: dataQualityHomeState.selectedCategoryTenant.value,
          filterSelectedUtil: e.value,
          filterSelectedArea: dataQualityHomeState.selectedAreaTenant.value,
          meterDataGaps: dataGapsTenant,
          searchText
        }
        let meterGapsFiltered = filterMeterDataGaps({...dataGapsWithFiltersUtil})
        updateDataQualityHomeState({
          dataGapTenantSorted: meterGapsFiltered || [],
          selectedUtilityTenant: {value: e.value},
        })
      }
    }
  }

  const areaChangeHandler = (e, type) =>{
    if (type === LANDLORD) {
      const dataGapsLandlord = getDataGapLandlord(missingData)
      if (dataGapsLandlord) {
        const dataGapsWithFiltersArea = {
          filterEstimate: dataQualityHomeState.selectedCategoryLandlord.value,
          filterSelectedUtil: dataQualityHomeState.selectedUtilityLandlord.value,
          filterSelectedArea: e.value,
          meterDataGaps: dataGapsLandlord,
          searchText
        }
        let meterGapsFiltered = filterMeterDataGaps({...dataGapsWithFiltersArea})
        updateDataQualityHomeState({
          dataGapLandlordSorted: meterGapsFiltered || [],
          selectedAreaLandlord: {value: e.value},
        })
      }
    } else if (type === TENANT) {
      const dataGapsTenant = getDataGapTenant(missingData)
      if (dataGapsTenant) {
        const dataGapsWithFiltersArea = {
          filterEstimate: dataQualityHomeState.selectedCategoryTenant.value,
          filterSelectedUtil: dataQualityHomeState.selectedUtilityTenant.value,
          filterSelectedArea: e.value,
          meterDataGaps: dataGapsTenant,
          searchText
        }
        let meterGapsFiltered = filterMeterDataGaps({...dataGapsWithFiltersArea})
        updateDataQualityHomeState({
          dataGapTenantSorted: meterGapsFiltered || [],
          selectedAreaTenant: {value: e.value},
        })
      }
    }
  }

  const dataCategoriesChangeHandler = (e, type) => {
    if (type === LANDLORD) {
      const dataGapsLandlord = getDataGapLandlord(missingData)
      if (dataGapsLandlord) {
        const dataGapsWithFiltersObjectLandlord= createDataGapsWithFiltersObject(LANDLORD, e.value, dataGapsLandlord, dataQualityHomeState)
        dataGapsWithFiltersObjectLandlord.searchText = searchText
        let dataGapsLandlordFiltered = filterMeterDataGaps({...dataGapsWithFiltersObjectLandlord})
        updateDataQualityHomeState({
          dataGapLandlordSorted: dataGapsLandlordFiltered || [],
          selectedCategoryLandlord: {value: e.value},
        })
      }
    } else if (type === TENANT) {
      const dataGapsTenant = getDataGapTenant(missingData)
      if (dataGapsTenant) {
        const dataGapsWithFiltersObjectTenant=createDataGapsWithFiltersObject(TENANT, e.value, dataGapsTenant, dataQualityHomeState)
        dataGapsWithFiltersObjectTenant.searchText = searchText
        let dataGapsTenantFiltered = filterMeterDataGaps({...dataGapsWithFiltersObjectTenant})
        updateDataQualityHomeState({
          dataGapTenantSorted: dataGapsTenantFiltered || [],
          selectedCategoryTenant: {value: e.value},
        })
      }
    }
  }

  function filterTableData(searchText) {
    if(summaryType === LANDLORD) {
      const dataGapsLandlord = getDataGapLandlord(missingData)
      if (dataGapsLandlord) {
        const dataGapsWithFiltersArea = {
          filterEstimate: dataQualityHomeState.selectedCategoryLandlord.value,
          filterSelectedUtil: dataQualityHomeState.selectedUtilityLandlord.value,
          filterSelectedArea: dataQualityHomeState.selectedAreaLandlord.value,
          meterDataGaps: dataGapsLandlord,
          searchText
        }
        let meterGapsFiltered = filterMeterDataGaps({...dataGapsWithFiltersArea})
        updateDataQualityHomeState({
          dataGapLandlordSorted: meterGapsFiltered,
        })
      }
    } else if (summaryType === TENANT) {
      const dataGapsTenant = getDataGapTenant(missingData)
      if (dataGapsTenant) {
        const dataGapsWithFiltersArea = {
          filterEstimate: dataQualityHomeState.selectedCategoryTenant.value,
          filterSelectedUtil: dataQualityHomeState.selectedUtilityTenant.value,
          filterSelectedArea: dataQualityHomeState.selectedAreaTenant.value,
          meterDataGaps: dataGapsTenant,
          searchText
        }
        let meterGapsFiltered = filterMeterDataGaps({...dataGapsWithFiltersArea})
        updateDataQualityHomeState({
          dataGapTenantSorted: meterGapsFiltered,
        })
      }
    }
  }

  //region Custom templates

  function dropdownItemTemplate(option) {
    return (
      <>
        <div className="dropdown-item-1">
          <div data-testid={option.value} className="dropdown-item-2 dropdown-item-wrap">{option.label}</div>
        </div>
        <div className={'dropdown-panel-item-horiz-line'} />
      </>
    )
  }

  function getDropdownMenu(options, value, onChange, id, type) {
    return (
      <Dropdown
        appendTo="self"
        options={options}
        className={CLASS_SDROPDOWN}
        value={value}
        onChange={(e) => onChange(e, type)}
        itemTemplate={dropdownItemTemplate}
        scrollHeight={DROP_DOWN_HEIGHT}
        id={id}
      />
    )
  }

  function searchMeters(e) {
    let searchText = e.target.value
    setSearchText(searchText)
    filterTableData(searchText)
  }

  function clearSearch() {
    setSearchText('')
    filterTableData('')
  }

  function getDataQualityFilters() {
    const categoryValue = summaryType === LANDLORD ? dataQualityHomeState.selectedCategoryLandlord?.value : dataQualityHomeState.selectedCategoryTenant?.value
    const utilList = summaryType === LANDLORD ? dataQualityHomeState.utilityListsLandlordMeterDataGap : dataQualityHomeState.utilityListsTenantMeterDataGap
    const utilValue = summaryType === LANDLORD ? dataQualityHomeState.selectedUtilityLandlord.value : dataQualityHomeState.selectedUtilityTenant.value
    const areaValue = summaryType === LANDLORD ? dataQualityHomeState.selectedAreaLandlord?.value : dataQualityHomeState.selectedAreaTenant?.value
    return (
      <div className="menu-container" key="comp-menu-container">
        <div className="menu-dropdowns">
          <div className={CLASS_MENU_TRACK + ' col-3 util-dropdown'} key="menu-track-categories">
            {getDropdownMenu(
              CATEGORIES_LIST,
              categoryValue,
              dataCategoriesChangeHandler,
              `categories-${summaryType?.toLowerCase()}`,
              summaryType
            )}
          </div>
          <div data-testid={'util-dropdown'} className={CLASS_MENU_TRACK + ' col-3 util-dropdown'} key="menu-track-utility">
            {getDropdownMenu(
              utilList,
              utilValue,
              utilityChangeHandler,
              `util-${summaryType?.toLowerCase()}`,
              summaryType
            )}
          </div>
          <div data-testid={'area-dropdown'} className={`${CLASS_MENU_TRACK + ' col-3 area-dropdown'} menu-track-area`} key="menu-track-area">
            {getDropdownMenu(
              dataQualityHomeState.areaListsMeterDataGap,
              areaValue,
              areaChangeHandler,
              `area-${summaryType?.toLowerCase()}`,
              summaryType
            )}
          </div>
        </div>

        {featureState && <div>
          <SearchField placeholder={getLocalizedValue(selectedLanguage, 't_search_meter_placeholder')}
            searchValue={searchText} onChange={searchMeters} clearField={clearSearch} addRef={false}/>
        </div>}
      </div>
    )
  }
  //end region

  const dqOverviewGraphLandlordProps={
    graphData:dqOverviewDataForLandlord.data,
    area:LANDLORD,
    expandGraphBarConfig: dataQualityHomeState.expandGraphBarConfigLandlord
  }

  const dqOverviewGraphTenantProps={
    graphData:dqOverviewDataForTenant.data,
    area:TENANT,
    expandGraphBarConfig:dataQualityHomeState.expandGraphBarConfigTenant
  }

  function refetchMissingAndMeterData(){
    props.meterData.refetch()
    missingData.refetch()
  }

  function getMissingTable() {
    return summaryType === LANDLORD ?
      <MissingDataTable
        landlord={true}
        colorLabel={dataQualityHomeState.colorLabelLandlord}
        missingDataPdf={missingDataPdf}
        selectedCategoryLandlord = {dataQualityHomeState.selectedCategoryLandlord.value}
        missingDataList={dataQualityHomeState.dataGapLandlordSorted}
        refetch={refetchMissingAndMeterData}
        selectedUtilityLandlord={dataQualityHomeState.selectedUtilityLandlord?.value}
        activeStackedBar={dqOverviewGraphLandlordProps?.expandGraphBarConfig?.graphColorLabel}
        isLoading={isMissingDataLoading(missingData)}
        missingData={missingData}
      /> :
      <MissingDataTable
        landlord={false}
        colorLabel={dataQualityHomeState.colorLabelTenant}
        missingDataPdf={missingDataPdf}
        selectedCategoryTenant = {dataQualityHomeState.selectedCategoryTenant.value}
        missingDataList={dataQualityHomeState.dataGapTenantSorted}
        refetch={refetchMissingAndMeterData}
        selectedUtilityTenant={dataQualityHomeState.selectedUtilityTenant.value}
        activeStackedBar={dqOverviewGraphTenantProps?.expandGraphBarConfig?.graphColorLabel}
        isLoading={isMissingDataLoading(missingData)}
        missingData={missingData}
      />
  }

  function getDataQualityOverviewGraph() {
    if (summaryType === LANDLORD) {
      if (dataQualityHomeState?.dataGapLandlordSorted?.length === 0 && dqOverviewDataForLandlord?.data?.[0]?.noCoverage === 1) {
        return null
      }
      return <DataQualityOverviewGraph {...dqOverviewGraphLandlordProps} />
    } else {
      if (dataQualityHomeState?.dataGapTenantSorted?.length === 0 && dqOverviewDataForTenant?.data?.[0]?.noCoverage === 1) {
        return null
      }
      return <DataQualityOverviewGraph {...dqOverviewGraphTenantProps} />
    }
  }

  return (
    <>
      <div className={'content-header'} role={`${summaryType}-content-header`}>
        {summaryType === LANDLORD ? I18n('t_landlord_procured') : I18n('t_tenant_procured')}
      </div>
      <div className={'menu-view'} role={`${summaryType}-menu-view`}>
        {getDataQualityFilters()}
      </div>
      <div className={'graph-view'}>
        {
          isOverviewGraphDataLoading(summaryType === LANDLORD ? dqOverviewDataForLandlord : dqOverviewDataForTenant) ?
            <DataQualityCardOverviewSkeleton/>
            :
            getDataQualityOverviewGraph()
        }
      </div>
      <div className={'missing-datatable-view-container'} role={`${summaryType}-datatable`}>
        {
          getMissingTable()
        }
      </div>
    </>
  )
} 

export default SummaryViewLandlordTenant

SummaryViewLandlordTenant.displayName = 'DataQualityHomeTabPdf'

SummaryViewLandlordTenant.propTypes = {
  dataQualityHomeState: PropTypes.object,
  updateDataQualityHomeState: PropTypes.func,
  missingData: PropTypes.object,
  summaryType: PropTypes.string,
  meterData: PropTypes.any,
  dqOverviewDataForLandlord: PropTypes.object,
  dqOverviewDataForTenant: PropTypes.object,
}