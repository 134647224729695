
import PropTypes from 'prop-types'
import {getLocalizedValue} from '../../../utils/helpers/Helper'
import './TargetPreviousVarianceLegend.scss'
import {Icon} from '../../atomic'

function TargetPreviousVarianceLegend({selectedLanguage}) {
  return (
    <div className="flex tp-variance-legend justify-content-between align-items-center">
      <span className="tp-variance-legend__target flex align-items-center">
        <Icon id="target" size={17} />
        <p>{getLocalizedValue(selectedLanguage, 't_variance_against_target')}</p>
      </span>
      <span className="tp-variance-legend__previous-period flex align-items-center">
        <Icon id="calendar" size={17} />
        <p>{getLocalizedValue(selectedLanguage, 't_variance_against_previous_period')}</p>
      </span>
    </div>
  )
}

TargetPreviousVarianceLegend.propTypes = {
  selectedLanguage: PropTypes.string
}

TargetPreviousVarianceLegend.displayName = 'TargetPreviousVarianceLegend'

export default TargetPreviousVarianceLegend