import {
  convertToExcelExportFormat,
  formatDateToDDMMYY,getValidData,UTILITY_MAP_FOR_PERFORMANCE_EXPORT,getRequiredUnit,
  getLocalizedColumnNamesWithUnits,getNumberOfMonthsAndEndMonth,UNIT_TYPE_FOR_EXPORT,getExcelCellName,
} from '../../ExportHelper'
import { UTILITY_LABELS_OBJECT_TITLE_CASE} from '../../../helpers/Constants'

import { 
  benchmarkAndStandardBenchmarkData, 
  benchmarkAndStandardBenchmarkTitle,
} from './assetPerformanceCommonExportHelper'

export function isObject(objValue) {
  return objValue && typeof objValue === 'object' && objValue.constructor === Object
}

export function getMonthlyConsumption(timePeriod,pickerValue,utilityConsumptionInfo){
  let noOfMonthsAndEndMonthObject = getNumberOfMonthsAndEndMonth(timePeriod,pickerValue )
  let monthRepresentingNumbersArray = Array.from({length: noOfMonthsAndEndMonthObject?.numberOfMonths},
    (_, index) => (noOfMonthsAndEndMonthObject?.endMonth - index + 12) % 12).reverse()
  let monthlyConsumptionArray= []
  monthRepresentingNumbersArray.forEach(monthNumber => {
    let monthlyConsumption = 0
    if(!utilityConsumptionInfo.monthlyConsumption){
      monthlyConsumptionArray.push(monthlyConsumption)
    }else{
      utilityConsumptionInfo.monthlyConsumption[monthNumber + 1] !== undefined ?
        monthlyConsumptionArray.push(utilityConsumptionInfo.monthlyConsumption[monthNumber + 1]):
        monthlyConsumptionArray.push(monthlyConsumption)
    }
    
  })
  return monthlyConsumptionArray
}

export function mappedConsumptionDataByMonthly(utilityConsumptionInfo, timePeriod, pickerValue, intl, translationKey){
  if(isObject(utilityConsumptionInfo?.monthlyConsumption) && !Object.keys(utilityConsumptionInfo?.monthlyConsumption).length || utilityConsumptionInfo?.monthlyConsumption === null){
    const getMonthOfObject = getNumberOfMonthsAndEndMonth(timePeriod, pickerValue)
    const monthNumber = getMonthOfObject?.numberOfMonths - 1 || 0
    return [...getLocalizedColumnNamesWithUnits([{key: translationKey }], intl), ...new Array(monthNumber).fill(' - ')]
  }
  return getMonthlyConsumption(timePeriod,pickerValue,utilityConsumptionInfo)
}

export function rangeSelectionMonthly(monthlyConsumptionData, index, assetTableColumnNamesWithLocalization){
  if(Array.isArray(assetTableColumnNamesWithLocalization)){
    if(isObject(monthlyConsumptionData) && !Object.keys(monthlyConsumptionData).length || monthlyConsumptionData === null){
      const consumptionIndex = assetTableColumnNamesWithLocalization.findIndex(item => item.match(/Consumption|Emissions/))
      if(consumptionIndex !== -1){
        return {
          start: getExcelCellName(consumptionIndex + 1),
          end: getExcelCellName(assetTableColumnNamesWithLocalization.length),
          index,
        }
      }
    }
  }
  return null
}

export function getAssetMonthlyPerformanceHeading(utility, getNumberOfMonths) {
  let commonTableHeaders1 = [
    {key: 't_asset_ref'}, {key: 't_asset_name'}, {key: 't_fund'}, { key: 't_country'},{key: 't_city'}, {key: 't_sector'}, {key: 't_gresb_sector'}, { key: 't_purchase_date'},
    {key: 't_sale_date'}, {key: 't_total_asset_floor_area', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.AREA, null, null)},{key: 't_occupancy_excel'},{key: 't_is_the_asset_tenant'}, { key: 't_reporting_period'}
  ]

  const commonHeadersForConsumption = [
    {key: 't_asset_level_total_consumption_excel', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)},{key: 't_reporting_period_carbon_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
    {key: 't_asset_level_total_consumption_excel', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)},{key: 't_reporting_period_carbon_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
    {key: 't_reporting_period_gap_filler_data_percentage'}, {key: 't_over_under_target_excel'},
    { key: 't_intensity_excel', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_INTENSITY, null, null)},
  ]

  const carbonScopeField = [
    {key: 't_scope_1_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
    {key: 't_scope_2_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
    {key: 't_scope_3_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
  ]

  switch (utility) {
  case UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON:
    return [...commonTableHeaders1,
      {key: 't_current_total_carbon_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      ...carbonScopeField,
      {key: 't_current_total_carbon_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      ...carbonScopeField,
      {key: 't_reporting_period_gap_filler_data_percentage'},
      {key: 't_carbon_intensity_excel', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.CARBON_INTENSITY, null, null)},
      {key: 't_over_under_target_excel'},
    ]

  case UTILITY_LABELS_OBJECT_TITLE_CASE.WATER:

    return  [...commonTableHeaders1,
      {key: 't_total_consumption', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.WATER_CONSUMPTION, null, null)},{key: 't_total_consumption', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.WATER_CONSUMPTION, null, null)},{key: 't_reporting_period_gap_filler_data_percentage'}, 
      {key: 't_over_under_target_excel'},{key: 't_water_intensity_excel', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.WATER_INTENSITY, null, null)},
      ...benchmarkAndStandardBenchmarkTitle(getNumberOfMonths, true),
    ]

  case UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE:
    return [...commonTableHeaders1,{key: 't_month'},{key: 't_hazardous_waste', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_non_hazardous_waste', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},{key: 't_total_waste_generated', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_landfill_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},{key: 't_incinerated', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_reuse_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},{key: 't_waste_to_energy_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_recycled_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},{key: 't_unknown_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)}]

  case UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY:
    return [...commonTableHeaders1,
      ...commonHeadersForConsumption,
      ...benchmarkAndStandardBenchmarkTitle(getNumberOfMonths),
    ]
  case UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY:
    return [...commonTableHeaders1, ...commonHeadersForConsumption]
  case UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL:
    return [...commonTableHeaders1,{key: 't_utility_type'}, 
      ...commonHeadersForConsumption,
      ...benchmarkAndStandardBenchmarkTitle(getNumberOfMonths), 
    ]
  }
}

export function getAssetMonthlyPerformanceData({utility, assetTableData, selectedLanguage, timePeriod, pickerValue, getNumberOfMonths, assetTableColumnNamesWithLocalization, intl}) {
  function getAssetTableRowData(utilityConsumptionInfo, utility,asset){

    let commonTableColumnValuesSet1 = [
      asset.assetReference,
      getValidData(asset.name, selectedLanguage, false, false),getValidData(asset.fundName, selectedLanguage, false, false),
      getValidData(asset.assetLocation, selectedLanguage), getValidData(asset.assetLocation.includes(',') && asset.assetLocation.split(',')[0], selectedLanguage), getValidData(asset.sector, selectedLanguage),
      getValidData(asset.gresbSector, selectedLanguage),formatDateToDDMMYY(asset.assetPurchaseDate, selectedLanguage),
      formatDateToDDMMYY(asset.assetSaleDate, selectedLanguage),getValidData(asset.floorArea, selectedLanguage),
      getValidData(asset.occupancy, selectedLanguage),getValidData(asset.isTenantControlled, selectedLanguage),getValidData(utilityConsumptionInfo.reportingPeriod, selectedLanguage),
    ]
    
    function getMonthlyConsumptionData(){
      return mappedConsumptionDataByMonthly(utilityConsumptionInfo, timePeriod, pickerValue, intl, 't_no_export_data_available_for_single_asset')
    }

    const commonTableColumnDataForConsumption = [
      ...getMonthlyConsumptionData(),
      getValidData(utilityConsumptionInfo.actualConsumption, selectedLanguage),getValidData(utilityConsumptionInfo.actualCurrentCarbonConsumption, selectedLanguage),
      getValidData(utilityConsumptionInfo.consumption, selectedLanguage),getValidData(utilityConsumptionInfo.currentCarbonEmission, selectedLanguage),
      '-',
      getValidData(utilityConsumptionInfo.targetToDelta, selectedLanguage),getValidData(utilityConsumptionInfo.totalIntensity, selectedLanguage),
    ]

    switch (utility) {
    case UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON:
      return [...commonTableColumnValuesSet1,
        ...getMonthlyConsumptionData(),
        getValidData(utilityConsumptionInfo.actualConsumption, selectedLanguage),
        getValidData(utilityConsumptionInfo.actualScope1, selectedLanguage),
        getValidData(utilityConsumptionInfo.actualScope2, selectedLanguage),
        getValidData(utilityConsumptionInfo.actualScope3, selectedLanguage),
        getValidData(utilityConsumptionInfo.consumption, selectedLanguage),
        getValidData(utilityConsumptionInfo.scope1, selectedLanguage),
        getValidData(utilityConsumptionInfo.scope2, selectedLanguage),
        getValidData(utilityConsumptionInfo.scope3, selectedLanguage),
        '-',
        getValidData(utilityConsumptionInfo.totalIntensity, selectedLanguage),
        getValidData(utilityConsumptionInfo.targetToDelta, selectedLanguage),
      ]

    case UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY:
      return [...commonTableColumnValuesSet1, ...commonTableColumnDataForConsumption,
        ...benchmarkAndStandardBenchmarkData(utilityConsumptionInfo, selectedLanguage, getNumberOfMonths),
      ]

    case UTILITY_LABELS_OBJECT_TITLE_CASE.WATER:
      return [...commonTableColumnValuesSet1, ...getMonthlyConsumptionData(), 
        getValidData(utilityConsumptionInfo.actualConsumption, selectedLanguage),getValidData(utilityConsumptionInfo.consumption, selectedLanguage),
        '-',
        getValidData(utilityConsumptionInfo.targetToDelta, selectedLanguage),getValidData(utilityConsumptionInfo.totalIntensity, selectedLanguage), 
        ...benchmarkAndStandardBenchmarkData(utilityConsumptionInfo, selectedLanguage, getNumberOfMonths)]

    case UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE:
      return [...commonTableColumnValuesSet1,getValidData(utilityConsumptionInfo.month, selectedLanguage),
        getValidData(utilityConsumptionInfo.hazardous, selectedLanguage),getValidData(utilityConsumptionInfo.nonHazardous, selectedLanguage),
        getValidData(utilityConsumptionInfo.tonnes, selectedLanguage),getValidData(utilityConsumptionInfo.landfill, selectedLanguage),
        getValidData(utilityConsumptionInfo.incinerated, selectedLanguage),getValidData(utilityConsumptionInfo.reuse, selectedLanguage),
        getValidData(utilityConsumptionInfo.incineratedWer, selectedLanguage),getValidData(utilityConsumptionInfo.recycled, selectedLanguage),
        getValidData(utilityConsumptionInfo.mrfUnknown, selectedLanguage)]

    case UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY:
      return [...commonTableColumnValuesSet1, ...commonTableColumnDataForConsumption]

    case UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL:
      return [...commonTableColumnValuesSet1, 
        getValidData(utilityConsumptionInfo.utility, selectedLanguage),
        ...commonTableColumnDataForConsumption,
        ...benchmarkAndStandardBenchmarkData(utilityConsumptionInfo, selectedLanguage, getNumberOfMonths),
      ]
    }
  }

  // execution starts here...
  let assetTableRows = []
  let assetTableNumberOfRows = 0
  const rangeDataSelection = []
  if (assetTableData)
    assetTableData.forEach((asset) => {
      let utilityConsumptionInfoOfAssetArray = asset[UTILITY_MAP_FOR_PERFORMANCE_EXPORT[utility]] || []
      utilityConsumptionInfoOfAssetArray.forEach(utilityConsumptionInfo => {
        assetTableNumberOfRows+=1
        let assetTableRowData = getAssetTableRowData(utilityConsumptionInfo, utility, asset)
        const getRangeSelection = rangeSelectionMonthly(utilityConsumptionInfo.monthlyConsumption, assetTableRows.length, assetTableColumnNamesWithLocalization)
        if(getRangeSelection){
          rangeDataSelection.push(getRangeSelection)
        }
        assetTableRows.push(convertToExcelExportFormat(assetTableRowData))
      })
    })
  return {assetTableRows,assetTableNumberOfRows, rangeDataSelection}
}