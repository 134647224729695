import React, {useEffect, useState} from 'react'
import './ESConnectionHome.scss'
import I18n from '../../../../../utils/i18n/I18n'
import {ENERGY_STAR_STEPS, esLearnMore, getESErrorMessage} from '../../../../../utils/helpers/Constants'
import ESConnectionStepOne from '../energy-star-link/ESConnectionStepOne/ESConnectionStepOne'
import ESConnectionStepTwo from '../energy-star-link/ESConnectionStepTwo/ESConnectionStepTwo'
import ESConnectionStepThree from '../energy-star-link/ESConnectionStepThree/ESConnectionStepThree'
import ESConnectionStepFour from '../energy-star-link/ESConnectionStepFour/ESConnectionStepFour'
import ESConnectionSuccess from '../energy-star-link/ESConnectionSuccess/ESConnectionSuccess'
import {LOCALES} from '../../../../../utils/i18n'
import {getLocalStorageByKey, getLocalStorageItem, setLocalStorageItem} from '../../../../../utils/helpers/Helper'
import {useMutation} from 'react-query'
import {updateSettings} from '../../../../../services/settings-service'
import ESAssets from '../../energy-star-matched-assets/ESAssets'
import {useHistory} from 'react-router-dom'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const ESConnectionComponent = () => {
  const [errorMessage, setErrorMessage] = useState(getESErrorMessage[null])
  const {loginDispatch, loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const esConnectionStep = userInfo.connectionStep
  const searchParams = new URLSearchParams(location.search)
  const history = useHistory()
  const [matchMoreAssets, setMatchMoreAssets] = useState(false)
  const [stepActiveIndex, setStepActiveIndex] = useState(searchParams.get('esStep') ? Number(searchParams.get('esStep')) : esConnectionStep+1)
  const updateSettingMutation = useMutation(updateSettings)

  useEffect(() => {
    addSearchParam()
  }, [stepActiveIndex])

  const addSearchParam = () => {
    searchParams.set('esStep',stepActiveIndex || esConnectionStep+1)
    history.replace({
      search: searchParams.toString(),
    })
  }

  async function connectionStepHandler(stepIndex) {
    let selectedStepObj = {
      connectionStep: stepIndex,
      unitSystem: userInfo.unitSystem,
      languagePreference: Object.keys(LOCALES).find(key => LOCALES[key] === selectedLanguage),
      currencyUnit: userInfo.currencyUnit
    }
    setErrorMessage(getESErrorMessage[null])
    try {
      await updateSettingMutation.mutateAsync({
        userId: getLocalStorageItem('userId'),
        updatedData: selectedStepObj
      })
      loginDispatch({ type: 'UPDATE_USER_INFO', payload: { connectionStep: stepIndex}})
      setLocalStorageItem('userInfo', {...getLocalStorageByKey('userInfo'), connectionStep: stepIndex})
      setStepActiveIndex(stepIndex + 1)
    } catch (e) {
      setErrorMessage(getESErrorMessage[6])
    }
  }

  async function onStepChange(index) {
    searchParams.set('esStep',index+1)
    history.replace({
      search: searchParams.toString(),
    })
    await connectionStepHandler(index)
  }

  function stepHeader(stepIndex) {
    let stepTitle
    if (stepIndex === ENERGY_STAR_STEPS.INIT_ESPM_STEP) {
      stepTitle = I18n('t_es_step_title', {
        0: stepIndex,
        1: 4,
        2: I18n('t_es_step_one')
      })
    } else if (stepIndex === ENERGY_STAR_STEPS.ACCEPT_ESPM_ID_STEP) {
      stepTitle = I18n('t_es_step_title', {
        0: stepIndex,
        1: 4,
        2: I18n('t_es_step_two')
      })
    } else if (stepIndex === ENERGY_STAR_STEPS.SHARE_PROPERTIES_STEP) {
      stepTitle = I18n('t_es_step_title', {
        0: stepIndex,
        1: 4,
        2: I18n('t_es_step_three')
      })
    } else if (stepIndex === ENERGY_STAR_STEPS.CHOOSE_ASSETS_STEP) {
      if(matchMoreAssets) {
        stepTitle = I18n('t_es_step_four')
      } else {
        stepTitle = I18n('t_es_step_title', {
          0: stepIndex,
          1: 4,
          2: I18n('t_es_step_four')
        })
      }
    } else if (stepIndex === ENERGY_STAR_STEPS.SUCCESS_STEP) {
      stepTitle = I18n('t_success')
    }
    return (
      <>{stepTitle}</>
    )
  }

  const matchMore = ()=>{
    setMatchMoreAssets(true)
    setStepActiveIndex(ENERGY_STAR_STEPS.CHOOSE_ASSETS_STEP)
  }

  return (
    <div className="es-connection-home grey-bg">
      <div className="container-layout grey-bg gutter">
        <h1 role={'esc-common-title'}>{I18n('t_energy_star_integration')}</h1>
        {stepActiveIndex < 6 ? (<>
          <div className={'es-connection-home__es-connection-header'} role={'esc-common-header'}>
            <span className={'es-connection-home__es-connection-header--es-sub-text'}>{I18n('t_es_sub_text')}</span>
            <a className={'es-connection-home__es-connection-header--es-learner'} href={esLearnMore} target="_blank" rel="noreferrer noopener">
              {I18n('t_learn_more')}
            </a>
          </div>
          <div className={'es-connection-home__es-multi-steps white-bg'}>
            <h1 role={'esc-header'}>{stepHeader(stepActiveIndex)}</h1>
            {stepActiveIndex === ENERGY_STAR_STEPS.INIT_ESPM_STEP &&
                          <ESConnectionStepOne stepActiveIndex={stepActiveIndex} onStepChange={onStepChange}/>
            }
            {stepActiveIndex === ENERGY_STAR_STEPS.ACCEPT_ESPM_ID_STEP &&
                         <ESConnectionStepTwo stepActiveIndex={stepActiveIndex} onStepChange={onStepChange}
                           isError={(msg)=>setErrorMessage(msg)}/>
            }
            {stepActiveIndex === ENERGY_STAR_STEPS.SHARE_PROPERTIES_STEP &&
                         <ESConnectionStepThree stepActiveIndex={stepActiveIndex} onStepChange={onStepChange}
                           isError={(msg)=>setErrorMessage(msg)}/>
            }
            {stepActiveIndex === ENERGY_STAR_STEPS.CHOOSE_ASSETS_STEP &&
                         <ESConnectionStepFour stepActiveIndex={stepActiveIndex} onStepChange={onStepChange}
                           isError={(msg)=>setErrorMessage(msg)} matchMoreAssets={matchMoreAssets}/>
            }
            {stepActiveIndex === ENERGY_STAR_STEPS.SUCCESS_STEP &&
                        <ESConnectionSuccess onStepChange={onStepChange}/>
            }
            {errorMessage !== '' && <span className="es-connection-home__es-multi-steps--p-error">{errorMessage}</span>}
          </div>
        </>) : <ESAssets onStepChange={onStepChange} setMatchMoreAssets={matchMore}/>}
      </div>
    </div>
  )
}

export default ESConnectionComponent