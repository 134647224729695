import {useRef, useState} from 'react'
import I18n from '../../../../utils/i18n/I18n'
import {calendarDateFormat} from '../../../../utils/helpers/DateUtils'
import {
  getDropdownItemTemplate,
  selectedItemTemplate
} from '../../../../utils/helpers/Helper'
import './DocumentsFilter.scss'
import {Button, Calendar} from 'components/atomic'
import {DOCUMENT_FILTER_TYPES, documentFilterTypes} from '../DocumentsUtil'
import CustomSidebar from '../../../common/custom-sidebar/CustomSidebar'
import PropTypes from 'prop-types'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'
import {MultiSelect, Text} from '../../../atomic'

export const DocumentsFilter = (props) => {
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const previousFilterSelections = useRef(props.filterSelections)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)

  function handleFilterSelectionChange(e, option) {
    setIsButtonDisabled(false)
    props.handleFilterSelectionChange(e, option, props.tabIndex)
  }

  function onHideCalendar(field) {
    const currentValue = props.filterSelections[field]

    if (currentValue && currentValue[1] === null) {
      handleFilterSelectionChange({value: null}, field)
      const previousFilters = {...previousFilterSelections.current}
      const currentFilters = {...props.filterSelections}
      delete previousFilters[field]
      delete currentFilters[field]

      if(JSON.stringify(previousFilters) === JSON.stringify(currentFilters)) {
        setIsButtonDisabled(true)
      }
    }
  }

  function onHideSidebar() { props.onHideOrCloseOfFilter(previousFilterSelections.current, props.tabIndex) }

  const renderFilterFields = () => {
    const filterTypes = Object.keys(documentFilterTypes(selectedLanguage, props.tabIndex,props.data,props.isLoading))
    return filterTypes.map((option) => {
      const label = documentFilterTypes(selectedLanguage, props.tabIndex,props.data,props.isLoading)[option].label
      const dropdownOptions = documentFilterTypes(selectedLanguage, props.tabIndex,props.data,props.isLoading)[option]
        .dropdownOptions?.map(option => option?.label||option)
      const placeholder = documentFilterTypes(selectedLanguage, props.tabIndex,props.data,props.isLoading)[option].placeholder
      const filterPlaceholder = documentFilterTypes(selectedLanguage, props.tabIndex,props.data,props.isLoading)[option].filterPlaceholder
      const selectedOption = props.filterSelections[option]
      const fieldClassName = [DOCUMENT_FILTER_TYPES.VALID_WITHIN, DOCUMENT_FILTER_TYPES.EVIDENCE_DATE_RANGE].includes(option)
        ? 'filter-content-container__calendar'
        : 'filter-content-container__dropdown'
      return (
        <div key={option} className={fieldClassName}>

          {[DOCUMENT_FILTER_TYPES.VALID_WITHIN, DOCUMENT_FILTER_TYPES.EVIDENCE_DATE_RANGE].includes(option) ?
            <Calendar
              dateFormat={calendarDateFormat()}
              label={label}
              name={option}
              placeholder={placeholder}
              value={selectedOption}
              selectionMode="range"
              onHide={() => onHideCalendar(option)}
              onChange={(e) => handleFilterSelectionChange(e, option)}/>
            :
            <>
              <div className={`${fieldClassName}--label`}>
                <Text content={label}/>
              </div>
              <MultiSelect
                size={'lg'}
                value={selectedOption ? selectedOption.map(item => item.label || item) : selectedOption}
                options={dropdownOptions}
                maxSelectedLabels={3}
                disabled={props.isLoading || dropdownOptions?.length === 0}
                onChange={(e) => handleFilterSelectionChange(e, option)}
                onHide={props.onSelectionDone}
                placeholderKey={placeholder}
                data-testid={option}
                itemTemplate={getDropdownItemTemplate}
                selectedItemTemplate={(item) => selectedItemTemplate(item, props.filterSelections[option])}
                panelClassName={dropdownOptions.length > 7 ? 'multiselect-filter-dropdown' : 'multiselect-dropdown'}
                filter={dropdownOptions.length > 7}
                filterPlaceholder={filterPlaceholder}
                showSelectAll={false}
              />
            </>
          }
        </div>
      )
    })
  }

  const renderApplyAndCancelButton = () => {
    const handleApplyButtonClick = () => {
      previousFilterSelections.current = props.filterSelections
      props.handleFiltersApplyButtonClick(props.filterSelections, props.tabIndex)
    }

    return (
      <div className="filter-content-container__apply-cancel-button-container">
        <Button 
          content='t_apply'
          onClick={handleApplyButtonClick}
          disabled={isButtonDisabled}
          size='small'
        />
        <Button 
          outlined={true} 
          content='t_cancel'
          onClick={props.handleFilterClearButtonClick}
          size='small'
        />
      </div>
    )
  }

  return (
    <CustomSidebar isSidebarVisible={props.isFilterVisible}
      onHideSidebar={onHideSidebar}
      customClass="custom-filter-sidebar documents-filter"
      sidebarHeader={props.tabIndex === 0 ? I18n('t_filter_documents') : I18n('t_filter_consumption_evidence')}
      dismissable={false}>
      <div className="filter-content-section" data-testid='documents-filter'>
        <div className="filter-content-container">
          {renderFilterFields()}
        </div>
        {renderApplyAndCancelButton()}
      </div>
    </CustomSidebar>
  )
}

DocumentsFilter.propTypes = {
  isFilterVisible: PropTypes.bool,
  tabIndex: PropTypes.number,
  filterSelections: PropTypes.object,
  onHideOrCloseOfFilter: PropTypes.func,
  handleFiltersApplyButtonClick: PropTypes.func,
  handleFilterSelectionChange: PropTypes.func,
  handleFilterClearButtonClick: PropTypes.func,
  isLoading:PropTypes.bool,
  data:PropTypes.object,
  onSelectionDone:PropTypes.func
}