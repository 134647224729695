import React,  {useContext, useEffect} from 'react'
import {COMMON_PAGES} from '../../../utils/helpers/Constants'
import {Redirect, Route, useHistory} from 'react-router-dom'
import {Flags} from '../../../utils/reducers/FeatureFlagReducer'
import {isNZCModuleAccessible, isUserLoggedIn, prefixSlash} from '../../../utils/helpers/Helper'
import FundAccessRoute from '../access-routes/FundAccessRoute'
import useNzcFeature from '../../../utils/custom-hooks/useNzcFeature'
import {updateFeatureFlagsAndContinue} from '../../pages/funds/net-zero-carbon-home/nzc-utils/nzc-utils'
import {FeatureFlagContext} from 'Contexts'

const NzcFeatureRoute = ({component: Component, flag, ...rest}) => {
  const nzcFeatureFlag = useNzcFeature(flag)
  const history = useHistory()
  const featureFlagContext = useContext(FeatureFlagContext)
  // Handles page reload/refresh
  useEffect(() => {
    if (isUserLoggedIn() && !nzcFeatureFlag.current) {
      updateFeatureFlagsAndContinue(history, featureFlagContext, nzcFeatureFlag, flag)
    }
  }, [])
  if (!isUserLoggedIn()) {
    return <Redirect to={{pathname: prefixSlash(COMMON_PAGES.login), state: {referrer: `${window.location.pathname}${window.location.search}`}}} />
  } else {
    return <Route render={props => (nzcFeatureFlag.current && isNZCModuleAccessible()) ? <FundAccessRoute singleFund component={Component} {...props} flag={Flags.FundViews} {...rest} /> :null} {...rest} />
  }
}

export default NzcFeatureRoute