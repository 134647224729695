import React from 'react'
import { Skeleton } from 'primereact/skeleton'
import './IndividualMetersChartSkeleton.scss'

const IndividualMetersChartSkeleton = () => {
  return (
    <div className="custom-skeleton p-rel flex contour align-items-center i-m-c-s mb-4">
      <div>
        <div className="flex justify-content-start align-items-center">
          <Skeleton width="6rem" borderRadius="0" height=".5rem" className="mb-2"/>
          <div className="flex ml-auto align-items-center">
            <Skeleton width="6rem" borderRadius="0" height=".5rem" className="mb-2"/>
            <Skeleton shape="square" size="25px" borderRadius="3px" height=".5rem" className="mb-2 ml-3"/>
          </div>
        </div>
        <Skeleton width="100%" borderRadius="0" height="1.5rem"/>
      </div>

    </div>
  )
}

export default IndividualMetersChartSkeleton
