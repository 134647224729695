export const LOCALES = {
  ENGLISH: 'en-GB',
  GERMAN:  'de-DE',
  FRENCH:  'fr-FR',
  ITALIAN: 'it-IT',
  SPANISH: 'es-ES',
  SWEDISH: 'sv-SE',
}

export const REV_LOCALES = {
  'en-GB': 'ENGLISH',
  'de-DE': 'GERMAN',
  'fr-FR': 'FRENCH',
  'it-IT': 'ITALIAN',
  'es-ES': 'SPANISH',
  'sv-SE': 'SWEDISH',
}

export const UNITS = {
  METRIC: 'Metric',
  IMPERIAL: 'Imperial'
}

export const BULK_UPLOAD = {
  CONSUMPTION: 'consumption',
  ACTION_PLAN: 'action',
  ALL: 'all'
}