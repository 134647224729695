import React from 'react'
import I18n from '../../../../utils/i18n/I18n'
import '../asset-row-skeleton/AssetRowSkeleton.scss'
import {noDataAvailableMessage} from '../../../../utils/helpers/MessageUtility'

const NZCModelNotesCard = ({ data, isNzcDefaultModel }) => {    
  const { scopeName, description } = data
  return (
    <div className={'model-notes-card'}>
      <div className={'summary-card'}>
        {scopeName && description ? (
          <>
            <span className={'card-heading'}>
              <span>{scopeName} </span>
              {isNzcDefaultModel && <span> - {I18n('t_nzc_default_text')}</span>}
            </span>
            <div className={'notes-description'}>
              {description}
            </div>
          </>
        ):
          noDataAvailableMessage()
        }
                
      </div>
    </div>
  )
}

export default NZCModelNotesCard