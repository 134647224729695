import axiosInstance from './common/axios-config'

export const updateSettings = async (userData) => {
  return await axiosInstance.put(`/api/v1/Users/${userData.userId}/Settings`, userData.updatedData)
}

export const changePassword = async (passwordInfo) => {
  return await axiosInstance.post('/api/v1/Authentication/ChangePassword', passwordInfo)
}

export const resetPassword = async (passwordInfo) => {
  return await axiosInstance.post('/api/v1/Authentication/ResetPassword', passwordInfo)
}

export const forgotPassword = async (email) => {
  return await axiosInstance.post('/api/v1/Authentication/ForgotPassword', email)
}