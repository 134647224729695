import React from 'react'

const RowInfo = ({options}) => {

  return (
    <span className={'skip-options'}>
      {options.first} - {options.last} of {options.totalRecords}
    </span>
  )
}

export default RowInfo