import axiosInstance from '../common/axios-config'
import {CUSTOM} from '../../utils/helpers/Constants'

export const GetFundDQMetric = async (keyInfo) => {
  const fundGroupId=keyInfo?.queryKey[1]
  const timePeriod = keyInfo?.queryKey[2]
  const noOfMonths = keyInfo?.queryKey[3]
  const endDate = keyInfo?.queryKey[4]
  let data
  
  if(!fundGroupId) {
    return
  }
  if(!noOfMonths || !endDate){
    data = await axiosInstance.get(`/api/v1/FundDataQuality/${fundGroupId}/FundDataQualityResponsibility?period=${timePeriod === 'CUS' ? 'YTD' : timePeriod}`)
  } else {
    data = await axiosInstance.get(`/api/v1/FundDataQuality/${fundGroupId}/FundDataQualityResponsibility?period=${timePeriod === 'CUS' ? 'YTD' : timePeriod}&noOfMonths=${noOfMonths}&endMonth=${endDate}`)
  }
  return data
}

export const GetFundAssetActions = async (keyInfo) => {
  let assetList
  const fundGroupId=keyInfo.queryKey[1]
  const oDataQuery=keyInfo.queryKey[2]
  const impact=keyInfo.queryKey[3].replace(/&/g,'%26')
  if(fundGroupId){
    if(oDataQuery) {
      assetList= await axiosInstance.get(`/api/v1/FundGroups/${fundGroupId}/AssetList${oDataQuery}${impact === 'All actions' ? '' : '&impact=' + impact}`)
    } else {
      assetList= await axiosInstance.get(`/api/v1/FundGroups/${fundGroupId}/AssetList`)
    }
  }
  return assetList
}

/*export const getUrlParam = (url) => {
  const urlParts = url.split('/');
  const lastUrlPart = urlParts.pop() || urlParts.pop();  // handle potential trailing slash
  return lastUrlPart;
}*/

export const getFundGroups = async (organisationId) => {
  const { data } = await axiosInstance.get(`/api/v1/FundGroups?organisationId=${organisationId}`)
  return data
}

export const getFundGroupsQuery = async (keys) => {
  const [queryKey, organisationId] = keys.queryKey
  if (organisationId > 0) {
    try {
      return await getFundGroups(organisationId)
    } catch (error) {
      console.log(error)
      return error.response
    }
  } else {
    return null
  }
}

export const getFundActionLens = async (keyInfo) => {
  const fundGroupId = keyInfo.queryKey[1]
  
  if(!fundGroupId) {
    return
  }

  const { data } = await axiosInstance.get(`/api/v1/FundGroups/${fundGroupId}/ActionLens`)
  return data
}

export const getFundEpcEnergyStarLens = async (keyInfo) => {
  const fundGroupId = keyInfo.queryKey[1]

  if(!fundGroupId) {
    return
  }

  const { data } = await axiosInstance.get(`/api/v1/FundGroups/${fundGroupId}/EPCEnergyStarLens`)
  return data
}

export const GetFundKeyfactsData = async (keyInfo) => {
  const fundGroupId = keyInfo.hasOwnProperty('queryKey') ? keyInfo.queryKey[1] : keyInfo
  if (!fundGroupId) {
    return
  }
  const {data} = await axiosInstance.get(`/api/v1/FundGroups/keyfacts/${fundGroupId}/users`)
  return data
}

export const getFundRenewablesData = async (keyInfo) => {
  const fundGroupId =  keyInfo.queryKey[1]
  const period = keyInfo.queryKey[2]
  const noOfMonths = keyInfo.queryKey[3]
  const endMonth = keyInfo.queryKey[4]
  const {data} =  await axiosInstance.get(`/api/v1/Renewables?fundGroupId=${fundGroupId}&period=${period}&noOfMonths=${noOfMonths}&endMonth=${endMonth}`)
  return data
}

export const getFundDataQualityData = async (keyInfo) => {
  const fundGroupId =  keyInfo.queryKey[1]
  const dateType = keyInfo.queryKey[2]
  const timeNoOfMonths = keyInfo.queryKey[3]
  const timeEndDate = keyInfo.queryKey[4]
  const isTimePeriodChanged = dateType=== CUSTOM ? keyInfo.queryKey[5] : keyInfo.queryKey[3]
  const selectedUtilitiesKeys = ['Electricity', 'Gas', 'OtherFuelsAndThermals', 'Water']
  let data
  let apiURL
  if(dateType=== CUSTOM){
    apiURL=`/api/v1/FundDataQuality/${fundGroupId}/FundDataQualityResponsibility/UtilitySummary/Details?period=${dateType}&noOfMonths=${timeNoOfMonths}&endMonth=${timeEndDate}&isTimePeriodChanged=${isTimePeriodChanged}`
  } else {
    apiURL=`/api/v1/FundDataQuality/${fundGroupId}/FundDataQualityResponsibility/UtilitySummary/Details?period=${dateType}&isTimePeriodChanged=${isTimePeriodChanged}`
  }
  if (!fundGroupId || !apiURL) {
    return
  }
  data = await axiosInstance.post(apiURL, selectedUtilitiesKeys)
  return data
}