import React from 'react'
import {Skeleton} from 'primereact/skeleton'
import  '../../../pages/admin/groups/groups-home/GroupsHome.scss'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'

const GroupsGridSkeleton = () => {
  const gridValue = Array.from({ length: 6 })

  const bodyTemplate = () => {
    return <Skeleton/>
  }

  const headerTemplate = (length) => {
    return <div className="p-ml-1 p-mb-4">
      <Skeleton width={length}/>
    </div>
  }

  return (
    <div className="custom-skeleton p-rel p-d-flex p-flex-column p-ai-center users-page-skeleton">
      <DataTable value={gridValue} className="groups-list-table">
        <Column field="Name" header={headerTemplate('8rem')} style={{width: '34%'}} body={bodyTemplate}/>
        <Column field="Assets" header={headerTemplate('4rem')} style={{width: '11%'}} body={bodyTemplate}/>
        <Column field="Members" header={headerTemplate('8rem')} style={{width: '17%'}} body={bodyTemplate}/>
      </DataTable>
    </div>
  )
}

export default GroupsGridSkeleton
