import {Page, Text, View, Document, Font} from '@react-pdf/renderer'
import React, {useState} from 'react'
import PdfLogo from '../../../../../utils/data-exports/fixed-components/logo/PdfLogo'
import PdfHeader from '../../../../../utils/data-exports/fixed-components/header/PdfHeader'
import Asset from '../../../../../utils/data-exports/asset/Asset'
import styles from '../../../../../utils/data-exports/style-sheet/Styles'
import UtilityCard from '../../../../../utils/data-exports/utility/UtilityCard'
import PdfPagination from '../../../../../utils/data-exports/fixed-components/footer/PdfPagination'
import DataQuality from '../../../../../utils/data-exports/utility/DataQuality'
import ActionPlan from '../../../../../utils/data-exports/common-tables/ActionPlan'
import {I18nProvider} from '../../../../../utils/i18n'
import I18n from '../../../../../utils/i18n/I18n'
import {
  CARBON,
  ELECTRICITY,
  eventDuration, UTILITY_LABELS_OBJECT_TITLE_CASE,
  WASTE,
  WATER
} from '../../../../../utils/helpers/Constants'
import {getFillerImage} from '../../../../../utils/helpers/Helper'
import UtilityCardLarge from '../../../../../utils/data-exports/utility/UtilityCardLarge'
import PropTypes from 'prop-types'
import DataQualityV2 from 'utils/data-exports/utility/DataQualityV2'

Font.registerHyphenationCallback(word => (
  [word]
))

const DashboardPdf = (props) => {
  const today = new Date(), currentYear = today.getFullYear(), prevYear = today.getFullYear() - 1
  const [utilities] = useState(props.utility ? props.utility : [])
  const [language] = useState(props.language ? props.language : 'en-GB')
  const [timePeriod] = useState(eventDuration(props.timePeriod, language))
  const LANDLORD = 'Landlord'
  const TENANT = 'Tenant'
  const AGREED_ACTIONS = 'AgreedActions'

  const classifyUtility = (utilities, value) => {
    let result = ''
    for (const utility of utilities) {
      if (utility.utilitySummary === value) {
        result = utility
        break
      }
    }
    return result
  }

  const actionData = () => {
    let actionsData = []
    for (const individualAction of props.actionPlan || []) {
      if (individualAction.action.status === AGREED_ACTIONS) {
        actionsData.push(individualAction)
      }
    }
    return actionsData
  }

  const dqArea = (dataQuality, area) => {
    let areaDq = []
    for (const dq of dataQuality) {
      if (dq.responsbility === area) {
        areaDq.push(dq)
      }
    }
    return areaDq
  }

  return (
    <Document>
      <I18nProvider locale={props.language}>
        <Page size="A4" style={styles.page}>
          <View>
            <View style={styles.tableRow} fixed>
              <View>
                <PdfLogo header={I18n('t_esg_dashboard')}/>
              </View>
              <View>
                <PdfHeader asset={props.asset} language={props.language} unitSystem={props.unitSystem}/>
              </View>
            </View>

            <View style={[styles.title, styles.dateRangePosition]} fixed>
              <Text style={styles.textAlignRight}>{I18n('t_date_range')}: {timePeriod}</Text>
            </View>

            <Asset assetImgSrc={getFillerImage(props.asset, true, true)} asset={props.asset}
              lastRefurbished={props.lastRefurbished} certifications={props.certifications}
              language={language} isDashboard={true} unitSystem={props.unitSystem}/>

            <UtilityCard utility={classifyUtility(utilities, ELECTRICITY)} dashboard={true} time={timePeriod}
              showTarget={props.showTarget} utilityName={ELECTRICITY} language={language} unitSystem={props.unitSystem}/>

            <UtilityCard utility={classifyUtility(utilities, UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL)}
              dashboard={true} showTarget={props.showTarget} language={language} time={timePeriod}
              utilityName={UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL} unitSystem={props.unitSystem}/>

            <UtilityCard utility={classifyUtility(utilities, WATER)} dashboard={true} time={timePeriod}
              showTarget={props.showTarget} utilityName={WATER} language={language} unitSystem={props.unitSystem}/>

            <UtilityCardLarge utility={classifyUtility(utilities, WASTE)} dashboard={true} utilityName={WASTE}
              time={timePeriod} language={language} unitSystem={props.unitSystem}/>

            <UtilityCardLarge utility={classifyUtility(utilities, CARBON)} dashboard={true} time={timePeriod}
              showTarget={props.showTarget} utilityName={CARBON} language={language} unitSystem={props.unitSystem}/>
              
            {props.assetDQV2 ? 
              <DataQualityV2
                landlordCompleteness={props.dataQuality.completenessDataLandlord}
                tenantCompleteness={props.dataQuality.completenessDataTenant} 
                language={language}
                timePeriod={timePeriod}
              /> :
              <DataQuality 
                landlord={dqArea(props.dataQuality, LANDLORD)} 
                time={timePeriod}
                tenant={dqArea(props.dataQuality, TENANT)}
                language={language}/>  
            }

            <Text style={[styles.title, styles.dashboardTableTitle]}>
              {I18n('t_action_plan')} {prevYear}/{currentYear}
            </Text>

            {actionData().length > 0 ?
              <ActionPlan data={actionData()} language={language} isDashboard={true}/> :
              <View style={[styles.dashboardTable, styles.planBorder]}>
                <Text style={[styles.title, styles.noRecords]} break>
                  {I18n('t_no_results')}
                </Text>
              </View>
            }
            <PdfPagination language={language} unitSystem={props.unitSystem}/>
          </View>
        </Page>
      </I18nProvider>
    </Document>
  )
}

DashboardPdf.propTypes = {
  asset: PropTypes.object,
  language: PropTypes.string,
  unitSystem: PropTypes.string,
  dataQuality: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  assetDQV2: PropTypes.bool,
  showTarget: PropTypes.bool,
}
DashboardPdf.displayName = 'DashboardPdf'

export default DashboardPdf
