import React, {useEffect, useState} from 'react'
import {useMutation, useQuery} from 'react-query'
import '../asset-form/AssetForm.scss'
import {InputText} from 'primereact/inputtext'
import {Dropdown} from 'primereact/dropdown'
import {InputTextarea} from 'primereact/inputtextarea'
import {Checkbox} from 'primereact/checkbox'
import {FileUpload} from 'primereact/fileupload'
import {Formik} from 'formik'
import AssetFooter from '../asset-footer/AssetFooter'
import QuestionMarkIcon from '../../../../../resources/images/icon/question-mark.svg'
import {GetPortfolioData} from '../../../../../services/assets/portfolio-service'
import {noRefetchOnWindowFocus} from '../../../../../services/common/useQuery-config'
import {AddNewAssetEPC} from '../../../../../services/admin/epc-service'

const AssetEpcForm = (props) => {

  const allTranslated = 'All'
  const chooseOptions = {label:  <u>Select or drop a file</u>, icon: ' '}
  const uploadOptions = {label: <u>Upload</u>, icon: ' ', className: ' '}
  const [selectedFile, setSelectedFile]=useState(null)
  const [schemeList, setSchemeList] = useState([allTranslated])
  const {isLoading, data} = useQuery(GetPortfolioData, noRefetchOnWindowFocus)

  // Formik properties.
  const initialValues= {
    scheme: '',
    epcScore: '',
    epcRating: '',
    certificateNumber: '',
    startDate: '',
    endDate: '',
  }

  function createAssetEPCObject(assetEPC) {
    // TODO: Actual implementations for creating new Asset level EPC object.
    return assetEPC
  }

  function onSubmit(values) {
    // TODO: Actual implementations for form submission.
    if(values){
      const newAssetEPCObj=createAssetEPCObject(values)
      submitNewAssetEPC(newAssetEPCObj)
    }
  }

  function getEmptyTemplate() {
    return ''
  }

  const submitNewAssetEPC = async (newAssetEPC) => {
    try {
      await newAssetEPCMutation.mutateAsync(newAssetEPC)
    } catch (error) {
      console.log(error.toString())
    }
  }

  const newAssetEPCMutation = useMutation(AddNewAssetEPC, {
    onSuccess: (data) => {
      if(data){
        //Redirect to the home page.
      }
    }
  })

  function getHeaderTemplate(options){
    const { className, chooseButton, uploadButton, cancelButton } = options

    return (
      <div className={'file-chooser-container'}>
        <div className={'asset-img-upload-title'}>Upload certificate</div>
        <span className={`${selectedFile?'hide-upload-option':''}`}>{chooseButton}</span>
        <span className={`${selectedFile?'':'hide-upload-option'}`}>{uploadButton}</span>
      </div>
    )
  }

  function onRemoveHandler(evt) {
    setSelectedFile(null)
  }

  function onSelectHandler(evt) {
    setSelectedFile(evt.files[0])
  }

  const questionMarkIcon = (className) => <><img className={className} src={QuestionMarkIcon} alt="QuestionMarkIcon"/></>

  useEffect(() => {
    if(data) {
      setSchemeList(data)
    }
  }, [data, allTranslated])

  const handleTabChange = () => {
    props.setActiveIndexTab(3)
    window.scroll(0, 0)
  }

  // Asset form columns.
  const columnEpc= formik => <>
    {/*If Units = ‘Yes’ in Asset details tab*/}
    <span className="unit-yes-label" data-testid={'unit-label'}>EPCs can be added to individual units when editing unit details</span>
    <div className={'options-container field-checkbox'}>
      <div className="flex epc-lease-cbx-wrapper" data-testid={'epc-lease-cbx'}>
        <Checkbox
          className={'lease-cbx'}
          inputId="repairingInsuringLease"
          name="repairingInsuringLease"
          checked={formik.values.repairingInsuringLease}
          {...formik.getFieldProps('repairingInsuringLease')}
        />
        <label className="label-checkbox" htmlFor="repairingInsuringLease">Add an asset level EPC as well?</label>
        {questionMarkIcon('')}
      </div>
    </div>
    {/*If Units = ‘Yes’ in Asset details tab*/}
    <div className="field-input input-dropdown scheme-name">
      <Dropdown
        className={'form-dropdown'}
        id="schemeName"
        name="scheme"
        {...formik.getFieldProps('scheme')}
        placeholder="Scheme"
        options={schemeList}
        panelClassName="form-dropdown-panel"
        data-testid={'scheme-dropdown'}
      />
      {questionMarkIcon('icon-question-mark')}
    </div>
    <div className="grid field-mini-input">
      <div className='input-mini'>
        <div className={'input-container epc-score col'}>
          <div className={'p-float-label'} data-testid={'epc-score-input'}>
            <InputText maxLength="3" keyfilter="num" max={'150'} id="epcSco" name="epcScore" {...formik.getFieldProps('epcScore')} />
            <label htmlFor="assetRef">EPC Score (0 - 150)</label>
          </div>
        </div>
        {questionMarkIcon('icon-question-mark')}
      </div>
      <div className='input-epc-rating input-dropdown epc-rating col'>
        <Dropdown
          className={'form-dropdown'}
          id="epcRating"
          name="epcRating"
          {...formik.getFieldProps('epcRating')}
          placeholder="EPC Rating"
          panelClassName="form-dropdown-panel"
          options={['All']}
          data-testid={'epc-rating-dropdown'}
        />
        {questionMarkIcon('icon-question-mark')}
      </div>
    </div>
    <div className="field-input">
      <div className={'input-container certificate-number'}>
        <div className={'p-float-label'} data-testid={'certificate-no-input'}>
          <InputText id="certificate-number" name="certificateNumber" {...formik.getFieldProps('certificateNumber')} />
          <label htmlFor="assetRef">Certificate number</label>
        </div>
      </div>
      {questionMarkIcon('icon-question-mark')}
    </div>
    <div className="flex lodgement-field-date">
      <div className="col-6 from-date">
        <div className='flex lodgement-label-date'>
          <span>Lodgement/start date</span>
          {questionMarkIcon('')}
        </div>
        <div className="flex input-date" data-testid={'start-date-input'}>
          <InputText id="fromDate" maxLength="2" keyfilter="num" />
          <InputText id="fromMonth" maxLength="2" keyfilter="num" />
          <InputText id="fromYear" maxLength="4" keyfilter="num" />
        </div>
        <span className="example-date">eg. 06 / 12 / 2022</span>
      </div>
      <div className="col-6 to-date">
        <div className='flex expiry-label-date'>
          <span>Expiry date</span>
          {questionMarkIcon('')}
        </div>
        <div className="flex input-date" data-testid={'expiry-date-input'}>
          <InputText id="toDate" maxLength="2" keyfilter="num" />
          <InputText id="toMonth" maxLength="2" keyfilter="num" />
          <InputText id="toYear" maxLength="4" keyfilter="num" />
        </div>
        <span className="example-date">eg. 06 / 12 / 2022</span>
      </div>
    </div>
    <div className="field-upload">
      <div className={'upload-asset-img-container image-upload'} data-testid={'certificate-upload'}>
        <FileUpload
          className={'asset-img-uploader'}
          name="asetImgFile"
          url=""
          multiple
          accept="image/*"
          maxFileSize={1000000}
          emptyTemplate={getEmptyTemplate}
          // itemTemplate={getItemTemplate}
          chooseOptions={chooseOptions}
          uploadOptions={uploadOptions}
          headerTemplate={getHeaderTemplate}
          onSelect={onSelectHandler}
          onRemove={onRemoveHandler}
        />
      </div>
      {questionMarkIcon('icon-question-mark')}
    </div>
    <div className={'input-text-area asset-epc-notes'} data-testid={'notes-input'}>
      <InputTextarea autoResize="none" placeholder="Notes..."/>
      {questionMarkIcon('icon-question-mark')}
    </div>
  </>

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {formik => (
        <>
          <div className={'asset-form'}>
            <form onSubmit={formik.handleSubmit}>
              <div className={'epc-form-table grid'}>
                <div className={'l-column'}>
                  {columnEpc(formik)}
                </div>
              </div>
            </form>
          </div>
          <div className={'footer-divider'}>
            <hr/>
          </div>
          <div className={'footer-container'}>
            <AssetFooter
              submitHandler={formik.handleSubmit}
              handleNextTabBtnText={'Users & Groups'}
              handleNextTab={handleTabChange}
            />
          </div>
        </>
      )}
    </Formik>
  )
}

export default AssetEpcForm