import {useState, useEffect} from 'react'
import I18n from '../../../utils/i18n/I18n'
import downArrowRedIcon from '../../../resources/images/chart/down-arrow-red.svg'
import downArrowGreenIcon from '../../../resources/images/chart/down-arrow-green.svg'
import upArrowGreenIcon from '../../../resources/images/chart/up-arrow-green.svg'
import upArrowRedIcon from '../../../resources/images/chart/up-arrow-red.svg'
import {formatNumWOneDecimal} from '../../../services/common/formatter'
import { UTILITY_LABELS_OBJECT_TITLE_CASE } from '../../../utils/helpers/Constants'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const FeedbackRating = (props) => {
  const [variance, setVariance] = useState()
  const [utility, setUtility] = useState()
  const [isTarget, setIsTarget] = useState(false)
  const [displayIcon, setDisplayIcon] = useState(true)
  const { loginState: { userInfo } } = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference

  useEffect(() => {
    if(props) {
      if(props.variance !== undefined) {
        setVariance(typeof props.variance === 'number' ? props.variance * 100 : null)
      }

      if(props.utility !== undefined) {
        setUtility(props.utility)
      }

      if(props.target !== undefined) {
        setIsTarget(props.target)
      }

      setDisplayIcon(typeof props.variance === 'number' && props.displayIcon !== undefined ? props.displayIcon : false)

    }
  }, [props.variance, props.utility, props.target])


  const getCssColorClass = () => {
    let className
    if(variance !== undefined) {
      if (utility.toLowerCase() !== UTILITY_LABELS_OBJECT_TITLE_CASE.RECYCLED_WASTE.toLowerCase() && utility.toLowerCase() !== UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE.toLowerCase()) {
        className = variance > 0 ? 'red' : (variance < 0 ? 'green' : 'black')
      } else {
        className = variance > 0 ? 'green' : (variance < 0 ? 'red' : 'black')
      }
    }

    if(variance === 0) {
      className = 'black'
    }

    return className
  }

  const getUtilityConsumptionText = () => {
    let description = ''
    if(typeof (variance) === 'number' && variance !== 0){
      if(isTarget) {
        description = variance > 0 ? I18n('t_above_target') : I18n('t_below_target')
      } else {
        description = variance > 0 ? I18n('t_rise') : I18n('t_fall')
      }
    }
  
    return description
  }

  const getPerformanceRatingIcon = () => {
    let icon
    if (utility !== UTILITY_LABELS_OBJECT_TITLE_CASE.RECYCLED_WASTE) {
      icon = variance > 0 ? upArrowRedIcon : downArrowGreenIcon
    } else {
      icon = variance > 0 ? upArrowGreenIcon : downArrowRedIcon
    }

    return icon
  }

  return (
    <>
      <div className={'utility-consumption-rating'} data-testid={'utility-consumption-rating'}>
        {variance === 0 || !displayIcon ? 
          null :
          <img src={getPerformanceRatingIcon()} className={'perf-icon'} alt={'feedback-icon'} />
        }
        <span className={`rating-text ${getCssColorClass()}`}>
          {typeof (variance) === 'number' ? `${formatNumWOneDecimal(selectedLanguage, Math.abs(variance))}% ` : '-'}{getUtilityConsumptionText()}
        </span>
      </div>
    </>
  )
}

export default FeedbackRating