import {getInitialPickerValue, getInitialTimePeriod} from '../../../../../services/common/datepicker-service'
import {formatNumWTwoDecimal, getMinOrRoundedValue, roundUnitValue, roundVarianceWithOneDP, trucateNumWODecimal, trucateNumWOneDecimal, trucateNumWTwoDecimal, VARIANCE_MIN_VALUE_THRESHOLD_0_09} from '../../../../../services/common/formatter'
import PaginatorCurrentPageReport from '../portfolio-components/PaginatorCurrentPageReport'
import {convertToBTUUnits, convertToCO2Units, convertToEnergyUnits, formatPercentage, getLocalizedDateMessage, getLocalizedValue, getLocalStorageItem, getUnitLabel, getUnitName, setAssetData} from '../../../../../utils/helpers/Helper'
import {CARBON, ELECTRICITY, FUND_VIEWS_PAGES, GAS_FUELS, WASTE} from '../../../../../utils/helpers/Constants'
import {Icon, Text, Tooltip, Tag} from '../../../../atomic'
import TooltipTwoColumnsLayout from '../../../../atomic/Tooltip/TooltipContent/TooltipTwoColumnsLayout'
import EmptyCellIcon from '../../../../../resources/images/icon/EmptyCellIcon.svg'
import DataQualityContainerSmallV3 from '../../../../common/data-quality-container/DataQualityContainerSmallV3'
import IconAndContentCell from '../portfolio-components/IconAndContentCell'
import {UNITS} from '../../../../../utils/i18n/constants'
import DataQualityCoverageContainerV3 from 'components/common/data-quality-container/DataQualityCoverageContainerV3'

export const MIN_VALUE_INDICATOR = '< 0.1%'
export const LABEL_ALL = 'All'
export function getDqTooltipItems(selectedLanguage) {
  return [
    {label: getLocalizedValue(selectedLanguage, 't_actual_data_legend'), labelIconClass: 'legend-actual'},
    {label: getLocalizedValue(selectedLanguage, 't_supplier_estimate'), labelIconClass: 'legend-estimated'},
    {label: getLocalizedValue(selectedLanguage, 't_calculated_estimate'), labelIconClass: 'legend-not-covered'},
    {label: getLocalizedValue(selectedLanguage, 't_missing_tc'), labelIconClass: 'legend-incomplete'},  ]
}

export function getDqCoverageTooltipItems(item, unitSystem, selectedLanguage) {
  return [
    {label: `${getMinOrRoundedValue(item.value)?.toLocaleString(selectedLanguage)} ${getUnitLabel(unitSystem)} ${getLocalizedValue(selectedLanguage, 't_coverage_by_floor_area')}`, labelIconClass: 'legend-coverage'},
    {label: `${getMinOrRoundedValue(item.value)?.toLocaleString(selectedLanguage)} ${getUnitLabel(unitSystem)} ${getLocalizedValue(selectedLanguage, 't_floor_area_unaccounted_for')}`, labelIconClass: 'legend-unaccounted'},
  ]
}

export const setInitialPortfolioState = (queryValues) => {
  const timePeriod = getInitialTimePeriod(queryValues)
  const pickerValue = getInitialPickerValue(queryValues)

  let filterOrders = queryValues.get('filterOrder') === null ? '0000' : queryValues.get('filterOrder')
  const [fundOrder, locationOrder, typeOrder, esScoreOrder]=[...filterOrders].map(Number)

  return {
    first: 0,
    page: 0,
    rows: 10,
    timePeriod,
    pickerValue,
    sortOrderText: 'desc',
    isOverlayOpen: false,
    filterStateV3: {
      searchTerm: queryValues.get('asset') === null ? '' : queryValues.get('asset'),
      sortByField: 'Electricity',
      sortByFieldOrder: 1,
      sortByParameter: 'Electricity',
      sortEnableIcon: false,
      filterFundSelected: queryValues.get('fund') === null ? LABEL_ALL : queryValues.get('fund'),
      filterLocationSelected: queryValues.get('location') === null ? LABEL_ALL : queryValues.get('location'),
      filterSectorSelected: queryValues.get('type') === null ? LABEL_ALL : queryValues.get('type'),
      filterEsScoreSelected: queryValues.get('esScoreRange') === null ? LABEL_ALL : queryValues.get('esScoreRange'),
      fundOrder,
      locationOrder,
      typeOrder,
      esScoreOrder
    },
  }
}

export function getPortfolioPaginationParams(filterState, pageSize, skip, sortOrder) {

  const {
    searchTerm,
    sortByField,
    filterFundSelected,
    filterLocationSelected,
    filterSectorSelected,
    filterEsScoreSelected,
  } = filterState

  return {
    sortField: sortByField,
    sortOrder,
    searchTerm,
    fund: filterFundSelected === LABEL_ALL ? '' : filterFundSelected,
    location: filterLocationSelected === LABEL_ALL ? '' : filterLocationSelected,
    sector: filterSectorSelected === LABEL_ALL ? '' : filterSectorSelected,
    ranges: filterEsScoreSelected === LABEL_ALL ? '' : filterEsScoreSelected,
    pageSize,
    skip
  }
}

export function getColumnProps(rowData, uName, selectedLanguage) {
  let utP = '-'
  let utV = ''
  const utilityData = rowData.utilitySummaries.filter(x => x.type === uName)
  let utilPerf = !utilityData[0] ? null : utilityData[0].variancePercentage
  let utilValue = !utilityData[0] ? null : utilityData[0].varianceAbsolute
  let utilPerfNoDelimiter = utilPerf
  let isPositive = utilValue >= 0
  if (utilPerf !== null) {
    utilPerf = trucateNumWOneDecimal(utilPerf)
    utilValue = uName === 'Water' || uName === 'Carbon' ? trucateNumWOneDecimal(utilValue) : (uName === 'Waste' ? trucateNumWTwoDecimal(utilValue) : trucateNumWODecimal(utilValue))
    utilPerf = formatNumWTwoDecimal(selectedLanguage, utilPerf)
    utilValue = formatNumWTwoDecimal(selectedLanguage, utilValue)
    if (isPositive) {
      utilPerf = '+' + utilPerf
      utilValue = '+' + utilValue
    }
    utP = utilPerf + '%'
    utV = utilValue
  }
  const ulPer = Math.trunc(utilPerfNoDelimiter)
  let colorText = 'grey-text'
  if (uName !== 'Waste') {
    colorText = ulPer > 5 ? 'red-text' : (ulPer < -5 ? 'green-text' : colorText)
  } else {
    colorText = ulPer > 5 ? 'green-text' : (ulPer < -5 ? 'red-text' : colorText)
  }
  return {utP, utV, utilityData, colorText}
}

export function getPortfolioPaginatorTemplate() {
  return {
    layout: 'PrevPageLink CurrentPageReport NextPageLink',
    'CurrentPageReport': (options) => <PaginatorCurrentPageReport options={options} />
  }
}

export const storeAssetData = (e) => {
  setAssetData(e.data.assetAddress)
}

export function getActionTypeTitle(title) {
  switch (title) {
  case 'agreed':
  case 'applicable':
  case 'completed':
  case 'overdue':
    return title.charAt(0).toUpperCase() + title.slice(1)
  case 'underReview':
    return 'Under review'
  default:
    return ''
  }
}

function getActionItemData(actionStatusCount, selectedLanguage) {

  const actionsLabelMap = {
    agreed: getLocalizedValue(selectedLanguage, 't_agreed'),
    applicable: getLocalizedValue(selectedLanguage, 't_applicable'),
    underReview: getLocalizedValue(selectedLanguage, 't_under_review'),
    overdue: getLocalizedValue(selectedLanguage, 't_overdue'),
    completed: getLocalizedValue(selectedLanguage, 't_completed'),
  }
  const actionItems2 = []
  Object.keys(actionStatusCount).forEach(objKey => {
    const actionCount = actionStatusCount[objKey]
    let colour = 'forest'
    if (actionCount > 0) {
      if ('overdue' === objKey) {
        colour = 'red'
      } else if ('completed' === objKey) {
        colour = 'green'
      }
      actionItems2.push({value: actionCount, colour,  label: actionsLabelMap[objKey], key: objKey})
    }
  })
  return actionItems2
}

function getActionItems(actionItems) {
  return actionItems?.map(({value, colour, label, key}, index)=>{
    const contentSeparator= (actionItems.length > 1 && (index+1) !== actionItems.length) ? ',' : ''
    const actionItemContent=`${value} ${label}`
    return  <span key={key}><Text content={actionItemContent} colour={colour} />{contentSeparator} </span>
  })
}

export const getActionsTemplate = (rowData, colData, selectedLanguage, gotoPage) => {

  const actionStatusCount = rowData?.actionStatusCount
  const targetClassName = `action-item-${colData.rowIndex}`
  const actionItems = getActionItemData(actionStatusCount, selectedLanguage)
  const actionLabels = ['overdue', 'agreed', 'applicable', 'underReview', 'completed']
  actionItems?.sort((a, b) => actionLabels.indexOf(a.key) - actionLabels.indexOf(b.key))
  
  return (
    <div className={`actions-data ${targetClassName}`} onClick={() => gotoPage(rowData, colData, FUND_VIEWS_PAGES.actionPlan)} aria-hidden={true} onKeyDown={()=>{}}>{actionItems.length > 0 ? getActionItems(actionItems): '-'}</div>
  )
}

export const floorAreaTemplate = (rowData, unitSystem, selectedLanguage) => {

  return (
    <div className="floor-area-data">
      <Text content={`${getMinOrRoundedValue(rowData.gia)?.toLocaleString(selectedLanguage, {maximumFractionDigits: 0})} ${getUnitLabel(unitSystem)}`} />
    </div>
  )
}

export const occupancyTemplate = (rowData) => {
  return (
    <div className="occupancy-data">
      <Text content={`${rowData.occupancy}%`} />
    </div>
  )
}

export const energyRatingTemplate = (rowData, isEsScoreDropdownVisible) => {
  const energyRatingData = isEsScoreDropdownVisible ? rowData.energyStarScore : (rowData.epcRating ? `${rowData.epcRating} - ${rowData.epcScheme}` : null)
  return (
    <div className="energy-rating-data">
      <Text content={energyRatingData ? energyRatingData : getNoDataElement()}/>
    </div>
  )
}
export const getNoDataElement = (name, assetId) => {
  return (
    <div className={'empty-cell cursor tooltip-grid-empty-' + name + '-' + assetId}>
      <div className={'no-data-cell'}><img src={EmptyCellIcon} alt='EmptyCellIcon'/></div>
    </div>
  )
}

export function formatDate(date, selectedLanguage) {
  const dateOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  }
  const formattedDate = date.toLocaleDateString(selectedLanguage, dateOptions)
  return date.getMonth() === 8 ? formattedDate.replace(/Sept/, 'Sep') : formattedDate
}

export const assetCategoryTemplate = (rowData, selectedLanguage) => {
  let assetSoldInfo = rowData.dateOfSale ? `${getLocalizedValue(selectedLanguage, 't_nzc_sold')} ${getLocalizedDateMessage(rowData.dateOfSale, 'MONTH_YEAR', selectedLanguage)}` : null
  return <div className='flex gap-1'>
    {assetSoldInfo ? <Tag colour='sand' contentKey={assetSoldInfo}/> : null}
    {rowData.isJointVenture ? <Tag colour='nature' contentKey='JV'/> : null}
  </div>
}

export function getPfDqTooltipItems(dqData, selectedLanguage) {
  const dqTooltipItems = []
  dqData?.forEach((bar, index) => {
    if (bar.value > 0) {
      dqTooltipItems.push({
        label: getDqTooltipItems(selectedLanguage)[index].label,
        labelIcon: <div className={`legend-item-square ${getDqTooltipItems(selectedLanguage)[index].labelIconClass}`}></div>,
        value: formatPercentage(dqData[index]?.value) + '%',
      })
    }
  })
  return dqTooltipItems
}

const getDqCoveragePercentage = (dqCoverageData, dqCoverageItem) => {
  const coverageValue = Number(formatPercentage(dqCoverageData.find(item => item.name === 'dq-coverage-percentage')?.value))

  if (dqCoverageItem.name === 'dq-unaccounted') {
    return 100 - coverageValue
  }
  return coverageValue
}

export function getPfDqCoverageTooltipItems(dqData, unitSystem, selectedLanguage) {
  const dqTooltipItems = []
  dqData?.forEach((item, index) => {
    if (item.value > 0 && item.name !== 'dq-coverage-percentage') {
      dqTooltipItems.push({
        label: getDqCoverageTooltipItems(item, unitSystem, selectedLanguage)[index].label,
        labelIcon: <div className={`legend-item-square ${getDqCoverageTooltipItems(item, unitSystem, selectedLanguage)[index].labelIconClass}`}></div>,
        value: trucateNumWTwoDecimal(getDqCoveragePercentage(dqData, dqData[index])) + '%',
      })
    }
  })
  return dqTooltipItems
}

export const dataCompletenessChartTemplate = (rowData, colData, gotoPage) => {
  return (
    <DataQualityContainerSmallV3
      dqChartData={rowData?.dataQualityCompleteness}
      noDataElement={getNoDataElement('dataQualityCompleteness', rowData?.assetId)}
      onClick={() => gotoPage(rowData, colData, FUND_VIEWS_PAGES.dataQuality)}
      columnName={colData.field}
      containerClass='data-quality-charts-v3'
      stackedBarClass='dq-bar-v3'
      tooltipClass={`${colData.field?.toLowerCase()}-dq-chart-${colData.rowIndex+1}`}
      getDqTooltipItems={getPfDqTooltipItems}
    />
  )
}

export const dataCoverageChartTemplate = (rowData, colData, gotoPage) => {
  return (
    <DataQualityCoverageContainerV3
      dqChartData={rowData?.dataQualityCoverage}
      noDataElement={getNoDataElement('dataQualityCoverage', rowData?.assetId)}
      onClick={() => gotoPage(rowData, colData, FUND_VIEWS_PAGES.dataQuality)}
      containerClass='data-quality-charts-v3'
      tooltipClass={`${colData.field?.toLowerCase()}-dq-chart-${colData.rowIndex+1}`}
      getDqTooltipItems={getPfDqCoverageTooltipItems}
    />
  )
}

export function toggleTooltip(e) {
  return e?.target?.clientWidth < e?.target?.scrollWidth
}

export function getVarianceTextColor(variance, utility) {
  let colorText = 'teal'
  if (utility !== 'Waste') {
    if (variance > 5) {
      colorText = 'red'
    } else if (variance < -5) {
      colorText = 'green'
    }
  } else if (variance > 5) {
    colorText = 'green'
  } else if (variance < -5) {
    colorText = 'red'
  }
  return colorText
}

export function getVarianceArrowIcon(variance) {
  let arrowIconId
  if( variance > 5){
    arrowIconId='arrow_ascending'
  } else if(variance < -5){
    arrowIconId='arrow_descending'
  }
  return arrowIconId
}

function getTooltipFooter(daysMissingPercentage, selectedLanguage) {
  if(!daysMissingPercentage){
    return null
  }
  const footerContent=`${Math.round(daysMissingPercentage)}${getLocalizedValue(selectedLanguage, 't_percentage_missing')}`

  return (
    <div className='d-flex'>
      <Icon className='mr-2' id='warning_solid_circle' size={17} colour='red' />
      <Text content={footerContent} />
    </div>
  )
}

function getVariancePercentageAndAbsolute(thisPeriod, previousEstimated, hasTarget, targetPercentage) {
  let tartOrPreviousPeriodValue
  let variancePercentage
  let varianceAbsolute
  if (hasTarget) {
    tartOrPreviousPeriodValue = previousEstimated === 0 ? 0 : (previousEstimated - (targetPercentage) / 100 * previousEstimated)
  } else {
    tartOrPreviousPeriodValue = previousEstimated
  }
  variancePercentage = tartOrPreviousPeriodValue === 0 ? 0 : (thisPeriod - tartOrPreviousPeriodValue) / tartOrPreviousPeriodValue
  varianceAbsolute = thisPeriod - tartOrPreviousPeriodValue

  return {variancePercentage, varianceAbsolute}
}

function roundAndFormatUnitValue(unitValue, utilityType, selectedLanguage, unitName) {
  const varianceAbsoluteRounded=roundUnitValue(unitValue, utilityType, unitName)?.toLocaleString(selectedLanguage, {maximumFractionDigits: 0})
  return `${varianceAbsoluteRounded} ${unitName}`
}

function getTooltipItems(colUtility, floorAreaMeasurement, utilityType, selectedLanguage) {

  let {previousAbsolute, previousFilledData, estimated, currentAbsolute, filledData, hasTarget, targetPercentage}=colUtility
  filledData= filledData ?? 0

  const thisPeriod=currentAbsolute+filledData
  const previousEstimated=previousAbsolute+previousFilledData
  const filledDataPercentage= !filledData? 0: (filledData*100)/estimated
  let tartOrPreviousPeriodValue=hasTarget ? previousEstimated-(targetPercentage)/100*previousEstimated : previousEstimated

  // Unit Conversion
  const {value: thisPeriodConverted, name: thisPeriodUnitName}=convertUnitValueForUtility(thisPeriod, utilityType, floorAreaMeasurement)
  const {value: filledDataConverted, name: filledDataUnitName}=convertUnitValueForUtility(filledData, utilityType, floorAreaMeasurement)
  const {value: tartOrPreviousPeriodValueConverted, name: tartOrPreviousPeriodValueUnitName}=convertUnitValueForUtility(tartOrPreviousPeriodValue, utilityType, floorAreaMeasurement)

  const filledDataPercentageAbsolute = Math.abs(filledDataPercentage)
  const variancePercentageContent = filledDataPercentageAbsolute !== 0 && filledDataPercentageAbsolute < VARIANCE_MIN_VALUE_THRESHOLD_0_09 ? MIN_VALUE_INDICATOR : `${roundVarianceWithOneDP(filledDataPercentage)}`
  const gapFilledValueRoundedAndFormatted = roundAndFormatUnitValue(filledDataConverted, utilityType, selectedLanguage, filledDataUnitName)

  const tooltipItems=[
    {
      label: getLocalizedValue(selectedLanguage, hasTarget?'t_target': 't_previous_period'),
      value: roundAndFormatUnitValue(tartOrPreviousPeriodValueConverted, utilityType, selectedLanguage, tartOrPreviousPeriodValueUnitName)
    },
    {
      label: getLocalizedValue(selectedLanguage, 't_this_period'),
      value: roundAndFormatUnitValue(thisPeriodConverted, utilityType, selectedLanguage, thisPeriodUnitName)
    }
  ]

  if(utilityType !== WASTE){
    const {value: currentAbsoluteConverted, name: currentAbsoluteUnitName}=convertUnitValueForUtility(currentAbsolute, utilityType, floorAreaMeasurement)

    tooltipItems.push( {
      label: getLocalizedValue(selectedLanguage, 't_actual'),
      value: roundAndFormatUnitValue(currentAbsoluteConverted, utilityType, selectedLanguage, currentAbsoluteUnitName)

    },
    {
      label: getLocalizedValue(selectedLanguage, 't_gap_filled_split'),
      value: `(${variancePercentageContent}%) ${gapFilledValueRoundedAndFormatted}`
    })
  }

  return  tooltipItems
}

function getVarianceIconAndTitleKey(hasTarget) {
  let varianceTypeIconId
  let titleLeftKey

  if (hasTarget) {
    varianceTypeIconId = 'target'
    titleLeftKey = 't_target_variance'
  } else {
    varianceTypeIconId = 'calendar'
    titleLeftKey = 't_period_variance'
  }
  return {varianceTypeIconId, titleLeftKey}
}

export function getUtilBodyClasses({field, rowIndex}, otherClasses, isColumnSelected) {
  return `${field}-${rowIndex+1} ${otherClasses} ${isColumnSelected ? 'selected-column' : ''} cursor-pointer`
}

function convertUnitValueForUtility(value, utilityType, unitSystem) {
  const unitValueAbsolute = Math.abs(value)
  let unitData

  switch (utilityType) {
  case ELECTRICITY:
  case GAS_FUELS:
    unitData = unitSystem === UNITS.METRIC ? convertToEnergyUnits(unitValueAbsolute) : convertToBTUUnits(unitValueAbsolute)
    break
  case CARBON:
    unitData = convertToCO2Units(unitValueAbsolute)
    break
  default:
    unitData = {value, name: getUnitName(utilityType, unitSystem)}
  }
  unitData.value = value < 0 ? -unitData.value : unitData.value

  return unitData
}

export function getContentCellNode({utilitySummaries, floorAreaMeasurement}, {field, rowIndex}, utilityType, selectedLanguage) {

  const colUtility=utilitySummaries?.find(utilitySummary=>utilitySummary.type===utilityType)
  if(!colUtility){
    return '-'
  }

  const {previousAbsolute, previousFilledData, currentAbsolute, filledData, hasTarget, targetPercentage}=colUtility
  const tooltipTargetClass=`${field}-${rowIndex+1}`
  const {varianceTypeIconId, titleLeftKey} = getVarianceIconAndTitleKey(hasTarget)
  let thisPeriod
  let previousEstimated

  if (utilityType === WASTE) {
    thisPeriod = currentAbsolute
    previousEstimated = previousAbsolute
  } else {
    thisPeriod = currentAbsolute + filledData
    previousEstimated = previousAbsolute + (previousFilledData ?? 0)
  }
  thisPeriod = thisPeriod ?? 0
  previousEstimated = previousEstimated ?? 0

  const {variancePercentage, varianceAbsolute}=getVariancePercentageAndAbsolute(thisPeriod, previousEstimated, hasTarget, targetPercentage)
  const variancePercentageRounded=roundVarianceWithOneDP(variancePercentage*100)

  // Unit Conversion
  const {value: varianceAbsoluteConverted, name: unitName}=convertUnitValueForUtility(varianceAbsolute, utilityType, floorAreaMeasurement)

  // Number rounding and formatting
  const varianceAbsoluteRounded=roundUnitValue(varianceAbsoluteConverted, utilityType, unitName)?.toLocaleString(selectedLanguage, {maximumFractionDigits: 0})

  const varianceTextColor = getVarianceTextColor(variancePercentage * 100, utilityType)
  const varianceArrowIconId = getVarianceArrowIcon(variancePercentage * 100)

  const variancePercentageSign=(variancePercentage > 0) ? '+' : ''
  const varianceAbsoluteSign=(varianceAbsolute > 0 && !isNaN(Number(roundUnitValue(varianceAbsolute, utilityType, unitName)))) ? '+' : ''

  const varianceAbs=Math.abs(variancePercentage*100)
  const variancePercentageContent= varianceAbs!== 0 && varianceAbs < VARIANCE_MIN_VALUE_THRESHOLD_0_09 ? MIN_VALUE_INDICATOR : `${variancePercentageSign}${variancePercentageRounded}%`
  const varianceAbsoluteContent=`(${varianceAbsoluteSign}${varianceAbsoluteRounded} ${unitName})`

  let titleRightContent=varianceAbs!== 0 && varianceAbs < VARIANCE_MIN_VALUE_THRESHOLD_0_09 ? MIN_VALUE_INDICATOR : `${Math.abs(variancePercentageRounded)}%`
  const titleRightAbsolute=`${varianceAbsoluteSign}${varianceAbsoluteRounded} ${unitName}`
  titleRightContent=`(${titleRightContent}) ${titleRightAbsolute}`

  return(
    <>
      <IconAndContentCell {...{iconId: varianceTypeIconId, variancePercentageContent, varianceAbsoluteContent, varianceTextColor}} />
      <Tooltip target={`.${tooltipTargetClass}`} padding="large" showDelay={100}>
        <TooltipTwoColumnsLayout
          titleLeft={getLocalizedValue(selectedLanguage, titleLeftKey)}
          titleLeftIcon={<Icon id={varianceTypeIconId} size={17}/>}
          titleRight={titleRightContent}
          titleRightIcon={varianceArrowIconId ? <Icon id={varianceArrowIconId} size={17} colour={varianceTextColor} /> : ''}
          titleRightColour={varianceTextColor}
          items={getTooltipItems(colUtility, floorAreaMeasurement, utilityType, selectedLanguage)}
          footer={utilityType !== WASTE? getTooltipFooter(colUtility.daysMissingPercentage, selectedLanguage) : ''}
        />
      </Tooltip>
    </>
  )
}

export function hasUserAssetInUSOrCanada() {
  return !!(getLocalStorageItem('validateLocationUS')?.hasUserAssetInUSOrCanada)
}

export function getCurrentOrderValue(selectedFilters) {
  let currentOrder=0
  selectedFilters.forEach(selectedFilter=>{
    if(selectedFilter !== LABEL_ALL){
      currentOrder +=1
    }
  })
  return currentOrder
}

export function getUpdatedOrder(existingFilter, existingOrder, selectedFilters, otherOrdersTotalValue) {
  let newOrder = 0
  let totalSelectedFilters = 0
  selectedFilters.forEach(selectedFilter => {
    if (selectedFilter !== LABEL_ALL) {
      totalSelectedFilters += 1
    }
  })

  if((existingFilter !== LABEL_ALL) && existingOrder > totalSelectedFilters || (existingOrder===totalSelectedFilters && otherOrdersTotalValue > (totalSelectedFilters+1))) {
    newOrder = existingOrder - 1
  } else {
    newOrder = existingOrder
  }

  return newOrder
}