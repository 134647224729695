import React from 'react'
import I18n from '../../../../utils/i18n/I18n'
import './AuthCardFooter.scss'

export const AuthCardFooter = (isError) => {
  if(!isError) {
    isError = false
  }
  return (
    <div className="auth-footer">
      <span className="xxx-small-text footer-text">
        {isError ? <>{I18n('t_contact_problem_msg_2')} </> : <>{I18n('t_contact_problem_msg_1')} </>}
        <a href="mailto:support@sieraglobal.com"><span>{I18n('t_contact_evora')}</span></a>
      </span>
    </div>
  )
}