import React from 'react'
import I18n from '../../../utils/i18n/I18n'

const Privacy = (props) => {

  return (
    <div className="grey-bg min-width-1280">
      <div className="display gutter">
        <div className="container-box">
          {I18n('t_privacy_page',
            {
              h1: msg => (<h1>{msg}</h1>),
              h3: msg => (<h3>{msg}</h3>),
              p: msg => (<p>{msg}</p>),
              b: msg => (<b>{msg}</b>),
              ul: msg => (<ul>{msg}</ul>),
              li: msg => (<li>{msg}</li>),
              strong: msg => (<strong>{msg}</strong>),
              emailevora: msg => (
                <a href="mailto:dataprivacy@evoraglobal.com">
                  {msg}
                </a>
              ),
              europeancommission: msg => (
                <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en" target="_blank" rel="noreferrer noopener">
                  {msg}
                </a>
              ),
              europeancommissionstandard: msg => (
                <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en" target="_blank" rel="noreferrer noopener">
                  {msg}
                </a>
              ),
              guidanceuk: msg => (
                <a href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/" target="_blank" rel="noreferrer noopener">
                  {msg}
                </a>
              ),
              icoorg: msg => (
                <a href="https://ico.org.uk/concerns/" target="_blank" rel="noreferrer noopener">
                  {msg}
                </a>
              )
            }
          )}
        </div>
      </div>
    </div>

  )
}

export default Privacy