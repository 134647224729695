import { ConfirmDialog as PrimeConfirmDialog } from 'primereact/confirmdialog'
import PropTypes from 'prop-types'
import './ConfirmDialog.scss'

const ConfirmDialog = (props) => {
  const {visible, onHide, message, header, icon, acceptLabel, rejectLabel, className, onAccept, onReject, acceptClassName, rejectClassName} = props
  return (
    <div>
      <PrimeConfirmDialog
        visible={visible}
        onHide={onHide}
        message={message}
        header={header}
        icon={icon}
        acceptLabel={acceptLabel}
        rejectLabel={rejectLabel}
        className={`custom-confirm-dialog ${className}`}
        accept={onAccept}
        reject={onReject}
        acceptClassName={`custom-accept-className ${acceptClassName}`}
        rejectClassName={`custom-reject-className ${rejectClassName}`}
        resizable={false}
      />
    </div>
  )
}

ConfirmDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  onHide: PropTypes.func,
  message: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  icon: PropTypes.string,
  acceptLabel: PropTypes.string.isRequired,
  rejectLabel: PropTypes.string.isRequired,
  acceptClassName: PropTypes.string,
  rejectClassName: PropTypes.string,
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
  className: PropTypes.string,
}

ConfirmDialog.displayName = 'ConfirmDialog'

export default ConfirmDialog
