import { useFormikContext } from 'formik'
import { useQuery } from 'react-query'
import { noRefetchOnWindowFocus } from '../../../../services/common/useQuery-config'
import TextfieldFormik from '../../../common/formik-elements/text-field/TextfieldFormik'
import DropdownFormik from '../../../common/formik-elements/dropdown/DropdownFormik'
import { CURRENCY_UNITS, CURRENT_STATUS_DROPDOWN, DATE_FORMAT, DROPDOWN_TYPE, GRESB_SECTOR, UNIT_ARRAY, MeasurementUnits } from '../../../../utils/helpers/Constants'
import './AddEditAssetForm.scss'
import CalendarFormik from '../../../common/formik-elements/date-picker/CalendarFormik'
import TextfieldDropdownFormik from '../../../common/formik-elements/input-group/TextfieldDropdownFormik'
import { getLocalizedValue } from '../../../../utils/helpers/LanguageUtils'
import { calendarDateFormat } from '../../../../utils/helpers/DateUtils'
import PropTypes from 'prop-types'
import useCustomState from '../../../common/custom-hooks/useCustomState'
import { useDebounce } from 'use-debounce'
import {useEffect, useMemo, useCallback, useState} from 'react'
import { getAddAssetFormDetails } from '../../../../services/assets/asset-details-service'
import { procuredByList } from 'components/common/action-plan/actionPlanTableTemplate'
import CheckboxFormik from 'components/common/formik-elements/checkbox/checkboxFormik'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'
import { Text } from 'components/atomic/index'
import { useParams } from 'react-router-dom'
import addEditQueryData from './addEditQueryData'

export function hasAllValuesInArray(array, valuesToCheck) {
  const dataArray = array.map(item => item.code)
  return valuesToCheck.every(value => dataArray.includes(value))
}

const AddEditAssetForm = ({ isAssets, fundName, buildingUtilitesPresentDropdownValues, assetDetailsFields, updateCurrentAssetState }) => { // do not remove fundName
  const { loginState: { userInfo } } = useLoginAuthContext()
  const { assetId } = useParams()
  const selectedLanguage = userInfo.languagePreference
  const { values, handleChange, errors, touched, setValues, setFieldError, setFieldTouched, setTouched } = useFormikContext()
  const fundDropDownStatus = isAssets === undefined || isAssets === null
  const {customState, updateCustomState: setRegionFilterData} = useCustomState({regionFilterData: []})
  const [debouncedassetName] = useDebounce(values?.assetName, 500)
  const [debouncedassetReference] = useDebounce(values?.assetReference, 500)
  const assetFormData  = useQuery('getAssetFormDetails', getAddAssetFormDetails,{...noRefetchOnWindowFocus, enabled: true} )
  const getCountry = useCallback(() => {
    const currentCountry = assetFormData?.data?.assetCountryList?.find(country => Object.values(country)[0] === values.country)
    if(currentCountry && isNaN(values.country)) {
      setValues({ ...values, country: Object.keys(currentCountry)[0], countryLabel: values.country })
    }
    return currentCountry
  }, [assetFormData])

  const countryValue = getCountry()
  const {regionFilterData: {refetch: refetchAssetLocationFilters}, assetNameData: {refetch: refetchAssetNameDetails}, assetRefData: {refetch: refetchAssetReferenceDetails} } = addEditQueryData({
    regionFilterVariables:['getAssetLocationFilterDetails', Number(values.country) ? values.country : countryValue && Object.keys(countryValue)[0],values?.region],
    assetNameVariable: ['assetNameDetails', debouncedassetName],
    assetRefVariable: ['assetReferenceDetails', debouncedassetReference]
  })
  const CURRENCY = [
    {label: 'GBP', value: 'GBP', key: CURRENCY_UNITS.BRITISHPOUND},
    {label: 'EUR', value: 'EUR', key: CURRENCY_UNITS.EUROS},
    {label: 'USD', value: 'USD', key: CURRENCY_UNITS.USDOLLAR}
  ]
  const [unit, setUnit] = useState({ gia: 'M2', nla: 'M2' })
  const cityFilterData = []

  const getFundValueForAdding = () => {
    const entry = assetFormData?.data?.userAccessibleFunds?.find(obj => Object.values(obj)[0] === fundName)
    const key = entry ? Object.keys(entry)[0] : null
    return  key ? [{value: key, label: String(entry[key])}] : []
  }

  const onCheckboxClick = (e) => {
    setValues({ ...values, estimatedCpa: e.target.checked })
  }

  function getSectorDropDownValues(){
    return Array.isArray(assetFormData?.data?.sectorList) ?
      assetFormData?.data?.sectorList
        .map(sector => ({
          value: Object.keys(sector)[0],
          label: Object.values(sector)[0],
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
      : []
  }

  function getSectorLabel(sectorValue) {
    const sectorList = getSectorDropDownValues()
    const sector = sectorList.find(item => Number(item.value) === Number(sectorValue))
    return sector ? sector.label : null
  }

  const sectorList = getSectorDropDownValues()
  useEffect(() => {
    if(values?.country){
      if (isNaN(values.country)) {
        getCountry()
      }
      refetchAssetLocationFilters().then(x => setRegionFilterData({regionFilterData: getlocationDropDownValues(x?.data?.assetRegionList)}))
    }
  }, [values?.country, assetFormData?.data?.assetCountryList])

  const sectorMap = useMemo(() => assetFormData?.data?.sectorList?.reduce((acc, curr) => {
    const [key, value] = Object.entries(curr)[0]
    acc[key] = value
    return acc
  }, {}), [assetFormData?.data])

  useEffect(() => {
    if (!isAssets && assetFormData?.data && !values?.fund && getFundValueForAdding().length > 0) {
      setValues({ ...values, fund: getFundValueForAdding()[0]?.value})
    }
  }, [assetFormData?.data?.assetCountryList, !values.clientFund])

  useEffect(() => {
    onFocusOutEvent(debouncedassetName, 'assetName')
  }, [debouncedassetName])

  useEffect(() => {
    onFocusOutEvent(debouncedassetReference, 'assetReference')
  }, [debouncedassetReference])

  const getGresbSectorDropDownValues = () => {
    const sectorLabel = getSectorLabel(values.sector)
    switch (sectorLabel) {
    case 'Education':
      return GRESB_SECTOR.EDUCATION
    case 'Healthcare':
      return GRESB_SECTOR.HEALTHCARE
    case 'Hotel':
      return GRESB_SECTOR.HOTEL
    case 'Industrial':
      return GRESB_SECTOR.INDUSTRIAL
    case 'Lodging, Leisure & Recreation':
      return GRESB_SECTOR.LODGING_LEISURE_CREATION
    case 'Mixed Use':
      return GRESB_SECTOR.MIXEDUSE
    case 'Office':
      return GRESB_SECTOR.OFFICE
    case 'Residential, Multi Family':
      return  GRESB_SECTOR.RESIDENTIAL_MULTIFAMILY
    case 'Residential Single Family':
      return GRESB_SECTOR.RESIDENTIAL_SINGLEFAMILY
    case 'Retail, High Street':
      return GRESB_SECTOR.RETAIL_HIGH_STREET
    case 'Retail, Shopping Center':
      return  GRESB_SECTOR.RETAIL_SHOPPING_CENTER
    case 'Retail, Warehouse':
      return  GRESB_SECTOR.RETAIL_SHOPPING_CENTER
    case 'Other':
      return GRESB_SECTOR.OTHER
    case 'Industrial, Distribution Warehouse':
      return GRESB_SECTOR.INDUSTRIAL_DISTRIBUTION_WAREHOUSE
    case 'Technology/Science':
      return  GRESB_SECTOR.TECHNOLOGY_SCIENCE
      
    default:
      return []
    }
  }
  const handleSectorChange = (event) => {
    const selectedSectorId = event.target.value
    setValues({ ...values, propertySector: sectorMap[selectedSectorId], sector: selectedSectorId, gresbSector: '' })
  }

  const fetchForEnteredNames = async (reftechApi) => {
    try {
      return await reftechApi()
    }
    catch (error) {
      console.error('Error:', error)
    }
  }

  function onFocusOutEvent(value, name){
    const isAssetName = name === 'assetName'
    if (value && assetDetailsFields &&  assetDetailsFields[isAssetName ? 'propertyName' : 'propertyReference'] === value) {
      setFieldError(name, '')
    } else {
      // Conditionally call the appropriate refetch function based on isAssetName
      fetchForEnteredNames(isAssetName ? refetchAssetNameDetails : refetchAssetReferenceDetails).then((result) => {
        updateCurrentAssetState({
          [isAssetName ? 'assetNameDetails': 'assetReferenceDetails']: result.data
        })
      })
    }
  }

  function getCountryDropDownValues(){
    return Array.isArray(assetFormData?.data?.assetCountryList)?
      assetFormData?.data?.assetCountryList.map(country => ({
        value: Object.keys(country)[0],
        label: Object.values(country)[0],
      })) : []
  }

  function getlocationDropDownValues(data){
    return Array.isArray(data)?
      data.map(item => ({
        value: Object.keys(item)[0],
        label: Object.values(item)[0],
      })) : []
  }

  function getFundDropDownValues(){
    if(!isAssets && assetFormData?.data && values?.fund){
      const keyToFind = values?.fund
      const entry = assetFormData?.data?.userAccessibleFunds?.find(obj => obj[keyToFind] !== undefined)
      const label = entry?.[keyToFind]
      return [{ label: String(label), value: keyToFind }]
    }

    if (!isAssets && assetFormData?.data && !values?.fund) {
      return getFundValueForAdding()
    }

    if(Array.isArray(assetFormData?.data?.userAccessibleFunds)){
      const res = assetFormData?.data?.userAccessibleFunds.reduce((acc, curr) => {
        if(curr && typeof curr === 'object'){
          acc.push({label: String(Object.values(curr)[0]), value: Object.keys(curr)[0]})
        }
        return acc
      }, [])
      return res
    }
    return []
  }

  const handleError = (param, unit) => {
    let error = ''
    if (param !== '' && (param === 0 || param !== 0)) {
      if ((unit === MeasurementUnits.M2 && Number(param) < 10) || (unit === MeasurementUnits.FT2 && Number(param) < 100)) {
        error = <Text className='medium-text building-utilities-warning' colour='orange' content={getLocalizedValue(selectedLanguage, 't_gia_nla_cpa_ext_length_validation_message')} />
      }
    }
    return error
  }

  const handleCountryChange = (event) => {
    const selectedCountry = assetFormData?.data?.assetCountryList.find(country => Object.keys(country)[0] === event.target.value)
    setFieldError('region', '')
    setFieldTouched('region', false)
    setRegionFilterData({regionFileterDate: []})
    setValues({ ...values, country: Object.keys(selectedCountry)[0], countryLabel: Object.values(selectedCountry)[0], region: ''})
  }

  const handleUnitChange = (fieldName, newUnit) => {
    setUnit((prevUnit) => ({
      ...prevUnit,
      [fieldName]: newUnit,
    }))
  }

  const handleRegionChange = (event) => {
    const newRegion =  customState.regionFilterData.find(region => region?.value === event.target.value)
    setValues({ ...values, region: newRegion?.value})
  }

  function hasCheckWarning(){
    if(Array.isArray(values?.buildingUtilitesPresent) && values?.buildingUtilitesPresent.length){
      const dataItem = values.buildingUtilitesPresent
      if(hasAllValuesInArray(dataItem, ['ignoreGasMaxCoverage', 'ignoreDHMaxCoverage', 'ignoreDCMaxCoverage'])){
        return <Text colour='orange' content={getLocalizedValue(selectedLanguage, 't_warning_gas_district_heating_and_district_cooling')} />
      }
      if(hasAllValuesInArray(dataItem, ['ignoreGasMaxCoverage', 'ignoreDHMaxCoverage'])){
        return  <Text colour='orange' content={getLocalizedValue(selectedLanguage, 't_warning_gas_district_heating')} />
      }
      if(hasAllValuesInArray(dataItem, ['ignoreGasMaxCoverage', 'ignoreDCMaxCoverage'])){
        return <Text colour='orange' content={getLocalizedValue(selectedLanguage, 't_warning_gas_district_cooling')} />
      }
    }
    return ''
  }

  function convertArrayToLowerCaseKeys(arr) {
    return arr.map(item => {
      const key = Object.keys(item)[0] // Assuming each object has only one key
      const value = item[key]
      return { [key.toLowerCase()]: value }
    })
  }

  const isGresbSectorValue = getGresbSectorDropDownValues().includes(values.gresbSector)
  useEffect(() => {
    setTouched({ ...touched, 'buildingUtilitesPresent': true })
    const sector = Number(values.sector) && sectorMap ? sectorMap[values.sector] : values.sectorID
    if (values.estimatedCpa && isGresbSectorValue && sector) {
      const sectorCaseConverted = sector.toLowerCase()
      const cpaSectorList = convertArrayToLowerCaseKeys(assetFormData?.data?.cpaRatioList)?.find((sec) => Object.keys(sec).includes(sectorCaseConverted))
      if (cpaSectorList) {
        const cpaRatio = cpaSectorList[sectorCaseConverted][values.gresbSector]
        const cpa = ((values.nla || 0) / ( 1 - cpaRatio)) * cpaRatio
        const gia = (values.nla || 0) + cpa
        setValues({ ...values, gia: gia, cpa: cpa })
      }
    }
  }, [values.nla, values.cpa, values.estimatedCpa, values.gia, values.gresbSector, sectorMap])

  return (
    <>
      <div className='grid p-4'>
        <div className='col-5 pr-3'>
          <span className='mt-0 large-text-2'>{getLocalizedValue(selectedLanguage, 't_lifecycles')}</span>
          <p className='mr-3 ml-0 medium-text-3'>{getLocalizedValue(selectedLanguage, 't_lifecycles_explanation')}</p>
        </div>
        <div className="col-7 flex flex-column gap-1">
          <DropdownFormik
            value={values.currentStatus}
            handleChange={handleChange}
            tooltipContentKeys={{content: 't_current_status_tooltip', title: 't_current_status'}}
            tooltipTriggerClass='current_status_alt'
            label={`${getLocalizedValue(selectedLanguage, 't_current_status')}*`}
            name='currentStatus'
            placeholder={getLocalizedValue(selectedLanguage, 't_select_asset_status')}
            errors={errors}
            touched={touched}
            options={CURRENT_STATUS_DROPDOWN} />
        </div>
      </div>
      <div className='border-bottom-2 my-2 p-0'/>
      <div className='grid p-4'>
        <div className='col-5 pr-3'>
          <span className='mt-0 large-text-2'>{getLocalizedValue(selectedLanguage, 't_key_dates')}</span>
        </div>
        <div className="col-7 flex flex-column gap-1">
          <TextfieldFormik value={values?.yearBuilt ? values.yearBuilt : ''} label={getLocalizedValue(selectedLanguage, 't_year_built')} name='yearBuilt' errors={errors} touched={touched}/>
          <CalendarFormik value={values.purchaseDate ? new Date(values.purchaseDate) : null} label={`${getLocalizedValue(selectedLanguage, 't_purchase_date')}*`} name='purchaseDate' placeholder={getLocalizedValue(selectedLanguage, 't_select_date')} errors={errors} touched={touched} dateFormat={calendarDateFormat()} />
          <CalendarFormik clear value={values.soldDate ? new Date(values.soldDate) : null} label={getLocalizedValue(selectedLanguage, 't_sold_date')} name='soldDate' errors={errors} placeholder={getLocalizedValue(selectedLanguage, 't_select_date')} touched={touched} dateFormat={calendarDateFormat()}  purchaseDate={values.purchaseDate} /> 
        </div>
      </div>
      <div className='border-bottom-2 my-2 p-0'/>
      <div className='grid p-4'>
        <div className='col-5 pr-3'>
          <span className='mt-0 large-text-2'>{getLocalizedValue(selectedLanguage, 't_asset_details')}</span>
        </div>
        <div className="col-7 flex flex-column gap-1">
          <TextfieldFormik handleChange={handleChange} label={`${getLocalizedValue(selectedLanguage, 't_asset_name')}*`} testId='asset-name' name='assetName' value={values.assetName} errors={errors} touched={touched} />
          <TextfieldFormik handleChange={handleChange} tooltipContentKeys={{content: 't_asset_ref_tooltip', title: 't_asset_reference'}} tooltipTriggerClass='asset_ref_alt' testId='asset-ref' label={`${getLocalizedValue(selectedLanguage, 't_asset_reference')}*`} name='assetReference' value={values.assetReference} errors={errors} touched={touched} 
          />
          <DropdownFormik dataTestId='fund-dropdown-add-edit' handleChange={handleChange} label={`${getLocalizedValue(selectedLanguage, 't_fund')}*`} name='fund' placeholder={getLocalizedValue(selectedLanguage, 't_select_fund')} errors={errors} touched={touched} options={getFundDropDownValues()} isEnabled={fundDropDownStatus} value={values?.fund} />
          <TextfieldFormik handleChange={handleChange} label={`${getLocalizedValue(selectedLanguage, 't_asset_ownership')} %*`} value={values.assetOwnership} name='assetOwnership' errors={errors} touched={touched}/>
        </div>
      </div>
      <div className='border-bottom-2 my-2 p-0'/>
      <div className='grid p-4'>
        <div className='col-5 pr-3'>
          <span className='mt-0 large-text-2'>{getLocalizedValue(selectedLanguage, 't_address')}</span>
        </div>
        <div className="col-7 flex flex-column gap-1">
          <DropdownFormik dataTestId='fund-country-add-edit' value={Number(values.country) ? values.country : countryValue && Object.keys(countryValue)[0]} handleChange={handleChange} label={`${getLocalizedValue(selectedLanguage, 't_country')}*`} name='country' placeholder={getLocalizedValue(selectedLanguage, 't_select_country')} errors={errors} touched={touched} options={getCountryDropDownValues()} isFilterRequired={true} onOptionChange={handleCountryChange}/>
          <DropdownFormik  handleChange={handleChange} label={`${getLocalizedValue(selectedLanguage, 't_region')}*`} name='region' value={values.region} placeholder={getLocalizedValue(selectedLanguage, 't_select_region')} errors={errors} touched={touched} options={customState.regionFilterData} isFilterRequired={true} isEnabled={!values?.country} onOptionChange={handleRegionChange}/>
          <TextfieldFormik handleChange={handleChange} label={getLocalizedValue(selectedLanguage, 't_city')} name='city' value={values.city} errors={errors} touched={touched} options={cityFilterData} isFilterRequired={true} />
          <TextfieldFormik handleChange={handleChange} label={getLocalizedValue(selectedLanguage, 't_street')} name='street' value={values.street} errors={errors} touched={touched} />
          <TextfieldFormik handleChange={handleChange} label={getLocalizedValue(selectedLanguage, 't_postcode')} name='postcode' value={values.postcode} errors={errors} touched={touched} />
        </div>
      </div>
      <div className='border-bottom-2 my-2 p-0'/>
      <div className='grid p-4'>
        <div className='col-5 pr-3'>
          <span className='mt-0 large-text-2'>{getLocalizedValue(selectedLanguage, 't_building_details')}</span>
        </div>
        <div className="col-7 flex flex-column gap-1">
          <DropdownFormik
            label={`${getLocalizedValue(selectedLanguage, 't_operation_control')}*`}
            name='operationControl'
            placeholder={getLocalizedValue(selectedLanguage, 't_select_operational_control')}
            errors={errors}
            value={values.operationControl}
            touched={touched}
            options={procuredByList}
            tooltipContentKeys={{content: [{
              contentKey: 't_operation_control_landlord_controlled_tooltip',
              dynamicValueKey: 't_landlord_controlled',
            },
            {
              contentKey: 't_operation_control_tenant_controlled_tooltip',
              dynamicValueKey: 't_tenant_controlled',
            }], title: 't_operation_control'}}
            tooltipTriggerClass={'operation_control_alt'}
          />
          <TextfieldDropdownFormik
            value={values.gav}
            handleChange={handleChange}
            label={getLocalizedValue(selectedLanguage, 't_gross_asset_value')}
            name='gav'
            errors={errors}
            touched={touched}
            options={CURRENCY}
            inputType={'number'}/>
          {values.gav && (
            <CalendarFormik
              handleChange={handleChange}
              label={`${getLocalizedValue(selectedLanguage, 't_gav_evaluation_date')}*`}
              name='gavEvalDate'
              value={values?.gavEvalDate}
              placeholder={getLocalizedValue(selectedLanguage, 't_select_date')}
              errors={errors}
              view={'date'}
              touched={touched}
              dateFormat={DATE_FORMAT}
              showIcon />
          )}
          <DropdownFormik
            handleChange={handleChange}
            isFilterRequired={false}
            label={`${getLocalizedValue(selectedLanguage, 't_building_utilities')}*`}
            dropdownType={DROPDOWN_TYPE.MULTI}
            name='buildingUtilitesPresent'
            placeholder={getLocalizedValue(selectedLanguage, 't_select_building_utilities')}
            errors={errors}
            touched={touched}
            hasWarning={!!hasCheckWarning()}
            value={values.buildingUtilitesPresent}
            options={buildingUtilitesPresentDropdownValues}
          />
          <div className='medium-text building-utilities-warning'>
            {hasCheckWarning()}
          </div>
        </div>
      </div><div className='border-bottom-2 my-2 p-0' /><div className='grid p-4'>
        <div className='col-5 pr-3'>
          <span className='mt-0 large-text-2'>{getLocalizedValue(selectedLanguage, 't_sector')}</span>
        </div>
        <div className="col-7 flex flex-column gap-1">
          <DropdownFormik
            handleChange={handleChange}
            value={String(values.sector)}
            label={`${getLocalizedValue(selectedLanguage, 't_sector')}*`}
            name='sector' placeholder={getLocalizedValue(selectedLanguage, 't_select_sector')}
            errors={errors}
            touched={touched}
            options={sectorList}
            onOptionChange={handleSectorChange} />
          <DropdownFormik
            clear={isGresbSectorValue}
            handleChange={handleChange}
            value={values.gresbSector}
            label={getLocalizedValue(selectedLanguage, 't_gresb_sector')}
            name='gresbSector'
            placeholder={getLocalizedValue(selectedLanguage, 't_select_gresb_sector')}
            errors={errors}
            touched={touched}
            options={getGresbSectorDropDownValues()}
            tooltipContentKeys={{content: 't_gresb_sector_tooltip', title: 't_gresb_sector'}}
            tooltipTriggerClass='gresb_sector_alt'
            isEnabled={!values.sector}/>
          {/*No need to make any Changes it will be working once backend changes are updated*/}
          {/*<DropdownFormik
            clear
            handleChange={handleChange}
            label={getLocalizedValue(selectedLanguage, 't_energy_star_sector')}
            name='energyStarSector'
            value={values.energyStarSector}
            placeholder={getLocalizedValue(selectedLanguage, 't_select_energy_star_sector')}
            errors={errors}
            touched={touched}
            options={getEnergyStarSectorDropDownValues(values)}
            tooltipContentKeys={{content: 't_energy_star_sector_tooltip', title: 't_energy_star_sector'}}
            tooltipTriggerClass='energy_star_sector_alt'
            isEnabled={!values.sector}/>*/}
        </div>
      </div>
      <div className='border-bottom-2 my-2 p-0' />
      <div className='grid p-4'>
        <div className='col-5 pr-3'>
          <span className='mt-0 large-text-2'>{getLocalizedValue(selectedLanguage, 't_floor_area')}</span>
        </div>
        <div className="col-7 flex flex-column gap-1">
          {values.gresbSector && (values.nla > 0) && (
            <CheckboxFormik hasLabel variantSize={'checkbox-md'} labelText={'Estimate CPA'} name='estimatedCpa' value={values.estimatedCpa} onChange={onCheckboxClick}/>
          )}
          <TextfieldDropdownFormik
            value={values.gia}
            handleChange={handleChange}
            label={`${getLocalizedValue(selectedLanguage, 't_gia')}*`}
            name='gia'
            errors={errors}
            touched={touched}
            options={UNIT_ARRAY}
            disabled={isGresbSectorValue && values.estimatedCpa}
            testId="gia"
            tooltipContentKeys={{content:'t_gia_tooltip_content', title:'t_gia'}}
            tooltipTriggerClass='gia_alt'
            hasWarning={!!handleError(values.gia, unit.gia)}
            inputType="number"
            unit={unit.gia}
            onUnitChange={(newUnit) => handleUnitChange('gia', newUnit)}
          />
          {handleError(values.gia, unit.gia)}


          <TextfieldDropdownFormik
            value={values.nla}
            handleChange={handleChange}
            label={`${getLocalizedValue(selectedLanguage, 't_nla')}*`}
            name='nla'
            testId="nla"
            errors={errors}
            touched={touched}
            options={UNIT_ARRAY}
            hasWarning={!!handleError(values.nla, unit.nla)}
            tooltipContentKeys={{content:'t_nla_tooltip_content', title:'t_nla'}}
            tooltipTriggerClass='nla_alt'
            inputType="number"
            unit={unit.nla}
            onUnitChange={(newUnit) => handleUnitChange('nla', newUnit)}
          />
          {handleError(values.nla, unit.nla)}


          <TextfieldDropdownFormik
            value={values.cpa}
            handleChange={handleChange}
            label={getLocalizedValue(selectedLanguage, 't_cpa')}
            name='cpa'
            disabled={isGresbSectorValue && values.estimatedCpa}
            errors={errors}
            touched={touched}
            options={UNIT_ARRAY}
            tooltipContentKeys={{content:'t_cpa_tooltip_content', title:'t_cpa'}}
            tooltipTriggerClass='cpa_alt'
            inputType="number" />
          <TextfieldDropdownFormik
            value={values.ext}
            handleChange={handleChange}
            label={getLocalizedValue(selectedLanguage, 't_ext')}
            name='ext'
            errors={errors}
            touched={touched}
            options={UNIT_ARRAY}
            tooltipContentKeys={{content:'t_ext_tooltip_content', title:'t_ext'}}
            tooltipTriggerClass='text_alt'
            inputType="number" />
        </div>
      </div>
      <div className='border-bottom-2 my-2 p-0' />
    </>
  )
}

AddEditAssetForm.propTypes = {
  isAssets: PropTypes.bool,
  fundName: PropTypes.string,
  buildingUtilitesPresentDropdownValues: PropTypes.array,
  assetDetailsFields: PropTypes.object,
  updateCurrentAssetState: PropTypes.object
}

export default AddEditAssetForm