import PropTypes from 'prop-types'
import './DQProgressBar.scss'
import {forwardRef} from 'react'
import CSS_VARIABLES from '../../../../../resources/css/_variables.scss'

/**
 * Primary UI component for user interaction
 */
const DQProgressBar = forwardRef((
  {
    value,
    className,
    testId
  }, ref) => {

  return (
    <div className={className ? `dqBarWrapper ${className}` : 'dqBarWrapper'} data-testid={testId}>
      {value?.actual != 0 && (
        <span style={{width: `${Math.round(value?.actual * 100 < 1 ? 1 : value?.actual * 100)}%`, backgroundColor: `${CSS_VARIABLES.forest}`}}></span>
      )}
      {value?.estimated != 0 && (
        <span style={{width: `${Math.round(value?.estimated * 100 < 1 ? 1 : value?.estimated * 100)}%`, backgroundColor: `${CSS_VARIABLES.forest_50}`}}></span>
      )}
      {value?.incomplete != 0 && (
        <span style={{width: `${Math.round(value?.incomplete * 100 < 1 ? 1 : value?.incomplete * 100)}%`, backgroundColor: `${CSS_VARIABLES.nature}`}}></span>
      )}
      {value?.notCovered != 0 && (
        <span style={{width: `${Math.round(value?.notCovered * 100 < 1 ? 1 : value?.notCovered * 100)}%`, backgroundColor: `${CSS_VARIABLES.sand}`}}></span>
      )}
    </div>
  )
})

export default DQProgressBar

DQProgressBar.propTypes = {
  value: PropTypes.object,
  className: PropTypes.string,
  testId: PropTypes.string,
}

DQProgressBar.displayName = 'DQProgressBar'