import React from 'react'
import './PageContainer.scss'

// This page container includes the common page layout and styles.
function PageContainer(props) {
  return (
    <div className={'page-container grey-bg'}>
      <div className={'page-view container-layout grey-bg gutter'}>
        <div className={'page-view-content'}>
          {props.children}
        </div>
      </div>
    </div>
  )
}

export default PageContainer