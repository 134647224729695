import React, {useState} from 'react'
import I18n from '../../../../../utils/i18n/I18n'
import CustomAccordion from '../../../../common/custom-accordion/CustomAccordion'
import RecipientEmailContainer from '../../../../common/recipient-email-container/RecipientEmailContainer'
import CustomSidebar from '../../../../common/custom-sidebar/CustomSidebar'
import {
  addEditConsumptionAccordionTemplate,
  dqMeterAccordionTemplate,
  prepareDataForAddEdit,
  requestConsumptionAccordionTemplate,
  requestConsumptionMeterTemplate
} from './ConsumptionUtil'
import PropTypes from 'prop-types'
import {useMutation} from 'react-query'
import {
  addNewMissingDataRequestInternal,
  getDataGapRequestObj,
  submitDataQualityRequest,
  updateDataQualityRequest
} from '../../../../../services/assets/dataquality-service'
import {createConsumptionRequestData} from '../../../../../utils/helpers/Helper'
import sendConfirmationIcon from '../../../../../resources/images/icon/action-plan/send-confirmation.svg'
import '../consumption/add-edit/AddEditConsumption.scss'
import useErrorHandling from '../../../../common/error-handling/ErrorHandling'
import {Button} from '../../../../atomic'

function ConsumptionNavigator({
  assetId,
  selectedData = [],
  isSidebarVisible,
  setEmailAddresses,
  sendDataHandler,
  showRequestConfirmation,
  setShowRequestConfirmation,
  setIsSidebarVisible,
  meterInfo,
  isMeter,
  isMeterAndIsRequestConsumption,
  dqMeterData,
  gapsPerMonth = [],
  language,
  unitSystem,
  colorLabel,
  refetch,
  allData
}) {
  const isRequestConsumption = isSidebarVisible
  const selectedDataLength = selectedData ? selectedData.length : 1
  const [activeIndex, setActiveIndex] = useState(getActiveIndex())
  const [data, setData] = useState(prepareDataForAddEdit(selectedData))
  const [isSubmitClicked, setIsSubmitClicked] = useState(false)
  const consumptionDataSubmitMutation = useMutation(submitDataQualityRequest)
  const consumptionDataUpdateMutation = useMutation(updateDataQualityRequest)
  const submitButtonLabel = isRequestConsumption.isAddConsumptionBar
    ? I18n('t_submit_consumption')
    : getUpdateButtonLabel()
  const requestConfirmationText = isRequestConsumption.isAddConsumptionBar
    ? I18n('t_submit_confirmation')
    : getUpdateConfirmationLabel()
  const [showConfirmationText, setShowConfirmationText] = useState(false)
  const sidebarStyle = isMeter && !isMeterAndIsRequestConsumption ? 'missing-data-sidebar-container dq-meter-sidebar-container' : 'missing-data-sidebar-container'
  const {errorDialog, ShowErrorDialog, errorDialogVisible} = useErrorHandling()

  function navigate(type, isHeader) {
    if (type.isRequestConsumptionBar) {
      if (isMeterAndIsRequestConsumption) {
        return isHeader ? I18n('t_header_request_consumption') : requestConsumptionMeterTemplate(data)
      } else {
        return isHeader ? I18n('t_header_request_consumption') : requestConsumptionAccordionTemplate(data)
      }
    }
    if (type.isAddConsumptionBar) {
      return isHeader ? I18n('t_add_consumption_title') : addEditConsumptionAccordionTemplate(
        data,
        getDetailsOnSubmit,
        isSubmitClicked,
        activeIndex)
    }
    if (isRequestConsumption.isEditConsumptionBar) {
      const headerText = data?.length > 1 ? I18n('t_edit_records_title') :
        I18n('t_edit_record_title')
      return isHeader ? headerText : addEditConsumptionAccordionTemplate(
        data,
        getDetailsOnSubmit,
        isSubmitClicked,
        activeIndex
      )
    }
    if (isMeter) {
      return isHeader ? dqMeterData.electricityMPAN :
        dqMeterAccordionTemplate(gapsPerMonth, dqMeterData, language, unitSystem, colorLabel, refetch, allData)
    }
    return null
  }

  function getActiveIndex() {
    if (isRequestConsumption.isRequestConsumptionBar) {
      return selectedData?.length === 1 ? 0 : -1
    }
    return 0
  }

  function getUpdateButtonLabel() {
    return selectedDataLength === 1
      ? I18n('t_update_record') : I18n('t_update_records')
  }

  function getUpdateConfirmationLabel() {
    return selectedDataLength === 1
      ? I18n('t_update_record_confirmation') : I18n('t_update_records_confirmation')
  }

  async function getDetailsOnSubmit(newData) {
    if (newData.data) {
      setData(prevData => {
        const updatedData = [...prevData]
        updatedData[newData.index] = {
          ...newData.data
        }
        return updatedData
      })

      if (isSubmitClicked) {
        data[newData.index] = newData.data
        await submitConsumptionRequest()
      }
    }
  }

  function handleSubmitClick() {
    setIsSubmitClicked(true)
  }

  function parseSubmitResponse(response,requestData) {
    const hasErrorCode = response.some((item) => item.errorCode !== null)
    if (hasErrorCode) {
      errorDialog({title: I18n('t_error_title'), message: I18n('t_consumption_upload_error_text_one') })
    } else {
      setShowConfirmationText(true)
      if (isMeter) {
        response.map((data,index)=>{
          data.utility = requestData[index].utility
        })
        refetch(data, response)
      }
      resetData()
    }
  }

  const missingDataMutation = useMutation(addNewMissingDataRequestInternal, {
    onSuccess: async (response) => {
      if (response) {
        const touchedData = data.filter(data => data.isErrors === false && data.isDirty === true)
        try {
          const requestId = response.split('/')[3].substr(1)
          const requestData = createConsumptionRequestData(touchedData, requestId)

          if (isRequestConsumption.isAddConsumptionBar) {
            await consumptionDataSubmitMutation.mutateAsync(requestData)
              .then((response) => parseSubmitResponse(response,requestData))
          }
          if (isRequestConsumption.isEditConsumptionBar) {
            await consumptionDataUpdateMutation.mutateAsync(requestData)
              .then((response) => parseSubmitResponse(response,requestData))
          }
        } catch (error) {
          errorDialog({title: I18n('t_error_title'), message: error.toString()})
        }
      }
    }
  })

  const resetData = ()=>{
    const resetData = data.map(data => {
      if(data.isErrors === false && data.isDirty === true){
        data.isErrors = false
        data.isDirty = false
        data.isWarnings = false
        data.isWarnAndError = false
        return data
      }else{
        return data
      }
    })
    setData(resetData)
  }

  const submitConsumptionRequest = async () => {
    try {
      const touchedData = data.filter(data => data.isErrors === false && data.isDirty === true)
      if (touchedData.length > 0) {
        await missingDataMutation.mutateAsync({assetId: assetId, dataGap: getDataGapRequestObj(touchedData)}).then(()=>{
          setIsSubmitClicked(false)
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  function getTabIndex(tabIndex) {
    setActiveIndex(tabIndex)
  }

  return (
    <CustomSidebar customClass={sidebarStyle} isSidebarVisible={true} dismissable={false}
      setIsSidebarVisible={setIsSidebarVisible} sidebarHeader={navigate(isRequestConsumption, true)}>
      {meterInfo && <div className={'nav-meter-info'}>{meterInfo}</div>}
      {((!isMeter && !isMeterAndIsRequestConsumption) || (isRequestConsumption.isEditConsumptionBar || isRequestConsumption.isAddConsumptionBar)) &&
                <CustomAccordion
                  data={data || [dqMeterData]}
                  activeIndex={activeIndex}
                  setActiveIndex={getTabIndex}
                  isAccordionDisabled={(isRequestConsumption.isEditConsumptionBar || isRequestConsumption.isAddConsumptionBar) && selectedData.length === 1}>
                  {navigate(isRequestConsumption, false)}
                </CustomAccordion>
      }
      {((isMeter || isMeterAndIsRequestConsumption) && !(isRequestConsumption.isEditConsumptionBar || isRequestConsumption.isAddConsumptionBar)) &&
                navigate(isRequestConsumption, false)}
      {isRequestConsumption.isRequestConsumptionBar &&
                <RecipientEmailContainer setEmailAddresses={setEmailAddresses} sendDataHandler={sendDataHandler}
                  showRequestConfirmation={showRequestConfirmation}
                  setShowRequestConfirmation={setShowRequestConfirmation}
                />
      }
      <div className={'consumption-footer'}>
        {(isRequestConsumption.isAddConsumptionBar || isRequestConsumption.isEditConsumptionBar) &&
            <div className={'flex mb-2'}>
              <Button content={submitButtonLabel} colour={'teal'} onClick={handleSubmitClick} size={'small'}
                className={'mr-2'}/>
              <Button content={'t_cancel'} size={'small'} colour={'teal'} onClick={() => setIsSidebarVisible(false)}/>
            </div>
        }
        {showConfirmationText && <div className={'mt-4'}>
          <img className={'mr-0_5'} src={sendConfirmationIcon}
            alt="confirmation icon"/><span>{requestConfirmationText}</span>
        </div>}
      </div>
      {errorDialogVisible && <ShowErrorDialog/>}
    </CustomSidebar>
  )
}

ConsumptionNavigator.propTypes = {
  assetId: PropTypes.string,
  selectedData: PropTypes.array,
  isSidebarVisible: PropTypes.any,
  setEmailAddresses: PropTypes.func,
  sendDataHandler: PropTypes.func,
  showRequestConfirmation: PropTypes.bool,
  setShowRequestConfirmation: PropTypes.func,
  setIsSidebarVisible: PropTypes.func.isRequired,
  meterInfo: PropTypes.object,
  isMeter: PropTypes.bool,
  isMeterAndIsRequestConsumption: PropTypes.bool,
  dqMeterData: PropTypes.object,
  gapsPerMonth: PropTypes.array,
  language: PropTypes.string,
  unitSystem: PropTypes.string,
  colorLabel: PropTypes.string,
  refetch: PropTypes.func,
  allData: PropTypes.object
}

ConsumptionNavigator.displayName = 'ConsumptionNavigator'

export default ConsumptionNavigator