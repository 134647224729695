import React, {useEffect, useState} from 'react'
import './ModalDialogHome.scss'
import {Dialog} from 'primereact/dialog'
import {TabView, TabPanel} from 'primereact/tabview'
import {helperMethodEvents} from '../../../services/assets/performance-service'
import ModalDialogMeterView from '../../pages/assets/performance/dialogs/ModalDialogMeterView'
import CommonNote from '../../common/action-plan/CommonNote'
import I18n from '../../../utils/i18n/I18n'
import {useMutation, useQueryClient} from 'react-query'
import {getLocalizedValue, getLocalStorageItem} from '../../../utils/helpers/Helper'
import PropTypes from 'prop-types'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const ModalDialogHome = (props) => {
  const queryClient = new useQueryClient()
  const { loginState: { userInfo } } = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const [activeIndex, setActiveIndex] = useState(props.activeTabIndex ? props.activeTabIndex : 0 )
  const [isUnSavedNote, setNoteUnsaved] = useState(false)
  const [isSubmit, setSubmit] = useState(false)
  const [assetId] = useState(props.assetId)
  const selectedData = props.selection
  const [selectedNoteData, setSelectedNoteData] = useState(props.selection?.eventNotes||[])
  const [newNotesAdded] = useState([])
  const [deletedNotes] = useState([])
  const [updatedNotes] = useState([])
  const [edit, setEdit] = useState(false)
  const {mutateAsync} = useMutation({
    mutationFn: helperMethodEvents,
    onSuccess: () => {
      queryClient.invalidateQueries('data_events')
    }
  })
  const [hideClick, setHideClick] = useState(false)
  const [tempNoteId, setTempNoteId] = useState(0)
  const [noteState, setNoteState] = useState(null)

  useEffect(() => {
    if(props?.data){
      const findNoteItem = props?.data.find(item => item.eventID === props?.selection?.eventID)
      setSelectedNoteData(findNoteItem?.eventNotes)
    }else if(props?.eventsList) {
      setSelectedNoteData(props?.eventsList?.eventNotes)
    }
  }, [props?.eventsList, props?.data, props?.selection])

  const onHide = async (noteId) => {
    setHideClick(true)
    if(!noteState){
      props.onEventActionHideHandler(false)
    }
    if(isUnSavedNote === false){
      setEdit(true)
      await onSaveClick(noteId, edit)
      setHideClick(false)
    }
  }

  const isTextExist=(field, content)=>{
    if (!field) {
      setHideClick(false)
    }
    setNoteState(content)
    setNoteUnsaved(field)
    setEdit(true)
  }

  const onSaveNote =  (noteData, edit,toUpdate,noteId) => {
    try {
      if (toUpdate === 'delete') {
        deleteNote(noteData)
      } else if (toUpdate) {
        updateNote(noteData,noteId)
      } else {
        saveNoteItem(noteData, edit, toUpdate)
      }
    } catch {
      console.log('unable to perform Note Action')
    }
  }

  const saveNoteItem = async (noteValue, edit,toUpdateNote) => {
    const nodeItemValue = {'dateCreated': new Date(), 'content': noteValue,'userName' :getLocalStorageItem('userName'),toUpdateNote,tempNoteId}
    selectedNoteData.push(nodeItemValue)
    newNotesAdded.push(nodeItemValue)
    setTempNoteId(tempNoteId+1)

    const eventId = selectedData?.eventID
    const params = {assetId: assetId, eventId: eventId, notesInfo: {...nodeItemValue}, action: ''}
    await mutateAsync(params)
    setNoteUnsaved(!isUnSavedNote)
    props.reLoad()
  }

  async function deleteNote(noteData) {
    if(noteData.noteID){
      let noteIndex = selectedNoteData.findIndex(note => note.noteID === noteData.noteID)
      if (noteIndex !== -1) {
        selectedNoteData.splice(noteIndex, 1)
        deletedNotes.push(noteData)
      }
    }else{
      let newNoteIndex = newNotesAdded.findIndex(note => note.tempNoteId === noteData.tempNoteId)
      newNotesAdded.splice(newNoteIndex, 1)
      let noteIndex = selectedNoteData.findIndex(note => note.tempNoteId === noteData.tempNoteId)
      selectedNoteData.splice(noteIndex, 1)
    }
    const noteDeleteItem = {notesInfo: noteData, assetId, eventId: selectedData.eventID, 'action': 'deleteEventNote'}
    await mutateAsync(noteDeleteItem)
    props.reLoad()
  }

  async function updateNote(content,noteData) {
    if(noteData.noteID){
      let noteIndex = selectedNoteData.findIndex(note => note.noteID === noteData.noteID)
      selectedNoteData[noteIndex].content = content
      updatedNotes.push(selectedNoteData[noteIndex])
    }else{
      let newNoteUpdatingIndex = newNotesAdded.findIndex(note => note.tempNoteId === noteData.tempNoteId)
      newNotesAdded[newNoteUpdatingIndex].content = content
      let noteIndex = selectedNoteData.findIndex(note => note.tempNoteId === noteData.tempNoteId)
      selectedNoteData[noteIndex].content = content
    }
    const noteUpdateItem = {notesInfo: noteData, assetId, eventId: selectedData.eventID, 'action': 'updateEventNote'}
    await mutateAsync(noteUpdateItem)
    setNoteUnsaved(!isUnSavedNote)
    props.reLoad()
  }

  function getNotesValue(value){
    setNoteState(value)
  }

  const onSaveClick = async () => {
    setSubmit(true)
  }
  const CommonNoteErrorMessage = ()=>{
    return(
      <>
        {noteState ? <div className="action-footer">
          <div className="pt-4 float-right">
            <div className="col-12 no-padding" style={{display: isUnSavedNote && hideClick ? 'block' : 'none'}}>
              <div className="larger-text-bold p-error-message">{I18n('t_post_note_warning_message')}</div>
            </div>
          </div>
        </div>: null}
      </>
    )
  }

  return (
    <Dialog
      className="modal-dialog-container"
      visible={props.showDialog}
      onHide={onHide}
      draggable={false}
      header = {props.header}
      blockScroll
      resizable={false}
    >
      <div className="mt-2">
        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
          <TabPanel header={getLocalizedValue(selectedLanguage, 't_meters')} disabled={isUnSavedNote}>
            <ModalDialogMeterView
              data = {props.meterSummaryData}
              utility = {props.utility}
              yearMonth = {props.yearMonth}
            />
          </TabPanel>
          <TabPanel header={I18n('t_notes')} headerClassName={isUnSavedNote && isSubmit ? 'red-font' : ''}>
            <CommonNote
              assetId={assetId}
              onSaveNote={onSaveNote}
              selectedData={selectedData}
              notesData={selectedNoteData}
              isTextExist={isTextExist}
              errorMsgComponent={hideClick ? <CommonNoteErrorMessage/> : null}
              getNotesValue={getNotesValue} 
              isNotesValue={props.isNotesValue}
              content={noteState}
            />
          </TabPanel>
        </TabView>
      </div>
    </Dialog>
  )
}

ModalDialogHome.propTypes = {
  utility: PropTypes.string,
  yearMonth: PropTypes.number
}

export default ModalDialogHome