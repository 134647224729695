import {Text, View} from '@react-pdf/renderer'
import React from 'react'
import styles from '../style-sheet/Styles'
import I18n from '../../i18n/I18n'
import {truncateDecimal} from '../../helpers/Helper'
import PropTypes from 'prop-types'

const DataQualityV2 = ({landlordCompleteness, tenantCompleteness}) => {
  return (
    <View style={[styles.assets, styles.borderExceptBottom, styles.secondRowUtilityLarge,styles.left410]}>
      <Text style={[styles.title,styles.topM17]}>{I18n('t_data_quality')}</Text>
      <View style={styles.dqLandlord}>
        <Text style={[styles.assetTextSeven, styles.blackColor,styles.mTM2]}>{I18n('t_landlord_procured')}</Text>
        <View style={[styles.tableRow]}>
          <View style={styles.dqLeft}>
            <Text style={[styles.assetTextEightBold,styles.mT0]}>
              {landlordCompleteness?.actualPercentage ? truncateDecimal(landlordCompleteness.actualPercentage * 100, 1)+' %' : '-'}
            </Text>
            <Text style={[styles.assetTextSeven, styles.greyColor,styles.mT0]}>{I18n('t_actual')}</Text>
          </View>
          <View style={styles.dqRight}>
            <Text style={[styles.assetTextEightBold,styles.mT0]}>
              {landlordCompleteness?.supplierEstimatePercentage ? truncateDecimal((landlordCompleteness.supplierEstimatePercentage) * 100, 1)+' %' : '-'}
            </Text>
            <Text style={[styles.assetTextSeven, styles.greyColor,styles.mT0]}>{I18n('t_supplier_estimate')}</Text>
          </View>
        </View>
        <View style={[styles.tableRow,styles.mT0]}>
          <View style={styles.dqLeft}>
            <Text style={[styles.assetTextEightBold,styles.mT0]}>
              {landlordCompleteness?.calculatedEstimatePercentage ? truncateDecimal((landlordCompleteness.calculatedEstimatePercentage) * 100, 1)+' %' : '-'}
            </Text>
            <Text style={[styles.assetTextSeven, styles.greyColor,styles.mT0]}>{I18n('t_calculated_estimate')}</Text>
          </View>
          <View style={styles.dqRight}>
            <Text style={[styles.assetTextEightBold,styles.mT0]}>
              {landlordCompleteness?.missingPercentage ? truncateDecimal(landlordCompleteness.missingPercentage * 100, 1)+' %' : '-'}
            </Text>
            <Text style={[styles.assetTextSeven, styles.greyColor,styles.mT0]}>{I18n('t_missing_tc')}</Text>
          </View>
        </View>
      </View>
      <View style={[styles.dqTenant]}>
        <Text style={[styles.dqLabel, styles.blackColor]}>{I18n('t_tenant_procured')}</Text>
        <View style={[styles.tableRow]}>
          <View style={styles.dqLeft}>
            <Text style={[styles.assetTextEightBold,styles.mT0]}>
              {tenantCompleteness?.actualPercentage ? truncateDecimal(tenantCompleteness.actualPercentage * 100, 1)+' %' : '-'}
            </Text>
            <Text style={[styles.assetTextSeven, styles.greyColor,styles.mT0]}>{I18n('t_actual')}</Text>
          </View>
          <View style={styles.dqRight}>
            <Text style={[styles.assetTextEightBold,styles.mT0]}>
              {tenantCompleteness?.supplierEstimatePercentage ? truncateDecimal((tenantCompleteness.supplierEstimatePercentage) * 100, 1)+' %' : '-'}
            </Text>
            <Text style={[styles.assetTextSeven, styles.greyColor,styles.mT0]}>{I18n('t_supplier_estimate')}</Text>
          </View>
        </View>
        <View style={[styles.tableRow,styles.mT0]}>
          <View style={styles.dqLeft}>
            <Text style={[styles.assetTextEightBold,styles.mT0]}>
              {tenantCompleteness?.calculatedEstimatePercentage ? truncateDecimal((tenantCompleteness.calculatedEstimatePercentage) * 100, 1)+' %' : '-'}
            </Text>
            <Text style={[styles.assetTextSeven, styles.greyColor,styles.mT0]}>{I18n('t_calculated_estimate')}</Text>
          </View>
          <View style={styles.dqRight}>
            <Text style={[styles.assetTextEightBold,styles.mT0]}>
              {tenantCompleteness?.missingPercentage ? truncateDecimal(tenantCompleteness.missingPercentage * 100, 1)+' %' : '-'}
            </Text>
            <Text style={[styles.assetTextSeven, styles.greyColor,styles.mT0]}>{I18n('t_missing_tc')}</Text>
          </View>
        </View>
      </View>
    </View>
  )
}

DataQualityV2.propTypes = {
  landlordCompleteness: PropTypes.shape({
    actualPercentage: PropTypes.number,
    calculatedEstimatePercentage: PropTypes.number,
    missingPercentage: PropTypes.number,
    supplierEstimatePercentage: PropTypes.number,
  }),
  tenantCompleteness: PropTypes.shape({
    actualPercentage: PropTypes.number,
    calculatedEstimatePercentage: PropTypes.number,
    missingPercentage: PropTypes.number,
    supplierEstimatePercentage: PropTypes.number,
  })
}

export default DataQualityV2