import React, {useEffect, useState} from 'react'
import {GetUrlParams} from '../../services/login-service'
import {CachedUserState} from '../helpers/Helper'
import {LOCAL_STORAGE_KEYS} from '../helpers/Constants'

const useFundUrlValidator = ({matchRoute, instanceName, orgName, fundName, assetName, assetId, actionId}) => {
  const urlParamsRequest = {instanceName, orgName, fundName, assetName, assetId, actionId}
  const [urlValidated, setUrlValidated] = useState(false)
  const [isUrlValid, setIsUrlValid] = useState(false)

  useEffect(() => {
    const cacheUrlParamsIds=CachedUserState.getUserDataFromCache(LOCAL_STORAGE_KEYS.cacheUrlParamsIdsData)
    const urlParamsObject={cacheUrlParamsIds, instanceName, orgName, fundName, assetId, actionId}
    const {urlParamsExist, newUrlParamsIds}=checkIfUrlParamsExistInCache(urlParamsObject)

    // this is never triggering to true
    if(urlParamsExist){
      const {path, url}=getPathAndUrl(matchRoute, newUrlParamsIds)
      if (path === url) {
        setIsUrlValid(true)
      }
      setUrlValidated(true)
    } else {
      //  'urlParamsRequest = /assets
      GetUrlParams(urlParamsRequest).then(urlParameterIds => {
        const {path, url}=getPathAndUrl(matchRoute, urlParameterIds)
        
        if (path === url) {
          setIsUrlValid(true)
        } else {
          console.warn(`SIERA. useFundUrlValidator(): URL validation failed:
- Client URL: ${url}
- Server URL: ${path}
On the browser dev tools check the call to the '/UrlParameterIds' endpoint to see which parameters were sent and received. Is the user in the same instance than the fund or asset requested?`)
        }
        setUrlValidated(true)
      }).catch((error) => {
        console.log(error)
        setUrlValidated(true)
      })
    }
  }, [])

  function checkIfUrlParamsExistInCache({cacheUrlParamsIds, instanceName, orgName, fundName, assetId, actionId}) {
    let urlParamsExist = false
    let newUrlParamsIds={
      'instanceName': null,
      'instanceId': null,
      'organisationName': null,
      'organisationId': null,
      'fundName': null,
      'fundGroupId': null,
      'fundId': null,
      'assetName': null,
      'assetId': null,
      'actionId': null
    }
    if(instanceName && orgName && fundName && assetId && actionId) {
      urlParamsExist = (instanceName === (cacheUrlParamsIds?.instanceName)) && (orgName === (cacheUrlParamsIds?.organisationName)) &&
          (fundName === (cacheUrlParamsIds?.fundName)) && (assetId === (cacheUrlParamsIds?.assetId)) && (actionId === (cacheUrlParamsIds?.actionId))
      if(urlParamsExist){
        newUrlParamsIds={...newUrlParamsIds, instanceName, organisationName:orgName, fundName, assetId}
      }
    } else if(instanceName && orgName && fundName && assetId) {
      urlParamsExist = (instanceName === (cacheUrlParamsIds?.instanceName)) && (orgName === (cacheUrlParamsIds?.organisationName)) &&
        (fundName === (cacheUrlParamsIds?.fundName)) && (assetId === (cacheUrlParamsIds?.assetId))
      if(urlParamsExist){
        newUrlParamsIds={...newUrlParamsIds, instanceName, organisationName:orgName, fundName, assetId}
      }
    } else if(instanceName && orgName && fundName) {
      urlParamsExist = (instanceName === (cacheUrlParamsIds?.instanceName)) && (orgName === (cacheUrlParamsIds?.organisationName)) &&
        (fundName === (cacheUrlParamsIds?.fundName))
      if(urlParamsExist){
        newUrlParamsIds={...newUrlParamsIds, instanceName, organisationName:orgName, fundName}
      }
    } else if(instanceName && orgName) {
      urlParamsExist = (instanceName === (cacheUrlParamsIds?.instanceName)) && (orgName === (cacheUrlParamsIds?.organisationName))
      if(urlParamsExist){
        newUrlParamsIds={...newUrlParamsIds, instanceName, organisationName:orgName}
      }
    } else if(instanceName) {
      urlParamsExist = (instanceName === (cacheUrlParamsIds?.instanceName))
      if(urlParamsExist){
        newUrlParamsIds={...newUrlParamsIds, instanceName}
      }
    }
    return {urlParamsExist, newUrlParamsIds}
  }

  function getPathAndUrl(matchRoute, urlParameterIds) {
    let path = matchRoute.path
    let url = matchRoute.url
    path = path
      .replace(':instanceName', urlParameterIds?.instanceName)
      .replace(':orgName', urlParameterIds?.organisationName)
      .replace(':fundName', urlParameterIds?.fundName)
      .replace(':assetId', urlParameterIds?.assetId)
      .replace(':actionId', urlParameterIds?.actionId)
    path = path?.toLowerCase()
    url = url?.toLowerCase()

    return {path, url}
  }

  return({urlValidated, isUrlValid})
}

export default useFundUrlValidator