import {Page, View, Document, Text, Image} from '@react-pdf/renderer'
import React, {} from 'react'
import PdfLogo from '../../../../../utils/data-exports/fixed-components/logo/PdfLogo'
import PdfPagination from '../../../../../utils/data-exports/fixed-components/footer/PdfPagination'
import PdfHeader from '../../../../../utils/data-exports/fixed-components/header/PdfHeader'
import styles from '../../../../../utils/data-exports/style-sheet/Styles'
import I18n from '../../../../../utils/i18n/I18n'
import {I18nProvider} from '../../../../../utils/i18n'
import {eventDuration} from '../../../../../utils/helpers/Constants'

const MeterPdf = (props) => {

  return (
    <Document>
      <I18nProvider locale={props.language}>
        <Page size="A4" object-fit="fill" style={styles.page}>
          <View>
            <View style={styles.tableRow} fixed>
              <View>
                <PdfLogo header={I18n('t_esg_data_quality')}/>
              </View>
              <View>
                <PdfHeader asset={props.asset} language={props.language}/>
              </View>
            </View>
            <View style={[styles.title,styles.tableRow]} fixed>
              <View style={{display: 'flex', flexDirection: 'row', width: '100%', marginTop: 30, marginBottom: 10}}>
                <Text>{I18n('t_req_utility')}: {I18n('t_' + props.utility.toLowerCase())} {' '} | {' '}
                  {I18n('t_area')}: {I18n('t_' +props.area.toLowerCase())}</Text>
                <Text style={{marginLeft: 'auto'}}>{I18n('t_date_range')}: {eventDuration(props.period,props.language)}</Text>
              </View>
            </View>
            <Image src={props.meterSrc} style={styles.dqMeterImg}/>
            <PdfPagination/>
          </View>
        </Page>
      </I18nProvider>
    </Document>
  )
}

export default MeterPdf