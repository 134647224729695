import React, {useRef, useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {SelectButton} from 'primereact/selectbutton'
import { Tooltip } from 'primereact/tooltip'
import {Dropdown} from 'primereact/dropdown'

import './DatePicker.scss'
import DateIcon from '../../../resources/images/icon/date.svg'
import DateForest from '../../../resources/images/icon/calendar-forest.svg'
import I18n from '../../../utils/i18n/I18n'
import {monthListShortWithLocale, shortMonths, dataPickerStartYear} from '../../../utils/helpers/Constants'
import {savePickerDateInLS} from '../../../utils/helpers/Helper'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const DatePicker = (props) => {
  const pickerValue = props.pickerValue
  const endMonths = ['1', '3', '6', '12']
  const yearsArray = Array.from(new Array(new Date().getFullYear() - (dataPickerStartYear - 1)), (val, index) => dataPickerStartYear + index)
  const years = yearsArray.map((year => year.toString().slice(-2)))

  const { loginState: { userInfo } } = useLoginAuthContext()
  const ref = useRef(null)
  const [dateActive, setDateActive] = useState(props.dateActive)
  const [dateHover, setDateHover] = useState()

  const formatter = new Intl.DateTimeFormat(userInfo.languagePreference, { month: 'short' })
  const currentMonth = formatter.format(new Date())

  const handleDatePicker = (e) => {
    setDateActive(!dateActive)
  }

  const handleChange = (e) => {
    props.onChange(e)
    setDateActive(undefined)
  }

  const handleDropdownChange = (e) => {
    e.stopPropagation()
    props.pickerOnChange(e)
  }

  useEffect(() => {
    if (dateActive === false) {
      props.customDatePickerOnClick()
    }
    const checkIfClickedOutside = e => {
      const disableForDropdown = document.querySelector('.p-dropdown-panel')
      if (dateActive && ref.current && !ref.current.contains(e.target)) {
        setDateActive(disableForDropdown ? true : false)
      }
    }
    document.addEventListener('click', checkIfClickedOutside)
    return () => {
      document.removeEventListener('click', checkIfClickedOutside)
    }
  }, [dateActive])

  useEffect(() => {
    savePickerDateInLS(props.value, props.pickerValue)
  }, [props.value, props.pickerValue])

  const dateDescClassName = pickerValue.pickerValueMonths === '1' ? 'custom-picker-value one-picker-value' : pickerValue.pickerValueMonths === '3' || pickerValue.pickerValueMonths === '6' ? 'custom-picker-value three-six-single-picker-value' : 'custom-picker-value'

  return (
    <div ref={ref} className="date-picker-filter">
      {props.value === 'CUS' && dateActive!==true ? (
        <>
          <span className={dateDescClassName}>{I18n(pickerValue.pickerValueMonths === '1' ? 't_picker_filter_value_single_month' : 't_picker_filter_value', {
            0: pickerValue.pickerValueMonths,
            1: monthListShortWithLocale[pickerValue.pickerValueEndMonth],
            2: pickerValue.pickerValueEndYear
          })}
          </span>
          <div className="border-right border-right-custom" />
          <div className="date-icon">
            <img onClick={handleDatePicker} aria-haspopup aria-controls="overlay_panel"
              className={'datepicker-icon active-datepicker'} src={DateForest} alt={'dateIcon'}
            />
          </div>
        </>
      ) : (
        <>
          <Tooltip className={props.tooltipClassName} target="tooltipTarget">{props.tooltipValue}</Tooltip>
          <SelectButton
            className={props.selectButtonClassName}
            value={dateActive === true ? '' : props.value}
            options={props.options}
            onChange={(e) => handleChange(e)}
            itemTemplate={props.itemTemplate}
          />
          <div className="date-icon">
            <img onClick={handleDatePicker} aria-haspopup aria-controls="overlay_panel"
              className={'datepicker-icon'} src={(dateActive === true) || (dateHover === true) ? DateForest : DateIcon} alt={'dateIcon'}
              onMouseEnter={() => setDateHover(true)}
              onMouseLeave={() => setDateHover(false)}
            />
          </div>
        </>
      )}
      {dateActive && (
        <div className="pick-date-overlay">
          <div className="no-padding picker-dropdowns-root">
            <p className="picker-label">{I18n('t_show_me')}:</p>
            <Dropdown
              options={endMonths}
              dropdownIcon={'pi pi-caret-down'}
              value={pickerValue.pickerValueMonths}
              className="picker-dropdown picker-dropdown-1"
              panelClassName="picker-overlay day-dropdown"
              onChange={(e) => handleDropdownChange(e)}
              name="pickerValueMonths"
            />
            <span className="month-label">{pickerValue.pickerValueMonths === '1' ? I18n('t_month') : I18n('t_months')}</span>
          </div>
          <div className="no-padding picker-dropdowns-root">
            <p className="picker-label">{I18n('t_end_of')}:</p>
            <div style={{display: 'flex'}} className="col-12 no-padding">
              <Dropdown
                data-testid="month-picker"
                options={shortMonths}
                dropdownIcon={'pi pi-caret-down'}
                value={pickerValue.pickerValueEndMonth}
                panelClassName="picker-overlay"
                className="picker-dropdown"
                onChange={(e) => handleDropdownChange(e)}
                name="pickerValueEndMonth"
                placeholder={currentMonth}
              />
              <Dropdown
                data-testid="year-picker"
                options={years.reverse()}
                dropdownIcon={'pi pi-caret-down'}
                value={pickerValue.pickerValueEndYear}
                panelClassName="picker-overlay year-dropdown"
                className="picker-dropdown"
                onChange={(e) => handleDropdownChange(e)}
                name="pickerValueEndYear"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default DatePicker

DatePicker.propTypes = {
  value: PropTypes.string,
  pickerValue: PropTypes.object
}