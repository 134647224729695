import React from 'react'
import './AssetFooter.scss'
import {Button} from 'primereact/button'
import {useHistory} from 'react-router-dom'
import I18n from '../../../../../utils/i18n/I18n'


const AssetFooter = (props) => {
  const navigate  = useHistory()

  function closeAddAsset() {
    navigate.goBack()
  }

  return (
    <div className={'asset-footer'}>
      <div className={'footer-buttons-container p-grid'}>
        <div className={'buttons-container p-col-4'}>
          <div className={'buttons-save-next'}>
            <div className={'button-container container-save'}>
              <Button type="submit" data-testid="save-exit" className={'button-save'} onClick={props.submitHandler}>{I18n('t_save_exit')}</Button>
            </div>
            <div className={'button-container container-next'}>
              <Button type="submit" data-testid="next-button" onClick={props.handleNextTab} className={'button-next'}>{I18n(props.handleNextTabBtnText)}</Button>
            </div>
          </div>
        </div>
        <div className={'button-cancel-container p-col-8'}>
          <Button onClick={closeAddAsset} className={'action-cancel'} data-testid="cancel-button">Cancel</Button>
        </div>
      </div>
    </div>
  )
}

export default AssetFooter