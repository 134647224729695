import axiosInstance from '../common/axios-config'

export const getAddAssetFormDetails = async () => {
  const {data} = await axiosInstance.get('api/v1/Assets/AssetFormDetails')
  return data
}

export const GetAddAssetLocationFilterDetails = async (keys) => {
  const countryId = keys?.queryKey[1]
  if (countryId) {
    const {data} = await axiosInstance.get(`api/v1/Assets/LocationFilter?selectedCountryId=${countryId}`)
    return data
  }
}

export const GetAssetFunds = async (keys) => {
  const assetId = keys?.queryKey[1]
  if (assetId) {
    const {data} = await axiosInstance.get(`api/v1/Assets/${assetId}/AssetFunds`)
    return data
  }
}