import React, {useState} from 'react'
import './ConsumptionUploadTemplate.scss'
import I18n from '../../../../utils/i18n/I18n'
import {Button} from 'primereact/button'
import CommonDialog from '../../../common/modal-dialogs/ModalDialogCommon'
import UploadTemplate from '../consumption-upload-oneschema/ConsumptionUploadOneSchema'
import GenerateTemplate from '../consumption-generate-template/consumption-generate-template-modal/ConsumptionGenerateTemplate'
import {bulkUpload, consumptionUpload, oneSchemaTemplate, topDownPathways} from '../../../../utils/helpers/Constants'
import { getLocalizedValue } from '../../../../utils/helpers/Helper'
import useErrorHandling from '../../../common/error-handling/ErrorHandling'
import {
  generateTopDownPathwaysTemplate
} from '../../funds/net-zero-carbon-home/nzc-target-pathway/nzc-top-down-pathways/GenerateTopDownPathwaysTemplateHelper'
import PropTypes from 'prop-types'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const ConsumptionUploadTemplate = (props) => {
  const { loginState: { userInfo } } = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const [consumptionModal, setConsumptionModal] = useState('')
  const generateTemplate = 'generateTemplate'
  const uploadTemplate = 'uploadTemplate'
  const { typeOfUpload, routeVia, history } = props
  const { errorDialog, ShowErrorDialog, errorDialogVisible } = useErrorHandling()

  function handleConsumptionModal(action, isGenerateTemplate) {
    getTemplateKey() === 'fund_nzc_top_down_pathways' && isGenerateTemplate ? generateTopDownPathwaysTemplate({routeVia, history, selectedLanguage} ) : setConsumptionModal(action)
  }

  function hideConsumptionModal(message) {
    setConsumptionModal('')
    if(message && message.title === 'error'){
      errorDialog({title:I18n('t_error_title'),message: message.info})
    }
  }

  function getTemplateKey(){
    switch(typeOfUpload){
    case consumptionUpload:
      return oneSchemaTemplate.consumptionUpload
    case 'action-upload':
      return oneSchemaTemplate.assetActionPlans
    case topDownPathways:
      return oneSchemaTemplate.fundNzcTopDownPathways
    default:
      return oneSchemaTemplate.consumptionUpload
    }
  }

  return (
    <div className={'consumption-template white-bg'}>
      <div className={'mb-4'}>
        <div className={'m-0 mb-4 d-grid'}>
          <span className={'consumption-template__title'}>
            1. {I18n(bulkUpload[typeOfUpload]?.pointOne)}
          </span>
          <span className={'consumption-template__title--sub-text'}>
            {I18n(bulkUpload[typeOfUpload]?.pointOneSubtext)}
          </span>
        </div>
        <Button
          className={'consumption-template__generate-btn grey-bordered-whit-bg-btn p-3'}
          label={getLocalizedValue(selectedLanguage, 't_download_template')}
          onClick={() => handleConsumptionModal(generateTemplate, true)}/>
      </div>
      <div>
        <div className={'m-0 mb-4 d-grid'}>
          <span className={'consumption-template__title'}>
            2. {I18n(bulkUpload[typeOfUpload]?.pointTwo)}
          </span>
          <span className={'consumption-template__title--sub-text'}>
            {I18n(bulkUpload[typeOfUpload]?.pointTwoSubtext)}
          </span>
        </div>
        <Button
          className={'consumption-template__schema-btn common-blue-bg-btn p-3'}
          label={getLocalizedValue(selectedLanguage, bulkUpload[typeOfUpload]?.buttonName)}
          onClick={() => handleConsumptionModal(uploadTemplate, false)}/>
      </div>
      <CommonDialog
        modal={true}
        style={{width: '1080px', height: 'fit-content'}}
        visible={consumptionModal === generateTemplate}
        onHide={hideConsumptionModal}
        header={I18n(typeOfUpload === 'action-upload' ? 't_generate_your_action_plan_template' : 't_generate_your_template')}
        children={<GenerateTemplate
          hideGenerateTemplate={hideConsumptionModal}
          history={props.history}
          typeOfUpload={typeOfUpload}
        />}
        className={'consumption-upload-modal scrollbar-track-hide'}
      />
      <UploadTemplate
        consumptionDialog={consumptionModal === uploadTemplate}
        hideConsumptionDialog={hideConsumptionModal}
        setUploadStatus={props.setUploadStatus}
        templateName={getTemplateKey()}
      />
      {errorDialogVisible && <ShowErrorDialog/>}
    </div>
  )
}

ConsumptionUploadTemplate.propTypes = {
  history: PropTypes.object,
  routeVia: PropTypes.string,
  typeOfUpload: PropTypes.string,
}
export default ConsumptionUploadTemplate
