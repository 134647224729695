import PropTypes from 'prop-types'

const StackedBarChartV3 = ({data, classNames}) => {

  return (
    <div className={classNames} data-testid={classNames}>
      <div className="chart-bars-wrapper-v3">
        {data.map(({value, colour}) => {
          return value > 0 ?
            <span key={`${colour}-bar-part`} className={`${colour} border-${value * 100}`} style={{width: `${value * 100}%`}} />
            :
            null
        })}
      </div>
    </div>
  )
}

StackedBarChartV3.propTypes = {
  data: PropTypes.array,
  classNames: PropTypes.string
}

StackedBarChartV3.displayName = 'StackedBarChartV3'

export default StackedBarChartV3
