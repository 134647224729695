import React, {useState} from 'react'
import UserDetailsContainer from '../user-details-container/UserDetailsContainer'
import {UserActions} from '../../../../../utils/helpers/Constants'

const UserDetailsAdd = (props) => {
  const [data] = useState(props.location?.state)
  const dataNotEmpty=()=>data && data.userDetails

  const addUserConfigData={
    headerTitle: dataNotEmpty()? data.userDetails.username : 't_add_user',
    userDetails: dataNotEmpty()?data.userDetails:null,
    activeIndex: dataNotEmpty()? data.activeIndex:0,
    userAction: UserActions.ADD_USER,
    selectedAssets: dataNotEmpty()?data.selectedAssets: null
  }

  return(
    <div className='add-user-details-home' data-testid={'add-user-details'}>
      <UserDetailsContainer {...addUserConfigData} />
    </div>
  )
}

export default UserDetailsAdd
