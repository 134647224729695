import PropTypes from 'prop-types'
import './DataQualityMeterView.scss'
import MeterGraph from './MeterGraph'
import MeterGraphMonthsLegend from './MeterGraphMonthsLegend'
import DataQualityMenuHeader from './DataQualityMenuHeader'
import { Icon, Text } from 'components/atomic/index'
import IndividualMetersMonthLabelsSkeleton from 'components/common/skeletons/individual-meters-month-labels-skeleton/IndividualMetersMonthLabelsSkeleton'
import IndividualMetersChartSkeleton from 'components/common/skeletons/individual-meters-chart-skeleton/IndividualMetersChartSkeleton'
import { useEffect, useState } from 'react'

const DataQualityMeterView = ({
  meterUtility, 
  meterArea, 
  updateDataQualityState, 
  selectedLanguage, 
  assetMetersDetails,
  assetMetersDetailsLoading,
  onEnterDataHandler,
  sendDataHandler
}) => {
  const [searchValue, setSearchValue] = useState('')
  const [filteredMeters, setFilteredMeters] = useState([])

  useEffect(() => {
    if(searchValue !== '') {
      const filteredMeters = assetMetersDetails.filter(meter => meter.meterName.toLowerCase().includes(searchValue.toLowerCase()))
      setFilteredMeters(filteredMeters)
    } else {
      setFilteredMeters(assetMetersDetails)
    }
  }, [assetMetersDetails, searchValue])

  function getMeterSGraphs() {
    return filteredMeters.map((meterData, index) => {
      return (
        <div className="qq-meter-view__content__graph" key={index}>
          <MeterGraph 
            meterData={meterData} 
            onEnterDataHandler={onEnterDataHandler}
            sendDataHandler={sendDataHandler}
          />
        </div>
      )
    })
  }

  function getCompletenessLegend() {
    const completenessList = [
      {key: 't_actual_data_legend', colour: 'forest'},
      {key: 't_supplier_estimate', colour: 'green_light'},
      {key: 't_calculated_estimate', colour: 'sand'},
      {key: 't_missing_tc', colour: 'orange'}
    ]
  
    return (
      completenessList.map((completeness, index) => (
        <div key={index} className='flex align-items-center gap-2'>
          <Icon colour={completeness.colour} size={10} id='square_solid' />
          <Text content={completeness.key} size="xs" />
        </div>
      ))
    )
  }

  const renderLoadingSkeleton = () => (
    <>
      <IndividualMetersMonthLabelsSkeleton />
      <IndividualMetersChartSkeleton />
      <IndividualMetersChartSkeleton />
      <IndividualMetersChartSkeleton />
    </>
  )

  const renderMeterContent = () => (
    <>
      <div className="qq-meter-view__content__header">
        <MeterGraphMonthsLegend meterData={assetMetersDetails[0]} />
      </div>
      {getMeterSGraphs()}
      <div className="qq-meter-view__content__legends">
        {getCompletenessLegend()}
      </div>
    </>
  )

  const renderContent = () => {
    if (assetMetersDetailsLoading) {
      return renderLoadingSkeleton()
    }
    if (filteredMeters && filteredMeters.length > 0) {
      return renderMeterContent()
    }
    return <Text content="t_msg_no_data" size='s' />
  }

  const searchMetersHandler = (e) => {
    setSearchValue(e.target.value)
  }

  const clearSearchField = () => {
    setSearchValue('')
  }

  return (
    <div className="qq-meter-view">
      <DataQualityMenuHeader
        meterUtility={meterUtility}
        meterArea={meterArea}
        updateDataQualityState={updateDataQualityState}
        selectedLanguage={selectedLanguage}
        searchMetersHandler={searchMetersHandler}
        clearSearchField={clearSearchField}
        searchValue={searchValue}
      />
      <div className="qq-meter-view__content">
        {renderContent()}
      </div>
    </div>
  )
}

DataQualityMeterView.propTypes = {
  meterUtility: PropTypes.string,
  meterArea: PropTypes.string,
  updateDataQualityState: PropTypes.func,
  selectedLanguage: PropTypes.string,
  assetMetersDetails: PropTypes.array,
  assetMetersDetailsLoading: PropTypes.bool,
  onEnterDataHandler: PropTypes.func,
  sendDataHandler: PropTypes.func
}
DataQualityMeterView.displayName = 'DataQualityMeterView'

export default DataQualityMeterView