import {Skeleton} from 'primereact/skeleton'

const ActionPlanTileSkeleton = () => {
  return (
    <div className="action-tile-skeleton">
      <Skeleton width="77px" borderRadius="0" height="1rem" />
      <Skeleton width="117px" borderRadius="0" height="1rem" className="mt-3"/>
      <div className="flex mt-3">
        <Skeleton width="101px" borderRadius="0" height="1rem" className="mr-3"/>
        <Skeleton width="101px" borderRadius="0" height="1rem" className="mr-3"/>
        <Skeleton width="101px" borderRadius="0" height="1rem" className="mr-3"/>
        <Skeleton width="101px" borderRadius="0" height="1rem" className="mr-3"/>
        <Skeleton width="101px" borderRadius="0" height="1rem" />
      </div>
    </div>
  )
}
export default ActionPlanTileSkeleton