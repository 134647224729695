import UserDetailsContainer from '../user-details-container/UserDetailsContainer'
import {UserActions} from '../../../../../utils/helpers/Constants'

const UserDetailsEdit = (props) => {
  const data=props.location?.state

  const editUserConfigData={
    headerTitle: 't_edit_user',
    userDetails: data && data.userDetails ? data : {},
    activeIndex: data && data.activeIndex ? data.activeIndex : 0, //Active index will be present only for user groups and asset edit
    userAction: UserActions.EDIT_USER
  }

  return(
    <div className='edit-user-home' data-testid={'edit-user-home'}>
      <UserDetailsContainer {...editUserConfigData} />
    </div>
  )
}

export default UserDetailsEdit
