import exclamationIcon from '../../../../../resources/images/icon/exclamation-orange.svg'
import downArrowRedIcon from '../../../../../resources/images/chart/down-arrow-red.svg'
import downArrowGreenIcon from '../../../../../resources/images/chart/down-arrow-green.svg'
import upArrowGreenIcon from '../../../../../resources/images/chart/up-arrow-green.svg'
import upArrowRedIcon from '../../../../../resources/images/chart/up-arrow-red.svg'
import I18n from '../../../../../utils/i18n/I18n'
import closeGray from '../../../../../resources/images/icon/close-gray.svg'
import './FundPerformanceSummary.scss'
import React, {useRef} from 'react'
import {Skeleton} from 'primereact/skeleton'
import PropTypes from 'prop-types'
import {roundAndFormatNumberWODecimal, formatPercentage, roundBaseTen, roundAndFormattedVarianceWithOneDP} from '../../../../../services/common/formatter'
import {getLocalizedValue, bigNumberFormatter, CurrencyUnitToLabel, getDivisor} from '../../../../../utils/helpers/Helper'
import {getSuffix} from '../FundPerformanceUtilitySuffixes'
import {costMask, GAP_FILLING, gapFillLearnMore, UTILITY_LABELS_OBJECT_TITLE_CASE, UtilityTypes} from '../../../../../utils/helpers/Constants'
import { OverlayPanel } from 'primereact/overlaypanel'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const FundPerformanceSummarySkeleton = () => {
  return <>
    <div className="fund-performance-summary">
      <div className="card">
        <span className="value">
          <Skeleton width="6rem" borderRadius="0" height="1.3rem"></Skeleton>
        </span>
      </div>
      <div className={'card no-border'}>
        <span className={'warning'}>
          <Skeleton width="6rem" borderRadius="0" height="1rem"></Skeleton>
        </span>
      </div>
      <div className={'card'}>
        <span className={'critical'}>
          <Skeleton width="6rem" borderRadius="0" height="1rem"></Skeleton>
        </span>
      </div>
      <div className="card no-border">
        <div className={'card-column'}>
          <Skeleton width="6rem" borderRadius="0" height="1.3rem"></Skeleton>
          <span className={'subtext'}>
            <Skeleton width="4rem" borderRadius="0" height="0.3rem" className={'mt-1'}></Skeleton>
          </span>
        </div>
      </div>
    </div>
  </>
}

const getColourCssClass = (selectedUtility, data) => {
  let className = ''
  let dataTwoDecimals = data && data.toFixed(3)

  if(dataTwoDecimals !== undefined) {
    className = dataTwoDecimals > 0 ? 'redValue' : (dataTwoDecimals < 0 ? 'greenValue' : 'value')
  }
  if(dataTwoDecimals === 0) {
    className = 'value'
  }
  if(selectedUtility === UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE) {
    className = dataTwoDecimals > 0 ? 'greenValue' : (dataTwoDecimals < 0 ? 'redValue' : 'value')
  }
  return className
}

const getCostSubtext = (data, selectedLanguage, hasTargetSet = false ) => {
  let costLabelText = ''
  if(data?.additionalValue !== null && data?.additionalValue !== undefined) {
    if(hasTargetSet) {
      costLabelText = data.additionalValue.toFixed(3) >= 0 ? getLocalizedValue(selectedLanguage, 't_estimated_overspend').toLowerCase() : getLocalizedValue(selectedLanguage, 't_estimated_saving')
    }
    else {
      costLabelText = data.additionalValue.toFixed(3) >= 0 ? getLocalizedValue(selectedLanguage, 't_funds_performance_cost_higher') : getLocalizedValue(selectedLanguage,'t_funds_performance_cost_lower')
    }
  } else {
    costLabelText = getLocalizedValue(selectedLanguage, 't_no_data_for_previous_period')
  }
  return costLabelText
}


export const getVarianceString = (variance,selectedLanguage) => {
  return roundAndFormattedVarianceWithOneDP(Math.abs(variance*100)).toLocaleString(selectedLanguage)
}

export const getHasNumberToString = (value,selectedLanguage) => {
  if(value && !isNaN(value)){
    return formatPercentage(selectedLanguage, value, 1)
  }
  return ' - '
}
export const getTotalRecycledPercentage = (data,selectedLanguage) => {
  let value = data?.totalRecycledPercentage
  return formatPercentage(selectedLanguage, value)
}

export const getPerformanceConsumption = (data,selectedLanguage,selectedUtility, isGapFilling ) => {
  let value
  if(selectedUtility === UtilityTypes.Waste){
    value=data.value // totalWaste
  } else {
    value = isGapFilling ? data?.estimatedTotalConsumption + data?.actualTotalConsumption : data?.actualTotalConsumption
  }
  if(value !== null) {
    let divisor = getDivisor(selectedUtility, value)
    if (selectedUtility !== UtilityTypes.Water) {
      value = value / divisor
    }
  }
  return value !== null ? roundAndFormatNumberWODecimal(selectedLanguage, value) : 0
}

const FundPerformanceSummary = ({data, isLoading, selectedLanguage, selectedUtility, selectedBreakdown, isGapFillingSelected}) => {

  const op = useRef(null)
  const {loginState: {userInfo}} = useLoginAuthContext()
  const currencyUnit = userInfo.currencyUnit
  const additionalValue = data?.additionalValue
  const additionalValueVariance = data?.additionalValueVariance
  const isGapFilling = selectedBreakdown?.map(option=>(option.key)).includes(GAP_FILLING)


  const getVariance = () => {
    let value = data?.variance
    if(!isGapFillingSelected && selectedUtility !== UtilityTypes.Waste && data){
      //gap-fill off
      //variance = [(currentActual - previousActual) / previousActual]
      const {previousValue: previousTotalEstimated, previousFilledData, actualTotalConsumption}=data
      const previousActualDataPercentage = 1 - previousFilledData
      const previousActualTotalConsumption = previousActualDataPercentage === 0 ? 0 : previousTotalEstimated * (previousActualDataPercentage)
      value = previousActualTotalConsumption === 0 ? 0: (actualTotalConsumption - previousActualTotalConsumption) / previousActualTotalConsumption
    }
    return value
  }

  const getVarianceSubText = (variance, hasTargetSet = false ) => {

    let targetLabelText = ''
    if(hasTargetSet && isGapFillingSelected) {
      targetLabelText = (variance !== null && variance !== undefined) ? variance.toFixed(3) >= 0 ? 't_above_target' : 't_below_target' : 't_no_data_for_previous_period'
    } else {
      targetLabelText = (variance !== null && variance !== undefined )? variance.toFixed(3) >= 0 ? 't_funds_performance_cost_higher' : 't_funds_performance_cost_lower': 't_no_data_for_previous_period'
    }

    if(selectedUtility === UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE) {
      targetLabelText = (variance !== null && variance !== undefined) ? variance.toFixed(3) >= 0 ? 't_more_recycled' : 't_less_recycled': 't_no_data_for_previous_period'
    }

    return getLocalizedValue(selectedLanguage, targetLabelText).toLowerCase()
  }

  const getTotalUtilityLabel = () => {
    let utilitySummaryLabel
    switch(selectedUtility) {
    case UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON:
      utilitySummaryLabel = getLocalizedValue(selectedLanguage, 't_total_carb_emissions')
      break
    case UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY:
      utilitySummaryLabel = getLocalizedValue(selectedLanguage, 't_total_elec_used')
      break
    case UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL:
      utilitySummaryLabel = getLocalizedValue(selectedLanguage, 't_total_gas_fuels_thermals')
      break
    case UTILITY_LABELS_OBJECT_TITLE_CASE.WATER:
      utilitySummaryLabel = getLocalizedValue(selectedLanguage, 't_total_water_used')
      break
    case UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE:
      utilitySummaryLabel = getLocalizedValue(selectedLanguage, 't_total_waste').toLowerCase()
      break
    case 'RecycledWaste':
      utilitySummaryLabel = getLocalizedValue(selectedLanguage, 't_total_recycled_waste')
      break
    default:
      utilitySummaryLabel = ''
    }
    return utilitySummaryLabel
  }

  const gapFilledDialogContent = () => {
    return (
      <div className={'gap-filled-dialog-content'}>
        <img onClick={(e) => op.current.hide(e)} src={closeGray} alt={'close-help'} className={'gap-filled-dialog-content__close-button'} />
        <>{I18n('t_gap_filled_data_dialog_content', { p: msg => ( <>{msg}</> ) } )}</><br />
        <a className={'gap-filled-dialog-content__gap-filled-learner'} href={gapFillLearnMore} target="_blank" rel="noreferrer noopener">
          {I18n('t_learn_more')}<i className="p-2 pi pi-external-link"></i>
        </a>
      </div>
    )
  }

  if(isLoading || !data){
    return <FundPerformanceSummarySkeleton/>
  }

  if(data.value === 0 && data.filledData === 0 && data.variance === 0 && (additionalValue === 0 || additionalValue === null )&& (additionalValueVariance === 0 || additionalValueVariance === null)){
    return <div className='fund-performance-summary'><span className='no-data-section'>{I18n('t_nodata_for_timeperiod')}</span></div>
  }
  
  const getGapFilledContent = () => {
    return  (isGapFilling &&
        <div className={'card gap-filled-summary flex-column'} data-testid={'gap-filled-icon'}>
          <span className={'value flex-row'}>
            { data?.filledData !== null ?
              <>
                <img src={exclamationIcon} height={'24x'} width="24px" className="icon" alt={'exclamation icon'}/>
                <span> {formatPercentage(selectedLanguage, data?.filledData)}%</span>
              </> :
              '-'
            }
          </span>
          <span onClick={(e) => op.current.toggle(e)} id='gap-filled-dialog' className="card-sub-title underline">{I18n('t_gap_filled_data')}</span>
          <div style={{ position: 'relative'}}>
            <OverlayPanel appendTo={document.querySelector('gap-filled-dialog')} className="fund-perf-scope-info" ref={op} position="bottom">
              {gapFilledDialogContent()}
            </OverlayPanel>
          </div>
        </div>)
  }

  const getGapFilledContentForWaste = () => {
    return <div className={'card gap-filled-summary flex-column'} data-testid={'gap-filled-icon'}>
      <span className={'value flex-row'}>
        <span>
          {data?.totalRecycled !== null ?
            <>
              {roundAndFormatNumberWODecimal(selectedLanguage, data?.totalRecycled)}<span className="value-units">t</span>
            </> :
            '-'
          }
        </span>
      </span>
      <span className="card-sub-title">{getLocalizedValue(selectedLanguage, 't_recycledwaste').toLowerCase() }</span>
    </div>
  }

  return <>
    <div className="fund-performance-summary">
      <div className="card total-consumption-summary flex-column">
        <span className="value">
          {getPerformanceConsumption(data,selectedLanguage,selectedUtility, isGapFilling)}
          <span className="value-units">
            {isGapFilling ?
              getSuffix(selectedUtility, data?.estimatedTotalConsumption + data?.actualTotalConsumption)
              :
              getSuffix(selectedUtility,  data?.actualTotalConsumption)}
          </span>
        </span>
        <span className="card-sub-title">{getTotalUtilityLabel()}</span>
      </div>
      
      {selectedUtility !== UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE ? getGapFilledContent() : getGapFilledContentForWaste()}

      <div className={'card variance-percentage-summary flex-column'}>
        <span className={'value flex-row'} data-testid={'variance-filled-icon'}>
          {selectedUtility !== UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE ?
            <>
              {
                getVariance() !== null && getVariance() !== undefined ?
                  <>
                    {getVariance().toFixed(3) > 0 ?
                      <img src={upArrowRedIcon} className={'icon icon-22'} alt={'Thumbs down icon'} />
                      :
                      getVariance().toFixed(3) < 0 ?
                        <img src={downArrowGreenIcon} className={'icon icon-22'} alt={'Applause icon'} />
                        :
                        null
                    }
                    <span className={getColourCssClass(selectedUtility, getVariance())}>{getVarianceString(getVariance(), selectedLanguage)?.replace('-', '')}%</span>
                  </>
                  :
                  '-'
              }
            </> :
            <span>
              {
                getTotalRecycledPercentage(data,selectedLanguage) !== null ?
                  `${getTotalRecycledPercentage(data,selectedLanguage)}%`
                  :
                  '-'
              }
            </span>
          }
        </span>
        <span className="card-sub-title">
          {selectedUtility === UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE ?
            getLocalizedValue(selectedLanguage, 't_recycled').toLowerCase()
            :
            getVarianceSubText(getVariance(), data?.targetCalculatedAgainstPreviousPeriod)
          }
        </span>
      </div>

      {selectedUtility === UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE &&
            <div className={'card variance-cost-summary flex-column'}>
              <span className={'value flex-row'}>
                {
                  getVariance() !== null && getVariance() !== undefined ?
                    <>
                      {getVariance().toFixed(3) > 0 ?
                        <img src={upArrowGreenIcon} className={'icon icon-22'} alt={'Applause iconThumbs down icon'}/>
                        :
                        getVariance().toFixed(3) < 0 ?
                          <img src={downArrowRedIcon} className={'icon icon-22'} alt={'Thumbs down icon'}/>
                          :
                          null
                      }
                      <span className={getColourCssClass(selectedUtility, getVariance())}>{getVarianceString(getVariance(), selectedLanguage).replace('-', '')}%</span>
                    </> :
                    '-'
                }
              </span>
              <span className="card-sub-title">
                {getVarianceSubText(getVariance(), data?.targetCalculatedAgainstPreviousPeriod)}
              </span>
            </div>
      }
      {data?.utility !== UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE && data?.utility !== UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON ?
        <div className="card no-border flex-column">
          <span className={'value flex-row'}>
            {
              data?.additionalValue !== null ?
                <>
                  <span data-testid={'currency'} className={getColourCssClass(selectedUtility, data?.additionalValue)}>
                    {costMask[CurrencyUnitToLabel(currencyUnit)]}{bigNumberFormatter(roundBaseTen(Math.abs(data?.additionalValue)))}
                  </span>
                </> :
                '-'
            }
          </span>

          <span className="card-sub-title">
            {getCostSubtext(data, selectedLanguage, data?.targetCalculatedAgainstPreviousPeriod)}
          </span>
        </div>
        : null
      }
    </div>
  </>
}

FundPerformanceSummary.propTypes = {
  data: PropTypes.shape({
    fundGroupId: PropTypes.number,
    utility: PropTypes.string,
    value: PropTypes.number,
    units: PropTypes.string,
    filledData: PropTypes.number,
    variance: PropTypes.number,
    additionalValue: PropTypes.number,
    additionalValueVariance: PropTypes.number,
    targetCalculatedAgainstPreviousPeriod: PropTypes.bool,
    estimatedTotalConsumption: PropTypes.number,
    actualTotalConsumption: PropTypes.number,
  }),
  isLoading: PropTypes.bool,
  selectedUtility: PropTypes.string,
  selectedLanguage: PropTypes.string,
  selectedBreakdown: PropTypes.array,
}

export default FundPerformanceSummary
