import React, {useState} from 'react'

const useAssetTimePeriodState = (stateInitialiser) => {
  const [timePeriodState, setTimePeriodState]=useState(stateInitialiser)

  function updateTimePeriodState(newState) {
    setTimePeriodState(previousState=>{
      return {
        ...previousState,
        ...newState
      }
    })
  }
  return {timePeriodState, updateTimePeriodState}
}

export default useAssetTimePeriodState