import PropTypes from 'prop-types'
import './IndividualMeterIndicator.scss'
import React, {useContext, useEffect, useState} from 'react'
import I18n from '../../../../../utils/i18n/I18n'
import {
  monthList,
  monthListShort
} from '../../../../../utils/helpers/Constants'
import IndividualMetersChartSkeleton from '../../../../common/skeletons/individual-meters-chart-skeleton/IndividualMetersChartSkeleton'
import IndividualMetersMonthLabelsSkeleton from '../../../../common/skeletons/individual-meters-month-labels-skeleton/IndividualMetersMonthLabelsSkeleton'
import {Dropdown} from 'primereact/dropdown'
import LegendTeal from '../../../../../resources/images/icon/legend-teal.svg'
import LegendYellow from '../../../../../resources/images/icon/legend-yellow.svg'
import LegendEstPdf from '../../../../../resources/images/icon/legend-est-pdf.svg'
import LegendMissingPdf from '../../../../../resources/images/icon/legend-missing-pdf.svg'
import LegendRed from '../../../../../resources/images/icon/legend-red.svg'
import { Tooltip } from 'primereact/tooltip'
import DqMeterModal from '../../data-quality/dq-meter-modal/DQMeterModal'
import {useMutation} from 'react-query'
import {addNewMissingDataRequestInternal, getDataGapRequestObj} from '../../../../../services/assets/dataquality-service'
import {FeatureFlagContext, PortfolioContext} from 'Contexts'
import ErrorDialog from '../../data-quality/error-dialog/ErrorDialog'
import RequestDataDialog from '../dialogs/RequestDataDialog'
import {getLocalizedValue, unBlockDialogScroll} from '../../../../../utils/helpers/Helper'
import { useParams } from 'react-router-dom'
import {
  getSelectAllIconNode,
  setSelectAllIcon,
} from '../../../../../utils/helpers/DataTableUtils'
import ConsumptionNavigator from '../../data-quality/consumption/ConsumptionNavigator'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

function IndividualMeterIndicator(props) {

  const {loginState: {userInfo}} = useLoginAuthContext()
  const unitSystem = userInfo.unitSystem
  const selectedLanguage = userInfo.languagePreference
  const featureContext = useContext(FeatureFlagContext)
  const featureState = featureContext.featureState?.sieraplus_consumptionOperations

  const missingDataList = (props?.missingDataWrapper?.data?.assetMeterDataQualitySummaries) ?
    props.missingDataWrapper.data.assetMeterDataQualitySummaries : null

  let fyStartMonth
  let qtrMonthMarker

  const ytdActiveClass='ytd-active'
  const commCssClass = 'grid-column border-tb-1p '
  const CLASS_METER_STICKY = 'meter-sticky'
  const CLASS_GRID_COLUMN='grid-column'
  const CLASS_SDROP_DOWN='sDropdown'

  const [fractionStr, setFractionStr] = useState()
  const gridColumns = {gridTemplateColumns: fractionStr}
  const ALL_UTIL_KEY='t_alu'
  const ALL_AREA_KEY = 't_aas'
  const menuItemAll='All'
  const CLASS_MENU_TRACK='menu-track'
  const CLASS_MONTH_LABEL='month-label'
  const CLASS_MONTH_LABEL_TXT='month-label-txt'
  const CLASS_QTR_TOP='qtr-top'
  const METER_GRAPH_TOOLTIP='meter-graph-tooltip'
  const utilityListsDefault={label: I18n(ALL_UTIL_KEY), value: menuItemAll}
  const areaListsDefault={label: I18n(ALL_AREA_KEY), value: menuItemAll}
  const [offsetTop, setOffsetTop]=useState(0)
  const meterData= props.meterData

  const [period] = useState()
  const [utilityLists, setUtilityLists] = useState([utilityListsDefault])
  const [areaLists, setAreaLists] = useState([areaListsDefault])
  const [selectedUtility, setSelectedUtility] = useState(props.utility || utilityListsDefault)
  const [selectedArea, setSelectedArea] = useState(props.area || areaListsDefault)
  const [dqMeterModalShow, setDqMeterModalShow] = useState(false)
  const [showDqMeterSidebar, setShowDqMeterSidebar] = useState(false)
  const [dqMeterData, setDqMeterData] = useState(null)
  const [selectedRecords, setSelectedRecords] = useState([])

  const [errorDialogVisible, setErrorDialogVisible] = useState(false)
  const [showRequestDataDlg, setShowRequestDataDlg] = useState(false)
  const [errorDialogData, setErrorDialogData] = useState()
  const [selectedMeterData, setSelectedMeterData]=useState(null)
  const [reFetchData, setReFetchData] = useState(false)

  const pfContext = useContext(PortfolioContext)
  const rowDataCtx = pfContext.pfState.data
  const {assetId} = useParams()

  const MSG_KEY_TITLE_CANNOT_REQUEST_DATA = 't_title_cannot_request_data'
  const [gapsPerMonth, setGapsPerMonth]=useState([])

  const CLASS_FY_START_BORDER_L = period !== undefined && 'fyStart-border-l'
  const selectAllIconSelectors = '.meter-datatable .p-datatable-table .p-datatable-thead div.p-checkbox-box span.p-checkbox-icon'

  const gapCssColorMap={
    'red':'MISSING' ,
    'blue': 'ACTUAL',
    'yellow': 'EST',
    'grey': 'NC'
  }

  const gapCssColorMapPdf={
    'red':'missing-pdf' ,
    'blue': 'actual-pdf',
    'yellow': 'est-pdf',
    'grey': 'nc-pdf'
  }

  if(meterData?.data?.assetMeterDataQualityDetails.length > 0){
    fyStartMonth=meterData.data.assetMeterDataQualityDetails[0].clientFundStartMonth
  }

  useEffect(() => {
    if(meterData?.data?.assetMeterDataQualityDetails.length > 0){
      fyStartMonth = fyStartMonth || meterData.data.assetMeterDataQualityDetails[0].clientFundStartMonth
      let noOfMonths=meterData.data.assetMeterDataQualityDetails[0].gapsPerMonth.length
      setFractionStr('repeat(' + noOfMonths + ', 1fr)')
    }
  }, [meterData.data])

  useEffect(() => {
    if(!showDqMeterSidebar) {
      setSelectedRecords([])
      setDqMeterData(null)
    }
  }, [showDqMeterSidebar])

  useEffect(() => {
    const selectAllIconNode = getSelectAllIconNode(selectAllIconSelectors)
    setSelectAllIcon(selectedRecords, gapsPerMonth, selectAllIconNode)
  }, [selectedRecords])

  const getFYStartBorderClass = (gapMonth, rootIndex) => {
    let labelBorderClasses = ''
    if (gapMonth.month === fyStartMonth && rootIndex !== 0){
      labelBorderClasses = period !== 'YTD' && CLASS_FY_START_BORDER_L
    }
    return labelBorderClasses
  }

  const getLabelText = (meterData) => {
    let newLabel
    if(meterData?.meterComment){
      newLabel=`${getLocalizedValue(selectedLanguage, 't_' + meterData.responsibility.toLowerCase())}: ${meterData.meterComment}`
    } else {
      newLabel= getLocalizedValue(selectedLanguage, 't_' + meterData.responsibility.toLowerCase())
    }
    return newLabel
  }

  //Graph Label.
  const getLabelsElements = (rootIndex, meterData) => meterData.gapsPerMonth.map((gap, index) => {
    let graphLabelElement
    const elementKey=getGraphKey(gap)
    const classFYStartBorder=getFYStartBorderClass(gap, rootIndex)
    if (index === 0) {
      if ((index + 1) === meterData.gapsPerMonth.length) {
        graphLabelElement = <div key={`label-${elementKey}`}>
          <div className={'labels'}>
            <span className="label-left" key={`label-left-${elementKey}`}>{getLabelText(meterData)}</span>
            <span className="label-right label-float-r" key={`label-right-${elementKey}`}>{I18n('t_' + meterData.utility.toLowerCase())}</span>
          </div>
        </div>
      } else {
        graphLabelElement = <div key={`label-${elementKey}`}>
          <div className={'label-left'}>{getLabelText(meterData)}</div>
        </div>
      }
    } else if ((index + 1) === meterData.gapsPerMonth.length) {
      graphLabelElement = <div className={'label-right label-txt-rtl'} key={`label-${elementKey}`}>
        <div>
          <span>{I18n('t_' + meterData.utility.toLowerCase())}</span>
        </div>
      </div>
    } else {
      graphLabelElement = <div key={`label-${elementKey}`}/>
    }
    return <div key={`parent-label-${elementKey}`} className={`graph-label-parent ${classFYStartBorder} ${CLASS_GRID_COLUMN}`}>{graphLabelElement}</div>
  })

  const getDatagapColor = (gapColour) => {
    return (props.isPdf
      ? gapCssColorMapPdf[gapColour.toString().toLowerCase()]
      : gapCssColorMap[gapColour.toString().toLowerCase()])
  }

  function graphBoxClickHandler(meterData, gapPerMonth) {

    setDqMeterData({
      areaCovered: meterData.areaCovered,
      electricityMPAN: meterData.electricityMPAN,
      isSubMeter: meterData.isSubMeter,
      meterComment: meterData.meterComment,
      meterId: meterData.meterId,
      meterStatus: meterData.meterStatus,
      utility: meterData.utility,
      provider: meterData.provider,
      floorArea: meterData.floorArea,
      floorAreaType: meterData.floorAreaType,
    })

    if (missingDataList) {
      let dataGapsPerMonth = missingDataList.filter((dataGap) => {
        const dataGapMonth=new Date(dataGap.startDate).getMonth()+1
        return gapPerMonth.month && dataGap.startDate && dataGap.electricityMPAN && gapPerMonth.month === dataGapMonth &&
          dataGap.electricityMPAN === meterData.electricityMPAN
      })

      setGapsPerMonth(dataGapsPerMonth)
    }

    if (featureState) {
      setShowDqMeterSidebar(!showDqMeterSidebar)
    } else {
      setDqMeterModalShow(!dqMeterModalShow)
    }
  }

  const getGraphKey=(gap)=>`key-${gap.position}${gap.month}`

  const getGraphColour = (gap) => {
    if ((gap.gap === 'Partial')) {
      return props.isPdf ? gapCssColorMapPdf['yellow'] : gapCssColorMap.yellow
    }
    return getDatagapColor(gap.colour)
  }

  //Graph component.
  const getGraphElements = (rootIndex, meterData) => meterData.gapsPerMonth.map((gap, index) => {
    let graphElement
    let classFYStartBorder = ''
    let classGraphBorders=getGraphBorderClasses(meterData.gapsPerMonth, index, gap)
    const graphColour = getGraphColour(gap)
    if (gap.month === fyStartMonth) {
      classFYStartBorder = period !== 'YTD' && CLASS_FY_START_BORDER_L
    }
    graphElement = <div data-testid={`graph-element-${gap.position}`}
      className="graph-box"
      key={getGraphKey(gap)} onClick={()=>graphBoxClickHandler(meterData, gap)}>
      {getTooltipCommElements(rootIndex, index, gap, cssClassBuilder(graphColour + classGraphBorders))}
    </div>
    return <div key={`parent-graph-${getGraphKey(gap)}`} className={`graph-parent ${classFYStartBorder}`}>{graphElement}</div>
  })

  const getQtrBorder = (index) => {
    let qtrBorderClass = ''
    if ((index + 1) === fyStartMonth || (index + 1) === (qtrMonthMarker + 3)) {
      qtrMonthMarker = index + 1
      qtrBorderClass = 'qtr-border'
    }
    return qtrBorderClass
  }

  const getMonthLabelElements = (dataQualityDetail) => dataQualityDetail.gapsPerMonth.map((gap, index) => {
    let monthNames = dataQualityDetail.gapsPerMonth.length < 4 ? monthList : monthListShort
    let fontSizeClass = dataQualityDetail.gapsPerMonth.length > 12 ? 'small-text-label' : 'large-text-label'
    let cssClasses = `${CLASS_MONTH_LABEL_TXT} ${CLASS_MONTH_LABEL} ${fontSizeClass} ${getQtrBorder(index)}`
    return <span className={cssClasses} key={`month-label-${getGraphKey(gap)}`}>{I18n('t_' + monthNames[gap.month].toLowerCase())}</span>
  })

  const getMonthLabeQtrTopEle = (dataQualityDetail) => dataQualityDetail.gapsPerMonth.map((gap, index) => {
    if (index === 0) {
      return <span className={`${CLASS_MONTH_LABEL} ${CLASS_QTR_TOP}`} key={`month-label-qtr-${getGraphKey(gap)}`} />
    } else if ((index + 1 === dataQualityDetail.gapsPerMonth.length)) {
      return <span className={`${CLASS_MONTH_LABEL} ${CLASS_QTR_TOP} qtr-border-top-r`} key={`month-label-qtr-${getGraphKey(gap)}`} />
    }
    return <span className={`${CLASS_MONTH_LABEL} ${CLASS_QTR_TOP}`} key={`month-label-qtr-${getGraphKey(gap)}`}/>
  })

  const getMonthLabelQtrBottomEle = (dataQualityDetail) => dataQualityDetail.gapsPerMonth.map((gap, index) => {
    return <span className={`${CLASS_MONTH_LABEL} qtr-bottom ${getQtrBorder(index)}`} key={`month-label-qtr-btm-${getGraphKey(gap)}`} />
  })

  const getYearLegendElements = (dataQualityDetail) => dataQualityDetail.gapsPerMonth.map((gap, index) => {
    let gapYear
    let cssClasses = ''
    if (index === 0 || gap.month === 1) {
      gapYear = gap.year
      cssClasses = 'year-legend-text '
    }
    return <div className={`${cssClasses}`} key={`year-legend-${getGraphKey(gap)}`}>{gapYear}</div>
  })

  const cssClassBuilder = (colorClass) => commCssClass + colorClass

  const getTooltipCommElements = (rowIndex, gapIndex, gap, tooltipClasses) => {
    gap.colour = gap.gap === 'Partial' ? 'yellow' : gap.colour
    return <>
      <Tooltip
        appendTo='self'
        autoHide={false}
        target={`.graph-tooltip-${gap.colour}-${rowIndex}-${gapIndex}`}
        className={'heatmap-tooltip'}
        key={`graph-tooltip-${getGraphKey(gap)}`}>
        <span>{I18n('t_' + gap.colour.toLowerCase())}</span>
      </Tooltip>
      <div
        id={METER_GRAPH_TOOLTIP}
        className={`${tooltipClasses} graph-tooltip-${gap.colour}-${rowIndex}-${gapIndex}`}
        key={`tooltip-${getGraphKey(gap)}`}
        data-pr-position="bottom"
      />
    </>
  }

  const getGraphBorderClasses = (gapsPerMonth, index, gap) => {
    let borderClasses
    if(period === 'YTD' || period === undefined){
      borderClasses = 'border-l-1p'
    } else {
      if (gap.month !== fyStartMonth) {
        borderClasses = 'border-l-1p'
      }
    }
    if ((index + 1) === gapsPerMonth.length) {
      borderClasses =`border-r-1p ${borderClasses}`
    }
    return ` ${borderClasses}`
  }

  const setYTDColor=()=>{
    let ytdElement = document.querySelector(`.meter-container .${CLASS_MENU_TRACK} .p-selectbutton .p-button`)

    if (ytdElement?.classList) {
      if (ytdElement.ariaPressed === 'false') {
        ytdElement.classList.remove(ytdActiveClass)
      } else {
        ytdElement.classList.add(ytdActiveClass)
      }
    }
  }

  function addStickyHeaderMenu(){
    let meterHeader = document.getElementById('menuMonthsContainer')
    if(!offsetTop){
      setOffsetTop(meterHeader.offsetTop)
    }
    window.onscroll = ()=>{
      if (window.scrollY > offsetTop) {
        meterHeader.classList.add(CLASS_METER_STICKY)
      } else {
        meterHeader.classList.remove(CLASS_METER_STICKY)
      }
    }
  }

  useEffect(()=>{
    addStickyHeaderMenu()
    setYTDColor()
  })

  useEffect(()=>{
    loadMenuItems(meterData.data)
  }, [meterData.data])

  useEffect(() => {
    let meterUtility = selectedUtility.value
    let meterArea = selectedArea.value
    if(utilityLists.length>1 || areaLists.length>1){
      meterUtility=utilityLists.some(element => element.value === selectedUtility.value) ? selectedUtility.value : menuItemAll
      setSelectedUtility({value: meterUtility})

      meterArea=areaLists.some(element => element.value === selectedArea.value) ? selectedArea.value : menuItemAll
      setSelectedArea({value: meterArea})
    }
  }, [utilityLists, selectedUtility.value, selectedArea.value])

  function getMonthLabelsElements(dataQualityDetailFirst){
    return <div className={'month-labels'} style={gridColumns} key="comp-month-labels" >
      {getMonthLabeQtrTopEle(dataQualityDetailFirst)}
      {getMonthLabelElements(dataQualityDetailFirst)}
      {getMonthLabelQtrBottomEle(dataQualityDetailFirst)}
      {getYearLegendElements(dataQualityDetailFirst)}
    </div>
  }

  const meterNoDataElement=<div className="meter-no-data">
    <div className="meter-no-data-msg">{I18n('t_heatmap_no_meterdata_msg')}</div>
  </div>

  const loadMenuItems=(data)=> {
    if(data?.availableAreas && data?.availableUtilities){
      let utilItems=data.availableUtilities.map((util)=>{
        return {label: I18n('t_' + util.toLowerCase()), value: util}
      })
      setUtilityLists([utilityListsDefault,...utilItems])
      let areaItems=data.availableAreas.map((area)=>{
        return {label: I18n('t_' + area.toLowerCase()), value: area}
      })
      setAreaLists([areaListsDefault,...areaItems])
    }
  }

  // Load all meters
  const loadMeters = () => {
    return meterData.data.assetMeterDataQualityDetails.map((meterData, index) => {
      return <React.Fragment key={`frag-${meterData.meterId}`}>
        <div className="graph-labels" style={gridColumns} key={`labels-${meterData.meterId}`}>
          {getLabelsElements(index, meterData)}
        </div>
        <div className="graphs" style={gridColumns} key={`graphs-${meterData.meterId}`}>
          {getGraphElements(index, meterData)}
        </div>
      </React.Fragment>
    })
  }

  const meterDataQualityDetailsNotEmpty=()=>meterData?.data?.assetMeterDataQualityDetails.length > 0

  function getMetersGraphElement() {
    return <div className="graph-container" key="comp-graph-container" id="meterGraphContainer">
      {meterDataQualityDetailsNotEmpty()?loadMeters():meterNoDataElement}
    </div>
  }

  const utilityChangeHandler = (e) => {
    let newUtility = e.value
    if (newUtility) {
      const newUtilObj={value: newUtility}
      setSelectedUtility(newUtilObj)
      props.updateUtility(newUtilObj)
      props.updater(newUtilObj, selectedArea)
    }
  }

  const areaChangeHandler = (e) => {
    let newArea = e.value
    if (newArea) {
      const newAreaObj={value: newArea}
      setSelectedArea(newAreaObj)
      props.updateArea(newAreaObj)
      props.updater(selectedUtility, newAreaObj)
    }
  }

  const onUtilityClick = (evt) => {
    let parent = evt.target.parentElement.parentElement
    let targetEle=parent.children[parent.children.length-1].firstChild
    targetEle.style.maxHeight = '13rem'
  }

  const legendMenuElement=<div className="col-6 no-padding legend-menu">
    <img src={LegendTeal} alt="actual-blue"/>
    <span>{I18n('t_actual')}</span>
    <img src={props.isPdf ? LegendEstPdf : LegendYellow} alt="estimated-yellow"/>
    <span>{I18n('t_estimated')}</span>
    <img src={props.isPdf ? LegendMissingPdf : LegendRed} alt="missing-red"/>
    <span>{I18n('t_missing')}</span>
  </div>

  const dropdownItemTemplate  = (option) => {
    return (
      <>
        <div className="dropdown-item-1">
          <div className="dropdown-item-2 dropdown-item-wrap">{option.label}</div>
        </div>
        <div className={'dropdown-panel-item-horiz-line'} />
      </>
    )
  }

  const utilDropdownMenu = <Dropdown options={utilityLists} className={CLASS_SDROP_DOWN} value={selectedUtility.value}
    onChange={utilityChangeHandler}
    onFocus={(e) => onUtilityClick(e)} itemTemplate={dropdownItemTemplate}
    scrollHeight="24em"/>

  const areaDropdownMenu = <Dropdown options={areaLists} className={CLASS_SDROP_DOWN} value={selectedArea.value}
    onChange={areaChangeHandler}
    itemTemplate={dropdownItemTemplate} scrollHeight="24em"/>

  const getMeterDQLabelElements = meterDataQualityDetailsNotEmpty() ? getMonthLabelsElements(meterData.data.assetMeterDataQualityDetails[0]) : ''

  function getDataQualityFilters(utilDropdownMenu, areaDropdownMenu) {
    return (
      <div className="menu-container" key="comp-menu-container">
        <div className="menu-dropdowns">
          <div className={CLASS_MENU_TRACK + ' col-3 util-dropdown'} key="menu-track-utility">
            {props.isPdf ? null : utilDropdownMenu}
          </div>
          <div className={`${CLASS_MENU_TRACK + ' col-3 area-dropdown'} menu-track-area`} key="menu-track-area">
            {props.isPdf ? null : areaDropdownMenu}
          </div>
          {legendMenuElement}
        </div>
      </div>
    )
  }

  function dqMeterModalOnHide(){
    setDqMeterModalShow(!dqMeterModalShow)
    unBlockDialogScroll()
  }

  function refetchMeterDataGaps(){
    if(props.missingDataWrapper){
      props.missingDataWrapper.refetch()
    }
  }

  useEffect(() => {
    if(reFetchData){
      refetchMeterDataGaps()
      setReFetchData(false)
    }
  },[reFetchData])

  function reloadOnSuccess(){
    setReFetchData(true)
  }

  function reloadSidebarData() {
    refetchMeterDataGaps()
    props.meterData.refetch()
  }

  const missingDataMutation = useMutation(addNewMissingDataRequestInternal, {
    onSuccess: (data) => {
      if(data){
        //Redirect to the link provided
        window.open(data)
      } else {
        setErrorDialogData({title: I18n( MSG_KEY_TITLE_CANNOT_REQUEST_DATA), message: 'No redirect data from server'})
        setErrorDialogVisible(true)
      }
      reloadOnSuccess()
    }
  })

  const enterDataHandler = async (selectedMeterDataFromModal) => {
    try {
      await missingDataMutation.mutateAsync({assetId, dataGap: getDataGapRequestObj(selectedMeterDataFromModal)})
    } catch (error) {
      setErrorDialogData({title: I18n( MSG_KEY_TITLE_CANNOT_REQUEST_DATA), message: error.toString()})
      setErrorDialogVisible(true)
    }
  }

  const requestDataHandler = (selectedMeterDataFromModal) => {
    setSelectedMeterData(selectedMeterDataFromModal)
    setShowRequestDataDlg(!showRequestDataDlg)
    dqMeterModalOnHide()
  }

  const onDialogActionHandler=()=>{
    setShowRequestDataDlg(!showRequestDataDlg)
  }

  function dlgCloseHandler() {
    setErrorDialogVisible(false)
  }

  function getMeterContainerComponent(){
    return <div className="meter-container" key="comp-meter-container" >
      <div className="menu-months-view" id="menuMonthsContainer">
        {getDataQualityFilters(utilDropdownMenu, areaDropdownMenu)}
        {(meterData?.isLoading) ?
          <IndividualMetersMonthLabelsSkeleton /> :
          getMeterDQLabelElements
        }
      </div>
      {(meterData?.isLoading) ?
        <>
          <IndividualMetersChartSkeleton />
          <IndividualMetersChartSkeleton />
          <IndividualMetersChartSkeleton />
        </> :
        getMetersGraphElement()}

      {dqMeterModalShow &&
          <DqMeterModal
            showDialog={dqMeterModalShow}
            onHideHandler={dqMeterModalOnHide}
            meterModalData={gapsPerMonth}
            dqMeterModalParams={dqMeterData}
            enterDataHandler={enterDataHandler}
            requestDataHandler={requestDataHandler}
          />}
      {showDqMeterSidebar &&
          <ConsumptionNavigator isSidebarVisible={showDqMeterSidebar} isMeter={true}
            setIsSidebarVisible={()=>setShowDqMeterSidebar(false)} unitSystem={unitSystem}
            colorLabel={props.colorLabel} refetch={reloadSidebarData} allData={props.missingDataWrapper}
            gapsPerMonth={gapsPerMonth} dqMeterData={dqMeterData} language={selectedLanguage}/>
      }

      {showRequestDataDlg && <RequestDataDialog
        showDialog={showRequestDataDlg}
        onEventActionHideHandler={onDialogActionHandler}
        data={selectedMeterData}
        reload={reloadOnSuccess}
        assetAddress={rowDataCtx.assetAddress}
        assetId={assetId}
      />}
      {errorDialogVisible &&
      <ErrorDialog data={errorDialogData}  dialogVisible={errorDialogVisible} onHideHandler={dlgCloseHandler} closeHandler={dlgCloseHandler} />}
    </div>
  }

  return (getMeterContainerComponent())
}

export default IndividualMeterIndicator
IndividualMeterIndicator.displayName = 'IndividualMeterIndicator'

IndividualMeterIndicator.propTypes = {
  area: PropTypes.object,
  isPdf: PropTypes.bool,
  meterData: PropTypes.object,
  meterModalParams: PropTypes.object,
  updateArea: PropTypes.func,
  updateUtility: PropTypes.func,
  updater: PropTypes.func,
  utility: PropTypes.object,
  missingDataWrapper: PropTypes.any
}