import axiosInstance from '../common/axios-config'

export const GetAssetFormContent = async (keys) => {
  const assetId = keys?.queryKey[1]
  if(!assetId) {
    return
  }
  
  const { data } = await axiosInstance.get(`/api/v1/Assets/${assetId}/EditAssetForm`)
  return data
}

export const UpdateAssetFormContent = async (newContent) => {
  const {data} = await axiosInstance.put(`/api/v1/Assets/${newContent.assetId}`, newContent.requestData)
  return data
}

export const AddAssetFormContent = async (newContent) => {
  const {data} = await axiosInstance.post('/api/v1/Assets/AddAsset', newContent.requestData)
  return data
}

export const GetSectorList = () => {
  
}