import React, {useContext, useEffect, useState, useTransition} from 'react'
import './BreadCrumbNavigation.scss'
import {BreadCrumb} from 'primereact/breadcrumb'
import {useHistory} from 'react-router-dom/cjs/react-router-dom'
import {useLocation, useParams} from 'react-router-dom'
import I18n from '../../../utils/i18n/I18n'
import {Skeleton} from 'primereact/skeleton'
import {
  CachedUserState,
  createNavURLForFundPages,
  createNavURLForUserPages,
  getInstance,
  getLocalStorageItem,
  getLoggedInUserRole
} from '../../../utils/helpers/Helper'
import {
  ADMIN_PAGES, ASSET_ACTION_PLAN, ASSET_DASHBOARD, consumptionUpload,
  FUND_DASHBOARD, FUND_NZC_TOP_DOWN_PATHWAYS, GROUP_DETAILS,
  LOCAL_STORAGE_KEYS, topDownPathways,
  USER_FUND_ACCESSES
} from '../../../utils/helpers/Constants'
import {FeatureFlagContext} from 'Contexts'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const BreadCrumbNavigation = () => {
  const [breadCrumbItems, setBreadCrumbItems] = useState([])
  const navigate = useHistory()
  const location = useLocation()
  const userFundAccesses = getLocalStorageItem('userFundAccessType')
  const breadCrumbClassName = 'bread-crumb'
  const featureFlagContext = useContext(FeatureFlagContext)
  const featureState = featureFlagContext.featureState
  const loginContext = useLoginAuthContext()
  const {instanceName, orgName, fundName, assetId, userId, groupId, routeVia, type, actionId} = useParams()
  const [isPending, startTransition] = useTransition()
  const homePath = loginContext?.loginState?.homePath

  useEffect(() => {
    let breadCrumbItems = []
    if (userFundAccesses) {
      startTransition(() => {
        const menuItems = getBreadCrumbItemsList()
        if (menuItems?.length) {
          breadCrumbItems = getBreadCrumbs(menuItems)
          setBreadCrumbItems(breadCrumbItems)
        }
      })
    }
  }, [location.pathname, userFundAccesses, loginContext.loginState])

  function getBreadCrumbs(templateAsPerPage) {
    let breadCrumb = []
    templateAsPerPage.map(model => {
      let modelObject
      if(model){
        if(model.navigation){
          modelObject = {label: model.label, command: () => navigate.push(model.navigation)}
        }else{
          modelObject = {label: model.label, command: () => navigate.goBack()}
        }
        breadCrumb.push(modelObject)
      }
    })
    return breadCrumb
  }

  function getFundNameFromCache() {
    return CachedUserState.getUserDataFromCache(LOCAL_STORAGE_KEYS.cacheUrlParamsIdsData)?.actualFundName || CachedUserState.getUserDataFromCache(LOCAL_STORAGE_KEYS.selectedOrgAndFund)?.fundName
  }

  function getAssetNameFromCache() {
    return CachedUserState.getUserDataFromCache(LOCAL_STORAGE_KEYS.cacheUrlParamsIdsData)?.actualAssetName || CachedUserState.getUserDataFromCache(LOCAL_STORAGE_KEYS.selectedOrgAndFund)?.assetName
  }

  function getOrgNameFromCache() {
    const selectedOrgAndFund=CachedUserState.getUserDataFromCache(LOCAL_STORAGE_KEYS.selectedOrgAndFund)
    let orgNameFromCache = orgName
    if(selectedOrgAndFund?.orgName) {
      orgNameFromCache=selectedOrgAndFund.orgName
    }
    return orgNameFromCache
  }

  function getBreadCrumbItemsList() {
    let breadCrumbItems = []

    if(homePath && location.pathname !== homePath.pathname && USER_FUND_ACCESSES.NoFundAccess !== userFundAccesses) {
      breadCrumbItems.push({label: I18n('t_home'), navigation: homePath})
    }

    if(instanceName && orgName && featureState.sieraplus_fundViews) {
      switch(userFundAccesses) {
      case USER_FUND_ACCESSES.MultipleOrganisationAccess:
        if (fundName) { // fund list page
          breadCrumbItems.push({
            label: getOrgNameFromCache(),
            navigation: createNavURLForFundPages({instanceName, orgName})
          })

          // fund dashboard page
          assetId && breadCrumbItems.push({
            label: getFundNameFromCache(), navigation: createNavURLForFundPages(
              {instanceName, orgName, fundName})
          })

          actionId && breadCrumbItems.push({
            label: getAssetNameFromCache(), navigation: createNavURLForFundPages(
              {instanceName, orgName, fundName, assetId, pathSuffix: 'action-plan'})
          })
        }
        break

      case USER_FUND_ACCESSES.MultipleFundAccess:
        if(fundName && assetId) {
          breadCrumbItems.push({
            label: getFundNameFromCache(),
            navigation: createNavURLForFundPages({instanceName, orgName, fundName})
          }) // fund dashboard page

          actionId && breadCrumbItems.push({
            label: getAssetNameFromCache(), navigation: createNavURLForFundPages(
              {instanceName, orgName, fundName, assetId, pathSuffix: 'action-plan'})
          })
        }
        break

      case USER_FUND_ACCESSES.SingleFundAccess:
      case USER_FUND_ACCESSES.NoFundAccess:
        break
      }
      return breadCrumbItems
    }
    return [...breadCrumbItems,...getBreadCrumbForUserOrGroup()]
  }

  function getBreadCrumbForUserOrGroup() {
    let breadCrumbItems = []

    if(userId && getLoggedInUserRole() === 'ADMIN') {
      breadCrumbItems.push({label: I18n('t_users'), navigation: createNavURLForUserPages(
        {instanceName: getInstance(),pathSuffix: ADMIN_PAGES.users })})

      if (location.pathname?.includes(ADMIN_PAGES.edit)) {
        const userProfilePath=createNavURLForUserPages(
          {pathPrefix: ADMIN_PAGES.users, userId: userId})
        breadCrumbItems.push({label: userId, navigation: userProfilePath})
      }

    } else if(groupId) {
      if(location.pathname?.includes(ADMIN_PAGES.myGroups)) {
        breadCrumbItems.push({
          label: I18n('t_my_groups'),
          navigation: createNavURLForUserPages({
            instanceName: getInstance(),
            pathSuffix: 'groups',
            userId: getLocalStorageItem(LOCAL_STORAGE_KEYS.userId) })
        })

      } else {
        breadCrumbItems.push({label: I18n('t_groups'),
          navigation: createNavURLForUserPages({
            instanceName: getInstance(),
            pathSuffix: ADMIN_PAGES.groups
          })})
      }

    } else if(type?.includes(consumptionUpload) || type?.includes('action-upload') || type?.includes(topDownPathways)) {
      breadCrumbItems = [...breadCrumbItems, ...getBreadCrumbForBulkConsumption()]
    }

    return breadCrumbItems
  }

  function getBreadCrumbForBulkConsumption() {
    const breadCrumbItems = []
    switch (routeVia) {
    case GROUP_DETAILS:
      breadCrumbItems.push({
        label: I18n('t_my_groups'),
        navigation: createNavURLForUserPages({
          instanceName: getInstance(),
          pathSuffix: 'groups',
          userId: getLocalStorageItem(LOCAL_STORAGE_KEYS.userId)
        })})
      breadCrumbItems.push({label: navigate.location.state?.fileName, navigation: navigate.location.state.from})
      break
    case ASSET_ACTION_PLAN:
    case ASSET_DASHBOARD:
      if ([USER_FUND_ACCESSES.MultipleFundAccess, USER_FUND_ACCESSES.MultipleOrganisationAccess].includes(userFundAccesses)) {
        breadCrumbItems.push({
          label: getFundNameFromCache(),
          navigation: createNavURLForFundPages({
            instanceName: getInstance(),
            orgName: CachedUserState.getUserDataFromCache(LOCAL_STORAGE_KEYS.cacheUrlParamsIdsData)?.organisationName,
            fundName: CachedUserState.getUserDataFromCache(LOCAL_STORAGE_KEYS.selectedOrgAndFund)?.fundUrlName
          })
        })
      }
      breadCrumbItems.push({
        label: navigate.location.state?.fileName,
        navigation: navigate.location.state.from
      })
      break
    case FUND_DASHBOARD:
    case FUND_NZC_TOP_DOWN_PATHWAYS:
      if ([USER_FUND_ACCESSES.MultipleFundAccess, USER_FUND_ACCESSES.MultipleOrganisationAccess].includes(userFundAccesses) && navigate.location.state) {
        breadCrumbItems.push({
          label: navigate.location.state?.fileName,
          navigation: navigate.location.state?.from
        })
      }
      break
    }
    return breadCrumbItems
  }

  function getBreadCrumb() {
    return isPending ?
      <div className={'skeleton-grey'}>
        <Skeleton width={'6rem'}/>
      </div> :
      <BreadCrumb separatorIcon={<span>/</span>} model={breadCrumbItems} />
  }

  return (
    <div className='min-width-1280'>
      <div className={`${breadCrumbClassName} gutter`}>
        {getBreadCrumb()}
      </div>
    </div>
  )
}

export default BreadCrumbNavigation
