import PropTypes from 'prop-types'
import './DashboardCompletenessChart.scss'
import {Bar} from 'react-chartjs-2'
import {getCompletenessChartData, getCompletenessChartOptions} from '../../../pages/assets/data-quality/data-quality-hooks-&-helpers/dataQualityHelper'
import {completenessDaysToPercentages, getLocalizedValue} from '../../../../utils/helpers/Helper'
import {Icon, Text} from '../../../atomic'
import {formatNumWOneDecimal} from '../../../../services/common/formatter'
import PeriodView from '../../period-view/PeriodView'
import DashboardCompletenessSkeleton from './skeleton/DashboardCompletenessSkeleton'

const DashboardCompletenessChart = ({completenessData, timePeriod, pickerValue, selectedLanguage, assetDashboard, isLoading}) => {
  if(isLoading) {
    return <DashboardCompletenessSkeleton assetDashboard={assetDashboard}/>
  }
  
  if(!completenessData){
    return <Text content="t_msg_no_data" size="xs" />
  }

  const {actualPercentage, calculatedEstimatePercentage, supplierEstimatePercentage, missingPercentage, overallPercentage} = completenessDaysToPercentages(completenessData)
  const iconCommonProps={id: 'square_solid', size: 10}
  const className = !assetDashboard ? 'db-completeness-chart' : 'db-completeness-chart-assets'

  const completenessChartColours = {
    actualPercentage: 'forest',
    supplierEstimatePercentage: 'green_light',
    calculatedEstimatePercentage: 'sand',
    missingPercentage: 'orange'
  }

  function getLegendTextProps(value, msgKey) {
    return {
      content: `${formatNumWOneDecimal(selectedLanguage, value)}% ${getLocalizedValue(selectedLanguage, msgKey)}`,
      size: 'xs'
    }
  }

  return (
    <div className={className}>
      <div className={assetDashboard ? `${className}__title` : ''}>
        <div className={`${className}__percentage`}>
          <Text content={`${formatNumWOneDecimal(selectedLanguage, overallPercentage)}% ${getLocalizedValue(selectedLanguage, 't_reports_data_completeness')}`} size="xs" weight="bold" />
        </div>
        <div className={`${className}__period`}>
          <PeriodView timePeriod={timePeriod} pickerValue={pickerValue}/>
        </div>
      </div>
      <div className={`${className}__chart`}>
        <Bar data={getCompletenessChartData(completenessDaysToPercentages(completenessData))} options={getCompletenessChartOptions(100)} />
      </div>
      <div className={`${className}__legends`}>
        {actualPercentage > 0 &&
              <div>
                <Icon  colour={completenessChartColours.actualPercentage} {...iconCommonProps}  />
                <Text {...getLegendTextProps(actualPercentage, 't_actual_data_legend')} />
              </div>
        }

        {supplierEstimatePercentage > 0 &&
              <div>
                <Icon colour={completenessChartColours.supplierEstimatePercentage} {...iconCommonProps} />
                <Text {...getLegendTextProps(supplierEstimatePercentage, 't_supplier_estimate')} />
              </div>
        }

        {calculatedEstimatePercentage > 0 &&
              <div>
                <Icon colour={completenessChartColours.calculatedEstimatePercentage} {...iconCommonProps} />
                <Text {...getLegendTextProps(calculatedEstimatePercentage, 't_calculated_estimate')} />
              </div>
        }

        {missingPercentage > 0 &&
              <div>
                <Icon colour={completenessChartColours.missingPercentage} {...iconCommonProps} />
                <Text {...getLegendTextProps(missingPercentage, 't_missing_tc')} />
              </div>
        }
      </div>
    </div>
  )
}

DashboardCompletenessChart.propTypes = {
  completenessData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  timePeriod: PropTypes.string,
  pickerValue: PropTypes.object,
  selectedLanguage: PropTypes.string,
  assetDashboard: PropTypes.bool,
  isLoading: PropTypes.bool
}

DashboardCompletenessChart.displayName = 'DashboardCompletenessChart'

export default DashboardCompletenessChart