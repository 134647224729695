import { OverlayPanel as PrimeOverlayPanel } from 'primereact/overlaypanel'
import PropTypes from 'prop-types'
import './OverlayPanel.scss'

const OverlayPanel = (props) => {
  const {panelRef, showCloseIcon, panelChildren, className, dismissable, onShow, onHide, appendTo} = props
  return (
    <div>
      <PrimeOverlayPanel
        ref={panelRef}
        showCloseIcon={showCloseIcon}
        className={`custom-overlaypanel ${className}`}
        dismissable={dismissable}
        onHide={onHide}
        onShow={onShow}
        appendTo={appendTo}
      >{panelChildren}</PrimeOverlayPanel>
    </div>
  )
}

OverlayPanel.propTypes = {
  /**
   * Trigger the overlay panel.
   */
  panelRef: PropTypes.any.isRequired,
  /**
   * When enabled, displays a close icon at top right corner.
   * DefaultProp is true.
   */
  showCloseIcon: PropTypes.bool,
  /**
   * Additional CSS class to add to the overlaypanel.
   */
  className: PropTypes.string,
  /**
   * Used to get the child elements of the component.
   */
  panelChildren: PropTypes.node.isRequired,
  /**
   * Enables to hide the overlay when outside is clicked.
   * DefaultProp is true.
   */
  dismissable: PropTypes.bool,
  /**
   * Callback to invoke when overlay becomes hidden.
   */
  onHide: PropTypes.func,
  /**
   * Callback to invoke when overlay becomes visible.
   */
  onShow: PropTypes.func,
  /**
   * DOM element instance where the overlay panel should be mounted. Valid values are any DOM Element and 'self'. The self value is used to render a component where it is located.
   */
  appendTo: PropTypes.string
}

OverlayPanel.displayName = 'OverlayPanel'

OverlayPanel.defaultProps = {
  showCloseIcon: true,
  dismissable: true
}

export default OverlayPanel