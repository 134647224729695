import React, {useRef} from 'react'
import {Sidebar} from 'primereact/sidebar'
import './AssetActionDetailsFilter.scss'
import {getDropdownItemTemplate, getLocalizedValue} from '../../../../../utils/helpers/Helper'
import {Dropdown} from 'primereact/dropdown'
import {Button} from 'primereact/button'
import I18n from '../../../../../utils/i18n/I18n'
import {costTypeList,impactList,procuredByList,scopeList,statusList} from '../../../../common/action-plan/actionPlanTableTemplate'
import PropTypes from 'prop-types'
import InfoOverlayPanelContent from '../../../../atomic/OverlayPanel/OverlayPanelContent/OverlayPanelContent'
import OverlayPanel from '../../../../atomic/OverlayPanel/OverlayPanel'
import useCustomState from '../../../../common/custom-hooks/useCustomState'
import {Icon, Text} from '../../../../atomic'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

export const ACTION_FILTER_TYPES = {
  STATUS:'status',
  IMPACT:'impact',
  COST_TYPE:'costType',
  SCOPE:'scope',
  PROCURED_BY:'procuredBy'
}
export const filterTypesAndInitialValues = (selectedLanguage) =>{
  return {
    [ACTION_FILTER_TYPES.STATUS]: {
      label: 't_status',
      dropdownOptions: statusList,
      isInfoIconVisible: false,
      placeholder: getLocalizedValue(selectedLanguage, 't_select_status'),
      infoContent: null
    },
    [ACTION_FILTER_TYPES.IMPACT]: {
      label: 't_impact',
      dropdownOptions: impactList,
      isInfoIconVisible: true,
      placeholder: getLocalizedValue(selectedLanguage, 't_select_impact'),
      infoContent: 't_impact_info'
    },
    [ACTION_FILTER_TYPES.COST_TYPE]: {
      label: 't_cost_type',
      dropdownOptions: costTypeList,
      isInfoIconVisible: true,
      placeholder: getLocalizedValue(selectedLanguage, 't_select_cost_type'),
      infoContent: 't_cost_type_info'
    },
    [ACTION_FILTER_TYPES.SCOPE]: {
      label: 't_scope',
      dropdownOptions: scopeList,
      isInfoIconVisible: true,
      placeholder: getLocalizedValue(selectedLanguage, 't_select_scope'),
      infoContent: 't_scope_info'
    },
    [ACTION_FILTER_TYPES.PROCURED_BY]: {
      label: 't_procured_by',
      dropdownOptions: procuredByList,
      isInfoIconVisible: false,
      placeholder: getLocalizedValue(selectedLanguage, 't_select_procurement'),
      infoContent: null
    },
  }
}

const setInitialActionDetailsFilterState = () => {
  return {
    infoOverlayContent: null
  }
}

const AssetActionDetailsFilter = (props) => {
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const previousActionFilterSelections = useRef(props.actionFilterDropdownSelections)
  const infoOverlayRef = useRef(null)
  const {customState: actionDetailsFilterState, updateCustomState: updateActionDetailsFilterState}=useCustomState(() => setInitialActionDetailsFilterState())

  // region : render functions
  const handleOverlayPanel = (e, label, infoContent) => {
    infoOverlayRef.current.toggle(e)
    updateActionDetailsFilterState({infoOverlayContent: {label, infoContent}})
  }

  const renderFilterSideBarHeader = () => { return (<Text content='t_filter' size='xs' className='filter-header-title'/>) }
  const renderFilterOptions = () => {
    const filterTypes = Object.keys(filterTypesAndInitialValues(selectedLanguage))
    return filterTypes.map((option) => {
      const label = filterTypesAndInitialValues(selectedLanguage)[option].label
      const dropdownOptions = filterTypesAndInitialValues(selectedLanguage)[option].dropdownOptions.map(option => option.label)
      const isInfoIconVisible = filterTypesAndInitialValues(selectedLanguage)[option].isInfoIconVisible
      const placeholder = filterTypesAndInitialValues(selectedLanguage)[option].placeholder
      const infoContent = filterTypesAndInitialValues(selectedLanguage)[option].infoContent
      const selectedOption = props.actionFilterDropdownSelections[option]
      return (
        <div key={option} className="filter-content-container__dropdown">
          <div className="filter-content-container__dropdown--label">
            <Text content={label} />
            {isInfoIconVisible && <span onClick={(e) => handleOverlayPanel(e, label, infoContent)}><Icon testId={'info_solid'} id='info_solid' /></span>}
          </div>
          <Dropdown
            placeholder={placeholder}
            options={dropdownOptions}
            itemTemplate={getDropdownItemTemplate}
            value={selectedOption ? selectedOption.label : selectedOption}
            onChange={(e) => props.handleFilterSelectionChange(e,option)}
            name={option}
          />
        </div>
      )
    })
  }

  const renderApplyAndCancelButton = () => {
    const handleApplyButtonClick = () => {
      previousActionFilterSelections.current = props.actionFilterDropdownSelections
      props.handleFiltersApplyButtonClick(props.actionFilterDropdownSelections)
    }
    const isApplyButtonDisabled = JSON.stringify(previousActionFilterSelections.current) === JSON.stringify(props.actionFilterDropdownSelections)
    return (
      <div className="filter-content-container__apply-cancel-button-container">
        <Button className="filter-content-container__apply-cancel-button-container--apply" label={I18n('t_apply')}
          aria-label='Apply' onClick={handleApplyButtonClick}
          disabled={isApplyButtonDisabled}/>
        <Button className="filter-content-container__apply-cancel-button-container--cancel" label={I18n('t_clear_all')}
          aria-label='Clear all' onClick={props.handleFilterClearButtonClick}/>
      </div>
    )
  }

  // endregion

  return (
    <Sidebar pt={{ closeButton: { 'data-testid': 'close-button' } }} visible={props.isFilterVisible} position="right" closeOnEscape={false} blockScroll showCloseIcon={true}
      appendTo="self" onHide={() => props.onHideOrCloseOfFilter(previousActionFilterSelections.current)}
      className="custom-filter-sidebar asset-action-details-filter-sidebar"
      icons={renderFilterSideBarHeader}  role="complementary" data-testid="asset-actions-filter-sidebar" dismissable={false}>
      <div className="filter-content-container">
        {renderFilterOptions()}
        <OverlayPanel
          panelRef={infoOverlayRef}
          className={'pl-unset pr-30'}
          panelChildren={
            <InfoOverlayPanelContent
              iconId={'info_solid'}
              header={actionDetailsFilterState?.infoOverlayContent?.label}
              panelContent={actionDetailsFilterState?.infoOverlayContent?.infoContent}
            />}
        />
        {renderApplyAndCancelButton()}
      </div>
    </Sidebar>
  )
}

AssetActionDetailsFilter.propTypes = {
  isFilterVisible: PropTypes.bool,
  onHideOrCloseOfFilter: PropTypes.func,
  handleFiltersApplyButtonClick: PropTypes.func,
  actionFilterDropdownSelections: PropTypes.object,
  handleFilterSelectionChange: PropTypes.func,
  handleFilterClearButtonClick: PropTypes.func,

}
export default React.memo(AssetActionDetailsFilter)
