import {Field, ErrorMessage} from 'formik'
import { InputText } from 'primereact/inputtext'
import './TextfieldFormik.scss'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { Icon, Text, Tooltip } from 'components/atomic/index'


const TextfieldFormik = ({ name, label,testId, tooltipTriggerClass, inputType = 'text', tooltipContentKeys, errors, touched, value, isEnabled=false }) => {
  const handleKeyPress = (event) => {
    if (name === 'city' && inputType === 'text' && event.key.match(/\d/)) {
      event.preventDefault()
    }
  }

  return(
    <div className={cn(errors[name] && touched[name] ? 'error-field' : 'text-field', 'flex flex-column my-2 gap-1')}>
      <label className="relative" htmlFor={name}>
        {label}
        {tooltipContentKeys && tooltipTriggerClass &&
        <>
          <Icon id='info_solid' size={17} className={tooltipTriggerClass ? `${tooltipTriggerClass} ml-2` : 'ml-2'} testId={tooltipTriggerClass}/>
          <Tooltip target={`.${tooltipTriggerClass}`} width={230}>
            <Text content={tooltipContentKeys.title} weight='bold' className='mb-2'/>
            <Text content={tooltipContentKeys.content} />
          </Tooltip>
        </>
        }
      </label>
      <Field name={name}>
        {({ field, form: { setFieldTouched } }) => (
          <InputText
            {...field}
            onClick={() => !touched[name] && setFieldTouched(name, true)}
            value={value}
            disabled={isEnabled}
            className={`${errors[name] && touched[name] ? 'error-field' : ''}`}
            data-testid={testId}
            onKeyPress={handleKeyPress}
          />
        )}
      </Field>
      <ErrorMessage name={name} component="div" className="error medium-text my-0" />
    </div>
  )}


TextfieldFormik.propTypes = {
  label: PropTypes.string,
  errors: PropTypes.object,
  value: PropTypes.string,
  isEnabled: PropTypes.bool,
  touched: PropTypes.object,
  tooltipTriggerClass: PropTypes.string,
  tooltipContentKeys: PropTypes.shape({
    content: PropTypes.string,
    title: PropTypes.string,
  }),
  name: PropTypes.string,
  inputType: PropTypes.string,
  testId: PropTypes.string,
}

export default TextfieldFormik