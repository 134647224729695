import React from 'react'
import I18n from 'utils/i18n/I18n'
import './FundReportContentSidebar.scss'
import CustomSidebar from 'components/common/custom-sidebar/CustomSidebar'
import PropTypes from 'prop-types'
import FundReportContentForm from './FundReportContentForm'
import { ProgressSpinner } from 'primereact/progressspinner'

const FundReportContentSidebarContent = (props) => {
  const {
    fundReportContentData,
    fundReportContentLoading,
    year,
    isVisible,
    setIsVisible,
    submitNewReportData
  } = props

  return (
    <CustomSidebar
      customClass='create-report-sidebar'
      isSidebarVisible={isVisible}
      position="right"
      sidebarHeader={I18n('t_reports_create_report_header', { year })}
      setIsSidebarVisible={setIsVisible}
      dismissable={true}
    >
      {fundReportContentLoading &&
        <ProgressSpinner/>
      }
      {(!fundReportContentLoading && isVisible) && (
        <FundReportContentForm
          fundReportContentData={fundReportContentData}
          submitNewReportData={submitNewReportData}
          setIsVisible={setIsVisible}
          year={year}
        />
      )
      }
    </CustomSidebar>
  )
}

FundReportContentSidebarContent.propTypes = {
  fundReportContentData: PropTypes.object,
  fundReportContentLoading: PropTypes.bool,
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isVisible: PropTypes.bool,
  setIsVisible: PropTypes.func,
  submitNewReportData: PropTypes.func,
}

export default FundReportContentSidebarContent
FundReportContentSidebarContent.displayName = 'FundReportContentSidebarContent'