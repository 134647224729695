import React, {useContext, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import './NZCAssets.scss'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import SquareIcon from '../../../../../resources/images/icon/square.svg'
import dot from '../../../../../resources/images/dialog/dot.svg'
import triangleIcon from '../../../../../resources/images/icon/rounded-triangle.svg'
import CustomBadge from '../../../../common/custom-badge/CustomBadge'
import {  bigNumberFormatter,  getFiller,  getFillerImage,  getLocalizedValue,  getQueryValuesForNZCAssets,
  SessionUserState,  getLocalizedDateMessage, setAssetData,} from '../../../../../utils/helpers/Helper'
import {groupedAssetsOrderBy, assetsOrderByColumnChange} from '../../../../../utils/helpers/Constants'
import {FeatureFlagContext} from 'Contexts'
import {InputText} from 'primereact/inputtext'
import ClearIcon from '../../../../../resources/images/icon/close-gray.svg'
import infoWhite from '../../../../../resources/images/icon/info.svg'
import {Button} from 'primereact/button'
import {useMutation} from 'react-query'
import {updateNzcAssets} from '../../../../../services/funds/nzc-service'
import NzcAssetsFilterModal from './nzc-assets-filter-modal/NZCAssetsFilterModal'
import I18n from '../../../../../utils/i18n/I18n'
import {Badge} from 'primereact/badge'
import { confirmDialog } from 'primereact/confirmdialog'
import {OverlayPanel} from 'primereact/overlaypanel'
import { Tooltip } from 'primereact/tooltip'
import {closeAssetPanel} from '../../../assets/performance/performance-home/ChartHelper'
import CSS_VARIABLES from '../../../../../resources/css/_variables.scss'
import {isTextOverflown} from '../../../../../utils/UtilsFunctions'
import { closeFunction } from '../nzc-utils/nzc-utils'
import {queryParamsForFundNzc} from '../../../../../utils/signals/CommonSignals'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'
import {ButtonWrapper} from '../../../../atomic'

const NZCAssets = (props) => {
  const featureContext = useContext(FeatureFlagContext)
  const featureState = featureContext.featureState
  const reportingYear = props.reportingYear
  const searchWrapperRef = useRef(null)
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const [fundAssetFilter, setFundAssetFilter] = useState('')
  const fundAssetsRef = useRef([])
  const [faPlaceholder, setFaPlaceholder]   = useState(getLocalizedValue(selectedLanguage, 't_find_asset'))
  const [showSearchInput, setShowSearchInput] = useState(false)
  const [editAsset, setEditAsset] = useState(false)
  const [selectedAssets, setSelectedAssets] = useState([])
  const defaultColumn = {label: I18n('t_nzc_data_availability'), value: 'dataAvailablePercentage'}
  const defaultSort = 'highestFirst'
  const columnQuery = getQueryValuesForNZCAssets('column', defaultColumn)
  const sortQuery = getQueryValuesForNZCAssets('sort',defaultSort)
  const [assetsOrderBy, setAssetsOrderBy] = useState(columnQuery)
  const [graphIntensity, setGraphIntensity] = useState()
  const [assetsSortBy, setAssetsSortBy] = useState(sortQuery)
  const assetsOrderByPanel = useRef(null)
  const [isDataQualityInfo, setDataQualityInfo] = useState('data-quality dot')
  const [assetTooltipMessage, setAssetTooltipMessage] = useState(getLocalizedValue(selectedLanguage, 't_asset_data_missing_message'))
  const dataQualityRef = useRef(null)
  const assetValidRef = useRef(null)
  const GRESB_ERROR_MESSAGE = 'Unavailable, asset GRESB sector is missing'
  const COUNTRY_ERROR_MESSAGE = 'Unavailable, asset country is invalid'
  const SOLD_BEFORE_BASELINE_YEAR_ERROR_MESSAGE = 'Unavailable, asset is sold before the baseline year'
  const toolTipContent={
    'data-quality dot':getLocalizedValue(selectedLanguage, 't_raw_data'),
    'data-quality square':getLocalizedValue(selectedLanguage, 't_uplifted_data'),
    'data-quality triangle':getLocalizedValue(selectedLanguage, 't_benchmark_data')
  }
  const op = useRef(null)
  const popoverRef = useRef(false)
  const popoverElement = useRef(null)
  const [isInfo, setInfo] = useState(false)
  const [missingDataFilter, setMissingDataFilter] = useState(false)  //TODO on the time of data override
  const updateAssets = useMutation(updateNzcAssets, {
    onError: () => {
      //TODO
      props.updateGraphLoadingStatus(false)
      confirmDialog({
        message: 'Some assets contain validation errors, please check.',
        header: 'Error',
        draggable: false,
        className:'nzc-assets-error-dialog'
      })
      //TODO
    },
    onSuccess:()=>{
      //TODO
      props.refetchData()
      setEditAsset(false)
    }
  })

  const [showAssetsFilterModal, setShowAssetsFilterModal] = useState(false)
  const ASSETS_FILTER_OPTIONS = Object.freeze({
    allSectors: 'All sectors',
    allCountries: 'All countries',
    allAssets: 'All assets',
    soldAssets: 'Sold assets',
    notIncludedInModel: 'Not included in current NZC model',
    assetWithoutEnergySavingsActionFilter: 'Assets with no energy saving actions specified'
  })
  const ALL_SECTORS_KEY = 't_all_sectors'
  const ALL_COUNTRIES_KEY = 't_all_countries'
  const defaultSectorOption = {label: getLocalizedValue(selectedLanguage, ALL_SECTORS_KEY), value: ASSETS_FILTER_OPTIONS.allSectors}
  const defaultCountryOption = {label: getLocalizedValue(selectedLanguage, ALL_COUNTRIES_KEY), value: ASSETS_FILTER_OPTIONS.allCountries}
  const defaultAssetStatusOption = {label: getLocalizedValue(selectedLanguage, 't_all_assets'), value: ASSETS_FILTER_OPTIONS.allAssets}
  const modalData = {
    selectedSector: getQueryValuesForNZCAssets('sector',defaultSectorOption),
    selectedCountry: getQueryValuesForNZCAssets('country',defaultCountryOption),
    selectedAssetStatus: getQueryValuesForNZCAssets('assetStatus',defaultAssetStatusOption),
    sectorMenuOptions: [defaultSectorOption],
    countryMenuOptions: [defaultCountryOption],
    assetStatus: [defaultAssetStatusOption],
    filteredAssetsLength: props.fundAssets?props.fundAssets.length:0,
    sectorOption: defaultSectorOption,
    countryOption: defaultCountryOption,
    assetStatusOption: defaultAssetStatusOption,
    allSectors: ASSETS_FILTER_OPTIONS.allSectors,
    allCountries: ASSETS_FILTER_OPTIONS.allCountries,
    allAssets: ASSETS_FILTER_OPTIONS.allAssets,
    notIncludedInModel: ASSETS_FILTER_OPTIONS.notIncludedInModel,
    soldAssets: ASSETS_FILTER_OPTIONS.soldAssets,
    assetsFilterOptions: ASSETS_FILTER_OPTIONS,
    assetWithoutEnergySavingsActionFilter : ASSETS_FILTER_OPTIONS.assetWithoutEnergySavingsActionFilter
  }
  const [assetsFilterModalData, setAssetsFilterModalData] = useState(modalData)
  const isModelDataOverridden = props.isModelDataOverridden
  const invalidAssetCountRef = useRef()

  const changeIntensity = (e) => {
    setGraphIntensity(groupedAssetsOrderBy?.itemsShow?.find((intensity) => intensity.value === e.detail.value))
    if (assetsOrderBy.value !== e.detail.value) {
      setAssetsOrderBy(groupedAssetsOrderBy?.itemsShow[0])
    }
  }

  useEffect(() => {
    document.addEventListener('changeIntensity', changeIntensity)
    return () => {
      document.removeEventListener('changeIntensity', () => {})
    }
  }, [])

  useEffect(() => {
    if(props.fundAssets?.length > 0) {
      onFindAssetFilterChange({searchValue: fundAssetFilter})
      const filterScopeAsset = props.fundAssets?.filter(element=> element.asset.isScopeAsset && !isAssetInvalidV2(element))
      invalidAssetCountRef.current = props.fundAssets?.filter(element=> !isAssetInvalidV2(element)).length
      setSelectedAssets(filterScopeAsset)
      onAssetsSortChange(assetsSortBy)
      onAssetsShowColumnsChange(assetsOrderBy)
      // TODO: If dataOverridden true, disable data missing field in asset column filter
      setMissingDataFilter(true)
      fundAssetsRef.current=filterFundAssets(
        assetsFilterModalData?.selectedSector?.value,
        assetsFilterModalData?.selectedCountry?.value,
        assetsFilterModalData?.selectedAssetStatus?.value)
    }
    setFaPlaceholder(getLocalizedValue(selectedLanguage, 't_find_asset'))
  }, [props.fundAssets, assetsSortBy, assetsOrderBy?.label, assetsOrderBy?.value])

  useEffect(() => {
    let queryParams= {
      asset: fundAssetFilter,
      sector: assetsFilterModalData.selectedSector.value,
      country: assetsFilterModalData.selectedCountry.value,
      assetStatus: assetsFilterModalData.selectedAssetStatus.value,
      column: assetsOrderBy?.value,
      sort: assetsSortBy,
    }
    if (graphIntensity) {
      queryParams = { ...queryParams, graphIntensity: graphIntensity?.value}
    }
    queryParamsForFundNzc.value = queryParams
    props.updateUrlQueryParams(queryParams)
  }, [fundAssetFilter, assetsFilterModalData, assetsOrderBy?.value, assetsSortBy, graphIntensity])

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (showSearchInput && searchWrapperRef.current && !searchWrapperRef.current.contains(e.target)) {
        setShowSearchInput(false)
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [showSearchInput])

  useEffect(() => {
    if (props.fundAssets && props.fundAssets?.length > 0) {
      prepareSectorAndCountryMenuOptions(props.fundAssets)
    }
  }, [props.fundAssets])

  useEffect(() => {
    if (isModelDataOverridden) {
      let selectedIntensity = SessionUserState.getUserDataFromSession('graphIntensity') &&
          SessionUserState.getUserDataFromSession('graphIntensity')[props.fundId]
      selectedIntensity === assetsOrderByColumnChange.energyIntensity ?
        setAssetsOrderBy({label: I18n('t_energy_intensity_nzc'), value: 'energyIntensity'})
        : setAssetsOrderBy({label: I18n('t_nzc_carbon_intensity'), value: 'carbonIntensity'})    }
  }, [isModelDataOverridden])

  useLayoutEffect(() => {
    const index = props.fundAssets?.findIndex((e) => e?.asset?.assetId === props?.assetRowClick) || 0
    if (document.querySelectorAll('tr') && index) {
      if (document.querySelectorAll('tr')[index + 1]) {
        document.querySelectorAll('tr')[index + 1]?.scrollIntoView({block: 'center', behavior: 'auto'})
      }
    }
  }, [props.assetRowClick, props.fundAssets])

  useLayoutEffect(()=>{
    if(isInfo){
      let viewport = document.getElementsByClassName('i-icon-size cursor')[0]
      let viewportPosition = viewport.getBoundingClientRect()
    
      let tooltip = document.getElementsByClassName('info-overlay')[0]
      if(tooltip && viewportPosition){
        let tooltipPosition = tooltip?.getBoundingClientRect()
        tooltip.style.left = viewportPosition.left - 30 + 'px'
        
        let tooltipArrow = tooltip.firstChild
        let tooltipArrowPosition = tooltipArrow?.getBoundingClientRect()
        tooltipArrow.style.left = (tooltipPosition.right - tooltipArrowPosition.right + 5) + 'px'
      }
    }
  },[isInfo])

  useLayoutEffect(() => {
    setTimeout(() => {
      if (!popoverRef.current && popoverElement.current) {
        popoverElement.current.click(setInformationHandler)
        popoverRef.current = true
      }
    }, 1000)
  }, [])
  function filterFundAssets(sector, country, assetStatus) {

    let filteredAssets = []
    if (fundAssetsRef.current) {
      filteredAssets = props.fundAssets?.filter(fundAsset => {
        let assetsFilterFlag = false
        if (sector && country && assetStatus && fundAsset.asset) {
          let assetStatusFilterFlag = assetsFilterModalData.allAssets === assetStatus
          if (assetStatus === assetsFilterModalData.notIncludedInModel) {
            assetStatusFilterFlag = !fundAsset.asset.isScopeAsset
          } else if (assetStatus === assetsFilterModalData.soldAssets) {
            assetStatusFilterFlag = fundAsset.asset.isSold
          }
          else if (assetStatus === getLocalizedValue(selectedLanguage, 't_asset_without_energy_saving_action')){
            assetStatusFilterFlag = !props?.actionBasedTPData?.assetIdsOfAssetsWithAgreedActions?.includes(fundAsset.asset.assetId )
          }
          assetsFilterFlag = !!(
            (assetsFilterModalData.allSectors === sector || fundAsset.asset.sector === sector) &&
                      (assetsFilterModalData.allCountries === country || fundAsset.asset.country === country) &&
                      assetStatusFilterFlag
          )
        }
        return assetsFilterFlag
      })
      return filteredAssets
    }
  }
    
  const removeDuplicateOptions = (menuOption, index, menuOptions) =>
    !!menuOption && index === menuOptions.findIndex((argMenuOption) => (!!argMenuOption && argMenuOption.value === menuOption.value))

  function compareMenuOptions(a, b) {
    if (a.label < b.label) {
      return -1
    }
    if (a.label > b.label) {
      return 1
    }
    return 0
  }

  function getLocalizedLabel(labelKey) {
    let label=getLocalizedValue(selectedLanguage, `t_${labelKey?.toLowerCase()}`)
    if(!label){
      label=labelKey
    }
    return label
  }

  function prepareSectorAndCountryMenuOptions(fundAssets) {
    if (fundAssets) {
      let sectorOptions = []
      let countryOptions = []
      let assetStatusOptions = []
      fundAssets.forEach(fundAsset => {
        countryOptions.push({label: fundAsset.asset.country, value: fundAsset.asset.country})
        sectorOptions.push({label: getLocalizedLabel(fundAsset.asset.sector), value: fundAsset.asset.sector})

      })
      sectorOptions = sectorOptions.filter(removeDuplicateOptions)
      countryOptions = countryOptions.filter(removeDuplicateOptions)
      assetStatusOptions.push(
        {label: getLocalizedValue(selectedLanguage, 't_not_included_in_model'), value: ASSETS_FILTER_OPTIONS.notIncludedInModel},
        {label: getLocalizedValue(selectedLanguage, 't_sold_assets'), value: ASSETS_FILTER_OPTIONS.soldAssets},
        {label: getLocalizedValue(selectedLanguage, 't_asset_without_energy_saving_action'), value: ASSETS_FILTER_OPTIONS.assetWithoutEnergySavingsActionFilter},
      )

      let sortedSectorList=[defaultSectorOption, ...sectorOptions]
      let sortedCountryList=[defaultCountryOption, ...countryOptions]
      let assetStatusList=[defaultAssetStatusOption, ...assetStatusOptions]
      sortedSectorList=sortedSectorList.sort(compareMenuOptions)
      sortedCountryList=sortedCountryList.sort(compareMenuOptions)
      setAssetsFilterModalData({
        ...assetsFilterModalData,
        sectorMenuOptions: sortedSectorList,
        countryMenuOptions: sortedCountryList,
        assetStatusOptions: assetStatusList,
      })
    }
  }

  const searchFilterExpand = () => {
    setShowSearchInput(true)
  }

  const onFindAssetFilterChange = (event) => {
    const selectedFilterValue = event.target ? event.target.value : event.searchValue
    doFilter(selectedFilterValue)
  }

  function doFilter(searchTermFilterValue) {
    fundAssetsRef.current = getFilteredData(searchTermFilterValue)
    setFundAssetFilter(searchTermFilterValue)
  }

  function listFilter(listToBeFiltered, filterValue) {
    filterValue = filterValue.toLowerCase()
    filterValue = filterValue.replace(/[%,+-]/g, '')
    return listToBeFiltered.filter((f) => {
      if (f.asset.assetName.toLowerCase().indexOf(filterValue) > -1) {return true}
      if (f.asset.city.toLowerCase().indexOf(filterValue) > -1) {return true}
      if (f.asset.country.toLowerCase().indexOf(filterValue) > -1) {return true}
    })
  }

  const getFilteredData = (searchTermFilterValue) => {
    return listFilter(props.fundAssets, searchTermFilterValue)
  }

  const clearSearch = () => {
    doFilter('')
    setFundAssetFilter('')
    setShowSearchInput(false)
  }

  function isAssetInvalidV2(rowData){
    const invalidReasons = rowData.asset?.invalidReasons || []
    const hasInvalidReasons = invalidReasons.length > 0
    const hasErrorMessageOtherThanSoldBeforeBaselineYearError = invalidReasons.some(error => error !== SOLD_BEFORE_BASELINE_YEAR_ERROR_MESSAGE)

    return featureState.sieraplus_nzcAssetSoldAndPurchased ? hasInvalidReasons : hasInvalidReasons && hasErrorMessageOtherThanSoldBeforeBaselineYearError
  }

  function onValidationMessageClick(invalidReasons,event){
    if(invalidReasons){
      event.preventDefault()
      event.stopPropagation()
    }
  }

  const rowClass = (rowData) => {
    let invalidAsset=!(rowData?.asset.isScopeAsset) || isAssetInvalidV2(rowData)
    let scopeAssets = !!(rowData?.asset.isScopeAsset)
    if(editAsset){
      invalidAsset=isAssetInvalidV2(rowData)
    }

    return {
      'invalid-asset' : !isAssetInvalidV2(rowData) && invalidAsset,
      'scope-asset': scopeAssets,
      'invalid-asset-1': isAssetInvalidV2(rowData),
      'row-click-highlight' : `${props.assetRowClick}`.split('_')[0] === `${rowData.asset.assetId}`
    }
  }

  const totalAssetLength = () =>{
    return props.fundAssets?.length || 0
  }
    
  const notSelectedAssetLength = () =>{
    return (totalAssetLength() - (selectedAssets?selectedAssets.length:0))
  } 

  function getAssetStatus(rowData) {
    let assetStatus=''
    if(isAssetWithErrorOtherThanSoldBeforeBaselineYearError(rowData)){
      assetStatus=`[ ${getLocalizedValue(selectedLanguage, 't_data_warning')} ]`
    }
    return assetStatus
  }

  const isAssetWithGresbOrCountryErrorMessage = (rowData) => {
    return (rowData.asset?.invalidReasons.includes(GRESB_ERROR_MESSAGE) || rowData.asset?.invalidReasons.includes(COUNTRY_ERROR_MESSAGE))
  }
  const isAssetWithErrorOtherThanSoldBeforeBaselineYearError = (rowData) => {
    const invalidReasons = rowData.asset?.invalidReasons || []
    return invalidReasons.some(error => error !== SOLD_BEFORE_BASELINE_YEAR_ERROR_MESSAGE)
  }
  const assetColumnTemplate = (rowData) => {
    let tooltipPosition
    if(isAssetWithErrorOtherThanSoldBeforeBaselineYearError(rowData)){
      tooltipPosition ='right-11 top+1'
    }
    else{
      tooltipPosition='right-48 top+1'
    }
    return (
      <>
        <Tooltip className={'portfolio-comm-tooltip long-text-tooltip nzc-data-asset-tooltip'} target={`.nzc-asset-name-tooltip-${rowData?.asset?.assetId}`} />
        <div className='py-2'>
          <div className="grid col-12 no-padding cursor">
            <div className="col-3 no-padding fund-asset-image-container">
              <img
                src={getFillerImage(rowData.asset)}
                alt="Asset-Image"
                className={`fund-asset-image ${rowData.asset.assetImageThumbnailUri ? 'image-actual' : 'image-filler'}`}
                onError={({currentTarget}) => {
                  currentTarget.onerror = null
                  currentTarget.src = getFiller(rowData.asset.sector)
                }}
              />
            </div>
            <div className="col-9 no-padding fund-asset-details">
              <div className={`asset-name nzc-asset-name-tooltip-${rowData?.asset?.assetId}`} data-ptf-tooltip={rowData.asset.assetName} data-pr-position="top" data-pr-my="left-104 top-69" onMouseEnter={(e)=>isTextOverflown(e)}>{rowData.asset.assetName}</div>
              <div className="country-name">{rowData.asset.city}, {rowData.asset.country}</div>
              <div className={`validation-message ${isAssetWithErrorOtherThanSoldBeforeBaselineYearError(rowData)}`}
                onClick={(e)=>onValidationMessageClick(rowData.asset?.invalidReasons,e)}
                onMouseOver={(e) => {
                  if(isAssetWithErrorOtherThanSoldBeforeBaselineYearError(rowData)){
                    if(isAssetWithGresbOrCountryErrorMessage(rowData)){
                      setAssetTooltipMessage(getLocalizedValue(selectedLanguage, 't_no_gresb_sector_error_message'))
                      assetValidRef.current.show(e)
                    }
                    else{
                      setAssetTooltipMessage(getLocalizedValue(selectedLanguage, 't_asset_data_missing_message'))
                      assetValidRef.current.show(e)
                    }
                  }
                }}
                onMouseLeave={(e) => {
                  assetValidRef.current.hide(e)
                }}
                data-pr-classname={'nzc-data-quality-tooltip'}
                data-pr-at={tooltipPosition}
              >{getAssetStatus(rowData)}</div>
            </div>
          </div>
          <div>
            {
              rowData.asset.dateOfSale ? <CustomBadge message={`${getLocalizedValue(selectedLanguage, 't_sold_label')} ${getLocalizedDateMessage(rowData.asset.dateOfSale, 'MONTH_YEAR', selectedLanguage)}`} styling='sold-label'  /> : null
            }
          </div>
          
        </div>
        
      </>
    )
  }

  const percentageColumnTemplate = (rowData) => {
    let percentageValue
    let imageSrc
    let className
    let tooltipPosition
    let roundedPercentage = Math.trunc(rowData.dataAvailablePercentage * 100)
    if(assetsOrderBy?.value === assetsOrderByColumnChange.carbonIntensity) {
      percentageValue = Math.trunc(rowData.carbonIntensity).toLocaleString(selectedLanguage, {maximumFractionDigits: 2 })
    } else if(assetsOrderBy?.value === assetsOrderByColumnChange.energyIntensity) {
      percentageValue = Math.trunc(rowData.energyIntensity).toLocaleString(selectedLanguage, {maximumFractionDigits: 2 })
    } else if(assetsOrderBy?.value === assetsOrderByColumnChange.gav){
      percentageValue = bigNumberFormatter(rowData.asset.gav,selectedLanguage)
    } else{
      percentageValue = rowData.dataAvailablePercentage === null ? '0 %' : `${roundedPercentage} %`
    }
        
    if(roundedPercentage>=50 && roundedPercentage<=99){
      className = 'square'
      imageSrc = SquareIcon
      tooltipPosition = 'right+18 top-2'
    }
    else if(roundedPercentage<=49){
      className = 'triangle'
      imageSrc = triangleIcon
      tooltipPosition = 'right+36 top-2'
    }
    else{
      className = 'dot'
      imageSrc = dot
      tooltipPosition = 'right+4 top-2'
    }
    return (
      <div className={'no-padding cursor missing-data'}>
        {assetsOrderBy?.value === assetsOrderByColumnChange.dataAvailablePercentage && <span><img className={`data-quality ${className}`} src={imageSrc} alt={'DataQualityIcon'}
          data-pr-classname={'nzc-data-quality-tooltip'} data-pr-at={tooltipPosition}
          onMouseEnter={(e) => {
            setDataQualityInfo(e.currentTarget.className)
            dataQualityRef.current.show(e)
          }}
          onMouseLeave={(e) => {
            dataQualityRef.current.hide(e)
          }}/></span>}
        <div className="missing-data-percentage">{percentageValue}</div>
      </div>
    )
  }

  const onSaveAssets = async () => {
    const selectedAssetIds = selectedAssets.map(asset => asset.asset.assetId)
    SessionUserState.saveUserDataToSession('modelButton', !selectedAssetIds.length > 0)
    props.updateGraphLoadingStatus(true)
    props.updateNzcGraphData(null)
    await updateAssets.mutateAsync({fundGroupId: props.fundGroupId, scopeId: props.nzcScopeId, selectedAssetIds})
    props.reloadNzcData()
  }

  const onEditAsset = () => {
    setEditAsset(true)
    closeAssetPanel()
    props.setAssetRowClick()
  }

  const onCancel = () => {
    setEditAsset(false)
    const filterScopeAsset = props.fundAssets?.filter(element=> element.asset.isScopeAsset && !isAssetInvalidV2(element))
    setSelectedAssets(filterScopeAsset)
  }

  function assetFilterClickHandler() {
    setShowAssetsFilterModal(!showAssetsFilterModal)
  }

  function viewNotIncludedAssetsHandler(assetFilter) {
    op.current.hide()
    if(assetFilter==='not-included-assets'){
      setAssetsFilterModalData({
        ...assetsFilterModalData,
        selectedAssetStatus: {label: getLocalizedValue(selectedLanguage, 't_not_included_in_model'), value: ASSETS_FILTER_OPTIONS.notIncludedInModel},
        selectedSector: defaultSectorOption,
        selectedCountry: defaultCountryOption
      })
      fundAssetsRef.current = filterFundAssets(
        ASSETS_FILTER_OPTIONS.allSectors,
        ASSETS_FILTER_OPTIONS.allCountries,
        ASSETS_FILTER_OPTIONS.notIncludedInModel
      )
    }
  }

  function getFiltersCount() {
    let filterCount=0
    if(assetsFilterModalData.selectedSector.value!==ASSETS_FILTER_OPTIONS.allSectors){
      filterCount+=1
    }
    if(assetsFilterModalData.selectedCountry.value!==ASSETS_FILTER_OPTIONS.allCountries){
      filterCount+=1
    }
    if(assetsFilterModalData.selectedAssetStatus.value!==ASSETS_FILTER_OPTIONS.allAssets){
      filterCount+=1
    }
    return filterCount
  }

  function onAssetsShowColumnsChange(item){
    setAssetsOrderBy({label: item?.label, value: item?.value})
    props.setAssetOrderByValue({label: item?.label, value: item?.value})
    assetsOrderByPanel.current.hide()
  }

  function onAssetsSortChange(value){
    if(value === assetsOrderByColumnChange.lowestFirst) {
      if(assetsOrderBy?.value === assetsOrderByColumnChange.gav){
        fundAssetsRef.current = props.fundAssets?.sort((a, b) => (a.asset.gav - b.asset.gav || a.asset.isScopeAsset - b.asset.isScopeAsset))
      } else{
        fundAssetsRef.current = props.fundAssets?.sort((a, b) => (a[assetsOrderBy?.value] - b[assetsOrderBy?.value] || a.asset.isScopeAsset - b.asset.isScopeAsset))
      }
    } else {
      if(assetsOrderBy?.value === assetsOrderByColumnChange.gav){
        fundAssetsRef.current = props.fundAssets?.sort((a, b) => (b.asset.gav - a.asset.gav || b.asset.isScopeAsset - a.asset.isScopeAsset))
      } else{
        fundAssetsRef.current = props.fundAssets?.sort((a, b) => (b[assetsOrderBy?.value] - a[assetsOrderBy?.value] || b.asset.isScopeAsset - a.asset.isScopeAsset))
      }
    }
    setAssetsSortBy(value)
    assetsOrderByPanel.current.hide()
  }
  const graphIntensityValue = SessionUserState.getUserDataFromSession('graphIntensity')
  const itemsShow = useMemo(() => groupedAssetsOrderBy.itemsShow.reduce((a, b) => {
    const requiredValue = () => (graphIntensityValue[props.fundId] === b.value || !graphIntensityValue[props.fundId]) && a.push(b)
    switch(b.value) {
    case assetsOrderByColumnChange.carbonIntensity: featureState?.sieraplus_nzcFundEnergyIntensity && graphIntensityValue ? requiredValue() : a.push(b); break
    case assetsOrderByColumnChange.energyIntensity: graphIntensityValue && graphIntensityValue[props.fundId] === b.value && b.value === assetsOrderByColumnChange.energyIntensity && featureState?.sieraplus_nzcFundEnergyIntensity && a.push(b); break
    default: a.push(b); break
    }
    return a
  }, []), [graphIntensityValue])
  const assetsOrderByTemplate = (option, itemsShow) => {
    return (
      <>
        <div className={'overlay-item-group'}>{option.labelShow}:</div>
        {itemsShow?.map(item => {
          const baselineYearCondition = item.value === assetsOrderByColumnChange.dataAvailablePercentage || item.value === assetsOrderByColumnChange.carbonIntensity || item.value === assetsOrderByColumnChange.energyIntensity
          const baseLineYearLabel = baselineYearCondition ? reportingYear : ''
          const overrideClass = item.value === 'dataAvailablePercentage' && isModelDataOverridden ? 'overridden-data' : ''
          return (
            <div key={item.value} className={`${overrideClass} ${item.value === assetsOrderBy?.value ? 'overlay-item highlight' : 'overlay-item'}`}
              onClick={() => onAssetsShowColumnsChange(item)}>{item.label} {baseLineYearLabel}</div>
          )
        })}
        <div className={'border-line'}/>
        <div className={'overlay-item-group'}>{option.labelSort}:</div>
        {option.itemsSort.map(item => (
          <div key={item.value} className={item.value === assetsSortBy ? 'overlay-item highlight' : 'overlay-item'}
            onClick={() => onAssetsSortChange(item.value)}>{item.label}</div>
        ))}
      </>
    )
  }

  function onAssetRowClick(e) {
    if(editAsset || props.chartOptions.assetsPlotData === false || props.isDataLoading || isAssetInvalidV2(e.data) || !(e.data?.asset?.isScopeAsset)) {
      return null
    } else {
      props.setAssetRowClick(props.previousClickedAssetRow.current === e.data.asset?.assetId ? '' : e.data.asset?.assetId)
      closeFunction(document.querySelector('.asset-detail-panel'), 'remove')
      if (props.previousClickedAssetRow.current === e.data.asset?.assetId) {
        // To remove the asset id if its already selected
        props.clearPreviousAssetId(props.previousClickedAssetRow.current === e.data.asset?.assetId)
      } else {
        closeFunction(document.querySelector('.asset-detail-panel'), 'none')
        // this is to close the pop up after clicking on new asset and open again with new data
        setTimeout(() => {
          closeFunction(document.querySelector('.asset-detail-panel'), 'block')
        }, 1000)
      }
      props.setIsAssetRowClicked(!props.isAssetRowClicked)
      setAssetData(e.data.asset?.assetName)

      // added js code for to load asset panel tooltip in chart
      let assetColumnClass = document.querySelector('.row-click')
      if(assetColumnClass) {
        assetColumnClass.classList.add('row-click-highlight')
      }
    }
  }

  const header = () => {
    const searchRef = fundAssetFilter.length > 0 ? null : searchWrapperRef
    return (
      <>
        <div className={`assets-table-header grid ${props.fundAssets?.length ? '' : 'disabled'}`}>
          <div className="filter-block grid" ref={searchRef}>
            <span className="p-filter assets-filter-icons">
              <i className="pi pi-filter-fill cursor filter-icon" style={{fontSize: '17px', color: CSS_VARIABLES.forest}} onClick={assetFilterClickHandler} />
              <span className="filter-type-text" onClick={assetFilterClickHandler} data-testid="assets-filter-title">{I18n('t_assets_filter_title')}</span>
              {getFiltersCount() > 0 && <Badge value={getFiltersCount()} />}
            </span>
            {showSearchInput ? (
              <span className="p-search-right">
                <i className="pi pi-search cursor" style={{fontSize: '17px', color: CSS_VARIABLES.white}}/>
                <InputText ref={gf => gf && gf.focus()} value={fundAssetFilter} onChange={onFindAssetFilterChange}
                  className="input-text" placeholder={faPlaceholder}/>
                {fundAssetFilter !== '' && (
                  <img src={ClearIcon} alt="Clear" className="clear-icon cursor highlight-clickable-opacity"
                    onClick={clearSearch}/>
                )}
              </span>
            ) : (
              <span className="p-filter" onClick={searchFilterExpand}>
                <i className="pi pi-search cursor filter-icon" style={{fontSize: '17px', color: CSS_VARIABLES.forest}}/>
                <span className="filter-type-text" data-testid="filter-type-text">{I18n('t_search')}</span>
              </span>
            )}
          </div>
        </div>
      </>

    )}

  function disableSaveChangesButton() {
    let someValidAsset = false
    if (props.fundAssets) {
      someValidAsset = props.fundAssets?.some(fundAsset => {
        const {asset} = fundAsset
        let isSomeAssetValid = false
        if (asset) {
          isSomeAssetValid = !asset.invalidReasons
        }
        return (isSomeAssetValid)
      }
      )
    }
    return (!someValidAsset)
  }

  function setInformationHandler(e){
    if(totalAssetLength() !== selectedAssets.length){
      setInfo(true)                   
      op.current.show(e)
    }
  }

  function assetsInModelHandler(){
    function isAssetEditable(){
      return invalidAssetCountRef.current ? <ButtonWrapper className={`scope-edit clickable-highlight ${props.isFileImported ? 'disabled' : ''} `} onClick={onEditAsset} data-testid="scope-edit">{I18n('t_edit')}</ButtonWrapper> : <></>
    }
    return (
      <div className={`asset-table-footer ${totalAssetLength() ? '' : 'disabled'}`}>           
        {editAsset ? <Button data-testid='save-button' label={I18n('t_save_changes')} onClick={onSaveAssets} disabled={disableSaveChangesButton()} className={'common-blue-bg-btn save-button button-highlight'} /> : null}
        <OverlayPanel className="info-overlay nzc-assets-overlay-panel" ref={op} showCloseIcon onHide={()=>{setInfo(false)}} visible={isInfo} style={{width: '14em'}}>
          <div className="help-block"> 
            <img src={infoWhite} alt={getLocalizedValue(selectedLanguage, 't_info')} className="i-icon-size cursor" /> 
            {notSelectedAssetLength()}&nbsp;
            {getLocalizedValue(selectedLanguage, 't_assets_not_in_model')}
          </div>
          <div className="help-text">
            {getLocalizedValue(selectedLanguage, 't_reason_assets_not_in_model')}
          </div>
          <div onClick={()=>viewNotIncludedAssetsHandler('not-included-assets')} className="view-assets">
            {getLocalizedValue(selectedLanguage, 't_apply_filter_view_these_assets')}
          </div>
        </OverlayPanel>
        {notSelectedAssetLength() > 0 && <img src={infoWhite} alt={getLocalizedValue(selectedLanguage, 't_info')} className="i-icon-size cursor popover-information" onClick={setInformationHandler} ref={popoverElement} />}
        <div className={editAsset ? 'cursor assets-count-text' : 'cursor'} onClick={setInformationHandler}>
          {selectedAssets ? selectedAssets.length :0 }&nbsp;/&nbsp;{totalAssetLength()} {I18n('t_assets_in_the_model')}
        </div>
                
        {editAsset ?
          <div className='scope-edit scope-cancel clickable-highlight' onClick={onCancel} data-testid="scope-cancel">{I18n('t_cancel')}</div> :
          isAssetEditable()
        }
      </div>
    )
  }

  function getTableHeaderNode() {
    const baselineYearCondition = assetsOrderBy?.value === assetsOrderByColumnChange.dataAvailablePercentage || assetsOrderBy?.value === assetsOrderByColumnChange.carbonIntensity || assetsOrderBy?.value === assetsOrderByColumnChange.energyIntensity
    const baseLineYearLabel = baselineYearCondition ? reportingYear : ''
    return(
      <div className={'assets-header'}>
        <div aria-haspopup className={`assets-order-by cursor ${props.fundAssets?.length ? '' : 'disabled'}`}>
          <span onClick={(e) => assetsOrderByPanel.current.toggle(e)}>
            <span data-testid="dropdown-dataAvailable">{assetsOrderBy?.label} {baseLineYearLabel}</span>
            <i aria-haspopup aria-controls="assetsOrderByPanel" className="pi pi-angle-down order-by-trigger-icon" />
          </span>

          <OverlayPanel ref={assetsOrderByPanel} id="assetsOrderByPanel" className="assets-order-by-panel">
            {assetsOrderByTemplate(groupedAssetsOrderBy, itemsShow)}
          </OverlayPanel>
        </div>
      </div>
    )
  }

  function isRowSelectable(rowData){
    return rowData.data? !isAssetInvalidV2(rowData.data) : true
  }
  return (
    <div className={'nzc-assets-container'}>
      {header()}
      <Tooltip
        className='nzc-data-quality-tooltip'
        event='hover'
        content={toolTipContent[isDataQualityInfo]}
        position='top'
        ref={dataQualityRef}
      />
      <Tooltip
        className='nzc-data-quality-tooltip'
        event='hover'
        content={assetTooltipMessage}
        position='top'
        ref={assetValidRef}
      />
      <div className="nzc-assets-table-container">
        <DataTable
          value={fundAssetsRef.current}
          className={`nzc-asset-datatable ${editAsset ? 'nzc-asset-datatable-edit' : 'nzc-asset-datatable-view'}`}
          onSelectionChange={(e) => setSelectedAssets(e.value)}
          selection={selectedAssets}
          selectionMode="single"
          stripedRows
          scrollHeight="640px"
          scrollable
          rowClassName={rowClass}
          showSelectionElement={rowData => isAssetInvalidV2(rowData) ? null : !isAssetInvalidV2(rowData)}
          emptyMessage={getLocalizedValue(selectedLanguage, 't_no_assets')}
          onRowClick={onAssetRowClick}
          tableStyle={{tableLayout: 'fixed'}}
          header={getTableHeaderNode}
          isDataSelectable={isRowSelectable}
        >
          {editAsset ? <Column className={'nzc-assets-checkbox'} selectionMode="multiple" style={{width: '15%'}} />:null}
          <Column field="asset" body={assetColumnTemplate} className={'asset-column'} style={{'width': '73%'}}/>
          <Column field="missingPercentage" body={percentageColumnTemplate} className={'data-missing-column'} style={{'width': '27%'}}/>
        </DataTable>
      </div>
      {assetsInModelHandler()}
      {showAssetsFilterModal && <NzcAssetsFilterModal
        showFilterModal={showAssetsFilterModal}
        setShowAssetsFilterModal={setShowAssetsFilterModal}
        fundAssets={props.fundAssets}
        fundAssetsRef={fundAssetsRef}
        assetsFilterModalData={assetsFilterModalData}
        setAssetsFilterModalData={setAssetsFilterModalData}
        filterFundAssets={filterFundAssets}
      />}
    </div>
  )
}

NZCAssets.propTypes = {
  fundAssets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      sector: PropTypes.string,
      country: PropTypes.string,
      assetStatus: PropTypes.string,
      baselineYear: PropTypes.number,
      baselineValue: PropTypes.number,
      currentValue: PropTypes.number,
      missingPercentage: PropTypes.number,
    })
  ).isRequired,
  reportingYear: PropTypes.number,
  refetchData: PropTypes.func,
  fundGroupId: PropTypes.number,
  nzcScopeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setAssetOrderByValue: PropTypes.func,
  updateGraphLoadingStatus: PropTypes.func,
  updateNzcGraphData: PropTypes.func,
  updateUrlQueryParams: PropTypes.func,
  assetRowClick: PropTypes.number,
  setAssetRowClick: PropTypes.func,
  chartOptions: PropTypes.object,
  isDataLoading: PropTypes.bool,
  isModelDataOverridden: PropTypes.bool,
  reloadNzcData: PropTypes.func,
  isFileImported: PropTypes.bool,
  actionBasedTPData: PropTypes.object,
  isAssetRowClicked: PropTypes.bool,
  setIsAssetRowClicked: PropTypes.func,
  previousClickedAssetRow: PropTypes.object,
  clearPreviousAssetId: PropTypes.func,
}

NZCAssets.displayName = 'NZCAssets'

export default React.memo(NZCAssets)