
import PropTypes from 'prop-types'
import './DataQualityCoverageGraph.scss'
import {Chart} from 'primereact/chart'
import {Icon, Text} from '../../../../atomic'
import {formatPercentage, getLocalizedValue} from '../../../../../utils/helpers/Helper'
import {getCoverageChartData, getCoverageChartOptions} from '../data-quality-hooks-&-helpers/dataQualityHelper'
import { isEmptyObject } from 'utils/helpers/Utils'
import DataCoverageSkeleton from './skeletons/DataCoverageSkeleton'
import { getMinOrRoundedValue } from 'services/common/formatter'

const DataQualityCoverageGraph = ({coverageData, selectedLanguage, areaUnit, actionHandler, isLoading}) => {
  if(isLoading) {
    return <DataCoverageSkeleton />
  }
  if(!coverageData || isEmptyObject(coverageData)) {
    return (
      <div className="dq-coverage flex align-items-center">
        <div className="dq-coverage__legends">
          <div className="dq-coverage__legends__d-coverage flex align-items-center">
            <Text content={`0% ${getLocalizedValue(selectedLanguage, 't_data_coverage')}`} size="xs" />
            <Icon id="info_solid" size={17} onClickHandler={actionHandler} className='cursor' />
          </div>
        </div>
      </div>
    )
  }
  const {areaCovered, areaNotCovered, coveragePercentage} = coverageData

  return (
    <div className="dq-coverage flex align-items-center">
      {(areaCovered !== 0 || areaNotCovered !== 0) && (
        <div className="dq-coverage__chart">
          <Chart type="doughnut" data={getCoverageChartData(coverageData)} options={getCoverageChartOptions()} className='w-6rem h-6rem'/>
        </div>
      )}
      <div className="dq-coverage__legends">
        <div className="dq-coverage__legends__d-coverage flex align-items-center">
          <Text content={`${formatPercentage(coveragePercentage )}% ${getLocalizedValue(selectedLanguage, 't_data_coverage')}`} size="xs" />
          <Icon id="info_solid" size={17} onClickHandler={actionHandler} className='cursor' />
        </div>
        {(areaCovered !== 0 || areaNotCovered !== 0) && (
          <>
            <div className="dq-coverage__legends__a-coverage flex align-items-center">
              <Icon id="square_solid" colour="blue" size={10} />
              <Text content={`${getMinOrRoundedValue(areaCovered).toLocaleString(selectedLanguage)}${areaUnit} ${getLocalizedValue(selectedLanguage, 't_coverage_by_floor_area')}`} size="xs" />
            </div>
            <div className="dq-coverage__legends__a-unaccounted flex align-items-center">
              <Icon id="square_solid" colour="forest_20" size={10} />
              <Text content={`${getMinOrRoundedValue(areaNotCovered).toLocaleString(selectedLanguage)}${areaUnit} ${getLocalizedValue(selectedLanguage, 't_floor_area_unaccounted_for')}`} size="xs" />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

DataQualityCoverageGraph.propTypes = {
  coverageData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  selectedLanguage: PropTypes.string,
  areaUnit: PropTypes.string,
  actionHandler: PropTypes.func,
  isLoading: PropTypes.bool
}

DataQualityCoverageGraph.displayName = 'DataQualityCoverageGraph'

export default DataQualityCoverageGraph