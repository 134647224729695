import {useContext, useEffect, useMemo} from 'react'
import {useMutation} from 'react-query'
import {useHistory, useLocation, useParams} from 'react-router-dom'
import './DataQualityHomeV2.scss'
import {periodOptions} from '../../../../../services/common/datepicker-service'
import {AREA, LANDLORD, PERIOD_TYPES, TENANT,} from '../../../../../utils/helpers/Constants'
import AssetLayout from '../../../../../layouts/asset/AssetLayout'
import {Button, Text} from '../../../../atomic'
import {FeatureFlagContext, PortfolioContext} from '../../../../../Contexts'
import {createEndMonthParam, getLocalizedValue, getUrlParams, unitsConverter} from '../../../../../utils/helpers/Helper'
import {useLoginAuthContext} from '../../../login/auth0/UserInfoProvider'
import useCustomState from '../../../../common/custom-hooks/useCustomState'
import {createMissingDataReqObjectV2, getDQInitialState} from '../data-quality-hooks-&-helpers/dataQualityHelper'
import DataQualityContent from '../dq-components/DataQualityContent'
import {datePickerQueryValues, filterQueryParams} from '../../../../../utils/query-params/QueryParams'
import useDataQualityHomeDataV2 from '../data-quality-hooks-&-helpers/useDataQualityHomeDataV2'
import useAssetCoverageCompletenessData from '../../../../../utils/custom-hooks/data-quality/useAssetCoverageCompletenessData'
import DataQualityMeterView from '../dq-components/DataQualityMeterView'
import {useDebounce} from 'use-debounce'
import { addNewMissingDataRequest, addNewMissingDataRequestInternal } from 'services/assets/dataquality-service'
import useErrorHandling from 'components/common/error-handling/ErrorHandling'
import { useI18n } from 'utils/i18n/I18n'

const DataQualityHomeV2 = () => {
  const queryValues = getUrlParams(useLocation().search)
  const i18n = useI18n()
  const {assetId} = useParams()
  const history = useHistory()
  const {featureState: {sieraplus_assetDataQualityLayoutV2}} = useContext(FeatureFlagContext)
  const { pfState: {data: rowData} } = useContext(PortfolioContext)
  const {loginState: {userInfo: {languagePreference: selectedLanguage, unitSystem}}} = useLoginAuthContext()
  const areaLabel = unitsConverter(AREA, unitSystem)
  const { errorDialog } = useErrorHandling()

  const {
    customState: dataQualityState,
    updateCustomState: updateDataQualityState
  } = useCustomState(() => getDQInitialState(queryValues))

  const {
    timePeriod,
    timeNoOfMonthsPeriod,
    timeEndDatePeriod,
    pickerValue,
    tabIndex,
    categorySelectedLandlord,
    utilitySelectedLandlord,
    areaSelectedLandlord,
    categorySelectedTenant,
    utilitySelectedTenant,
    areaSelectedTenant,
    meterUtility,
    meterArea,
    tenantFirst,
    landlordFirst,
    tenantRows,
    landlordRows,
    searchMeterNameLandlord,
    searchMeterNameTenant,
  } = dataQualityState

  const apiParamsV2 = {
    timePeriod,
    timeNoOfMonthsPeriod,
    timeEndDatePeriod,
    meterUtility,
    meterArea,
    utilitySelectedLandlord: utilitySelectedLandlord,
    areaSelectedLandlord: areaSelectedLandlord,
    utilitySelectedTenant: utilitySelectedTenant,
    areaSelectedTenant: areaSelectedTenant,
    categorySelectedLandlord: categorySelectedLandlord,
    categorySelectedTenant: categorySelectedTenant,
    tenantFirst,
    landlordFirst,
    tenantRows,
    landlordRows
  }

  const apiParamsSearch = useMemo(() => ({
    searchMeterNameLandlord,
    searchMeterNameTenant
  }), [searchMeterNameLandlord, searchMeterNameTenant])

  const [deferredParamsSearch] = useDebounce(apiParamsSearch, 500)

  const responsibilities = {
    landlord: LANDLORD,
    tenant: TENANT,
    both: null
  }

  const {
    assetMetersLandlord, 
    assetMetersLandlordLoading, 
    assetMetersTenant, 
    assetMetersTenantLoading, 
    assetMetersDetails,
    assetMetersDetailsLoading
  } = useDataQualityHomeDataV2(assetId, {...apiParamsV2, ...deferredParamsSearch}, responsibilities, unitSystem)

  const {
    assetCompletenessLandlord: {data: assetCompletenessLandlordData, isLoading: assetCompletenessLandlordLoading},
    assetCoverageLandlord: {data: assetCoverageLandlordData, isLoading: assetCoverageLandlordLoading},
    assetCompletenessTenant: {data: assetCompletenessTenantData, isLoading: assetCompletenessTenantLoading},
    assetCoverageTenant: {data: assetCoverageTenantData, isLoading: assetCoverageTenantLoading}
  } = useAssetCoverageCompletenessData(assetId, apiParamsV2, responsibilities, sieraplus_assetDataQualityLayoutV2, unitSystem)

  const addMissingDataMutation = useMutation(addNewMissingDataRequestInternal, {
    onSuccess: (data) => {
      if(data){
        window.open(data)
      } else {
        errorDialog({ title: i18n.formatMessage({ id: 't_title_cannot_request_data' }), message: getLocalizedValue(selectedLanguage, 't_no_redirect_data')})
      }
    }
  })

  const requestMissingDataMutation = useMutation(addNewMissingDataRequest)

  const sendDataHandler = async (emails, data, onSuccess) => {
    try {
      await requestMissingDataMutation.mutateAsync({
        assetId,
        emails: emails,
        dataGap: createMissingDataReqObjectV2(data),
      })
      onSuccess()
    } catch (error) {
      console.log(error.toString())
    }
  }

  const onEnterDataHandler = async (selectedRows, onSuccess) => {
    try {
      await addMissingDataMutation.mutateAsync({assetId, dataGap: createMissingDataReqObjectV2(selectedRows)})
      onSuccess()
    } catch (error) {
      errorDialog({ title: i18n.formatMessage({ id: 't_title_cannot_request_data' }), message: error.toString()})
    }
  }

  useEffect(()=>{
    const datePickerValue = datePickerQueryValues(timePeriod, pickerValue)

    let queryParams = {
      view: tabIndex === 1 ? 'summary' : 'meter',
      timePeriod: datePickerValue.timePeriodSelected,
      noOfMonths: datePickerValue.noOfMonths,
      endMonth: datePickerValue.endMonth,
      endYear: datePickerValue.endYear,
    }

    if(tabIndex === 1){
      queryParams={
        ...queryParams,
        landlordCategory:  categorySelectedLandlord,
        landlordUtility: utilitySelectedLandlord,
        landlordArea: areaSelectedLandlord,

        tenantCategory:  categorySelectedTenant,
        tenantUtility: utilitySelectedTenant,
        tenantArea: areaSelectedTenant,
      }
    } else {
      queryParams={
        ...queryParams,
        meterUtility: meterUtility,
        meterArea: meterArea,
      }
    }

    filterQueryParams(history, queryParams, rowData)
  }, 
  [
    timePeriod, 
    pickerValue, 
    categorySelectedLandlord, 
    utilitySelectedLandlord, 
    areaSelectedLandlord, 
    categorySelectedTenant, 
    utilitySelectedTenant, 
    areaSelectedTenant, 
    meterUtility, 
    meterArea, 
    tabIndex
  ])
  //endregion

  const getPeriodMenuItem = (option) => (option === PERIOD_TYPES.YTD) ?
    <span className='p-button-label p-c period-menu-ytd-tooltip' data-pr-position="top">{option}</span>
    :
    <span className='p-button-label p-c'>{option}</span>


  function pickerOnChangeHandler(event) {
    const pickerValueUpdated = {...pickerValue, [event?.target?.name]: event?.target?.value}
    updateDataQualityState({
      timeNoOfMonthsPeriod: pickerValueUpdated.pickerValueMonths,
      timeEndDatePeriod: createEndMonthParam(pickerValueUpdated.pickerValueEndYear, pickerValueUpdated.pickerValueEndMonth),
      timePeriod: PERIOD_TYPES.CUSTOM,
      pickerValue: pickerValueUpdated
    })
  }

  function getBackNavigationProps() {
    return {
      period: timePeriod,
      options: periodOptions,
      datePickerOnChange: (e) => updateDataQualityState({timePeriod: e.value}),
      customDatePickerOnClick: () => {},
      pickerOnChange: pickerOnChangeHandler,
      customDateSummary: timePeriod === PERIOD_TYPES.CUSTOM,
      itemTemplate: getPeriodMenuItem,
      pickerValue: pickerValue,
      customTime: false,
      isOverlayPanelOpen: false
    }
  }

  function getDQContentProps() {
    return {
      meterData: assetMetersLandlord,
      meterDataLoading: assetMetersLandlordLoading,
      completenessData: assetCompletenessLandlordData,
      completenessDataLoading: assetCompletenessLandlordLoading,
      coverageData: assetCoverageLandlordData,
      coverageDataLoading: assetCoverageLandlordLoading,
      selectedLanguage,
      dataQualityState,
      updateDataQualityState,
      responsibility: LANDLORD,
      areaUnit: areaLabel,
      sendDataHandler: sendDataHandler,
      onEnterDataHandler: onEnterDataHandler,
    }
  }

  function getDQContentPropsTenant() {
    return {
      meterData: assetMetersTenant,
      meterDataLoading: assetMetersTenantLoading,
      completenessData: assetCompletenessTenantData,
      completenessDataLoading: assetCompletenessTenantLoading,
      coverageData: assetCoverageTenantData,
      coverageDataLoading: assetCoverageTenantLoading,
      selectedLanguage,
      dataQualityState,
      updateDataQualityState,
      responsibility: TENANT,
      areaUnit: areaLabel,
      sendDataHandler: sendDataHandler,
      onEnterDataHandler: onEnterDataHandler,
    }
  }

  function tabActionHandler(tabIndex) {
    updateDataQualityState({tabIndex})
  }

  return (
    <AssetLayout getBackNavigationProps={getBackNavigationProps} pageContainerClass={'dq-container'}>
      <div className='dq-container__main grey-bg'>
        <div className="dq-container__main__tab">
          <div className="dq-container__main__tab__content flex">
            <Text content={`${getLocalizedValue(selectedLanguage, 't_show')}:`} className="left-title" />
            <Button
              onClick={()=>tabActionHandler(1)}
              content='t_summary'
              outlined
              size="small"
              className={`tab-${tabIndex===1?1:0}`}
            />
            <div className="dq-container__main__tab__content__vline" />
            <Button
              onClick={()=>tabActionHandler(2)}
              content='t_title_meter_view'
              outlined
              size="small"
              className={`tab-${tabIndex===2?2:0}`}
            />
          </div>
        </div>
        <div className="dq-container__main__content">
          {tabIndex === 1 ?
            <>
              <DataQualityContent {...getDQContentProps()} />
              {(!!assetCompletenessTenantData || !!assetCoverageTenantData) &&
                    <DataQualityContent {...getDQContentPropsTenant()} />}
            </>
            :
            <DataQualityMeterView
              meterUtility={meterUtility}
              meterArea={meterArea}
              updateDataQualityState={updateDataQualityState}
              selectedLanguage={selectedLanguage}
              assetMetersDetails={assetMetersDetails}
              assetMetersDetailsLoading={assetMetersDetailsLoading}
              sendDataHandler={sendDataHandler}
              onEnterDataHandler={onEnterDataHandler}
            />
          }
        </div>
      </div>
    </AssetLayout>
  )
}

DataQualityHomeV2.displayName = 'DataQualityHomeV2'

export default DataQualityHomeV2