import {Skeleton} from 'primereact/skeleton'

const DataQualityTileSkeleton = () => {
  return (
    <div className="data-quality-tile-skeleton">
      <Skeleton width="83px" borderRadius="0" height="1rem" />
      <Skeleton width="151px" borderRadius="0" height="1rem" className="mt-2"/>
      <Skeleton width="550px" borderRadius="0" height="1rem" className="mt-3"/>
      <div className="flex mt-2">
        <Skeleton width="91px" borderRadius="0" height="1rem" className="mr-3"/>
        <Skeleton width="91px" borderRadius="0" height="1rem" className="mr-3"/>
        <Skeleton width="91px" borderRadius="0" height="1rem" className="mr-3"/>
        <Skeleton width="91px" borderRadius="0" height="1rem" />
      </div>
    </div>
  )
}
export default DataQualityTileSkeleton