import React, {useEffect, useState} from 'react'
import {NavLink, useParams} from 'react-router-dom'
import './NavigationTab.scss'
import {createNavURLForFundPages, SessionUserState} from '../../../utils/helpers/Helper'
import { FUND_VIEWS_PAGES } from '../../../utils/helpers/Constants'
import hrLine from '../../../resources/images/icon/rectangle-hr-line.svg'
import PropTypes from 'prop-types'

const NavigationTab = (props) => {

  //region Declarations/Initialisations
  const {instanceName, orgName, fundName, fundId, fundGroupId, } = useParams()
  const [deviceWidth, setDeviceWidth] = useState()
  //endregion

  //region Side effects.
  useEffect(() => {
    setDeviceWidth(window.innerWidth)
  }, [deviceWidth])
  //endregion

  const resetUtilitySelected = (e) => {
    if(e.target.dataset.path === FUND_VIEWS_PAGES.performance && !e.target.parentNode.classList.contains('link-active')){
      SessionUserState.saveUserDataToSession(`nav-tab-${FUND_VIEWS_PAGES.performance}`, true)
    }
  }

  //region Helper methods for FundDashboard.
  function createNavLinks(navLinkConfigs) {
    return navLinkConfigs.map((navLinkConfig, index)=>{
      return (
        <NavLink
          key={`nav-link-${index}`}
          exact={true}
          to={{state: navLinkConfig.state, pathname: createNavURLForFundPages({instanceName, orgName, fundName, fundId, fundGroupId, pathSuffix: navLinkConfig.path})}}
          activeClassName={navLinkConfig.activeClassName}
        >
          <li onClick={(e) => {resetUtilitySelected(e)}} data-path={navLinkConfig.path} className="tab-text">{navLinkConfig.tabText} {navLinkConfig.fundname}</li>
        </NavLink>
      )
    })
  }
  //endregion

  return (
    <div className="grid navigation-tab">
      <div className="col-12 nav-tab-container">
        <ul className="nav-tab-component">
          {props.navLinkConfigs && createNavLinks(props.navLinkConfigs)}
        </ul>
        {props.isNewLayout && <img src={hrLine} alt="siera-hr-line" className='hr-line'/> }
      </div>
    </div>
  )
}

NavigationTab.propTypes = {
  isNewLayout : PropTypes.bool,
}

export default NavigationTab