import React from 'react'
import {Skeleton} from 'primereact/skeleton'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import '../../../pages/energy-star/energy-star-connection/energy-star-link/ESConnectionStep.scss'

const ESConnectionStepFourSkeleton = () => {
  const dummyArray = Array.from({ length: 4 })
  const bodyTemplate = (length) => {
    return <Skeleton width={length}/>
  }

  const headerTemplate = () => {
    return <div className="ml:col-1">
      <Skeleton width={'4rem'}/>
    </div>
  }

  return (
    <div className="custom-skeleton p-rel flex flex-column align-items-center users-page-skeleton">
      <DataTable value={dummyArray} tableClassName={'es-asset-table no-border mb:col-5'} tableStyle={{width:'auto'}}>
        <Column selectionMode="multiple" className={'es-selection'}/>
        <Column field="sieraPlusAssets" header={headerTemplate()} body={()=>bodyTemplate('38rem')} className={'siera-assets'}/>
        <Column field="esAsset" header={headerTemplate()} body={()=>bodyTemplate('38rem')} className={'es-assets'}/>
      </DataTable>
    </div>
  )
}

export default ESConnectionStepFourSkeleton
