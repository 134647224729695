import { useState, useEffect, useRef } from 'react'
import { Field } from 'formik'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import './TextfieldDropdownFormik.scss'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { Icon, Text, Tooltip } from 'components/atomic/index'

const TextfieldDropdownFormik = (props) => {
  const { name, label, errors, touched, isInfo, value, options, tooltipTriggerClass, tooltipContentKeys, inputType = 'text', testId, disabled, hasWarning, onUnitChange,unit,handleChange} = props
  const [isDropdownClicked, setIsDropdownClicked] = useState(false)
  const [currentUnit, setCurrentUnit] = useState(unit)
  const dropdownRef = useRef(null)

  const handleDropdownClick = () => {
    setIsDropdownClicked(true)
  }

  const handleClickOutside = (event) => {
    if (dropdownRef?.current && !dropdownRef?.current?.contains(event.target)) {
      setIsDropdownClicked(false)
    }
  }
  const handleKeyPress = (event) => {
    if (event.key === 'e' || event.key === 'E') {
      event.preventDefault()
    }
    if (event.key === '-' && inputType === 'number') {
      event.preventDefault()
    }
    if (event.key === '0' && !['cpa', 'ext'].includes(name) && event.target.value.trim() === '') {
      event.preventDefault()
    }
  }
  const handleKeyDown = (event) => {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault()
    }
  }
  const handleInputChange = (e) => {
    setCurrentUnit(unit)
    handleChange(e)
  }

  const handleDropdownChange = (e, setFieldValueFormik) => {
    setFieldValueFormik(`${name}-dropdown`, e.value)
    if (currentUnit !== e.value) {
      onUnitChange(e.value)
      setCurrentUnit(e.value)
    }
  }

  useEffect(() => {
    setCurrentUnit(unit)
  }, [unit])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  return (
    <div className={cn(errors[name] && (touched[name] || touched[`${name}-dropdown`]) && 'input-group-error', 'flex flex-column my-2 my-2 gap-1')}>
      <div>
        <label className='relative' htmlFor={name}>
          {label} {isInfo && <img src={infoGrey} className='ml-2 info-button absoulte' alt="" />}
        </label>
        {tooltipContentKeys && tooltipTriggerClass && 
        <>
          <Icon id='info_solid' size={17} className={tooltipTriggerClass ? `${tooltipTriggerClass} ml-2` : 'ml-2'}/>
          <Tooltip target={`.${tooltipTriggerClass}`} width={230}>
            <Text content={tooltipContentKeys.title} weight='bold' className='mb-2'/>
            <Text content={tooltipContentKeys.content} />
          </Tooltip>
        </>
        }
      </div>
      <div ref={dropdownRef} className={cn('p-inputgroup flex-1 multi-input', { 'focus-on-dropdown': isDropdownClicked }, {'input-border-warning': (hasWarning && !errors[name])})}>
        <Field name={name} error={true}>
          {({ field }) => (
            <>
              <InputText {...field} type={inputType} value={value} disabled={disabled} className='multi-input__textfield' data-testid={testId} onClick={() => setIsDropdownClicked(false)} onKeyPress={handleKeyPress} onKeyDown={handleKeyDown}  onWheel={(e) => e.target.blur()} onChange={handleInputChange}  />
              <div className='multi-input__border-line' />
            </>
          )}
        </Field>
        <Field name={`${name}-dropdown`}>
          {({ field, form: { setFieldValue: setFieldValueFormik } }) => (
            <Dropdown
              {...field}
              onClick={() => handleDropdownClick()}
              options={options}
              className='multi-input__dropdown'
              value={field.value}
              onChange={(e) => handleDropdownChange(e, setFieldValueFormik)}
            />
          )}
        </Field>
      </div>
      {errors[name] && (touched[name] || touched[`${name}-dropdown`]) &&
      <Text className={cn(`error medium-text ${!errors[name] && hasWarning && 'input-text-warning'}`)} colour='red' content={errors[name]} />
      }
    </div>
  )}

TextfieldDropdownFormik.propTypes = {
  label: PropTypes.string,
  errors: PropTypes.object,
  touched: PropTypes.object,
  value: PropTypes.string,
  isInfo: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  name: PropTypes.string,
  tooltipTriggerClass: PropTypes.string,
  tooltipContentKeys: PropTypes.shape({
    content: PropTypes.string,
    title: PropTypes.string,
  }),
  inputType: PropTypes.string,
  testId: PropTypes.string,
  hasWarning: PropTypes.bool,
  onUnitChange: PropTypes.func,
  unit:PropTypes.string,
  handleChange: PropTypes.func.isRequired,

}

export default TextfieldDropdownFormik