import axiosInstance from '../common/axios-config'

export const GetAssetGroups = async (queryParam) => {
  let data
  if(queryParam.queryKey[0]){
    data = await axiosInstance.get(`/api/v1/UserAssetGroups/${queryParam.queryKey[0]}`)
  }else{
    data = await axiosInstance.get('/api/v1/AssetGroups')
  }
  return data
}

export const GetAssetGroupsByGroupId = async (keys) => {
  const data = await GetAssetGroup(keys.queryKey[1])
  return data
}

export const GetAssetGroup = async (assetGroupID) => {
  if(assetGroupID){
    const {data} = await axiosInstance.get(`/api/v1/AssetGroups/${assetGroupID}`)
    return data
  }else{
    return null
  }
}

export const DeleteGroup = async (group) => {
  const {data} = await axiosInstance.delete(`/api/v1/AssetGroups/${group.assetGroupID}`,group)
  return data
}

export const UpdateGroup = async (group) => {
  const {data} = await axiosInstance.put(`/api/v1/AssetGroups/AssetGroupData/${group.groupId}`,group.groupData)
  return data
}
export const addGroup = async ({groupData}) => {
  const {data} = await axiosInstance.post('/api/v1/AssetGroups/AssetGroupData',groupData)
  return data
}