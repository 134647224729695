import {
  getValidData,
  getRequiredUnit,
  getLocalizedColumnNamesWithUnits,
  UNIT_TYPE_FOR_EXPORT,
  getExcelCellName,
} from '../../ExportHelper'
import {getLocalizedValue} from '../../../helpers/Helper'
import { UTILITY_LABELS_OBJECT_TITLE_CASE} from '../../../helpers/Constants'
import { excelCellFormatting } from '../excel-exports/AssetHomeAssetDetailsExcelHelper'

export const carbonEmissionGapHeading =  () => {
  return  [
    {key: 't_scope_1_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
    {key: 't_scope_2_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
    {key: 't_scope_3_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
    {key: 't_total_carbon_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
  ]
}

export const electricityAndGasFuelThermalGapHeading = () => {
  return [
    {key: 't_current_period_consumption_with_gapFilled_data', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)},
    {key: 't_previous_period_consumption_with_gapFilled_data',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)},
    {key: 't_current_total_carbon_emissions',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
    {key: 't_previous_period_total_carbon_emissions',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)}
  ]
}

export const waterConsumptionGapHeading = () => {
  return [
    {key: 't_current_consumption_with_gapFilled', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.WATER_CONSUMPTION, null, null)},
    {key: 't_previous_consumption_with_gapFilled_data', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.WATER_CONSUMPTION, null, null)},
  ]
}

export const energyConsumptionGapHeading = () => {
  return [
    {key: 't_current_energy_consumption_with_gapFilled_data', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)},
    {key: 't_previous_energy_consumption_kwh_with_gapFilled_data',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)}, //NOTE
    {key: 't_current_total_carbon_emissions',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
    {key: 't_previous_period_total_carbon_emissions',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
  ]
}

export function getConsumptionTableData(utilityConsumptionInfo, selectedLanguage, intl){
  if(hasCheckConsumptionData(utilityConsumptionInfo)){
    return mappedAnnualCurrentConsumption(null, selectedLanguage, intl, 't_no_export_data_available_for_single_asset')
  }
  return getValidData(utilityConsumptionInfo?.consumption, selectedLanguage)
}

export function commonConsumptionGapFieldValue(utilityConsumptionInfo, selectedLanguage, intl){
  return [
    getConsumptionTableData(utilityConsumptionInfo, selectedLanguage, intl),
    getValidData(utilityConsumptionInfo?.previousConsumption, selectedLanguage),
    getValidData(utilityConsumptionInfo?.currentCarbonEmission, selectedLanguage),
    getValidData(utilityConsumptionInfo?.previousCarbonEmission, selectedLanguage),
    getValidData(utilityConsumptionInfo?.actualConsumption, selectedLanguage),
    getValidData(utilityConsumptionInfo?.actualPreviousConsumption, selectedLanguage),
    getValidData(utilityConsumptionInfo?.actualCurrentCarbonConsumption, selectedLanguage),
    getValidData(utilityConsumptionInfo?.actualPreviousCarbonConsumption, selectedLanguage),
  ]
}

export function hasCheckConsumptionData(utilityConsumptionInfo){
  const { consumption, previousConsumption, actualConsumption, actualPreviousConsumption} = utilityConsumptionInfo
  return (consumption === null && previousConsumption === null && actualConsumption === null && actualPreviousConsumption === null)
}
export function hasCheckCarbonConsumption(utilityConsumptionInfo, utility){
  return (UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON === utility && utilityConsumptionInfo?.consumption === null && utilityConsumptionInfo?.actualConsumption === null)
}

export function mappedAnnualCurrentConsumption(value, selectedLanguage, intl, translationKey){
  if(value === null){
    return getLocalizedColumnNamesWithUnits([{key: translationKey }], intl)[0]
  }
  return getValidData(value, selectedLanguage)
} 

export function benchmarkAndStandardBenchmarkData(dataItem, selectedLanguage, getNumberOfMonths){
  if(Number(getNumberOfMonths) !== 12) {
    return []
  }
  return [
    getValidData(dataItem?.benchmarkValue, selectedLanguage) === '-' ? getLocalizedValue(selectedLanguage, 't_not_available') : getValidData(dataItem?.benchmarkValue, selectedLanguage),
    getValidData(dataItem?.benchmarkVariance, selectedLanguage) === '-' ? getLocalizedValue(selectedLanguage, 't_not_available')  : getValidData(dataItem?.benchmarkVariance, selectedLanguage),
  ]
}

export function annualRangeSelection(consumption, index, assetTableColumnNamesWithLocalization, consumptionKeys){
  if(Array.isArray(assetTableColumnNamesWithLocalization) && consumption === null){
    const consumptionIndex = assetTableColumnNamesWithLocalization.findIndex(item => consumptionKeys.includes(item.key))
    if(consumptionIndex !== -1){
      return {
        start: getExcelCellName(consumptionIndex + 1),
        end: getExcelCellName(assetTableColumnNamesWithLocalization.length),
        index,
      }
    }
  }
  return null
}

export function benchmarkAndStandardBenchmarkTitle(getNumberOfMonths, hasWater = false){
  const totalMonthsNumber = Number(getNumberOfMonths)
  if(totalMonthsNumber !== 12){
    return []
  }
  const unitType = hasWater ? UNIT_TYPE_FOR_EXPORT.WATER_INTENSITY : UNIT_TYPE_FOR_EXPORT.ENERGY_INTENSITY
  return [
    {key: 't_industry_standard_benchmark',unit:getRequiredUnit(unitType, null, null)},
    {key: 't_variance_against_industry_standard_benchmark'},
  ]
}

export const MERGE_CELL_GAP_FIELD_DATA = {
  'Energy': {mergeRange: ['M16:Z16', 'AA16:AB16'], excludingStartCell: 13, excludingEndCell: 26, includeStartCell: 27, includeEndCell: 28, rowNumber: 16},
  'Carbon': {mergeRange: ['M16:AB16', 'AC16:AF16'], excludingStartCell: 13, excludingEndCell: 28, includeStartCell: 29, includeEndCell: 32, rowNumber: 16},
  'Electricity': {mergeRange: ['M16:Z16', 'AA16:AB16'], excludingStartCell: 13, excludingEndCell: 26, includeStartCell: 27, includeEndCell: 28, rowNumber: 16},
  'GasFuelsAndThermals': {mergeRange: ['N16:AA16', 'AB16:AC16'], excludingStartCell: 14, excludingEndCell: 27, includeStartCell: 28, includeEndCell: 29, rowNumber: 16},
  'Water': {mergeRange: ['M16:Y16', 'Z16:Z16'], excludingStartCell: 13, excludingEndCell: 25, includeStartCell: 26, includeEndCell: 26, rowNumber: 16},
}

export const MERGE_CELL_GAP_FIELD_METER_ANNUAL_DATA = {
  'Energy': {mergeRange: ['V16:Y16', 'Z16:AC16'], excludingStartCell: 26, excludingEndCell: 29, includeStartCell: 22, includeEndCell: 25, rowNumber: 16},
  'Carbon': {mergeRange: ['V16:W16', 'X16:Y16'], excludingStartCell: 24, excludingEndCell: 25, includeStartCell: 22, includeEndCell: 23, rowNumber: 16},
  'Electricity': {mergeRange: ['T16:W16', 'X16:AA16'], excludingStartCell: 24, excludingEndCell: 27, includeStartCell: 20, includeEndCell: 23, rowNumber: 16},
  'GasFuelsAndThermals': {mergeRange: ['U16:X16', 'Y16:AB16'], excludingStartCell: 25, excludingEndCell: 28, includeStartCell: 21, includeEndCell: 24, rowNumber: 16},
  'Water': {mergeRange: ['S16:T16', 'U16:V16'], excludingStartCell: 21, excludingEndCell: 22, includeStartCell: 19, includeEndCell: 20, rowNumber: 16},
}

export const MERGE_CELL_GAP_FIELD_METER_MONTHLY_DATA = {
  'Energy': {mergeRange: ['V16:AI16', 'AJ16:AK16'], excludingStartCell: 22, excludingEndCell: 35, includeStartCell: 36, includeEndCell: 37, rowNumber: 16},
  'Carbon': {mergeRange: ['W16:AI16', 'AJ16:AJ16'], excludingStartCell: 23, excludingEndCell: 35, includeStartCell: 36, includeEndCell: 36, rowNumber: 16},
  'Electricity': {mergeRange: ['U16:AH16', 'AI16:AJ16'], excludingStartCell: 21, excludingEndCell: 34, includeStartCell: 35, includeEndCell: 36, rowNumber: 16},
  'GasFuelsAndThermals': {mergeRange: ['V16:AI16', 'AJ16:AK16'], excludingStartCell: 22, excludingEndCell: 35, includeStartCell: 36, includeEndCell: 37, rowNumber: 16},
  'Water': {mergeRange: ['T16:AF16', 'AG16:AG16'], excludingStartCell: 20, excludingEndCell: 32, includeStartCell: 33, includeEndCell: 33, rowNumber: 16},
}

export function setMergeCellExcelBorder(worksheet, selectedLanguage, getCellConfig){
  if(getCellConfig) {
    const excludingValue = getLocalizedValue(selectedLanguage, 't_excluding_gap_filled')
    const includingValue = getLocalizedValue(selectedLanguage, 't_including_gap_filled')
    const { excludingStartCell, excludingEndCell, includeStartCell, includeEndCell, rowNumber } = getCellConfig
    setExcelCellValueAndStyle(excludingStartCell, excludingEndCell, rowNumber, excludingValue, worksheet)
    setExcelCellValueAndStyle(includeStartCell, includeEndCell, rowNumber, includingValue, worksheet)
  }
  return worksheet
}

function setExcelCellValueAndStyle(start, end, rowNumber, value, worksheet){
  for(let i = start; i <= end; i++){
    const cellName = `${getExcelCellName(i)}${rowNumber}`
    if(i === start){
      worksheet[cellName] = {v: value}
    }else{
      worksheet[cellName] = {v: ' '}
    }
    worksheet[cellName].s = excelCellFormatting(false)
  }
}