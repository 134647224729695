import {useQueries} from 'react-query'
import {QueryKeyPrefix} from '../../../../utils/helpers/Constants'
import {noRefetchOnWindowFocus} from '../../../../services/common/useQuery-config'
import {
  availableCapacity,
  getConsumptionEvidenceList,
  getDocumentsAndCertificatesList
} from '../../../../services/documents/documents-service'
import {useDebounce} from 'use-debounce'

const useDocumentsData = (tabIndex,searchString,filterString) => {
  const getSearchData = tabIndex !== 0 ? searchString.consumptionEvidenceSearch: searchString.documentsCertsSearch
  const [debouncedSearchString] = useDebounce(getSearchData, 300)

  const queries = useQueries(
    [
      {
        queryKey: [QueryKeyPrefix.DOCUMENT_LIST_DATA,debouncedSearchString,filterString.current],
        queryFn: getDocumentsAndCertificatesList,
        ...noRefetchOnWindowFocus,
        enabled: tabIndex === 0
      },
      {
        queryKey: [QueryKeyPrefix.CONSUMPTION_EVIDENCE_DATA,debouncedSearchString,filterString.current],
        queryFn: getConsumptionEvidenceList,
        ...noRefetchOnWindowFocus,
        enabled: tabIndex === 1
      },
      {
        queryKey: QueryKeyPrefix.AVAILABLE_STORAGE_LIMIT,
        queryFn: availableCapacity,
        ...noRefetchOnWindowFocus
      }
    ]
  )

  const [
    { isFetching: documentsDataLoading, data: documentsDataList },
    { isLoading: consumptionEvidenceDataLoading, data: consumptionEvidenceDataList },
    { isLoading: availableCapacityLoading, data: availableStorageCapacity }
  ] = queries

  const documentsDataRefetch = async () => {
    await Promise.all(queries.map(async (query,index) => {
      if(index !== 1){
        await query.refetch()
      }
    }))
  }

  return {
    documentsDataLoading,
    documentsDataList,
    documentsDataRefetch,
    consumptionEvidenceDataLoading,
    consumptionEvidenceDataList,
    availableStorageCapacity,
    availableCapacityLoading
  }
}

export default useDocumentsData