import React, {useState} from 'react'
import './NzcChartOptions.scss'
import OptionsFormFieldCheckbox from './options-form-field-checkbox/OptionsFormFieldCheckbox'
import {getLocalizedValue} from '../../../../utils/helpers/Helper'
import {Button} from 'primereact/button'
import I18n from '../../../../utils/i18n/I18n'
import ChartAxisOptions from './chart-axis-options/ChartAxisOptions'
import {fromAndToYearsValidationCondition, minMaxValidationCondition} from '../nzc-common-utils'
import {useMutation} from 'react-query'
import {UpdateNZCConfigChartOptions} from '../../../../services/funds/nzc-service'
import {X_SCALE_YEARS} from '../nzc-constants'
import NzcBenchmarkOptions from '../../nzc/nzc-benchmark/NzcBenchmarkOptions'
import {useNzcBenchmarkOptions} from '../../../../utils/custom-hooks/nzc-hooks/useNzcBenchmarkOptions'

const NzcChartOptions = ({propsConfig}) => {

  //region Declarations/Initializations
  const {nzcSettingsData,  chartOptionsFormState, selectedLanguage, estimatedYScaleMax, reloadData, scopeId, handleChartOptions, updateChartOptionsFormState, graphIntensity, getBenchmarkOptionsData}=propsConfig
  const [applyState, setApplyState] = useState(false)
  const [formErrors, setFormErrors] = useState({fromToYear:false, minMax: false})
  const updateNzcSettingsMutation = useMutation(UpdateNZCConfigChartOptions)
  const {benchmarkOption, benchmarkOptionsPropsMemoised}=useNzcBenchmarkOptions(nzcSettingsData)
  //endregion

  function updateFormErrors(newFormErrors) {
    setFormErrors(prevFormErrors=>{
      return {...prevFormErrors, ...newFormErrors }
    })
  }

  function handleOnBlurOfXAxisValue() {
    updateFormErrors({fromToYear: fromAndToYearsValidationCondition(chartOptionsFormState.xAxisScaleFrom, chartOptionsFormState.xAxisScaleTo)})
  }


  function showStrandingDatesChangeHandler(event) {
    updateChartOptionsFormState({displayStrandingDates: event.checked})
  }

  function getMessage(messageKey) {
    return getLocalizedValue(selectedLanguage, messageKey)
  }

  function handleApply() {
    const settings = {
      ...chartOptionsFormState,
      selectedMarketBenchmarkID: benchmarkOption.selectedMarketBenchmarkID,
      displayMarketBenchmark: benchmarkOption.displayMarketBenchmark,

    }
    updateNzcSettingsMutation.mutateAsync({scopeId, settings, graphIntensity}).then(() => {
      reloadData()
    })
    handleChartOptions(false)
  }

  const disableApplyButton=()=> !!(applyState || formErrors.fromToYear || formErrors.minMax)

  function handleYAxis(e) {
    updateChartOptionsFormState({yAxisAutoScale: e.checked})
    updateFormErrors({minMax: minMaxValidationCondition(0, estimatedYScaleMax)})
    if (!chartOptionsFormState.yAxisAutoScale) {
      updateChartOptionsFormState({yAxisScaleMin: 0, yAxisScaleMax: estimatedYScaleMax})
      if (chartOptionsFormState.xAxisAutoScale) {
        setApplyState(false)
      }
    }
  }

  function handleXAxis(e) {
    updateFormErrors({fromToYear: fromAndToYearsValidationCondition(nzcSettingsData.baselineYear, X_SCALE_YEARS.y2k50)})
    let newChartOptions={xAxisAutoScale: e.checked}
    if (!chartOptionsFormState.xAxisAutoScale) {
      newChartOptions={...newChartOptions, xAxisScaleFrom: nzcSettingsData.baselineYear, xAxisScaleTo: X_SCALE_YEARS.y2k50}
      if (chartOptionsFormState.yAxisAutoScale) {
        setApplyState(false)
      }
    }
    updateChartOptionsFormState(newChartOptions)
  }

  function handleOnBlurOfYAxisValue() {
    updateFormErrors({minMax: minMaxValidationCondition(chartOptionsFormState.yAxisScaleMin, chartOptionsFormState.yAxisScaleMax)})
  }

  function getPropsForChartAxisOptions() {
    return {
      chartOptionsFormState,
      formErrors,
      handleYAxis,
      handleXAxis,
      handleOnBlurOfYAxisValue,
      handleOnBlurOfXAxisValue,
      updateChartOptionsFormState,
    }
  }

  function getPropsForStrandingDatesCheckbox() {
    return {
      checkboxLabel: getMessage('t_show_stranding_dates'),
      checkedState: chartOptionsFormState.displayStrandingDates,
      onChangeHandler: showStrandingDatesChangeHandler,
      inputId: 'stranding-dates'
    }
  }

  return (
    <div className={'nzc-chart-options-form'}>
      <div className='chart-options-body'>
        <div className='body-col-left'>
          <OptionsFormFieldCheckbox propsConfig={getPropsForStrandingDatesCheckbox()} />
          <div className='top-border-line'/>
          <div className="benchmark-options-wrapper">
            <NzcBenchmarkOptions benchmarkOptionsProps={benchmarkOptionsPropsMemoised} assetNzc getBenchmarkOptionsData={getBenchmarkOptionsData}/>
          </div>
        </div>
        <div className='body-col-right'>
          <ChartAxisOptions propsConfig={getPropsForChartAxisOptions()} />
        </div>
      </div>
      <div className='border-line' />
      <div className='chart-options-footer padding-bottom-3'>
        <Button label={I18n('t_apply')} onClick={handleApply} className="common-blue-bg-btn apply-button button-highlight" disabled={disableApplyButton()}/>
      </div>
    </div>
  )
}

export default NzcChartOptions