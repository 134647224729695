import { useCallback, useEffect, useState } from 'react'
import { MultiSelect } from 'primereact/multiselect'
import { Checkbox } from 'primereact/checkbox'
import PropTypes from 'prop-types'

export default function GroupedMultiSelect(props) {
  const { optionList, onMultiGroupSelection, placeholder, values, name } = props
  const [selectedOptions, setSelectedOptions] = useState([])

  useEffect(() => {
    if(Array.isArray(values)){
      setSelectedOptions(values)
    }
  }, [values])
 
  const isAllGroupSelected = (options) => {
    return options.items.every((v) => selectedOptions.includes(v.value))
  }
 
  const handleGroupToggle = (options, checked) => {
    if (checked) {
      setSelectedOptions([
        ...selectedOptions,
        ...options.items.map((item) => item.value),
      ])
    } else {
      const filteredCities = selectedOptions.filter(
        (item) => !options.items.map((c) => c.value).includes(item),
      )
      setSelectedOptions(filteredCities)
    }
  }
  
  const groupedItemTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <Checkbox
          checked={isAllGroupSelected(option)}
          onChange={(e) => {
            e.stopPropagation()
            handleGroupToggle(option, e.checked)
          }}
          className="mr-2"
        />
        <div>{option.label}</div>
      </div>
    )
  }

  const onMultiChangeHandler = useCallback(event => {
    setSelectedOptions(event.value)
    onMultiGroupSelection(name, event.value)
  }, [onMultiGroupSelection])
 
  return (
    <div className="card flex justify-content-center">
      <MultiSelect
        {...props}
        value={selectedOptions}
        options={optionList}
        onChange={onMultiChangeHandler}
        optionLabel="label"
        optionGroupLabel="label"
        optionGroupChildren="items"
        optionGroupTemplate={groupedItemTemplate}
        placeholder={placeholder}
        display="chip"
        className="w-full md:w-20rem"
        filter
      />
    </div>
  )
}

GroupedMultiSelect.propTypes = {
  optionList: PropTypes.array,
  placeholder: PropTypes.string,
  onMultiGroupSelection: PropTypes.func,
  values: PropTypes.array,
  name: PropTypes.string,
}