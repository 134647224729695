import { useFormikContext } from 'formik'
import './AddEditAssetForm.scss'
import { getLocalizedValue } from '../../../../utils/helpers/LanguageUtils'
import PropTypes from 'prop-types'
import { Accordion } from 'components/atomic'
import NumberfieldFormik from '../../../common/formik-elements/number-field/NumberfieldFormik'
import CalendarFormik from '../../../common/formik-elements/date-picker/CalendarFormik'
import { Icon, LinkButton, Text, Timeline, Tooltip } from 'components/atomic/index'
import { DATE_FORMAT } from 'utils/helpers/Constants'
import { isDataFieldFailing } from './AddEditAssetSidebar'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'
import React, { useContext } from 'react'
import { FeatureFlagContext } from 'Contexts'

export function hasAllValuesInArray(array, valuesToCheck) {
  const dataArray = array.map(item => item.code)
  return valuesToCheck.every(value => dataArray.includes(value))
}

const getDataReadinessWarningMessage = (dataReadinessArray, year) => {
  return dataReadinessArray?.length > 0 ? <Text content={`t_reports_${dataReadinessArray[0]}_msg`} contentDynamic={{reportingPeriod: <Text content={year.toString()}/>}} /> : null
}

export const currencyOptions = [
  { name: 'GBP', value: '£' },
]

const PeriodicReportingForm = ({
  reportingYear,
  fossilFuelExposureTimeline,
  setFossilFuelExposureTimeline,
  wasteTimeline,
  setWasteTimeline,
  gavTimeline,
  setGavTimeline,
  occupancyTimeline,
  setOccupancyTimeline,
  dataReadinessChecks
}) => {
  const { loginState: { userInfo } } = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const { values, errors, handleChange } = useFormikContext()
  const featureFlag = useContext(FeatureFlagContext)
  const sfdrOccupancyFlag = featureFlag.featureState.sfdr_occupancy_view

  const exposureStatuses = [
    { name: getLocalizedValue(selectedLanguage,'t_exposed'), value: true },
    { name: getLocalizedValue(selectedLanguage,'t_not_exposed'), value: false },
  ]
  
  const wasteStatuses = [
    { name: getLocalizedValue(selectedLanguage,'t_active'), value: true },
    { name: getLocalizedValue(selectedLanguage,'t_inactive'), value: false },
  ]

  const accordionItems = [
    {
      title: 't_fossil_fuel_exposure',
      leftContent: <Text content='t_fossil_fuel_exposure_extended_edit_data' />,
      rightContent: (
        <Timeline
          setters={setFossilFuelExposureTimeline}
          values={fossilFuelExposureTimeline.values} 
          initialValues={fossilFuelExposureTimeline.initialValues}
          valueLabelKey='t_exposure'
          valueDropdownOptions={exposureStatuses} 
          editModeRecordIds={fossilFuelExposureTimeline.editModeRecordIds}
          valueErrors={fossilFuelExposureTimeline.valueErrors}
          dateErrors={fossilFuelExposureTimeline.dateErrors}
          addNewRecordKey='t_add_new_exposure'
          addOldRecordKey='t_add_historic_exposure'
        />
      ),
      warning: isDataFieldFailing(dataReadinessChecks?.FossilFuelExposureDataField),
      warningTextElement: getDataReadinessWarningMessage(dataReadinessChecks?.FossilFuelExposureDataField, reportingYear),
      error: fossilFuelExposureTimeline.valueErrors.length > 0 || fossilFuelExposureTimeline.dateErrors.length > 0
    },
    {
      title: 't_waste_contract_status',
      leftContent: <Text content='t_waste_contracts_extended' />,
      rightContent: (
        <Timeline
          setters={setWasteTimeline}
          initialValues={wasteTimeline.initialValues}
          values={wasteTimeline.values} 
          valueLabelKey='t_contract_status'
          valueDropdownOptions={wasteStatuses} 
          editModeRecordIds={wasteTimeline.editModeRecordIds}
          valueErrors={wasteTimeline.valueErrors}
          dateErrors={wasteTimeline.dateErrors}
          addNewRecordKey='t_add_new_status'
          addOldRecordKey='t_add_historic_status'
        />
      ),
      warning: isDataFieldFailing(dataReadinessChecks?.WasteContracts),
      warningTextElement: getDataReadinessWarningMessage(dataReadinessChecks?.WasteContracts, reportingYear),
      error: wasteTimeline.valueErrors.length > 0 || wasteTimeline.dateErrors.length > 0
    },
    {
      title: 't_gav',
      leftContent: <Text content='t_gav_extended' />,
      rightContent: (
        <Timeline
          setters={setGavTimeline}
          values={gavTimeline.values} 
          initialValues={gavTimeline.initialValues}
          valueLabelKey='t_gav'
          isValueInput
          valueDropdownOptions={currencyOptions} 
          editModeRecordIds={gavTimeline.editModeRecordIds}
          valueErrors={gavTimeline.valueErrors}
          dateErrors={gavTimeline.dateErrors}
          startDateLabelKey='t_evaluation_date'
          addNewRecordKey='t_add_new_evaluation'
          hideAddOldRecord
          hideCurrentPrefix
          hideEndDate
        />
      ),
      warning: isDataFieldFailing(dataReadinessChecks?.GrossAssetValue),
      warningTextElement: getDataReadinessWarningMessage(dataReadinessChecks?.GrossAssetValue, reportingYear),
      error: gavTimeline.valueErrors.length > 0 || gavTimeline.dateErrors.length > 0
    },
    {
      title: 't_occupancy',
      leftContent: <Text content='t_occupancy_extended' />,
      rightContent: (
        <Timeline
          setters={setOccupancyTimeline}
          values={occupancyTimeline.values} 
          initialValues={occupancyTimeline.initialValues}
          valueLabelKey='t_occupancy_excel'
          isValueInput
          isPercentageInput
          addNewRecordKey='t_add_new_occupancy'
          editModeRecordIds={occupancyTimeline.editModeRecordIds}
          valueErrors={occupancyTimeline.valueErrors}
          dateErrors={occupancyTimeline.dateErrors}
          hideAddOldRecord
          hideCurrentPrefix
          hideEndDate
        />
      ),
      error: occupancyTimeline.valueErrors.length > 0 || occupancyTimeline.dateErrors.length > 0
    },
    {
      title: 't_resource_consumption',
      leftContent: <Text content='t_resource_consumption_extended' />,
      rightContent: (
        <>
          <NumberfieldFormik handleChange={handleChange} dataTestId='sustainable-weight' min={0} label={`${getLocalizedValue(selectedLanguage, 't_sustainable_tonnes')}`} name='sustainableTonnes' errors={errors} tooltipElement={
            <>
              <Icon id='info_solid' size={17} className='sustainable-tonnes-info'/>
              <Tooltip target='.sustainable-tonnes-info' width={300} autoHide={false} hideDelay={200}>
                <Text content='t_sustainable_tonnes_tooltip_content' />
                <LinkButton content='t_click_to_read'/>
              </Tooltip>
            </>} />
          <NumberfieldFormik handleChange={handleChange} dataTestId='total-weight' min={0} label={`${getLocalizedValue(selectedLanguage, 't_total_tonnes')}`} name='totalTonnes' errors={errors} />
          <CalendarFormik value={values.startDate} handleChange={handleChange} testId='resource-start-date' label={`${getLocalizedValue(selectedLanguage, 't_start_date')}`} name='startDate' placeholder={getLocalizedValue(selectedLanguage, 't_start_date')} errors={errors} dateFormat={DATE_FORMAT} showIcon />
          <CalendarFormik value={values.endDate} handleChange={handleChange} testId='resource-end-date' label={`${getLocalizedValue(selectedLanguage, 't_end_date')}`} name='endDate' placeholder={getLocalizedValue(selectedLanguage, 't_end_date')} errors={errors} dateFormat={DATE_FORMAT} showIcon />
        </>
      ),
      warning: isDataFieldFailing(dataReadinessChecks?.ResourceConsumptionDataField),
      warningTextElement:
      dataReadinessChecks?.ResourceConsumptionDataField ?
        <Text
          content='t_reports_resource_consumption_not_found_msg' 
          contentDynamic={{areas: <>{dataReadinessChecks?.ResourceConsumptionDataField?.map((value, index)=> <React.Fragment key={value}><Text key={value} content={`t_reports_${value}`} />{index !==  dataReadinessChecks?.ResourceConsumptionDataField?.length - 1 && <span>, </span>}</React.Fragment> )} </>}}/>
        : null,
      error: (errors && (errors['sustainableTonnes'] !== undefined || errors['totalTonnes'] !== undefined || errors['startDate'] !== undefined || errors['endDate'] !== undefined))
    }
  ]

  const filteredAccordionItems = sfdrOccupancyFlag
    ? accordionItems
    : accordionItems.filter(item => item.title !== 't_occupancy')

  return (
    <Accordion items={filteredAccordionItems}>
    </Accordion>
  )
}

PeriodicReportingForm.propTypes = {
  reportingYear: PropTypes.number,
  fossilFuelExposureTimeline: PropTypes.object,
  setFossilFuelExposureTimeline: PropTypes.func,
  wasteTimeline: PropTypes.object,
  setWasteTimeline: PropTypes.func,
  gavTimeline: PropTypes.object,
  setGavTimeline: PropTypes.func,
  occupancyTimeline: PropTypes.object,
  setOccupancyTimeline: PropTypes.func,
  dataReadinessChecks: PropTypes.object
}

export default PeriodicReportingForm