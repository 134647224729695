import React from 'react'
import { Skeleton } from 'primereact/skeleton'
import './ActionStatusCardsSkeleton.scss'

const ActionStatusCardsSkeleton = () => {
  const skeletonActionCardStyle={
    width: '100%', 
    height: '100%'
  }
    
  return (
    <>
      <div className={'skeleton-card-action'}>
        <Skeleton {...skeletonActionCardStyle}/>
      </div>
      <div className={'skeleton-card-action'}>
        <Skeleton {...skeletonActionCardStyle}/>
      </div>
      <div className={'skeleton-card-action'}>
        <Skeleton {...skeletonActionCardStyle}/>
      </div>
      <div className={'skeleton-card-action'}>
        <Skeleton {...skeletonActionCardStyle}/>
      </div>
      <div className={'skeleton-card-action'}>
        <Skeleton {...skeletonActionCardStyle}/>
      </div>
    </>
  )
}

export default ActionStatusCardsSkeleton