import React, { useState, useCallback } from 'react'
import ErrorDialog from '../../pages/assets/data-quality/error-dialog/ErrorDialog'
import I18n from '../../../utils/i18n/I18n'

// Custom hook for error handling
function useErrorHandling() {
  const [error, setError] = useState(null)
  const [errorDialogVisible, setErrorDialogVisible] = useState(false)

  // Function to show an error dialog
  const errorDialog = useCallback((errorMessage) => {
    setError(errorMessage)
    setErrorDialogVisible(true)
  }, [])

  // Render the error dialog
  const ShowErrorDialog = () => {
    return <ErrorDialog
      data={error||{title:I18n('t_error_title'),message:I18n('t_error_request_400')}}
      dialogVisible={errorDialogVisible}
      onHideHandler={()=>setErrorDialogVisible(false)}
      closeHandler={()=>setErrorDialogVisible(false)} />

  }

  return { errorDialog, ShowErrorDialog, errorDialogVisible }
}

export default useErrorHandling
