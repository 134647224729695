import {ConfirmPopup} from 'primereact/confirmpopup'
import PropTypes from 'prop-types'
import './ConfirmationPopup.scss'

/**
 *
 */
const ConfirmationPopup = ({accept, reject, visible, dismissable, target, message, footer, onHide, className}) => {
  return (
    <ConfirmPopup
      className={className ? `confirmation-popup ${className}` : 'confirmation-popup'}
      dismissable={dismissable}
      target={target}
      visible={visible}
      footer={footer}
      message={message}
      onHide={onHide}
      accept={accept}
      reject={reject}
    />
  )
}

ConfirmationPopup.propTypes = {
  accept: PropTypes.func,
  reject: PropTypes.func,
  onHide: PropTypes.func,
  visible: PropTypes.bool.isRequired,
  dismissable: PropTypes.bool.isRequired,
  target: PropTypes.object,
  message: PropTypes.object.isRequired,
  footer: PropTypes.object.isRequired,
  className: PropTypes.string,
}

export default ConfirmationPopup