import React from 'react'
import PropTypes from 'prop-types'
import { Text } from 'components/atomic/index'
import {Skeleton} from 'primereact/skeleton'
import { Checkbox } from 'primereact/checkbox'
import bauStrandingImg from '../../../../../resources/images/icon/bau-stranding.svg'    
import agreedStrandingImg from '../../../../../resources/images/icon/agreed-actions-stranding.svg'  
import agreedNotStrandingImg from '../../../../../resources/images/icon/agreed-actions-not-stranding.svg'  
import applicableNotStrandingImg from '../../../../../resources/images/icon/applicable-actions-not-stranding.svg'
import applicableStrandingImg from '../../../../../resources/images/icon/applicable-actions-stranding.svg'
import './StaticNZCPathwayHeader.scss'
import { STRANDINGS } from 'utils/helpers/Constants'

const StaticNZCPathwayHeader = ({isLoading, strandings, totalAgreedActions, totalApplicableActions, handleApplicableActionsChange, applicableChecked}) => {
  const isPathwayStranding = (pathway, strandings) => strandings?.some(stranding => stranding.strandingOf === pathway)
  const getStrandingText = (pathway, strandings) => {
    const stranding = strandings?.find(stranding => stranding.strandingOf === pathway)
    if(stranding) {
      return <Text content='t_stranding_year_year' contentDynamic={{0: stranding.year}}/>
    }
    return <Text content='t_not_stranding' />
  }
  if(isLoading) return <Skeleton width="100%" height="4rem" className="mb-2" data-testid='pathway-header-skeleton'/>

  return (
    <div className='static-net-zero-carbon__pathways-header' data-testid='static-nzc-pathways-header'>
      <div className='pathways-content'>
        <div className='pathway-title'>
          {totalApplicableActions > 0 && (
            <Checkbox name="applicableActions" checked={applicableChecked} onChange={handleApplicableActionsChange} className='align-items-baseline' data-testid='applicable-actions-checkbox' />
          )} 
          <Text content='t_applicable_actions_count_nzc' contentDynamic={{0: totalApplicableActions}} />
        </div>
        <Text content='t_actions_pathway_optional' colour='faded_teal' />
      </div>
      <div className='pathways-content'>
        <div className='pathway-title'>
          <Text content='t_agreed_actions_count_nzc' contentDynamic={{0: totalAgreedActions}} />
        </div>
        <Text content='t_actions_pathway_recommended' colour='faded_teal' />
      </div>
      <div className='pathways-content'>
        <div className='pathway-title'>
          <img src={bauStrandingImg} alt='bau-stranding' className='align-items-baseline' />
          {getStrandingText(STRANDINGS.BAU, strandings)}
        </div>
        <Text content='t_bau_pathway' colour='faded_teal' />
      </div>
      <div className='pathways-content' data-testid='agreed-actions-stranding'>
        <div className='pathway-title'>
          <img src={isPathwayStranding(STRANDINGS.AGREED_ACTIONS_PATHWAY, strandings) ? agreedStrandingImg : agreedNotStrandingImg} alt='agreed-actions-stranding' className='align-items-baseline' />
          {getStrandingText(STRANDINGS.AGREED_ACTIONS_PATHWAY, strandings)}
        </div>
        <Text content='t_agreed_actions_pathway' colour='faded_teal' />
      </div>
      {applicableChecked && (
        <div className='pathways-content' data-testid='applicable-actions-stranding'>
          <div className='pathway-title'>
            <img src={isPathwayStranding(STRANDINGS.APPLICABLE_ACTIONS_PATHWAY, strandings) ? applicableStrandingImg : applicableNotStrandingImg} alt='applicable-actions-stranding' className='align-items-baseline' />
            {getStrandingText(STRANDINGS.APPLICABLE_ACTIONS_PATHWAY, strandings)}
          </div>
          <Text content='t_applicable_actions_pathway' colour='faded_teal' />
        </div>
      )}
    </div>
  )
}

StaticNZCPathwayHeader.propTypes = {
  isLoading: PropTypes.bool,
  strandings: PropTypes.array,
  applicableChecked: PropTypes.bool,
  handleApplicableActionsChange: PropTypes.func,
  totalApplicableActions: PropTypes.number,
  totalAgreedActions: PropTypes.number,
}

export default StaticNZCPathwayHeader 