import React, {useState} from 'react'

const useEventActionsState = () => {
  const getInitialEventActionsState = () => ({
    showActionDlg: false,
    showCommercialEventDlg: false,
    actionEditMode: false,
    visibleActiveSurveyDialog: false,
    assetEventsData: [],
    selectedRowActionData: null,
    selectedRowEventData: null
  })
  const [eventActionsState, setEventActionsState] = useState(getInitialEventActionsState)

  function updateEventActionsState(newState) {
    setEventActionsState(previousState => {
      return {
        ...previousState,
        ...newState
      }
    })
  }
  return {eventActionsState, updateEventActionsState}
}

export default useEventActionsState