import {
  actionTemplateV2,
  areaCoveredTemplateV2,
  costTemplateV2,
  costTypeTemplateV2, createdByTemplateV2, dateCreatedTemplateV2,
  estimatedChangeTemplateV2,
  impactTemplateV2,
  modifiedDateTemplateV2,
  notesTemplateV2,
  scheduledDateTemplateV2,
  scopeTemplateV2
} from '../../components/common/action-plan/actionPlanTableTemplate'

export function assetActionColumns(selectedLanguage)
{
  return [
    {
      key: 1,
      field: 'actionDescription',
      header: 't_action',
      bodyTemplate: (rowData) => actionTemplateV2(rowData, selectedLanguage),
      style: {minWidth: '21rem'}
    },
    {
      key: 2,
      field: 'impact',
      header: 't_impact',
      bodyTemplate: (rowData) => impactTemplateV2(rowData),
      style: {minWidth: '7rem'}
    },
    {
      key: 3,
      field: 'dueDate',
      header: 't_scheduled_date',
      bodyTemplate: (rowData, dataTableParameters) => scheduledDateTemplateV2(rowData, dataTableParameters),
      style: {minWidth: '8rem'},
      sortable: true,
    },
    {
      key: 4,
      field: 'estimatedChange',
      header: 't_estimated_change',
      bodyTemplate: (rowData, dataTableParameters) => estimatedChangeTemplateV2(rowData, dataTableParameters),
      style: {minWidth: '9rem'}
    },
    {
      key: 5,
      field: 'cost',
      header: 't_cost',
      bodyTemplate: (rowData, dataTableParameters) => costTemplateV2(rowData, dataTableParameters,selectedLanguage),
      style: {minWidth: '7rem'},
      sortable: true,
    },
    {
      key: 6,
      field: 'costType',
      header: 't_cost_type',
      bodyTemplate: (rowData) => costTypeTemplateV2(rowData),
      style: {minWidth: '9rem'}
    },
    {
      key: 7,
      field: 'areaCovered',
      header: 't_area_covered',
      bodyTemplate: (rowData) => areaCoveredTemplateV2(rowData),
      style: {minWidth: '9rem'}
    },
    {
      key: 8,
      field: 'scope',
      header: 't_scope',
      bodyTemplate: (rowData) => scopeTemplateV2(rowData),
      style: {minWidth: '6rem'}
    },
    {
      key: 9,
      field: 'lastModified',
      header: 't_modified_date',
      bodyTemplate: (rowData, dataTableParameters) => modifiedDateTemplateV2(rowData, dataTableParameters),
      style: {minWidth: '8rem'},
      sortable: true
    },
    {
      key: 10,
      field: 'createdBy',
      header: 't_created_by',
      bodyTemplate: (rowData) => createdByTemplateV2(rowData),
      style: {minWidth: '9rem'}
    },
    {
      key: 11,
      field: 'dateCreated',
      header: 't_date_created',
      bodyTemplate: (rowData, dataTableParameters) => dateCreatedTemplateV2(rowData, dataTableParameters),
      style: {minWidth: '8rem'},
      sortable: true
    },
    {
      key: 12,
      field: 'actionNotes',
      header: 't_notes',
      bodyTemplate: (rowData) => notesTemplateV2(rowData),
      style: {minWidth: '6rem'}
    },
  ]
}