import {InputText} from 'primereact/inputtext'
import './CertificateHeader.scss'
import PropTypes from 'prop-types'
import { Button, Icon } from 'components/atomic'
import { getLocalizedValue } from 'utils/helpers/LanguageUtils'
import { Text } from 'components/atomic/index'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

export const CertificatesHeader = ({title}) => {
  const { loginState: { userInfo } } = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  return (
    <div className={'certificates-header-container'}>
      <Text className={'certificates-header-container__title'} size='md' weight='bold' content={title} />
      <div className={'certificates-header-container__actions'}>

        <Button
          size={'small'}
          content='t_export'
          outlined={true}
        />

        <span className="p-input-icon-right header-actions__search-certificates">
          <InputText name='search' value='' placeholder={getLocalizedValue(selectedLanguage, 't_search_scheme_asset_name')} />
          <i role={'search-icon'} className="pi pi-search" />
        </span>
        <Button
          size={'small'}
          content='t_btn_add'
          icon={<Icon id='plus' />}
          disabled
        />
      </div>
    </div>)
}

CertificatesHeader.propTypes = {
  title : PropTypes.object.isRequired,
}