import PropTypes from 'prop-types'
import React, {useState, useEffect, useContext} from 'react'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import I18n from '../../../../../utils/i18n/I18n'
import './ActionPlan.scss'
import {FeatureFlagContext} from 'Contexts'
import {actionTemplate, impactTemplate, managerTemplate} from '../../../../common/action-plan/actionPlanTableTemplate'
import {areaCoveredMask, costMask, costTypeMask, ENERGY, WATER} from '../../../../../utils/helpers/Constants'
import commentBlue from '../../../../../resources/images/chart/comment-blue.svg'
import CSS_VARIABLES from '../../../../../resources/css/_variables.scss'
import {
  CurrencyUnitToLabel,
  getLocalizedValue,
  unitDateFormatter,
  unitsConverter
} from '../../../../../utils/helpers/Helper'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const Table = ({isDialog, action, actions, selectedRow}) => {

  //region Declarations/Initializations
  const {loginState: {userInfo}} = useLoginAuthContext()
  const featureContext = useContext(FeatureFlagContext)
  const featureState = featureContext.featureState
  const selectedLanguage = userInfo.languagePreference
  const unitSystem = userInfo.unitSystem
  const currencyUnit = userInfo.currencyUnit
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [showActionDlg, setShowActionDlg] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)

  //endregion

  //region useEffect Implementation
  useEffect(() => {
    selectedRow(selectedProduct, activeIndex)
  }, [selectedProduct, activeIndex])

  useEffect(() => {
    if (isDialog) {
      setSelectedProduct(null)
      setActiveIndex(0)
    }
  }, [isDialog])

  //endregion

  //region Helper methods
  const onRowSelect = () => {
    setShowActionDlg(!showActionDlg)
  }

  const formatUnits = (value) => {
    let newValue = ''
    if (value) {
      newValue = Number(value).toLocaleString(selectedLanguage)
    }
    return newValue
  }

  function currentUnit(data) {
    let unit = 'kWh'
    const energyUtility = data === ENERGY ? unitsConverter(ENERGY, unitSystem) : ' '
    if (data) {
      unit = data === WATER ? unitsConverter(data, unitSystem) : energyUtility
    }
    return unit.toString()
  }

  //endregion

  //region Custom templates
  const dateTemplateWithIndicator = (rowData) => {
    let date = getLocalizedValue(selectedLanguage, 't_add_date')
    let colorText
    if (rowData && rowData.action.dueDate !== '' && rowData.action.dueDate !== null) {
      date = unitDateFormatter(rowData.action.dueDate, unitSystem, selectedLanguage)
    }
    if (rowData.action.actionIndicator === 'Red') {
      colorText = 'red-dot'
    } else if (rowData.action.actionIndicator === 'Yellow') {
      colorText = 'yellow-dot'
    } else if (rowData.action.actionIndicator === 'Green') {
      colorText = 'green-dot'
    } else if (rowData.action.actionIndicator === 'Blue') {
      colorText = 'blue-dot'
    } else if (rowData.action.actionIndicator === 'White' && rowData.action.status === 'AgreedActions') {
      colorText = 'red-border-dot'
    } else if (rowData.action.actionIndicator === 'White') {
      colorText = 'white-dot'
    } else if (rowData.action.actionIndicator === 'NotSpecified') {
      colorText = ''
    }
    return (
      <div>
        {rowData.action.status !== 'Completed' ? <div className={`a-date-indicator ${colorText}`}/> : ''}
        {date !== getLocalizedValue(selectedLanguage, 't_add_date') ?
          <div className="perf-col-text3">{date}</div> :
          <div className="add-detail">{I18n('t_add_date')}</div>}
      </div>
    )
  }

  const notesTemplate = (rowData) => {
    return <div onClick={() => setActiveIndex(1)}>
      <div className="notification-number" id={`row-${rowData.action.actionId}`}>
        <div className="col-12 badge-style">
          {rowData.actionNotes.length > 0 &&
                        <span className={rowData.actionNotes.length > 9 ?  'p-badge a-table-left p-badge-info column-note' : 'p-badge a-table p-badge-info column-note'}>
                          {rowData.actionNotes.length > 99 ? '99+' : rowData.actionNotes.length}
                        </span>
          }
        </div>
      </div>
      {rowData.actionNotes.length > 0 ?
        <img src={commentBlue} alt="" data-id={rowData.action.actionId} className="note-icon"/> : null}
    </div>
  }

  const costTemplate = (rowData) => {
    let costValue = '-'
    let val = '-'
    let currencyLabel
    if(featureState.sieraplus_currency){
      currencyLabel = costMask[CurrencyUnitToLabel(currencyUnit)]
    } else {
      currencyLabel = costMask[rowData.action.currency]
    }
    if (rowData.action.cost) {
      costValue = formatUnits(rowData.action.cost)
      val = <div>{currencyLabel}{' ' + costValue}</div>
    }
    return val
  }

  const targetTemplate = (rowData) => {
    let tarPer = '-'
    let target = '-'
    let colorText = 'grey-text'
    if (rowData.action.targetUsagePercentage) {
      tarPer = formatUnits(rowData.action.targetUsagePercentage * 100) + '%'
    }
    if (rowData.action.target) {
      target = formatUnits(rowData.action.target) + currentUnit(rowData.action.impact)
    }
    if (rowData.action.targetPercent && rowData.action.targetPercent > 5) {
      colorText = 'red-text'
    } else if (rowData.action.targetPercent && rowData.targetPercent < 5) {
      colorText = 'green-text'
    }
    return (
      <div>
        <div className={`perf-col-text1 ${colorText}`}>{tarPer}</div>
        <div className="perf-col-text3">{target === '-' ? '' : '(' + target + ')'}</div>
      </div>
    )
  }

  const completionDateTemplate = (rowData) => {
    let date = ''
    if (rowData.action.completion) {
      date = unitDateFormatter(rowData.action.completion, unitSystem, selectedLanguage)
    }
    return (
      <div>
        {date !== '' ? <div className="perf-col-text3">{date}</div> : '-'}
      </div>
    )
  }

  const dueDateTemplate = (rowData) => {
    let date = ''
    if (rowData.action.dueDate) {
      date = unitDateFormatter(rowData.action.dueDate, unitSystem, selectedLanguage)
    }
    return (
      <div>
        {date !== '' ? <div className="perf-col-text3">{date}</div> : '-'}
      </div>
    )
  }

  const costTypeTemplate = (rowData) => {
    return <div className="perf-col-text5">{costTypeMask[rowData.action.costType]}</div>
  }

  const areaCoveredTemplate = (rowData) => {
    return <div className="perf-col-text5">
      {rowData.action.areaCovered ? areaCoveredMask[rowData.action.areaCovered] : '-' }
    </div>
  }

  const scopeTemplate = (rowData) => {
    return <div className="perf-col-text5">{rowData.action.scope ? rowData.action.scope : '-'}</div>
  }

  const procuredByTemplate = (rowData) => {
    return <div className="perf-col-text5">
      {rowData.action.procuredBy ? rowData.action.procuredBy : '-'}
    </div>
  }

  const getBodyClassName = () => {
    return action === 'NotApplicable' ? 'table-body-action-na' : 'table-body-action'
  }

  //endregion

  return (
    <DataTable value={actions}
      onRowSelect={onRowSelect}
      selection={selectedProduct}
      selectionMode="single"
      className={`p-datatable-gridlines event-actions-grid ${action === 'Completed' ? 'completed-actions-grid' : ''}`}
      onSelectionChange={e => setSelectedProduct(e.value)}
      data-testid={'action-datatable'}>

      <Column field="actionDescription"
        header={I18n('t_action')}
        body={actionTemplate}
        headerClassName="table-header-action"
        bodyClassName={action === 'NotApplicable' ? 'table-body-action-na-impact' : 'table-body-action-impact'}
        style={{width: '25%', color: CSS_VARIABLES.forest}}
      />

      {action === 'Completed' ?
        <Column field="completion"
          header={I18n('t_completion')}
          body={completionDateTemplate}
          headerClassName="table-header-action"
          bodyClassName={getBodyClassName()}
          style={{textAlign: 'center', width: '8.5%'}}
        />
        :
        <Column field="completion"
          header={I18n('t_due_date')}
          body={action !== 'NotApplicable' ? dateTemplateWithIndicator : dueDateTemplate}
          headerClassName="table-header-action"
          bodyClassName={getBodyClassName()}
          style={{textAlign: 'center', width: '8.5%'}}
        />
      }

      <Column field="impact"
        header={I18n('t_impact')}
        body={impactTemplate}
        headerClassName="table-header-action"
        bodyClassName={getBodyClassName()}
        style={{textAlign: 'center', width: '8%'}}
      />

      <Column field="target"
        header={I18n('t_estimated_savings')}
        body={targetTemplate}
        headerClassName="table-header-action"
        bodyClassName={getBodyClassName()}
        style={{textAlign: 'center', width: '8%'}}
      />

      <Column field="cost"
        header={I18n('t_cost')}
        body={costTemplate}
        headerClassName="table-header-action"
        bodyClassName={getBodyClassName()}
        style={{textAlign: 'center', width: '8%'}}
      />

      <Column field="costType"
        header={I18n('t_cost_type')}
        body={costTypeTemplate}
        headerClassName="table-header-action"
        bodyClassName={getBodyClassName()}
        style={{textAlign: 'center', width: '8%'}}
      />

      <Column field="procuredBy"
        header={I18n('t_procured_by')}
        body={procuredByTemplate}
        headerClassName="table-header-action"
        bodyClassName={getBodyClassName()}
        style={{textAlign: 'center', width: '8%'}}
      />

      <Column field="areaCovered"
        header={I18n('t_area_covered')}
        body={areaCoveredTemplate} headerClassName="table-header-action"
        bodyClassName={getBodyClassName()}
        style={{textAlign: 'center', width: '8%'}}
      />

      <Column field="scope"
        header={I18n('t_scope')}
        body={scopeTemplate}
        headerClassName="table-header-action"
        bodyClassName={getBodyClassName()}
        style={{textAlign: 'center', width: '7%'}}
      />

      <Column field="manager"
        header={I18n('t_manager')}
        body={managerTemplate}
        headerClassName="table-header-action"
        bodyClassName={getBodyClassName()}
        style={{textAlign: 'center', width: '7%'}}
      />

      <Column field="actionNotes"
        header={I18n('t_notes')} body={notesTemplate}
        headerClassName="table-header-action"
        bodyClassName={getBodyClassName()}
        style={{textAlign: 'center', width: '8%'}}
      />

    </DataTable>
  )
}

export default Table

Table.displayName = 'Table'

Table.propTypes = {
  isDialog: PropTypes.bool, 
  action: PropTypes.string, 
  actions: PropTypes.object, 
  selectedRow: PropTypes.func
}