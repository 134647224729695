import React from 'react'
import I18n from '../../../utils/i18n/I18n'
import {useHistory} from 'react-router-dom'
import {createNavURLForFundPages, getInstance} from '../../../utils/helpers/Helper'
import {ASSET_PAGES} from '../../../utils/helpers/Constants'
import './NoMatchedRoute.scss'

const NoMatchedRoute = () => {

  const history = useHistory()

  return (
    <div className="grey-bg min-width-760">
      <div className="display gutter">
        <div className="container-box">
          <h1>{I18n('t_page_not_found')}</h1>
          <p>{I18n('t_page_not_found_reason',{contactEvora: <a className='contact-link' href=''>{I18n('t_Contact_Evora')}</a>})}</p>
          <button className="blue-btn p-button p-component" onClick={() => history.push(createNavURLForFundPages({instanceName: getInstance(), pathSuffix: ASSET_PAGES.assets}), {replace: true})}>{I18n('t_error_back_to_homepage')}</button>
        </div>
      </div>
      <div className="footer-wrapper">
      </div>
    </div>

  )
}
export default NoMatchedRoute