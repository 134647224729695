import React, {useContext, useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import './FundCommonHeader.scss'
import NavigationTab from '../../../common/nav-tab/NavigationTab'
import {Dropdown} from 'primereact/dropdown'
import {
  CachedUserState,
  getLocalizedValue,
  getUserRole
} from '../../../../utils/helpers/Helper'
import {FeatureFlagContext} from 'Contexts'
import DatePicker from '../../../common/date-picker/DatePicker'
import {
  DROP_DOWN_HEIGHT, ELLIPSIS, EXPORT, DATA_FROM,
  FUND_ACTION_PLAN, FUND_DASHBOARD, GAS_FUELS_THERMALS, OTHER_FUELS_THERMALS,
  verticalEllipsisJson, LOCAL_STORAGE_KEYS
} from '../../../../utils/helpers/Constants'
import {useParams} from 'react-router-dom'
import I18n from '../../../../utils/i18n/I18n'
import {Button} from 'primereact/button'
import {OverlayPanel} from 'primereact/overlaypanel'
import FundExportSelection from '../../../../utils/data-exports/FundExportSelection'
import {VerticalEllipsis} from '../../../common/ellipsis-v/VerticalEllipsis'
import {Tooltip} from 'primereact/tooltip'
import MultiSelectDropdown from '../../../common/multi-select/MultiSelectDropdown'
import { BULK_UPLOAD } from '../../../../utils/i18n/constants'
import cn from 'classnames'
import UseUrlParameterIds  from '../../../../utils/custom-hooks/UseUrlParameterIds'
import { useQuery } from 'react-query'
import { getFundGroupsQuery } from '../../../../services/funds/fund-group-service'
import {noRefetchOnWindowFocus} from '../../../../services/common/useQuery-config'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const FundCommonHeader = (props) => {
  const {loginState} = useLoginAuthContext()
  const selectedLanguage = loginState.userInfo.languagePreference
  const featureContext            = useContext(FeatureFlagContext)
  const featureState              = featureContext.featureState
  const enableExport = !(props?.excelExportInfo?.options?.enableExport && (props?.excelExportInfo?.options?.enableExport === 'enabled' || props?.excelExportInfo?.options?.enableExport === true))
  const exportOverlay = useRef(null)
  const [isOverlayOpen, setIsOverlayOpen]=useState(false)
  const {instanceName, orgName} = useParams()
  const [orgId, setOrgId]=useState(null)
  const urlParameterIds=UseUrlParameterIds({instanceName, orgName})
  const queryParams = ['FundGroups', orgId]
  const {isLoading, data: fundGroup} = useQuery(queryParams, getFundGroupsQuery, noRefetchOnWindowFocus)
  const [fundName,setFundName] = useState(CachedUserState.getUserDataFromCache(LOCAL_STORAGE_KEYS.selectedOrgAndFund)?.fundName || CachedUserState.getUserDataFromCache(LOCAL_STORAGE_KEYS.cacheUrlParamsIdsData)?.actualFundName)

  useEffect(()=>{
    if(urlParameterIds && urlParameterIds.organisationId){
      setOrgId(urlParameterIds.organisationId)
    }
  },[urlParameterIds])

  useEffect(() => {
    if(!fundName && fundGroup && fundGroup.funds?.length > 0) {
      setFundName(fundGroup.funds.find(fund => fund.id === parseInt(urlParameterIds.fundId))?.name)
      let fund =fundGroup.funds.find(fund => fund.id === parseInt(urlParameterIds.fundId))
      if(!fund) {
        location.history.push('/')
      }
      saveUserDataToCache(LOCAL_STORAGE_KEYS.selectedOrgAndFund, {
        fundName: fund?.fundname,
        fundUrlName: fund?.fundUrlName,
        fundId: parseInt(urlParameterIds.fundId),
      })

    }
  },[fundGroup, fundName])

  const getDropdownItemTemplate = (option) => {
    const translateImpact = 't_' + (option.value === 'Health & Wellbeing' ? option.value.toLowerCase().replace(/ & /g,'_and_') : option.value.toLowerCase().replace(/ /g,'_'))
    const translateImpactValue = getLocalizedValue(selectedLanguage, translateImpact)
    const title = translateImpactValue.length > 21 ? `${translateImpactValue} (${option.count})` : null
    return (
      <>
        <div className="dropdown-item-1">
          <div title={title} className="dropdown-item-2 dropdown-item-wrap">{option.label}</div>
        </div>
        <div className={'dropdown-panel-item-horiz-line'} />
      </>
    )
  }

  const getDropdownValueTemplate = (value) => {
    const translateImpact = 't_' + (value && value.value === 'Health & Wellbeing' ? value && value.value.toLowerCase().replace(/ & /g,'_and_') : value && value.value.toLowerCase().replace(/ /g,'_'))
    const translateImpactValue = getLocalizedValue(selectedLanguage, translateImpact)
    const title = translateImpactValue && translateImpactValue.length > 21 ? `${translateImpactValue} (${value && value.count})` : null
    return (
      <span className={'dropdown-value'} title={title} >{value === null ? getLocalizedValue(selectedLanguage, 't_all_actions') : value.label}</span>
    )
  }

  const dropdownFilterElements = <Dropdown appendTo="self"
    panelClassName="fund-dropdown-panel"
    options={props.options}
    value={props.dropdownValue}
    className="sDropdown"
    placeholder={getLocalizedValue(selectedLanguage, 't_all_actions')}
    onChange={(e) => {props.onChange(e)}}
    itemTemplate={getDropdownItemTemplate}
    valueTemplate={getDropdownValueTemplate}
    scrollHeight={DROP_DOWN_HEIGHT} id={'fund-dropdown'}
  />

  const getPeriodMenuItem = (option) => (option === 'YTD') ?
    <span className='p-button-label p-c period-menu-ytd-tooltip' data-pr-position="top">{option}</span> :
    <span className='p-button-label p-c'>{option}</span>

  const exportButtonClickHandler = (e) => {
    switch (props?.excelExportInfo?.options?.dataFrom) {
    case DATA_FROM.FUND.FUND_NZC:
    case DATA_FROM.FUND.FUND_DATA_QUALITY_V2:
      props.onActionClick(e)
      break
    default:
      props?.excelExportInfo?.options?.hasOverlay && exportOverlay.current.toggle(e)
    }
  }
  /*
        * getRequiredDataForExport() used to get the required data that should be passed to <FundExportSelection/>
          based on the fund page data we are exporting
    */
  const getRequiredDataForExport = () => {
    const commonPropsForDataExport = {
      'timePeriod': props.period,
      'pickerValue': props.pickerValue,
      'selectedFund': {...props.selectedFund, fundname: fundName },
      'selectedUtility': props.dropdownValue,
      'dataFrom': props?.excelExportInfo?.options?.dataFrom,
      'fundAssetListQueryOptions': props?.excelExportInfo?.fundAssetListQueryOptions,
      'dataToBeExported': props?.excelExportInfo?.requiredData,
      'overlayRef': exportOverlay,
    }

    function getFundPerformanceRequiredExportData() {
      return {
        ...commonPropsForDataExport,
        'allUtilitiesList': props.options,
      }
    }

    function getFundDataQualityRequiredExportData() {
      return {
        ...commonPropsForDataExport,
      }
    }

    function getFundActionPlanRequiredExportData() {
      return {
        ...commonPropsForDataExport
      }
    }

    switch (props?.excelExportInfo?.options?.dataFrom) {

    case DATA_FROM.FUND.FUND_PERFORMANCE:
      return getFundPerformanceRequiredExportData()
    case DATA_FROM.FUND.FUND_DATA_QUALITY:
      return getFundDataQualityRequiredExportData()
    case DATA_FROM.FUND.FUND_ACTION_PLAN :
      return getFundActionPlanRequiredExportData()
    }
  }
  const isExportVisible = () => props.action === EXPORT
  function getExportOverlayPanelClassName() {
    let fixedClassName = `export-overlay ${props?.excelExportInfo?.options?.dataFrom}`
    switch (props.dropdownValue){
    case GAS_FUELS_THERMALS:
    case OTHER_FUELS_THERMALS:
      return fixedClassName.concat( ' gft-panel')
    default: return fixedClassName
    }
  }
  function getExportButtonIcon() {
    if(!enableExport) {
      if(props?.excelExportInfo?.options?.dataFrom === DATA_FROM.FUND.FUND_NZC ) {
        return  props?.isNZCExportOverlayPanelOpen ? 'pi pi-angle-up' : 'pi pi-angle-down'
      } else if(props?.excelExportInfo?.options?.dataFrom === DATA_FROM.FUND.FUND_DATA_QUALITY_V2) {
        return null
      } else {
        return  isOverlayOpen ? 'pi pi-angle-up' : 'pi pi-angle-down'
      }
    }
  }
  
  const featureFlagState = (featureState?.sieraplus_consumptionBulkUpload || featureState?.sieraplus_AssetModification)
  const verticalEllipsisCondition = () => {
    switch(props.componentName) {
    case FUND_DASHBOARD: return {isEnabled: getUserRole() && props.showVerticalEllipsis && featureFlagState, flag: BULK_UPLOAD.CONSUMPTION}
    case FUND_ACTION_PLAN: return {isEnabled: props.isActionPlanBulkUploadEnabled, flag: BULK_UPLOAD.ACTION_PLAN}
    default: return {isEnabled: false, flag: false}
    }
  }

  const verticalEllipsisKeyValue = {
    ...verticalEllipsisJson,
    'action': {
      ...verticalEllipsisJson['action'],
      'displayBulkUploadButton': true,
    },
    'consumption': {
      ...verticalEllipsisJson['consumption'],
      'displayBulkUploadButton': getUserRole() && featureState.sieraplus_consumptionBulkUpload,
      'displayAssetAddButton': true,
      'displayAssetEditButton': false,
    }
  }

  return (
    <div className={'fund-common-header'}>
      <div className={'fund-name-date-picker-container p-d-flex p-jc-between'}>
        <div className='fund-name-section'>{fundName}</div>
        {props.hasDatePicker &&
          <div className='fund-date-picker-section'>
            <DatePicker value={props.period} options={props.periodOptions} onChange={props.datePickerOnChange}
              itemTemplate={getPeriodMenuItem} pickerValue={props.pickerValue} pickerOnChange={props.pickerOnChange}
              customDateSummary={props.customDateSummary} customDatePickerOnClick={props.customDatePickerOnClick}
              dateActive={props.customTime} />
          </div>
        }
        <div id="fund-export-container" style={{position: 'relative'}} className={cn('fund-export-container', props.isActionPlanBulkUploadEnabled && 'ml-auto')}>
          {isExportVisible() &&
            <>
              <Tooltip className={'portfolio-comm-tooltip long-text-tooltip'} target=".nav-export" position='top'/>
              <div className={'nav-export'} id='export'  data-pr-tooltip={enableExport ? getLocalizedValue(selectedLanguage,'t_no_data_to_export') : ''}>
                <Button label={getLocalizedValue(selectedLanguage, 't_export')} className={'export-btn nav-export-btn'}
                  onClick={exportButtonClickHandler}
                  disabled={enableExport} data-testid='export-btn-id'
                  icon={getExportButtonIcon()} iconPos={'right'}
                  loading={props?.excelExportInfo?.options?.isLoading}
                />
              </div>
              {props?.excelExportInfo?.options?.hasOverlay &&
                    <OverlayPanel ref={exportOverlay} appendTo={document.getElementById('export')}
                      className={getExportOverlayPanelClassName()} data-testid='overlay-id'
                      onShow={() => setIsOverlayOpen(true)} onHide={() => setIsOverlayOpen(false)}>
                      <FundExportSelection {...getRequiredDataForExport()}/>
                    </OverlayPanel>}
            </>
          }
        </div>
        {verticalEllipsisCondition().isEnabled &&
          <div className={'ml-3 relative'} id={ELLIPSIS}>
            <VerticalEllipsis
              keyId={props.bulkUploadConsumptionId}
              routeVia={{name: fundName, routeVia: FUND_DASHBOARD}}
              flag={verticalEllipsisCondition().flag}
              bulkUploadKeyValue={verticalEllipsisKeyValue[verticalEllipsisCondition().flag]}
              selectedLanguage={selectedLanguage}
            />
          </div>
        }
      </div>
      <div className={`back-navigation-menu-container ${props.hasBlueBackground && 'white-navigation'} `}>
        <NavigationTab navLinkConfigs={props.navLinkConfigs} isNewLayout = {props.isNewLayout ? props.isNewLayout : false}/>
      </div>
      {props.hasDropdownFilter &&
        <div className={props.multiSelectDQData?.showMultiDropdown ?
          'multiselect-menus-container' : 'dropdown-menus-container'}>
          <div data-testid={'utility-dropdown'}>
            {props.multiSelectDQData?.showMultiDropdown ?
              <div className={'multiselect-utility-dropdown'}>
                <label className={'multiselect-utility-label'}>{I18n('t_utilities')}</label>
                <MultiSelectDropdown
                  dataInfo={props.multiSelectDQData}
                  onChangeHandler={props.multiSelectOnChangeHandler}
                  multiSelectedOptions={props.multiSelectedOptions}
                  onHideDropdown={props.onHideDropdown}
                  disableOptionItemHandler={props.disableOptionItemHandler}
                />
              </div>
              :
              <div className={'action-dropdown'} data-testid={'action-dropdown'}>
                {dropdownFilterElements}
              </div>
            }
          </div>
        </div>
      }

    </div>
  )
}


FundCommonHeader.propTypes = {
  action: PropTypes.string,
  hasDropdownFilter: PropTypes.bool,
  hasDatePicker: PropTypes.bool,
  hasBlueBackground: PropTypes.bool,
  navLinkConfigs: PropTypes.array,
  options: PropTypes.array,
  period: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  periodOptions: PropTypes.array,
  pickerOnChange: PropTypes.func,
  pickerValue: PropTypes.object,
  selectedFund: PropTypes.object,
  showVerticalEllipsis: PropTypes.bool,
  dropdownValue: PropTypes.string,
  onChange: PropTypes.func,
  datePickerOnChange: PropTypes.func,
  customDateSummary: PropTypes.any,
  customDatePickerOnClick: PropTypes.func,
  customTime: PropTypes.bool,
  excelExportInfo: PropTypes.object,
  bulkUploadConsumptionId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  isNewLayout: PropTypes.bool,
  onActionClick: PropTypes.func,
}

FundCommonHeader.displayName = 'FundCommonHeader'

export default FundCommonHeader