import axiosInstance from '../common/axios-config'

export const addBulkActionPlan = async (queryParam) => {
  const {data} = await axiosInstance.get(`api/v1/BulkUploadAction/${queryParam[1]}/FundAssetsBulkAddActions`)
  return data
}

export const fetchBulkActionPlanToEdit = async (queryParam) => {
  const {data} = await axiosInstance.get(`api/v1/BulkUploadAction/${queryParam[1]}/FundAssetsBulkEditActions`)
  return data
}

export const fetchBulkPortfolioActionPlan = async (queryParam) => {
  const queryVariables = queryParam[1]?.paginationParams || queryParam[1]?.paginationParameters
  const {data} = await axiosInstance.get(`/api/v1/BulkUploadAction/PortfolioAssetsBulkAddActions?fund=${queryVariables?.fund}&location=${queryVariables?.location}&sector=${queryVariables?.sector}&searchTerm=${queryVariables?.searchTerm}&period=${queryParam[1].period}&noOfMonths=${queryParam[1].noOfMonths}&endMonth=${queryParam[1].endDate}`)
  return data
}

export const fetchBulkPortfolioEditActionPlan = async (queryParam) => {
  const queryVariables = queryParam[1]?.paginationParams || queryParam[1]?.paginationParameters
  const {data} = await axiosInstance.get(`/api/v1/BulkUploadAction/PortfolioAssetsBulkEditActions?fund=${queryVariables?.fund}&location=${queryVariables?.location}&sector=${queryVariables?.sector}&searchTerm=${queryVariables?.searchTerm}&period=${queryParam[1].period}&noOfMonths=${queryParam[1].noOfMonths}&endMonth=${queryParam[1].endDate}`)
  return data
}