import React, {memo} from 'react'
import './NzcBenchmarkOptions.scss'
import {RadioButton} from 'primereact/radiobutton'
import I18n from '../../../../utils/i18n/I18n'
import {BENCHMARK_OPTIONS} from '../../../../utils/helpers/Constants'

const NzcBenchmarkOptions = ({benchmarkOptionsProps,assetNzc, getBenchmarkOptionsData}) => {

  const {benchmarkOption, benchmarkChangeAction}=benchmarkOptionsProps
  const enabledBenchmark = getBenchmarkOptionsData && getBenchmarkOptionsData?.find((option) => option.enabled)

  /**
   * Returns benchmark id value
   *
   * @param {string} labelToFind to get benchmark label
   */
  function getValueByLabel(labelToFind) {
    for (const item of getBenchmarkOptionsData) {
      if (item.label === labelToFind) {
        return item.value
      }
    }
    return null
  }

  /**
   * Returns the checked benchmark options
   *
   * @param {number} id to get the radio option id
   */
  const checkedBenchMarkOptions = (id) => {
    if (assetNzc) {
      switch (id) {
      case enabledBenchmark?.value:
        return !!enabledBenchmark?.enabled && benchmarkOption.selectedMarketBenchmarkID === enabledBenchmark?.value
      case BENCHMARK_OPTIONS.noBenchmark:
        return benchmarkOption.selectedMarketBenchmarkID === BENCHMARK_OPTIONS.noBenchmark || !enabledBenchmark?.enabled
      default:
        return false
      }
    } else {
      switch (id) {
      case getValueByLabel(BENCHMARK_OPTIONS.office):
      case getValueByLabel(BENCHMARK_OPTIONS.shoppingCenter):
        return benchmarkOption.selectedMarketBenchmarkID === id && !!benchmarkOption.displayMarketBenchmark
      default:
        return benchmarkOption.selectedMarketBenchmarkID === BENCHMARK_OPTIONS.noBenchmark || !benchmarkOption.displayMarketBenchmark
      }
    }
  }


  /**
   * Returns boolean value to disable the benchmark
   *
   * @param {number} id to get the radio option id
   */
  const disableBenchmarkOptions = (id) => {
    if (assetNzc) {
      if (id === enabledBenchmark?.value) {
        return !enabledBenchmark?.enabled
      } else return !BENCHMARK_OPTIONS.noBenchmark
    }

    return false
  }

  return (
    <div className="nzc-benchmark-options">
      <div className="header"><span className="header-title">{I18n('t_benchmark_line')}</span></div>
      <div className="content">
        <div className="content-no-benchmark content-row">
          <RadioButton
            className="content-no-benchmark-option"
            inputId="content-no-benchmark-option"
            onChange={() => benchmarkChangeAction(BENCHMARK_OPTIONS.noBenchmark)}
            checked={checkedBenchMarkOptions(BENCHMARK_OPTIONS.noBenchmark)}
          />
          <label className="content-no-benchmark-label" htmlFor="content-no-benchmark-option">{I18n('t_no_benchmark')}</label>
        </div>
        <div className="content-office content-row">
          <RadioButton
            className="content-office-option"
            inputId="content-office-option"
            onChange={()=>benchmarkChangeAction(getValueByLabel(BENCHMARK_OPTIONS.office))}
            checked={checkedBenchMarkOptions(getValueByLabel(BENCHMARK_OPTIONS.office))}
            disabled={disableBenchmarkOptions(getValueByLabel(BENCHMARK_OPTIONS.office))}
          />
          <label className="content-office-label" htmlFor="content-office-option">{I18n('t_benchmark_office_ac')}</label>
        </div>
        <div className="content-sc content-row">
          <RadioButton
            className="content-sc-option"
            inputId="content-sc-option"
            onChange={()=>benchmarkChangeAction(getValueByLabel(BENCHMARK_OPTIONS.shoppingCenter))}
            checked={checkedBenchMarkOptions(getValueByLabel(BENCHMARK_OPTIONS.shoppingCenter))}
            disabled={disableBenchmarkOptions(getValueByLabel(BENCHMARK_OPTIONS.shoppingCenter))}
          />
          <label className="content-sc-label" htmlFor="content-sc-option">{I18n('t_benchmark_sc_ac')}</label>
        </div>
      </div>
    </div>
  )
}

export default memo(NzcBenchmarkOptions)