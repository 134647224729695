import { noRefetchOnWindowFocus } from '../../../../services/common/useQuery-config'
import { GetNZCScopeDetails, GetNZCScopes, GetNZCTargetPathways } from '../../../../services/funds/nzc-service'
import { useQueries } from 'react-query'

const useNZCQueryData = ({
  scopeVariables,
  scopeDetailsVariables,
  graphDataVariable
}) => {
  const [nzcScope, scopeDetails, graphNZCData, nzcPerformanceSummary] = useQueries([
    { queryKey: scopeVariables, queryFn: GetNZCScopes, ...noRefetchOnWindowFocus, enabled: !!scopeVariables[1] },
    { queryKey: scopeDetailsVariables, queryFn: GetNZCScopeDetails, ...noRefetchOnWindowFocus, enabled: (!!scopeDetailsVariables[1] && !!scopeDetailsVariables[2]) },
    { queryKey: graphDataVariable, queryFn: GetNZCTargetPathways, ...noRefetchOnWindowFocus }
  ])

  return {
    nzcScope, scopeDetails, graphNZCData, nzcPerformanceSummary
  }
}

export default useNZCQueryData