import PropTypes from 'prop-types'
import {getSortIcons, getUtilConsumptionTemplate} from '../../../../../utils/helpers/DataTableUtils'
import {Column} from 'primereact/column'
import {DataTable} from 'primereact/datatable'
import './MeterTable.scss'
import {AREA, sortDown, sortUp} from '../../../../../utils/helpers/Constants'
import {Icon, Text} from '../../../../atomic'
import {
  formatNumWODecimal,
  trucateNumWODecimal,
} from '../../../../../services/common/formatter'
import { unitsConverter } from 'utils/helpers/Helper'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'
import { isLandlord } from '../data-quality-hooks-&-helpers/dataQualityHelper'
import { getPortfolioPaginatorTemplate } from '../../portfolio/hooks-and-helpers/PortfolioHelper'
import { isEmptyObject } from 'utils/helpers/Utils'
import { formatDateRange, formatDate } from 'utils/helpers/DateUtils'

const MeterTable = ({
  data,
  onSort,
  sortField,
  sortOrder,
  metersSelected,
  meterSelectedFieldName,
  updateDataQualityState,
  first,
  rows,
  responsibility
}) => {
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const unitSystem = userInfo.unitSystem
  const sortIconToggle = sortOrder === 1 ? sortUp : sortDown
  const dataCategoriesColours = Object.freeze({
    actual: 'forest',
    actual_data: 'forest',
    missing_tc: 'orange',
    calculated_estimate: 'sand',
    supplier_estimate: 'green_light'
  })

  function replaceSpaceWithHyphen(text) {
    return text.replace(/\s+/g, '_')?.toLowerCase()
  }

  function getCellContentTemplate(rowData, {field}) {
    if(rowData[field]) {
      return <Text content={rowData[field]} size="xs" />
    }
    return <Text content="-" size="xs" />
  }

  function getOriginContentTemplate(rowData, {field}) {
    let origin = rowData[field]
    if(rowData[field] === '' || !rowData[field]) return <Text content="-" size="xs"/>
    return <Text content={origin} size="xs"/>
  }

  function getSubmittedContentTemplate(rowData, {field}) {
    let submittedContent = rowData.submittedBy || '-'
    if(rowData.submittedDate)
    {
      submittedContent = `${rowData.submittedBy} ${formatDate(rowData.submittedDate, selectedLanguage)}`
    }
    return <Text content={submittedContent} size="xs"/>
  }

  function utilFloorAreaTemplate(rowData) {
    let floorArea = '-'
    if (rowData.floorArea) {
      floorArea = `${formatNumWODecimal(selectedLanguage, trucateNumWODecimal(rowData.floorArea))} ${unitsConverter(AREA, unitSystem)}`
    }
    return <Text content={floorArea} size="xs" />
  }

  const utilConsumptionTemplate = (rowData) => {
    return getUtilConsumptionTemplate(rowData, selectedLanguage, unitSystem, null, true)
  }

  const dateIntervalTemplate = (rowData) => {
    if (!rowData.dateRange || isEmptyObject(rowData.dateRange)) return <Text content="-" size="xs" />
    const {end, start} = rowData.dateRange
    return (
      <Text
        content={formatDateRange(start, end)}
        size="xs"
      />
    )
  }

  function getIconAndContentTemplate(rowData, {field}) {
    return (
      <>
        <Icon id="square_solid" colour={dataCategoriesColours[replaceSpaceWithHyphen(rowData.dataCategory)]} size={10} />
        <Text content={`t_${rowData[field]}`} size="xs" />
      </>
    )
  }

  function getColumnHeader(headerKey) {
    return <Text content={headerKey} size="s" />
  }

  const onMeterSelectionChange = (evt) => {
    updateDataQualityState({[meterSelectedFieldName]: evt.value})
  }

  const onPageChange = (event) => {
    isLandlord(responsibility) ?
      updateDataQualityState({landlordFirst: event.first, landlordRows: event.rows}) : 
      updateDataQualityState({tenantFirst: event.first, tenantRows: event.rows})
  }

  const onShowSelectionCheckbox = (rowData) => {
    return rowData?.dataCategory?.toLowerCase() === 'missing_tc' ? true : null
  }

  return (
    <div className="dq-meters">
      <DataTable
        value={data?.data}
        emptyMessage={<Text content="t_no_results" size="xs" />}
        className="dq-meters__table"
        onSort={onSort}
        sortField={sortField}
        sortOrder={sortOrder}
        defaultSortOrder={-1}
        paginator={data?.total > 5}
        lazy
        first={first}
        rows={rows}
        paginatorTemplate={data?.total > 5 ? getPortfolioPaginatorTemplate() : null}
        paginatorClassName='data-table-paginator'
        selection={metersSelected}
        onSelectionChange={onMeterSelectionChange}
        sortIcon={getSortIcons()}
        scrollHeight={'451px'}
        onPage={e => onPageChange(e)}
        totalRecords={data?.total}
        showSelectionElement={rowData => onShowSelectionCheckbox(rowData)}
        selectionMode='checkbox'
      >
        {data?.data?.some(meter => meter.dataCategory === 'missing_tc') ? <Column selectionMode="multiple" className="selection-icon" /> : null}
        <Column
          field="meterName"
          header={() => getColumnHeader('t_meter_name')}
          headerClassName={sortIconToggle}
          body={getCellContentTemplate}
          className="meter-name"
        />
        <Column
          field="dataCategory"
          header={() => getColumnHeader('t_data_category')}
          headerClassName={sortIconToggle}
          body={getIconAndContentTemplate}
          className="data-category"
        />
        <Column
          field="utility"
          header={() => getColumnHeader('t_utility_type')}
          headerClassName={sortIconToggle}
          body={getCellContentTemplate}
          className="utility-type"
        />
        <Column
          field="supplier"
          header={() => getColumnHeader('t_supplier')}
          headerClassName={sortIconToggle}
          body={getCellContentTemplate}
          className="supplier"
        />
        <Column
          field="areaCovered"
          header={() => getColumnHeader('t_area_covered')}
          headerClassName={sortIconToggle}
          body={getOriginContentTemplate}
          className="area-covered"
        />
        <Column
          field="floorArea"
          header={() => getColumnHeader('t_area_served')}
          headerClassName={sortIconToggle}
          body={utilFloorAreaTemplate}
          className="area-served"
        />
        <Column
          field="date"
          header={() => getColumnHeader('t_date')}
          headerClassName={sortIconToggle}
          body={dateIntervalTemplate}
          className="date"
        />
        <Column
          field="consumption"
          header={() => getColumnHeader('t_consumption')}
          headerClassName={sortIconToggle}
          body={utilConsumptionTemplate}
          className="consumption"
        />
        <Column
          field="origin"
          header={() => getColumnHeader('t_origin')}
          headerClassName={sortIconToggle}
          body={getOriginContentTemplate}
          className="origin"
        />
        <Column
          field="submittedBy"
          header={() => getColumnHeader('t_submitted_by')}
          headerClassName={sortIconToggle}
          body={getSubmittedContentTemplate}
          className="submitted-by"
        />
      </DataTable>
    </div>
  )
}

MeterTable.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onSort: PropTypes.func,
  sortField: PropTypes.string,
  sortOrder: PropTypes.number,
  metersSelected: PropTypes.array,
  meterSelectedFieldName: PropTypes.string,
  updateDataQualityState: PropTypes.func,
  first: PropTypes.number,
  rows: PropTypes.number,
  responsibility: PropTypes.string
}

MeterTable.displayName = 'MeterTable'

export default MeterTable