import React from 'react'
import {Button} from 'primereact/button'
import I18n from '../../../../utils/i18n/I18n'
import './ConsumptionUploadStatus.scss'
import { Icon } from 'components/atomic'
import {getLocalStorageItem, unitDateFormatter} from '../../../../utils/helpers/Helper'
import PropTypes from 'prop-types'
import {topDownPathways,consumptionUpload} from '../../../../utils/helpers/Constants'
import { useHistory, useParams } from 'react-router-dom'

const ConsumptionUploadStatus = ({uploadStatus, handleReturnClick, typeOfUpload, selectedLanguage, unitSystem}) => {
  const userName = getLocalStorageItem('userName')
  const { consumptionId } = useParams()
  const navigate = useHistory()
  const assetName = navigate?.location?.state?.fileName
  function getAppropriateText(message){
    function getTopDownPathwayMessageOne(){
      return uploadStatus.count === 1 ? I18n('t_top_down_pathway_success_message_one_single_pathway', {0: uploadStatus.count}) :
        I18n('t_top_down_pathway_success_message_one', {0: uploadStatus.count})
    }
    const ACTION_UPLOAD='action-upload'
    const isTopDownPathway = typeOfUpload === topDownPathways
    const isactionupload= typeOfUpload ===ACTION_UPLOAD
    switch (message){
    case ACTION_UPLOAD: return !Number(consumptionId) ?  {
      textOne:  I18n('t_action_upload_success_text_one', {
        0: uploadStatus.count,
        1: unitDateFormatter(new Date(), unitSystem, selectedLanguage),
        2: userName
      }),
      textTwo: <>
        <Icon id="warning_solid_triangle" colour='orange' className={'consumption-status__exclamation-icon'}/>
        <span className={'larger-text-400'}>{I18n('t_action_upload_success_text_two')}</span>
      </>
    } : {
      textOne:  I18n('t_action_upload_single_asset_success_text_one', {
        0: assetName,
        1: unitDateFormatter(new Date(), unitSystem, selectedLanguage),
        2: userName
      }),
      textTwo: <>
        <Icon id="warning_solid_triangle" colour='orange' className={'consumption-status__exclamation-icon'}/>
        <span className={'larger-text-400'}>{I18n('t_action_upload_success_text_two')}</span>
      </>
    }
    case consumptionUpload: return {
      textOne:  I18n('t_consumption_upload_success_text_one', {
        0: uploadStatus.count,
        1: unitDateFormatter(new Date(), unitSystem, selectedLanguage),
        2: userName
      }),
      textTwo: <>
        <Icon id="warning_solid_triangle" colour='orange' className={'consumption-status__exclamation-icon'}/>
        <span className={'larger-text-400'}>{I18n('t_consumption_upload_success_text_two')}</span>
      </>
    }
    case topDownPathways: return {
      textOne: getTopDownPathwayMessageOne(),
      textTwo: <span className={'larger-text-400'}>{I18n('t_top_down_pathway_success_message_two')}</span>
    }
    case 'button':
      if (isactionupload) {
        return I18n('t_return_action')
      } else if (isTopDownPathway) {
        return I18n('t_return_to_net_zero_carbon')
      } else {
        return I18n('t_return_home')
      }

    default : return ''
    }
  }
  const successMessage = getAppropriateText(typeOfUpload)
  return (
    <div className={'consumption-status white-bg mb-4'}>
      {uploadStatus.status === 'SUCCESS' ?
        <div role={'upload-status-info'}>
          <span data-testid={'upload-success-title'} className={'content-align-center'}>
            <Icon id="check_solid_circle" colour='green' className={'consumption-status__info-icon'} size={'35'}/>
            <span className={'xxx-large-text'}>{I18n('t_consumption_upload_success_title')}</span>
          </span>
          <p className={'larger-text-400 mb-0'} data-testid={'upload-success-text-one'}>
            {successMessage.textOne}
          </p>
          <p className={'content-align-center mt-0_5'} data-testid={'upload-success-text-two'}>
            {successMessage.textTwo}
          </p>
        </div>
        :
        <div role={'upload-status-info'}>
          <span data-testid={'upload-error-title'} className={'content-align-center'}>
            <Icon id="warning_solid_circle" colour='red' className={'consumption-status__info-icon'} size={'35'} testId='warning_solid_circle'/>
            <span className={'xxx-large-text'}>{I18n('t_consumption_upload_error_title')}</span>
          </span>
          <p className={'larger-text-400 mb-0'} data-testid={'upload-error-text-one'}>
            {I18n('t_consumption_upload_error_text_one')}
          </p>
          <pre className={'content-align-center larger-text-400 mt-0_5'} data-testid={'upload-error-text-two'}>
            {I18n('t_consumption_upload_error_text_two', {
              0: <a className={'support-link transform-text-lowercase'} href={'mailto:support@sieraglobal.com'}
                target="_blank" rel="noreferrer noopener">
                {I18n('t_es_contact_support')}
              </a>
            })}
          </pre>
        </div>
      }

      <Button
        className={'consumption-status__return-btn common-blue-bg-btn p-3'}
        label={getAppropriateText('button')}
        onClick={handleReturnClick} aria-label='return-back-btn'/>
    </div>
  )
}

ConsumptionUploadStatus.propTypes = {
  uploadStatus: PropTypes.shape({
    count: PropTypes.number
  }),
  typeOfUpload: PropTypes.string,
  handleReturnClick: PropTypes.func,
  selectedLanguage: PropTypes.string,
  unitSystem: PropTypes.string
}
export default ConsumptionUploadStatus