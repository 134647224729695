import React from 'react'
import './ConsumptionUploadOneSchema.scss'
import OneSchemaImporter from '@oneschema/react'
import {useMutation} from 'react-query'
import { BulkConsumptionFinalSubmit } from '../../../../services/buk-consumption/bulk-consumption-service'
import PropTypes from 'prop-types' // oneSchema integration
import {actionOptions, ACTIONS_FILE_TYPE, oneSchemaTemplate} from '../../../../utils/helpers/Constants'
import {uploadNzcTopdownPathways} from '../../../../services/funds/nzc-service'
import {useLocation, useParams} from 'react-router-dom'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const ConsumptionUploadOneSchema = (props) => {
  const {consumptionId} = useParams()
  const location = useLocation()
  const queryValues = new URLSearchParams(location.search)
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const submitExcelSheet = useMutation(BulkConsumptionFinalSubmit)
  const submitTopdownPathways = useMutation(uploadNzcTopdownPathways)
  const oneSchemaClientId = process.env.REACT_APP_ONE_SCHEMA_CLIENT_ID
  const oneSchemaUserJwt = process.env.REACT_APP_ONE_SCHEMA_USER_JWT
  const oneSchemaBaseURL = process.env.REACT_APP_ONE_SCHEMA_BASE_URL
  //SPR-6013
  const templateMap = {
    consumption_upload: 'Consumptions',
    'assets_action_plans': 'assets_action_plans',
    'fund_nzc_top_down_pathways': 'fund_nzc_top_down_pathways'
  }

  const onSuccessTopdownPathways = (data) => {
    const nzcIntensityType = queryValues.get('intensity')
    submitTopdownPathways.mutateAsync({
      scopeId: consumptionId,
      intensityType: nzcIntensityType?.charAt(0).toUpperCase() + nzcIntensityType?.slice(1),
      topDownPathways: {
        'topdownPathways': data.records,
        'fileName': data.sheet_metadata.original_file_name
      }
    }).then((response) => {
      props.setUploadStatus({status: 'SUCCESS', count: data.count})
    }).catch((error) => {
      props.setUploadStatus({status: 'ERROR', count: 0})
    })
  }

  const getFileType = (sheetname) => {
    return sheetname.includes(actionOptions.OPTION_EDIT_USER) ? ACTIONS_FILE_TYPE.EDIT_ACTIONS : ACTIONS_FILE_TYPE.ADD_ACTIONS
  }

  const onSuccess = async (data) => {
    if(props.templateName === oneSchemaTemplate.fundNzcTopDownPathways){
      onSuccessTopdownPathways(data)
    } else {
      await submitExcelSheet.mutateAsync({
        'embed_id': data.embed_id,
        'count': data.count,
        'file_type': templateMap['assets_action_plans'] === data?.template_key ? getFileType(data?.sheet_metadata?.original_sheet_name) : templateMap[data?.template_key],
        'file_name': data.sheet_metadata.original_file_name
      }).then((response) => {
        props.setUploadStatus({status: 'SUCCESS', count: data.count})
      }).catch((error) => {
        props.setUploadStatus({status: 'ERROR', count: 0})
      })
    }
  }

  const onCancel = () => {
    // TODO handle cancel
  }

  const onError = (message) => {
    // TODO 'ERROR' to be replaced with appropriate API response
    props.setUploadStatus({status: 'ERROR', count: 0})
  }

  const onRequestCloseOneSchema = () => {
    props.hideConsumptionDialog()
  }
  
  return (
    <div>
      <OneSchemaImporter
        /* managing state */
        isOpen={props.consumptionDialog}
        onRequestClose={onRequestCloseOneSchema}
        /* required config values
        * Hard coded to test*/
        clientId={oneSchemaClientId}
        // skipMapping
        userJwt={oneSchemaUserJwt}
        templateKey={props.templateName}
        baseUrl={oneSchemaBaseURL}
        /* optional config values */
        importConfig={{type: 'local', metadataOnly: props.templateName !== oneSchemaTemplate.fundNzcTopDownPathways}}
        devMode={false}
        className='oneschema-importer'
        languageCode={selectedLanguage}
        inline={true}
        customizationOverrides={{
          uploaderShowSidebar: props.templateName !== oneSchemaTemplate.fundNzcTopDownPathways,
          uploaderShowSidebarBanner: true,
          includeExcelTemplate: false,
          importMaxRowLimit: props.templateName === oneSchemaTemplate.fundNzcTopDownPathways ? 7 : ''
        }}
        /* handling results */
        onSuccess={onSuccess}
        onCancel={onCancel}
        onError={onError}
      />
    </div>
  )
}

ConsumptionUploadOneSchema.propTypes = {
  templateName: PropTypes.string,
  setUploadStatus: PropTypes.object,
}
export default ConsumptionUploadOneSchema
