import React, {useState} from 'react'
import './NzcModel.scss'
import {Button} from 'primereact/button'
import I18n from '../../../../../utils/i18n/I18n'
import {useMutation} from 'react-query'
import {getLocalizedValue} from '../../../../../utils/helpers/Helper'
import {FileUpload} from 'primereact/fileupload'
import {useFormik} from 'formik'
import CommonCreateModel from './CommonCreateModel'
import {uploadNzcAssetImportData, uploadNZCImportData, ValidateNZCImportData} from '../../../../../services/funds/nzc-service'
import warningIcon from '../../../../../resources/images/icon/warning-red.svg'
import {hasModelNameValidationError, nzcModelNameValidator} from '../../../../common/net-zero-carbon/nzc-common-utils'
import {MODEL_FILE_NAME_MAX_ALLOWED_CHARS} from '../../../../common/net-zero-carbon/nzc-constants'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const NzcImportModel = (props) => {
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const chooseOptions = {label:  <u>{I18n('t_select_file')}</u>, icon: ' '}
  const [fileName, setFileName] = useState('')
  const [fileLength, setFileLength] = useState(0)
  const [errorResponse, setErrorResponse] = useState('')
  const csvFileReader = new FileReader()
  const [csvValidationResponse, setCsvValidationResponse] = useState([])
  const [copied, setCopied] = useState('')
  const [fileUploadRequest, setFileUploadRequest]= useState()

  const validateNZCImportDataMutation = useMutation(ValidateNZCImportData)
  const uploadNZCImportDataMutation = useMutation(props.assetLevel ? uploadNzcAssetImportData : uploadNZCImportData)
  const [excelHeaderRowCount] = useState(1)
  const [fileNameError, setFileNameError]=useState(false)

  function validateImportedCsv  (csvString) {
    const csvAssetHeader = csvString.slice(0, csvString.indexOf('\n')).split(',').map(headerValue => headerValue.replace(/(?:\r\n|\r|\n|\t)/g, ''))
    const csvAssetRows = csvString.slice(csvString.indexOf('\n') + 1).split('\n').map(rowValue => rowValue.replace(/(?:\r\n|\r|\n|\t)/g, ''))
    let values
    const csvFileArray = csvAssetRows.map(i => {
      values = i.split(/,(?=(?:[^"]*"[^"]*")*[^"]*$)/)
      return csvAssetHeader.reduce((csvObjects, csvHeader, index) => {
        csvObjects[csvHeader] = values[index]
        return csvObjects
      }, {})
    }).map((asset) => {
      return {
        assetId: parseInt(asset['Asset ID']),
        assetRef: asset['Asset Ref'],
        assetName: asset['Asset Name'],
        totalConsumption: parseInt(asset['Actual Consumption (kWh)']),
        totalCarbon: parseInt(asset['Actual Carbon (kg CO2)']),
        gia: parseInt(asset['wholeBuildingFloorArea (m2)']),
        occupancy: parseInt(asset['Occupancy ( % )']),
        totalCarbonIntensity: parseInt(asset['Actual Carbon Intensity (kg CO2/m2)']),
        year: parseInt(asset['Year']),
        utilityType: asset['Utility Type'],
        country: asset['Country'],
        fund: asset['Fund'],
        sector: asset['Sector'],
        notes: asset['Notes'],
        floorAreaCoverage: parseInt(asset['Floor area coverage with actual data (m2)']),
        actualCompleteDataPercentage: parseInt(asset['Data completeness of actual data ( % )']),
        upliftedCarbon: parseInt(asset['Uplifted Carbon (kg CO2)']),
        upliftedCarbonIntensity: parseInt(asset['Uplifted Carbon Intensity (kg CO2/m2)']),
        benchmarkedCarbon: parseInt(asset['Benchmarked Carbon (kg CO2)']),
        benchmarkedCarbonIntensity: parseInt(asset['Benchmarked Carbon Intensity (kg CO2/m2)']),
        upliftedEnergyIntensity: parseInt(asset['Uplifted Energy Intensity (kWh/m2)']),
        benchmarkedEnergyIntensity: parseInt(asset['Benchmarked Energy Intensity (kWh/m2)']),
        actualEnergyIntensity: parseInt(asset['Actual Energy Intensity (kWh/m2)'])
      }
    }).slice(0, -1) // removing last object coming empty values

    const importInfo = {
      scopeId: props.scopeId,
      csvImportedArray: csvFileArray
    }
    validateNZCImportDataMutation.mutateAsync(importInfo).then(response => {
      setCsvValidationResponse(response)
      if (response.status === 204) {
        setFileUploadRequest(importInfo)
      }
    }).catch(error => {
      setErrorResponse(error)
      setFileLength(0)
    })
  }

  function handleUpload (event) {
    if(event.files[0]){
      const importFileName=event.files[0].name
      if(importFileName?.length > MODEL_FILE_NAME_MAX_ALLOWED_CHARS){
        setFileNameError(true)
        setFileName(importFileName)
      } else {
        csvFileReader.onload = function (e) {
          const csvOutput = e.target.result
          validateImportedCsv(csvOutput)
        }
        csvFileReader.readAsText(event.files[0])
        const selectedFileName = event.files.map(file => (file.name))
        setFileName(selectedFileName)
        setFileLength(event.files.length)
      }
    }
  }

  function fileUploadContainer() {
    return (
      <>
        {validateNZCImportDataMutation.status === 'loading' ? (
          <div className={'import-nzc-file import-nzc-file-loader'}>{I18n('t_loading')}...</div>
        ) : (
          <>
            {errorResponse &&
                            <span className={'error-msg invalid-error-msg'}>
                              <img src={warningIcon} alt={'warningIcon'}/><p>{I18n('t_select_valid_file')}</p>
                            </span>
            }
            <FileUpload
              data-testid='import-file-upload'
              className={'import-nzc-file import-file-upload'}
              name="importAssets"
              uploadHandler={handleUpload}
              customUpload={true}
              auto
              accept=".csv"
              chooseOptions={chooseOptions}
              emptyTemplate={<>{I18n('t_drag_file')}</>}
              multiple={false}
            />
          </>
        )}
      </>
    )
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      description : ''
    },
    validate: nzcModelNameValidator,
    onSubmit: async (data) => {
      await uploadFile(data)
    }
  })

  async function uploadFile(data) {
    const nzcUploadData=fileUploadRequest?.csvImportedArray.map(asset=>{
      return({
        propertyId: asset.assetId,
        utilityType: asset.utilityType,
        year: asset.year,
        totalConsumption: asset.totalConsumption
      })
    })
    const nzcUploadDataRequestObject={
      scopeId: fileUploadRequest.scopeId,
      uploadData: {
        scopeName: data.name,
        description: data.description,
        overrideDocumentName:fileName[0],
        importConfigurationDataList: nzcUploadData,
      }
    }
    if(props.assetLevel){
      nzcUploadDataRequestObject.assetId=props.assetId
    } else {
      nzcUploadDataRequestObject.uploadData.fundGroupId=props.fundGroupId
    }
    const fileUploadErrorMessage={title: getLocalizedValue(selectedLanguage, 't_upload_nzc_data_failed'), message: `File: ${fileName[0]}`}
    uploadNZCImportDataMutation.mutateAsync(nzcUploadDataRequestObject).then(response=>{
      if(response?.targetPlanId>0){
        props.updateScopeId(response?.targetPlanId)
        props.updateNzcDialogsState({showConfirmModal: true, uploadFileName: response?.overrideDocumentName,})
        formik.resetForm()
        props.onHideModel()
        if(props.importModelSuccessHandler){
          props.importModelSuccessHandler()
        }
      } else {
        showUploadFileErrorDialog(fileUploadErrorMessage)
      }
    }).catch((error)=>{
      if(error.response.data !== 'ModelName exists') {
        showUploadFileErrorDialog(fileUploadErrorMessage)
      }
    })
  }

  function showUploadFileErrorDialog(errorDialogMessage) {
    props.updateNzcDialogsState({
      errorDialogVisible: true,
      errorDialogData: errorDialogMessage
    })
  }

  const resetError = () => {
    uploadNZCImportDataMutation.reset()
  }

  function handleCreateModel() {
    return (
      <CommonCreateModel
        formik={formik}
        duplicateModelNameErrorFlag={uploadNZCImportDataMutation?.error?.response?.data === 'ModelName exists'}
        explanationTitle={I18n('t_import_create_explanation')}
        resetError={resetError}
      />
    )
  }

  function handleDeleteImport() {
    setCsvValidationResponse([])
    setFileLength(0)
    setErrorResponse('')
    setFileNameError(false)
  }

  function handleCopyToClipboard () {
    const copyValidationMsg = csvValidationResponse.data?.map((row) => {
      return `Row:${row.rowNumber + excelHeaderRowCount} - ${row.errorDescription}`
    })
    navigator.clipboard.writeText(copyValidationMsg).catch(error=>{
      console.log(error)
    })
    setCopied(getLocalizedValue(selectedLanguage, 't_copied'))
    setTimeout(() => {
      setCopied('')
    }, 1000)
  }

  function fetchRowNumber(rowNumber){
    if(!rowNumber || typeof rowNumber !== 'number'){
      return ''
    }
    return <>{I18n('t_row')}&nbsp;{rowNumber + excelHeaderRowCount }</>
  }

  function getAssetOrFundName() {
    return (props.assetLevel ? props.assetName : props.fundName)
  }

  function getImportNameMessageKey() {
    return (props.assetLevel ? 't_import_model_explanation_note_asset_name' : 't_import_model_explanation_note_foundName')
  }

  function fetchImportNotes (){
    return (
      <div className='model-notes-para' data-testid="import-info">
        <p className={'create-model-para'}> - {I18n('t_import_model_explanation_note_link', {link: <a href="#" target='_blank'>{I18n('t_evoranzcdata')}</a>})}</p>
        <p className={'create-model-para'}> - {I18n('t_import_model_explanation_note_description')}</p>
        <p className={'create-model-para'}> - {I18n(getImportNameMessageKey(), {fundOrAssetName: getAssetOrFundName()})}</p>
      </div>
    )
  }

  function csvValidationContainer() {
    return (
      <div className={'validation-msg'}>
        {csvValidationResponse.data?.map((row) => (
          <div className={'error-msg'} key={row.rowNumber}>
            <span className={'row-number'}>{fetchRowNumber(row.rowNumber)}</span>
            <span className={'error-description'}>{row.errorDescription}</span>
          </div>
        )) }
      </div>
    )
  }

  function importModelContainer(){
    if(csvValidationResponse.status === 204){
      return (
        <>
          <div className={'uploaded-file-container'}>
            <span className={'file-name'}><i className={'pi pi-file-excel'} />{fileName}</span>
            <span className={'success-msg'}>{I18n('t_upload_success_msg')}</span>
          </div>
          {handleCreateModel()}
        </>
      )
    } else if(csvValidationResponse.status === 200 || fileNameError) {
      const errorMessageKey = fileNameError ? 't_import_file_error_message' : 't_upload_error_msg'
      return (
        <>
          <div className={'uploaded-file-container validation-container'}>
            <div>
              <span className={'file-name'}><i className={'pi pi-file-excel'} />{fileName} <i className="trash-3-line cursor" data-testid="delete-excel" onClick={handleDeleteImport} /></span>
              <span className={'error-msg'}><img src={warningIcon} alt={'warningIcon'}/><p>{I18n(errorMessageKey)}</p></span>
            </div>
          </div>
          { !fileNameError && csvValidationContainer()}
          { !fileNameError && <div onClick={handleCopyToClipboard} className={'copy-clipboard cursor clickable-highlight'}><i className={'pi pi-copy'} />&nbsp;{I18n('t_copy_clipboard')}</div>}
          {copied}
        </>
      )
    } else {
      return (
        <>
          {fetchImportNotes()}
          {fileUploadContainer()}
        </>
      )
    }
  }

  return (
    <div className={'nzc-model-content'}>
      <div className={'nzc-create-model nzc-import-model'}>{importModelContainer()}</div>
      <div className={'border-line'} />
      <div className='padding-bottom-3'>
        <Button
          label={I18n('t_import')}
          disabled={fileLength === 0 || csvValidationResponse.status === 200 || hasModelNameValidationError(formik)}
          onClick={formik.handleSubmit}
          data-testid='submit-button'
          className="common-blue-bg-btn open-button button-highlight"
        />
      </div>
    </div>
  )
}

export default NzcImportModel