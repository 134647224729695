import CSS_VARIABLES from '../../../../../resources/css/_variables.scss'
import {
  GAP_FILLING,
  monthList,
  monthListShort,
  ScopeTypes,
  SplitTypes,
  UTILITY_LABELS_OBJECT_TITLE_CASE,
  UtilityTypes
} from '../../../../../utils/helpers/Constants'
import {trucateNumWOneDecimal} from '../../../../../services/common/formatter'
import {AssetChartToolTip} from '../../../../common/tooltip/ChartHelperPerformanceTooltip'
import {getBarSize, getChartBackgroundColor, getDivisorForTonneOrMegawatt, getLocalizedValue, smallFirstLetterInKey} from '../../../../../utils/helpers/Helper'
import I18n from '../../../../../utils/i18n/I18n'
import {ActionEventType, UtilitySummaryItemKey} from './performanceConstants'

export function getDataSet(dataSetConfig) {
  const defaultChartDataSet={
    backgroundColor: 'transparent',
    hoverBackgroundColor: 'transparent',
    data: [],
    borderRadius: 0,
    key: '',
    selectedLanguage: 'en-GB',
    datalabels:{display:false},
    stackName: ''
  }

  return {...defaultChartDataSet, ...dataSetConfig}
}

function getBarStackColor(isPdf, selectedUtilityName, stackName, dataSelectionFilterKey, defaultBgColor) {
  let bgColor
  if(isPdf){
    bgColor=CSS_VARIABLES.forest
  } else {
    bgColor=getChartBackgroundColor(stackName, dataSelectionFilterKey, defaultBgColor, selectedUtilityName)
  }
  return bgColor
}

function chartDataPointsNotEmpty(chartDataPoints) {
  return !!chartDataPoints?.some(chartDataPoint=>chartDataPoint>0)
}

export function getAssetChartDataSets(assetChartDataSetsParams) {

  const {isPdf, utilConsumptionsData, selectedLanguage, selectedUtility, isGapFillingSelected, dataSelectionFilterKey, dataSelectionIndex, dataSelectionFilters}=assetChartDataSetsParams
  if (!utilConsumptionsData) {
    return []
  }

  const divisor = getDivisorForTonneOrMegawatt(selectedUtility)
  const { barPercentage, categoryPercentage } = getBarSize(utilConsumptionsData, dataSelectionFilters)
  const filterKeyIndexes = {dataSelectionFilterKey, dataSelectionIndex}
  let bgColor
  let performanceChartDataset=[]
  const datasetsCommonConfigs = {
    stack: dataSelectionIndex+1,
    filterKeyIndexes: filterKeyIndexes,
    selectedLanguage: selectedLanguage,
    barPercentage: barPercentage,
    categoryPercentage: categoryPercentage,
    dataSelectionFilterKey: dataSelectionFilterKey
  }

  if(isGapFillingSelected || selectedUtility === UtilityTypes.Waste){
    const actualBelowTargetData=utilConsumptionsData.map(monthlyConsumptionData => monthlyConsumptionData.actualBelowTarget/divisor)
    if(chartDataPointsNotEmpty(actualBelowTargetData)){
      bgColor =getBarStackColor(isPdf, selectedUtility, 'actualBelowTarget', dataSelectionFilterKey, null)
      performanceChartDataset.push( getDataSet({
        backgroundColor: bgColor,
        hoverBackgroundColor:bgColor,
        data: actualBelowTargetData,
        key: 'actualBelowTargetData',
        stackName: 'actualBelow',
        ...datasetsCommonConfigs
      }))
    }

    const estimatedBelowTargetData=utilConsumptionsData.map(monthlyConsumptionData => monthlyConsumptionData.estimatedBelowTarget/divisor)
    if(chartDataPointsNotEmpty(estimatedBelowTargetData) && isGapFillingSelected){
      bgColor=getBarStackColor(isPdf, selectedUtility, 'estimatedBelowTarget', dataSelectionFilterKey, null)
      performanceChartDataset.push(
        getDataSet({
          backgroundColor: bgColor,
          hoverBackgroundColor: bgColor,
          data: estimatedBelowTargetData,
          key: 'actualBelowTargetData',
          stackName: 'estimatedBelow',
          ...datasetsCommonConfigs
        })
      )
    }

    const actualAboveTargetData=utilConsumptionsData.map(monthlyConsumptionData => monthlyConsumptionData.actualAboveTarget/divisor)
    if(chartDataPointsNotEmpty(actualAboveTargetData)){
      bgColor=getBarStackColor(isPdf, selectedUtility, 'actualAboveTarget', dataSelectionFilterKey, null)
      performanceChartDataset.push(
        getDataSet({
          backgroundColor: bgColor,
          hoverBackgroundColor: bgColor,
          data: actualAboveTargetData,
          key: 'actualAboveTargetData',
          stackName: 'actualAbove',
          ...datasetsCommonConfigs
        })
      )
    }

    const estimatedAboveTargetData=utilConsumptionsData.map(monthlyConsumptionData => monthlyConsumptionData.estimatedAboveTarget/divisor)
    if(chartDataPointsNotEmpty(estimatedAboveTargetData) && isGapFillingSelected){
      bgColor=getBarStackColor(isPdf, selectedUtility, 'estimatedAboveTarget', dataSelectionFilterKey, null)
      performanceChartDataset.push(
        getDataSet({
          backgroundColor: bgColor,
          hoverBackgroundColor: bgColor,
          data: estimatedAboveTargetData,
          key: 'actualAboveTargetData',
          stackName: 'estimatedAbove',
          ...datasetsCommonConfigs
        })
      )
    }
  } else {
    // gap-fill OFF
    const actualBelowTargetData=utilConsumptionsData.map(monthlyConsumptionData => (monthlyConsumptionData.current-monthlyConsumptionData.gapFilled)/divisor)
    if(chartDataPointsNotEmpty(actualBelowTargetData)){
      bgColor =getBarStackColor(isPdf, selectedUtility, 'actualBelowTarget', dataSelectionFilterKey, null)
      performanceChartDataset.push( getDataSet({
        backgroundColor: bgColor,
        hoverBackgroundColor:bgColor,
        data: actualBelowTargetData,
        key: 'actualBelowTargetData',
        stackName: 'actualBelow', // actual only
        ...datasetsCommonConfigs
      }))
    }
  }

  if(UtilityTypes.Waste === selectedUtility){
    const landfillDS=  utilConsumptionsData.map(monthlyConsumptionData => (monthlyConsumptionData.additionalAmount)) ?? []
    chartDataPointsNotEmpty(landfillDS) && performanceChartDataset.push(
      getDataSet({
        backgroundColor: CSS_VARIABLES.sand,
        hoverBackgroundColor: CSS_VARIABLES.sand,
        data: landfillDS,
        key: 'landfillData',
        selectedUtility: selectedUtility,
        stackName: 'additionalAmount',
        ...datasetsCommonConfigs
      })
    )
  }
  addDatasetForBarToTargetDifference(utilConsumptionsData, datasetsCommonConfigs, dataSelectionFilterKey, selectedUtility, performanceChartDataset, isGapFillingSelected)

  return performanceChartDataset
}

function addDatasetForBarToTargetDifference(utilConsumptionsData, datasetsCommonConfigs, dataSelectionFilterKey, selectedUtility, performanceChartDataset, isGapFillingSelected) {
  if (dataSelectionFilterKey === ScopeTypes.all) {
    const divisor = getDivisorForTonneOrMegawatt(selectedUtility)
    const targetToBarDifferences = []
    utilConsumptionsData.forEach((monthlyPerformancesData) => {
      if (selectedUtility !== UtilityTypes.Waste) {
        let stackedBarHeight =  isGapFillingSelected ?
          monthlyPerformancesData.actualBelowTarget + monthlyPerformancesData.actualAboveTarget + monthlyPerformancesData.estimatedBelowTarget + monthlyPerformancesData.estimatedAboveTarget
          :
          monthlyPerformancesData.actualBelowTarget + monthlyPerformancesData.actualAboveTarget
        stackedBarHeight=stackedBarHeight/divisor
        const targetAmount= isGapFillingSelected ? monthlyPerformancesData.targetAmount/divisor : monthlyPerformancesData.previousActual/divisor
        let barHeightToTargetDifference = targetAmount - stackedBarHeight // a gap b/w stackedBarHeight and target-line
        targetToBarDifferences.push(barHeightToTargetDifference > 0 ? barHeightToTargetDifference : 0)
      }
    })
    // This dataset is for showing the tooltip in the space between the stacked-bar and target line
    performanceChartDataset.push(
      getDataSet({
        label: 'Space between target line and stacked-bar',
        data: targetToBarDifferences,
        borderRadius: 0,
        key: 'spaceBetweenTargetAndStackedBar',
        ...datasetsCommonConfigs
      }))
  }
}

export function getDataFieldValue(monthlyConsumptionData, field) {
  let consumptionParamValue=monthlyConsumptionData?.[field]
  if(!consumptionParamValue){
    consumptionParamValue=0
  }
  return consumptionParamValue
}

export //update Datasets with API data
function getTargetObject(isPdf, utilConsumptionsData, selectedUtilityName, timePeriod, dataSelectionKey, isGapFillingSelected){
  return utilConsumptionsData?.map((utilConsumption, index) => {
    let tgtDifference = parseInt(utilConsumption['targetDifference'] * 100)

    if(!isGapFillingSelected){
      const actual = utilConsumption['actualAboveTarget'] + utilConsumption['actualBelowTarget']
      const variancePercentage = (actual - utilConsumption.previousActual) / utilConsumption.previousActual
      tgtDifference = parseInt(variancePercentage * 100)
    }

    let barPercentageColor=CSS_VARIABLES.forest
    const isSelectedUtilityWaste=selectedUtilityName === UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE
    let percentageLabel=''
    let yCoordinate=0
    const divisor = getDivisorForTonneOrMegawatt(selectedUtilityName)

    if(tgtDifference < -5){
      if (isSelectedUtilityWaste) {
        barPercentageColor =CSS_VARIABLES.red
      } else {
        barPercentageColor =CSS_VARIABLES.green
      }
    } else if(tgtDifference > 5){
      if (isSelectedUtilityWaste) {
        barPercentageColor =CSS_VARIABLES.green
      } else {
        barPercentageColor=CSS_VARIABLES.red
      }
    }
    if(utilConsumption.targetDifference !== null){
      percentageLabel=trucateNumWOneDecimal(utilConsumption.targetDifference * 100)
    }
    yCoordinate= isGapFillingSelected ? utilConsumption.targetAmount : utilConsumption.previousActual
    yCoordinate = yCoordinate > 0 ? yCoordinate / divisor : 0

    return {
      dataIndex: index,
      yCoordinate,
      color: barPercentageColor,
      percentageLabel,
      utilityName: selectedUtilityName,
      timePeriod: timePeriod,
      actualBelowTarget: getDataFieldValue(utilConsumption, 'actualBelowTarget'),
      actualAboveTarget: getDataFieldValue(utilConsumption, 'actualAboveTarget'),
      estimatedUptoTarget: getDataFieldValue(utilConsumption, 'estimatedBelowTarget'),
      estimatedOverTarget: getDataFieldValue(utilConsumption, 'estimatedAboveTarget'),
      additionalAmount: getDataFieldValue(utilConsumption, 'additionalAmount'),
      missingData: utilConsumption['missingAmount'],
      missingPercentage : utilConsumption['missingPercentage'],
      gapFilled: utilConsumption['gapFilled'],
      variance: utilConsumption['variance'],
      targetDifference: utilConsumption['targetDifference'] / 100,
      current: utilConsumption['current'],
      currentEst: utilConsumption['currentEst'],
      previous: utilConsumption['previous'],
      previousActual: utilConsumption['previousActual'],
      target: utilConsumption['targetAmount'],
      totalEstimated: utilConsumption['totalAmount'],
      targetPercentage: utilConsumption['targetPercentage'] / 100,
      currentPercentage: utilConsumption['currentPercentage'],
      year: utilConsumption['year'],
      monthNumber: utilConsumption['monthNumber'],
      events:utilConsumption['events'],
      filledDataPercentage: utilConsumption['filledDataPercentage'],
      isPdf:isPdf,
      dataSelectionKey
    }
  })
}

export function getYScaleTicksValue(utilConsumptionsDataForSelectedKeys, selectedUtility, isGapFillingSelected) {
  //update Y-axis with API data
  const yScaleTicksValue={min: 0, max: 0, stepSize: 0}
  const maxOfTotalAmounts=[]
  let totalAmounts
  const divisor = getDivisorForTonneOrMegawatt(selectedUtility)

  if(utilConsumptionsDataForSelectedKeys){
    let stepValueForYAxis
    utilConsumptionsDataForSelectedKeys.forEach(utilConsumptionsData=>{
      let maxOfConsumptionsData=utilConsumptionsData?.map(monthlyConsumptionData => {
        let amount
        let target
        if(isGapFillingSelected){
          amount = monthlyConsumptionData.totalAmount
          target = monthlyConsumptionData.targetAmount
        } else {
          amount = monthlyConsumptionData.actualAboveTarget + monthlyConsumptionData.actualBelowTarget
          target = monthlyConsumptionData.previous
        }
        return Math.max(amount + monthlyConsumptionData.additionalAmount, target) / divisor
      })
      maxOfTotalAmounts.push(...maxOfConsumptionsData)
    })
    totalAmounts = maxOfTotalAmounts?.length > 0 ? maxOfTotalAmounts : [0]
    let maxValue = Math.max(...totalAmounts)
    // To handle special case where api response for max value is 0 ,and in range from 0.51 to 1
    if ((maxValue > 0.5 && maxValue <= 1) || maxValue === 0) {
      maxValue = 1
    } else if (maxValue <= 1) {
      maxValue = 0.5
    }
    stepValueForYAxis = maxValue / 10
    if (stepValueForYAxis >= 0.05 && stepValueForYAxis < 0.1) {
      stepValueForYAxis = 0.1
    } else if (stepValueForYAxis < 0.1) {
      stepValueForYAxis = 0.05
    }
    if (stepValueForYAxis >= 1) { // Handle scenario with max value 0 and in range from 0.51 to 1
      stepValueForYAxis = Math.trunc(stepValueForYAxis)
      maxValue = Math.trunc(maxValue)
    }
    yScaleTicksValue.max=maxValue + (3 * stepValueForYAxis)
    yScaleTicksValue.stepSize=stepValueForYAxis
  }
  return yScaleTicksValue
}

export function getMonthlyPerformancesForChartEvents(monthlyConsumptionPerformancesData, selectedUtilityName) {
  //update chart timeline events
  const utilityName=selectedUtilityName.toLowerCase()
  const getUtilityType = event => event.content.find(x => x.key === 'UtilityType')
  const isDataQualityEvent=(event)=>(event?.eventType === ActionEventType.DATAQUALITY_ISSUE && event.content.length > 0 && utilityName.includes(event.content.find(eventContent =>eventContent.key === 'MeterType')?.value?.toLowerCase()))
  const isPerformanceVarianceEvent = (event) => (event?.eventType === ActionEventType.PERFORMANCE_VARIANCE && event.content.length > 0 && getUtilityType(event) && utilityName.includes(getUtilityType(event)?.value.toLowerCase()))
  const isCommercialOrCompletedEvent=(event)=>(event?.eventType === ActionEventType.COMMERCIAL_EVENT || event.eventType === ActionEventType.COMPLETED_ACTION)
  return monthlyConsumptionPerformancesData?.map((monthlyPerformance) => {
    let eventsList = []
    monthlyPerformance?.events?.forEach((event) => {
      let monthKey = new Date(event.impactDate).getMonth()+1
      let eventYear = new Date(event.impactDate).getFullYear()
      if (((monthList[monthKey] === monthlyPerformance.month) || (monthListShort[monthKey] === monthlyPerformance.month)) && (eventYear === monthlyPerformance.year)) {
        if(isDataQualityEvent(event) || isPerformanceVarianceEvent(event) || isCommercialOrCompletedEvent(event)) {
          eventsList.push(event)
        }
      }
    })
    return {
      events: eventsList,
      month: monthlyPerformance.month,
      year: monthlyPerformance.year,
      isTwoYearsData: monthlyConsumptionPerformancesData.length === 24,
      variancePercentage: trucateNumWOneDecimal(monthlyPerformance['targetDifference'] * 100)
    }
  })
}

export function getPerformanceDataForSelectedUtility(assetPerformanceData, selectedUtilityName) {
  return assetPerformanceData?.find(performanceData => performanceData['utilitySummary'] === selectedUtilityName)
}

export function getUtilityConsumptionData(utilConsumptionsData, timePeriodInMonths) {
  let utilConsumptionData
  if (utilConsumptionsData?.length>0) {
    utilConsumptionData = utilConsumptionsData.map((monthlyConsumptionData) => ({
      month: monthList[monthlyConsumptionData['month']],
      monthNumber: monthlyConsumptionData['month'],
      year: monthlyConsumptionData['year'],
      actualAboveTarget: getDataFieldValue(monthlyConsumptionData, 'actualAboveTarget'),
      actualBelowTarget: getDataFieldValue(monthlyConsumptionData, 'actualBelowTarget'),
      estimatedBelowTarget: getDataFieldValue(monthlyConsumptionData, 'estimatedBelowTarget'),
      estimatedAboveTarget: getDataFieldValue(monthlyConsumptionData, 'estimatedAboveTarget'),
      targetAmount: getDataFieldValue(monthlyConsumptionData, 'targetAmount'),
      targetDifference: getDataFieldValue(monthlyConsumptionData, 'targetAmountPercentage'),
      additionalAmount: monthlyConsumptionData['additionalAmount'],
      totalAmount: monthlyConsumptionData['actualBelowTarget'] + monthlyConsumptionData['actualAboveTarget'] +  monthlyConsumptionData['estimatedBelowTarget'] + monthlyConsumptionData['estimatedAboveTarget'],
      missingAmount: (monthlyConsumptionData['amountOverTarget'] <= 0 && monthlyConsumptionData['missing'] !== null) ? monthlyConsumptionData['missing'] : 0,
      missingPercentage: monthlyConsumptionData['missingPercentage'],
      gapFilled: monthlyConsumptionData['filledData'],
      variance: monthlyConsumptionData['varianceAbsolute'],
      current: monthlyConsumptionData['currentAbsolute'],
      currentEst: monthlyConsumptionData['currentEstimated'],
      previousActual: monthlyConsumptionData['previousActual'],
      previous: monthlyConsumptionData['previousAbsolute'],
      targetPercentage: monthlyConsumptionData['targetPercentage'],
      currentPercentage: monthlyConsumptionData['currentPercentage'],
      events: monthlyConsumptionData['events'],
      filledDataPercentage: monthlyConsumptionData['filledDataPercentage'],
    }))
    utilConsumptionData = utilConsumptionData.slice(Math.max(utilConsumptionData.length - timePeriodInMonths))
  }
  return utilConsumptionData
}

export const createMonthLabelsForXAxis = (forMonths, consumptionData, selectedLanguage) => {
  let monthsSet = Array(forMonths)
  for (let i = 0; i < forMonths; i++) {
    if (forMonths < 6) {
      let month = smallFirstLetterInKey(consumptionData[i].month)
      monthsSet[i] = getLocalizedValue(selectedLanguage, month)
    } else {
      let month = smallFirstLetterInKey(consumptionData[i].month.substr(0, 3))
      monthsSet[i] = getLocalizedValue(selectedLanguage, month)
    }
  }
  return monthsSet
}

export function getTooltipConfig(selectedLanguage){
  return {
    enabled: false,
    position: 'average',
    mode: 'point',
    language: selectedLanguage,
    external: AssetChartToolTip
  }
}

function getSummaryFieldValueOrDefault(fieldValue) {
  return fieldValue ?? 0
}

const getTotalUtilityLabel = (selectedUtilityName, selectedLanguage) => {
  let utilitySummaryLabel
  switch(selectedUtilityName) {
  case UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON:
    utilitySummaryLabel = getLocalizedValue(selectedLanguage, 't_total_carb_emissions')
    break
  case UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY:
    utilitySummaryLabel = getLocalizedValue(selectedLanguage, 't_total_elec_used')
    break
  case UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL:
    utilitySummaryLabel = getLocalizedValue(selectedLanguage, 't_total_gas_fuels_thermals')
    break
  case UTILITY_LABELS_OBJECT_TITLE_CASE.WATER:
    utilitySummaryLabel = getLocalizedValue(selectedLanguage, 't_total_water_used')
    break
  case UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE:
    utilitySummaryLabel = getLocalizedValue(selectedLanguage, 't_total_waste').toLowerCase()
    break
  case 'RecycledWaste':
    utilitySummaryLabel = getLocalizedValue(selectedLanguage, 't_total_recycled_waste')
    break
  default:
    utilitySummaryLabel = ''
  }
  return utilitySummaryLabel
}

function getEstimateLabel(variancePercentage){
  return (variancePercentage < 0 ? I18n('t_est_savings') : I18n('t_est_overspend'))
}

function getTargetLabel(key, variancePercentage, selectedUtilityName) {
  let targetLabel
  if (key === UtilitySummaryItemKey.usageCost) {
    targetLabel = getEstimateLabel(variancePercentage)
  } else if (selectedUtilityName === UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE) {
    if(variancePercentage < 0){
      targetLabel = 't_under_recycling_target'
    } else {
      targetLabel = 't_over_recycling_target'
    }
  } else if (variancePercentage < 0) {
    targetLabel = I18n('t_below_target')
  } else {
    targetLabel = I18n('t_above_target')
  }
  return targetLabel
}

function getNonTargetLabel(key, variancePercentage, selectedUtilityName, isAssetGapFillingFlagEnabled) {
  let nonTargetLabel
  if (key === UtilitySummaryItemKey.usageCost) {
    nonTargetLabel = getEstimateLabel(variancePercentage)
  } else if (isAssetGapFillingFlagEnabled && selectedUtilityName === UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE) {
    if(variancePercentage < 0 ){
      nonTargetLabel = 't_less_recycled_than_previous_year'
    } else {
      nonTargetLabel = 't_more_recycled_than_previous_year'
    }
  } else if (variancePercentage < 0) {
    nonTargetLabel = 't_lower_than_previous_period'
  } else {
    nonTargetLabel = 't_higher_than_previous_period'
  }
  return nonTargetLabel
}

function getSummaryCaptionText(variancePercentage, key, selectedUtilityName, showTarget, isGapFillingSelected, hasTarget = false) {
  let captionText
  if (variancePercentage === null && key === UtilitySummaryItemKey.usagePercentage) {
    captionText = 't_no_data_for_comparison'
    return captionText
  }
  if ((showTarget || hasTarget) && isGapFillingSelected) { // If Target available
    captionText= getTargetLabel(key, variancePercentage, selectedUtilityName)
  } else { // If Target Not-available
    captionText=getNonTargetLabel(key, variancePercentage, selectedUtilityName, isGapFillingSelected)
  }
  return captionText
}

function getTargetPercentage(utilityPerformanceSummary, isGapFillingSelected) {
  let targetPercentage = utilityPerformanceSummary?.['targetDeviationPercentage']
  if (!isGapFillingSelected && utilityPerformanceSummary && targetPercentage) { // gap-fill OFF
    const {actual, previousActual} = utilityPerformanceSummary
    targetPercentage = previousActual === 0 ? 0 : (actual - previousActual) / previousActual
    targetPercentage = targetPercentage * 100
  }
  return targetPercentage
}

export function getUtilityPerformanceSummaryData(assetUtilConsumptions, isGapFillingSelected, selectedUtilityName, selectedLanguage, showTarget) {
  if(!assetUtilConsumptions){
    return null
  }
  const utilityPerformanceSummary = assetUtilConsumptions.summary
  let currentTotalConsumption
  if(selectedUtilityName === UtilityTypes.Waste){
    currentTotalConsumption=utilityPerformanceSummary['currentTotalConsumption']
  } else {
    currentTotalConsumption = isGapFillingSelected ? utilityPerformanceSummary['currentTotalConsumption'] : utilityPerformanceSummary['consumption']
  }
  let periodDifferencePercentage = utilityPerformanceSummary['periodDifferencePercentage'] === null ? null : (utilityPerformanceSummary['periodDifferencePercentage'] * 100)
  let gapFilledPercentage = ((utilityPerformanceSummary['estimated'] / currentTotalConsumption) * 100).toFixed(0)
  const targetDeviationPercentage=getTargetPercentage(utilityPerformanceSummary, isGapFillingSelected)
  
  // utilityPerformanceSummaryData for the chart header section
  let utilityPerformanceSummaryData = [{
    value: getSummaryFieldValueOrDefault(currentTotalConsumption), //+ GetSuffix(performanceState.selectedUtility.name, currentTotalConsumption),
    title: getTotalUtilityLabel(selectedUtilityName, selectedLanguage),
    key: UtilitySummaryItemKey.currentTotalConsumption
  }]

  if(selectedUtilityName === UtilityTypes.Waste) {
    utilityPerformanceSummaryData.push({
      value: Math.abs(utilityPerformanceSummary['currentRecycledWaste']),
      title: I18n('t_recycled_waste'),
      key: UtilitySummaryItemKey.currentTotalWaste
    })
    let currentPercentageRecycled = (utilityPerformanceSummary['currentPercentageRecycled'] * 100)
    utilityPerformanceSummaryData.push({
      value: getSummaryFieldValueOrDefault(currentPercentageRecycled),
      title: I18n('t_recycled'),
      key: UtilitySummaryItemKey.percentageRecycled
    })
    utilityPerformanceSummaryData.push({
      value: getSummaryFieldValueOrDefault(periodDifferencePercentage),
      title: getSummaryCaptionText(periodDifferencePercentage, 'usagePercentage', selectedUtilityName, showTarget, isGapFillingSelected, false),
      key: UtilitySummaryItemKey.usagePercentage,
      showIcon: (periodDifferencePercentage !== 0)
    })
  } else {
    if (isGapFillingSelected) {
      utilityPerformanceSummaryData.push({
        value: gapFilledPercentage == null || !isFinite(gapFilledPercentage) || isNaN(gapFilledPercentage) ? '0%' : `${gapFilledPercentage}%`,
        title: I18n('t_gap_filled_data'),
        key: UtilitySummaryItemKey.gapFilledPercentage
      })
    }
    utilityPerformanceSummaryData.push({
      value: getSummaryFieldValueOrDefault(targetDeviationPercentage),
      title: getSummaryCaptionText(targetDeviationPercentage, UtilitySummaryItemKey.usagePercentage, selectedUtilityName, showTarget, isGapFillingSelected, utilityPerformanceSummary['hasTarget']),
      key: UtilitySummaryItemKey.usagePercentage,
      showIcon: true
    })
    utilityPerformanceSummaryData.push({
      value: getSummaryFieldValueOrDefault(Math.trunc(utilityPerformanceSummary['periodDifferenceCost'])),
      title: getSummaryCaptionText(periodDifferencePercentage, UtilitySummaryItemKey.usageCost, selectedUtilityName, showTarget, isGapFillingSelected, utilityPerformanceSummary['hasTarget']),
      key: UtilitySummaryItemKey.usageCost
    })
  }
  utilityPerformanceSummaryData = ((selectedUtilityName === UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON)) ? utilityPerformanceSummaryData.slice(0, -1) : utilityPerformanceSummaryData
  return utilityPerformanceSummaryData
}

export function getMultiSelectDropdownOptions(selectedUtility, selectedLanguage) {
  if (selectedUtility === UtilityTypes.Carbon) {
    let options = [
      {name: getLocalizedValue(selectedLanguage, 't_scope1_landlord'), key: ScopeTypes.scope1},
      {name: getLocalizedValue(selectedLanguage, 't_scope2_landlord'), key: ScopeTypes.scope2},
      {name: getLocalizedValue(selectedLanguage, 't_scope3_tenant'), key: ScopeTypes.scope3},
      {name: getLocalizedValue(selectedLanguage, 't_gap_filling'), key: GAP_FILLING}
    ]

    return {
      showMultiDropdown: true,
      placeHolderText: 't_choose_breakdown',
      showPanelHeader: false,
      className: 'asset_breakdown_dropdown',
      panelClassName: 'breakdown_dropdown_panel',
      options: options
    }
  } else {
    let options = [
      {name: getLocalizedValue(selectedLanguage, 't_landlord'), key: SplitTypes.landlord},
      {name: getLocalizedValue(selectedLanguage, 't_tenant'), key: SplitTypes.tenant},
      {name: getLocalizedValue(selectedLanguage, 't_gap_filling'), key: GAP_FILLING}
    ]

    return {
      showMultiDropdown: selectedUtility !== UtilityTypes.Waste,
      placeHolderText: 't_choose_breakdown',
      showPanelHeader: false,
      className: 'asset_breakdown_dropdown',
      panelClassName: 'breakdown_dropdown_panel',
      options: options
    }
  }
}


export function getChartDataForSelectedKeys(assetUtilConsumptionsData, selectedUtility, dataSelectionFilters, isPdf, selectedLanguage, isGapFillingSelected, timePeriod) {
  const datasetsMain=[]
  const targetLineDatasets=[]
  let utilConsumptionsDataForSelectedKeys=[]
  let consumptionsDataForSelectedKey
  let utilConsumptionsData
  let performanceChartDataset
  let assetChartDataSetsParams
  const utilityConsumptionsAll=[]
  const barStacksCount={}

  if(dataSelectionFilters?.length > 0){ // There are user selection
    utilityConsumptionsAll.push(...getUtilityConsumptionData(assetUtilConsumptionsData?.consumptions?.[ScopeTypes.all], assetUtilConsumptionsData?.consumptions?.[ScopeTypes.all]?.length))
    dataSelectionFilters.forEach((dataSelectionFilter, dataSelectionIndex) => {
      consumptionsDataForSelectedKey=assetUtilConsumptionsData?.consumptions?.[dataSelectionFilter.key]
      utilConsumptionsData=getUtilityConsumptionData(consumptionsDataForSelectedKey, consumptionsDataForSelectedKey?.length)
      utilConsumptionsDataForSelectedKeys.push(utilConsumptionsData)
      assetChartDataSetsParams={isPdf, utilConsumptionsData, selectedLanguage, selectedUtility, isGapFillingSelected, dataSelectionFilterKey: dataSelectionFilter.key, dataSelectionIndex, dataSelectionFilters}
      performanceChartDataset=getAssetChartDataSets(assetChartDataSetsParams)
      barStacksCount[dataSelectionFilter.key]=performanceChartDataset.length
      datasetsMain.push(...performanceChartDataset)
      targetLineDatasets.push(getTargetObject(false, utilConsumptionsData, selectedUtility, timePeriod, dataSelectionFilter.key, isGapFillingSelected))
    })
  } else {
    consumptionsDataForSelectedKey=assetUtilConsumptionsData?.consumptions?.[ScopeTypes.all]
    utilConsumptionsData=getUtilityConsumptionData(consumptionsDataForSelectedKey, consumptionsDataForSelectedKey?.length)
    utilConsumptionsDataForSelectedKeys.push(utilConsumptionsData)
    assetChartDataSetsParams={isPdf, utilConsumptionsData, selectedLanguage, selectedUtility, isGapFillingSelected, dataSelectionFilterKey: ScopeTypes.all, dataSelectionIndex: 0, dataSelectionFilters}
    performanceChartDataset=getAssetChartDataSets(assetChartDataSetsParams)
    datasetsMain.push(...performanceChartDataset)
    targetLineDatasets.push(getTargetObject(false, utilConsumptionsData, selectedUtility, timePeriod, ScopeTypes.all, isGapFillingSelected))
  }

  return {datasetsMain, targetLineDatasets, utilConsumptionsDataForSelectedKeys, utilityConsumptionsAll, barStacksCount}
}
