import React from 'react'
import {useQuery} from 'react-query'
import {getFundGroupData} from '../../../services/organisation-service'
import {noRefetchOnWindowFocus} from '../../../services/common/useQuery-config'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

/* eslint-disable react/display-name */
const withFundGroupList = WrappedComponent => {
  return () => {
    const { loginState: { userInfo } } = useLoginAuthContext()
    const {data: fundGroups, isLoading} = useQuery(['fundGroupData', userInfo.unitSystem, userInfo.currencyUnit], getFundGroupData, noRefetchOnWindowFocus)

    return (
      <>
        <WrappedComponent fundGroups={fundGroups} loading={isLoading}/>
      </>
    )
  }
}

export default withFundGroupList

