import { excelHelpers, getLocalizedValue } from '../../../helpers/Helper'
import {
  getEmptyRows,
  formatDateToDDMMYY,
  mappedExcelItem
} from '../../ExportHelper'
import XlsxPopulate from 'xlsx-populate'
import FileSaver from 'file-saver'

export async function AssetHomeActionPlanExcelHelper(props, assetActionPlanExportData,selectedLanguage,getFilterSelectionValue) {
  function excelTitle() {
    return {A: getLocalizedValue(selectedLanguage, 't_fund_action_plan_report')}
  }

  function getSummaryTableTitle(summaryName) {
    const summaryType = {
      agreedAction: getLocalizedValue(selectedLanguage, 't_total_agreed_actions'),
      overdueAction: getLocalizedValue(selectedLanguage, 't_total_overdue_action'),
      completedAction: getLocalizedValue(selectedLanguage, 't_total_completed_action'),
      applicableAction: getLocalizedValue(selectedLanguage, 't_total_applicable_action'),
      actionUnderReview: getLocalizedValue(selectedLanguage, 't_total_action_under_review'),
    }
    return {
      A: summaryType[summaryName],
      B: getLocalizedValue(selectedLanguage, 't_total_costs'),
      C: getLocalizedValue(selectedLanguage, 't_non_recoverable'),
      D: getLocalizedValue(selectedLanguage, 't_un_costed_actions'),
    }
  }

  function getSummaryTableData(data) {
    return {
      A: data.totalActionsCount,
      B: data.totalCost,
      C: data.nonRecoverable,
      D: data.unCosted,
    }
  }

  function actionTitle( isCompletedActionTab = false) {
    const actionPlanTitleSet1 = {
      A: getLocalizedValue(selectedLanguage, 't_asset_ref'),
      B: getLocalizedValue(selectedLanguage, 't_asset_name'),
      C: getLocalizedValue(selectedLanguage, 't_fund'),
      D: getLocalizedValue(selectedLanguage, 't_action'),
      E: getLocalizedValue(selectedLanguage, 't_due_date'),
    }
    const actionPlanTitleGeneralTabSet ={
      F: getLocalizedValue(selectedLanguage, 't_impact'),
      G: getLocalizedValue(selectedLanguage, 't_utility_type'),
      H: getLocalizedValue(selectedLanguage, 't_action_estimated_savings'),
      I: getLocalizedValue(selectedLanguage, 't_unit_of_measurement'),
      J: getLocalizedValue(selectedLanguage, 't_cost'),
      K: getLocalizedValue(selectedLanguage, 't_currency'),
      L: getLocalizedValue(selectedLanguage, 't_cost_type'),
      M: getLocalizedValue(selectedLanguage, 't_procured_by'),
      N: getLocalizedValue(selectedLanguage, 't_action_area_covered'),
      O: getLocalizedValue(selectedLanguage, 't_scope'),
      P: getLocalizedValue(selectedLanguage, 't_manager'),
      Q: getLocalizedValue(selectedLanguage, 't_notes'), 
    }
    const actionPlanTitleAcompletedTabSet ={
      F: getLocalizedValue(selectedLanguage, 't_date_completed'),
      G: getLocalizedValue(selectedLanguage, 't_impact'),
      H: getLocalizedValue(selectedLanguage, 't_utility_type'),
      I: getLocalizedValue(selectedLanguage, 't_action_estimated_savings'),
      J: getLocalizedValue(selectedLanguage, 't_unit_of_measurement'),
      K: getLocalizedValue(selectedLanguage, 't_cost'),
      L: getLocalizedValue(selectedLanguage, 't_currency'),
      M: getLocalizedValue(selectedLanguage, 't_cost_type'),
      N: getLocalizedValue(selectedLanguage, 't_procured_by'),
      O: getLocalizedValue(selectedLanguage, 't_action_area_covered'),
      P: getLocalizedValue(selectedLanguage, 't_scope'),
      Q: getLocalizedValue(selectedLanguage, 't_manager'),
      R: getLocalizedValue(selectedLanguage, 't_notes'), 
    }
    return {...actionPlanTitleSet1,
      ...(isCompletedActionTab? actionPlanTitleAcompletedTabSet : actionPlanTitleGeneralTabSet)}
  }

  function actionData(data, isCompletedActionTab = false) {
    if (!Array.isArray(data) || Array.isArray(data) && !data.length) {
      return [{
        D: getLocalizedValue(selectedLanguage, 't_no_assets_selected_actions_status')
      }]
    }
    const actionDataSet = data.map(val => ({
      A: val.assetRef,
      B: val.assetName,
      C: val.fundName,
      D: val.actionDescription,
      E: val.dueDate,
    }))
    const actionDataGeneralTabSet = data.map(val => ({
      F: val.impact,
      G: val.utilityType,
      H: val.estimatedSavings,
      I: val.unitOfMeasurement,
      J: val.cost,
      K: val.currency,
      L: val.costType,
      M: val.procuredBy,
      N: val.areaCovered,
      O: val.scope,
      P: val.manager,
      Q: val.notes
    }))
    const actionDataCompletedTabSet = data.map(val => ({
      F: val.dateCompleted,
      G: val.impact,
      H: val.utilityType,
      I: val.estimatedSavings,
      J: val.unitOfMeasurement,
      K: val.cost,
      L: val.currency,
      M: val.costType,
      N: val.procuredBy,
      O: val.areaCovered,
      P: val.scope,
      Q: val.manager,
      R: val.notes
    }))
    const combinedArray = actionDataSet.map((item, index) => (
      isCompletedActionTab ? { ...item, ...actionDataCompletedTabSet[index] } : { ...item, ...actionDataGeneralTabSet[index] }
    ))
    return combinedArray
  }

  function getNotes(notes) {
    if (Array.isArray(notes) && notes.length) {
      const result = notes.sort((item1, item2) => (item1.noteId < item2.noteId) ? 1 : -1)
      return result[0].content
    }
    return ' - '
  }

  function mappedActionData(data) {
    const summaryData = data?.actionPlanSummary || null
    const actionData = data?.actionPlanDetails || null
    if (Array.isArray(actionData)) {
      return {
        summaryData: {
          nonRecoverable: mappedExcelItem(summaryData?.nonRecoverable, selectedLanguage),
          totalActionsCount: mappedExcelItem(summaryData?.totalActionsCount, selectedLanguage),
          totalCost: mappedExcelItem(summaryData?.totalCost, selectedLanguage),
          unCosted: mappedExcelItem(summaryData?.unCosted, selectedLanguage),
        },
        actionPlanDetails: actionData.map(item => {
          const {action, actionNotes} = item
          if (typeof action === 'object') {
            return {
              assetRef: mappedExcelItem(action.assetRef, selectedLanguage),
              assetName: mappedExcelItem(action.assetName, selectedLanguage),
              fundName: mappedExcelItem(action.fundName, selectedLanguage),
              actionDescription: mappedExcelItem(action.actionDescription, selectedLanguage),
              dueDate: formatDateToDDMMYY(action.dueDate, selectedLanguage),
              dateCompleted: formatDateToDDMMYY(action.completion, selectedLanguage),
              impact: mappedExcelItem(action.impact, selectedLanguage),
              utilityType: mappedExcelItem(action.utilityType, selectedLanguage),
              estimatedSavings: mappedExcelItem(action.target, selectedLanguage),
              unitOfMeasurement: mappedExcelItem(action.unitOfMeasurement, selectedLanguage),
              cost: mappedExcelItem(action.cost, selectedLanguage),
              currency: mappedExcelItem(action.currency, selectedLanguage),
              costType: mappedExcelItem(action.costType, selectedLanguage),
              procuredBy: mappedExcelItem(action.procuredBy, selectedLanguage),
              areaCovered: mappedExcelItem(action.areaCovered, selectedLanguage),
              scope: mappedExcelItem(action.scope, selectedLanguage),
              manager: mappedExcelItem(action.manager, selectedLanguage),
              notes: mappedExcelItem(getNotes(actionNotes), selectedLanguage),
            }
          }
          return null
        }).filter(v => !!v)
      }
    }
    return {
      summaryData: {
        nonRecoverable: ' - ',
        totalActionsCount: ' - ',
        totalCost: ' - ',
        unCosted: ' - '
      },
      actionPlanDetails: [],
    }
  }

  function getFilterSelections(){
    return[
      {A: getFilterSelectionValue[0][0]},
      {A: getFilterSelectionValue[1][0]},
      {A: getFilterSelectionValue[2][0]},
      {A: getFilterSelectionValue[3][0]}
    ]
  }

  function mapAssetActionPlanData(assetData, summaryTitle, actionKey, actionTitleHeading, isCompletedActionTab = false) {
    const {summaryData, actionPlanDetails} = mappedActionData(assetData)
    const actionPlanExcelData = []
    actionPlanExcelData.push(excelTitle())
    actionPlanExcelData.push(...getFilterSelections())
    actionPlanExcelData.push(...getEmptyRows(2))
    actionPlanExcelData.push({A: getLocalizedValue(selectedLanguage, summaryTitle)})
    const summaryExcelTitle = getSummaryTableTitle(actionKey)
    actionPlanExcelData.push(summaryExcelTitle)
    const summaryTableData = getSummaryTableData(summaryData)
    actionPlanExcelData.push(summaryTableData)
    actionPlanExcelData.push(...getEmptyRows(2))
    actionPlanExcelData.push({A: getLocalizedValue(selectedLanguage, actionTitleHeading)})
    const actionTitleItem = actionTitle(isCompletedActionTab)
    actionPlanExcelData.push(actionTitleItem)
    const actionDataItem = actionData(actionPlanDetails, isCompletedActionTab)
    actionPlanExcelData.push(...actionDataItem)
    return {data: actionPlanExcelData, actionDataItem: actionPlanDetails.length}
  }

  // execution starts here ...
  if (assetActionPlanExportData?.data) {
    const blob = await XlsxPopulate.fromBlankAsync().then(workbook => {
      const {
        agreedActions,
        overdueActions,
        completedActions,
        applicableActions,
        underReviewActions
      } = assetActionPlanExportData.data

      const agreedData = { ...mapAssetActionPlanData(agreedActions, 't_summary_of_agreed_action', 'agreedAction', 't_asset_agree_actions'), name: getLocalizedValue(selectedLanguage, 't_agreed')}
      const overdueData = { ...mapAssetActionPlanData(overdueActions, 't_summary_of_overdue_action', 'overdueAction', 't_overdue_actions'), name: getLocalizedValue(selectedLanguage, 't_overdue')}
      const completedData = { ...mapAssetActionPlanData(completedActions, 't_summary_of_completed_actions', 'completedAction', 't_completed_actions', true), name: getLocalizedValue(selectedLanguage, 't_completed') }
      const applicableData = { ...mapAssetActionPlanData(applicableActions, 't_summary_of_applicable_actions', 'applicableAction', 't_applicable_actions'),  name: getLocalizedValue(selectedLanguage, 't_applicable')}
      const underReviewData = { ...mapAssetActionPlanData(underReviewActions, 't_summary_of_actions_under_review', 'actionUnderReview', 't_actions_under_review'), name: getLocalizedValue(selectedLanguage, 't_under_review') }
      const actionPlanDataArray = [agreedData, overdueData, completedData, applicableData, underReviewData]
      actionPlanDataArray.forEach((sheet, index) => {
        const excelSheet = index === 0 ? workbook.sheet(0).name(sheet.name) : workbook.addSheet(sheet.name)
        const { data } = sheet
        const error = 'No Error'
        excelHelpers(data, excelSheet, error)
      })
      workbook.activeSheet(getLocalizedValue(selectedLanguage, 't_agreed'))
      return workbook.outputAsync(workbook)
    })
    FileSaver.saveAs(blob, getLocalizedValue(selectedLanguage, 't_action_export_from_portfolio_view')+'.xlsx')
  }
}