import axiosInstance from '../common/axios-config'
import {
  BOTH,
  LANDLORD, TENANT
} from '../../utils/helpers/Constants'
import moment from 'moment'

function isTenant(procureBy) {
  return procureBy.value.toString().includes(TENANT) ? TENANT : BOTH
}

function getProcuredBy(procureBy) {
  return procureBy.value.toString().includes(LANDLORD) ? LANDLORD : isTenant(procureBy)
}

function convertDateToFormat(value){
  return moment(value, 'MMM YY').format('YYYY-MM-DD')
}

export const BulkConsumptionForAssetDashboard = async (queryParam) => {
  const {data} = await axiosInstance.get(`/api/v1/BulkUpload/Consumption/${queryParam[1]}/AssetBulkUploadData?fromDate=${convertDateToFormat(queryParam[2].timePeriodFrom)}&toDate=${convertDateToFormat(queryParam[2].timePeriodTo)}&includeInActiveMeters=${queryParam[2].inactiveMeters}&procuredBy=${getProcuredBy(queryParam[2].metersIncluded)}`)
  return data
}

export const BulkConsumptionForOwnedGroup = async (queryParam) => {
  const {data} = await axiosInstance.get(`/api/v1/BulkUpload/Consumption/${queryParam[1]}/AssetGroupBulkUploadData?fromDate=${convertDateToFormat(queryParam[2].timePeriodFrom)}&toDate=${convertDateToFormat(queryParam[2].timePeriodTo)}&includeInActiveMeters=${queryParam[2].inactiveMeters}&procuredBy=${getProcuredBy(queryParam[2].metersIncluded)}`)
  return data
}

export const BulkConsumptionForPortfolioAssetMeterData = async (queryParam) => {
  const {data} = await axiosInstance.get(`/api/v1/BulkUpload/Consumption/PortfolioAssetMeterData?sortField=${queryParam[1].paginationParameters.sortField}&sortOrder=${queryParam[1].paginationParameters.sortOrder}&fund=${queryParam[1].paginationParameters.fund}&location=${queryParam[1].paginationParameters.location}&sector=${queryParam[1].paginationParameters.sector}&sector=${queryParam[1].paginationParameters.sector}&searchTerm=${queryParam[1].paginationParameters.searchTerm}&period=${queryParam[1].period}&noOfMonths=${queryParam[1].noOfMonths}&endMonth=${queryParam[1].endDate}&skip=${queryParam[1].paginationParameters.skip}&pageSize=${queryParam[1].paginationParameters.pageSize}&fromDate=${convertDateToFormat(queryParam[2].timePeriodFrom)}&toDate=${convertDateToFormat(queryParam[2].timePeriodTo)}&includeInActiveMeters=${queryParam[2].inactiveMeters}&procuredBy=${getProcuredBy(queryParam[2].metersIncluded)}`)
  return data
}

export const BulkConsumptionForFundDashboard = async (queryParam) => {
  const {data} = await axiosInstance.get(`/api/v1/BulkUpload/Consumption/${queryParam[1]}/FundAssetsBulkUploadData?fromDate=${convertDateToFormat(queryParam[2].timePeriodFrom)}&toDate=${convertDateToFormat(queryParam[2].timePeriodTo)}&includeInActiveMeters=${queryParam[2].inactiveMeters}&procuredBy=${getProcuredBy(queryParam[2].metersIncluded)}`)
  return data
}

export const BulkConsumptionFinalSubmit = async (queryParam) => {
  const {data} = await axiosInstance.post('/api/v1/BulkUpload/Upload',queryParam)
  return data
}

export const BulkUploadAssetActionPlanRequest = async (consumptionId, hasEdit) => {
  const {data} = await axiosInstance.get(`/api/v1/BulkUploadAction/${consumptionId}/AssetBulkActions?isEdit=${hasEdit}`)
  return data && !Array.isArray(data) ? [data] : data
}
