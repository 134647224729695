import {useContext, useEffect, useMemo, useRef, useState} from 'react'
import FundCommonHeader from '../common-header/FundCommonHeader'
import FundPerformanceSummary from './FundPerformanceSummary/FundPerformanceSummary'
import FundPerformanceGraph from './FundPerformanceGraph/FundPerformanceGraph'
import FundPerformanceAssetList from './asset-list/FundPerformanceAssetList'
import {FeatureFlagContext, PortfolioContext} from 'Contexts'
import {useHistory} from 'react-router-dom/cjs/react-router-dom'
import useFundIds from '../../../../utils/custom-hooks/useFundIds'
import {createEndMonthParam, getFromSession, getFundViewsNavLinkConfigsForFlags, selectedUtilityKeyNames, SessionUserState} from '../../../../utils/helpers/Helper'
import {DATA_FROM, EXPORT, FUND_VIEWS_PAGES, GAP_FILLING, PERIOD_TYPES, PERIOD_VIEWS, UtilityTypes} from '../../../../utils/helpers/Constants'
import {getTimeperiodLabel, periodOptions} from '../../../../services/common/datepicker-service'
import {updateQueryParamsForFundViews} from '../../../../utils/helpers/DatePickerUtils'
import {createQueryParams} from '../../../../utils/query-params/QueryParams'
import {portfolioStateInit} from '../../../../utils/reducers/PortfolioReducer'
import {getFundUtilitiesList} from '../../../../utils/data-exports/ExportHelper'
import useFundPerformanceData from './fund-performance-hooks-&-helpers/useFundPerformanceData'
import {getPerformanceChartData, getQueryParamsForPerformance, setInitialPerformanceHomeState} from './fund-performance-hooks-&-helpers/fundPerformanceHelper'
import FundPerformanceStackedChartSkeleton from '../../../common/skeletons/fund-performance-chart-skeleton/FundPerformanceStackedChartSkeleton'
import './FundPerformanceHome.scss'
import useCustomState from '../../../common/custom-hooks/useCustomState'
import FundPerformanceFilterHeader from './FundPerformanceFilterHeader/FundPerformanceFilterHeader'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const FundPerformanceHome = () => {
  const isNavigationFromPerformanceTab = getFromSession(`nav-tab-${FUND_VIEWS_PAGES.performance}`)
  if(isNavigationFromPerformanceTab) {
    SessionUserState.removeUserDataFromSession(`nav-tab-${FUND_VIEWS_PAGES.performance}`)
  }

  const queryValues =  new URLSearchParams(document.location.search)
  const [dataSelectionFilters, setDataSelectionFilters] = useState([])
  const history = useHistory()
  // These variables are required to make the API calls for Performance data
  const {fundId, fundGroupID, fundname, fundUrlName, instanceName, orgName, fundName} = useFundIds(history)
  const performanceQueryParamsRef = useRef(null)

  const selectedFundProps =  useMemo(() => {
    return {fundId, fundGroupID, fundname, fundUrlName}
  }, [fundId, fundGroupID, fundname, fundUrlName])

  // CONTEXTS
  const pfContext = useContext(PortfolioContext)
  const {loginState} = useLoginAuthContext()
  const selectedLanguage = loginState.userInfo.languagePreference
  const unitSystem = loginState.userInfo.unitSystem
  const currencyUnit = loginState.userInfo.currencyUnit
  const {featureState} = useContext(FeatureFlagContext)
  const selectedUtilityLabel = selectedUtilityKeyNames(isNavigationFromPerformanceTab ? portfolioStateInit.colFieldFund : pfContext.pfState.colFieldFund)
  const {customState: fundPerformanceState, updateCustomState: updateFundPerformanceState}=useCustomState(() => setInitialPerformanceHomeState(fundGroupID, fundname, selectedUtilityLabel, queryValues))

  function isGapFillingSelected() {
    return !!fundPerformanceState.selectedBreakdown?.find(breakdownOption=>breakdownOption.key===GAP_FILLING)
  }

  // Variables that don't need to be in state but needs access to context Data or state Data
  const isTargetAvailable = pfContext.pfState.showTarget
  const fundUtilitiesList = getFundUtilitiesList(selectedLanguage)
  const {pickerValue} = fundPerformanceState
  const time = getTimeperiodLabel(fundPerformanceState.timePeriod)
  const timeEndDatePeriod =  createEndMonthParam(pickerValue.pickerValueEndYear, pickerValue.pickerValueEndMonth)
 
  // API calls
  const {fundPerformanceUtilityGraphData, fundPerformanceSummaryData} = useFundPerformanceData(updateFundPerformanceState, fundPerformanceState)
  const excelExportInfo = useMemo(()=>{
    return {
      'options': {
        'enableExport': !fundPerformanceSummaryData?.isLoading ?  'enabled' : 'disabled',
        'hasOverlay': true,
        'dataFrom': DATA_FROM.FUND.FUND_PERFORMANCE,
      },
      'fundAssetListQueryOptions': {
        'fundGroupId': fundGroupID,
        'odataQueryString': fundPerformanceState?.odataQueryString,
        'time': time,
        'timeNoOfMonths': pickerValue.pickerValueMonths,
        'timeEndDate': timeEndDatePeriod,
      }
    }
  },[fundPerformanceSummaryData?.data])

  function updateDataSelectionFilters(newState) {
    setDataSelectionFilters(newState)
  }

  // This will set the url params on load
  useEffect(() => {
    const queryParams=getQueryParamsForPerformance(fundPerformanceState.timePeriod, pickerValue, fundPerformanceState?.selectedUtility?.name, fundPerformanceState.periodView)
    performanceQueryParamsRef.current = createQueryParams(queryParams)
    updateQueryParamsForFundViews(queryParams, history?.location?.state, history, instanceName, orgName, fundName, FUND_VIEWS_PAGES.performance)
  }, [fundPerformanceState.selectedUtility?.name, fundPerformanceState.timePeriod, pickerValue, fundPerformanceState.periodView])

  useEffect(() => {
    let fundPerformanceUtilityGraphDataVariables
    if (fundPerformanceState.timePeriod === PERIOD_TYPES.CUSTOM) {
      fundPerformanceUtilityGraphDataVariables=[
        'fundPerformanceUtilityGraphData',
        fundGroupID,
        fundPerformanceState?.periodView,
        time,
        pickerValue.pickerValueMonths,
        timeEndDatePeriod,
        fundPerformanceState?.selectedUtility.name,
        unitSystem
      ]

    } else {
      fundPerformanceUtilityGraphDataVariables=[
        'fundPerformanceUtilityGraphData',
        fundGroupID,
        fundPerformanceState?.periodView,
        time,
        null,
        null,
        fundPerformanceState?.selectedUtility.name,
        unitSystem
      ]
    }

    const fundPerformanceSummaryVariables=[
      'fundPerformanceSummary',
      fundGroupID,
      fundPerformanceState?.selectedUtility.name,
      time,
      pickerValue.pickerValueMonths,
      timeEndDatePeriod,
      unitSystem,
      currencyUnit
    ]
    updateFundPerformanceState({fundPerformanceUtilityGraphDataVariables, fundPerformanceSummaryVariables, periodView: pickerValue?.pickerValueMonths == 1 ? PERIOD_VIEWS.MONTHLY : fundPerformanceState.periodView})
  }, [fundGroupID, fundPerformanceState.timePeriod, pickerValue, fundPerformanceState.selectedUtility?.name, fundPerformanceState.periodView, unitSystem, currencyUnit])

  function getFundPerformancesData() {
    let performancesData
    if(fundPerformanceState?.selectedUtility.name === UtilityTypes.Carbon) {
      if(Array.isArray(fundPerformanceUtilityGraphData?.data)){
        performancesData = fundPerformanceUtilityGraphData?.data
      }
    } else if (fundPerformanceState?.selectedUtility.name === UtilityTypes.Waste) {
      performancesData = fundPerformanceUtilityGraphData?.data.wasteConsumption
    } else {
      performancesData = fundPerformanceUtilityGraphData?.data?.utilityConsumptions
    }
    return performancesData
  }

  function getChartProps() {
    const performanceChartDataParams = {
      consumptionData: getFundPerformancesData(),
      dataSelectionFilters,
      isTargetAvailable,
      selectedLanguage,
      selectedUtility: fundPerformanceState?.selectedUtility.name,
      periodView: fundPerformanceState?.periodView,
      isGapFillingSelected: isGapFillingSelected()
    }

    return {
      performanceChartData: getPerformanceChartData(performanceChartDataParams),
      updateFundPerformanceState,
      periodView: fundPerformanceState?.periodView,
      timePeriod: fundPerformanceState?.timePeriod,
      selectedUtility: fundPerformanceState?.selectedUtility.name,
      selectedLanguage,
      fundPerformanceSummaryData: fundPerformanceSummaryData?.data,
      dataSelectionFilters,
      disableQuarterlyView : pickerValue?.pickerValueMonths == 1,
    }
  }

  function periodChangeAction(changeEvent) {
    const newPeriod=changeEvent.value
    updateFundPerformanceState({ timePeriod: newPeriod })
  }

  function datePickerChangeAction(changeEvent) {
    const newPickerValue={...pickerValue, [changeEvent?.target?.name]: changeEvent?.target?.value}
    updateFundPerformanceState({pickerValue: newPickerValue})
  }

  function customPeriodAction() {
    updateFundPerformanceState({ timePeriod: PERIOD_TYPES.CUSTOM })
  }

  return (
    <div className='container-layout gutter fund-performance-page'>
      <div className='fund-header'>
        <FundCommonHeader 
          hasDatePicker={true} 
          hasBlueBackground={false} 
          hasDropdownFilter={false}
          navLinkConfigs={getFundViewsNavLinkConfigsForFlags(selectedFundProps, selectedLanguage, featureState)}
          period={fundPerformanceState?.timePeriod}
          periodOptions={periodOptions}
          pickerValue={pickerValue}
          // FUNCTION FOR TIME PERIOD YTD/3M/6M/1Y
          datePickerOnChange={periodChangeAction}
          customDatePickerOnClick={customPeriodAction}
          pickerOnChange={datePickerChangeAction}
          action={EXPORT}
          excelExportInfo={excelExportInfo}
        />
      </div>
      <FundPerformanceFilterHeader
        fundPerformanceState={fundPerformanceState}
        updateFundPerformanceState={updateFundPerformanceState}
        getFundPerformancesData={getFundPerformancesData}
        updateDataSelectionFilters={updateDataSelectionFilters}
      />
      <div className="content-section-summary">
        <FundPerformanceSummary 
          data={fundPerformanceSummaryData?.data} 
          isLoading={fundPerformanceSummaryData?.isLoading} 
          selectedLanguage={selectedLanguage} 
          selectedUtility={fundPerformanceState?.selectedUtility.name}
          selectedBreakdown={fundPerformanceState.selectedBreakdown}
          isGapFillingSelected={isGapFillingSelected()}
        />
      </div>
      <div className="fund-performance-graph content-section-chart">
        {!fundPerformanceUtilityGraphData?.data ?
          <FundPerformanceStackedChartSkeleton />
          :
          <FundPerformanceGraph {...getChartProps()} />
        }
      </div>
      <div className='mt-6 content-section-assets-table'>
        <FundPerformanceAssetList
          updateFundPerformanceState={updateFundPerformanceState}
          sortField={fundPerformanceState?.sortField}
          sortOrder={fundPerformanceState?.sortOrder}
          selectedUtility={fundPerformanceState?.selectedUtility.name}
          performanceQueryParams={performanceQueryParamsRef.current}
          fundUtilitiesList={fundUtilitiesList}
          dataSelectionFilters={dataSelectionFilters}
          selectedBreakdown={fundPerformanceState.selectedBreakdown}
          fundGroupID={fundGroupID}
          odataQueryString={fundPerformanceState?.odataQueryString}
          pickerValue={pickerValue}
          time={time}
          isGapFillingSelected={isGapFillingSelected()}
        />
      </div>
    </div>
  )
}

export default FundPerformanceHome
FundPerformanceHome.displayName = 'FundPerformanceHome'