import { Text, View} from '@react-pdf/renderer'
import React from 'react'
import I18n from '../../../i18n/I18n'
import paginationStyles from '../../style-sheet/StylesPagination.js'
import {unitDateFormatter} from '../../../helpers/Helper'
import PropTypes from 'prop-types'

const PdfPagination = (props) => {
  const unitSystem = props.unitSystem

  const today = () => {
    if(props.language){
      return unitDateFormatter(new Date(),unitSystem,props.language)
    }
    return unitDateFormatter(new Date(),unitSystem,'en-GB')
  }

  return (
    <View style={[paginationStyles.pageNumber,{}]} fixed>
      <View style={[paginationStyles.tableRow]} >
        <View style={paginationStyles.font6}>
          <View style={[paginationStyles.tableRow]}>
            <Text>{I18n('t_pdf_page')} </Text>
            <Text render={({ pageNumber }) => (
              `${pageNumber}`)} fixed />
          </View>
          <View>
            <Text style={[paginationStyles.font6]}>{I18n('t_pdf_exported')} {today()}</Text>
          </View>
        </View>
        <View style={paginationStyles.font5}>
          <Text>{I18n('t_pdf_pagination_copyright')}</Text>
        </View>
      </View>
    </View>
  )
}

PdfPagination.propTypes = {
  unitSystem: PropTypes.string
}
PdfPagination.displayName = 'PdfPagination'

export default PdfPagination