
import PropTypes from 'prop-types'
import './DataQualityCompletenessGraph.scss'
import {Bar} from 'react-chartjs-2'
import {getLocalizedValue} from '../../../../../utils/helpers/Helper'
import {getCompletenessChartData, getCompletenessChartOptions, getCompletenessLegends} from '../data-quality-hooks-&-helpers/dataQualityHelper'
import {formatNumWOneDecimal} from '../../../../../services/common/formatter'
import { Icon, Text } from 'components/atomic/index'
import DataCompletenessSkeleton from './skeletons/DataCompletenessSkeleton'
import { allValuesZero, isEmptyObject } from 'utils/helpers/Utils'

const DataQualityCompletenessGraph = ({completenessData, selectedLanguage, actionHandler, isLoading}) => {
  if(isLoading) {
    return <DataCompletenessSkeleton />
  }
  if(!completenessData || isEmptyObject(completenessData) || allValuesZero(completenessData)) {
    return (
      <div className="dq-completeness">
        <div className="dq-completeness__top-legend flex align-items-center">
          <Text content={`0% ${getLocalizedValue(selectedLanguage, 't_data_completeness')}`} size="xs"/>
          <Icon id="info_solid" size={17} onClickHandler={actionHandler} className='cursor' />
        </div>
      </div>
    )
  }
  return (
    <div className="dq-completeness">
      <div className="dq-completeness__top-legend flex align-items-center">
        <Text content={`${formatNumWOneDecimal(selectedLanguage, completenessData?.overallPercentage)}% ${getLocalizedValue(selectedLanguage, 't_data_completeness')}`} size="xs"/>
        <Icon id="info_solid" size={17} onClickHandler={actionHandler} className='cursor' />
      </div>
      <div className="dq-completeness__chart">
        <Bar data={getCompletenessChartData(completenessData)} options={getCompletenessChartOptions(100)} />
      </div>
      <div className="dq-completeness__btm-legends flex align-items-center">
        {getCompletenessLegends(completenessData, selectedLanguage)}
      </div>
    </div>

  )
}

DataQualityCompletenessGraph.propTypes = {
  completenessData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  selectedLanguage: PropTypes.string,
  actionHandler: PropTypes.func,
  isLoading: PropTypes.bool
}

DataQualityCompletenessGraph.displayName = 'DataQualityCompletenessGraph'

export default DataQualityCompletenessGraph