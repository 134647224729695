import axiosInstance from '../common/axios-config'
import axiosInstancePaneer from '../common/axios-config-paneer'

export const GetUsersList = async () => {
  const {data} = await axiosInstance.get('/api/v1/Admin/Users')
  return data
}

export const GetUserById = async (keyInfo) => {
  const data = await GetUser(keyInfo.queryKey[0])
  return data
}

export const GetUser = async (userId) => {
  const {data} = await axiosInstance.get(`/api/v1/Admin/Users/${userId}`)
  return data
}

export const AddUser = async (newUserData) => {
  const {data} = await axiosInstance.post('/api/v1/Admin/Users',newUserData)
  return data
}

export const DeleteUser = async (userInfo) => {
  const {data} = await axiosInstance.delete(`/api/v1/Admin/Users/${userInfo.userId}`)
  return data
}

export const updateUserAccessStatus = async (userInfo) => {
  const {data} = await axiosInstance.put(`/api/v1/Admin/Users/${userInfo.userId}/UserAccessStatus`)
  return data
}


/*
  Get Groups , Assets based on the userId
 */
export const GetGroupsListById = async (userInfo) => {
  const authorisationId=userInfo.queryKey[1]
  if(authorisationId){
    const {data} = await axiosInstance.get(`/api/v1/UserAssetGroups/${userInfo.queryKey[1]}`)
    return data
  }
  return null
}

export const GetAuthRoles = async () => {
  const {data} = await axiosInstance.get('/api/v1/Authentication/Roles')
  return data
}

// Access all the available groups
export const GetAllGroupsList = async () => {
  const {data} = await axiosInstance.get('/api/v1/AssetGroups')
  return data
}

export const GetAllAssetsList = async () => {
  const {data} = await axiosInstancePaneer.get('/api/v1/Assets')
  return data
}

export const UpdateUser = async (userDataUpdate) => {
  const {data} = await axiosInstance.put(`/api/v1/Admin/Users/${userDataUpdate.userId}`,userDataUpdate.userData)
  return data
}

export const SendInvitationToUser = async (userInfo) => {
  const {data} = await axiosInstance.post(`/api/v1/Users/${userInfo.userId}/Signup`)
  return data
}

export const userVerify = async (userInfo) => {
  //Encoding the user token since the url decodes by default as we are passing directly (not as body)
  const userToken=encodeURIComponent(userInfo.queryKey[1])
  const {data} = await axiosInstance.get(`/api/v1/Users/${userInfo.queryKey[0]}/Verify?userToken=${userToken}`)
  return data
}

export const userCreationSignup = async (userInfo) => {
  const {data} = await axiosInstance.post(`/api/v1/Users/${userInfo.userId}/Signup/Confirmation`,userInfo.data)
  return data
}

export const addAssetGroups = async ({userId, assetGroups}) => {
  const {data} = await axiosInstance.post(`/api/v1/UserAssetGroups/${userId}`,assetGroups)
  return data
}

export const updateAssetGroups = async ({userId, assetGroups}) => {
  const {data} = await axiosInstance.put(`/api/v1/UserAssetGroups/${userId}`,assetGroups)
  return data
}

/**
  @returns `[
    {
      "instanceId": 0,
      "description": "string",
      "ownerUserID": "string",
      "urlName": "string"
    }
  ]`
**/
export const getInstancesForUser = async () => {
  const {data} = await axiosInstance.get('/api/v1/User/instances')
  return data
}

/**
  @returns undefined
**/
export const setInstanceForUser = async (instanceId) => {
  const {data} = await axiosInstance.post(`/api/v1/User/instances/assign/${instanceId}`)
  return data
}

export const AddAssetAccess = async ({userAuthId, assetIds}) => {
  const {data} = await axiosInstance.post(`/api/v1/AssetAccess/User/${userAuthId}`,assetIds)
  return data
}

export const UpdateAssetAccess = async ({userAuthId, assetIds}) => {
  const {data} = await axiosInstance.put(`/api/v1/AssetAccess/User/${userAuthId}`,assetIds)
  return data
}

export const getUserAssetAccess = async (userInfo) => {
  const {data} = await axiosInstance.get(`/api/v1/AssetAccess/${userInfo.queryKey[1]}`)
  return data
}

export const getUserAssetData = async (userInfo) => {
  const {data} = await axiosInstance.get(`/api/v1/Assets/${userInfo.queryKey[1]}/AssetData`)
  return data
}

export const getAllUserFundGroups = async () => {
  const {data} = await axiosInstance.get('/api/v1/FundGroups/users')
  return data
}

export const addRemoveFundsFromUser = async (queryKey) => {
  const {data} = await axiosInstance.put(`/api/v1/FundGroups/${queryKey.authorisationId}/users`,queryKey.funds)
  return data
}

export const removeFundsFromUser = async (fundId) => {
  const {data} = await axiosInstance.delete(`/api/v1/FundGroups/${fundId}/users`)
  return data
}

export const getAllFunds = async () => {
  const {data} = await axiosInstancePaneer.get('/api/v1/funds')
  return data
}

export const GetAssetGroup = async (assetGroupID) => {
  const  {data} = await axiosInstance.get(`/api/v1/AssetGroups/${assetGroupID}`)
  return data
}

export const AddUserFeedback = async (userFeedback) => {
  const {data} = await axiosInstance.post('/api/v1/UserFeedback/EmailUserFeedback', userFeedback)
  return data
}