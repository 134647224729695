
import PropTypes from 'prop-types'
import {Dropdown} from '../../../../atomic'
import SearchField from '../../../../common/search/SearchField'
import {getLocalizedValue} from '../../../../../utils/helpers/Helper'
import './DataQualityMenuHeader.scss'
import { areaListV2, utilityListV2 } from '../data-quality-hooks-&-helpers/dataQualityHelper'

const DataQualityMenuHeader = ({meterUtility, meterArea, updateDataQualityState, selectedLanguage, searchValue, searchMetersHandler, clearSearchField}) => {
  return (
    <div className="qq-menu-header flex justify-content-between">
      <div className="qq-menu-header__menus flex">
        <Dropdown
          value={meterUtility}
          onChange={e => updateDataQualityState({meterUtility: e.value})}
          options={utilityListV2}
          optionLabel="label"
          placeholderKey='t_alu' colour='light'
        />
        <Dropdown
          value={meterArea}
          onChange={e => updateDataQualityState({meterArea: e.value})}
          options={areaListV2}
          optionLabel="label"
          placeholderKey='t_aas'
          colour='light'
        />
      </div>
      <div className="qq-menu-header__search-meter">
        <SearchField
          placeholder={getLocalizedValue(selectedLanguage, 't_search_meter_placeholder')}
          searchValue={searchValue}
          onChange={searchMetersHandler}
          clearField={clearSearchField}
        />
      </div>
    </div>
  )
}

DataQualityMenuHeader.propTypes = {
  meterUtility: PropTypes.string,
  searchValue: PropTypes.string,
  meterArea: PropTypes.string,
  updateDataQualityState: PropTypes.func,
  selectedLanguage: PropTypes.string,
  searchMetersHandler: PropTypes.func,
  clearSearchField: PropTypes.func
}
DataQualityMenuHeader.displayName = 'DataQualityMenuHeader'

export default DataQualityMenuHeader