import {Text, View} from '@react-pdf/renderer'
import React, {useState} from 'react'
import styles from '../../../../../utils/data-exports/style-sheet/Styles'
import I18n from '../../../../../utils/i18n/I18n'
import {areaCoveredMask, costMask, costTypeMask, scopeMask} from '../../../../../utils/helpers/Constants'
import {
  CurrencyUnitToLabel,
  truncateDecimal,
  unitDateFormatter,
  unitsConverter
} from '../../../../../utils/helpers/Helper'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const AgreedAction = (props) => {
  const [agreedAction] = useState(props.agreedAction)
  const {loginState: {userInfo}} = useLoginAuthContext()
  const unitSystem = userInfo.unitSystem
  const currencyUnit = userInfo.currencyUnit

  /*
        Using this since word break is not working
        Handled for two line(24 character)
    */
  String.prototype.InsertAt=function(CharToInsert,Position){
    return this.slice(0,Position) + CharToInsert + this.slice(Position)
  }

  const formatUnits = (value) => {
    if (value != null) {
      if(value.toString().length>10)
        return Number(value).toLocaleString(props.language).InsertAt(' ',12)
      return Number(value).toLocaleString(props.language)
    }
    return value
  }

  const formatDate = (value) => {
    if (props.language) {
      return unitDateFormatter(new Date(value),unitSystem,props.language)
    }
    return unitDateFormatter(new Date(value),unitSystem,'en-GB')
  }

  function currentUnit(data){
    return  unitsConverter(data,unitSystem )
  }

  function dateTemplate(action) {
    if(props.completed){
      return action.completion ? formatDate(action.completion) : '-'
    }
    else{
      return action.dueDate ? formatDate(action.dueDate) : '-'
    }
  }

  function costUnits(currency){
    let currencyLabel
    if(props.featureState.sieraplus_currency){
      currencyLabel = costMask[CurrencyUnitToLabel(currencyUnit)]
    } else {
      currencyLabel = costMask[currency]
    }
    return currencyLabel
  }

  const tableToPrint = agreedAction.sort((first, next) => first.date < next.date ? 1 : -1).map(function (src, index) {
    return (
      <View key={index} style={[styles.tableRowFlex,{}]} wrap={false}>
        <View style={[styles.tableBorder, styles.tablePadding, (index % 2) !== 0 ? styles.bgColorGrey : null , styles.aAction]}>
          <Text style={[styles.dqText, {}]}>{src.action.actionDescription ? src.action.actionDescription : '-'}</Text>
        </View>

        <View style={[styles.tableBorder, styles.tablePadding, styles.actionCell, (index % 2) !== 0 ? styles.bgColorGrey : null , {}]}>
          <Text style={[styles.dqText, {}]}>{src.action.impact ? Array.from(src.action.impact) : '-'}</Text>
        </View>

        <View style={[styles.tableBorder, styles.tablePadding, styles.actionCell, (index % 2) !== 0 ? styles.bgColorGrey : null, {}]}>
          <Text style={[styles.dqText, {}]}>{src.action.current ?
            formatUnits(truncateDecimal(src.action.current,2)) + '' + currentUnit(src.action.impact) : '-'}
          </Text>
        </View>

        <View style={[styles.tableBorder, styles.tablePadding, (index % 2) !== 0 ? styles.bgColorGrey : null , styles.aTarget]}>
          <Text style={[styles.dqText]}>
            {(src.action.targetUsagePercentage === '' || src.action.targetUsagePercentage === null)? '-'
              : formatUnits(truncateDecimal(src.action.targetUsagePercentage,2)) + '%'}
          </Text>
          { (src.action.target === '' || src.action.target === null || src.action.target === 'undefined') ? null :
            <Text style={[styles.tableTitle]}>{'(' + formatUnits(truncateDecimal(src.action.target, 2)) +
                            currentUnit(src.action.impact) + ')'}
            </Text>
          }
        </View>

        <View style={[styles.tableBorder, styles.tablePadding, styles.aTarget,(index % 2) !== 0 ? styles.bgColorGrey : null]}>
          <Text style={[styles.dqText]}>
            {src.action.currency && src.action.cost ? costUnits(src.action.currency) : ''}
            {src.action.cost === null  || src.action.cost === '' ? '-' : ' ' + formatUnits(src.action.cost)}
          </Text>
        </View>

        <View style={[styles.tableBorder, styles.tablePadding ,styles.costTypeCell,(index % 2) !== 0 ? styles.bgColorGrey : null]}>
          <Text style={[styles.dqText,{}]}>{costTypeMask[src.action.costType]}</Text>
        </View>

        <View style={[styles.tableBorder, styles.tablePadding, styles.actionCell, (index % 2) !== 0 ? styles.bgColorGrey : null]}>
          <Text style={[styles.dqText, {}]}>{areaCoveredMask[src.action.areaCovered]}</Text>
        </View>

        <View style={[styles.tableBorder,styles.completionCell, styles.tablePadding, (index % 2) !== 0 ? styles.bgColorGrey : null]}>
          <Text style={[styles.dqText, {}]}>{dateTemplate(src.action)}</Text>
        </View>
      </View>
    )
  })
  return (
    <View>
      <View wrap={false}>
        <Text style={[styles.title, styles.mB10]}>{props.title}</Text>
        <View style={[styles.tableTitle, styles.columnHeader,styles.greyColor]}>
          <View style={styles.aAction}>
            <Text style={styles.mL2}>{I18n('t_action')}</Text>
          </View>
          <View style={styles.actionCell}>
            <Text style={styles.mL2}>{I18n('t_impact')}</Text>
          </View>
          <View style={styles.actionCell}>
            <Text style={styles.mL2}>{I18n('t_current')}</Text>
          </View>
          <View style={styles.aTarget}>
            <Text style={styles.mL2}>{I18n('t_estimated_savings')}</Text>
          </View>
          <View style={styles.aTarget}>
            <Text style={styles.mL2}>{I18n('t_cost')}</Text>
          </View>
          <View style={styles.costTypeCell}>
            <Text style={styles.mL2}>{I18n('t_cost_type')}</Text>
          </View>
          <View style={styles.actionCell}>
            <Text style={styles.mL2}>{I18n('t_area_covered')}</Text>
          </View>
          <View style={styles.actionCell}>
            <Text style={styles.mL2}>{props.completed ? I18n('t_completion') : I18n('t_due_date')}</Text>
          </View>
        </View>
      </View>
      <View style={[styles.tableWidth]}>
        {tableToPrint}
      </View>
    </View>
  )
}

export default AgreedAction