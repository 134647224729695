import {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {Button} from 'primereact/button'
import {Chips} from 'primereact/chips'
import {dqEmailRegex} from '../../../utils/helpers/Constants'
import {getLocalizedValue} from '../../../utils/helpers/Helper'
import I18n from '../../../utils/i18n/I18n'
import CSS_VARIABLES from '../../../resources/css/_variables.scss'
import './RecipientEmailContainer.scss'
import sendConfirmationIcon from '../../../resources/images/icon/action-plan/send-confirmation.svg'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const RecipientEmailContainer = ({
  setEmailAddresses,
  showRequestConfirmation = false,
  setShowRequestConfirmation,
  sendDataHandler,
}) => {
  const customChip = (item) => {
    return (
      <div className="label-chip-container">
        <span className="chip-label">{item}</span>
        <i className="pi pi-times chip-circle" onClick={emailChipIconRemoveHandler}/>
      </div>
    )
  }
  const [emails, setEmails]= useState([])
  const [invalidEmails, setInvalidEmails]=useState([])
  const emailPattern = new RegExp(dqEmailRegex)
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference

  const addEmailHandler = (evt) => {
    let newEmail = evt.value
    if(!emailPattern.test(newEmail)) {
      let prevInvalidEmails = [...invalidEmails]
      prevInvalidEmails.push(newEmail)
      setInvalidEmails(prevInvalidEmails)
    }
  }

  const chipChangeHandler = (event) => {
    setEmails(event.value)
    setEmailAddresses(event.value)
  }

  function removeEmail(emailToRemove) {
    if(emailToRemove){
      let newInvalidEmails=invalidEmails.filter(email=>email!==emailToRemove) //Remove from [invalidEmails]
      setInvalidEmails(newInvalidEmails)

      let newEmails=emails.filter(email=>email!==emailToRemove) //Remove from [emails]
      setEmails(newEmails)
      setEmailAddresses(newEmails)
    }
  }

  function removeEmailHandler(evt) {
    removeEmail(evt.value[0])
  }

  function emailChipIconRemoveHandler(evt) {
    let currTarget = evt.currentTarget
    if(currTarget?.parentElement?.textContent) {
      let emailToRemove = currTarget.parentElement.textContent
      removeEmail(emailToRemove)
    }
  }

  function isEmailValid() {
    return (emails?.length > 0 && invalidEmails?.length === 0)
  }

  function setInvalidEmailColor() {
    let element = document.querySelector('div.p-chips ul.p-inputtext.p-chips-multiple-container')
    if(element?.children) {
      Array.from(element.children).forEach((child) => {
        child.style.color = emailPattern.test(child.textContent)
          ? CSS_VARIABLES.forest : CSS_VARIABLES.red
      })
    }
  }

  useEffect(() => {
    setShowRequestConfirmation(false)
    setInvalidEmailColor()
  }, [emails])

  return <div className="recipient-email-section" role={'recipient-container'}>
    <div className="recipient-email-section__label">{I18n('t_request_email_label')}</div>
    <div className="recipient-email-section__input">
      <Chips
        value={emails}
        onChange={chipChangeHandler}
        itemTemplate={customChip}
        separator=","
        onAdd={(e)=>addEmailHandler(e)}
        onRemove={removeEmailHandler}
        placeholder={getLocalizedValue(selectedLanguage, 't_request_email_placeholder')}
      />
    </div>
    <div className={'email-request'}>
      <Button className={'common-blue-bg-btn email-request__submit-button'} label={I18n('t_request_send_button')}
        name={'send-request'} disabled={!(isEmailValid())} onClick={sendDataHandler}/>
      {showRequestConfirmation && <div className={'request-confirmation'}>
        <img className={'mr-0_5'} src={sendConfirmationIcon}
          alt="confirmation icon"/><span>{I18n('t_email_sent_confirmation')}</span>
      </div>}
    </div>
  </div>
}

RecipientEmailContainer.propTypes = {
  showRequestConfirmation: PropTypes.bool,
  setShowRequestConfirmation: PropTypes.func.isRequired,
  setEmailAddresses: PropTypes.func.isRequired,
  sendDataHandler: PropTypes.func.isRequired,
}

export default RecipientEmailContainer