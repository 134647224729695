import I18n from '../../../../../utils/i18n/I18n'
import './NetZeroCarbonV3.scss'
import FundNzcCardSkeletonV3 from '../../../skeletons/fund-nzc-card-skeleton/FundNzcCardSkeletonV3'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Chart } from 'primereact/chart'
import {useQuery} from 'react-query'
import { GetNZCSettings } from 'services/funds/nzc-service'
import { chartDefaultBaseYears, createStaticNZCGraphDataSets, getNzcChartOptions, getNzcChartPlugins } from './netZeroCarbonHelper'
import { noRefetchOnWindowFocus } from 'services/common/useQuery-config'
import { STRANDINGS } from 'utils/helpers/Constants'
import { Text } from 'components/atomic/index'

const StaticNetZeroCarbon = ({ selectedLanguage, isLoading, targetPathways, isDependentLoading = false, nzcScopeId, assetLevel = false }) => {
  const [nzcGraphData, setNZCGraphData] = useState()
  const getNzcSettingsData = useQuery(['GetNZCSettings', nzcScopeId], GetNZCSettings, noRefetchOnWindowFocus)
  const nzcBaseLineYear = getNzcSettingsData?.data?.baselineYear
  const strandingYear = targetPathways?.strandings?.find(stranding => stranding.strandingOf === STRANDINGS.BAU)?.year ?? `pre - ${targetPathways?.targetPathways[0]?.year}`

  useEffect(() => {
    let datasets = createStaticNZCGraphDataSets(targetPathways, selectedLanguage, nzcBaseLineYear)
    if (datasets){
      setNZCGraphData({ labels: chartDefaultBaseYears.map(x => (x % 2 === 0 || x === nzcBaseLineYear) ? x : null), datasets })
    }
  }, [nzcScopeId, targetPathways, nzcBaseLineYear])

  return (
    <>
      {isLoading || isDependentLoading || !targetPathways ?
        <FundNzcCardSkeletonV3 />
        :
        <div data-testid="NZC-fund-graph-static" className='db-nzc-card'>
          <div className='db-nzc-card__header flex align-items-center justify-content-between'>
            <div className="db-nzc-card__header__label capitalize">
              <Text
                content='t_bau_stranding'
              />
            </div>
            <Text
              content={strandingYear}
              testId='static-nzc-lens-stranding-year'
            />
          </div>
          <div className="db-nzc-card__body">
            <div className='db-nzc-card__body__chart bbx' data-testid='static-nzc-lens-graph'>
              <Chart
                data={nzcGraphData}
                options={getNzcChartOptions(assetLevel, nzcBaseLineYear)}
                plugins={getNzcChartPlugins(assetLevel, nzcBaseLineYear)}
              />
            </div>
          </div>
          <hr />
          <div className="db-nzc-card__footer brx">
            <div className='db-nzc-card__footer__intensity flex align-items-center justify-content-between' data-testid="footer-label">
              <Text
                content="t_carbon_intensity"
              />
              <Text
                content="t_carbon_intensity_value"
                contentDynamic={{ 0: targetPathways?.scopeFundPerformance?.carbonIntensity }}
                testId='static-nzc-lens-carbon-intensity'
              />
            </div>
            <div data-testid="static-nzc-lens-reporting-year" className='db-nzc-card__footer__baseline'>{I18n('t_reporting_year')}: {targetPathways?.scopeFundPerformance?.year}</div>
          </div>
        </div>
      }
    </>
  )
}

StaticNetZeroCarbon.displayName = 'StaticNetZeroCarbon'

StaticNetZeroCarbon.propTypes = {
  selectedLanguage: PropTypes.string,
  isLoading: PropTypes.bool,
  targetPathways: PropTypes.object,
  isDependentLoading: PropTypes.bool,
  nzcScopeId: PropTypes.number,
  assetLevel: PropTypes.bool
}

export default StaticNetZeroCarbon