import axiosInstance from '../common/axios-config'
import {assetsOrderByColumnChange} from '../../utils/helpers/Constants'

// TODO: this isn't handling the error, it's reporting it.
function nzcGetApiErrorHandler(error) {
  if (error.response) {
    // Request made and server responded
    console.log(error.response)
  } else if (error.request) {
    // The request was made but no response was received
    console.log(error.request)
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message)
  }
}

const getIntensity = (queryKey) => {
  return !queryKey || queryKey === assetsOrderByColumnChange.carbonIntensity ? 'Carbon' : 'Energy'
}

const nzcScopeBaseURL = 'api/v1/NZC/Scopes'
const nzcAssetBaseUrl = 'api/v1/NZC/Asset'

const nzcBaseUrlWithScope = (scopeId) => {
  return `${nzcScopeBaseURL}/${scopeId}`
}

const nzcAssetBaseUrlWithAsset = (assetId) => {
  return `${nzcAssetBaseUrl}/${assetId}/Scopes`
}

export const GetNZCTargetPathways = async (queryInfo) => {
  const scopeId = queryInfo.queryKey[1]
  const intensityType = getIntensity(queryInfo.queryKey[2])
  let targetPathwaysData
  if (scopeId) {
    const {data} = await axiosInstance.get(`${nzcBaseUrlWithScope(scopeId)}/TargetPathways?intensityType=${intensityType}`).catch(nzcGetApiErrorHandler)
    targetPathwaysData = data
  }
  return targetPathwaysData
}

export const GetStaticNZCTargetPathways = async (keys) => {
  const fundGroupId = keys?.queryKey[1]
  const intensity = keys?.queryKey[2]
  if(!fundGroupId || !intensity) {
    return
  }

  const { data } = await axiosInstance.get(`/api/v1/NZC/Fund/${fundGroupId}/TargetPathways?intensityType=${intensity}`)
  return data
}

export const GetNZCScopes = async (queryInfo) => {
  const fundGroupId = queryInfo.queryKey[1]
  let scopeData
  if (fundGroupId) {
    const {data} = await axiosInstance.get(`${nzcScopeBaseURL}?fundGroupId=${fundGroupId}`).catch(nzcGetApiErrorHandler)
    scopeData = data
  }
  return scopeData
}

export const GetNZCScopeDetails = async (queryInfo) => {
  const scopeId=queryInfo.queryKey[1]
  const fundId=queryInfo.queryKey[2]
  const intensityType = getIntensity(queryInfo.queryKey[3])
  if(scopeId && fundId){
    const {data} = await axiosInstance.get(`${nzcBaseUrlWithScope(scopeId)}?fundGroupId=${fundId}&intensityType=${intensityType}`)
    return data
  }
  return null
}

export const CreateNZCScope = async (fundInfo) => {
  const fundGroupId=fundInfo.fundGroupId
  if(fundGroupId){
    const {data} = await axiosInstance.post(`${nzcScopeBaseURL}/default?fundGroupId=${fundGroupId}`)
    return data
  }
  return null
}

export const SetAsDefaultModel = async (scopeId) => {
  const {data} = await axiosInstance.post(`/${nzcBaseUrlWithScope(scopeId)}/SetNZCDefaultModel`)
  return data
}

export const setAssetDefaultModel = async ({ assetId, scopeId }) => {
  return axiosInstance.post(`${nzcAssetBaseUrlWithAsset(assetId)}/${scopeId}/SetNZCDefaultModel`)
}

export const CreateNZCModel = async (modelInfo) => {
  const {data} = await axiosInstance.post(nzcScopeBaseURL, modelInfo)
  return data
}

export const DuplicateNZCModel = async ({scopeId, modelInfo}) => {
  const {data} = await axiosInstance.post(`${nzcBaseUrlWithScope(scopeId)}/Duplicate`, modelInfo)
  return data
}

export const DeleteNZCModel = async (modelInfo) => {
  const scopeId=modelInfo.scopeId
  const fundGroupId = modelInfo.fundGroupId
  const {data} = await axiosInstance.delete(`${nzcScopeBaseURL}?scopeId=${scopeId}&fundGroupId=${fundGroupId}`)
  return data
}

export const deleteAssetNZCModel = async (modelInfo) => {
  const scopeId=modelInfo.scopeId
  const assetId = modelInfo.assetId
  return axiosInstance.delete(`${nzcAssetBaseUrlWithAsset(assetId)}/${scopeId}`)
}

export const ExportNzcConfigurationData = async (queryInfo) => {
  const scopeId=queryInfo.queryKey[1]
  if(scopeId){
    const {data} = await axiosInstance.get(`${nzcBaseUrlWithScope(scopeId)}/Export`)
    return data
  }
  return null
}

export const ValidateNZCImportData = async (importInfo) => {
  const csvImportedArray = importInfo.csvImportedArray
  const scopeId = importInfo.scopeId
  const {data, status} = await axiosInstance.post(`${nzcBaseUrlWithScope(scopeId)}/ValidateNZCImportData`, csvImportedArray)
  return {data, status}
}

export async function uploadNZCImportData({scopeId, uploadData}) {
  const {data} = await axiosInstance.post(`/${nzcBaseUrlWithScope(scopeId)}/UploadNZCImportData`, uploadData)
  return data
}

export const DeleteScopeTarget = async ({scopeId, targetPathwayNumber, intensity}) => {
  const intensityType = getIntensity(intensity)
  if(scopeId && targetPathwayNumber){
    const {data} = await axiosInstance.delete(`${nzcBaseUrlWithScope(scopeId)}/Targets?targetPathwayNumber=${targetPathwayNumber}&intensityType=${intensityType}`)
    return data
  }
  return null
}

export const CreateTargetPathway = async ({targetPathwayData, nzcScopeId, intensity}) => {
  const intensityType = getIntensity(intensity)
  if(nzcScopeId){
    const {data} = await axiosInstance.post(`${nzcScopeBaseURL}/${nzcScopeId}/Targets?intensityType=${intensityType}`,targetPathwayData)
    return data
  }
  return null
}

export const UpdateNZCConfigSettings = async (settingsInfo) => {
  const scopeId = settingsInfo.scopeId
  const intensityType = getIntensity(settingsInfo?.graphIntensity)
  if (scopeId) {
    const { data } = await axiosInstance.put(`${nzcBaseUrlWithScope(scopeId)}/Config/Settings?intensityType=${intensityType}`, settingsInfo.settings)
    return data
  }
  return null
}
export const UpdateNZCConfigTargetPathways = async (settingsInfo) => {
  const scopeId = settingsInfo.scopeId
  const intensityType = getIntensity(settingsInfo?.graphIntensity)
  if (scopeId) {
    const { data } = await axiosInstance.put(`${nzcBaseUrlWithScope(scopeId)}/Config/TargetPathways?intensityType=${intensityType}`, settingsInfo.settings)
    return data
  }
  return null
}

export const UpdateNZCConfigChartOptions = async (settingsInfo) => {
  const scopeId = settingsInfo.scopeId
  const intensityType = getIntensity(settingsInfo?.graphIntensity)
  if (scopeId) {
    const { data } = await axiosInstance.put(`${nzcBaseUrlWithScope(scopeId)}/Config/ChartOptions?intensityType=${intensityType}`, settingsInfo.settings)
    return data
  }
  return null
}


export const GetNZCSettings = async (queryInfo) => {
  const scopeId = queryInfo.queryKey[1]
  const intensityType = getIntensity(queryInfo.queryKey[2])
  if (scopeId) {
    const { data } = await axiosInstance.get(`${nzcBaseUrlWithScope(scopeId)}/Config?intensityType=${intensityType}`)
    return data
  }
  return null
}

export const getActionBasedTPData= async (keyInfo)=>{
  const scopeId = keyInfo?.queryKey[1]
  if(scopeId){
    const {data} = await axiosInstance.get(`${nzcBaseUrlWithScope(scopeId)}/Actions/Count`)
    return data
  }
}

export const getBenchmarkOptions = async (queryInfo) => {
  const intensityType = getIntensity(queryInfo.queryKey[1])
  const assetId = queryInfo.queryKey[2]
  if(assetId) {
    const { data } = await axiosInstance.get(`api/v1/NZC/Asset/${assetId}/MarketBenchmarkOptions?intensityType=${intensityType}`)
    return data ? data : []
  } else {
    const { data } = await axiosInstance.get(`api/v1/NZC/MarketBenchamarkOptions?intensityType=${intensityType}`)
    return data ? data : []
  }
} 

export const updateNzcAssets = async (assetInfo) => {
  const assetIds = assetInfo.selectedAssetIds
  const fundGroupId = assetInfo.fundGroupId
  const scopeId = assetInfo.scopeId
  const {data} = await axiosInstance.put(`${nzcBaseUrlWithScope(scopeId)}/Assets?fundGroupId=${fundGroupId}`, assetIds)
  return data
}

export const GetNZCDataQualitySummaryData = async (keyInfo) => {
  let data
  let queryParams
  const scopeId = keyInfo.queryKey[1]
  const timePeriod = keyInfo.queryKey[2]
  const noOfMonths = keyInfo.queryKey[3]
  const endDate = keyInfo.queryKey[4]
  if (!scopeId) {
    return
  }
  if(timePeriod === 'Custom'){
    queryParams=`period=${timePeriod}&noOfMonths=${noOfMonths}&endMonth=${endDate}`
  } else {
    if(timePeriod){
      queryParams=`period=${timePeriod}`
    } else {
      queryParams='period=YTD'
    }
  }
  data = await axiosInstance.get(`/${nzcBaseUrlWithScope(scopeId)}/NZCDQMetricData?${queryParams}`)
  return data
}

// /api/v1/NZC/Asset/Scopes/{id}
export const getAssetNzcScopeDetails = async (queryInfo) => {
  const scopeId=queryInfo.queryKey[1]
  return axiosInstance.get(`${nzcAssetBaseUrl}/Scopes/${scopeId}`)
}

// /api/v1/NZC/Asset/{assetId}/Scopes/default
export const createAssetNZCDefaultScope = async (assetId) => {
  return axiosInstance.post(`${nzcAssetBaseUrlWithAsset(assetId)}/default`)
}

// AssetLevel create model api
export const createAssetNZCModel = async (modelInfo) => {
  return axiosInstance.post(`${nzcAssetBaseUrl}/Scopes`, modelInfo)
}

// Asset level duplicate model api
export const DuplicateAssetNZCModel = async ({scopeId, modelInfo}) => {
  return axiosInstance.post(`${nzcAssetBaseUrl}/Scopes/${scopeId}/Duplicate`, modelInfo)
}

// Asset level model list
export const assetLevelModelList = async (queryInfo) => {
  const assetId = queryInfo.queryKey[1]
  return axiosInstance.get(`${nzcAssetBaseUrlWithAsset(assetId)}`)
}

// /api/v1/NZC/Asset/{assetId}/scope/{scopeId}/actionDetails/{year}
export const getAssetNzcActionDetails= async (keyInfo)=>{
  const assetId = keyInfo?.queryKey[1]
  const scopeId = keyInfo?.queryKey[2]
  const year = keyInfo?.queryKey[3]
  const intensityType = getIntensity(keyInfo?.queryKey[4])
  const {data} = await axiosInstance.get(`${nzcAssetBaseUrl}/${assetId}/Scope/${scopeId}/actionDetails/${year}?intensityType=${intensityType}`)
  return data
}

export async function uploadNzcAssetImportData({assetId, scopeId, uploadData}) {
  const {data} = await axiosInstance.post(`${nzcAssetBaseUrlWithAsset(assetId)}/${scopeId}/UploadNZCImportData`, uploadData)
  return data
}

export const uploadNzcTopdownPathways = ({scopeId, intensityType, topDownPathways}) => {
  const {data} = axiosInstance.post(`${nzcBaseUrlWithScope(scopeId)}/Config/topdownpathways?intensityType=${intensityType}`, topDownPathways)
  return data
}

export const deleteNzcTopdownPathways = async ({scopeId, intensityType}) => {
  const {data} = await axiosInstance.delete(`${nzcBaseUrlWithScope(scopeId)}/Config/topdownpathways?intensityType=${intensityType}`)
  return data
}