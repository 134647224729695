import React, {useEffect, useState} from 'react'
import './NzcModel.scss'
import {InputText} from 'primereact/inputtext'
import {Button} from 'primereact/button'
import I18n from '../../../../../utils/i18n/I18n'
import {ExportNzcConfigurationData} from '../../../../../services/funds/nzc-service'
import {useQuery} from 'react-query'
import {noRefetchOnWindowFocus} from '../../../../../services/common/useQuery-config'
import {getAssetOrderName} from '../../../../../utils/helpers/Constants'
import NZCModelSkeleton from '../../../../common/skeletons/nzc-fund-skeleton/NzcModelSkeleton'
import {MODEL_FILE_NAME_MAX_ALLOWED_CHARS} from '../../../../common/net-zero-carbon/nzc-constants'
import {nzcExportModelData} from '../../../../common/net-zero-carbon/nzc-common-utils'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const NzcExportModel = (props) => {
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const scopeName = props.NzcScopeDetailsData?.scopeName === undefined ? '' : props.NzcScopeDetailsData?.scopeName
  const assetOrderBy = props.assetOrderByValue?.value === undefined ? '' : props.assetOrderByValue?.value
  const assetOrderLabel = getAssetOrderName(assetOrderBy, selectedLanguage)
  const exportDefaultFileName = scopeName + '_' + assetOrderLabel
  const [exportFileName, setExportFileName] = useState({ value: exportDefaultFileName + '.csv', error: ''})
  const { data: NZCExportConfigurationData, isLoading: NZCExportConfigurationDataLoading } = useQuery(['ExportNzcConfigurationData', props.scopeId], ExportNzcConfigurationData, noRefetchOnWindowFocus)
  const [exportConfigurationData, setExportConfigurationData] = useState([])

  useEffect(() => {
    if(NZCExportConfigurationData){
      setExportConfigurationData(NZCExportConfigurationData)
    }
  }, [NZCExportConfigurationData])

  function handleChange(event) {
    const { value } = event.target
    if (value.endsWith('.csv')) {
      setExportFileName({ ...exportFileName, value: value, error: ''})
    }
  }

  function filterArrayOfObj(assetsArray) {
    let filteredArray = []
    //outer array
    if (assetsArray.length !== 0) {
      assetsArray.filter(function(item) {
        const sortedObject = nzcExportModelData(item)
        filteredArray.push(sortedObject)
      })
    } else {
      const sortedObject = nzcExportModelData({})
      filteredArray.push(sortedObject)
    }

    return filteredArray
  }

  function handleCSVExport(e) {
    e.preventDefault()
    if (exportFileName.value.length > MODEL_FILE_NAME_MAX_ALLOWED_CHARS) {
      setExportFileName({ ...exportFileName, error: I18n('t_export_file_error_message') })
    } else {
      const assetsCsv = exportConfigurationData && exportConfigurationData.map((asset) => {
        return asset
      })
      import('xlsx').then(xlsx => {
        const a_asset = xlsx.utils.json_to_sheet(filterArrayOfObj(JSON.parse(JSON.stringify(assetsCsv))))
        const new_workbook = xlsx.utils.book_new()
        xlsx.utils.book_append_sheet(new_workbook, a_asset, 'Export Configuration Data')
        xlsx.writeFile(new_workbook, exportFileName.value)
      })
      props.onHideModel()
    }
  }

  return (
    <div className={'nzc-model-content'}>
      {NZCExportConfigurationDataLoading ? (
        <NZCModelSkeleton />
      ) : (
        <div className={'nzc-model-content__nzc-create-model'}>
          <p className={'create-model-para'}>{props.assetLevel ? I18n('t_export_model_explanation_text_asset') : I18n('t_export_model_explanation_text')}</p>
          <div className="nzc-model-content__nzc-create-model--create-model-input">
            <div className={'nzc-model-content__nzc-create-model--create-model-input--input-name'}>
              <div className="nzc-model-content__nzc-create-model--create-model-input--input-name--input-name-label">{I18n('t_name')}</div>
              <InputText
                value={exportFileName.value}
                id='name'
                data-testid="export-name-field"
                name='name'
                onChange={handleChange}
                className={'nzc-model-content__nzc-create-model--create-model-input--input-text-name'}
              />
              {exportFileName.error && <div data-testid="error-message" className="p-error">{exportFileName.error}</div>}
            </div>
          </div>
        </div>
      )}
      <div className={'border-line'} />
      <div className='padding-bottom-3'>
        <Button data-testid={'nzc-export'} label={I18n('t_export')} disabled={exportFileName.error} onClick={handleCSVExport} className="common-blue-bg-btn open-button button-highlight" />
      </div>
    </div>
  )
}

export default NzcExportModel