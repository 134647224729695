import React from 'react'
import { useHistory } from 'react-router-dom'

import I18n from '../../../utils/i18n/I18n'
import Footer from '../../common/footer/Footer'
import './AuthError.scss'

const AuthError = () => {
  const history = useHistory()

  return (
    <div className="grey-bg min-width-760">
      <div className="display gutter">
        <div className="container-box">
          <h1>{I18n('t_page_auth_error')}</h1>
          <p>{I18n('t_page_auth_error_reason',{contactEvora: <a className='contact-link' href=''>{I18n('t_Contact_Evora')}</a>})}</p>
          <button className="blue-btn p-button p-component" onClick={() => history.push('/login', {replace: true})}>{I18n('t_back_to_login')}</button>
        </div>
      </div>
      <div className="footer-wrapper">
        <Footer />
      </div>
    </div>
  )
}
export default AuthError