import {Button as PrimeButton} from 'primereact/button'
import PropTypes from 'prop-types'
import './Button.scss'
import {forwardRef} from 'react'
import Text from '../../atomic/Text/Text'

const getCssClass = (className, content, colour) => {
  let cssClass = ['siera-button']
  if(className) {
    cssClass.push(className)
  }
  if(colour) {
    cssClass.push(colour)
  }
  if(!content) {
    cssClass.push('just-icon')
  }

  return cssClass.join(' ')
}

const getTextColour = (outlined, buttonColour) => {
  let labelColour = 'white'
  if(outlined) {
    labelColour = 'teal'
    if(buttonColour === 'red') {
      labelColour = 'red'
    }
  }
  
  return labelColour
}
/**
 * Primary UI component for user interaction
 */
const Button = forwardRef((
  {
    content,
    contentDynamic,
    icon,
    iconPosition,
    className,
    size,
    colour,
    outlined,
    loading,
    disabled,
    onClick,
    onKeyDown,
    type,
    testId
  },
  ref) => {
  return (
    <PrimeButton
      className={getCssClass(className, content, colour)}
      size={size}
      outlined={outlined}
      disabled={disabled || loading}
      onClick={onClick}
      onKeyDown={onKeyDown}
      data-testid={testId}
      type={type}
      ref={ref}
    >
      {loading && <span className='pi pi-spin pi-spinner mr-2'></span>}
      {!loading && iconPosition === 'left' && icon}
      {content && 
        <Text 
          content={content}
          contentDynamic={contentDynamic}
          colour={getTextColour(outlined, colour)} 
        />
      }
      {iconPosition === 'right' && icon}
    </PrimeButton>
  )
})

export default Button

Button.propTypes = {
  /** The translation id (ie. 't_assets').<br />
   * Entire list of keys can be found here: src\resources\messages\en-GB.js.<br />
   * For scenarios where translation is not necessary (email addresses, asset/meter/fund/user names, etc) we just pass the raw data as the content.
   */
  content: PropTypes.string,
  /**
   * Accepts an object with any number of items. <br /> 
   * The key/value pairs will then be inserted in the specified place in the string.
   */
  contentDynamic: PropTypes.object,
  /**
   * 	Defines the colour of the button
   */
  colour: PropTypes.oneOf(['teal', 'red']),
  /**
   * 	Defines the size of the button
   */
  size: PropTypes.oneOf(['regular', 'small']),
  /**
   * Optional: Icon content of the button. Example `<Icon id='target' />`
   */
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /**
   * Optional: Icon position to the left or right of the button's label' />`
   */
  iconPosition: PropTypes.oneOf(['left', 'right']),
  /**
   * Optional: Pass outlined as true to switch the button style to outlined
  */
  outlined: PropTypes.bool,
  /**
   * Optional: Pass disabled true to disable the button
   */
  disabled: PropTypes.bool,
  /**
   * Optional: Pass loading state to display loading spinner
   */
  loading: PropTypes.bool,
  /**
  * Callback function that gets invoked when the user clicks the button
  */
  onClick: PropTypes.func,
  /**
  * Callback function that gets invoked when the user clicks any key on the keyboard
  */
  onKeyDown: PropTypes.func,
  /**
   * Optional: The testId to add to the button
   */
  testId: PropTypes.string,
  /**
   * Optional: The additional css class name to add to the button
   */
  className: PropTypes.string,
  /**
   * Optional: The type of the button, ex: submit, button, reset
   */
  type: PropTypes.string
}

Button.defaultProps = {
  colour: 'teal',
  size: 'regular',
  iconPosition: 'left'
}

Button.displayName = 'Button'