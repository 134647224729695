import React from 'react'
import I18n from '../../../../utils/i18n/I18n'
import {Checkbox} from 'primereact/checkbox'
import {Dropdown} from 'primereact/dropdown'
import cn from 'classnames'
import {getScopeTPName} from '../net-zero-carbon/asset-nzc-utils/asset-nzc-utils'

/* eslint-disable react/display-name */
const CustomTPViews = ({ tpIconViewHandler, getViewIconClassName, isCreateOrEditMode, tabExpanded, targetPathwayNumbersArray,
  value, options, tabIndex, progressTargetYearFunction, strandingYear, tpName, tpIconEditHandler, tpIconDeleteHandler,
  selectedTP, handleShowProgressTowardsTargetCheckboxSelection, progressTowardsTargetYearOptions, handleProgressTowardsTargetDropdown,
  getDropdownItemTemplate, year, checkboxStatus }) => {
  const PanelHeaderTemplate = React.memo(({ options,tabIndex,progressTargetYearFunction,strandingYear, tpName }) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-right' : 'pi pi-chevron-up'
    const className = `${options.className} justify-content-start`
    const showProgressTargetYear = !isCreateOrEditMode() && !tabExpanded && targetPathwayNumbersArray.length>0 && (getScopeTPName(tabIndex) === selectedTP)
    return(
      <div className={cn(`panel-header ${className}`)} role="target-pathway-modification-options-container">
        <button className={cn(options.togglerClassName)} onClick={options.onTogglerClick}>
          <span className={cn(toggleIcon)} />
        </button>
        <span className={'header-title'} role='target-pathway-name'>{tpName}</span>
        <i className={cn(getViewIconClassName(tabIndex))} onClick={(e)=>tpIconViewHandler(e, tabIndex)} role='target-pathway-eye'/>
        <i className="pencil-2-line" onClick={(e) => tpIconEditHandler(e, tabIndex)} role='target-pathway-edit'/>
        <i className="trash-3-line" onClick={(e) => tpIconDeleteHandler(e, tabIndex)} role ='target-pathway-delete' />
        <div className='line-break' />
        {!isNaN(strandingYear) && <span className={'tp-stranding-year'}>{I18n('t_tp_stranding_year')}{strandingYear}</span>}
        <div className='line-break' />
        {showProgressTargetYear ? progressTargetYearFunction : null}
      </div>
    )
  })

  const ProgressTargetYear = React.memo(({ year, checkboxStatus }) => {
    return <div className={cn('progress-towards-target')} data-testid='progress-towards-target'>
      <Checkbox inputId="progress-towards-target-checkbox"
        checked={checkboxStatus}
        className={'progress-towards-target-checkbox'} name={'show-progress-towards-target-checkbox'}
        onChange={handleShowProgressTowardsTargetCheckboxSelection}/>
      <label htmlFor={'progress-towards-target-checkbox'} className='progress-towards-target-label' data-testid='show_progress'>{I18n('t_show_progress_towards_target_for')}</label>
      <Dropdown
        className={'progress-towards-target-dropdown'}
        itemTemplate={getDropdownItemTemplate}
        panelClassName={'dropdown-overlay-panel-white p-dropdown-panel-no-top-border target-pathway-dropdown'}
        name="progress-towards-target-dropdown"
        placeholder={year}
        disabled={!checkboxStatus}
        onChange={handleProgressTowardsTargetDropdown}
        options={progressTowardsTargetYearOptions}
        value={year}
      />
    </div>
  })

  const returnView = (value) => {
    switch (value) {
    case 1: return <PanelHeaderTemplate options={options} tabIndex={tabIndex} progressTargetYearFunction={progressTargetYearFunction} strandingYear={strandingYear} tpName={tpName} />
    case 2: return <ProgressTargetYear year={year} checkboxStatus={checkboxStatus} />
    default: return
    }
  }
  return (
    returnView(value)
  )
}

export default CustomTPViews