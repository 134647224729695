import React, {useState} from 'react'
import './DqMeterModal.scss'
import {Dialog} from 'primereact/dialog'
import {Button} from 'primereact/button'
import {Column} from 'primereact/column'
import {DataTable} from 'primereact/datatable'
import warningIcon from '../../../../../resources/images/icon/warning-red.svg'
import {meterUtilityUnits} from '../../../../../utils/helpers/Constants'
import {formatNumWODecimal, trucateNumWODecimal} from '../../../../../services/common/formatter'
import {getLocalizedValue} from '../../../../../utils/helpers/Helper'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

function DqMeterModal(props) {
  //region Declarations/Initializations
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const STR_EMPTY=''
  const meterModalData=props.meterModalData
  const meterModalParams=props.dqMeterModalParams
  const checkboxIconSelectorClasses='.dq-meter-modal-content .meter-modal-datatable .p-datatable-thead th.p-selection-column div.p-checkbox-box span.p-checkbox-icon'
  const [selectedMeterData, setSelectedMeterData] = useState([])
  //endregion

  //region Helper methods
  function getLocalisedMsg(msgKey) {
    msgKey=msgKey.toLowerCase()
    if(msgKey==='missing'){
      msgKey='missing_tc'
    }
    return getLocalizedValue(selectedLanguage, `t_${msgKey}`)
  }

  function getMeterModalTitle(){
    let modalTitle=STR_EMPTY
    if(meterModalParams){
      modalTitle=`(${meterModalParams.electricityMPAN})`
      if(meterModalParams.meterComment){
        modalTitle=`${meterModalParams.meterComment} (${meterModalParams.electricityMPAN})`
      }
    }
    return modalTitle
  }

  const selectedMeterDataNotEmpty=()=>selectedMeterData && selectedMeterData.length>0

  const meterModalDataNotEmpty=()=>meterModalData && meterModalData.length>0

  function handleSelectionWithFilledConsumption(newSelectionExceptFilledConsumption, checkboxOffsetParent) {
    const cssClassPiCheck = 'pi-check'
    const cssClassPi = 'pi'
    if (newSelectionExceptFilledConsumption) {
      const globalCheckboxIconNode = document.querySelector(checkboxIconSelectorClasses)
      const meterModalDataLength = meterModalDataNotEmpty() ? meterModalData.length : null
      const dataExceptFilledConsumption = meterModalData.filter(selection => !selection.isConsumption)
      if (newSelectionExceptFilledConsumption.length !== meterModalDataLength) {
        if (globalCheckboxIconNode) {
          if (checkboxOffsetParent && checkboxOffsetParent.tagName === 'TH') {
            // Handling select/unselect all event with FilledConsumption.
            if (globalCheckboxIconNode.classList.contains(cssClassPiCheck)) {
              globalCheckboxIconNode.classList.remove(cssClassPi)
              globalCheckboxIconNode.classList.remove(cssClassPiCheck)
              newSelectionExceptFilledConsumption = []
            } else {
              globalCheckboxIconNode.classList.add(cssClassPi)
              globalCheckboxIconNode.classList.add(cssClassPiCheck)
            }
          } else if (newSelectionExceptFilledConsumption.length === dataExceptFilledConsumption.length || newSelectionExceptFilledConsumption.length > 0) {
            // Row select event with FilledConsumption .
            globalCheckboxIconNode.classList.add(cssClassPi)
            globalCheckboxIconNode.classList.add(cssClassPiCheck)
          } else if (newSelectionExceptFilledConsumption.length === 0) {
            // Row unselect event FilledConsumption.
            globalCheckboxIconNode.classList.remove(cssClassPi)
            globalCheckboxIconNode.classList.remove(cssClassPiCheck)
          }
        }
      }
    }
    setSelectedMeterData(newSelectionExceptFilledConsumption)
  }

  const onMeterDataSelectionChange = (evt) => {
    let newSelection = evt.value
    let newSelectionExceptFilledConsumption = newSelection.filter(selection => !selection.isConsumption)
    handleSelectionWithFilledConsumption(newSelectionExceptFilledConsumption, evt.originalEvent.target.offsetParent)
  }

  const onShowSelectionCheckbox = (rowData) => {
    if(rowData.estimate === 'Missing'){
      return true
    }
  }

  function getHeaderCheckboxShowCssClass() {
    let checkboxShowCssClass=''
    if(meterModalData){
      const dataGap=meterModalData.find(({isConsumption})=>isConsumption===false)
      if(dataGap){
        checkboxShowCssClass='dq-meter-modal-header-checkbox-show'
      }
    }
    return checkboxShowCssClass
  }

  //endregion

  //region Custom templates
  function getLabelFloorArea() {
    //Measurement Unit: [M2, FT2]
    const floorAreaUnitTypes={
      M2: <span>m<sup>2</sup></span>,
      FT2: <span>ft<sup>2</sup></span>,
    }

    let floorArea = STR_EMPTY
    if (meterModalParams) {
      if(meterModalParams.floorArea){
        floorArea = formatNumWODecimal(selectedLanguage,trucateNumWODecimal(meterModalParams.floorArea))
      }
      if(meterModalParams.floorAreaType){
        floorArea = <span>{floorArea}{floorAreaUnitTypes[meterModalParams.floorAreaType]}</span>
      }
    }
    return <span>{floorArea}</span>
  }

  function getLabelAreaCovered() {
    let areaCovered = STR_EMPTY
    if (meterModalParams && meterModalParams.areaCovered) {
      areaCovered = meterModalParams.areaCovered
    }
    return <span>{areaCovered?getLocalisedMsg(areaCovered):areaCovered}</span>
  }

  function getHeaderLabelUtility() {
    let headerLabelUtility=STR_EMPTY
    if (meterModalParams) {
      if(meterModalParams.utility){
        headerLabelUtility=<span>{getLocalisedMsg(meterModalParams.utility)}</span>
      }
      if(meterModalParams.provider){
        headerLabelUtility=<span>{`${getLocalisedMsg(meterModalParams.utility)} - ${meterModalParams.provider}`}</span>
      }
    }
    return headerLabelUtility
  }

  function getDqMeterModalHeaderElement(){
    return (
      <>
        <div className="dq-meter-modal-header-title">{getMeterModalTitle()}</div>
        <div className="dq-meter-modal-header-labels">
          <div className="header-label label-util">{getHeaderLabelUtility()}</div>
          <div className="header-label label-floor">{getLabelFloorArea()} <span className="label-floor-area">{getLocalisedMsg( 'floor_area')}</span></div>
          <div className="header-label label-area">{getLabelAreaCovered()}</div>
        </div>
      </>
    )
  }

  function getDqMeterModalFooterElement(){
    return (
      <>
        <div className="dq-meter-modal-footer" style={{visibility: meterModalDataNotEmpty()?'visible': 'hidden'}}>
          <Button className="btn-request footer-btn" disabled={!selectedMeterDataNotEmpty()} onClick={()=>props.requestDataHandler(selectedMeterData)}>{getLocalisedMsg( 'request_data')}</Button>
          <Button className="btn-enter footer-btn" disabled={!selectedMeterDataNotEmpty()} onClick={()=> props.enterDataHandler(selectedMeterData)}>{getLocalisedMsg( 'btn_enter')}</Button>
        </div>
      </>
    )
  }

  // Table body templates.
  const CHAR_DASH='-'
  const dateSummaryBodyTemplate = (rowData) => {
    return rowData.dateSummary ? rowData.dateSummary : CHAR_DASH
  }

  const estimateBodyTemplate = (rowData) => {
    let msgKeyEstimate
    if (rowData.estimate) {
      msgKeyEstimate = rowData.estimate.toLowerCase()
      msgKeyEstimate = 'estimate' === msgKeyEstimate ? 'estimated' : 'missing' === msgKeyEstimate ? rowData.estimate : msgKeyEstimate
    } else {
      msgKeyEstimate = CHAR_DASH
    }
    return <span>{getLocalisedMsg(msgKeyEstimate)}</span>
  }

  const sourceBodyTemplate = (rowData) => {
    return rowData.source ? rowData.source : CHAR_DASH
  }

  const consumptionBodyTemplate = (rowData) => {
    let consumptionBodyValue
    if (rowData.estimate && 'missing' === rowData.estimate.toLowerCase()) {
      consumptionBodyValue = <img src={warningIcon} alt="missing data"/>
    } else {
      if (rowData.consumption) {
        consumptionBodyValue = formatNumWODecimal(selectedLanguage, trucateNumWODecimal(rowData.consumption))
        if (rowData.estimate.toLowerCase() === 'estimate') {
          consumptionBodyValue = <span>{`${consumptionBodyValue} ${meterUtilityUnits[rowData.utility]} (EST)`}</span>
        } else {
          consumptionBodyValue = `${consumptionBodyValue} ${meterUtilityUnits[rowData.utility]}`
        }
      } else {
        consumptionBodyValue = CHAR_DASH
      }
    }
    return consumptionBodyValue
  }

  function getHeaderNode(header){
    return (
      <span>{getLocalisedMsg(header)}</span>
    )
  }

  function getMeterDataTable(){
    return (
      <DataTable
        value={meterModalData}
        selection={selectedMeterData}
        onSelectionChange={e => onMeterDataSelectionChange(e) }
        showSelectionElement={rowData => onShowSelectionCheckbox(rowData)}
        scrollable
        scrollHeight="16.813rem"
        className={`meter-modal-datatable ${getHeaderCheckboxShowCssClass()}`}
      >
        <Column field="dateSummary" body={dateSummaryBodyTemplate} header={getHeaderNode('date')} style={{width: '15%'}} />
        <Column field="estimate" body={estimateBodyTemplate} header={getHeaderNode('data')} style={{width: '20%'}} />
        <Column field="source" body={sourceBodyTemplate} header={getHeaderNode('source')} style={{width: '20%'}} />
        <Column field="consumption" body={consumptionBodyTemplate} header={getHeaderNode('consumption')} style={{width: '40%'}} />
        <Column selectionMode="multiple" headerStyle={{width: '5%'}} style={{width: '5%'}} />
      </DataTable>
    )
  }

  const getNodataElement=()=><div className="no-missing-data-container">
    <span className="data-quality-table-msg">{getLocalisedMsg('no_records_found')}</span>
  </div>

  //endregion

  return (
    <Dialog
      className="dq-meter-dialog"
      visible={props.showDialog}
      header={getDqMeterModalHeaderElement()}
      onHide={props.onHideHandler}
      footer={getDqMeterModalFooterElement}
      blockScroll
    >
      <div className="dq-meter-modal-content" data-testid={'dq-modal-data'}>
        {meterModalData && meterModalData.length>0? getMeterDataTable() : getNodataElement()}
      </div>
    </Dialog>
  )
}

export default DqMeterModal