import React from 'react'
import {Skeleton} from 'primereact/skeleton'
import './PerformanceEventActionsSkeleton.scss'

const PassportActionsGridSkeleton = () => {
  return (
    <div className="custom-skeleton p-rel">
      <div className="flex contour">
        <Skeleton shape="square" size="1rem"/>
        <Skeleton width="30%"/>
        <Skeleton width="12%"/>
        <Skeleton width="12%"/>
        <Skeleton width="30%"/>
      </div>
    </div>
  )
}

export default PassportActionsGridSkeleton