import React from 'react'
import {Skeleton} from 'primereact/skeleton'
import './DataCompletenessSkeleton.scss'

const DataCompletenessSkeleton = () => {
  const getSkeletonLegend = (index) => (
    <div className='dq-completeness-skeleton__legends--legend' key={index}>
      <Skeleton width="10px" borderRadius="3px" height="10px" />
      <Skeleton width="120px" borderRadius="3px" height="10px" />
    </div>
  )

  return (
    <div className='dq-completeness-skeleton'>
      <Skeleton width="198px" height="15px" />
      <Skeleton width="100%" borderRadius="3px" height="20px" />
      <div className='dq-completeness-skeleton__legends'>
        {Array.from({ length: 4 }).map((_, index) => (
          getSkeletonLegend(index)
        ))}
      </div>
    </div>
  )
}

export default DataCompletenessSkeleton