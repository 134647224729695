import { SessionUserState } from '../../../../../utils/helpers/Helper'

export const storeGraphIntensity = (value, fundId) => {
  let savedGraphIntensity
  if (SessionUserState.getUserDataFromSession('graphIntensity')) {
    savedGraphIntensity = SessionUserState.getUserDataFromSession('graphIntensity')
    savedGraphIntensity = {
      ...savedGraphIntensity,
      [fundId]: value
    }
    SessionUserState.saveUserDataToSession('graphIntensity', savedGraphIntensity)
  } else {
    SessionUserState.saveUserDataToSession('graphIntensity', { [fundId]: value })
  }
}

/**
 * Store the asset intensity in session storage
 *
 * @param {string} value to get asset intensity value
 * @param {number} assetId to get the id
 */
export const storeAssetGraphIntensity = (value, assetId) => {
  let savedAssetGraphIntensity
  if (SessionUserState.getUserDataFromSession('assetGraphIntensity')) {
    savedAssetGraphIntensity = SessionUserState.getUserDataFromSession('assetGraphIntensity')
    savedAssetGraphIntensity = {
      ...savedAssetGraphIntensity,
      [assetId]: value
    }
    SessionUserState.saveUserDataToSession('assetGraphIntensity', savedAssetGraphIntensity)
  } else {
    SessionUserState.saveUserDataToSession('assetGraphIntensity', { [assetId]: value })
  }
}

export const returnDropdownValue = (graphIntensity, graphOptions) => {
  const graphDropDownValue = graphOptions.find((option) => option.value === graphIntensity)
  return graphDropDownValue
}