import axiosInstance from '../common/axios-config'

export const esAcceptPendingAccountSharingRequest = async (accountId) => {
  const {data} = await axiosInstance.get(`/api/v1/EnergyStar/AcceptPendingAccountSharingRequests/${accountId}`)
  return data
}

export const esSharePropertiesValidate = async () => {
  const {data} = await axiosInstance.get('/api/v1/EnergyStar/PropertySharingRequestExist')
  return data
}

export const esPropertyMatchWithSieraPlus = async () => {
  const {data} = await axiosInstance.get('/api/v1/EnergyStar/EnergyStarPropertyMatchWithSieraProperty')
  return data
}

export const esPropertyAddToSieraPlus = async (properties) => {
  const {data} = await axiosInstance.post('/api/v1/EnergyStar/AcceptMatchedPendingPropertySharingRequest',properties)
  return data
}

export const esMatchedSieraPropertiesInfo = async () => {
  const {data} = await axiosInstance.get('/api/v1/EnergyStar/MatchedSieraPropertiesInfo')
  return data
}

export const removeEsSuccessfulMatch = async (energyStarPropertyId) => {
  const {data} = await axiosInstance.put(`/api/v1/EnergyStar/DiscontinueUseOfEnergyStarProperty/${energyStarPropertyId}`)
  return data
}

export const resetEsConnection = async () => {
  const {data} = await axiosInstance.delete('/api/v1/EnergyStar/ResetEnergyStarAccountIntegration')
  return data
}

export const esForceSyncGenerateMetricsReport = async () => {
  const {data} = await axiosInstance.get('/api/v1/EnergyStar/ForceSync/GenerateMetricsReport')
  return data
}

export const esGenerateMetricsReport = async (propertyIds) => {
  const {data} = await axiosInstance.post('/api/v1/EnergyStar/GenerateMetricsReport', propertyIds)
  return data
}

export const updateEnergyStarMetrics = async (reportId) => {
  const {data} = await axiosInstance.put(`/api/v1/EnergyStar/UpdateEnergyStarMetrics/${reportId}`)
  return data
}

export const esMetricsReportStatus = async (requestParams) => {
  const {data} = await axiosInstance.get(`/api/v1/EnergyStar/reports/${requestParams.queryKey[1]}/MetricsReportStatus`)
  return data
}
