import React from 'react'
import './SpinningLoader.scss'

const SpinningLoader = ({size}) => {
  return (
    <span style={{width: size, height: size}} className='loading-spinner'/>
  )
}

export default SpinningLoader
