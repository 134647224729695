import PropTypes from 'prop-types'
import {dashboardActionsStatusList} from '../../hooks-and-helpers/dashboardHelper'
import I18n from '../../../../../../utils/i18n/I18n'
import './ActionPlanTile.scss'

const ActionPlanTile = ({adbActions}) => {
  function getActonPlanContent() {
    const actionsCountObj = {
      completed: adbActions?.filter((item) => {return item.action.status === 'Completed'}).length,
      agreed: adbActions?.filter((item) => {return item.action.status === 'AgreedActions'}).length,
      applicable: adbActions?.filter((item) => {return item.action.status === 'Applicable'}).length,
      underReview: adbActions?.filter((item) => {return item.action.status === 'ActionsUnderReview'}).length,
      overdue: adbActions?.filter((item) => {return item.action.status === 'Overdue'}).length
    }

    return dashboardActionsStatusList.map((item) => {
      return <div className={`action-plan-tile__breakdown__items__item ${item.className}`} key={item.key}>
        <span className="`action-plan-tile__breakdown__items__item__label">{item.label}: </span>
        <span className="`action-plan-tile__breakdown__items__item__value">{actionsCountObj[item.key]}</span>
      </div>
    })
  }

  return <div className="action-plan-tile">
    <span className='action-plan-tile__title'>{I18n('t_action_plan')}</span>
    <div className='action-plan-tile__breakdown'>
      <span className='action-plan-tile__breakdown__title'>{I18n('t_actions_breakdown')}</span>
      <div className="action-plan-tile__breakdown__items">
        {getActonPlanContent()}
      </div>
    </div>
  </div>
}

ActionPlanTile.propTypes = {
  adbActions: PropTypes.object
}

ActionPlanTile.displayName = 'ActionPlanTile'

export default ActionPlanTile