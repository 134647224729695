import React, {useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import I18n from '../../../../utils/i18n/I18n'
import {  bulkUpload,  consumptionUpload,  FUND_VIEWS_PAGES,
  LOCAL_STORAGE_KEYS,  topDownPathways} from '../../../../utils/helpers/Constants'
import './ConsumptionUploadHome.scss'
import ConsumptionUploadTemplate from '../consumption-upload-template/ConsumptionUploadTemplate'
import ConsumptionUploadStatus from '../consumption-upload-status/ConsumptionUploadStatus'
import {CachedUserState, createNavURLForFundPages} from '../../../../utils/helpers/Helper'
import {queryParamsForFundNzc} from '../../../../utils/signals/CommonSignals'
import {createQueryParams} from '../../../../utils/query-params/QueryParams'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const ConsumptionUploadHome = () => {
  const history = useHistory()
  const { type, routeVia } = useParams()
  const typeOfUpload = type || consumptionUpload
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const unitSystem = userInfo.unitSystem
  const learnMore = 'https://sieraglobal.zendesk.com/hc/en-gb/sections/4403780291089-Guides'
  const [uploadStatus, setUploadStatus] = useState({status: '', count: 0})
  function handleReturnClick() {
    const cachedUrlParamIds = CachedUserState.getUserDataFromCache(LOCAL_STORAGE_KEYS.cacheUrlParamsIdsData)
    const instanceName = cachedUrlParamIds?.instanceName
    const orgName = cachedUrlParamIds?.organisationName
    const fundName = CachedUserState.getUserDataFromCache(LOCAL_STORAGE_KEYS.selectedOrgAndFund)?.fundUrlName
    typeOfUpload === topDownPathways ?
      history.push({pathname : createNavURLForFundPages({ instanceName, orgName, fundName, pathSuffix: FUND_VIEWS_PAGES.netZeroCarbon }),state : {from:topDownPathways}, search : '?' + createQueryParams(queryParamsForFundNzc.value).toString()}):
      history.goBack()


  }

  return (
    <div className={'consumption-upload-home grey-bg'}>
      <div className="container-layout grey-bg gutter">
        <h1>{I18n(bulkUpload[typeOfUpload]?.title)}</h1>
        <div role={'consumption-user-message'} className={'consumption-upload-home__header'}>
          <span
            className={'consumption-upload-home__header--sub-text'}>{I18n(bulkUpload[typeOfUpload]?.subtext, {
              0: <a className={'link'} href={learnMore} target="_blank"
                rel="noreferrer noopener">{I18n('t_learn_more')}</a>
            })}</span>
        </div>
        {!uploadStatus.status ? <ConsumptionUploadTemplate history={history} setUploadStatus={setUploadStatus} typeOfUpload={typeOfUpload} routeVia={routeVia} />
          : <ConsumptionUploadStatus selectedLanguage={selectedLanguage} unitSystem={unitSystem} uploadStatus={uploadStatus} handleReturnClick={handleReturnClick} typeOfUpload={typeOfUpload} />}
      </div>
    </div>
  )
}

export default ConsumptionUploadHome