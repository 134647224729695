import React, {useState} from 'react'
import './AssetHome.scss'
import AssetHeader from '../asset-header/AssetHeader'
import {useHistory} from 'react-router-dom'
import CloseIcon from '../../../../../resources/images/icon/close-gray.svg'
import I18n from '../../../../../utils/i18n/I18n'

function AssetHome(props) {
  const navigate  = useHistory()
  const [activeIndexTab, setActiveIndexTab]=useState(0)
  const tabMenuOptions = [
    {label: I18n('t_asset_details'), value: 'Asset details'},
    {label: I18n('t_key_dates'), value: 'Key dates'},
    {label: I18n('t_epc'), value: 'EPC'},
    {label: I18n('t_users_groups'), value: 'Users & Groups'},
  ]

  function getFormContent(){
    let formContent
    if(activeIndexTab===0){
      formContent=props.getAssetFormContent(setActiveIndexTab)
    } else if(activeIndexTab===1){
      formContent=props.getAssetKeyDates(setActiveIndexTab)
    } else if(activeIndexTab===2){
      formContent=props.getAssetEpcFormContent(setActiveIndexTab)
    } else if(activeIndexTab===3) {
      formContent = <div>Users & Groups</div>
    }
    return formContent
  }

  function closeAddAsset() {
    navigate.goBack()
  }

  return (
    <div className={'add-asset-home grey-bg'} data-testid={'add-asset-home'}>
      <div className={'container-layout grey-bg gutter pt-40 pb-40'}>
        <div className={'form-view'} data-testid={'form-view'}>
          <div  className={'close-icon-container'}><img className="cursor" onClick={closeAddAsset} src={CloseIcon} alt="CloseIcon" /></div>
          <div className={'header-container'}>
            <AssetHeader assetHeaderConfig={{tabMenuOptions, headerTitle: props.configData.headerTitle}} activeIndex={activeIndexTab} setActiveIndex={setActiveIndexTab} />
          </div>
          <div className={'form-container pb-80'}>
            {getFormContent()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AssetHome