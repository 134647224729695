import React from 'react'
import Icons from '../../../resources/images/icons/icons-sprite.svg'
import PropTypes from 'prop-types'
import './Icon.scss'

export const ICONS_IDs = [
  'arrow_ascending',
  'arrow_descending',
  'arrow_down',
  'signal',
  'warning_solid_triangle_rounded',
  'arrow_up_outlined',
  'arrow_left',
  'arrow_right',
  'arrow_up',
  'attachment_clip',
  'battery',
  'building_education',
  'building_healthcare',
  'building_hotel',
  'building_industrial',
  'building_leisure',
  'building_mixed',
  'building_office',
  'building_other',
  'building_residential',
  'building_retail',
  'building_technology',
  'calendar',
  'calendar_checked',
  'caret_down',
  'caret_up',
  'check',
  'check_outline_circle',
  'check_solid_circle',
  'check_solid_square',
  'circle_outline',
  'circle_solid',
  'close',
  'close_solid_circle',
  'cog_wheel',
  'collapse_table',
  'columns',
  'download',
  'eye_closed',
  'eye_open',
  'expand_table',
  'external_link',
  'file_copy',
  'file_empty',
  'file_filled',
  'funnel',
  'graph',
  'graph_decrease',
  'help_outline',
  'help_solid_circle',
  'help_solid_square',
  'info_outline',
  'info_solid',
  'kebab_menu',
  'leaf',
  'passport',
  'pencil',
  'plus',
  'siera_icon',
  'siera_icon_outline',
  'solar_panel',
  'sort_ascending',
  'sort_default',
  'sort_descending',
  'speech_bubble',
  'square_solid',
  'target',
  'trash_can',
  'triangle_solid',
  'upload',
  'user',
  'warning_outline_circle',
  'warning_solid_circle',
  'warning_solid_triangle',
  'zoom',
  'success_tick',
  'filter_icon'
]

const getCssClass = (className, colour, id) => {
  let cssClass = [`icon icon-${id}`]
  if(className) {
    cssClass.push(className)
  }

  if(colour) {
    cssClass.push(colour)
  }

  return cssClass.join(' ')
}

const Icon = ({
  id, 
  colour, 
  size, 
  className, 
  testId,
  onClickHandler
}) => (
  <svg 
    data-testid={testId} 
    className={getCssClass(className, colour, id)}
    width={size} 
    height={size}
    onClick={onClickHandler}
  >
    <use href={`${Icons}#${id}`} xlinkHref={`${Icons}#${id}`} />
  </svg>
)

Icon.propTypes = {
  id: PropTypes.oneOf([...ICONS_IDs]).isRequired,
  colour: PropTypes.oneOf(['forest', 'forest_80', 'forest_40', 'forest_20', 'deep_ocean', 'red', 'green', 'green_light', 'orange', 'white', 'sand', 'blue']),
  size: PropTypes.oneOf([10, 15, 16, 17, 20, 24]),
  className: PropTypes.string,
  testId: PropTypes.string,
  onClickHandler: PropTypes.func
}

Icon.defaultProps = {
  colour: 'forest',
  size: 24,

}

export default Icon