import { useState, useContext, useEffect } from 'react'
import { TabView, TabPanel } from 'primereact/tabview'
import { getLocalizedValue } from '../../../../utils/helpers/LanguageUtils'
import CustomSidebar from '../../../common/custom-sidebar/CustomSidebar'
import AddEditAssetForm from './AddEditAssetForm'
import PeriodicReportingForm, { currencyOptions } from './PeriodicReportingForm'
import StaticReportingForm from './StaticReportingForm'
import PropTypes from 'prop-types'
import { FeatureFlagContext, ToastContext } from 'Contexts'
import { reportFrameworkOptions, reportYearOptionsV2 } from '../../funds/fund-reports/mappings'
import './AddEditAssetForm.scss'
import { useFormik, FormikProvider, Form } from 'formik'
import * as Yup from 'yup'
import { convertDateToUTCFormat } from '../../../../utils/helpers/Helper'
import { CURRENCY_UNITS, CURRENT_STATUS_DROPDOWN, MeasurementUnits } from '../../../../utils/helpers/Constants'
import { useQuery, useMutation } from 'react-query'
import { useParams, useHistory } from 'react-router-dom'
import { CheckFundReportDataReadiness } from 'services/funds/fund-reports-service'
import { Button, Dropdown, Icon, MultiSelect, Text, Tooltip } from 'components/atomic/index'
import { AddAssetFormContent, GetAssetFormContent, UpdateAssetFormContent } from 'services/assets/asset-form'
import { isEmptyObject } from 'utils/helpers/Utils'
import useFundIds from 'utils/custom-hooks/useFundIds'
import { v4 as uuidv4 } from 'uuid'
import I18n, { useI18n } from 'utils/i18n/I18n'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'
import useCustomState from 'components/common/custom-hooks/useCustomState'

const ASSET_DATA_FIELDS = ['GrossAssetValue', 'FossilFuelExposureDataField', 'WasteContracts', 'EPCWarning', 'PlanningPermission', 'PrimaryEnergyDemand', 'BiodiversityDataField', 'ResourceConsumptionDataField']

export const isDataFieldFailing = dataField => dataField?.length > 0

const AddEditAssetSidebar = (props) => {
  const {customState: currentAssetState, updateCustomState: updateCurrentAssetState} = useCustomState({assetNameDetails: null, assetReferenceDetails: null})
  const {loginState} = useLoginAuthContext()
  const selectedLanguage = loginState.userInfo.languagePreference
  const currencyUnit = loginState.userInfo.currencyUnit
  const { assetId } = useParams()
  const history = useHistory()
  const { fundId } = useFundIds(history)
  const {setIsSidebarVisible, framework, year, panelIndex, isSidebarVisible, isAssets, assetIdAlt, newestYear, oldestYear, fundName} = props
  const featureFlag = useContext(FeatureFlagContext)
  const sfdrFeatureFlag = featureFlag.featureState.sieraplus_sfdr
  const unitSystem = loginState.userInfo.unitSystem?.toLowerCase() === 'metric' ? MeasurementUnits.M2 : MeasurementUnits.FT2
  const intl = useI18n()
  const pastJulyBoolYear = new Date().getMonth() > 5 ? new Date().getFullYear() : new Date().getFullYear() - 1
  const [ yearVar, setYearVar ] = useState(year ? parseInt(year) : (pastJulyBoolYear))
  const [selectedFrameworks, setSelectedFrameworks] = useState([framework ?? reportFrameworkOptions[0].value])
  const [shouldFetchAssetData, setShouldFetchAssetData] = useState(true)
  const { toastDispatcher } = useContext(ToastContext)

  const [activeIndex, setActiveIndex] = useState(panelIndex ?? 0)
  
  const isAccessedFromElipsis = assetIdAlt === undefined || assetIdAlt === null
  const {
    data: dataReadiness,
  } = useQuery({
    queryKey: ['CheckFundReportDataReadiness', isAccessedFromElipsis ? assetId : assetIdAlt, yearVar, ASSET_DATA_FIELDS],
    queryFn: CheckFundReportDataReadiness,
  })
  
  const {
    data: assetFormContent
  } = useQuery({
    queryKey: ['GetAssetFormContent', isAccessedFromElipsis ? assetId : assetIdAlt],
    queryFn: GetAssetFormContent,
    enabled: !!assetId || !!assetIdAlt,
    onSuccess: () => {
      setShouldFetchAssetData(false)
    },
  })

  const assetDetailsFields = assetFormContent?.assetDetails
  const periodicReportingFields = assetFormContent?.periodicReporting
  const staticReportingFields = assetFormContent?.staticReporting

  useEffect(() => {
    if(!shouldFetchAssetData) {
      const fossilFuelExposures = periodicReportingFields?.fossilFuelExposures?.map((record) => {
        record.id = record.id ?? uuidv4()
        return record
      }) ?? []
      const wasteContracts = periodicReportingFields?.wasteContracts?.map((record) => {
        record.id = record.id ?? uuidv4()
        return record
      }) ?? []
      const assetValues = periodicReportingFields?.assetValues?.map((record) => {
        record.id = record.id ?? uuidv4()
        record.valueOption = record.valueOption ?? currencyOptions[0].value
        return record
      }) ?? []
  
      setFossilFuelExposureTimeline({
        values: fossilFuelExposures,
        initialValues: fossilFuelExposures,
        editModeRecordIds: [],
        valueErrors: [],
        dateErrors: [],
      })
      setWasteTimeline({
        values: wasteContracts,
        initialValues: wasteContracts,
        editModeRecordIds: [],
        valueErrors: [],
        dateErrors: [],
      })
      setGavTimeline({
        values: assetValues,
        initialValues: assetValues,
        editModeRecordIds: [],
        valueErrors: [],
        dateErrors: [],
      })
    }
  }, [shouldFetchAssetData])

  useEffect(() => {
    if(!shouldFetchAssetData) {
      setShouldFetchAssetData(true)
    }
  }, [assetIdAlt])

  const updateAssetForm = useMutation(UpdateAssetFormContent, {
    onSuccess: (data) => {
      setIsSidebarVisible(false)
      toastDispatcher({
        type: 'SUCCESS',
        title: 't_edit_asset_title',
        summary: <Text content='t_edit_asset_summary'/>
      })
    }
  })
  const addAssetForm = useMutation(AddAssetFormContent, {
    onSuccess: (data) => {
      setIsSidebarVisible(false)
      toastDispatcher({
        type: 'SUCCESS',
        title: 't_add_asset_success',
        summary: <Text content='t_add_asset_summary' />
      })
    }
  })

  const [fossilFuelExposureTimeline, setFossilFuelExposureTimeline] = useState({
    values: [],
    initialValues: [],
    editModeRecordIds: [],
    valueErrors: [],
    dateErrors: [],
  })
  const [wasteTimeline, setWasteTimeline] = useState({
    values: [],
    initialValues: [],
    editModeRecordIds: [],
    valueErrors: [],
    dateErrors: [],
  })
  const [gavTimeline, setGavTimeline] = useState({
    values: [],
    initialValues: [],
    editModeRecordIds: [],
    valueErrors: [],
    dateErrors: [],
  })
  const [occupancyTimeline, setOccupancyTimeline] = useState({
    values: [],
    initialValues: [],
    editModeRecordIds: [],
    valueErrors: [],
    dateErrors: [],
  })

  const onReportChange = (e) => {
    setSelectedFrameworks(e.value)
  }

  const onYearChange = (e) => {
    setYearVar(e.value)
  }

  const getFrameworkSelectionTemplate = () => {
    return (
      <div className='p-4'>
        <div className='grid'>
          <div className='col-6 pr-4'>
            <div className='flex flex-row'>
              <label className='my-2 flex align-items-center' htmlFor='currentFramework'>
                <Text content='t_report_data_checklist' size='s'/>
                <Icon id='info_solid' size={17} className='ml-2 framework-info-icon'/>
                <Tooltip target=".framework-info-icon" width={230}>
                  <Text content="t_report_data_checklist_info" />
                </Tooltip>
              </label>
            </div>
            <MultiSelect value={selectedFrameworks}
              onChange={onReportChange}
              options={reportFrameworkOptions}
              size='lg'
              optionLabel="label"
              showSelectAll={false}
              placeholderKey={getLocalizedValue(selectedLanguage, 't_select_framework')}
              testId='framework-multiselect'
            />
          </div>
          <div className="col-6 pr-3">
            <label className='my-2 flex align-items-center' htmlFor='reportingYear'>
              <Text content='t_reporting_year' size='s'/>
            </label>
            <Dropdown
              value={yearVar}
              onChange={onYearChange}
              name='reportingYear'
              size='lg'
              testId='reporting-year-dropdown'
              options={reportYearOptionsV2(oldestYear ?? 2017, newestYear ?? (new Date().getMonth() > 5 ? new Date().getFullYear() : new Date().getFullYear() - 1), intl.formatMessage)} 
            />
          </div>
        </div>
        {dataReadiness?.EPCWarning?.length > 0  && selectedFrameworks.includes('sfdr') && (
          <div className='epc-warning-container'>
            <Icon id='warning_solid_triangle' size={20} colour='orange'/>
            <Text content={`t_reports_${dataReadiness.EPCWarning[0]}_msg`} colour='orange' testId='asset-epc-warning'/>
          </div>
        )}
      </div>
    )
  }

  const sharedSaveFooter = (onSubmit) => {
    return ( 
      <div className='d-flex p-4'>
        <Button content={getLocalizedValue(selectedLanguage, 't_save_changes')} disabled={!isEmptyObject(formik.errors)} onClick={onSubmit} type="button" testId='save-asset-form' />
        <Button content={getLocalizedValue(selectedLanguage, 't_cancel')} onClick={() => setIsSidebarVisible(false)} className='ml-4' type="button" outlined />
      </div>
    )
  }

  const CURRENCY = [
    {label: 'GBP', value: 'GBP', key: CURRENCY_UNITS.BRITISHPOUND},
    {label: 'EUR', value: 'EUR', key: CURRENCY_UNITS.EUROS},
    {label: 'USD', value: 'USD', key: CURRENCY_UNITS.USDOLLAR}
  ]

  const DEFAULT_BUILDING_UTILITIES_VALUE = [    { label: ` ${getLocalizedValue(selectedLanguage, 't_electricity')}`, code: 'ignoreElectricityMaxCoverage' },
    { label: ` ${getLocalizedValue(selectedLanguage, 't_gas')}`, code: 'ignoreGasMaxCoverage' },
    { label: ` ${getLocalizedValue(selectedLanguage, 't_water')}`, code: 'ignoreWaterMaxCoverage'},]

  const BUILDING_UTILITIES_DROPDOWN_VALUE = [
    { label: ` ${getLocalizedValue(selectedLanguage, 't_electricity')}`, code: 'ignoreElectricityMaxCoverage' },
    { label: ` ${getLocalizedValue(selectedLanguage, 't_gas')}`, code: 'ignoreGasMaxCoverage' },
    { label: ` ${getLocalizedValue(selectedLanguage, 't_water')}`, code: 'ignoreWaterMaxCoverage'},
    { label: ` ${getLocalizedValue(selectedLanguage, 't_oil')}`, code: 'ignoreOilMaxCoverage' },
    { label: ` ${getLocalizedValue(selectedLanguage, 't_util_heating')}`, code: 'ignoreDHMaxCoverage' },
    { label: ` ${getLocalizedValue(selectedLanguage, 't_util_cooling')}`, code: 'ignoreDCMaxCoverage' }
  ]
  
  function updateCoverage(selectedCoverage) {
    const coverage = BUILDING_UTILITIES_DROPDOWN_VALUE.map(option => selectedCoverage[option.code] && ({
      ...option
    })).filter(Boolean)
    return coverage
  }

  const gavObject = CURRENCY.find((e) => e.key === currencyUnit)
  const currentDate = new Date()

  const genericNumberErrorMessage = getLocalizedValue(selectedLanguage, 't_generic_number_message')

  const validationSchema = Yup.object().shape({
    planningPermissionDate: Yup.date()
      .nullable(true)
      .test({
        name: 'before-year-built',
        message: getLocalizedValue(selectedLanguage, 't_planning_permission_before_year_built'),
        test(value, ctx) {
          const { yearBuilt } = ctx.parent
          if(yearBuilt && yearBuilt !== '' && value && value !== '') {
            return new Date(value).getFullYear() <= yearBuilt
          }
          return true
        }
      }),
    //StaticReportingValidations
    ped: Yup.number(genericNumberErrorMessage)
      .nullable(true)
      .positive(I18n('t_generic_positivenumber_message', {field: getLocalizedValue(selectedLanguage, 't_ped')}))
      .typeError(I18n('t_generic_number_message', {field: getLocalizedValue(selectedLanguage, 't_ped')})),
    nzebThreshold: Yup.number(genericNumberErrorMessage)
      .nullable(true)
      .positive(I18n('t_generic_positivenumber_message', {field: getLocalizedValue(selectedLanguage, 't_nzeb_threshold')}))
      .typeError(I18n('t_generic_number_message', {field: getLocalizedValue(selectedLanguage, 't_nzeb_threshold')})),
    totalHorizontalArea: Yup.number(genericNumberErrorMessage)
      .nullable(true)
      .positive(I18n('t_generic_positivenumber_message', {field: getLocalizedValue(selectedLanguage, 't_total_horizontal_area')}))
      .typeError(I18n('t_generic_number_message', {field: getLocalizedValue(selectedLanguage, 't_total_horizontal_area')})),
    totalVerticalArea: Yup.number(genericNumberErrorMessage)
      .nullable(true)
      .positive(I18n('t_generic_positivenumber_message', {field: getLocalizedValue(selectedLanguage, 't_total_vertical_area')}))
      .typeError(I18n('t_generic_number_message', {field: getLocalizedValue(selectedLanguage, 't_total_vertical_area')})),
    horizontalArtificial: Yup.number(genericNumberErrorMessage)
      .nullable(true)
      .positive(I18n('t_generic_positivenumber_message', {field: getLocalizedValue(selectedLanguage, 't_horizontal_artificial')}))
      .max(Yup.ref('totalHorizontalArea'), getLocalizedValue(selectedLanguage, 't_horizontal_artificial_less_than_total_message'))
      .typeError(I18n('t_generic_number_message', {field: getLocalizedValue(selectedLanguage, 't_horizontal_artificial')})),
    verticalArtificial: Yup.number(genericNumberErrorMessage)
      .nullable(true)
      .positive(I18n('t_generic_positivenumber_message', {field: getLocalizedValue(selectedLanguage, 't_vertical_artificial')}))
      .max(Yup.ref('totalVerticalArea'),  getLocalizedValue(selectedLanguage, 't_vertical_artificial_less_than_total_message'))
      .typeError(I18n('t_generic_number_message', {field: getLocalizedValue(selectedLanguage, 't_vertical_artificial')})),
    //AddEditValidations
    totalTonnes: Yup.number(genericNumberErrorMessage)
      .nullable(true)
      .positive(I18n('t_generic_positivenumber_message', { field: getLocalizedValue(selectedLanguage, 't_total_tonnes') }))
      .typeError(I18n('t_generic_number_message', { field: getLocalizedValue(selectedLanguage, 't_total_tonnes') }))
      .test({
        name: 'required-if-filled',
        message: getLocalizedValue(selectedLanguage, 't_cannot_be_empty'),
        test(value, ctx) {
          const { sustainableTonnes, endDate, startDate } = ctx.parent
          return !(sustainableTonnes === 0 || sustainableTonnes ||  endDate || startDate) || !!value
        }
      }),
    sustainableTonnes: Yup.number(genericNumberErrorMessage)
      .nullable(true)
      .min(0, I18n('t_generic_positivenumber_message', { field: getLocalizedValue(selectedLanguage, 't_sustainable_tonnes') }))
      .typeError(I18n('t_generic_number_message', {field: getLocalizedValue(selectedLanguage, 't_sustainable_tonnes')}))
      .test({
        name: 'required-if-filled',
        message: getLocalizedValue(selectedLanguage, 't_cannot_be_empty'),
        test(value, ctx) {
          const { totalTonnes, endDate, startDate } = ctx.parent
          const isAnyFieldFilled = totalTonnes || endDate || startDate || totalTonnes === 0
          if (isAnyFieldFilled) {
            return value !== null && value !== undefined
          }
          return true
        }
      })
      .when(['totalTonnes'], {
        is: (totalTonnes) => totalTonnes !== null && totalTonnes !== undefined,
        then: Yup.number().required(getLocalizedValue(selectedLanguage, 't_cannot_be_empty'))
          .max(Yup.ref('totalTonnes'), getLocalizedValue(selectedLanguage, 't_sustainable_weight_greater_than_total_weight')).nullable(true),
        otherwise: Yup.number().nullable(true)
      }),
    endDate: Yup.date()
      .nullable(true)
      .max(currentDate, getLocalizedValue(selectedLanguage, 't_future_date_error'))
      .test({
        name: 'required-if-filled',
        message: getLocalizedValue(selectedLanguage, 't_cannot_be_empty'),
        test(value, ctx) {
          const { sustainableTonnes, totalTonnes, startDate } = ctx.parent
          return !(sustainableTonnes === 0 || sustainableTonnes || totalTonnes || totalTonnes === 0 || startDate) || !!value
        }
      })
      .when(['startDate'], {
        is: (startDate) => startDate !== null && startDate !== undefined,
        then: Yup.date().required(getLocalizedValue(selectedLanguage, 't_cannot_be_empty'))
          .min(Yup.ref('startDate'), getLocalizedValue(selectedLanguage, 't_end_date_after_start_date')),
        otherwise: Yup.date().nullable(true)
      }),
    startDate: Yup.date()
      .nullable(true)
      .max(Yup.ref('endDate'), getLocalizedValue(selectedLanguage, 't_start_date_before_end_date'))
      .test({
        name: 'required-if-filled',
        message: getLocalizedValue(selectedLanguage, 't_cannot_be_empty'),
        test(value, ctx) {
          const { sustainableTonnes, totalTonnes, endDate } = ctx.parent
          return !(sustainableTonnes === 0 || sustainableTonnes ||  totalTonnes || totalTonnes === 0 || endDate) || !!value
        }
      }),
    estimatedCpa: Yup.boolean().nullable(true),
    yearBuilt:Yup.number()
      .nullable(true)
      .min(1800,getLocalizedValue(selectedLanguage,'t_past_year_error'))
      .max(new Date().getFullYear(),getLocalizedValue(selectedLanguage,'t_future_year_error'))
      .integer(getLocalizedValue(selectedLanguage, 't_invalid_year_error'))
      .typeError(getLocalizedValue(selectedLanguage, 't_invalid_year_error')),
    gia: Yup.lazy((value, schema) =>
      !value && !schema.parent.nla && !schema.parent.estimatedCpa
        ? Yup.number().required(getLocalizedValue(selectedLanguage, 't_gia_nla_required_message'))
        : Yup.number().nullable(true)
          .when(['nla', 'cpa', 'estimatedCpa'], {
            is: (nla, cpa, estimatedCpa) => !estimatedCpa && nla,
            then: Yup.number().test({
              name: 'gia',
              test: function (value, ctx) {
                const { nla, cpa = 0 } = ctx.parent
                const isValid = value === nla + cpa
                return isValid || ctx.createError({ message: getLocalizedValue(selectedLanguage, 't_gia_nla_cpa_adding error') })
              }
            })
          })
    ),
    nla: Yup.lazy((value, schema) =>
      !value && !schema.parent.gia && !schema.parent.estimatedCpa
        ? Yup.number().required(getLocalizedValue(selectedLanguage, 't_gia_nla_required_message'))
        : Yup.number().nullable(true)
    ),
    cpa: Yup.number().nullable(true),
    ext: Yup.number().nullable(true),
    sector: Yup.string().required(getLocalizedValue(selectedLanguage, 't_please_select_option_from_dropdown_from_dropdown')),
    country: Yup.string().required(getLocalizedValue(selectedLanguage, 't_select_the_country_of_asset')).nullable(),
    region: Yup.string().required(getLocalizedValue(selectedLanguage, 't_select_the_region_of_asset')),
    currentStatus:Yup.string().required(getLocalizedValue(selectedLanguage, 't_select_the_status_of_asset')).nullable(),
    assetName: Yup.string()
      .required(getLocalizedValue(selectedLanguage, 't_enter_asset_name'))
      .test({
        name: 'validate-asset-name',
        message: getLocalizedValue(selectedLanguage, 't_validation_asset_name'),
        test: function () {
          return !currentAssetState.assetNameDetails || (currentAssetState.assetNameDetails.responseType !== false)
        },
      })
      .nullable(),
    assetReference: Yup.string()
      .required(getLocalizedValue(selectedLanguage, 't_enter_asset_reference'))
      .test({
        name: 'validate-asset-reference',
        message: getLocalizedValue(selectedLanguage, 't_validation_asset_reference'),
        test: function () {
          return !currentAssetState.assetReferenceDetails || (currentAssetState.assetReferenceDetails.responseType !== false)
        }
      })
      .nullable(),
    fund: Yup.string().required(getLocalizedValue(selectedLanguage, 't_select_assetdetails_fund')),
    assetOwnership: Yup.number()
      .typeError(getLocalizedValue(selectedLanguage,'t_asset_ownership_invalid_input'))
      .required(getLocalizedValue(selectedLanguage, 't_asset_ownership_required'))
      .min(1, getLocalizedValue(selectedLanguage, 't_asset_ownership_min_value'))
      .max(100, getLocalizedValue(selectedLanguage, 't_asset_ownership_max_value'))
      .nullable(true),
    operationControl:  Yup.string().test({
      name: 'is-operationControl',
      skipAbsent: true,
      test(value, ctx) {
        if(!value){
          return ctx.createError({ message: getLocalizedValue(selectedLanguage, 't_operational_control_asset') })
        }
        return true
      }
    }),
    buildingUtilitesPresent: Yup.array().test({
      name: 'is-buildingUtilitesPresent',
      skipAbsent: true,
      test(value, ctx) {
        if(!value.length){
          return ctx.createError({ message: getLocalizedValue(selectedLanguage, 't_select_at_least_one_option') })
        }
        return true
      }
    }),
    purchaseDate: Yup.date().nullable(true).required(getLocalizedValue(selectedLanguage, 't_date_required')).max(currentDate, getLocalizedValue(selectedLanguage, 't_future_date_error')),
    soldDate: Yup.date()
      .nullable(true)
      .when('purchaseDate', (purchaseDate, schema) => {
        return purchaseDate
          ? schema
            .min(purchaseDate, getLocalizedValue(selectedLanguage, 't_sale_date_error'))
          : schema.test({
            name: 'purchaseDate',
            exclusive: true,
            test: ((dateSold) => { return !dateSold }), // Always return false to trigger the error
            message: getLocalizedValue(selectedLanguage, 't_sale_date_before_purchase_date_error'),
          })
      }),
    gavEvalDate: Yup.date().nullable(true).test({
      name: 'is-gavEvalDate',
      skipAbsent: true,
      test(value, ctx){
        if(ctx?.parent?.gav && !value){
          return ctx.createError({ message: getLocalizedValue(selectedLanguage, 't_enter_date_of_evaluation') })
        }
        return true
      }
    })
  })

  const formik = useFormik({
    initialValues: {
      energyStarSector: assetDetailsFields?.propertyEnergyStarSectorId,
      estimatedCpa: assetDetailsFields?.estimateCPA || false,
      currentStatus: CURRENT_STATUS_DROPDOWN.find((status) => status.value === assetDetailsFields?.propertyStatus) ? assetDetailsFields?.propertyStatus : null,
      yearBuilt: assetDetailsFields?.yearBuilt,
      assetName: assetDetailsFields?.propertyName,
      gresbSector: assetDetailsFields?.gresbSector,
      assetReference: assetDetailsFields?.propertyReference,
      fund: assetDetailsFields?.clientFundID,
      clientFundId: assetDetailsFields?.clientFundID,
      assetOwnership: assetDetailsFields?.fundOwnership || '100',
      purchaseDate: assetDetailsFields?.dateOfPurchase  ? new Date(assetDetailsFields?.dateOfPurchase) : null,
      soldDate: assetDetailsFields?.dateOfSale  ? new Date(assetDetailsFields?.dateOfSale) : null,
      country: assetDetailsFields?.propertyCountry?.includes('Invalid country') ? null : assetDetailsFields?.propertyCountry,
      region: String(assetDetailsFields?.propertyRegionId),
      street: assetDetailsFields?.propertyAddress,
      city: assetDetailsFields?.propertyCity,
      postcode: assetDetailsFields?.propertyPostCode,
      gav: assetDetailsFields?.currentAssetValue,
      propertySector: assetDetailsFields?.sector,
      sector: assetDetailsFields?.sectorID,
      'gav-dropdown': gavObject?.value,
      'gia-dropdown': assetDetailsFields?.userUnitOfMeasurement || unitSystem,
      'nla-dropdown': assetDetailsFields?.userUnitOfMeasurement || unitSystem,
      'cpa-dropdown': assetDetailsFields?.userUnitOfMeasurement || unitSystem,
      'ext-dropdown': assetDetailsFields?.userUnitOfMeasurement || unitSystem,
      gia: assetDetailsFields?.gia,
      nla: assetDetailsFields?.nla,
      cpa: assetDetailsFields?.cpa,
      ext: assetDetailsFields?.ext,
      operationControl: assetDetailsFields?.gresBManagementStatus,
      buildingUtilitesPresent: assetId ? updateCoverage({ignoreElectricityMaxCoverage: assetDetailsFields?.ignoreElectricityMaxCoverage,
        ignoreGasMaxCoverage: assetDetailsFields?.ignoreGasMaxCoverage,
        ignoreWaterMaxCoverage: assetDetailsFields?.ignoreWaterMaxCoverage,
        ignoreOilMaxCoverage: assetDetailsFields?.ignoreOilMaxCoverage,
        ignoreDHMaxCoverage: assetDetailsFields?.ignoreDHMaxCoverage,
        ignoreDCMaxCoverage: assetDetailsFields?.ignoreDCMaxCoverage}) : DEFAULT_BUILDING_UTILITIES_VALUE,
      gavEvalDate: assetDetailsFields?.valuationDate ? new Date(assetDetailsFields?.valuationDate) : null,
      ped: staticReportingFields?.primaryEnergyDemand,
      nzebThreshold: staticReportingFields?.nzebThreshold,
      totalHorizontalArea: staticReportingFields?.totalHorizontalAreaM2,
      totalVerticalArea: staticReportingFields?.totalVerticalAreaM2,
      horizontalArtificial: staticReportingFields?.artificialHorizontalAreaM2,
      verticalArtificial: staticReportingFields?.artificialVerticalAreaM2,
      planningPermissionDate: staticReportingFields?.planningPermissionDate ? new Date(staticReportingFields?.planningPermissionDate) : '',
      sustainableTonnes: periodicReportingFields?.resourceConsumption?.sustainableWeight,
      totalTonnes: periodicReportingFields?.resourceConsumption?.totalWeight,
      startDate: periodicReportingFields?.resourceConsumption?.startDate ? new Date(periodicReportingFields?.resourceConsumption.startDate) : '',
      endDate: periodicReportingFields?.resourceConsumption?.endDate ? new Date(periodicReportingFields?.resourceConsumption.endDate) : '',
    },
    onSubmit: (values) => {
      if(!!assetId || !!assetIdAlt){
        submitUpdateFormData(createAssetFormDataObject(values))
      } else {
        submitNewFormData(createAssetFormDataObject(values))
      }
    },
    validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true
  })

  const submitNewFormData = async (newFormObject) => {
    await addAssetForm.mutateAsync({requestData: newFormObject})
  }

  const submitUpdateFormData = async (newFormObject) => {
    await updateAssetForm.mutateAsync({ assetId: assetIdAlt ?? assetId, requestData: newFormObject})
  }

  const timelineValuesCleanup = (values) => {
    return values.filter(record => {
      if (record.startDate && record.startDate !== '' && record.value !== null && typeof(record.value) !== 'undefined' && record.value !== '') {
        delete record.endDate
        if (record.valueOption) {
          delete record.valueOption
        }
        delete record.id
        record.value = JSON.parse(record.value)
        return true
      }
      return false
    })
  }

  const createBuildingUtilitiesObj = (selectedCoverage) => {
    const coverage = {}
    BUILDING_UTILITIES_DROPDOWN_VALUE.forEach(option => {
      coverage[option.code] = selectedCoverage.some(code => code.code === option.code)
    })
    return coverage
  }

  const createAssetFormDataObject = (values) => {
    const assetDetails = {
      propertyEnergyStarSectorId: values?.energyStarSector ? parseInt(values?.energyStarSector) : null,
      propertyName: values.assetName,
      propertyReference: values.assetReference,
      propertyStatus: values.currentStatus,
      propertyRegionId: parseInt(values?.region),
      yearBuilt: values?.yearBuilt , //TODO: Update with actual value once it's implemented (now it's a Date object, but needs to be year only)
      dateOfPurchase: convertDateToUTCFormat(values?.purchaseDate),
      dateOfSale: values?.soldDate ? convertDateToUTCFormat(values?.soldDate) : null,
      propertyCountry: values.countryLabel,
      gia: values.gia,
      nla: values.nla,
      cpa: values.cpa !== '' ? values.cpa : null,
      ext: values.ext !== '' ? values.ext : null,
      propertyPostCode: values.postcode,
      propertyAddress: values.street,
      propertyCity: values.city,
      sectorID: values.sector, //TODO: Update with actual value once it's implemented
      userUnitOfMeasurement: values['gia-dropdown'],
      // nlaMeasurementUnit: values['nla-dropdown'],
      // cpaMeasurementUnit: values['cpa-dropdown'],
      // extMeasurementUnit: values['ext-dropdown'], // TODO: update when required
      clientLocationID: null,
      clientFundID: values?.fund,
      clientID: fundId,
      // clientFundDetails: {
      //   [fundId ?? 123919]: fundName, 
      // },
      estimateCPA: values.estimatedCpa,
      clientLocationName: null,
      gresBManagementStatus: values?.operationControl,
      gresbSector: `${values.propertySector} : ${values?.gresbSector}`,
      currentAssetValue: values?.gav ? values?.gav : null,
      fundOwnership: values?.assetOwnership || '100',
      valuationDate: values?.gavEvalDate ? convertDateToUTCFormat(values?.gavEvalDate) : null,
      valuationCurrency: values['gav-dropdown'],
      sector: values?.propertySector ? values?.propertySector : assetDetailsFields?.propertySector,
      ...createBuildingUtilitiesObj(values.buildingUtilitesPresent)
    }

    const resourceConsumptionValues = {
      sustainableWeight: values.sustainableTonnes,
      totalWeight: values.totalTonnes,
      startDate: values.startDate ?? null,
      endDate: values.endDate ?? null,
      ...(periodicReportingFields?.resourceConsumption?.resourceConsumptionId
        ? {resourceConsumptionId: periodicReportingFields.resourceConsumption.resourceConsumptionId }
        : {}),
    }

    const allValuesNull = Object.values(resourceConsumptionValues).every(value => value === null || !value)
    
    const periodicReporting = {
      fossilFuelExposures: timelineValuesCleanup(fossilFuelExposureTimeline.values),
      wasteContracts: timelineValuesCleanup(wasteTimeline.values),
      assetValues: timelineValuesCleanup(gavTimeline.values),
      // occupancy: timelineValuesCleanup(occupancy.values), TODO: To be added once api supports occupancy
      resourceConsumption: allValuesNull ? null : resourceConsumptionValues,
    }
    
    const staticReporting = {
      planningPermissionDate: values.planningPermissionDate ? values.planningPermissionDate : null,
      primaryEnergyDemand: values.ped,
      nzebThreshold: values.nzebThreshold,
      artificialHorizontalAreaM2: values.horizontalArtificial,
      artificialVerticalAreaM2: values.verticalArtificial,
      totalHorizontalAreaM2: values.totalHorizontalArea,
      totalVerticalAreaM2: values.totalVerticalArea,
    }

    return {
      assetDetails,
      ...(sfdrFeatureFlag
        ? { periodicReporting, staticReporting }
        : {}),
    }
  }

  function onHideSidebar() {
    setIsSidebarVisible(false)
    setTimeout(() => {
      assetIdAlt ? setActiveIndex(1) : setActiveIndex(0)  
    }, 200)
  }

  return (
    <CustomSidebar
      sidebarHeader={getLocalizedValue(selectedLanguage, !!assetId || !!assetIdAlt ? 't_edit_asset' : 't_add_asset')}
      isSidebarVisible={isSidebarVisible}
      onHideSidebar={onHideSidebar}
      setIsSidebarVisible={setIsSidebarVisible}
      dismissable={false}
      customClass='asset-sidebar'
      testId={!assetId ? 'add-asset-sidebar' : 'edit-asset-sidebar'}>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} className='asset-sidebar__tab-view'>
            <TabPanel header={getLocalizedValue(selectedLanguage, 't_asset_details')} data-testid='asset-details-tab'>
              <AddEditAssetForm updateCurrentAssetState={updateCurrentAssetState} setIsSidebarVisible={setIsSidebarVisible} isAssets={isAssets} fundName={fundName} buildingUtilitesPresentDropdownValues={BUILDING_UTILITIES_DROPDOWN_VALUE} assetDetailsFields={assetDetailsFields} />
              {sharedSaveFooter(formik.handleSubmit)}
            </TabPanel>
            {sfdrFeatureFlag && (<TabPanel header={getLocalizedValue(selectedLanguage, 't_periodic_reporting_data')} data-testid='periodic-data-tab'>
              {getFrameworkSelectionTemplate()}
              <PeriodicReportingForm
                reportingYear={yearVar} 
                setIsSidebarVisible={setIsSidebarVisible} 
                fossilFuelExposureTimeline={fossilFuelExposureTimeline}
                setFossilFuelExposureTimeline={setFossilFuelExposureTimeline}
                wasteTimeline={wasteTimeline}
                setWasteTimeline={setWasteTimeline}
                gavTimeline={gavTimeline}
                setGavTimeline={setGavTimeline}
                occupancyTimeline={occupancyTimeline}
                setOccupancyTimeline={setOccupancyTimeline}
                dataReadinessChecks={dataReadiness}
              />
              {sharedSaveFooter(formik.handleSubmit)}
            </TabPanel>)}
            {sfdrFeatureFlag && (<TabPanel header={getLocalizedValue(selectedLanguage, 't_static_reporting_data')} data-testid='static-data-tab'>
              {getFrameworkSelectionTemplate()}
              <StaticReportingForm
                setIsSidebarVisible={setIsSidebarVisible} dataReadinessChecks={dataReadiness}/>
              {sharedSaveFooter(formik.handleSubmit)}
            </TabPanel>)}
          </TabView>
        </Form>
      </FormikProvider>
    </CustomSidebar >
  )
}

AddEditAssetSidebar.propTypes = {
  isSidebarVisible: PropTypes.bool,
  setIsSidebarVisible: PropTypes.func,
  panelIndex: PropTypes.number,
  assetIdAlt: PropTypes.number,
  newestYear: PropTypes.number,
  oldestYear: PropTypes.number,
  isAssets: PropTypes.bool,
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  framework: PropTypes.oneOf(['sfdr']),
  fundName: PropTypes.string
}

export default AddEditAssetSidebar