import {memo} from 'react'
import { TabView, TabPanel } from 'primereact/tabview'
import I18n from '../../../../../utils/i18n/I18n'
import './FundProcurementTabsV2.scss'
import FundDataQualityProcurementSkeleton from '../../../../common/skeletons/fund-data-quality-procurement-skeleton/FundDataQualityProcurementSkeleton'
import PropTypes from 'prop-types'
import FundProcurementChartV2 from './procurement-chart/FundProcurementChart'
import TabPanelHeaderTemplate from '../fund-dq-components/TabPanelHeaderTemplate'
import {BOTH} from '../../../../../utils/helpers/Constants'


const FundProcurementTabs = ({isLoading, fundDQSummaryData, selectedUtility, selectedLanguage}) => {

  function getTotalDataForSelectedUtility(fundDQSummaryData) {
    const data = fundDQSummaryData?.filter(data=> BOTH === data.responsibility)
    if(data?.[0]?.summary) {
      return data?.map(data => data?.summary)
    } else {
      return []
    }
  }

  function getTotalMissingData(fundDQSummaryData) {
    let totalMissingData

    if(fundDQSummaryData?.length > 0) {
      totalMissingData = (Math.round(fundDQSummaryData[0]?.incompletePercentage * 100)) + Math.round((fundDQSummaryData[0]?.notCoveredPercentage * 100))
    } else {
      totalMissingData=100
    }
    return totalMissingData
  }

  const tabHeaderTemplate = (options, fundDQSummaryData) => {
    const tabHeaderTemplateProps={isLoading, options, missingData: getTotalMissingData(fundDQSummaryData)}
    return <TabPanelHeaderTemplate {...tabHeaderTemplateProps} />
  }

  return (
    <div className="fund-dq-tabview">
      <TabView>
        <TabPanel header={I18n('t_total_data')} headerTemplate={(options)=>tabHeaderTemplate(options, getTotalDataForSelectedUtility(fundDQSummaryData) ?? [])}>
          {isLoading ?
            <FundDataQualityProcurementSkeleton />
            :
            <FundProcurementChartV2 data={getTotalDataForSelectedUtility(fundDQSummaryData) ?? []} selectedLanguage={selectedLanguage} />}
        </TabPanel>
      </TabView>
    </div>
  )
}

FundProcurementTabs.propTypes = {
  fundDQSummaryData: PropTypes.array,
  isLoading: PropTypes.bool,
  selectedUtility: PropTypes.array,
  selectedLanguage: PropTypes.string
}

FundProcurementTabs.displayName = 'FundProcurementTabs'

export default memo(FundProcurementTabs)