import React from 'react'
import {Skeleton} from 'primereact/skeleton'
import  '../../../pages/admin/users/users-home/UsersHome.scss'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'

const UsersGridSkeleton = () => {
  const emptyArray = Array.from({ length: 5 })
  const bodyTemplate = () => {
    return <Skeleton/>
  }

  const headerTemplate = (length) => {
    return <div className="p-ml-1 p-mb-4">
      <Skeleton width={length}/>
    </div>
  }

  return (
    <div className="custom-skeleton p-rel p-d-flex p-flex-column p-ai-center users-page-skeleton">
      <DataTable value={emptyArray} className="users-list-table">
        <Column field="Name" header={headerTemplate('8rem')} style={{width: '20%'}} body={bodyTemplate}/>
        <Column field="Company" header={headerTemplate('8rem')} style={{width: '20%'}} body={bodyTemplate}/>
        <Column field="Role" header={headerTemplate('8rem')} style={{width: '20%'}} body={bodyTemplate}/>
        <Column field="Assets" header={headerTemplate('4rem')} style={{width: '10%'}} body={bodyTemplate}/>
        <Column field="Last Login" header={headerTemplate('8rem')} style={{width: '20%'}} body={bodyTemplate}/>
      </DataTable>
    </div>
  )
}

export default UsersGridSkeleton
