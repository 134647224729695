import {memo, useState} from 'react'
import PropTypes from 'prop-types'
import PopupDialog from '../modal-dialogs/PopupDialog'
import I18n from '../../../utils/i18n/I18n'
import {countDown, getLocalStorageByKey} from '../../../utils/helpers/Helper'
import {Ping} from '../../../services/login-service'
import {useIdleTimer} from 'react-idle-timer'
import {DEFAULT_SESSION_PROMPT_TIMEOUT, DEFAULT_SESSION_TIMEOUT, LOCAL_STORAGE_KEYS} from '../../../utils/helpers/Constants'

const SessionExpiryMessageDialog = ({logoutUser}) => {

  const [showIdlePrompt, setShowIdlePrompt] = useState(false)
  const idleTimer = useIdleTimer({
    timeout: getSessionTimeout(),
    promptBeforeIdle: getPromptTimeout(), // IdleTimer -- The `promptTimeout` property has been deprecated in favor of `promptBeforeIdle`. It will be removed in the next major release.
    onIdle: onSessionExpiryPromptGoingIdle,
    onPrompt: onSessionAboutToExpirePrompt,
    onMessage: onSessionExpiryMessage,
    crossTab: true,
    syncTimers: 200
  })

  function onSessionAboutToExpirePrompt() {
    setShowIdlePrompt(true)
  }

  function onSessionExpiryPromptGoingIdle() {
    setShowIdlePrompt(false)
    logoutUser()
  }

  function onSessionExpiryMessage(message) {
    doResetSessionExpiry(message)
  }

  function getPromptTimeout() {
    const sessionTimeout = getSessionTimeout()
    const userInfo = getLocalStorageByKey(LOCAL_STORAGE_KEYS.userInfo)
    let promptTimeout = userInfo?.sessionTimeoutUI ?? DEFAULT_SESSION_PROMPT_TIMEOUT

    if (sessionTimeout > promptTimeout) {
      return promptTimeout
    }
    return sessionTimeout / 2
  }

  function getSessionTimeout() {
    const userInfo = getLocalStorageByKey(LOCAL_STORAGE_KEYS.userInfo)
    if (userInfo?.sessionTimeout) {
      return userInfo.sessionTimeout
    }
    return DEFAULT_SESSION_TIMEOUT
  }

  const resetSessionExpiry = (result) => {
    doResetSessionExpiry(result)
    idleTimer.message(result)
  }

  const doResetSessionExpiry = (result) => {
    if (result && result === 'reject') {
      logoutUser()
    } else {
      Ping().finally(() => {
        idleTimer.start()
        setShowIdlePrompt(false)
      })
    }
  }

  return (
    <div className="session-expiry-message-dialog">
      {showIdlePrompt &&
                <PopupDialog visible={showIdlePrompt}
                  header={I18n('t_session_expiry_title')}
                  message={I18n('t_session_expiry_warning', {0: countDown(showIdlePrompt, getPromptTimeout())})}
                  className={'sessionExpiry-Warning-Dlg'}
                  onHide={(result) => resetSessionExpiry(result)}
                  dismissable={true}
                  rejectLabel={I18n('t_log_out_now')}
                  acceptLabel={I18n('t_stay_logged_in')}
                />}
    </div>
  )
}

SessionExpiryMessageDialog.propTypes = {
  logoutUser: PropTypes.func
}

export default memo(SessionExpiryMessageDialog)