import * as configcat from 'configcat-js'

export const getFeatureFlagsValue = async (defaultFeatureFlag, userLoginInfo) => {
  const userDomain = userLoginInfo?.userName?.split('@').pop()
  const userConfiguration =  {
    identifier : userLoginInfo?.userId, // Unique identifier.
    email : userLoginInfo?.userName,
    //country : userLoginInfo.userInfo?.country,
    custom : {
      // "Language": userContext.userState.language,
      UserRole: userLoginInfo?.userRole,
      domain: userDomain
    }
  }
  const featureFlagsValue = defaultFeatureFlag
  const featureFlagClient = configcat.createClientWithManualPoll(process.env.REACT_APP_CONFIGCAT_SDK_KEY)
  await featureFlagClient.forceRefreshAsync()
  try {
    const settings = await featureFlagClient.getAllValuesAsync(userConfiguration, { requestTimeoutMs: 5000 })
    settings.forEach(i => featureFlagsValue[i.settingKey] = i.settingValue)
  }
  catch {
    console.log('Failed to load feature flags')
  }
  finally {
    featureFlagsValue.initialised = true
  }
  return featureFlagsValue
}
