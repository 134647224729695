import I18n from '../i18n/I18n'
import {getLocalizedValue} from './LanguageUtils'
import CSS_VARIABLES from '../../resources/css/_variables.scss'
import {LOCALES} from '../i18n'
import {UNITS} from '../i18n/constants'

export const dataPickerStartYear = 2001

export const monthList = Object.freeze({
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December'
})

export const reportingPeriodMap = {
  'YTD': new Date().getMonth()+1, 
  '3M': 3, 
  '6M': 6, 
  '1Y': 12
}

export const monthListShort = Object.freeze({
  1 : 'Jan',
  2 : 'Feb',
  3 : 'Mar',
  4 : 'Apr',
  5 : 'May',
  6 : 'Jun',
  7 : 'Jul',
  8 : 'Aug',
  9 : 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec'
})

export const monthsRepresentedByNumbers = Object.freeze({
  0 : 'Jan',
  1 : 'Feb',
  2 : 'Mar',
  3 : 'Apr',
  4 : 'May',
  5 : 'Jun',
  6 : 'Jul',
  7 : 'Aug',
  8 : 'Sep',
  9 : 'Oct',
  10: 'Nov',
  11: 'Dec'
})

export const monthNumbers = Object.freeze({
  'jan': 1,
  'feb': 2,
  'mar': 3,
  'apr': 4,
  'may': 5,
  'jun': 6,
  'jul': 7,
  'aug': 8,
  'sep': 9,
  'oct': 10,
  'nov': 11,
  'dec': 12
})

export const shortMonths = [
  {label: I18n('t_jan'), value: '1'},
  {label: I18n('t_feb'), value: '2'},
  {label: I18n('t_mar'), value: '3'},
  {label: I18n('t_apr'), value: '4'},
  {label: I18n('t_may'), value: '5'},
  {label: I18n('t_jun'), value: '6'},
  {label: I18n('t_jul'), value: '7'},
  {label: I18n('t_aug'), value: '8'},
  {label: I18n('t_sep'), value: '9'},
  {label: I18n('t_oct'), value: '10'},
  {label: I18n('t_nov'), value: '11'},
  {label: I18n('t_dec'), value: '12'}
]

export const CURRENT_STATUS_DROPDOWN = [
  { label: I18n('t_land'), value: 'Land'},
  { label: I18n('t_standing_investment'), value: 'StandingInvestment' },
  { label: I18n('t_new_construction'), value: 'NewConstruction'},
  { label: I18n('t_major_renovation'), value: 'MajorRenovation'},
]

export const currentMonth = new Date().getMonth() + 1
export const currentYear = new Date().getFullYear().toString().slice(-2)
export const CURRENT_YEAR_FULL = new Date().getFullYear()

export const monthListShortWithLocale = Object.freeze({
  1 : I18n('t_jan'),
  2 : I18n('t_feb'),
  3 : I18n('t_mar'),
  4 : I18n('t_apr'),
  5 : I18n('t_may'),
  6 : I18n('t_jun'),
  7 : I18n('t_jul'),
  8 : I18n('t_aug'),
  9 : I18n('t_sep'),
  10: I18n('t_oct'),
  11: I18n('t_nov'),
  12: I18n('t_dec')
})

export const DATE_FORMAT = 'dd M y'
export const WARNING_MSG = 'Warning Message 1'

export const UTILITY_LABELS_OBJECT_TITLE_CASE = Object.freeze({
  ELECTRICITY:      'Electricity',
  GAS_FUEL_THERMAL: 'GasFuelsAndThermals',
  WATER:            'Water',
  WASTE:            'Waste',
  CARBON:           'Carbon',
  THERMALS:         'Thermals',
  RECYCLED_WASTE:   'RecycledWaste',
  ENERGY_INTENSITY: 'EnergyIntensity',
  INTENSITY:         'Intensity',
  ENERGY:             'Energy',
  SUMMARY: 'Summary',
  OTHER_FUELS_THERMALS : 'OtherFuelsAndThermals',
  GAS : 'Gas',
  ENERGY_SUMMARY:'Energy Summary',
  DISTRICT_COOLING:'districtCooling',
  DISTRICT_HEATING:'districtHeating',
  GAS_TYPE:'gas',
  OIL:'oil',
  CARBON_EMISSION: 'Carbon Emissions',
  WASTE_RECYCLING:   'Waste Recycling',
})

export const UTILITY_TITLE_V3 = Object.freeze({
  CARBON_EMISSION: 'Carbon Emissions',
  ENERGY_INTENSITY: 'Energy Intensity',
  ELECTRICITY:      'Electricity',
  GAS_FUEL_THERMAL: 'Gas, Fuels & Thermals',
  WATER:            'Water',
  RECYCLED_WASTE: 'Waste Recycling',
})

// Simplified version of UTILITY_LABELS_OBJECT_TITLE_CASE, logically named and more readable.
// This property names map directly to names in API data.
export const UtilityTypes = Object.freeze({
  Carbon: 'Carbon',
  EnergyIntensity: 'EnergyIntensity',
  Energy: 'Energy',
  Electricity: 'Electricity',
  GasFuelsAndThermals: 'GasFuelsAndThermals',
  Water: 'Water',
  Waste: 'Waste',
  RecycledWaste: 'RecycledWaste',
  Summary: 'Summary'
})

/**
* Landlord/Tenant split types
*/
export const SplitTypes = Object.freeze({
  all: 'all',
  landlord: 'landlord',
  tenant: 'tenant'
})
  
/**
  * Carbon data scope types.
  */
export const ScopeTypes = Object.freeze({
  all: 'all',
  scope1: 'scope1',
  scope2: 'scope2',
  scope3: 'scope3'
})

export const UTILITY_OTHER_NAME_MAP = Object.freeze({
  FuelsAndThermals : 'GasFuelsAndThermals',
  Gas : 'GasFuelsAndThermals',
  Energy : 'EnergyIntensity',
  OtherFuelsAndThermals:'OtherFuelsAndThermals',
  All:'All',
  Electricity:'Electricity',
  Water:'Water',
  Waste:'Waste',
})

export const notiType = Object.freeze({
  PerfVar: 'PerformanceVariance',
  Action:  'Action',
  DataQua: 'DataQuality'
})

export const costTypeMask = {
  'RevenueServiceCharge': I18n('t_revenue_service'),
  'CapitalNonRecoverable': I18n('t_capital_non_recover'),
  'CapitalRecoverable': I18n('t_capital_recover'),
  'None':I18n('t_none'),
  '': '-',
  null:'-'
}

export const areaCoveredMask = {
  'CommonAreas': I18n('t_commonareas'),
  'Exterior': I18n('t_exterior'),
  'TenantServices': I18n('t_tenant_services'),
  'WholeBuilding': I18n('t_wholebuilding'),
  'Landlord': I18n('t_landlord'),
  'SharedServices':I18n('t_shared_services'),
  '': '-',
  null:'-'
}

export const costMask = {
  'GBP': '£',
  'EUR': '€',
  'USD': '$'
}

export const epcRating = {
  'A+': 'd-green',
  'A': 'd-green',
  'B': 'p-green',
  'C': 'l-green',
  'D': 'yellow',
  'E': 'l-orange',
  'F': 'd-orange',
  'G': 'red',
  'n/c': 'grey',
}

export const epsRating = {
  '1-25': 'red',
  '26-74': 'l-orange',
  '75-100':'p-green'
}

export const EPS = 'EPS'

export const meterUtilityUnits = Object.freeze({
  DistrictCooling: 'kWh',
  DistrictHeating: 'kWh',
  Electricity: 'kWh',
  Gas: 'kWh',
  Water: 'm³',
  Waste: 't',
  Oil : 'kWh',
})

export const GRAPHTEXT = {empty: 'empty'}
export const GRAPH_LABELS = ['ACTUAL', 'ESTIMATE', 'MISSING', 'NC',] // The order of the meterConsumptionTypes follows the order of the DQ graph-bars.
export const GRAPH_PDF_CSS=['actual-pdf','est-pdf','missing-pdf','nc-pdf']
export const GRAPH_LABELS_PDF = {
  'actual-pdf': 'ACTUAL', 'est-pdf': 'ESTIMATED', 'missing-pdf': 'INCOMEPLETE', 'nc-pdf': 'NOT COVERED'
}
export const GRAPH_PDF_THICK_LINE={'actual' : 'actual-pdf','estimate' : 'est-pdf','missing' : 'missing-pdf','not_covered_uc' :'nc-pdf'}
export const PDF_LABEL={'actual' : 'Actual Data','estimate' : 'Estimated Data','missing' : 'Incomplete data','nc' :'Not Covered Data'}

export const LEGEND_TEMPLATE = 
  <div data-testid='data-quality-legend-test' className='data-quality-legend'>
    <div className='actual_data_swatch'></div>
    <p>{I18n('t_actual_data_legend')}</p>
    <div className='estimated_data_swatch'></div>
    <p>{I18n('t_estimated_data_legend')}</p>
    <div className='incomplete_data_swatch'></div>
    <p>{I18n('t_incomplete_data_legend')}</p>
    <div className='not_covered_data_swatch'></div>
    <p>{I18n('t_not_covered_data_legend')}</p>
  </div>
  
export const PAGINATOR_WITH_LEGEND_TEMPLATE = {
  layout: 'PrevPageLink CurrentPageReport NextPageLink RowsPerPageDropdown',
  'RowsPerPageDropdown': () => {
    return LEGEND_TEMPLATE
  }
}

export const eventDuration = (value,selectedLanguage) => {
  let duration
  if (value === 'YTD') {
    duration=getLocalizedValue(selectedLanguage,'t_graph_ytd')
  }
  else if(value === '3M'){
    duration=getLocalizedValue(selectedLanguage,'t_three_months')
  }
  else if(value === '6M'){
    duration=getLocalizedValue(selectedLanguage,'t_six_months')
  }
  else if(value === '1Y'){
    duration=getLocalizedValue(selectedLanguage,'t_one_year')
  }
  else{
    duration=value
  }
  return duration
}

//Constants representing AssetControl Enum:[ Landlord, Tenant ] in the server.
export const assetControl = {
  Landlord: 'Landlord',
  Tenant: 'Tenant'
}

export const MeasurementUnits = Object.freeze({
  M2: 'M2',
  FT2: 'FT2'
})

export const UserActions = Object.freeze({
  ADD_USER: 'ADD_USER',
  EDIT_USER: 'EDIT_USER',
  DELETE_USER: 'DELETE_USER',
  ADD_GROUP: 'ADD_GROUP',
  EDIT_GROUP: 'EDIT_GROUP',
  DELETE_GROUP: 'DELETE_GROUP'
})

export const Action = 'ACTION'

export const actionOptions = Object.freeze({
  OPTION_RESEND_INVITE: 'Resend invite',
  OPTION_REVOKE_ACCESS: 'Revoke access',
  OPTION_EDIT_USER: 'Edit'
})

export const UserRoles = Object.freeze({
  USER: 'USER',
  ADMIN:  'ADMIN',
  ADMINISTRATOR:  'ADMINISTRATOR'
})

// Maps user roles with different user actions.
export const UserPermissions = Object.freeze({
  [UserActions.ADD_USER]:[UserRoles.ADMIN, UserRoles.ADMINISTRATOR],
  [UserActions.EDIT_USER]:[UserRoles.ADMIN, UserRoles.ADMINISTRATOR],
  [UserActions.DELETE_USER]:[UserRoles.ADMIN, UserRoles.ADMINISTRATOR],
  [UserActions.ADD_GROUP]:[UserRoles.ADMIN, UserRoles.ADMINISTRATOR],
  [UserActions.EDIT_GROUP]:[UserRoles.ADMIN, UserRoles.ADMINISTRATOR],
  [UserActions.DELETE_GROUP]:[UserRoles.ADMIN, UserRoles.ADMINISTRATOR]
})

export const userInfo = 'userDetails'
export const groupInfo = 'groupDetails'
export const userGroupDetails = 'userGroupsDetails'
export const groupUserDetails = 'groupUserDetails'
export const userAssetDetails = 'userAssetsDetails'
export const storeGroupAsset = 'storeGroupAsset'
export const userFundDetails = 'userFundsDetails'
export const years = Array.from(Array(new Date().getFullYear() - (dataPickerStartYear - 1)), (_, i) => (i + dataPickerStartYear).toString())
export const CacheKeyFundGroups = 'FundGroups'
export const CacheKeyOrganisation = 'Organisations'
export const CacheKeyAsset = 'assetName'
export const CacheKeyUserState = 'CachedUserState'
export const CacheIdAndValueState = 'CacheIdAndValueState'
export const dpCustomTimePeriod = 'dpCustomTimePeriod'
export const dpTimePeriod = 'dpTimePeriod'
export const weightedAverageCarbonIntensity = 'weightedAverageCarbonIntensity'
export const weightedAverageCostIntensity = 'weightedAverageCostIntensity'
export const weightedAverageEnergyIntensity = 'weightedAverageEnergyIntensity'
export const co2Emission = 'co2Emission'
export const gasFuelsAndThermals = 'Gas, Fuels & Thermals'
export const topDownPathways = 'top-down-pathways'
export const consumptionUpload = 'consumption-upload'

export const utilityUnits = {
  'Electricity': 'kWh',
  'GasFuelsAndThermals': 'kWh',
  'Water': 'm³',
  'Waste': 'Tonnes',
  'Carbon': 'kg CO₂e'
}

// TODO: These values are based on summary API but when compared with figma units are different
export const METRIC_TONNES_UTILITY_UNIT = {
  'Electricity': 'MWh',
  'GasFuelsAndThermals': 'MWh',
  'Water': 'm³',
  'Waste': 't',
  'Carbon': 'tCO₂e',
  'Energy': 'MWh/m²/yr'
}

export const IMPERIAL_TONNES_UTILITY_UNIT = {
  'Electricity': 'MBtu',
  'GasFuelsAndThermals': 'MBtu',
  'Water': 'gal',
  'Waste': 't',
  'Carbon': 'tCO₂e',
  'Energy': 'MBtu/ft²/yr'
}

//API Enum: [SingleFundAccess, MultipleFundAccess, MultipleOrganisationAccess, NoFundAccess ]
export const USER_FUND_ACCESSES  = Object.freeze({
  NoFundAccess: 'NoFundAccess', // go to assests page
  SingleFundAccess: 'SingleFundAccess', // fund dashboard
  MultipleFundAccess: 'MultipleFundAccess', // fund list page
  MultipleOrganisationAccess: 'MultipleOrganisationAccess'  // orginsation list
})

export const FUND_DATAQUALITY_SCOPES  = Object.freeze({
  Both: 'Both',
  Landlord: 'Landlord',
  Tenant: 'Tenant'
})

export const FLOOR_MEASUREMENT_TYPES  = Object.freeze({
  metric: 'm²',
  imperial: 'ft²'
})

export const dropdownPaginationOptions = [
  { label: 20, value: 20, className: 'items-per-page-option' },
  { label: 50, value: 50, className: 'items-per-page-option' }
]

export const FLOOR_MEASUREMENT_UNITS = Object.freeze({
  M2: 'm²',
  FT2: 'ft²'
})

export const UNIT_ARRAY = Object.entries(FLOOR_MEASUREMENT_UNITS).map(([value, label]) => ({ value, label }))

// FundViews pages names
export const FUND_VIEWS_PAGES  = Object.freeze({
  organisations: 'organisations',
  funds: 'funds',
  dashboard: 'dashboard',
  performance: 'performance',
  dataQuality: 'data-quality',
  actionPlan: 'action-plan',
  netZeroCarbon: 'net-zero-carbon',
  fundReports: 'reports',
})

export const ASSET_PAGES  = Object.freeze({
  assets: 'assets',
  passport: 'passport',
  dataQuality: 'data-quality',
  actionPlanHome: 'action-plan'
})

export const USERS_PAGES  = Object.freeze({
  users: 'users',
})

export const ADMIN_PAGES  = Object.freeze({
  users: 'users',
  groups: 'groups',
  myGroups:'my-groups',
  add: 'add',
  edit: 'edit'
})

export const COMMON_PAGES  = Object.freeze({
  login: 'login',
  notfound: 'notfound',
})

// Keys for localStorage needs to be added below.
export const LOCAL_STORAGE_KEYS  = Object.freeze({
  userInfo: 'userInfo',
  selectedOrgAndFund: 'selectedOrgAndFund',
  userRole: 'userRole',
  userId: 'userId',
  userFundAccessType: 'userFundAccessType',
  loginTimestamp: 'loginTimestamp',
  defaultDatePickerValue: 'defaultDatePickerValue',
  dpCustomTimePeriod: 'dpCustomTimePeriod',
  dpTimePeriod: 'dpTimePeriod',
  cacheUrlParamsIdsData: 'cacheUrlParamsIdsData',
  deletedModelId: 'deletedModelId',
  cacheCustomDateValue: 'cacheCustomDateValue'
})

// CSS classes for nav links
export const CSS_LINK_ACTIVE='link-active'
export const CSS_LINK_ACTIVE_GREY='link-active grey'
export const CSS_LINK_ACTIVE_GREY_DISABLED='link-active grey-disabled'

export const ADMIN = 'Admin'
export const ADMINISTRATOR = 'Administrator'
export const USERS = 'Users'
export const ADMIN_LOCALE = 't_admin'
export const USERS_LOCALE = 't_users'

export const FY_START_FOR_ASSET_ACCESS = '1 January'

export const PERIOD_TYPES  = Object.freeze({
  CUSTOM: 'CUS',
  YTD: 'YTD',
})

export const alphaNumericRegex = (/^[a-zA-Z0-9]+$/)
export const specialCharacterRegex = (/^[^\w*]+$/)
export const regexValidFileName = (/[\\/:"*?<>|~`]+|^\s+|\s+$/g)
export const regexActionStatus=(/([A-Z][a-z]+)(?=[A-Z]|$)/)
export const numericRegex=(/^\d+$/)
export const alphabetsCapitalizationRegex = /[A-Z][a-z]+/g
export const CAPITAL_LETTER_REGEX = /([A-Z])/g

export const dqEmailRegex = (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
// Allow only numbers, at most one minus sign at the beginning, and an optional decimal point
export const regexNumbers = /[^0-9.-]/g
export const leadingZeroRegex = /^0+(?!\.)/
export const removeMandatorySymbol = /\*/g
export const removeYearAndMandatorySymbol = /\/yr\*?/g

export const modelsOverlayOptions    = [
  { label: I18n('t_create_new_model'),     value: 'Create a new model' },
  { label: I18n('t_open_model'),           value: 'Open a model' },
  { label: I18n('t_duplicate_model'),  value: 'Duplicate model'},
  { label: I18n('t_export_config_data'),          value: 'Export configuration data' },
  { label: I18n('t_import_config_data'),           value: 'Import configuration data' },
]
export const modelsOptions = Object.freeze({
  createNewModel: 'Create a new model',
  openModel: 'Open a model',
  duplicateModel: 'Duplicate model',
  exportConfigurationData: 'Export configuration data',
  importConfigurationData: 'Import configuration data',
})
export const USER_AUTHORISED_MODULES = Object.freeze({
  STATIC_NZC: 'Static NZC Siera Plus',
  NZC: 'NZC Siera Plus'
})

export const CUSTOM = 'Custom'

export const groupedAssetsOrderBy = {
  labelShow: I18n('t_show'),
  itemsShow: [
    {label: I18n('t_nzc_data_availability'), value: 'dataAvailablePercentage'},
    {label: I18n('t_energy_intensity_nzc'), value: 'energyIntensity'},
    { label: I18n('t_nzc_carbon_intensity'), value: 'carbonIntensity' },
    { label: I18n('t_gav'), value: 'gav' },
  ],
  labelSort: I18n('t_sort'),
  itemsSort: [
    { label: I18n('t_lowest_first'), value: 'lowestFirst' },
    { label: I18n('t_highest_first'), value: 'highestFirst' },
  ]
}

export const assetsOrderByColumnChange = Object.freeze({
  dataAvailablePercentage: 'dataAvailablePercentage',
  energyIntensity: 'energyIntensity',
  carbonIntensity: 'carbonIntensity',
  gav: 'gav',
  lowestFirst: 'lowestFirst',
  highestFirst: 'highestFirst',
})

export function getColumnName(key) {
  let columnName
  switch (key) {
  case assetsOrderByColumnChange.carbonIntensity:
    columnName = I18n('t_nzc_carbon_intensity')
    break
  case assetsOrderByColumnChange.energyIntensity:
    columnName = I18n('t_energy_intensity_nzc')
    break
  case assetsOrderByColumnChange.gav:
    columnName = I18n('t_gav')
    break
  case assetsOrderByColumnChange.dataAvailablePercentage:
    columnName = I18n('t_nzc_data_availability')
    break
  default:
    columnName = I18n('t_nzc_data_availability')
  }
  return columnName
}

export function getAssetOrderName(key, selectedLanguage) {
  let orderName
  switch (key) {
  case assetsOrderByColumnChange.carbonIntensity:
    orderName = getLocalizedValue(selectedLanguage, 't_carbon_intensity').replace(/ /g,'_')
    break
  case assetsOrderByColumnChange.gav:
    orderName = getLocalizedValue(selectedLanguage, 't_gav')
    break
  case assetsOrderByColumnChange.dataAvailablePercentage:
    orderName = getLocalizedValue(selectedLanguage, 't_nzc_data_availability').replace(/ /g,'_')
    break
  default:
    orderName = getLocalizedValue(selectedLanguage, 't_nzc_data_availability').replace(/ /g,'_')
  }
  return orderName
}

export const defaultBaselineYear = 2019

export const SCOPE_TARGET_MODE = Object.freeze({
  create: 'create',
  view: 'view',
  edit: 'edit',
  delete: 'delete'
})

export const minWidth1280 = 1280
export const minWidth760 = 760

export const targetPathwaysMilestoneYears = Object.freeze({
  year2030 : 2030,
  year2035 : 2035,
  year2040 : 2040
})

export const LANGUAGE_PREF = 'languagePreference'
export const UNIT = 'unitSystem'
export const CURRENCY_UNIT_PREF = 'currencyUnit'
export const ES_CONNECTION_STEP_PREF = 'connectionStep'

export const mapResponsibilityToTitle = Object.freeze({
  Both: 't_total_data_missing',
  Landlord: 't_landlord_data_missing',
  Tenant: 't_tenant_data_missing'
})

export const roundAndMultiply100 = (value) => {
  if(!value || typeof(value) !== 'number') {
    return
  }

  return Math.round(value * 100)
}

export const dataQualityObject = Object.freeze({
  actualPercentage: {translationKey: 't_actual', cssClass: 'ACTUAL', color: CSS_VARIABLES.forest},
  estimatedPercentage: {translationKey: 't_estimated', cssClass: 'EST', color: CSS_VARIABLES.forest_50},
  incompletePercentage: {translationKey: 't_incomplete', cssClass: 'MISSING', color: CSS_VARIABLES.nature},
  notCoveredPercentage: {translationKey: 't_not_covered', cssClass: 'NC', color: CSS_VARIABLES.sand},
})

export const dataQualityObjectV2 = Object.freeze({
  actualPercentage: {translationKey: 't_actual_data_legend', cssClass: 'ACTUAL', color: CSS_VARIABLES.forest, legendColor: 'forest'},
  estimatedPercentage: {translationKey: 't_supplier_estimate', cssClass: 'EST', color: CSS_VARIABLES.forest_50, legendColor: 'green_light'},
  notCoveredPercentage: {translationKey: 't_calculated_estimate', cssClass: 'NC', color: CSS_VARIABLES.sand, legendColor: 'sand'},
  incompletePercentage: {translationKey: 't_missing_tc', cssClass: 'MISSING', color: CSS_VARIABLES.nature, legendColor: 'orange'},
})

export const addConsumptionEstimateList = [
  {label: I18n('t_actual'), value: 'Actual'},
  {label: I18n('t_estimated'), value: 'Estimate'}
]

export const actualOriginList= ['AMR','Invoices','Provided by tenant','Meter readings']

export const estimatedOriginList= ['AMR', 'Invoices','Provided by tenant','Meter readings','Calculated estimate','Supplier estimate']

export const PERFORMANCE_UTILITY_MAP = {
  'carbonPercentage': 'Carbon',
  'electricityPercentage': 'Electricity',
  'fuelsPercentage': 'GasFuelsAndThermals',
  'waterPercentage': 'Water',
  'wastePercentage': 'Waste'
}

export const DATA_QUALITY_KEYS = Object.freeze({
  ACTUAL: 'actual',
  ESTIMATE: 'estimate',
  MISSING: 'missing',
  NOT_COVERED: 'not_covered_uc',
  INCOMPLETE: 'Incomplete',
  SUPPLIER_ESTIMATE: 'supplier',
  CALCULATED_ESTIMATE: 'calculated',
})

export const MASK_DATA_QUALITY_KEYS = Object.freeze({
  actual: 'Actual',
  estimate: 'Estimate',
  missing: 'Missing',
  All: 'All'
})

export const ELECTRICITY = 'Electricity'
export const GAS_FUELS = 'FuelsAndThermals'
export const WATER = 'Water'
export const WASTE = 'Waste'
export const CARBON = 'Carbon'
export const ENERGY = 'Energy'
export const AREA = 'Area'
export const GAS = 'Gas'
export const GAS_FUELS_THERMALS = 'GasFuelsAndThermals'
export const OTHER_FUELS_THERMALS='OtherFuelsAndThermals'
export const DISTRICT_HEATING = 'DistrictHeating'
export const DISTRICT_COOLING = 'DistrictCooling'
export const DISTRICT_SPACE_HEATING = 'District Heating'
export const DISTRICT_SPACE_COOLING = 'District Cooling'
export const OIL = 'Oil'
export const WASTE_RECYCLING = 'Waste recycling'

export const TARGET_PATHWAY = 'Target Pathway '

export const DEFAULT_SESSION_TIMEOUT = 1800000 // 30 * 60 * 1000 = 30 minutes in milliseconds
export const DEFAULT_SESSION_PROMPT_TIMEOUT = 120000 // 2 * 60 * 1000 = 2 minutes in milliseconds

export const CURRENCY_UNITS = {
  BRITISHPOUND: 'BritishPound',
  EUROS: 'Euros',
  USDOLLAR: 'USDollar'
}

export const USE_QUERY_STATUS = Object.freeze({
  idle : 'idle'
})

export const  emptyAction = {
  completion: '', impact: '', actionDescription: '',
  status: 'Applicable', current: null, target: null, performanceMeasure: null, utilityType: null,
  costType: '', manager: '', scope: '', cost: null, dueDate: '', currency: 'GBP', actionNotes: [],
  procuredBy: '', areaCovered: ''
}

export const actionStatusHeaderColor = {
  Agreed: 'card-agreed',
  Overdue: 'card-overdue',
  Completed: 'card-completed',
  Applicable: 'card-applicable',
  UnderReview: 'card-under-review'
}

export const actionStatusHeaders = {
  Agreed: 't_agreed',
  Overdue: 't_overdue',
  Completed: 't_completed',
  Applicable: 't_applicable',
  UnderReview: 't_under_review'
}

export const fundDataQualityUtilitiesList = [
  {label: I18n('t_alu'), value: 'All'},
  {label: I18n('t_electricity'), value: 'Electricity'},
  {label: I18n('t_gas'), value: 'Gas'},
  {label: I18n('t_otherfuelsandthermals'), value: 'OtherFuelsAndThermals'},
  {label: I18n('t_water'), value: 'Water'},
]
export const fundPerformanceUtilitiesList = [
  {label: I18n('t_alu'), value: 'All'},
  {label: I18n('t_electricity'), value: 'Electricity'},
  {label: I18n('t_gasFuelsAndThermals'), value: 'GasFuelsAndThermals'},
  {label: I18n('t_water'), value: 'Water'},
  {label: I18n('t_waste_recycling'), value: 'Waste'},
  {label: I18n('t_carbon'), value: 'Carbon'},

]
export const getFundDataQualityUtilitiesList = (value, selectedLanguage) => {
  switch (value){
  case UTILITY_OTHER_NAME_MAP.All :
  case UTILITY_LABELS_OBJECT_TITLE_CASE.SUMMARY:
    return getLocalizedValue(selectedLanguage,'t_alu')
  case UTILITY_OTHER_NAME_MAP.Electricity: return getLocalizedValue(selectedLanguage,'t_electricity')
  case GAS: return getLocalizedValue(selectedLanguage,'t_gas')
  case UTILITY_OTHER_NAME_MAP.OtherFuelsAndThermals: return getLocalizedValue(selectedLanguage,'t_otherfuelsandthermals')
  case UTILITY_OTHER_NAME_MAP.Water: return getLocalizedValue(selectedLanguage,'t_water')
  }
}

export const ascending = 'asc'
export const descending = 'desc'
export const sortUp = 'sort-up'
export const sortDown = 'sort-down'

export const espmUrl = 'https://portfoliomanager.energystar.gov'
export const esLearnMore = `${espmUrl}/webservices/pdf/Connection_and_Sharing_for_Data_Exchange_en_US.pdf`
export const esPortfolioManager = `${espmUrl}/pm/contact/add`
export const esShareProperties = `${espmUrl}/pm/sharing/wsBulkSharing`
export const FUND_BAU_LINE = 'fundBAULine'
export const gapFillLearnMore = 'https://sieraglobal.zendesk.com/hc/en-gb/articles/11107134286877-Gap-Filling-Methodology-Explained'

export const API_ERROR = 'api_error'

export const getESErrorMessage = {
  1: I18n('t_es_step_one_error'),
  2: I18n('t_es_step_two_error'),
  3: I18n('t_es_step_three_error'),
  6:I18n('t_es_api_error'), //API error
  null: ''
}

export const ASSET_PERFORMANCE_TYPES = Object.freeze({
  co2Emission : 'co2Emission',
  costIntensity : 'costIntensity',
  energyIntensity : 'energyIntensity',
  carbonIntensity : 'carbonIntensity'
})

export const TOP = 'top'

export const EXPORT_TYPE_JPEG='JPEG'
export const EXPORT_TYPE_PNG='PNG'

export const EXPORT_IMAGE_SCALES = Object.freeze({
  small: 1,
  medium: 2,
  large: 3
})

export const SYNC_LABELS_OBJECT_TITLE_CASE = Object.freeze({
  MONTHLY: 'Monthly',
  YEARLY: 'Yearly',
})

export const syncFrequencyList = [
  {label: I18n('t_monthly'), value: SYNC_LABELS_OBJECT_TITLE_CASE.MONTHLY},
  {label: I18n('t_yearly'), value: SYNC_LABELS_OBJECT_TITLE_CASE.YEARLY}
]

export const externalFundBauTooltipHandlerName='externalFundBauTooltipHandler'
export const fundBAUBubbleName='fundBAUBubble'

export const ENERGY_STAR_STEPS = Object.freeze({
  INIT_ESPM_STEP: 1,
  ACCEPT_ESPM_ID_STEP: 2,
  SHARE_PROPERTIES_STEP: 3,
  CHOOSE_ASSETS_STEP: 4,
  SUCCESS_STEP: 5
})

export const UNITED_STATES = 'United States'
export const CANADA = 'Canada'
export const checkCountries = [UNITED_STATES, CANADA]

// The minimum required data set size to show the top/bottom performers in the
// Portfolio View. It doesn't really make sense to change this from 10, but if you do, make sure
// it is an EVEN number as it is used when dividing the result set into top/bottom performers.
// It is also referenced in the portfolio-service.
export const PORTFOLIO_MINIMUM_DATA_SET_SIZE = 10

export const DROP_DOWN_HEIGHT = '15.8em'
export const EXPORT_AS_PDF = 'Export as PDF'
export const ARIA_CONTROLS_OVERLAY_PANEL = 'overlay_panel'
export const GRAPH_MAX_YEAR_2050= 2050
export const PATHWAY_NAME= 'pathway Name'

export const ASSET_NZC_STRANDINGS = Object.freeze({
  bau: 'BAU',
  actionBasedPathway: 'Action Based Pathway',
  assetBAU: 'Asset BAU',
})

export const SBT_VERSIONS = Object.freeze({
  sbt_1_5: 1.5,
  sbt_2_0: 2.0
})

export const DD_MM_YYYY = 'DD/MM/YYYY'
export const MM_DD_YYYY = 'MM/DD/YYYY'
export const REMOVE = 'REMOVE'
export const CANCEL = 'CANCEL'
export const DATA_FROM = {
  FUND: {
    FUND_DATA_QUALITY: 'fund-data-quality',
    FUND_DATA_QUALITY_V2: 'fund-data-quality-v2',
    FUND_PERFORMANCE: 'fund-performance',
    FUND_ACTION_PLAN: 'fund-action-plan',
    FUND_NZC: 'fund-nzc'
  },
  ASSET:{
    ASSET_PORTFOLIO:'asset-portfolio',
    ASSET_PERFORMANCE : 'asset-performance',
    ASSET_DATA_QUALITY : 'asset-dataQuality',
    ASSET_ACTION_PLAN : 'asset-actionPlan',
    ASSET_PASSPORT : 'asset-passport',
  }
}
export const EXPORT = 'Export'
export const ELLIPSIS = 'ellipsis'

export const singleUtilityFundDQExportSheetNames = {
  'total' : 'Total',
  'tenant' : 'Tenant',
  'landlord' : 'Landlord'
}

export const ActionImpactList = Object.freeze({
  Biodiversity: 'Biodiversity',
  Building_Performance: 'Building Performance',
  Community_Engagement: 'Community Engagement',
  Energy: 'Energy',
  Governance_Recommendations: 'Governance Recommendations',
  Health_And_Wellbeing: 'Health & Wellbeing',
  Social_Value: 'Social Value',
  Tenant_Engagement: 'Tenant Engagement',
  Waste: 'Waste',
  Water: 'Water',
  Other: 'Other'
})

export const SUPPORT_GUIDES  = Object.freeze({
  organisationsList: 'https://sieraglobal.zendesk.com/hc/en-gb/articles/8143069364753',
  fundsList: 'https://sieraglobal.zendesk.com/hc/en-gb/articles/8143107856785',
  fundDashboard: 'https://sieraglobal.zendesk.com/hc/en-gb/articles/8143115290769',
  fundPerformance: 'https://sieraglobal.zendesk.com/hc/en-gb/articles/8143212386577',
  fundDataQuality: 'https://sieraglobal.zendesk.com/hc/en-gb/articles/8143307945233',
  fundActionPlan: 'https://sieraglobal.zendesk.com/hc/en-gb/articles/8143373390225',
  assetPortfolio: 'https://sieraglobal.zendesk.com/hc/en-gb/articles/4403015082257',
  assetDashboard: 'https://sieraglobal.zendesk.com/hc/en-gb/articles/4404146452753',
  assetPerformance: 'https://sieraglobal.zendesk.com/hc/en-gb/articles/4404141442961',
  assetDataQuality: 'https://sieraglobal.zendesk.com/hc/en-gb/articles/4404146818321',
  assetActionPlan: 'https://sieraglobal.zendesk.com/hc/en-gb/articles/4404141447697',
  assetPassport: 'https://sieraglobal.zendesk.com/hc/en-gb/articles/4404141452433',
  guides: 'https://sieraglobal.zendesk.com/hc/en-gb/sections/4403780291089-Guides'
})

export const RELEASE_NOTES  = Object.freeze({
  releaseVersion: 'https://sieraglobal.zendesk.com/hc/en-gb/sections/360005510677-Releases'
})

export const QueryKeyPrefix = Object.freeze({
  ASSET_NZC_ACTIONDETAILS: 'asset-nzc-actionDetails',
  ASSET_AVAILABLE_UTIL_METERS: 'asset-available-util-meters',
  ASSET_ACTIONS: 'asset-actions',
  ASSET_ACTIVE_SURVEY: 'asset-active-survey',
  ASSET_PERFORMANCE_DATA: 'asset-performance-data',
  FUND_DATA_QUALITY_ASSETS: 'fund-data-quality-assets',
  FUND_DATA_QUALITY_SUMMARY: 'fund-data-quality-summary',
  PORTFOLIO_FILTER_OPTIONS: 'portfolio_filter_options',
  PORTFOLIO_VIEW_DATA: 'portfolio_view_data',
  PORTFOLIO_EXPORT_DATA: 'portfolio_export_data',
  DOCUMENT_LIST_DATA: 'document_list_data',
  ENERGY_RATING: 'energy_rating',
  CONSUMPTION_EVIDENCE_DATA: 'consumption_evidence_data',
  AVAILABLE_STORAGE_LIMIT:'userStorageLimit',
  FILTER_OPTIONS:'filter_options'
})

export const typeOfRecordOptions = [
  {label: I18n('t_consumption'), value: 'Consumption'},
]

export const typeOfActions = [
  {label: I18n('t_add_action_plan_in_bulk'), value: 'add'},
  {label: I18n('t_edit_action_plan_in_bulk'), value: 'edit'}
]

export const metersIncludedOptions = [
  {label: I18n('t_landlord_procured_meters'), value: 'Landlord procured meters'},
  {label: I18n('t_tenant_procured_meters'), value: 'Tenant procured meters'},
  {label: I18n('t_both'), value: 'Both'},
]

export const getHeaderNamesForPrePopulatedTemp={
  assetReference:'t_asset_reference',
  assetName:'t_asset_name',
  utility:'t_req_utility',
  areaCovered:'t_area_covered',
  meterStatus: 't_active_inactive',
  meterSerialNumber:'t_serial_number',
  meterMPAN:'t_required_meter_pan',
  meterId:'t_required_meter_id',
  fromDate:'t_required_from_date',
  toDate:'t_required_to_date',
  consumption:'t_required_consumption',
  consumptionType:'t_required_estimate_actual',
  origin:'t_required_origin',
  meterComment:'t_comment'
}

export const verticalEllipsisJson = {
  'action': {
    'buttonName': 't_upload_bulk_action_plan',
    'displayBulkUploadButton': false,
    'displayAssetAddbutton': false
  },
  'consumption': {
    'buttonName': 't_upload_bulk_consumption',
    'displayBulkUploadButton': false,
    'displayAssetAddButton': false,
    'addButtonName': 't_add_asset',
    'editAssetButtonName': 't_edit_asset',
    'displayAssetEditButton': true,
  }
}

export const bulkUpload = {
  'action-upload': {
    'title': 't_upload_action_plans',
    'subtext': 't_action_plan_improvement_sub_text',
    'pointOne': 't_action_plan_upload_template_point_one',
    'pointOneSubtext': 't_action_plan_upload_point_one_subtext',
    'pointTwoSubtext':'t_action_plan_upload_point_two_subtext',
    'buttonName': 't_upload_template',
    'pointTwo': 't_action_plan_point_two',
  },
  'consumption-upload': {
    'title': 't_upload_consumption_data',
    'subtext': 't_consumption_upload_sub_text',
    'pointOne': 't_consumption_upload_template_point_one',
    'pointOneSubtext': 't_consumption_upload_point_one_subtext',
    'pointTwoSubtext':'t_action_plan_upload_point_two_subtext',
    'buttonName': 't_upload_via_oneschema',
    'pointTwo': 't_upload_consumption_data',
  },
  'top-down-pathways':{
    'title': 't_upload_nzc_top_down_pathways',
    'subtext': 't_upload_nzc_top_down_pathways_subtext',
    'pointOne': 't_upload_nzc_top_down_pathways_point_one',
    'pointOneSubtext': 't_upload_nzc_top_down_pathways_point_one_subtext',
    'pointTwoSubtext':'t_upload_nzc_top_down_pathways_point_two_subtext',
    'buttonName': 't_upload_via_oneschema',
    'pointTwo': 't_upload_nzc_top_down_pathways_point_two',
  }
}

export const GROUP_DETAILS = 'groupsDetails'
export const FUND_DASHBOARD = 'FundDashboard'
export const FUND_REPORTS = 'FundReports'
export const FUND_ACTION_PLAN = 'fundActionPlan'
export const EDIT_FUND_ACTION_PLAN = 'fundActionPlanBulkEdit'
export const ASSET_ACTION_PLAN_BULK_UPLOAD = 'assetActionPlanBulkUpload'
export const ASSET_ACTION_PLAN_BULK_UPLOAD_EDIT = 'assetActionPlanBulkUploadEdit'
export const PORTFOLIO_ACTION_PLAN = 'portFolioBulkActionPlanUpload'
export const EDIT_PORTFOLIO_ACTION_PLAN = 'portfolioActionPlanBulkEdit'
export const ASSET_DASHBOARD = 'assetDashboard'
export const ASSET_ACTION_PLAN = 'assetActionPlan'
export const BOTH = 'Both'
export const LANDLORD = 'Landlord'
export const TENANT = 'Tenant'
export const PORTFOLIO = 'portfolio'
export const ALPHABETS_REGEX = /[^a-zA-Z]+/g
export const FUND_DASHBOARD_SPACIAL_SYMBOLS_REGEX = /_&_|\s|,_/gi
export const SIERAPLUS_VERSION = process.env.REACT_APP_VERSION
export const ATKINSON_HYPERLEGIBLE_REGULAR = 'AtkinsonHyperlegibleRegular'
export const ATKINSON_HYPERLEGIBLE_BOLD = 'AtkinsonHyperlegibleBold'
export const FUND_NZC_TOP_DOWN_PATHWAYS = 'fund-net-zero-carbon'

export const nzcIntensityGraphOptions = [
  {label: I18n('t_nzc_carbon_intensity'), value: 'carbonIntensity' },
  {label: I18n('t_energy_intensity_nzc'), value: 'energyIntensity'}
]

export const nzcStaticIntensityOptions = [
  {label: I18n('t_nzc_carbon_intensity'), value: 'Carbon' },
  {label: I18n('t_energy_intensity_nzc'), value: 'Energy'}
]

export const LanguageListMenu = [
  {label: I18n('t_english'), value: LOCALES.ENGLISH},
  {label: I18n('t_german'), value: LOCALES.GERMAN},
  {label: I18n('t_french'), value: LOCALES.FRENCH},
  {label: I18n('t_italian'), value: LOCALES.ITALIAN},
  {label: I18n('t_spanish'), value: LOCALES.SPANISH},
  {label: I18n('t_swedish'), value: LOCALES.SWEDISH},
]

export const UnitsListMenu = [
  {label: I18n('t_metric'), value: UNITS.METRIC},
  {label: I18n('t_imperial'), value: UNITS.IMPERIAL},
]

export const CurrencyUnitsListMenu = [
  {label: 'GBP', value: CURRENCY_UNITS.BRITISHPOUND},
  {label: 'EUR', value: CURRENCY_UNITS.EUROS},
  {label: 'USD', value: CURRENCY_UNITS.USDOLLAR},
]

export const SCOPES_MESSAGE_KEYMAP = {
  scope1: 't_scope1',
  scope2: 't_scope2',
  scope3: 't_scope3'
}

export const MENU_ITEM_ALL='All'
export const CLASS_MENU_TRACK='menu-track'
export const CLASS_SDROPDOWN='sDropdown'

// Link transfer constants
export const LINK_TRANSFER  = Object.freeze({
  TAB_ID: 'TAB_ID',
  LINK_TIME_STAMP: '_lts'
})

export const BENCHMARK_OPTIONS = Object.freeze({
  noBenchmark: null,
  office: 'BBP REEB Office A/C Typical Practice',
  shoppingCenter: 'BBP REEB Enclosed SC A/C Typical Practice'
})

export const SPLIT_TYPES_MESSAGE_KEYMAP = {
  landlord: 't_landlord',
  tenant: 't_tenant'
}

export const TONNE_OR_MEGAWATT=1000
export const UNIT_1K=1000
export const UNIT_1M=1000000

export const PageAccessLevels = Object.freeze({
  ASSET: 'ASSET',
  FUND: 'FUND'
})

export const ACTIONS_STATUS = Object.freeze({
  OVERDUE:'Overdue',
  AGREED : 'Agreed',
  APPLICABLE : 'Applicable',
  UNDER_REVIEW : 'UnderReview',
  COMPLETED : 'Completed'

})

export const PERIOD_VIEWS = Object.freeze({
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly'
})

export const GAP_FILLING = 'gap-filling'

export const QUERYKEYS_TO_INVALIDATE_ON_CURRENCY_OR_UNIT_CHANGE = ['asset-utility-performance',QueryKeyPrefix.FUND_DATA_QUALITY_ASSETS,'actionDetails' ]

export const FORM_FIELD_VARIANT_SIZE = {
  LARGE: 'LARGE',
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
}

export const KEYBOARD_ACTIONS = ['Enter', 'Space']

export const DROPDOWN_TYPE = {
  SINGLE: 'SINGLE',
  MULTI: 'MULTI',
  MULTI_GROUP: 'MULTI_GROUP',
}

export const SYNC_COMPLETION_STATUS = Object.freeze({
  SUCCESS: 'success',
  ERROR: 'error'
})

export const METRIC_REPORT_STATUS = Object.freeze({
  GENERATED: 'GENERATED',
  INPROCESS: 'IN_PROCESS',
  FAILED: 'FAILED',
  FETCHED: 'FETCHED'
})

export const COL_FIELD_UTILITY = {
  CarbonPercentage: 'carbonPercentage',
  ElectricityPercentage: 'electricityPercentage',
  FuelsPercentage:'fuelsPercentage',
  WaterPercentage: 'waterPercentage',
  WastePercentage:'wastePercentage',
}

export const Views  = Object.freeze({
  AssetDashboard: 'AssetDashboard',
  FundDashboard: 'FundDashboard'
})

export const oneSchemaTemplate = Object.freeze({
  consumptionUpload: 'consumption_upload',
  assetActionPlans: 'assets_action_plans',
  fundNzcTopDownPathways: 'fund_nzc_top_down_pathways'
})

export const topdownPathwayColors = [
  CSS_VARIABLES.pink_dark,
  CSS_VARIABLES.foliage,
  CSS_VARIABLES.green_dark,
  CSS_VARIABLES.brown,
  CSS_VARIABLES.red,
  CSS_VARIABLES.blue_dark,
  CSS_VARIABLES.grey_light,
]

export const DOCUMENT_TYPE=[{label:'Sustainability action plan',value:'Sustainability action plan'},
  {label:I18n('t_audit_report'),value:'Audit/Recommendations report'},
  {label:I18n('t_assurance_doc'),value:'Assurance document'},
  {label:I18n('t_fund_report'),value:'Fund reports'},
  {label:I18n('t_green_building_certificate'),value:'Green Building Certificate'},
  {label:I18n('t_energy_rating_certificate'),value:'Energy Rating Certificate'},
  {label:I18n('t_renewable_energy_certificate'),value:'Renewable energy certificate'},
  {label:I18n('t_other'),value: 'Other'}]

export const RECORD_TYPE = [{label:I18n('t_fund'),value:'Fund'},
  {label:I18n('t_assets'),value:'Asset'}]

export const GRESB_SECTOR = {
  EDUCATION: [
    'Library',
    'Other',
    'School',
    'University'
  ],
  HEALTHCARE: [
    'Healthcare Center',
    'Medical Office',
    'Other',
    'Senior Homes'
  ],
  HOTEL: ['Hotel'],
  INDUSTRIAL: [
    'Industrial Park',
    'Manufacturing',
    'Other'
  ],
  INDUSTRIAL_DISTRIBUTION_WAREHOUSE:[
    'Industrial: Non-refrigerated Warehouse',
    'Industrial: Refrigerated Warehouse'
  ],
  LODGING_LEISURE_CREATION: [
    'Fitness Center',
    'Indoor Arena',
    'Museum/Gallery',
    'Performing Arts',
    'Swimming Center',
    'Other'
  ],
  MIXEDUSE: [
    'Office/Industrial',
    'Office/Retail',
    'Other',
    'Office/Residential'
  ],
  OFFICE: [
    'Business Park',
    'Corporate: High-Rise Office',
    'Corporate: Low-Rise Office',
    'Corporate: Mid-Rise Office',
    'Other'
  ],
  OTHER: [
    'Other',
    'Parking (Indoors)',
    'Self-Storage'
  ],
  RESIDENTIAL_MULTIFAMILY: [
    'High-Rise Multi-Family',
    'Low-Rise Multi-Family',
    'Mid-Rise Multi Family'
  ],
  RESIDENTIAL_SINGLEFAMILY: [
    'Family Homes',
    'Other',
    'Retirement Living',
    'Student Living'
  ],
  RETAIL_HIGH_STREET:['Retail: High Street'],
  RETAIL_SHOPPING_CENTER: [
    'Restaurants/Bars',
    'Retail Centers: Lifestyle Center',
    'Retail Centers: Shopping Center',
    'Retail Centers: Strip Mall',
    'Other'
  ],
  RETAIL_WAREHOUSE:['Retail Centers: Warehouse'],
  TECHNOLOGY_SCIENCE: [
    'Data Center',
    'Laboratory/Life Sciences',
    'Other'
  ]
}

export const ACTIONS_FILE_TYPE = {
  ADD_ACTIONS: 'ActionsAdd',
  EDIT_ACTIONS: 'ActionsEdit'
}

export const UpdateActions = {
  EDIT: 'Edit',
  DELETE: 'Delete',
  CANCEL: 'Cancel',
  CLOSE: 'Close'
}

export const EnergyUnitNames = {
  KWH: 'kWh',
  MWH: 'MWh',
  GWH: 'GWh'
}

export const BTUUnitNames = {
  KBTU: 'kBtu',
  MBTU: 'MBtu'
}

export const CO2UnitNames = {
  KGCO2: 'kgCO₂e',
  TCO2: 'tCO₂e'
}

export const STRANDINGS = {
  BAU:'BAU',
  AGREED_ACTIONS_PATHWAY: 'Agreed Actions Pathway',
  APPLICABLE_ACTIONS_PATHWAY: 'Applicable Actions Pathway'
}