import React from 'react'
import {Skeleton} from 'primereact/skeleton'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import '../../../pages/energy-star/energy-star-connection/energy-star-link/ESConnectionStep.scss'

const ESAssetsSkeleton = () => {
  const dummyArray = Array.from({ length: 5 })
  const bodyTemplate = (length) => {
    return <Skeleton width={length}/>
  }

  const headerTemplate = () => {
    return <div className="ml:col-1">
      <Skeleton width={'4rem'}/>
    </div>
  }

  return (
    <div className="custom-skeleton p-rel flex flex-column align-items-center users-page-skeleton">
      <DataTable value={dummyArray} tableClassName={'es-asset-table no-border mb:col-5'}>
        <Column field="esPropertyName" header={headerTemplate()} body={()=>bodyTemplate('20rem')} className={'es-property-name'}/>
        <Column field="esPropertyAddress" header={headerTemplate()} body={()=>bodyTemplate('30rem')} className={'es-property-address'}/>
        <Column field="esPropertyFund" header={headerTemplate()} body={()=>bodyTemplate('15rem')} className={'es-property-fund'}/>
        <Column field="esPropertyLastSync" header={headerTemplate()} body={()=>bodyTemplate('15rem')} className={'es-property-last-sync'}/>
      </DataTable>
    </div>
  )
}

export default ESAssetsSkeleton
