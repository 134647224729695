import React, {useState} from 'react'
import '../ESConnectionStep.scss'
import I18n from '../../../../../../utils/i18n/I18n'
import {Button} from 'primereact/button'
import {InputText} from 'primereact/inputtext'
import {ENERGY_STAR_STEPS, getESErrorMessage} from '../../../../../../utils/helpers/Constants'
import {esAcceptPendingAccountSharingRequest} from '../../../../../../services/energy-star/energystar-service'
import {getLocalizedValue} from '../../../../../../utils/helpers/Helper'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const ESConnectionStepTwo = (props) => {
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const [accountId, setAccountId] = useState('')
  const accountIdPlaceholder = getLocalizedValue(selectedLanguage, 't_example')

  async function confirmConnection() {
    try {
      await esAcceptPendingAccountSharingRequest(accountId)
      props.onStepChange(ENERGY_STAR_STEPS.ACCEPT_ESPM_ID_STEP)
    } catch (e) {
      props.isError(getESErrorMessage[2])
    }
  }

  function onCancelConnection(){
    props.onStepChange(null)
  }

  function onChangeAccountId(e) {
    const reg = /^[0-9\b]+$/
    if (e.target.value === '' || reg.test(e.target.value)) {
      setAccountId(e.target.value)
    }
  }

  return (
    <div className={'es-connection-step'} role={'esc-two-info'}>
      <span className={'step-sub-text step-sub-info'} >{I18n('t_es_account_id')}</span>
      <InputText
        value={accountId}
        id='accountId'
        name='accountId'
        onChange={onChangeAccountId}
        className={'step-input'}
        placeholder={`${accountIdPlaceholder} 305427`}
        role={'account-id'}
      />
      <span className={'step-request-text'}>{I18n('t_es_not_recognise_request')}</span>
      <div role={'ecs-two-buttons'}>
        <Button disabled={accountId.length <= 4} className={'common-blue-bg-btn step-button'} onClick={confirmConnection} label={I18n('t_es_confirm_connection')} />
        <Button className={'grey-bordered-whit-bg-btn step-cancel-btn'} onClick={onCancelConnection} label={I18n('t_cancel')} />
      </div>
    </div>
  )
}

export default ESConnectionStepTwo