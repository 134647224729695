import React, {useContext, useRef} from 'react'
import {Sidebar} from 'primereact/sidebar'
import './Header.scss'
import settingTeal from '../../../resources/images/icon/settings.teal.svg'
import avatarTeal from '../../../resources/images/icon/avatar.teal.svg'
import logoutIconTeal from '../../../resources/images/icon/logout-arrow.teal.svg'
import {FeatureFlagContext} from 'Contexts'
import NotificationMenu from '../../pages/settings/notifications/NotificationMenu'
import LanguageMenu from '../../pages/settings/Language/Languages'
import I18n from '../../../utils/i18n/I18n'
import {Accordion, AccordionTab} from 'primereact/accordion'
import {Button} from 'primereact/button'
import Avatar from 'react-avatar'
import {getLocalizedValue, getLocalStorageItem, getUserRole} from '../../../utils/helpers/Helper'
import Units from '../../pages/settings/unit/Units.js'
import CSS_VARIABLES from '../../../resources/css/_variables.scss'
import Currency from '../../pages/settings/currency/Currency'
import {LanguageListMenu, UnitsListMenu, CurrencyUnitsListMenu} from '../../../utils/helpers/Constants'
import { useLoginAuthContext } from '../../pages/login/auth0/UserInfoProvider'

import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup'
import { Toast } from 'primereact/toast'

const SettingSidebar = (props) => {
  const { loginState: { userInfo, user } } = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const featureContext = useContext(FeatureFlagContext)
  const featureState = featureContext.featureState
  const CLASS_HEADER_HIGHLIGHT_CLICKABLE_OPACITY = 'header-highlight-clickable-opacity'
  const getFeatureFlagValue = featureState.sieraplus_fundViews === true
  const notificationMenu = [
    {label: I18n('t_email_notifications'), isSelected: true},
  ]
  const validateLocationUS = getLocalStorageItem('validateLocationUS')
  const {loginState, logOutOfAuthAndApp, changePassword} = useLoginAuthContext()
  const isChangePasswordAvailable = loginState.user.connectionStrategy === 'auth0'


  const toast = useRef(null)
  const accept = () => {
    console.log('accept')
    changePassword.mutate()
    toast.current.show({ severity: 'info', summary: 'Password change confirmed', detail: `a reset password email has been sent to ${loginState.user.email}`, life: 3000 })
  }

  const reject = () => {
    toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 })
  }


  const confirmPasswordChange = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: `Are you sure you want to change password for ${loginState.user.email}?`,
      icon: 'pi pi-exclamation-triangle',
      accept,
      reject
    })
  }

  return (
    <Sidebar visible={props.visibleRight} position="right" baseZIndex={1000000} onHide={() => props.isVisible()}
      className="side-bar" showCloseIcon={false} appendTo={'self'} blockScroll>
      {!getUserRole() || !getFeatureFlagValue ?
        <div className="sidebar-header">
          <div className="settings">
            <span className="settings-icon">
              <img src={settingTeal} alt={getLocalizedValue(selectedLanguage, 't_settings')}/>
            </span>
            <span className="settings-title">{I18n('t_settings')}</span>
          </div>
        </div> : null}
      <div className={getFeatureFlagValue ? 'user-Details' : 'user-Details b-bottom'}>
        <div>
          {getLocalStorageItem('userName') ?
            <Avatar className="header-react-avatar" name={getLocalStorageItem('userName')}
              src={user.picture} round={true}
              textSizeRatio={2.5} color={CSS_VARIABLES.forest_10} fgColor={CSS_VARIABLES.forest}
            /> : null
          }
        </div>
        <div className="user-basic-info">
          <span className="user-name">{getLocalStorageItem('userName')}</span>
          <span className="user-name-caption">{getLocalStorageItem('instanceDescription')}</span>
        </div>
      </div>
      <div className={'side-bar-menu'}>
        {getUserRole() && (featureState.sieraplus_userAndAssetAdmin || featureState.sieraplus_userGroupAdmin || featureState.sieraplus_energyStarIntegration)?
          <>
            <div className="sidebar-header" data-testid={'sidebar-header'}>
              <div className="settings">
                <span className="settings-icon">
                  <img src={settingTeal} alt={getLocalizedValue(selectedLanguage, 't_site_admin')}/>
                </span>
                <span className="settings-title">
                  {getLocalizedValue(selectedLanguage, 't_site_admin')}
                </span>
              </div>
            </div>
            {featureState.sieraplus_userAndAssetAdmin && (
              <Button label={I18n('t_users')} icon="pi pi-check" iconPos="right" className={'my-profile'}
                onClick={() => props.usersNavigate()} data-testid={'users-btn'}/>)}
            {featureState.sieraplus_userGroupAdmin && (
              <Button label={I18n('t_groups')} icon="pi pi-check" iconPos="right" className={'my-profile'}
                onClick={() => props.groupsNavigate()} data-testid={'groups-btn'}/>)}
            {(featureState.sieraplus_energyStarIntegration && validateLocationUS?.hasUserAssetInUSOrCanada && getUserRole()) && (
              <Button label={I18n('t_energy_star_integration')} icon="pi pi-check" iconPos="right" className={'my-profile'}
                onClick={() => props.energyStarNavigate()} data-testid={'energy-btn'}/>)}
            <div className="sidebar-header mt-5" data-testid={'account-header'}>
              <div className="settings">
                <span className="settings-icon">
                  <img src={avatarTeal} alt={getLocalizedValue(selectedLanguage, 't_settings')}/>
                </span>
                <span className="settings-title">
                  {getLocalizedValue(selectedLanguage, 't_my_account')}
                </span>
              </div>
            </div>
          </>
          : null
        }
        {featureState.sieraplus_userAndAssetAdmin && (
          <>
            <Button label={getLocalizedValue(selectedLanguage, 't_my_profile')} icon="pi pi-check"
              iconPos="right" className={getUserRole() ? 'my-profile' : 'my-profile b-top b-no-bottom'}
              onClick={() => props.navToUser()} data-testid={'my-profile-btn'}/>
            {/* <Button label={getLocalizedValue(selectedLanguage, 't_my_groups')} icon="pi pi-check"
              iconPos="right" className={getUserRole() ? 'my-groups' : 'my-groups b-top'}
              onClick={() => props.navToMyGroups()} data-testid={'my-groups-btn'}/> */}
          </>
        )}
        <Accordion className={'settings-accordion'}>
          <AccordionTab header={I18n('t_notifications')} data-testid={'notification-menu'}>
            <NotificationMenu menudata={notificationMenu[0]}/>
          </AccordionTab>
          <AccordionTab header={I18n('t_language')} data-testid={'language-menu'}>
            <LanguageMenu menudata={LanguageListMenu[0]}/>
            <LanguageMenu menudata={LanguageListMenu[1]}/>
            <LanguageMenu menudata={LanguageListMenu[2]}/>
            <LanguageMenu menudata={LanguageListMenu[3]}/>
            <LanguageMenu menudata={LanguageListMenu[4]}/>
            <LanguageMenu menudata={LanguageListMenu[5]}/>
          </AccordionTab>
          {featureState.sieraplus_unitSystem &&
            <AccordionTab header={I18n('t_units')} data-testid={'units-menu'}>
              <Units menudata={UnitsListMenu}/>
            </AccordionTab>
          }
          {featureState.sieraplus_currency &&
            <AccordionTab header={I18n('t_currency')} data-testid={'currency-menu'}>
              <Currency menudata={CurrencyUnitsListMenu}/>
            </AccordionTab>
          }
          {isChangePasswordAvailable && 
          <AccordionTab header={I18n('t_change_password')} data-testid={'password-menu'}>
            <Toast ref={toast} />
            <ConfirmPopup />
            <div className="card flex flex-wrap gap-2 justify-content-center">
              <Button onClick={confirmPasswordChange} icon="pi pi-check" label={I18n('t_change_password')}></Button>
            </div>
          </AccordionTab>
          }
        </Accordion>
      </div>
      <div className={`logout ${CLASS_HEADER_HIGHLIGHT_CLICKABLE_OPACITY}`} onClick={() => logOutOfAuthAndApp()}>
        <span className={'logout-title'}>{I18n('t_logout')}</span>
        <span className={'logout-icon'}>
          <img src={logoutIconTeal} alt="logout" className="logout-icon"/>
        </span>
      </div>
    </Sidebar>
  )
}

export default React.memo(SettingSidebar)
