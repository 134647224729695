import React, {useRef, useState} from 'react'
import I18n from '../../../../../utils/i18n/I18n'
import {InputText} from 'primereact/inputtext'
import {Button} from 'primereact/button'
import right from '../../../../../resources/images/icon/right.svg'

const ShareAModel = (props) =>{
  const [copyLinkBtnClicked,setCopyLinkBtnClicked] = useState(false)
  const selectLinkRef = useRef(null)
  const link = props?.shareLink

  function handleCopyLinkBtnClick(){
    setCopyLinkBtnClicked(true)
    selectLinkRef.current.select()
    navigator.clipboard.writeText(link)
  }
  return (
    <div className='share-a-model'>
      <span className='link-label' role='link-label'>{I18n('t_link')}</span>
      <InputText
        ref={selectLinkRef}
        value={link}
        id='model-link'
        name='model-link'
        className={'share-a-model__input-text-link'}
      />
      <div className='h-line'/>
      <div className='share-a-model__copy-link-btn-tick-label'>
        <Button data-testid="open-button" label={I18n('t_copy_link')} onClick={handleCopyLinkBtnClick}
          className="common-blue-bg-btn open-button button-highlight share-a-model__copy-link-btn-tick-label--copy-link-btn" role='copy-link-button'/>
        {copyLinkBtnClicked && <>
          <img src={right} alt={I18n('t_tick')} className='share-a-model__copy-link-btn-tick-label--copy-link-tick'/>
          <span className='share-a-model__copy-link-btn-tick-label--copy-link-label' role='copy-link-label'>{I18n('t_link_copied')}</span>
        </>}
      </div>
    </div>
  )
}
export default ShareAModel