import {shortMonths, years, LOCAL_STORAGE_KEYS, PERIOD_TYPES, FY_START_FOR_ASSET_ACCESS} from '../helpers/Constants'
import {CachedUserState} from '../helpers/Helper'
import {GetDefaultDateWithCachedData} from '../helpers/DatePickerUtils'

export const filterQueryParams = (history, queryParams, state) => {
  let params =createQueryParams(queryParams)
  //Global
  history.replace({
    ...history,
    pathname: history.location.pathname,
    search: '?' + params.toString(),
    state: state
  })
}

export function createQueryParams(queryParams) {
  let params = new URLSearchParams(queryParams)
  const allValue = [
    'All',
    'Alles',
    'Tout',
    'Tutti',
    'Todo',
    'Alla'
  ]
  //Portfolio
  if(queryParams.asset === ''){
    params.delete('asset')
  }
  if(allValue.includes(queryParams.fund)){
    params.delete('fund')
  }
  if(allValue.includes(queryParams.location)){
    params.delete('location')
  }
  if(allValue.includes(queryParams.type)){
    params.delete('type')
  }
  if(allValue.includes(queryParams.esScoreRange)){
    params.delete('esScoreRange')
  }
  //Portfolio
  //DataQuality
  if(queryParams.landlordUtility === 'All'){
    params.delete('landlordUtility')
  }
  if(queryParams.landlordArea === 'All'){
    params.delete('landlordArea')
  }
  if(queryParams.tenantUtility === 'All'){
    params.delete('tenantUtility')
  }
  if(queryParams.tenantArea === 'All'){
    params.delete('tenantArea')
  }
  if(queryParams.meterUtility === 'All'){
    params.delete('meterUtility')
  }
  if(queryParams.meterArea === 'All'){
    params.delete('meterArea')
  }
  //DataQuality
  //Performance
  if(queryParams.utility === ''){
    params.delete('utility')
  }
  //Performance
  //AssetActionPlanHome
  if(queryParams.searchAction === ''){
    params.delete('searchAction')
  }
  if(queryParams.page === 0){
    params.delete('page')
  }
  //AssetActionPlanHome
  //Passport
  if(queryParams.performance === ''){
    params.delete('performance')
  }
  //Passport
  //Global
  if(!queryParams.timePeriod){
    params.delete('timePeriod')
  }
  if(!queryParams.noOfMonths){
    params.delete('noOfMonths')
  }
  if(!queryParams.endMonth){
    params.delete('endMonth')
  }
  if(!queryParams.endYear){
    params.delete('endYear')
  }
  return params
}

export const datePickerQueryValues = (timePeriod, pickerValue) => {
  return {
    timePeriodSelected: timePeriod,
    noOfMonths:timePeriod === PERIOD_TYPES.CUSTOM ? pickerValue.pickerValueMonths : '',
    endMonth:timePeriod === PERIOD_TYPES.CUSTOM ? pickerValue.pickerValueEndMonth : '',
    endYear:timePeriod === PERIOD_TYPES.CUSTOM ? pickerValue.pickerValueEndYear : ''
  }
}


export const datePickerQuery = (periodOptions, datePickerValues, setPickerValue) => {
  const newPeriodOptions = [...periodOptions, PERIOD_TYPES.CUSTOM]

  datePickerValues.timePeriodSelected=newPeriodOptions.some(element => element === datePickerValues.timePeriodSelected) === true ? datePickerValues.timePeriodSelected : 'YTD'
  const defaultPicker = GetDefaultDateWithCachedData(FY_START_FOR_ASSET_ACCESS, false, CachedUserState.getUserDataFromCache(LOCAL_STORAGE_KEYS.loginTimestamp))
  if(datePickerValues.timePeriodSelected === PERIOD_TYPES.CUSTOM){
    datePickerValues.noOfMonths=['1', '3', '6', '12'].some(element => element === datePickerValues.noOfMonths) === true ? datePickerValues.noOfMonths : defaultPicker.pickerValueMonths
    datePickerValues.endMonth=shortMonths.some(element => element.value === datePickerValues.endMonth) === true ? datePickerValues.endMonth : defaultPicker.pickerValueEndMonth
    datePickerValues.endYear=years.some(element => element.slice(-2).toString() === datePickerValues.endYear) === true ? datePickerValues.endYear : defaultPicker.pickerValueEndYear

    const customPickerValues = {
      timePeriodSelected: datePickerValues.timePeriodSelected,
      pickerValueMonths: datePickerValues.noOfMonths,
      pickerValueEndMonth: datePickerValues.endMonth,
      pickerValueEndYear: datePickerValues.endYear
    }
    setPickerValue(customPickerValues)
    localStorage.setItem(LOCAL_STORAGE_KEYS.dpCustomTimePeriod, JSON.stringify(customPickerValues))
  } else {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.dpCustomTimePeriod)
  }
}