import React from 'react'
import {Skeleton} from 'primereact/skeleton'
import './FundDataQualityProcurementSkeleton.scss'

const FundDataQualityProcurementSkeleton = () => {
  return (
    <div className="fund-dq-procurement-skeleton">
      <div className="fund-dq-procurement-label-wrap">
        <div className="fund-dq-proc-label">
          <Skeleton width="4px" borderRadius="3px" height="35px" />
          <div className="fund-dq-proc-label-wrap">
            <Skeleton width="40px" borderRadius="3px" height="15px" />
            <Skeleton width="65px" borderRadius="3px" height="10px" />
          </div>
        </div>
        <div className="fund-dq-proc-label">
          <Skeleton width="4px" borderRadius="3px" height="35px" />
          <div className="fund-dq-proc-label-wrap">
            <Skeleton width="40px" borderRadius="3px" height="15px" />
            <Skeleton width="85px" borderRadius="3px" height="10px" />
          </div>
        </div>
        <div className="fund-dq-proc-label">
          <Skeleton width="4px" borderRadius="3px" height="35px" />
          <div className="fund-dq-proc-label-wrap">
            <Skeleton width="40x" borderRadius="3px" height="15px" />
            <Skeleton width="75px" borderRadius="3px" height="10px" />
          </div>
        </div>
        <div className="fund-dq-proc-label">
          <Skeleton width="4px" borderRadius="3px" height="35px" />
          <div className="fund-dq-proc-label-wrap">
            <Skeleton width="40px" borderRadius="3px" height="15px" />
            <Skeleton width="100px" borderRadius="3px" height="10px" />
          </div>
        </div>
      </div>
      <Skeleton width="100%" borderRadius="3px" height="20px" />
    </div>
  )
}

export default FundDataQualityProcurementSkeleton