import React from 'react'
import './PerformanceCard.scss'
import {noDataAvailableMessage} from '../../../../utils/helpers/MessageUtility'
import I18n from '../../../../utils/i18n/I18n'
import {useQuery} from 'react-query'
import {GetNZCTargetPathways} from '../../../../services/funds/nzc-service'
import {noRefetchOnWindowFocus} from '../../../../services/common/useQuery-config'
import NZCPerformanceSummaryCardSkeleton
  from '../../skeletons/NZC-summary-card-skeleton/NZCPerformanceSummaryCardSkeleton'
import { AdjustValue } from '../../../pages/funds/performance/FundPerformanceUtilitySuffixes'
import { CARBON } from '../../../../utils/helpers/Constants'
import PropTypes from 'prop-types'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const PerformanceCard = (props) => {
  const assetLevelValuesFlag = !!props?.assetLevelFlag
  const isDataAvailable  = !!props?.targetPathways
  const getNZCTargetPathwaysQuery  = useQuery(['NZCTargetPathways', props.scopeId], GetNZCTargetPathways, {...noRefetchOnWindowFocus, enabled: !isDataAvailable})
  let nzcAssetGraphData = isDataAvailable? props.targetPathways?.assetGraphData[0] : getNZCTargetPathwaysQuery.data?.assetGraphData[0]
  const reqVal = nzcAssetGraphData?.graphData?.findIndex((g) => g.year === props?.reportingYear)
  let performanceCardData = nzcAssetGraphData?.graphData[reqVal]
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference

  function getAgregrateValues(data, field) {
    return assetLevelValuesFlag ? data[field] : data.reduce((total, currentItem) => total + currentItem[field], 0)  
  }

  return (
    <div className={'performance-card'}>
      {
        getNZCTargetPathwaysQuery.isLoading ?
          <>
            <NZCPerformanceSummaryCardSkeleton/>
          </> :
          (nzcAssetGraphData && performanceCardData ?
            <>
              <div className={'summary-card'}>
                <span className={'card-heading medium-text previousYear'}>{I18n('t_reporting_year')}: {props?.reportingYear}</span>
                <div className={'list-item ex-large-text'}>{I18n('t_nzc_carbon_intensity')} <span className={'ex-large-text-light'}>{Math.round(getAgregrateValues(performanceCardData, 'carbonIntensity') ?? 0).toLocaleString(selectedLanguage, {maximumFractionDigits: 2 })} {I18n('t_kg_co2_m2_yr')}</span></div>
                <div className={'list-item ex-large-text'}>{I18n('t_cost_intensity')} <span className={'ex-large-text-light'}>{Number(getAgregrateValues(performanceCardData, 'costIntensity'))?.toLocaleString(selectedLanguage, {maximumFractionDigits: 2 })} GBP/m²/yr</span></div>
                <div className={'list-item ex-large-text'}>{I18n('t_energy_intensity_nzc')} <span className={'ex-large-text-light'}>{Math.round(getAgregrateValues(performanceCardData, 'energyIntensity') ?? 0).toLocaleString(selectedLanguage, {maximumFractionDigits: 2 })} {I18n('t_kWh_m2_yr')}</span></div>
                <div className={'list-item ex-large-text'}>{I18n('t_co2_emissions')} <span className={'ex-large-text-light'}>{AdjustValue(Number(getAgregrateValues(performanceCardData, 'co2Emission')),CARBON)?.toLocaleString(selectedLanguage, {maximumFractionDigits: 2 })} {I18n('t_ton_co2_yr')}</span></div>
              </div>
            </>
            :
            <div className={'summary-card'}>{noDataAvailableMessage()}</div>
          )
      }
      <>
      </>
    </div>
  )
}

PerformanceCard.propTypes = {
  reportingYear : PropTypes.number
}

export default PerformanceCard