import React from 'react'
import { Skeleton } from 'primereact/skeleton'
import './IndividualMetersMonthLabelsSkeleton.scss'

const IndividualMetersMonthLabelsSkeleton = () => {
  return (
    <>
      <div className="custom-skeleton p-rel flex contour i-m-m-l-s mb-5">
        <div className="flex justify-content-between">
          <span className="performance-headers-skeleton-divider"/>
          <Skeleton width="4rem" borderRadius="0" height="1rem" className="mt-3 mr-2 ml-2"/>
          <Skeleton width="4rem" borderRadius="0" height="1rem" className="mt-3 mr-2 ml-2"/>
          <Skeleton width="4rem" borderRadius="0" height="1rem" className="mt-3 mr-2 ml-2"/>
          <Skeleton width="4rem" borderRadius="0" height="1rem" className="mt-3 mr-2 ml-2"/>
          <Skeleton width="4rem" borderRadius="0" height="1rem" className="mt-3 mr-2 ml-2"/>
          <Skeleton width="4rem" borderRadius="0" height="1rem" className="mt-3 mr-2 ml-2"/>
          <Skeleton width="4rem" borderRadius="0" height="1rem" className="mt-3 mr-2 ml-2"/>
          <Skeleton width="4rem" borderRadius="0" height="1rem" className="mt-3 mr-2 ml-2"/>
          <Skeleton width="4rem" borderRadius="0" height="1rem" className="mt-3 mr-2 ml-2"/>
          <Skeleton width="4rem" borderRadius="0" height="1rem" className="mt-3 mr-2 ml-2"/>
          <Skeleton width="4rem" borderRadius="0" height="1rem" className="mt-3 mr-2 ml-2"/>
          <Skeleton width="4rem" borderRadius="0" height="1rem" className="mt-3 mr-2 ml-2"/>
          <span className="performance-headers-skeleton-divider"/>
        </div>
      </div>
      <div className="year-legend-view custom-skeleton p-rel flex contour mb-5">
        <Skeleton width="2rem" borderRadius="0" height="0.8rem" className="mr-2 ml-2"/>
        <div className={'year-legend-right'}>
          <Skeleton width="2rem" borderRadius="0" height="0.8rem" className="ml-2"/>
        </div>
      </div>
    </>
  )
}

export default IndividualMetersMonthLabelsSkeleton
