import React, {useState, useRef, useContext, useEffect} from 'react'
import './ActionPlanHome.scss'
import BackNavigation from '../../../../common/back-nav/BackNavigation'
import ActionPlanTable from '../actions/ActionPlanTable'
import {OverlayPanel} from 'primereact/overlaypanel'
import {useHistory, useParams} from 'react-router-dom'
import {FeatureFlagContext, PortfolioContext} from 'Contexts'
import AssetExportSelection from '../../../../../utils/data-exports/AssetExportSelection'
import ActionEdit from '../dialogs/Action'
import I18n from '../../../../../utils/i18n/I18n'
import {useQuery} from 'react-query'
import {actionData, activeSurveyAsset} from '../../../../../services/assets/actionplan-service'
import {GetAssetHeaderData} from '../../../../../services/assets/portfolio-service'
import {manualFetch, noRefetchOnWindowFocus} from '../../../../../services/common/useQuery-config'
import moment from 'moment'
import InfoModal from '../../../../common/modal-dialogs/InfoModal'
import {createNavURLForFundPages, disableExcelExport, getUserRole} from '../../../../../utils/helpers/Helper'
import {ASSET_ACTION_PLAN, emptyAction, FUND_VIEWS_PAGES} from '../../../../../utils/helpers/Constants'
import '../../../../../utils/data-exports/ExportOverlayPanel.scss'
import {useAssetAvailableUtilityMeters} from '../../../../../utils/custom-hooks/api-queries/actions-api-queries-hooks'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'
const ActionPlanHome = () => {

  //region Declarations/Initializations
  const {instanceName, orgName, fundName, assetId} = useParams()
  const exportOverlay = useRef(null)
  const pfContext = useContext(PortfolioContext)
  const featureContext            = useContext(FeatureFlagContext)
  const featureState              = featureContext.featureState
  const rowData = pfContext.pfState.data
  const [showActionDlg, setShowActionDlg] = useState(false)
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const currencyUnit = userInfo.currencyUnit
  const unitSystem = userInfo.unitSystem
  const [selectedAction, setSelectedAction] = useState(null)
  const [reFetchData, setReFetchData] = useState(false)
  const history = useHistory()
  const actionPlans = useQuery(['actionPlan', assetId, currencyUnit, unitSystem], actionData, {...noRefetchOnWindowFocus, enabled: !!(assetId)})
  const assetHasActiveSurvey = useQuery(['assetHasActiveSurvey', assetId], activeSurveyAsset)
  const queryValues = new URLSearchParams(new URL(window.location.href).search)
  const actionIDADB = parseInt(queryValues.get('actionIDADB'))
  const [activeSurveyState, setActiveSurveyState] = useState(false)
  const [visibleActiveSurveyDialog, setVisibleActiveSurveyDialog] = useState(false)
  const assetHeaderInfo = useQuery(['assetHeaderData', assetId], GetAssetHeaderData, manualFetch)
  const [period, setPeriod] = useState('t_ytd_uppercase')
  const periodOptions = ['YTD', '3M', '6M', '1Y', '2Y']
  const [pickerValue, setPickerValue] = useState({
    pickerValueMonths: '1',
    pickerValueEndMonth: moment().format('MMM'),
    pickerValueEndYear: moment().format('YY')
  })
  const {data: assetAvailableUtilityMeters, refetch: refetchAssetAvailableUtilityMeters}=useAssetAvailableUtilityMeters(assetId)
  const [isOverlayPanelOpen, setIsOverlayPanelOpen]=useState(false)
  //endregion

  //region useEffect Implementation
  useEffect(()=>{
    if(showActionDlg){
      refetchAssetAvailableUtilityMeters().catch(error=>{
        console.log(error)
      })
    }
  }, [showActionDlg])

  useEffect(() => {
    if (assetHeaderInfo.data) {
      pfContext.pfDispatcher({...pfContext.pfState, ...{data: assetHeaderInfo.data}})
    } else if (rowData && Object.keys(rowData)?.length === 1) {
      assetHeaderInfo.refetch()
    }
  }, [assetHeaderInfo])

  useEffect(() => {
    if (assetHasActiveSurvey && assetHasActiveSurvey.data !== undefined) {
      setActiveSurveyState(assetHasActiveSurvey.data)
    }
  }, [assetHasActiveSurvey.data])

  useEffect(() => {
    if (reFetchData) {
      actionPlans.refetch()
      setReFetchData(false)
    }
    //Filter action-data based on action-id from Dashboard.
    if (actionPlans && actionPlans.data) {
      if (actionIDADB) {
        let actionArray = actionPlans.data.filter((action) => action.action.actionId === actionIDADB)
        if (actionArray && actionArray.length > 0) {
          setSelectedAction(actionArray[0])
          setShowActionDlg(!!actionArray)
        }
      }
    }
  }, [actionPlans.data, reFetchData])

  //endregion

  //region Dialog & Overlay handlers
  const closeOverlay = () => {
    exportOverlay.current.hide()
  }

  const reLoad = () => {
    actionPlans.refetch()
  }

  function groupAction(action) {
    let actionData = []
    if (actionPlans && actionPlans.data) {
      for (const individualAction of actionPlans.data) {
        if (individualAction.action.status === action) {
          actionData.push(individualAction)
        }
      }
    }
    return actionData
  }

  const closeActionDialog = () => {
    setShowActionDlg(false)
    if (actionIDADB) {
      queryValues.delete('actionIDADB')
    }
    closeDialog()
  }

  const closeDialog = () => {
    setSelectedAction('')
    setShowActionDlg(!showActionDlg)
  }

  //endregion

  //region Helper methods

  const onActionClick = () => {
    setSelectedAction(emptyAction)
    // Show action dialog if current asset doesn't have an active survey
    setShowActionDlg(!showActionDlg && !activeSurveyState)
    // Show active survey warning dialog
    setVisibleActiveSurveyDialog(true)
  }

  const clearActionIDHandler = () => history.replace(createNavURLForFundPages({
    instanceName,
    orgName,
    fundName,
    assetId,
    pathSuffix: FUND_VIEWS_PAGES.actionPlan
  }), {data: rowData})

  //endregion

  //region Datepicker handlers
  const changeTimePeriodHandler = (value) => {
    if (value) {
      setPeriod(value)
    }
  }

  const changeDatePickerHandler = (name, value) => {
    setPickerValue({
      ...pickerValue,
      [name]: value,
    })
  }

  const closeActiveSurveyDialog = () => {
    setVisibleActiveSurveyDialog(false)
  }
  //endregion

  //region Custom templates
  const getPeriodMenuItem = (option) => (option === 'YTD') ?
    <span className='p-button-label p-c period-menu-ytd-tooltip' data-pr-position="top">{option}
    </span> : <span className='p-button-label p-c'>{option}</span>
    //endregion

  // region export props
  const assetActionPlanExportInfoProps = {
    'closeOverlay':closeOverlay, 'actionPlan':true, 'rowData':rowData, // not used anywhere
    'actionData':actionPlans.data, 'language':selectedLanguage,
    'disableExcelExport' :disableExcelExport(actionPlans?.data, 'assetActionPlan'),
    'exportFrom' : {'page-type':'asset-page','from':'asset-actionPlan'},
  }

  function getAssetMetersProps() {
    return {assetAvailableUtilityMeters, refetchAssetAvailableUtilityMeters}
  }

  // endregion

  return (
    <div data-page_identifier="asset_action_plan" className="action-plan-home action-plan grey-bg" data-testid={'asset-action-plan'}>
      <div className="container-layout grey-bg gutter">

        <div role={'back-navigation'}>
          <BackNavigation
            addAction={true}
            onAddActionButtonClick={onActionClick}
            exportName="Export"
            onExportClick={(e) => exportOverlay.current.toggle(e)} aria-haspopup
            disableExportButton = {actionPlans.isLoading || actionPlans?.data?.length === 0}
            aria-controls="overlay_panel"
            period={period}
            options={periodOptions}
            componentName={ASSET_ACTION_PLAN}
            datePickerOnChange={(e) => changeTimePeriodHandler(e.value)}
            itemTemplate={getPeriodMenuItem}
            pickerValue={pickerValue}
            pickerOnChange={(e) => changeDatePickerHandler(e.target.name, e.target.value)}
            hideTimePeriodFilter
            routeVia={ASSET_ACTION_PLAN}
            isOverlayPanelOpen = {isOverlayPanelOpen}
            isActionPlanBulkUploadEnabled={getUserRole() && featureState?.sieraplus_actionPlanBulkUpload}
            bulkUploadConsumptionId={assetId}
            showVerticalEllipsis={true}
          />
        </div>

        <OverlayPanel ref={exportOverlay} appendTo={document.getElementById('export')} className="export-overlay"
          onShow={() => setIsOverlayPanelOpen(true)} onHide={() => setIsOverlayPanelOpen(false)}>
          <AssetExportSelection exportDetails = {assetActionPlanExportInfoProps}/>
        </OverlayPanel>

        <div className="page-content" role={'action-tables'}>
          <ActionPlanTable
            action="AgreedActions"
            title={I18n('t_agreed_actions')}
            onActionClick={onActionClick}
            reLoad={reLoad} activeSurveyState={activeSurveyState}
            actionData={groupAction('AgreedActions')}
            isLoading={actionPlans.isLoading}
            {...getAssetMetersProps()}
          />

          <ActionPlanTable
            action="Applicable"
            title={I18n('t_applicable_actions')}
            onActionClick={onActionClick} reLoad={reLoad}
            activeSurveyState={activeSurveyState}
            actionData={groupAction('Applicable')}
            isLoading={actionPlans.isLoading}
            {...getAssetMetersProps()}
          />

          <ActionPlanTable
            action="ActionsUnderReview"
            title={I18n('t_actions_under_review')}
            actionReview={true}
            onActionClick={onActionClick} reLoad={reLoad}
            activeSurveyState={activeSurveyState}
            actionData={groupAction('ActionsUnderReview')}
            isLoading={actionPlans.isLoading}
            {...getAssetMetersProps()}
          />

          <ActionPlanTable
            action="Completed"
            title={I18n('t_completed_actions')}
            completedAction={true}
            reLoad={reLoad} isLoading={actionPlans.isLoading}
            onActionClick={onActionClick}
            actionData={groupAction('Completed')}
            activeSurveyState={activeSurveyState}
            {...getAssetMetersProps()}
          />

          <ActionPlanTable
            action="NotApplicable"
            title={I18n('t_non_applicable')}
            applicableAction={true}
            reLoad={reLoad} isLoading={actionPlans.isLoading}
            onActionClick={onActionClick}
            actionData={groupAction('NotApplicable')}
            activeSurveyState={activeSurveyState}
            {...getAssetMetersProps()}
          />

        </div>
      </div>
      <div role={'action-dialog'}>
        { showActionDlg &&
            <ActionEdit
              assetId={assetId}
              closeActionDialog={closeActionDialog}
              reLoad={reLoad}
              dlgActionCleanupHandler={actionIDADB ? clearActionIDHandler : null}
              edit={!!actionIDADB}
              activeIndex={0}
              showDialog={showActionDlg}
              selection={selectedAction}
              assetAvailableUtilityMeters={assetAvailableUtilityMeters}
            />
        }

      </div>
      {activeSurveyState && <InfoModal onCloseActiveSurveyDialog={closeActiveSurveyDialog}
        visibleActiveSurveyDialog={visibleActiveSurveyDialog}/>
      }
    </div>
  )
}

export default ActionPlanHome

