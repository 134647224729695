import React from 'react'
import {Skeleton} from 'primereact/skeleton'
import './DashboardCoverageSkeleton.scss'
import PropTypes from 'prop-types'

const DashboardCoverageSkeleton = ({assetDashboard}) => {
  const className = !assetDashboard ? 'db-dq-skeleton' : 'db-asset-dq-skeleton'
  return (
    <div className='dashboard-coverage-skeleton'>
      <Skeleton width="120px" borderRadius="3px" height="12px" />
      <div className={className}>
        <Skeleton shape="circle" size="5.5rem" />
        <div className='dashboard-coverage-skeleton__legends'>
          <div className='dashboard-coverage-skeleton__legends--legend'>
            <Skeleton width="10px" borderRadius="3px" height="10px" />
            <Skeleton width="150px" borderRadius="3px" height="12px" />
          </div>
          <div className='dashboard-coverage-skeleton__legends--legend'>
            <Skeleton width="10px" borderRadius="3px" height="10px" />
            <Skeleton width="150px" borderRadius="3px" height="12px" />
          </div>
        </div>
      </div>
    </div>
  )
}

DashboardCoverageSkeleton.propTypes = {
  assetDashboard: PropTypes.bool
}

export default DashboardCoverageSkeleton