import React from 'react'
import PropTypes from 'prop-types'
import {Sidebar} from 'primereact/sidebar'
import './CustomSidebar.scss'

const CustomSidebar = ({children, isSidebarVisible, setIsSidebarVisible, sidebarHeader, customClass='', dismissable, onHideSidebar, testId}) => {
  const customIcons = (
    <h3 className={'sidebar-title'} role={'title'}>{sidebarHeader}</h3>
  )

  function sidebarCloseHandler() {
    if(onHideSidebar) {
      onHideSidebar()
    } else {
      setIsSidebarVisible(false)
    }
  }

  return <Sidebar 
    visible={isSidebarVisible} 
    position="right" 
    onHide={sidebarCloseHandler}
    showCloseIcon 
    baseZIndex={1000000} 
    blockScroll={true}
    icons={customIcons} 
    className={`custom-sidebar-container ${customClass}`} 
    dismissable={dismissable} 
    data-testid={testId}
    pt={{ closeButton: {'data-testid': 'close-button'} }}
  >
    {children}
  </Sidebar>
}

CustomSidebar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.array]),
  isSidebarVisible: PropTypes.bool,
  setIsSidebarVisible: PropTypes.func,
  sidebarHeader: PropTypes.oneOfType([PropTypes.object,PropTypes.string]),
  customClass: PropTypes.string,
  dismissable: PropTypes.bool.isRequired,
  onHideSidebar: PropTypes.func,
  testId: PropTypes.string
}
export default CustomSidebar