import React, {useEffect, useState} from 'react'
import {Button} from 'primereact/button'
import {Dialog} from 'primereact/dialog'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import I18n from '../../../../../utils/i18n/I18n'
import closeWhite from '../../../../../resources/images/icon/dialog-close-white.svg'
import {Chips} from 'primereact/chips'
import {useMutation} from 'react-query'
import {addNewMissingDataRequest, sameDayDatesCheck} from '../../../../../services/assets/dataquality-service'
import {
  createMissingDataReqObject,
  getLocalizedValue, momentDate,
} from '../../../../../utils/helpers/Helper'
import {assetControl, dqEmailRegex} from '../../../../../utils/helpers/Constants'
import CSS_VARIABLES from '../../../../../resources/css/_variables.scss'
import {UNITS} from '../../../../../utils/i18n/constants'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const RequestDataDialog = ({data, onEventActionHideHandler, requestCleanupHandler, showDialog, reload, assetAddress, assetId}) => {

  //Expected meter-data schema=[{dataGapDate:"", meterComment:"", meterId:0, responsibility:"", utility:"", year: 0, "provider": ""}]
  let closeDg=false
  const [emails, setEmails]= useState([])
  const missingData=data
  const MSG_KEY_REQUEST_DATA = 't_request_data'
  const CLASS_PDIALOG_TITLE='p-dialog-title'

  const [invalidEmails, setInvalidEmails]=useState([])

  const emailPattern = new RegExp(dqEmailRegex)

  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const unitSystem = userInfo.unitSystem

  function isEmailValid(){
    return (emails && emails.length>0 && invalidEmails.length<1)
  }

  function removeEmailFromInvalidEmails(emailToRemove){
    //Remove from [invalidEmails]
    if(emailToRemove){
      let newInvalidEmails=invalidEmails.filter(email=>email!==emailToRemove)
      setInvalidEmails(newInvalidEmails)
    }
  }

  function removeEmailFromEmails(emailToRemove){
    if(emailToRemove){
      //Remove from [emails]
      let newEmails=emails.filter(email=>email!==emailToRemove)
      setEmails(newEmails)
    }
  }

  function emailChipIconRemoveHandler(evt){
    let currTarget=evt.currentTarget
    if(currTarget && currTarget.parentElement && currTarget.parentElement.textContent){
      let emailToRemove=currTarget.parentElement.textContent
      removeEmailFromEmails(emailToRemove)
      removeEmailFromInvalidEmails(emailToRemove)
    }
  }

  function setInvalidEmailColor(){
    let element = document.querySelector('div.p-chips ul.p-inputtext.p-chips-multiple-container')
    if(element){
      Array.from(element.children).forEach((child) => {
        if(emailPattern.test(child.textContent)) {
          child.style.color = CSS_VARIABLES.forest
        } else {
          child.style.color = CSS_VARIABLES.red
        }
      })
    }
  }


  useEffect(() => {
    setInvalidEmailColor()
  }, [emails])

  const emailChangeHandler=(evt)=>setEmails(evt.value)

  const emailAddHandler=(evt)=>{
    let newEmail=evt.value
    if(!emailPattern.test(newEmail)) {
      let prevInvalidEmails = [...invalidEmails]
      prevInvalidEmails.push(newEmail)
      setInvalidEmails(prevInvalidEmails)
    }
  }

  const customChip = (item) => {
    return (
      <div className="label-chip-container">
        <span className="chip-label">{item}</span>
        <i className="pi pi-times chip-circle" onClick={emailChipIconRemoveHandler}/>
      </div>
    )
  }

  function onRemoveEmailHandler(evt) {
    removeEmailFromInvalidEmails(evt.value[0])
  }

  const emailChips=<Chips
    value={emails}
    onChange={(e)=>emailChangeHandler(e)}
    itemTemplate={customChip}
    separator=","
    onAdd={(e)=>emailAddHandler(e)}
    onRemove={onRemoveEmailHandler}
    placeholder={getLocalizedValue(selectedLanguage, 't_email_hint')}
  />

  const missingDataMutation = useMutation(addNewMissingDataRequest, {
    onSuccess: () => {
      onDialogCloseHandler()
      if(reload){
        reload()
      }
    }
  })

  const sendDataHandler = async () => {
    try {
      await missingDataMutation.mutateAsync({assetId, emails: emails, dataGap: createMissingDataReqObject(missingData)})
    } catch (error) {
      console.log(error.toString())
    }
  }

  const renderFooter = (
    <div className="dq-Dlg-footer" data-testid={'dialog-footer'}>
      <div className="footer-send">
        <Button className="send-mail req-dialog-highlight-clickable-opacity" onClick={sendDataHandler}
          disabled={!(isEmailValid())}>{I18n('t_request_data')}</Button>
      </div>
    </div>
  )

  function getDialogTitle() {
    let dialogTitle=getLocalizedValue(selectedLanguage, MSG_KEY_REQUEST_DATA)
    if(assetAddress){
      dialogTitle=`${dialogTitle} - ${assetAddress}`
    }
    return dialogTitle
  }

  const dgHeaderComponent = () => <span className={CLASS_PDIALOG_TITLE} data-testid={'dialog-header'}>{getDialogTitle()}</span>

  const onDialogCloseHandler=()=>{
    onEventActionHideHandler()
  }

  const closeIcon=<img src={closeWhite} alt={getLocalizedValue(selectedLanguage, 't_close')} style={{width: '16px', height: '16px'}}
    className={'req-dialog-highlight-clickable-opacity'} onClick={onDialogCloseHandler} data-testid={'close-icon'}/>

  const providerTemplate = (rowData) => {
    let supplier='-'
    if(rowData.provider){
      supplier=rowData.provider
    }
    return supplier
  }

  const meterNameTemplate = (rowData) => {
    let msgKeyResponsibility=rowData.electricityMPAN ? rowData.electricityMPAN : rowData.responsibility
    if(msgKeyResponsibility && (msgKeyResponsibility===assetControl.Landlord || msgKeyResponsibility===assetControl.Tenant)){
      msgKeyResponsibility=getLocalizedValue(selectedLanguage, `t_${msgKeyResponsibility.toLowerCase()}`)
    }
    return <span>{msgKeyResponsibility}</span>
  }

  const utilTemplate = (rowData) => {
    let utilMsg=''
    if(rowData && rowData.utility){
      utilMsg=<span>{I18n('t_' + rowData.utility.toLowerCase())}</span>
    }
    return utilMsg
  }

  const dataGapNameTemplate = (rowData) => {
    let dataGapTemplate=''
    if(rowData?.dataGapDate){
      dataGapTemplate=<span>{I18n('t_' + rowData.dataGapDate.substring(0, 3).toLowerCase())} {' '}
        {unitSystem === UNITS.IMPERIAL ? rowData.year : rowData.year?.toString().substring(2,4)}</span>
    }
    //ToDO Check this block is required
    if(rowData.partialDateStart || rowData.partialDateEnd) {
      let partialDateStart = rowData.partialDateStart
      let partialDateEnd = rowData.partialDateEnd
      let partialDateStartArray = partialDateStart.split('/')
      let partialDateEndArray = partialDateEnd.split('/')
      let partialStartDate = new Date(partialDateStartArray[2] + '-' +  partialDateStartArray[1] + '-' + partialDateStartArray[0])
      let partialEndDate = new Date(partialDateEndArray[2] + '-' +  partialDateEndArray[1] + '-' + partialDateEndArray[0])
      let partialStartDateFormatted = partialStartDate.toLocaleDateString('en-GB', {day: '2-digit'})
      let partialEndDateFormatted = partialEndDate.toLocaleDateString('en-GB', {year: 'numeric', month: 'short', day: '2-digit'})

      dataGapTemplate = sameDayDatesCheck(partialStartDate, partialEndDate) ? <span>{partialEndDateFormatted}</span> : <span>{partialStartDateFormatted} - {partialEndDateFormatted}</span>
    }
    if(rowData.isPartial){
      let partialStartDate = new Date(rowData.partialStart)
      let partialEndDate = new Date(rowData.partialEnd)
      if(unitSystem === UNITS.IMPERIAL){
        if(momentDate(partialStartDate,'MMM','en-GB') === momentDate(partialEndDate,'MMM','en-GB')){
          let partialStartDateFormatted = momentDate(partialStartDate,'MMM D','en-GB')
          let partialEndDateFormatted = momentDate(partialEndDate,'D, YYYY','en-GB')
          dataGapTemplate = sameDayDatesCheck(partialStartDate, partialEndDate) ? <span>{partialEndDateFormatted} </span> : <span>{partialStartDateFormatted} - {partialEndDateFormatted} </span>
        }else{
          let partialStartDateFormatted = momentDate(partialStartDate,'MMM D','en-GB')
          let partialEndDateFormatted = momentDate(partialEndDate,'MMM D, YYYY','en-GB')
          dataGapTemplate = sameDayDatesCheck(partialStartDate, partialEndDate) ? <span>{partialEndDateFormatted} </span> : <span>{partialStartDateFormatted} - {partialEndDateFormatted} </span>
        }
      }else{
        let partialStartDateFormatted = partialStartDate.toLocaleDateString('en-GB', {day: '2-digit'})
        let partialEndDateFormatted = momentDate(partialEndDate,'D MMM YY','en-GB')

        dataGapTemplate = sameDayDatesCheck(partialStartDate, partialEndDate) ? <span>{partialEndDateFormatted} </span> : <span>{partialStartDateFormatted} - {partialEndDateFormatted} </span>
      }
    }
    return dataGapTemplate
  }


  useEffect(() => {
    return function cleanupRequestDialog() {
      if (requestCleanupHandler) {
        requestCleanupHandler()
      }
    }
  }, [])

  return (
    <Dialog className="dq-Dlg" header={dgHeaderComponent} footer={renderFooter}
      onHide={onEventActionHideHandler} icons={closeIcon} closable={closeDg} visible={showDialog} blockScroll data-testid={'request-data-dialog'}>
      <div className="header-recipient">
        <div className="recipient-label">{I18n('t_req_recipient')}:</div>
        <div className="recipient-input">{emailChips}</div>
      </div>
      <div className={`mail-content${missingData && missingData.length>5?' padding-right':''}`}>
        <DataTable value={missingData} className="dq-Dlg-table" onSort={() => false} data-testid={'dialog-table'}>
          <Column field="responsibility" body={meterNameTemplate} header={I18n( 't_req_meter')} className="meterColumn" />
          <Column field="utility" body={utilTemplate} header={I18n( 't_req_utility')} className="utilityColumn" />
          <Column field="dataGapDate" body={dataGapNameTemplate} header={I18n( 't_req_missing_data_period')} className="missingDataColumn" />
          <Column field="provider" body={providerTemplate} header={I18n( 't_req_supplier')} className="missingDataColumn" />
        </DataTable>
      </div>
    </Dialog>
  )
}

export default RequestDataDialog

RequestDataDialog.displayName = 'RequestDataDialog'