import axiosInstance from './common/axios-config'
import axiosInstancePaneer from './common/axios-config-paneer'
import {CachedUserState, saveUrlParamsIds, SessionUserState} from '../utils/helpers/Helper'

/**
 * FUNCTION NOT USED ANY MORE: logging logic is now handled by the Auth0 implementation
 * stored in the `src\components\pages\login\auth0` folder.
 */
export const LoginUser = async (user) => {
  try {
    const {data} = await axiosInstance.post('/api/v1/Authentication/Login', {
      email: user.username,
      password: user.password
    })
    return data
  } catch (error) {
    console.log(error)
    return error.response
  }
}

export const loginAPI = async (username, password) => {
  let response = {}
  try {
    await axiosInstance.get(`/api/v1/Login?email=${username}&password=${password}`).then(res => {
      response = res.data
    })
    return response
  } catch (e) {
    console.log('error', e)
  }
  console.log('Response: ' + response)
}

export const Logout = () => {
  localStorage.removeItem('userInfo')
  localStorage.removeItem('dpTimePeriod')
  localStorage.removeItem('dpCustomTimePeriod')
  SetAuthorizationToken(null)
  CachedUserState.clearUserDataCache()
  SessionUserState.clearUserDataFromSession()
}

export const SetAuthorizationToken = (token) => {
  if(token) {
    axiosInstance.defaults.headers.common['authorization'] = `Bearer ${token}`
    axiosInstancePaneer.defaults.headers.common['authorization'] = `Bearer ${token}`
  } else {
    delete axiosInstance.defaults.headers.common['authorization']
    delete axiosInstancePaneer.defaults.headers.common['authorization']
  }
}

export const GetUsersConfigurationData = async () => {
  try {
    const {data} = await axiosInstance.get('api/v1/User/Settings')
    return data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const GetUserFundAccesses = async () => {
  try {
    const {data} = await axiosInstance.get('/api/v1/Users/FundAccess')
    return data
  } catch (error) {
    console.log(error)
    throw error
  }
}

//API
export const GetUrlParameterIds = async (keys) => {
  const {instanceName, orgName, fundName, assetName, assetId, actionId}=keys.queryKey[1]
  return await GetUrlParams({instanceName, orgName, fundName, assetName, assetId, actionId})
}

export const GetUrlParams = async ({instanceName, orgName, fundName, assetName, assetId, actionId}) => {
  const queryParams=[]
  if(instanceName){
    queryParams.push(`instanceName=${instanceName}`)
  }
  if(orgName){
    queryParams.push(`organisationName=${orgName}`)
  }
  if(fundName){
    queryParams.push(`fundName=${fundName}`)
  }
  if(assetId){
    queryParams.push(`assetId=${assetId}`)
  } else if(assetName){
    queryParams.push(`assetName=${assetName}`)
  }
  let UrlParameterIdsData
  if (queryParams.length > 0) {
    await axiosInstance.get(`/api/v1/WebsiteNavigation/UrlParameterIds?${queryParams.join('&')}`)
      .then(response => {
        UrlParameterIdsData = {...response.data, actionId: parseInt(actionId)}
        saveUrlParamsIds(UrlParameterIdsData)
      })
      .catch(error => {
        UrlParameterIdsData = error.response
      })
  }
  return UrlParameterIdsData
}

export const Ping = async () => {
  await axiosInstance.head('/api/v1/Authentication/Ping')
}

export const ValidateUserAssetCountry = async () => {
  try {
    const {data} = await axiosInstance.get('api/v1/EnergyStar/GetEnergyStarMatchedSieraPropertyDetails')
    return data
  } catch (error) {
    console.log(error)
    throw error
  }
}