import React from 'react'
import {TabMenu} from 'primereact/tabmenu'
import {
  UserActions,
} from '../../../../../utils/helpers/Constants'
import {tabNavigatable} from '../../../../../utils/helpers/Helper'

function GroupsHeader(props) {

  function getMousePointerClass() {
    return 'tabmenu-add-user'
  }

  function updateActiveIndex(evt){
    const userSelectingTab =evt.value
    if (props.groupAction === UserActions.EDIT_GROUP || tabNavigatable(userSelectingTab.value,props.visited)) {
      props.setActiveIndex(evt.index)
    }
  }


  return (
    <div className={'user-header'}>
      <div className={'addUser-title'}>{props.userHeaderConfig.headerTitle}</div>
      <div className={'addUser-menu p-d-flex p-jc-between'}>
        <TabMenu
          activeIndex={props.activeIndex}
          model={props.userHeaderConfig.tabMenuOptions}
          onTabChange={(e)=>updateActiveIndex(e)}
          className={`user-details-header-tabmenu ${getMousePointerClass()}`}
        />
      </div>
    </div>
  )
}

export default GroupsHeader