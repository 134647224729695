import React from 'react'
import './DataQualityOverviewGraph.scss'
import {getGraphDataForArea, prepareDQGraphData} from '../../../../../services/assets/dataquality-service'
import I18n from '../../../../../utils/i18n/I18n'
import {GRAPH_LABELS_PDF, GRAPH_PDF_CSS, GRAPH_PDF_THICK_LINE} from '../../../../../utils/helpers/Constants'

function DataQualityOverviewGraph({graphData, area, userDataColor}) {
  const secondLineColor = GRAPH_PDF_THICK_LINE[userDataColor]
  const labels = GRAPH_PDF_CSS
  let singleBarGridStyle
  let convertedPercent = []
  let graphLabels = []
  let dataOverviewGraph
  const CLASS_GRAPH_SEPARATOR = 'graph-separator'
  const CLASS_GRAPH_LABEL = 'graph-label'
  let selectedConsumptionTypeLandlord

  if (graphData && graphData.length > 0) {
    dataOverviewGraph = getGraphDataForArea(graphData, area)
  }

  const dataNotEmpty = () => {
    return (dataOverviewGraph)
  }

  if (dataNotEmpty()) {
    prepareDQGraphData(dataOverviewGraph, convertedPercent, graphLabels, labels)
  }

  function getGraphGridUnits() {
    return convertedPercent.map((unit) => {
      let unitValue = '0fr'
      if (unit) {
        unitValue = `${unit}fr`
      }
      return unitValue
    }).join(' ')
  }

  singleBarGridStyle = {
    display: 'grid',
    gridTemplateColumns: getGraphGridUnits()
  }

  const cssClassBuilder = (colorClass) => {
    if (colorClass === secondLineColor) {
      return 'graph-thick ' + colorClass
    } else {
      return 'graph ' + colorClass
    }
  }

  function getGraphLabelsKey(label) {
    return GRAPH_LABELS_PDF[label]
  }

  const getTrackElements = () => convertedPercent.map((fr, index) => {
    let activeGraph = selectedConsumptionTypeLandlord?.toUpperCase() === getGraphLabelsKey(graphLabels[index])?.toUpperCase() ? 'active' : ''
    return <div key={index} className={`${cssClassBuilder(graphLabels[index])} ${activeGraph}`}/>
  })

  function removeFraction(number) {
    return Math.trunc(number)
  }

  const getTrackLineSeparatorElements = () => convertedPercent.map((fr, index) => {
    let lineSeparatorNodeClass = fr === 0 ? '' : CLASS_GRAPH_SEPARATOR
    return <div key={index} className={lineSeparatorNodeClass}/>
  })

  const getLegendElements = () => convertedPercent.map((fr, index) => {
    return (
      <div style={{flex: `1 1 ${fr}%`}} key={'legend-items-' + fr + index} className='dq-legends-wrap'>
        <span className={`dq-legend-item ${graphLabels[index]}`}></span>
        <div>
          <div className="percentage">{removeFraction(fr)}%</div>
          <div className={CLASS_GRAPH_LABEL} key={index} data-testid={'data-quality-pdf-graph-label'}>
            <div
              className="dq-graph-label-inner-txt">{getGraphLabelsKey(graphLabels[index])}</div>
          </div>
        </div>

      </div>
    )
  })

  const graphComponent = () => <div className="landlord-graph" key="graph-landlord">
    <div className="graphs-labels" style={singleBarGridStyle} key="landlord-graph-labels" data-testid={'data-quality-pdf-graph-element'} >
      {getLegendElements()}
    </div>
    <div className="graph-separators" style={singleBarGridStyle} key="landlord-missing-percentages" data-testid={'data-quality-pdf-graph-separators'}>
      {getTrackLineSeparatorElements()}
    </div>
    <div className="graphs-pdf" style={singleBarGridStyle} key="landlord-graphs" data-testid={'data-quality-pdf-graph-track'}>
      {getTrackElements()}
    </div>
  </div>

  function getDQNodataGraphElement() {
    return <div className="landlord-graph nodata-dq-overview-graph-container-pdf" key="graph-landlord">
      <div className="graphs" style={singleBarGridStyle}>
        <div className="nodata-dq-overview-graph est-pdf"/>
      </div>
      <div className="graph-headers mt-6" key="graph-landlord-headers">
        <div className="header-left-pdf" key="graph-landlord-header-left">{I18n('t_msg_no_data')}</div>
      </div>
    </div>
  }

  const getGraphComponent = () => {
    let component
    if (dataNotEmpty()) {
      component = graphComponent()
    } else {
      singleBarGridStyle = {display: 'grid', gridTemplateColumns: '1fr'}
      component = getDQNodataGraphElement()
    }
    return component
  }

  return (getGraphComponent())
}

export default DataQualityOverviewGraph