import React from 'react'
import { useQuery, useMutation } from 'react-query'
import PropTypes from 'prop-types'
import FundReportContentSidebarContent from './FundReportContentSidebarContent'
import { GetFundReportContent, UpdateFundReportContent } from 'services/funds/fund-reports-service'
import useFundIds from 'utils/custom-hooks/useFundIds'

const FundReportContentSidebar = ({ year, isVisible, setIsVisible }) => {
  const { fundId, fundGroupID } = useFundIds()

  const {
    isLoading: fundReportContentLoading,
    data: fundReportContentData
  } = useQuery(['GetFundReportContent', fundGroupID, year, isVisible], GetFundReportContent, {
    enabled: !!year
  })

  const updateMutation = useMutation(UpdateFundReportContent)

  const submitNewReportData = async (newReportObject) => {
    await updateMutation.mutateAsync({requestData: newReportObject, fundId: fundId, year: year})
  }

  return (
    isVisible && (
      <FundReportContentSidebarContent
        year={year}
        fundReportContentLoading={fundReportContentLoading}
        fundReportContentData={fundReportContentData}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        submitNewReportData={submitNewReportData}
      />
    )
  )
}

FundReportContentSidebar.propTypes = {
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isVisible: PropTypes.bool,
  setIsVisible: PropTypes.func,
}

export default FundReportContentSidebar
FundReportContentSidebar.displayName = 'FundReportContentSidebar'