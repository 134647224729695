import moment from 'moment'

import {LOCAL_STORAGE_KEYS, PERIOD_TYPES} from '../../utils/helpers/Constants'
import {getQuarterEndMonth} from '../../utils/helpers/DatePickerUtils.js'

export const periodOptions = ['YTD', '3M', '6M', '1Y']

export const getTimeperiodLabel = (value) => {
  let timePeriodLabel
  switch (value) {
  case 'YTD':
    timePeriodLabel = 'YTD'
    break
  case '1Y':
    timePeriodLabel = 'OneYear'
    break
  case '3M':
    timePeriodLabel = 'ThreeMonths'
    break
  case '6M':
    timePeriodLabel = 'SixMonths'
    break
  case '2Y':
    timePeriodLabel = 'TwoYears'
    break
  case 'CUS': 
    timePeriodLabel = 'Custom'
    break
  default:
    timePeriodLabel = 'OneYear'
  }
  return timePeriodLabel
}

export const customPickerValuesDefault = {
  pickerValueMonths: '1',
  pickerValueEndMonth: '3',
  pickerValueEndYear: moment().format('YY')
}

export function getInitialTimePeriod(queryParams) {
  const params = queryParams || new URLSearchParams(new URL(window.location.href).search)
  const timePeriodFromLS = localStorage.getItem(LOCAL_STORAGE_KEYS.dpTimePeriod)
  return params?.get('timePeriod') || timePeriodFromLS || PERIOD_TYPES.CUSTOM
}

export function getInitialPickerValue(queryParams) {
  const params = queryParams || new URLSearchParams(new URL(window.location.href).search)
  const pickerValuesFromLSString = localStorage.getItem(LOCAL_STORAGE_KEYS.dpCustomTimePeriod)
  const pickerValuesFromLS = (pickerValuesFromLSString && JSON.parse(pickerValuesFromLSString))
  const previousEndedQuarterDefaults = getQuarterEndMonth(1, new Date()) // Not the last ended quarter, but the one before the last ended
  return {
    pickerValueEndMonth: params?.get('endMonth') || pickerValuesFromLS?.pickerValueEndMonth || previousEndedQuarterDefaults.fyQtrEndMonth.toString(),
    pickerValueEndYear: params?.get('endYear') || pickerValuesFromLS?.pickerValueEndYear || previousEndedQuarterDefaults.fyQtrEndYear.toString().substring(2),
    pickerValueMonths: params?.get('noOfMonths') || pickerValuesFromLS?.pickerValueMonths || '12'
  }
}
