import { Toast } from 'primereact/toast'
import './ToastMessage.scss'
import { useContext, useEffect, useRef } from 'react'
import {ToastContext} from 'Contexts'
import { showToastMessage } from 'utils/helpers/Helper'

const ToastMessage = () => {
  const toastRef = useRef(null)
  const { toastState } = useContext(ToastContext)
  useEffect(() => {
    showToastMessage(toastState.toast, toastRef, { life: 3000, title: toastState.title, summary: toastState.summary })
  }, [toastState])

  return (
    <div className='toast-message'>
      <Toast ref={toastRef} />
    </div>
  )
}

export default ToastMessage

