import React, {useEffect, useState, useRef} from 'react'
import './ESAssets.scss'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Dropdown} from 'primereact/dropdown'
import {InputText} from 'primereact/inputtext'
import {Button} from 'primereact/button'
import {Skeleton} from 'primereact/skeleton'
import I18n from '../../../../utils/i18n/I18n'
import {
  getLocalizedDateMessage,
  getLocalizedValue,
  getPaginatorTemplate,
  handleClickOutsideHidePanel,
  unitDateFormatter
} from '../../../../utils/helpers/Helper'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {GetUserDropdownFilters} from '../../../../services/assets/portfolio-service'
import {fetchWithRetry, noRefetchOnWindowFocus} from '../../../../services/common/useQuery-config'
import refresh from '../../../../resources/images/icon/refreshGrey.svg'
import {noDataMessage} from '../../../../utils/helpers/MessageUtility'
import {
  esForceSyncGenerateMetricsReport,
  esMatchedSieraPropertiesInfo,
  esMetricsReportStatus,
  esPropertyMatchWithSieraPlus,
  removeEsSuccessfulMatch,
  updateEnergyStarMetrics
} from '../../../../services/energy-star/energystar-service'
import ESAssetsSkeleton from '../../../common/skeletons/energy-star-skeleton/ESAssetsSkeleton'
import {
  CANCEL,
  METRIC_REPORT_STATUS,
  REMOVE,
  SYNC_COMPLETION_STATUS,
  syncFrequencyList
} from '../../../../utils/helpers/Constants'
import MenuIcon from '../../../../resources/images/icon/three-dots-vertical-custom.svg'
import CustomBadge from '../../../common/custom-badge/CustomBadge'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const ESAssets = (props) => {

  //region Initialization
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const unitSystem = userInfo.unitSystem
  const searchBoxPlaceholder = getLocalizedValue(selectedLanguage, 't_find_asset')
  const allTranslated = getLocalizedValue(selectedLanguage, 't_all')
  const [first, setFirst] = useState(0)
  const [rows, setRows] = useState(20)
  const filterElements = {
    searchAsset: '',
    syncFrequency: 'Monthly',
    fund: null,
    location: null,
    type: null
  }
  const [filterState, setFilterState] = useState(filterElements)
  const {
    isLoading: userDropdownFiltersLoading,
    data: userDropdownFilters,
    refetch: fetchUserDropdownFilters
  } = useQuery(['userFilter', {isEnergyStarOptions: true}], GetUserDropdownFilters, noRefetchOnWindowFocus)
  const QUERY_KEY_SUCCESS_MATCH = 'esSuccessfulMatches'
  const QUERY_KEY_PROPERTY_MATCH = 'esPropertyMatch'
  const {
    isLoading: successfulMatchesLoading,
    data: successfulMatches,
    remove: removeSuccessfulMatches // A function to remove the query from the cache.
  } = useQuery(QUERY_KEY_SUCCESS_MATCH, esMatchedSieraPropertiesInfo, noRefetchOnWindowFocus)
  const {
    isLoading: matchPropertiesLoading,
    data: matchProperties,
    refetch: fetchMatchProperties
  } = useQuery(QUERY_KEY_PROPERTY_MATCH, esPropertyMatchWithSieraPlus, noRefetchOnWindowFocus)
  const [filteredAssetDetails, setFilteredAssetDetails] = useState([])
  const SPACE_WITH_COMA = ', '
  let totalAssetsLength = 0
  let matchedAssetsLength = 0
  if (matchProperties || successfulMatches) {
    const matchedFilterAssets = matchProperties?.filter((el) => {
      return parseInt(el.energyStarPropertyId) && parseInt(el.sieraPropertyId)
    })
    matchedAssetsLength = successfulMatches?.length ? successfulMatches?.length : 0
    totalAssetsLength = isNaN(matchedFilterAssets?.length + successfulMatches?.length) ? matchedFilterAssets?.length : matchedFilterAssets?.length + successfulMatches?.length
  }

  const removeOverlay = useRef(null)
  const [removeMatchToggle, setRemoveMatchToggle] = useState(false)
  const [successMatch, setSuccessMatch] = useState(null)
  const [forceSyncInProgress, setForceSyncInProgress] = useState(false)
  const [syncCompletionStatus, setSyncCompletionStatus] = useState('')

  const removeSuccessfulMatch = useMutation(removeEsSuccessfulMatch)

  const {
    data: reportId,
    refetch: generateMetricReport,
  } = useQuery(['forceSync'], esForceSyncGenerateMetricsReport, {
    ...noRefetchOnWindowFocus,
    enabled: false
  })

  const {
    isLoading: isStatusLoading,
    data: metricStatusData,
    refetch: getMetricStatus,
  } = useQuery(['metricStatus', reportId], esMetricsReportStatus, {
    ...fetchWithRetry,
    enabled: !!reportId && !syncCompletionStatus
  })

  const updateESMetrics = useMutation(updateEnergyStarMetrics)
  const queryClient = useQueryClient()

  // endregion
  //region UseEffect Implementation
  useEffect(() => {
    if ((filterState.searchAsset !== '')) {
      filterEsAssetList(filterState.searchAsset)
    } else {
      filterEsAssetList('')
    }
  }, [filterState.fund, filterState.location, filterState.type, filterState.searchAsset, successfulMatches])

  useEffect(() => {
    handleClickOutsideHidePanel(removeOverlay, setSuccessMatch)
  }, [removeOverlay])

  useEffect(() => {
    if(metricStatusData === METRIC_REPORT_STATUS.INPROCESS) {
      setForceSyncInProgress(true)
      if(!isStatusLoading) {
        (async () => {
          await getMetricStatus()
        })()
      }

    } else if (metricStatusData === METRIC_REPORT_STATUS.GENERATED && reportId) {
      queryClient.setQueriesData({queryKey: 'metricStatus'}, {metricStatusData: METRIC_REPORT_STATUS.FETCHED})
      updateESMetrics.mutateAsync(reportId)
        .then((response) => {
          if(response) {
            setSyncCompletionStatus(SYNC_COMPLETION_STATUS.SUCCESS)
            queryClient.removeQueries({ queryKey: 'forceSync' })
          }
          setForceSyncInProgress(false)
        }, (error) => {
          setForceSyncInProgress(false)
          setSyncCompletionStatus(SYNC_COMPLETION_STATUS.ERROR)
          queryClient.removeQueries({ queryKey: 'forceSync' })
        })
    }
  }, [metricStatusData])
  // endregion

  //region filter es asset table
  function filterEsAssetList(searchText) {
    let filteredResult = []
    let searchLC = searchText.toLowerCase()
    const search = (data) => {
      return data?.sieraPropertyFundName?.toLowerCase().includes(searchLC) || data?.sieraPropertyCountry?.toLowerCase().includes(searchLC) ||
                data?.sieraPropertySector?.toLowerCase().includes(searchLC) ||
                data?.sieraPropertyName?.toLowerCase().includes(searchLC) ||
                data?.sieraPropertyAddress?.toLowerCase().includes(searchLC)
    }
    const esFilteredAssetDetails = successfulMatches && successfulMatches?.filter((data) => {
      if ((filterState.type !== null && filterState.type !== allTranslated ? filterState.type === data?.sieraPropertySector : true)
                && (filterState.fund !== null && filterState.fund !== allTranslated ? filterState.fund === data?.sieraPropertyFundName : true)
                && (filterState.location !== null && filterState.location !== allTranslated ? filterState.location === data?.sieraPropertyCountry : true)
                && (filterState.searchAsset !== '' ? search(data) : true)) {
        return filteredResult.push(data)
      }
    })

    setFilteredAssetDetails(esFilteredAssetDetails)
    return filteredResult
  }

  const onFindAssetFilterChange = (event) => {
    setFilterState({
      ...filterState,
      searchAsset: event.target.value
    })
    let filteredList = filterEsAssetList(event.target.value)
    setFilteredAssetDetails(filteredList)
  }

  const clearSearch = () => {
    setFilterState({
      ...filterState,
      searchAsset: ''
    })
    let filteredList = filterEsAssetList('')
    setFilteredAssetDetails(filteredList)
  }

  const onFilterChange = (event) => {
    setFilterState({
      ...filterState,
      syncFrequency: event.target.name === 'syncDropdown' ? event.target.value : filterState.syncFrequency,
      fund: event.target.name === 'fundDropdown' ? event.target.value : filterState.fund,
      location: event.target.name === 'locationDropdown' ? event.target.value : filterState.location,
      type: event.target.name === 'typeDropdown' ? event.target.value : filterState.type
    })
  }

  const resetDropdownFilter = () => {
    setFilterState({
      ...filterState,
      syncFrequency: 'Monthly',
      fund: allTranslated,
      location: allTranslated,
      type: allTranslated,
    })
    let filteredList = filterEsAssetList('')
    setFilteredAssetDetails(filteredList)
  }

  const onPageChange = (event) => {
    setFirst(event.first)
    setRows(event.rows)
  }

  // endregion

  //region filter dropdown
  const dropdownItemTemplate = (option) => {
    return (
      <>
        <div className="dropdown-item-1">
          <div className="dropdown-item-2 dropdown-item-wrap">{option}</div>
        </div>
        <div className={'dropdown-panel-item-horiz-line'}/>
      </>
    )
  }

  const commonDropdownItemTemplate = (option) => {
    return (
      <>
        <div className="dropdown-item-1">
          <div className="dropdown-item-2">{option.label}</div>
        </div>
        <div className={'dropdown-panel-item-horiz-line'}/>
      </>
    )
  }

  const syncFrequencyFilterElements = (
    <div className={'content-align-center sync-frequency mt-0_6'}>
      <Dropdown
        disabled={true}
        appendTo="self"
        panelClassName="es-dropdown-panel"
        options={syncFrequencyList}
        value={filterState.syncFrequency}
        name="syncDropdown"
        className="sDropdown"
        placeholder={'Monthly'}
        onChange={onFilterChange}
        itemTemplate={commonDropdownItemTemplate}
        scrollHeight="24em"
      />
      <Button className={'common-blue-bg-btn sync-frequency__button'}
        label={'Force Sync'}
        icon={forceSyncInProgress ? 'pi pi-spinner pi-spin': 'pi pi-sync'}
        iconPos="left"
        disabled={forceSyncInProgress}
        onClick={forceSyncAssets}/>
      {syncCompletionStatus && <div className={'sync-frequency__completion'}>
        {syncCompletionStatus === SYNC_COMPLETION_STATUS.SUCCESS
          ? <><i className="pi pi-check mr-0_5 fw-6"></i> <span>{I18n('t_sync_success_message')}</span></>
          : <span className={'error'}>{I18n('t_error_request_400')}</span>}
      </div>}
    </div>
  )

  const fundFilterElements =
        <Dropdown
          appendTo="self"
          panelClassName="es-dropdown-panel"
          options={userDropdownFiltersLoading ? [allTranslated] : [allTranslated].concat((userDropdownFilters?.data.funds)?.sort())}
          value={filterState.fund}
          name="fundDropdown"
          className="sDropdown"
          placeholder={allTranslated}
          onChange={onFilterChange}
          itemTemplate={dropdownItemTemplate}
          scrollHeight="24em"
        />

  const locationFilterElements =
        <Dropdown
          appendTo="self"
          panelClassName="es-dropdown-panel"
          options={userDropdownFiltersLoading ? [allTranslated] : [allTranslated].concat((userDropdownFilters?.data.locations)?.sort())}
          value={filterState.location}
          name="locationDropdown"
          className="sDropdown"
          placeholder={allTranslated}
          onChange={onFilterChange}
          itemTemplate={dropdownItemTemplate}
          scrollHeight="24em"
        />

  const typeFilterElements =
        <Dropdown
          appendTo="self"
          panelClassName="es-dropdown-panel"
          options={userDropdownFiltersLoading ? [allTranslated] : [allTranslated].concat((userDropdownFilters?.data.types)?.sort())}
          value={filterState.type}
          name="typeDropdown"
          className="sDropdown"
          placeholder={allTranslated}
          onChange={onFilterChange}
          itemTemplate={dropdownItemTemplate}
          scrollHeight="24em"
        />

  // endregion

  const handleMatchMoreAssets = () => {
    props.setMatchMoreAssets(true)
  }

  function removeMatchOverlay(rowData) {
    setSuccessMatch(rowData)
  }

  function toggleRemoveMatch(toggle) {
    setRemoveMatchToggle(toggle)
  }

  async function handleRemoveEsMatch(action) {
    setSuccessMatch(null)
    setRemoveMatchToggle(false)
    if(action !== CANCEL) {
      try {
        await removeSuccessfulMatch.mutateAsync(successMatch.energyStarPropertyId)
        await fetchMatchProperties()
        removeSuccessfulMatches()
        await fetchUserDropdownFilters()
      } catch (e) {
        console.log(e.toString())
      }
    }
  }

  async function forceSyncAssets() {
    setSyncCompletionStatus('')
    setForceSyncInProgress(true)
    await generateMetricReport()
  }

  //region table header
  const esAssetsHeader = (
    <div className={'es-table-header grid'}>
      <div role={'es-matched-action'} className="es-table-header__es-matched-action col-12">
        <div className={'es-table-header__es-matched-action--filter-elements'}>
          <span className={'es-table-header__es-matched-action--filter-label'}>{I18n('t_es_sync_frequency')}</span>
          {syncFrequencyFilterElements}
        </div>
        <Button className={'common-blue-bg-btn es-table-header__es-matched-action--es-match-more-btn'}
          onClick={() => handleMatchMoreAssets()} label={I18n('t_es_match_more_assets')}/>
      </div>
      <div role={'es-filter'} className={'es-table-header__es-filter col-12'}>
        <div className={'es-table-header__es-filter--filter-elements'}>
          <span className={'es-table-header__es-filter--filter-label'}>{I18n('t_fund')}</span>
          {fundFilterElements}
        </div>
        <div className={'es-table-header__es-filter--filter-elements'}>
          <span className={'es-table-header__es-filter--filter-label'}>{I18n('t_location')}</span>
          {locationFilterElements}
        </div>
        <div className={'es-table-header__es-filter--filter-elements'}>
          <span className={'es-table-header__es-filter--filter-label'}>{I18n('t_type')}</span>
          {typeFilterElements}
        </div>
        <div className="es-table-header__es-filter--dropdown-clear-icon">
          <img src={refresh} alt={getLocalizedValue(selectedLanguage, 't_refresh')}
            className={filterState.fund === allTranslated && filterState.location === allTranslated && filterState.type === allTranslated ? 'low-opacity' : 'cursor highlight-clickable-opacity'}
            onClick={resetDropdownFilter}/>
        </div>
        <div role={'es-input-search'} className={'es-table-header__es-filter--es-filter-assets'}>
          <InputText className="es-table-header__es-filter--es-input" value={filterState.searchAsset} onChange={onFindAssetFilterChange}
            placeholder={searchBoxPlaceholder}/>
          {filterState.searchAsset !== '' ? (
            <i className="pi pi-times-circle cursor" style={{fontSize: '17px'}} onClick={clearSearch}/>
          ) : <i className="pi pi-search cursor" style={{fontSize: '17px'}}/>}
        </div>
      </div>
    </div>
  )
  // endregion

  //region table template
  function esNameTemplate(rowData) {
    return <div className={'asset-column'} role={'es-asset-details'}>
      <span>{rowData.sieraPropertyName}</span>

      {rowData.sieraPropertyDateofSale ?
        <CustomBadge message={`${getLocalizedValue(selectedLanguage, 't_sold_badge_label')} ${getLocalizedDateMessage(rowData.sieraPropertyDateofSale, 'MONTH_YEAR', selectedLanguage)}`} styling='sold-label'  /> : null
      }
    </div>
  }

  function esAddressTemplate(rowData) {
    return rowData.sieraPropertyName + SPACE_WITH_COMA + rowData.sieraPropertyAddress + SPACE_WITH_COMA +
            rowData.sieraPropertyCity + SPACE_WITH_COMA + rowData.sieraPropertyCountry
  }

  function esFundTemplate(rowData) {
    return rowData.sieraPropertyFundName
  }

  function esLastSyncTemplate(rowData) {
    return <div className='flex relative'>
      {unitDateFormatter(rowData.lastSync, unitSystem, selectedLanguage)}
      {esActionTemplate(rowData)}
    </div>
  }

  function esActionTemplate(rowData) {
    return (
      <div role={'remove-overlay'} className='ml-auto'>
        <img src={MenuIcon} onClick={() => removeMatchOverlay(rowData)} alt="menu"
          className="menu-icon cursor"/>
        {rowData.energyStarPropertyId === successMatch?.energyStarPropertyId && (
          <div role={'remove-match-popup'} ref={removeOverlay} className={'remove-match-overlay'}>
            <span className={'cursor label-remove-match'}
              onClick={() => toggleRemoveMatch(true)}>{I18n('t_remove_match')}</span>
            {removeMatchToggle && (
              <div role={'remove-overlay-actions'} className={'mt-2'}>
                <span className={'label-confirmation'}>{I18n('t_delete_confirmation')}</span>
                <div className={'flex mt-2'}>
                  <Button className={'es-remove-btn'}
                    onClick={() => handleRemoveEsMatch(REMOVE)}
                    label={I18n('t_remove')}/>
                  <Button className={'grey-bordered-whit-bg-btn es-cancel-btn'}
                    onClick={() => handleRemoveEsMatch(CANCEL)}
                    label={I18n('t_cancel')}/>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }

  // endregion

  function getEsAssetsTableData(assetDetails) {
    return assetDetails?.length > 0 ? (
      <DataTable
        value={assetDetails}
        tableClassName={assetDetails?.length < 20 ? 'es-asset-table mb:col-5' : 'es-asset-table'}
        first={first}
        onPage={e => onPageChange(e)}
        rows={rows}
        paginator={assetDetails?.length > 20}
        totalRecords={assetDetails?.length}
        paginatorTemplate={getPaginatorTemplate('items-per-page-options-white')}
        paginatorClassName={'data-table-paginator es-connection-paginator'}
      >
        <Column field="name" style={{'width': '25%'}} header={I18n('t_name')} body={esNameTemplate}
          className={'es-column-name'}/>
        <Column field="address"  style={{'width': '40%'}} header={I18n('t_address')} body={esAddressTemplate}
          className={'es-column-name'}/>
        <Column field="fund"  style={{'width': '20%'}} header={I18n('t_fund')} body={esFundTemplate}
          className={'es-column-name'}/>
        <Column field="lastSync"  style={{'width': '20%'}} header={I18n('t_last_sync')} body={esLastSyncTemplate}
          className={'es-column-name'}/>
      </DataTable>
    ) : noDataMessage()
  }

  return (
    <div role={'es-matched-assets'} className={'es-assets'}>
      <div className={'es-assets__es-connection-header'} role={'esc-common-header'}>
        {successfulMatchesLoading || matchPropertiesLoading ? <Skeleton height="1.5rem" width="15rem"/> :
          <span className={'es-assets__es-connection-header--es-matched-assets-count'}>{I18n('t_es_matched_assets_count', {
            0: matchedAssetsLength,
            1: totalAssetsLength
          })}</span>
        }
        <span className={'es-assets__es-connection-header--es-sub-text'}>{I18n('t_es_matched_assets_info')}</span>
        {esAssetsHeader}
      </div>
      {successfulMatchesLoading ? <ESAssetsSkeleton/> : getEsAssetsTableData(filteredAssetDetails)}
    </div>
  )
}

export default ESAssets