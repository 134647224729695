import React, { useEffect, useState} from 'react'
import './GroupsHome.scss'
import {useQuery} from 'react-query'
import {noRefetchOnWindowFocus} from '../../../../../services/common/useQuery-config'
import {GetAssetGroups} from '../../../../../services/admin/groups-service'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import I18n from '../../../../../utils/i18n/I18n'
import HeaderContainer from '../../Common/HeaderContainer'
import {
  createNavURLForUserPages,
  getLoggedInUserRole,
  getPaginatorTemplate,
} from '../../../../../utils/helpers/Helper'
import {ADMIN_PAGES, UserActions} from '../../../../../utils/helpers/Constants'
import {useHistory} from 'react-router-dom/cjs/react-router-dom'
import {useParams} from 'react-router-dom'
import '../../users/users-home/UsersHome.scss'
import GroupsGridSkeleton from '../../../../common/skeletons/groups-grid-skeleton/GroupsGridSkeleton'

function GroupsHome() {

  const {instanceName} = useParams()
  const [groupsGridData, setGroupsGridData] = useState([])
  const [searchText, setSearchText] = useState('')
  const [first, setFirst] = useState(0)
  const [rows, setRows] = useState(20)
  const history = useHistory()
  const {userId} = useParams()
  const {data: assetGroupsData, isLoading}  = useQuery([getUserId(),'GroupsData'], GetAssetGroups,noRefetchOnWindowFocus)

  useEffect(() => {
    if (assetGroupsData) {
      userId ? setGroupsGridData(assetGroupsData.data.groups) : setGroupsGridData(assetGroupsData.data)
    }
  }, [assetGroupsData,userId])

  useEffect(() => {
    return ()=>setGroupsGridData([])
  }, [])

  //region Helper methods

  function getUserId() {
    return userId ? userId :  null
  }

  function searchGroups(e) {
    let searchText = e.target.value
    setSearchText(searchText)
    let filteredList = assetGroupsData.data.filter((key) => {
      if (key.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
                key.memberCount.toString().toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
                key.assetCount.toString().toLowerCase().indexOf(searchText.toLowerCase()) > -1) {
        return true
      }
    })
    setGroupsGridData(filteredList)
  }

  function clearSearch() {
    let searchText = ''
    setSearchText(searchText)
    setGroupsGridData(assetGroupsData.data)
  }

  function addGroupHandler(){
    if(getLoggedInUserRole() ==='ADMIN' || getLoggedInUserRole() ==='ADMINISTRATOR'){
      const groupAddPath=createNavURLForUserPages({instanceName, groupId: ADMIN_PAGES.groups, pathSuffix: ADMIN_PAGES.add})
      history.push({pathname: groupAddPath},
        {
          activeIndex : 0,
          userAction: UserActions.ADD_GROUP
        }
      )
    }
  }

  function groupsRowClickHandler(e) {
    const groupProfilePath=createNavURLForUserPages({
      pathPrefix: getUserId()? ADMIN_PAGES.myGroups :ADMIN_PAGES.groups, groupId: e.data?.assetGroupID
    })
    history.push({
      pathname: groupProfilePath,
      state: e.data // e.data: selected row data
    })
  }

  const onPageChange = (event) => {
    setFirst(event.first)
    setRows(event.rows)
  }

  //endregion

  //region Custom templates

  function assetsTemplate(rowData) {
    return rowData.assetCount
  }

  //endregion
  return (
    <div className="grey-bg min-width-1280">
      <div className={'container-layout groups-homePage-wrapper gutter'}>
        <HeaderContainer title={!getUserId() ? 't_groups' : 't_my_groups'}
          placeholder={'t_find_group'} addButtonLabel={'t_add_new_group'}
          searchValue={searchText} searchHandler={searchGroups} addItemHandler={addGroupHandler} clearField={clearSearch}/>
        {isLoading ?
          <div className="groups-grid-before-load groups-list-container">
            <GroupsGridSkeleton/>
          </div> :
          <div className={`groups-list-container ${groupsGridData?.length > 20 && 'more-groups'}`}>
            { groupsGridData?.length > 0 ?
              <DataTable
                value={groupsGridData}
                selectionMode="single"
                className="groups-list-table"
                stripedRows
                onRowClick={(e) => groupsRowClickHandler(e)}
                paginator={groupsGridData.length>20}
                first={first}
                rows={rows}
                paginatorTemplate={getPaginatorTemplate('items-per-page-options-white')}
                paginatorClassName={'data-table-paginator group-home-paginator'}
                onPage={e=> onPageChange(e)}
                totalRecords={groupsGridData.length}
                role={'groups-list-table'}>
                <Column field="name" header={I18n('t_name')} className="userNameColumn" style={{width: '34%'}}/>
                <Column field="assets" header={I18n('t_asset_s')} body={assetsTemplate} className="assetsColumn" style={{width: '11%'}}/>
                <Column field="memberCount" header={I18n('t_users')} className="lastLoginColumn" style={{width: '17%'}}/>
              </DataTable> :
              <div className="no-result-msg">
                <span className="no-result-text">{I18n('t_groups_not_found')}</span>
              </div>
            }
          </div>
        }
      </div>
    </div>
  )
}

export default GroupsHome
