import PropTypes from 'prop-types'
import { useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Skeleton } from 'primereact/skeleton'
import './FundReportAssetBreakdown.scss'
import I18n, { useI18n } from 'utils/i18n/I18n'
import { REPORT_STATUS } from '../mappings'
import './FundReportAssetBreakdownContent.scss'
import { Button, Icon, ButtonWrapper, Tooltip, TooltipTwoColumnsLayout, Text } from 'components/atomic'
import DQProgressBar from '../dq-progress-bar/DQProgressBar'
import { PAGINATOR_WITH_LEGEND_TEMPLATE } from 'utils/helpers/Constants'

export const DataCompletenessInformation = ({ translationKey }) => {
  const heading = (msg) => <b>{msg}</b>
  const subParagraph = (msg) => <p>{msg}</p>
  return (
    <div className='ex-large-text-light'>
      {I18n(translationKey,
        {
          p: msg => subParagraph(msg),
          b: msg => heading(msg),
        }
      )}
    </div>
  )
}
DataCompletenessInformation.propTypes = {
  translationKey: PropTypes.string.isRequired,
}
DataCompletenessInformation.displayName = 'DataCompletenessInformation'

const FundReportAssetBreakdownContent = (props) => {
  const {
    year,
    fundReportAssetBreakdownData,
    fundReportAssetBreakdownLoading,
    fundReportSummaryData,
    onBackToListClick,
    onCreateReportClick,
    onClickEditData,
    onExportClick,
    onRefetchData,
    download,
    onDataCompletenessBarClick
  } = props

  const intl = useI18n()

  const [first, setFirst] = useState(fundReportAssetBreakdownData?.skip ?? 0)
  const [rows, setRows] = useState(fundReportAssetBreakdownData?.pageSize ?? 10)

  const [loading, setLoading] = useState(false)

  const loadLazyData = (page) => {
    setLoading(true)
    onRefetchData(page)
    setLoading(false)
  }

  const onPageChange = (e) => {
    setFirst(e.first)
    setRows(e.rows)
    loadLazyData(e.page)
  }

  const gridValue = Array.from({ length: 10 })

  const skeletonTemplate = (width) => {
    return <Skeleton width={width} />
  }

  function dataQualityHeaderTemplate() {
    return (
      <div className='flex-row'>
        {I18n('t_data_quality')}
        <Icon id='info_solid' size={17} className='data-quality-info ml-2' testId='dq-asset-breakdown-header-tooltip-test'/>
        <Tooltip target='.data-quality-info' width={350}>
          <Text content='t_data_quality_info' />
        </Tooltip>
      </div>
    )
  }

  function nameTemplate(rowData) {
    return (
      <Text content={rowData.assetName} />
    )
  }

  function referenceTemplate(rowData) {
    return (
      <Text content={rowData.assetReference} />
    )
  }

  function dataCompletenessTemplate(rowData, rowIndex) {
    const tooltipItems = [
      {
        label: 't_actual',
        labelIcon: <div className='legend-item-square legend-actual'></div>,
        value: `${(rowData?.dataQuality?.actual*100).toFixed(1)}%`,
      },
      {
        label: 't_estimated',
        labelIcon: <div className='legend-item-square legend-estimated'></div>,
        value: `${(rowData?.dataQuality?.estimated*100).toFixed(1)}%`,
      },
      {
        label: 't_missing',
        labelIcon: <div className='legend-item-square legend-incomplete'></div>,
        value: `${(rowData?.dataQuality?.incomplete*100).toFixed(1)}%`,
      },
      {
        label: 't_not_covered',
        labelIcon: <div className='legend-item-square legend-not-covered'></div>,
        value: `${(rowData?.dataQuality?.notCovered*100).toFixed(1)}%`,
      }
    ]

    return (
      <ButtonWrapper
        testId={'fund-report-asset-data-completeness-' + rowData.assetId}
        onClick={() => onDataCompletenessBarClick(rowData.assetId)}
      >
        <Tooltip
          target={`.dq-asset-breakdown-tooltip-trigger-${rowIndex}`}
          padding='large'
        >
          <TooltipTwoColumnsLayout
            titleLeft='t_data_quality'
            items={tooltipItems}
          />
        </Tooltip>
        <DQProgressBar testId={`dq-asset-breakdown-tooltip-trigger-${rowIndex}-test`} className={`dq-asset-breakdown-tooltip-trigger-${rowIndex}`} value={rowData.dataQuality}/>
      </ButtonWrapper>
    )
  }

  function reportMissingRequiredTemplate(rowData) {
    return (
      <div className='flex-row'>
        <Text content='t_reports_SFDR' contentDynamic={{count: rowData.missingRequiredFields}} />
      </div>
    )
  }

  function reportMissingOptionalTemplate(rowData) {
    return (
      <div className='flex-row'>
        <Text content='t_reports_SFDR' contentDynamic={{count: rowData.missingOptionalFields }} />
      </div>
    )
  }

  function buttonTemplate(rowData) {
    return (
      <div className='report-button-flex'>
        <Button
          testId={`fund-reports-breakdown-content-add-edit-btn-${rowData.assetId}`}
          content={rowData.missingRequiredFields === 0 && (rowData?.dataQuality?.actual + rowData?.dataQuality?.estimated === 1) ? 't_edit_data' : 't_add_data'}
          size='small'
          outlined
          onClick={() => onClickEditData(true, rowData.assetId, 1)}
        />
      </div>
    )
  }

  const header = (
    <div className='table-cell-wide'>
      <Text content='t_year_dynamic' contentDynamic={{year: year}} weight='bold' className='py-3 pl-4' testId='fund-breakdown-year-title'/>
      {!fundReportAssetBreakdownLoading && (
        <div className='header-button-container'>
          <div>
            <Button
              content='t_back_to_list'
              icon={<Icon id='arrow_left' />}
              outlined={true}
              size='small'
              onClick={onBackToListClick}
              testId={'fund-reports-back-to-list-btn'}
            />
          </div>
          {(fundReportSummaryData?.reportStatus === REPORT_STATUS.CanCreateReport ||
            fundReportSummaryData?.reportStatus === REPORT_STATUS.ReportGenerated) && (
            <Button
              content='t_reports_create'
              size='small'
              onClick={() => onCreateReportClick(year)}
              testId='fund-reports-breakdown-create-report-btn'
            />
          )}
          {fundReportSummaryData?.reportStatus === REPORT_STATUS.Incomplete && (
            <Button
              content='t_export'
              size='small'
              loading={download}
              onClick={() => onExportClick(year)}
              testId='fund-reports-breakdown-export-btn'
            />
          )}
        </div>
      )}
    </div>
  )

  function currentPageReportTemplate(assets) {
    const totalAssets = assets?.totalAssets
    const firstRecord = first + 1
    const lastRecord = totalAssets - first < rows && totalAssets > 0
      ? totalAssets
      : first + rows
  
    return totalAssets > 0
      ? `${firstRecord} - ${lastRecord} ${intl.formatMessage({ id: 't_of' })} ${totalAssets}`
      : '0'
  }

  return (
    <div className='fund-report-table' data-testid='report-asset-breakdown'>
      <div className='fund-report-table__container'>
        {fundReportAssetBreakdownLoading ? (
          <DataTable value={gridValue} data-testid={'fund-report-skeleton'}>
            <Column field='sk_t_year' header={skeletonTemplate('5rem')} body={skeletonTemplate('90%')} />
            <Column field='sk_t_data_quality' header={skeletonTemplate('5rem')} body={skeletonTemplate('90%')} />
            <Column field='sk_t_reports_report_completeness' header={skeletonTemplate('5rem')} body={skeletonTemplate('90%')} />
            <Column field='sk_t_button' header={skeletonTemplate('5rem')} body={skeletonTemplate('90%')} />
          </DataTable>
        ) : (
          <DataTable
            value={fundReportAssetBreakdownData?.assets}
            header={header}
            paginator={fundReportAssetBreakdownData?.totalAssets}
            lazy
            first={first}
            rows={rows}
            loading={loading}
            onPage={(e) => onPageChange(e)}
            totalRecords={fundReportAssetBreakdownData?.totalAssets}
            tableStyle={{ minWidth: '50rem' }}
            paginatorTemplate={PAGINATOR_WITH_LEGEND_TEMPLATE}
            currentPageReportTemplate={currentPageReportTemplate(fundReportAssetBreakdownData)}
            paginatorClassName='asset-paginator'
          >
            <Column field='t_asset_name' header={<Text content='t_asset_name_tc' weight='bold'/>} body={nameTemplate} />
            <Column field='t_asset_reference' header={<Text content='t_asset_reference_tc' weight='bold'/>} body={referenceTemplate} />
            <Column bodyClassName='highlighted' field='t_reports_data_completeness' header={dataQualityHeaderTemplate}  body={(rowData, rowProps) => dataCompletenessTemplate(rowData, rowProps.rowIndex)} />
            <Column field='t_reports_missing_required' header={<Text content='t_reports_missing_required' weight='bold'/>} body={reportMissingRequiredTemplate} />
            <Column field='t_reports_missing_optional' header={<Text content='t_reports_missing_optional' weight='bold'/>} body={reportMissingOptionalTemplate} />
            <Column field='t_button' body={buttonTemplate} />
          </DataTable>
        )}
      </div>
    </div>
  )
}

FundReportAssetBreakdownContent.propTypes = {
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fundReportAssetBreakdownData: PropTypes.object,
  fundReportAssetBreakdownLoading: PropTypes.bool,
  fundReportSummaryData: PropTypes.object,
  onCreateReportClick: PropTypes.func,
  onClickEditData: PropTypes.func,
  onBackToListClick: PropTypes.func,
  onExportClick: PropTypes.func,
  onRefetchData: PropTypes.func,
  download: PropTypes.bool,
  onDataCompletenessBarClick: PropTypes.func
}

FundReportAssetBreakdownContent.displayName = 'FundReportAssetBreakdownContent'

export default FundReportAssetBreakdownContent
