import React, {useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import {GetUsersList} from '../../../../../services/admin/users-service'
import {noRefetchOnWindowFocus} from '../../../../../services/common/useQuery-config'
import UsersGridSkeleton from '../../../../common/skeletons/users-grid-skeleton/UsersGridSkeleton'
import I18n from '../../../../../utils/i18n/I18n'
import {Column} from 'primereact/column'
import {DataTable} from 'primereact/datatable'
import '../group-users/GroupUsers.scss'
import SearchField from '../../../../common/search/SearchField'
import {
  getDateTimeTemplate,
  getLocalizedValue, getLocalStorageByKey, getPaginatorTemplate,
  onSelection, setLocalStorageItem
} from '../../../../../utils/helpers/Helper'
import {
  getSelectAllIconNode,
  getSelectAllIconNodeContainer,
  setSelectAllIcon
} from '../../../../../utils/helpers/DataTableUtils'
import { groupUserDetails, UserActions} from '../../../../../utils/helpers/Constants'
import GroupFooter from '../group-footer/GroupFooter'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'


function GroupUsers(props){
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const unitSystem = userInfo.unitSystem
  const {data: usersData, isLoading: isUsersLoading}  = useQuery(['usersInfoList'], GetUsersList, noRefetchOnWindowFocus)
  const [filteredHiddenSelectedUsers,setFilteredHiddenSelectedUsers]=useState([])
  const [filteredSelectedUsers,setFilteredSelectedUsers]=useState([])
  const [usersGridData,setUsersGridData] = useState([])
  const [usersUnderSelectedGroup,setUsersUnderSelectedGroup] = useState([])
  const [searchText,setSearchText] = useState('')
  const [activeIndex,setActiveIndex] =useState(0)
  const [selectedActiveClass, setSelectedActiveClass] = useState('op-5')
  const [totalActiveClass, setTotalActiveClass] = useState('')
  const [unSavedData,setUnSavedData] = useState(false)
  const [first, setFirst] = useState(0)
  const [rows, setRows] = useState(20)
  const selectAllIconSelectors='.group-users .users-list-container .users-list-table .p-datatable-thead div.p-checkbox-box span.p-checkbox-icon'
  const groups='groups'
  const isEditGroupAction = () => props.groupAction === UserActions.EDIT_GROUP
  
  useEffect(()=>{
    props.visited && props.visited(2)
  },[])


  // to set complete users data and users associated with selected group
  useEffect(() => {
    const groupUserDetailsObject = getLocalStorageByKey(groupUserDetails)
    setUsersUnderSelectedGroup(groupUserDetailsObject && groupUserDetailsObject.groupUsers ? groupUserDetailsObject.groupUsers : filterUsersUnderSelectedGroup())
    setUsersGridData(usersData)
  }, [props.groupData?.users, usersData])

  useEffect(() => {
    const selectAllIconNode = getSelectAllIconNode(selectAllIconSelectors)
    if (searchText === '') {
      setSelectAllIcon(usersUnderSelectedGroup, usersGridData, selectAllIconNode)
    } else {
      setSelectAllIcon(filteredSelectedUsers, usersGridData, selectAllIconNode)
    }
  }, [usersUnderSelectedGroup, filteredSelectedUsers, usersGridData])

  useEffect(()=>{
    if(unSavedData){
      saveUserIdsOfSelectedUsers(usersUnderSelectedGroup)
    }
  },[usersUnderSelectedGroup])

  function clearSearch() {
    setSearchText('')
    activeIndex === 0 ? setUsersGridData(usersData) : setUsersGridData(usersUnderSelectedGroup)
  }

  function filterUsersUnderSelectedGroup(){
    if (props.groupData?.users && usersData) {
      return usersData.filter((users) => props.groupData.users.some(usersUnderSelectedGroup => usersUnderSelectedGroup.authorisationID === users.authorisationId))
    }
    return []
  }

  function searchUsers(e) {
    let searchText = e.target.value
    setSearchText(searchText)
    let searchContainer = activeIndex === 0 ? usersData : usersUnderSelectedGroup
    let searchLC = searchText?.toLowerCase()
    let filteredList = searchContainer.filter((key) => {
      if ((key.userName?.toLowerCase().includes(searchLC))) {
        return true
      }
    })

    setFilteredHiddenSelectedUsers(() => {
      return usersUnderSelectedGroup.filter((selectedUser) => !filteredList.find(filteredUser => (selectedUser.userId === filteredUser.userId)))
    })
    setFilteredSelectedUsers(() => {
      return usersUnderSelectedGroup.filter((selectedUser) => filteredList.find(filteredUser => (selectedUser.userId === filteredUser.userId)))
    })
    setUsersGridData(filteredList)

  }

  function onUsersSelectionChange(e) {
    let newSelectedUser = e.value
    setUnSavedData(true)
    const thNode = getSelectAllIconNodeContainer(e)
    onSelection(groups,newSelectedUser, searchText, thNode, setUsersUnderSelectedGroup, setFilteredSelectedUsers, filteredHiddenSelectedUsers)
    if (activeIndex === 1) {
      setUsersGridData(newSelectedUser)
    }
  }

  function saveUserIdsOfSelectedUsers(newSelectedUser) {
    let groupUserIds = []
    if(newSelectedUser){
      newSelectedUser.forEach(selectedUser => {
        groupUserIds = [...groupUserIds,...selectedUser.userId]
      })
    }
    const userDetails = {isUsersNavigatable : true, groupUsers : newSelectedUser, userIdsForSelectedUsers : groupUserIds}
    setLocalStorageItem(groupUserDetails,userDetails)
  }

  function allUsersHandler() {
    setActiveIndex(0)
    setTotalActiveClass('')
    setSelectedActiveClass('op-5')
    setUsersGridData(usersData)
    setSearchText('')
  }

  function selectedUsersHandler() {
    setActiveIndex(1)
    setTotalActiveClass('op-5')
    setSelectedActiveClass('')
    setUsersGridData(usersUnderSelectedGroup)
    setSearchText('')
  }

  function getChooseGroupsLabel() {
    let chooseGroupLabel=I18n('t_choose_groups_for_new_user')
    if(isEditGroupAction()){
      let groupName=''
      if(props.groupData && props.groupData.name){
        groupName=props.groupData.name
      }
      chooseGroupLabel=I18n('t_choose_users_who_will_be_able_to_access_group', {0: groupName})
    }
    return chooseGroupLabel
  }

  function getFieldValueOrPlaceholder(fieldValue) {
    return fieldValue?fieldValue:'-'
  }

  const onPageChange = (event) => {
    setFirst(event.first)
    setRows(event.rows)
  }

  const lastLoginTemplate = (rowData) => {
    let dateTime = getDateTimeTemplate(rowData, selectedLanguage, unitSystem)
    return (
      <div className='lastLogin-template'>
        <span className='lastLogin-label'>{getFieldValueOrPlaceholder(dateTime)}</span>
      </div>
    )
  }

  function addUpdateGroupUsers() {
    props.saveAndExit()
  }

  return(
    <div className={'group-users'}>
      <div className="flex justify-content-between users">
        <div data-testid={'groups-label'}>{getChooseGroupsLabel()}</div>
        <div className="count-selection-container">
          <div data-testid={'users-count'} className={`total-count count-status ${totalActiveClass}`} onClick={allUsersHandler}>
            {usersData ? usersData.length : 0} {I18n('t_users')}
          </div>
          <div data-testid={'users-selected-count'} className={`selected-count count-status ${selectedActiveClass}`} onClick={selectedUsersHandler}>
            {usersUnderSelectedGroup.length}
          </div>
          <div data-testid={'selected-label'} className={`selected-label count-status ${selectedActiveClass}`} onClick={selectedUsersHandler}>
            {I18n('t_selected')}
          </div>
        </div>
      </div>
      <div className="flex">
        <div className={'search-field-w-top'}>
          <SearchField placeholder={getLocalizedValue(selectedLanguage, 't_find_user')} searchValue={searchText}
            onChange={searchUsers} clearField={clearSearch} addRef={true}/>
        </div>
      </div>
      {
        isUsersLoading?
          <div className="users-grid-before-load users-list-container">
            <UsersGridSkeleton/>
          </div> :
          <div className="users-list-container">
            {usersGridData?.length > 0 ?
              <DataTable
                value={usersGridData}
                className="users-list-table"
                stripedRows
                selection={searchText === '' ? usersUnderSelectedGroup:filteredSelectedUsers}
                onSelectionChange={(e) => onUsersSelectionChange(e)}
                scrollable
                paginator={usersGridData.length>20}
                first={first}
                rows={rows}
                paginatorTemplate={getPaginatorTemplate('items-per-page-options-white')}
                paginatorClassName={'data-table-paginator group-users-paginator'}
                onPage={e=> onPageChange(e)}
                totalRecords={usersGridData.length}
              >
                <Column field="userName" header={I18n('t_name')} className="name-column" />
                <Column field="company" header={I18n('t_company')} className="company-column"  />
                <Column field="role" header={I18n('t_role')} className="role-company" />
                <Column field="numberOfAssets" header={I18n('t_asset_s')} className="numberOfAsset-column" />
                <Column field="lastLogin" header={I18n('t_last_login')} className="lastLogin-column" body={lastLoginTemplate} />
                <Column selectionMode="multiple" className="checkbox-column"
                />
              </DataTable>
              :
              <div className="no-result-msg">
                <span className="no-result-text">{I18n('t_users_not_found')}</span>
              </div>}

          </div>
      }
      <div className={'footer-divider'}>
        <hr/>
      </div>
      <div className={'g-form-footer'}>
        <GroupFooter
          submitHandler={()=> addUpdateGroupUsers()}
          handleNextTabBtnText="t_next_choose_assets"
          groupAction={props.groupAction}
          hasPermissionForEditUser={isEditGroupAction()}
          lastPage={true}
        />
      </div>
    </div>
  )
}

export default GroupUsers