import PropTypes from 'prop-types'
import './MeterGraph.scss'
import {Tooltip, Text} from '../../../../atomic'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'
import { getLocalizedValue } from 'utils/helpers/LanguageUtils'
import DqMeterModalV2 from '../dq-meter-modal/DQMeterModalV2'
import { useContext, useState } from 'react'
import { unBlockDialogScroll } from 'utils/helpers/Helper'
import RequestDataDialogV2 from '../../performance/dialogs/RequestDataDialogV2'
import useErrorHandling from 'components/common/error-handling/ErrorHandling'
import { PortfolioContext } from 'Contexts'

const MeterGraph = ({meterData, sendDataHandler, onEnterDataHandler}) => {
  const [meterModalShow, setMeterModalShow] = useState(false)
  const [showRequestDataDlg, setShowRequestDataDlg] = useState(false)
  const [modalSelectedMeters, setModalSelectedMeters] = useState([])
  const [requestData, setRequestData] = useState([])
  const [meterModalGapData, setMeterModalGapData] = useState({})
  const { ShowErrorDialog, errorDialogVisible } = useErrorHandling()
  const { loginState: { userInfo } } = useLoginAuthContext()
  const { pfState: {data: rowData} } = useContext(PortfolioContext)
  const selectedLanguage = userInfo.languagePreference
  const tooltipMessagesMap = {
    actual: 't_actual_data_legend',
    supplier: 't_supplier_estimate',
    calculated: 't_calculated_estimate',
    missing: 't_missing_tc',
    inactive: 't_meter_inactive_sold'
  }

  function graphBoxClickHandler(meterGapData) {
    if(!meterGapData.gap) return
    setMeterModalGapData(meterGapData)
    setMeterModalShow(prev => !prev)
  }

  function getGraphGaps() {
    return meterData?.gapsPerMonth?.map((gapData, index) => {
      const tooltipClass = `gap-${meterData.meterId}-${gapData.position}`
      return (
        <div key={index}>
          <Tooltip target={`.${tooltipClass}`} position='bottom' showDelay={100}>
            <Text content={tooltipMessagesMap[gapData.gap] || tooltipMessagesMap.inactive} />
          </Tooltip>
          <button className={`meter-graph__gaps__${gapData.gap || 'inactive'} ${tooltipClass}`} onClick={() => graphBoxClickHandler(gapData)}/>
        </div>
      )
    }
    )
  }

  function meterModalOnHide() {
    setMeterModalShow(false)
    unBlockDialogScroll()
    setModalSelectedMeters([])
  }

  function onRequestDataHandlerModalMeter() {
    const { utility, meterName, supplier } = meterData
    setRequestData(modalSelectedMeters.map(meter => {
      return {
        ...meter,
        meterName: meterName,
        utility: utility,
        supplier: supplier,
      }
    })
    )
    setMeterModalShow(false)
    setShowRequestDataDlg(true)
  }

  function updateSelectedMeters(selectedMeters) {
    setModalSelectedMeters(selectedMeters)
  }

  function onDialogActionHandler() {
    setShowRequestDataDlg(false)
    setModalSelectedMeters([])
    setRequestData([])
    unBlockDialogScroll()
  }

  const responsibilityText = getLocalizedValue(selectedLanguage, `t_${meterData.responsibility.toLowerCase()}`)
  const utilityText = getLocalizedValue(selectedLanguage, `t_${meterData.utility.toLowerCase()}`)
  const combinedText = responsibilityText + ' - ' + utilityText
  
  return (
    <div className="meter-graph">
      <div className="meter-graph__labels flex justify-content-between">
        <div className="meter-graph__labels__left">{meterData.meterName}</div>
        <div className="meter-graph__labels__right">
          {combinedText}
        </div>
      </div>
      <div className="meter-graph__gaps flex">
        {getGraphGaps()}
      </div>
      {meterModalShow &&
          <DqMeterModalV2
            showDialog={meterModalShow}
            onHideHandler={meterModalOnHide}
            meterModalGapData={meterModalGapData}
            meterData={meterData}
            metersSelected={modalSelectedMeters}
            updateSelectedMeters={updateSelectedMeters}
            onRequestDataHandler={onRequestDataHandlerModalMeter}
            onEnterDataHandler={onEnterDataHandler}
          />}
      {showRequestDataDlg &&  
          <RequestDataDialogV2
            showDialog={showRequestDataDlg}
            onEventActionHideHandler={onDialogActionHandler}
            data={requestData}
            assetAddress={rowData.assetAddress}
            sendDataHandler={sendDataHandler}
          />
      }
      {errorDialogVisible && <ShowErrorDialog />}

    </div>
  )
}

MeterGraph.propTypes = {
  meterData: PropTypes.object,
  sendDataHandler: PropTypes.func,
  onEnterDataHandler: PropTypes.func
}
MeterGraph.displayName = 'MeterGraph'

export default MeterGraph