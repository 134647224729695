import {memo, useContext} from 'react'
import PropTypes from 'prop-types'
import {DataTable} from 'primereact/datatable'
import {noData} from '../../../../../utils/helpers/MessageUtility'
import TargetPreviousVarianceLegend from '../../../../common/period-view/TargetPreviousVarianceLegend'
import {Column} from 'primereact/column'
import I18n from '../../../../../utils/i18n/I18n'
import {CachedUserState, createNavURLForFundPages} from '../../../../../utils/helpers/Helper'
import CustomTooltip from '../../../../common/tooltip/Tooltip'
import {FUND_VIEWS_PAGES, GAS_FUELS, LOCAL_STORAGE_KEYS, UtilityTypes} from '../../../../../utils/helpers/Constants'
import {PortfolioContext} from '../../../../../Contexts'
import {useHistory, useParams} from 'react-router-dom'
import {getActionsTemplate, floorAreaTemplate, formatDate, getPortfolioPaginatorTemplate, occupancyTemplate, storeAssetData, assetCategoryTemplate, getContentCellNode, getUtilBodyClasses, energyRatingTemplate, dataCompletenessChartTemplate, dataCoverageChartTemplate} from '../hooks-and-helpers/PortfolioHelper'
import './PortfolioDataTableV3.scss'
import TableCellImageText from './TableCellImageText'
import {Text} from '../../../../atomic'
import { getSortIcons } from 'utils/helpers/DataTableUtils'

function PortfolioDataTableV3({
  portfolioDataPaginated,
  updatePortfolioState,
  first,
  rows,
  selectedLanguage,
  filterStateV3,
  unitSystem,
  isEsScoreDropdownVisible
}) {

  //region DECLARATIONS/INITIALISATIONS
  const {pfState, pfDispatcher} = useContext(PortfolioContext) // kept for backward-compatibility with older pages
  const {instanceName} = useParams()
  const history = useHistory()
  const {sortByField, sortByFieldOrder, sortByParameter,} = filterStateV3

  const {data: portfolioViewData, headers} = portfolioDataPaginated || {}
  const totalCount = headers?.['x-total-count']
  const totalUnPaginatedRecords = totalCount ? parseInt(totalCount) : portfolioViewData?.length ?? 0
  const sortIconToggle = sortByFieldOrder === 1 ? 'sort-down' : 'sort-up'
  const formattedDate = formatDate(new Date(), selectedLanguage)
  const SELECTED_COLUMN = 'selected-column'
  const EMPTY_STRING = ''
  //endregion

  //region HANDLERS/FUNCTIONS
  function getActionsHeaderAndIconElement() {
    return (
      <>
        <CustomTooltip position="top" target=".tooltip-action-info" toolTipClassName={'common-tooltip actions-tooltip'}>{I18n('t_actions_date', {0: formattedDate})}</CustomTooltip>
        <span className="action-label">{I18n('t_action_plan')}</span>
      </>
    )
  }

  const onPageChange = (e) => {
    updatePortfolioState({
      first: e.first,
      page: e.page,
      rows: e.rows
    })
  }

  function getInstanceUrlName(rowData) {
    return rowData.instanceUrlName ? rowData.instanceUrlName : instanceName
  }

  const gotoPage = (rowData, colData, pathSuffix) => {
    pfDispatcher({...pfState, ...{data: rowData, colField: colData.field}})
    CachedUserState.saveUserDataToCache(LOCAL_STORAGE_KEYS.selectedOrgAndFund, {
      orgName: rowData.organisationName,
      orgUrlName: rowData.organisationUrlName,
      fundName: rowData.fundName,
      fundUrlName: rowData.fundUrlName
    })
    const instanceUrlName = getInstanceUrlName(rowData)
    const navURLConfig = {
      instanceName: instanceUrlName,
      orgName: rowData.organisationUrlName,
      fundName: rowData.fundUrlName,
      assetId: rowData.assetId
    }
    if (pathSuffix) {
      navURLConfig.pathSuffix = pathSuffix
    }
    history.push(createNavURLForFundPages(navURLConfig))
  }

  function onSort(e) {
    let orderRev = sortByFieldOrder
    if (e.sortField === sortByField) {
      if (orderRev === 1) {
        orderRev = -1
      } else {
        orderRev = 1
      }
    }

    updatePortfolioState({
      sortOrderText: orderRev === -1 ? 'asc' : 'desc',
      filterStateV3: {
        ...filterStateV3,
        sortByField: e.sortField,
        sortByParameter: e.sortField, //Set sort utility dropdown
        sortByFieldOrder: orderRev,
        sortEnableIcon: sortByFieldOrder === -1
      }
    })
  }

  function getAssetDetailsTemplate(rowData, colData) {
    return <TableCellImageText {...{rowData, colData, gotoPage}} />
  }

  function cellClickAction({field, rowData}) {
    if (['carbonPercentage', 'electricityPercentage', 'fuelsPercentage', 'waterPercentage', 'wastePercentage'].includes(field)) {
      gotoPage(rowData, {field}, FUND_VIEWS_PAGES.performance)
    }
  }
  //endregion

  return (
    <div className={portfolioViewData?.length>0 ? 'portfolio-data-view' : 'portfolio-data-view empty-table'}>
      <DataTable
        value={portfolioViewData}
        onSort={onSort}
        onPage={e => onPageChange(e)}
        onRowClick={storeAssetData}
        sortField={sortByField}
        sortOrder={sortByFieldOrder}
        className="portfolio-data-view__table"
        emptyMessage={noData()}
        paginator={true}
        first={first}
        rows={rows}
        lazy
        totalRecords={totalUnPaginatedRecords}
        paginatorTemplate={totalUnPaginatedRecords > 10 ? getPortfolioPaginatorTemplate() : null}
        paginatorRight={<TargetPreviousVarianceLegend selectedLanguage={selectedLanguage}/>}
        paginatorClassName={'data-table-paginator'}
        cellSelection
        onCellClick={cellClickAction}
        scrollable={true}
        sortIcon={getSortIcons()}
      >
        <Column
          field="gia"
          header={I18n('t_asset_reference_address')}
          headerClassName={` not-sortable ${sortIconToggle}`}
          body={getAssetDetailsTemplate}
          className={`asset-details-column ${(sortByParameter === 'gia') ? SELECTED_COLUMN : EMPTY_STRING}`}
          frozen={true}
        />
        <Column
          field=""
          header={EMPTY_STRING}
          headerClassName={` ${sortIconToggle}`}
          body={(rowData)=>assetCategoryTemplate(rowData, selectedLanguage)}
          className={'asset-category-column'}
        />
        <Column
          field="carbonPercentage"
          sortField={UtilityTypes.Carbon}
          header={I18n('t_carbon_emissions')}
          headerClassName={` ${sortIconToggle} h-carbon-column`}
          body={(rowData, colData)=>getContentCellNode(rowData, colData, UtilityTypes.Carbon, selectedLanguage)}
          bodyClassName={(_, options)=>getUtilBodyClasses(options, 'carbon-column ', sortByParameter === UtilityTypes.Carbon)}
          sortable
        />
        <Column
          field="electricityPercentage"
          sortField={UtilityTypes.Electricity}
          header={I18n('t_electricity')}
          headerClassName={` ${sortIconToggle} h-electricity-column`}
          body={(rowData, colData)=>getContentCellNode(rowData, colData, UtilityTypes.Electricity, selectedLanguage)}
          bodyClassName={(_, options)=>getUtilBodyClasses(options, 'electricity-column', sortByParameter === UtilityTypes.Electricity)}
          sortable
        />
        <Column
          field="fuelsPercentage"
          sortField={GAS_FUELS}
          header={I18n('t_gas_fuel_thermal')}
          headerClassName={` ${sortIconToggle} h-fuels-thermals-column`}
          body={(rowData, colData)=>getContentCellNode(rowData, colData, GAS_FUELS, selectedLanguage)}
          bodyClassName={(_, options)=>getUtilBodyClasses(options, 'fuels-thermals-column', sortByParameter === GAS_FUELS)}
          sortable
        />
        <Column
          field="waterPercentage"
          sortField={UtilityTypes.Water}
          header={I18n('t_water')}
          headerClassName={` ${sortIconToggle} h-water-column`}
          body={(rowData, colData)=>getContentCellNode(rowData, colData, UtilityTypes.Water, selectedLanguage)}
          bodyClassName={(_, options)=>getUtilBodyClasses(options, 'water-column', sortByParameter === UtilityTypes.Water)}
          sortable
        />
        <Column
          field="wastePercentage"
          sortField={UtilityTypes.Waste}
          header={I18n('t_waste_recycling')}
          headerClassName={` ${sortIconToggle} h-waste-column`}
          body={(rowData, colData)=>getContentCellNode(rowData, colData, UtilityTypes.Waste, selectedLanguage)}
          bodyClassName={(_, options)=>getUtilBodyClasses(options, 'waste-column', sortByParameter === UtilityTypes.Waste)}
          sortable
        />
        <Column
          field="actionRank"
          header={getActionsHeaderAndIconElement}
          headerClassName={` not-sortable ${sortIconToggle}`}
          body={(rowData, colData)=>getActionsTemplate(rowData, colData, selectedLanguage, gotoPage)}
          className={'actions-column cursor-pointer'}
        />
        <Column
          field='dataQualityCompleteness'
          header={I18n('t_data_completeness')}
          headerClassName={` not-sortable ${sortIconToggle}`}
          body={(rowData, colData)=>dataCompletenessChartTemplate(rowData, colData, gotoPage)}
          bodyClassName={(_, {rowIndex})=>(`data-completeness-column dataqualitycompleteness-dq-chart-${rowIndex+1}`)}
        />
        <Column
          field='dataQualityCoverage'
          header={I18n('t_data_coverage')}
          headerClassName={` not-sortable ${sortIconToggle}`}
          body={(rowData, colData)=>dataCoverageChartTemplate(rowData, colData, gotoPage)}
          bodyClassName={(_, {rowIndex})=>(`data-coverage-column dataqualitycoverage-dq-chart-${rowIndex+1}`)}
        />
        <Column
          field="fundName"
          header={I18n('t_fundname')}
          headerClassName={` not-sortable ${sortIconToggle}`}
          className={'fund-name-column'}
          body={(rowData)=><Text content={`${rowData.fundName}`} />}
        />
        <Column
          field="gia"
          header={I18n('t_floor_area')}
          headerClassName={` not-sortable ${sortIconToggle}`}
          body={(rowData)=>floorAreaTemplate(rowData, unitSystem, selectedLanguage)}
          className={'floor-area-column'}
        />
        <Column
          field="occupancy"
          header={I18n('t_occupancy')}
          headerClassName={` not-sortable ${sortIconToggle}`}
          body={occupancyTemplate}
          className={'occupancy-column'}
        />
        <Column
          field="epcRating"
          header={I18n('t_energy_rating')}
          headerClassName={` not-sortable ${sortIconToggle}`}
          body={(rowData)=>energyRatingTemplate(rowData, isEsScoreDropdownVisible)}
          className={'energy-rating-column'}
        />
      </DataTable>
    </div>
  )
}

PortfolioDataTableV3.propTypes = {
  portfolioDataPaginated: PropTypes.object,
  updatePortfolioState: PropTypes.func,
  first: PropTypes.number,
  rows: PropTypes.number,
  selectedLanguage: PropTypes.string,
  filterStateV3: PropTypes.object,
  unitSystem: PropTypes.string,
}

PortfolioDataTableV3.displayName = 'PortfolioDataTableV3'

export default memo(PortfolioDataTableV3)