import {Page, Text, View, Document, Image, Font} from '@react-pdf/renderer'
import React, { useState} from 'react'
import PdfLogo from '../../../../../utils/data-exports/fixed-components/logo/PdfLogo'
import PdfPagination from '../../../../../utils/data-exports/fixed-components/footer/PdfPagination'
import PdfHeader from '../../../../../utils/data-exports/fixed-components/header/PdfHeader'
import PdfPageBreak from '../../../../../utils/data-exports/fixed-components/PdfPageBreak'
import Asset from '../../../../../utils/data-exports/asset/Asset'
import styles from '../../../../../utils/data-exports/style-sheet/Styles'
import UtilityCard from '../../../../../utils/data-exports/utility/UtilityCard'
import UtilityForChart from '../../../../../utils/data-exports/utility/UtilityForChart'
import ActionPlan from '../../../../../utils/data-exports/common-tables/ActionPlan'
import {I18nProvider} from '../../../../../utils/i18n'
import I18n from '../../../../../utils/i18n/I18n'
import {getFillerImage, getLocalizedValue} from '../../../../../utils/helpers/Helper'
import {
  CARBON,
  ELECTRICITY,
  UTILITY_LABELS_OBJECT_TITLE_CASE,
  WASTE,
  WATER
} from '../../../../../utils/helpers/Constants'

Font.registerHyphenationCallback(word => (
  [word]
))

const PassportPdf = (props) => {
  const today = new Date(), currentYear = today.getFullYear(), previousYear = today.getFullYear() - 1
  const [utilities] = useState(props.utility ? props.utility : [])
  const classifyUtility = (utilities,value) => {
    let result=''
    for (const utility of utilities) {
      if(utility.utility === value){
        result=utility
        break
      }
    }
    return result
  }

  const renderLegend = (isWaste) => {
    return (
      <View style={[styles.tableRow, isWaste ? styles.withWaste : styles.withOutWaste]}>
        {isWaste ?
          <View style={[styles.tableRow,styles.pL5]}>
            <View style={styles.passportLegendWaste}/>
            <View style={styles.pL5}><Text style={[styles.assetTextSeven,styles.topM2]}>{getLocalizedValue(props.language, 't_non_recycled')}</Text></View>
          </View> :  null
        }
        <View style={[styles.tableRow,styles.pL5]}>
          <View style={styles.passportLegendFirst}/>
          <View style={styles.pL5}><Text style={[styles.assetTextSeven,styles.topM2]}>{currentYear-1}/{currentYear}</Text></View>
        </View>
        <View style={[styles.tableRow,styles.pL5]}>
          <View style={styles.passportLegendSecond}/>
          <View style={styles.pL5}><Text style={[styles.assetTextSeven,styles.topM2]}>{previousYear-1}/{previousYear}</Text></View>
        </View>
      </View>
    )
  }

  const tableForElectricityChart = (props) => (
    <View style={[styles.charts, styles.passportChartSize]} wrap={false}>
      {renderLegend()}
      <Text style={[styles.title, styles.passportChartTitle]}>{I18n('t_electricity')}</Text>
      <Image src={props.electricity} style={[styles.image, {}]}/>
      <UtilityForChart utility={classifyUtility(utilities,'Electricity')} language={props.language}/>
    </View>
  )

  const tableForWasteChart = (props) => (
    <View style={[styles.charts, styles.passportChartSize]} wrap={false}>
      {renderLegend(true)}
      <Text style={[styles.title, styles.passportChartTitle]}>{I18n('t_waste_recycling')}</Text>
      <Image src={props.waste} style={[styles.image, {}]}/>
      <UtilityForChart utility={classifyUtility(utilities,'Waste')} language={props.language}/>
    </View>
  )

  const tableForThermalsChart = (props) => (
    <View style={[styles.charts, styles.passportChartSize, styles.passportChartLeft]} wrap={false}>
      {renderLegend()}
      <Text style={[styles.title, styles.passportChartTitle]}>{I18n('t_gas_fuel_thermal')}</Text>
      <Image src={props.thermal} style={[styles.image, {}]}/>
      <UtilityForChart utility={classifyUtility(utilities,'GasFuelsAndThermals')} language={props.language}/>
    </View>
  )

  const tableForWaterChart = (props) => (
    <View style={[styles.charts, styles.passportChartSize]} wrap={false}>
      {renderLegend()}
      <Text style={[styles.title, styles.passportChartTitle]}>{I18n('t_water')}</Text>
      <Image src={props.water} style={[styles.image, {}]}/>
      <UtilityForChart utility={classifyUtility(utilities,'Water')} language={props.language}/>
    </View>
  )

  const tableForCarbonChart = (props) => (
    <View style={[styles.charts, styles.passportChartSize, styles.passportChartLeft]} wrap={false}>
      {renderLegend()}
      <Text style={[styles.title, styles.passportChartTitle]}>{I18n('t_carbon')}</Text>
      <Image src={props.carbon} style={[styles.image, {}]}/>
      <UtilityForChart utility={classifyUtility(utilities,'Carbon')} language={props.language}/>
    </View>
  )

  return (
    <Document>
      <I18nProvider locale={props.language}>
        <Page size="A4" style={styles.page}>
          <View>
            <View style={styles.tableRow} fixed>
              <View>
                <PdfLogo header={I18n('t_esg_passport')}/>
              </View>
              <View>
                <PdfHeader asset={props.asset} language={props.language}/>
              </View>
            </View>

            <View style={[styles.title,styles.dateRangePosition]} fixed>
              <Text style={styles.textAlignRight}>{I18n('t_date_range')}: {I18n('t_graph_ytd')}</Text>
            </View>

            <Asset assetImgSrc={getFillerImage(props.asset, true, true)} asset={props.asset}
              language={props.language} lastRefurbished={props.lastRefurbished} certifications={props.certifications}
              isDashboard={false}/>

            <UtilityCard utility={classifyUtility(utilities,ELECTRICITY)} passport={true} utilityName={ELECTRICITY}
              time={I18n('t_graph_ytd')} language={props.language}/>

            <UtilityCard utility={classifyUtility(utilities,UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL)}
              passport={true} utilityName={UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL}
              time={I18n('t_graph_ytd')} language={props.language}/>

            <UtilityCard utility={classifyUtility(utilities,WATER)} passport={true} utilityName={WATER}
              time={I18n('t_graph_ytd')} language={props.language}/>

            <UtilityCard utility={classifyUtility(utilities,WASTE)} passport={true} utilityName={WASTE}
              time={I18n('t_graph_ytd')} language={props.language}/>

            <UtilityCard utility={classifyUtility(utilities,CARBON)} passport={true} utilityName={CARBON}
              time={I18n('t_graph_ytd')} language={props.language}/>

            <Text style={[styles.title, styles.passportTableTitle]}>
              {I18n('t_completed_actions')}
            </Text>

            {props.data.length>0 ? <ActionPlan data={props.data} language={props.language} isDashboard={false}/>:
              <View style={[styles.passportTable,styles.planBorder]}>
                <Text style={[styles.title,styles.noRecords]} break>{I18n('t_no_results')}</Text>
              </View>
            }

            <PdfPageBreak/>
            <View style={[styles.tableRow, styles.passportChartFirst]} wrap={false}>
              {tableForElectricityChart(props)}
              {tableForThermalsChart(props)}
            </View>
            <View style={[styles.tableRow, styles.passportChartSecond]} wrap={false}>
              {tableForWaterChart(props)}
              {tableForCarbonChart(props)}
            </View>
            <View style={[styles.tableRow, styles.passportChartFirst]} wrap={false}>
              {tableForWasteChart(props)}
            </View>
            <PdfPagination/>
          </View>
        </Page>
      </I18nProvider>
    </Document>
  )
}

export default PassportPdf

PassportPdf.displayName = 'PassportPdf'