import React, {useEffect, useRef, useState} from 'react'
import './UserAssets.scss'
import UserFooter from '../user-footer/UserFooter'
import AssetsContainer from '../../../Common/asset/AssetsContainer'
import I18n from '../../../../../../utils/i18n/I18n'
import {useMutation, useQuery} from 'react-query'
import {
  AddAssetAccess,
  addAssetGroups,
  addRemoveFundsFromUser,
  AddUser,
  getAllFunds,
  GetGroupsListById,
  getUserAssetData,
  UpdateAssetAccess,
  updateAssetGroups,
  UpdateUser
} from '../../../../../../services/admin/users-service'
import {fetchAfterLongTime, noRefetchOnWindowFocus} from '../../../../../../services/common/useQuery-config'
import {
  ADMIN_PAGES,
  UserActions,
  userAssetDetails,
  userFundDetails,
  userGroupDetails,
  userInfo
} from '../../../../../../utils/helpers/Constants'
import ErrorDialog from '../../../../assets/data-quality/error-dialog/ErrorDialog'
import {useHistory} from 'react-router-dom/cjs/react-router-dom'
import {
  createNavURLForUserPages,
  createUserAssetGroupsObject,
  getInstance,
  getLocalStorageByKey,
  getLoggedInUserRole,
  getStructuredFund,
  hasPermission,
  messageCheck,
  setLocalStorageItem
} from '../../../../../../utils/helpers/Helper'
import {useParams} from 'react-router-dom'

function UserAssets(props){
  //region Declarations/Initialisations
  const MSG_KEY_TITLE_CANNOT_POST_DATA = 't_title_cannot_post_data'
  const MSG_KEY_TITLE_CANNOT_PUT_DATA = 't_title_cannot_put_data'
  const pathToUsersPage=createNavURLForUserPages({instanceName: getInstance(),pathSuffix: ADMIN_PAGES.users })
  const [errorDialogVisible, setErrorDialogVisible] = useState(false)
  const [errorDialogData, setErrorDialogData] = useState()
  const history = useHistory()
  const assetsSelectedTabsActiveIndex=useRef(0)
  const [filteredAssetDetails, setFilteredAssetDetails] = useState([])
  const [assetDetails, setAssetDetails] = useState([])
  const [selectedAssets, setSelectedAssets] = useState([])
  const [initiallySelectedAssets, setInitiallySelectedAssets] = useState([])
  const emptyStr = ''
  const hasPermissionForEditUser=hasPermission(UserActions.EDIT_USER, getLoggedInUserRole())
  const assetsStatusInactiveClass = 'assets-status-inactive'
  const [totalAssetsActiveClass, setTotalAssetsActiveClass] = useState(emptyStr)
  const [memberAssetsActiveClass, setMemberAssetsActiveClass] = useState(assetsStatusInactiveClass)
  const isEditUserAction = () => props.userAction === UserActions.EDIT_USER
  const urlParams = useParams()
  const {data: userAssetGroups,isLoading : isLoadingSelectedGroup}  = useQuery(['userAssetGroups', urlParams.userId], GetGroupsListById, noRefetchOnWindowFocus)
  useQuery(['allFunds'], getAllFunds, fetchAfterLongTime)
  const getUserAssetDataQuery = useQuery(['userAssetData', getUserAuthId()], getUserAssetData, noRefetchOnWindowFocus)
  const addAssetAccessMutation = useMutation(AddAssetAccess)
  const updateAssetAccessMutation = useMutation(UpdateAssetAccess)
  const addUserMutation = useMutation(AddUser)
  const updateUserMutation = useMutation(UpdateUser)
  const addUserAssetGroupsMutation = useMutation(addAssetGroups)
  const updateUserAssetGroupsMutation = useMutation(updateAssetGroups)
  const addRemoveFundUserMutation = useMutation(addRemoveFundsFromUser)
  const [assetsViaGroupsAndFunds, setAssetsViaGroupsAndFunds] = useState([])
  const [isTabChanged, setTab] = useState(false)
  //endregion
  //region Side effects.
  useEffect(()=>{
    props.visited && props.visited(3)
  },[])
  useEffect(() => {
    const completeAssetsList=getUserAssetDataQuery.data
    // For AssetAccess
    if (completeAssetsList) {
      let assetsSelectedViaAssetsTab = getAssetsFromRespectiveTabs(completeAssetsList,getLocalStorageByKey(userAssetDetails)?.assetsDetails ,'assets-tab')
      let assetsSelectedViaGroupsTab = getAssetsFromRespectiveTabs(completeAssetsList,getLocalStorageByKey(userGroupDetails)?.assetIdsForSelectedGroups,'groups-tab')
      let assetsSelectedViaFundsTab = getAssetsFromRespectiveTabs(completeAssetsList,getLocalStorageByKey(userFundDetails)?.assetIdsOfSelectedFunds,'funds-tab')

      let assetsSelectedViaFundsAndGroupsTab=[...assetsSelectedViaGroupsTab, ...assetsSelectedViaFundsTab]
      // Remove duplicate assets
      if(assetsSelectedViaFundsAndGroupsTab.length>0){
        assetsSelectedViaFundsAndGroupsTab = assetsSelectedViaFundsAndGroupsTab.filter(removeDuplicateAssets)
      }
      if(assetsSelectedViaAssetsTab.length>0){
        assetsSelectedViaAssetsTab = assetsSelectedViaAssetsTab.filter(removeDuplicateAssets)
      }

      let assetsSelectedViaFundsGroupsAndAssetsTab = [...assetsSelectedViaAssetsTab, ...assetsSelectedViaFundsAndGroupsTab]
      assetsSelectedViaFundsGroupsAndAssetsTab = assetsSelectedViaFundsGroupsAndAssetsTab.filter(removeDuplicateAssets)
      setInitiallySelectedAssets(assetsSelectedViaFundsGroupsAndAssetsTab)
      setSelectedAssets(assetsSelectedViaFundsGroupsAndAssetsTab)
      setAssetsViaGroupsAndFunds(assetsSelectedViaFundsAndGroupsTab)
      setAssetDetails(completeAssetsList)
      setFilteredAssetDetails(completeAssetsList)

    }

    const userAssetDetailsCache=getLocalStorageByKey(userAssetDetails)
    const cacheObject={isAssetsNavigatable: true, isUnchanged: true}
    if(userAssetDetailsCache && userAssetDetailsCache.assetsDetails) {
      cacheObject.assetsDetails = userAssetDetailsCache.assetsDetails
    }
    setLocalStorageItem(userAssetDetails, cacheObject)
  }, [getUserAssetDataQuery.data])

  useEffect(() => {
    if(initiallySelectedAssets.length !== selectedAssets.length){
      props.unSavedData()
    }
  },[selectedAssets])
  //endregion

  //region Helper methods for user assets component.
  const removeDuplicateAssets=(asset, index, assets) =>
    !!asset && index === assets.findIndex((argAsset) =>
      (!!argAsset && argAsset.assetId === asset.assetId))

  function getAssetsFromRespectiveTabs(completeAssetsList, cacheInfo, tab) {
    if (cacheInfo) {
      if (tab === 'assets-tab') {
        return cacheInfo
      } else {
        return cacheInfo.map(assetId => completeAssetsList.find(asset => asset.assetId === assetId))
      }
    } else {
      if (tab === 'assets-tab') {
        return completeAssetsList.filter(asset => asset.isAssetAccess && !asset.isGroupAccess && !asset.isFundAccess)
      } else {
        return completeAssetsList.filter(asset => tab === 'funds-tab' ? asset.isFundAccess : asset.isGroupAccess)
      }
    }
  }

  function cacheSelectedAssetsExcludingGroupAssets(newSelectedAssets) {
    let selectedAssets
    if(newSelectedAssets && newSelectedAssets.length>0){
      selectedAssets=newSelectedAssets.filter((asset)=>!(isAssetBelongToUserGroup(asset.assetId)))
    }
    const userDetails={isAssetsNavigatable: true}
    if(selectedAssets) {
      userDetails.assetsDetails = selectedAssets
    }
    localStorage.setItem(userAssetDetails, JSON.stringify(userDetails))
  }

  function getSelectedOrCreatedUser() {
    return (isEditUserAction() ? props.userDetails : props.createdNewUser)
  }

  async function getSelectedOrCreatingNewUser() {
    return (isEditUserAction() ? props.userDetails : await postNewUserData(getLocalStorageByKey(userInfo)))
  }

  function getUserAuthId() {
    const userDetails = getSelectedOrCreatedUser()
    return (userDetails && userDetails.authorisationId ? userDetails.authorisationId : null)
  }

  async function getUserInfo() {
    const userDetails = await getSelectedOrCreatingNewUser()
    return (userDetails ? userDetails : null)
  }

  const postNewUserData = async (userData) => {
    let newUser
    newUser = await addUserMutation.mutateAsync(userData.userDetails)
    if(newUser.hasOwnProperty('userId')){
      props.updateNewUser(newUser)
    } else {
      setErrorDialogData({title: I18n(MSG_KEY_TITLE_CANNOT_POST_DATA),
        message: `Error: ${messageCheck(newUser)}`})
      setErrorDialogVisible(true)
    }
    return newUser
  }

  function isAssetBelongToUserGroup(assetId) {
    let isAssetFromUserGroupOrFund
    if(props.userAction === UserActions.EDIT_USER){
      if (userAssetGroups && userAssetGroups.assets) {
        isAssetFromUserGroupOrFund = assetsViaGroupsAndFunds.map(asset => asset.assetId).includes(assetId)
      }
      if(!isAssetFromUserGroupOrFund){
        const userGroupDetailsObject=getLocalStorageByKey(userGroupDetails)
        if(userGroupDetailsObject && userGroupDetailsObject.assetIdsForSelectedGroups){
          isAssetFromUserGroupOrFund=userGroupDetailsObject.assetIdsForSelectedGroups.includes(assetId)
        }
      }
    } else {
      const userGroupDetailsObject=getLocalStorageByKey(userGroupDetails)
      if(userGroupDetailsObject && userGroupDetailsObject.assetIdsForSelectedGroups){
        isAssetFromUserGroupOrFund=userGroupDetailsObject.assetIdsForSelectedGroups.includes(assetId)
      }
    }
    if(!isAssetFromUserGroupOrFund){
      const userFundDetailsFromCache=getLocalStorageByKey(userFundDetails) // Get assets from previously cached selected funds.
      const assetIdsOfSelectedFunds=userFundDetailsFromCache?.assetIdsOfSelectedFunds
      if(assetIdsOfSelectedFunds){
        isAssetFromUserGroupOrFund=assetIdsOfSelectedFunds.includes(assetId)
      }
    }
    return isAssetFromUserGroupOrFund
  }

  function selection(selection,filteredList) {
    cacheSelectedAssetsExcludingGroupAssets(selection)
    setSelectedAssets(selection)
    if (assetsSelectedTabsActiveIndex.current === 1) {
      setFilteredAssetDetails(filteredList)
    }
  }

  function updatedFilteredList(filteredList){
    setFilteredAssetDetails(filteredList)
  }

  function allAssetsTabHandler() {
    assetsSelectedTabsActiveIndex.current=0
    setFilteredAssetDetails(getUserAssetDataQuery.data)
    setTotalAssetsActiveClass(emptyStr)
    setMemberAssetsActiveClass(assetsStatusInactiveClass)
    setTab(!isTabChanged)
  }

  function selectedAssetsTabHandler() {
    assetsSelectedTabsActiveIndex.current=1
    setFilteredAssetDetails(selectedAssets)
    setTotalAssetsActiveClass(assetsStatusInactiveClass)
    setMemberAssetsActiveClass(emptyStr)
    setTab(!isTabChanged)
  }

  function getChooseAssetsLabel() {
    let chooseAssetsLabel=I18n('t_choose_assets_for_new_user')
    if(props.userAction === UserActions.EDIT_USER){
      let userName=''
      if(props.userDetails && props.userDetails.userName){
        userName=props.userDetails.userName
      }
      chooseAssetsLabel=I18n('t_choose_assets_for_update_user', {0: userName})
    }
    return chooseAssetsLabel
  }

  function createAssetsIdsObject() {
    return {'assetIds': selectedAssets.map((selectedAsset) => selectedAsset.assetId)}
  }

  function excludeGroupAssetId(assetIdsFromSelectedAssets) {
    let assetIds=assetIdsFromSelectedAssets
    if(assetIdsFromSelectedAssets.length>0 && userAssetGroups && userAssetGroups.assets.length>0){
      const assetIdsFromUserGroups=assetsViaGroupsAndFunds?.map(asset=>asset.assetId)
      assetIds=assetIdsFromSelectedAssets.filter((assetId)=>!(assetIdsFromUserGroups.includes(assetId)))
    }
    return assetIds
  }

  const addUpdateUserAssets = async () => {
    let errorMsgKey = MSG_KEY_TITLE_CANNOT_POST_DATA
    let assetIdsObject = createAssetsIdsObject()
    assetIdsObject.assetIds=excludeGroupAssetId(assetIdsObject.assetIds)
    let userInfo = await getUserInfo()//User is created, If it is a new user -- Not disturbing the below flow
    let userAuthId =  getUserAuthId()
    if(isEditUserAction() || userInfo.hasOwnProperty('userId')){
      const userAssetIdsObject = {userAuthId: isEditUserAction() ? userAuthId : userInfo.authorisationId, assetIds: assetIdsObject}
      try {
        if (isEditUserAction()) {
          errorMsgKey = MSG_KEY_TITLE_CANNOT_PUT_DATA
          await handleEditUser(userAssetIdsObject,userInfo)
          history.goBack()
        }else{
          await handleNewUser(userAssetIdsObject,userInfo)
          history.push({pathname: pathToUsersPage, state: {...userInfo, isAddUser: true}})
        }
        await props.refetchUser()
      } catch (error) {
        const errorResponse = (error.response && error.response.data) ? `Error: ${error.response.data.title}` : error.toString()
        setErrorDialogData({title: I18n(errorMsgKey), message:errorResponse})
        setErrorDialogVisible(true)
      }
    }
  }

  async function handleEditUser(userAssetIdsObject,values) {
    await updateAssetAccessMutation.mutateAsync(userAssetIdsObject)
    //Saves Details data
    if (getLocalStorageByKey(userInfo)) {
      const userData = getLocalStorageByKey(userInfo).userDetails
      await updateUserMutation.mutateAsync({userId: values.userId, userData: userData})
    }
    //Saves Assets data
    if (getLocalStorageByKey(userGroupDetails)) {
      await postGroupsData(values)
    }
    //Saves Fund data
    if (getLocalStorageByKey(userFundDetails)) {
      await postFundsData(values)
    }
  }

  async function postFundsData(userData) {
    await addRemoveFundUserMutation.mutateAsync({
      funds: getStructuredFund(getLocalStorageByKey(userFundDetails).fundDetails)
      , authorisationId: userData.authorisationId
    })
  }

  async function handleNewUser(userAssetIdsObject,values) {
    if(userAssetIdsObject.assetIds.assetIds.length > 0){
      await addAssetAccessMutation.mutateAsync(userAssetIdsObject)
    }
    //Saves Assets data
    if (getLocalStorageByKey(userGroupDetails) && getLocalStorageByKey(userGroupDetails).assetGroups.length > 0) {
      await postGroupsData(values)
    }
    //Saves Fund data
    if (getLocalStorageByKey(userFundDetails) && getLocalStorageByKey(userFundDetails).fundDetails.length > 0) {
      await postFundsData(values)
    }
  }

  async function postGroupsData(userData) {
    let assetGroupIdsObject = createUserAssetGroupsObject(getLocalStorageByKey(userGroupDetails).assetGroups)
    let userAuthIdForGroups = userData.userId
    const userAssetGroupsObject = {userId: userAuthIdForGroups, assetGroups: assetGroupIdsObject}
    if(isEditUserAction()){
      await updateUserAssetGroupsMutation.mutateAsync(userAssetGroupsObject)
    } else {
      if(assetGroupIdsObject.assetGroupIds.length > 0)
      {
        await addUserAssetGroupsMutation.mutateAsync(userAssetGroupsObject)
      }
    }
  }
  //endregion
  return(
    <div className="user-asset">
      <div className="flex justify-content-between asset">
        <div>{getChooseAssetsLabel()}</div>
        <div className="asset-total-selected-container">
          <div className={`asset-total-count assets-count-status ${totalAssetsActiveClass}`} onClick={allAssetsTabHandler}>
            {assetDetails.length} {I18n('t_asset_s')}
          </div>
          <div className={`asset-selected-count assets-count-status ${memberAssetsActiveClass}`} onClick={selectedAssetsTabHandler}>
            {selectedAssets.length}
          </div>
          <div className={`asset-selected-label assets-count-status ${memberAssetsActiveClass}`} onClick={selectedAssetsTabHandler}>
            {I18n('t_selected')}
          </div>
        </div>
      </div>
      <AssetsContainer
        filteredAssetDetails={filteredAssetDetails}
        updatedFilteredList={updatedFilteredList}
        selectedUser={props.userDetails}
        edit={true} 
        isTabSwitched={isTabChanged}
        hasPermissionForEditUser={hasPermissionForEditUser}
        isLoading={isLoadingSelectedGroup || getUserAssetDataQuery.isLoading}
        selectedAssets={selectedAssets}
        assetDetails={assetDetails}
        selection={selection}
        currentTab={assetsSelectedTabsActiveIndex.current}
        isAssetBelongToUserGroup={isAssetBelongToUserGroup}
        assetsViaGroupsAndFunds={assetsViaGroupsAndFunds}
      />
      <div className={'footer-divider'}>
        <hr/>
      </div>
      <div className={'footer-container'}>
        <UserFooter
          submitHandler={addUpdateUserAssets}
          userAction={props.userAction}
          handleNextTab={null}
          hasPermissionForEditUser={props.hasPermissionForEditUser}
        />
      </div>
      {errorDialogVisible && <ErrorDialog
        data={errorDialogData}
        dialogVisible={errorDialogVisible}
        onHideHandler={()=>setErrorDialogVisible(false)}
        closeHandler={()=>setErrorDialogVisible(false)}
      />}
    </div>
  )
}

export default UserAssets