import React from 'react'
import BreadCrumbNavigation from './BreadCrumbNavigation'

/* eslint-disable react/display-name */
const withBreadCrumb = (WrappedComponent) => {
  return () => {
    return (
      <>
        <BreadCrumbNavigation/>
        <WrappedComponent />
      </>
    )
  }
}

export default withBreadCrumb