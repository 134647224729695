import { Icon } from 'components/atomic/index'
import {Field} from 'formik'
import PropTypes from 'prop-types'
import {Calendar} from '../../../atomic'

const CalendarFormik = ({ name, label, errors, touched, value, isEnabled=false, placeholder, dateFormat, testId, view,purchaseDate, clear = false }) => {
  const isTouched = touched ? touched[name] : true
  const today = new Date()
  const maxDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())
  const minDate = purchaseDate ? new Date(purchaseDate) : null

  return (
    <div className='flex flex-column my-2 gap-1'>
      <label htmlFor={name}>{label}</label><Field name={name}>
        {({ field, form: { setFieldTouched, setFieldValue }}) => (
          <Calendar
            {...{...field, onClick: () => { touched && !touched[name] && setFieldTouched(name, true)}}}
            dateFormat={dateFormat}
            closeIcon={value && clear ? <Icon id='close' onClickHandler={(e) => { setFieldValue(name, ''); e.stopPropagation()}} size={17} /> : false }
            name={name}
            value={value}
            disabled={isEnabled}
            testId={testId}
            apppendSelf={false}
            minDate={minDate}
            maxDate={maxDate}
            placeholder={placeholder}
            view={view}
            className={`${errors[name] && isTouched ? 'p-error-border' : ''}`}/>
        )}
      </Field>
      {errors[name] && isTouched && (
        <span className="error medium-text my-0" data-testid={`${testId}-error`}>
          {errors[name]}
        </span>
      )}
    </div>
  )
}

CalendarFormik.propTypes = {
  label: PropTypes.string,
  errors: PropTypes.object,
  isEnabled: PropTypes.bool,
  touched: PropTypes.object,
  value: PropTypes.string,
  dateFormat: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  testId: PropTypes.string,
  view: PropTypes.string,
  purchaseDate: PropTypes.string,
  clear: PropTypes.bool
}

export default CalendarFormik