import React from 'react'
import {Skeleton} from 'primereact/skeleton'
import './FundDataQualityProcurementMissingValueSkeleton.scss'

const FundDataQualityProcurementMissingValueSkeleton = () => {
  return (
    <div className="fund-dq-procurement-missing-value-skeleton">
      <Skeleton width="100px" borderRadius="3px" height="30px" />
    </div>
  )
}

export default FundDataQualityProcurementMissingValueSkeleton