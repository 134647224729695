import React, {useContext, useEffect, useRef, useState} from 'react'
import {useParams} from 'react-router-dom'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {useIntl} from 'react-intl'
import {Button} from 'primereact/button'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {InputText} from 'primereact/inputtext'
import {Dropdown} from 'primereact/dropdown'
import {Checkbox} from 'primereact/checkbox'
import {Tooltip} from 'primereact/tooltip'
import I18n from '../../../utils/i18n/I18n'
import {fetchAfterLongTime, noRefetchOnWindowFocus} from '../../../services/common/useQuery-config'
import {
  GetAllNotifications,
  GetAssetHeader,
  UpdateNotification,
  UpdateNotifications
} from '../../../services/notification-service'
import {noDataMessage} from '../../../utils/helpers/MessageUtility'
import {monthList, notiType} from '../../../utils/helpers/Constants'
import {formatNumWODecimal, trucateNumWODecimal} from '../../../services/common/formatter'
import {PortfolioContext} from 'Contexts'
import {
  getFillerImage,
  getLocalizedDateMessage,
  getLocalizedValue,
  getPaginatorTemplate,
  getPerformanceModalHeaderData,
  selectedUtilityKeyNames
} from '../../../utils/helpers/Helper'
import NotificationsGridSkeleton from '../../common/skeletons/notifications-grid-skeleton/NotificationsGridSkeleton'
import {
  addNewMissingDataRequestInternal,
  getFirstLastDateISO,
  monthNamesArray
} from '../../../services/assets/dataquality-service'
import RequestDataDialog from '../assets/performance/dialogs/RequestDataDialog'
import ModalDialogHome from '../../common/modal-dialogs/ModalDialogHome'
import PerformanceModalDialogHeader from '../assets/performance/dialogs/PerformanceModalDialogHeader'
import ActionEdit from '../assets/action-plan/dialogs/Action'
import {GetAssetEventsData, GetPerformanceDataByMonth} from '../../../services/assets/performance-service'
import ErrorDialog from '../assets/data-quality/error-dialog/ErrorDialog'
import {actionData} from '../../../services/assets/actionplan-service'
import {isTextOverflown} from '../../../utils/UtilsFunctions'
import calendarIcon from '../../../resources/images/icon/calendar-icon.svg'
import perfGraphIcon from '../../../resources/images/icon/perf-graph-icon.svg'
import exclamationMarkIcon from '../../../resources/images/icon/exclamation-mark-icon.svg'
import dataBaseIcon from '../../../resources/images/icon/database-icon.svg'
import checkMarkGreen from '../../../resources/images/icon/check-mark-green.svg'
import cross from '../../../resources/images/icon/cross-wo-bg.svg'
import ClearIcon from '../../../resources/images/icon/clear-search-icon.svg'
import clearFilterIcon from '../../../resources/images/icon/clear-filter-active.svg'
import CSS_VARIABLES from '../../../resources/css/_variables.scss'
import './Notification.scss'
import '../../common/paginator/Paginator.scss'
import BreadCrumbNavigation from '../../common/bread-crumb/BreadCrumbNavigation'
import { useLoginAuthContext } from '../login/auth0/UserInfoProvider'

function useUnMountComponent(){
  const [value, setValue] = useState(0)
  return () => setValue(value + 1)
}

function NotificationHeaders({
  selectedLanguage,
  fundFilterElements,
  locationFilterElements,
  nState,
  clearFilter,
  setNotificationState,
  onNotificationFilterKeyDown,
  faPlaceholder
}){
  return (
    <div className='table-header grid'>
      <div className='col-12 task-header'>
        <span className='xxx-large-text task-text'>{I18n('t_notifications')}</span>
      </div>
      <div className='col-12'>
        <div className='col-8 no-padding filter-dropdown mb-15'>
          <div className='col-4 no-padding filter-text'>{I18n('t_fund')}</div>
          <div className='col-4 no-padding filter-text'>{I18n('t_assets')}</div>
          <div className='col-4 no-padding filter-text'/>
        </div>

        <div className='filter-dropdown'>
          <div className='col-8 no-padding filter-dropdown'>
            <div className='col-4 no-padding'>{fundFilterElements}</div>
            <div className='col-4 no-padding'>{locationFilterElements}</div>
            <div className='col-4 no-padding'>
              <img style={{opacity: nState.fund === 'All' && nState.asset === 'All' ? '0.5' : '1'}}
                src={clearFilterIcon} alt={getLocalizedValue(selectedLanguage, 't_refresh')}
                className='cursor notif-highlight-clickable-opacity' onClick={clearFilter}/>
            </div>
          </div>
          <div className='col-1'/>
          <div className='col-3 no-padding filter-dropdown-end'>
            <span className='p-input-icon-left search-asset'>
              <i className='pi pi-search cursor'/>
              <form>
                <InputText value={nState.globalFilter} onChange={(e) => setNotificationState({globalFilter: e.target.value})} onKeyDown={(e) => onNotificationFilterKeyDown(e)} className='input-text' placeholder={faPlaceholder}/>
              </form>
              {nState.globalFilter !== '' && (
                <img src={ClearIcon} alt='Clear' className='clear-icon cursor highlight-clickable-opacity' onClick={() => setNotificationState({globalFilter: ''})}/>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}


function NotificationDataTable({
  tableData,
  headerNoData,
  propertyDetailsTemplate,
  cellColor,
  taskBody,
  typeBody,
  dateBody,
  tickBody,
  fundFilterElements,
  locationFilterElements
}){

  return (
    <DataTable {...tableData} >
      <Column header={headerNoData ? '' : I18n('t_assets')}
        headerClassName='notification-table-header'
        body={propertyDetailsTemplate}
        style={{'width': '24%', color: cellColor}}/>
      <Column header={headerNoData ? '' : I18n('t_task')}
        headerClassName='notification-table-header'
        body={taskBody}
        style={{'width': '44%', textAlign: 'left', color: cellColor}}/>
      <Column header={headerNoData ? '' : I18n('t_type')}
        headerClassName='notification-table-header'
        body={typeBody}
        style={{'width': '13%', textAlign: 'left', color: cellColor}}/>
      <Column header={headerNoData ? '' : tableData?.className.includes('completed-table') ? I18n('t_complete_by') : I18n('t_date')}
        headerClassName='notification-table-header'
        body={dateBody}
        style={{'width': '13%', textAlign: 'left', color: cellColor}}/>
      { tableData?.className.includes('completed-table') ?
        <Column headerClassName='notification-table-header'
          body={tickBody}
          style={{width: '6%', textAlign: 'center', paddingLeft: '0'}}/> :
        <Column header={headerNoData ? '' : I18n('t_complete')}
          headerClassName='notification-table-header'
          selectionMode='multiple'
          style={{width: '6%', textAlign: 'center', paddingLeft: '0'}}/>
      }
      <Column field='fundName' style={{display: 'none'}}
        filterElement={fundFilterElements}/>
      <Column field='assetAddress' style={{display: 'none'}}
        filterElement={locationFilterElements}/>
      <Column field='dataContent.0' style={{display: 'none'}}/>
      <Column field='dataContent.1' style={{display: 'none'}}/>
      <Column field='dataContent.2' style={{display: 'none'}}/>
      <Column field='dataContent.3' style={{display: 'none'}}/>
    </DataTable>
  )
}

const Notification = () => {
  const {assetId} = useParams()
  const pfContext = useContext(PortfolioContext)
  const intl = useIntl()
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const allTranslated = intl.formatMessage({id: 't_all'})
  const DEFAULT_NOTIFICATION_STATE = {
    filteredInProgressTasks: [],
    filteredCompletedTasks: [],
    globalFilter: '',
    fund: allTranslated,
    asset: allTranslated,
    shownNumber: 10,
    checkAll: false,
    selectedInProgress: [],
    updateSelectedTasks: [],
    showRequestDataDlg: false,
    showAddNoteDlg: false,
    notificationAssetId: null,
    showActionDlg: false,
    reFetchData: false,
    selectedAction: null,
    gapData: {},
    actions: [],
    events: [],
    modalHeader: null,
    meterSummary: null,
    yearMonth: null,
    firstC: 0,
    rowsC: 20,
    firstP: 0,
    rowsP: 20,
    selectedUtilityLabel: null,
    selectedEvent: null,
    errorDialogVisible: false,
    errorDialogData: null,
    assetAddress: null,
  }
  let fundLists = [allTranslated]
  let locationList= [allTranslated]
  let faPlaceholder = getLocalizedValue(selectedLanguage, 't_find_asset')
  let notifications= []
  let totalNotifications= 0
  let completedTasks = []
  let inProgressTasks = []
  let noData= false
  let noDataCA= false
  let noDataIP= false
  const [nState, setNState] = useState(DEFAULT_NOTIFICATION_STATE)
  const ntdt = useRef(null)
  const ncdt = useRef(null)
  const recordDisplayed = useRef(null) // To track filtered / searched recored displayed for InProgress table
  const {isLoading, data, refetch} = useQuery('notifications', GetAllNotifications, fetchAfterLongTime)
  const {data: dataEvents, refetch: refecthEvents} = useQuery(['eventsData', nState.notificationAssetId], GetAssetEventsData, noRefetchOnWindowFocus)
  const {data: dataActions, refetch: refetchActions} = useQuery(['actionPlans', nState.notificationAssetId], actionData, noRefetchOnWindowFocus)
  const [perfVariables, setPerfVariables] = useState([])
  const perfModalData = useQuery(perfVariables, GetPerformanceDataByMonth, noRefetchOnWindowFocus)
  const isTargetAvailable = pfContext.pfState.showTarget
  const MSG_KEY_TITLE_CANNOT_REQUEST_DATA = 't_title_cannot_request_data'
  const fetchCurrentIndex = useUnMountComponent()
  const queryClient=useQueryClient()


  const updatedTasks = useMutation(UpdateNotifications, {// Called onSubmit
    onSuccess: () => {
      refetch()
    }
  })

  const noFiltersApplied = nState.globalFilter === '' && nState.fund === allTranslated && nState.asset === allTranslated

  const updatedTask = useMutation(UpdateNotification, {// Called on completed row click
    onSuccess: () => {
      refetch()
    }
  })

  const assetData = useMutation(GetAssetHeader)

  const unitSystem = userInfo.unitSystem
  const isNavFromDashboard = () => typeof assetId === 'string'

  //region useEffect Implementation
  useEffect(() => {
    return fetchCurrentIndex
  }, [])

  useEffect(() => {
    const currentNotification = notifications
    const currentProgress = inProgressTasks
    const currentCompleted = completedTasks

    if (inProgressTasks.length > 0 && completedTasks.length > 0) {
      notifications = currentNotification
      inProgressTasks= currentProgress
      completedTasks= currentCompleted
      noDataCA = currentCompleted.length < 1
      noDataIP= currentProgress.length < 1
    }
  }, [nState.globalFilter])

  function setNotificationState(notificationState){
    setNState(prevState => {
      return {
        ...prevState, ...notificationState
      }
    })
  }

  useEffect(() => {
    let isRefetchData = nState.reFetchData
    let events = nState.events
    if (nState.reFetchData) {
      isRefetchData = false
    }
    if (nState.notificationAssetId !== undefined) {
      events = dataEvents
    }
    if (nState.selectedEvent && dataEvents) {
      const currentListOfNotes = dataEvents.filter(x => x.eventID === nState.selectedEvent.eventID)
      events = currentListOfNotes[0]
    }
    setNotificationState({reFetchData: isRefetchData, events: events})
  }, [dataEvents, nState.reFetchData, nState.showAddNoteDlg, nState.notificationAssetId, nState.selectedEvent, nState.events])

  useEffect(() => {
    let isRefetchData = nState.reFetchData
    let actions = nState.actions
    if (nState.reFetchData === true) {
      refetchActions()
      isRefetchData = false
    }
    if (nState.notificationAssetId !== undefined) {
      actions = dataActions
    }
    setNotificationState({reFetchData: isRefetchData, actions: actions})
  }, [nState.reFetchData, dataActions, nState.actions, nState.notificationAssetId])

  useEffect(() => {
    if (nState.notificationAssetId !== undefined) {
      if (nState.yearMonth !== null && nState.selectedUtilityLabel !== null) {
        setPerfVariables(['performanceData', nState.notificationAssetId, nState.yearMonth, nState.selectedUtilityLabel])
      }
    }
    if (perfModalData.data) {
      let headerData = getPerformanceModalHeaderData(perfModalData, isTargetAvailable)
      setNotificationState({modalHeader: headerData, meterSummary: perfModalData.data.assetMeterDataQualitySummaries})
    }
  }, [perfModalData.data, nState.showAddNoteDlg, nState.yearMonth, nState.selectedUtilityLabel, nState.notificationAssetId])

  useEffect(()=>{
    if (isNavFromDashboard() && data?.assetNotifications) {
      const rowData = pfContext.pfState.data
      const assetAddress = data.assetNotifications.filter(asset=>asset.assetId===rowData.assetId)[0]?.assetAddress
      ntdt.current?.filter(rowData.fundName, 'fundName', 'equals')
      ncdt.current?.filter(rowData.fundName, 'fundName', 'equals')
      ntdt.current?.filter(assetAddress, 'assetAddress', 'equals')
      ncdt.current?.filter(assetAddress, 'assetAddress', 'equals')
      setNotificationState({fund: rowData.fundName, asset: assetAddress})
    }
  },[data,data?.assetNotifications])

  //endregion

  //region Helper methods
  function getPFStateRowData() {
    let pfRowData = null
    if (pfContext && pfContext.pfState && pfContext.pfState.data) {
      pfRowData = pfContext.pfState.data
    }
    return pfRowData
  }

  const getFilteredDropdownItemsList = (data, type) => {
    return data.map(record => {
      if (record[type] !== '' && record[type] !== null) {
        return record[type]
      }
    }).reduce((unique, item) => {
      return unique.includes(item) ? unique : [...unique, item]
    }, [])
  }

  if(data && data.assetNotifications) {
    let explodedNotifications = []
    let completedNotifications = []
    let inProgressNotifications = []
    let length = 0
    let newFundList = getFilteredDropdownItemsList(data.assetNotifications, 'fundName')
    fundLists = [allTranslated, ...newFundList]
    let newLocationList = getFilteredDropdownItemsList(data.assetNotifications, 'assetAddress')
    locationList= [allTranslated, ...newLocationList]

    data.assetNotifications.forEach(an => {
      an.tasks.forEach(task => {
        length++
        const notificationItemData = {
          'assetId': an.assetId,
          'assetAddress': an.assetAddress,
          'fundName': an.fundName,
          'assetImageUri': an.assetImageUri,
          'assetImageThumbnailUri': an.assetImageThumbnailUri,
          'sector': an.sector,
          'notificationId': task.notificationId,
          'actionId': task.actionId,
          'notificationType': task.notificationType,
          'impactDate': task.impactDate,
          'dateClosed': task.dateClosed,
          'content': task.content,
          'isCompleted': task.isCompleted
        }
        explodedNotifications.push(notificationItemData)

        if (task.isCompleted) {
          completedNotifications.push(notificationItemData)
        } else {
          inProgressNotifications.push(notificationItemData)
        }
      })
    })

    if (isNavFromDashboard()) {
      const rowData = pfContext.pfState.data
      //Shifting the matching rows to top of list
      completedNotifications.sort((a, b) => (a.fundName === rowData.fundName && a.assetAddress === rowData.assetAddress) ? -1 : (b.fundName === rowData.fundName && b.assetAddress === rowData.assetAddress) ? 1 : 0)
    }

    //Set for checkall
    recordDisplayed.current = inProgressNotifications

    //Show no data found msg if empty list from server
    let isNoData
    isNoData = data.assetNotifications.length <= 0

    notifications = explodedNotifications
    totalNotifications= length
    inProgressTasks = inProgressNotifications
    completedTasks = completedNotifications
    noDataCA = completedNotifications.length < 1
    noDataIP= inProgressNotifications.length < 1
    noData= isNoData
  }

  const onFundFilterChange = (event) => {
    if (event.value === allTranslated) {
      ntdt.current.filter('', 'fundName', 'equals')
      ncdt.current.filter('', 'fundName', 'equals')
    } else {
      ntdt.current.filter(event.value, 'fundName', 'equals')
      ncdt.current.filter(event.value, 'fundName', 'equals')
    }
    setNotificationState({fund: event.value})
  }

  const onAssetFilterChange = (event) => {
    if (event.value === allTranslated) {
      ntdt.current.filter('', 'assetAddress', 'equals')
      ncdt.current.filter(null, 'assetAddress', 'equals')
    } else {
      ntdt.current.filter(event.value, 'assetAddress', 'equals')
      ncdt.current.filter(event.value, 'assetAddress', 'equals')
    }
    setNotificationState({asset: event.value})
  }

  function clearFilter() {
    recordDisplayed.current = inProgressTasks
    ntdt.current.reset()
    ncdt.current.reset()

    setNState((prevState => {
      return {
        ...prevState,
        fund: allTranslated,
        asset: allTranslated,
        checkAll: prevState.selectedInProgress.length === inProgressTasks.length
      }
    }))

    noDataCA = completedTasks.length < 1 // apply styles in completed table
    noDataIP = inProgressTasks.length < 1   // show check all box & apply styles in Inprogress
  }

  const onNotificationFilterKeyDown = (event) => {
    if(event.code === 'Enter') {
      event.preventDefault()
    }
  }

  function handleTaskColumn(rowData) {
    setNotificationState({ notificationAssetId: rowData.assetId})
  }

  const reLoad = () => {
    setNotificationState({reFetchData: true})
  }

  const navigateToPage = (notificationType, rowData, dialogueType) => async () => {
    try {
      await assetData.mutate(rowData.assetId, {
        onSuccess: (data) => {
          if (notificationType === notiType.PerfVar) {
            const utilityField = rowData.content.find(x => x.key === 'UtilityType').value.toLowerCase() === 'gasfuelsandthermals' ? 'GasFuelsAndThermals' : rowData.content.find(x => x.key === 'UtilityType').value.toLowerCase()
            handleNotificationDialogues(data, utilityField, dialogueType, rowData)
          } else if (notificationType === notiType.DataQua) {
            const content = rowData.content ? rowData.content : null
            const missingDataReq = [{
              dataGapDate: monthNamesArray[content ? (rowData.content.find(x => x.key === 'Month').value - 1) : 0],
              responsibility: rowData.content.find(x => x.key === 'ElectricityMPAN').value ? rowData.content.find(x => x.key === 'ElectricityMPAN').value : null,
              meterId: rowData.content.find(x => x.key === 'MeterId').value ? rowData.content.find(x => x.key === 'MeterId').value : null,
              utility: rowData.content.find(x => x.key === 'MeterType').value ? rowData.content.find(x => x.key === 'MeterType').value : null,
              year: rowData.content.find(x => x.key === 'Year').value ? rowData.content.find(x => x.key === 'Year').value : '',
              meterComment: ''
            }]
            const colField = selectedUtilityKeyNames(rowData.content[0].value)
            data.gapData = missingDataReq
            handleNotificationDialogues(data, colField, dialogueType, rowData)
          } else if (notificationType === notiType.Action) {
            handleNotificationDialogues(data, null, dialogueType, rowData)
          }
        }
      })
    } catch (e) {
      console.log(e)
    }
  }

  function getPerformanceContent(content, rowData, isCompleted = false) {
    const utilityType = content.find(x => x.key === 'UtilityType').value
    const utilityTypeMsgKey = utilityType.toLowerCase() === 'gasfuelsandthermals' ? 'gas_fuel_thermal' : utilityType.toLowerCase()
    const util = I18n(`t_${utilityTypeMsgKey}`)
    return I18n('t_notif_template_performance', {
      0: util,
      1: I18n('t_' + monthList[content.find(x => x.key === 'Month').value].toLowerCase()),
      2: formatNumWODecimal(selectedLanguage, trucateNumWODecimal(content.find(x => x.key === 'DifferencePercentage').value)),
      3: I18n('t_' + content.find(x => x.key === 'UnderOrOver').value),
      4: <span className={`task-link cursor ${isCompleted ? 'disabled-link' : ''}`}
        onClick={navigateToPage(notiType.PerfVar, rowData, 'add-note')}>{I18n('t_notif_template_performance_link_1')}</span>
    })
  }

  // DataQualityContent
  function dlgCloseHandler() {
    setNotificationState({errorDialogVisible: false})
  }

  function getEnterDataReqObject(meterDataGap) {
    const firstLastDate = getFirstLastDateISO(meterDataGap.year, (meterDataGap.month - 1))
    return [{
      'meterID': meterDataGap.meterId,
      'dateStart': firstLastDate.firstDay,
      'dateEnd': firstLastDate.lastDay
    }]
  }

  const missingDataMutation = useMutation(addNewMissingDataRequestInternal, {
    onSuccess: (data) => {
      if (data) {
        window.open(data)
      } else {
        setNotificationState({errorDialogVisible: true, errorDialogData: { title: I18n(MSG_KEY_TITLE_CANNOT_REQUEST_DATA),  message: getLocalizedValue(selectedLanguage, 't_no_redirect_data')}})
      }
      refetch()
    }
  })

  const onEnterDataHandler = async (meterDataGap) => {
    try {
      await missingDataMutation.mutateAsync({
        assetId: meterDataGap.assetId,
        dataGap: getEnterDataReqObject(meterDataGap)
      })
    } catch (error) {
      setNotificationState({errorDialogVisible: true, errorDialogData: {title: I18n(MSG_KEY_TITLE_CANNOT_REQUEST_DATA), message: error.toString()}})
    }
  }

  function getDataQualityContent(content, rowData, isCompleted = false) {
    const meterDataGap = {
      assetId: rowData.assetId,
      meterId: content.find(x => x.key === 'MeterId').value,
      month: content.find(x => x.key === 'Month').value,
      year: content.find(x => x.key === 'Year').value
    }
    return I18n('t_notif_template_data_quality', {
      0: I18n('t_' + monthList[content.find(x => x.key === 'Month').value].toLowerCase()),
      1: content.find(x => x.key === 'ElectricityMPAN').value,
      2: <span
        className={`task-link cursor ${isCompleted ? 'disabled-link' : ''}`}
        onClick={() => onEnterDataHandler(meterDataGap)}>
        {I18n('t_btn_enter')}
      </span>,
      3: <span
        className={`task-link cursor ${isCompleted ? 'disabled-link' : ''}`}
        onClick={navigateToPage(notiType.DataQua, rowData, 'request-data')}>
        {I18n('t_notif_template_data_quality_link_1')}
      </span>
    })
  }

  function getActionContent(content, rowData, isCompleted = false) {
    return I18n('t_notif_template_action', {
      0: content.find(x => x.key === 'Description').value,
      1: <span className={`task-link cursor ${isCompleted ? 'disabled-link' : ''}`}
        onClick={navigateToPage(notiType.Action, rowData, 'add-action')}>{I18n('t_notif_template_action_link_1')}</span>
    })
  }

  const handleCheckAll = (e) => {
    let selectedInProgress
    if (e.checked) {
      if (noFiltersApplied) {
        selectedInProgress = inProgressTasks
      } else {
        let filteredTasks = nState.filteredInProgressTasks.filter((filteredTask) => !nState.selectedInProgress.find((selectedTask) => (selectedTask.notificationId === filteredTask.notificationId)))
        selectedInProgress = [...nState.selectedInProgress, ...filteredTasks]
      }
    } else {
      if (noFiltersApplied) {
        selectedInProgress = []
      } else {
        selectedInProgress = nState.selectedInProgress.filter((selectedItem) => !nState.filteredInProgressTasks.find(filteredItem => (selectedItem.notificationId === filteredItem.notificationId)))
      }
    }
    setNotificationState({checkAll: e.checked, selectedInProgress: selectedInProgress})
  }

  function handleCompletedTableDisplay(filterData) {
    let isNoDataCA = filterData.length <=0

    setNotificationState({filteredCompletedTasks: filterData})
    noDataCA = isNoDataCA
  }

  function handleInProgressTableDisplay(filterData) {
    let isNoDataIP = filterData.length <=0

    setNotificationState({filteredInProgressTasks: filterData})
    noDataIP= isNoDataIP
  }

  const handleOnSubmit = async () => {
    let tasks = []
    queryClient.removeQueries('adbNotifications')
    nState.selectedInProgress.forEach(sip => {
      tasks.push({
        'notificationId': sip.notificationId,
        'actionId': sip.actionId,
        'notificationType': sip.notificationType,
        'impactDate': sip.impactDate,
        'dateClosed': sip.dateClosed,
        'content': sip.content,
        'isCompleted': true
      })
    })
    try {
      await updatedTasks.mutate([...tasks])

      if (noFiltersApplied) {
        setNState((prevState => {
          return {
            ...prevState,
            checkAll: false,
            selectedInProgress: [],
            updateSelectedTasks: tasks
          }
        }))
        inProgressTasks = inProgressTasks.filter((inProgressTask) => !nState.selectedInProgress.find(selectedTask => (selectedTask.notificationId === inProgressTask.notificationId)))
        completedTasks = [...completedTasks, ...nState.selectedInProgress]

      }
      if (!noFiltersApplied) {
        setNState((prevState => {
          return {
            ...prevState,
            filteredInProgressTasks: prevState.filteredInProgressTasks.filter((fipt) => !prevState.selectedInProgress.find(sip => (sip.notificationId === fipt.notificationId))),
            filteredCompletedTasks: [...prevState.filteredCompletedTasks, ...prevState.selectedInProgress],
            checkAll: false,
            selectedInProgress: [],
            updateSelectedTasks: tasks
          }
        }))
      }
    } catch (e) {
      console.log(e.toString())
    }

  }

  const completedTable = (event) => {
    setNotificationState({firstC: event.first, rowsC: event.rows})
  }

  const progressTable = (event) => {
    setNotificationState({firstP: event.first, rowsP: event.rows})
  }

  const InProgressTableAttributes = () => {
    let attributes = {
      ref: ntdt,
      value: inProgressTasks,
      className: 'p-datatable-striped p-datatable-gridlines',
      header: header,
      selection: nState.selectedInProgress,
      globalFilter: nState.globalFilter,
      emptyMessage: noDataMessage(),
      onValueChange: handleInProgressTableDisplay,
      totalRecords: totalNotifications,
      onSelectionChange: (e) => {
        setNotificationState({selectedInProgress: e.value, checkAll: false})
      }
    }
    // Add paginator if there's data.
    if (!noDataIP) {
      attributes = {
        ...attributes,
        paginator: noFiltersApplied ? inProgressTasks && inProgressTasks.length > 20 : nState.filteredInProgressTasks && nState.filteredInProgressTasks.length > 20,
        paginatorTemplate: getPaginatorTemplate('items-per-page-options-white'),
        first: nState.firstP,
        rows: nState.rowsP,
        onPage: progressTable,
        paginatorClassName: 'data-table-paginator',
      }
    }

    return attributes
  }

  const CompletedTableAttributes = () => {
    let attributes = {
      ref: ncdt,
      value: completedTasks,
      className: noDataCA ? '' : 'p-datatable-striped completed-table',
      emptyMessage: noDataMessage(),
      globalFilter: nState.globalFilter,
      onValueChange: handleCompletedTableDisplay,
      onRowClick: shiftCompletedTask
    }


    // Add paginator if there's data.
    if (!noDataCA) {
      attributes = {
        ...attributes,
        paginator: noFiltersApplied ? completedTasks && completedTasks.length > 20 : nState.filteredCompletedTasks && nState.filteredCompletedTasks.length > 20,
        paginatorTemplate: getPaginatorTemplate('items-per-page-options-yellow'),
        first: nState.firstC,
        rows: nState.rowsC,
        onPage: completedTable,
        paginatorClassName: 'data-table-paginator',
      }
    }

    return attributes
  }

  function filterRowId(rowData, key){
    return rowData.content.filter(x => x.key === key)
  }

  //endregion

  //region Dialog Handlers
  const handleNotificationDialogues = (data, colField, dialogueType, rowData) => {
    const notificationObject = new Date(rowData.impactDate)
    const month = notificationObject.getMonth() + 1
    const year = notificationObject.getFullYear()
    const pfRowData = getPFStateRowData()
    if (dialogueType === 'request-data') {
      setNotificationState({showRequestDataDlg: rowData.notificationId, gapData: data.gapData, assetAddress: rowData.assetAddress})
    } else if (dialogueType === 'add-note') {
      const rowId = filterRowId(rowData,'EventId')
      const selectedEventRow = nState.events?.filter(x => x.eventID.toString() === rowId[0].value)
      setNotificationState({showAddNoteDlg: rowData.notificationId, yearMonth: year + ('0' + month).slice(-2), selectedUtilityLabel: colField, selectedEvent: {...selectedEventRow[0]}})

    } else if (dialogueType === 'add-action') {
      const rowId = filterRowId(rowData,'RecommendationId')
      const selectedActionRow = nState.actions?.filter(x => x.action.actionId.toString() === rowId[0].value)
      setNState(prevState => {
        return {
          ...prevState,
          showActionDlg: !prevState.showActionDlg,
          selectedAction: {...selectedActionRow[0]}
        }
      })
    }
    pfContext.pfDispatcher({...pfContext.pfState, ...{data: pfRowData, colField: colField}})
  }

  function closeRequestDataDialog() {
    setNotificationState({showRequestDataDlg: false})
  }

  function addNoteDlgHideHandler() {
    setNotificationState({showAddNoteDlg: false})
  }

  const closeDialog = () => {
    setNState(prevState => {
      return {...prevState, showActionDlg: prevState.showActionDlg, selectedAction: ''}
    })
  }

  const closeDialogEv = () => {
    setNState(prevState => {
      return {...prevState, showAddNoteDlg: !prevState.showAddNoteDlg, selectedEvent: ''}
    }
    )
  }

  const closeEventDialog = () => {
    addNoteDlgHideHandler()
    closeDialogEv()
  }

  const closeActionDialog = () => {
    setNotificationState({ showActionDlg: false})
    closeDialog()
  }

  //endregion

  //region Custom templates
  const dropdownItemTemplate = (option) => {
    return (
      <>
        <div className='dropdown-item-1'>
          <div className='dropdown-item-2 dropdown-item-wrap'>{option}</div>
        </div>
        <div className={'dropdown-panel-item-horiz-line'}/>
      </>
    )
  }

  function dropdownItemList(optionList, value, changeHandler){
    return (
      <Dropdown 
        appendTo='self' 
        panelClassName='notification-dropdown-panel'
        options={optionList} 
        value={value} 
        className='sDropdown'
        placeholder={allTranslated}
        onChange={changeHandler} 
        itemTemplate={dropdownItemTemplate}
        scrollHeight='24em'
      />
    )
  }

  const fundFilterElements = dropdownItemList(fundLists, nState.fund, onFundFilterChange)
  const locationFilterElements = dropdownItemList(locationList, nState.asset, onAssetFilterChange)

  const header = <NotificationHeaders 
    selectedLanguage={selectedLanguage} 
    fundFilterElements={fundFilterElements}
    locationFilterElements={locationFilterElements}
    nState={nState}
    clearFilter={clearFilter}
    setNotificationState={setNotificationState}
    onNotificationFilterKeyDown={onNotificationFilterKeyDown}
    faPlaceholder={faPlaceholder}
  />

  const typeTemplate = (rowData,isCompleted) => {
    let notificationType = rowData.notificationType
    let taskType = ''
    if (notificationType === notiType.PerfVar) {
      taskType = I18n('t_performance')
    } else if (notificationType === notiType.DataQua) {
      taskType = I18n('t_data_quality')
    } else if (notificationType === notiType.Action) {
      taskType = I18n('t_actions')
    }
    return (
      <>
        <div className='text-style noti-col-text3' onMouseEnter={()=>changeTheSrc(cross,rowData,isCompleted)}
          onMouseLeave={()=>changeTheSrc(checkMarkGreen,rowData,isCompleted)}>{taskType}
        </div>
      </>
    )
  }

  function contructMessage(taskType, content, rowData, isCompleted) {
    if (taskType === notiType.PerfVar) {
      return getPerformanceContent(content, rowData, isCompleted)
    } else if (taskType === notiType.DataQua) {
      return getDataQualityContent(content, rowData, isCompleted)
    } else if (taskType === notiType.Action) {
      return getActionContent(content, rowData, isCompleted)
    }
  }

  const taskTemplate = (rowData) => {
    let iconData = calendarIcon
    let iconDataAlt = 't_calendar'
    let notificationType = rowData.notificationType
    if (notificationType === notiType.PerfVar) {
      iconData = perfGraphIcon
      iconDataAlt = 't_chart'
    } else if (notificationType === notiType.DataQua) {
      iconData = dataBaseIcon
      iconDataAlt = 't_hand_right'
    } else if (notificationType === notiType.DataCov) {
      iconData = exclamationMarkIcon
      iconDataAlt = 't_exclamation'
    }
    let content = rowData.content
    let taskType = rowData.notificationType
    const firstMsgs = contructMessage(taskType, content, rowData)

    return (
      <div onMouseOver={() => handleTaskColumn(rowData)} className='noti-col-text3 text-container'>
        <img src={iconData} alt={getLocalizedValue(selectedLanguage, iconDataAlt)} className='info-icon'/>
        <span>{firstMsgs}</span>
      </div>
    )
  }

  const dateTemplate = (rowData,isCompleted) => {
    const date = getLocalizedDateMessage(rowData.impactDate, unitSystem, selectedLanguage)

    return (
      <div onMouseEnter={()=>changeTheSrc(cross,rowData,isCompleted)}
        onMouseLeave={()=>changeTheSrc(checkMarkGreen,rowData,isCompleted)}>
        <span className='text-style noti-col-text3'>{date}</span>
      </div>
    )
  }

  async function shiftCompletedTask(e) {
    let shiftedTaskArray = [e.data]
    const shiftData = {
      'notificationId': e.data.notificationId,
      'actionId': e.data.actionId,
      'notificationType': e.data.notificationType,
      'impactDate': e.data.impactDate,
      'dateClosed': e.data.dateClosed,
      'content': e.data.content,
      'isCompleted': false
    }
    queryClient.removeQueries('adbNotifications')
    try {
      await updatedTask.mutate({
        notificationId: shiftData.notificationId,
        updatedData: shiftData
      })

      let taskShifted = []
      if (noFiltersApplied) {
        taskShifted = nState.completedTasks.filter((completedTask) => shiftedTaskArray.find(shiftTask => (completedTask.notificationId === shiftTask.notificationId)))
        setNState(prevState => {
          return {
            ...prevState,
            inProgressTasks: [...prevState.inProgressTasks, ...taskShifted],
            completedTasks: prevState.completedTasks.filter((completedTask) => shiftedTaskArray.find(shiftTask => (completedTask.notificationId !== shiftTask.notificationId))),
          }
        }
        )
      }
      if (!noFiltersApplied) {
        taskShifted = nState.filteredCompletedTasks.filter((completedTask) => shiftedTaskArray.find(shiftTask => (completedTask.notificationId === shiftTask.notificationId)))
        setNState(prevState => {
          return {
            ...prevState,
            filteredInProgressTasks: [...prevState.filteredInProgressTasks, ...taskShifted],
            filteredCompletedTasks: prevState.filteredCompletedTasks.filter((completedTask) => shiftedTaskArray.find(shiftTask => (completedTask.notificationId !== shiftTask.notificationId))),
          }
        })
      }
    } catch (e) {
      console.log(e.toString())
    }
    shiftedTaskArray = []
  }

  const tickTemplate = (rowData,isCompleted) => {
    return (
      <div className='cursor' onMouseEnter={()=>changeTheSrc(cross,rowData,isCompleted)}
        onMouseLeave={()=>changeTheSrc(checkMarkGreen,rowData,isCompleted)}>
        <img id={'tick-complete-'+rowData.notificationId} src={checkMarkGreen} alt={getLocalizedValue(selectedLanguage, 't_tick')}/>
      </div>
    )
  }

  function changeTheSrc(src,rowData,isCompleted) {
    if(isCompleted === true){
      const getRowIndex= `tick-complete-${rowData.notificationId}`
      document.getElementById(getRowIndex).src=src
    }
  }

  const propertyDetailsTemplate = (rowData,isCompleted) => {
    const propertyImage = getFillerImage(rowData, true)
    return (
      <div onMouseEnter={()=>changeTheSrc(cross,rowData,isCompleted)} onMouseLeave={()=>changeTheSrc(checkMarkGreen,rowData,isCompleted)}>
        <Tooltip className={'notification-comm-tooltip long-text-tooltip'}
          target='.notification-widget-p-tooltip'/>
        <div className='notif-asset-name-image'>
          <img src={propertyImage} alt={rowData.sector}
            className={rowData.assetImageThumbnailUri ? 'n-image-actual' : 'n-image-filler'}/>

          <div className='asset-address-fund-name text-wrapper'>
            <div className={'asset-address-txt-wrapper text-wrapper'}>
              <div className='noti-col-text1 notification-widget-p-tooltip text-wrapper' data-pr-tooltip=''
                data-ptf-tooltip={rowData.assetAddress} data-pr-position='right' onMouseEnter={(e)=>isTextOverflown(e)}>
                {Array.from(rowData.assetAddress)}</div>
            </div>
            <div className={'fund-name-txt-wrapper text-wrapper'}>
              <div className='noti-col-text2 notification-widget-p-tooltip text-wrapper' data-pr-tooltip=''
                data-ptf-tooltip={rowData.fundName} data-pr-position='right' onMouseEnter={(e)=>isTextOverflown(e)}>
                {Array.from(rowData.fundName)}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const taskCompletedTemplate = (rowData,isCompleted) => {
    let content = rowData.content
    let taskType = rowData.notificationType
    let firstMsgs = contructMessage(taskType, content, rowData, true)

    return (
      <div className='noti-col-text3' onMouseEnter={()=>changeTheSrc(cross,rowData,isCompleted)}
        onMouseLeave={()=>changeTheSrc(checkMarkGreen,rowData,isCompleted)}>
        <span>{firstMsgs}</span>
      </div>
    )
  }

  //endregion

  function constructTable() {
    return noData ?
      <div className={'no-data-msg no-data'}>
        {header}
        {noDataMessage()}
      </div> :
      <>
        <div className={`in-progress-table ${noDataIP ? 'no-data-msg' : ''}`}>
          <NotificationDataTable
            tableData={InProgressTableAttributes()}
            headerNoData={noDataIP}
            propertyDetailsTemplate={propertyDetailsTemplate}
            cellColor={CSS_VARIABLES.forest}
            taskBody={taskTemplate}
            typeBody={typeTemplate}
            dateBody={dateTemplate}
            fundFilterElements={fundFilterElements}
            locationFilterElements={locationFilterElements}
          />
        </div>

        {noDataIP ? '' :
          <div
            className={`check-all ${noFiltersApplied ? inProgressTasks.length > 20 : nState.filteredInProgressTasks.length > 20}`}>
            <Checkbox className='check-box' inputId='binary' checked={nState.checkAll}
              onChange={e => handleCheckAll(e)}/>
            <label className='check-label'
              htmlFor='binary'>{I18n('t_check_all_completed')}</label>
            <Button label={I18n('t_submit')} className='submit' onClick={handleOnSubmit}
              disabled={!(nState.selectedInProgress && nState.selectedInProgress.length > 0)}/>
          </div>
        }
        <div className='line'>
          <hr className='straight-line'/>
        </div>
        <div className={`${noDataCA ? 'no-data-msg' : 'completed-label'}`}>
          <div className='complete-text'>{I18n('t_completed')}</div>
          <NotificationDataTable
            tableData={CompletedTableAttributes()}
            headerNoData={noDataCA}
            propertyDetailsTemplate={(rowData)=>propertyDetailsTemplate(rowData,true)}
            cellColor={CSS_VARIABLES.forest}
            taskBody={(rowData)=>taskCompletedTemplate(rowData,true)}
            typeBody={(rowData)=>typeTemplate(rowData,true)}
            dateBody={(rowData)=>dateTemplate(rowData,true)}
            tickBody={(rowData)=>tickTemplate(rowData,true)}
            fundFilterElements={fundFilterElements}
            locationFilterElements={locationFilterElements}
          />
        </div>
      </>
  }

  return (
    <div className='notification-page min-width-1280'>
      <div className='notification-home'>
        <BreadCrumbNavigation/>
        <div className='container-layout notification-content gutter'>
          <div className='notification-table col-12'>
            {isLoading ?
              <>
                {header}
                <div className='notifications-grid-before-load'>
                  <NotificationsGridSkeleton/>
                  <NotificationsGridSkeleton/>
                  <NotificationsGridSkeleton/>
                </div>
              </>:
              constructTable()
            }
          </div>
          <RequestDataDialog
            showDialog={nState.showRequestDataDlg}
            onEventActionHideHandler={closeRequestDataDialog}
            data={nState.gapData}
            requestCleanupHandler={() => console.log()}
            assetAddress={nState.assetAddress}
            assetId = {nState.notificationAssetId}
          />
          <ModalDialogHome
            assetId={nState.notificationAssetId}
            showDialog={nState.showAddNoteDlg}
            onModalDialogCloseHandler={addNoteDlgHideHandler}
            header={<PerformanceModalDialogHeader header={nState.modalHeader}/>}
            meterSummaryData={nState.meterSummary}
            activeTabIndex={1}
            reLoad={reLoad}
            dlgActionCleanupHandler={null}
            edit={true}
            eventsList={nState.events}
            selection={nState.selectedEvent}
            refetchData={refecthEvents}
            utility={nState.selectedUtilityLabel}
            yearMonth={nState.yearMonth}
            closeEventDialog={closeEventDialog}
            onEventActionHideHandler={() => {
              closeDialogEv()
            }}
          />
          <ActionEdit
            assetId={nState.notificationAssetId}
            closeActionDialog={closeActionDialog}
            reLoad={reLoad}
            dlgActionCleanupHandler={null}
            edit={true}
            activeIndex={0}
            showDialog={nState.showActionDlg}
            selection={nState.selectedAction}
            refetchData={refetchActions}
            actionsList={nState.actions}
            onEventActionHideHandler={() => {
              closeDialog()
            }}
          />
          {nState.errorDialogVisible && <ErrorDialog
            data={nState.errorDialogData}
            dialogVisible={nState.errorDialogVisible}
            onHideHandler={dlgCloseHandler}
            closeHandler={dlgCloseHandler}/>}
        </div>
      </div>
    </div>
  )
}

export default Notification
