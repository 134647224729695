import {useQueries} from 'react-query'
import {getPortfolioFilterOptionsV3, getPortfolioViewDataV3, GetPortfolioViewExportData} from '../../../../../services/assets/portfolio-service'
import {noRefetchOnWindowFocus} from '../../../../../services/common/useQuery-config'
import {QueryKeyPrefix} from '../../../../../utils/helpers/Constants'

const usePortfolioData = (timePeriod, pickerValue, paginationParams, filterOptionsParams, unitSystem) => {

  const [
    {isLoading: userDropdownFiltersLoading, data: userDropdownFilters},
    {isLoading: portfolioDataPaginatedIsLoading, data: portfolioDataPaginated},
    {data: portfolioExportData},
  ] = useQueries([
    {
      queryKey: [QueryKeyPrefix.PORTFOLIO_FILTER_OPTIONS, filterOptionsParams],
      queryFn: getPortfolioFilterOptionsV3,
      ...noRefetchOnWindowFocus
    },
    {
      queryKey: [QueryKeyPrefix.PORTFOLIO_VIEW_DATA, timePeriod, pickerValue, paginationParams, unitSystem],
      queryFn: getPortfolioViewDataV3,
      ...noRefetchOnWindowFocus
    },
    {
      queryKey: [QueryKeyPrefix.PORTFOLIO_EXPORT_DATA, {paginationParameters: paginationParams}],
      queryFn: GetPortfolioViewExportData,
      ...noRefetchOnWindowFocus
    }
  ])

  return {
    userDropdownFiltersLoading,
    userDropdownFilters,
    portfolioDataPaginatedIsLoading,
    portfolioDataPaginated,
    portfolioExportData
  }
}

export default usePortfolioData