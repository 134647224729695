import React from 'react'
import '../ESConnectionStep.scss'
import I18n from '../../../../../../utils/i18n/I18n'
import {ENERGY_STAR_STEPS, esPortfolioManager} from '../../../../../../utils/helpers/Constants'
import {Button} from 'primereact/button'

const ESConnectionStepOne = (props) => {

  function addedContact() {
    props.onStepChange(ENERGY_STAR_STEPS.INIT_ESPM_STEP)
  }

  return (
    <div className={'es-connection-step'} role={'esc-one-info'}>
      <span className={'step-sub-text'}>
        {I18n('t_es_step_one_sub_text', {
          0: <a role={'step-link'} className={'step-link'} href={esPortfolioManager} target="_blank" rel="noreferrer noopener">{I18n('t_es_login_pf_manager')}</a>
        })}
      </span>
      <span className={'step-sub-text step-sub-info'} >{I18n('t_es_prompted_username')}</span>
      <Button className={'common-blue-bg-btn step-button'} onClick={addedContact} label={I18n('t_es_add_contact_button')} />
    </div>
  )
}

export default ESConnectionStepOne