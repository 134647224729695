import React, {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import './NzcModel.scss'
import {InputText} from 'primereact/inputtext'
import ClearIcon from '../../../../../resources/images/icon/close-gray.svg'
import MenuIcon from '../../../../../resources/images/icon/three-dots-vertical-custom.svg'
import {
  getLocalizedValue,
  removeRequiredSessionStorageCache,
  SessionUserState
} from '../../../../../utils/helpers/Helper'
import { Accordion, AccordionTab } from 'primereact/accordion'
import {Button} from 'primereact/button'
import I18n from '../../../../../utils/i18n/I18n'
import {OverlayPanel} from 'primereact/overlaypanel'
import NZCModelSkeleton from '../../../../common/skeletons/nzc-fund-skeleton/NzcModelSkeleton'
import {useMutation} from 'react-query'
import {deleteAssetNZCModel, DeleteNZCModel, SetAsDefaultModel, setAssetDefaultModel} from '../../../../../services/funds/nzc-service'
import RoundedLabel from '../../../../common/rounded-label/RoundedLabel'
import csvImage from '../../../../../resources/images/icon/csv/csv.svg'
import CommonDialog from '../../../../common/modal-dialogs/ModalDialogCommon'
import ShareAModel from './ShareAModel'
import CSS_VARIABLES from '../../../../../resources/css/_variables.scss'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const NzcOpenAModel = (props) => {
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const [filterModel, setFilterModel] = useState('')
  const [filteredScope, setFilteredScope] = useState([])
  const [dataSize, setDataSize] = useState(0)
  const modelMenu = useRef(null)
  const defaultModelRef = useRef(null)
  const [deleteModel, setDeleteModel] = useState(false)
  const [scopeId, setScopeId] = useState(null)
  const [toggleNotes, setToggleNotes] = useState(false)
  const[openModelState, setOpenModelState]=useState({
    modelErrorMessage: null
  })
  const selectedScopeName = useRef(null)
  const [shareModelOptionClicked,setShareModelOptionClicked] = useState(false)
  // Queries and Mutations
  const setDefaultMutation = useMutation(props.assetId ? setAssetDefaultModel : SetAsDefaultModel)
  const deleteNzcModel = useMutation(props.assetId ? deleteAssetNZCModel : DeleteNZCModel, {
    onError: (error) => {
      //TODO
    },
    onSuccess:()=>{
      //TODO
      props.refetchGetNZCScopes()
    }
  })
  const shareLink = useRef(null)

  useEffect(() => {
    if(props && props.getNZCScopes){
      const nzcScopes = props.getNZCScopes?.filter(scope =>
        scope.scopeName.toLowerCase().includes(filterModel.toLowerCase())
      )
      setFilteredScope(nzcScopes?.reverse())
      setDataSize(nzcScopes?.length)
    } else {
      setDataSize(0)
    }
  }, [filterModel, props.getNZCScopes])

  function updateOpenModelState(newModelState) {
    setOpenModelState({...openModelState, ...newModelState})
  }

  function onFindModelFilterChange(event){
    setFilterModel(event.target.value)
  }

  function clearSearch(){
    setFilterModel('')
  }


  function handleModelOptionClick (event,scope){
    function handleNonDefaultModelOptions(event, modelScopeId){
      event.preventDefault()
      event.stopPropagation()
      modelMenu.current.toggle(event)
      if (defaultModelRef.current) {
        defaultModelRef.current.hide()
      }
      setDeleteModel(false)
      setScopeId(modelScopeId)
    }
    function handleDefaultModelOptions(event, modelScopeId) {
      defaultModelRef.current.toggle(event)
      setScopeId(modelScopeId)
    }
    selectedScopeName.current = scope.scopeName
    !isDefaultModel(scope.scopeId) ? handleNonDefaultModelOptions(event, scope.scopeId) : handleDefaultModelOptions(event,scope.scopeId)
  }


  function onTabClick (modelScopeId) {
    setScopeId(modelScopeId)
  }

  function isCurrentModel(scope) {
    let scopeIdFromSession
    if (props?.fundId) {
      scopeIdFromSession = SessionUserState.getUserDataFromSession(props.fundId)
      if(scopeIdFromSession){
        scopeIdFromSession=parseInt(scopeIdFromSession)
      }
    }
    return props.assetId ? Number(props.currentModelId) === Number(scope?.scopeId) : (scopeIdFromSession && (scopeIdFromSession === scope?.scopeId))
  }


  const headerTemplate =(scope) =>{
    const isDefaultModel = scope.scopeId === props.defaultModelScopeId
    let options = { day: 'numeric', month: 'short', year: '2-digit' }
    const formatter = new Intl.DateTimeFormat(selectedLanguage, options)
    const scopeCreatedMonth = formatter.format(new Date(scope.dateCreated))
    return(
      <div onClick={() => onTabClick(scope.scopeId)} className={'accor-header-model'}>
        <div className="accor-header-model__model-name-col">
          {scope.isDataOverridden && <img src={csvImage} className="accor-header-model__model-name-col--file-type-icon" />}
          <div className={'accor-header-model__model-name-col--model-name'}>{scope.scopeName}</div>
          {isDefaultModel && <div className={'accor-header-model__model-name-col--p-default-model'} data-testid="default-model-name">
            <RoundedLabel data-testid="default-model-name" name={getLocalizedValue(selectedLanguage, 't_default_model_name')} />
          </div>}
          {isCurrentModel(scope) && <div className={'accor-header-model__model-name-col--model-current'}>
            <RoundedLabel name={getLocalizedValue(selectedLanguage, 't_current_model')} />
          </div>}
        </div>
        <div className="accor-header-model__model-date-created-col created-date">{scopeCreatedMonth}</div>
        <div className="accor-header-model__model-menu-col" data-testid="click-function" onClick={(event) => handleModelOptionClick(event,scope)} aria-controls="popup_menu" aria-haspopup>
          <img src={MenuIcon} alt="menu" className="accor-header-model__model-menu-col--menu-icon cursor"/>
        </div>
      </div>
    )
  }

  const onDelete = () => {
    setDeleteModel(true)
  }

  const onDeleteModel = async () => {
    const newfilteredList = filteredScope.filter(item => item.scopeId !== scopeId)
    setFilteredScope(newfilteredList)
    const modelInfo = {
      fundGroupId: props.assetId ? 0 : props.fundGroupId,
      assetId: props.assetId ? props.assetId : 0,
      scopeId: scopeId
    }
    modelMenu.current.hide()
    deleteNzcModel.mutate(modelInfo)
    setDeleteModel(false)
    props.updateScopeId(props.defaultModelScopeId)
  }

  const onCancel = () => {
    setDeleteModel(false)
  }

  const loadModel = () => {
    return (
      <>
        {dataSize ? (
          <Accordion className={'open-model-data-accordion'} onTabOpen={()=>setToggleNotes(true)} onTabClose={()=>setToggleNotes(false)}>
            {filteredScope.map((scope) => (
              <AccordionTab headerTemplate={headerTemplate(scope)} key={scope.scopeId}>
                {toggleNotes && <p className="model-notes"><span>{I18n('t_notes_lowercase')}:&nbsp;</span>{scope.description}</p>}
              </AccordionTab>
            ))}
          </Accordion>
        ) : (
          <div className={'no-models-text'}>
            {I18n('t_no_results')}
          </div>
        )}
      </>
    )
  }

  function onOpenModel () {
    removeRequiredSessionStorageCache()
    props.updateScopeId(scopeId)
    props.onHideModel()
  }

  const defaultSuccessCallBack = () => {
    if (props.assetId) {
      props.refetchGetNZCScopes()
    } else {
      props?.refetchFundGroupKeyfacts()
    }
  }

  function setDefaultModelAction() {
    setDefaultMutation.mutateAsync(props.assetId ? {assetId: props.assetId, scopeId} : scopeId).then((response)=>{
      if(response){
        defaultSuccessCallBack()
      } else {
        updateOpenModelState({modelErrorMessage: getLocalizedValue(selectedLanguage, 't_setting_default_model_failed')})
      }
    }).catch(error=>{
      updateOpenModelState({modelErrorMessage: getLocalizedValue(selectedLanguage, 't_setting_default_model_failed')})
    }).finally(()=>{
      modelMenu.current.hide()
    })
  }

  function onShowModelTooltip() {
    updateOpenModelState({modelErrorMessage: null})
  }

  function isDefaultModel(modelId) {
    return(modelId === props?.defaultModelScopeId)
  }

  function handleShareModel() {
    setShareModelOptionClicked(true)
    modelMenu.current.hide()
    defaultModelRef.current.hide()
    const queryValues = new URLSearchParams(new URL(window.location).search)
    queryValues.set('model', scopeId)
    shareLink.current = window.location.origin + window.location.pathname + '?' + queryValues.toString()
  }

  function hideShareModelPopup() {
    setShareModelOptionClicked(false)
  }

  function getSharedModelTitle(){
    return <div className={'share-model-name'}>{I18n('t_share')} {selectedScopeName.current}</div>
  }

  return (
    <div className={'nzc-model-content scrollbar-track-hide'}>
      <div className='nzc-model-content__open-model-header'>
        <div className="nzc-model-content__open-model-header--p-search">
          <i className="pi pi-search cursor" style={{fontSize: '17px'}}/>
          <InputText value={filterModel} onChange={onFindModelFilterChange} className="nzc-model-content__open-model-header--p-search--input-text" placeholder={getLocalizedValue(selectedLanguage, 't_search')} data-testid="modal-search-bar"/>
          {filterModel !== '' && (
            <img src={ClearIcon} alt="Clear" className="nzc-model-content__open-model-header--p-search--clear-icon cursor highlight-clickable-opacity"
              onClick={clearSearch}/>
          )}
        </div>
        <div className={'nzc-model-content__open-model-header--error-message-label'}>{openModelState.modelErrorMessage}</div>
      </div>
      <div className={'open-model-data'}>
        {props.getNZCScopesLoading ? (
          <NZCModelSkeleton/>
        ) : (
          <>
            {loadModel()}
          </>
        )}
        <OverlayPanel ref={modelMenu} style={{ width: deleteModel ?  '12.5rem' : '11rem' }} breakpoints={{'960px': '75vw', '640px': '100vw'}} id="popup_menu" className={'model-popup-menu'} onShow={onShowModelTooltip}>
          { !isDefaultModel(scopeId) && <div className={'menu-item cursor'} onClick={setDefaultModelAction}>{I18n('t_default_model')}</div> }
          <span className='menu-item default-model-share' onClick={handleShareModel} role='share-model'>{I18n('t_share_model')}</span>
          <div className={'menu-item cursor'} onClick={onDelete}>{I18n('t_delete_model')}</div>
          {deleteModel && (
            <div className={'menu-item delete-option'}>
              <span onClick={onDeleteModel} className={'cursor'}>{I18n('t_yes')}</span>
                            &nbsp;{I18n('t_delete_yes_cancel')}&nbsp;
              <span onClick={onCancel} className={'cursor'}>{I18n('t_cancel')}</span>
            </div>
          )}
        </OverlayPanel>
        <OverlayPanel ref={defaultModelRef} style={{width: '15rem'}} breakpoints={{'960px': '75vw', '640px': '100vw'}} id="popup_menu"
          className={'model-popup-menu'} onShow={onShowModelTooltip}>
          <span className='menu-item default-model-share' onClick={handleShareModel} role='share-model'>{I18n('t_share_model')}</span>
          <div className='h-line'/>
          <p data-testid="default-model-message" className='default-model-message'>{I18n('t_default_model_delete')}</p>
        </OverlayPanel>
      </div>
      <div className="padding-bottom-3 sticky-button">
        <div className={'border-line'} />
        <Button data-testid="open-button" onClick={onOpenModel} disabled={scopeId === null} label={I18n('t_open')} className="common-blue-bg-btn open-button button-highlight" />
      </div>
      <CommonDialog
        modal={true}
        style={{width: CSS_VARIABLES.width_1080_px, height: CSS_VARIABLES.height_fit_content}}
        visible={shareModelOptionClicked}
        onHide={hideShareModelPopup}
        header={getSharedModelTitle}
        children={<ShareAModel shareLink = {shareLink.current}/>}
        className={'nzc-model scrollbar-track-hide share-model-header'}
      />
    </div>
  )
}

NzcOpenAModel.propTypes = {
  getNZCScopesLoading: PropTypes.bool,
  getNZCScopes: PropTypes.array,
  refetchGetNZCScopes: PropTypes.func,
  onHideModel: PropTypes.func,
  updateScopeId: PropTypes.func,
  assetId: PropTypes.string,
  defaultModelScopeId: PropTypes.number,
  refetchFundGroupKeyfacts: PropTypes.func,
  fundId: PropTypes.number,
  fundGroupId: PropTypes.number,
  currentModelId: PropTypes.number
}

NzcOpenAModel.displayName = 'NzcOpenAModel'

export default NzcOpenAModel