import React, { useContext, useEffect, useRef, useState, useLayoutEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  CachedUserState, createEndMonthParam, createNavURLForFundPages, getFundViewsNavLinkConfigsForFlags, getLegendImage,
  getLocalizedValue, getLocalStorageByKey, getRoundOffValueDivisbleBy5, SessionUserState, setMinToZeroForVariousNzcLines
} from '../../../../utils/helpers/Helper'
import {FeatureFlagContext} from 'Contexts'
import './DefaultFundNetZeroCarbon.scss'
import FundNzcGraph from '../nzc-line-graph/FundNZCGraph'
import {useMutation, useQuery} from 'react-query'
import {CreateNZCScope, GetNZCSettings, getBenchmarkOptions, GetNZCDataQualitySummaryData, getActionBasedTPData,} from '../../../../services/funds/nzc-service'
import NZCAssets from './nzc-assets/NZCAssets'
import {noRefetchOnWindowFocus} from '../../../../services/common/useQuery-config'
import {useLocation, useParams} from 'react-router-dom'
import {
  CacheKeyFundGroups, FUND_DATAQUALITY_SCOPES, FUND_VIEWS_PAGES, modelsOptions,
  modelsOverlayOptions, SCOPE_TARGET_MODE, TARGET_PATHWAY, monthListShortWithLocale,
  USE_QUERY_STATUS, LOCAL_STORAGE_KEYS, FUND_BAU_LINE, externalFundBauTooltipHandlerName,
  fundBAUBubbleName, weightedAverageCarbonIntensity, CARBON, co2Emission,
  weightedAverageEnergyIntensity, weightedAverageCostIntensity, SBT_VERSIONS, topDownPathways
} from '../../../../utils/helpers/Constants'
import I18n from '../../../../utils/i18n/I18n'
import NZCPerformanceSummaryCardSkeleton from '../../../common/skeletons/NZC-summary-card-skeleton/NZCPerformanceSummaryCardSkeleton'
import FundCommonHeader from '../common-header/FundCommonHeader'
import {noDataAvailableMessage} from '../../../../utils/helpers/MessageUtility'
import {ChartSkeleton, NZCAssetTableSkeleton, NZCPageDoubleLineSkeleton, NZCPageSingleLineSkeleton} from '../../../common/skeletons/nzc-fund-skeleton/NZCAssetTableSkeleton'
import {TabPanel, TabView} from 'primereact/tabview'
import {Button} from 'primereact/button'
import NzcTargetPathwayForm from './nzc-target-pathway/NzcTargetPathwayForm'
import NzcSettingsForm from './nzc-settings/NzcSettingsForm'
import {OverlayPanel} from 'primereact/overlaypanel'
import CommonDialog from '../../../common/modal-dialogs/ModalDialogCommon'
import NzcOpenAModel from './nzc-models/OpenAModel'
import NzcCreateAModel from './nzc-models/CreateAModel'
import NzcExportModel from './nzc-models/ExportModel'
import NzcImportModel from './nzc-models/NzcImportModel'
import NzcDuplicateModel from './nzc-models/DuplicateModel'
import {closeAssetPanel, externalTooltipHandlerNzc, FUND_BAU_TOOLTIP_ROOT, getDefaultTooltipConfig, hideAssetTooltip, hideFundBauTooltip, removeAssetTooltipNode, removeFundBauTooltipNode, showFundBauTooltip} from '../../assets/performance/performance-home/ChartHelper'
import RoundedLabel from '../../../common/rounded-label/RoundedLabel'
import NZCModelNotesCard from '../../../common/skeletons/NZCModelNotesCard/NZCModelNotesCard'
import ErrorDialog from '../../assets/data-quality/error-dialog/ErrorDialog'
import PopupDialog from '../../../common/modal-dialogs/PopupDialog'
import {useHistory} from 'react-router-dom/cjs/react-router-dom'
import {GetDefaultDateWithCachedData, updateQueryParamsForFundViews} from '../../../../utils/helpers/DatePickerUtils'
import OverviewChart from '../../../common/charts/overview-chart/OverviewChart'
import {getTimeperiodLabel} from '../../../../services/common/datepicker-service'
import OverviewChartSkeleton from '../../../common/charts/overview-chart/skeleton/OverviewChartSkeleton'
import CSS_VARIABLES from '../../../../resources/css/_variables.scss'
import {
  closeFunction, createNZCEnergyIntensityDatasets, dataPointColors, getBooleanActionBasedTPToPlot,
  getDeletedModelName, getGraphDataset, getScientificGHGIntensity, getShape, returnGraphIntensity,
  saveGraphIntensity, setScopeIdForSession, openPopUponClick, plotBauStranding, getYMAxValue, plotTpTargetYear,
  plotStrandingPoints, plotTopDownPathway, getMaxTopDownPathwayValue} from './nzc-utils/nzc-utils'
import classNames from 'classnames'
import { getEPCRating } from '../../../../services/assets/portfolio-service'
import {createBauDataset, getNZCChartSieraPlusLogo, overlayPanelErrorHandler} from './nzc-chart-helper/nzc-chart-helper'
import NzcExportOverlay from './nzc-popup/NzcExportOverlay'
import { Tooltip } from 'primereact/tooltip'
import {isTextOverflown} from '../../../../utils/UtilsFunctions'
import { AdjustValue } from '../performance/FundPerformanceUtilitySuffixes'
import {GRAPH_Y_SCALE_MAX_PADDING, TPS_NAMES_MAP} from '../../../common/net-zero-carbon/nzc-constants'
import {
  actionsDataNotEmpty,
  getDefaultFontObjectForChart,
  getYearForFinancialDate
} from '../../../common/net-zero-carbon/nzc-common-utils'
import useNZCQueryData from './useNZCQueryData'
import {
  getBAUStrandingYearDetails,
  STRANDING_POINT_IDENTIFIER
} from '../../assets/net-zero-carbon/asset-nzc-utils/asset-nzc-utils'
import {truncateAndFormatNumWOneDecimal} from '../../../../services/common/formatter'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const DefaultFundNetZeroCarbon = (props) => {
  //region Declarations/Initializations
  const { fundId, fundGroupId, fundKeyfactsData, refetchFundGroupKeyfacts } = props
  const location = useLocation()
  const fundGraphRef = useRef(null)
  const queryValues = new URLSearchParams(location.search)
  const greenColor = CSS_VARIABLES.green
  const orangeColor = CSS_VARIABLES.nature
  const {instanceName, orgName, fundName} = useParams()
  const[selectedFund, setSelectedFund]=useState({})
  const history = useHistory()
  const {loginState} = useLoginAuthContext()
  const selectedLanguage = loginState.userInfo.languagePreference
  const [nzcGraphData, setNZCGraphData]=useState()
  const [nzcScopeId, setNZCScopeId] = useState()
  const [openModelScopeId, setOpenModelScopeId] = useState(null)
  const [nzcScopeAssetData, setNzcScopeAssetData] = useState([])
  const [isNZCModelOverridden, setIsNZCModelOverridden] = useState(false)
  const featureContext            = useContext(FeatureFlagContext)
  const featureState              = featureContext.featureState
  const sessionIntensityvalue = SessionUserState.getUserDataFromSession('graphIntensity')
  const sessionModelKey = fundId
  const graphIntensity = returnGraphIntensity(sessionIntensityvalue, sessionModelKey, featureState, queryValues)
  const { nzcScope, scopeDetails, graphNZCData } = useNZCQueryData({
    scopeVariables: ['NZCScopes', fundGroupId],
    scopeDetailsVariables: ['NZCScopeDetails', nzcScopeId, fundGroupId, graphIntensity],
    graphDataVariable: ['NZCTargetPathways', nzcScopeId, graphIntensity]
  })
  const {isLoading: getNZCScopesLoading, data: getNZCScopes, refetch: refetchGetNZCScopes, status: getScopesStatus, isFetching: isFetchingScopes} = nzcScope
  const {data: NzcScopeDetailsData, refetch: refetchNzcScopeDetailsData, status: scopeDetailsStatus, isFetching: isFetchingScopeDetails} = scopeDetails
  const navLinkConfigs = getFundViewsNavLinkConfigsForFlags(selectedFund, selectedLanguage, featureState)
  const getNzcSettingsData = useQuery(['GetNZCSettings', nzcScopeId, graphIntensity], GetNZCSettings, noRefetchOnWindowFocus)
  const {data: actionBasedTPData} = useQuery(['ActionBasedTPData',nzcScopeId],getActionBasedTPData,noRefetchOnWindowFocus)
  const [optionsTabActiveIndex, setOptionsTabActiveIndex]=useState(0)
  const [createMode, setCreateMode] = useState(true)
  const [baselineYear, setBaselineYear] = useState()
  const [openModel, setOpenModel] = useState('')
  const modelsOverlay       = useRef(null)
  const [graphDataLoading, setGraphDataLoading]=useState(false)
  const [assetOrderByValue, setAssetOrderByValue] = useState('')
  const isAdmin = loginState.userInfo.userRole !== 'Admin'
  const [targetPathwayData, setTargetPathwayData]=useState()
  const [targetsAndPathwayDetails,setTargetsAndPathwayDetails] = useState({})
  const ACTION_BASED_CARBON_PATHWAY = getLocalizedValue(selectedLanguage, 't_action_based_carbon_pathway')
  const STRANDING_YEAR_TP = 'Target stranding in year'
  const BAU_STRANDING_YEAR = 'Bau stranding in year'
  const ACTION_PATHWAY_STRANDING_YEAR = 'ACTION_PATHWAY_STRANDING_YEAR'
  const [variables, setVariables] = useState([])
  const [dpCustomTime, setDpCustomTime] = useState({})
  const {isLoading: nzcDQSummaryDataLoading, data: nzcDQSummaryData, refetch: refetchNZCDQMetricData} = useQuery(variables, GetNZCDataQualitySummaryData, noRefetchOnWindowFocus)
  const [scopeTargetState, setScopeTargetState]=useState({
    tpMode: null,
    selectedTPName: null,
    activeIndex: null
  })
  const chartOptionsData = {
    assetsPlotData: false,
    loadChartOptions: false,
    xAxisScaleFrom: 0,
    xAxisScaleTo: 0,
    xAxisAutoScale: true,
    yAxisScaleMin: 0,
    yAxisScaleMax: 0,
    yAxisAutoScale: true,
  }
  const [strandingYearForTP,setStrandingYearForTP] = useState(null)
  const [chartOptions, setChartOptions] = useState(chartOptionsData)
  const [showChartOptions , setShowChartOptions] = useState(true)
  const nzcBaseLineYear = getNzcSettingsData?.data?.baselineYear
  const [yAxisMaxValue, setYAxisMaxValue] = useState(0)
  const [assetRowClick, setAssetRowClick] = useState()
  const previousAssetRowClick = useRef(null)
  const queryOptions={...noRefetchOnWindowFocus, enabled: !!assetRowClick}
  const {data: dataEPCRating } = useQuery(['EPCRating', `${assetRowClick}`.split('_')[0]], getEPCRating, queryOptions)
  let epcRatingLabel='-'
  if(dataEPCRating?.epcRating && dataEPCRating?.epcScheme){
    epcRatingLabel=`${dataEPCRating.epcRating} - ${dataEPCRating.epcScheme}`
  }
  SessionUserState.saveUserDataToSession('epc', epcRatingLabel)
  const nzcChartAssetPanel = useRef()
  const createNZCScopeMutation = useMutation(CreateNZCScope)
  const [nzcDialogsState, setNzcDialogsState] = useState({
    errorDialogVisible: false,
    errorDialogData: null,
    showConfirmModal: false,
    uploadFileName: null,
    assetError: false
  })
  const [sessionModelState, setSessionModelState] = useState({showConfirmModalDeleted: false})

  const [urlQueryParams, setUrlQueryParams]=useState()

  const [ modelNotesCardDetails, setModelNotesCardDetails ] = useState({
    scopeName: '',
    description: '',
  })
  const exportOverlayRef     = useRef(null)
  const nzcGraphRef     = useRef(null)
  const [isAssetRowClicked,setIsAssetRowClicked] = useState(false)
  let reportingYear = featureState.sieraplus_nzc_reportingYear ? getYearForFinancialDate(fundKeyfactsData?.financialYearStart) : new Date().getFullYear() - 1
  const [isNZCExportOverlayPanelOpen, setIsNZCExportOverlayPanelOpen]=useState(false)
  const [isModelsOverlayPanelOpen, setIsModelsOverlayPanelOpen]=useState(false)
  //endregion
  const fundAssetsWithCurrentYear = useMemo(() => {
    return NzcScopeDetailsData?.fundAssets?.map((e) => {
      const asset = graphNZCData?.data?.assetGraphData?.find((f) => f.assetId === e.asset.assetId)
      const reqVal = asset?.graphData?.findIndex((g) => g.year === reportingYear)
      return {
        ...e,
        ...asset?.graphData[reqVal],
        agreedActions: asset?.graphData[reqVal]?.agreedActions ? asset?.graphData[reqVal].agreedActions : 0,
        applicableActions: asset?.graphData[reqVal]?.applicableActions ? asset?.graphData[reqVal].applicableActions : 0,
        carbonIntensity: asset?.graphData[reqVal]?.carbonIntensity ? asset?.graphData[reqVal].carbonIntensity : 0,
        co2Emission: asset?.graphData[reqVal]?.co2Emission ? asset?.graphData[reqVal].co2Emission : 0,
        costIntensity: asset?.graphData[reqVal]?.costIntensity ? asset?.graphData[reqVal].costIntensity : 0,
        dataAvailablePercentage: asset?.graphData[reqVal]?.dataAvailablePercentage ? asset?.graphData[reqVal].dataAvailablePercentage : 0,
        energyIntensity: asset?.graphData[reqVal]?.energyIntensity ? asset?.graphData[reqVal].energyIntensity : 0,
        missingPercentage: asset?.graphData[reqVal]?.missingPercentage ? asset?.graphData[reqVal].missingPercentage : 0,
        year: asset?.graphData[reqVal]?.year ? asset?.graphData[reqVal].year : reportingYear,
        actionsUnderReview: asset?.graphData[reqVal]?.actionsUnderReview ? asset?.graphData[reqVal].actionsUnderReview : 0,
        weightedAverageCarbonIntensity: asset?.graphData[reqVal]?.weightedAverageCarbonIntensity ? asset?.graphData[reqVal].weightedAverageCarbonIntensity : 0,
        weightedAverageCostIntensity: asset?.graphData[reqVal]?.weightedAverageCostIntensity ? asset?.graphData[reqVal].weightedAverageCostIntensity : 0,
        weightedAverageEnergyIntensity: asset?.graphData[reqVal]?.weightedAverageEnergyIntensity ? asset?.graphData[reqVal].weightedAverageEnergyIntensity : 0,
      }
    })
  }, [NzcScopeDetailsData?.fundAssets, graphNZCData?.data?.assetGraphData, reportingYear])

  //region Side effects
  useEffect(()=>{
    const errorEvent='error'
    window.addEventListener(errorEvent, overlayPanelErrorHandler)
    return(()=>window.removeEventListener(errorEvent, overlayPanelErrorHandler))
  }, [])

  useEffect(() => {
    if(props.location && props.location.state){
      setSelectedFund(props.location.state)
    } else {
      setSelectedFund(CachedUserState.getUserDataFromCache(CacheKeyFundGroups))
    }
  }, [])

  useEffect(() => {
    const nzcSettingsData = getNzcSettingsData?.data
    if (nzcSettingsData) {
      updateTargetsAndPathwayDetails({
        showProgressTowardsTargetYear: targetsAndPathwayDetails.showProgressTowardsTargetYear ? targetsAndPathwayDetails.showProgressTowardsTargetYear : nzcSettingsData.showTargetProgress,
        progressTowardsTargetYear: targetsAndPathwayDetails.progressTowardsTargetYear ? targetsAndPathwayDetails.progressTowardsTargetYear : nzcSettingsData.progressTargetYear,
        energySavingActions: targetsAndPathwayDetails.energySavingActions ? targetsAndPathwayDetails.energySavingActions : nzcSettingsData.energySavingActions,
        showTopDownPathways: targetsAndPathwayDetails.showTopDownPathways ? targetsAndPathwayDetails.showTopDownPathways : nzcSettingsData.showTopDownPathways
      })
    }
  }, [getNzcSettingsData?.data?.energySavingActions, getNzcSettingsData?.data?.showTargetProgress, getNzcSettingsData?.data?.progressTargetYear, getNzcSettingsData?.data?.showTopDownPathways, graphIntensity])

  let nzcScopeIdFromSession = SessionUserState.getUserDataFromSession(sessionModelKey)
  let fundDefaultScopeId = fundKeyfactsData?.nzcDefaultModel
  let nzcScopeIdFromUrl = queryValues.get('model')
  const isEnergyIntensity = (fundGraphRef.current?.hideGraph() || graphIntensity) === 'energyIntensity'
  const [fundIntensityValue, setFundIntensityValue] = useState()
  useEffect(() => {
    saveGraphIntensity(graphIntensity, sessionModelKey)
    if (nzcScopeIdFromUrl && nzcScopeIdFromSession &&  (nzcScopeIdFromSession !== nzcScopeIdFromUrl)) {
      nzcScopeIdFromUrl=parseInt(nzcScopeIdFromUrl)
      if (sessionModelKey) {
        SessionUserState.saveUserDataToSession(sessionModelKey, nzcScopeIdFromUrl)
        setSessionModelName(nzcScopeIdFromUrl)
      }
      nzcScopeIdFromSession = nzcScopeIdFromUrl
    }
    if (nzcScopeIdFromSession) {
      nzcScopeIdFromSession=parseInt(nzcScopeIdFromSession)
      setScopeIdForSession(setNZCScopeId, refetchGetNZCScopes, fundDefaultScopeId, sessionModelKey, nzcScopeIdFromSession, updateSessionModelState)
    } else {
      if (nzcScopeIdFromUrl && (nzcScopeIdFromUrl !== fundDefaultScopeId)) {
        setNZCScopeId(nzcScopeIdFromUrl)
      }
      else if (fundDefaultScopeId) {
        setNZCScopeId(fundDefaultScopeId)
        nzcScopeIdFromSession = fundDefaultScopeId
      } else if (fundGroupId) {
        handleCreateNZCScope(fundGroupId)
      }
    }
  }, [fundKeyfactsData, openModelScopeId])

  useLayoutEffect(() => {
    if (urlQueryParams) {
      urlQueryParams.model = nzcScopeIdFromSession || nzcScopeIdFromUrl || fundDefaultScopeId
      if (graphIntensity)
        urlQueryParams.graphIntensity = graphIntensity
      updateQueryParamsForFundViews(urlQueryParams, null, history, instanceName, orgName, fundName, FUND_VIEWS_PAGES.netZeroCarbon)
      SessionUserState.saveUserDataToSession(sessionModelKey, nzcScopeIdFromUrl)
    }
  }, [urlQueryParams, fundIntensityValue, graphIntensity])

  useEffect(()=>{
    setOptionsTabActiveIndex(location?.state && location?.state?.from === topDownPathways ? 1 : 0)
  },[openModelScopeId])

  useEffect(() => {
    if (fundKeyfactsData?.financialYearStart) {
      const customTime = GetDefaultDateWithCachedData(fundKeyfactsData.financialYearStart, false, CachedUserState.getUserDataFromCache(LOCAL_STORAGE_KEYS.loginTimestamp))
      setDpCustomTime(customTime)
      const time = getTimeperiodLabel('CUS')
      const timeNoOfMonths = customTime && customTime.pickerValueMonths
      const timeEndDate = customTime && createEndMonthParam(customTime.pickerValueEndYear, customTime.pickerValueEndMonth)
      setVariables(['nzcDQSummaryData', nzcScopeId, time, timeNoOfMonths, timeEndDate])
    }

  }, [nzcScopeId, fundKeyfactsData])

  const benchMarkOptions = useQuery(['getBenchmarkOptions',graphIntensity], getBenchmarkOptions, noRefetchOnWindowFocus)
  const returnBenchmarkOptions = () => {
    return benchMarkOptions?.data ? benchMarkOptions?.data?.map((e) => {
      return {
        label: e.marketBenchmarkName,
        value: e.marketBenchmarkID,
        actualValue: e.marketBenchmarkValue
      }
    }) : []
  }

  useEffect(() => {
    if (getNzcSettingsData) {
      setChartOptions({
        ...chartOptions,
        assetsPlotData: getNzcSettingsData?.data?.plotIndividualAssets,
        xAxisScaleFrom: getNzcSettingsData?.data?.xAxisScaleFrom ? getNzcSettingsData.data.xAxisScaleFrom : nzcBaseLineYear,
        xAxisScaleTo: getNzcSettingsData?.data?.xAxisScaleTo ? getNzcSettingsData.data.xAxisScaleTo : 2050,
        xAxisAutoScale: getNzcSettingsData?.data?.xAxisAutoScale,
        yAxisScaleMin: getNzcSettingsData?.data?.yAxisScaleMin ? getNzcSettingsData.data.yAxisScaleMin : 0,
        yAxisScaleMax: getNzcSettingsData?.data?.yAxisScaleMax ? getNzcSettingsData.data.yAxisScaleMax : yAxisMaxValue,
        yAxisAutoScale: getNzcSettingsData?.data?.yAxisAutoScale,
        displayMarketBenchmark: getNzcSettingsData?.data?.displayMarketBenchmark,
        selectedMarketBenchmarkID: getNzcSettingsData?.data?.selectedMarketBenchmarkID,
        displayStrandingDates: getNzcSettingsData?.data?.displayStrandingDates,
      })
    }
  }, [getNzcSettingsData.data, yAxisMaxValue, graphNZCData?.data])

  useEffect(() => {
    if (getNzcSettingsData) {
      // to set TP details on load of NZC
      let tpMode
      let selectedTPName
      let activeIndex
      const tpFromNzcSettingsData = getNzcSettingsData?.data?.targetPathway
      if (tpFromNzcSettingsData) {
        tpMode = 'view'
        selectedTPName = `tp${tpFromNzcSettingsData}`
        activeIndex = tpFromNzcSettingsData - 1
      } else {
        tpMode = null
        selectedTPName = null
        activeIndex = null
      }
      updateScopeTargetState({
        tpMode: tpMode,
        selectedTPName: selectedTPName,
        activeIndex: activeIndex,
        loadingTPData: false
      })
    }
  }, [getNzcSettingsData.data])
  const benchmarkObj = chartOptions?.displayMarketBenchmark && chartOptions?.selectedMarketBenchmarkID && returnBenchmarkOptions().find((e) => e.value === chartOptions?.selectedMarketBenchmarkID)
  useEffect(()=>{
    SessionUserState.saveUserDataToSession('modelButton', NzcScopeDetailsData?.scopeAssets?.length === 0)
    let reloadingGraphData=graphDataLoading
    let graphData= nzcGraphData
    let nzcTargetPathwayData=targetPathwayData
    setBaselineYear(nzcBaseLineYear)
    const targetPathwaysData=graphNZCData?.data
    if(!chartOptions.loadChartOptions){
      const conditionToPlot = (targetPathwaysData?.graphData?.length > 0 && NzcScopeDetailsData?.fundAssets) || getNzcSettingsData?.data?.energySavingActions
      graphData=conditionToPlot ? getNZCChartData(targetPathwaysData, assetRowClick) : null
      reloadingGraphData=false
      nzcTargetPathwayData=conditionToPlot ? targetPathwaysData : null
      setShowChartOptions(!(targetPathwaysData === '' || targetPathwaysData?.graphData?.length === 0))
      setGraphDataLoading(reloadingGraphData)
      if(graphData){
        setNZCGraphData({ ...graphData, data: { ...graphData?.data, labels: []} })
      } else {
        setNZCGraphData(graphData)
      }
      setTargetPathwayData(nzcTargetPathwayData)
    }
    setModelNotesCardDetails({
      scopeName: NzcScopeDetailsData?.scopeName || '',
      description: NzcScopeDetailsData?.description || '',
    })
  }, [fundGraphRef.current?.hideGraph(), graphNZCData.data, scopeTargetState, chartOptions, NzcScopeDetailsData, assetRowClick,strandingYearForTP,isAssetRowClicked, targetsAndPathwayDetails?.showTopDownPathways])

  useEffect(() => {
    if(NzcScopeDetailsData){
      setNzcScopeAssetData(NzcScopeDetailsData?.fundAssets)
      setIsNZCModelOverridden(NzcScopeDetailsData?.isDataOverridden)
    }
  }, [NzcScopeDetailsData?.fundAssets, NzcScopeDetailsData?.isDataOverridden])

  const setAssetValueToRef = (value) => {
    previousAssetRowClick.current = value
    try {
      triggerTooltip(nzcChartAssetPanel.current, value)
    } catch(error) {
      console.log(error)
    }
  }

  useEffect(() => {
    // triggering tooltip through JS
    const asset = graphNZCData?.data?.assetGraphData?.find((f) => f.assetId === assetRowClick)
    const intensity = graphNZCData?.data?.assetIntensities.find(assetIntensity=>`${assetRowClick}`.includes(assetIntensity.propertyID)) || {propertyID :assetRowClick }
    openPopUponClick({
      clickedAsset: asset, assetRowClick, assetIntensity: intensity, previousAssetRowClick, setAssetValueToReference: setAssetValueToRef, clearAssetRowClick
    })
  }, [assetRowClick])

  // to set stranding year for TP and BAU
  useEffect(()=>{
    if(scopeTargetState.tpMode==='view'){
      let strandingYearForTP
      strandingYearForTP = Math.round(graphNZCData?.data?.strandings.filter(strandingDetails => TPS_NAMES_MAP[scopeTargetState.selectedTPName] === strandingDetails.strandingOf)[0]?.year)
      setStrandingYearForTP(strandingYearForTP)
    }
  },[scopeTargetState,graphNZCData])
  //endregion
  //region Functions/Handlers
  function updateStrandingYear(strandingYear){
    setStrandingYearForTP(strandingYear)
  }
  function setSessionModelName(modelId) {
    let modelName=getNZCScopes?.find(model=> modelId===model.scopeId)?.scopeName
    if(!modelName){
      refetchGetNZCScopes().then(scopesData=>{
        if(scopesData.data){
          modelName=scopesData.data.find(scope=> scope.scopeId===modelId)?.scopeName
          if(modelName){
            SessionUserState.saveUserDataToSession(modelId, modelName)
          }
        }
      })
    } else {
      SessionUserState.saveUserDataToSession(modelId, modelName)
    }
  }

  function reloadNzcData() {
    graphNZCData.refetch()
    refetchNZCDQMetricData()
  }

  function updateUrlQueryParams(newUrlQueryParams) {
    setUrlQueryParams(newUrlQueryParams)
  }

  function updateModelForQueryParams(newScopeId) {
    if(urlQueryParams && newScopeId){
      urlQueryParams.model=newScopeId
      updateQueryParamsForFundViews(urlQueryParams, null, history, instanceName, orgName, fundName, FUND_VIEWS_PAGES.netZeroCarbon)
    }
  }

  const updateScopeId = (newScopeId) => {
    if(fundId){
      SessionUserState.saveUserDataToSession(`${fundId}`, newScopeId)
      setSessionModelName(newScopeId)
    }
    updateModelForQueryParams(newScopeId)
    setOpenModelScopeId(newScopeId)
    setNZCScopeId(newScopeId)
  }

  function updateNzcDialogsState(newNzcDialogsState) {
    setNzcDialogsState({...nzcDialogsState, ...newNzcDialogsState})
  }

  function updateSessionModelState(newSessionModelState) {
    setSessionModelState({...sessionModelState, ...newSessionModelState})
  }

  function isNzcDefaultModel() {
    return(!!(getDefaultModel() && nzcScopeId && parseInt(nzcScopeId) === getDefaultModel()))
  }

  function errorDialogCloseHandler() {
    if (nzcDialogsState.assetError) {
      setAssetRowClick()
      updateNzcDialogsState({errorDialogVisible: false, assetError: false})
    } else {
      updateNzcDialogsState({errorDialogVisible: false})
    }
  }

  function confirmDialogCloseHandler() {
    updateNzcDialogsState({showConfirmModal: false})
  }

  function hideNzcConfirmDialog() {
    updateNzcDialogsState({showConfirmModalDeleted: false})
  }

  function scopeAssetsNotEmpty() {
    let scopeAssets=graphNZCData.data?.scopeAssets
    return !!(scopeAssets && scopeAssets.length>0)
  }

  const conditions = !scopeAssetsNotEmpty() || !nzcGraphDataNotEmpty()

  function updateScopeTargetState(newScopeTarget) {
    setScopeTargetState(prevState => {
      return {...prevState, ...newScopeTarget}
    })
  }

  function updateTargetsAndPathwayDetails(newState) {
    setTargetsAndPathwayDetails(prevState => {
      return {
        ...prevState,
        ...newState
      }
    })
  }

  function handleCreateNZCScope(selectedFundId) {
    createNZCScopeMutation.mutateAsync({'fundGroupId':selectedFundId}).then(scopeId => {
      updateModelForQueryParams(scopeId)
      setOpenModelScopeId(scopeId)
      setNZCScopeId(scopeId)
      refetchGetNZCScopes()
      refetchFundGroupKeyfacts()
    })
  }

  function isViewOrEditMode() {
    return (scopeTargetState.tpMode===SCOPE_TARGET_MODE.view || scopeTargetState.tpMode===SCOPE_TARGET_MODE.edit) && scopeTargetState.selectedTPName
  }

  function getDefaultModel() {
    return fundKeyfactsData?.nzcDefaultModel
  }

  const getBenchmarkgraphValue = (rebEnclosedValue) => {
    return yAxisMaxValue < rebEnclosedValue ? rebEnclosedValue + 5 : rebEnclosedValue
  }

  const returnXaxisValue = (value) => {
    return (value + 1) % 5 === 0 ? `${value}`.substring(2, value.length) : value
  }

  function createNZCGraphDataSets(nzcData, assetRowClick) {
    let rect = getLegendImage('science')
    let rectBau = getLegendImage('bau')
    let tpImage = getLegendImage('targetPathwaySvg')
    let dashedLine = getLegendImage('action-carbon-tp')
    let circleGreen = getLegendImage('actionBasedStrandingYear')
    let bauStrandingYearIcon = getLegendImage('bauStrandingYear')
    let targetPathwayStrandingYearIcon = getLegendImage('targetPathwayStrandingSvg')
    const scienceBasedTargetLabel = getNzcSettingsData?.data?.scienceBasedTarget === SBT_VERSIONS.sbt_1_5 ? 't_scientific_ghg_intensity' : 't_scientific_ghg_intensity(2.0)'
    let scientificGHGIntensity = {
      type: 'line',
      order: 3,
      label: `   ${getLocalizedValue(selectedLanguage, scienceBasedTargetLabel)}`,
      borderColor: CSS_VARIABLES.blue,
      backgroundColor: CSS_VARIABLES.blue,
      borderWidth: 2,
      fill: false,
      pointStyle: rect,
      data: [],
      datalabels:{
        display:false
      }
    }
    let graphDatasets = getGraphDataset(featureState.sieraplus_scienceBasedPathways, scientificGHGIntensity)
    const targetPathwayNames = getNzcSettingsData?.data?.targetPathwayNames

    let bauStrandingLabel = STRANDING_POINT_IDENTIFIER.BAU.CARBON
    let bauStandingsArray=graphNZCData?.data?.strandings.filter(stranding => stranding.strandingOf === bauStrandingLabel)
    let bauStrandingYear = bauStandingsArray.length> 0 && Math.floor(getBAUStrandingYearDetails(bauStandingsArray).year)

    if(isViewOrEditMode() && targetPathwayNames){
      graphDatasets[scopeTargetState.selectedTPName]={
        type: 'line',
        order: 4,
        label: `     ${JSON.parse(targetPathwayNames)[scopeTargetState.selectedTPName]}`,

        borderColor: CSS_VARIABLES.nature,
        backgroundColor: CSS_VARIABLES.nature,
        borderWidth: 2,
        borderDash: [10, 10],
        borderCapStyle: 'round',
        fill: false,
        pointStyle: tpImage,
        data: [],
        datalabels:{
          display:false
        },
        graphName: 'targetPathway',
      }
    }
    if (getBooleanActionBasedTPToPlot(getNzcSettingsData?.data?.energySavingActions, featureState.sieraplus_actionBasedPathways)) {
      graphDatasets[ACTION_BASED_CARBON_PATHWAY] = {
        type: 'line',
        order: 4,
        label: `    ${ACTION_BASED_CARBON_PATHWAY}`,
        borderColor: CSS_VARIABLES.green,
        backgroundColor: CSS_VARIABLES.green,
        borderWidth: 2,
        borderDash: [10, 10],
        borderCapStyle: 'round',
        fill: false,
        pointStyle: dashedLine,
        data: [],
        datalabels:{
          display:false
        }
      }
    }
    if (getNzcSettingsData?.data?.displayStrandingDates && isViewOrEditMode()  &&  !isNaN(strandingYearForTP)) {
      graphDatasets[STRANDING_YEAR_TP]={
        type: 'line',
        order: 4,
        label: ` ${getLocalizedValue(selectedLanguage, 't_target_stranding_year')}${strandingYearForTP}`,
        fill: false,
        pointStyle: targetPathwayStrandingYearIcon,
        data: [],
        datalabels:{
          display:false
        },
      }
    }
    if(plotBauStranding(getNzcSettingsData, bauStrandingYear)) {
      graphDatasets[BAU_STRANDING_YEAR] = {
        type: 'line',
        order: 4,
        label: ` ${getLocalizedValue(selectedLanguage, 't_bau_stranding_year')}${bauStrandingYear}`,
        fill: false,
        pointStyle: bauStrandingYearIcon,
        data: [],
        datalabels: {
          display: false
        },
      }
    }
    if (getBooleanActionBasedTPToPlot(getNzcSettingsData?.data?.energySavingActions, featureState.sieraplus_actionBasedPathways) && nzcData?.strandings.filter(strandingDetails => STRANDING_POINT_IDENTIFIER.ACTION_BASED_PATHWAYS.CARBON === strandingDetails.strandingOf).length>0 && actionsDataNotEmpty(actionBasedTPData)) {
      graphDatasets[`${ACTION_PATHWAY_STRANDING_YEAR}`] = {
        type: 'line',
        order: 4,
        label: ` ${getLocalizedValue(selectedLanguage, 't_action_based_pathway_stranding')} ${Math.round(nzcData?.strandings.filter(strandingDetails => STRANDING_POINT_IDENTIFIER.ACTION_BASED_PATHWAYS.CARBON === strandingDetails.strandingOf)[0].year)}`,
        fill: false,
        pointStyle: circleGreen,
        data: [],
        datalabels: {
          display: false
        }
      }
    } 

    let originalData=[]
    if(nzcData?.graphData){
      nzcData?.graphData?.sort((a,b)=>a.year-b.year)
      nzcData?.graphData?.forEach((graphData)=>{
        getScientificGHGIntensity(featureState.sieraplus_scienceBasedPathways, graphDatasets.scientificGHGIntensity, graphData, 1, fundIntensityValue)
        if(isViewOrEditMode() && graphDatasets[scopeTargetState.selectedTPName] && graphData.year > reportingYear){
          graphDatasets[scopeTargetState.selectedTPName].data.push({x:graphData.year,y:graphData[scopeTargetState.selectedTPName]===null?0: graphData[scopeTargetState.selectedTPName]})
        }
        if (getBooleanActionBasedTPToPlot(getNzcSettingsData?.data?.energySavingActions, featureState.sieraplus_actionBasedPathways) && actionsDataNotEmpty(actionBasedTPData) && graphData.year > reportingYear) {
          graphDatasets[ACTION_BASED_CARBON_PATHWAY].data.push({x:graphData.year,y: setMinToZeroForVariousNzcLines(graphData.weightedAverageEstimatedSavingsCarbonIntensity)})
        }
      })
      // Fund level progress over time(BAU)
      createBauDataset(originalData, nzcData, rectBau, selectedLanguage, (!(chartOptions.xAxisAutoScale) ? getNzcSettingsData?.data?.xAxisScaleFrom : baselineYear), selectedFund?.fundname, fundIntensityValue, reportingYear)
    }
    if (chartOptions?.displayMarketBenchmark && chartOptions?.selectedMarketBenchmarkID) {
      graphDatasets = { ...graphDatasets,
        benchmarkData: {
          type: 'line',
          order: 4,
          label: `   ${(benchmarkObj?.label)}`,
          borderColor:  CSS_VARIABLES.deep_ocean,
          backgroundColor: CSS_VARIABLES.deep_ocean,
          borderWidth: 2,
          borderDash: [10, 10],
          fill: false,
          pointStyle: getLegendImage('enclosed'),
          data: (() => {
            let data = []
            const yAxisVal = getBenchmarkgraphValue(Number(benchmarkObj?.actualValue))
            for (let i = baselineYear; i <= 2050; i++) {
              data.push({
                x: i,
                y: yAxisVal
              })
            }
            return data
          })(),
          datalabels:{
            display:false
          }
        }
      }
    }
    getScientificGHGIntensity(featureState.sieraplus_scienceBasedPathways, graphDatasets.scientificGHGIntensity, null, 2, originalData, fundIntensityValue)
    if(getBooleanActionBasedTPToPlot(getNzcSettingsData?.data?.energySavingActions, featureState.sieraplus_actionBasedPathways)){
      originalData.push(graphDatasets[ACTION_BASED_CARBON_PATHWAY])
      if(getNzcSettingsData?.data?.displayStrandingDates && graphDatasets[`${ACTION_PATHWAY_STRANDING_YEAR}`]){
        originalData.push(graphDatasets[`${ACTION_PATHWAY_STRANDING_YEAR}`])
      }
    }

    if(isViewOrEditMode()){
      originalData.push(graphDatasets[scopeTargetState.selectedTPName])
      if(getNzcSettingsData?.data?.displayStrandingDates &&  !isNaN(strandingYearForTP) ){
        originalData.push(graphDatasets[STRANDING_YEAR_TP])
      }
    }

    if(plotBauStranding(getNzcSettingsData, bauStrandingYear)){
      originalData.push(graphDatasets[BAU_STRANDING_YEAR])
    }

    if (chartOptions?.displayMarketBenchmark && chartOptions?.selectedMarketBenchmarkID) {
      originalData.push(graphDatasets.benchmarkData)
    }
    plotTpTargetYear({targetsAndPathwayDetails, scopeTargetState, nzcData, originalData, selectedLanguage, chartOptions,getNzcSettingsData})

    if(chartOptions?.assetsPlotData && nzcData?.assetIntensities && NzcScopeDetailsData?.fundAssets?.length > 0){
      nzcData.assetIntensities.forEach((assetIntensity)=>{
        if (Number(assetIntensity.propertyID) !== Number(assetRowClick)) {
          const requiredAsset = NzcScopeDetailsData?.fundAssets?.find(x => x.asset.assetId === assetIntensity.propertyID)
          const assetIdForLabel = requiredAsset?.asset?.assetId
          let dataAvailablePercentage = Math.trunc(requiredAsset?.dataAvailablePercentage* 100)
          let shape = 'circle'
          let color = `${greenColor}`
          if (dataAvailablePercentage >= 50 && dataAvailablePercentage <= 99){
            shape = 'rectRounded'
            color = `${orangeColor}`
          }
          else if (dataAvailablePercentage <= 49){
            shape = 'triangle'
            color = CSS_VARIABLES.red
          }
          originalData.push({
            type: 'bubble',
            data: [{
              x: baselineYear,
              y: assetIntensity.intensity,
              r: 8,
              label: assetIdForLabel
            }],
            backgroundColor: color,
            borderColor: color,
            pointStyle: shape,
            datalabels: {display: false},
            order: 1,
            xAxisID: 'xAxis',
            language: selectedLanguage,
            clip: false
          })
        }
      })
    }

    plotStrandingPoints({
      nzcData, chartOptions, scopeTargetState, getNzcSettingsData, isViewOrEditMode, originalData, featureState, isEnergyIntensity, actionBasedTPData
    })

    if(nzcData?.topDownPathways && targetsAndPathwayDetails?.showTopDownPathways){
      nzcData?.topDownPathways?.topdownPathways?.map((pathway, index) => plotTopDownPathway(pathway, index, nzcData, originalData, getNzcSettingsData))
    }

    if(assetRowClick){
      let selectedAssetData = getAssetGraphDatasetsForCarbonIntensity(nzcData, assetRowClick)
      originalData.push(...selectedAssetData)
    }
    return originalData
  }

  function getMaxIntensity(intensityValue) {
    return(Math.ceil((Math.max(...intensityValue) + 4) / 10) * 10)
  }

  const clearAssetRowClick = () => {
    setAssetRowClick()
    previousAssetRowClick.current = ''
  }

  const targetPathwayNames = getNzcSettingsData?.data?.targetPathwayNames

  function getMaxYScale(nzcData) {
    let carbonIntensityArray = []
    nzcData?.assetGraphData?.forEach((assetIntensity) => {
      const intensity = assetIntensity?.graphData.map((graph) => graph.carbonIntensity)
      carbonIntensityArray = [...carbonIntensityArray, ...intensity]
    })
    let maxBauIntensity = getMaxIntensity(nzcData?.graphData?.map(graphData => graphData.bauGhgIntensity))
    const maxScientificIntensity = getMaxIntensity(nzcData?.graphData?.map(graphData => graphData.scientificGHGIntensity))
    const maxCarbonIntensity = getMaxIntensity(carbonIntensityArray)
    const maxScopeAssetsIntensity = getMaxIntensity(nzcData?.assetIntensities.map(o => o.intensity))
    const topDownPathWayValue = getMaxTopDownPathwayValue(nzcData)
    const roundedValue = Math.max(maxScopeAssetsIntensity, maxScientificIntensity, maxCarbonIntensity, topDownPathWayValue, maxBauIntensity)
    return getRoundOffValueDivisbleBy5(roundedValue + (roundedValue / 10))
  }

  function getNZCChartData(nzcData, assetRowClick) {
    if(!nzcData){
      return null
    }
    setYAxisMaxValue(getYMAxValue(isEnergyIntensity, benchmarkObj, getMaxYScale, GRAPH_Y_SCALE_MAX_PADDING, nzcData, baselineYear))
    return ({
      data: {
        labels:[
          {index: isViewOrEditMode() ? 3 : 2, label: 'ABOVE TARGET'},
          {index: isViewOrEditMode() ? 4 : 3, label: 'BELOW TARGET'}
        ],
        // show blank for energy intensity until its completed 
        datasets: isEnergyIntensity ? createNZCEnergyIntensityDatasets({
          actionBasedTPData, featureState, NzcScopeDetailsData, baselineYear, selectedLanguage, chartOptions, getNzcSettingsData,
          selectedFund, nzcData, previousAssetRowClick, assetRowClick, epcRatingLabel, dash, clearAssetRowClick, fundIntensityValue,
          isViewOrEditMode, targetPathwayNames, scopeTargetState, targetsAndPathwayDetails, strandingYearForTP, isEnergyIntensity,benchmarkObj, yAxisMaxValue, reportingYear ,nzcDialogsState,setNzcDialogsState
        }) : createNZCGraphDataSets(nzcData, assetRowClick)
      },
      options: getGraphOptions()
    })
  }

  const getAssetValue = (e, activeElement) => {
    const value = activeElement?.element?.$context?.raw?.label
    if (value && Number(`${value}`.split('_')[0])) {
      setTimeout(() => {
        closeFunction(document.querySelector('.asset-detail-panel'), 'block')
        if (document.getElementById('closeAssetPanel')) {
          document.getElementById('closeAssetPanel').onclick = () => {
            closeFunction(document.querySelector('.asset-detail-panel'), 'none')
          }
        }
      }, 500)
      if (Number(value) && !(`${value}`.includes('_'))) {
        closeFunction(document.querySelector('.asset-detail-panel'), Number(value) !== Number(assetRowClick) ? 'remove' : '')
        setAssetRowClick(Number(value))
      }
      try {
        triggerTooltip(e.chart, value)
      } catch(error) {
        console.log(error)
      }
    }
  }

  function chartActionHandler(...chartActionParams) {
    const [actionEvent, activeElements,chart]=chartActionParams
    const firstActiveElement=activeElements[0]
    if(firstActiveElement) {
      const dataset=chart.data.datasets[firstActiveElement.datasetIndex]
      if(dataset.graphName && fundBAUBubbleName === dataset.graphName){
        if(assetRowClick){
          removeAssetRowClickValue()
        }
        fundBauTooltipHandler(chart, firstActiveElement.datasetIndex)
      } else {
        getAssetValue(actionEvent, firstActiveElement)
      }
    }
  }

  // Method to trigger tooltip
  function triggerTooltip(chart, assetId) {
    // Remove previous fund bau tooltip if opened
    let previousTooltipDatasetIndex = chart?.tooltip?.getActiveElements()[0]?.datasetIndex
    if(chart?.config?.options?.plugins?.tooltip?.externalName===externalFundBauTooltipHandlerName && previousTooltipDatasetIndex){
      hideFundBauTooltip(chart)
    } else {
      if(assetId){
        removeFundBauTooltipNode()
      }
    }
    const assetTooltipConfig = {
      assetNzcPageLink: featureState?.sieraplus_assetnzc ? createNavURLForFundPages({instanceName, orgName, fundName, assetId, pathSuffix: FUND_VIEWS_PAGES.netZeroCarbon}) : null,
      showAssetNzcPageLink: !!(featureState && featureState.sieraplus_assetnzc)
    }
    chart.options.plugins.tooltip = {...getDefaultTooltipConfig(), external: (context) => externalTooltipHandlerNzc(context, assetTooltipConfig, isEnergyIntensity), externalName: 'externalTooltipHandlerNzc'}
    chart.update()
    const tooltip = chart && chart?.tooltip
    const datasetIndex = chart?.data && chart?.data?.datasets.map((dataset) => dataset.assetIntensityId).indexOf(`${assetId}`)
    tooltip?.setActiveElements([
      {
        datasetIndex: datasetIndex,
        index: 0,
      }],
    {
      x: tooltip.x,
      y: tooltip.y,
    }
    )
    chart.update()
  }

  function triggerFundBauTooltip(chart, activeElementDatasetIndex) {
    // Remove previous asset tooltip if opened
    let prevAssetTooltipDatasetIndex = chart.tooltip.getActiveElements()[0]?.datasetIndex
    if(chart.config.options.plugins.tooltip?.externalName==='externalTooltipHandlerNzc' && prevAssetTooltipDatasetIndex){
      hideAssetTooltip(chart)
    }
    const activeElementsFromTooltip = chart.tooltip.getActiveElements()
    let previousTooltipDatasetIndex = activeElementsFromTooltip[0]?.datasetIndex
    // Remove prev tooltip
    if (previousTooltipDatasetIndex === activeElementDatasetIndex) {
      const prevTooltipConfig=chart.config.options.plugins.tooltip
      if(prevTooltipConfig?.externalName===externalFundBauTooltipHandlerName){
        hideFundBauTooltip(chart)
      } else {
        hideFundBauTooltip(chart)
        showFundBauTooltip(chart, activeElementDatasetIndex)
      }
    } else {
      if(previousTooltipDatasetIndex){
        // Handle tooltip switch b/w diamond icons
        const prevTooltipConfig=chart.config.options.plugins.tooltip
        if(prevTooltipConfig?.externalName===externalFundBauTooltipHandlerName){
          hideFundBauTooltip(chart)
        }
        showFundBauTooltip(chart, activeElementDatasetIndex)
      } else {
        const tooltipNode=document.getElementById(FUND_BAU_TOOLTIP_ROOT)
        if(tooltipNode){
          removeFundBauTooltipNode()
          const prevDatasetIndex=tooltipNode.datasetIndex
          if(activeElementDatasetIndex!==prevDatasetIndex){
            showFundBauTooltip(chart, activeElementDatasetIndex)
          }
        } else {
          showFundBauTooltip(chart, activeElementDatasetIndex)
        }
      }
    }
  }

  function fundBauTooltipHandler(chart, activeElementDatasetIndex) {
    if (chart && activeElementDatasetIndex) {
      setTimeout(() => {
        const tooltipCloseIcon = document.getElementById('img-popup-close-icon')
        if (tooltipCloseIcon) {
          tooltipCloseIcon.onclick = () => {
            chart.tooltip.setActiveElements([], {x: 0, y: 0})
            chart.update()
            removeFundBauTooltipNode()
          }
        }
      }, 500)
      triggerFundBauTooltip(chart, activeElementDatasetIndex)
    }
  }

  function getGraphOptions() {
    return(
      {
        onClick: chartActionHandler,
        responsive: true,
        maintainAspectRatio: false,
        layout: {padding: {top: 21, bottom: -7}},
        plugins: {
          tooltip: {
            enabled: false,
            ...getDefaultTooltipConfig(),
          },
          datalabels:{
            backgroundColor: CSS_VARIABLES.white,
            borderColor: CSS_VARIABLES.forest,
            borderRadius: 3,
            borderWidth	:0.1,
            color: (value) => {
              return (value?.dataset?.label?.includes('BAU')||value?.dataset?.label?.includes(TARGET_PATHWAY)) ?  CSS_VARIABLES.red : CSS_VARIABLES.forest
            },
            clamp:true,
            display:true,
            anchor: 'end',
            align: (value) => {
              let data = value?.dataset?.data?.length > 0 && value?.dataset?.data[0]
              let position = 'right'
              let reduceYears = data.label.includes(TARGET_PATHWAY)?3:2
              if(Math.round(data.x)!==chartOptions.xAxisScaleFrom){
                if((Math.round(data.x,1)>=2043)||((Math.round(data.x,1)>=chartOptions.xAxisScaleTo-reduceYears)&& !(chartOptions.xAxisScaleTo<Math.round(data.x,1)))){
                  position='left'
                }
              }
              return position
            },
            font: {
              family: CSS_VARIABLES.fontFamilyBase,
              weight: 400,
              size: 12,
            },
            formatter: function (value) {
              return ((value?.label?.includes('BAU'))||(value?.label?.includes(TARGET_PATHWAY))||(value?.label?.includes('Action'))) ? null : value.label
            },
            offset: 5,
            padding: {
              right: 5,
              left: 5,
              top: 6,
              bottom: 5
            }
          },
          aspectRatio: 5 / 3,
          layout: {
            padding: 16
          },
          legend: {
            position: 'bottom',
            align: 'start',
            onClick: null,
            labels: {
              usePointStyle: true,
              paddingBottom: 20,
              paddingLeft: 15,
              paddingRight: 15,
              font: getDefaultFontObjectForChart({weight: 400, size: 9}),
              generateLabels: (chart) => {
                nzcChartAssetPanel.current = nzcGraphRef && chart
                let generatedLabels=[]
                let visibility = []
                for (let i = 0; i < chart.data.datasets.length; i++){
                  if(chart.isDatasetVisible(i)){
                    visibility.push(CSS_VARIABLES.forest)
                  } else {
                    visibility.push(CSS_VARIABLES.forest_50)
                  }
                }
                const reqLegends = [{
                  text: ` ${getLocalizedValue(selectedLanguage, 't_benchmark_data')}`,
                  lineWidth: 2,
                  pointStyle: getLegendImage('curvyTriangle'),
                  hidden: false,
                  index: 8,
                  fontColor: CSS_VARIABLES.forest
                }, {
                  text: ` ${getLocalizedValue(selectedLanguage, 't_uplifted_estimated_data')}`,
                  fillStyle: `${orangeColor}`,
                  strokeStyle: `${orangeColor}`,
                  lineWidth:2,
                  pointStyle: 'rectRounded',
                  hidden: false,
                  index: 9,
                  fontColor: CSS_VARIABLES.forest
                }, 
                {
                  text: ` ${getLocalizedValue(selectedLanguage, 't_complete_data')}`,
                  fillStyle: `${greenColor}`,
                  strokeStyle: `${greenColor}`,
                  lineWidth:1,
                  pointStyle: 'circle',
                  hidden: false,
                  index: 10,
                  fontColor: CSS_VARIABLES.forest
                }]
                const bauLegend={
                  text: `    ${getLocalizedValue(selectedLanguage, 't_bau_ghg_intensity')}`,
                  pointStyle: getLegendImage('bau'),
                  hidden: false,
                  index: 12,
                }
                reqLegends.push({
                  text: ` ${getLocalizedValue(selectedLanguage, isEnergyIntensity ? 't_fund_energy_intensity' : 't_fund_carbon_intensity')}`,
                  fillStyle: CSS_VARIABLES.pink,
                  strokeStyle: CSS_VARIABLES.pink,
                  pointStyle: 'rectRot',
                  hidden: false,
                  index: 11,
                  lineWidth:1,
                  fontColor: CSS_VARIABLES.forest
                }, bauLegend)
                if( chart.data && chart.data.datasets){
                  generatedLabels= chart.data.datasets.map((dataset, index) => ({
                    text: dataset.label,
                    fillStyle: dataset.backgroundColor,
                    strokeStyle: dataset.backgroundColor,
                    lineWidth:dataset.borderWidth,
                    pointStyle: dataset.pointStyle,
                    hidden: dataset.datalabels?.display,
                    index: index,
                    fontColor: visibility[index],
                    lineDash:dataset?.graphName?.includes('topdownPathway')?[5,3]:[],
                  }))
                }
                generatedLabels = reqLegends.concat(generatedLabels)
                if(chart.data && chart.data.labels){
                  chart.data.labels.forEach((labelObject,index)=>{
                    const color=index===0?dataPointColors.red:dataPointColors.green
                    generatedLabels.push(
                      {
                        text: labelObject.label,
                        fillStyle: color,
                        strokeStyle: color,
                        lineWidth:2,
                        pointStyle: 'rect',
                        hidden: false,
                        index: labelObject.index,
                        fontColor: visibility[labelObject.index]
                      }
                    )
                  })
                }
                return generatedLabels
              },

              filter:function(label){
                if(label?.text===FUND_BAU_LINE){
                  return false
                }
                return label.text
              }
            }
          }
        },
        scales: {
          xAxis:{
            afterFit: (chart) => {
              chart.paddingBottom += 116
            },
            type:'linear',
            ticks: {
              stepSize: 1,
              color: CSS_VARIABLES.forest,
              autoSkip: false,
              padding: 13,
              maxRotation: 0,
              autoSkipPadding: 0,
              font: getDefaultFontObjectForChart({weight: 400, size: 8.5}),
              callback: function (value, i){
                return value % 5 === 0 || i === 0 ? returnXaxisValue(value) : ''
              }
            },
            grid: {
              display: true,
              color:  (chartContext) => {
                if (((baselineYear + 1) % 5 === 0 && chartContext?.tick?.value % 5 === 0) || chartContext?.tick?.value % 5 === 0) {
                  return CSS_VARIABLES.forest_20
                }
              },
              tickColor: CSS_VARIABLES.forest_20,
            },
            min: !(chartOptions.xAxisAutoScale)? getNzcSettingsData?.data?.xAxisScaleFrom : baselineYear,
            max: !(chartOptions.xAxisAutoScale)? getNzcSettingsData?.data?.xAxisScaleTo : 2050
          },
          xAxisAssetIntensities:{
            type:'linear',
            display: false,
            beginAtZero: true
          },
          y: {
            suggestedMin: 5,
            min: !(chartOptions.yAxisAutoScale)? getNzcSettingsData?.data?.yAxisScaleMin : 0,
            max: !(chartOptions.yAxisAutoScale) ? getNzcSettingsData?.data?.yAxisScaleMax : yAxisMaxValue,
            ticks: {
              stepSize:()=> {
                if ((!(chartOptions.yAxisAutoScale)? getNzcSettingsData?.data?.yAxisScaleMax : yAxisMaxValue) >= 200) {
                  return ((!(chartOptions.yAxisAutoScale)? getNzcSettingsData?.data?.yAxisScaleMax : yAxisMaxValue)/10)
                }
                else{
                  return 5
                }
              },
              padding: 7,
              callback: (value)  =>{
                let max = (!(chartOptions.yAxisAutoScale)? getNzcSettingsData?.data?.yAxisScaleMax : yAxisMaxValue)
                if(value===0){
                  return 0
                }
                if(max <= 200){
                  return Math.round(value)
                }
                return  truncateAndFormatNumWOneDecimal(selectedLanguage,Math.round(value))
              },
              color: CSS_VARIABLES.forest,
              font: getDefaultFontObjectForChart({
                style: 'normal',
                weight: 400,
                size: 9
              })
            },
            grid: {
              drawTicks: false,
            }
          }
        },
        elements: {
          point:{
            radius: 0
          }
        }
      }
    )
  }

  const returnSolidOrDottedLine = (lineContext, assetDataPoint, value) => {
    const assetIndividualPointData = assetDataPoint.find((e) => lineContext.p1.parsed.x === e.year)
    return (assetIndividualPointData?.pointStyle === 'triangle') || assetDataPoint.length === 1 ? value : [6, 0]
  }
  let dataIndex = 0
  const dash = (assetDataPoint, lineContext, value, currentYear) => {
    dataIndex = lineContext.p1.parsed.x === currentYear && dataIndex === 0 ? lineContext.p1DataIndex : dataIndex
    return (lineContext.p1DataIndex <= dataIndex  || dataIndex === 0) && currentYear !== baselineYear ? value : returnSolidOrDottedLine(lineContext, assetDataPoint, value)
  }

  function getAssetGraphDatasetsForCarbonIntensity(nzcData, assetRowClick){
    const assetGraphDatasets=[]
    if(nzcData && assetRowClick){
      const {assetGraphData, assetIntensities}=nzcData
      const clickedAsset = nzcData?.assetGraphData?.find((f) => f.assetId === assetRowClick)
      const splitAssetRowClick = `${assetRowClick}`.split('_')[0]
      let assetData
      const bauDataset={
        type: 'line',
        label: `    ${getLocalizedValue(selectedLanguage, 't_selected_asset')}`,
        borderColor: dataPointColors.yellow,
        backgroundColor: dataPointColors.yellow,
        borderWidth: 2,
        fill: false,
        pointStyle: getLegendImage('selectedAsset'),
        data: [],
        segment: {
          borderDash: context => dash(assetGraphDatasets.filter((e) => e.assetIntensityId), context, [4, 3], reportingYear) || [4, 3]
        },
        datalabels:{display:false}
      }
      // onClick function tooltip code
      // 1 0 => complete, 1 1 => uplifted, 0 0 => benchmark
      clickedAsset?.graphData?.sort((assetA, assetB) => assetB.year - assetA.year)

      if (!clickedAsset) {
        setNzcDialogsState({...nzcDialogsState, assetError: true ,errorDialogVisible: true, errorDialogData: { message: I18n('t_asset_error_message') }})
      }
      if(assetRowClick && nzcData?.assetIntensities && nzcScopeAssetData.length>0){
        const assetIntensity=assetIntensities.find(assetIntensity=>`${assetRowClick}`.includes(assetIntensity.propertyID)) || {propertyID : assetRowClick}
        let fundAssets = nzcScopeAssetData.find(element => !!(element.asset.assetId === assetIntensity?.propertyID && element.asset.isScopeAsset))
        const clickedAssetRow = clickedAsset && clickedAsset?.graphData?.find((e) => e.year === reportingYear)
        if(!clickedAssetRow){
          const latestYear = clickedAsset?.graphData.sort((a, b) => b.year - a.year)
          reportingYear = latestYear?.[0]?.year
        }
        
        for (let i = reportingYear; i >= baselineYear; i--) {
          const requiredYAxisValue = clickedAsset?.graphData?.find((e) => e.year === i)
          const assetPanelData = { ...fundAssets, ...requiredYAxisValue, strandingYear: clickedAsset?.strandingYear?.year, epcRating: epcRatingLabel, dataAvailablePercentage: requiredYAxisValue?.dataAvailablePercentage || 0, featureStateSoldAndPurchased: featureState?.sieraplus_nzcAssetSoldAndPurchased}
          const otherValueForShape = requiredYAxisValue?.dataAvailablePercentage >= 0.50 ? 2 : 3
          const valueForShape = requiredYAxisValue?.dataAvailablePercentage === 1 ? 1 : otherValueForShape
          previousAssetRowClick.current !== assetRowClick &&  assetGraphDatasets.push({
            type: 'bubble',
            data: [{
              x: i,
              y: requiredYAxisValue?.carbonIntensity,
              r: 8,
              datasets: assetPanelData,
              label: i === reportingYear ? splitAssetRowClick : splitAssetRowClick.concat(`_${i}`)
            }],
            backgroundColor: getShape(valueForShape).color,
            borderColor: getShape(valueForShape).color,
            pointStyle: getShape(valueForShape).shape,
            datalabels: {display: false},
            xAxisID: 'xAxis',
            language: selectedLanguage,
            assetIntensityId: reportingYear === i ? splitAssetRowClick : splitAssetRowClick.concat(`_${i}`),
            year: i,
            clip: false
          })
        }

        if(clickedAsset && assetGraphData){
          assetData=assetGraphData.find(graphData=> graphData.assetId === Number(splitAssetRowClick))
          if (assetData) {
            assetData?.graphData?.forEach((graphData)=>{
              bauDataset.data.push({x:graphData.year,y:graphData.carbonIntensity})
            })
          }
          previousAssetRowClick.current !== assetRowClick && assetGraphDatasets.push(bauDataset)
        }
      }

      //Stranding
      const stranding=assetData?.strandingYear
      if(clickedAsset && stranding){
        assetGraphDatasets.push(
          {
            type: 'bubble',
            data: [{
              x: stranding.year,
              y: stranding.strandingPoint,
              r: 17,
              label:stranding.strandingOf
            }],
            backgroundColor: 'transparent',
            borderColor: dataPointColors.yellow,
            clip: false
          }
        )
        assetGraphDatasets.push({
          type: 'line',
          order: 4,
          label: getLocalizedValue(selectedLanguage, 't_selected_asset_stranding') + Math.round(stranding.year),
          fill: false,
          pointStyle: getLegendImage('assetStranding'),
          data: [],
          datalabels: {display: false}
        })
      }
    }
    return assetGraphDatasets
  }

  function getAgregrateValues(data,field){
    return data.reduce((total,currentItem) => total + currentItem[field] , 0 )
  }

  function getNZCPerformanceSummary (perfData) {
    return (
      <>
        <span className={'summary-heading large-text-bold-2'}>{I18n('t_performance_assets_model')}</span>
        {(perfData && perfData.length > 0) ? (
          <>
            <div className={'summary-card'}>
              <span className={'card-heading medium-text previousYear'}>{I18n('t_reporting_year')}: {reportingYear}</span>
              <div>
                <div className={'list-item ex-large-text'}>{I18n('t_average_carbon_intensity')} <span className={'ex-large-text-light'}>{Math.round(getAgregrateValues(perfData, weightedAverageCarbonIntensity) ?? 0).toLocaleString(selectedLanguage, {maximumFractionDigits: 2 })} {I18n('t_kg_co2_m2_yr')}</span></div>
                <div className={'list-item ex-large-text'}>{I18n('t_average_cost_intensity')} <span className={'ex-large-text-light'}>{Number(getAgregrateValues(perfData, weightedAverageCostIntensity)?.toFixed(2)).toLocaleString(selectedLanguage, {maximumFractionDigits: 2 })} GBP/m²/yr</span></div>
                <div className={'list-item ex-large-text'}>{I18n('t_average_energy_intensity')} <span className={'ex-large-text-light'}>{Math.round(getAgregrateValues(perfData, weightedAverageEnergyIntensity) ?? 0).toLocaleString(selectedLanguage, {maximumFractionDigits: 2 })} {I18n('t_kWh_m2_yr')}</span></div>
                <div className={'list-item ex-large-text'}>{I18n('t_co2_emissions')} <span className={'ex-large-text-light'}>{AdjustValue(getAgregrateValues(perfData, co2Emission),CARBON).toLocaleString(selectedLanguage, {maximumFractionDigits: 2 })} {I18n('t_ton_co2_yr')}</span></div>
              </div>
            </div>
          </>
        ) : (
          <div className={'summary-card'}>{noDataAvailableMessage()}</div>
        )}
      </>
    )
  }

  const fundDQCardClickHandler = () => {
    history.push({
      pathname: createNavURLForFundPages({instanceName, orgName, fundName, pathSuffix: FUND_VIEWS_PAGES.dataQuality}),
      state: props.location?.state
    })
  }

  function getNZCDataQualitySummary () {
    let isDataAvailable = nzcDQSummaryData?.data[0]
    return (
      <>
        <span className={'summary-heading large-text-bold-2'}>{I18n('t_aggregated_data_quality_assets_model')}</span>
        {nzcDQSummaryDataLoading ? <div className={'summary-card nzc-DQMetric'}><OverviewChartSkeleton /></div> :
          isDataAvailable ?
            <div className={'summary-card nzc-DQMetric'}>
              <OverviewChart
                isLoading={nzcDQSummaryDataLoading}
                isDependentLoading={false}
                owner={FUND_DATAQUALITY_SCOPES.Both}
                data={nzcDQSummaryData?.data[0]}
                clickHandler = {fundDQCardClickHandler}
                basedOn={getDataQualityHeaderBasedOn()}
              />
              {/* <hr />
              <OverviewChart
                isLoading={false}
                isDependentLoading={false}
                owner={FUND_DATAQUALITY_SCOPES.Landlord}
                data={getFundDQMetric(FUND_DATAQUALITY_SCOPES.Landlord)} // TODO: SPR-2717 this needs to be updated
              />
              <hr />
              <OverviewChart
                isLoading={false}
                isDependentLoading={false}
                owner={FUND_DATAQUALITY_SCOPES.Tenant}
                data={getFundDQMetric(FUND_DATAQUALITY_SCOPES.Tenant)} // TODO: SPR-2717 this needs to be updated
              /> */}
            </div>
            :
            <div className={'summary-card nzc-DQMetric'}>{noDataAvailableMessage()}</div>
        }
      </>
    )
  }

  function onTabChange(tabIndex) {
    setOptionsTabActiveIndex(tabIndex)
    setCreateMode(true)
    previousAssetRowClick.current = null
    closeAssetPanel()
    setAssetRowClick()
    if(tabIndex===0 && nzcGraphRef.current){
      try {
        removeAssetTooltipNode(nzcGraphRef.current)
      } catch(err) {
        console.log(err)
      }
    }
  }

  function handleModels (modelOption) {
    modelsOverlay.current.hide()
    if(modelOption === modelsOptions.createNewModel){
      setOpenModel(modelsOptions.createNewModel)
    } else if(modelOption === modelsOptions.openModel) {
      setOpenModel(modelsOptions.openModel)
      modelsOverlay.current.hide()
    } else if(modelOption === modelsOptions.duplicateModel) {
      setOpenModel(modelsOptions.duplicateModel)
    } else if(modelOption === modelsOptions.exportConfigurationData){
      setOpenModel(modelsOptions.exportConfigurationData)
    } else if(modelOption === modelsOptions.importConfigurationData){
      setOpenModel(modelsOptions.importConfigurationData)
    }
  }

  function onHideModel(){
    setOpenModel('')
  }

  function handleModelHeader (model) {
    if(model === modelsOptions.createNewModel){
      return I18n('t_create_new_model')
    } else if(model === modelsOptions.openModel) {
      return I18n('t_open_model')
    } else if(model === modelsOptions.duplicateModel) {
      return I18n('t_duplicate_model')
    } else if(model === modelsOptions.exportConfigurationData){
      return I18n('t_export_config_data')
    } else if(model === modelsOptions.importConfigurationData){
      return I18n('t_import_config_data')
    }
  }

  const createAModelSuccessCallback = (newScopeId, checkboxValue) => {
    updateScopeId(newScopeId)
    if(checkboxValue){
      refetchGetNZCScopes()
      refetchFundGroupKeyfacts()
    }
  }

  function handleModelContainer(model){
    if(model === modelsOptions.createNewModel){
      return <NzcCreateAModel
        onHideModel={onHideModel}
        fundGroupId={fundGroupId}
        createAModelSuccessCallback={createAModelSuccessCallback}
      />
    } else if(model === modelsOptions.openModel) {
      return <NzcOpenAModel
        fundGroupId={fundGroupId}
        getNZCScopes={getNZCScopes}
        getNZCScopesLoading={getNZCScopesLoading}
        refetchGetNZCScopes={refetchGetNZCScopes}
        onHideModel={onHideModel}
        defaultModelScopeId={getDefaultModel()}
        setOpenModelScopeId={setOpenModelScopeId}
        refetchFundGroupKeyfacts={refetchFundGroupKeyfacts}
        updateScopeId={updateScopeId}
        fundId={fundId}
      />
    } else if(model === modelsOptions.duplicateModel) {
      return <NzcDuplicateModel
        onHideModel={onHideModel}
        fundGroupId={fundGroupId}
        NzcScopeName={NzcScopeDetailsData.scopeName}
        scopeId={nzcScopeId}
        duplicateSuccessModelCallback={createAModelSuccessCallback}
      />
    } else if(model === modelsOptions.exportConfigurationData){
      return <NzcExportModel
        NzcScopeDetailsData={NzcScopeDetailsData}
        scopeId={nzcScopeId}
        onHideModel={onHideModel}
        assetOrderByValue={assetOrderByValue}
      />
    } else if(model === modelsOptions.importConfigurationData){
      return <NzcImportModel
        onHideModel={onHideModel}
        fundGroupId={fundGroupId}
        scopeId={nzcScopeId}
        updateScopeId={updateScopeId}
        updateNzcDialogsState={updateNzcDialogsState}
        fundName={selectedFund.fundname || ''}
      />
    }
  }

  function handleModelOverlay (e) {
    modelsOverlay.current.toggle(e)
    previousAssetRowClick.current = null
    setAssetRowClick()
    closeAssetPanel()
  }

  function handleModelOverlayOptions(option, i) {
    const conditionToEnableButton = ((option.value === modelsOptions.duplicateModel || option.value.includes(modelsOptions.exportConfigurationData) || option.value.includes(modelsOptions.importConfigurationData)) && SessionUserState.getUserDataFromSession('modelButton'))
    const conditionWhenNotAdmin = isAdmin && (option.value === modelsOptions.importConfigurationData || option.value === modelsOptions.exportConfigurationData)
    const conditionToEnableButtonForNotAdmin = SessionUserState.getUserDataFromSession('modelButton') && option.value === modelsOptions.duplicateModel && isAdmin
    return (
      <>{conditionWhenNotAdmin ? null : (
        <><div className={classNames('overlay-model-option cursor',  (conditionToEnableButtonForNotAdmin || conditionToEnableButton) && 'disable-section')} key={i} data-testid={`model-options-${i}`}
          onClick={() => conditionToEnableButton ? '' : handleModels(option.value)}>{option.label}</div>
        {!(isAdmin) && option.value === modelsOptions.duplicateModel && <div className={'border-line'}/>}
        </>
      )}
      </>
    )
  }

  function isDataLoading() {
    return (graphNZCData.isLoading || graphDataLoading || getNZCScopesLoading || graphNZCData.isFetching)
  }

  function updateNzcGraphData(nzcGraphData) {
    setNZCGraphData(nzcGraphData)
  }

  function updateGraphLoadingStatus(graphLoadingStatus) {
    setGraphDataLoading(graphLoadingStatus)
  }

  function reloadScopeAndTargetPathwayData() {
    refetchNzcScopeDetailsData().then(()=>{
      graphNZCData.refetch().then(()=>{
        updateGraphLoadingStatus(false)
      }).catch(error=>{
        console.log(error)
        updateGraphLoadingStatus(false)
      })
    }).catch(error=>{
      console.log(error)
      updateGraphLoadingStatus(false)
    })
  }

  function scopeDataLoading() {
    return !((nzcScopeId && getScopesStatus!=='error') && scopeDetailsStatus==='success' && getScopesStatus==='success')
  }

  const getDefaultLabel = () => {
    return isNzcDefaultModel() ? <RoundedLabel name={getLocalizedValue(selectedLanguage, 't_default_model_name')}/> : null
  }
  //endregion

  function getDataQualityHeaderBasedOn() {
    return <span>{dpCustomTime.pickerValueMonths} {dpCustomTime.pickerValueMonths === '1' ? I18n('t_month') : I18n('t_months')} {I18n('t_end_of')} {monthListShortWithLocale[dpCustomTime.pickerValueEndMonth]} {dpCustomTime.pickerValueEndYear}</span>
  }

  function isAssetDataLoading() {
    return((isFetchingScopes || isFetchingScopeDetails) || (getScopesStatus===USE_QUERY_STATUS.idle || scopeDetailsStatus===USE_QUERY_STATUS.idle))
  }

  function confirmDialogFooter(){
    return (
      <div>
        <Button label={I18n('t_error_ok')} className={'dialog-ok'} onClick={confirmDialogCloseHandler}/>
      </div>
    )
  }

  function nzcGraphDataNotEmpty() {
    return !!(nzcGraphData)
  }

  const removeAssetRowClickValue = () => {
    setAssetRowClick()
    previousAssetRowClick.current = null
  }

  function getExportButtonStatus() {
    return `${nzcGraphData && (NzcScopeDetailsData && NzcScopeDetailsData.scopeAssets?.length > 0) ? 'enabled' : 'disabled'}`
  }
  const clearPreviousAssetId = (isValue) => {
    if (isValue) {
      previousAssetRowClick.current = null
    }
  }
  const excelExportInfo = {
    'options':{
      'enableExport': getExportButtonStatus(),
      'hasOverlay':false,
      'dataFrom':'fund-nzc'
    },
  }

  function onShowExportOverlayPanel() {
    setIsNZCExportOverlayPanelOpen(true)
  }

  function onHideExportOverlayPanel() {
    setIsNZCExportOverlayPanelOpen(false)
  }

  return (
    <div className="grey-bg default-net-zero-carbon min-width-1280 scrollbar-track-hide" data-testid="default-fund-nzc">
      <div className={'container-layout default-net-zero-carbon__fund-nzc-content gutter'}>
        <div className={'fund-header'}>
          <FundCommonHeader
            hasDatePicker={false}
            hasBlueBackground={false}
            hasDropdownFilter={false}
            navLinkConfigs={navLinkConfigs}
            action={'Export'}
            onActionClick={(e) => exportOverlayRef.current.toggle(e)}
            excelExportInfo={excelExportInfo}
            isNZCExportOverlayPanelOpen={isNZCExportOverlayPanelOpen}
          />
          <NzcExportOverlay {...{exportOverlayRef, nzcGraphRef, selectedFund, docname: NzcScopeDetailsData?.isDataOverridden ? NzcScopeDetailsData?.overriddenDocumentName : null, selectedLanguage, appendTarget: document.getElementById('nzc-export'),'dataFrom':'fund-nzc', onShowExportOverlayPanel, onHideExportOverlayPanel, intensity: fundIntensityValue}} />
        </div>
        <>
          <div className={'default-net-zero-carbon__fund-nzc-content--asset-table-nzc-chart-container'}>
            <div className={'default-net-zero-carbon__fund-nzc-content--asset-table-nzc-chart-container--asset-table-nzc-chart-header'} data-testid="asset-table-nzc-chart-header">
              <div className={'default-net-zero-carbon__fund-nzc-content--asset-table-nzc-chart-container--asset-table-nzc-chart-header--nzc-chart-left'}>
                {scopeDataLoading() ? <NZCPageSingleLineSkeleton/> :
                  <>
                    <div className={'default-net-zero-carbon__fund-nzc-content--asset-table-nzc-chart-container--asset-table-nzc-chart-header--nzc-chart-title'}>
                      <Tooltip className={'portfolio-comm-tooltip long-text-tooltip nzc-model-name-tooltip'} target=".override" />
                      <span className={'default-net-zero-carbon__fund-nzc-content--asset-table-nzc-chart-container--asset-table-nzc-chart-header--nzc-chart-title--override'} data-ptf-tooltip={` ${NzcScopeDetailsData?.scopeName}`} data-pr-position="bottom" data-pr-my="left-287 bottom+52" onMouseEnter={(e)=>isTextOverflown(e)}>{I18n('t_model')}:{` ${NzcScopeDetailsData?.scopeName}`} </span>
                    </div>
                    <div className="default-net-zero-carbon__fund-nzc-content--asset-table-nzc-chart-container--asset-table-nzc-chart-header--default-label-container">
                      {getDefaultLabel()}
                    </div>
                  </>
                }
              </div>
              <div className={'default-net-zero-carbon__fund-nzc-content--asset-table-nzc-chart-container--asset-table-nzc-chart-header--nzc-model-button'}>
                <Button label={getLocalizedValue(selectedLanguage,'t_models')} className="common-yellow-bg-btn default-net-zero-carbon__fund-nzc-content--asset-table-nzc-chart-container--asset-table-nzc-chart-header--nzc-model-button--models btn-models button-highlight" disabled={!NzcScopeDetailsData || NzcScopeDetailsData?.fundAssets?.length === 0} onClick={(e) => handleModelOverlay(e)} aria-haspopup aria-controls="models-button"
                  icon={(NzcScopeDetailsData || NzcScopeDetailsData?.fundAssets?.length !== 0) && (isModelsOverlayPanelOpen ? 'pi pi-angle-up' : 'pi pi-angle-down') } iconPos={'right'}
                />
                <OverlayPanel data-testid={'models-overlay'} ref={modelsOverlay} id='models-button' style={{ width: '16rem' }} className="models-overlay"
                  onShow={() => setIsModelsOverlayPanelOpen(true)} onHide={() => setIsModelsOverlayPanelOpen(false)}>
                  {modelsOverlayOptions.map((option, i) => (
                    <React.Fragment key={`overlay-option-${i}`}>
                      {handleModelOverlayOptions(option, i)}
                    </React.Fragment>
                  ))}
                </OverlayPanel>
              </div>
            </div>
            <div className={'default-net-zero-carbon__fund-nzc-content--asset-table-nzc-chart-container--nzc-assets-table-chart'}>
              <div className={'default-net-zero-carbon__fund-nzc-content--asset-table-nzc-chart-container--nzc-assets-table-chart--nzc-options-col'}>
                <TabView className={'default-net-zero-carbon__fund-nzc-content--asset-table-nzc-chart-container--nzc-assets-table-chart--nzc-options-col--nzc-tabview'} activeIndex={optionsTabActiveIndex} onTabChange={(e)=>onTabChange(e.index)}>
                  <TabPanel header={getLocalizedValue(selectedLanguage, 't_asset_s')}>
                    { isAssetDataLoading() ?
                      <>
                        <NZCAssetTableSkeleton/>
                      </>
                      :
                      <NZCAssets
                        fundAssets={fundAssetsWithCurrentYear}
                        reportingYear={reportingYear}
                        refetchData={reloadScopeAndTargetPathwayData}
                        fundGroupId={fundGroupId}
                        nzcScopeId={nzcScopeId}
                        setAssetOrderByValue={setAssetOrderByValue}
                        updateGraphLoadingStatus={updateGraphLoadingStatus}
                        updateNzcGraphData={updateNzcGraphData}
                        assetRowClick={assetRowClick}
                        setAssetRowClick={setAssetRowClick}
                        chartOptions={chartOptions}
                        isDataLoading={isDataLoading()}
                        isModelDataOverridden={isNZCModelOverridden}
                        updateUrlQueryParams={updateUrlQueryParams}
                        reloadNzcData={reloadNzcData}
                        isFileImported={NzcScopeDetailsData?.isDataOverridden}
                        actionBasedTPData={actionBasedTPData}
                        isAssetRowClicked={isAssetRowClicked}
                        setIsAssetRowClicked={setIsAssetRowClicked}
                        previousClickedAssetRow = {previousAssetRowClick}
                        clearPreviousAssetId={clearPreviousAssetId}
                        fundId={sessionModelKey}
                      />
                    }
                  </TabPanel>
                  <TabPanel
                    contentClassName={'nzc-target-content'}
                    header={getLocalizedValue(selectedLanguage, 't_targets_and_pathways')}
                    disabled={conditions}
                    headerClassName={conditions ? 'nzc-tab-disabled' : ''}
                  >
                    {isFetchingScopeDetails ? <NZCPageSingleLineSkeleton/> : 
                      <NzcTargetPathwayForm
                        nzcScopeId={nzcScopeId}
                        setCreateMode={setCreateMode}
                        createMode={createMode}
                        setNZCGraphData={setNZCGraphData}
                        nzcSettingsData={getNzcSettingsData.data}
                        getNzcSettingsData={getNzcSettingsData}
                        setGraphDataLoading={setGraphDataLoading}
                        targetPathwayData={targetPathwayData}
                        scopeTargetState={scopeTargetState}
                        updateScopeTargetState={updateScopeTargetState}
                        refetchTargetPathwayData={graphNZCData.refetch}
                        updateTargetsAndPathwayDetails={updateTargetsAndPathwayDetails}
                        updateStrandingYear={updateStrandingYear}
                        updateUrlQueryParams={updateUrlQueryParams}
                        actionBasedTPDetails={actionBasedTPData}
                        baselineYear={baselineYear}
                        graphIntensity={isEnergyIntensity}
                        topdownPathways={graphNZCData?.data}
                        reportingYear={reportingYear}
                      />
                    }
                  </TabPanel>
                  <TabPanel
                    header={getLocalizedValue(selectedLanguage, 't_settings')}
                    disabled={NzcScopeDetailsData?.scopeAssets?.length === 0}
                    headerClassName={NzcScopeDetailsData?.scopeAssets?.length === 0  ? 'nzc-tab-disabled' : ''}
                  >
                    {!getNzcSettingsData.isLoading && <NzcSettingsForm
                      scopeId={nzcScopeId}
                      chartOptions={chartOptions}
                      refetchData={() => {
                        setNZCGraphData(null)
                        getNzcSettingsData.refetch()
                        graphNZCData.refetch()
                        refetchNzcScopeDetailsData()
                      }}
                      nzcSettingsData={getNzcSettingsData.data}
                      isModelDataOverridden={isNZCModelOverridden}
                      graphIntensity={fundIntensityValue}
                      reportingYear={reportingYear}
                    />}
                  </TabPanel>
                </TabView>
              </div>
              <div className={'default-net-zero-carbon__fund-nzc-content--asset-table-nzc-chart-container--nzc-assets-table-chart--nzc-chart-container'}>
                {isDataLoading()  || scopeDataLoading() ?
                  <div className='default-net-zero-carbon__fund-nzc-content--asset-table-nzc-chart-container--nzc-assets-table-chart--nzc-chart-container--chart-skeleton'>
                    {getNZCChartSieraPlusLogo()}
                    <ChartSkeleton/>
                  </div>
                  :
                  <FundNzcGraph
                    ref={fundGraphRef}
                    clearAssetRowClick={clearAssetRowClick}
                    nzcChartAssetPanel={nzcChartAssetPanel}
                    nzcGraphData={nzcGraphData}
                    scopeAssets={NzcScopeDetailsData?.scopeAssets}
                    importedFileName={NzcScopeDetailsData?.isDataOverridden ? NzcScopeDetailsData?.overriddenDocumentName : ''}
                    chartOptions={chartOptions}
                    setChartOptions={setChartOptions}
                    showChartOptions={showChartOptions}
                    nzcSettingsData={getNzcSettingsData.data}
                    scopeId={nzcScopeId}
                    fundId={sessionModelKey}
                    graphIntensity={graphIntensity}
                    maxValueYAxis={yAxisMaxValue}
                    nzcGraphRef={nzcGraphRef}
                    refetchData={() => {
                      getNzcSettingsData.refetch()
                      graphNZCData.refetch()
                    }}
                    setAssetRowClick={setAssetRowClick}
                    removeAssetRowClickValue={removeAssetRowClickValue}
                    scopeDataLoading={scopeDataLoading()}
                    setFundIntensityValue={setFundIntensityValue}
                    getBenchmarkOptionsData ={returnBenchmarkOptions()}
                    updateTargetsAndPathwayDetails={updateTargetsAndPathwayDetails}

                  />
                }
                {scopeDataLoading() &&
            <div className="default-net-zero-carbon__fund-nzc-content--asset-table-nzc-chart-container--nzc-assets-table-chart--nzc-chart-container--legend-skeleton">
              <NZCPageDoubleLineSkeleton/>
            </div>
                }
              </div>
            </div>
          </div>
          <div className={'default-net-zero-carbon__fund-nzc-content--nzc-graph-summary'} data-testid="summary-card">
            <div className={'model-notes'}>
              <span className={'summary-heading large-text-bold-2'}>{I18n('t_model_notes')}</span>
              <NZCModelNotesCard data={modelNotesCardDetails} isNzcDefaultModel={isNzcDefaultModel()} />
            </div>
            <div className={'performance-summary-model'}>
              {
                scopeDataLoading() ?
                  <>
                    <span
                      className={'summary-heading large-text-bold-2'}>{I18n('t_performance_assets_model')}</span>
                    <NZCPerformanceSummaryCardSkeleton/>
                  </>
                  :
                  getNZCPerformanceSummary(fundAssetsWithCurrentYear)
              }
            </div>
            <div data-testid="dataQualitySummary" className={'data-quality-summary-model'}>
              {getNZCDataQualitySummary()}
            </div>
          </div>
        </>
        <>
          <CommonDialog
            modal={true}
            style={{width: '1080px', height: 'fit-content'}}
            visible={openModel}
            onHide={onHideModel}
            header={handleModelHeader(openModel)}
            children={handleModelContainer(openModel)}
            className={'nzc-model scrollbar-track-hide'}
          />
          {nzcDialogsState.errorDialogVisible && <ErrorDialog
            data={nzcDialogsState.errorDialogData}
            dialogVisible={nzcDialogsState.errorDialogVisible}
            onHideHandler={errorDialogCloseHandler}
            closeHandler={errorDialogCloseHandler}
          />}
          {nzcDialogsState.showConfirmModal && <PopupDialog
            visible={nzcDialogsState.showConfirmModal}
            header={I18n('t_user_creation_confirmation_popup_title')}
            onHide={confirmDialogCloseHandler}
            footer={confirmDialogFooter}
            message={I18n('t_nzc_file_uploaded_success', {0: nzcDialogsState.uploadFileName})}
          />}
          {sessionModelState.showConfirmModalDeleted && <PopupDialog
            visible={sessionModelState.showConfirmModalDeleted}
            header={I18n('t_user_creation_confirmation_popup_title')}
            onHide={hideNzcConfirmDialog}
            footer={<div />}
            message={I18n('t_nzc_session_model_deleted', {0: getDeletedModelName()})}
          />}
        </>
      </div>
    </div>
  )
}

DefaultFundNetZeroCarbon.propTypes = {
  location: PropTypes.object,
  fundId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fundGroupId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fundKeyfactsData: PropTypes.object,
  refetchFundGroupKeyfacts: PropTypes.func
}

DefaultFundNetZeroCarbon.displayName = 'DefaultNetZeroCarbon'

export default DefaultFundNetZeroCarbon
