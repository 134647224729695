import {  CurrencyUnitToLabel,  getLocalizedValue,  unitDateFormatter,  unitsConverter} from '../../../helpers/Helper'
import {checkExcelCellData} from '../../ExportHelper'
import {areaCoveredMask, costTypeMask, DATA_FROM} from '../../../helpers/Constants'
import XlsxPopulate from 'xlsx-populate'
import FileSaver from 'file-saver'
import {prePopulatedSheet} from './AssetHomeDataQualityExcelHelper'
import {
  ACTION_PLAN_COLUMNS, areaCoveredListForExport,
  getPaybackPeriod, gresbMeasureListV2ForExport, impactListForExport, procuredByListForExport,
  statusListForExport
} from '../../../../components/common/action-plan/actionPlanTableTemplate'


export const AssetActionPlanExcelHelper = async (props, selectedLanguage, unitSystem, currencyUnit, agreedActions, applicableAction, completedAction, actionsUnderReview, overdueAction, featureState) => {
  const formatUnits = (value) => {
    let newValue = ''
    if (value) {
      newValue = Number(value).toLocaleString(selectedLanguage)
    }
    return newValue
  }

  function filterArrayOfObj(actionArray) {
    let filteredArray = []
    //outer array
    if (Array.isArray(actionArray) && actionArray.length) {
      actionArray.forEach((item) => {
        let currencyLabel
        if (featureState.sieraplus_currency) {
          currencyLabel = CurrencyUnitToLabel(currencyUnit)
        } else {
          currencyLabel = item.action.currency
        }
        let sortedObject = {
          Action: item.action.actionDescription,
          Impact: checkExcelCellData(item.action.impact, selectedLanguage),
          Current: item.action.current
            ? formatUnits(item.action.current) +
                        unitsConverter(item.action.impact, unitSystem)
            : '-',
          'Estimated Savings':
                        item.action.target && item.action.target !== 'undefined'
                          ? formatUnits(item.action.target) +
                            unitsConverter(item.action.impact, unitSystem)
                          : '-',
          'Target percent': item.action.targetUsagePercentage
            ? item.action.targetUsagePercentage + '%'
            : '-',
          Cost: checkExcelCellData(item.action.cost, selectedLanguage),
          Currency: checkExcelCellData(currencyLabel, selectedLanguage),
          'Cost type': costTypeMask[item.action.costType].props?.id
            ? getLocalizedValue(
              selectedLanguage,
              costTypeMask[item.action.costType].props?.id
            )
            : '-',
          'Area Covered': areaCoveredMask[item.action.areaCovered].props?.id
            ? getLocalizedValue(
              selectedLanguage,
              areaCoveredMask[item.action.areaCovered].props.id
            )
            : '-',
          Completion: item.action.completion
            ? unitDateFormatter(
              new Date(item.action.completion),
              unitSystem,
              props?.exportDetails.language
            )
            : '-',
        }
        filteredArray.push(sortedObject)
      })
    } else {
      let sortedObject = {
        Action: '',
        Impact: '',
        Current: '',
        'Estimated Savings': '',
        'Target percent': '',
        Cost: '',
        Currency: '',
        'Cost type': '',
        'Area Covered': '',
        Completion: '',
      }
      filteredArray.push(sortedObject)
    }
    return filteredArray
  }

  function getCostType(costType) {
    return costTypeMask[costType]?.props?.id
      ? getLocalizedValue(selectedLanguage, costTypeMask[costType].props.id)
      : '-'
  }

  function getFormattedCompletion(completion) {
    return unitDateFormatter(new Date(completion), unitSystem, props?.exportDetails.language)
  }

  function mapItemToFilteredObject(item) {
    const getUtilitySavingsInformation = () => {
      return item?.utilitySavings?.reduce((utilitySavings, obj) => {
        if(obj.isPrimaryUtility){
          utilitySavings[ACTION_PLAN_COLUMNS.asset.primaryUtility] = obj.utilityType
          utilitySavings[ACTION_PLAN_COLUMNS.asset.primaryUtilityPerYear] = obj.estimatedSavings
          utilitySavings[ACTION_PLAN_COLUMNS.asset.primaryCostPerYear] = obj.utilityCostChange
        }else{
          utilitySavings[ACTION_PLAN_COLUMNS.asset.secondaryUtility] = obj.utilityType
          utilitySavings[ACTION_PLAN_COLUMNS.asset.secondaryUtilityPerYear] = obj.estimatedSavings
          utilitySavings[ACTION_PLAN_COLUMNS.asset.secondaryCostPerYear] = obj.utilityCostChange
        }
        return utilitySavings
      }, {[ACTION_PLAN_COLUMNS.asset.primaryUtility]: '-', [ACTION_PLAN_COLUMNS.asset.primaryUtilityPerYear]: '-',
        [ACTION_PLAN_COLUMNS.asset.primaryCostPerYear]: '-', [ACTION_PLAN_COLUMNS.asset.secondaryUtility]:'-',
        [ACTION_PLAN_COLUMNS.asset.secondaryUtilityPerYear]: '-',[ACTION_PLAN_COLUMNS.asset.secondaryCostPerYear]: '-',})
    }


    return {
      [ACTION_PLAN_COLUMNS.asset.actionId]: checkExcelCellData(item?.actionId , selectedLanguage),
      [ACTION_PLAN_COLUMNS.asset.actionTitle]: checkExcelCellData(item?.actionDescription, selectedLanguage),
      [ACTION_PLAN_COLUMNS.asset.manager] : checkExcelCellData(item?.manager , selectedLanguage),
      [ACTION_PLAN_COLUMNS.asset.status] : item?.status ? statusListForExport(selectedLanguage).find((status) => status.value === item.status)?.label: '-',
      [ACTION_PLAN_COLUMNS.asset.impact]: item?.impact ? impactListForExport(selectedLanguage).find((impact) => impact.value === item?.impact)?.label : '-',
      [ACTION_PLAN_COLUMNS.asset.gresbEfficiencyMeasure]: item?.performanceMeasure ? gresbMeasureListV2ForExport(selectedLanguage).find((gresb) => gresb.value === item?.performanceMeasure)?.label : '-',
      [ACTION_PLAN_COLUMNS.asset.areaCovered]: item?.areaCovered ?  areaCoveredListForExport(selectedLanguage).find((areaCovered) => areaCovered.value === item?.areaCovered)?.label : '-',
      [ACTION_PLAN_COLUMNS.asset.scheduleDate] : item?.dueDate ? getFormattedCompletion(item.dueDate) : '-',
      [ACTION_PLAN_COLUMNS.asset.completionDate] : item?.completion ? getFormattedCompletion(item.completion) : '-',
      [ACTION_PLAN_COLUMNS.asset.costType]: getCostType(item?.costType),
      [ACTION_PLAN_COLUMNS.asset.currency]: checkExcelCellData(item?.currency, selectedLanguage),
      [ACTION_PLAN_COLUMNS.asset.costOfAction]: checkExcelCellData(item?.originalCost, selectedLanguage),
      [ACTION_PLAN_COLUMNS.asset.estimatedChange]: item?.estimatedChange && item?.estimatedChange !== 'undefined' ? formatUnits(item?.estimatedChange) + unitsConverter(item?.impact, unitSystem) : '-',
      ...getUtilitySavingsInformation(),
      [ACTION_PLAN_COLUMNS.asset.paybackPeriod]:item?.paybackPeriod ? getPaybackPeriod(item?.paybackPeriod,selectedLanguage): '-',
      [ACTION_PLAN_COLUMNS.asset.scope]: item?.scope?.length > 0 ? item?.scope?.join(', ') : '-',
      [ACTION_PLAN_COLUMNS.asset.procuredBy] : item?.procuredBy ? procuredByListForExport(selectedLanguage).find((procuredBy) => procuredBy.value === item.procuredBy)?.label  : '-',
      [ACTION_PLAN_COLUMNS.asset.createdDate]: item?.dateCreated ? unitDateFormatter(new Date(item?.dateCreated), unitSystem, props?.exportDetails.language) : '-',
      [ACTION_PLAN_COLUMNS.asset.modifiedDate]: item?.lastModified ? unitDateFormatter(new Date(item?.lastModified), unitSystem, props?.exportDetails.language) : '-',
      [ACTION_PLAN_COLUMNS.asset.createdBy]: checkExcelCellData(item?.createdBy , selectedLanguage),
    }
  }

  function filterArrayOfObjV2(actionArray) {
    if (!Array.isArray(actionArray) || actionArray.length === 0) {
      return [{
        [ACTION_PLAN_COLUMNS.asset.actionId]:'',
        [ACTION_PLAN_COLUMNS.asset.actionTitle]: '',
        [ACTION_PLAN_COLUMNS.asset.manager] : '',
        [ACTION_PLAN_COLUMNS.asset.status]: '',
        [ACTION_PLAN_COLUMNS.asset.impact]: '',
        [ACTION_PLAN_COLUMNS.asset.gresbEfficiencyMeasure] : '',
        [ACTION_PLAN_COLUMNS.asset.areaCovered]: '',
        [ACTION_PLAN_COLUMNS.asset.scheduleDate] : '',
        [ACTION_PLAN_COLUMNS.asset.completionDate] : '',
        [ACTION_PLAN_COLUMNS.asset.costType]: '',
        [ACTION_PLAN_COLUMNS.asset.currency]: '',
        [ACTION_PLAN_COLUMNS.asset.costOfAction]: '',
        [ACTION_PLAN_COLUMNS.asset.estimatedChange]: '',
        [ACTION_PLAN_COLUMNS.asset.primaryUtility]: '',
        [ACTION_PLAN_COLUMNS.asset.primaryUtilityPerYear]: '',
        [ACTION_PLAN_COLUMNS.asset.primaryCostPerYear]: '',
        [ACTION_PLAN_COLUMNS.asset.secondaryUtility]:'',
        [ACTION_PLAN_COLUMNS.asset.secondaryUtilityPerYear]: '',
        [ACTION_PLAN_COLUMNS.asset.secondaryCostPerYear]: '',
        [ACTION_PLAN_COLUMNS.asset.paybackPeriod]:'',
        [ACTION_PLAN_COLUMNS.asset.scope]:'',
        [ACTION_PLAN_COLUMNS.asset.procuredBy] : '',
        [ACTION_PLAN_COLUMNS.asset.createdDate]: '',
        [ACTION_PLAN_COLUMNS.asset.modifiedDate]: '',
        [ACTION_PLAN_COLUMNS.asset.createdBy]: '',
      },{[ACTION_PLAN_COLUMNS.asset.actionId] : getLocalizedValue(selectedLanguage,'t_no_data_available')}]
    }

    return actionArray.map(mapItemToFilteredObject)
  }

  const blob = await XlsxPopulate.fromBlankAsync().then(workbook => {
    const flagAssetActionPlan = featureState.sieraplus_assetActionPlan
    const agreed_action = flagAssetActionPlan ? filterArrayOfObjV2(JSON.parse(JSON.stringify(agreedActions))) : filterArrayOfObj(JSON.parse(JSON.stringify(agreedActions)))
    const applicable_action = flagAssetActionPlan ? filterArrayOfObjV2(JSON.parse(JSON.stringify(applicableAction))) : filterArrayOfObj(JSON.parse(JSON.stringify(applicableAction)))
    const actionsUnderReviewSheet = flagAssetActionPlan ? filterArrayOfObjV2(JSON.parse(JSON.stringify(actionsUnderReview))) : filterArrayOfObj(JSON.parse(JSON.stringify(actionsUnderReview)))
    const completedActionSheet = flagAssetActionPlan ? filterArrayOfObjV2(JSON.parse(JSON.stringify(completedAction))) : filterArrayOfObj(JSON.parse(JSON.stringify(completedAction)))
    const overdueActionSheet = flagAssetActionPlan && filterArrayOfObjV2(JSON.parse(JSON.stringify(overdueAction)))
    const actionPlanArray = flagAssetActionPlan ? [agreed_action, applicable_action, actionsUnderReviewSheet, completedActionSheet, overdueActionSheet] : [agreed_action, applicable_action, actionsUnderReviewSheet, completedActionSheet]
    const actionPlanTabName = flagAssetActionPlan ? ['t_agreed_action', 't_applicable_action', 't_action_under_review', 't_completed_action', 't_overdue'] : ['t_agreed_action', 't_applicable_action', 't_action_under_review', 't_completed_action']
    const keys = Object.keys(agreed_action[0])
    actionPlanArray.forEach((actionPlan, index) => {
      prePopulatedSheet(actionPlan, actionPlanTabName[index], workbook, index, keys, selectedLanguage,DATA_FROM.ASSET.ASSET_ACTION_PLAN)
    })
    workbook.activeSheet(getLocalizedValue(selectedLanguage, 't_agreed_action'))
    return workbook.outputAsync(workbook)
  })
  FileSaver.saveAs(blob, getLocalizedValue(selectedLanguage, 't_action_excel'))
}
