import React from 'react'
import {Skeleton} from 'primereact/skeleton'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import I18n from '../../../../utils/i18n/I18n'
import '../../../pages/admin/users/users-add/user-funds/UserFunds.scss'

const UserFundSkeleton = () => {
  const dummyArray = Array.from({ length: 5 })
  const bodyTemplate = (length) => {
    return <Skeleton width={length}/>
  }

  const headerTemplate = () => {
    return <div className="p-ml-1">
      <Skeleton width={'4rem'}/>
    </div>
  }

  return (
    <div className="custom-skeleton p-rel p-d-flex p-flex-column p-ai-center users-page-skeleton">
      <DataTable value={dummyArray} className="user-funds-grid"  stripedRows scrollHeight='850px' scrollable>
        <Column field="name" headerClassName="fund-grid-header name-column"
          header={headerTemplate()} body={()=>bodyTemplate('17rem')} bodyClassName="name-column"/>
        <Column field="assets" headerClassName="fund-grid-header assets-column"
          header={headerTemplate()} body={()=>bodyTemplate('5rem')} bodyClassName="assets-column"/>
        <Column field="members" headerClassName="fund-grid-header members-column"
          header={headerTemplate()} body={()=>bodyTemplate('5rem')} bodyClassName="members-column"/>
      </DataTable>
    </div>
  )
}

export default UserFundSkeleton
