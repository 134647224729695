import {getLocalizedValue, unitsConverter} from '../../../helpers/Helper'
import {checkExcelCellData} from '../../ExportHelper'
import {AREA, DATA_FROM, GAS_FUELS_THERMALS} from '../../../helpers/Constants'
import XlsxPopulate from 'xlsx-populate'
import FileSaver from 'file-saver'
import { getDQItemStatus, prePopulatedSheet } from './AssetHomeDataQualityExcelHelper'

export const AssetDataQualityExcelHelper = async (props,selectedLanguage, unitSystem) => {
  function filterDQArrayOfObj(array) {
    let filteredArray = []
    //outer array
    if (Array.isArray(array) && array.length) {
      array.forEach(function (item) {
        let sortedObject = {
          Status: getDQItemStatus(item.estimate),
          'Procured By': checkExcelCellData(item.responsibility, selectedLanguage),
          Meter: checkExcelCellData(item.electricityMPAN, selectedLanguage),
          Date: checkExcelCellData(item.dateSummary, selectedLanguage),
          Type: checkExcelCellData(item.utility, selectedLanguage),
          Provider: checkExcelCellData(item.provider, selectedLanguage),
          Location: checkExcelCellData(item.location, selectedLanguage),
          'Floor Area': item.floorArea + unitsConverter(AREA, unitSystem),
          Source: checkExcelCellData(item.source, selectedLanguage),
          Consumption: item.consumption ? item.consumption + unitsConverter(  item.utility === 'Gas' ? GAS_FUELS_THERMALS : item.utility,  unitSystem) : '-',
        }
        filteredArray.push(sortedObject)
      })
    } else {
      let sortedObject = {Status: '','Procured By': '',Meter: '',Date: '',Type: '',Provider: '',Location: '','Floor Area': '', Source: '',Consumption: ''}
      filteredArray.push(sortedObject)
    }
    return filteredArray
  }

  const createExcel = (landlordData, tenantData, keys) => {
    return XlsxPopulate.fromBlankAsync()
      .then(workbook => {
        prePopulatedSheet(landlordData, 't_graph_landlord', workbook, 0, keys, selectedLanguage,DATA_FROM.ASSET.ASSET_DATA_QUALITY)
        prePopulatedSheet(tenantData, 't_tenant', workbook, 1, keys, selectedLanguage,DATA_FROM.ASSET.ASSET_DATA_QUALITY)
        workbook.activeSheet(getLocalizedValue(selectedLanguage, 't_graph_landlord'))
        return workbook.outputAsync(workbook)
      })
  }
  // execution starts here...
  const landlordData = filterDQArrayOfObj(JSON.parse(JSON.stringify(props?.exportDetails.landlordExcel)))
  const tenantData = filterDQArrayOfObj(JSON.parse(JSON.stringify(props?.exportDetails.tenantExcel)))
  if(landlordData.length === 1 && !landlordData[0].Status){
    landlordData[0].Status = getLocalizedValue(selectedLanguage, 't_no_export_data_available')
  }
  if(tenantData.length === 1 && !tenantData[0].Status){
    tenantData[0].Status = getLocalizedValue(selectedLanguage, 't_no_export_data_available')
  }
  const keys = Object.keys(landlordData[0])
  const blob = await createExcel(landlordData, tenantData, keys)
  FileSaver.saveAs(blob, getLocalizedValue(selectedLanguage, 't_data_quality_excel'))
}