import React from 'react'
import AssetHome from '../asset-home/AssetHome'
import AssetForm from '../asset-form/AssetForm'
import AssetEpcForm from '../asset-epc-form/AssetEpcForm'
import AssetKeyDates from '../asset-key-dates/AssetKeyDates'

const AssetAdd = () => {
  const assetHomeConfigData={headerTitle: 't_add_asset'}

  return(
    <div data-testid={'asset-add'}>
      <AssetHome
        configData={assetHomeConfigData}
        getAssetFormContent={(setActiveIndexTab)=>(<AssetForm setActiveIndexTab={setActiveIndexTab} />)}
        getAssetEpcFormContent={(setActiveIndexTab) => (<AssetEpcForm setActiveIndexTab={setActiveIndexTab} />)}
        getAssetKeyDates={(setActiveIndexTab) => (<AssetKeyDates setActiveIndexTab={setActiveIndexTab} />)}
      />
    </div>
  )
}

export default AssetAdd
