import React from 'react'
import PropTypes from 'prop-types'
import { MultiSelect as PrimeMultiSelect } from 'primereact/multiselect'
import './MultiSelect.scss'
import Text from '../Text/Text'
        
const MultiSelect = ({
  colour,
  size,
  value,
  options,
  onChange,
  placeholderKey,
  optionLabel,
  optionGroupLabel,
  optionGroupChildren,
  onHide,
  disabled,
  testId,
  showSelectAll,
  fixedPlaceholder,
  error,
  errorMessageKey,
  filter,
  name,
  selectedItemTemplate,
  maxSelectedLabels,
  optionDisabled
}) => {
  return (
    <>
      <PrimeMultiSelect
        value={value}
        options={options}
        onChange={onChange}
        name={name}
        selectedItemTemplate={selectedItemTemplate}
        maxSelectedLabels={maxSelectedLabels}
        optionDisabled={optionDisabled}
        placeholder={<Text content={placeholderKey} colour={colour === 'light' ? 'teal' : 'white'}/>}
        optionLabel={optionLabel}
        optionGroupLabel={optionGroupLabel}
        optionGroupChildren={optionGroupChildren}
        onHide={onHide}
        disabled={disabled}
        data-testid={testId}
        className={`siera-multiselect--${colour} siera-multiselect--${size} ${error ? 'p-invalid' : ''}`}
        panelClassName={`siera-multiselect-panel--${colour} siera-multiselect-panel--${size}`}
        fixedPlaceholder={fixedPlaceholder}
        showSelectAll={showSelectAll}
        itemClassName="siera-multiselect-item"
        filter={filter}
        header={null}
        panelHeaderTemplate={!showSelectAll && !filter ? () => null : null}
      />
      {error && errorMessageKey && (
        <Text content={errorMessageKey} className="red mt-1" testId={testId ? `${testId}-error` : null}/>
      )}
    </>
  )
}

MultiSelect.propTypes = {
  colour: PropTypes.oneOf(['light', 'dark']),
  size: PropTypes.oneOf(['sm', 'lg']),
  value: PropTypes.array,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
      })
    ),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
            disabled: PropTypes.bool,
          })
        ),
      })
    ),
    PropTypes.array,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  placeholderKey: PropTypes.string,
  optionLabel: PropTypes.string,
  optionGroupLabel: PropTypes.string,
  optionGroupChildren: PropTypes.string,
  onHide: PropTypes.func,
  testId: PropTypes.string,
  disabled: PropTypes.bool,
  showSelectAll: PropTypes.bool,
  fixedPlaceholder: PropTypes.bool,
  error: PropTypes.bool,
  errorMessageKey: PropTypes.string,
  filter: PropTypes.bool,
  name: PropTypes.string,
  selectedItemTemplate: PropTypes.func,
  maxSelectedLabels: PropTypes.number,
  optionDisabled: PropTypes.func,
}

MultiSelect.defaultProps = {
  colour: 'light',
  size: 'sm',
  fixedPlaceholder: false,
  showSelectAll: false,
}

export default MultiSelect