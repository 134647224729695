import './DataQualityContainerSmall.scss'
import PropTypes from 'prop-types'
import StackedBarChartV3 from '../charts/StackBarChartV3'
import Tooltip from '../../atomic/Tooltip/Tooltip'
import TooltipTwoColumnsLayout from '../../atomic/Tooltip/TooltipContent/TooltipTwoColumnsLayout'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'
import { ButtonWrapper } from 'components/atomic/index'

const DataQualityContainerSmallV3 = ({dqChartData, noDataElement, onClick, columnName, containerClass, stackedBarClass, tooltipClass, getDqTooltipItems}) => {

  const chartClassNames=`${columnName?.toLowerCase()}-chart ${stackedBarClass}`
  const { loginState: { userInfo } } = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference

  if (!dqChartData) {
    return noDataElement
  }
  const {actualPercentage, supplierEstimatePercentage, calculatedEstimatePercentage, missingPercentage} = dqChartData
  const chartData=[
    { value: actualPercentage, colour: 'dq-actual' },
    { value: supplierEstimatePercentage, colour: 'dq-estimated' },
    { value: calculatedEstimatePercentage, colour: 'dq-not-covered' },
    { value: missingPercentage, colour: 'dq-incomplete' },
  ]

  return (
    <>
      <ButtonWrapper className={`data-quality-charts cursor ${containerClass}`} testId="data-quality-charts" onClick={onClick}>
        {dqChartData && <StackedBarChartV3 data={chartData} classNames={chartClassNames} />}
      </ButtonWrapper>
      <Tooltip target={`.${tooltipClass}`} padding="medium" showDelay={100}>
        <TooltipTwoColumnsLayout items={getDqTooltipItems(chartData, selectedLanguage)} />
      </Tooltip>
    </>
  )
}

DataQualityContainerSmallV3.propTypes = {
  dqChartData: PropTypes.object,
  noDataElement: PropTypes.object,
  onClick: PropTypes.func,
  columnName: PropTypes.string,
  containerClass: PropTypes.string,
  stackedBarClass: PropTypes.string,
  tooltipClass: PropTypes.string,
  getDqTooltipItems: PropTypes.func
}

DataQualityContainerSmallV3.displayName = 'DataQualityContainerSmallV3'

export default DataQualityContainerSmallV3
