import React from 'react'
import I18n from '../../../utils/i18n/I18n'

const Terms = (props) => {

  return (
    <div className="grey-bg min-width-1280">
      <div className="display gutter">
        <div className="container-box">
          {I18n('t_terms_page',
            {
              h1: msg => (<h1>{msg}</h1>),
              h3: msg => (<h3>{msg}</h3>),
              p: msg => (<p>{msg}</p>)
            }
          )}
        </div>
      </div>
    </div>

  )
}

export default Terms