import React from 'react'
import { Skeleton } from 'primereact/skeleton'
import './AssetTableHeaderSkeleton.scss'

const AssetTableHeaderSkeleton = () => {
  return (
    <div className="custom-skeleton p-rel">
      <div className="flex asset-table-header-skeleton">
        <Skeleton width="6%" borderRadius="0" height="0.5rem"/>
        <Skeleton width="6%" borderRadius="0" height="0.5rem" />
        <Skeleton width="6%" borderRadius="0" height="0.5rem"/>
        <Skeleton width="6%" borderRadius="0" height="0.5rem"/>
        <Skeleton width="6%" borderRadius="0" height="0.5rem"/>
        <Skeleton width="6%" borderRadius="0" height="0.5rem"/>
        <Skeleton width="6%" borderRadius="0" height="0.5rem"/>
        <Skeleton width="6%" borderRadius="0" height="0.5rem"/>
      </div>
    </div>
  )
}

export default AssetTableHeaderSkeleton