import { useQuery } from 'react-query'
import PropTypes from 'prop-types'
import FundReportSummariesContent from './FundReportSummariesContent'
import { GetFundReports } from 'services/funds/fund-reports-service'
import { fundReportPAIExcelExport } from '../export/pai/fundReportPAIExcelExport'
import { useEffect, useState } from 'react'
import { useI18n } from 'utils/i18n/I18n'
import useFundIds from 'utils/custom-hooks/useFundIds'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const FundReportSummaries = ({ onSummaryClick, onEditReportClick, onCreateReportClick, refetchTimeStamp, fundReportSummariesLoading, fundReportSummariesData, refetchFundReportSummaryData }) => {
  const [downloadYear, setDownloadYear] = useState(null)
  const intl = useI18n()
  const {loginState} = useLoginAuthContext()
  const selectedLanguage = loginState.userInfo.languagePreference
  const { fundId } = useFundIds()

  const {
    isLoading: fundReportsLoading,
    data: fundReportsData,
    isError: fundReportsError,
  } = useQuery({
    queryKey: ['GetFundReports', fundId, downloadYear],
    queryFn: GetFundReports,
    retry: false,
  })
  
  useEffect(() => {
    if(downloadYear !== null && !fundReportsError && !fundReportsLoading) {
      triggerExportHandler()
    }
  }, [downloadYear, fundReportsLoading, fundReportsError])

  useEffect(() => {
    if(!fundReportSummariesLoading){
      refetchFundReportSummaryData()
    }
  }, [refetchTimeStamp])

  const onDownloadReportClick = (downloadYear) => {
    setDownloadYear(downloadYear)
  }

  const triggerExportHandler = async () => {
    if (!fundReportsLoading && !fundReportsError && fundReportsData) {
      await fundReportPAIExcelExport({ fundReportsData, formatMessage: intl.formatMessage, selectedLanguage: selectedLanguage})
      setDownloadYear(null)
    }
  }

  return (
    <FundReportSummariesContent
      downloadYear={downloadYear}
      fundReportSummariesLoading={fundReportSummariesLoading}
      fundReportSummariesData={fundReportSummariesData}
      onSummaryClick={onSummaryClick}
      onCreateReportClick={onCreateReportClick}
      onDownloadReportClick={onDownloadReportClick}
      fundReportsLoading={fundReportsLoading}
      onEditReportClick={onEditReportClick}
    />
  )
}

FundReportSummaries.propTypes = {
  onSummaryClick: PropTypes.func,
  onCreateReportClick: PropTypes.func,
  onEditReportClick: PropTypes.func,
  refetchTimeStamp: PropTypes.number,
  fundReportSummariesLoading: PropTypes.bool,
  fundReportSummariesData: PropTypes.object,
  refetchFundReportSummaryData: PropTypes.func,
}

export default FundReportSummaries
FundReportSummaries.displayName = 'FundReportSummaries'