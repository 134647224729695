import PropTypes from 'prop-types'
import {Bar} from 'react-chartjs-2'
import './TileStackedBarChart.scss'
import {horizontalLinePlugin} from '../../../pages/assets/performance/performance-home/ChartHelper'
import {memo} from 'react'
import {getDefaultOptions} from '../../../pages/funds/fund-dashboard/fund-dashboard-hooks-&-helpers/fundDashboardHelper'

const TileStackedBarChart = ({chartDatasets, yMax}) => {

  const data = {
    labels: [''],
    datasets: chartDatasets ?? []
  }

  return (
    <div className="util-stacked-bar-view">
      <Bar data={data} options={getDefaultOptions(yMax)} plugins={[horizontalLinePlugin]}/>
    </div>
  )
}

TileStackedBarChart.propTypes = {
  chartDatasets: PropTypes.array,
  yMax: PropTypes.number
}
TileStackedBarChart.displayName = 'TileStackedBarChart'
export default memo(TileStackedBarChart)