import React from 'react'
import '../ESConnectionStep.scss'
import I18n from '../../../../../../utils/i18n/I18n'
import {ENERGY_STAR_STEPS, esShareProperties, getESErrorMessage} from '../../../../../../utils/helpers/Constants'
import {Button} from 'primereact/button'
import {esSharePropertiesValidate} from '../../../../../../services/energy-star/energystar-service'
import PropTypes from 'prop-types'

const ESConnectionStepThree = (props) => {

  async function setUpPropertiesSharing() {
    try {
      await esSharePropertiesValidate()
      props.onStepChange(ENERGY_STAR_STEPS.SHARE_PROPERTIES_STEP)
    } catch (e) {
      props.isError(getESErrorMessage[ENERGY_STAR_STEPS.SHARE_PROPERTIES_STEP])
    }
  }

  return (
    <div className={'es-connection-step'} data-testid={'esc-three-info'}>
      <span className={'step-sub-text'}>
        {I18n('t_es_account_sharing', {
          0: <a className={'step-link'} href={esShareProperties.toString()} target="_blank" rel="noreferrer noopener">{I18n('t_es_sharing')}</a>
        })}
      </span>
      <span className={'step-sub-text'} >{I18n('t_es_select_share')}</span>
      <span className={'step-sub-text step-sub-info'} >{I18n('t_es_set_props_full_access')}</span>
      <Button className={'common-blue-bg-btn step-button'} onClick={setUpPropertiesSharing} label={I18n('t_es_i_have_set_up_sharing')} />
    </div>
  )
}

ESConnectionStepThree.propTypes={
  onStepChange:PropTypes.func.isRequired,
  isError:PropTypes.func.isRequired
}

export default ESConnectionStepThree