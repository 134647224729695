
import PropTypes from 'prop-types'
import DashboardSummaryCard from './DashboardSummaryCard'
import DataQualityTileSkeleton from '../skeletons/DataQualityTileSkeleton'
import {NavLink, useParams} from 'react-router-dom'
import {getAssetPagesNavUrl} from '../../hooks-and-helpers/dashboardHelper'
import {FUND_VIEWS_PAGES} from '../../../../../../utils/helpers/Constants'
import DataQualityTile from './DataQualityTile'
import ActionPlanTileSkeleton from '../skeletons/ActionPlanTileSkeleton'
import ActionPlanTile from './ActionPlanTile'

const DashboardCardsGrid = ({
  dataQualityData,
  dataQualityDataLoading,
  timePeriod,
  pickerValue,
  isLoadingActionData,
  adbActions,
  selectedLanguage
}) => {


  const {instanceName, orgName, fundName, assetId} = useParams()

  return (
    <div className="asset-dashboard__card-grid">
      <DashboardSummaryCard isDataLoading={dataQualityDataLoading} skeleton={<DataQualityTileSkeleton/>}
        isDataEmpty={!dataQualityData}>
        <NavLink to={getAssetPagesNavUrl({
          instanceName,
          orgName,
          fundName,
          assetId,
          pathSuffix: FUND_VIEWS_PAGES.dataQuality
        })}>
          <DataQualityTile
            dataQualityData={dataQualityData?.find(dqData => dqData?.responsbility === 'Both')}
            selectedLanguage={selectedLanguage}
            timePeriod={timePeriod}
            pickerValue={pickerValue}
          />
        </NavLink>
      </DashboardSummaryCard>
      <DashboardSummaryCard isDataLoading={isLoadingActionData} skeleton={<ActionPlanTileSkeleton/>}
        isDataEmpty={!adbActions}>
        <NavLink to={getAssetPagesNavUrl({
          instanceName,
          orgName,
          fundName,
          assetId,
          pathSuffix: FUND_VIEWS_PAGES.actionPlan
        })}>
          <ActionPlanTile adbActions={adbActions}/>
        </NavLink>
      </DashboardSummaryCard>
    </div>
  )
}

DashboardCardsGrid.propTypes = {
  dataQualityData: PropTypes.object,
  dataQualityDataLoading: PropTypes.bool,
  timePeriod: PropTypes.string,
  pickerValue: PropTypes.object,
  isLoadingActionData: PropTypes.bool,
  adbActions: PropTypes.object,
  selectedLanguage: PropTypes.string
}

DashboardCardsGrid.displayName = 'DashboardCardsGrid'

export default DashboardCardsGrid