import {UTILITY_LABELS_OBJECT_TITLE_CASE} from '../../../../../utils/helpers/Constants'
import CSS_VARIABLES from '../../../../../resources/css/_variables.scss'
import {getDefaultFontObjectForChart} from '../../../../common/net-zero-carbon/nzc-common-utils'

export const chartOptions = {
  maintainAspectRatio: false,
  aspectRatio: 1.2,
  plugins: {
    legend: {
      labels: {
        color: CSS_VARIABLES.forest,
        boxWidth: 20,
        boxHeight: 6, // this feature will work with latest version of Chart library
        paddingRight: 1,
        font: getDefaultFontObjectForChart({size: 12})
      },
      position: 'bottom',
      align: 'start',
      fontFamily: CSS_VARIABLES.fontFamilyBase,
      maxHeight: 25,
      onClick: function (e) {
        e.stopPropagation()
      }
    }
  },
  animation: false,
  scales: {
    x: {
      stacked: true,
      ticks: {
        color: CSS_VARIABLES.forest,
        padding: 20, // label distance from x-axis
        font: getDefaultFontObjectForChart({size: 14})
      },
      grid: {
        drawOnChartArea: true, // vertical lines
        drawBorder: false, // x-axis line
        drawTicks: false, // below x-axis vertical lines
      }
    },
    y: {
      ticks: {
        color: CSS_VARIABLES.forest,
        padding: 9, // label distance from y-axis
        beginAtZero: true, // No negative value
        font: getDefaultFontObjectForChart({size: 12})
      },
      grid: {
        display: true,
        drawTicks: false,
      }
    }
  },
  tooltips: {
    enabled: false
  },
  events: ['click'] // this will remove hover color change only graph responds on click
}

export const exportChartOptionsPdf = {
  maintainAspectRatio: true,
  aspectRatio: 1.6,
  plugins: {
    legend: {
      labels: {
        fontColor: CSS_VARIABLES.forest,
        fontSize: 12,
        boxWidth: 20,
        boxHeight: 6, // this feature will work with latest version of Chart library
        padding: 10,
      },
      position: 'bottom',
      align: 'end',
      display: false,
      fontFamily: CSS_VARIABLES.fontFamilyBase,
      maxHeight: 25,
      onClick: function (e) {
        e.stopPropagation()
      }
    }
  },
  animation: false,
  scales: {
    x: {
      stacked: true,
      ticks: {
        color: CSS_VARIABLES.forest,
        padding: 20, // label distance from x-axis
        font: getDefaultFontObjectForChart()
      },
      grid: {
        display: false,
        drawTicks: false,
      }
    },
    y: {
      ticks: {
        padding: 20, // label distance from y-axis
        beginAtZero: true, // No negative value
        font: getDefaultFontObjectForChart()
      },
      grid: {
        drawOnChartArea: true, // vertical lines
        drawBorder: false, // x-axis line
        drawTicks: false, // below x-axis vertical lines
      }
    }
  },
  tooltips: {
    enabled: false
  },
}

export function setGraphTypePdf(utility, previousConsumption, currentConsumption,pState) {
  let dataSet = []
  const isWaste = utility === 'Waste'
  dataSet.push({
    type: 'bar',
    backgroundColor: CSS_VARIABLES.forest,
    borderRadius: 5,
    data: currentConsumption,
    barThickness: 40,
    borderSkipped: 'bottom',
    datalabels:{
      display:false
    }
  })
  if (isWaste) {
    dataSet.push({
      type: 'bar',
      backgroundColor: CSS_VARIABLES.sand,
      borderRadius: 5,
      data: pState.currentNrPdf,
      barThickness: 40,
      datalabels:{
        display:false
      }
    })
  }
  dataSet.push({
    type: 'line',
    backgroundColor: CSS_VARIABLES.deep_ocean,
    borderColor: CSS_VARIABLES.deep_ocean,
    borderWidth: 2,
    fill: false,
    data: previousConsumption,
    lineTension: 0,
    pointRadius: 0,
    borderSkipped: 'bottom',
    datalabels:{
      display:false
    }
  })
  return dataSet
}

export function setGraphType(pState, preOneYear, latestYear, labelNonRecycled, labelRecycled, preTwoYear) {
  let dataSet = []
  const isWaste = pState.parameter === UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE
  dataSet.push({
    type: 'bar',
    label: preOneYear + '/' + latestYear + ((isWaste) ? labelRecycled : ''),
    backgroundColor: CSS_VARIABLES.forest,
    borderRadius: 5,
    borderSkipped: 'bottom',
    data: pState.currentConsumption,
    barThickness: 20,
    datalabels:{
      display:false
    }
  })
  if (isWaste) {
    dataSet.push({
      type: 'bar',
      label: preOneYear + '/' + latestYear + labelNonRecycled,
      backgroundColor: CSS_VARIABLES.sand,
      borderRadius: 5,
      borderSkipped: 'bottom',
      data: pState.currentYearNR,
      barThickness: 20,
      datalabels:{
        display:false
      }
    })
  }
  dataSet.push({
    type: 'line',
    label: preTwoYear + '/' + preOneYear.toString().substring(2, 4) + ((isWaste) ? labelRecycled : ''),
    backgroundColor: CSS_VARIABLES.forest,
    borderColor: CSS_VARIABLES.forest,
    borderWidth: 1.5,
    fill: false,
    data: pState.previousConsumption,
    lineTension: 0,
    pointRadius: 0,
    datalabels:{
      display:false
    }
  })
  return dataSet
}

