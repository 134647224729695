import {useQueries} from 'react-query'
import {QueryKeyPrefix} from '../../../../../utils/helpers/Constants'
import {getFundDataQualityAssets, getFundDataQualityAssetsV2, getFundDataQualityProcurementData} from '../../../../../services/funds/fund-data-quality-service'
import {getDefaultQueryConfig} from './fundDataqualityHelper'

const useFundDataQualityData = (apiParamsForAssetsQuery) => {

  const {
    fundGroupId,
    oDataQuery,
    selectedUtility,
    time,
    timeNoOfMonths,
    timeEndDate,
    isTimePeriodChanged,
    selectedView,
    featureFundDQLayoutV2,
    selectedAgent,
    first,
    rows,
    oDataSortOrder
  } = apiParamsForAssetsQuery

  const selectedUtilitiesKeys = selectedUtility.map(item => item.key)
  const selectedAgentKeys = selectedAgent || []

  const [
    {isLoading: fundDQAssetsDataLoading, data: {data: fundDQAssetsData} = {}},
    {isLoading: fundDQSummaryDataLoading, data: {data: fundDQSummaryData} = {}},
  ] = useQueries([
    {
      queryKey: [QueryKeyPrefix.FUND_DATA_QUALITY_ASSETS, {fundGroupId, oDataQuery, selectedUtilitiesKeys, time, timeNoOfMonths, timeEndDate, selectedView, selectedAgentKeys, first, rows, oDataSortOrder}],
      queryFn: featureFundDQLayoutV2 ? getFundDataQualityAssetsV2 : getFundDataQualityAssets,
      ...getDefaultQueryConfig(!!(fundGroupId))
    },
    {
      queryKey: [QueryKeyPrefix.FUND_DATA_QUALITY_SUMMARY, {fundGroupId, time, timeNoOfMonths, timeEndDate, selectedUtilitiesKeys, isTimePeriodChanged}],
      queryFn: getFundDataQualityProcurementData,
      ...getDefaultQueryConfig(!!fundGroupId && !featureFundDQLayoutV2)
    }
  ])

  return {fundDQAssetsDataLoading, fundDQAssetsData, fundDQSummaryDataLoading, fundDQSummaryData}
}

export default useFundDataQualityData