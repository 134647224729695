import PropTypes from 'prop-types'
import './KeyFactsBar.scss'
import {asset_Keyfacts_List} from '../../pages/assets/dashboard/hooks-and-helpers/dashboardHelper'
import {bigNumberFormatter, getLocalizedValue} from '../../../utils/helpers/Helper'
import FundPerformanceCardSkeleton from '../skeletons/fund-performance-cards/FundPerformanceCardSkeleton'
import building_Hotel_Image from '../../../resources/images/icon/filler/building_hotel.svg'

function KeyFactsBar({keyFactsData, selectedLanguage, areaLabel, isLoading}) {
  function getKeyFactItemValue(item) {
    const itemValue = keyFactsData && keyFactsData[item.apikey] ? keyFactsData[item.apikey] : '-'
    if (item.apikey === 'totalFloorArea') {
      return bigNumberFormatter(itemValue)+' '+areaLabel
    } else {
      return itemValue
    }
  }
  function getAssetKeyfactsInfo() {
    const propertyImage = keyFactsData?.assetImageUri
    const content = asset_Keyfacts_List.map((item) => {
      return <div className={`keyfact-item ${item.className}`} key={item.apikey}>
        <span>{`${getLocalizedValue(selectedLanguage, item.label) + ': ' +getKeyFactItemValue(item)}`} </span>
        <span className="vertical-line">|</span>
      </div>
    })

    return <>
      <div className={`image-box ${propertyImage && 'asset-full-image'}`}>
        <div className={`image-in ${!propertyImage && 'asset-filler-image'}`}>
          <img src={propertyImage ? propertyImage : building_Hotel_Image} alt={''} className='asset-image'></img>
        </div>
        {propertyImage &&
        <div className="hover-img">
          <img src={keyFactsData?.assetImageUri} alt={''} className='asset-image-hover'></img>
        </div>
        }
      </div>
      <span className="vertical-line">|</span>
      {content}
    </>
  }

  return (
    isLoading ? <div className={'flex align-items-center pl-4'}>
      <FundPerformanceCardSkeleton isFundDashboardLayoutV3={true}/>
    </div> :
      <div className={'keyfacts-bar'}>
        {getAssetKeyfactsInfo()}
      </div>
  )
}

KeyFactsBar.propTypes = {
  keyFactsData: PropTypes.object,
  selectedLanguage: PropTypes.string,
  areaLabel: PropTypes.string,
  isLoading: PropTypes.string
}

export default KeyFactsBar