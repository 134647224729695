import {Text, View} from '@react-pdf/renderer'
import React, {useState} from 'react'
import styles from '../style-sheet/Styles'
import I18n from '../../i18n/I18n'
import {calcTitleAndPosition, firstRow, secondRow, thirdRow} from '../pdf-utils/PdfHelper'

const UtilityCardLarge = (props) => {
  const [utility] = useState(props.utility)
  const [isDashboard] = useState(props.dashboard)
  const  isAllDataNotNull = isDashboard ? utility.targetAmount !== null && utility.amountUptoTarget !== null &&
                         utility.differencePercentage !== null : utility.previousYearTotalConsumption !== null &&
                         utility.currentYearTotalConsumption !== null && utility.percentageChange !== null

  const calcRow = () => {
    return styles.secondRowUtilityLarge
  }

  return (
    <>
      {isAllDataNotNull ?
        <View style={[styles.assets, styles.borderExceptBottom, calcRow(), calcTitleAndPosition(props.utilityName).position]}>
          <Text style={[styles.title, styles.topM17]}>
            {calcTitleAndPosition(props.utilityName).title}
          </Text>
          {firstRow(props,utility)}
          {secondRow(props,utility,props.dashboard)}
          {thirdRow(props,utility)}
        </View>
        :
        <View style={[styles.assets, styles.planBorder, calcRow(), calcTitleAndPosition(props.utilityName).position]}>
          <Text style={[styles.title, styles.topM17]}>
            {calcTitleAndPosition(props.utilityName).title}
          </Text>
          <Text style={[styles.assetTextSeven,styles.mL8]}>
            {I18n('t_msg_no_data')}
          </Text>
        </View>
      }
    </>
  )
}

export default UtilityCardLarge