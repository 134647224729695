import PropTypes from 'prop-types'
import FundDataQualityProcurementMissingValueSkeleton from '../../../../common/skeletons/fund-data-quality-procurement-skeleton/FundDataQualityProcurementMissingValueSkeleton'
import I18n from '../../../../../utils/i18n/I18n'
import {memo} from 'react'

const TabPanelHeaderTemplate = ({isLoading, options, missingData}) => {
  const tabProps = options.props.children.length ? options.props.children[options.index].props : options.props.children.props // SPR-2020

  return (
    <div className="align-items-center" style={{ cursor: 'pointer' }} onClick={options.onClick}>
      <div className="tab-header">
        {tabProps.header}
      </div>
      <div className="tab-missing-data-subheader">
        {isLoading ?
          <FundDataQualityProcurementMissingValueSkeleton /> :
          <>
            <span className="missing-value">{`${missingData}%`}</span>
            <span className="missing-label">{I18n('t_missing_lc')}</span>
          </>
        }
      </div>
    </div>
  )
}

TabPanelHeaderTemplate.propTypes = {
  isLoading: PropTypes.bool,
  missingData: PropTypes.number,
  options: PropTypes.object,
}

TabPanelHeaderTemplate.displayName = 'TabPanelHeaderTemplate'
export default memo(TabPanelHeaderTemplate)