import axios from 'axios'

import {Logout} from '../login-service'

const baseURL = process.env.REACT_APP_API_ENDPOINT
const location = window.location.pathname

let axiosInstance = axios.create({
  baseURL: baseURL,
})

export const addAccessTokenInterceptor = (getAccessTokenSilently) => {
  axiosInstance.interceptors.request.use(async (config) => {
    const token = await getAccessTokenSilently()
    config.headers['authorization'] = 'Bearer ' + token
    return config
  })
}

axiosInstance.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Ensure the error is carrying the response message if available.
  if (error && error.response && error.response.status === 401 && !(location.includes('/login') || location.includes('/'))) {
    Logout()
    window.location = '/login'
  }
  if(Boolean(error.response) && Boolean(error?.response?.data?.title)) {
    error.message = error.response.data.title
  }

  return Promise.reject(error)
})

export default axiosInstance

