import {Field, ErrorMessage} from 'formik'
import { Dropdown } from 'primereact/dropdown'
import React from 'react'
import './DropdownFormik.scss'
import infoGrey from '../../../../resources/images/icon/info.svg'
import PropTypes from 'prop-types'
import cn from 'classnames'
import GroupedMultiSelect from './GroupedMultiSelect'
import MultiSelectDropDown from './MultiSelectDropdown'
import { DROPDOWN_TYPE } from '../../../../utils/helpers/Constants'
import { Icon, Text, Tooltip } from 'components/atomic/index'

const DropdownFormik = ({ name, hasWarning, label, dataTestId, errors, touched, clear = false, options, value, placeholder, isInfo, dropdownType = DROPDOWN_TYPE.SINGLE,  tooltipTriggerClass, tooltipContentKeys, isFilterRequired, isEnabled=false, onOptionChange=()=>{}}) => {
  function dropdownSelection(field, callBack, name, value){
    if(dropdownType === DROPDOWN_TYPE.MULTI_GROUP){
      return <GroupedMultiSelect 
        {...field}
        name={name}
        optionList={options} 
        placeholder={placeholder}
        onMultiGroupSelection={callBack} 
      />
    }

    if(dropdownType === DROPDOWN_TYPE.MULTI){
      return <MultiSelectDropDown 
        {...field}
        name={name}
        optionList={options} 
        onChange={(option) => callBack(option.value)}
        placeholder={placeholder}
        values={value}
        onMultiSelect={callBack}
        isFilterRequired={isFilterRequired}
      />
    }

    return <Dropdown
      {...field}
      value={value}
      options={options}
      placeholder={placeholder}
      disabled = {isEnabled}
      showClear={value && clear}
      filter={isFilterRequired}
      panelClassName="custom-dropdown-panel"
      onChange={(e) => {callBack && callBack(name, e.value); onOptionChange(e)}} // Update setFieldValue usage
    />
  }
  
  return (
    <div className={cn(errors[name] && touched[name] ? 'error-field' : 'dropdown-field', 'flex flex-column my-2 gap-1', {'warning-field': hasWarning})}>
      <div>
        <label className='relative' htmlFor={name}>
          {label} {isInfo && <img src={infoGrey} className='ml-2 absolute info-button' alt="info" />}
          {tooltipContentKeys && tooltipTriggerClass && 
            <>
              <Icon id='info_solid' size={17} className={tooltipTriggerClass ? `${tooltipTriggerClass} ml-2` : 'ml-2'} testId={tooltipTriggerClass}/>
              <Tooltip target={`.${tooltipTriggerClass}`} width={300}>
                <Text content={tooltipContentKeys.title} weight='bold' className='mb-2'/>
                {
                  Array.isArray(tooltipContentKeys.content) 
                    ? tooltipContentKeys.content.map((item) => (
                      <React.Fragment key={`tooltip-${item.contentKey}`}>
                        <Text content={item.contentKey} className='mb-2'
                          contentDynamic={{
                            dynamic: <Text content={item.dynamicValueKey} weight='bold'/>
                          }}
                        />
                      </React.Fragment>
                    ))
                    : <Text content={tooltipContentKeys.content} />
                }
              </Tooltip>
            </>
          }
        </label>
      </div>
      <Field name={name} data-testid={dataTestId}>
        {({ field, form: { setFieldValue } }) => (
          dropdownSelection(field, setFieldValue, name, value)
        )}
      </Field>
      <ErrorMessage name={name} component="div" className="error medium-text" />
    </div>
  )
}

DropdownFormik.propTypes = {
  label: PropTypes.string,
  errors: PropTypes.object,
  touched: PropTypes.object,
  isInfo: PropTypes.bool,
  name: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  dropdownType: PropTypes.string,
  tooltipTriggerClass: PropTypes.string,
  tooltipContentKeys: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.oneOf([PropTypes.arrayOf(PropTypes.shape({
      contentKey: PropTypes.string,
      dynamicValueKey: PropTypes.string,
    })), 
    PropTypes. PropTypes.string]),
  }),
  isFilterRequired: PropTypes.bool,
  defaultValues: PropTypes.array,
  dataTestId: PropTypes.string,
  isEnabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onOptionChange: PropTypes.object,
  hasWarning: PropTypes.bool,
  clear: PropTypes.bool
}

export default DropdownFormik
