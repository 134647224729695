export const REPORT_STATUS =  {
  Incomplete: 'Incomplete',
  NoAssetOwned: 'NoAssetOwned',
  CanCreateReport: 'CanCreateReport',
  ReportGenerated: 'ReportGenerated'
}

export const reportFrameworkOptions = [
  { label: 'EU SFDR PAI', value: 'sfdr' }
]

export const reportYearOptionsV2 = (startYear, newestYear, formatMessage) => {
  const localize = (id) => formatMessage({ id })
  return (
    Array.from({ length: (newestYear) - (startYear - 1) }, (_, i) => ({
      label: localize('t_jan') + ' - ' + localize('t_dec') + ' ' + String((newestYear) - i),
      value: (newestYear) - i
    }))
  )
}

export const mandatoryPAIs = ['fossilFuels', 'energyEfficiency']

export const getGroupedPAIs = (formatMessage) => {
  const localize = (id) => formatMessage({ id })
  return (
    [
      {
        label: localize('t_real_estate_mandatory'),
        items: [
          { label: localize('t_fossil_fuels'), value: 'fossilFuels', disabled: true },
          { label: localize('t_energy_efficiency'), value: 'energyEfficiency', disabled: true },
        ]
      },
      {
        label: localize('t_select_at_least'),
        items: [
          { label: localize('t_adverse_impact_ghg_emissions'), value: 'greenhouseGasEmissions' },
          { label: localize('t_energy_consumption'), value: 'energyConsumption' },
          { label: localize('t_waste_utility'), value: 'waste' },
          { label: localize('t_resource_consumption'), value: 'resourceConsumption' },
          { label: localize('t_biodiversity'), value: 'biodiversity' }
        ]
      },
    ]
  )
}