import {
  utilitiesListForPerformanceExport,
  convertToExcelExportFormat,
  EXPORT_TYPES,
  formatDateToDDMMYY,
  getDateRangeAndTimePeriodInfo,
  getEmptyRows,
  getPerformanceSheetTitle,
  getSheetName,
  getTableHeadersForExcelObject,
  getTitleObject,
  getValidData,
  LEVEL_OF_DETAILS,
  TIME_PERIOD_GRANULARITY,
  UTILITY_MAP_FOR_PERFORMANCE_EXPORT,
  getRequiredUnit,
  getLocalizedColumnNamesWithUnits,
  getNumberOfMonthsAndEndMonth,
  UNIT_TYPE_FOR_EXPORT,
  getUnitRequiredForAssetSummary,
  excelSummaryTableData,
} from '../../ExportHelper'
import XlsxPopulate from 'xlsx-populate'
import FileSaver from 'file-saver'
import {excelHelpers, formatDateToDdMonYy, getLocalizedValue} from '../../../helpers/Helper'
import {  monthsRepresentedByNumbers, UTILITY_LABELS_OBJECT_TITLE_CASE,
  UTILITY_OTHER_NAME_MAP} from '../../../helpers/Constants'
import { benchmarkAndStandardBenchmarkData, benchmarkAndStandardBenchmarkTitle, annualRangeSelection,
  mappedAnnualCurrentConsumption  } from '../asset-performance-helper/assetPerformanceCommonExportHelper'
import { getAssetTableRowInfoForAnnualDataV2Export, getAssetTableColumnNamesForAnnualDataV2Export } from '../asset-performance-helper/annualAssetPerformanceExportHelper'
import { getAssetMonthlyPerformanceHeading, getAssetMonthlyPerformanceData,
  mappedConsumptionDataByMonthly, rangeSelectionMonthly } from '../asset-performance-helper/monthlyAssetPerformanceExportHelper'
import { getColumnNameMeterAnnual, getTableDataMeterAnnual } from '../asset-performance-helper/meterAnnualPerformanceExportHelper'
import { getMeterMonthlyExcelHeader, getMeterMonthlyExcelData } from '../asset-performance-helper/meterMonthlyPerformanceExportHelper'

function getRowAndColumnInfoToApplyAdditionalStyles() {
  return {
    [LEVEL_OF_DETAILS.asset]: {
      [TIME_PERIOD_GRANULARITY.ANNUAL]: {
        rowNumber:15,
        startColumnASCIINumber: 77,
        numberOfColumns:9
      },
      [TIME_PERIOD_GRANULARITY.MONTHLY]:{
        rowNumber:15,
        startColumnASCIINumber: 78,
        numberOfColumns:9
      }
    },
    [LEVEL_OF_DETAILS.meter]: {
      [TIME_PERIOD_GRANULARITY.ANNUAL]:{
        rowNumber:15,
        startColumnASCIINumber: 78,
        numberOfColumns:9
      },
      [TIME_PERIOD_GRANULARITY.MONTHLY]:{
        rowNumber:15,
        startColumnASCIINumber: 79,
        numberOfColumns:9
      }
    }
  }
}

function hasFeatureFlag({sireplus_gapfillingexports}){
  return sireplus_gapfillingexports
}

function getSheetHeaderInformation(utility, dateInfo,getFilterSelectionValue, selectedLanguage, assetHomeExportSelections, type) {
  let dateRange = getLocalizedValue(selectedLanguage, 't_date_range') + ' : ' + dateInfo.dateRange
  const granularity = type === 1 ? assetHomeExportSelections.asset_home_performance_excel_export.time_period_granularity.excelValue : assetHomeExportSelections.asset_performance_all_utility_excel_export.time_period_granularity.excelValue
  const levelOfDetail = type === 1 ? assetHomeExportSelections.asset_home_performance_excel_export.level_of_detail.excelValue : assetHomeExportSelections.asset_performance_all_utility_excel_export.level_of_detail.excelValue
  let commonItems = [
    { A: getLocalizedValue(selectedLanguage, getPerformanceSheetTitle(utility)) },
    {A: ''},
    { A: getFilterSelectionValue[0][0] }
  ]
  
  if (type === 1) {
    commonItems = commonItems.filter((heading) => heading.A !== '')
    commonItems.push(
      { A: getFilterSelectionValue[1][0] },
      { A: getFilterSelectionValue[2][0] }
    )
  }
  
  commonItems.push(
    { A: dateRange },
    { A: `${getLocalizedValue(selectedLanguage, 't_level_of_detail')}: ${levelOfDetail}` },
    { A: `${getLocalizedValue(selectedLanguage, 't_time_period_granularity')}: ${granularity}` },
    { A: `${getLocalizedValue(selectedLanguage, 't_date_of_export')}: ${formatDateToDdMonYy()}` }
  )
  
  const result = commonItems
  
  return result
}

function getSummaryTableTitle(utility) {
  if(utility === UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY){
    return 't_energy_summary'
  }
  return 't_summary'
}

function getSummaryTableColumnNames(utility,summaryTableRowData){

  const electricityAndGasFuelThermal = [
    {key: 't_current_reporting_period_total_consumption', unit:getUnitRequiredForAssetSummary(summaryTableRowData?.value)},
    {key: 't_previous_reporting_period_total_consumption',unit:getUnitRequiredForAssetSummary(summaryTableRowData?.previousValue)},
    {key: 't_over_under_target_excel'}
  ]

  switch (utility) {
  case UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON:
    return [
      {key: 't_current_reporting_period_total_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_previous_reporting_period_total_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},{key: 't_over_under_target_excel'}
    ]
  case UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY:
    return electricityAndGasFuelThermal
  case UTILITY_LABELS_OBJECT_TITLE_CASE.WATER:
    return [
      {key: 't_current_reporting_period_total_consumption', unit: getRequiredUnit(UNIT_TYPE_FOR_EXPORT.WATER_CONSUMPTION, null, null)},
      {key: 't_previous_reporting_period_total_consumption', unit: getRequiredUnit(UNIT_TYPE_FOR_EXPORT.WATER_CONSUMPTION, null, null)},
      {key: 't_over_under_target_excel'}
    ]
  case UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE:
    return [
      {key: 't_current_reporting_period_total_waste', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},{key: 't_current_reporting_period_recycled_waste', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_current_percentage_recycled'},{key: 't_previous_reporting_period_total_waste', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},{key: 't_previous_reporting_period_recycled_waste', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)}, {key: 't_recycled_percentage_change_excel'}
    ]
  case UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY:
    return [
      {key: 't_current_reporting_period_total_energy_consumption', unit:getUnitRequiredForAssetSummary(summaryTableRowData?.value)},
      {key: 't_previous_reporting_period_total_energy_consumption', unit:getUnitRequiredForAssetSummary(summaryTableRowData?.previousValue)},{key: 't_over_under_target_excel'}
    ]

  case UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL:
    return electricityAndGasFuelThermal
  }
}

function getSummaryTableRowInfo(utility, summaryTableData, selectedLanguage) {
  let percentageChangeInReCycledWaste = utility === UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE && getValidData(summaryTableData?.variance, selectedLanguage, true) === '-' ? 'N/A' : getValidData(summaryTableData?.variance, selectedLanguage, true)
  
  if(utility === UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE){
    return [excelSummaryTableData(summaryTableData?.value, utility),
      excelSummaryTableData(summaryTableData?.additionalValue, utility),excelSummaryTableData(summaryTableData?.additionalValueVariance, utility),
      excelSummaryTableData(summaryTableData?.previousValue, utility),excelSummaryTableData(summaryTableData?.previousRecycledWaste, utility),
      percentageChangeInReCycledWaste
    ]
  }
  return [excelSummaryTableData(summaryTableData?.value, utility),
    excelSummaryTableData(summaryTableData?.previousValue, utility),getValidData(summaryTableData?.variance, selectedLanguage,  true)
  ]
}

function getAssetTableColumnNamesForAnnualDataExport(utility, getNumberOfMonths) {
  let commonTableHeaders = [
    {key: 't_asset_ref'}, {key: 't_name'}, {key: 't_fund'}, { key: 't_country'}, {key: 't_city'},{key: 't_sector'}, {key: 't_gresb_sector'}, { key: 't_purchase_date'},
    {key: 't_sale_date'}, {key: 't_total_asset_floor_area', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.AREA, null, null)},{key: 't_occupancy_excel'},{key: 't_is_the_asset_tenant'},
  ]

  switch (utility) {
  case UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON:
    return [...commonTableHeaders,{key: 't_reporting_period'},
      {key: 't_scope_1_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},{key: 't_scope_2_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_scope_3_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},{key: 't_total_carbon_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_carbon_intensity_excel', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.CARBON_INTENSITY, null, null)},{key: 't_over_under_target_excel'}]
  case UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY:
    return  [...commonTableHeaders,{key: 't_current_consumption_with_excluding_gapFilled_data', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)},
      {key: 't_previous_consumption_with_excluding_gapFilled_data',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)},
      {key: 't_over_under_target_excel'},{key: 't_intensity_excel',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_INTENSITY, null, null)},
      ...benchmarkAndStandardBenchmarkTitle(getNumberOfMonths),
      {key: 't_current_total_carbon_emissions',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},{key: 't_previous_period_total_carbon_emissions',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)}]
  case UTILITY_LABELS_OBJECT_TITLE_CASE.WATER:

    return  [...commonTableHeaders,{key: 't_current_consumption_with_excluding_gapFilled_data', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.WATER_CONSUMPTION, null, null)},
      {key: 't_previous_consumption_with_excluding_gapFilled_data', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.WATER_CONSUMPTION, null, null)},
      {key: 't_over_under_target_excel'},{key: 't_water_intensity_excel', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.WATER_INTENSITY, null, null)},
      ...benchmarkAndStandardBenchmarkTitle(getNumberOfMonths, true)
    ]

  case UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE:
    return [...commonTableHeaders,{key: 't_reporting_period'},{key: 't_hazardous_waste', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_non_hazardous_waste', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},{key: 't_total_waste_generated', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_landfill_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},{key: 't_incinerated', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_reuse_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},{key: 't_waste_to_energy_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_recycled_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},{key: 't_unknown_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)}]
  case UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY:
    return  [...commonTableHeaders,
      {key: 't_current_energy_consumption_with_excluding_gapFilled_data', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)},
      {key: 't_previous_energy_consumption_kwh_with_excluding_gapFilled_data',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)}, //NOTE
      {key: 't_over_under_target_excel'},{key: 't_intensity_excel',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_INTENSITY, null, null)},
      {key: 't_current_total_carbon_emissions',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},{key: 't_previous_period_total_carbon_emissions',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)}]

  case UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL:
    return  [...commonTableHeaders,{key: 't_utility_type'},{key: 't_current_consumption_with_excluding_gapFilled_data', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)},
      {key: 't_previous_consumption_with_excluding_gapFilled_data',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)},{key: 't_over_under_target_excel'},
      {key: 't_intensity_excel',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_INTENSITY, null, null)},
      ...benchmarkAndStandardBenchmarkTitle(getNumberOfMonths),
      {key: 't_current_total_carbon_emissions',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_previous_period_total_carbon_emissions',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)}]
  }
}

function getConsumptionTableData(utilityConsumptionInfo, selectedLanguage, intl){
  return utilityConsumptionInfo?.actualConsumption === null && utilityConsumptionInfo?.actualPreviousConsumption === null ? 
    mappedAnnualCurrentConsumption(null, selectedLanguage, intl, 't_no_export_data_available_for_single_asset') : 
    getValidData(utilityConsumptionInfo?.actualConsumption, selectedLanguage)
}

function getConsumptionDataForCarbon(utilityConsumptionInfo, selectedLanguage, intl){
  return utilityConsumptionInfo?.consumption === null ? 
    mappedAnnualCurrentConsumption(null, selectedLanguage, intl, 't_no_export_data_available_for_single_asset') :
    getValidData(utilityConsumptionInfo?.consumption, selectedLanguage)
}

function getAssetTableRowInfoForAnnualDataExport(utility, assetTableData, selectedLanguage, getNumberOfMonths, assetTableColumnNamesWithoutLocalization, intl) {
  function getAssetTableRowData(utilityConsumptionInfo, utility, asset) {

    let commonTableColumnValuesSet1 = [
      getValidData(asset.assetReference, selectedLanguage, false, false),getValidData(asset.name, selectedLanguage, false, false),
      getValidData(asset.fundName, selectedLanguage, false, false),getValidData(asset.assetLocation, selectedLanguage),
      getValidData(asset.assetCity, selectedLanguage),
      getValidData(asset.sector, selectedLanguage),getValidData(asset.gresbSector, selectedLanguage),
      formatDateToDDMMYY(asset.assetPurchaseDate, selectedLanguage),
      formatDateToDDMMYY(asset.assetSaleDate, selectedLanguage),getValidData(asset.floorArea, selectedLanguage),
      getValidData(asset.occupancy, selectedLanguage),getValidData(asset.isTenantControlled, selectedLanguage),
    ]
    let commonTableColumnValuesSet2=[
      getConsumptionTableData(utilityConsumptionInfo, selectedLanguage, intl),
      getValidData(utilityConsumptionInfo?.actualPreviousConsumption, selectedLanguage),
      getValidData(utilityConsumptionInfo?.targetToDelta, selectedLanguage),
      getValidData(utilityConsumptionInfo?.totalIntensity, selectedLanguage),
      ...benchmarkAndStandardBenchmarkData(utilityConsumptionInfo, selectedLanguage, getNumberOfMonths),
      getValidData(utilityConsumptionInfo?.actualCurrentCarbonConsumption, selectedLanguage),
      getValidData(utilityConsumptionInfo?.actualPreviousCarbonConsumption, selectedLanguage)]

    function getCarbonScopeOneValue(){
      if(utilityConsumptionInfo?.consumption === null){
        return mappedAnnualCurrentConsumption(null, selectedLanguage, intl, 't_no_export_data_available_for_single_asset')
      }
      return getValidData(utilityConsumptionInfo?.scope1, selectedLanguage)
    }

    switch (utility) {
    case UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON:
      return [...commonTableColumnValuesSet1,getValidData(utilityConsumptionInfo?.reportingPeriod, selectedLanguage),
        getCarbonScopeOneValue(),
        getValidData(utilityConsumptionInfo?.scope2, selectedLanguage),getValidData(utilityConsumptionInfo?.scope3, selectedLanguage),
        getConsumptionDataForCarbon(utilityConsumptionInfo, selectedLanguage, intl),
        getValidData(utilityConsumptionInfo?.totalIntensity, selectedLanguage),
        getValidData(utilityConsumptionInfo?.targetToDelta, selectedLanguage)]

    case UTILITY_LABELS_OBJECT_TITLE_CASE.WATER:
      return [...commonTableColumnValuesSet1, 
        getConsumptionTableData(utilityConsumptionInfo, selectedLanguage, intl),
        getValidData(utilityConsumptionInfo?.previousConsumption, selectedLanguage),
        getValidData(utilityConsumptionInfo?.targetToDelta, selectedLanguage),
        getValidData(utilityConsumptionInfo?.totalIntensity, selectedLanguage),
        ...benchmarkAndStandardBenchmarkData(utilityConsumptionInfo, selectedLanguage, getNumberOfMonths),
      ]

    case UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE:
      return [...commonTableColumnValuesSet1,
        getValidData(utilityConsumptionInfo?.reportingPeriod, selectedLanguage),getValidData(utilityConsumptionInfo?.hazardous, selectedLanguage),
        getValidData(utilityConsumptionInfo?.nonHazardous, selectedLanguage),getValidData(utilityConsumptionInfo?.tonnes, selectedLanguage),
        getValidData(utilityConsumptionInfo?.landfill, selectedLanguage),getValidData(utilityConsumptionInfo?.incinerated, selectedLanguage),
        getValidData(utilityConsumptionInfo?.reuse, selectedLanguage),getValidData(utilityConsumptionInfo?.incineratedWer, selectedLanguage),
        getValidData(utilityConsumptionInfo?.recycled, selectedLanguage),getValidData(utilityConsumptionInfo?.mrfUnknown, selectedLanguage),]

    case UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY:
      return [...commonTableColumnValuesSet1, ...commonTableColumnValuesSet2]

    case UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY:
      return [...commonTableColumnValuesSet1,
        getConsumptionTableData(utilityConsumptionInfo, selectedLanguage, intl),
        getValidData(utilityConsumptionInfo?.actualPreviousConsumption, selectedLanguage),
        getValidData(utilityConsumptionInfo?.targetToDelta, selectedLanguage),
        getValidData(utilityConsumptionInfo?.intensity, selectedLanguage), 
        getValidData(utilityConsumptionInfo?.actualCurrentCarbonConsumption, selectedLanguage),
        getValidData(utilityConsumptionInfo?.actualPreviousCarbonConsumption, selectedLanguage)
      ]

    case UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL:
      return [
        ...commonTableColumnValuesSet1,
        getValidData(utilityConsumptionInfo.utility, selectedLanguage),
        ...commonTableColumnValuesSet2]
    }
  }

  // execution starts here...
  let assetTableRows = []
  let assetTableNumberOfRows = 0
  const rangeDataSelection = []
  const consumptionKeys = ['t_current_energy_consumption_with_excluding_gapFilled_data', 't_current_consumption_with_excluding_gapFilled_data', 't_scope_1_emissions']
  if (assetTableData)
    assetTableData.forEach((asset) => {
      let utilityConsumptionInfoOfAssetArray = asset[UTILITY_MAP_FOR_PERFORMANCE_EXPORT[utility]]
      if(utilityConsumptionInfoOfAssetArray?.length>0){
        utilityConsumptionInfoOfAssetArray?.forEach(utilityConsumptionInfo => {
          assetTableNumberOfRows+=1
          let assetTableRowData = getAssetTableRowData(utilityConsumptionInfo, utility, asset)
          if(utilityConsumptionInfo.actualConsumption === null && utilityConsumptionInfo.actualPreviousConsumption === null || UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON === utility && utilityConsumptionInfo.consumption === null){
            const getRangeSelection = annualRangeSelection(null, assetTableRows.length, assetTableColumnNamesWithoutLocalization, consumptionKeys)
            if(getRangeSelection){
              rangeDataSelection.push(getRangeSelection)
            }
          }
          assetTableRows.push(convertToExcelExportFormat(assetTableRowData))
        })
      }
    })
  return {assetTableRows,assetTableNumberOfRows, rangeDataSelection}
}

function getAssetTableColumnNamesForMonthlyDataExport(utility, getNumberOfMonths) {
  let commonTableHeaders1 = [
    {key: 't_asset_ref'}, {key: 't_asset_name'}, {key: 't_fund'}, { key: 't_country'},{key: 't_city'}, {key: 't_sector'}, {key: 't_gresb_sector'}, { key: 't_purchase_date'},
    {key: 't_sale_date'}, {key: 't_total_asset_floor_area', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.AREA, null, null)},
    {key: 't_occupancy_excel'},{key: 't_is_the_asset_tenant'}, { key: 't_reporting_period'}
  ]

  let commonTableHeaders2 = [
    {key: 't_asset_level_total_consumption_excel', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)},{key: 't_reporting_period_gap_filler_data_percentage'}, {key: 't_over_under_target_excel'},
    { key: 't_intensity_excel', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_INTENSITY, null, null)},
  ]

  switch (utility) {
  case UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON:
    return [...commonTableHeaders1,
      {key: 't_current_total_carbon_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_reporting_period_gap_filler_data_percentage'},
      {key: 't_carbon_intensity_excel', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.CARBON_INTENSITY, null, null)},{key: 't_over_under_target_excel'},
      {key: 't_scope_1_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},{key: 't_scope_2_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_scope_3_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
    ]

  case UTILITY_LABELS_OBJECT_TITLE_CASE.WATER:

    return  [...commonTableHeaders1,{key: 't_total_consumption', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.WATER_CONSUMPTION, null, null)},
      {key: 't_reporting_period_gap_filler_data_percentage'}, {key: 't_over_under_target_excel'},
      {key: 't_water_intensity_excel', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.WATER_INTENSITY, null, null)},
      ...benchmarkAndStandardBenchmarkTitle(getNumberOfMonths, true),
    ]

  case UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE:
    return [...commonTableHeaders1,{key: 't_month'},
      {key: 't_hazardous_waste', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_non_hazardous_waste', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_total_waste_generated', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_landfill_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_incinerated', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},{key: 't_reuse_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_waste_to_energy_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},{key: 't_recycled_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_unknown_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)}]

  case UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY:
    return [...commonTableHeaders1,...commonTableHeaders2, 
      ...benchmarkAndStandardBenchmarkTitle(getNumberOfMonths),
      {key: 't_reporting_period_carbon_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
    ]
  case UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY:
    return [...commonTableHeaders1,...commonTableHeaders2, {key: 't_reporting_period_carbon_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)}]
  case UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL:
    return [...commonTableHeaders1,{key: 't_utility_type'}, 
      ...commonTableHeaders2, ...benchmarkAndStandardBenchmarkTitle(getNumberOfMonths), {key: 't_reporting_period_carbon_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
    ]
  }
}

function getAssetTableRowInfoForMonthlyDataExport({utility, assetTableData, selectedLanguage, timePeriod, pickerValue, getNumberOfMonths, assetTableColumnNamesWithLocalization, intl}) {
  function getAssetTableRowData(utilityConsumptionInfo, utility,asset){

    let commonTableColumnValuesSet1 = [
      asset.assetReference,getValidData(asset.name, selectedLanguage, false, false),getValidData(asset.fundName, selectedLanguage, false, false),
      getValidData(asset.assetLocation, selectedLanguage), getValidData(asset.assetCity, selectedLanguage), getValidData(asset.sector, selectedLanguage),getValidData(asset.gresbSector, selectedLanguage),
      formatDateToDDMMYY(asset.assetPurchaseDate, selectedLanguage),formatDateToDDMMYY(asset.assetSaleDate, selectedLanguage),getValidData(asset.floorArea, selectedLanguage),
      getValidData(asset.occupancy, selectedLanguage),getValidData(asset.isTenantControlled, selectedLanguage),getValidData(utilityConsumptionInfo.reportingPeriod, selectedLanguage),
    ]
    let commonTableColumnValuesSet2 = [
      getValidData(utilityConsumptionInfo.consumption, selectedLanguage),
      '-',getValidData(utilityConsumptionInfo.targetToDelta, selectedLanguage),getValidData(utilityConsumptionInfo.totalIntensity, selectedLanguage),
      ...benchmarkAndStandardBenchmarkData(utilityConsumptionInfo, selectedLanguage, getNumberOfMonths),
      getValidData(utilityConsumptionInfo.currentCarbonEmission, selectedLanguage)
    ]
    
    function getMonthlyConsumptionData(){
      return mappedConsumptionDataByMonthly(utilityConsumptionInfo, timePeriod, pickerValue, intl, 't_no_export_data_available_for_single_asset')
    }

    switch (utility) {
    case UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON:
      return [...commonTableColumnValuesSet1,
        ...getMonthlyConsumptionData(),
        getValidData(utilityConsumptionInfo.consumption, selectedLanguage),
        '-',
        getValidData(utilityConsumptionInfo.totalIntensity, selectedLanguage),
        getValidData(utilityConsumptionInfo.targetToDelta, selectedLanguage, ),
        getValidData(utilityConsumptionInfo.scope1, selectedLanguage),
        getValidData(utilityConsumptionInfo.scope2, selectedLanguage),
        getValidData(utilityConsumptionInfo.scope3, selectedLanguage)]

    case UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY:
      return [...commonTableColumnValuesSet1, ...getMonthlyConsumptionData(), ...commonTableColumnValuesSet2]

    case UTILITY_LABELS_OBJECT_TITLE_CASE.WATER:
      return [...commonTableColumnValuesSet1, ...getMonthlyConsumptionData(), 
        getValidData(utilityConsumptionInfo.consumption, selectedLanguage),
        '-',
        getValidData(utilityConsumptionInfo.targetToDelta, selectedLanguage),
        getValidData(utilityConsumptionInfo.totalIntensity, selectedLanguage), 
        ...benchmarkAndStandardBenchmarkData(utilityConsumptionInfo, selectedLanguage, getNumberOfMonths)]

    case UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE:
      return [...commonTableColumnValuesSet1,
        getValidData(utilityConsumptionInfo.month, selectedLanguage),
        getValidData(utilityConsumptionInfo.hazardous, selectedLanguage),
        getValidData(utilityConsumptionInfo.nonHazardous, selectedLanguage),
        getValidData(utilityConsumptionInfo.tonnes, selectedLanguage),
        getValidData(utilityConsumptionInfo.landfill, selectedLanguage),
        getValidData(utilityConsumptionInfo.incinerated, selectedLanguage),
        getValidData(utilityConsumptionInfo.reuse, selectedLanguage),
        getValidData(utilityConsumptionInfo.incineratedWer, selectedLanguage),
        getValidData(utilityConsumptionInfo.recycled, selectedLanguage),
        getValidData(utilityConsumptionInfo.mrfUnknown, selectedLanguage)]

    case UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY:
      return [...commonTableColumnValuesSet1, ...getMonthlyConsumptionData(),
        getValidData(utilityConsumptionInfo.consumption, selectedLanguage),
        '-',
        getValidData(utilityConsumptionInfo.targetToDelta, selectedLanguage, ),
        getValidData(utilityConsumptionInfo.intensity, selectedLanguage),
        getValidData(utilityConsumptionInfo.currentCarbonEmission, selectedLanguage)]

    case UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL:
      return [...commonTableColumnValuesSet1, getValidData(utilityConsumptionInfo.utility, selectedLanguage),
        ...getMonthlyConsumptionData(), ...commonTableColumnValuesSet2]
    }
  }

  // execution starts here...
  let assetTableRows = []
  let assetTableNumberOfRows = 0
  const rangeDataSelection = []
  if (assetTableData)
    assetTableData.forEach((asset) => {
      let utilityConsumptionInfoOfAssetArray = asset[UTILITY_MAP_FOR_PERFORMANCE_EXPORT[utility]]
      utilityConsumptionInfoOfAssetArray.forEach(utilityConsumptionInfo => {
        assetTableNumberOfRows+=1
        let assetTableRowData = getAssetTableRowData(utilityConsumptionInfo, utility, asset)
        const getRangeSelection = rangeSelectionMonthly(utilityConsumptionInfo.monthlyConsumption, assetTableRows.length, assetTableColumnNamesWithLocalization)
        if(getRangeSelection){
          rangeDataSelection.push(getRangeSelection)
        }
        assetTableRows.push(convertToExcelExportFormat(assetTableRowData))
      })
    })
  return {assetTableRows,assetTableNumberOfRows, rangeDataSelection}
}

function attachMonthColumnsToAssetTable(utility, assetTableColumnNamesLocalized, selectedLanguage, timePeriod, pickerValue, selectedLevelOfDetail) {

  let noOfMonthsAndEndMonthObject = getNumberOfMonthsAndEndMonth(timePeriod, pickerValue)
  let monthRepresentingNumbersArray = Array.from({length: noOfMonthsAndEndMonthObject?.numberOfMonths},
    (_, index) => (noOfMonthsAndEndMonthObject.endMonth - index + 12) % 12).reverse()
  let monthColumns
  let insertMonthColumnsAt
  switch (utility) {
  case UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON:
    insertMonthColumnsAt = selectedLevelOfDetail === LEVEL_OF_DETAILS.asset ? 13 : 23
    monthColumns = monthRepresentingNumbersArray.map(monthNumber => `${getLocalizedValue(selectedLanguage, 't_emissions')} ${monthsRepresentedByNumbers[monthNumber]} (${getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)})`)
    assetTableColumnNamesLocalized.splice(insertMonthColumnsAt, 0, ...monthColumns)
    break
  case UTILITY_LABELS_OBJECT_TITLE_CASE.WATER:
    insertMonthColumnsAt = selectedLevelOfDetail === LEVEL_OF_DETAILS.asset ? 13 : 20

    monthColumns = monthRepresentingNumbersArray.map(monthNumber => `${getLocalizedValue(selectedLanguage, 't_consumption')} ${monthsRepresentedByNumbers[monthNumber]} (${getRequiredUnit(UNIT_TYPE_FOR_EXPORT.WATER_CONSUMPTION, null, null)})`)
    assetTableColumnNamesLocalized.splice(insertMonthColumnsAt, 0, ...monthColumns)
    break
  case UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY:
    insertMonthColumnsAt = selectedLevelOfDetail === LEVEL_OF_DETAILS.asset ? 13 : 21

    monthColumns = monthRepresentingNumbersArray.map(monthNumber => `${getLocalizedValue(selectedLanguage, 't_consumption')} ${monthsRepresentedByNumbers[monthNumber]} (${getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)})`)
    assetTableColumnNamesLocalized.splice(insertMonthColumnsAt, 0, ...monthColumns)
    break
  case UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY:
    insertMonthColumnsAt = selectedLevelOfDetail === LEVEL_OF_DETAILS.asset ? 13 : 22

    monthColumns = monthRepresentingNumbersArray.map(monthNumber => `${getLocalizedValue(selectedLanguage, 't_consumption')} ${monthsRepresentedByNumbers[monthNumber]} (${getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)})`)
    assetTableColumnNamesLocalized.splice(insertMonthColumnsAt, 0, ...monthColumns)
    break
  case UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL:
    insertMonthColumnsAt = selectedLevelOfDetail === LEVEL_OF_DETAILS.asset ? 14 : 22

    monthColumns = monthRepresentingNumbersArray.map(monthNumber => `${getLocalizedValue(selectedLanguage, 't_consumption')} ${monthsRepresentedByNumbers[monthNumber]} (${getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)})`)
    assetTableColumnNamesLocalized.splice(insertMonthColumnsAt, 0, ...monthColumns)
    break
  }
  return assetTableColumnNamesLocalized
}

function getAssetTableColumnNamesForAnnualByMeterDataExport(utility) {

  let commonTableHeaders1 = [{key: 't_asset_ref'}, {key: 't_asset_name'}, {key: 't_fund'}, { key: 't_country'},{key: 't_city'}, {key: 't_sector'}, {key: 't_gresb_sector'}, { key: 't_purchase_date'},{key: 't_sale_date'}]
  let commonTableHeaders2 = [
    {key: 't_meter_name'}, {key: 't_serial_number'}, {key: 't_comment'}, { key: 't_area_covered'},
    {key: 't_procured_by'}, {key: 't_status'}, { key: 't_active_from'},{key: 't_active_to'}, {key: 't_is_this_a_submeter'}]

  switch (utility) {
  case UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON :
    return [...commonTableHeaders1,  {key: 't_meter_name'}, {key: 't_serial_number'},  {key: 't_utility_type'},...commonTableHeaders2.slice(2),{key: 't_floor_area_served',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.AREA, null, null)},{key: 't_green_tariff'}, {key: 't_scope'},
      {key: 't_current_total_carbon_emissions', unit : getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},{key: 't_current_period_gapFilled_data'},{key: 't_previous_period_carbon_emissions', unit : getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},{key: 't_previous_period_gapFilled_data'}
    ]

  case UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY :
    return  [...commonTableHeaders1, ...commonTableHeaders2,
      {key: 't_floor_area_served', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.AREA, null, null)},
      {key: 't_green_tariff'},
      {key: 't_current_annual_consumption_includes_gap_filled_data',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)},
      {key: 't_current_year_gap'},
      {key: 't_previous_year_total_consumption_includes_gap_filled_data',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)},
      {key: 't_previous_period_gapFilled_data'},
      {key: 't_current_total_carbon_emissions',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_scope'}]

  case UTILITY_LABELS_OBJECT_TITLE_CASE.WATER :
    return  [...commonTableHeaders1, ...commonTableHeaders2,
      {key: 't_floor_area_served', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.AREA, null, null)},
      {key: 't_current_annual_consumption_includes_gap_filled_data',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.WATER_CONSUMPTION, null, null)},
      {key: 't_current_period_gapFilled_data'},
      {key: 't_previous_periods_total_consumption_includes_gap_filled_data',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.WATER_CONSUMPTION, null, null)},
      {key: 't_previous_period_gapFilled_data'}
    ]

  case UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE :
    return [...commonTableHeaders1,  {key: 't_waste_disposal_route_reference'},  {key: 't_contractor'},
      {key: 't_description'},  {key: 't_reporting_period'},
      {key: 't_hazardous_waste', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_non_hazardous_waste', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_total_waste_generated', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_landfill_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_incinerated', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_reuse_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_waste_to_energy_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_recycled_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_unknown_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)}]

  case UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY :
    return  [...commonTableHeaders1,
      {key: 't_total_asset_floor_area', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.AREA, null, null)},
      {key: 't_utility_type'}, ...commonTableHeaders2,
      {key: 't_floor_area_served_by_meter',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.AREA, null, null)},
      {key: 't_green_tariff'},
      {key: 't_current_annual_consumption_includes_gap_filled_data', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)},
      {key: 't_current_year_gap'},
      {key: 't_previous_year_total_consumption_includes_gap_filled_data',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)},
      {key: 't_previous_year_gap'},
      {key: 't_current_total_carbon_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},{key: 't_scope'}]

  case UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL :
    return  [...commonTableHeaders1,{key: 't_meter_name'},{key: 't_serial_number'},{key: 't_utility_type'},
      ...commonTableHeaders2.slice(2), {key: 't_floor_area_served', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.AREA, null, null)},
      {key: 't_green_tariff'},{key: 't_current_annual_consumption_includes_gap_filled_data',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)},
      {key: 't_current_period_gapFilled_data'},
      {key: 't_previous_periods_total_consumption_include_gap_filled_data',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)},
      {key: 't_previous_period_gapFilled_data'},
      {key: 't_current_total_carbon_emissions',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_scope'}]

  }
}


function getAssetTableRowInfoForAnnualByMeterDataExport(utility, assetTableData, selectedLanguage, assetTableColumnNamesWithLocalization, intl) {
  function getAssetTableRowData(utilityConsumptionInfo, utility, asset){

    let commonAssetTableRowDataSet1 = [
      getValidData(asset?.assetReference, selectedLanguage, false, false),
      getValidData(asset?.name, selectedLanguage, false, false),
      getValidData(asset?.fundName, selectedLanguage, false, false),
      getValidData(asset?.assetLocation, selectedLanguage),
      getValidData(asset.assetCity, selectedLanguage),
      getValidData(asset?.sector, selectedLanguage),
      getValidData(asset?.gresbSector, selectedLanguage),
      formatDateToDDMMYY(asset?.assetPurchaseDate, selectedLanguage),
      formatDateToDDMMYY(asset?.dateOfSale, selectedLanguage),
    ]
    let commonAssetTableRowDataSet2 = [
      getValidData(String(utilityConsumptionInfo?.meterName), selectedLanguage, false, false),
      getValidData(utilityConsumptionInfo?.serialNumber, selectedLanguage),
      getValidData(utilityConsumptionInfo?.meterComment, selectedLanguage, false, false),
      getValidData(utilityConsumptionInfo?.areaCovered, selectedLanguage),
      getValidData(utilityConsumptionInfo?.procuredBy, selectedLanguage),
      getValidData(utilityConsumptionInfo?.meterStatus, selectedLanguage),
      getValidData(utilityConsumptionInfo?.activeFrom, selectedLanguage),
      getValidData(utilityConsumptionInfo?.activeTo, selectedLanguage),
      getValidData(utilityConsumptionInfo?.isSubMeter, selectedLanguage),
    ]

    let commonAssetTableRowDataSet3 = [
      getValidData(utilityConsumptionInfo.nlaServed, selectedLanguage),
      getValidData(utilityConsumptionInfo.greenTariff, selectedLanguage),
      mappedAnnualCurrentConsumption(utilityConsumptionInfo.currentConsumption, selectedLanguage, intl, 't_no_meter_data_available'),
      getValidData(utilityConsumptionInfo.currentGapFilled, selectedLanguage),
      getValidData(utilityConsumptionInfo.previousConsumption, selectedLanguage),
      getValidData(utilityConsumptionInfo.previousGapFilled, selectedLanguage),
      getValidData(utilityConsumptionInfo.currentCarbonEmission, selectedLanguage),
      getValidData(utilityConsumptionInfo.scope, selectedLanguage),
    ]

    switch (utility) {
    case UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON :
      return [...commonAssetTableRowDataSet1, ...commonAssetTableRowDataSet2.slice(0,2),
        getValidData(utilityConsumptionInfo.utilityType, selectedLanguage),
        ...commonAssetTableRowDataSet2.slice(2), ...commonAssetTableRowDataSet3.slice(0,2),
        getValidData(utilityConsumptionInfo.scope, selectedLanguage),
        mappedAnnualCurrentConsumption(utilityConsumptionInfo.currentCarbonEmission, selectedLanguage, intl, 't_no_meter_data_available'),
        getValidData(utilityConsumptionInfo.currentGapFilled, selectedLanguage),
        getValidData(utilityConsumptionInfo.previousCarbonEmission, selectedLanguage),
        getValidData(utilityConsumptionInfo.previousGapFilled, selectedLanguage),
      ]
    case UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY :
      return [...commonAssetTableRowDataSet1, ...commonAssetTableRowDataSet2, ...commonAssetTableRowDataSet3]
    case UTILITY_LABELS_OBJECT_TITLE_CASE.WATER :
      return [...commonAssetTableRowDataSet1, ...commonAssetTableRowDataSet2, ...commonAssetTableRowDataSet3.slice(0,1),
        ...commonAssetTableRowDataSet3.slice(2,6)]
    case UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE :
      return [...commonAssetTableRowDataSet1,getValidData(utilityConsumptionInfo.wasteDestination, selectedLanguage),getValidData(utilityConsumptionInfo.wasteContractor, selectedLanguage),
        getValidData(utilityConsumptionInfo.wasteCollectionDescription, selectedLanguage),getValidData(utilityConsumptionInfo.reportingPeriod, selectedLanguage),
        getValidData(utilityConsumptionInfo?.hazardous, selectedLanguage),getValidData(utilityConsumptionInfo?.nonHazardous, selectedLanguage),getValidData(utilityConsumptionInfo?.tonnes, selectedLanguage),
        getValidData(utilityConsumptionInfo?.landfill, selectedLanguage),getValidData(utilityConsumptionInfo?.incinerated, selectedLanguage),getValidData(utilityConsumptionInfo?.reuse, selectedLanguage),
        getValidData(utilityConsumptionInfo?.incineratedWer, selectedLanguage),getValidData(utilityConsumptionInfo?.recycled, selectedLanguage),getValidData(utilityConsumptionInfo?.mrfUnknown, selectedLanguage)
      ]
    case UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY :
      return [...commonAssetTableRowDataSet1,
        getValidData(asset.floorArea, selectedLanguage),
        getValidData(utilityConsumptionInfo.utilityType, selectedLanguage),
        ...commonAssetTableRowDataSet2, ...commonAssetTableRowDataSet3]
    case UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL :
      return [...commonAssetTableRowDataSet1,
        ...commonAssetTableRowDataSet2.slice(0,2),
        getValidData(utilityConsumptionInfo.utilityType, selectedLanguage),
        ...commonAssetTableRowDataSet2.slice(2), ...commonAssetTableRowDataSet3
      ]
    }
  }
  return getAnnualByMeterPerformanceResponse(assetTableData, getAssetTableRowData, assetTableColumnNamesWithLocalization, utility)
}

function getAnnualByMeterPerformanceResponse(assetTableData, getAssetTableRowData, assetTableColumnNamesWithLocalization, utility){
  let assetTableRows = []
  let assetTableNumberOfRows = 0
  const rangeDataSelection = [] 
  const consumptionKeys = ['t_current_annual_consumption_includes_gap_filled_data', 't_current_total_carbon_emissions']
  if(assetTableData){
    assetTableData.forEach((asset) => {
      let utilityConsumptionInfoOfAssetArray = asset[UTILITY_MAP_FOR_PERFORMANCE_EXPORT[utility]]
      
      if(utilityConsumptionInfoOfAssetArray.length > 0){
        utilityConsumptionInfoOfAssetArray.forEach((utilityConsumptionInfo) => {
          assetTableNumberOfRows+=1
          let assetTableRowData = getAssetTableRowData(utilityConsumptionInfo, utility, asset)
          const getRangeSelection = annualRangeSelection(utilityConsumptionInfo.currentConsumption, assetTableRows.length, assetTableColumnNamesWithLocalization, consumptionKeys)
          if(getRangeSelection){
            rangeDataSelection.push(getRangeSelection)
          }
          assetTableRows.push(convertToExcelExportFormat(assetTableRowData))
        })
      }
    })
  }
  return {assetTableRows,assetTableNumberOfRows, rangeDataSelection}
}

function getAssetTableColumnNamesForMonthlyByMeterDataExport(utility) {
  let commonTableHeaders1 = [
    {key: 't_asset_ref'}, {key: 't_asset_name'}, {key: 't_fund'}, { key: 't_country'}, {key: 't_city'},
    {key: 't_sector'}, {key: 't_gresb_sector'}, { key: 't_purchase_date'},{key: 't_sale_date'}]
  let commonTableHeaders2 = [
    {key: 't_meter_name'}, {key: 't_serial_number'}, {key: 't_comment'}, { key: 't_area_covered'},{key: 't_procured_by'}, {key: 't_status'}, { key: 't_active_from'},
    {key: 't_active_to'}, {key: 't_is_this_a_submeter'}]
  switch (utility) {
  case UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON :
    return [...commonTableHeaders1,  {key: 't_meter_name'}, {key: 't_serial_number'},  {key: 't_utility_type'},...commonTableHeaders2.slice(2),
      {key: 't_floor_area_served',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.AREA, null, null)},
      {key: 't_green_tariff'}, {key: 't_reporting_period'},{key: 't_scope'},{key: 't_total_emission_excel', unit : getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
    ]

  case UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY :

    return  [...commonTableHeaders1, ...commonTableHeaders2,
      {key: 't_floor_area_served', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.AREA, null, null)},
      {key: 't_green_tariff'},{key: 't_reporting_period'},
      {key: 't_total_consumption',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)},
      {key: 't_reporting_period_gap_filler_data_percentage'},
      {key: 't_reporting_period_carbon_emissions',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_scope'}]

  case UTILITY_LABELS_OBJECT_TITLE_CASE.WATER :
    return  [...commonTableHeaders1, ...commonTableHeaders2,
      {key: 't_floor_area_served', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.AREA, null, null)},
      {key: 't_reporting_period'},
      {key: 't_total_consumption',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.WATER_CONSUMPTION, null, null)},
      {key: 't_reporting_period_gap_filler_data_percentage'}
    ]

  case UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE :
    return [...commonTableHeaders1,  {key: 't_waste_disposal_route_reference'},  {key: 't_contractor'},
      {key: 't_description'},{key: 't_reporting_period'},  {key: 't_month'},
      {key: 't_hazardous_waste', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_non_hazardous_waste', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_total_waste_generated', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_landfill_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_incinerated', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_reuse_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_waste_to_energy_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_recycled_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_unknown_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)}]

  case UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY :
    return  [...commonTableHeaders1,
      {key: 't_utility_type'},
      ...commonTableHeaders2,
      {key: 't_floor_area_served_by_meter',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.AREA, null, null)},
      {key: 't_green_tariff'}, {key: 't_reporting_period'},
      {key: 't_total_consumption',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)},
      {key: 't_reporting_period_gap_filler_data_percentage'},
      {key: 't_reporting_period_carbon_emissions',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_scope'}]

  case UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL :
    return  [...commonTableHeaders1,{key: 't_utility_type'},...commonTableHeaders2,
      {key: 't_floor_area_served', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.AREA, null, null)},
      {key: 't_green_tariff'}, {key: 't_reporting_period'},
      {key: 't_total_consumption',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)},
      {key: 't_reporting_period_gap_filler_data_percentage'},
      {key: 't_reporting_period_carbon_emissions',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_scope'}]

  }
}

function getAssetTableRowInfoForMonthlyByMeterDataExport(utility, assetTableData,selectedLanguage, timePeriod, pickerValue, assetTableColumnNamesWithLocalization, intl) {
  function getAssetTableRowData(utilityConsumptionInfo, utility,asset){

    let commonTableColumnValuesSet1 = [
      asset.assetReference,
      getValidData(asset.name, selectedLanguage, false, false),
      getValidData(asset.fundName, selectedLanguage, false, false),
      getValidData(asset.assetLocation, selectedLanguage),
      getValidData(asset.assetCity, selectedLanguage),
      getValidData(asset.sector, selectedLanguage),
      getValidData(asset.gresbSector, selectedLanguage),
      formatDateToDDMMYY(asset.assetPurchaseDate, selectedLanguage),
      formatDateToDDMMYY(asset.dateOfSale, selectedLanguage),
    ]

    let commonTableColumnValuesSet2 = [
      getValidData(utilityConsumptionInfo.meterName, selectedLanguage, false, false),
      getValidData(utilityConsumptionInfo.serialNumber, selectedLanguage),
      getValidData(utilityConsumptionInfo.meterComment, selectedLanguage, false, false),
      getValidData(utilityConsumptionInfo.areaCovered, selectedLanguage),
      getValidData(utilityConsumptionInfo.procuredBy, selectedLanguage),
      getValidData(utilityConsumptionInfo.meterStatus, selectedLanguage),
      getValidData(utilityConsumptionInfo.activeFrom, selectedLanguage),
      getValidData(utilityConsumptionInfo.activeTo, selectedLanguage),
      getValidData(utilityConsumptionInfo.isSubMeter, selectedLanguage),
    ]

    function mappedMonthlyConsumption(){
      return mappedConsumptionDataByMonthly(utilityConsumptionInfo, timePeriod, pickerValue, intl, 't_no_meter_data_available')
    }

    switch (utility) {
    case UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON:
      return [...commonTableColumnValuesSet1,
        getValidData(utilityConsumptionInfo.meterName, selectedLanguage),
        getValidData(utilityConsumptionInfo.serialNumber, selectedLanguage),
        getValidData(utilityConsumptionInfo.utilityType, selectedLanguage),
        ...commonTableColumnValuesSet2.slice(2),
        getValidData(utilityConsumptionInfo.nlaServed, selectedLanguage),
        getValidData(utilityConsumptionInfo.greenTariff, selectedLanguage),
        getValidData(utilityConsumptionInfo.reportingPeriod, selectedLanguage),
        getValidData(utilityConsumptionInfo.scope, selectedLanguage),

        ...mappedMonthlyConsumption(),
        getValidData(utilityConsumptionInfo.currentCarbonEmission, selectedLanguage)]

    case UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY:
      return [...commonTableColumnValuesSet1, ...commonTableColumnValuesSet2,
        getValidData(utilityConsumptionInfo.nlaServed, selectedLanguage),
        getValidData(utilityConsumptionInfo.greenTariff, selectedLanguage),
        getValidData(utilityConsumptionInfo.reportingPeriod, selectedLanguage),
        ...mappedMonthlyConsumption(),
        getValidData(utilityConsumptionInfo.currentConsumption, selectedLanguage),
        '-',
        getValidData(utilityConsumptionInfo.currentCarbonEmission, selectedLanguage),
        getValidData(utilityConsumptionInfo.scope, selectedLanguage),
      ]

    case UTILITY_LABELS_OBJECT_TITLE_CASE.WATER:
      return [...commonTableColumnValuesSet1, ...commonTableColumnValuesSet2,
        getValidData(utilityConsumptionInfo.nlaServed, selectedLanguage),
        getValidData(utilityConsumptionInfo.reportingPeriod, selectedLanguage),
        ...mappedMonthlyConsumption(),
        getValidData(utilityConsumptionInfo.currentConsumption, selectedLanguage),
        '-',]

    case UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE:
      return [...commonTableColumnValuesSet1,
        getValidData(utilityConsumptionInfo.wasteDestination, selectedLanguage),
        getValidData(utilityConsumptionInfo.wasteContractor, selectedLanguage),
        getValidData(utilityConsumptionInfo.wasteCollectionDescription, selectedLanguage),
        getValidData(utilityConsumptionInfo.reportingPeriod, selectedLanguage),
        getValidData(utilityConsumptionInfo.month, selectedLanguage),
        getValidData(utilityConsumptionInfo?.hazardous, selectedLanguage),
        getValidData(utilityConsumptionInfo?.nonHazardous, selectedLanguage),
        getValidData(utilityConsumptionInfo?.tonnes, selectedLanguage),
        getValidData(utilityConsumptionInfo?.landfill, selectedLanguage),
        getValidData(utilityConsumptionInfo?.incinerated, selectedLanguage),
        getValidData(utilityConsumptionInfo?.reuse, selectedLanguage),
        getValidData(utilityConsumptionInfo?.incineratedWer, selectedLanguage),
        getValidData(utilityConsumptionInfo?.recycled, selectedLanguage),
        getValidData(utilityConsumptionInfo?.mrfUnknown, selectedLanguage)
      ]

    case UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY:
      return [...commonTableColumnValuesSet1,
        getValidData(utilityConsumptionInfo.utilityType, selectedLanguage),
        ...commonTableColumnValuesSet2,
        getValidData(utilityConsumptionInfo.nlaServed, selectedLanguage),
        getValidData(utilityConsumptionInfo.greenTariff, selectedLanguage),
        getValidData(utilityConsumptionInfo.reportingPeriod, selectedLanguage),
        ...mappedMonthlyConsumption(),
        getValidData(utilityConsumptionInfo.currentConsumption, selectedLanguage),
        '-',
        getValidData(utilityConsumptionInfo.currentCarbonEmission, selectedLanguage),
        getValidData(utilityConsumptionInfo.scope, selectedLanguage)]

    case UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL:
      return [...commonTableColumnValuesSet1, getValidData(utilityConsumptionInfo.utilityType, selectedLanguage),
        ...commonTableColumnValuesSet2,getValidData(utilityConsumptionInfo.nlaServed, selectedLanguage),
        getValidData(utilityConsumptionInfo.greenTariff, selectedLanguage),
        getValidData(utilityConsumptionInfo.reportingPeriod, selectedLanguage),
        ...mappedMonthlyConsumption(),
        getValidData(utilityConsumptionInfo.currentConsumption, selectedLanguage),
        '-',
        getValidData(utilityConsumptionInfo.currentCarbonEmission, selectedLanguage),
        getValidData(utilityConsumptionInfo.scope, selectedLanguage),]
    }
  }

  // execution starts here...
  let assetTableNumberOfRows = 0
  let assetTableRows = []
  const rangeDataSelection = []

  if(assetTableData){
    assetTableData.forEach((asset) => {
      let utilityConsumptionInfoOfAssetArray = asset[UTILITY_MAP_FOR_PERFORMANCE_EXPORT[utility]]
      if(utilityConsumptionInfoOfAssetArray.length > 0) {
        utilityConsumptionInfoOfAssetArray.forEach(utilityConsumptionInfo => {
          assetTableNumberOfRows += 1
          let assetTableRowData = getAssetTableRowData(utilityConsumptionInfo, utility, asset)
          const getRangeSelection = rangeSelectionMonthly(utilityConsumptionInfo.monthlyConsumption, assetTableRows.length, assetTableColumnNamesWithLocalization)
          if(getRangeSelection){
            rangeDataSelection.push(getRangeSelection)
          }
          assetTableRows.push(convertToExcelExportFormat(assetTableRowData))
        })
      }
    })
  }
  return {assetTableRows,assetTableNumberOfRows, rangeDataSelection}
}

function getAnnualAssesPerformanceExcelHeader(flag, utility, getNumberOfMonths){
  if(flag){
    return getAssetTableColumnNamesForAnnualDataV2Export(utility, getNumberOfMonths)
  }
  return getAssetTableColumnNamesForAnnualDataExport(utility, getNumberOfMonths)
}

function getAnnualAssesPerformanceExcelData(utility,assetTableData, selectedLanguage, getNumberOfMonths, assetTableColumnNamesWithoutLocalization, intl, flag){
  if(flag){
    return getAssetTableRowInfoForAnnualDataV2Export(utility,assetTableData, selectedLanguage, getNumberOfMonths, assetTableColumnNamesWithoutLocalization, intl)
  }
  return getAssetTableRowInfoForAnnualDataExport(utility,assetTableData, selectedLanguage, getNumberOfMonths, assetTableColumnNamesWithoutLocalization, intl)
}

function getMonthlyAssesPerformanceExcelHeader(flag, utility, getNumberOfMonths){
  if(flag){
    return getAssetMonthlyPerformanceHeading(utility, getNumberOfMonths)
  }
  return getAssetTableColumnNamesForMonthlyDataExport(utility, getNumberOfMonths)
}

function getMonthlyAssesPerformanceExcelData(assetMonthlyProps, flag){
  if(flag){
    return getAssetMonthlyPerformanceData(assetMonthlyProps)
  }
  return getAssetTableRowInfoForMonthlyDataExport(assetMonthlyProps)
}

function getMeterAnnualPerformanceTableHeader(utility, featureFlag){
  if(featureFlag){
    return getColumnNameMeterAnnual(utility)
  }
  return getAssetTableColumnNamesForAnnualByMeterDataExport(utility)
}

function getMeterAnnualPerformanceTableData(utility,assetTableData, selectedLanguage, assetTableColumnNamesWithoutLocalization, intl, featureFlag){
  if(featureFlag) {
    return getTableDataMeterAnnual(utility,assetTableData, selectedLanguage, assetTableColumnNamesWithoutLocalization, intl)
  }
  return getAssetTableRowInfoForAnnualByMeterDataExport(utility,assetTableData, selectedLanguage, assetTableColumnNamesWithoutLocalization, intl)
}

function getMeterMonthlyPerformanceTableHeader(utility, featureFlag){
  if(featureFlag){
    return getMeterMonthlyExcelHeader(utility)
  }
  return getAssetTableColumnNamesForMonthlyByMeterDataExport(utility)
}

function getMeterMonthlyPerformanceTableData({utility,assetTableData, selectedLanguage, timePeriod, pickerValue, assetTableColumnNamesWithLocalization, intl, featureFlag, type}){
  if(featureFlag || type === 2) {
    return getMeterMonthlyExcelData(utility,assetTableData, selectedLanguage, timePeriod, pickerValue, assetTableColumnNamesWithLocalization, intl)
  }
  return getAssetTableRowInfoForMonthlyByMeterDataExport(utility,assetTableData, selectedLanguage, timePeriod, pickerValue, assetTableColumnNamesWithLocalization, intl)
}

function getAssetTableInformation({assetPerformanceExportData,selectedTimePeriodGranularity,selectedLevelOfDetail,utility,intl,selectedLanguage, props, featureState, type}){
  let assetTableData = assetPerformanceExportData?.data?.assetDetails
  const getNumberOfMonths = props?.exportDetails?.noOfMonths ? props?.exportDetails?.noOfMonths : props?.exportDetails?.pickerValue?.pickerValueMonths
  let assetTableColumnNamesWithLocalization, assetTableRowInfo
  const HAS_FEATURE_FLAG = hasFeatureFlag(featureState)
  if(selectedTimePeriodGranularity === TIME_PERIOD_GRANULARITY.ANNUAL){
    if(selectedLevelOfDetail === LEVEL_OF_DETAILS.asset){
      let assetTableColumnNamesWithoutLocalization = getAnnualAssesPerformanceExcelHeader(HAS_FEATURE_FLAG, utility, getNumberOfMonths)
      assetTableColumnNamesWithLocalization =  getLocalizedColumnNamesWithUnits(assetTableColumnNamesWithoutLocalization, intl)
      assetTableRowInfo = getAnnualAssesPerformanceExcelData(utility,assetTableData, selectedLanguage, getNumberOfMonths, assetTableColumnNamesWithoutLocalization, intl, HAS_FEATURE_FLAG)
    }else{
      let assetTableColumnNamesWithoutLocalization = getMeterAnnualPerformanceTableHeader(utility, HAS_FEATURE_FLAG)
      assetTableColumnNamesWithLocalization =  getLocalizedColumnNamesWithUnits(assetTableColumnNamesWithoutLocalization, intl)
      assetTableRowInfo = getMeterAnnualPerformanceTableData(utility,assetTableData, selectedLanguage, assetTableColumnNamesWithoutLocalization, intl, HAS_FEATURE_FLAG)
    }
  }else if(selectedLevelOfDetail === LEVEL_OF_DETAILS.asset){
    let assetTableColumnNamesWithoutLocalizationAndMonthColumns = getMonthlyAssesPerformanceExcelHeader(HAS_FEATURE_FLAG, utility, getNumberOfMonths)
    let assetTableColumnNamesLocalizedWithoutMonthColumns =  getLocalizedColumnNamesWithUnits(assetTableColumnNamesWithoutLocalizationAndMonthColumns, intl)
    assetTableColumnNamesWithLocalization = attachMonthColumnsToAssetTable(utility,assetTableColumnNamesLocalizedWithoutMonthColumns, selectedLanguage, props?.exportDetails?.timePeriod,props?.exportDetails?.pickerValue,selectedLevelOfDetail)
    const assetMonthlyProps = {
      utility, 
      assetTableData, 
      selectedLanguage, 
      timePeriod: props?.exportDetails?.timePeriod, 
      pickerValue: props?.exportDetails?.pickerValue, 
      getNumberOfMonths, 
      assetTableColumnNamesWithLocalization, 
      intl
    }
    assetTableRowInfo = getMonthlyAssesPerformanceExcelData(assetMonthlyProps, HAS_FEATURE_FLAG)
  }else{
    let assetTableColumnNamesWithoutLocalizationAndMonthColumns = getMeterMonthlyPerformanceTableHeader(utility, HAS_FEATURE_FLAG)
    let assetTableColumnNamesLocalizedWithoutMonthColumns = getLocalizedColumnNamesWithUnits(assetTableColumnNamesWithoutLocalizationAndMonthColumns,intl)
    assetTableColumnNamesWithLocalization = attachMonthColumnsToAssetTable(utility,assetTableColumnNamesLocalizedWithoutMonthColumns, selectedLanguage, props?.exportDetails?.timePeriod || props?.exportDetails?.pickerValue?.timePeriodSelected,props?.exportDetails?.pickerValue,selectedLevelOfDetail)
    const meterParams = { 
      utility,
      assetTableData, 
      selectedLanguage, 
      timePeriod: props?.exportDetails?.timePeriod ? props?.exportDetails?.timePeriod : props?.exportDetails?.pickerValue?.timePeriodSelected, 
      pickerValue: props?.exportDetails?.pickerValue ?  props?.exportDetails?.pickerValue : props?.pickerValue, 
      assetTableColumnNamesWithLocalization, 
      intl, 
      featureFlag: HAS_FEATURE_FLAG,
      type
    }
    assetTableRowInfo = getMeterMonthlyPerformanceTableData(meterParams)
  }
  
  return {
    assetTableColumnNamesWithLocalization,
    assetTableRowData : assetTableRowInfo?.assetTableRows,
    assetTableNumberOfColumns: assetTableColumnNamesWithLocalization.length,
    assetTableNumberOfRows: assetTableRowInfo.assetTableNumberOfRows,
    rangeDataSelection: assetTableRowInfo?.rangeDataSelection || [], 
  }
}



export async function exportAssetHomePerformanceExcel(params) {
  const { props, getFilterSelectionValue,selectedLanguage,assetHomeExportSelections,assetPerformanceExportData,intl, featureState, type } = params
  function populateDataForExcelExport(utility, selectedTimePeriodGranularity,selectedLevelOfDetail){

    let excelDataArray = []
    let mergeRange = []
    let dateInfo = getDateRangeAndTimePeriodInfo(props?.exportDetails?.timePeriod || props?.exportDetails?.pickerValue.timePeriodSelected, props?.exportDetails?.pickerValue, selectedLanguage)
    excelDataArray.push(...getSheetHeaderInformation(utility, dateInfo, getFilterSelectionValue, selectedLanguage, assetHomeExportSelections, type))
    excelDataArray.push(...getEmptyRows(2))
    if (type === 2) {
      excelDataArray.push(...getEmptyRows(1))
    }
    let table1Title = getSummaryTableTitle(utility)
    excelDataArray.push(getTitleObject(table1Title, selectedLanguage))
    let summaryTableData = assetPerformanceExportData?.data?.allUtilitySummary?.[UTILITY_MAP_FOR_PERFORMANCE_EXPORT[utility]]
    let summaryTableColumnNamesWithoutLocalization = getSummaryTableColumnNames(utility,summaryTableData)
    let summaryTableColumnNamesWithLocalization = getLocalizedColumnNamesWithUnits(summaryTableColumnNamesWithoutLocalization, intl)
    excelDataArray.push(convertToExcelExportFormat(summaryTableColumnNamesWithLocalization))
    let summaryTableDataArray = getSummaryTableRowInfo(utility, summaryTableData, selectedLanguage)

    excelDataArray.push(getTableHeadersForExcelObject(summaryTableDataArray))
    excelDataArray.push(...getEmptyRows(2))
    excelDataArray.push(getTitleObject('t_asset_information', selectedLanguage))
    let assetTableInformation = getAssetTableInformation({assetPerformanceExportData,selectedTimePeriodGranularity,selectedLevelOfDetail,utility,intl,selectedLanguage, props, featureState, type})
    excelDataArray.push(convertToExcelExportFormat(assetTableInformation.assetTableColumnNamesWithLocalization))
    if(Array.isArray(assetTableInformation?.rangeDataSelection) && assetTableInformation.rangeDataSelection.length){
      mergeRange =  assetTableInformation.rangeDataSelection.map(item => {
        const rowNumber = (excelDataArray.length + item.index) + 1
        return `${item.start}${rowNumber}:${item.end}${rowNumber}`
      })
    }
    excelDataArray.push(...assetTableInformation.assetTableRowData)
    return {
      excelDataArray,
      summaryTableNumberOfColumns: summaryTableColumnNamesWithoutLocalization.length,
      summaryTableNumberOfRows: 1,
      assetTableNumberOfColumns: assetTableInformation.assetTableColumnNamesWithLocalization.length,
      assetTableNumberOfRows: assetTableInformation.assetTableNumberOfRows,
      mergeRange,
    }
  }

  const rangeObject = {
    [`${LEVEL_OF_DETAILS.asset}-${TIME_PERIOD_GRANULARITY.ANNUAL}`] : {[UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY]: {range_including: 'M16:P16', range_excluding: 'Q16:T16' },
      [UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY]: {range_including: 'M16:P16', range_excluding: 'Q16:T16' },
      [UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL]: {range_including: 'N16:Q16', range_excluding: 'R16:U16' },
      [UTILITY_LABELS_OBJECT_TITLE_CASE.WATER]: {range_including: 'M16:N16', range_excluding: 'O16:P16' },
      [UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE]: {range_including: 'N16:P16', range_excluding: 'Q16:V16' },
      [UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON]: {range_including: 'N16:Q16', range_excluding: 'R16:U16' }},
    [`${LEVEL_OF_DETAILS.asset}-${TIME_PERIOD_GRANULARITY.MONTHLY}`]: {[UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY]: {range_including: 'AB16:AC16', range_excluding: 'N16:AA16' },
      [UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY]: {range_including: 'AB16:AC16', range_excluding: 'N16:AA16' },
      [UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL]: {range_including: 'AC16:AD16', range_excluding: 'N16:AB16' },
      [UTILITY_LABELS_OBJECT_TITLE_CASE.WATER]: {range_including: 'AA16:AA16', range_excluding: 'N16:Z16' },
      [UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE]: {range_including: 'O16:Q16', range_excluding: 'R16:W16' },
      [UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON]: {range_including: 'AD16:AG16', range_excluding: 'N16:AC16' }},
    [`${LEVEL_OF_DETAILS.meter}-${TIME_PERIOD_GRANULARITY.ANNUAL}`] : {[UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY]: {range_including: 'W16:Z16', range_excluding: 'AA16:AD16' },
      [UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY]: {range_including: 'U16:X16', range_excluding: 'Y16:AB16' },
      [UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL]: {range_including: 'V16:Y16', range_excluding: 'Z16:AC16' },
      [UTILITY_LABELS_OBJECT_TITLE_CASE.WATER]: {range_including: 'T16:U16', range_excluding: 'V16:W16' },
      [UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE]: {range_including: 'O16:Q16', range_excluding: 'R16:V16' },
      [UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON]: {range_including: 'W16:X16', range_excluding: 'Y16:Z16' }},
    [`${LEVEL_OF_DETAILS.meter}-${TIME_PERIOD_GRANULARITY.MONTHLY}`]: { 
      [UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY]: {range_including: 'AK16:AL16', range_excluding: 'W16:AJ16' },
      [UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY]: {range_including: 'AJ16:AK16', range_excluding: 'V16:AI16' },
      [UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL]: {range_including: 'AK16:AL16', range_excluding: 'W16:AJ16' },
      [UTILITY_LABELS_OBJECT_TITLE_CASE.WATER]: {range_including: 'AH16:AH16', range_excluding: 'U16:AG16' },
      [UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE]: {range_including: 'P16:R16', range_excluding: 'S16:W16' },
      [UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON]: {range_including: 'AK16:AK16', range_excluding: 'X16:AJ16' },
    }
  }

  const mergeColumnsIncludingAndExcluding = (excelSheet, selectedLevelOfDetail, selectedTimePeriodGranularity, sheet) => {
    const styleObject = { horizontalAlignment: 'center', border: true }
    if (hasFeatureFlag(featureState)) {
      if (sheet.value !== UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE) {
        excelSheet.range(rangeObject[`${selectedLevelOfDetail}-${selectedTimePeriodGranularity}`][sheet.value].range_including).value(getLocalizedValue(selectedLanguage, 't_including_gap_filled')).merged(true).style(styleObject)
        excelSheet.range(rangeObject[`${selectedLevelOfDetail}-${selectedTimePeriodGranularity}`][sheet.value].range_excluding).value(getLocalizedValue(selectedLanguage, 't_excluding_gap_filled')).merged(true).style(styleObject)
      } else {
        excelSheet.range(rangeObject[`${selectedLevelOfDetail}-${selectedTimePeriodGranularity}`][sheet.value].range_including).value(getLocalizedValue(selectedLanguage, 't_waste_generated')).merged(true).style(styleObject)
        excelSheet.range(rangeObject[`${selectedLevelOfDetail}-${selectedTimePeriodGranularity}`][sheet.value].range_excluding).value(getLocalizedValue(selectedLanguage, 't_destination')).merged(true).style(styleObject)
      }
    }
  }

  async function generateAssetHomePerformanceExcel(utilityArray){
    let selectedTimePeriodGranularity = type === 1 ? assetHomeExportSelections.asset_home_performance_excel_export.time_period_granularity.value : assetHomeExportSelections.asset_performance_all_utility_excel_export.time_period_granularity.value
    let selectedLevelOfDetail = type === 1 ? assetHomeExportSelections.asset_home_performance_excel_export.level_of_detail.value : assetHomeExportSelections.asset_performance_all_utility_excel_export.level_of_detail.value
    const blob = await XlsxPopulate.fromBlankAsync()
      .then(workbook => {
        utilityArray.forEach((sheet, index) => {
          const sheetName = getSheetName(sheet.value, selectedLanguage)
          const excelSheet = index === 0 ? workbook.sheet(0).name(sheetName) : workbook.addSheet(sheetName)
          let assetPerformanceExportDataInformation = 
          populateDataForExcelExport(sheet.value,selectedTimePeriodGranularity,selectedLevelOfDetail)
          const noDataMessage = selectedLevelOfDetail === LEVEL_OF_DETAILS.asset ? 't_no_export_data_available' : 't_no_meter_data_available'
          const errorMesage = getLocalizedValue(selectedLanguage, noDataMessage)
          if(!assetPerformanceExportDataInformation.assetTableNumberOfRows){
            assetPerformanceExportDataInformation.excelDataArray.push({A: errorMesage})
          }
          mergeColumnsIncludingAndExcluding(excelSheet, selectedLevelOfDetail, selectedTimePeriodGranularity, sheet)
          
          excelHelpers(assetPerformanceExportDataInformation.excelDataArray, excelSheet, errorMesage, selectedLanguage)
        })
        workbook.activeSheet(getSheetName(utilityArray[0].value, selectedLanguage))
        return workbook.outputAsync(workbook)
      })
    FileSaver.saveAs(blob, getLocalizedValue(selectedLanguage, 't_asset_performance_excel_file_name'))
  }

  // execution begins here...
  let selectedExportType = type === 1 ? assetHomeExportSelections[EXPORT_TYPES.ASSET.ASSET_HOME_PERFORMANCE_EXCEL_EXPORT].utility.value
    : assetHomeExportSelections[EXPORT_TYPES.ASSET.ASSET_PERFORMANCE_ALL_UTILITY_EXCEL_EXPORT].utility.value
  let utilityArray
  if (selectedExportType === UTILITY_OTHER_NAME_MAP.All) {
    utilityArray = utilitiesListForPerformanceExport(selectedLanguage)
    utilityArray.unshift({label: getLocalizedValue(selectedLanguage, 't_energy_summary'),value: UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY})
    await generateAssetHomePerformanceExcel(utilityArray)
  } else {
    utilityArray = [type === 1 ? assetHomeExportSelections[EXPORT_TYPES.ASSET.ASSET_HOME_PERFORMANCE_EXCEL_EXPORT].utility : assetHomeExportSelections[EXPORT_TYPES.ASSET.ASSET_PERFORMANCE_ALL_UTILITY_EXCEL_EXPORT].utility]
    await generateAssetHomePerformanceExcel(utilityArray)
  }
}
