import {Skeleton} from 'primereact/skeleton'
import './PortfolioAssetsTableSkeleton.scss'

const PortfolioAssetsTableSkeleton = () => {

  function getSkeletonRow() {
    const bodyRow=(
      <div className="pf-sk__body__row flex align-items-center justify-content-center">
        <div className="sk-pf-wrapper wp-asset-ref flex align-items-center justify-content-center"><Skeleton width="182px" borderRadius="0" height="17px" className="sk-asset-ref"/></div>
        <div className="sk-pf-wrapper asset-badge flex align-items-center justify-content-center"><Skeleton width="251px" borderRadius="0" height="17px" className="sk-empty"/></div>
        <div className="sk-pf-wrapper wp-carbon flex align-items-center justify-content-center"><Skeleton width="142px" borderRadius="0" height="17px"/></div>
        <div className="sk-pf-wrapper wp-electricity flex align-items-center justify-content-center"><Skeleton width="68px" borderRadius="0" height="17px"/></div>
        <div className="sk-pf-wrapper wp-gas-fuels flex align-items-center justify-content-center"><Skeleton width="150px" borderRadius="0" height="17px"/></div>
        <div className="sk-pf-wrapper wp-water flex align-items-center justify-content-center"><Skeleton width="39px" borderRadius="0" height="17px"/></div>
        <div className="sk-pf-wrapper wp-waste flex align-items-center justify-content-center"><Skeleton width="109px" borderRadius="0" height="17px"/></div>
      </div>
    )

    return new Array(10).fill(0).map(()=> bodyRow)
  }

  return (
    <div className="pf-sk">
      <div className="pf-sk__header flex align-items-center justify-content-center">
        <div className="sk-pf-wrapper wp-asset-ref flex align-items-center justify-content-center"><Skeleton width="182px" borderRadius="0" height="17px" className="sk-asset-ref"/></div>
        <div className="sk-pf-wrapper asset-badge flex align-items-center justify-content-center"><Skeleton width="251px" borderRadius="0" height="17px" className="sk-empty"/></div>
        <div className="sk-pf-wrapper wp-carbon flex align-items-center justify-content-center"><Skeleton width="142px" borderRadius="0" height="17px"/></div>
        <div className="sk-pf-wrapper wp-electricity flex align-items-center justify-content-center"><Skeleton width="68px" borderRadius="0" height="17px"/></div>
        <div className="sk-pf-wrapper wp-gas-fuels flex align-items-center justify-content-center"><Skeleton width="150px" borderRadius="0" height="17px"/></div>
        <div className="sk-pf-wrapper wp-water flex align-items-center justify-content-center"><Skeleton width="39px" borderRadius="0" height="17px"/></div>
        <div className="sk-pf-wrapper wp-waste flex align-items-center justify-content-center"><Skeleton width="109px" borderRadius="0" height="17px"/></div>
      </div>
      <div className="pf-sk__body">{getSkeletonRow()}</div>
    </div>

  )
}

export default PortfolioAssetsTableSkeleton




