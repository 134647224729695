import React, { useEffect, useState, useRef } from 'react'
import './FundReportFilterHeader.scss'
import {Dropdown} from 'primereact/dropdown'
import { MultiSelect } from 'primereact/multiselect' 
import PropTypes from 'prop-types'
import { getGroupedPAIs, mandatoryPAIs, reportFrameworkOptions } from '../mappings'
import { Button } from 'components/atomic'
import I18n, { useI18n } from 'utils/i18n/I18n'
import { GetFundReportOptionalMetrics, UpdateFundReportOptionalMetrics } from 'services/funds/fund-reports-service'
import useFundIds from 'utils/custom-hooks/useFundIds'
import { useQuery, useMutation } from 'react-query'
import { noRefetchOnWindowFocus } from 'services/common/useQuery-config'

const FundReportFilterHeader = ({updateReportingFramework, framework, setRefetchTimeStamp}) => { 
  const { fundId, fundGroupID } = useFundIds()
  const multiSelect = useRef(null)
  const {
    isLoading: optionalMetricsLoading,
    data: optionalMetricsData,
    refetch: refetchOptionalMetrics
  } = useQuery({
    queryKey: ['GetFundReportOptionalMetrics', fundGroupID],
    queryFn: GetFundReportOptionalMetrics,
    refetchOnWindowFocus: noRefetchOnWindowFocus.refetchOnWindowFocus
  })
  const updateOptionalMetricsMutation = useMutation(UpdateFundReportOptionalMetrics, {
    onSuccess: () => {
      refetchOptionalMetrics()
      setRefetchTimeStamp(Date.now())
    }
  })
  const [optionalPAIs, setOptionalPAIs] = useState([])
  const [temporarySelectedPAIs, setTemporarySelectedPAIs] = useState([])
  const optionalMetricsArray = optionalMetricsData
    ? Object.keys(optionalMetricsData).filter(key => typeof optionalMetricsData[key] === 'boolean' && optionalMetricsData[key])
    : []

  const intl = useI18n()
  const noOptionalPAISelected = !temporarySelectedPAIs || temporarySelectedPAIs.length <= 2

  useEffect(() => {
    if (optionalMetricsArray && optionalMetricsArray.length > 0) {
      setOptionalPAIs(optionalMetricsArray)
      setTemporarySelectedPAIs([...mandatoryPAIs, ...optionalMetricsArray])
    } else {
      setOptionalPAIs([])
      setTemporarySelectedPAIs([...mandatoryPAIs])
    }
  }, [optionalMetricsData])

  const onReportChange = (e) => {
    updateReportingFramework(e.value)
  }

  const onHide = () => {
    setTemporarySelectedPAIs([...mandatoryPAIs, ...optionalPAIs])
  }

  const updateOptionalMetrics = async (newMetricsObject) => {
    if (updateOptionalMetricsMutation.isLoading) {
      return
    }
    await updateOptionalMetricsMutation.mutateAsync({requestData: newMetricsObject, fundId: fundId})
  }

  const createOptionalMetricsObject = () => {
    const updatedOptionalMetrics = { ...optionalMetricsData }
    const savedOptionalPAIs = temporarySelectedPAIs.filter(pai => !mandatoryPAIs.includes(pai))
    Object.keys(updatedOptionalMetrics).forEach(key => {
      if (savedOptionalPAIs.includes(key)) {
        updatedOptionalMetrics[key] = true
      } else if (typeof updatedOptionalMetrics[key] === 'boolean') {
        updatedOptionalMetrics[key] = false
      }
    })
    delete updatedOptionalMetrics.fundPaiOptionalMetricsId
    return updatedOptionalMetrics
  }

  const onSavePAIClick = async () => {
    try {
      const updatedOptionalMetrics = createOptionalMetricsObject()
      await updateOptionalMetrics(updatedOptionalMetrics)
    } catch (error) {
      console.log(error)
    }
  }

  const optionGroupTemplate = (option, index) => {
    const labelClassName = noOptionalPAISelected
      ? 'item-group-label item-group-label--error'
      : 'item-group-label'
  
    const labelTestId = `pai-option-group-label-${index}`
  
    return (
      <div className={labelClassName} data-testid={labelTestId}>
        <span>{option.label}</span>
      </div>
    )
  }

  const footerTemplate = (props, hide) => {
    return (
      <div className="multiselect-footer">
        <Button
          content='t_save'
          size='small'
          onClick={() => {
            onSavePAIClick()
            hide()
          }}
          disabled={noOptionalPAISelected}
          testId='save-pai-selection-button'
        />
      </div>
    )
  }

  return (
    <div className='fundReportFilterSection' data-testid='fund-report-filter-header'>
      <Dropdown
        appendTo="self"
        panelClassName="framework-dropdown-panel"
        options={reportFrameworkOptions}
        value={framework}
        className={'fundReportFilterSection__dropdown fundReportFilterSection__dropdown--framework'}
        onChange={onReportChange}
        data-testid='framework-dropdown'
      />
      <MultiSelect
        ref={multiSelect}
        value={temporarySelectedPAIs}
        options={getGroupedPAIs(intl.formatMessage)}
        onChange={(e) => {
          multiSelect.current.show()
          e.stopPropagation()
          setTemporarySelectedPAIs(e.value)
        }}
        optionLabel="label"
        optionGroupLabel="label"
        optionGroupChildren="items"
        panelFooterTemplate={footerTemplate}
        optionGroupTemplate={optionGroupTemplate}
        placeholder={I18n('t_pai_selection')}
        fixedPlaceholder={true}
        className='fundReportFilterSection__multiselect'
        panelClassName='pai-selection-multiselect-panel'
        onHide={onHide}
        data-testid='pai-selection-multiselect'
        disabled={optionalMetricsLoading || !optionalMetricsArray}
      />
    </div>
  )
}

FundReportFilterHeader.propTypes = {
  updateReportingFramework: PropTypes.func.isRequired,
  framework: PropTypes.oneOf(['sfdr']).isRequired,
  setRefetchTimeStamp: PropTypes.func.isRequired,
  optionalMetricsData: PropTypes.shape({
    fundPaiOptionalMetricsId: PropTypes.number,
    fundId: PropTypes.number,
    greenhouseGasEmissions: PropTypes.bool,
    energyConsumption: PropTypes.bool,
    waste: PropTypes.bool,
    resourceConsumption: PropTypes.bool,
    biodiversity: PropTypes.bool,
  }),
}

export default FundReportFilterHeader
FundReportFilterHeader.displayName = 'FundReportFilterHeader'