import moment from 'moment'
import { UNITS } from '../i18n/constants'
import { getLocalStorageItem } from './Utils'
import { DD_MM_YYYY, MM_DD_YYYY } from './Constants'

export const formatDateToDdMonYy = () => {
  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ]
  const currentDate = new Date()
  const day = currentDate.getDate().toString().padStart(2, '0')
  const month = months[currentDate.getMonth()]
  const year = currentDate.getFullYear().toString().slice(-2)

  return `${day} ${month} ${year}`
}

export const momentDateForExport = (date, format, selectedLanguage) => {
  let requiredDate = moment(date).locale(selectedLanguage).format(format)
  return requiredDate !== 'Invalid date' ? requiredDate : 'null'
}

export function formatDateToDDMMYY(value, selectedLanguage) {
  if(!value) {
    return '-'
  }
  const dateFormatType = getLocalStorageItem('unitSystem') === UNITS.IMPERIAL ? MM_DD_YYYY : DD_MM_YYYY
  return momentDateForExport(value, dateFormatType, selectedLanguage)
}

export const calendarDateFormat = () => {
  const getUnitSystem = getLocalStorageItem('unitSystem')
  return getUnitSystem === UNITS.IMPERIAL ? 'M dd yy' : 'dd M yy'
}

export function formatDate(value, selectedLanguage) {
  if(!value) {
    return '-'
  }
  const getUnitSystem = getLocalStorageItem('unitSystem')
  let format = getUnitSystem === UNITS.IMPERIAL ? 'MMM D yy' : 'D MMM yy'
  return moment(value).locale(selectedLanguage).format(format)
}

export function formatDateRange(startDate, endDate) {
  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ]

  const formatSingleDate = (date) => {
    const day = date.getDate().toString()
    const month = months[date.getMonth()]
    const year = date.getFullYear().toString().slice(-2)
    return { day, month, year }
  }

  const start = new Date(startDate)
  const end = new Date(endDate)

  const startFormatted = formatSingleDate(start)
  const endFormatted = formatSingleDate(end)

  if (startFormatted.month === endFormatted.month && startFormatted.year === endFormatted.year) {
    return `${startFormatted.day} - ${endFormatted.day} ${startFormatted.month} ${startFormatted.year}`
  } else if (startFormatted.year === endFormatted.year) {
    return `${startFormatted.day} ${startFormatted.month} - ${endFormatted.day} ${endFormatted.month} ${startFormatted.year}`
  } else {
    return `${startFormatted.day} ${startFormatted.month} ${startFormatted.year} - ${endFormatted.day} ${endFormatted.month} ${endFormatted.year}`
  }
}