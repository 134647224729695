import sieraLogo from '../../../../resources/images/ExportPdf/siera-logo.png'
import {View, StyleSheet, Image, Text, Font} from '@react-pdf/renderer'
import React from 'react'
import CSS_VARIABLES from '../../../../resources/css/_variables.scss'
import atkinsonHyperlegibleRegular from '../../../../resources/fonts/font-atkinson-hyperlegible/AtkinsonHyperlegible-Regular.ttf'
import {ATKINSON_HYPERLEGIBLE_REGULAR} from '../../../helpers/Constants'

Font.register({family: ATKINSON_HYPERLEGIBLE_REGULAR, src: atkinsonHyperlegibleRegular, fontStyle: 'normal'})
const styles = StyleSheet.create({
  logo: {
    width: 102,
    height: 29,
  },
  text:{
    color: CSS_VARIABLES.forest,
    fontFamily: ATKINSON_HYPERLEGIBLE_REGULAR,
    fontSize:11,
    top:5,
    left:26
  }
})

const PdfLogo = (props) => {
  return (
    <View fixed>
      <Image src={sieraLogo} style={styles.logo} />
      <Text style={styles.text}>
        {props.header}
      </Text>
    </View>
  )
}

export default PdfLogo