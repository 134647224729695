import React, {useEffect, useState} from 'react'
import './AssetForm.scss'
import {InputText} from 'primereact/inputtext'
import {Dropdown} from 'primereact/dropdown'
import {Checkbox} from 'primereact/checkbox'
import {RadioButton} from 'primereact/radiobutton'
import {FileUpload} from 'primereact/fileupload'
import {InputNumber} from 'primereact/inputnumber'
import {SelectButton} from 'primereact/selectbutton'
import {Formik} from 'formik'
import {MapConfig} from '../../../../../services/common/map-config'
import * as Yup from 'yup'
import AssetFooter from '../asset-footer/AssetFooter'
import QuestionMarkIcon from '../../../../../resources/images/icon/question-mark.svg'
import {useMutation, useQuery} from 'react-query'
import {AddAsset, getFunds} from '../../../../../services/admin/epc-service'
import {MeasurementUnits} from '../../../../../utils/helpers/Constants'
import I18n from '../../../../../utils/i18n/I18n'
import ErrorDialog from '../../../assets/data-quality/error-dialog/ErrorDialog'
import {noRefetchOnWindowFocus} from '../../../../../services/common/useQuery-config'
import {getLocalizedValue} from '../../../../../utils/helpers/Helper'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const AssetForm = (props) => {
  const mandatoryChar = '*'
  const chooseOptions = {label:  <u>{I18n('t_upload_select_or_drop_file')}</u>, icon: ' '}
  const uploadOptions = {label: <u>Upload</u>, icon: ' ', className: ' '}
  const [selectedFile, setSelectedFile]=useState(null)
  const [floorAreaUnits, setFloorAreaUnits] = useState('m²')
  const addressInputId = 'findAddress'
  const mapId = 'find-address-map'
  const messageRequired='Required'
  const floorAreaUnitsOptions = ['m²', 'ft²']
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference

  const newAssetMutation = useMutation(AddAsset)
  const MSG_KEY_TITLE_CANNOT_ADD_ASSET = 't_title_cannot_add_asset'
  const [errorDialogVisible, setErrorDialogVisible] = useState(false)
  const [errorDialogData, setErrorDialogData] = useState()
  const [funds, setFunds]     = useState([])
  const [sectors, setSectors]     = useState([])

  // For API Queries.
  const getFundsQuery  = useQuery(['assetFunds'], getFunds, noRefetchOnWindowFocus)

  useEffect(() => {
    MapConfig(addressInputId, mapId)
  })

  useEffect(()=>{
    loadFundsToMenu(getFundsQuery.data)
    loadSectorsToMenu()
  }, [getFundsQuery.data])

  const loadFundsToMenu=(funds)=> {
    if(funds){
      let assetFunds=funds.map((fund)=>{
        return {label: fund.fundName, value: fund.fundId}
      })
      setFunds(assetFunds)
    }
  }

  function loadSectorsToMenu() {
    const sectors = ['Other', 'Retail', 'Office', 'Industrial', 'Residential', 'Hotel', 'LodgingLeisureRecreation', 'Education', 'TechnologyScience', 'Healthcare', 'MixedUseOffice']
    let sectorsList = sectors.map((sector) => {
      return {label: I18n('t_' + sector.toLowerCase()), value: sector}
    })
    setSectors(sectorsList)
  }

  // Formik properties.
  const initialValues= {
    // Left column fields.
    assetName: '',
    assetReference: '',
    findAddress: '',
    numberIdentifier: null,
    street: '',
    city: '',
    county: '',
    country: '',
    postcode: '',
    // Right column fields.
    fundId: '',
    sector: '',
    currentAssetValue: null,
    currencyGAV: '£GBP',
    fri: true,  //Full repairing and insuring lease *
    gia: null,
    nla: null,
    cpa: null,
    ext: null,
    unitsYesNo: 'No',
    measurementType: ''
  }

  const validationSchema=Yup.object({
    assetName: Yup.string().required(messageRequired),
    assetReference: Yup.string().required(messageRequired),
    numberIdentifier: Yup.string().required(messageRequired),
    street: Yup.string().required(messageRequired),
    city: Yup.string().required(messageRequired),
    country: Yup.string().required(messageRequired),
    postcode: Yup.string().required(messageRequired),
    // Right column fields.
    fundId: Yup.string().required(messageRequired),
    sector: Yup.string().required(messageRequired),
    nla: Yup.number().required(messageRequired).min(1, 'NLA values cannot be less than 1')
      .nullable()
  })

  function createAssetObject(asset) {
    // TODO: Actual implementations for creating new Asset object.
    asset.measurementType = (floorAreaUnits === floorAreaUnitsOptions[0]) ? MeasurementUnits.M2 : MeasurementUnits.FT2
    return asset
  }

  function onSubmit(values) {
    // TODO: Actual implementations for form submission.
    if(values){
      const newAssetEPCObj=createAssetObject(values)
      submitNewAsset(newAssetEPCObj)
    }
  }

  function dlgCloseHandler() {
    setErrorDialogVisible(false)
  }

  const submitNewAsset = async (newAssetEPC) => {
    try {
      await newAssetMutation.mutateAsync(newAssetEPC)
    } catch (error) {
      setErrorDialogData({title: I18n(MSG_KEY_TITLE_CANNOT_ADD_ASSET), message: error.toString()})
      setErrorDialogVisible(true)
    }
  }

  function isInputError(formik, fieldName){
    return (formik.touched[fieldName] && formik.errors[fieldName])
  }

  // File upload custom template
  function setFieldValueAsset(evt, fieldName, formik, ){
    formik.setFieldValue(fieldName, evt.value)
  }

  function onRemoveHandler(evt) {
    setSelectedFile(null)
  }

  function onSelectHandler(evt) {
    setSelectedFile(evt.files[0])
  }

  function getEmptyTemplate() {
    // return (
    //   <>
    //     <div>or drop a file here (JPG)</div>
    //   </>
    // );

    return ''
  }

  function getItemTemplate(file, props){
    return (
      <div className={'selected-file-detials'}>
        <div className={'file-name'}>{file.name}</div>
        <div className={'file-size'}>{props.formatSize}</div>
      </div>
    )
  }

  function getHeaderTemplate(options){
    const { className, chooseButton, uploadButton, cancelButton } = options

    return (
      <div className={'file-chooser-container'}>
        <div className={'asset-img-upload-title'}>{I18n('t_upload_image')}</div>
        <span className={`${selectedFile?'hide-upload-option':''}`}>{chooseButton}</span>
        <span className={`${selectedFile?'':'hide-upload-option'}`}>{uploadButton}</span>
      </div>
    )
  }

  const questionMarkIcon = (className) => <><img className={className} src={QuestionMarkIcon} alt="QuestionMarkIcon"/></>

  const handleTabChange = (formik) => {
    const values = formik.values
    if (values.assetName === '' || values.assetReference === '' || values.numberIdentifier === '' ||
    values.street === '' || values.city === '' || values.country === '' || values.postalCode === '' ||
    values.fundId === '' || values.sector === '' || values.nla === null || values.nla === 0) {
      formik.handleSubmit()
      window.scroll(0, 0)
    } else{
      onSubmit()
      props.setActiveIndexTab(1)
      window.scroll(0, 0)
    }
  }

  // Asset form columns.
  const columnLeft= formik => <>
    <div className={`input-container asset-name ${isInputError(formik, 'assetName') ? 'input-border-error' : ''}`}>
      <div className={'p-float-label'}>
        <InputText data-testid="assetName" id="assetName" required name="assetName" {...formik.getFieldProps('assetName')} />
        <label data-testid="assetNameLabel" htmlFor="assetName">{I18n('t_asset_name')} <span>{mandatoryChar}</span></label>
      </div>
    </div>
    <div className="field-input">
      <div className={`input-container asset-ref ${isInputError(formik, 'assetReference') ? 'input-border-error' : ''}`}>
        <div className={'p-float-label'}>
          <InputText id="assetRef" data-testid="assetRef" required name="assetReference" {...formik.getFieldProps('assetReference')} />
          <label data-testid="assetRefLabel" htmlFor="assetRef">{I18n('t_asset_reference')} <span>{mandatoryChar}</span></label>
        </div>
      </div>
      {questionMarkIcon('icon-question-mark')}
    </div>
    <div className="field-input">
      <div className="input-container asset-address">
        <i className="pi pi-search address-search-icon"/>
        <InputText className="input-address" autoComplete="off" id={addressInputId} placeholder={getLocalizedValue(selectedLanguage, 't_find_address')}/>
      </div>
      {questionMarkIcon('icon-question-mark')}
    </div>
    <div className="location-map">
      <div className="map" id={mapId} />
    </div>
    <div data-testid="add-address-manual" className={'sub-title-address-manual'}>{I18n('t_add_address_manually')}</div>
    <div className={`input-container number-identifier ${isInputError(formik, 'numberIdentifier') ? 'input-border-error' : ''}`}>
      <div className={'p-float-label'} data-testid="numberIdentifier">
        <InputNumber
          id="numberIdentifier"
          required
          name="numberIdentifier"
          value={formik.values.numberIdentifier}
          onChange={(e)=>setFieldValueAsset(e,'numberIdentifier', formik)}
          onBlur={formik.handleBlur}
        />
        <label data-testid="numberIdentifierLabel" htmlFor="numberIdentifier">{I18n('t_number_identifier')} <span>{mandatoryChar}</span></label>
      </div>
    </div>
    <div className={`input-container street-name ${isInputError(formik, 'street') ? 'input-border-error' : ''}`}>
      <div className={'p-float-label'} data-testid="street-name">
        <InputText id="streetName" required name="street" {...formik.getFieldProps('street')} />
        <label data-testid="stree-name-label" htmlFor="streetName">{I18n('t_street')} <span>{mandatoryChar}</span></label>
      </div>
    </div>
    <div className={`input-container town-city ${isInputError(formik, 'city') ? 'input-border-error' : ''}`}>
      <div className={'p-float-label'}>
        <InputText id="city" name="city" {...formik.getFieldProps('city')} />
        <label htmlFor="city">{I18n('t_town_city')} <span>{mandatoryChar}</span></label>
      </div>
    </div>
    <div className="input-container county-name">
      <div className={'p-float-label'}>
        <InputText id="countyName" name="county" {...formik.getFieldProps('county')} />
        <label htmlFor="countyName">{I18n('t_county')}</label>
      </div>
    </div>
    <div className={`input-container country-name ${isInputError(formik, 'country') ? 'input-border-error' : ''}`}>
      <div className={'p-float-label'}>
        <InputText id="countryName" name="country" {...formik.getFieldProps('country')} />
        <label htmlFor="countryName">{I18n('t_country')} <span>{mandatoryChar}</span></label>
      </div>
    </div>
    <div className={`input-container postcode-zip ${isInputError(formik, 'postcode') ? 'input-border-error' : ''}`}>
      <div className={'p-float-label'}>
        <InputText id="postcode" name="postcode" {...formik.getFieldProps('postcode')} />
        <label htmlFor="postcode">{I18n('t_postcode_zip')} <span>{mandatoryChar}</span></label>
      </div>
    </div>
    <div className={'required-fields-indicator'}><span>{mandatoryChar}</span> {I18n('t_required_fields')}</div>
  </>

  const columnRight = formik => <>
    <div data-testid="fundDropdown" className={`field-input input-dropdown fund-name ${isInputError(formik, 'fundId') ? 'input-border-error' : ''}`}>
      {/*<div className="input-container fund-name">*/}
      {/*<div className={`p-float-label`}>*/}
      <Dropdown
        className={'form-dropdown'}
        id="fundId"
        name="fundId"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.fundId}
        options={funds}
        placeholder={`${getLocalizedValue(selectedLanguage, 't_fund')} ${mandatoryChar}`}
        panelClassName="form-dropdown-panel"
      />
      {/*<label htmlFor="fundName">{`Fund ${mandatoryChar}`}</label>*/}
      {/*</div>*/}
      {/*</div>*/}
      {questionMarkIcon('icon-question-mark')}
    </div>
    <div className={`field-input input-dropdown sector-name ${isInputError(formik, 'sector') ? 'input-border-error' : ''}`}>
      {/*<div className="input-container fund-name">*/}
      {/*<div className={`p-float-label`}>*/}
      <Dropdown
        className={'form-dropdown'}
        id="sector"
        name="sector"
        {...formik.getFieldProps('sector')}
        options={sectors}
        placeholder={`${getLocalizedValue(selectedLanguage, 't_sector_type')} ${mandatoryChar}`}
        panelClassName="form-dropdown-panel"
      />
      {/*<label htmlFor="sectorType">{`Sector type ${mandatoryChar}`}</label>*/}
      {/*</div>*/}
      {/*</div>*/}
      {questionMarkIcon('icon-question-mark')}
    </div>

    <div className={'group-input-container'}>
      <div className="field-input">
        <div className="input-container gav">
          <div className={'p-float-label'}>
            <InputNumber
              id="currentAssetValue"
              name="currentAssetValue"
              value={formik.values.currentAssetValue}
              onChange={(e)=>setFieldValueAsset(e,'currentAssetValue', formik)}
              onBlur={formik.handleBlur}
            />
            <label htmlFor="currentAssetValue">{I18n('t_gross_asset_value')}</label>
          </div>
        </div>
        {questionMarkIcon('icon-question-mark')}
      </div>
      <div className="field-input input-dropdown gav-currency">
        {/*<div className="input-container gav-currency">*/}
        {/*  <div className={`p-float-label`}>*/}
        <Dropdown
          className={'form-dropdown gav-currency-dropdown'}
          id="currencyGAV"
          name="currencyGAV"
          placeholder={'£GBP'}
          {...formik.getFieldProps('currencyGAV')}
          panelClassName="form-dropdown-panel"
          options={['£GBP']}
        />
        {/*  </div>*/}
        {/*</div>*/}
        {questionMarkIcon('icon-question-mark')}
      </div>
    </div>
    <div className={'options-container'}>
      <div className="lease-cbx-wrapper">
        <Checkbox
          className={'lease-cbx'}
          inputId="fri"
          name="fri"
          checked={formik.values.fri}
          {...formik.getFieldProps('fri')}
        />
        <label className="label-checkbox" htmlFor="fri">{I18n('t_fri')} <span>{mandatoryChar}</span></label>
        {questionMarkIcon('icon-info')}
      </div>
    </div>
    <div className={'flex labels-container'}>
      <div className={'title-floor-areas'}>{I18n('t_floor_areas')}</div>
      <SelectButton className="label-area-unit" value={floorAreaUnits} options={floorAreaUnitsOptions} onChange={(e) => setFloorAreaUnits(e.value)} />
    </div>
    <div className={'group-input-container'}>
      <div className="field-input">
        <div className="input-container gia">
          <div className={'p-float-label'}>
            <InputNumber
              inputId="gia"
              name="gia"
              value={formik.values.gia}
              onChange={(e)=>setFieldValueAsset(e,'gia', formik)}
              onBlur={formik.handleBlur}
            />
            <label htmlFor="gia">{I18n('t_gia')}</label>
          </div>
        </div>
        {questionMarkIcon('icon-question-mark')}
      </div>
      <div className="field-input">
        <div className={`input-container nla ${isInputError(formik, 'nla') ? 'input-border-error' : ''}`}>
          <div className={'p-float-label'} data-testid="nla">
            <InputNumber
              inputId="nla"
              name="nla"
              value={formik.values.nla}
              onKeyDown={(e) => e.code === 'Minus' && e.preventDefault()}
              required
              onChange={(e)=> setFieldValueAsset(e,'nla', formik)}
              onBlur={formik.handleBlur}
            />
            <label data-testid='nlaLabel' htmlFor="nla">{I18n('t_nla')} <span>{mandatoryChar}</span></label>
          </div>
        </div>
        {questionMarkIcon('icon-question-mark')}
      </div>
    </div>
    <div className={'group-input-container'}>
      <div className="field-input">
        <div className="input-container cpa">
          <div className={'p-float-label'}>
            <InputNumber
              inputId="cpa"
              name="cpa"
              value={formik.values.cpa}
              onChange={(e)=>setFieldValueAsset(e,'cpa', formik)}
              onBlur={formik.handleBlur}
            />
            <label htmlFor="cpa">{I18n('t_cpa')}</label>
          </div>
        </div>
        {questionMarkIcon('icon-question-mark')}
      </div>
      <div className="field-input">
        <div className="input-container ext">
          <div className={'p-float-label'}>
            <InputNumber
              inputId="ext"
              name="ext"
              value={formik.values.ext}
              onChange={(e)=>setFieldValueAsset(e,'ext', formik)}
              onBlur={formik.handleBlur}
            />
            <label htmlFor="ext">{I18n('t_ext')}</label>
          </div>
        </div>
        {questionMarkIcon('icon-question-mark')}
      </div>
    </div>
    <div className={'label-units'}>{I18n('t_units')}</div>
    <div className={'msg-units'}>{I18n('t_units_desc')}</div>
    <div className={'no-yes-options'}>
      <div className={'option-no'}>
        <RadioButton
          className={'r-btn-no'}
          inputId="unitsNo"
          value="No"
          name="unitsYesNo"
          checked={formik.values.unitsYesNo==='No'}
          onChange={(e)=>setFieldValueAsset(e,'unitsYesNo', formik)}
          onBlur={formik.handleBlur}
        />
        <label htmlFor="unitsNo">{I18n('t_no')}</label>
      </div>
      <div className={'options-separator-line'} />
      <div className={'option-yes'}>
        <RadioButton
          className={'r-btn-yes'}
          inputId="unitsYes"
          name="unitsYesNo"
          value="Yes"
          checked={formik.values.unitsYesNo==='Yes'}
          onChange={(e)=>setFieldValueAsset(e,'unitsYesNo', formik)}
          onBlur={formik.handleBlur}
        />
        <label htmlFor="unitsYes">{I18n('t_yes')}</label>
      </div>
    </div>
    <div className="field-upload">
      <div className={'upload-asset-img-container'}>
        <FileUpload
          className={'asset-img-uploader'}
          name="asetImgFile"
          url=""
          multiple
          accept="image/*"
          maxFileSize={1000000}
          emptyTemplate={getEmptyTemplate}
          // itemTemplate={getItemTemplate}
          chooseOptions={chooseOptions}
          uploadOptions={uploadOptions}
          headerTemplate={getHeaderTemplate}
          onSelect={onSelectHandler}
          onRemove={onRemoveHandler}
        />
      </div>
      {questionMarkIcon('icon-question-mark')}
    </div>
  </>

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {formik => (
        <>
          <span className="error-message-1">{formik?.errors?.nla && formik?.errors?.nla !== 'Required' && formik.errors.nla}</span>
          <span className="error-message">{!(formik.isValid) ? I18n('t_form_error_message') : ''}</span>
          <div className={'asset-form'}>
            <form onSubmit={formik.handleSubmit}>
              <div className={'form-table grid'}>
                <div className={'l-column col'}>
                  {columnLeft(formik)}
                </div>
                <div className={'r-column col'}>
                  {columnRight(formik)}
                </div>
              </div>
            </form>
          </div>
          <div className={'footer-divider'}>
            <hr/>
          </div>
          <div data-testid="footer" className={'footer-container'}>
            <AssetFooter
              submitHandler={formik.handleSubmit}
              handleNextTabBtnText={'t_next_add_key_dates'}
              handleNextTab={() => handleTabChange(formik)}
            />
          </div>
          {errorDialogVisible && <ErrorDialog
            data={errorDialogData}
            dialogVisible={errorDialogVisible}
            onHideHandler={dlgCloseHandler}
            closeHandler={dlgCloseHandler} />}
        </>
      )}
    </Formik>
  )
}

export default AssetForm