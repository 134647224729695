import React from 'react'
import { Skeleton } from 'primereact/skeleton'

const ForgotPasswordSkeleton = () => {
  return (
    <div className="custom-skeleton p-rel contour p-d-flex p-flex-column p-ai-center">
      <Skeleton width="2.5rem" borderRadius="0" height=".5rem" className="p-mb-3 p-mt-4"/>
      <Skeleton width="20rem" borderRadius="0" height=".5rem" className="p-mb-2"/>
      <Skeleton width="4rem" borderRadius="0" height=".5rem" className="p-mt-3"/>
    </div>
  )
}

export default ForgotPasswordSkeleton
