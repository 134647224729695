import React, {useEffect, useState} from 'react'
import {Button} from 'primereact/button'
import './ConsumptionOperations.scss'
import {getLocalizedValue} from '../../../../../../utils/helpers/Helper'
import {MASK_DATA_QUALITY_KEYS} from '../../../../../../utils/helpers/Constants'
import PropTypes from 'prop-types'

export const ConsumptionOperations = (props) => {
  const recordsCount = props.selectedRecords?.length
  const getCurrentLanguage = props.language
  const [deleteWarningMessage, setDeleteWarningMessage] =  useState(false)
  const verifySelectedRecordsType = (typesList) => props.selectedRecords.every((data) => typesList.includes(data.estimate))
  const isAllSelectedWithNoTableSelection = (props.filter === MASK_DATA_QUALITY_KEYS.All && recordsCount === 0)
  const isAddAndRequestDisabled = isAllSelectedWithNoTableSelection
      || !(recordsCount > 0 && verifySelectedRecordsType([MASK_DATA_QUALITY_KEYS.missing])) || deleteWarningMessage
  const isEditAndDeleteDisabled = isAllSelectedWithNoTableSelection
      || !((recordsCount > 0 && verifySelectedRecordsType([MASK_DATA_QUALITY_KEYS.actual, MASK_DATA_QUALITY_KEYS.estimate])))
  const disableEnableButton = {
    addIsDisabled: isAddAndRequestDisabled, requestIsDisabled: isAddAndRequestDisabled,
    editIsDisabled: isEditAndDeleteDisabled, deleteIsDisabled: isEditAndDeleteDisabled
  }
  const labelForRecords = {
    DELETE: recordsCount > 1 && !disableEnableButton.deleteIsDisabled ? getLocalizedValue(getCurrentLanguage,'t_delete_records') :
      getLocalizedValue(getCurrentLanguage,'t_delete_record'),
    EDIT: recordsCount > 1 && !disableEnableButton.editIsDisabled ? getLocalizedValue(getCurrentLanguage,'t_edit_records') :
      getLocalizedValue(getCurrentLanguage,'t_edit_record')
  }
  const isMissingRecordPresent = props.allRecords.some((item) => item.estimate === MASK_DATA_QUALITY_KEYS.missing)
  const isActualEstimateRecordPresent =
      props.allRecords.some((item) => [MASK_DATA_QUALITY_KEYS.actual, MASK_DATA_QUALITY_KEYS.estimate].includes(item.estimate))

  useEffect(() => {
    // spr-5841: hide delete confirmation when all records are unselected
    if(deleteWarningMessage && recordsCount === 0) {
      setDeleteWarningMessage(false)
    }
  }, [props.selectedRecords])

  function showUnShowAddRequestBtn() {
    const addRequestBtn = <>
      <Button className={'forest-bordered-white-btn pointer-hightlight mr-4'} onClick={()=>props.addConsumption('ADD')}
        label={getLocalizedValue(getCurrentLanguage,'t_add_consumptions')}
        name={'add-consumption'} disabled={disableEnableButton.addIsDisabled}
      />
      <Button className={'forest-bordered-white-btn pointer-hightlight mr-4'} onClick={() => props.requestConsumption('REQUEST')}
        label={getLocalizedValue(getCurrentLanguage,'t_btn_request_consumption')}
        name={'edit-consumption'} disabled={disableEnableButton.requestIsDisabled}
      />
    </>
    return (props.filter === MASK_DATA_QUALITY_KEYS.All
        || props.filter === MASK_DATA_QUALITY_KEYS.missing) && isMissingRecordPresent ? addRequestBtn : null
  }

  function deleteConsumption(){
    props.deleteConsumption()
    setDeleteWarningMessage(false)
  }

  const handleKeyUp = () => {
    console.log('key up called')
  }

  function displayDeleteWarningMessage() {
    return <div className='flex align-self-center p-warn' data-testid={'warn-delete'}>
      <span className={'pr-3'} data-testid={'warn-delete-message'}>
        {getLocalizedValue(getCurrentLanguage, recordsCount > 1 ? 't_delete_warn_s' : 't_delete_warn')}
      </span>
      <div className={'pr-3 link-yes'} data-testid={'warn-delete-yes-link'} onClick={deleteConsumption} onKeyUp={handleKeyUp}>
        {getLocalizedValue(getCurrentLanguage, 't_delete_yes')}
      </div> /
      <span className={'pl-3 link-no'} data-testid={'warn-delete-cancel-link'} onClick={() => setDeleteWarningMessage(false)}
        onKeyUp={handleKeyUp}>
        {getLocalizedValue(getCurrentLanguage, 't_cancel_delete')}
      </span>
    </div>
  }
  function showUnShowEditDeleteBtn() {
    const editDeleteBtn = <>
      <Button className={'forest-bordered-white-btn pointer-hightlight mr-4'} label={labelForRecords.EDIT}
        name={'edit-record'} disabled={disableEnableButton.editIsDisabled || deleteWarningMessage} onClick={() => props.editRecord('EDIT')}
      />
      <Button className={'red-bordered-white-btn pointer-hightlight mr-4'} label={labelForRecords.DELETE}
        name={'delete-record'} disabled={disableEnableButton.deleteIsDisabled}
        onClick={() => setDeleteWarningMessage(true)}/>
    </>
    return (props.filter === MASK_DATA_QUALITY_KEYS.All
        || props.filter === MASK_DATA_QUALITY_KEYS.estimate
        || props.filter === MASK_DATA_QUALITY_KEYS.actual) && isActualEstimateRecordPresent ? editDeleteBtn : null
  }

  return (<div className={props.isMeter ? 'btns-container-consumption' : 'btns-container-consumption flex'}>
    {showUnShowAddRequestBtn()}
    {showUnShowEditDeleteBtn()}
    <div className={props.isMeter ? 'mt-3' : 'align-self-center'}>
      {deleteWarningMessage && displayDeleteWarningMessage()}
    </div>
  </div>)
}

ConsumptionOperations.propTypes = {
  filter: PropTypes.string.isRequired,
  allRecords: PropTypes.array.isRequired,
  selectedRecords: PropTypes.array.isRequired,
  language: PropTypes.string.isRequired,
  isMeter : PropTypes.bool,
  editRecord:PropTypes.func,
  addConsumption:PropTypes.func,
  requestConsumption:PropTypes.func,
  deleteConsumption:PropTypes.func
}

