import React from 'react'
import {useQuery} from 'react-query'
import {GetPerformanceDataByMonth} from '../../../../../services/assets/performance-service'
import {noRefetchOnWindowFocus} from '../../../../../services/common/useQuery-config'

const usePerformanceDataMonthly = (assetId, selectedUtilityName, yearMonth) => {
  const {data: performanceDataMonthly, isLoading: performanceDataMonthlyIsLoading} = useQuery(['performance-data-by-yearmonth', assetId, yearMonth, selectedUtilityName], GetPerformanceDataByMonth, {...noRefetchOnWindowFocus, enabled: !!(assetId && yearMonth)})
  return {performanceDataMonthlyIsLoading, performanceDataMonthly}
}

export default usePerformanceDataMonthly