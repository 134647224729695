import React from 'react'
import {Skeleton} from 'primereact/skeleton'
import  '../../../pages/admin/users/users-home/UsersHome.scss'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'

const UserOverviewGroupsSkeleton = () => {
  const dummyArray = Array.from({ length: 5 })
  const bodyTemplate = () => {
    return <Skeleton width={'17rem'}/>
  }

  return (
    <div className="custom-skeleton p-rel p-d-flex p-flex-column p-ai-center users-page-skeleton">
      <DataTable value={dummyArray} className="user-groups-grid no-blue">
        <Column field="code" body={bodyTemplate}/>
      </DataTable>
    </div>
  )
}

export default UserOverviewGroupsSkeleton
