import { useParams } from 'react-router-dom'
import { GetFundKeyfactsData } from 'services/funds/fund-group-service'
import UseUrlParameterIds from 'utils/custom-hooks/UseUrlParameterIds'
import StaticFundNetZeroCarbon from '../static-net-zero-carbon/StaticFundNetZeroCarbon'
import DefaultFundNetZeroCarbon from './DefaultFundNetZeroCarbon'
import {useQuery} from 'react-query'

const FundNetZeroCarbonHome = () => {
  const {instanceName, orgName, fundName} = useParams()
  const urlParameterIds = UseUrlParameterIds({ instanceName, orgName, fundName })
  const {data: fundKeyfactsData, refetch: refetchFundGroupKeyfacts} = useQuery(['FundKeyfacts', urlParameterIds.fundGroupId], GetFundKeyfactsData, {enabled: !!urlParameterIds})
  if (!urlParameterIds || !fundKeyfactsData) {
    return null
  }
  if (fundKeyfactsData.isNZCStaticModel) {
    return <StaticFundNetZeroCarbon
      fundGroupId={urlParameterIds.fundGroupId}
      financialYearStart={fundKeyfactsData.financialYearStart}
      defaultModel={fundKeyfactsData.nzcDefaultModel}
    />
  }  
    
  return <DefaultFundNetZeroCarbon
    fundId={urlParameterIds.fundId}
    fundGroupId={urlParameterIds.fundGroupId}
    fundKeyfactsData={fundKeyfactsData}
    refetchFundGroupKeyfacts={refetchFundGroupKeyfacts}
  />
}

export default FundNetZeroCarbonHome