import {Page, View, Document, Text, Image} from '@react-pdf/renderer'
import React, {} from 'react'
import PdfLogo from '../../../../../utils/data-exports/fixed-components/logo/PdfLogo'
import PdfPagination from '../../../../../utils/data-exports/fixed-components/footer/PdfPagination'
import PdfHeader from '../../../../../utils/data-exports/fixed-components/header/PdfHeader'
import styles from '../../../../../utils/data-exports/style-sheet/Styles'
import MissingData from './MissingData'
import I18n from '../../../../../utils/i18n/I18n'
import {I18nProvider} from '../../../../../utils/i18n'
import PdfPageBreak from '../../../../../utils/data-exports/fixed-components/PdfPageBreak'
import {eventDuration, PDF_LABEL} from '../../../../../utils/helpers/Constants'

const DataQualityPdf = (props) => {
  const language=props.language

  const dqImage = (props) => {
    let area = props.landlord ? I18n('t_graph_landlord') : I18n('t_tenant')
    return (
      <View style={[styles.tableRow, styles.top10]}>
        <View style={[styles.planBorder, styles.dqImage]}>
          <Text style={[styles.title, styles.topM14]}>{area}</Text>
          <Image src={props.image} style={styles.dqImagepadding}/>
        </View>
      </View>
    )
  }

  const noData = () =>{
    return (
      <View style={[styles.mT40,styles.planBorder, styles.tableWidth]}>
        <Text style={[styles.title, styles.topM14]}>{PDF_LABEL[props.colorLabel]}</Text>
        <Text style={[styles.title,styles.noRecords]}>{I18n('t_no_results')}</Text>
      </View>
    )
  }

  function missingData() {
    let missingDataList = []
    let year=null
    for (let i = 0; i < props.areaData.length; i++) {
      function getYear(data){
        let areaYear=new Date(data[0].startDate).getFullYear()
        if(year !== areaYear){
          areaYear=new Date(data[0].startDate).getFullYear()
          year=new Date(data[0].startDate).getFullYear()
        }else{
          areaYear=null
        }
        return areaYear
      }
      missingDataList.push(<MissingData data={props.areaData[i]} label={props.qtrLabel} year={getYear(props.areaData[i])}
        colorLabel={props.colorLabel} key={i}
        isLastArray={i === props.areaData.length-1 ? true : false}/>)
    }
    return missingDataList
  }

  return (
    <Document>
      <I18nProvider locale={language}>
        <Page size="A4" object-fit="fill" style={styles.page}>
          <View>
            <View style={styles.tableRow} fixed>
              <View>
                <PdfLogo header={I18n('t_esg_data_quality')}/>
              </View>
              <View>
                <PdfHeader asset={props.asset} language={language}/>
              </View>
            </View>
            <View>
              <View style={[styles.title,styles.tableRow]} fixed>
                <View style={[styles.dqHeader]}>
                  <Text>{I18n('t_req_utility')}: {I18n('t_' + props.utility.toLowerCase())} {' '} | {' '}
                    {I18n('t_area')}: {I18n('t_' +props.area.toLowerCase())}</Text>
                  <Text style={{marginLeft: 'auto'}}>{I18n('t_date_range')}: {eventDuration(props.period,language)}</Text>
                </View>
              </View>
              {dqImage(props)}
              {(props.areaData.length === 0) ? noData() : missingData()}
              <PdfPageBreak/>
            </View>
            <PdfPagination/>
          </View>
        </Page>
      </I18nProvider>
    </Document>
  )
}

export default DataQualityPdf