import { useQueries } from 'react-query'
import {getDqOverviewDataForLandlord, getDqOverviewDataForTenant, GetMeterData, GetMissingDataList} from '../../../../../services/assets/dataquality-service'
import {GetAssetHeaderData} from '../../../../../services/assets/portfolio-service'
import { noRefetchOnWindowFocus, retryCount } from '../../../../../services/common/useQuery-config'

const useDataQualityHomeData = (assetId, dataQualityHomeState, time, timePeriodState) => {
  const {
    selectedUtilityLandlord,
    selectedUtilityTenant,
    selectedAreaLandlord,
    selectedAreaTenant,
    variablesMDList,
    variablesMeterData
  } = dataQualityHomeState

  const {timeEndDatePeriod: endMonth, timeNoOfMonthsPeriod: noOfMonths} = timePeriodState

  const [assetHeaderInfo, missingData, meterData, dqOverviewDataForLandlord, dqOverviewDataForTenant] = useQueries([
    {
      queryKey: ['asset-header-data', assetId],
      queryFn: GetAssetHeaderData,
      retry: retryCount,
      ...getQueryConfig(!!assetId)
    },
    {
      queryKey: variablesMDList,
      queryFn: GetMissingDataList,
      retry: retryCount,
      ...getQueryConfig(!!assetId)
    },
    {
      queryKey: variablesMeterData,
      queryFn: GetMeterData,
      retry: retryCount,
      ...getQueryConfig(!!assetId)
    },
    {
      queryKey: ['asset-dq-overview-data-landlord', assetId, time, noOfMonths, endMonth, selectedUtilityLandlord?.value, selectedAreaLandlord?.value],
      queryFn: getDqOverviewDataForLandlord,
      retry: retryCount,
      ...getQueryConfig(!!assetId && !!time && !!selectedUtilityLandlord?.value && !!selectedAreaLandlord?.value)
    },
    {
      queryKey: ['asset-dq-overview-data-tenant', assetId, time, noOfMonths, endMonth, selectedUtilityTenant?.value, selectedAreaTenant?.value],
      queryFn: getDqOverviewDataForTenant,
      retry: retryCount,
      ...getQueryConfig(!!assetId && !!time && !!selectedUtilityTenant?.value && !!selectedAreaTenant?.value)
    }
  ])

  function getQueryConfig(fetchOnMount) {
    return {...noRefetchOnWindowFocus, staleTime: 999999, enabled: fetchOnMount}
  }

  return {
    assetHeaderInfo,
    missingData,
    meterData,
    dqOverviewDataForLandlord,
    dqOverviewDataForTenant
  }
}

export default useDataQualityHomeData