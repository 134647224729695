import { View,Text} from '@react-pdf/renderer'
import React from 'react'

const PdfPageBreak = () => {
  return (
    <Text break>

    </Text>
  )
}

export default PdfPageBreak