import React, {useContext, useEffect, useMemo, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import './NzcTargetPathwayForm.scss'
import {InputNumber} from 'primereact/inputnumber'
import {RadioButton} from 'primereact/radiobutton'
import I18n from '../../../../../utils/i18n/I18n'
import ErrorDialog from '../../../assets/data-quality/error-dialog/ErrorDialog'
import {useMutation, useQueryClient} from 'react-query'
import {
  CreateTargetPathway,
  deleteNzcTopdownPathways,
  DeleteScopeTarget,
  UpdateNZCConfigTargetPathways
} from '../../../../../services/funds/nzc-service'

import {
  getDropdownItemTemplate,
  getLocalizedValue,
  SessionUserState,
  removeRequiredSessionStorageCache,
  getQueryValuesForNZCAssets,
  CachedUserState, handleLengthyName
} from '../../../../../utils/helpers/Helper'
import {FeatureFlagContext} from 'Contexts'
import {LOCAL_STORAGE_KEYS, SCOPE_TARGET_MODE} from '../../../../../utils/helpers/Constants'
import PopupDialog from '../../../../common/modal-dialogs/PopupDialog'
import {OverlayPanel} from 'primereact/overlaypanel'
import {Checkbox} from 'primereact/checkbox'
import questionMarkIcon from '../../../../../resources/images/icon/question-mark.svg'
import caution from '../../../../../resources/images/icon/caution.svg'
import approved from '../../../../../resources/images/icon/action-plan/approved.svg'
import plusIcon from '../../../../../resources/images/icon/plus.svg'
import {Dropdown} from 'primereact/dropdown'
import {Panel} from 'primereact/panel'
import {InputText} from 'primereact/inputtext'
import {getCustomTPNameErrorState} from '../../../assets/net-zero-carbon/asset-nzc-utils/asset-nzc-utils'
import { actionsDataNotEmpty,  agreedActionsNotEmpty,  applicableActionsNotEmpty,  applyNoEnergySavingsActionFilter,  getActionCheckboxStatus,
  getCustomTPNames,  getDefaultTPName,  isScopeTargetsNotEmpty,  newSettingsChangesExist,} from '../../../../common/net-zero-carbon/nzc-common-utils'
import {STRANDING_YEAR_TP1, STRANDING_YEAR_TP2, STRANDING_YEAR_TP3, TPS_NAMES_MAP, YEAR_2030} from '../../../../common/net-zero-carbon/nzc-constants'
import {Button, ButtonWrapper, Icon, Text, Tooltip} from 'components/atomic'
import {useHistory} from 'react-router-dom'
import ConfirmDialog from '../../../../atomic/ConfirmDialog/ConfirmDialog'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const NzcTargetPathwayForm = (props) => {
  const [errorDialogVisible, setErrorDialogVisible] = useState(false)
  const [errorDialogData, setErrorDialogData] = useState()
  const buttonRef = useRef(null)
  const { graphIntensity } = props
  const isDisabledRef = useRef(null)
  const addTargetPathWayMutation = useMutation(CreateTargetPathway)
  const deleteScopeTargetMutation = useMutation(DeleteScopeTarget)
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const history = useHistory()
  const placeholderString=''
  const scopeId = props?.nzcScopeId
  const targetPathwayFormObject={
    showAddTargetForm: false,
    scopeTP1: null,
    scopeTP2: null,
    scopeTP3: null,
    scopeTPCreate: false,
    tabExpanded: false,
    tab1Class: placeholderString,
    tab2Class: placeholderString,
    tab3Class: placeholderString,
    scopeId:null,
    showConfirmModal: false,
    selectedScopeTPData:null,
    tabIndex: null,
    strandingYearTP1:placeholderString,
    strandingYearTP2:placeholderString,
    strandingYearTP3:placeholderString,
  }
  let targetPathwayNumbersArray = [...new Set(props?.targetPathwayData?.scopeTargets.map((item) => item.targetPathwayNumber))].sort().filter((number) => number !== 0)
  const [targetPathwayFormState, setTargetPathwayFormState]=useState(targetPathwayFormObject)
  const cssClassHideTab='hide-a-tab'
  const targetFormItemsRef=useRef({})
  const actionBasedTargetPathwayOP = useRef(null)
  const scienceBasedTargetPathwayOP = useRef(null)
  const customBasedTargetPathwayOP = useRef(null)
  const [targetPathwayDetailsObj,setTargetPathwayDetailsObj] = useState(getInitialTargetPathwayValues)
  const [isEdit,setIsEdit] = useState({layer_button_1: false, layer_button_2: false})
  const [targetPathwayOPText,setTargetPathwayOPText]= useState('')
  const actionBasedPathwayId = 'action-based-title-icon'
  const scienceBasedPathwayId = 'science-based-target'
  const customBasedPathwayId = 'custom-target-pathways-title-icon'
  const validateTotalReductionsRef=useRef(false)
  const progressTowardsTargetYearOptions = [2025,2030,2035,2040,2045,2050]
  const updateNzcSettingsMutation = useMutation(UpdateNZCConfigTargetPathways)
  const [deletingTPTabIndex,setDeletingTPTabIndex]= useState(null)
  const tpDetails = TPS_NAMES_MAP
  let newCreatedOrEditedTPNumber= useRef(null)
  const featureContext            = useContext(FeatureFlagContext)
  const featureState              = featureContext.featureState
  const assetCountWithNoAgreedActions = props?.actionBasedTPDetails?.totalAssets - props?.actionBasedTPDetails?.assetCountOfAssetsWithAgreedActions
  const [inputTextTouched,setInputTextTouched] = useState(false)
  const errorDialogProps = {data:errorDialogData, dialogVisible:errorDialogVisible, onHideHandler:dlgCloseHandler, closeHandler:dlgCloseHandler}
  const [customTargetPathwayName,setCustomTargetPathwayName] = useState({})
  const targetPathWayData = useRef(null)
  const previousActiveIndex = useRef(null)
  const settingsChangesExist=useMemo(()=>newSettingsChangesExist(targetPathwayDetailsObj, props.nzcSettingsData, customTargetPathwayName, targetPathwayFormState?.selectedScopeTPData, targetPathWayData), [targetPathwayDetailsObj, props.nzcSettingsData, customTargetPathwayName, targetPathwayFormState])
  const deleteTopDownPathway = useMutation(deleteNzcTopdownPathways)
  const [confirmDeleteTopdownPathway, setConfirmDeleteTopdownPathway] = useState(false)
  const queryClient=useQueryClient()

  const customTPNameError = useMemo(() => {
    return  getCustomTPNameErrorState(customTargetPathwayName, getTPNumber(), selectedLanguage, props?.nzcSettingsData, inputTextTouched)
  }, [customTargetPathwayName,inputTextTouched])

  function getInitialTargetPathwayValues() {

    const agreedActionCheckboxStatus = getActionCheckboxStatus(props?.nzcSettingsData?.agreedActions, props?.actionBasedTPDetails?.agreedActions, props?.actionBasedTPDetails,true)
    const applicableActionCheckboxStatus = getActionCheckboxStatus(props?.nzcSettingsData?.applicableActions, props?.actionBasedTPDetails?.applicableActions, props?.actionBasedTPDetails,false)
    const energySavingActionsCheckboxStatus = (agreedActionCheckboxStatus || applicableActionCheckboxStatus) && props?.nzcSettingsData?.energySavingActions
    props.updateTargetsAndPathwayDetails({energySavingActions:energySavingActionsCheckboxStatus})

    return {
      energySavingActions: energySavingActionsCheckboxStatus,
      agreedActions: agreedActionCheckboxStatus && props?.nzcSettingsData?.energySavingActions,
      applicableActions: applicableActionCheckboxStatus && props?.nzcSettingsData?.energySavingActions,
      scienceBasedTargetOption: props?.nzcSettingsData?.scienceBasedTarget,
      progressTowardsTargetYear: props?.nzcSettingsData?.progressTargetYear,
      showProgressTowardsTargetYear: props?.nzcSettingsData?.showTargetProgress,
      selectedTP: props?.nzcSettingsData?.targetPathway !== null ? `tp${props?.nzcSettingsData?.targetPathway}` : null,
      showTopDownPathways: props?.nzcSettingsData?.showTopDownPathways
    }
  }

  const checkingForDisimilarities = () => {
    if(settingsChangesExist){
      setIsEdit({
        ...isEdit,
        layer_button_1: true
      })
    } else {
      setIsEdit({
        ...isEdit,
        layer_button_1: false
      })
    }
    props.updateTargetsAndPathwayDetails(targetPathwayDetailsObj)
  }

  useEffect(()=>{
    cancelTargetPathwayViewDetailsUpdate() // reset state on component exit
  },[])


  useEffect(()=>{
    checkingForDisimilarities()
  },[targetPathwayDetailsObj, settingsChangesExist])

  useEffect(()=>{
    updateNZCTPData()
  }, [props.targetPathwayData])

  useEffect(() => {
    if (props.scopeTargetState.tpMode === SCOPE_TARGET_MODE.edit) {
      if (targetPathwayFormState[`scopeTP${props.scopeTargetState.activeIndex+1}`]) {
        let selectedTPData=targetPathwayFormState[`scopeTP${props.scopeTargetState.activeIndex+1}`]
        selectedTPData=selectedTPData.map(filterAndCreateScopeData)
        updateTargetPathwayFormState({selectedScopeTPData:[...selectedTPData]})
      }
    } else {
      updateTargetPathwayFormState({selectedScopeTPData:[...getNewTPDataObject()]})
      targetPathWayData.current = [...getNewTPDataObject()]
    }
  }, [targetPathwayFormState[`scopeTP${props.scopeTargetState.activeIndex+1}`]])

  function getFundCustomTPNames(t1Data,t2Data,t3Data) {
    let customTPNames = JSON.parse(props?.nzcSettingsData?.targetPathwayNames)
    let tpNames = getCustomTPNames(t1Data, t2Data, t3Data, customTPNames, selectedLanguage)
    setCustomTargetPathwayName(tpNames)
  }

  function updateNZCTPData() {
    let t1Data
    let t2Data
    let t3Data
    const targetPathwayData=props.targetPathwayData
    if(targetPathwayData && targetPathwayData.scopeTargets){
      targetPathwayData.scopeTargets.sort((a,b)=>a.targetYear-b.targetYear)
      const scopeTargets=targetPathwayData.scopeTargets.filter(scopeTarget=>scopeTarget.targetYear>=props?.reportingYear + 1)
      t1Data=scopeTargets.filter(scopeTP=>1===scopeTP.targetPathwayNumber).map(filterAndCreateScopeData)
      t2Data=scopeTargets.filter(scopeTP=>2===scopeTP.targetPathwayNumber).map(filterAndCreateScopeData)
      t3Data=scopeTargets.filter(scopeTP=>3===scopeTP.targetPathwayNumber).map(filterAndCreateScopeData)
      updateTargetPathwayFormState({
        scopeTP1: t1Data.length>0?t1Data:null,
        scopeTP2: t2Data.length>0?t2Data:null,
        scopeTP3: t3Data.length>0?t3Data:null,
        scopeId: targetPathwayData.scopeId,
        strandingYearTP1: getStrandingYear(STRANDING_YEAR_TP1),
        strandingYearTP2: getStrandingYear(STRANDING_YEAR_TP2),
        strandingYearTP3: getStrandingYear(STRANDING_YEAR_TP3),
      })
      if (newCreatedOrEditedTPNumber.current !== null) {
        SessionUserState.saveUserDataToSession('strandingYear', getStrandingYear(tpDetails[`tp${newCreatedOrEditedTPNumber.current}`]))
        props.updateStrandingYear(getStrandingYear(tpDetails[`tp${newCreatedOrEditedTPNumber.current}`]))
      }
      getFundCustomTPNames(t1Data,t2Data,t3Data)
    }
  }
  function getStrandingYear(targetPathwayNumber) {
    return Math.round(props?.targetPathwayData.strandings.filter(strandingDetails => targetPathwayNumber === strandingDetails.strandingOf)[0]?.year)
  }

  function updateTargetPathwayFormState(newTargetPathwayFormState) {
    setTargetPathwayFormState(prevTargetPathwayFormState => {
      return {...prevTargetPathwayFormState, ...newTargetPathwayFormState}
    })
  }

  function isReductionDataNotEmpty() {
    return !!(targetPathwayFormState.selectedScopeTPData)
  }

  function updateSettingsForDeletedTP() {
    const checkSelectedTp = targetPathwayDetailsObj.selectedTP ? targetPathwayDetailsObj.selectedTP.substring(2) : null
    const settings = {
      baseLineYear: props?.baselineYear,
      targetPathway: deletingTPTabIndex === props.nzcSettingsData.targetPathway - 1 ? null : checkSelectedTp,
      showTargetProgress: deletingTPTabIndex === props.nzcSettingsData.targetPathway - 1 ? false : targetPathwayDetailsObj.showProgressTowardsTargetYear,
      progressTargetYear: deletingTPTabIndex === props.nzcSettingsData.targetPathway - 1 ? YEAR_2030 : targetPathwayDetailsObj.progressTowardsTargetYear,
      energySavingActions: targetPathwayDetailsObj.energySavingActions,
      applicableActions: targetPathwayDetailsObj.applicableActions,
      agreedActions: targetPathwayDetailsObj.agreedActions,
      scienceBasedTarget: targetPathwayDetailsObj.scienceBasedTargetOption,
      showTopDownPathways: targetPathwayDetailsObj.showTopDownPathways,
      targetPathwayNames: JSON.stringify(customTargetPathwayName)
    }
    setTargetPathwayDetailsObj(prevState => {
      return {
        ...prevState,
        selectedTP: settings.targetPathway ? `tp${settings.targetPathway}` : null,
        showProgressTowardsTargetYear: settings.showTargetProgress,
        progressTowardsTargetYear: settings.progressTargetYear
      }
    })
    updateNzcSettingsMutation.mutateAsync({scopeId, settings, graphIntensity}).then(() => {
      props.getNzcSettingsData.refetch()
      props.refetchTargetPathwayData()
    })
  }

  async function deleteScopeTarget(targetPathwayNumber) {
    updateTargetPathwayFormState({showConfirmModal: false})
    deleteScopeTargetMutation.mutateAsync({scopeId: targetPathwayFormState.scopeId, targetPathwayNumber, intensity: graphIntensity}).then(()=>{
      const selectedTP=getScopeTPName(targetPathwayNumber - 1)
      updateScopeTargetActions(SCOPE_TARGET_MODE.delete, targetPathwayNumber - 1, selectedTP)
      props.refetchTargetPathwayData().finally(()=>{
        updateTargetPathwayFormState({tabExpanded: false, tab1Class: placeholderString,tab2Class:placeholderString, tab3Class:placeholderString})
      })
      updateSettingsForDeletedTP()
    }).catch((error)=>{
      const errorResponse = (error.response && error.response.data) ? `Error: ${error.response.data.title}` : error.toString()
      setErrorDialogData({title: I18n('t_title_delete_target_pathway_failed'), message: errorResponse})
      setErrorDialogVisible(true)
      props.setGraphDataLoading(false)
      props.refetchTargetPathwayData()
    })
  }

  function filterAndCreateScopeData(scopeData) {
    if(scopeData.percentage===null || scopeData.percentage===undefined){
      scopeData.percentage=0
    }
    return {...scopeData}
  }

  const createTargetPathway = async () => {
    let errorMsgKey = props.scopeTargetState.tpMode === SCOPE_TARGET_MODE.edit ? 't_title_update_target_pathway_failed' : 't_title_cannot_create_target_pathway'
    let showTargetProgress = false
    let progressTowardsTargetYear = YEAR_2030
    if(props.scopeTargetState.tpMode === SCOPE_TARGET_MODE.edit && getTPNumber()-1 === previousActiveIndex.current){
      showTargetProgress = props?.nzcSettingsData?.showTargetProgress
      progressTowardsTargetYear = props?.nzcSettingsData?.progressTargetYear
    }
    let selectedFormScopeData=targetPathwayFormState.selectedScopeTPData
    if(selectedFormScopeData){
      selectedFormScopeData=selectedFormScopeData.map(filterAndCreateScopeData)
    }
    const tpRequestObject={targetPathwayData: [{'nzcTargetPlanTargetData':selectedFormScopeData, 'targetPathwayNames': customTargetPathwayName,'showTargetProgress':showTargetProgress, 'progressTargetYear':progressTowardsTargetYear }], nzcScopeId: props.nzcScopeId, intensity: graphIntensity}
    addTargetPathWayMutation.mutateAsync(tpRequestObject).then(newTargetCreated => {
      if (newTargetCreated) {
        props.refetchTargetPathwayData().then(() => {
          newCreatedOrEditedTPNumber.current = getTPNumber()
          setInputTextTouched(false)
          updateScopeTargetMode({
            tpMode:  SCOPE_TARGET_MODE.view,
            selectedTPName: `tp${getTPNumber()}`,
            activeIndex: getTPNumber()-1,
            loadingTPData: false
          })
        })
        props.getNzcSettingsData.refetch()
        props.updateTargetsAndPathwayDetails({selectedTP:`tp${getTPNumber()}`})
        setTargetPathwayDetailsObj(prevState => {
          return {
            ...prevState,
            selectedTP: `tp${getTPNumber()}`,
            progressTowardsTargetYear: progressTowardsTargetYear,
            showProgressTowardsTargetYear: showTargetProgress
          }
        })
      }
    }).catch(error => {
      const errorResponse = (error.response && error.response.data) ? `Error: ${error.response.data.title}` : error.toString()
      setErrorDialogData({title: I18n(errorMsgKey), message: errorResponse})
      setErrorDialogVisible(true)
      props.setGraphDataLoading(false)
    }).finally(()=>{
      resetTabHide()
    })
    props.setNZCGraphData(null)
    props.setGraphDataLoading(true)
  }

  const onSubmit = async () => {
    if (buttonRef.current) {
      let classList = buttonRef.current.classList.value
      classList = `${classList} button-disabled`
      buttonRef.current.classList = classList
    }
    setDeletingTPTabIndex(null)
    await createTargetPathway()
  }

  function resetTabHide() {
    updateTargetPathwayFormState({
      tab1Class:placeholderString,
      tab2Class:placeholderString,
      tab3Class:placeholderString,
      tabExpanded: false
    })
  }

  function resetScopeTPMode() {
    if (props.scopeTargetState.tpMode === SCOPE_TARGET_MODE.edit) {
      updateScopeTargetMode({
        tpMode: props.scopeTargetState.prevTpMode,
        selectedTPName: props.scopeTargetState.prevActiveIndex === props.scopeTargetState.activeIndex ? `tp${Number(props.scopeTargetState.activeIndex + 1)}` : `tp${props.scopeTargetState.prevActiveIndex + 1}`,
        activeIndex: props.scopeTargetState.prevActiveIndex === props.scopeTargetState.activeIndex ? props.scopeTargetState.activeIndex : props.scopeTargetState.prevActiveIndex,
        prevActiveIndex: null,
        prevTpMode: null,
        loadingTPData:false})
      setTargetPathwayDetailsObj({ ...targetPathwayDetailsObj, selectedTP: props.scopeTargetState.prevActiveIndex !== null ? `tp${props.scopeTargetState.prevActiveIndex + 1}`: null})
      checkingForDisimilarities()
    } else {
      const tpFromNzcSettingsData = props?.nzcSettingsData?.targetPathway
      updateScopeTargetMode({
        tpMode: props?.nzcSettingsData?.targetPathway ? SCOPE_TARGET_MODE.view : null,
        selectedTPName: tpFromNzcSettingsData ? `tp${Number(tpFromNzcSettingsData)}` : null,
        activeIndex: tpFromNzcSettingsData ? Number(tpFromNzcSettingsData) - 1 : null,
        loadingTPData: false
      })
    }
  }

  function cancelHandler() {
    resetTabHide()
    resetScopeTPMode()
    setInputTextTouched(false)
    updateNZCTPData()
  }

  function updateValidateTotalReductionState(state) {
    validateTotalReductionsRef.current=state
  }

  function onChange(event, selectedScopeTPData, inputFieldName, scopeTPIndex, selectedScopeTP) {
    setIsEdit({
      ...isEdit,
      layer_button_2: true
    })
    if(selectedScopeTPData){
      selectedScopeTP.percentage=event.value
      selectedScopeTPData[scopeTPIndex]=selectedScopeTP
      updateTargetPathwayFormState({selectedScopeTPData: selectedScopeTPData})
    }
    updateValidateTotalReductionState(false)
  }

  function onBlur(blurEvent, selectedScopeTPData, inputFieldName, selectedScopeTP, scopeTPIndex) {
    const cumulativeInputElement = targetFormItemsRef.current.querySelector(`#cumulative-input-${inputFieldName}`)
    let value = blurEvent.target.value
    value = value.replace('%.', '')
    if(!value){
      value='0%'
    }
    blurEvent.target.value=value
    let fieldValue = selectedScopeTP.percentage
    let totalReductions = getScopePathwayTotal(selectedScopeTPData)
    let diffValue = fieldValue
    if (fieldValue && totalReductions >= 100) {
      diffValue = 100 - (totalReductions - fieldValue)
      selectedScopeTP.percentage = diffValue
      selectedScopeTPData[scopeTPIndex] = selectedScopeTP
      updateValidateTotalReductionState(true)
      updateTargetPathwayFormState({selectedScopeTPData: selectedScopeTPData})
      isDisabledRef.current.show(blurEvent, cumulativeInputElement)
    }
  }

  function getScopePathwayTotal(currentScopeTPData) {
    let filteredScopeTP = currentScopeTPData.filter(scopeTP => !!scopeTP.percentage)
    const scopeTPPercentages=filteredScopeTP.map(scopeTP=>scopeTP.percentage)
    let totalScopeTPPercentages=0
    if(scopeTPPercentages && scopeTPPercentages.length > 0){
      totalScopeTPPercentages=scopeTPPercentages.reduce((previousValue, currentValue) => previousValue + currentValue)
    }
    return totalScopeTPPercentages
  }

  function inputFieldDisable(selectedScopeTP, selectedScopeTPData) {
    let inputFieldValue=selectedScopeTP.percentage
    let totalReductions = getScopePathwayTotal(selectedScopeTPData)
    return (!inputFieldValue && totalReductions >= 100 && validateTotalReductionsRef.current)
  }

  function getInputBorderStyle(selectedScopeTP, selectedScopeTPData) {
    let inputBorderStyle='input-border'
    if (inputFieldDisable(selectedScopeTP, selectedScopeTPData)) {
      inputBorderStyle='input-error-border'
    }
    return inputBorderStyle
  }

  function getInputFieldProps(selectedScopeTPData, name, selectedScopeTP, scopeTPIndex) {
    return {
      name: name,
      key: `reduction-${name}`,
      className: `reduction-input-number ${getInputBorderStyle(selectedScopeTP, selectedScopeTPData)}`,
      mode: 'decimal',
      maxFractionDigits: 1,
      suffix: '%',
      min: 0,
      max: 100,
      disabled: inputFieldDisable(selectedScopeTP, selectedScopeTPData),
      value: selectedScopeTP.percentage,
      onBlur: (e) => onBlur(e, selectedScopeTPData, name, selectedScopeTP, scopeTPIndex),
      onValueChange: (e) => onChange(e, selectedScopeTPData, name, scopeTPIndex, selectedScopeTP),
      onChange: (e) => onChange(e, selectedScopeTPData, name, scopeTPIndex, selectedScopeTP),
    }
  }

  function getCumulativeReductionForField(selectedScopePTData, endYear) {
    const initialValue = 0
    let cumulativeSum = initialValue
    const [fieldValue] = selectedScopePTData.filter(scopeTP=>scopeTP.targetYear === endYear)
    if(fieldValue?.percentage){
      cumulativeSum= (selectedScopePTData?.filter(scopeTP => scopeTP.targetYear <= endYear).map(scopeTP => scopeTP.percentage)
        .reduce((previousValue, currentValue) => previousValue + currentValue, initialValue))
    }
    return cumulativeSum
  }

  function getDisabledInputFieldProps(className, value, inputFieldName, inputFieldId) {
    return {
      id: inputFieldId,
      name: inputFieldName,
      key: `cumulative-${inputFieldName}`,
      className: className,
      mode: 'decimal',
      maxFractionDigits: 1,
      suffix: '%',
      min: 0,
      max: 100,
      value: value,
      disabled: true,
    }
  }

  function isCreateOrEditMode() {
    return (props.scopeTargetState.tpMode===SCOPE_TARGET_MODE.create || props.scopeTargetState.tpMode===SCOPE_TARGET_MODE.edit)
  }

  function getFieldElements(selectedScopeData, startYear, index, yearIncrement) {
    let currentSPData=selectedScopeData[index]
    let inputFieldName = currentSPData?.targetYear
    let targetYearEnd = parseInt((startYear).toString().slice(2))
    return(
      <div className={'target-input-container'} key={`target-input-container-${inputFieldName}`}>
        <div className={'input-year'} key={`input-year-${inputFieldName}`}>{`${startYear} - ${targetYearEnd+yearIncrement}`}</div>
        <div className={'input-reduction'} key={`input-reduction-${inputFieldName}`}>
          {isCreateOrEditMode()? <InputNumber {...getInputFieldProps(selectedScopeData, inputFieldName, currentSPData, index)} />:
            <InputNumber {...getDisabledInputFieldProps('reduction-value input-number-disabled', currentSPData.percentage, inputFieldName, `reduction-input-${inputFieldName}`)} />
          }
        </div>
        <OverlayPanel  className="info-cumulative" ref={isDisabledRef} showCloseIcon style={{width: '14em'}}>
          <div className="help-block">
            {getLocalizedValue(selectedLanguage, 't_warning')}
          </div>
          <div className="help-text">{getLocalizedValue(selectedLanguage, 't_target_warning')}</div>
        </OverlayPanel>
        <div className={'input-cumulative'} key={`input-cumulative-${inputFieldName}`}
        >
          <InputNumber {...getDisabledInputFieldProps('input-number-cumulative input-number-disabled', getCumulativeReductionForField(selectedScopeData, startYear), inputFieldName, `cumulative-input-${inputFieldName}`)}
          />
        </div>
      </div>
    )
  }

  function addFieldsForMilestoneYears(inputFields, selectedScopeData, startYear, index) {
    const milestoneYearIncrement=5
    inputFields.push(<div className={'milestone-years'}>{I18n('t_milestone_years')}</div>)
    inputFields.push(getFieldElements(selectedScopeData, startYear, index, milestoneYearIncrement))
    startYear+=milestoneYearIncrement
    index++
    inputFields.push(getFieldElements(selectedScopeData, startYear, index, milestoneYearIncrement))
    startYear+=milestoneYearIncrement
    index++
    inputFields.push(getFieldElements(selectedScopeData, startYear, index, 10))
    return inputFields
  }

  function getTPDataTable(selectedScopeData) {
    let inputFields = []
    if(selectedScopeData && selectedScopeData.length>0){
      let index=0
      let startYear = selectedScopeData[index].targetYear
      const endYear=getTPEndYear(startYear)
      const yearIncrement=1
      while (startYear <= endYear) {
        inputFields.push(getFieldElements(selectedScopeData, startYear, index, yearIncrement))
        startYear++
        index++
      }
      // For milestone years
      inputFields=addFieldsForMilestoneYears(inputFields, selectedScopeData, startYear, index)
    }
    return inputFields
  }

  function dlgCloseHandler() {
    setErrorDialogVisible(false)
  }

  function getTPNumber() {
    let tpNumber
    if(props.scopeTargetState.tpMode===SCOPE_TARGET_MODE.create){
      tpNumber=getTPNumberForCreateAction()
    } else if(props.scopeTargetState.tpMode===SCOPE_TARGET_MODE.edit){
      tpNumber=props.scopeTargetState.activeIndex+1
    }
    return tpNumber
  }

  function getFormContentViewElements(selectedScopePathwayData) {
    const tpNumber=getTPNumber()
    if (tpNumber && customTargetPathwayName[`tp${tpNumber}`] === undefined) {
      let defaultTPName = getDefaultTPName(tpNumber, selectedLanguage)
      let updatedTPNameObject = customTargetPathwayName
      updatedTPNameObject[`tp${getTPNumber()}`] = defaultTPName
      setCustomTargetPathwayName({...updatedTPNameObject})
    }

    function handleTPCustomNameInput(e,tabIndex) {
      e.preventDefault()
      let updatedTPName = customTargetPathwayName
      setIsEdit({
        ...isEdit,
        layer_button_2: true
      })
      updatedTPName[`tp${tabIndex}`] = e.target.value
      setCustomTargetPathwayName({...updatedTPName})
    }
    const hasTpPercentageGreaterThanZero = selectedScopePathwayData?.some(item => item.percentage > 0)

    return(
      <>
        <div className={'target-form-content'} data-testid="target-form-content">
          {isCreateOrEditMode() &&
              <div className='tp-name-and-validation-errors'>
                <InputText
                  name='target-pathway-name'
                  id='tp-name'
                  className={`tp-name tp-label-border ${customTPNameError.isTPNameError && 'tp-name-error'}`}
                  value={customTargetPathwayName[`tp${getTPNumber()}`]}
                  onChange={(e) => handleTPCustomNameInput(e,getTPNumber())}
                  onFocus={() => {setInputTextTouched(true)}}
                />
                {customTPNameError.isTPNameError &&
                    customTPNameError.errors.map((err, index) => {
                      return <p className='tp-validation-errors' key={index} data-testid='tp-name-error'>{err}</p>
                    })}
              </div>
          }
          <div className={'target-form-input-labels'}>
            <div className={'form-label-empty'}/>
            <div className={'form-label-reduction'}>
              <div className={'label-reduction-text'}>{I18n('t_reduction')} (%)</div>
            </div>
            <div className={'form-label-cumulative'}>
              <div className={'label-reduction-text'}>{I18n('t_cumulative')} (%)</div>
            </div>
          </div>
          <div className={'target-form-input-container'} ref={targetFormItemsRef}>
            {getTPDataTable(selectedScopePathwayData)}
          </div>
          {isCreateOrEditMode() ?
            <div className={'fund-nzc-apply-cancel tp-form-submission-container'}>
              <div className="footer-btn-apply">
                <Button
                  content='t_apply'
                  className={'btn-apply button-highlight form-apply-btn'}
                  ref={buttonRef}
                  onClick={() => onSubmit()}
                  disabled={!isEdit.layer_button_2 || !isReductionDataNotEmpty() || customTPNameError.isTPNameError || customTargetPathwayName[`tp${getTPNumber()}`]?.length === 0 || !hasTpPercentageGreaterThanZero}
                  data-testid='add-or-edit-tp'
                />
              </div>
              <div className="footer-btn-cancel form-cancel"><label className={'btn-cancel button-highlight form-cancel-label'} onClick={cancelHandler}>{I18n('t_cancel')}</label></div>
            </div> : ''
          }
        </div>
      </>
    )
  }

  function addAnotherTargetHandler() {
    updateValidateTotalReductionState(false)
    props.updateScopeTargetState({tpMode:SCOPE_TARGET_MODE.create})
    updateTargetPathwayFormState({selectedScopeTPData:[...getNewTPDataObject()]})
    setIsEdit({
      ...isEdit,
      layer_button_2: true
    })
  }

  function preventEvent(event) {
    event.preventDefault()
    event.stopPropagation()
  }

  function updateScopeTargetMode(newScopeTargetMode) {
    props.updateScopeTargetState(newScopeTargetMode)
  }

  const updateStateForTarget = (prevTpMode, prevIndex, newTPAction, tabIndex, selectedTP, isDeleteAction) => {
    props.updateScopeTargetState({
      tpMode: (prevTpMode === newTPAction && prevIndex === tabIndex ? null : newTPAction),
      selectedTPName: isDeleteAction ? null : selectedTP,
      activeIndex: isDeleteAction ? -1 : tabIndex,
      prevActiveIndex: newTPAction === SCOPE_TARGET_MODE.edit ? prevIndex : null,
      prevTpMode: newTPAction === SCOPE_TARGET_MODE.edit ? prevTpMode : null
    })
  }

  function updateScopeTargetActions(newTPAction, tabIndex, selectedTP) {
    const prevTpMode=props.scopeTargetState.tpMode
    const prevIndex=props.scopeTargetState.activeIndex
    previousActiveIndex.current= prevIndex
    const isDeleteAction = newTPAction === SCOPE_TARGET_MODE.delete
    if (prevTpMode === newTPAction && prevIndex === tabIndex) { // Same TP clicked twice/toggle off the currently displayed TP
      setTargetPathwayDetailsObj(prevState => {
        return {
          ...prevState,
          selectedTP: null,
          showProgressTowardsTargetYear: false,
          progressTowardsTargetYear: YEAR_2030
        }
      })
      SessionUserState.saveUserDataToSession('strandingYear', '-')
      props?.updateStrandingYear(null)

    } else if (isDeleteAction && getActiveIndex() ===  tabIndex + 1) {
      updateStateForTarget(prevTpMode, prevIndex, newTPAction, tabIndex, selectedTP, isDeleteAction)
      return
    } else {
      let showProgressTowardsTargetYear
      let progressTowardsTargetYear
      if (newTPAction !== SCOPE_TARGET_MODE.edit) {
        showProgressTowardsTargetYear = false
        progressTowardsTargetYear = YEAR_2030
      }
      else{
        if(tabIndex+1 !==  props.nzcSettingsData.targetPathway ){
          showProgressTowardsTargetYear= false
          progressTowardsTargetYear= YEAR_2030
        }
        else{
          showProgressTowardsTargetYear= props.nzcSettingsData.showTargetProgress
          progressTowardsTargetYear= props.nzcSettingsData.progressTargetYear
        }
      }
      let strandingYear = getStrandingYear(tpDetails[selectedTP])
      SessionUserState.saveUserDataToSession('strandingYear',strandingYear)
      props?.updateStrandingYear(strandingYear)
      setTargetPathwayDetailsObj(prevState => {
        return {
          ...prevState,
          selectedTP: selectedTP,
          showProgressTowardsTargetYear: showProgressTowardsTargetYear,
          progressTowardsTargetYear: progressTowardsTargetYear
        }
      })
    }
    props.updateTargetsAndPathwayDetails({showProgressTowardsTargetYear: false, progressTowardsTargetYear: YEAR_2030})
    updateStateForTarget(prevTpMode, prevIndex, newTPAction, tabIndex, selectedTP, isDeleteAction)
  }

  function getScopeTPName(tabIndex) {
    return(`tp${tabIndex+1}`)
  }

  function tpIconViewHandler(event, tabIndex) {
    const selectedTP=getScopeTPName(tabIndex)
    preventEvent(event)
    updateScopeTargetActions(SCOPE_TARGET_MODE.view, tabIndex, selectedTP)
  }

  function tpIconEditHandler(event, tabIndex) {
    updateValidateTotalReductionState(true)
    const selectedTP=getScopeTPName(tabIndex)
    const settingsTP = props?.nzcSettingsData?.targetPathway !== null  ?`tp${props?.nzcSettingsData?.targetPathway}`: null
    setIsEdit({
      ...isEdit,
      layer_button_2: selectedTP !== settingsTP
    })
    let selectedTPData=targetPathwayFormState[`scopeTP${tabIndex+1}`]
    selectedTPData=selectedTPData.map(filterAndCreateScopeData)
    preventEvent(event)
    updateScopeTargetActions(SCOPE_TARGET_MODE.edit, tabIndex, selectedTP)
    updateTargetPathwayFormState({selectedScopeTPData:[...selectedTPData]})
    targetPathWayData.current = props?.targetPathwayData?.scopeTargets?.filter(scopeTarget=>scopeTarget.targetYear>=props?.reportingYear + 1)
  }

  function tpIconDeleteHandler(event, tabIndex) {
    setDeletingTPTabIndex(tabIndex)
    preventEvent(event)
    updateTargetPathwayFormState({showConfirmModal: true, tabIndex})
  }

  function getViewIconClassName(tabIndex) {
    let viewIconClassName='eye-off-lined'
    if((props.scopeTargetState.tpMode===SCOPE_TARGET_MODE.view && (props.scopeTargetState.activeIndex===tabIndex))){
      viewIconClassName='eye-on-lined'
    }
    return viewIconClassName
  }

  function handleProgressTowardsTargetDropdown(e) {
    setTargetPathwayDetailsObj(prevState => {
      return {
        ...prevState, progressTowardsTargetYear: e.value
      }
    })
  }

  function tabOpenHandler(tabOpenEvent) {
    let tabHeaderHide={}
    if(tabOpenEvent===0){
      tabHeaderHide={tab2Class:cssClassHideTab, tab3Class:cssClassHideTab}
    } else if(tabOpenEvent===1){
      tabHeaderHide={tab1Class:cssClassHideTab, tab3Class:cssClassHideTab}
    } else if(tabOpenEvent===2){
      tabHeaderHide={tab1Class:cssClassHideTab, tab2Class:cssClassHideTab}
    }
    updateTargetPathwayFormState({tabExpanded: true, activeIndex: tabOpenEvent, ...tabHeaderHide})
  }

  function tabCloseHandler() {
    updateTargetPathwayFormState({tabExpanded: false, activeIndex: null, tab1Class:placeholderString,tab2Class:placeholderString, tab3Class:placeholderString})
  }

  function getTPPanelElements() {

    function progressTargetYear(year,checkboxStatus){
      return <div className={'progress-towards-target'} data-testid='progress-towards-target'>
        <Checkbox inputId="progress-towards-target-checkbox"
          checked={checkboxStatus}
          className="progress-towards-target-checkbox" name={'show-progress-towards-target-checkbox'}
          onChange={handleShowProgressTowardsTargetCheckboxSelection}/>
        <label htmlFor="progress-towards-target-checkbox" className='progress-towards-target-label' data-testid='show_progress'>{I18n('t_show_progress_towards_target_for')}</label>
        <Dropdown
          options={progressTowardsTargetYearOptions}
          value={year}
          className="progress-towards-target-dropdown"
          itemTemplate={getDropdownItemTemplate}
          panelClassName={'dropdown-overlay-panel-white p-dropdown-panel-no-top-border target-pathway-dropdown'}
          name="progress-towards-target-dropdown"
          placeholder={year}
          disabled={!checkboxStatus}
          onChange={handleProgressTowardsTargetDropdown}
        />
      </div>
    }

    function panelHeaderTemplate(options,tabIndex,progressTargetYearFunction,strandingYear, tpName){
      const toggleIcon = options.collapsed ? 'pi pi-chevron-right' : 'pi pi-chevron-up'
      const className = `${options.className} justify-content-start`
      const showProgressTargetYear = !isCreateOrEditMode() && !targetPathwayFormState?.tabExpanded && targetPathwayNumbersArray.length>0 && (getScopeTPName(tabIndex) === targetPathwayDetailsObj.selectedTP)
      return(
        <div className={`panel-header ${className}`}>
          <button className={options.togglerClassName} onClick={options.onTogglerClick}>
            <span className={toggleIcon}/>
          </button>
          <span className={'header-title'} >{tpName}</span>
          <i className={getViewIconClassName(tabIndex)} onClick={(e)=>tpIconViewHandler(e, tabIndex)} data-testid='tp-eye'/>
          <i className="pencil-2-line" onClick={(e)=>tpIconEditHandler(e, tabIndex)} />
          <i className="trash-3-line" onClick={(e)=>tpIconDeleteHandler(e, tabIndex)} />
          <div className='line-break'/>
          {!isNaN(strandingYear) && <span className='tp-stranding-year'>{I18n('t_tp_stranding_year')}{strandingYear}</span>}
          <div className='line-break'/>
          { showProgressTargetYear ? progressTargetYearFunction: null }
        </div>
      )
    }

    function progressTargetYearForTP(selectedTP, tabIndex) {
      if (selectedTP === null || selectedTP === undefined ) {
        if (tabIndex + 1 === props?.nzcSettingsData?.targetPathway) {
          return progressTargetYear(targetPathwayDetailsObj.progressTowardsTargetYear, targetPathwayDetailsObj.showProgressTowardsTargetYear)
        }
        return null
      } else {
        return progressTargetYear(targetPathwayDetailsObj.progressTowardsTargetYear, targetPathwayDetailsObj.showProgressTowardsTargetYear)
      }
    }

    function getATabHeaderTemplate0(options,tabIndex) {
      let strandingYear = targetPathwayFormState.strandingYearTP1
      return panelHeaderTemplate(options,tabIndex,progressTargetYearForTP(targetPathwayDetailsObj.selectedTP,tabIndex),strandingYear,customTargetPathwayName[getScopeTPName(tabIndex)])
    }

    function getATabHeaderTemplate1(options,tabIndex) {
      let strandingYear = targetPathwayFormState.strandingYearTP2
      return panelHeaderTemplate(options,tabIndex,progressTargetYearForTP(targetPathwayDetailsObj.selectedTP,tabIndex),strandingYear,customTargetPathwayName[getScopeTPName(tabIndex)])
    }
    function getATabHeaderTemplate2(options,tabIndex) {
      let strandingYear = targetPathwayFormState.strandingYearTP3
      return panelHeaderTemplate(options,tabIndex,progressTargetYearForTP(targetPathwayDetailsObj.selectedTP,tabIndex),strandingYear,customTargetPathwayName[getScopeTPName(tabIndex)])
    }

    return(
      <>
        <div className={'existing-target-pathways'}>
          {targetPathwayFormState.scopeTP1?.length > 0 &&
            <Panel className={targetPathwayFormState.tab1Class} toggleable
              onExpand={()=>tabOpenHandler(0)}
              headerTemplate={(options) => getATabHeaderTemplate0(options, 0)}
              onCollapse={()=>tabCloseHandler()}
              collapsed={true}
            >
              {getFormContentViewElements(targetPathwayFormState.scopeTP1)}
            </Panel>
          }
          {targetPathwayFormState.scopeTP2?.length > 0 &&
            <Panel className={targetPathwayFormState.tab2Class} toggleable
              onExpand={()=>tabOpenHandler(1)}
              headerTemplate={(options) => getATabHeaderTemplate1(options, 1)}
              onCollapse={()=>tabCloseHandler()}
              collapsed={true}
            >
              {getFormContentViewElements(targetPathwayFormState.scopeTP2)}
            </Panel>
          }
          {targetPathwayFormState.scopeTP3?.length > 0 &&
            <Panel className={targetPathwayFormState.tab3Class} toggleable
              onExpand={()=>tabOpenHandler(2)}
              headerTemplate={(options) => getATabHeaderTemplate2(options, 2)}
              onCollapse={()=>tabCloseHandler()}
              collapsed={true}
            >
              {getFormContentViewElements(targetPathwayFormState.scopeTP3)}
            </Panel>
          }
        </div>
      </>
    )
  }

  function getTPEndYear(startYear) {
    return(startYear+5)
  }

  function getTPNumberForCreateAction() {
    let tpNumber
    if (!targetPathwayFormState.scopeTP3) {
      tpNumber = 3
    }
    if (!targetPathwayFormState.scopeTP2) {
      tpNumber = 2
    }
    if (!targetPathwayFormState.scopeTP1) {
      tpNumber = 1
    }
    return tpNumber
  }

  function getScopeDataObject(startYear, yearIncrement, tpNumber) {
    return {
      ScopeId: props.nzcScopeId,
      targetYear: startYear,
      targetYearIncrement: yearIncrement,
      percentage: 0,
      targetPathwayNumber: tpNumber
    }
  }

  function getNewTPDataObject() {
    const tpNumber=getTPNumberForCreateAction()
    let newTPDataObject = []
    if (props.nzcScopeId) {
      let startYear = props.reportingYear + 1
      const endYear = getTPEndYear(startYear)
      while (startYear <= endYear) {
        newTPDataObject.push(getScopeDataObject(startYear, 1, tpNumber))
        startYear++
      }
      // For milestone years
      newTPDataObject.push(getScopeDataObject(startYear, 5, tpNumber))
      startYear+=5
      newTPDataObject.push(getScopeDataObject(startYear, 5, tpNumber))
      startYear+=5
      newTPDataObject.push(getScopeDataObject(startYear, 10, tpNumber))
    }
    return newTPDataObject
  }

  function isFundScopeTargetsNotEmpty() {
    return isScopeTargetsNotEmpty(props.targetPathwayData)
  }

  function getTargetBodyContentElements() {
    let targetBodyContent
    const tpMode=props.scopeTargetState?.tpMode
    if(isCreateOrEditMode()){
      targetBodyContent=getFormContentViewElements(targetPathwayFormState.selectedScopeTPData)
    } else if(isFundScopeTargetsNotEmpty() && (tpMode!==SCOPE_TARGET_MODE.edit && tpMode!==SCOPE_TARGET_MODE.create)){
      targetBodyContent=getTPPanelElements()
    }
    return targetBodyContent
  }

  function closeConfirmDialog() {
    setDeletingTPTabIndex(null)
    updateTargetPathwayFormState({showConfirmModal: false})
  }

  function getActiveIndex() {
    return targetPathwayFormState.tabIndex + 1
  }

  function confirmDialogFooter(){
    return(
      <div className="delete-footer">
        <div className="flex justify-content-center pt-0">
          <div className="pr-2">
            <Button 
              content='t_no'
              outlined={true} 
              onClick={()=>closeConfirmDialog()}
            />
          </div>
          <div>
            <Button 
              content='t_yes'
              onClick={()=>deleteScopeTarget(getActiveIndex())}
            />
          </div>
        </div>
      </div>
    )
  }

  function getDeletePathwayModalTitle() {
    return `${getLocalizedValue(selectedLanguage, 't_delete_target_pathway_title')} ${getActiveIndex()}`
  }

  function handleEnergySavingsActionCheckbox(event) {
    const applicableActions=(event.checked && !agreedActionsNotEmpty(props.actionBasedTPDetails) && applicableActionsNotEmpty(props.actionBasedTPDetails)) ? event.checked : false
    const agreedActions= agreedActionsNotEmpty(props.actionBasedTPDetails)? event.checked: false
    setTargetPathwayDetailsObj(prevState => {
      return{
        ...prevState,
        applicableActions: applicableActions,
        agreedActions: agreedActions,
        energySavingActions: event.checked
      }
    })
    props.updateTargetsAndPathwayDetails({energySavingActions: event.checked})
  }

  function handleSelectedActionBasedPathway(event) {
    if (event.target.name === 'agreed-action') {
      setTargetPathwayDetailsObj(prevState => {
        return {
          ...prevState,
          agreedActions: event.checked ,
          energySavingActions: (event.checked || prevState.applicableActions)
        }
      })
    } else if(event.target.name === 'applicable-action') {
      setTargetPathwayDetailsObj(prevState => {
        return {
          ...prevState,
          applicableActions: event.checked,
          energySavingActions: (event.checked || prevState.agreedActions)
        }
      })
    }
  }

  function  handleSelectedScienceBasedTarget(event) {
    let selectedSBTOption = event.target.name==='SBT-option-1' ? 1.5 : 2
    setTargetPathwayDetailsObj(prevState => {
      return{
        ...prevState,
        scienceBasedTargetOption: selectedSBTOption,
      }
    })
    SessionUserState.saveUserDataToSession('sbt', selectedSBTOption)
  }

  async function submitTargetPathwayData() {
    let targetPathwayNumber
    if(isEdit.layer_button_2 && getTPNumber()) {
      targetPathwayNumber = parseInt(getTPNumber())
    } else if(targetPathwayDetailsObj.selectedTP) {
      targetPathwayNumber = parseInt(targetPathwayDetailsObj.selectedTP.substring(2))
    } else {
      targetPathwayNumber = null
    }

    const settings = {
      baseLineYear: props?.baselineYear,
      energySavingActions:targetPathwayDetailsObj.energySavingActions,
      applicableActions:targetPathwayDetailsObj.applicableActions,
      agreedActions:targetPathwayDetailsObj.agreedActions,
      showTargetProgress: isEdit.layer_button_2 && getTPNumber() ? false : targetPathwayDetailsObj.showProgressTowardsTargetYear,
      progressTargetYear: isEdit.layer_button_2 && getTPNumber() ? YEAR_2030 : targetPathwayDetailsObj.progressTowardsTargetYear,
      targetPathway: targetPathwayNumber,
      scienceBasedTarget: targetPathwayDetailsObj.scienceBasedTargetOption,
      showTopDownPathways: targetPathwayDetailsObj.showTopDownPathways,
      targetPathwayNames: JSON.stringify(customTargetPathwayName)
    }

    await updateNzcSettingsMutation.mutateAsync({scopeId, settings, graphIntensity}).then(() => {
      props.refetchTargetPathwayData()
      removeRequiredSessionStorageCache()
      props.getNzcSettingsData.refetch()
      props.updateTargetsAndPathwayDetails(targetPathwayDetailsObj)
      setIsEdit({
        ...isEdit,
        layer_button_1: false
      })
    })
  }

  function cancelTargetPathwayViewDetailsUpdate(){
    removeRequiredSessionStorageCache()
    let revertedBackTPDetails = {
      showProgressTowardsTargetYear: props?.nzcSettingsData?.showTargetProgress,
      energySavingActions: props?.nzcSettingsData?.energySavingActions,
      agreedActions: props?.nzcSettingsData?.agreedActions,
      applicableActions: props?.nzcSettingsData?.applicableActions,
      progressTowardsTargetYear: props?.nzcSettingsData?.progressTargetYear,
      selectedTP: props?.nzcSettingsData?.targetPathway ?`tp${props?.nzcSettingsData?.targetPathway}`: null,
      scienceBasedTargetOption: props?.nzcSettingsData?.scienceBasedTarget,
      showTopDownPathways: props?.nzcSettingsData?.showTopDownPathways
    }
    setTargetPathwayDetailsObj(prevState => {
      return{
        ...prevState,
        ...revertedBackTPDetails
      }
    })
    props.updateScopeTargetState({
      tpMode: props?.nzcSettingsData?.targetPathway ?'view' : null,
      selectedTPName:props?.nzcSettingsData?.targetPathway ? `tp${Number(props?.nzcSettingsData?.targetPathway)}`:null,
      activeIndex: props?.nzcSettingsData?.targetPathway ?Number(props?.nzcSettingsData?.targetPathway) -1:null,
      loadingTPData:false})
    props.updateTargetsAndPathwayDetails(revertedBackTPDetails)
    setIsEdit({
      ...isEdit,
      layer_button_1: false
    })
  }

  function handleQuestionMarkClick(event, tpType) {
    if(tpType==='action-based-tp'){
      setTargetPathwayOPText(I18n('t_action_based_target_pathway'))
      actionBasedTargetPathwayOP.current.toggle(event)
    }
    if(tpType==='science-based-tp'){
      setTargetPathwayOPText(getLocalizedValue(selectedLanguage, 't_science_based_target_pathway'))
      scienceBasedTargetPathwayOP.current.toggle(event)
    }
    if(tpType==='custom-based-tp'){
      setTargetPathwayOPText(I18n('t_custom_target_pathway'))
      customBasedTargetPathwayOP.current.toggle(event)
    }
  }

  function handleShowProgressTowardsTargetCheckboxSelection(event) {
    setTargetPathwayDetailsObj(prevState => {
      return{
        ...prevState,
        showProgressTowardsTargetYear: event.checked,
        progressTowardsTargetYear: targetPathwayDetailsObj.progressTowardsTargetYear
      }
    })
  }

  function applyFundNoEnergySavingsActionFilter() {
    applyNoEnergySavingsActionFilter(props?.updateUrlQueryParams, getQueryValuesForNZCAssets, selectedLanguage)
  }

  function getOverlayPanel(tpRef, id) {
    function getAssetsInfoWithNoAgreedActions() {
      return  <div className='action-based-tp-data'>
        <img src={caution} alt="caution icon"/>
        <div className='asset-count-filter'>
          <span className='asset-count'>{I18n('t_assets_info_with_no_agreed_actions', {0: assetCountWithNoAgreedActions, 1:props?.actionBasedTPDetails?.totalAssets })}</span>          <div className="view-assets" onClick={applyFundNoEnergySavingsActionFilter}>
            {getLocalizedValue(selectedLanguage, 't_apply_filter_view_these_assets')}
          </div>
        </div>
      </div>
    }

    function getAssetInfoWithEnergySavingAction() {
      return  <div className='action-based-tp-data'>
        <img src={approved} alt="approved icon"/>
        <div className='asset-count-filter'>
          <span className='asset-count'>{I18n('t_assets_info_with_agreed_actions', {0:props?.actionBasedTPDetails?.totalAssets })}</span>        </div>
      </div>
    }

    return <>
      <OverlayPanel className='question-mark-op' appendTo={document.getElementById(id)} ref={tpRef} showCloseIcon style={{width: '14em'}}>
        <div className={`question-mark-op-text ${assetCountWithNoAgreedActions > 0 && id}-1`}>
          <span data-testid='tp-details-overlaypanel'>{targetPathwayOPText}</span>
          {id === 'action-based-title-icon' ? assetCountWithNoAgreedActions > 0 ? getAssetsInfoWithNoAgreedActions() : getAssetInfoWithEnergySavingAction() : null }
        </div>
      </OverlayPanel>
    </>
  }

  const getActionBasedTargetPathways = () =>{
    return <>
      <div className='action-based-pathways'>
        <div className='action-based-title-icon' id={actionBasedPathwayId}>
          <label htmlFor="target-pathway-sub-titles" className='target-pathway-sub-titles'
            data-testid='action-based-tp'>{I18n('t_action_based_pathways')}</label>
          <img src={questionMarkIcon} alt="" className='question-mark-icon'
            data-testid='action-based-tp-question-mark'
            onClick={(event) => handleQuestionMarkClick(event, 'action-based-tp')}/>
        </div>
        <div className='energy-saving-action' data-testid="energy-saving-action" role='energy-saving-action'>
          <Checkbox
            inputId="energy-saving-action-checkbox"
            checked={targetPathwayDetailsObj.energySavingActions && actionsDataNotEmpty(props.actionBasedTPDetails)}
            onChange={handleEnergySavingsActionCheckbox}
            name='energy-saving-action'
            disabled={!actionsDataNotEmpty(props.actionBasedTPDetails)}
          />
          <label htmlFor="energy-saving-action-checkbox"
            className='energy-saving-action-label'>{I18n('t_energy_saving_action')}</label>
        </div>
        {targetPathwayDetailsObj.energySavingActions && actionsDataNotEmpty(props.actionBasedTPDetails) &&
            <>
              <div className='agreed-action' role='agreed-actions' data-testid="agreed-actions-checkbox">
                <Checkbox
                  inputId="agreed-action-checkbox"
                  name='agreed-action'
                  checked={!!(targetPathwayDetailsObj.agreedActions && agreedActionsNotEmpty(props.actionBasedTPDetails))}
                  onChange={handleSelectedActionBasedPathway}
                  disabled={!agreedActionsNotEmpty(props.actionBasedTPDetails)}
                />
                <label htmlFor="agreed-action-checkbox" className="agreed-action-label"
                  data-testid="agreed-actions">{I18n('t_agreed_actions_count', {0: props?.actionBasedTPDetails?.agreedActions})}</label>
              </div>
              <div className='applicable-action' data-testid="applicable-action-checkbox">
                <Checkbox
                  inputId="applicable-action-checkbox"
                  name='applicable-action'
                  checked={!!(targetPathwayDetailsObj.applicableActions && applicableActionsNotEmpty(props.actionBasedTPDetails))}
                  onChange={handleSelectedActionBasedPathway}
                  disabled={!applicableActionsNotEmpty(props.actionBasedTPDetails)}
                />
                <label htmlFor="applicable-action-checkbox" className="applicable-action-label"
                  data-testid="applicable-actions">{I18n('t_applicable_actions_count', {0: props?.actionBasedTPDetails?.applicableActions})}</label>
              </div>
            </>
        }
        {getOverlayPanel(actionBasedTargetPathwayOP, actionBasedPathwayId)}
      </div>
      <div className='nzc-h-line' />
    </>
  }
  const getScienceBasedTargetPathways = () =>{
    const disableSbt2 = graphIntensity ? 'pointer-events-none opacity-60' : 'pointer-events-auto'
    return <>
      <div className='science-based-target'>
        <div className='science-based-title-icon' id={scienceBasedPathwayId}>
          <label htmlFor="target-pathway-sub-titles" className='target-pathway-sub-titles'
            data-testid='science-based-tp'>{I18n('t_science_based_target')}</label>
          <img src={questionMarkIcon} alt="" className='question-mark-icon'
            onClick={(event) => handleQuestionMarkClick(event, 'science-based-tp')}
            data-testid='science-based-tp-question-mark'/>
        </div>
        <div className='science-based-target-options'>
          <div className='science-based-target-option-1' data-testid="radio-1.5">
            <RadioButton checked={Number(targetPathwayDetailsObj.scienceBasedTargetOption) !== 2}
              inputId="SBT-option-1"
              name='SBT-option-1' onChange={handleSelectedScienceBasedTarget}/>
            <label htmlFor="SBT-option-1" className='SBT-option-1'>{I18n('t_SBT_option_1')}</label>
          </div>
          <div className={`science-based-target-option-2 ${disableSbt2}`} data-testid="radio-2.0">
            <RadioButton checked={Number(targetPathwayDetailsObj.scienceBasedTargetOption) === 2}
              inputId="SBT-option-2"
              name='SBT-option-2' onChange={handleSelectedScienceBasedTarget}/>
            <label htmlFor="SBT-option-2" className='SBT-option-2'>{I18n('t_SBT_option_2')}</label>
          </div>
        </div>
        {getOverlayPanel(scienceBasedTargetPathwayOP, scienceBasedPathwayId)}
      </div>
      <div className={'nzc-h-line'}/>
    </>
  }

  function getTopDownPathways(){
    const topDownPathwaysData = props.topdownPathways?.topDownPathways
    const topDownPathwayFileName = topDownPathwaysData?.fileName.replace('.xlsx', '')
    const topDownPathwayFileNameShort = handleLengthyName(topDownPathwayFileName, 30)

    function handleUploadTopDownPathways(){
      const fundName = CachedUserState.getUserDataFromCache(LOCAL_STORAGE_KEYS.selectedOrgAndFund)?.fundName
      history.push({
        pathname: `/${props.nzcScopeId}/fund-net-zero-carbon/upload/top-down-pathways`,
        search:`?intensity=${graphIntensity ? 'energy': 'carbon'}`,
        state: { fileName: fundName, from: history.location.pathname }
      })
    }

    function handleTopDownPathwaysView() {
      setTargetPathwayDetailsObj(prevState => {
        return {
          ...prevState,
          showTopDownPathways: !targetPathwayDetailsObj.showTopDownPathways
        }
      })
    }

    const deleteTopdownHeader = <><Icon id={'warning_solid_circle'} colour='red' /> <Text className='bold s' content={'t_delete_top_down_pathway'} /></>
    
    function handleDeleteTopdown(action) {
      if(action === 'cancel') {
        setConfirmDeleteTopdownPathway(false)
      } else {
        setConfirmDeleteTopdownPathway(true)
      }
    }

    function deleteTopDownPathways() {
      deleteTopDownPathway.mutateAsync({
        scopeId: props.nzcScopeId,
        intensityType: graphIntensity ? 'Energy': 'Carbon',
      }).then(() => {
        setTargetPathwayDetailsObj(prevState => {
          return {
            ...prevState,
            showTopDownPathways: false
          }
        })
        queryClient.removeQueries('NZCTargetPathways')
        queryClient.removeQueries('GetNZCSettings')
      })
    }

    return <div className='top-down-pathways-container'>
      <span>{I18n('t_top_down_pathways')}</span>
      {topDownPathwaysData ? <div className={'d-flex mt-4'}>
        {topDownPathwayFileName?.length > 30 &&
        <Tooltip target={'.topdown-filename'} padding="small" width={'350'}>
          <Text content={topDownPathwayFileName} />
        </Tooltip>}
        <Text className={'regular s topdown-filename'} content={topDownPathwayFileNameShort} />
        <ButtonWrapper onClick={handleTopDownPathwaysView}>
          <span className={'ml-auto'} ><Icon className={'icon-eye'} id={targetPathwayDetailsObj.showTopDownPathways ? 'eye_open' : 'eye_closed'} testId='top-down=pathway-visibility-status-icon' /></span>
        </ButtonWrapper>
        <ButtonWrapper onClick={() => handleDeleteTopdown('delete')}>
          <span className={'mr-3'}><Icon className={'icon-trash'} id={'trash_can'}  testId='top-down-pathway-delete'/></span>
        </ButtonWrapper>
      </div> :
        <div className='top-down-pathways-container__upload-button'>
          <Button
            content={'t_upload_top_down_pathways'}
            icon={<Icon id='upload' />}
            outlined={true}
            size='small'
            onClick={handleUploadTopDownPathways}
            testId={'upload-top-down-pathways'}
          />
        </div>
      }
      {confirmDeleteTopdownPathway &&
      <ConfirmDialog
        visible={confirmDeleteTopdownPathway}
        acceptLabel={I18n('t_delete')}
        rejectLabel={I18n('t_cancel')}
        header={deleteTopdownHeader}
        message={I18n('t_delete_topdownpathway_confirm_text')}
        onAccept={() => deleteTopDownPathways()}
        onReject={() => handleDeleteTopdown('cancel')}
        onHide={() => handleDeleteTopdown('cancel')}
      />
      }
      <div className={'nzc-h-line'}/>
    </div>
  }
  const  getCustomBasedTargetPathways = () =>{
    return <div className='custom-target-pathways-title-icon' id={customBasedPathwayId}>
      <label className='target-pathway-sub-titles'
        data-testid='custom-based-tp'>{I18n('t_custom_target_pathways')}</label>
      <img src={questionMarkIcon} alt="" className='question-mark-icon'
        onClick={(event) => handleQuestionMarkClick(event, 'custom-based-tp')}
        data-testid='custom-based-tp-question-mark'/>
      {getOverlayPanel(customBasedTargetPathwayOP, customBasedPathwayId)}
    </div>
  }


  return (
    <div className={'nzc-target-pathway-form'}>
      {!isCreateOrEditMode() && !targetPathwayFormState?.tabExpanded &&
          <>
            {featureState.sieraplus_scienceBasedPathways && getScienceBasedTargetPathways()}
            {featureState.sieraplus_actionBasedPathways && getActionBasedTargetPathways()}
            {featureState.sieraplus_nzcUploadTargetPathway && getTopDownPathways()}
            {getCustomBasedTargetPathways()}
          </>
      }
      <div className={'target-body'}>
        {getTargetBodyContentElements()}
      </div>
      {!isCreateOrEditMode() && !targetPathwayFormState?.tabExpanded && targetPathwayNumbersArray.length<3 &&
            <div className='custom-target-pathways'>
              <img src={plusIcon} alt="" className='plus-icon' onClick={addAnotherTargetHandler} data-testid='create-new-tp'/>
              <label htmlFor="custom-target-pathway-sub-titles" className='target-pathway-sub-titles'>{I18n('t_define_target_pathway')}</label>
            </div>}
      {!isCreateOrEditMode() && !targetPathwayFormState?.tabExpanded &&
            <div className={'fund-nzc-apply-cancel'}>
              <div className="footer-btn-apply">
                <Button 
                  content='t_apply'
                  className={'btn-apply button-highlight'} 
                  onClick={submitTargetPathwayData} 
                  disabled={!isEdit.layer_button_1} 
                />
              </div>
              <div className="footer-btn-cancel"><label className={`btn-cancel button-highlight ${!isEdit.layer_button_1}`} onClick={cancelTargetPathwayViewDetailsUpdate} >{I18n('t_cancel')}</label>
              </div>
            </div>
      }
      {errorDialogVisible && <ErrorDialog  {...errorDialogProps} />}
      {targetPathwayFormState.showConfirmModal && <PopupDialog
        visible={targetPathwayFormState.showConfirmModal}
        onHide={closeConfirmDialog}
        message={getLocalizedValue(selectedLanguage, 't_delete_target_pathway_message')}
        footer={confirmDialogFooter}
        className={'no-footer'}
        header={getDeletePathwayModalTitle()}
      />}
    </div>
  )
}

NzcTargetPathwayForm.propTypes = {
  nzcScopeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  baselineYear: PropTypes.number,
  scopeTargetState: PropTypes.shape({
    tpMode: PropTypes.string,
    activeIndex: PropTypes.number,
  }),
  nzcSettingsData: PropTypes.shape({
    scienceBasedTarget: PropTypes.number,
    targetPathwayNames: PropTypes.string,
    showTargetProgress: PropTypes.bool,
    energySavingActions: PropTypes.bool,
    targetPathway:PropTypes.object,
    agreedActions: PropTypes.bool,
    applicableActions: PropTypes.bool,
    showTopDownPathways: PropTypes.bool,
  }),
  updateTargetsAndPathwayDetails: PropTypes.func,
  updateScopeTargetState: PropTypes.func,
  setNZCGraphData: PropTypes.func,
  targetPathwayData: PropTypes.object,
  actionBasedTPDetails: PropTypes.object,
  updateStrandingYear: PropTypes.func,
  refetchTargetPathwayData: PropTypes.func,
  setGraphDataLoading: PropTypes.func,
  topdownPathways: PropTypes.object,
  reportingYear: PropTypes.number
}

NzcTargetPathwayForm.displayName = 'NzcTargetPathwayForm'

export default NzcTargetPathwayForm