import PropTypes from 'prop-types'
import { getLocalizedValue, getLocalizedValueUppercase } from '../../../../utils/helpers/Helper'
import {UTILITY_LABELS_OBJECT_TITLE_CASE, UtilityTypes} from '../../../../utils/helpers/Constants'
import I18n from '../../../../utils/i18n/I18n'
import './ChartLegend.scss'

const ChartLegend = ({selectedLanguage, targetCalculatedAgainstPreviousPeriod, selectedUtility, dataSelectionFilters, isGapFillingSelected}) => {

  function getLegendForEachDataSelection(dataSelection) {
    if(selectedUtility !== UtilityTypes.Waste && selectedUtility !== UtilityTypes.Carbon){
      return (
        <>
          <li key={dataSelection.key} className={`legend-${dataSelection.key}`}>{dataSelection.name} {I18n('t_procured_data')}</li>
          {isGapFillingSelected && <li key={`${dataSelection.key}-gap-filled`} className={`legend-${dataSelection.key}-gap-filled`}>{I18n('t_gap_filled_split')} {dataSelection.key} {I18n('t_procured_data')}</li>}
        </>
      )
    } else {
      return(
        <>
          <li key={dataSelection.key} className={`legend-${dataSelection.key}`}>{dataSelection.name}</li>
          {isGapFillingSelected && <li key={`${dataSelection.key}-gap-filled`} className={`legend-${dataSelection.key}-gap-filled`}>{dataSelection.name} {I18n('t_gap_filled_scope')}</li>}
        </>
      )
    }
  }

  function getLegendBasedOnDataSelection(targetCalculatedAgainstPreviousPeriod) {
    if (dataSelectionFilters.length > 0) {
      return (
        <ul>
          {dataSelectionFilters.map(dataSelection => getLegendForEachDataSelection(dataSelection))}
        </ul>
      )
    } else {
      return getDefaultLegendItems(targetCalculatedAgainstPreviousPeriod)
    }
  }

  function getDefaultLegendItems(targetCalculatedAgainstPreviousPeriod) {
    if(targetCalculatedAgainstPreviousPeriod && isGapFillingSelected){
      return(
        <ul>
          <li className="actual-data-legend">{getLocalizedValue(selectedLanguage, 't_actual')}</li>
          <li className="target-data-legend">{getLocalizedValue(selectedLanguage, 't_target')}</li>
          {isGapFillingSelected && <li className="estimated-data-legend">{getLocalizedValue(selectedLanguage, 't_gap_filled')}</li>}
        </ul>
      )
    } else {
      return(
        <ul>
          <li className="actual-data-legend">{getLocalizedValue(selectedLanguage, 't_actual')}</li>
          <li className="target-data-legend">{getLocalizedValue(selectedLanguage, 't_previous_period')}</li>
          {isGapFillingSelected && <li className="estimated-data-legend">{getLocalizedValue(selectedLanguage, 't_gap_filled')}</li>}
        </ul>
      )
    }
  }

  return (
    <div className="chart-legend">
      {selectedUtility === UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE ? (
        <ul>
          <li className="current-data-legend">{getLocalizedValueUppercase(selectedLanguage, 't_current')}</li>
          {targetCalculatedAgainstPreviousPeriod === true ? (
            <li className="target-data-legend">{getLocalizedValueUppercase(selectedLanguage, 't_target')}</li>
          ) : (
            <li className="target-data-legend">{getLocalizedValueUppercase(selectedLanguage, 't_previous_period').toUpperCase()}</li>
          )}
          <li className="above-target-data-legend">{getLocalizedValueUppercase(selectedLanguage, 't_above_target')}</li>
          <li className="non-recycled-data-legend">{getLocalizedValueUppercase(selectedLanguage, 't_non_recycled_waste')}</li>
        </ul>
      ) : (
        <>
          {getLegendBasedOnDataSelection(targetCalculatedAgainstPreviousPeriod)}
        </>
      )}
    </div>
  )
}

export default ChartLegend
ChartLegend.displayName = 'ChartLegend'

ChartLegend.propTypes = {
  selectedLanguage: PropTypes.string,
  targetCalculatedAgainstPreviousPeriod: PropTypes.bool,
  selectedUtility: PropTypes.string,
  dataSelectionFilters: PropTypes.array,
  isGapFillingSelected: PropTypes.bool,
}