import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {Dialog} from 'primereact/dialog'
import './NzcAssetsFilterModal.scss'
import {Dropdown} from 'primereact/dropdown'
import {Button} from 'primereact/button'
import I18n from '../../../../../../utils/i18n/I18n'
import {getLocalizedValue} from '../../../../../../utils/helpers/Helper'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const NzcAssetsFilterModal = (props) => {

  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const [menuOptionState, setMenuOptionState] = useState({
    country: props.assetsFilterModalData?.selectedCountry,
    sector: props.assetsFilterModalData?.selectedSector,
    assetStatus: props.assetsFilterModalData?.selectedAssetStatus,
  }
  )

  function updateMenuOptionState(newMenuOption) {
    setMenuOptionState({...menuOptionState, ...newMenuOption})
  }

  const hideModal = () => {
    props.setShowAssetsFilterModal(!props.showFilterModal)
  }

  const dropdownItemTemplate = (option) => {
    return (
      <>
        <div className="dropdown-item-1">
          <div className="dropdown-item-2 dropdown-item-wrap">{option.label}</div>
        </div>
        <div className={'dropdown-panel-item-horiz-line'}/>
      </>
    )
  }

  function sectorChangeHandler(e) {
    const newSector = e.value
    updateMenuOptionState({sector: {value: newSector}})
  }

  function countryChangeHandler(e) {
    const newCountry = e.value
    updateMenuOptionState({country: {value: newCountry}})
  }

  function assetStatusChangeHandler(e) {
    const newAssetStatus = e.value
    updateMenuOptionState({assetStatus: {value: newAssetStatus}})
  }

  function getFilteredAssetsLength() {
    return props.filterFundAssets(
      menuOptionState.sector?.value,
      menuOptionState.country?.value,
      menuOptionState.assetStatus?.value,
    )?.length
  }

  function updateAssetsFilterModalData(newFilterModalData) {
    props.setAssetsFilterModalData({...props.assetsFilterModalData, ...newFilterModalData})
  }

  function applyFilterHandler() {
    props.fundAssetsRef.current = props.filterFundAssets(
      menuOptionState.sector?.value,
      menuOptionState.country?.value,
      menuOptionState.assetStatus?.value
    )
    updateAssetsFilterModalData({
      selectedSector: menuOptionState.sector,
      selectedCountry: menuOptionState.country,
      selectedAssetStatus: menuOptionState.assetStatus,
    })
    hideModal()
  }

  function getDropdownMenuProps(menuOptions, selectedValue, onChangeHandler) {
    return ({
      appendTo: 'self',
      options: menuOptions,
      className: 'sDropdown-1 assets-filter-dropdown',
      value: selectedValue.value,
      scrollHeight: '280px',
      onChange: onChangeHandler,
      itemTemplate: dropdownItemTemplate
    })
  }

  function getModalFiltersCount() {
    let filterCount = 0
    if (menuOptionState.sector.value !== props.assetsFilterModalData.assetsFilterOptions.allSectors) {
      filterCount += 1
    }
    if (menuOptionState.country.value !== props.assetsFilterModalData.assetsFilterOptions.allCountries) {
      filterCount += 1
    }
    if (menuOptionState.assetStatus.value !== props.assetsFilterModalData.assetsFilterOptions.allAssets) {
      filterCount += 1
    }
    return filterCount
  }

  return (
    <Dialog
      className="nzc-assets-filter-modal dialog-close-highlight"
      header={getLocalizedValue(selectedLanguage, 't_assets_filter_title')}
      visible={props.showFilterModal}
      onHide={() => hideModal()}
      draggable={false}
      blockScroll
    >
      <div className={'nzc-assets-filter-modal__grid'}>
        <div className={'nzc-assets-filter-modal__grid-col'}>
          <div className={'nzc-assets-filter-modal__grid-col__label'}
            role='sector-label'>{I18n('t_sector')}</div>
          <Dropdown {...getDropdownMenuProps(props.assetsFilterModalData.sectorMenuOptions, menuOptionState.sector, sectorChangeHandler)} />
          <div className={'nzc-assets-filter-modal__grid-col__label'}
            role='country-label'>{I18n('t_country')}</div>
          <Dropdown {...getDropdownMenuProps(props.assetsFilterModalData.countryMenuOptions, menuOptionState.country, countryChangeHandler)}/>
          <div className={'nzc-assets-filter-modal__grid-col__label'}
            role='asset-label'>{I18n('t_asset_status')}</div>
          <Dropdown {...getDropdownMenuProps(props.assetsFilterModalData.assetStatusOptions, menuOptionState.assetStatus, assetStatusChangeHandler)}/>
        </div>
        <div className={'nzc-assets-filter-modal__grid--selected-info'}>
          <div className={'nzc-assets-filter-modal__grid--selected-info__text'} role='filtered-asset-count'>
            {getModalFiltersCount()} {I18n('t_assets_filter_count')} <br/>
            {I18n('t_assets_filter_result', {0: getFilteredAssetsLength(), 1: props.fundAssets?.length})}
          </div>
        </div>
      </div>
      <div className={'nzc-assets-filter-modal__h-line'}/>
      <div className={'nzc-assets-filter-modal__button'}>
        <Button className={'nzc-assets-filter-modal__button--apply button-highlight'}
          onClick={applyFilterHandler} role='apply-filter-button'>{I18n('t_apply')}</Button>
      </div>
    </Dialog>
  )
}

NzcAssetsFilterModal.propTypes = {
  showFilterModal: PropTypes.bool.isRequired,
  setShowAssetsFilterModal: PropTypes.func.isRequired,
  assetsFilterModalData: PropTypes.object.isRequired,
  setAssetsFilterModalData: PropTypes.func.isRequired,
  filterFundAssets: PropTypes.func.isRequired,
  fundAssets: PropTypes.array.isRequired,
  fundAssetsRef: PropTypes.object.isRequired,
}

NzcAssetsFilterModal.displayName = 'NzcAssetsFilterModal'

export default NzcAssetsFilterModal