import XlsxPopulate from 'xlsx-populate'
import FileSaver from 'file-saver'
import { formatDateToDdMonYy } from 'utils/helpers/DateUtils'
import { excelHelpers, getEmptyRows, formatData, excelHelpersFieldDesc } from 'utils/helpers/ExcelUtils'
import PropTypes from 'prop-types'

export const FundReportMissingDataExcelExport = async ({ fundReportMissingData, formatMessage }) => {
  const {
    fundName,
    year,
    missingData
  } = fundReportMissingData
  const localize = (id) => formatMessage({ id })
  const blob = await XlsxPopulate.fromBlankAsync().then(workbook => {
    const excelSheet = workbook.sheet(0).name('Missing Asset Data')
    let requiredDataToGenerateExcel = createMissingDataOverviewSheet(
      fundName,
      year,
      missingData,
      localize,
    )
    excelHelpers(requiredDataToGenerateExcel, excelSheet)
    excelSheet.range('A6:J6').style([
      { bold: true, verticalAlignment: 'center' },
      {
        fill: {
          type: 'solid',
          color: 'D9D9D9'
        }
      }
    ])
    excelSheet.range('A1:A1').style([
      { bold: true }
    ])
    const excelSheetDescription = workbook.addSheet('Field Descriptions')
    let requiredDataToGenerateExcelDesc = createFieldDescriptionsSheet(localize)
    excelHelpersFieldDesc(requiredDataToGenerateExcelDesc, excelSheetDescription, undefined, 'left')
    excelSheetDescription.range('A3:B3').style([
      { bold: true },
      {
        fill: {
          type: 'solid',
          color: 'D9D9D9'
        }
      }
    ])
    return workbook.outputAsync(workbook)
  })
  FileSaver.saveAs(blob, `${fundName} ${localize('t_missing_tc')} PAI ${localize('t_data')} ${year} ${formatDateToDdMonYy()}`)
}

const createMissingDataOverviewSheet = (
  fundName,
  year,
  missingData,
  localize
) => {
  let excelDataArray = []
  
  excelDataArray.push(...getMissingDataSheetHeaderInformation(fundName, year, localize))
  excelDataArray.push(...getEmptyRows(1))
  excelDataArray = getAssetReferenceTable(excelDataArray, missingData,localize)
  
  return excelDataArray
}

const createFieldDescriptionsSheet = (
  localize
) => {
  let excelDataArray = []
  
  excelDataArray.push(...[{A: `${localize('t_missing_field_description')}`}])
  excelDataArray.push(...getEmptyRows(1))
  excelDataArray = getFieldDescriptions(excelDataArray,localize)
  
  return excelDataArray
}


export const getMissingDataSheetHeaderInformation = (fundName, year, localize) => {
  return [
    {A: `SFDR PAIs ${localize('t_missing_tc')} ${localize('t_data')}`},
    {A: `${localize('t_fund_name')}: ${fundName}`},
    {A: `${localize('t_reporting_year')}: ${year}`},
    {A: `${localize('t_date_of_export')}: ${formatDateToDdMonYy()}`}
  ]
}

export const getMissingDataTableHeaderNames = (localize) => {
  return [{
    A: localize('t_asset_reference'),
    B: localize('t_asset_name'),
    C: localize('t_reports_data_completeness'),
    D: localize('t_fossil_fuels_exposure'),
    E: localize('t_energy_efficiency'),
    F: localize('t_adverse_impact_ghg_emissions'),
    G: localize('t_energy_consumption'),
    H: localize('t_waste_recycling'),
    I: localize('t_resource_consumption'),
    J: localize('t_biodiversity'),
  }]
}
  
export const getAssetReferenceTable = (currentExcelData, missingData, localize) => {
  if(!missingData) return currentExcelData

  const data = [...currentExcelData]
  data.push(...getMissingDataTableHeaderNames(localize))

  missingData.forEach((item, index) => {
    const reportPrefix = 't_reports_'
    let fossilFuelData = item.fossilFuels?.map((x) => localize(reportPrefix + x))
    let energyEfficiencyData = item.energyEfficiency?.map((x) => localize(reportPrefix + x))
    let greenhouseGasEmissionsData = item.greenhouseGasEmissions?.map((x) => localize(reportPrefix + x))
    let energyConsumptionData = item.energyConsumption?.map((x) => localize(reportPrefix + x))
    let wasteData = item.waste?.map((x) => localize(reportPrefix + x))
    let resourceConsumptionData = item.resourceConsumption?.map((x) => localize(reportPrefix + x))
    let biodiversityData = item.biodiversity?.map((x) => localize(reportPrefix + x))

    data.push(...[{
      A: formatData(item.assetReference, localize, false),
      B: formatData(item.assetName, localize),
      C: formatData(item.dataCompleteness, localize) + '%',
      D: formatData(fossilFuelData, localize),
      E: formatData(energyEfficiencyData, localize),
      F: formatData(greenhouseGasEmissionsData, localize),
      G: formatData(energyConsumptionData, localize),
      H: formatData(wasteData, localize),
      I: formatData(resourceConsumptionData, localize),
      J: formatData(biodiversityData, localize),
    }])
  })

  return data
}

export const getFieldDescriptions = (currentExcelData, localize) => {

  const data = [...currentExcelData]
  data.push(...[{
    A: localize('t_missing_data'),
    B: localize('t_description')
  }])

  data.push(
    {A: localize('t_asset_values'), B: localize('t_asset_values_extended')},
    {A: localize('t_fossil_fuel_exposure'), B: localize('t_fossil_fuel_exposure_extended')},
    {A: localize('t_planning_permission_date'), B: localize('t_planning_permission_date_extended')},
    {A: localize('t_epc_coverage'), B: localize('t_epc_coverage_extended')},
    {A: localize('t_ped'), B: localize('t_ped_extended')},
    {A: localize('t_nzeb_threshold'), B: localize('t_nzeb_threshold_extended')},
    {A: localize('t_asset_floor_area'), B: localize('t_asset_floor_area_extended')},
    {A: localize('t_waste_management'), B: localize('t_waste_management_extended')},
    {A: localize('t_total_raw_materials_weight'), B: localize('t_total_raw_materials_weight_extended')},
    {A: localize('t_sustainable_raw_materials_weight'), B: localize('t_sustainable_raw_materials_weight_extended')},
    {A: localize('t_total_horizontal_area'), B: localize('t_total_horizontal_area_extended')},
    {A: localize('t_total_vertical_area'), B: localize('t_total_vertical_area_extended')},
    {A: localize('t_artificial_horizontal_area'), B: localize('t_artificial_horizontal_area_extended')},
    {A: localize('t_artificial_vertical_area'), B: localize('t_artificial_vertical_area_extended')},
  )

  return data
}

FundReportMissingDataExcelExport.propTypes = {
  fundName: PropTypes.string.isRequired,
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  missingData: PropTypes.object.isRequired,
  formatMessage: PropTypes.func.isRequired,
}