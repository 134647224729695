import React, {useState} from 'react'
import {Button} from 'primereact/button'
import XlsxPopulate from 'xlsx-populate'
import FileSaver from 'file-saver'
import moment from 'moment'
import {columnIndexToLetter, createEmptyArray, getLocalizedValue} from '../../../../../utils/helpers/Helper'
import {
  ASSET_DASHBOARD,
  EDIT_FUND_ACTION_PLAN,
  EDIT_PORTFOLIO_ACTION_PLAN,
  FUND_ACTION_PLAN,
  FUND_DASHBOARD,
  getHeaderNamesForPrePopulatedTemp,
  GROUP_DETAILS,
  numericRegex,
  PORTFOLIO,
  PORTFOLIO_ACTION_PLAN,
  ASSET_ACTION_PLAN_BULK_UPLOAD,
  ASSET_ACTION_PLAN_BULK_UPLOAD_EDIT,
  ASSET_ACTION_PLAN,
  consumptionUpload
} from '../../../../../utils/helpers/Constants'
import {
  ALPHANUMERIC_COLUMNS, ALPHANUMERIC_STYLE, ANY_VALUE, AREA_COVERED_MASK, bulkUploadFileGenerationStyles,
  colsToBeFilled, DATE_COL, DROPDOWN_COL, emptyExcelObj, getInstructionsData, getTooltipLocale, GRAY_COLOR, LIST,
  TOOLTIP_COL, TRUE, UTILITY_DROPDOWN, utilityMask, WHITE_COLOR, excelParams, getWidth, flattenedData,
  EMPTY_ACTION_PLAN_OBJECT, colsNotToBeFilled, disableButton, transformedData, ACTION_UPLOAD_HEADERS, ACTION_ID_HEADER
} from './GenerateExcelForBulkConsumptionHelper'
import SpinningLoader from '../../../../common/spinning-loader/SpinningLoader'
import {useParams} from 'react-router-dom'
import {
  BulkConsumptionForAssetDashboard, BulkConsumptionForFundDashboard, BulkConsumptionForOwnedGroup,
  BulkConsumptionForPortfolioAssetMeterData, BulkUploadAssetActionPlanRequest } from '../../../../../services/buk-consumption/bulk-consumption-service'
import { addBulkActionPlan, fetchBulkActionPlanToEdit, fetchBulkPortfolioActionPlan, fetchBulkPortfolioEditActionPlan}
  from '../../../../../services/assets/actionplan-bulkupload-service'
import PropTypes from 'prop-types'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const GenerateExcelForBulkConsumption = ({fileName,onGenerate,additionalParams,history}) => {
  const [isDataLoading,setDataLoadingStatus] = useState(false)
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const params = useParams()
  const {consumptionId,routeVia, type} =  params
  let dropdownValues = {
    gresbEfficiency: '',
    assetRef: '',
    assetName: ''
  }

  const isEdit = additionalParams?.typeOfAction?.value === 'edit'
  const removeAssetRefFromToolTip = isEdit ? TOOLTIP_COL.filter((tooltip) => tooltip !== 'assetRef' && tooltip !== 'assetName') : TOOLTIP_COL

  function getAssetsActionPlan(){
    if(routeVia === ASSET_ACTION_PLAN){
      return isEdit ? ASSET_ACTION_PLAN_BULK_UPLOAD_EDIT : ASSET_ACTION_PLAN_BULK_UPLOAD
    }
    return isEdit ? EDIT_FUND_ACTION_PLAN : FUND_ACTION_PLAN
  }
  const portfolioActionBulkUpload = isEdit ? EDIT_PORTFOLIO_ACTION_PLAN : PORTFOLIO_ACTION_PLAN
  function getRouteFrom() {
    switch(type) {
    case consumptionUpload: return history?.location?.state?.isAssets ? PORTFOLIO : routeVia
    case 'action-upload': {
      if(!Number(consumptionId)){
        return portfolioActionBulkUpload
      }
      return getAssetsActionPlan()
    }
    default: return history?.location?.state?.isAssets ? PORTFOLIO : routeVia
    }
  }

  const HEADER = {
    'action-upload': isEdit ? { ...ACTION_ID_HEADER, ...ACTION_UPLOAD_HEADERS} : { ...ACTION_UPLOAD_HEADERS, ...ACTION_ID_HEADER},
    'consumption-upload': getHeaderNamesForPrePopulatedTemp
  }

  function getConsumptionId() {
    return history?.location?.state?.isAssets ? history.location.state.portfolioParameters : consumptionId
  }

  function createInstructionsSheet(workbook) {
    const instructionsSheet = workbook.sheet(0).name(getLocalizedValue(selectedLanguage,excelParams(isEdit)[type]?.primarySheetName))

    function getInstructionsPointStyle(columnIndex) {
      return columnIndex === 0 ? bulkUploadFileGenerationStyles.instructionsPoint : bulkUploadFileGenerationStyles.instructionsMessage
    }

    instructionsSheet.cell('A1').value(getLocalizedValue(selectedLanguage, excelParams(isEdit)[type]?.header))
      .style(bulkUploadFileGenerationStyles.instructionsHeader)

    instructionsSheet.cell('A3').value(getLocalizedValue(selectedLanguage,'t_bulk_consumption_instruction_note'))
      .style(bulkUploadFileGenerationStyles.instructionsPleaseNote)

    instructionsSheet.range('A3:B3').style(bulkUploadFileGenerationStyles.topBorder)
    instructionsSheet.cell('B3').style(bulkUploadFileGenerationStyles.rightBorder)

    getInstructionsData(selectedLanguage, type, isEdit).forEach((row, rowIndex) => {
      Object.values(row).forEach((value, columnIndex) => {
        instructionsSheet.cell(rowIndex + 4, columnIndex + 1).value(value).style(getInstructionsPointStyle(columnIndex))
        if(columnIndex===1){
          instructionsSheet.cell(rowIndex + 4, columnIndex + 1).style(bulkUploadFileGenerationStyles.rightBorder)
        }
      })
    })
    instructionsSheet.range(excelParams(isEdit)[type]?.borderBottom).style(bulkUploadFileGenerationStyles.bottomBorder)
    instructionsSheet.column('A').width(14)
    instructionsSheet.column('B').width(76).style(bulkUploadFileGenerationStyles.wrapText)
  }

  function setTooltipForHeaders(key) {
    return removeAssetRefFromToolTip.includes(key) ? {
      type: ANY_VALUE, showInputMessage: TRUE, prompt: getTooltipLocale(selectedLanguage, key), promptTitle: ''} :
      {type: ANY_VALUE, showInputMessage: false}
  }

  function setHeaderStyle(cell, key, sheet) {
    const columnIndex = cell.columnNumber()
    const columnLetter = columnIndexToLetter(columnIndex)
    if (getLocalizedValue(selectedLanguage,HEADER[type][key])?.includes('*')) {
      cell.style(bulkUploadFileGenerationStyles.prePopulateGreen).dataValidation(setTooltipForHeaders(key))
    } else {
      cell.style(bulkUploadFileGenerationStyles.prePopulateBlue).dataValidation(setTooltipForHeaders(key))
    }
    sheet.column(columnLetter).width(getWidth(key)).style(bulkUploadFileGenerationStyles.wrapText)
  }

  function constructDropdown(dataRow, columnIndex, key, item,cellBGColor, dropdown) {
    dataRow.cell(columnIndex + 1).value(colsNotToBeFilled.includes(key) && !isEdit ? '' :
      item[key]).style({ ...cellBGColor, border: excelParams(isEdit)[type]?.isBorder}).dataValidation({
      type: LIST, showInputMessage: (!!getTooltipLocale(selectedLanguage, key)),
      prompt: getTooltipLocale(selectedLanguage, key), promptTitle: '',
      formula1: colsNotToBeFilled.includes(key) ? dropdown[key] : `"${UTILITY_DROPDOWN[key].join(',')}"`
    }
    )
  }

  function dateTemplate(dataRow, columnIndex, key, item,cellBGColor) {
    const formattedDate = item[key] ? moment(item[key]).format('DD-MM-YYYY') : ''
    dataRow.cell(columnIndex + 1).value(formattedDate).style({
      ...cellBGColor,
      border: excelParams(isEdit)[type]?.isBorder
    })
  }

  function setTooltip(key,columnIndex,dataRow){
    if(removeAssetRefFromToolTip.includes(key)){
      dataRow.cell(columnIndex+1).dataValidation({
        type: ANY_VALUE, showInputMessage: TRUE, prompt: getTooltipLocale(selectedLanguage, key), promptTitle: ''
      })
    }
  }

  function isGresbEfficiency(key,prePopulatedSheet){
    if  ((key === 'gresbEfficiency') && UTILITY_DROPDOWN[key]) {
      prePopulatedSheet.column('BA').hidden(true)
      if (!isEdit) prePopulatedSheet.column('T').hidden(true)
      UTILITY_DROPDOWN[key].forEach((dropdownValue, i) => {
        const cellCalculations = `BA${i + 1}`
        dropdownValues = {
          ...dropdownValues,
          gresbEfficiency: `$BA1:$${cellCalculations}`
        }
        prePopulatedSheet.cell(cellCalculations).value(dropdownValue)
      })
    }
  }

  function isAssetRef(key,bulkUploadExcelGenerateData,prePopulatedSheet){
    if (key === 'assetRef' && !isEdit) {
      prePopulatedSheet.column('BB').hidden(true)
      bulkUploadExcelGenerateData.filter((e) => e.assetRef).forEach((item, i) => {
        const cellCalculations = `BB${i + 1}`
        dropdownValues = {
          ...dropdownValues,
          assetRef: `$BB1:$${cellCalculations}`
        }
        prePopulatedSheet.cell(cellCalculations).value(item[key])
      })
    }
  }
  function isAssetName(key,bulkUploadExcelGenerateData,prePopulatedSheet){
    if (key === 'assetName' && !isEdit && type!==consumptionUpload) {
      prePopulatedSheet.column('BC').hidden(true)
      bulkUploadExcelGenerateData.filter((e) => e.assetName).forEach((item, i) => {
        const cellCalculations = `BC${i + 1}`
        dropdownValues = {
          ...dropdownValues,
          assetName: `$BC1:$${cellCalculations}`
        }
        prePopulatedSheet.cell(cellCalculations).value(item[key])
      })
    }
  }

  function createPrePopulatedSheet(bulkUploadExcelGenerateData,workbook) {
    const prePopulatedSheet = workbook.addSheet(getLocalizedValue(selectedLanguage,excelParams(isEdit)[type]?.secondarySheetName))
    const headerRow = prePopulatedSheet.row(1)

    Object.keys(HEADER[type] || emptyExcelObj).forEach((key, index) => {
      const cell=headerRow.cell(index + 1).value(getLocalizedValue(selectedLanguage,HEADER[type][key]))
      isGresbEfficiency(key,prePopulatedSheet)
      isAssetRef(key,bulkUploadExcelGenerateData,prePopulatedSheet)
      isAssetName(key,bulkUploadExcelGenerateData,prePopulatedSheet)
      setHeaderStyle(cell,key,prePopulatedSheet)
    })
    const removeAssetRef = isEdit || type === consumptionUpload ? DROPDOWN_COL.filter((columnName) => columnName !== 'assetRef' && columnName !== 'assetName') : DROPDOWN_COL
    prePopulatedBody(bulkUploadExcelGenerateData,prePopulatedSheet,removeAssetRef, dropdownValues)
    prePopulatedSheet.freezePanes(0,1)
  }

  function addCommentCharacterCount(key,dataRow,columnIndex){
    if(key==='comment'){
      dataRow.cell(columnIndex + 1).dataValidation(bulkUploadFileGenerationStyles.commentValidation)
    }
  }

  function addDescriptionToCell(dropdownCol,key,columnIndex,dataRow){
    if(!dropdownCol.includes(key)){
      setTooltip(key,columnIndex,dataRow)
    }
  }

  function setDataTOCells(item, key) {
    if (colsToBeFilled.includes(key)) {
      return item[key]
    }
    return item[key] || '-'
  }

  function setCellValue(key,item,columnIndex,dataRow,cellBGColor){
    const UTILITY = 'utility'
    const AREA_COVERED = 'areaCovered'

    if(key === UTILITY) {
      dataRow.cell(columnIndex + 1).value(utilityMask[item[key]]).style({
        ...cellBGColor, border: excelParams(isEdit)[type]?.isBorder
      })

    } else if(key === AREA_COVERED) {
      dataRow.cell(columnIndex + 1).value(AREA_COVERED_MASK[item[key]]).style({
        ...cellBGColor, border: excelParams(isEdit)[type]?.isBorder
      })
    } else if(ALPHANUMERIC_COLUMNS.includes(key) && numericRegex.test(item[key])) {
      /**
       * implementation to avoid warning in Excel columns:
       * "The number in this cell is formatted as text or preceded by an apostrophe - SPR-4544"
       */
      const cellStyle = {
        ...ALPHANUMERIC_STYLE, ...cellBGColor, border: excelParams(isEdit)[type]?.isBorder
      }
      item[key] = parseInt(item[key])
      dataRow.cell(columnIndex + 1).value(setDataTOCells(item,key)).style(cellStyle)

    } else {
      dataRow.cell(columnIndex + 1).value(setDataTOCells(item,key)).style({
        ...cellBGColor, border: excelParams(isEdit)[type]?.isBorder
      })
    }
  }

  function prePopulatedBody(bulkUploadExcelGenerateData,prePopulatedSheet,dropdownCol, dropdownValues){
    let setCellColor = TRUE

    function setBGColor(row) {
      if(row%3===0 && row!==0){
        setCellColor = !setCellColor
      }
      return setCellColor ? {fill: GRAY_COLOR,wrapText: TRUE} : {fill: WHITE_COLOR,wrapText: TRUE}
    }

    bulkUploadExcelGenerateData.forEach((item, rowIndex) => {
      const dataRow = prePopulatedSheet.row(rowIndex + 2)
      let cellBGColor = setBGColor(rowIndex)
      Object.keys(HEADER[type]).forEach((key, columnIndex) => {
        if(columnIndex===0 && !colsNotToBeFilled.includes(key)) {
          dataRow.cell(columnIndex + 1).value(setDataTOCells(item,key))
        }else if(dropdownCol.includes(key)){
          constructDropdown(dataRow,columnIndex,key,item,cellBGColor, dropdownValues)
        }else if(DATE_COL.includes(key)){
          dateTemplate(dataRow,columnIndex,key,item,cellBGColor)
        }else {
          setCellValue(key,item,columnIndex,dataRow,cellBGColor)
        }
        addCommentCharacterCount(key,dataRow,columnIndex)
        addDescriptionToCell(dropdownCol,key,columnIndex,dataRow)
      })
    })
  }

  function exportExcel(data) {
    return XlsxPopulate.fromBlankAsync()
      .then(workbook => {
        createInstructionsSheet(workbook)
        createPrePopulatedSheet(data,workbook)
        workbook.activeSheet('Instructions')
        return workbook.outputAsync(workbook)
      })
  }

  const createEmptyObjectToAdd = (objectFromApi) => {
    const flattenedArray = flattenedData(objectFromApi)
    const emptyObjects = createEmptyArray(499 - flattenedArray.length, EMPTY_ACTION_PLAN_OBJECT)
    return flattenedData(flattenedArray.concat(emptyObjects))
  }

  const bulkUploadRouter = async (queryKey) => {
    switch (queryKey[0]) {
    case FUND_DASHBOARD:
      return await BulkConsumptionForFundDashboard(queryKey)
    case FUND_ACTION_PLAN: {
      const objectFromApi =  await addBulkActionPlan(queryKey)
      return createEmptyObjectToAdd(objectFromApi)
    }
    case EDIT_FUND_ACTION_PLAN: {
      const objectFromApi =  await fetchBulkActionPlanToEdit(queryKey)
      return flattenedData(transformedData(objectFromApi), isEdit)
    }
    case PORTFOLIO_ACTION_PLAN: {
      const objectFromApi = await fetchBulkPortfolioActionPlan(queryKey)
      return createEmptyObjectToAdd(objectFromApi)
    }
    case EDIT_PORTFOLIO_ACTION_PLAN: {
      const objectFromApi = await fetchBulkPortfolioEditActionPlan(queryKey)
      return flattenedData(transformedData(objectFromApi), true)
    }
    case ASSET_DASHBOARD:
      return await BulkConsumptionForAssetDashboard(queryKey)
    case GROUP_DETAILS:
      return await BulkConsumptionForOwnedGroup(queryKey)
    case PORTFOLIO:
      return await BulkConsumptionForPortfolioAssetMeterData(queryKey)
    case ASSET_ACTION_PLAN_BULK_UPLOAD:{
      const response = await BulkUploadAssetActionPlanRequest(consumptionId, false)
      return createEmptyObjectToAdd(response)
    }
    case ASSET_ACTION_PLAN_BULK_UPLOAD_EDIT: {
      const response = await BulkUploadAssetActionPlanRequest(consumptionId, true)
      return flattenedData(transformedData(response), true)
    }
    default :
      return null
    }
  }

  async function generateExcelTemplate() {
    try {
      setDataLoadingStatus(true)
      const data = await bulkUploadRouter([getRouteFrom(), getConsumptionId(), additionalParams])
      const blob = await exportExcel(data)
      FileSaver.saveAs(blob, fileName+'.xlsx')
      setDataLoadingStatus(false)
      onGenerate(fileName)
    } catch (error) {
      onGenerate({info:error.message,title:'error'})
    }
  }

  return (
    <div>
      <Button className={'generate-template-modal__button common-blue-bg-btn'}
        onClick={generateExcelTemplate} label={getLocalizedValue(selectedLanguage, 't_generate')}
        disabled={disableButton(additionalParams, fileName, type)} loading={isDataLoading} loadingIcon={
          <SpinningLoader size={22} />}
      />
    </div>
  )
}

GenerateExcelForBulkConsumption.propTypes = {
  history:PropTypes.shape({
    location :PropTypes.shape({
      state:PropTypes.shape({
        isAssets:PropTypes.bool
      })
    })
  })

}
export default GenerateExcelForBulkConsumption