import React from 'react'
import {Skeleton} from 'primereact/skeleton'
import './AssetRowSkeleton.scss'

const AssetRowSkeleton = () => {
  const commonSkeleton = (
    <div>
      <Skeleton width="5rem"/>
      <Skeleton width="4rem"/>
    </div>
  )

  return (
    <div className="custom-skeleton p-rel mb-1">
      <div className="flex mt-4 p-abs contour row-container-skeleton">
        <div className="flex details-cell-skeleton-container">
          <Skeleton shape="circle" size="2rem" className="mr-3"/>
          <Skeleton shape="square" size="5.5rem" className="mr-3"/>
          <div>
            <Skeleton width="15rem" className="mb-2"/>
            <Skeleton width="20rem" className="mb-2"/>
            <Skeleton width="10rem" height=".5rem" className="mb-2"/>
            <Skeleton width="10rem" height=".4rem" className="mb-2"/>
            <Skeleton width="10rem" height=".3rem"/>
          </div>
        </div>
        <div className='flex regular-cell-skeleton-container'>
          {commonSkeleton}
          {commonSkeleton}
          {commonSkeleton}
          {commonSkeleton}
          {commonSkeleton}
          {commonSkeleton}
          {commonSkeleton}
        </div>
      </div>
      <Skeleton width="100%" borderRadius="0" height="8.25rem" animation="none"/>
    </div>
  )
}

export default AssetRowSkeleton