import {Loader} from '@googlemaps/js-api-loader'

export const MapConfig = (addressInputId, mapId) => {
  const mapLoader = new Loader({
    apiKey: 'AIzaSyBYE1pW-nczD-odzcolRSj7akX_ajuDBBE',
    libraries: ['places']
  })
  mapLoader.load().then((google) => {
    const map = new google.maps.Map(document.getElementById(mapId), {
      center: { lat: 51.507359, lng: -0.136439 },
      zoom: 13,
      mapTypeId: 'roadmap',
    })
    const input = document.getElementById(addressInputId)
    const searchBox = new google.maps.places.SearchBox(input)
    // map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input); // to show input field inside the map
    map.addListener('bounds_changed', () => {
      searchBox.setBounds(map.getBounds())
    })
    let markers = []
    searchBox.addListener('places_changed', () => {
      const places = searchBox.getPlaces()
      if (places.length === 0) {
        return
      }
      markers.forEach((marker) => {
        marker.setMap(null)
      })
      markers = []
      const bounds = new google.maps.LatLngBounds()
      console.log('bounds', google.maps.LatLngBounds())
      places.forEach((place) => {
        if (!place.geometry || !place.geometry.location) {
          console.log('Returned place contains no geometry')
          return
        }
        // const icon = {
        //     // url: place.icon,
        //     // size: new window.google.maps.Size(71, 71),
        //     // origin: new window.google.maps.Point(0, 0),
        //     // anchor: new window.google.maps.Point(170, 34),
        //     // scaledSize: new window.google.maps.Size(30, 45),
        //     image: require("../../resources/images/icon/Marker.svg")
        // };

        const image = 'https://i.ibb.co/vjHJJ31/iconmonstr-location-1.png'
        markers.push(
          new google.maps.Marker({
            map,
            icon: image,
            title: place.name,
            position: place.geometry.location,
          })
        )
        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport)
        } else {
          bounds.extend(place.geometry.location)
        }
      })
      map.fitBounds(bounds)
    })
  })
}