import React from 'react'
import PropTypes from 'prop-types'
import './ButtonWrapper.scss'

const ButtonWrapper = ({ testId, onClick, className, onMouseEnter, onMouseLeave, children }) => {
  return (
    <button
      className={className ? `button-wrapper ${className}` : 'button-wrapper'}
      data-testid={testId} 
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </button>
  )
}

ButtonWrapper.propTypes = {
  testId: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
}

export default ButtonWrapper