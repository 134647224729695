import React, { useEffect, useState } from 'react'
import './ResetPassword.scss'
import { Card } from 'primereact/card'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { AuthCardFooter } from '../auth-card-footer/AuthCardFooter'
import { NavLink, useHistory } from 'react-router-dom'
import I18n from '../../../../utils/i18n/I18n'
import { getLocalizedValue } from '../../../../utils/helpers/Helper'
import { useMutation } from 'react-query'
import { resetPassword } from '../../../../services/settings-service'
import showPassword from '../../../../resources/images/icon/eye-show.svg'
import hidePassword from '../../../../resources/images/icon/eye-hide.svg'
import { useLoginAuthContext } from '../auth0/UserInfoProvider'

function ResetPassword() {
  let passwordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{10,})')
  let lowerCaseLetters = new RegExp('^(?=.*[a-z])')
  let upperCaseLetters = new RegExp('^(?=.*[A-Z])')
  let numerics = new RegExp('^(?=.*[0-9])')

  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [token, setToken] = useState('')
  const [emPlaceholder, setEmPlaceholder] = useState(getLocalizedValue(selectedLanguage, 't_email'))
  const [pdPlaceholder, setPdPlaceholder] = useState(getLocalizedValue(selectedLanguage, 't_new_password'))
  const [cpdPlaceholder, setCpdPlaceholder] = useState(getLocalizedValue(selectedLanguage, 't_confirm_password'))
  const [disable, setDisable] = useState(true)
  const resetPasswordMutation = useMutation(resetPassword)
  const [passwordErrorKey, setPasswordErrorKey] = useState('')
  const [isPwdChanged, setIsPwdChanged] = useState(false)
  const navigate = useHistory()
  const [isApiError, setApiError] = useState(false)

  function onPasswordChangeHandler(e) {
    if (e.target.id === 'password') {
      setPassword(e.target.value)
    } else {
      setConfirmPassword(e.target.value)
    }
  }

  function onKeyPressEventHandler(e) {
    const passwordSpecialChars = [33, 64, 35, 36, 37, 38, 42] // special characters  [!@#$%^&*]
    if (passwordSpecialChars.includes(e.charCode) || lowerCaseLetters.test(e.key) || upperCaseLetters.test(e.key) || numerics.test(e.key)) {
      return true
    } else {
      e.preventDefault()
    }
  }

  function onShowPasswordClick(e) {
    const passInput = e.target.previousElementSibling
    if(passInput.getAttribute('type') === 'password') {
      passInput.setAttribute('type', 'text')
    } else {
      passInput.setAttribute('type', 'password')
    }

    if(e.target.getAttribute('src') === showPassword) {
      e.target.setAttribute('src', hidePassword)
    } else {
      e.target.setAttribute('src', showPassword)
    }
  }

  async function onResetPasswordClickHandler() {
    try {
      const data = await resetPasswordMutation.mutateAsync({
        'password': password,
        'confirmPassword': confirmPassword,
        'email': email,
        'token': token
      })
      setIsPwdChanged(true)
      setDisable(true)
      navigate.push('/reset-password-success')
    } catch (e) {
      if (e.response.data.errors) {
        if (e.response.data.errors.hasOwnProperty('EmailNotFound')) {
          setPasswordErrorKey('t_api_email_error')
          setApiError(true)
        }
        if (e.response.data.errors.hasOwnProperty('InvalidToken')) {
          setPasswordErrorKey('t_invalid_token')
          setApiError(true)
        }
      } else {
        setApiError(false)
      }
    }
  }

  useEffect(() => {
    // set email token and placeholders
    const urlParams = new URLSearchParams(window.location.search)
    const urlEmailParam = urlParams.get('email')
    const urlToken = urlParams.get('token')
    let urlTokenFormatted
    if (urlToken) {
      urlTokenFormatted = urlToken.replaceAll(' ', '+')
    }
    if (urlEmailParam) {
      setEmail(urlEmailParam)
    }
    if (urlToken) {
      setToken(urlTokenFormatted)
    }

    setEmPlaceholder(getLocalizedValue(selectedLanguage, 't_email'))
    setPdPlaceholder(getLocalizedValue(selectedLanguage, 't_new_password'))
    setCpdPlaceholder(getLocalizedValue(selectedLanguage, 't_confirm_new_password'))


    // password validation
    const testPassword = passwordRegex.test(password)
    const testConfirmPassword = passwordRegex.test(confirmPassword)

    if (password && confirmPassword && testPassword && testConfirmPassword && password === confirmPassword) {
      setDisable(false)
    } else {
      setDisable(true)
    }

    //show password Error messages
    if (password && confirmPassword) {
      if (password !== confirmPassword) {
        setPasswordErrorKey('t_password_mismatch')
      } else if (!testPassword && !testConfirmPassword) {
        setPasswordErrorKey('t_password_criteria')
      } else {
        setPasswordErrorKey('')
      }
    }
  }, [password, confirmPassword, selectedLanguage])

  return (
    <div className="login min-width-760">
      <div className="login-page">
        <div className="auth-card-container reset-pass-container pt-40 pb-40">
          <Card className={'auth-card'} title={I18n('t_create_password')} footer={AuthCardFooter(true)}>
            <div className="p-grid card-1">
              <div className="card-2">
                <div className="p-col-12 content-col-input">
                  <div className="input">
                    <InputText name="email"
                      disabled
                      value={email}
                      keyfilter="email"
                      placeholder={emPlaceholder}
                      className="input-text input-inputtext" />
                  </div>
                </div>
                <div className="p-col-12">
                  <div className="input-info">
                    <p>{I18n('t_password_rules')}</p>
                  </div>
                </div>
                <div className="p-col-12 content-col-input">
                  <div className="input">
                    <InputText id="password"
                      name="password"
                      type="password"
                      value={password}
                      placeholder={pdPlaceholder}
                      onKeyPress={(e) => onKeyPressEventHandler(e)}
                      onChange={(e) => onPasswordChangeHandler(e)}
                      className="input-text input-inputtext" />
                    <img alt={getLocalizedValue(selectedLanguage, 't_show_hide_pass')} src={showPassword} className="show-hide-pass" onClick={(e) => onShowPasswordClick(e)} />
                  </div>
                </div>
                <div className="p-col-12 content-col-input">
                  <div className="input">
                    <InputText id="confirmPassword"
                      name="confirm-password"
                      type="password"
                      value={confirmPassword}
                      placeholder={cpdPlaceholder}
                      onKeyPress={(e) => onKeyPressEventHandler(e)}
                      onChange={(e) => onPasswordChangeHandler(e)}
                      className="input-text input-inputtext" />
                    <img alt={getLocalizedValue(selectedLanguage, 't_show_hide_pass')} src={showPassword} className="show-hide-pass" onClick={(e) => onShowPasswordClick(e)} />
                  </div>
                </div>
                {!isPwdChanged ?
                  <div className="p-col-12">
                    <span className="password-info">
                      {passwordErrorKey.length > 0 && password.length > 0 && confirmPassword.length > 0 ?
                        <span className="error-pwd">{I18n(passwordErrorKey)}</span> :
                        null
                      }
                    </span>
                  </div> :
                  null
                }
                <div className="p-col-12 card-4">
                  {isApiError ?
                    <div className="p-col-9 no-padding">
                      <div className="small-text">
                        <NavLink to="/forgot-password" className="login-nav">
                          {I18n('t_try_again')}
                        </NavLink>
                      </div>
                    </div>
                    : null
                  }
                  <div className="p-col-3 no-padding">
                    <Button label={I18n('t_reset')} disabled={disable} className="btn-warning sign-in-btn"
                      onClick={onResetPasswordClickHandler} />
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword