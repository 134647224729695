import {noRefetchOnWindowFocus, STALE_TIME_DEFAULT} from '../../../../../services/common/useQuery-config'
import {getLocalizedValue} from '../../../../../utils/helpers/Helper'
import {BOTH, descending, PERIOD_TYPES, UTILITY_LABELS_OBJECT_TITLE_CASE} from '../../../../../utils/helpers/Constants'
import {getInitialTimePeriod, getInitialPickerValue} from '../../../../../services/common/datepicker-service'

export const commonDialogStyle={width: '630px', height: 'fit-content'}

export const staleTimeDefault = 240000 // 4 min < default cache time

export function getDefaultQueryConfig(fetchOnMount, ) {
  return {...noRefetchOnWindowFocus, staleTime: STALE_TIME_DEFAULT, enabled: fetchOnMount}
}

export function getQueryParams(periodType, pickerValue, isTimePeriodChanged=false) {
  let queryParams
  if (periodType === PERIOD_TYPES.CUSTOM) {
    queryParams = {
      timePeriod: periodType,
      noOfMonths: pickerValue.pickerValueMonths,
      endMonth: pickerValue.pickerValueEndMonth,
      endYear: pickerValue.pickerValueEndYear,
      isTimePeriodChanged: isTimePeriodChanged
    }
  } else {
    queryParams = {timePeriod: periodType, isTimePeriodChanged: isTimePeriodChanged}
  }
  return queryParams
}

export function fundDataQualityStateInitialiser(querySearchParams, selectedLanguage) {
  
  return {
    periodType: getInitialTimePeriod(querySearchParams),
    pickerValue: getInitialPickerValue(querySearchParams),
    sortField: 'portionOfMissingData',
    sortOrder: -1,
    oDataSortOrder: descending,
    selectedUtility: getMultiSelectUtilityOptions(selectedLanguage).options,
    procurementTabInfoDialogVisible: false,
    isTimePeriodChanged: false,
    multiSelectedUtility: getMultiSelectUtilityOptions(selectedLanguage).options,
    availableUtilities:[],
    selectedView: BOTH,
    multiSelectedAgent:[],
    selectedAgent: [],
    first: 0,
    rows: 10,
  }
}

export function getMultiSelectUtilityOptions(selectedLanguage) {
  return {
    showMultiDropdown: true,
    placeHolderText: 't_select_utility',
    showPanelHeader: true,
    className: 'fund-dq-multiSelect-dropdown',
    panelHeaderLabel: getLocalizedValue(selectedLanguage, 't_select_all'),
    options: [
      {name: getLocalizedValue(selectedLanguage, 't_electricity'), key: UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY},
      {name: getLocalizedValue(selectedLanguage, 't_gas'), key: UTILITY_LABELS_OBJECT_TITLE_CASE.GAS},
      {name: getLocalizedValue(selectedLanguage, 't_otherfuelsandthermals'), key: UTILITY_LABELS_OBJECT_TITLE_CASE.OTHER_FUELS_THERMALS},
      {name: getLocalizedValue(selectedLanguage, 't_water'), key: UTILITY_LABELS_OBJECT_TITLE_CASE.WATER},
    ]
  }
}

export const required_Dq_Order = {
  'actualPercentage': null,
  'estimatedPercentage': null,
  'notCoveredPercentage': null,
  'incompletePercentage': null,
}