import React from 'react'
import {Skeleton} from 'primereact/skeleton'
import './ActionPlanGridSkeleton.scss'
import '../../../pages/assets/action-plan/actions/ActionPlan.scss'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import CSS_VARIABLES from '../../../../resources/css/_variables.scss'

const ActionPlanGridSkeleton = () => {
  const emptyArray = Array.from({length: 3})

  const bodyTemplate = () => {
    return <Skeleton/>
  }

  const headerTemplate = () => {
    return <div className="ml-1 mb-2">
      <Skeleton width={'5rem'}/>
    </div>
  }

  const getBodyClassName = () => {
    return 'table-body-action'
  }

  return (
    <div className="custom-skeleton p-rel p-d-flex p-flex-column p-ai-center users-page-skeleton">
      <DataTable value={emptyArray}
        className={'p-datatable-gridlines event-actions-grid'}>
        <Column field="actionDescription" header={headerTemplate} body={bodyTemplate}
          headerClassName="table-header-action"
          bodyClassName={'table-body-action-impact'} style={{width: '25%', color: CSS_VARIABLES.forest}}
        />
        <Column field="completion" header={headerTemplate} body={bodyTemplate}
          headerClassName="table-header-action"
          bodyClassName={getBodyClassName()} style={{width: '10%'}}
        />
        <Column field="impact" header={headerTemplate} body={bodyTemplate} headerClassName="table-header-action"
          bodyClassName={getBodyClassName()} style={{width: '10%'}}
        />
        <Column field="target" header={headerTemplate} body={bodyTemplate} headerClassName="table-header-action"
          bodyClassName={getBodyClassName()} style={{width: '10%'}}
        />
        <Column field="cost" header={headerTemplate} body={bodyTemplate} headerClassName="table-header-action"
          bodyClassName={getBodyClassName()} style={{width: '10%'}}
        />
        <Column field="costType" header={headerTemplate} body={bodyTemplate}
          headerClassName="table-header-action"
          bodyClassName={getBodyClassName()} style={{width: '10%'}}
        />
        <Column field="procuredBy" header={headerTemplate} body={bodyTemplate}
          headerClassName="table-header-action"
          bodyClassName={getBodyClassName()} style={{width: '10%'}}
        />
        <Column field="areaCovered" header={headerTemplate} body={bodyTemplate}
          headerClassName="table-header-action"
          bodyClassName={getBodyClassName()} style={{width: '10%'}}
        />
        <Column field="scope" header={headerTemplate} body={bodyTemplate} headerClassName="table-header-action"
          bodyClassName={getBodyClassName()} style={{width: '10%'}}
        />
      </DataTable>
    </div>
  )
}

export default ActionPlanGridSkeleton