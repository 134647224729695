import React, {useContext, useEffect, useState} from 'react'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import I18n from '../../../../../utils/i18n/I18n'
import exclamationIcon from '../../../../../resources/images/icon/exclamation-orange.svg'
import {FeatureFlagContext, PortfolioContext} from 'Contexts'
import {useMutation} from 'react-query'
import {
  addNewMissingDataRequestInternal,
  getFirstLastDateISO,
  monthsNumber
} from '../../../../../services/assets/dataquality-service'
import RequestDataDialog from './RequestDataDialog'
import ErrorDialog from '../../data-quality/error-dialog/ErrorDialog'
import {useHistory, useParams} from 'react-router-dom'
import {
  getSelectAllIconNode,
  getSelectAllIconNodeContainer,
  setSelectAllIcon
} from '../../../../../utils/helpers/DataTableUtils'
import {
  createNavURLForFundPages, getLocalStorageByKey, saveCustomPickerValues
} from '../../../../../utils/helpers/Helper'
import PropTypes from 'prop-types'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const ModalDialogMeterView = (props) => {
  const [selectedDataGaps, setSelectedDataGaps] = useState([])
  const [showRequestDataDlg, setShowRequestDataDlg] = useState(false)
  const [errorDialogData, setErrorDialogData] = useState()
  const [errorDialogVisible, setErrorDialogVisible] = useState(false)
  const pfContext = useContext(PortfolioContext)
  const rowDataCtx = pfContext.pfState.data
  const {assetId} = useParams()
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const CLASS_BTN_DISABLE = 'btn-disable'
  const MSG_KEY_TITLE_CANNOT_REQUEST_DATA = 't_title_cannot_request_data'
  const NO_SPACE = ''
  let data = props.data
  let hasGaps = data !== null ? data.filter(item => item.isConsumption === false) : 0
  const selectAllIconSelectors='.perf-modal-dlg-meterView .modal-dialog-meter-grid .p-datatable-thead div.p-checkbox-box span.p-checkbox-icon'
  const dataWithoutMeterReading = (props.data?.filter((item) => {
    return !item.isConsumption === true
  }))
  const {instanceName,fundName,orgName} = useParams()
  const history = useHistory()
  const featureContext            = useContext(FeatureFlagContext)
  const featureState              = featureContext.featureState

  useEffect(() => {
    const selectAllIconNode = getSelectAllIconNode(selectAllIconSelectors)
    setSelectAllIcon(selectedDataGaps, dataWithoutMeterReading, selectAllIconNode)
  }, [selectedDataGaps, dataWithoutMeterReading])

  const formatUnits = (value) => {
    let newValue = ''
    if (value) {
      newValue = Number(value).toLocaleString(selectedLanguage)
    }
    return newValue
  }

  const formatValue = (value, selectedUtility) => {
    let formattedValue
    let unit = ' kWh'

    if (selectedUtility === 'Water')
      unit = ' m³'
    else if (selectedUtility === 'Carbon')
      unit = ' CO₂e'
    else if (selectedUtility === 'Waste')
      unit = ' t'

    if (value !== undefined) {
      value = value.toFixed(2)
    }
    formattedValue = formatUnits(value) + unit
    return formattedValue
  }

  const selectedDataNotEmpty = () => selectedDataGaps && selectedDataGaps.length > 0

  const onEnterDataHandler = async () => {
    try {
      await missingDataMutation.mutateAsync({assetId, dataGap: getMissingDataReq()})
    } catch (error) {
      setErrorDialogData({title: I18n(MSG_KEY_TITLE_CANNOT_REQUEST_DATA), message: error.toString()})
      setErrorDialogVisible(true)
    }
  }

  const missingDataMutation = useMutation(addNewMissingDataRequestInternal, {
    onSuccess: (data) => {
      if (data) {
        //Redirect to the link provided
        window.open(data)
      } else {
        setErrorDialogData({title: I18n(MSG_KEY_TITLE_CANNOT_REQUEST_DATA), message: 'No redirect data from server'})
        setErrorDialogVisible(true)
      }
    }
  })

  const missingDataListReqDataHandler = (showRequestDataDlg) => {
    setShowRequestDataDlg(showRequestDataDlg)
  }

  const onDialogActionHandler = () => {
    setShowRequestDataDlg(!showRequestDataDlg)
  }

  function dlgCloseHandler() {
    setErrorDialogVisible(false)
  }

  function navToDQHome(){
    changeDatePicker()
    const utilityMap = `&tenantUtility=${props.utility}&landlordUtility=${props.utility}`
    const navURLConfig={
      instanceName: instanceName,
      orgName: orgName,
      fundName: fundName,
      assetId: assetId,
      pathSuffix: 'data-quality?view=summary'
    }
    history.push(createNavURLForFundPages(navURLConfig)+utilityMap)
  }

  function changeDatePicker() {
    const datePickerVal = getLocalStorageByKey('dpCustomTimePeriod')
    const year = props.yearMonth?.substring(2,4).toString()
    const month = parseInt(props.yearMonth?.substring(4,6)).toString()
    saveCustomPickerValues({...datePickerVal,pickerValueMonths: '1',pickerValueEndMonth:month,pickerValueEndYear:year})
  }

  const renderFooter = () => {
    return (
      data?.length > 0 && featureState.sieraplus_consumptionOperations ?
        <div className="btns-container" data-testid={'dq-break-down'}>
          <button className={'btn-element pointer-hightlight '}
            onClick={navToDQHome}>{I18n('t_view_detailed_dq_break_down')}
          </button>
        </div> :
        <div className="btns-container f-right flex" data-testid={'footer-buttons'}>
          {hasGaps.length > 0 &&
                  <>
                    <button
                      className={'btn-element mr-4 pointer-hightlight' + (!selectedDataNotEmpty() ? CLASS_BTN_DISABLE : NO_SPACE)}
                      disabled={!selectedDataNotEmpty()}
                      onClick={() => {missingDataListReqDataHandler(!showRequestDataDlg)}}>{I18n('t_btn_request')}
                    </button>
                    <button
                      className={'btn-element pointer-hightlight ' + (!selectedDataNotEmpty() ? CLASS_BTN_DISABLE : NO_SPACE)}
                      onClick={onEnterDataHandler} disabled={!selectedDataNotEmpty()}>{I18n('t_btn_enter')}
                    </button>
                  </>
          }
        </div>
    )
  }

  const dateTemplate = (rowData) => {
    return (
      <div className="date-template">
        <span className="meterId">{rowData.dateSummary}</span>
      </div>
    )
  }

  const meterTemplate = (rowData) => {
    return (
      <div className="meter-template">
        <span className="meterId">{rowData.electricityMPAN}</span>
        <span className="meter-category">{rowData.responsibility === 'Landlord' ? 'L' : 'T'}</span>
      </div>
    )
  }

  const locationTemplate = (rowData) => {
    let location = '-'
    if (rowData.location)
      location = rowData.location
    return (
      <div className="location-template">
        <span className="meterId">{location}</span>
      </div>
    )
  }

  const sourceTemplate = (rowData) => {
    let source = '-'
    if (rowData.source) {
      source = rowData.source
    }
    return (
      <div className="source-template">
        <span className="meterId">{source}</span>
      </div>
    )
  }

  const readingTemplate = (rowData) => {
    return (
      <div className="reading-template">
        {rowData.consumption > 0 ?
          <div className="reading-template-data">
            <span>{formatValue(rowData.consumption, rowData.utility)} {rowData.estimate === 'Estimate' ? '(EST)' : ''}</span>
          </div> :
          <div className="reading-template-data">
            <img src={exclamationIcon} alt="missing data"></img>
            <span className="reading-template-data-text">missing</span>
          </div>
        }
      </div>
    )
  }

  const readingHeaderTemplate = () => {
    return (
      <div className="reading-header-template">
        <span>{I18n('t_reading')}</span>
      </div>
    )
  }

  const onMeterSelectionChange = (evt) => {
    let newSelection = evt.value.filter(selection => !selection.isConsumption)
    const thNode = getSelectAllIconNodeContainer(evt)
    if (thNode) {
      const checkboxIconPiMinus = thNode.querySelector('.p-checkbox-icon.pi.pi-minus')
      const checkboxIconPiCheck = thNode.querySelector('.p-checkbox-icon.pi.pi-check')
      if (checkboxIconPiMinus) {
        newSelection = []
      }
      if (checkboxIconPiCheck) {
        newSelection = []
      }
    }
    setSelectedDataGaps(newSelection)
  }

  const onShowSelectionCheckbox = (rowData) => {
    return rowData.isConsumption || featureState.sieraplus_consumptionOperations ? null : !rowData.isConsumption
  }

  const getMissingDataReq = () => {
    let missingDataReqArray = new Array(selectedDataGaps.length)
    selectedDataGaps.forEach((meter, index) => {
      if (meter.isPartial) {
        missingDataReqArray[index] = {
          'meterID': meter.meterId,
          'dateStart': meter.partialStart,
          'dateEnd': meter.partialEnd
        }
      } else {
        const firstLastDate = getFirstLastDateISO(meter.year, (monthsNumber[meter.dataGapDate.toLowerCase()] - 1))
        missingDataReqArray[index] = {
          'meterID': meter.meterId,
          'dateStart': firstLastDate.firstDay,
          'dateEnd': firstLastDate.lastDay
        }
      }
    })
    return missingDataReqArray
  }

  return (
    <div>
      <div className="perf-modal-dlg-meterView" data-testid={'perf-modal-dlg-meterView'}>
        {data !== null && data.length > 0 ?
          <>
            <DataTable value={data} className="grid-nogutter modal-dialog-meter-grid"
              stripedRows
              onSelectionChange={e => onMeterSelectionChange(e)}
              scrollable
              scrollHeight="18rem"
              showSelectionElement={rowData => onShowSelectionCheckbox(rowData)}
              selection={selectedDataGaps}>
              <Column field="dateSummary" header={I18n('t_date')} body={dateTemplate} className="date-column" style={{width: '14.5%'}}></Column>
              <Column field="electricityMPAN" header={I18n('t_req_meter')} body={meterTemplate} className="meter-column" style={{width: '30%'}}></Column>
              <Column field="location" header={I18n('t_location')} body={locationTemplate} className="location-column" style={{width: '18.5%'}}></Column>
              <Column field="source" header={I18n('t_source')} body={sourceTemplate} className="source-column" style={{width: '12%'}}></Column>
              <Column field="consumption" header={readingHeaderTemplate()} body={readingTemplate} className="reading-column" style={{width: '20%'}}></Column>
              {!featureState.sieraplus_consumptionOperations && hasGaps.length > 0 && <Column className="checkbox-column" selectionMode="multiple" headerStyle={{width: '5%'}}/>}
            </DataTable>
            {renderFooter()}
          </>: <>
            <div className="no-result-msg">
              <span className="no-result-msg-text">{I18n('t_no_records_found')}</span>
            </div>
            {renderFooter()}
          </>}


      </div>
      <div className={'missing-datatable-view'}>
        <div>
          {showRequestDataDlg && <RequestDataDialog
            showDialog={showRequestDataDlg}
            onEventActionHideHandler={onDialogActionHandler}
            data={selectedDataGaps}
            reload={null}
            assetAddress={rowDataCtx.assetAddress}
            assetId={assetId}
          />}
          {errorDialogVisible &&
          <ErrorDialog data={errorDialogData} dialogVisible={errorDialogVisible} onHideHandler={dlgCloseHandler}
            closeHandler={dlgCloseHandler}/>}
        </div>
      </div>
    </div>

  )
}

ModalDialogMeterView.propTypes = {
  utility: PropTypes.string,
  yearMonth: PropTypes.number,
  data:PropTypes.array
}

export default ModalDialogMeterView

ModalDialogMeterView.displayName ='ModalDialogMeterView'