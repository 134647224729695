import PropTypes from 'prop-types'
import {useState} from 'react'
import {Button} from 'primereact/button'
import commentRed from '../../../../../resources/images/chart/comment-red.svg'
import commentBlue from '../../../../../resources/images/chart/comment-blue.svg'
import ModalDialogHome from '../../../../common/modal-dialogs/ModalDialogHome'
import PerformanceModalDialogHeader from '../dialogs/PerformanceModalDialogHeader'
import arrowImage from '../../../../../resources/images/icon/rectangle.png'
import {monthsNumber} from '../../../../../services/assets/dataquality-service'
import {getLocalizedValue, getHeaderForPerformanceModal} from '../../../../../utils/helpers/Helper'
import {useParams} from 'react-router-dom'
import usePerformanceDataMonthly from '../performance-hooks-&-helpers/usePerformanceDataMonthly'
import './EventBar.scss'
import {ActionEventType} from '../performance-hooks-&-helpers/performanceConstants'

const EventBar = (props) => {

  //region Declare useContext/useState/useQuery/useRef
  const {assetId} = useParams()
  const selectedLanguage = props.selectedLanguage
  const [eventBarState, setEventBarState]=useState({showAddNoteDlg: false, selectedEvent: null, yearMonth: null})

  function updateEventBarState(newState) {
    setEventBarState(previousState=>{
      return {
        ...previousState,
        ...newState
      }
    })
  }

  const {performanceDataMonthlyIsLoading,performanceDataMonthly} = usePerformanceDataMonthly(assetId, props.selectedUtilityName, eventBarState.yearMonth)
  //endregion

  //region Dialog & Overlay handlers
  const onEventActionHideHandler = () => {
    updateEventBarState({showAddNoteDlg: false})
    props.noteDlgHideHandler()
  }

  const closeDialogEv = () => {
    updateEventBarState({showAddNoteDlg: false, selectedEvent: null})
  }

  const addNoteHandler = (e, monthlyPerformance) => {
    const currentEvent = monthlyPerformance.events?.filter(x => x.eventType === ActionEventType.PERFORMANCE_VARIANCE)
    updateEventBarState({showAddNoteDlg: !eventBarState.showAddNoteDlg, selectedEvent: currentEvent[0], yearMonth:  monthlyPerformance.year + ('0' + monthsNumber[monthlyPerformance.month.toLowerCase()]).slice(-2)})
  }
  //endregion

  //region Helper methods
  function getMonthNotesInfo(monthlyPerformanceEvents) {
    let notesInfo = {}
    if (monthlyPerformanceEvents.length > 0) {
      notesInfo.icon = commentBlue
      notesInfo.count = monthlyPerformanceEvents.filter(i=> i.eventType === ActionEventType.PERFORMANCE_VARIANCE)[0]?.eventNotes.length
      notesInfo.className = 'notes-blue'
    } else {
      notesInfo.icon = commentRed
      notesInfo.count = '?'
      notesInfo.className = 'notes-red'
    }
    return notesInfo
  }

  function getTooltipButtonText(notesInfo) {
    let notesCount = isNaN(parseInt(notesInfo.count)) ? '?' : parseInt(notesInfo.count)
    return notesCount === '?' ? getLocalizedValue(selectedLanguage, 't_add_variance_note') : `${notesCount} ${getLocalizedValue(selectedLanguage, 't_notes_lowercase')}`
  }

  const handleMouseOver = (e) => {
    let targetElement = e.currentTarget
    targetElement.classList.add('month-comment-section-hover')
    let arrowLeft
    let tooltipElement = targetElement.lastElementChild
    let targetPosition = targetElement.getBoundingClientRect()
    let tooltipPosition = tooltipElement.getBoundingClientRect()
    arrowLeft = targetPosition.left - tooltipPosition.left + (targetElement.clientWidth / 2)
    targetElement.lastElementChild.style.setProperty('--overlayArrowLeft', arrowLeft - 7 + 'px')
  }

  const handleMouseLeave = (e) => {
    let targetElement = e.currentTarget
    targetElement.classList.remove('month-comment-section-hover')
  }

  function getPerformanceModalHeader() {
    return (performanceDataMonthly && props.assetPerformanceData)? getHeaderForPerformanceModal(performanceDataMonthly, props.showTarget, props.isGapFillingSelected):null
  }

  //endregion

  return (
    <div className="chart-event-bar">
      <div className="flex">
        {props.monthlyPerformancesForChartEvents?.map((monthlyPerformance, index) => {
          let notesInfo = getMonthNotesInfo(monthlyPerformance.events)
          let variance = monthlyPerformance.variancePercentage
          return (
            <div className="month-section" key={monthlyPerformance?.month}>
              {(notesInfo.count > 0 && (-5 > variance || 5 < variance)) ?
                <div className="month-section-wrapper" onMouseEnter={(e) => handleMouseOver(e)} onMouseLeave={(e) => handleMouseLeave(e)}>
                  <div className="month-comment-section" data-id={`month-comment-${index}`}>
                    <img className={`comment-icon ${notesInfo.className}`} src={notesInfo.icon} alt="" onClick={(e) => addNoteHandler(e, monthlyPerformance)}/>
                    <span className={`comment-count ${notesInfo.className}`} onClick={(e) => addNoteHandler(e, monthlyPerformance)}>{notesInfo.count}</span>
                  </div>
                  <div className="monthNotes-Tooltiptext">
                    <img src={arrowImage} className="tooltip-arrow" alt="arrow-icon" />
                    <Button label={getTooltipButtonText(notesInfo)} className="p-button-link addNote-btn" onClick={(e) => addNoteHandler(e, monthlyPerformance)}/>
                  </div>
                </div>
                :
                <></>
              }
            </div>
          )
        })}
      </div>
      <ModalDialogHome
        assetId={props.assetId}
        showDialog={eventBarState.showAddNoteDlg}
        onModalDialogCloseHandler={onEventActionHideHandler}
        header={!performanceDataMonthlyIsLoading && <PerformanceModalDialogHeader header={getPerformanceModalHeader()}/>}
        meterSummaryData = {performanceDataMonthly?.assetMeterDataQualitySummaries}
        activeTabIndex={1}
        reLoad={props.reLoad}
        dlgActionCleanupHandler={null}
        edit={true}
        eventsList={props?.monthlyPerformancesForChartEvents}
        selection={eventBarState.selectedEvent}
        onEventActionHideHandler={() => {closeDialogEv()}}
        data={props.assetEventsData}
      />
    </div>
  )
}

export default EventBar

EventBar.displayName = 'EventBar'

EventBar.propTypes = {
  assetId: PropTypes.string,
  monthlyPerformancesForChartEvents: PropTypes.array,
  noteDlgHideHandler: PropTypes.func,
  selectedUtilityName: PropTypes.string,
  selectedLanguage: PropTypes.string,
  assetPerformanceData: PropTypes.array,
  showTarget: PropTypes.bool,
  isGapFillingSelected: PropTypes.bool,
  assetEventsData: PropTypes.object,
  reLoad: PropTypes.func,
}