import axiosInstance from '../common/axios-config'
import {CUSTOM} from '../../utils/helpers/Constants'

export async function getAssetCarbonPerformance(keys){
  const queryKeys = keys.queryKey
  const {assetId, noOfMonthsPeriod, selectedUtilityName, time, timeEndDate} = queryKeys[1]
  let apiQueryParams

  if(time=== CUSTOM){
    apiQueryParams= `utility=${selectedUtilityName}&period=${time}&noOfMonths=${noOfMonthsPeriod}&endMonth=${timeEndDate}`
  } else {
    apiQueryParams =`utility=${selectedUtilityName}&period=${time}`
  }
  const {data} = await axiosInstance.get(`/api/v1/Assets/${assetId}/Carbon/Performance?${apiQueryParams}`)
  return data
}

export async function getAssetUtilityPerformance(keys){
  const queryKeys = keys.queryKey
  const {assetId, noOfMonthsPeriod, selectedUtilityName, time, timeEndDate} = queryKeys[1]
  let apiQueryParams

  if(time=== CUSTOM){
    apiQueryParams= `utility=${selectedUtilityName}&period=${time}&noOfMonths=${noOfMonthsPeriod}&endMonth=${timeEndDate}`
  } else {
    apiQueryParams =`utility=${selectedUtilityName}&period=${time}`
  }
  const {data} = await axiosInstance.get(`/api/v1/Assets/${assetId}/Utility/Performance?${apiQueryParams}`)
  return data
}

export const fetchPerformanceUtilitySummaryExport = async ({queryKey}) => {
  if(!queryKey[4] || !queryKey[5]) {
    const {data} = await axiosInstance.get(`/api/v1/Assets/${queryKey[1]}/Consumption/Performance/UtilitySummaryExport?period=${queryKey[2] === 'Custom' ? 'YTD' : queryKey[2]}&utility=${queryKey[3]}`)
    return data
  } else {
    const {data} = await axiosInstance.get(`/api/v1/PerformanceExports/AssetExportDataForMeters?assetId=${queryKey[1]}&period=${queryKey[2]}&utility=${queryKey[3]}&noOfMonths=${queryKey[4]}&endMonth=${queryKey[5]}&MonthlyData=${true}`)
    return data
  }
}

export const getConsumptionYTDTotals = async (keys) => {
  const queryKeys = keys.queryKey
  if(!queryKeys[3] || !queryKeys[4]) {
    const {data} = await axiosInstance.get(`/api/v1/Assets/${queryKeys[1]}/Consumption/Performance/Totals?period=${queryKeys[2]}`)
    return data
  } else {
    const {data} = await axiosInstance.get(`/api/v1/Assets/${queryKeys[1]}/Consumption/Performance/Totals?period=${queryKeys[2]}&noOfMonths=${queryKeys[3]}&endMonth=${queryKeys[4]}`)
    return data
  }
}

export const GetAssetEventsData = async (keys) => {
  const queryKeys = keys.queryKey
  if(queryKeys[1] !== null && queryKeys[1] !== undefined) {
    const {data} = await axiosInstance.get(`/api/v1/Assets/${queryKeys[1]}/Events`)
    return data
  }
}

export const eventsDataNotifications = async (assetId) => {
  const {data} = await axiosInstance.get(`/api/v1/Assets/${assetId}/Events`)
  return data
}

export const EnterMissingData = (enterDataInfo) => {
  return axiosInstance.post(`/api/v1/Assets/${enterDataInfo.assetId}/DataQualityGapRequest/`, enterDataInfo.data)
}

export const AddEventNotes = async (eventInfo) => {
  return await axiosInstance.post(`/api/v1/Assets/${eventInfo.assetId}/Events/${eventInfo.eventId}/Notes`, eventInfo.notesInfo)
}

export const helperMethodEvents = async (eventInfo) => {
  let helperId
  if(eventInfo.action==='updateEventNote'){
    await updateEventNoteData(eventInfo)
  }else if(eventInfo.action==='deleteEventNote'){
    await deleteEventNoteData(eventInfo)
  }else {
    await addEventNoteData(eventInfo)
  }
  return helperId
}

export const updateEventNoteData = async (eventInfo) => {
  const {data} = await axiosInstance.put(`/api/v1/Assets/${eventInfo.assetId}/Events/${eventInfo.eventId}/Notes/${eventInfo.notesInfo.noteID}`, eventInfo.notesInfo)
  return data
}

export const deleteEventNoteData = async (eventInfo) => {
  const {data} = await axiosInstance.delete(`/api/v1/Assets/${eventInfo.assetId}/Events/${eventInfo.eventId}/Notes/${eventInfo.notesInfo.noteID}`)
  return data
}


export const addEventNoteData = async (eventInfo) => {
  const {data} = await axiosInstance.post(`/api/v1/Assets/${eventInfo.assetId}/Events/${eventInfo.eventId}/Notes`, eventInfo.notesInfo)
  return data
}

export const GetPerformanceDataByMonth = async (keys) => {
  const queryKeys = keys.queryKey
  if(queryKeys[2] !== null && queryKeys[2] !== undefined) {
    const { data } = await axiosInstance.get(`/api/v1/Assets/${queryKeys[1]}/Consumption/Performance/${queryKeys[2]}?meterUtility=${queryKeys[3]}`)
    return data
  }
}

