import {memo} from 'react'
import PropTypes from 'prop-types'
import {utilitiesV3} from '../../pages/funds/fund-dashboard/fund-dashboard-hooks-&-helpers/fundDashboardHelper'
import FundPerformanceCardSkeleton from '../skeletons/fund-performance-cards/FundPerformanceCardSkeleton'
import {NavLink} from 'react-router-dom'
import {createNavURLForFundPages, getChartDatasets} from '../../../utils/helpers/Helper'
import {COL_FIELD_UTILITY, FUND_VIEWS_PAGES, UtilityTypes, Views} from '../../../utils/helpers/Constants'
import UtilityPerformanceTileV3 from './UtilityPerformanceTileV3'
import './UtilityTilesContainer.scss'

const UtilityTilesContainer = ({ performanceData, isLoadingPerformanceData, getFundCommonProps, view}) => {

  const {
    instanceName,
    orgName,
    fundName,
    pfState,
    pfDispatcher,
    reportingPeriod
  }=getFundCommonProps()

  function getPerformancePageUrl(utilityName) {
    let pathParams = {instanceName, orgName, fundName, pathSuffix: FUND_VIEWS_PAGES.performance}
    if (Views.AssetDashboard === view) {
      pathParams = {
        instanceName,
        orgName,
        fundName,
        assetId: performanceData.assetId,
        pathSuffix: FUND_VIEWS_PAGES.performance
      }
    }

    return {
      pathname: createNavURLForFundPages(pathParams), search: `?utility=${utilityName}`
    }
  }

  function getUtilityTileV3Props(utilityName, utilityPerformanceData, wasteData) {
    if(utilityName === UtilityTypes.RecycledWaste && wasteData != null)
    {
      utilityPerformanceData.wasteValue = wasteData.value
    }

    return {
      utilityPerformanceData,
      utilityName,
      reportingPeriodSelected: reportingPeriod,
      view,
      ...getChartDatasets(utilityPerformanceData, view, utilityName, wasteData)
    }
  }

  return (
    <div className="utility-tiles-container">
      {
        isLoadingPerformanceData ?
          (
            Object.keys(utilitiesV3).map((labelKey) => {
              return (
                <div
                  key={labelKey}
                  className={'utility-tiles-container__tile bg-white p-4'}>
                  <FundPerformanceCardSkeleton />
                </div>
              )
            })
          )
          :
          <>
            <div className='utility-tiles-container__tile' key={UtilityTypes.Carbon}>
              <NavLink onClick={() => {pfDispatcher({...pfState, ...{data: pfState?.data, colFieldFund: COL_FIELD_UTILITY.CarbonPercentage}})}} to={getPerformancePageUrl(UtilityTypes.Carbon)}>
                <UtilityPerformanceTileV3 {...getUtilityTileV3Props(UtilityTypes.Carbon, performanceData?.carbonEmission)} />
              </NavLink>
            </div>
            <div className='utility-tiles-container__tile' key={UtilityTypes.EnergyIntensity} role="presentation">
              <UtilityPerformanceTileV3 {...getUtilityTileV3Props(UtilityTypes.EnergyIntensity, performanceData?.energyIntensity)} />
            </div>
            <div className='utility-tiles-container__tile' key={UtilityTypes.Electricity}>
              <NavLink onClick={() => {pfDispatcher({...pfState, ...{data: pfState?.data, colFieldFund: COL_FIELD_UTILITY.ElectricityPercentage}})}} to={getPerformancePageUrl(UtilityTypes.Electricity)}>
                <UtilityPerformanceTileV3 {...getUtilityTileV3Props(UtilityTypes.Electricity, performanceData?.electricity)} />
              </NavLink>
            </div>
            <div className='utility-tiles-container__tile' key={UtilityTypes.GasFuelsAndThermals}>
              <NavLink onClick={() => {pfDispatcher({...pfState, ...{data: pfState?.data, colFieldFund: COL_FIELD_UTILITY.FuelsPercentage}})}} to={getPerformancePageUrl(UtilityTypes.GasFuelsAndThermals)}>
                <UtilityPerformanceTileV3 {...getUtilityTileV3Props(UtilityTypes.GasFuelsAndThermals, performanceData?.gasFuelsAndThermals)} />
              </NavLink>
            </div>
            <div className='utility-tiles-container__tile' key={UtilityTypes.Water}>
              <NavLink onClick={() => {pfDispatcher({...pfState, ...{data: pfState?.data, colFieldFund: COL_FIELD_UTILITY.WaterPercentage}})}} to={getPerformancePageUrl(UtilityTypes.Water)}>
                <UtilityPerformanceTileV3 {...getUtilityTileV3Props(UtilityTypes.Water, performanceData?.water)} />
              </NavLink>
            </div>
            <div className='utility-tiles-container__tile' key={UtilityTypes.RecycledWaste}>
              <NavLink onClick={() => {pfDispatcher({...pfState, ...{data: pfState?.data, colFieldFund: COL_FIELD_UTILITY.WastePercentage}})}} to={getPerformancePageUrl(UtilityTypes.Waste)}>
                <UtilityPerformanceTileV3 {...getUtilityTileV3Props(UtilityTypes.RecycledWaste, performanceData?.recycledWaste, performanceData?.waste)} />
              </NavLink>
            </div>
          </>
      }
    </div>
  )
}

UtilityTilesContainer.propTypes = {
  performanceData: PropTypes.object,
  isLoadingPerformanceData: PropTypes.bool,
  getFundCommonProps: PropTypes.func,
  view: PropTypes.string
}
UtilityTilesContainer.displayName = 'UtilityTilesContainer'

export default memo(UtilityTilesContainer)