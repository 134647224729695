import { useContext, useState, useEffect } from 'react'
import { FeatureFlagContext} from 'Contexts'
import { useHistory } from 'react-router-dom'
import useFundIds from 'utils/custom-hooks/useFundIds'
import { FUND_VIEWS_PAGES, PERIOD_TYPES } from 'utils/helpers/Constants'
import { getFundViewsNavLinkConfigsForFlags, createNavURLForFundPages } from 'utils/helpers/Helper'
import FundCommonHeader from '../common-header/FundCommonHeader'
import FundReportAssetBreakdown from './asset-breakdown/FundReportAssetBreakdown'
import FundReportSummaries from './summaries/FundReportSummaries'
import FundReportContentSidebar from './content-siderbar/FundReportContentSidebar'
import FundReportFilterHeader from './filter-header/FundReportFilterHeader'
import { datePickerQueryValues } from 'utils/query-params/QueryParams'
import { reportFrameworkOptions } from './mappings'
import AddEditAssetSidebar from 'components/pages/assets/asset-form/AddEditAssetSidebar'
import { GetFundReportSummaries } from 'services/funds/fund-reports-service'
import { noRefetchOnWindowFocus } from 'services/common/useQuery-config'
import { useQuery } from 'react-query'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const breakdownYearParamName = 'breakdownYear'
const frameworkParamName = 'framework'

const isFrameworkValid = (framework) => {
  return framework && reportFrameworkOptions.some(option => option.value === framework)
}

const isYearValid = (year) => {
  return year.length === 4 && !isNaN(new Date(year))
}

const FundReportsHome = () => {
  const { featureState } = useContext(FeatureFlagContext)
  const {loginState} = useLoginAuthContext()
  const language = loginState.userInfo.languagePreference
  const history = useHistory()
  const { fundId, fundGroupID, fundname, fundName, fundUrlName, instanceName, orgName } = useFundIds()
  const [reportSidebarState, setReportSidebarState] = useState({ isVisible: false, year: null })
  const [editDataSidebarState, setEditDataSidebarState] = useState({ isVisible: false, assetId: null, tabIndex: 0 })
  const [refetchTimeStamp, setRefetchTimeStamp] = useState(0)
  const searchParams = new URLSearchParams(history.location.search)
  const breakdownYear = searchParams.get(breakdownYearParamName)
  const framework = searchParams.get(frameworkParamName)
  
  const {
    isFetching: fundReportSummariesLoading,
    data: fundReportSummariesData,
    refetch: refetchFundReportSummaryData
  } = useQuery({
    queryKey: ['GetFundReportSummaries', fundGroupID],
    queryFn: GetFundReportSummaries,
    retry: false,
    refetchOnWindowFocus: noRefetchOnWindowFocus.refetchOnWindowFocus
  })

  useEffect(() => {
    const params = new URLSearchParams(history.location.search)
    let searchUpdated = false
    if (!isFrameworkValid(params.get(frameworkParamName))) {
      params.delete(frameworkParamName)
      params.append(frameworkParamName, reportFrameworkOptions[0].value)
      searchUpdated = true
    }
    const breakdownYear = params.get(breakdownYearParamName)
    if (breakdownYear != null && !isYearValid(breakdownYear)) {
      params.delete(breakdownYearParamName)
      searchUpdated = true
    }
    if (searchUpdated) {
      history.replace({ search: params.toString() }, history.location.state)
    }
  }, [history, breakdownYear, framework])

  if (fundGroupID === undefined || (breakdownYear && !isYearValid(breakdownYear))) {
    return
  }

  const onBackToListClick = () => {
    searchParams.delete(breakdownYearParamName)
    history.push('?' + searchParams.toString(), history.location.state)
  }

  const onSummaryClick = (year) => {
    searchParams.append(breakdownYearParamName, year)
    history.push('?' + searchParams.toString(), history.location.state)
  }

  const onCreateReportClick = (year) => {
    setReportSidebarState({ isVisible: true, year: year })
  }

  const onClickEditData = (isVisible, assetId) => {
    setEditDataSidebarState({ isVisible, assetId: assetId, tabIndex: 1})
  }

  const setIsSidebarVisible = (isVisible) => {
    setReportSidebarState({ isVisible, year: reportSidebarState.year })
  }

  const onDataCompletenessBarClick = (assetId) => {
    const navURLConfig = {
      instanceName,
      orgName,
      fundName,
      assetId,
      pathSuffix: FUND_VIEWS_PAGES.dataQuality
    }
    const datePickerValue = {
      pickerValueMonths: 12,
      pickerValueEndMonth: 12,
      pickerValueEndYear: breakdownYear.substring(2)
    }
    const datePickerQuery = datePickerQueryValues(PERIOD_TYPES.CUSTOM, datePickerValue)
    const searchParams = new URLSearchParams()
    searchParams.append('noOfMonths', datePickerQuery.noOfMonths)
    searchParams.append('endMonth', datePickerQuery.endMonth)
    searchParams.append('endYear', datePickerQuery.endYear)
    searchParams.append('timePeriod', datePickerQuery.timePeriodSelected)

    history.push({ pathname: createNavURLForFundPages(navURLConfig), search: searchParams.toString() })
  }

  const updateReportingFramework = (framework) => {
    searchParams.set(frameworkParamName, framework)
    history.push('?' + searchParams.toString(), history.location.state)
  }

  const onEditReportClick = (year) => {
    setReportSidebarState({ isVisible: true, year: year })
  }

  return (
    <div className='container-layout gutter fund-reports-page'>
      <div className={'fund-header'}>
        <FundCommonHeader
          hasDatePicker={false}
          hasBlueBackground={false}
          hasDropdownFilter={false}
          navLinkConfigs={getFundViewsNavLinkConfigsForFlags({ fundId, fundGroupID, fundname, fundUrlName }, language, featureState)}
        />
      </div>
      {isFrameworkValid(framework) &&
        <>
          <FundReportFilterHeader
            updateReportingFramework={updateReportingFramework} 
            framework={framework}
            setRefetchTimeStamp={setRefetchTimeStamp}
          />
          <section>
            {
              breakdownYear ?
                <FundReportAssetBreakdown
                  year={breakdownYear}
                  onBackToListClick={onBackToListClick}
                  onCreateReportClick={onCreateReportClick}
                  onClickEditData={onClickEditData}
                  onDataCompletenessBarClick={onDataCompletenessBarClick}
                  refetchTimeStamp={refetchTimeStamp}
                /> :
                <FundReportSummaries
                  fundReportSummariesLoading={fundReportSummariesLoading}
                  fundReportSummariesData={fundReportSummariesData}
                  refetchFundReportSummaryData={refetchFundReportSummaryData}
                  onSummaryClick={onSummaryClick}
                  onCreateReportClick={onCreateReportClick}
                  onEditReportClick={onEditReportClick}
                  refetchTimeStamp={refetchTimeStamp}
                />
            }
          </section>
          {reportSidebarState.isVisible && (
            <FundReportContentSidebar
              year={reportSidebarState.year}
              isVisible={reportSidebarState.isVisible}
              setIsVisible={setIsSidebarVisible}
            />
          )}
          {breakdownYear && (
            <AddEditAssetSidebar
              framework={framework}
              year={breakdownYear}
              newestYear={fundReportSummariesData?.summaries?.[0]?.['year']}
              oldestYear={fundReportSummariesData?.summaries?.[fundReportSummariesData?.summaries.length - 1]?.['year']}
              panelIndex={1}
              isSidebarVisible={editDataSidebarState.isVisible}
              setIsSidebarVisible={onClickEditData}
              assetIdAlt={editDataSidebarState?.assetId}
              isAssets={true}/>
          )}
        </>
      }
    </div>
  )
}

export default FundReportsHome
FundReportsHome.displayName = 'FundReportsHome'