import React, {useEffect, useState} from 'react'
import '../NzcCardContainer.scss'
import {noDataAvailableMessage} from '../../../../utils/helpers/MessageUtility'
import OverviewChart from '../../charts/overview-chart/OverviewChart'
import {
  ASSET_PAGES,
  FUND_DATAQUALITY_SCOPES,
  FY_START_FOR_ASSET_ACCESS, LOCAL_STORAGE_KEYS,
  monthListShortWithLocale
} from '../../../../utils/helpers/Constants'
import I18n from '../../../../utils/i18n/I18n'
import OverviewChartSkeleton from '../../charts/overview-chart/skeleton/OverviewChartSkeleton'
import {useQuery} from 'react-query'
import {GetNZCDataQualitySummaryData} from '../../../../services/funds/nzc-service'
import {noRefetchOnWindowFocus} from '../../../../services/common/useQuery-config'
import {CachedUserState, createEndMonthParam, createNavURLForFundPages} from '../../../../utils/helpers/Helper'
import {useHistory, useParams} from 'react-router-dom'
import {GetDefaultDateWithCachedData} from '../../../../utils/helpers/DatePickerUtils'
import {getTimeperiodLabel} from '../../../../services/common/datepicker-service'

const DataQualityCard =  (props) => {

  const {instanceName, orgName, fundName, assetId} = useParams()
  const history = useHistory()
  const [dpCustomTime, setDpCustomTime] = useState({})
  const [variables, setVariables] = useState([])
  const {isLoading: nzcDQSummaryDataLoading, data: nzcDQSummaryData} = useQuery(variables, GetNZCDataQualitySummaryData, {...noRefetchOnWindowFocus, enabled: !!props.scopeId})
  let isDataAvailable = nzcDQSummaryData?.data[0]

  // Get the customTime object and set it in variables to get Dq card values
  useEffect(() => {
    const customTime = GetDefaultDateWithCachedData(FY_START_FOR_ASSET_ACCESS, false, CachedUserState.getUserDataFromCache(LOCAL_STORAGE_KEYS.loginTimestamp))
    setDpCustomTime(customTime)
    const time = getTimeperiodLabel('CUS')
    const timeNoOfMonths = customTime && customTime.pickerValueMonths
    const timeEndDate = customTime && createEndMonthParam(customTime.pickerValueEndYear, customTime.pickerValueEndMonth)
    setVariables(['nzcDQSummaryData', props.scopeId, time, timeNoOfMonths, timeEndDate])
  }, [props.scopeId])


  const nzcDQCardClickHandler = () => {
    history.push({
      pathname: createNavURLForFundPages({instanceName, orgName, fundName, assetId, pathSuffix: ASSET_PAGES.dataQuality}),
      state: props.location?.state
    })
  }

  function getDataQualityHeaderBasedOn() {
    return <span>{dpCustomTime?.pickerValueMonths} {dpCustomTime?.pickerValueMonths === '1' ? I18n('t_month') : I18n('t_months')} {I18n('t_end_of')} {monthListShortWithLocale[dpCustomTime?.pickerValueEndMonth]} {dpCustomTime?.pickerValueEndYear}</span>
  }

  function dataQualityOverviewChart() {
    return (
      <div className={'summary-card nzc-DQMetric'}>
        <OverviewChart
          isLoading={nzcDQSummaryDataLoading}
          owner={FUND_DATAQUALITY_SCOPES.Both}
          data={nzcDQSummaryData?.data[0]}
          clickHandler={nzcDQCardClickHandler}
          basedOn={getDataQualityHeaderBasedOn()}
        />
      </div>
    )
  }

  const loadingSkeleton = <div className={'summary-card nzc-DQMetric'}><OverviewChartSkeleton/></div>
  const noData = <div className={'summary-card nzc-DQMetric'}>{noDataAvailableMessage()}</div>

  return (
    <div className={'dataQuality-card'}>
      {nzcDQSummaryDataLoading ? loadingSkeleton : <>{isDataAvailable ? dataQualityOverviewChart() : noData}</>}
    </div>
  )
}

export default React.memo(DataQualityCard)