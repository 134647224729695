import {useParams} from 'react-router-dom'
import UseUrlParameterIds from './UseUrlParameterIds'

const useFundIds = (history) => {
  const {instanceName, orgName, fundName } = useParams()
  let fundIds = {}
  if (history?.location.state) {

    const { fundId, fundGroupID, fundname, fundUrlName } = history.location.state
    fundIds = {
      fundId,
      fundGroupID,
      fundname,
      fundUrlName,
      instanceName, 
      orgName,
      fundName
    }
  } else {
    const {fundId, fundGroupId,fundUrlName } = UseUrlParameterIds({instanceName, orgName, fundName})

    const formattedFundName = fundName
      ?.replace(/-/g, ' ') // replace hyphens with spaces
      ?.replace(/\b\w/g, (char) => char.toUpperCase())

    fundIds = {
      fundId,
      fundGroupID: fundGroupId,
      fundname: formattedFundName, // Team Apple Fund
      fundUrlName, 
      instanceName, 
      orgName,
      fundName // "team-apple-fund"
    }
  }
  
  return fundIds
}

export default useFundIds