import React, {useEffect, useState} from 'react'
import './ConsumptionGenerateTemplate.scss'
import {Dropdown} from 'primereact/dropdown'
import I18n from '../../../../../utils/i18n/I18n'
import {metersIncludedOptions, typeOfActions, typeOfRecordOptions} from '../../../../../utils/helpers/Constants'
import {Checkbox} from 'primereact/checkbox'
import {InputText} from 'primereact/inputtext'
import moment from 'moment'
import {fileNameChecker, formatDateToDdMonYy, getLocalizedValue} from '../../../../../utils/helpers/Helper'
import GenerateExcelForBulkConsumption from '../consumption-generate-excel/GenerateExcelForBulkConsumption'
import PropTypes from 'prop-types'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const ConsumptionGenerateTemplate = (props) => {
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const { typeOfUpload } = props
  const currentDate = new Date()
  const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 3)
  const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1)
  const templateInputElements = {
    typeOfRecord: {label: I18n('t_consumption'), value: 'Consumption'},
    metersIncluded: {label: I18n('t_landlord_procured_meters'), value: 'Landlord procured meters'},
    inactiveMeters: false,
    timePeriodFrom: moment(startDate).format('MMM YY'),
    timePeriodTo: moment(endDate).format('MMM YY'),
    consumptionFileName: '',
    typeOfAction: null
  }

  const [inputElements, setInputElements] = useState(templateInputElements)
  const fileNamePortFolioModule = `${getLocalizedValue(selectedLanguage, 't_consumption_upload')} ${inputElements.timePeriodFrom}-${inputElements.timePeriodTo}`
  const fileNameOtherModule = props.history && props.history.location?.state?.fileName ? `${props.history.location?.state?.fileName} ${getLocalizedValue(selectedLanguage, 't_consumption_upload')} ${inputElements.timePeriodFrom}-${inputElements.timePeriodTo}` : ''
  const fileName = props.history && props.history.location?.state?.isAssets ? fileNamePortFolioModule : fileNameOtherModule
  const mandatoryChar = '*'
  const fileNameOfType = {
    'action-upload': `${!props.history?.location?.state?.isAssets ? props?.history?.location?.state?.fileName : ''} ${getLocalizedValue(selectedLanguage, 't_action_upload')} ${formatDateToDdMonYy()}`,
    'consumption-upload': fileName
  }
  const isActionUpload = typeOfUpload.includes('action-upload')

  useEffect(() => {
    setInputElements({
      ...inputElements,
      consumptionFileName: fileNameOfType[typeOfUpload]
    })
  }, [fileName])

  const generateTimePeriodOptions = () => {
    const startYear = new Date().getFullYear() - 1
    const endYear = new Date().getFullYear()
    const getMonth = new Date().getMonth() + 1
    const getPreviousMonth = new Date().getMonth()
    const options = []

    for (let year = startYear; year <= endYear; year++) {
      const startMonthValue = year === startYear ? getMonth : 1
      const endMonthValue = year === endYear ? getPreviousMonth : 12
      for (let month = startMonthValue; month <= endMonthValue; month++) {
        const monthValue = new Date(year, month - 1).toLocaleString('en-US', {month: 'short'})
        const yearValue = (new Date(year, month - 1).getFullYear()).toString().slice(-2)
        const label = `${monthValue} ${yearValue}`
        const value = `${monthValue} ${yearValue}`
        const index = options.length
        options.push({label, value, index})
      }
    }

    return options.reverse()
  }

  const commonDropdownItemTemplate = (option) => {
    return (
      <>
        <div className="dropdown-item-1">
          <div className="dropdown-item-2">{option.label}</div>
        </div>
        <div className={'dropdown-panel-item-horiz-line'}/>
      </>
    )
  }

  const onHandleChange = (event) => {
    setInputElements({
      ...inputElements,
      typeOfRecord: event.target.name === 'typeOfRecord' ? {value: event.target.value} : inputElements.typeOfRecord,
      typeOfAction: event.target.name === 'typeOfAction'? {value: event.target.value} : inputElements.typeOfAction,
      metersIncluded: event.target.name === 'metersIncluded' ? {value: event.target.value} : inputElements.metersIncluded,
      inactiveMeters: event.target.name === 'inactiveMeters' ? event.checked : inputElements.inactiveMeters,
      timePeriodFrom: event.target.name === 'timePeriodFrom' ? event.target.value : inputElements.timePeriodFrom,
      timePeriodTo: event.target.name === 'timePeriodTo' ? event.target.value : inputElements.timePeriodTo,
      consumptionFileName: event.target.name === 'consumptionFileName' ? fileNameChecker(event.target.value) : inputElements.consumptionFileName
    })
  }

  const disableToPeriodOptions = (option) => {
    const selectedFromIndex = generateTimePeriodOptions().reverse().findIndex(from => from.label === inputElements.timePeriodFrom)
    return option.index < selectedFromIndex
  }

  const typeOfRecordDropdown = (
    <Dropdown
      disabled
      appendTo="self"
      panelClassName="generate-dropdown-panel"
      options={typeOfRecordOptions}
      value={inputElements.typeOfRecord.value}
      name="typeOfRecord"
      className="sDropdown-1"
      onChange={onHandleChange}
      itemTemplate={commonDropdownItemTemplate}
    />
  )

  const typeOfActionDropdown = (
    <Dropdown
      appendTo="self"
      panelClassName="generate-dropdown-panel"
      options={typeOfActions}
      value={inputElements?.typeOfAction?.value}
      name="typeOfAction"
      placeholder="Select..."
      className="sDropdown-1"
      onChange={onHandleChange}
      itemTemplate={commonDropdownItemTemplate}
    />
  )

  const metersIncludedDropdown = (
    <Dropdown
      appendTo="self"
      panelClassName="generate-dropdown-panel"
      options={metersIncludedOptions}
      value={inputElements.metersIncluded.value}
      name="metersIncluded"
      className="sDropdown-1"
      onChange={onHandleChange}
      itemTemplate={commonDropdownItemTemplate}
    />
  )

  const timePeriodFromDropdown = (
    <Dropdown
      appendTo="self"
      panelClassName="generate-dropdown-panel"
      options={generateTimePeriodOptions()}
      value={inputElements.timePeriodFrom}
      name="timePeriodFrom"
      className="sDropdown-1"
      onChange={onHandleChange}
      itemTemplate={commonDropdownItemTemplate}
      scrollHeight="15em"
    />
  )

  const timePeriodToDropdown = (
    <Dropdown
      appendTo="self"
      panelClassName="generate-dropdown-panel"
      options={generateTimePeriodOptions()}
      value={inputElements.timePeriodTo}
      name="timePeriodTo"
      className="sDropdown-1"
      onChange={onHandleChange}
      itemTemplate={commonDropdownItemTemplate}
      scrollHeight="15em"
      optionDisabled={(option) =>  disableToPeriodOptions(option)}
    />
  )

  function onGenerate(message) {
    props.hideGenerateTemplate(message)
  }

  const generatedTemplateActonItems = (
    <div className='grid col-12'>
      {!isActionUpload ?
        <div className='col-6'>
          <div className='generate-template-modal__user-input mt-4'>
            <span
              className={'generate-template-modal__user-input--label'}>{I18n('t_type_of_record')}</span>
            {typeOfRecordDropdown}
          </div>
          <div role={'meters-included'} className='generate-template-modal__user-input mt-4'>
            <span
              className={'generate-template-modal__user-input--label'}>{I18n('t_meters_included_template')}</span>
            {metersIncludedDropdown}
            <div
              className="generate-template-modal__user-input--cb flex align-items-center">
              <Checkbox name='inactiveMeters' onChange={onHandleChange}
                checked={inputElements.inactiveMeters}/>
              <label className="text-sm ml-2">{I18n('t_include_inactive_meters')}</label>
            </div>
          </div>
        </div> : <div className='col-6'>
          <div className='generate-template-modal__user-input mt-4'>
            <span
              className={'generate-template-modal__user-input--label'}>{I18n('t_template_to_generate')}</span>
            {typeOfActionDropdown}
          </div>
        </div>
      }
      <div className='col-6'>
        {!isActionUpload &&
        <div role={'time-period'} className='generate-template-modal__user-input mt-4'>
          <span
            className={'generate-template-modal__user-input--label'}>{I18n('t_time_period')}</span>
          <div className='generate-template-modal__user-input--tperiod flex'>
            <span
              className={'generate-template-modal__user-input--tperiod__label'}>{I18n('t_from')}</span>
            {timePeriodFromDropdown}
            <span
              className={'generate-template-modal__user-input--tperiod__label'}>{I18n('t_to')}</span>
            {timePeriodToDropdown}
          </div>
          <span
            className={'generate-template-modal__user-input--tperiod-user-text'}>{I18n('t_time_period_user_text')}</span>
        </div>
        }
        <div className={'generate-template-modal__user-input mt-4'}>
          <span
            className={'generate-template-modal__user-input--label'}>{I18n('t_file_name')} {mandatoryChar}</span>
          <InputText className="generate-template-modal__user-input--file-name"
            value={inputElements.consumptionFileName.trim()} name='consumptionFileName'
            onChange={onHandleChange} maxLength={100} />
        </div>
      </div>
    </div>
  )

  return (
    <div className="generate-template-modal">
      <span role={'user-text'} className='generate-template-modal__user-text'>{I18n('t_generate_template_user_text')}</span>
      {generatedTemplateActonItems}
      <hr className='generate-template-modal__divider'/>
      <div data-testid={'generate-template'}>
        <GenerateExcelForBulkConsumption fileName={inputElements.consumptionFileName} onGenerate={onGenerate}
          additionalParams={inputElements} history={props.history}/>
      </div>
    </div>
  )
}

ConsumptionGenerateTemplate.propTypes = {
  typeOfUpload: PropTypes.string,
  history: PropTypes.object
}

ConsumptionGenerateTemplate.displayName = 'ConsumptionGenerateTemplate'

export default ConsumptionGenerateTemplate