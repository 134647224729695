import React from 'react'
import '../../StaticFundNetZeroCarbon.scss'
import { Text } from 'components/atomic/index'
import { chartSpecsToastKeys } from '../../StaticFundNetZeroCarbon'

const StaticFundNZCNotesCard = () => {
  return (
    <div className='static-net-zero-carbon__container' data-testid='static-nzc-notes'>
      <Text content='Model notes' size='m' className='mb-1'/>
      <div className='static-net-zero-carbon__card'>
        <Text content='t_chart_preconfigured'/>
        <ul className='p-0'>
          {chartSpecsToastKeys.map((key) => (
            <Text content={key} key={key} size='xs' htmlTag='li'/>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default StaticFundNZCNotesCard