import React from 'react'
import { Skeleton } from 'primereact/skeleton'
import './FundPerformanceStackedChartSkeleton.scss'

const FundPerformanceStackedChartSkeleton = () => {
  return (
    <>
      <div className="p-rel fund-performance-stacked-chart-skeleton">
        <div className="flex p-dir-col skeleton-labels">
          <Skeleton width="1.5rem" borderRadius="2px" height="0.7rem" className=""/>
          <Skeleton width="1.5rem" borderRadius="2px" height="0.7rem" className=""/>
          <Skeleton width="1.5rem" borderRadius="2px" height="0.7rem" className=""/>
          <Skeleton width="1.2rem" borderRadius="2px" height="0.7rem" className=""/>
          <Skeleton width="1.2rem" borderRadius="2px" height="0.7rem" className=""/>
          <Skeleton width="1.2rem" borderRadius="2px" height="0.7rem" className=""/>
          <Skeleton width="1.2rem" borderRadius="2px" height="0.7rem" className=""/>
          <Skeleton width="1rem" borderRadius="2px" height="0.7rem" className=""/>
          <Skeleton width="1rem" borderRadius="2px" height="0.7rem" className=""/>
          <Skeleton width="0.7rem" borderRadius="2px" height="0.7rem" className=""/>

        </div>
        <div className="flex justify-content-around">
          <Skeleton width="3rem" borderRadius="3px 3px 0 0" height="13rem" className="mt-5"/>
          <Skeleton width="3rem" borderRadius="3px 3px 0 0" height="7rem" className="mt-5"/>
          <Skeleton width="3rem" borderRadius="3px 3px 0 0" height="20.4rem" className="mt-5"/>
          <Skeleton width="3rem" borderRadius="3px 3px 0 0" height="10rem" className="mt-5"/>
        </div>
      </div>
      <div className="chart-skeleton-legends">
        <div className="flex justify-content-between">
          <Skeleton width="15rem" borderRadius="3px 3px 0 0" height="0.7rem" className="mt-5"/>
          <Skeleton width="25rem" borderRadius="3px 3px 0 0" height="0.71rem" className="mt-5"/>
        </div>
      </div>
    </>
  )
}

export default FundPerformanceStackedChartSkeleton