import React from 'react'
import PropTypes from 'prop-types'
import './Tag.scss'
import { useIntl } from 'react-intl'
import Icon from '../Icon/Icon'

const Tag = ({
  colour,
  contentKey,
  iconLeftId,
  iconRightId,
  testId,
  className,
}) => {
  const intl = useIntl()

  const getClassName = (colour, className) => {
    return `siera-tag siera-tag--${colour} ${className ?? ''}`.trim()
  }

  return (
    <span className={getClassName(colour, className)} data-testid={testId}>
      {iconLeftId && (
        <Icon
          id={iconLeftId}
          className={`siera-tag-icon--${colour}`}
          size={17}
          testId={testId ? `${testId}-icon-left` : null}
        />
      )}
      <span
        className={`siera-tag-text siera-tag-text--${colour}`}
        data-testid={testId ? `${testId}-text` : null}
      >
        {intl.messages.hasOwnProperty(contentKey)
          ? intl.formatMessage({ id: `${contentKey}` })
          : contentKey}
      </span>
      {iconRightId && (
        <Icon
          id={iconRightId}
          className={`siera-tag-icon--${colour}`}
          size={17}
          testId={testId ? `${testId}-icon-right` : null}
        />
      )}
    </span>
  )
}

Tag.propTypes = {
  colour: PropTypes.oneOf(['teal', 'sand', 'red', 'green', 'nature']),
  contentKey: PropTypes.string.isRequired,
  iconLeftId: PropTypes.node,
  iconRightId: PropTypes.node,
  testId: PropTypes.string,
  className: PropTypes.string,
}

Tag.defaultProps = {
  colour: 'sand',
}

export default Tag