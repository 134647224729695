import React, {useEffect, useState} from 'react'
import './ForgotPassword.scss'
import {Card} from 'primereact/card'
import {InputText} from 'primereact/inputtext'
import {Button} from 'primereact/button'
import {AuthCardFooter} from '../auth-card-footer/AuthCardFooter'
import {NavLink, useHistory} from 'react-router-dom'
import I18n from '../../../../utils/i18n/I18n'
import {getLocalizedValue} from '../../../../utils/helpers/Helper'
import { useMutation } from 'react-query'
import { forgotPassword } from '../../../../services/settings-service'
import ForgotPasswordSkeleton from '../../../common/skeletons/forgot-password-skeleton/ForgotPasswordSkeleton'
import { useLoginAuthContext } from '../auth0/UserInfoProvider'

function ForgotPassword() {
  let emailRegex = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/g)

  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const [username, setUsername]           = useState('')
  const [unPlaceholder, setUnPlaceholder] = useState(getLocalizedValue(selectedLanguage, 't_email'))
  const [isLoading, setIsLoading]         = useState(false)
  const forgotPasswordMutation            = useMutation(forgotPassword)
  const navigate                          = useHistory()
  const [isError, setIsError]             = useState(false)


  async function onForgetPasswordClickHandler() {
    const testEmail = emailRegex.test(username)
    if (username.length > 0 && testEmail) {
      setIsError(false)
      setIsLoading(true)
      try {
        const data = await forgotPasswordMutation.mutateAsync({'email': username,})
        navigate.push('/forgot-password-success')
      } catch (e) {
        console.log(e)
      }
    } else {
      setIsError(true)
    }
  }

  useEffect(() => {
    setUnPlaceholder(getLocalizedValue(selectedLanguage,'t_email'))
  },[selectedLanguage, username])

  const getForgotPwdCardContent = () =>
    <div className="p-grid auth-card-content">
      <div className="p-col-12 no-padding content-col-input">
        <InputText name="username" value={username} keyfilter="email" placeholder={unPlaceholder} onChange={(e) =>
          setUsername(e.target.value)} className="input-input-text"/>
      </div>
      <div className="p-col-12 card-5 content-col-labels-btn labels-grid-container no-padding">
        <div className="p-col-9 no-padding label-error-sign-txts">
          <div className="xx-small-text-bold label-error-msg">{isError?I18n('t_error_user_username'):''}</div>
          <div className="label-sign-txt"><NavLink to="/login" className="login-nav">{I18n('t_back_sign_in')}</NavLink></div>
        </div>
        <div className="p-col-3 no-padding btn-container">
          <Button label={I18n('t_submit')} onClick={onForgetPasswordClickHandler} className="p-button-warning submit-btn clickable-comp-highlight"/>
        </div>
      </div>
    </div>

  return (
    <div className="forgot-pwd full-height-minus-header min-width-760">
      <div className="forgot-pwd-page">
        <div className="auth-card-container">
          <Card className={'auth-card'} title={I18n('t_forgot_password')} footer={AuthCardFooter(true)}>{!isLoading ? getForgotPwdCardContent() :
            <ForgotPasswordSkeleton/>}
          </Card>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword