import {useEffect, useState} from 'react'
import './EpcAnalysis.scss'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import ProgressBarWrapper from '../progress-bar-epc-action/ProgressBarWrapper'
import {epcRating} from '../../../../../../utils/helpers/Constants'
import I18n from '../../../../../../utils/i18n/I18n'
import { truncateDecimal } from '../../../../../../utils/helpers/Helper'
import PropTypes from 'prop-types'
import Text from '../../../../../atomic/Text/Text'

function NoDataFound({message}) {
  return <div className="no-result-msg">
    <span className="no-result-text">{message}</span>
  </div>
}

const EpcAnalysisV3 = ({epcData, isDependentLoading = false, featureState}) => {
  const [maxPercent, setMaxPercent] = useState(100)
  const [rating, setRating] = useState([])
  const ratingForLoading = Array.from({ length: 7 })
  const noEpc = 'No EPC'
  const nc = 'n/c'
  const certificateArray = ['A+','A','B','C','D','E','F','G']

  useEffect(() => {
    if (epcData && epcData.data) {
      const alteredRatingArray = certificateArray.map(cert=>{
        return [cert,epcData.data.epcRatingList[cert] ? epcData.data.epcRatingList[cert] : 0]
      })
      setRating(alteredRatingArray)
      const getAllRatingValues = Object.values(epcData.data.epcRatingList)
      setMaxPercent(Math.max(...getAllRatingValues.map(individualRating => individualRating)))
    }else{
      const alteredRatingArray = certificateArray.map(cert=>{
        return [cert, 0]
      })
      setRating(alteredRatingArray)
      setMaxPercent(0)
    }
  }, [epcData?.data])

  function ratingTemplate(rowData,index) {
    const newPercentage = (rowData[1] * 100) / maxPercent
    const label = rowData[0] === noEpc ? nc : rowData[0]
    return epcRating[label] ?
      <ProgressBarWrapper
        percentageData={newPercentage ? newPercentage : 0}
        label={label}
        percent={truncateDecimal(rowData[1], 1)}
        bgColor={epcRating[label]}
        key={index}
        className="progress-bar-v3"
      />
      :
      null
  }

  function ratingTemplateForLoading() {
    return <ProgressBarWrapper isLoading={true}/>
  }

  function ratingGrid() {
    return rating.length > 0 ? rating.map((epc,index) => ratingTemplate(epc,index)) : <NoDataFound message={I18n('t_no_certs')}/>
  }

  return (
    <div className={'epc-analysis epc-analysis-v3'} data-testid={'EPC-analysis'}>
      <div className={'coverage'} role={'floor-coverage'}>
        <Text content='t_coverage_by_floor_area' />
        <label>
          {epcData?.data?.totalFloorAreaPercentage ? truncateDecimal(epcData.data.totalFloorAreaPercentage, 1) : 0}%
        </label>
      </div>
      <div className={'valid-scores'} role={'valid-certs'}>
        <div className={'mb-3'}>
          <label className={'c-grey'}>{I18n('t_valid_certs_by_floor_area')}</label>
        </div>
        {epcData?.isLoading || isDependentLoading ?
          <DataTable className={'grid'} value={ratingForLoading}>
            <Column body={ratingTemplateForLoading}/>
          </DataTable> : ratingGrid()
        }
      </div>
      <div className={'epc-expiration'}  role={'epc-expire'}>
        <div className={'epc-expiration-title-percentage'}>
          <label className={'c-grey'}>{I18n('t_expire_within_12_months')}</label>
          <div className={'percentage-label'}>{epcData?.data?.epcExpiringPercentage ?
            truncateDecimal(epcData.data.epcExpiringPercentage, 1) : 0}%</div>
        </div>
        <div>
          {epcData?.isLoading || isDependentLoading ?
            <ProgressBarWrapper isLoading={true}/> :
            <ProgressBarWrapper percentageData={epcData?.data?.epcExpiringPercentage ?
              truncateDecimal(epcData.data.epcExpiringPercentage,1) : 0} bgColor={'d-blue'} isExpiry={true}/>
          }
        </div>
      </div>
    </div>
  )
}

EpcAnalysisV3.propTypes = {
  epcData: PropTypes.object,
  isDependentLoading: PropTypes.bool
}

EpcAnalysisV3.displayName = 'EpcAnalysis'

export default EpcAnalysisV3