import React from 'react'
import {Skeleton} from 'primereact/skeleton'
import  '../../../pages/admin/users/users-home/UsersHome.scss'

const GroupsDetailsSkeleton = () => {

  return (
    <div className="custom-skeleton p-rel flex flex-column">
      <div>
        <Skeleton  width="33.677rem" height="3.75rem" className="mb-5"/>
        <Skeleton  width="33.677rem" height="8.75rem" className="mb-5"/>
        <br/><br/>
        <Skeleton  width="77.677rem" height="0.4rem" className="mb-6"/>
        <Skeleton  width="13.19rem" height="3.68rem" className="mb-4"/>
      </div>
    </div>
  )
}

export default GroupsDetailsSkeleton
