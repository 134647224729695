import React, {useEffect} from 'react'
import {Dialog} from 'primereact/dialog'
import iconErrorType from '../../../../../resources/images/icon/icon-error-type.svg'
import './ErrorDialog.scss'
import {getLocalizedValue, unBlockDialogScroll} from '../../../../../utils/helpers/Helper'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const ErrorDialog = ({data, dialogVisible, onHideHandler, closeHandler}) => {
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference

  useEffect(()=>{
    return unBlockDialogScroll
  }, [])

  function closeAction() {
    if(closeHandler){
      closeHandler()
    }
  }

  return (
    <Dialog className="error-dlg-root" visible={dialogVisible} onHide={onHideHandler} blockScroll>
      <div className="grid dlg-header">
        <div className="col-1 header-icon-type"><img src={iconErrorType} alt={getLocalizedValue(selectedLanguage, 't_info')} /></div>
        <div className="col-10 header-title">{data?.title}</div>
        <div className="col-1 header-icon-close">
          <i className="pi pi-times" onClick={closeAction} />
        </div>
      </div>
      <div className="dlg-content">
        <div className="error-msg">{data?.message}</div>
      </div>
    </Dialog>
  )
}

export default ErrorDialog