import PropTypes from 'prop-types'
import MultiSelect from '../../../../atomic/MultiSelect/MultiSelect'
import Button from '../../../../atomic/Button/Button'
import {getLocalizedValue, selectedItemTemplate} from '../../../../../utils/helpers/Helper'
import {BOTH, LANDLORD, TENANT, UTILITY_LABELS_OBJECT_TITLE_CASE} from '../../../../../utils/helpers/Constants'
import {Text} from '../../../../atomic'

function FundDataQualityFilterBar({
  multiSelectOnChangeHandler,
  multiSelectedOptions,
  multiSelectAgentOnChangeHandler,
  multiSelectedAgentOptions,
  multiSelectAgentOptions,
  selectedLanguage,
  updateFundDataQualityState,
  selectedView,
  disableAgentDropDown
}) {

  const options= [
    {name: getLocalizedValue(selectedLanguage, 't_electricity'), key: UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY},
    {name: getLocalizedValue(selectedLanguage, 't_gas'), key: UTILITY_LABELS_OBJECT_TITLE_CASE.GAS},
    {name: getLocalizedValue(selectedLanguage, 't_otherfuelsandthermals'), key: UTILITY_LABELS_OBJECT_TITLE_CASE.OTHER_FUELS_THERMALS},
    {name: getLocalizedValue(selectedLanguage, 't_water'), key: UTILITY_LABELS_OBJECT_TITLE_CASE.WATER},
  ]

  function updateViewSelector(newView) {
    updateFundDataQualityState({selectedView: newView, first: 0, rows: 10})
  }

  return (
    <div className={'dq-filter-bar col-12 flex'}>
      <div className={'col-6 flex'}>
        <div className={'utility-section flex flex-column mr-4'}>
          <Text content={'t_utilities'} className={'pb-2'} />
          <MultiSelect
            value={multiSelectedOptions}
            onChange={(e)=>multiSelectOnChangeHandler(e.value)}
            options={options}
            optionLabel='name'
            placeholderKey='t_select_utility'
            selectedItemTemplate={(option) => selectedItemTemplate(option, multiSelectedOptions)}
            maxSelectedLabels={3}
            testId={'utility-selector'}
          />
        </div>
        <div className={'managing-agent-section flex flex-column'}>
          <Text content={'t_managing_agent_upper_case'} className={'pb-2'}/>
          <MultiSelect
            value={multiSelectedAgentOptions}
            onChange={(e)=>multiSelectAgentOnChangeHandler(e.value)}
            options={multiSelectAgentOptions}
            optionLabel='label'
            placeholderKey='t_select_agent'
            selectedItemTemplate={(option) => selectedItemTemplate(option, multiSelectedAgentOptions)}
            showSelectAll
            disabled={disableAgentDropDown}
            testId={'agent-selector'}
          />
        </div>
      </div>
      <div className={'col-6 flex justify-content-end align-items-end selectors'}>
        <Button
          content="t_total_data"
          className={'total-data'}
          onClick={() => updateViewSelector(BOTH)}
          outlined={selectedView!==BOTH}
        />
        <Button
          content="t_landlord"
          className={'no-border-radius'}
          onClick={() => updateViewSelector(LANDLORD)}
          outlined={selectedView!==LANDLORD}
        />
        <Button
          content="t_tenant"
          className={'tenant-data'}
          onClick={() => updateViewSelector(TENANT)}
          outlined={selectedView!==TENANT}
        />
      </div>
    </div>
  )
}

FundDataQualityFilterBar.propTypes = {
  selectedLanguage: PropTypes.string,
  multiSelectedOptions: PropTypes.array,
  multiSelectOnChangeHandler: PropTypes.func,
  multiSelectAgentOnChangeHandler: PropTypes.func,
  multiSelectedAgentOptions: PropTypes.array,
  multiSelectAgentOptions: PropTypes.array,
  disableAgentDropDown: PropTypes.bool
}

FundDataQualityFilterBar.defaultProps = {
  multiSelectAgentOptions: []
}

export default FundDataQualityFilterBar