import CustomCheckbox from 'components/common/custom-checkbox/CustomCheckbox'
import {Field, ErrorMessage} from 'formik'
import PropTypes from 'prop-types'

const CheckboxFormik = ({ name, testId, labelText, hasLabel, variantSize, value, onChange }) => {
  return (
    <div className='my-2'>
      <Field name={name}>
        {({ field}) => (
          <CustomCheckbox
            {...field}
            name={name}
            value={value}
            checked={value}
            hasLabel={hasLabel}
            labelText={labelText}
            variantSize={variantSize}
            onChange={onChange}
          />
        )}
      </Field>
      <ErrorMessage name={name} component="div" className="error medium-text my-0" data-testid={`${testId}-error`}/>
    </div>
  )
}

CheckboxFormik.propTypes = {
  labelText: PropTypes.string,
  hasLabel: PropTypes.bool,
  variantSize: PropTypes.string,
  name: PropTypes.string,
  testId: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  value: PropTypes.bool
}

export default CheckboxFormik