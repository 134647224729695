import React from 'react'
import { Skeleton } from 'primereact/skeleton'
import './PassportPageSkeleton.scss'

export function PassportPageChartSkeleton () {
  return (
    <>
      <div className="p-rel passport-chart-skeleton">
        <div className={'utility-title'}>
          <Skeleton width="8rem" borderRadius="2px" height="1rem" className=""/>
        </div>
        <div className="passport-graph">
          <div className="flex justify-content-around">
            <Skeleton width="2.5rem" borderRadius="3px 3px 0 0" height="13rem"/>
            <Skeleton width="2.5rem" borderRadius="3px 3px 0 0" height="7rem"/>
            <Skeleton width="2.5rem" borderRadius="3px 3px 0 0" height="18rem"/>
            <Skeleton width="2.5rem" borderRadius="3px 3px 0 0" height="15rem"/>
            <Skeleton width="2.5rem" borderRadius="3px 3px 0 0" height="10.4rem"/>
            <Skeleton width="2.5rem" borderRadius="3px 3px 0 0" height="5rem"/>
          </div>
        </div>
        <Skeleton width="8rem" borderRadius="2px" height="1rem" className="ml-4"/>
      </div>
    </>
  )
}

/*
<div className="flex p-dir-col skeleton-labels">
    <Skeleton width="1.5rem" borderRadius="2px" height="0.7rem"/>
    <Skeleton width="1.5rem" borderRadius="2px" height="0.7rem"/>
    <Skeleton width="1.5rem" borderRadius="2px" height="0.7rem"/>
    <Skeleton width="1.2rem" borderRadius="2px" height="0.7rem"/>
    <Skeleton width="1.2rem" borderRadius="2px" height="0.7rem"/>
    <Skeleton width="1.2rem" borderRadius="2px" height="0.7rem"/>
    <Skeleton width="1.2rem" borderRadius="2px" height="0.7rem"/>
    <Skeleton width="1rem" borderRadius="2px" height="0.7rem"/>
    <Skeleton width="1rem" borderRadius="2px" height="0.7rem"/>
    <Skeleton width="0.7rem" borderRadius="2px" height="0.7rem"/>
</div>*/
export function PassportPageConsumptionSummarySkeleton(){
  return(
    <>
      <div className={'col-12'}>
        <Skeleton width="7rem" borderRadius="2px" height="1.5rem"/>
      </div>
      <div className="col-12 no-padding-top" style={{marginTop:'1.125rem'}}>
        <Skeleton width="4rem" borderRadius="2px" height="1rem"/>
      </div>
    </>
  )
}

export function PassportPageSingleLineSkeleton(){
  return(
    <div className="col-12 no-padding-top">
      <Skeleton width="3rem" borderRadius="2px" height="1rem" />
    </div>
  )
}


