import {Avatar} from 'primereact/avatar'
import PropTypes from 'prop-types'

const CustomAvatar = ({label, size, shape, icon, styling}) => {
  return <Avatar label={label} size={size} shape={shape} icon={icon} className={styling}/>
}

CustomAvatar.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  shape: PropTypes.string,
  size: PropTypes.string,
  styling: PropTypes.string,
}

CustomAvatar.displayName = 'CustomAvatar'

export default CustomAvatar