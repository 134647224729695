import React, {useEffect, useState} from 'react'
import {Panel} from 'primereact/panel'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'

const CommercialEventModalDialog = (props) => {

  const [eventData, setEventData] = useState([])

  useEffect(() => {
    let data = props.data
    if (data) {
      let unitName =  data.content.filter(x => x.key === 'UnitName')[0].value

      const eventInfo = [
        {title: 'Unit name', value: unitName},
        {title: 'Unit ref.', value: ''},
        {title: 'Occupier', value: ''},
        {title: 'Floor area', value: ''},
        {title: 'Lease start', value: ''},
        {title: 'Lease break', value: ''},
        {title: 'Lease expiry', value: ''},
        {title: 'Est. rental value', value: ''}
      ]

      setEventData(eventInfo)
    }
  }, [props.data])

  return (
    <div className="commercialEvent-ModalDialog" data-testid={'commercial-event-dialog'}>
      <Panel className="ce-panel">
        <DataTable value={eventData.slice(0, 4)}>
          <Column field="title" className="data-key"/>
          <Column field="value" className="data-value"/>
        </DataTable>
      </Panel>
      <Panel className="ce-panel">
        <DataTable value={eventData.slice(-4)}>
          <Column field="title" className="data-key"/>
          <Column field="value" className="data-value"/>
        </DataTable>
      </Panel>
    </div>
  )
}

export default CommercialEventModalDialog

CommercialEventModalDialog.displayName = 'CommercialEventModalDialog'