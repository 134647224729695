import {Card} from 'primereact/card'
import {LANDLORD, PERIOD_TYPES, TENANT} from '../../../../../../utils/helpers/Constants'
import {getLocalizedValue} from '../../../../../../utils/helpers/Helper'
import {getMeterModalParams, isOverviewGraphDataLoading, updateMeterDataArea, updateMeterDataParams, updateMeterDataUtility} from '../../data-quality-hooks-&-helpers/dataQualityHelper'
import DataQualityOverviewGraphPdf from '../../landlord-overview/DataQualityOverviewGraphPdf'
import IndividualMeterIndicator from '../../../performance/individual-meter-indicator/IndividualMeterIndicator'
import PropTypes from 'prop-types'

const DataQualityHomeTabPdf=(props)=> {

  const {
    dataQualityHomeState,
    updateDataQualityHomeState,
    meterData,
    timePeriodState,
    time,
    assetId,
    selectedLanguage,
    rowData,
    selectedTimePeriod,
    dqOverviewDataForLandlord,
    dqOverviewDataForTenant
  } = props

  return(
    <div id="dqImage" className="charts-pdf">
      <Card id="landlordImage" className="dq-pdf-width">
        <div className="pb-4 black-text ex-large-text-bold">
          <span className="pb-2">{rowData?.assetAddress}</span><br/>
          <span>{rowData?.fundName}</span>
        </div>
        <span className="pl-3">{timePeriodState.timePeriod === PERIOD_TYPES.CUSTOM ? selectedTimePeriod : timePeriodState.timePeriod} -{getLocalizedValue(selectedLanguage, 't_landlord')}</span>
        <div className="pt-4" id="landlord">
          <div className="p-3">
            {
              isOverviewGraphDataLoading(dqOverviewDataForLandlord) ?
                null
                :
                <DataQualityOverviewGraphPdf graphData={dqOverviewDataForLandlord.data} area={LANDLORD} userDataColor={dataQualityHomeState.colorLabelLandlord}/>
            }
          </div>
        </div>
      </Card>
      <Card id="tenantImage" className="mt-2 dq-pdf-width">
        <div className="pb-4 black-text ex-large-text-bold">
          <span>{rowData?.fundName}</span><br/>
          <span>{rowData?.assetAddress}</span>
        </div>
        <span className="pl-3">{timePeriodState.timePeriod === PERIOD_TYPES.CUSTOM ? selectedTimePeriod : timePeriodState.timePeriod} -{getLocalizedValue(selectedLanguage, 't_tenant')}</span>
        <div className="pt-4" id="tenant">
          <div className="p-3">
            {
              isOverviewGraphDataLoading(dqOverviewDataForTenant) ?
                null
                :
                <DataQualityOverviewGraphPdf graphData={dqOverviewDataForTenant.data} area={TENANT} userDataColor={dataQualityHomeState.colorLabelTenant}/>
            }
          </div>
        </div>
      </Card>
      <Card id="dqMeterImage" className="mt-2 dq-pdf-width" >
        <div className="pb-4 black-text ex-large-text-bold">
          <span>{rowData?.fundName}</span><br/>
          <span>{rowData?.assetAddress}</span>
        </div>
        <span className="pl-3">{timePeriodState.timePeriod === PERIOD_TYPES.CUSTOM ? selectedTimePeriod : timePeriodState.timePeriod}</span>
        <div id="dqMeter" className="p-p-2">
          <IndividualMeterIndicator
            isPdf={true}
            meterData={meterData}
            updateUtility={(newUtilObj) => updateMeterDataUtility(newUtilObj, updateDataQualityHomeState)}
            updateArea={(newAreaObj) => updateMeterDataArea(newAreaObj, updateDataQualityHomeState)}
            updater={(newUtil, newArea) =>
              updateMeterDataParams(newUtil, newArea,  assetId, updateDataQualityHomeState, timePeriodState, time)
            }
            utility={dataQualityHomeState.selectedUtility}
            area={dataQualityHomeState.selectedArea}
            meterModalParams={getMeterModalParams(assetId, time, timePeriodState)} />
        </div>
      </Card>
    </div>
  )
}

export default DataQualityHomeTabPdf

DataQualityHomeTabPdf.displayName = 'DataQualityHomeTabPdf'

DataQualityHomeTabPdf.propTypes = {
  dataQualityHomeState: PropTypes.object,
  updateDataQualityHomeState: PropTypes.func,
  meterData: PropTypes.object,
  dqOverviewDataForLandlord: PropTypes.object,
  dqOverviewDataForTenant: PropTypes.object,
  timePeriodState: PropTypes.object,
  time: PropTypes.string,
  assetId: PropTypes.string,
  selectedLanguage: PropTypes.string,
  rowData: PropTypes.object,
  selectedTimePeriod: PropTypes.object
}