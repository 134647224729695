import {memo, useRef} from 'react'
import PropTypes from 'prop-types'
import {MultiSelect} from 'primereact/multiselect'
import './MultiSelectDropdown.scss'
import I18n from '../../../utils/i18n/I18n'
import {selectedItemTemplate} from '../../../utils/helpers/Helper'

function MultiSelectDropdown(props) {
  const elementRef = useRef(null)
  const onShowEventHandler = () => {
    elementRef.current.getElementsByClassName('p-multiselect-trigger-icon')[0].classList.replace('pi-chevron-down', 'pi-chevron-up')
    let selectAllCheckbox = document.querySelector(
      '.p-multiselect-header > .p-checkbox'
    )
    selectAllCheckbox.after(props.dataInfo.panelHeaderLabel)
  }

  const onHideEventHandler = () => {
    elementRef.current.getElementsByClassName('p-multiselect-trigger-icon')[0].classList.replace('pi-chevron-up', 'pi-chevron-down')
    props.onHideDropdown?.()
  }

  return (
    <div ref={elementRef} className={props?.className}>
      <MultiSelect 
        value={props.multiSelectedOptions}
        selectedItemTemplate={(option) => selectedItemTemplate(option, props.multiSelectedOptions)}
        onChange={(e) => props.onChangeHandler(e.value)}
        onShow={onShowEventHandler}
        onHide={onHideEventHandler} 
        options={props.dataInfo.options} 
        optionLabel="name"
        placeholder={I18n(props.dataInfo.placeHolderText)}
        maxSelectedLabels={3} 
        className={`w-full ${props.dataInfo?.className} common-multiselect`}
        showSelectAll={true}
        inputId="multiselect"
        panelClassName={props.dataInfo.showPanelHeader ? 'common-multiselect-panel': `${props.dataInfo?.panelClassName} multiselect-panel-header common-multiselect-panel`}
        data-testid={'multiselect-dropdown'}
        optionDisabled={props.disableOptionItemHandler}
      />
    </div>
  )
}

MultiSelectDropdown.propTypes = {
  className: PropTypes.string,
  dataInfo: PropTypes.object,
  multiSelectedOptions: PropTypes.array,
  onChangeHandler: PropTypes.func
}

MultiSelectDropdown.displayName = 'MultiSelectDropdown'
export default memo(MultiSelectDropdown)