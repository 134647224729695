import React, {useState} from 'react'
import {InputSwitch} from 'primereact/inputswitch'
import tickMark from '../../../../resources/images/icon/tick_mark.svg'
import I18n from '../../../../utils/i18n/I18n'
import {useMutation} from 'react-query'
import {updateSettings} from '../../../../services/settings-service'
import {getLocalStorageByKey, getLocalStorageItem, setLocalStorageItem} from '../../../../utils/helpers/Helper'
import { LOCAL_STORAGE_KEYS } from '../../../../utils/helpers/Constants'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const NotificationMenu = (props) => {
  const {loginDispatch, loginState: {userInfo}} = useLoginAuthContext()
  const emailNotifications = userInfo.emailNotifications
  const emailNotificationStatus = emailNotifications === undefined ? getLocalStorageItem('emailNotifications') : emailNotifications
  const [enableNotification, setEnableNotification] = useState(emailNotificationStatus)

  let primaryEmailConditions = {
    title: I18n('t_quarterly_email_notification'),
    conditions: [
      I18n('t_assets_missing_performance_targets'),
      I18n('t_missing_performance_data')
    ]
  }

  let secondaryEmailConditions = {
    title: I18n('t_other_notifications'),
    conditions: [
      I18n('t_action_completion_dates')
    ]
  }

  const updateNotificationMutation = useMutation(updateSettings)

  async function onNotificationSubmenuClick(e) {
    setEnableNotification(e.value)
    try {
      const data = await updateNotificationMutation.mutateAsync({
        userId: getLocalStorageItem('userId'),
        updatedData: {
          emailNotifications: e.value
        }
      })
      setLocalStorageItem(LOCAL_STORAGE_KEYS.userInfo,{...getLocalStorageByKey(LOCAL_STORAGE_KEYS.userInfo),emailNotifications: e.value})
      loginDispatch({ type: 'UPDATE_USER_INFO', payload: { emailNotifications: e.value}})
    } catch (e) {
      console.log(e.toString())
    }
  }

  const getNotificationConditions = (isEnabled, emailConditions) => {
    let conditions = emailConditions.conditions.map((item, index) => {
      return (
        <div className={`condition ${isEnabled ? 'condition_enabled' : ''}`} key={index}>
          <span className="condition_tick">
            <img src={tickMark} alt=""/>
          </span>
          <span className="condition_text">
            {item}
          </span>
        </div>
      )
    })
    return (
      <div className="notification_conditions">
        <span className="conditions_title">
          {emailConditions.title}
        </span>
        {conditions}
      </div>
    )
  }

  return (
    <div className="p-grid settings-Notification">
      <div className="p-col-8 menuItem-title" data-testid={'menu-item-title'}>{props.menudata.label}</div>
      <div className="p-col-1"></div>
      <div className="p-col-3 notification-input-switch" data-testid={'input-switch'}>
        <InputSwitch checked={enableNotification} onChange={(e) => onNotificationSubmenuClick(e)}/>
      </div>
      {getNotificationConditions(enableNotification, primaryEmailConditions)}
      {getNotificationConditions(enableNotification, secondaryEmailConditions)}
    </div>
  )
}

export default NotificationMenu