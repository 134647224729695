import React from 'react'
import {Skeleton} from 'primereact/skeleton'
import './AssetNzcTargetPathwaySkeleton.scss'

const AssetNzcTargetPathwaySkeleton = () => {

  function getCommonSkeletonNodes() {
    return (
      <>
        <Skeleton width="21.813rem" height="1.313rem" className="sk-large sk-common" />
        <Skeleton width="13.563rem" height="1.313rem" className="sk-medium sk-common" />
      </>
    )
  }

  return (
    <div className="asset-nzc-tp-skeleton">
      {getCommonSkeletonNodes()}
      <div className={'asset-nzc-h-line'}/>
      {getCommonSkeletonNodes()}
      <div className={'asset-nzc-h-line h-line-2'}/>
      {getCommonSkeletonNodes()}
      <Skeleton width="13.563rem" height="1.313rem" className="sk-small sk-common" />
    </div>
  )
}

export default AssetNzcTargetPathwaySkeleton