import React from 'react'
import {Dropdown} from 'primereact/dropdown'
import I18n from '../../../utils/i18n/I18n'
import {dropdownPaginationOptions} from '../../../utils/helpers/Constants'

const PaginatorDropdown = ({options,panelClassName, paginationOptions}) => {
  return (
    <>
      <span className="mx-1 per-page-label">{I18n('t_items_per_page')}:</span>
      <Dropdown panelClassName={panelClassName? panelClassName : 'items-per-page-options'}
        value={options.value} options={paginationOptions ?? dropdownPaginationOptions}
        onChange={options.onChange}
      />
    </>
  )
}


export default PaginatorDropdown