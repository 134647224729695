import React, {useEffect, useRef, useState} from 'react'
import { useQuery} from 'react-query'
import AssetsContainer from '../../Common/asset/AssetsContainer'
import I18n from '../../../../../utils/i18n/I18n'
import { getUserAssetData,} from '../../../../../services/admin/users-service'
import {noRefetchOnWindowFocus} from '../../../../../services/common/useQuery-config'
import {UserActions, storeGroupAsset,} from '../../../../../utils/helpers/Constants'
import {getLocalStorageByKey, setLocalStorageItem} from '../../../../../utils/helpers/Helper'
import GroupFooter from '../group-footer/GroupFooter'
import './GroupAssets.scss'

const EMPTY_STR = ''
function GroupAssets(props){
  const assetsGroupSelectedTabsActiveIndex=useRef(0)
  const [filteredGroupAssetDetails, setFilteredGroupAssetDetails] = useState([])
  const [groupAssetDetails, setGroupAssetDetails] = useState([])
  const [selectedGroupAssets, setSelectedGroupAssets] = useState([])
  const assetsGroupStatusInactiveClass = 'group-status-inactive'
  const [totalGroupAssetsActiveClass, setTotalGroupAssetsActiveClass] = useState(EMPTY_STR)
  const [memberGroupAssetsActiveClass, setMemberGroupAssetsActiveClass] = useState(assetsGroupStatusInactiveClass)
  const {data : getUserGroupAssetDataQuery,isLoading} = useQuery(['groupAssetData'], getUserAssetData, noRefetchOnWindowFocus)
  const [isTabChanged,setTab]=useState(false)
  //endregion

  //region Side effects.

  useEffect(()=>{
    props.visited && props.visited(1)
  },[])

  useEffect(() => {
    const groupAssetList=getUserGroupAssetDataQuery
    // For AssetAccess
    if (groupAssetList) {
      const filterAssets = []
      if(props.groupAction === UserActions.EDIT_GROUP){
        getUserGroupAssetDataQuery.map(asset=>{
          props.groupData?.assets?.filter(innerAsset=> {
            if(innerAsset.assetId === asset.assetId){
              filterAssets.push(asset)
            }
          })
        })
      }
      setSelectedGroupAssets(getLocalStorageByKey(storeGroupAsset) ? getLocalStorageByKey(storeGroupAsset)?.assetsDetails : filterAssets)
      setGroupAssetDetails(groupAssetList)
      setFilteredGroupAssetDetails(groupAssetList)
    }
  }, [getUserGroupAssetDataQuery,props.groupData?.assets])

  //endregion

  //region Helper methods for user assets component.

  function selection(selection,filteredList) {
    setSelectedGroupAssets(selection)
    if (assetsGroupSelectedTabsActiveIndex.current === 1) {
      setFilteredGroupAssetDetails(filteredList)
    }
    setLocalStorageItem(storeGroupAsset, {isAssetsNavigatable: true, assetsDetails:selection})
  }

  function allGroupAssetsTabHandler() {
    assetsGroupSelectedTabsActiveIndex.current=0
    setFilteredGroupAssetDetails(getUserGroupAssetDataQuery)
    setTotalGroupAssetsActiveClass(EMPTY_STR)
    setMemberGroupAssetsActiveClass(assetsGroupStatusInactiveClass)
    setTab(!isTabChanged)
  }

  function selectedGroupAssetsTabHandler() {
    assetsGroupSelectedTabsActiveIndex.current=1
    setFilteredGroupAssetDetails(selectedGroupAssets)
    setTotalGroupAssetsActiveClass(assetsGroupStatusInactiveClass)
    setMemberGroupAssetsActiveClass(EMPTY_STR)
    setTab(!isTabChanged)
  }

  function getGroupAssetsLabel() {
    let chooseAssetsLabel=I18n('t_choose_assets_group_for_new_user')
    if(props.groupAction === UserActions.EDIT_GROUP){
      let groupName=''
      if(props.groupData && props.groupData.name){
        groupName=props.groupData.name
      }
      chooseAssetsLabel=I18n('t_choose_assets_group_for_update_user', {0: groupName})
    }
    return chooseAssetsLabel
  }

  async function addUpdateGroupAssets() {
    await props.saveAndExit()
  }

  function handleTabChange(){
    props.showTab(2)
  }

  function updatedFilteredList(filteredList){
    setFilteredGroupAssetDetails(filteredList)
  }
  return(
    <div className="user-group-asset">
      <div className="flex justify-content-between asset">
        <div>{getGroupAssetsLabel()}</div>
        <div className="group-total-selected-container">
          <div className={`group-total-count group-count-status ${totalGroupAssetsActiveClass}`} onClick={allGroupAssetsTabHandler}>
            {groupAssetDetails.length} {I18n('t_asset_s')}
          </div>
          <div className={`group-selected-count group-count-status ${memberGroupAssetsActiveClass}`} onClick={selectedGroupAssetsTabHandler}>
            {selectedGroupAssets.length}
          </div>
          <div className={`group-selected-label group-count-status ${memberGroupAssetsActiveClass}`} onClick={selectedGroupAssetsTabHandler}>
            {I18n('t_selected')}
          </div>
        </div>
      </div>
      <AssetsContainer
        filteredAssetDetails={filteredGroupAssetDetails}
        edit={true} isTabSwitched={isTabChanged}
        isGroup={true} selection={selection}
        updatedFilteredList={updatedFilteredList}
        header={I18n('t_asset_s')}
        assetDetails={groupAssetDetails}
        isLoading={isLoading || props.groupData === true}
        hasPermissionForEditUser={true}
        currentTab={assetsGroupSelectedTabsActiveIndex.current}
        assetsViaGroupsAndFunds={[]}
        selectedAssets={selectedGroupAssets}
      />
      <div className={'group-footer-divider'}>
        <hr/>
      </div>
      <div className={'group-footer-container'}>
        <GroupFooter
          submitHandler={addUpdateGroupAssets}
          handleNextTabBtnText="t_next_users"
          isNextButtonDisabled={false}
          handleNextTab={() => handleTabChange()}
          groupAction={props.groupAction}
          lastPage={false}
        />
      </div>
    </div>
  )
}

export default GroupAssets