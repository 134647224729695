import React from 'react'
import PropTypes from 'prop-types'
import { Button, Dropdown, Text } from 'components/atomic/index'
import Tag from 'components/atomic/Tag/Tag'
import { ASSET_PAGES, FY_START_FOR_ASSET_ACCESS, LOCAL_STORAGE_KEYS, nzcStaticIntensityOptions } from 'utils/helpers/Constants'
import {Skeleton} from 'primereact/skeleton'
import './StaticNZCFilterHeader.scss'
import {useHistory} from 'react-router-dom'
import { createAndSaveCustomDefaultDate, createNavURLForFundPages, getInstance } from 'utils/helpers/Helper'
import { portfolioStateInit } from 'utils/reducers/PortfolioReducer'

const StaticNZCFilterHeader = ({totalAssets, intensity, handleIntensityChange, isLoading, fundName}) => {
  const history = useHistory()
  function gotoAssetsPage() {
    const dpCustomTime = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.dpCustomTimePeriod))
    const dpTime = localStorage.getItem(LOCAL_STORAGE_KEYS.dpTimePeriod)
    if(!dpTime && !dpCustomTime){
      createAndSaveCustomDefaultDate(FY_START_FOR_ASSET_ACCESS)
    }
    history.push({
      pathname: createNavURLForFundPages({instanceName: getInstance(), pathSuffix: ASSET_PAGES.assets}),
      state: portfolioStateInit,
      navFrom: 'assets-link',
      search: `?fund=${fundName}`
    })
  }

  if(isLoading) return <Skeleton width="100%" height="4rem" className="mb-2" data-testid='static-nzc-filter-header-skeleton'/>

  return (
    <div className="static-net-zero-carbon__filter-header" data-testid='static-nzc-filter-header'>
      <div className="flex align-items-center gap-2">
        <Text content="t_fund_view" weight="bold" />
        <Tag contentKey={`${totalAssets} assets`} colour="teal" />
      </div>
      <div className="flex gap-2 items-center">
        <Dropdown
          onChange={handleIntensityChange}
          value={intensity}
          optionLabel="label"
          className="w-full"
          options={nzcStaticIntensityOptions}
          testId='intensity-dropdown'
        />
        <Button content="t_assets_list" className="w-full" onClick={gotoAssetsPage} testId='assets-button' />
      </div>
    </div>
  )
}

StaticNZCFilterHeader.propTypes = {
  intensity: PropTypes.string,
  totalAssets: PropTypes.number,
  handleIntensityChange: PropTypes.func,
  isLoading: PropTypes.bool,
  fundName: PropTypes.string
}

export default StaticNZCFilterHeader