import React, {useContext, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {useMutation, useQuery} from 'react-query'
import PropTypes from 'prop-types'
import {Formik} from 'formik'
import * as Yup from 'yup'
import {useIntl} from 'react-intl'
import {InputText} from 'primereact/inputtext'
import {Dropdown} from 'primereact/dropdown'
import {Button} from 'primereact/button'

import './UserDetails.scss'
import QuestionMarkIcon from '../../../../../../resources/images/icon/question-mark.svg'
import UserFooter from '../user-footer/UserFooter'
import {
  ADMIN, ADMIN_LOCALE,
  ADMIN_PAGES, ADMINISTRATOR,
  UserActions,
  userAssetDetails,
  userFundDetails,
  userGroupDetails,
  userInfo,
  USERS, USERS_LOCALE
} from '../../../../../../utils/helpers/Constants'
import I18n from '../../../../../../utils/i18n/I18n'
import {FeatureFlagContext} from 'Contexts'
import {
  createAssetsIdsObject, createNavURLForUserPages, createUserAssetGroupsObject, getInstance, getLocalizedValue,
  getLocalStorageByKey, getStructuredFund, isLoggedInSelectedUserSame, isLoggedInUserAdmin, messageCheck, getLocalStorageItem
} from '../../../../../../utils/helpers/Helper'
import {
  AddAssetAccess, addAssetGroups, addRemoveFundsFromUser, AddUser,
  GetAuthRoles, UpdateAssetAccess, updateAssetGroups,
  UpdateUser, updateUserAccessStatus, getInstancesForUser, setInstanceForUser
} from '../../../../../../services/admin/users-service'
import {useLoginAuthContext} from '../../../../login/auth0/UserInfoProvider'
import ErrorDialog from '../../../../assets/data-quality/error-dialog/ErrorDialog'
import {noRefetchOnWindowFocus} from '../../../../../../services/common/useQuery-config'
import PopupDialog from '../../../../../common/modal-dialogs/PopupDialog'
import UserDetailsSkeleton from '../../../../../common/skeletons/users-grid-skeleton/UserDetailsSkeleton'
import ImageUploader from '../../../../../common/image-uploader/ImageUploader'

function UserDetails(props){
  const MSG_KEY_TITLE_CANNOT_POST_DATA = 't_title_cannot_post_data'
  const MSG_KEY_TITLE_CANNOT_PUT_DATA = 't_title_cannot_put_data'
  const mandatoryChar = '*'
  const validationConditionRequired='Required'
  const options = {year: 'numeric', month: 'short', day: 'numeric', second:undefined}
  let initialValues = {
    userId: '',
    userName: '',
    role: '',
    roleId: '',
    email: '',
    image: null
  }
  let ADMINISTRATOR_ROLE_ID = null
  let USER_ROLE_ID = null
  const instance = getInstance()
  const intl = useIntl()
  const validationErrorMessage = I18n('t_form_error_message')
  const pathUsersPage = createNavURLForUserPages({instanceName: instance,pathSuffix: ADMIN_PAGES.users })
  const isUserOnPageTheLoggedInOne = props.userDetails?.email === getLocalStorageItem('userName')
  const validationSchema = Yup.object({
    userName: Yup.string().required(validationConditionRequired),
    roleId: Yup.string().required(validationConditionRequired),
    email: Yup.string().email('Invalid email').required(validationConditionRequired)
  })

  const history = useHistory()
  const getAuthRolesQuery  = useQuery(['usersAuthRoles'], GetAuthRoles, noRefetchOnWindowFocus)
  const {data: instances, error: instancesError} = useQuery('instances', getInstancesForUser, {refetchOnWindowFocus: false, enabled: isUserOnPageTheLoggedInOne})
  const {mutateAsync: instancesMA, error: instancesMAError} = useMutation(setInstanceForUser)
  const addUserAssetGroupsMutation = useMutation(addAssetGroups)
  const updateUserAssetGroupsMutation = useMutation(updateAssetGroups)
  const addAssetAccessMutation = useMutation(AddAssetAccess)
  const updateAssetAccessMutation = useMutation(UpdateAssetAccess)
  const addRemoveFundUserMutation = useMutation(addRemoveFundsFromUser)
  const updateUserStatusMutation = useMutation(updateUserAccessStatus)
  const addUserMutation = useMutation(AddUser)
  const updateUserMutation = useMutation(UpdateUser)

  const {logOutOfAuthAndApp} = useLoginAuthContext()
  const {featureState} = useContext(FeatureFlagContext)
  const {loginState} = useLoginAuthContext()
  const selectedLanguage = loginState.userInfo.languagePreference

  const [selectedUserImage, setSelectedUserImage] = useState(null)
  const [errorDialogVisible, setErrorDialogVisible] = useState(false)
  const [errorDialogData, setErrorDialogData] = useState()
  const [formValues,setFormValues] = useState()
  const [authRoles, setAuthRoles] = useState([])
  const [instanceSelected, setInstanceSelected] = useState(instance)
  const [revokeButtonClicked, setRevokeButtonClicked] = useState(false)
  const warningMessageToRevoke = getLocalizedValue(selectedLanguage, 't_user_revoke_confirm_message')
  const [warningMessage,setWarningMessage] = useState(warningMessageToRevoke)
  const [isUser, setIsUser] = useState(true)
  const [isBlockedUser, setIsBlockedUser] = useState(false)
  const [lastLoginTime, setLastLoginTime] = useState('-')
  const [lastLoginDate, setLastLoginDate] = useState('-')
  const [lastLogin, setLastLogin] = useState(false)

  //region useEffect Implementations
  useEffect(() => {
    loadAuthRoles(getAuthRolesQuery.data)
  }, [getAuthRolesQuery.data])

  useEffect(() => {
    return () => {
      const userDetails = {isDetailsNavigatable: true, userDetails: formValues}
      localStorage.setItem(userInfo, JSON.stringify(userDetails))
    }
  }, [formValues])

  useEffect(() => {
    if (props.userDetails) {
      if (props.userDetails.lastLogin) {
        setLastLogin(true)
        let date = new Date(props.userDetails.lastLogin).toLocaleDateString(selectedLanguage, options)
        let time = new Date(props.userDetails.lastLogin).getHours()+1 + ':' + new Date(props.userDetails.lastLogin).getMinutes()
        setLastLoginTime(time)
        setLastLoginDate(date)
      }
      if (isLoggedInUserAdmin(JSON.parse(localStorage.getItem('userInfo')).userRole)) {
        setIsUser(false)
      } else {
        setIsUser(true)
      }
      setIsBlockedUser(props.userDetails.isBlocked)
    }
  }, [props.userDetails, isUser])

  //endregion

  //region Helper methods

  const isUserEditAction = () => props.userAction === UserActions.EDIT_USER

  function getInitialFormValues() {
    let initialFormValues=initialValues
    if(props.userDetails && isUserEditAction()) {
      const userDetails=props.userDetails
      initialFormValues = {
        userId: userDetails.userId,
        userName: userDetails.userName?userDetails.userName:'',
        role: userDetails.role !== null ? mapUserRoles(userDetails).role: USERS ,
        roleId: userDetails.roleId !== null ? mapUserRoles(userDetails).roleId: USER_ROLE_ID,
        email: userDetails.email?userDetails.email:'',
        image: userDetails.image?userDetails.image:null
      }
    }
    return initialFormValues
  }

  function mapUserRoles(userDetails){
    //Default will be admin for admin and administrator, Because admin is shown in windmere
    if(userDetails.role === ADMIN || userDetails.role === ADMINISTRATOR){
      userDetails.role = ADMIN
      userDetails.roleId = ADMINISTRATOR_ROLE_ID
    }else{
      userDetails.role = USERS
      userDetails.roleId = USER_ROLE_ID
    }
    return userDetails
  }

  if(props.userDetails && isUserEditAction() && authRoles.length > 0){
    //Dynamically assigning the admin , users roleID
    ADMINISTRATOR_ROLE_ID=authRoles.find(role=>role.label.props.id === ADMIN_LOCALE).value
    USER_ROLE_ID=authRoles.find(role=>role.label.props.id === USERS_LOCALE).value
    initialValues=getInitialFormValues()

    if(props.userDetails && props.userDetails.userProfileImage){
      setSelectedUserImage(props.userDetails.userProfileImage)
    }
  }

  function createMsgKeyForRoleName(roleName) {
    return roleName ? `t_${roleName.toLowerCase()}` : ''
  }

  const loadAuthRoles=(authRolesData)=> {
    if(authRolesData){
      let userRoles=authRolesData.map((role)=>{
        return {label: I18n(createMsgKeyForRoleName(role.name)), value: role.id}
      })
      setAuthRoles(userRoles)
    }
  }

  const questionMarkIcon = (className) => <><img className={className} src={QuestionMarkIcon} alt="QuestionMarkIcon"/></>

  // Mapping the user data to expected request schema.
  function createNewUserPostObject(newUserData) {
    let newUser = null
    if (newUserData) {
      newUser = {
        email: newUserData.email,
        roleId: newUserData.roleId,
        userName: newUserData.userName,
        image: selectedUserImage
      }
    }
    return newUser
  }

  async function postGroupsData(userData) {
    let assetGroupIdsObject = createUserAssetGroupsObject(getLocalStorageByKey(userGroupDetails).assetGroups)
    let userAuthIdForGroups = isUserEditAction() ? props.userDetails.userId : userData.userId
    const userAssetGroupsObject = {userId: userAuthIdForGroups, assetGroups: assetGroupIdsObject}
    if(isUserEditAction()){
      await updateUserAssetGroupsMutation.mutateAsync(userAssetGroupsObject)
    } else {
      await addUserAssetGroupsMutation.mutateAsync(userAssetGroupsObject)
    }
  }

  async function postAssetsData(userData) {
    let userAssetDetailsObj = getLocalStorageByKey(userAssetDetails)
    if(userAssetDetailsObj && userAssetDetailsObj.isUnchanged) {
      return
    }
    let assetIdsObject = createAssetsIdsObject(userAssetDetailsObj.assetsDetails)
    let userAuthIdForAssets = isUserEditAction() ? props.userDetails.authorisationId : userData.authorisationId
    const userAssetIdsObject = {userAuthId: userAuthIdForAssets, assetIds: assetIdsObject}
    if(isUserEditAction()){
      await updateAssetAccessMutation.mutateAsync(userAssetIdsObject)
    } else {
      await addAssetAccessMutation.mutateAsync(userAssetIdsObject)
    }
  }

  /*
    Handles edit action
   */
  async function handleEditUser(values,userData) {
    //Saves Details data
    await updateUserMutation.mutateAsync({userId: values.userId, userData: userData})
    //Saves groups data
    if (getLocalStorageByKey(userGroupDetails)) {
      await postGroupsData(values)
    }
    //Saves Assets data
    if (getLocalStorageByKey(userAssetDetails)) {
      await postAssetsData(values)
    }
    //Saves Fund data
    if (getLocalStorageByKey(userFundDetails)) {
      await postFundsData(props.userDetails)
    }
  }

  async function postFundsData(userData) {
    await addRemoveFundUserMutation.mutateAsync({
      funds: getStructuredFund(getLocalStorageByKey(userFundDetails)?.fundDetails)
      , authorisationId: userData.authorisationId
    })
  }

  /*
    Handles new creation action
   */
  async function handleNewUser(userData) {
    const newUser = await addUserMutation.mutateAsync(userData)
    if (newUser.hasOwnProperty('userId')) {
      //Saves Groups data
      if (getLocalStorageByKey(userGroupDetails)?.assetGroups?.length > 0) {
        await postGroupsData(newUser)
      }
      //Saves Assets data
      if (getLocalStorageByKey(userAssetDetails)?.assetsDetails?.length > 0) {
        await postAssetsData(newUser)
      }
      //Saves Fund data
      if (getLocalStorageByKey(userFundDetails)?.fundDetails?.length > 0) {
        await postFundsData(newUser)
      }
      //Invitation call
      //await sendUserInvitationMutation.mutate({userId: newUser.userId,});
      props.updateNewUser(newUser)
    } else {
      throw `Error: ${messageCheck(newUser)}`
    }
  }

  const postNewUserData = async (values) => {
    //values -- Form value
    //userData -- Object to post or save
    let errorMsgKey = MSG_KEY_TITLE_CANNOT_POST_DATA
    let userData = createNewUserPostObject(values)
    try {
      if (isUserEditAction()) {
        errorMsgKey = MSG_KEY_TITLE_CANNOT_PUT_DATA
        await handleEditUser(values,userData)
        history.goBack()
      } else {
        await handleNewUser(userData)
        history.push({pathname: pathUsersPage, state: {...userData, isAddUser: true}})
      }
    } catch (error) {
      const errorResponse = (error.response && error.response.data) ? `Error: ${error.response.data.title}` : error.toString()
      setErrorDialogData({title: I18n(errorMsgKey), message:errorResponse})
      setErrorDialogVisible(true)
    }
  }

  function onSubmit(values) {
    if(!values.toNextTab){
      values.toNextTab=false
    }
    postNewUserData(values)
  }

  function isInputError(formik, fieldName){
    return (formik.touched[fieldName] && formik.errors[fieldName])
  }

  function getCssForInputError(formik, fieldName) {
    return isInputError(formik, fieldName) ? 'input-border-error' : ''
  }

  const handleTabChange = (formik) => {
    const values = formik.values
    values.toNextTab=true
    props.showTab(1)
  }

  async function changeUserInstance() {
    await instancesMA(instances.find(i => i.urlName === instanceSelected)?.instanceId)
    logOutOfAuthAndApp({
      returnTo: `https://${instanceSelected}.${process.env.REACT_APP_AUTH0_WWW_LESS_LOGIN_HOSTNAME}/login`
    })
  }

  function dropdownItemTemplate(option) {
    return (
      <>
        <div className="dropdown-item-1">
          <div className="dropdown-item-2 dropdown-item-wrap">{option.label}</div>
        </div>
        <div className={'dropdown-panel-item-horiz-line'} />
      </>
    )
  }

  function getUserRoleElement(formik) {
    const userAction = props.userAction
    let userRoleElement =
      <div className={`dropdown-container ${getCssForInputError(formik, 'roleId')}`}>
        <span className="p-float-label">
          <Dropdown
            id="roleId"
            className={'sDropdown'}
            inputId="roleId"
            name="roleId"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.roleId}
            options={authRoles}
            panelClassName="dropdown-overlay-panel-white add-action-header-dropdown-panel"
            itemTemplate={dropdownItemTemplate}
          />
          <label htmlFor="roleId">{I18n('t_role')} {mandatoryChar}</label>
        </span>
      </div>

    if (userAction === UserActions.EDIT_USER && !props.hasPermissionForEditUser) {
      userRoleElement =
        <div className={'input-container input-container-no-border role'}>
          <div className={'p-float-label'}>
            <InputText
              disabled={true}
              id="role"
              name="role"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={getLocalizedValue(selectedLanguage, `t_${formik.values.role.toLowerCase()}`)}
            />
            <label htmlFor="role">{I18n('t_role')} <span>{mandatoryChar}</span></label>
          </div>
        </div>
    }
    return(userRoleElement)
  }

  function dlgCloseHandler() {
    setErrorDialogVisible(false)
  }

  function getFormValidationErrorMessage(formik) {
    function errorMessage(touchedField){
      //Will be changed after confirmation
      return touchedField === 'email' && formik.values.email!== '' ?  'Invalid email' : validationErrorMessage
    }

    let touchedFieldHasError
    if (formik && formik.touched) {
      const touchedFields = Object.keys(formik.touched)
      const errorsFields = Object.keys(formik.errors)
      touchedFieldHasError = touchedFields.find((touchedField) => errorsFields.includes(touchedField))
    }

    return touchedFieldHasError ? errorMessage(touchedFieldHasError) : ''
  }

  function checkForMandatoryFields(formik) {
    let isVisible = formik.values.roleId === '' ||  formik.values.email === '' || formik.values.userName === ''
    if(isVisible === false){
      isVisible = Object.keys(formik.errors).length !== 0
    }
    return isVisible
  }

  function setUserValues(formikActions) {
    formikActions? setFormValues(formikActions.values) : setFormValues({})
    if(formikActions? formikActions.dirty : false){
      props.unSavedData()
    }
  }

  //endregion

  // region handle revoke access button

  function hideTheDialog() {
    setRevokeButtonClicked(false)
    props.refetchUser()
    setWarningMessage(warningMessageToRevoke)
  }

  async function updateUserStatus() {
    try {
      await updateUserStatusMutation.mutate({
        userId: props.userDetails.userId,
      })
      setRevokeButtonClicked(false)
      setIsBlockedUser(!isBlockedUser)
    } catch (e) {
      console.log(e.toString())
    }
  }

  function confirmDialogFooter(){
    return <div className="revoke-footer">
      <div className="flex justify-content-center pt-0">
        <div className="pr-2">
          <Button label={I18n('t_no')} className={'dialog-no'} onClick={()=>hideTheDialog()}/>
        </div>
        <div>
          <Button label={I18n('t_yes')} className={'dialog-yes'} onClick={()=>updateUserStatus()}/>
        </div>
      </div>
    </div>

  }

  const changeUserAccessHandler = async (e) => {
    if (e.target.className === 'grant-access-button') {
      await updateUserStatus()
    } else {
      setRevokeButtonClicked(true)
    }
  }

  function lastLoginTemplate() {
    return lastLoginDate === '-' && lastLoginTime === '-' ?
      <i>{I18n('t_user_not_logged_in')}</i> :
      <i>{I18n('t_user_last_loggedIn_at', {0: lastLoginTime, 1: lastLoginDate})}</i>

  }

  function userActionBlock() {
    return ((props.hasPermissionForEditUser && isUserEditAction()) || (isLoggedInSelectedUserSame(props.userDetails))) ?
      <div className = {`user-actions ${isBlockedUser ? 'blockedUserBlock' : 'activeUserBlock'} ${isUser && 'normalUserBlock'} ${lastLogin}`}>
        {isBlockedUser ?
          <>
            <div className={'last-logged'}>
              <i>{I18n('t_user_access_revoked')}</i>
            </div>
            <div className={'grant-access'}>
              <button type="submit" className={'grant-access-button'}
                onClick={(e) => changeUserAccessHandler(e)}>
                {I18n('t_grant_access')}
              </button>
            </div>
          </> :
          <>
            <div className={'last-logged'}>
              {lastLoginTemplate()}
            </div>
            {!isUser && lastLogin &&
                    <>
                      <div className={'resend-invite'}>
                        <button type="submit" className={'resend-invite-button'}>
                          {I18n('t_resend_invite')}
                        </button>
                      </div>
                      <div className={'revoke-access'}>
                        <button type="submit" className={'revoke-access-button'}
                          onClick={(e) => changeUserAccessHandler(e)}>
                          {I18n('t_revoke_access')}
                        </button>
                      </div>
                    </>
            }
            {revokeButtonClicked && <PopupDialog visible={revokeButtonClicked} message={warningMessage}
              header={I18n('t_revoke_access')} className={'revokeAccess-Warning-Dlg'}
              onHide={hideTheDialog} footer={confirmDialogFooter}
            />}
            {featureState.sieraplus_userInstances && isUserOnPageTheLoggedInOne &&
              <>
                <div className="dropdown-container" style={{margin: '3.4rem 0 1rem'}}>
                  <span className="p-float-label">
                    <Dropdown
                      id="instanceId"
                      className="sDropdown"
                      inputId="instanceId"
                      name="instanceId"
                      onChange={e => { props.unSavedData(); setInstanceSelected(e.target.value?.label) }}
                      value={{label: instanceSelected}}
                      options={instances?.map(i => ({label: i.urlName}))}
                      panelClassName="dropdown-overlay-panel-white add-action-header-dropdown-panel"
                      itemTemplate={dropdownItemTemplate}
                    />
                    <label htmlFor="instanceId">{I18n('t_instance')} {mandatoryChar}</label>
                  </span>
                </div>
                {I18n('t_change_instance_explanation')}
                <div className={'resend-invite'}>
                  <button
                    type="button"
                    className={`resend-invite-button ${instanceSelected === instance ? 'disabled' : ''}`}
                    onClick={changeUserInstance}
                    disabled={instanceSelected === instance}
                    title={instanceSelected === instance ? intl.formatMessage({id: 't_instance_different'}) : ''}
                  >
                    {I18n('t_change_instance')}
                  </button>
                  <p className="error-message" style={{position: 'static', textAlign: 'left'}}>{instancesError?.toString() || instancesMAError?.toString() || ''}</p>
                </div>
              </>
            }
          </>
        }
      </div> : null
  }

  function setUserImage(image) {
    setSelectedUserImage(()=> image)
  }

  const userDetailsForm =  () => {
    return <Formik initialValues={getLocalStorageByKey(userInfo)?.userDetails || initialValues} onSubmit={onSubmit} validationSchema={validationSchema}
      innerRef={(formikActions) => setUserValues(formikActions)}>
      {formik => (
        <>
          <span className="error-message">{getFormValidationErrorMessage(formik)}</span>
          <div className={'user-create-edit-section'}>
            <div className={'userDetails-form'}>
              <form onSubmit={formik.handleSubmit} className={'p-fluid'}>
                <div className={'form-table p-grid'}>
                  <div className={'p-col'}>
                    <div className="field-input">
                      <ImageUploader getSelectedImage={setUserImage} userImage={selectedUserImage}/>
                    </div>
                    <div className="field-input">
                      <div className={`input-container user-name ${getCssForInputError(formik, 'userName')}`}>
                        <div className={'p-float-label'}>
                          <InputText id="userName" name="userName" {...formik.getFieldProps('userName')} />
                          <label htmlFor="userName">{I18n('t_name')} <span>{mandatoryChar}</span></label>
                        </div>
                      </div>
                      {props.hasPermissionForEditUser?questionMarkIcon('icon-question-mark'):''}
                    </div>
                    <div className="field-input">
                      {getUserRoleElement(formik)}
                      {props.hasPermissionForEditUser?questionMarkIcon('icon-question-mark'):''}
                    </div>
                    <div className="field-input">
                      <div className={`input-container email-address ${getCssForInputError(formik, 'email')}`}>
                        <div className={'p-float-label'}>
                          <InputText id="email" name="email" {...formik.getFieldProps('email')} disabled={isUserEditAction()}/>
                          <label htmlFor="email-address">{I18n('t_email_address')}</label>
                        </div>
                      </div>
                      {questionMarkIcon('icon-question-mark')}
                    </div>
                    <div className={'required-fields-indicator'}><span>{mandatoryChar}</span> {I18n('t_required_fields')}</div>
                  </div>
                </div>
              </form>
            </div>
            <div className={'user-actions-block'}>
              {userActionBlock()}
            </div>
          </div>
          <div className={'footer-divider'}>
            <hr/>
          </div>
          <div className={'footer-container'}>
            <UserFooter
              submitHandler={formik.handleSubmit} hasPermissionForEditUser={true}
              handleNextTabBtnText="t_next_choose_funds"
              isNextButtonDisabled={checkForMandatoryFields(formik)}
              handleNextTab={() => handleTabChange(formik)}
              userAction={props.userAction}
            />
          </div>
          {errorDialogVisible && <ErrorDialog
            data={errorDialogData}
            dialogVisible={errorDialogVisible}
            onHideHandler={dlgCloseHandler}
            closeHandler={dlgCloseHandler} />}
        </>
      )}
    </Formik>
  }

  // endregion

  return(
    <div className={'userDetails-container'}>
      {props.isUserDataLoading === false && authRoles.length > 0  ?
        userDetailsForm() : <UserDetailsSkeleton/>
      }
    </div>
  )
}

UserDetails.propTypes = {
  unSavedData: PropTypes.func,
  userDetails: PropTypes.object
}

export default UserDetails