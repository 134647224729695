import React, {useContext, useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {useQuery} from 'react-query'
import Avatar from 'react-avatar'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Button} from 'primereact/button'
import {Card} from 'primereact/card'
import {Skeleton} from 'primereact/skeleton'

import './UserDetailsHome.scss'
import CSS_VARIABLES from '../../../../../resources/css/_variables.scss'
import AssetsContainer from '../../Common/asset/AssetsContainer'
import I18n from '../../../../../utils/i18n/I18n'
import {GetGroupsListById, getUserAssetData, GetUserById} from '../../../../../services/admin/users-service'
import {noRefetchOnWindowFocus} from '../../../../../services/common/useQuery-config'
import UserOverviewGroupsSkeleton from '../../../../common/skeletons/users-grid-skeleton/UserOverviewGroupsSkeleton'
import {
  clearUserContent, createNavURLForUserPages, getLocalizedValue, getLoggedInUserRole,
  hasPermission, isLoggedInSelectedUserSame, getInstance
} from '../../../../../utils/helpers/Helper'
import {ADMIN, ADMIN_PAGES, ADMINISTRATOR, UserActions, USERS} from '../../../../../utils/helpers/Constants'
import {FeatureFlagContext} from 'Contexts'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const UserDetailsHome = () => {
  const CLASS_ACTION_HIGHLIGHT_CLICKABLE_OPACITY = 'action-highlight-clickable-opacity'
  const hasPermissionForEditUser = hasPermission(UserActions.EDIT_USER, getLoggedInUserRole())

  const navigate = useHistory()
  const urlParams = useParams()

  const {data: selectedUser, isLoading: isSelectedUserDataLoading}  = useQuery(urlParams.userId, GetUserById, noRefetchOnWindowFocus)
  const {data: userGroupData, isLoading} = useQuery(['userAssetGroups', urlParams.userId], GetGroupsListById, noRefetchOnWindowFocus)
  const getUserAssetDataQuery = useQuery(['userAssetData', selectedUser?.authorisationId], getUserAssetData, noRefetchOnWindowFocus)

  const {featureState}= useContext(FeatureFlagContext)
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference

  const [groups, setGroups] = useState([])
  const [funds, setFunds] = useState([])
  const [filteredAssetDetails, setFilteredAssetDetails] = useState([])
  const [assetDetails, setAssetDetails] = useState([])
  const [selectedGroup, setSelectedGroup] = useState(null)

  //region Side effects
  useEffect(() => {
    clearUserContent()
  }, [])

  useEffect(() => {
    let sortedUserGroups = []
    // Extracting user resources from AssetAccess data.
    if (userGroupData) {
      sortedUserGroups = userGroupData.groups
    }
    if(userGroupData && userGroupData.funds){
      setFunds(userGroupData.funds||[])
    }
    if(getUserAssetDataQuery.data){
      const userAssets=getUserAssetDataQuery.data.filter(asset=>asset.isGroupAccess || asset.isAssetAccess || asset.isFundAccess)
      setFilteredAssetDetails(userAssets)
      setAssetDetails(userAssets)
    }
    setGroups(sortedUserGroups)
  }, [userGroupData, getUserAssetDataQuery.data])
  //endregion

  //region Helper methods for user details home component.
  const selectedUserNotEmpty = () => !!selectedUser

  function getUserName() {
    return selectedUser ? selectedUser.userName : null
  }

  function NoDataFound({message}) {
    return <div className="no-result-msg">
      <span className="no-result-text">{message}</span>
    </div>
  }

  function hasPermissionToEditButton() {
    return (hasPermissionForEditUser || isLoggedInSelectedUserSame(selectedUser))
  }

  function getHeadingValue(value) {
    let headingValue = '-'
    if (value && (typeof value === 'string')) {
      headingValue = getLocalizedValue(selectedLanguage, `t_${value.toLowerCase()}`)
      headingValue = headingValue ? headingValue : value
    } else if (value){
      headingValue = value
    }
    return headingValue
  }

  const headingSkeleton=()=>{
    return <div className={'users-page-skeleton'}>
      <Skeleton width={'3.5rem'} height={'0.9rem'}/>
    </div>
  }

  const valueSkeleton=()=>{
    return <div className={'users-page-skeleton'}>
      <Skeleton className={'users-page-skeleton'} width={'10rem'} height={'1rem'}/>
    </div>
  }

  function userDetailsTemplate(heading, value) {
    return <div className="pb-2">
      <div className="user-header pb-1">{isSelectedUserDataLoading ? headingSkeleton() : heading}</div>
      <div className="user-value pb-1">{isSelectedUserDataLoading ? valueSkeleton() : getHeadingValue(value)}</div>
    </div>
  }

  function toRespectiveTab(ai) {
    const userEditPath=createNavURLForUserPages({pathPrefix: ADMIN_PAGES.users, userId: urlParams.userId, pathSuffix: 'edit'})
    navigate.push({
      pathname: userEditPath,
      state: {activeIndex: ai}
    })
  }

  const UserDetails = () => {
    const getRole = () => selectedUser.role  === ADMIN || selectedUser.role === ADMINISTRATOR ? ADMIN : USERS

    return <div className="user-details" data-testid={'user-details-card'}>
      <div className="flex justify-content-between pb-2">
        <span className="title">{I18n('t_details')}</span>
      </div>
      <Card className="user-details-card">
        <div className="flex">
          <div className="pr-5">
            <Avatar 
              className="user-image" 
              name={getUserName()}
              src={selectedUserNotEmpty() ? selectedUser.image : null}
              color={CSS_VARIABLES.forest_10}
              fgColor={CSS_VARIABLES.forest}
            />
          </div>
          <div>
            {userDetailsTemplate(I18n('t_name'), getUserName())}
            {userDetailsTemplate(I18n('t_company'), selectedUserNotEmpty() ? selectedUser.company : null)}
            {userDetailsTemplate(I18n('t_role'), selectedUserNotEmpty() && selectedUser.role!== null ?
              getRole() : 'Users')}
            {userDetailsTemplate(I18n('t_asset_s'), selectedUserNotEmpty() ? filteredAssetDetails?.length: null)}
            {userDetailsTemplate(I18n('t_email_address'), selectedUserNotEmpty() ? selectedUser.email : null)}
            {featureState.sieraplus_userInstances && userDetailsTemplate(I18n('t_instance'), getInstance())}
          </div>
        </div>
      </Card>
    </div>
  }

  const UserGroups = () => {
    return <div className="user-groups" data-testid={'user-group-card'}>
      <div className="flex justify-content-between pb-2">
        <span className="title">{I18n('t_groups')}</span>
      </div>
      <Card className="user-groups-card">
        {!isLoading ?
          <div>
            {groups.length > 0 ?
              <DataTable value={groups} selectionMode="single"
                className={hasPermissionForEditUser ? 'user-groups-grid' : 'user-groups-grid no-blue'}
                selection={hasPermissionForEditUser ? selectedGroup : null}
                scrollable scrollHeight="298px"
                onSelectionChange={e => hasPermissionForEditUser ? setSelectedGroup(e.value) : null}
                onRowClick={() => hasPermissionForEditUser ? toRespectiveTab(2) : null}>
                <Column field="name" bodyClassName="groups-body"
                  headerClassName="user-grid-header"/>
              </DataTable> :
              <NoDataFound message={I18n('t_groups_none')}/>
            }
          </div> :
          <div className="users-grid-before-load">
            <UserOverviewGroupsSkeleton/>
          </div>
        }
      </Card>
    </div>
  }

  const UserFunds = () => {
    return <div className="user-funds" data-testid={'user-funds-card'}>
      <div className="flex p-jc-between pb-2">
        <span className="title">{I18n('t_funds')}</span>
      </div>
      <Card className="user-funds-card">
        {!isLoading ?
          <div>
            {funds.length > 0 ?
              <DataTable value={funds} selectionMode="single"
                className={hasPermissionForEditUser ? 'user-groups-grid' : 'user-groups-grid no-blue'}
                selection={hasPermissionForEditUser ? selectedGroup : null}
                scrollable scrollHeight="298px"
                onSelectionChange={e => hasPermissionForEditUser ? setSelectedGroup(e.value) : null}
                onRowClick={() => hasPermissionForEditUser ? toRespectiveTab(1) : null}>
                <Column field="fundname" bodyClassName="groups-body"
                  headerClassName="user-grid-header"/>
              </DataTable> :
              <NoDataFound message={I18n('t_funds_none')}/>
            }
          </div> :
          <div className="users-grid-before-load">
            <UserOverviewGroupsSkeleton/>
          </div>
        }
      </Card>
    </div>
  }

  //endregion
  return (
    <div className="grey-bg min-width-1280">
      <div className={'user-overview'}>
        <div className={'container-layout gutter'}>
          <div className="flex justify-content-between pb-5">
            <div className="user-name" data-testid={'user-name'}>{getUserName()}</div>
            <div className={'edit-item-button flex'} data-testid={'edit-button'}>
              <Button
                label={I18n('t_edit')}
                className={`add-item ${CLASS_ACTION_HIGHLIGHT_CLICKABLE_OPACITY}`}
                onClick={() => toRespectiveTab(0)}
                style={{visibility: (hasPermissionToEditButton()) ? 'visible' : 'hidden'}}
              />
            </div>
          </div>
          <div className="user-container">
            <div>
              <UserDetails/>
            </div>
            <div>
              <UserFunds/>
            </div>
            <div>
              <UserGroups/>
            </div>
          </div>
          <div className="pb-6">
            <AssetsContainer
              filteredAssetDetails={filteredAssetDetails}
              edit={false}
              header={I18n('t_asset_s')}
              hasPermissionForEditUser={hasPermissionForEditUser}
              assetDetails={assetDetails}
              isLoading={getUserAssetDataQuery.isLoading}
              groupRowClickHandler={() => hasPermissionForEditUser ? toRespectiveTab(3) : null}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserDetailsHome