
import {Skeleton} from 'primereact/skeleton'
import './PerformanceChartSkeleton.scss'

const PerformanceChartSkeleton = () => {
  return (
    <>
      <div className="p-rel asset-performance-chart-skeleton">
        <div className="flex justify-content-around">
          <Skeleton width="3rem" borderRadius="3px 3px 0 0" height="13rem" className="mt-5"/>
          <Skeleton width="3rem" borderRadius="3px 3px 0 0" height="7rem" className="mt-5"/>
          <Skeleton width="3rem" borderRadius="3px 3px 0 0" height="20.4rem" className="mt-5"/>
          <Skeleton width="3rem" borderRadius="3px 3px 0 0" height="10rem" className="mt-5"/>
        </div>
      </div>
      <div className="chart-skeleton-legends">
        <div className="flex justify-content-between">
          <Skeleton width="15rem" borderRadius="3px 3px 0 0" height="0.9375rem" className="mt-5 mr-4"/>
          <Skeleton width="15rem" borderRadius="3px 3px 0 0" height="0.9375rem" className="mt-5 mr-4"/>
          <Skeleton width="15rem" borderRadius="3px 3px 0 0" height="0.9375rem" className="mt-5 mr-4"/>
          <Skeleton width="25rem" borderRadius="3px 3px 0 0" height="0.9375rem" className="mt-5"/>
        </div>
      </div>
    </>
  )
}


export default PerformanceChartSkeleton