import PropTypes from 'prop-types'
import {Icon, Text} from '../../../../atomic'
import './IconAndContentCell.scss'

function IconAndContentCell({iconId, variancePercentageContent, varianceAbsoluteContent, varianceTextColor}) {

  return (
    <div className={'icon-content-cell flex'}>
      {(iconId && variancePercentageContent && varianceAbsoluteContent) ?
        <>
          <Icon id={iconId} size={15} className="icon-content-cell__icon" colour="forest_40" />
          <Text content={variancePercentageContent} colour={varianceTextColor ?? ''} className="icon-content-cell__unit-percentage" />
          <Text content={varianceAbsoluteContent} className="icon-content-cell__unit-value" />
        </>
        :
        '-'
      }
    </div>
  )
}

IconAndContentCell.propTypes = {
  iconId: PropTypes.string,
  variancePercentageContent: PropTypes.string,
  varianceAbsoluteContent: PropTypes.string,
  varianceTextColor: PropTypes.string
}
IconAndContentCell.displayName = 'IconAndContentCell'

export default IconAndContentCell