import { Checkbox } from 'primereact/checkbox'
import PropTypes from 'prop-types'
import './NzcSettingsForm.scss'
import React, {useEffect, useMemo, useState} from 'react'
import { Button } from 'primereact/button'
import { useMutation } from 'react-query'
import { UpdateNZCConfigSettings } from '../../../../../services/funds/nzc-service'
import I18n from '../../../../../utils/i18n/I18n'
import {Dropdown} from 'primereact/dropdown'
import {assetsOrderByColumnChange, defaultBaselineYear} from '../../../../../utils/helpers/Constants'
import {getDropdownItemTemplate} from '../../../../../utils/helpers/Helper'


const NzcSettingsForm = ({ scopeId, refetchData, nzcSettingsData, isModelDataOverridden, graphIntensity, reportingYear}) => {

  const [gridDecarbonisation, setGridDecarbonisation] = useState(false)
  const [baselineYear, setBaselineYear] = useState('')
  let yearsDiff = (reportingYear - defaultBaselineYear) + 1
  const baselineYearOptions = Array.from({ length: yearsDiff }, (_, i) => i + defaultBaselineYear).map((v, idx) => reportingYear - idx)
  const updateNzcSettingsMutation = useMutation(UpdateNZCConfigSettings)
  const nzcSettingsDataNotChanged = useMemo(settingsDataNotChanged, [baselineYear, gridDecarbonisation, nzcSettingsData])

  useEffect(() => {
    if (nzcSettingsData){
      setSettingValues()
      setBaselineYear(nzcSettingsData.baselineYear)
    }
  }, [nzcSettingsData, scopeId])

  function settingsDataNotChanged() {
    return (nzcSettingsData?.baselineYear === baselineYear && nzcSettingsData.gridDecarbonisation === gridDecarbonisation)
  }

  function handleChange(e, value) {
    if(value === 'baselineYear'){
      setBaselineYear(e.target.value)
    } else {
      setGridDecarbonisation(e.checked)
    }
  }
  function setSettingValues() {
    setGridDecarbonisation(nzcSettingsData.gridDecarbonisation)
  }

  async function updateSettings() {
    const settings = {
      baseLineYear : baselineYear,
      gridDecarbonisation: gridDecarbonisation
    }
    const result = await updateNzcSettingsMutation.mutateAsync({ scopeId, settings, graphIntensity })
    if (result) {
      refetchData()
    }
  }

  return (
    <div className={'nzc-settings'}>
      <div className={'base-line-year-wrapper'}>
        <span data-testid="baseline" className={'base-line-year-label'}>{I18n('t_base_line_year')}</span>
        {baselineYear && <Dropdown
          value={baselineYear}
          itemTemplate={getDropdownItemTemplate}
          panelClassName={'dropdown-overlay-panel-white p-dropdown-panel-no-top-border nzc-setting-dropdown'}
          options={baselineYearOptions.reverse()}
          onChange={e => handleChange(e, 'baselineYear')}
          className={'base-line-year-dropdown'}
          disabled={isModelDataOverridden}
        />}
      </div>
      {graphIntensity !== assetsOrderByColumnChange.energyIntensity &&
      <div className={'gdc-wrapper'}>
        <Checkbox className="check-box" data-testid='setting-checkbox' inputId="check-box-grid-decarbonisation" onChange={e => handleChange(e, 'gridDecarbonisation')} checked={gridDecarbonisation}></Checkbox>
        <label className="check-box-label" htmlFor="check-box-grid-decarbonisation">{I18n('t_show')} {I18n('t_grid_decarbonisation')}</label>
      </div>
      }
      <div className={'buttons-wrapper'}>
        <div className={'div-btn-apply col-9'}>
          <Button type='submit' className='btn-apply-changes button-highlight' disabled={!!nzcSettingsDataNotChanged} onClick={updateSettings}>{I18n('t_apply')}</Button>
        </div>
      </div>
    </div>
  )
}

NzcSettingsForm.propTypes = {
  scopeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  refetchData: PropTypes.func,
  nzcSettingsData: PropTypes.object,
  isModelDataOverridden: PropTypes.bool,
  graphIntensity: PropTypes.string,
  reportingYear: PropTypes.number
}

NzcSettingsForm.displayName = 'NzcSettingsForm'

export default NzcSettingsForm
