import React from 'react'
import {RadioButton} from 'primereact/radiobutton'
import { getLocalizedValue } from '../../../../utils/helpers/Helper'
import I18n from '../../../../utils/i18n/I18n'
import questionMarkIcon from '../../../../resources/images/icon/question-mark.svg'
import PropTypes from 'prop-types'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const AssetSBT = ({ scienceBasedPathwayId, handleQuestionMarkClick, handleSelectedScienceBasedTarget, getOverlayPanel, scienceBasedTargetPathwayOP, targetPathwayDetailsObj, graphIntensity}) =>{

  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const text = getLocalizedValue(selectedLanguage, 't_science_based_target_pathway')
  const disableSbt2 = graphIntensity === 'energyIntensity' ? 'pointer-events-none opacity-60' : 'pointer-events-auto'
  return <>
    <div className='science-based-target'>
      <div className='science-based-title-icon' id={scienceBasedPathwayId}>
        <label htmlFor="target-pathway-sub-titles" className='target-pathway-sub-titles' data-testid='science-based-tp'>{I18n('t_science_based_target')}</label>
        <img src={questionMarkIcon} alt="" className='question-mark-icon' onClick={(event) => handleQuestionMarkClick(event, 'science-based-tp')} data-testid='science-based-tp-question-mark'/>
      </div>
      <div className='science-based-target-options'>
        <div className='science-based-target-option-1' data-testid="radio-1.5">
          <RadioButton checked={targetPathwayDetailsObj.scienceBasedTargetOption && Number(targetPathwayDetailsObj.scienceBasedTargetOption) !== 2.0} inputId="SBT-option-1" name='SBT-option-1' onChange={handleSelectedScienceBasedTarget} />
          <label htmlFor="SBT-option-1" className='SBT-option-1'>{I18n('t_SBT_option_1')}</label>
        </div>
        <div className={`science-based-target-option-2 ${disableSbt2}`} data-testid="radio-2.0">
          <RadioButton checked={targetPathwayDetailsObj.scienceBasedTargetOption && Number(targetPathwayDetailsObj.scienceBasedTargetOption) === 2.0} inputId="SBT-option-2" name='SBT-option-2' onChange={handleSelectedScienceBasedTarget} />
          <label htmlFor="SBT-option-2" className='SBT-option-2'>{I18n('t_SBT_option_2')}</label>
        </div>
      </div>
      {getOverlayPanel(scienceBasedTargetPathwayOP, scienceBasedPathwayId, text)}
    </div>
    <div className={'nzc-h-line'}/>
  </>
}

AssetSBT.propTypes={
  scienceBasedPathwayId: PropTypes.number,
  handleQuestionMarkClick: PropTypes.func,
  handleSelectedScienceBasedTarget: PropTypes.func,
  getOverlayPanel: PropTypes.func,
  scienceBasedTargetPathwayOP: PropTypes.string,
  targetPathwayDetailsObj: PropTypes.object,
  graphIntensity: PropTypes.string,
}

export default React.memo(AssetSBT)