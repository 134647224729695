import { getDefaultFontObjectForChart } from 'components/common/net-zero-carbon/nzc-common-utils'
import { truncateAndFormatNumWOneDecimal } from 'services/common/formatter'
import { FUND_BAU_LINE } from 'utils/helpers/Constants'
import { getLegendImage } from 'utils/helpers/Helper'
import { getLocalizedValue } from 'utils/helpers/LanguageUtils'
import CSS_VARIABLES from '../../../../../resources/css/_variables.scss'
import { getBorderDashValues } from 'components/common/nzc-card-container/cardContent/net-zero-carbon-card/netZeroCarbonHelper'

const getGraphData = (targetPathways, field) => {
  if (!targetPathways) {
    return {}
  }
  if (targetPathways.length > 0) {
    const targetData = targetPathways.map((pathway) => ({
      x: pathway.year,
      y: pathway[field],
    }))
    return targetData
  }
}

const getFundIntensityDiamonds = (targetPathways) => {
  if (!targetPathways) {
    return {}
  }
  const {targetPathways: data, scopeFundPerformance} = targetPathways
  if (data.length > 0) {
    const diamondData = data
      .filter((pathway) => pathway.year <= scopeFundPerformance.year)
      .map((pathway) => ({
        x: pathway.year,
        y: pathway.businessAsUsual,
        r: 7,
      }))
    return diamondData
  }
}

const returnXaxisValue = (value) => {
  return (value + 1) % 5 === 0 ? `${value}`.substring(2, value.length) : value
}

const getIntensityLabel = (intensityType, selectedLanguage) => {
  return intensityType === 'Energy' ? getLocalizedValue(selectedLanguage, 't_fund_energy_intensity') : getLocalizedValue(selectedLanguage, 't_fund_carbon_intensity')
}

export const createStaticNZCGraphDataSets = (targetPathways, showApplicableActions, selectedLanguage) => {
  if (!targetPathways) return {data: {}}
  
  const {targetPathways: data, scopeFundPerformance, totalAgreedActions, totalApplicableActions} = targetPathways
  let rect = getLegendImage('science')
  let rectBau = getLegendImage('bau')
  const agreedActionsIcon = getLegendImage('agreedActionsPathway')
  const applicableActionsIcon = getLegendImage('applicableActionsPathway')
  let scientificGHGIntensity = {
    type: 'line',
    order: 2,
    label: getLocalizedValue(selectedLanguage, 't_scientific_ghg_intensity'),
    borderColor: CSS_VARIABLES.blue,
    backgroundColor: CSS_VARIABLES.blue,
    borderWidth: 2,
    fill: false,
    pointStyle: rect,
    data: getGraphData(data, 'scienceBasedTarget'),
    datalabels: {
      display: false,
    },
  }
  
  const businessAsUsual = {
    type: 'line',
    order: 1,
    label: getLocalizedValue(selectedLanguage, 't_bau_ghg_intensity'),
    borderColor: CSS_VARIABLES.pink,
    backgroundColor: CSS_VARIABLES.pink,
    borderWidth: 2,
    fill: false,
    pointStyle: rectBau,
    borderDash: [8, 8],
    data: getGraphData(data, 'businessAsUsual'),
    segment: {
      borderDash: context => getBorderDashValues(context, getStaticLatestSolidLineYear(scopeFundPerformance.year, data))
    },
    datalabels: {
      display: false,
    },
    graphName: FUND_BAU_LINE,
  }

  const fundIntensityDiamonds = {
    type: 'bubble',
    data: getFundIntensityDiamonds(targetPathways),
    backgroundColor: CSS_VARIABLES.pink,
    borderColor: CSS_VARIABLES.pink,
    pointStyle: 'rectRot',
    datalabels: {display: false},
    label: getIntensityLabel(targetPathways.intensityType, selectedLanguage),
    graphName: 'fundBAUBubble',
    clip: false
  }

  const datasets = [scientificGHGIntensity, businessAsUsual, fundIntensityDiamonds]

  if(totalAgreedActions > 0) {
    const agreedActions = {
      type: 'line',
      order: 3,
      label: getLocalizedValue(selectedLanguage, 't_agreed_actions_pathway'),
      borderColor: CSS_VARIABLES.green,
      backgroundColor: CSS_VARIABLES.green,
      borderWidth: 2,
      borderDash: [8, 8],
      borderCapStyle: 'round',
      fill: false,
      pointStyle: agreedActionsIcon,
      data: getGraphData(data, 'agreedActionsEstimatedSavings'),
      datalabels:{
        display:false
      },
    }
    datasets.push(agreedActions)
  }

  if(showApplicableActions && totalApplicableActions > 0) {
    const applicableActions = {
      type: 'line',
      order: 4,
      label: getLocalizedValue(selectedLanguage, 't_applicable_actions_pathway'),
      borderColor: CSS_VARIABLES.yellow,
      backgroundColor: CSS_VARIABLES.yellow,
      borderWidth: 2,
      borderDash: [8, 8],
      borderCapStyle: 'round',
      fill: false,
      pointStyle: applicableActionsIcon,
      data: getGraphData(data, 'applicableActionsEstimatedSavings'),
      datalabels:{
        display:false
      },
    }
    datasets.push(applicableActions)
  }

  return {
    data: { datasets, labels: [] },
    options: getGraphOptions(selectedLanguage),
  }
}

export const getStaticLatestSolidLineYear = (reportingYear, data) => {
  const latestActualConsumptionYear = data
    .filter(item => item.isActualConsumptionData)
    .reduce((latest, item) => item.year > latest ? item.year : latest, 0)
  const latestSolidYear = Math.max(reportingYear, latestActualConsumptionYear)

  return latestSolidYear
}

function getGraphOptions(selectedLanguage) {
  return {
    responsive: true,
    maintainAspectRatio: false,
    layout: { padding: { top: 21, bottom: -7 } },
    plugins: {
      tooltip: {
        enabled: false,
      },
      datalabels: {
        backgroundColor: CSS_VARIABLES.white,
        borderColor: CSS_VARIABLES.forest,
        borderRadius: 3,
        borderWidth: 0.1,
        color: CSS_VARIABLES.forest,
        clamp: true,
        display: true,
        anchor: 'end',
        align: 'right',
        font: {
          family: CSS_VARIABLES.fontFamilyBase,
          weight: 400,
          size: 12,
        },
        formatter: function (value) {
          return value?.label?.includes('BAU') ||
            value?.label?.includes('Action')
            ? null
            : value.label
        },
        offset: 5,
        padding: {
          right: 5,
          left: 5,
          top: 6,
          bottom: 5,
        },
      },
      aspectRatio: 5 / 3,
      layout: {
        padding: 16,
      },
      legend: {
        position: 'bottom',
        align: 'start',
        onClick: null,
        labels: {
          usePointStyle: true,
          paddingBottom: 20,
          paddingLeft: 15,
          paddingRight: 15,
          font: getDefaultFontObjectForChart({ weight: 400, size: 9 }),
          generateLabels: (chart) => {
            let generatedLabels = []
            let visibility = []
            for (let i = 0; i < chart.data.datasets.length; i++) {
              if (chart.isDatasetVisible(i)) {
                visibility.push(CSS_VARIABLES.forest)
              } else {
                visibility.push(CSS_VARIABLES.forest_50)
              }
            }

            if (chart?.data?.datasets) {
              generatedLabels = chart.data.datasets.map((dataset, index) => ({
                text: `     ${dataset.label}`,
                fillStyle: dataset.backgroundColor,
                strokeStyle: dataset.backgroundColor,
                lineWidth: dataset.borderWidth,
                pointStyle: dataset.pointStyle,
                hidden: dataset.datalabels?.display,
                index: index,
                fontColor: visibility[index],
              }))
            }
            return generatedLabels
          },
        },
      },
    },
    scales: {
      xAxis: {
        afterFit: (chart) => {
          chart.paddingBottom += 30
        },
        type: 'linear',
        ticks: {
          stepSize: 1,
          color: CSS_VARIABLES.forest,
          autoSkip: false,
          padding: 13,
          maxRotation: 0,
          autoSkipPadding: 0,
          font: getDefaultFontObjectForChart({ weight: 400, size: 8.5 }),
          callback: function (value, i) {
            return value % 5 === 0 || i === 0 ? returnXaxisValue(value) : ''
          },
        },
        grid: {
          display: true,
          color: CSS_VARIABLES.forest_20,
          tickColor: CSS_VARIABLES.forest_20,
        },
        min: 2022,
        max: 2050,
      },
      y: {
        suggestedMin: 5,
        min: 0,
        ticks: {
          stepSize: () => {
            return 5
          },
          padding: 7,
          callback: (value) => {
            let max = 100
            if (value === 0) {
              return 0
            }
            if (max <= 200) {
              return Math.round(value)
            }
            return truncateAndFormatNumWOneDecimal(
              selectedLanguage,
              Math.round(value)
            )
          },
          color: CSS_VARIABLES.forest,
          font: getDefaultFontObjectForChart({
            style: 'normal',
            weight: 400,
            size: 9,
          }),
        },
        grid: {
          drawTicks: false,
        },
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  }
}