import React, {useState, useEffect} from 'react'
import I18n from '../../../../../utils/i18n/I18n'
import './ActionPlan.scss'
import ActionEdit from '../dialogs/Action'
import ActionPlanGridSkeleton from '../../../../common/skeletons/action-plan-grid-skeleton/ActionPlanGridSkeleton'
import Table from './Table'
import InfoModal from '../../../../common/modal-dialogs/InfoModal'
import {useParams} from 'react-router-dom'

const ActionPlanTable = (props) => {
  //region Declarations/Initializations
  const {assetId} = useParams()
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [showActionDlg, setShowActionDlg] = useState(false)
  const [isDialog, setDialog] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const [visibleActiveSurveyDialog, setVisibleActiveSurveyDialog] = useState(false)
  const [notesValue, setNotesValue] = useState('')
  const [isNotesValue, setIsNotesValue] = useState(false)
  //endregion

  useEffect(()=>{
    if(showActionDlg){
      props.refetchAssetAvailableUtilityMeters().catch(error=>{
        console.log(error)
      })
    }
  }, [showActionDlg])

  function getActionNotesValue(value){
    setNotesValue(value)
  }

  //region Dialog & Overlay handlers
  const closeDialog = () => {
    setSelectedProduct('')
    setShowActionDlg(!showActionDlg)
  }

  const closeActionDialog = () => {
    if(!notesValue){
      setDialog(true)
      setShowActionDlg(false)
      closeDialog()
      setIsNotesValue(false)
    }else{
      setIsNotesValue(true)
    }
  }

  const closeActiveSurveyDialog = () => {
    setVisibleActiveSurveyDialog(false)
    setSelectedProduct('')
    setDialog(true)
  }
  //endregion

  //region Helper methods

  const selectedRow = (row,activeIndex) => {
    if(row){
      setActiveIndex(activeIndex)
      setDialog(false)
      setSelectedProduct(row)
      // Show action dialog if current asset doesn't have an active survey
      setShowActionDlg(!showActionDlg && !props.activeSurveyState)
      // Show active survey warning dialog
      setVisibleActiveSurveyDialog(true)
    }
  }

  function getTableTitleClassName() {
    return `a-table-title ${props.action === 'AgreedActions' && 'agreed-actions-title'}`
  }

  //endregion

  function getActionTable() {
    return props.actionData.length > 0 ?
      <Table actions={props.actionData} action={props.action} selectedRow={selectedRow} isDialog={isDialog}/> :
      <div className="no-result-msg">
        <span className="no-result-text">{I18n('t_no_action_data')}.<br/> <br/>
          <span onClick={props.onActionClick} className="add-an-action-link">
            {I18n('t_add_an_action')}
          </span>
        </span>
      </div>
  }

  function getActionLoadingTable() {
    return <div className="action-plan-grid-before-load">
      <ActionPlanGridSkeleton/>
    </div>
  }

  return (
    <div className="agreed-action" data-testid={'action-table-content'}>
      <div className="col-12 pt-0">
        <div className="flex">
          <div data-testid={props.action} className={getTableTitleClassName()}>
            {props.title} {/* added separate className for agreed action title for SPR-606 */}
          </div>
        </div>
        <div className="pt-3" data-testid={'action-table'}>
          {props.isLoading ? getActionLoadingTable() : getActionTable()}
        </div>
        <div className="col-6 pb-4">
        </div>
        {props.actionReview ?
          <hr className="horizontal-blue-line"/> : props.applicableAction ? '' :<hr className="horizontal-line"/>
        }
      </div>
      <div data-testid={'action-dialog'}>
        { showActionDlg &&
            <ActionEdit
              edit={true}
              activeIndex={activeIndex}
              assetId={assetId}
              closeActionDialog={closeActionDialog}
              reLoad={props.reLoad}
              showDialog={showActionDlg}
              fetchNotesData={props.actionData}
              selection={selectedProduct}
              getActionNotesValue={getActionNotesValue}
              isNotesValue={isNotesValue}
              assetAvailableUtilityMeters={props.assetAvailableUtilityMeters}
            />
        }
      </div>

      {props.activeSurveyState &&
                <InfoModal onCloseActiveSurveyDialog={closeActiveSurveyDialog} visibleActiveSurveyDialog={visibleActiveSurveyDialog} />
      }
    </div>
  )
}

export default ActionPlanTable