import React from 'react'
import {Dialog} from 'primereact/dialog'

const CommonDialog = (props) => {
  return (
    <>
      <Dialog
        header={props.header}
        visible={props.visible}
        modal={props.modal}
        style={props.style}
        footer={props.footer}
        onHide={props.onHide}
        className={props.className}
        resizable={false}
        draggable={false}
        blockScroll
      >
        {props.children}
      </Dialog>
    </>
  )
}

export default CommonDialog