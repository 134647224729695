import I18n from '../i18n/I18n'
import React from 'react'

export const noDataMessage = (msg) => (
  <div className="no-result-msg" style={{margin: '2.5em 0'}}>
    <span className="no-result-text">
      {msg ? I18n('t_no_data_msg_1', {0: msg}) : I18n('t_no_results')}
    </span>
  </div>
)

export const emptyDataMessage = () => (
  <div className="no-result-msg">
    <span className="no-result-text">
      {I18n('t_no_results')}
    </span>
  </div>
)

export const noDataAvailableMessage = (msg) => (
  <div className="no-result-msg">
    <span className="no-result-text">
      {msg ? I18n('t_no_data_msg_1', {0: msg}) : I18n('t_model_no_data_available')}
    </span>
  </div>
)

export const noESAssetsMatch = () => (
  <div className="no-result-msg" style={{margin: '2.5em 0'}}>
    <span className="no-result-text">
      {I18n('t_no_assets_to_match')}
    </span>
  </div>
)

export const noData = () => (
  <div role={'no-data-msg'} className="no-result-msg">
    <span className="no-result-text">{I18n('t_msg_no_data')}</span>
  </div>
)