import I18n from '../../../../../utils/i18n/I18n'
import {memo} from 'react'
import PropTypes from 'prop-types'

const DataCategoriesInformation = (props) => {
  const heading = (msg) => <b>{msg}</b>
  const subParagraph = (msg) => <p>{msg}</p>
  return (
    <div className={`ex-large-text-light ${props.className}`}>
      {I18n(props.data,
        {
          p: msg => subParagraph(msg),
          b: msg => heading(msg),
        }
      )}
    </div>
  )
  
}

DataCategoriesInformation.propTypes = {
  data: PropTypes.string.isRequired,
  className: PropTypes.string
}

DataCategoriesInformation.displayName = 'DataCategoriesInformation'

export default memo(DataCategoriesInformation)