import {memo, useEffect, useState} from 'react'
import './Footer.scss'
import sieraLogoPrimary from '../../../resources/images/icon/footerIcons/siera-logo-primary.svg'
import {Button} from 'primereact/button'
import {NavLink, useHistory} from 'react-router-dom'
import I18n from '../../../utils/i18n/I18n'
import SessionExpiryMessageDialog from '../overlay-panel/SessionExpiryMessageDialog'
import {SIERAPLUS_VERSION} from '../../../utils/helpers/Constants'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const Footer = () => {
  const { loginState, logOutOfAuthAndApp } = useLoginAuthContext()
  const history = useHistory()
  const [versionNumber, setVersionNumber] = useState(SIERAPLUS_VERSION)
  const FOOTER_EXCLUDE_PAGES=['/login', '/expired-password', '/forgot-password', '/forgot-password-success', '/reset-password', '/reset-password-success', '/account-locked', '/signUp']
  const FOOTER_INCLUDE_PAGES=['/terms', '/cookies', '/privacy']

  useEffect(() => {
    const version = document.querySelector('[name=version]')?.content
    if (version && version !== '#VERSION#')
      setVersionNumber(version)
  }, [])

  if(!FOOTER_INCLUDE_PAGES.includes(history?.location?.pathname) && (!loginState.isLoggedIn || FOOTER_EXCLUDE_PAGES.includes(history?.location?.pathname))){
    return null
  }

  return (
    <div className="footer mt-4">
      <div className="footer__body gutter min-width-1280 mt-0 mb-0">
        <SessionExpiryMessageDialog logoutUser={() => logOutOfAuthAndApp()}/>
        <div className="footer__body__content">
          <div className="footer__body__content__col-left">
            <img src={sieraLogoPrimary} className="footer__body__content__col-left__logo-primary" alt="siera-logo-primary"/>
            <div className="footer__body__content__col-left__text-primary"><span className="siera-text">{I18n('t_siera_version')}: {versionNumber}</span></div>
          </div>
          <div className="footer__body__content__col-right">
            <span>© EVORA Global Limited {new Date().getFullYear()}</span>
            <span className="footer__body__content__col-right__v-line">|</span>
            <NavLink to="/privacy" className="footer__body__content__col-right__nav-link" target='_blank'>{I18n('t_privacy')}</NavLink>
            <span className="footer__body__content__col-right__v-line">|</span>
            <NavLink to="/cookies" className="footer__body__content__col-right__nav-link" target='_blank'>{I18n('t_cookies')}</NavLink>
            <span className="footer__body__content__col-right__v-line">|</span>
            <NavLink to="/terms" className="footer__body__content__col-right__nav-link" target='_blank'>{I18n('t_terms')}</NavLink>
            {loginState.isLoggedIn && <>
              <span className="footer__body__content__col-right__v-line">|</span>
              <Button label={I18n('t_logout')} text onClick={() => logOutOfAuthAndApp()}/>
            </>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(Footer)
Footer.displayName = 'Footer'