import {getLocalizedValue} from '../../../helpers/Helper'
import {checkExcelCellData, formatDateToDDMMYY, generateExcel} from '../../ExportHelper'
import CSS_VARIABLES from '../../../../resources/css/_variables.scss'
import XlsxPopulate from 'xlsx-populate'
import FileSaver from 'file-saver'

export async function AssetHomeAssetDetailsExcelHelper(props,getFilterSelectionValue,selectedLanguage) {
  const blob = await XlsxPopulate.fromBlankAsync().then(workbook => {
    const excelData = filterPortfolioArrayOfObj(props?.exportDetails.excelData,selectedLanguage)
    const assetPortfolioInfo = [[getLocalizedValue(selectedLanguage, 't_asset_details')], ...getFilterSelectionValue].flatMap(innerArray => innerArray)
    const excelSheet = workbook.sheet(0).name(getLocalizedValue(selectedLanguage, 't_excel_tab_name'))
    assetPortfolioInfo.forEach((info, row) => {
      excelSheet.cell(row + 1, 1).value(info).style({ bold: row === 0, fontSize: row === 0 ? 18 : 12 })
    })
    excelData.forEach((value, row) => {
      const styleObject = { horizontalAlignment: 'center', border: true }
      const keys = Object.keys(value)
      if (row === 0) {
        const headerRow = excelSheet.row(assetPortfolioInfo.length + 2)
        keys.forEach((key, index) => {
          headerRow.cell(index + 1).value(key).style({ bold: true, ...styleObject })
        })
      }
      keys.forEach((key, column) => {
        const cell = excelSheet.cell(assetPortfolioInfo.length + row + 3, column + 1)
        excelSheet.column(cell.columnNumber()).width(25)
        excelSheet.cell(assetPortfolioInfo.length + row + 3, column + 1).value(value[key]).style(styleObject)
      })
    })
    workbook.activeSheet(getLocalizedValue(selectedLanguage, 't_excel_tab_name'))
    return workbook.outputAsync(workbook)
  })
  try {
    FileSaver.saveAs(blob, getLocalizedValue(selectedLanguage, 't_portfolio_excel'))
  } catch(error) {
    console.log('error', error)
  }
}

function filterPortfolioArrayOfObj(excelData,selectedLanguage) {
  const excelHeaderTitle = assetExcelColumnName(selectedLanguage)
  if (!Array.isArray(excelData) || !excelData.length) {
    return [
      {
        [excelHeaderTitle.assetReference]: '',
        [excelHeaderTitle.assetName]: '',
        [excelHeaderTitle.client]: '',
        [excelHeaderTitle.fund]: '',
        [excelHeaderTitle.agent]: '',
        [excelHeaderTitle.address]: '',
        [excelHeaderTitle.country]: '',
        [excelHeaderTitle.postCode]: '',
        [excelHeaderTitle.purchaseDate]: '',
        [excelHeaderTitle.saleDate]: '',
        [excelHeaderTitle.propertyStatus]: '',
        [excelHeaderTitle.sector]: '',
        [excelHeaderTitle.GRESBSector]: '',
        [excelHeaderTitle.GRESBManagementStatus]: '',
        [excelHeaderTitle.giaMSqrt]: '',
        [excelHeaderTitle.nlaMSqrt]: '',
        [excelHeaderTitle.cpaMSqrt]: '',
        [excelHeaderTitle.isCpaEstimated]: '',
        [excelHeaderTitle.gav]: '',
        [excelHeaderTitle.gasExpected]: '',
        [excelHeaderTitle.waterExpected]: '',
        [excelHeaderTitle.electricityExpected]: '',
        [excelHeaderTitle.oilExpected]: '',
        [excelHeaderTitle.districtHeatingExpected]: '',
        [excelHeaderTitle.districtCoolingExpected]: '',
      },
    ]
  }
  return excelData.map((item) => ({
    [excelHeaderTitle.assetReference]: checkExcelCellData(item.assetReference, selectedLanguage, false),
    [excelHeaderTitle.assetName]: checkExcelCellData(item.assetName, selectedLanguage, false),
    [excelHeaderTitle.client]: checkExcelCellData(item.clientName, selectedLanguage, false),
    [excelHeaderTitle.fund]: checkExcelCellData(item.fundName, selectedLanguage, false),
    [excelHeaderTitle.agent]: checkExcelCellData(item.agentName, selectedLanguage, false),
    [excelHeaderTitle.address]: checkExcelCellData(item.assetAdderess, selectedLanguage, false),
    [excelHeaderTitle.country]: checkExcelCellData(item.assetCountry, selectedLanguage, true),
    [excelHeaderTitle.postCode]: checkExcelCellData(item.assetPostCode, selectedLanguage, false),
    [excelHeaderTitle.purchaseDate]: formatDateToDDMMYY(item.dateOfPurchase, selectedLanguage),
    [excelHeaderTitle.saleDate]: formatDateToDDMMYY(item.dateOfSale, selectedLanguage),
    [excelHeaderTitle.propertyStatus]: checkExcelCellData(item.assetStatus, selectedLanguage, false),
    [excelHeaderTitle.sector]: checkExcelCellData(item.sector, selectedLanguage, false),
    [excelHeaderTitle.GRESBSector]: checkExcelCellData(item.gresbSector, selectedLanguage, false),
    [excelHeaderTitle.GRESBManagementStatus]: checkExcelCellData(item.gresBManagementStatus, selectedLanguage, false),
    [excelHeaderTitle.giaMSqrt]: checkExcelCellData(item.gia, selectedLanguage, false),
    [excelHeaderTitle.nlaMSqrt]: checkExcelCellData(item.nla, selectedLanguage, false),
    [excelHeaderTitle.cpaMSqrt]: checkExcelCellData(item.cpa, selectedLanguage, false),
    [excelHeaderTitle.isCpaEstimated]: checkExcelCellData(item.estimateCPA, selectedLanguage, false),
    [excelHeaderTitle.gav]: checkExcelCellData(item.gav, selectedLanguage, false),
    [excelHeaderTitle.gasExpected]: checkExcelCellData(item.ignoreGasMaxCoverage, selectedLanguage, false),
    [excelHeaderTitle.waterExpected]: checkExcelCellData(item.ignoreWaterMaxCoverage, selectedLanguage, false),
    [excelHeaderTitle.electricityExpected]: checkExcelCellData(item.ignoreElectricityMaxCoverage, selectedLanguage, false),
    [excelHeaderTitle.oilExpected]: checkExcelCellData(item.ignoreOilMaxCoverage, selectedLanguage, false),
    [excelHeaderTitle.districtHeatingExpected]: checkExcelCellData(item.ignoreDHMaxCoverage, selectedLanguage, false),
    [excelHeaderTitle.districtCoolingExpected]: checkExcelCellData(item.ignoreDCMaxCoverage, selectedLanguage, false),
  }))
}

function assetExcelColumnName(selectedLanguage) {
  return {
    assetReference: getLocalizedValue(selectedLanguage, 't_asset_reference'),
    assetName: getLocalizedValue(selectedLanguage, 't_asset_name'),
    client: getLocalizedValue(selectedLanguage, 't_client_name'),
    fund: getLocalizedValue(selectedLanguage, 't_fund'),
    agent: getLocalizedValue(selectedLanguage, 't_agent_name'),
    address: getLocalizedValue(selectedLanguage, 't_address'),
    country: getLocalizedValue(selectedLanguage, 't_country'),
    postCode: getLocalizedValue(selectedLanguage, 't_post_code'),
    purchaseDate: getLocalizedValue(selectedLanguage, 't_purchase_date'),
    saleDate: getLocalizedValue(selectedLanguage, 't_sale_date'),
    propertyStatus: getLocalizedValue(selectedLanguage, 't_property_status'),
    sector: getLocalizedValue(selectedLanguage, 't_sector'),
    GRESBSector: getLocalizedValue(selectedLanguage, 't_gresb_sector'),
    GRESBManagementStatus: getLocalizedValue(selectedLanguage,  't_gresb_management_status'),
    giaMSqrt: getLocalizedValue(selectedLanguage, 't_gia_m_sqrt'),
    gav: getLocalizedValue(selectedLanguage, 't_gav'),
    nlaMSqrt: getLocalizedValue(selectedLanguage, 't_nla_m_sqrt'),
    cpaMSqrt: getLocalizedValue(selectedLanguage, 't_cpa_m_sqrt'),
    isCpaEstimated: getLocalizedValue(selectedLanguage, 't_is_cpa_estimated'),
    gasExpected: getLocalizedValue(selectedLanguage, 't_gas_expected'),
    waterExpected: getLocalizedValue(selectedLanguage, 't_water_expected'),
    electricityExpected: getLocalizedValue(    selectedLanguage,    't_electricity_expected'),
    oilExpected: getLocalizedValue(selectedLanguage, 't_oil_expected'),
    districtHeatingExpected: getLocalizedValue(    selectedLanguage,    't_district_heating_expected'),
    districtCoolingExpected: getLocalizedValue(    selectedLanguage,    't_district_cooling_expected'),
  }
}

export function excelCellFormatting(hasBoldFont) {
  return {
    font: {
      sz: 11,
      bold: hasBoldFont,
    },
    alignment: {
      wrapText: true,
      vertical: 'bottom',
      horizontal: 'center',
    },
    border: {
      right: {
        style: 'thin',
        color: CSS_VARIABLES.forest,
      },
      left: {
        style: 'thin',
        color: CSS_VARIABLES.forest,
      },
      top: {
        style: 'thin',
        color: CSS_VARIABLES.forest,
      },
      bottom: {
        style: 'thin',
        color: CSS_VARIABLES.forest,
      },
    },
  }
}