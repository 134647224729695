import {Font, StyleSheet} from '@react-pdf/renderer'
import atkinsonHyperlegibleRegular from '../../../resources/fonts/font-atkinson-hyperlegible/AtkinsonHyperlegible-Regular.ttf'
import CSS_VARIABLES from '../../../resources/css/_variables.scss'
import {ATKINSON_HYPERLEGIBLE_REGULAR} from '../../helpers/Constants'

Font.register({family: ATKINSON_HYPERLEGIBLE_REGULAR, src: atkinsonHyperlegibleRegular, fontStyle: 'normal'})

const paginationStyles = StyleSheet.create({
  pageNumber: {
    paddingTop:10,
    color: CSS_VARIABLES.forest,
    fontFamily: ATKINSON_HYPERLEGIBLE_REGULAR,
    top:754,
    position:'absolute'
  },
  tableRow:{
    display: 'flex',
    flexDirection: 'row',
  },
  font6:{
    fontSize:6,
    fontFamily: ATKINSON_HYPERLEGIBLE_REGULAR
  },
  font5:{
    left:210,
    width:255,
    fontSize:5,
    fontFamily: ATKINSON_HYPERLEGIBLE_REGULAR,
    textAlign:'right'
  },
  pT4:{
    paddingTop:4
  }
})

export default paginationStyles