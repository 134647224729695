import React, {useCallback, useContext, useLayoutEffect, useEffect, useRef, useState} from 'react'
import './AssetNetZeroCarbon.scss'
import BackNavigation from '../../../common/back-nav/BackNavigation'
import {periodOptions} from '../../../../services/common/datepicker-service'
import {  getRoundOffValueDivisbleBy5,  CachedUserState,  createNavURLForFundPages,  getLegendImage,  getLocalizedValue,
  SessionUserState,  getFromSession,  getLocalStorageByKey,  setMinToZeroForVariousNzcLines} from '../../../../utils/helpers/Helper'
import moment from 'moment'
import {FeatureFlagContext} from 'Contexts'
import {
  ARIA_CONTROLS_OVERLAY_PANEL, assetsOrderByColumnChange,
  CacheKeyFundGroups,
  defaultBaselineYear,
  EXPORT_AS_PDF,
  FUND_BAU_LINE,
  FUND_VIEWS_PAGES,
  modelsOptions,
  modelsOverlayOptions,
  nzcIntensityGraphOptions, SBT_VERSIONS,
  SCOPE_TARGET_MODE,
  TARGET_PATHWAY
} from '../../../../utils/helpers/Constants'
import {TabPanel, TabView} from 'primereact/tabview'
import AssetTPComponent from '../asset-target-pathway/AssetTPComponent'
import {createAssetNZCDefaultScope, getActionBasedTPData, getAssetNzcScopeDetails, getBenchmarkOptions, assetLevelModelList, GetNZCSettings, GetNZCTargetPathways, GetStaticNZCTargetPathways} from '../../../../services/funds/nzc-service'
import {useMutation, useQuery} from 'react-query'
import {noRefetchOnWindowFocus} from '../../../../services/common/useQuery-config'
import {useLocation, useParams} from 'react-router-dom'
import {  actionOverlayHideAction,  assetChartActionHandler,  createActionsCountGraphDataSets,
  dataPointColors,  generateGraphLabels,  getAssetBauProgressGraph,  getAssetInterimTargetGraph,
  getDatasetForDashLineChart,  getDatasetForLineChart,  getStaticIntensityValueFromDefault,  getStrandsGraphDatasets,  greenColor,
  orangeColor,  returnHeader,  STRANDING_POINT_IDENTIFIER,  updateGraphLabelsProperties} from './asset-nzc-utils/asset-nzc-utils'
import CSS_VARIABLES from '../../../../resources/css/_variables.scss'
import {ChartSkeleton, NZCPageSingleLineSkeleton} from '../../../common/skeletons/nzc-fund-skeleton/NZCAssetTableSkeleton'
import {Tooltip} from 'primereact/tooltip'
import {isTextOverflown} from '../../../../utils/UtilsFunctions'
import I18n from '../../../../utils/i18n/I18n'
import {Button} from 'primereact/button'
import RoundedLabel from '../../../common/rounded-label/RoundedLabel'
import AssetNzcGraph from './AssetNzcGraph'
import NzcSettingsForm from '../../funds/net-zero-carbon-home/nzc-settings/NzcSettingsForm'
import UseUrlParameterIds from '../../../../utils/custom-hooks/UseUrlParameterIds'
import { GetFundKeyfactsData } from '../../../../services/funds/fund-group-service'
import {OverlayPanel} from 'primereact/overlaypanel'
import NzcExportOverlay from '../../funds/net-zero-carbon-home/nzc-popup/NzcExportOverlay'
import {API_STATUS, GRAPH_Y_SCALE_MAX_PADDING, TPS_NAMES_MAP, X_SCALE_YEARS} from '../../../common/net-zero-carbon/nzc-constants'
import NzcCardContainer from '../../../common/nzc-card-container/NzcCardContainer'
import PerformanceCard from '../../../common/nzc-card-container/cardContent/PerformanceCard'
import DataQualityCard from '../../../common/nzc-card-container/cardContent/DataQualityCard'
import NetZeroCarbon from '../../../common/nzc-card-container/cardContent/net-zero-carbon-card/NetZeroCarbon'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import CommonDialog from '../../../common/modal-dialogs/ModalDialogCommon'
import NzcCreateAModel from '../../funds/net-zero-carbon-home/nzc-models/CreateAModel'
import NzcDuplicateModel from '../../funds/net-zero-carbon-home/nzc-models/DuplicateModel'
import {Skeleton} from 'primereact/skeleton'
import { updateQueryParamsForFundViews } from '../../../../utils/helpers/DatePickerUtils'
import NzcOpenAModel from '../../funds/net-zero-carbon-home/nzc-models/OpenAModel'
import {externalTooltipHandlerNzc, getDefaultTooltipConfig, hideAssetTooltip} from '../performance/performance-home/ChartHelper'
import {  closeFunction,  getBooleanActionBasedTPToPlot,  getScientificGHGIntensity, returnAssetGraphIntensity} from '../../funds/net-zero-carbon-home/nzc-utils/nzc-utils'
import { getEPCRating } from '../../../../services/assets/portfolio-service'
import NzcActionsOverlay from '../../../common/nzc/custom-overlay/NzcActionsOverlay'
import { Chart } from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation'
import NzcExportModel from '../../funds/net-zero-carbon-home/nzc-models/ExportModel'
import classnames from 'classnames'
import NzcImportModel from '../../funds/net-zero-carbon-home/nzc-models/NzcImportModel'
import ErrorDialog from '../data-quality/error-dialog/ErrorDialog'
import PopupDialog from '../../../common/modal-dialogs/PopupDialog'
import {getDefaultFontObjectForChart, getYearForFinancialDate} from '../../../common/net-zero-carbon/nzc-common-utils'
import {getNZCChartSieraPlusLogo} from '../../funds/net-zero-carbon-home/nzc-chart-helper/nzc-chart-helper'
import {truncateAndFormatNumWOneDecimal} from '../../../../services/common/formatter'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'
import {useMountEffect} from 'primereact/hooks'
import StaticNetZeroCarbon from 'components/common/nzc-card-container/cardContent/net-zero-carbon-card/StaticNetZeroCarbon'

Chart.register(annotationPlugin)
//region AssetNetZeroCarbon component specific handlers
const getPeriodMenuItem = (option) => (option === 'YTD')?
  <span className='p-button-label p-c period-menu-ytd-tooltip' data-pr-position="top">{option}</span> :
  <span className='p-button-label p-c'>{option}</span>
//endregion

const AssetNetZeroCarbon = () => {
  //region Declarations/Initializations
  const location = useLocation()
  const {instanceName, orgName, fundName, assetId} = useParams()
  const queryValues = new URLSearchParams(location.search)
  const featureContext            = useContext(FeatureFlagContext)
  const featureState              = featureContext.featureState
  let nzcAssetScopeIdFromUrl = queryValues.get('model') ? queryValues.get('model') : getFromSession(assetId)
  const [defaultScopeId, setDefaultScopeId]=useState(nzcAssetScopeIdFromUrl)
  const [modelPopup, setModelPopup] = useState('')
  const history = useHistory()
  const urlParameterIds=UseUrlParameterIds({instanceName, orgName, fundName, assetId})
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const userInfoStorage = getLocalStorageByKey('userInfo')
  const isAdmin = userInfoStorage.userRole !== 'Admin'
  const [optionsTabActiveIndex, setOptionsTabActiveIndex]=useState(0)
  const exportOverlayRef       = useRef(null)
  const [period, setPeriod] = useState(getLocalizedValue(selectedLanguage, 't_ytd_uppercase'))
  const [pickerValue, setPickerValue] = useState({pickerValueMonths: '1', pickerValueEndMonth: moment().format('MMM'), pickerValueEndYear: moment().format('YY')})
  const [scopeTargetState, setScopeTargetState]=useState({
    tpMode: null,
    selectedTPName: null,
    activeIndex: null,
  })
  const [assetNzcGraphData, setAssetNzcGraphData]=useState()
  const nzcGraphRef     = useRef(null)
  const [baselineYear, setBaselineYear] = useState()
  const [targetsAndPathwayDetails,setTargetsAndPathwayDetails] = useState({})
  const [estimatedYScaleMax, setEstimatedYScaleMax] = useState()
  const modelsOverlay = useRef(null)
  const [chartOptionsFormState, setChartOptionsFormState]=useState({
    xAxisScaleFrom: 0,
    xAxisScaleTo: 0,
    xAxisAutoScale: true,
    yAxisScaleMin: 0,
    yAxisScaleMax: 0,
    yAxisAutoScale: true,
    displayMarketBenchmark: true,
    selectedMarketBenchmarkID: 0,
    displayStrandingDates: true,
  })
  const [nzcActionOverlayState, setNzcActionOverlayState]=useState({showNzcActionOverlay: 'none', actionYear: null, assetId, defaultScopeId, actionOverlayPageNumber: 0})
  const [nzcDialogsState, setNzcDialogsState] = useState({
    errorDialogVisible: false,
    errorDialogData: null,
    showConfirmModal: false,
    uploadFileName: null,
    assetError: false
  })
  const navExportRef=useRef(null)
  const [isModelsOverlayPanelOpen, setIsModelsOverlayPanelOpen]=useState(false)
  const [isOverlayPanelOpen, setIsOverlayPanelOpen]=useState(false)
  const sessionAssetIntensityValue = SessionUserState.getUserDataFromSession('assetGraphIntensity')
  const assetGraphIntensity = returnAssetGraphIntensity(sessionAssetIntensityValue, assetId, featureState, queryValues)
  const assetIntensityQuery = assetGraphIntensity ? {value: assetGraphIntensity} : nzcIntensityGraphOptions[0]
  const [assetIntensityValue, setAssetsIntensityValue]=useState(assetIntensityQuery)
  const isEnergyIntensity = assetGraphIntensity === assetsOrderByColumnChange.energyIntensity
  //endregion

  //region React Queries
  const {data: fundKeyfactsData} = useQuery(['fundKeyfacts', urlParameterIds?.fundGroupId], GetFundKeyfactsData, {...noRefetchOnWindowFocus, enabled: !!urlParameterIds?.fundGroupId})
  const fundKeyfacts = fundKeyfactsData || CachedUserState.getUserDataFromCache(CacheKeyFundGroups)
  const fundDefaultScopeId = SessionUserState.getUserDataFromSession(fundKeyfacts?.fundId) ? SessionUserState.getUserDataFromSession(fundKeyfacts?.fundId) : fundKeyfacts?.nzcDefaultModel
  const fundDependQueryOptions = { ...noRefetchOnWindowFocus, enabled: (!!fundDefaultScopeId && !fundKeyfacts.isNZCStaticModel) }
  const {data: fundTargetPathways, isLoading: isLoadingFundTargetPathways, isFetching: isFetchingFundTP, isIdle: isIdleFundTP, isError: isErrorFundTP}  = useQuery(['nzcTargetPathways', fundDefaultScopeId, assetIntensityValue.value], GetNZCTargetPathways,  fundDependQueryOptions)
  const {
    isFetching: isLoadingStaticFundTargetPathways,
    data: staticFundTargetPathways,
    isError: isErrorStaticFundTargetPathways
  } = useQuery({
    queryKey: ['GetStaticNZCTargetPathways', urlParameterIds?.fundGroupId, getStaticIntensityValueFromDefault(assetIntensityValue.value)],
    queryFn: GetStaticNZCTargetPathways,
    retry: false,
    refetchOnWindowFocus: noRefetchOnWindowFocus.refetchOnWindowFocus,
    enabled: fundKeyfactsData?.isNZCStaticModel
  })
  const dependentQueryOptions={...noRefetchOnWindowFocus, enabled: (!!defaultScopeId)}
  const createAssetNZCDefaultScopeMutation = useMutation(createAssetNZCDefaultScope)
  const {data: modelSettings, isLoading: isLoadingModelSettings, refetch: refetchModelSettings} = useQuery(['assetNzcSettings', defaultScopeId, assetIntensityValue.value], GetNZCSettings, dependentQueryOptions)
  const {data: nzcScopeDetailsData, isLoading: isLoadingNzcScopeDetails, status: nzcScopeDetailsStatus, refetch: reftechAssetNZCScopeDetails, isFetching: nzcScopeDetailsDataFetching, isIdle: isIdleNzcScopeDetails} = useQuery(['assetNzcScopeDetails', defaultScopeId], getAssetNzcScopeDetails, dependentQueryOptions)
  const {data: marketBenchmarkOptions} = useQuery(['marketBenchmarkOptions', assetIntensityValue.value, assetId], getBenchmarkOptions, noRefetchOnWindowFocus)
  const {data: actionBasedTPData} = useQuery(['actionBasedTPData', defaultScopeId],getActionBasedTPData, dependentQueryOptions)
  const {data: targetPathways, isLoading:  isLoadingTargetPathways, refetch: refetchTargetPathways, status, isFetching: isFetchingTargetPathways}  = useQuery(['assetNzcTargetPathways', defaultScopeId, assetIntensityValue.value], GetNZCTargetPathways,  dependentQueryOptions)
  const nzcScopeDetails=nzcScopeDetailsData?.data
  const {isLoading: getAssetNZCScopesLoading, data: getAssetNZCScopes, refetch: refetchGetAssetNZCScopes} = useQuery(['NZCAssetScopes', assetId], assetLevelModelList, { ...noRefetchOnWindowFocus, enabled: !!assetId && !!defaultScopeId })
  const defaultModelScopeId = getAssetNZCScopes?.data ? getAssetNZCScopes?.data?.find((scope) => scope?.isDefaultModel)?.scopeId : null
  const beforeTabChangeActionRef=useRef()
  const queryOptions={...noRefetchOnWindowFocus, enabled: !!assetId}
  const {data: dataEPCRating } = useQuery(['EPCRating', `${assetId}`.split('_')[0]], getEPCRating, queryOptions)
  const reportingYear = featureState.sieraplus_nzc_reportingYear  ? getYearForFinancialDate(fundKeyfacts?.financialYearStart) : new Date().getFullYear() - 1

  //endregion

  let epcRatingLabel = '-'
  if(dataEPCRating?.epcRating && dataEPCRating?.epcScheme){
    epcRatingLabel = `${dataEPCRating.epcRating} - ${dataEPCRating.epcScheme}`
  }
  const chartLegendIcon = {
    ACTION_BASED_TARGET_PATHWAY: getLegendImage('action-carbon-tp'),
    CUSTOM_BASED_TARGET_PATHWAY: getLegendImage('targetPathwaySvg'),
    SCIENCE_BASED_TARGET_PATHWAY: getLegendImage('science'),
    SELECTED_BENCHMARK: getLegendImage('enclosed'),
    BENCHMARK_VALUE: getLegendImage('curvyTriangle'),
    BAU_STRANDING_YEAR: getLegendImage('bauStrandingYear'),
    CUSTOM_BASED_TARGET_PATHWAY_STRANDING_YEAR: getLegendImage('targetPathwayStrandingSvg'),
    ACTION_BASED_TARGET_PATHWAY_STRANDING_YEAR: getLegendImage('actionBasedStrandingYear'),
    SELECTED_ASSET : getLegendImage('selectedAsset'),
    BAU_LINE : getLegendImage('bau'),
  }

  //region Side effects
  useMountEffect(()=>{
    if(!defaultScopeId){
      createAssetNZCDefaultScopeMutation.mutateAsync(assetId).then(newDefaultScope=>{
        updateDefaultScopeId(newDefaultScope.data)
      }).catch(( )=>{
        updateAssetNzcGraphData(null)
      })
    }
  })

  useEffect(() => {
    const newBaseYear = (modelSettings?.baselineYear > 0) ? modelSettings?.baselineYear : defaultBaselineYear
    setBaselineYear(newBaseYear)
    if (modelSettings) {
      updateTargetsAndPathwayDetails({
        showProgressTowardsTargetYear: targetsAndPathwayDetails.showProgressTowardsTargetYear ? targetsAndPathwayDetails.showProgressTowardsTargetYear : modelSettings.showTargetProgress,
        progressTowardsTargetYear: targetsAndPathwayDetails.progressTowardsTargetYear ? targetsAndPathwayDetails.progressTowardsTargetYear : modelSettings.progressTargetYear,
        energySavingActions: targetsAndPathwayDetails.energySavingActions ? targetsAndPathwayDetails.energySavingActions : modelSettings.energySavingActions
      })
    }
  }, [modelSettings, targetPathways])

  useEffect(() => {
    initialiseChartOptions(modelSettings)
  }, [modelSettings, estimatedYScaleMax, targetPathways])

  useEffect(() => {
    // To set TP details on load of NZC
    setTPDetailsOnLoad(modelSettings)
  }, [modelSettings])

  useLayoutEffect(() => {
    if (defaultScopeId) {
      const urlQueryParams = {
        model: defaultScopeId,
        assetIntensity: assetIntensityValue.value
      }
      updateQueryParamsForFundViews(urlQueryParams, null, history, instanceName, orgName, fundName, FUND_VIEWS_PAGES.netZeroCarbon, assetId)
    }
  }, [defaultScopeId, assetIntensityValue])

  useEffect(()=>{
    if(targetPathways){
      const yMaxFromGraphData=getMaxYScale(targetPathways?.graphData)
      setEstimatedYScaleMax((benchmarkObj?.actualValue && (Number(benchmarkObj?.actualValue) > yMaxFromGraphData) ? getRoundOffValueDivisbleBy5(benchmarkObj?.actualValue) : yMaxFromGraphData) + GRAPH_Y_SCALE_MAX_PADDING)
      const nzcGraphData=getNzcGraphData(targetPathways, modelSettings)
      if(nzcGraphData){
        updateAssetNzcGraphData({ ...nzcGraphData, data: { ...nzcGraphData?.data, labels: []} })
      } else {
        updateAssetNzcGraphData(nzcGraphData)
      }
    } else {
      updateAssetNzcGraphData(null)
    }
  }, [targetPathways, scopeTargetState, chartOptionsFormState, reportingYear])
  //endregion

  //region Functions
  function updateNzcDialogsState(newNzcDialogsState) {
    setNzcDialogsState({...nzcDialogsState, ...newNzcDialogsState})
  }

  function errorDialogCloseHandler() {
    updateNzcDialogsState({errorDialogVisible: false})
  }

  function confirmDialogCloseHandler() {
    updateNzcDialogsState({showConfirmModal: false})
  }

  function confirmDialogFooter(){
    return (
      <div>
        <Button label={I18n('t_error_ok')} className={'dialog-ok'} onClick={confirmDialogCloseHandler}/>
      </div>
    )
  }

  function updateNzcActionOverlayState(newState) {
    setNzcActionOverlayState(prevState=>{
      return {
        ...prevState,
        ...newState
      }
    })
  }

  function settingsAndTpApiError() {
    return (API_STATUS.error === nzcScopeDetailsStatus || API_STATUS.error === status)
  }

  function actionsDataNotEmpty() {
    return !!(actionBasedTPData?.agreedActions > 0 || actionBasedTPData?.applicableActions > 0)
  }

  function initialiseChartOptions(modelSettings) {
    if (modelSettings) {
      updateChartOptionsFormState({
        xAxisScaleFrom: modelSettings?.xAxisScaleFrom ? modelSettings.xAxisScaleFrom : modelSettings?.baselineYear,
        xAxisScaleTo: modelSettings?.xAxisScaleTo ? modelSettings.xAxisScaleTo : X_SCALE_YEARS.y2k50,
        xAxisAutoScale: modelSettings?.xAxisAutoScale,
        yAxisScaleMin: modelSettings?.yAxisScaleMin ? modelSettings.yAxisScaleMin : 0,
        yAxisScaleMax: (modelSettings && modelSettings.yAxisAutoScale) ?  estimatedYScaleMax:  modelSettings.yAxisScaleMax,
        yAxisAutoScale: modelSettings?.yAxisAutoScale,
        displayMarketBenchmark: modelSettings?.displayMarketBenchmark,
        selectedMarketBenchmarkID: modelSettings?.selectedMarketBenchmarkID,
        displayStrandingDates: modelSettings?.displayStrandingDates,
      })
    }
  }

  const getBenchmarkOptionsData = () => {
    return marketBenchmarkOptions ? marketBenchmarkOptions?.map((marketBenchmarkOption) => {
      return {
        label: marketBenchmarkOption.marketBenchmarkName,
        value: marketBenchmarkOption.marketBenchmarkID,
        actualValue: marketBenchmarkOption.marketBenchmarkValue,
        enabled: marketBenchmarkOption.isEnabled
      }
    }) : []
  }

  const benchmarkObj = modelSettings?.displayMarketBenchmark && modelSettings?.selectedMarketBenchmarkID && getBenchmarkOptionsData().find((e) => e.value === modelSettings?.selectedMarketBenchmarkID)

  function updateChartOptionsFormState(newChartOptionsFormValues) {
    setChartOptionsFormState(prevChartOptionsFormValues=>{
      return {
        ...prevChartOptionsFormValues,
        ...newChartOptionsFormValues
      }
    })
  }

  function getFundNameWithParent(fundTargetPathways, fundKeyfacts, selectedLanguage) {
    const parentFundText = getLocalizedValue(selectedLanguage, 't_parent_fund')
    
    if (fundKeyfacts?.isNZCStaticModel) {
      return `${fundKeyfacts.fundname} ${parentFundText}`
    } else if (fundTargetPathways?.scopeName) {
      return `${fundTargetPathways.scopeName} ${parentFundText}`
    } else {
      return parentFundText
    }
  }

  function setTPDetailsOnLoad(modelSettings) {
    if (modelSettings) {
      let tpMode
      let selectedTPName
      let activeIndex
      const tpFromNzcSettingsData = modelSettings?.targetPathway
      if (tpFromNzcSettingsData) {
        tpMode = 'view'
        selectedTPName = `tp${tpFromNzcSettingsData}`
        activeIndex = tpFromNzcSettingsData - 1
      } else {
        tpMode = null
        selectedTPName = null
        activeIndex = null
      }
      updateScopeTargetState({
        tpMode: tpMode,
        selectedTPName: selectedTPName,
        activeIndex: activeIndex
      })
    }
  }

  function updateTargetsAndPathwayDetails(newState){
    setTargetsAndPathwayDetails(prevState => {
      return {
        ...prevState,
        ...newState
      }
    })
  }
  function updateDefaultScopeId(newDefaultScopeId) {
    setDefaultScopeId(newDefaultScopeId)
  }

  function updateScopeTargetState(newScopeTargetState) {
    setScopeTargetState(prevScopeTargetState=>{
      return {
        ...prevScopeTargetState,
        ...newScopeTargetState
      }
    })
  }

  function updateAssetNzcGraphData(newAssetGraphData){
    setAssetNzcGraphData(newAssetGraphData)
  }

  function getMaxIntensity(intensityValue) {
    return(Math.ceil((Math.max(...intensityValue) + 4) / 10) * 10)
  }

  function getMaxYScale(assetGraphData) {
    const isEnergyIntensity = assetIntensityValue?.value === assetsOrderByColumnChange.energyIntensity
    const bauIntensity = isEnergyIntensity ? 'weightedAverageEnergyIntensity' : 'bauGhgIntensity'
    const scientificIntensity = isEnergyIntensity ? 'scientificEnergyIntensity' : 'scientificGHGIntensity'
    let maxBauIntensity = getMaxIntensity(assetGraphData.map(graphDataElement => graphDataElement[bauIntensity]))
    const maxScientificIntensity = getMaxIntensity(assetGraphData.map(graphDataElement => graphDataElement[scientificIntensity]))
    if (maxScientificIntensity > maxBauIntensity) {
      maxBauIntensity = maxScientificIntensity
    }
    return maxBauIntensity
  }

  function getNzcGraphData(targetPathways, modelSettings){
    if(!targetPathways || !targetPathways?.graphData || !modelSettings){
      return null
    }
    const nzcGraphDatasetsForAsset=getNzcGraphDatasetsForAsset(targetPathways, assetId, baselineYear, modelSettings)
    if(nzcGraphDatasetsForAsset?.length>0){
      return ({
        data: {
          labels:[],
          datasets: nzcGraphDatasetsForAsset
        },
        options: getGraphOptions(targetPathways, modelSettings)
      })
    } else {
      return null
    }
  }

  const getXaxisValue = (value) => {
    return (value + 1) % 5 === 0 ? `${value}`.substring(2, value.length) : value
  }

  function isViewOrEditMode() {
    return (scopeTargetState.tpMode===SCOPE_TARGET_MODE.view || scopeTargetState.tpMode===SCOPE_TARGET_MODE.edit) && scopeTargetState.selectedTPName
  }

  const getBenchmarkGraphValue = (rebEnclosedValue) => {
    return rebEnclosedValue
  }

  const booleanToPlot = getBooleanActionBasedTPToPlot(modelSettings?.energySavingActions, featureState.sieraplus_actionBasedPathways)

  function getNzcGraphDatasetsForAsset(targetPathways, assetId, baselineYear, modelSettings) {
    if (!assetId || !baselineYear || !modelSettings) {
      return null
    }
    const displayStrandingDates=modelSettings?.displayStrandingDates
    const graphDatasets = []
    const strandings=targetPathways.strandings
    const actionBasedTPGraphDataSet={
      ...getDatasetForDashLineChart(),
      order: 4,
      label: `    ${getLocalizedValue(selectedLanguage, 't_action_based_carbon_pathway')}`,
      borderColor: CSS_VARIABLES.green,
      backgroundColor: CSS_VARIABLES.green,
      pointStyle: chartLegendIcon.ACTION_BASED_TARGET_PATHWAY,
    }
    const selectedTPGraphDataSet={
      ...getDatasetForDashLineChart(),
      order: 4,
      borderWidth: 2,
      borderColor: CSS_VARIABLES.nature,
      backgroundColor: CSS_VARIABLES.nature,
      pointStyle: chartLegendIcon.CUSTOM_BASED_TARGET_PATHWAY,
      graphName: 'targetPathway',
    }
    if(modelSettings?.targetPathwayNames && modelSettings?.targetPathwayNames!=='null'){
      selectedTPGraphDataSet.label=scopeTargetState.selectedTPName && modelSettings?.targetPathwayNames? `     ${JSON.parse(modelSettings?.targetPathwayNames)[scopeTargetState.selectedTPName]}`:''
    }
    const scienceBasedTargetLabel = modelSettings?.scienceBasedTarget === SBT_VERSIONS.sbt_1_5 ? 't_scientific_ghg_intensity' : 't_scientific_ghg_intensity(2.0)'
    const sbtGraphDataset={
      ...getDatasetForLineChart(),
      order: 3,
      label: `   ${getLocalizedValue(selectedLanguage, scienceBasedTargetLabel)}`,
      borderColor: CSS_VARIABLES.blue,
      backgroundColor: CSS_VARIABLES.blue,
      borderWidth: 2,
      pointStyle: chartLegendIcon.SCIENCE_BASED_TARGET_PATHWAY,
    }
    targetPathways?.graphData.sort((a, b) => a.year - b.year)
    targetPathways?.graphData.forEach((graphData)=>{
      getScientificGHGIntensity(featureState.sieraplus_scienceBasedPathways, sbtGraphDataset, graphData, 1, graphDatasets, assetIntensityValue?.value)
      // sbtGraphDataset.data.push({x:graphData.year,y:graphData.scientificGHGIntens.ity})
      if(isViewOrEditMode() && scopeTargetState.selectedTPName && graphData.year > reportingYear){
        selectedTPGraphDataSet.data.push({x:graphData.year,y:graphData[scopeTargetState.selectedTPName]===null?0: graphData[scopeTargetState.selectedTPName]})
      }
      if(booleanToPlot && actionsDataNotEmpty() && graphData.year > reportingYear){
        let avgEstimatedSavingsIntensity
        if(assetIntensityValue?.value === assetsOrderByColumnChange.energyIntensity){
          avgEstimatedSavingsIntensity = graphData.weightedAverageEstimatedSavingsEnergyIntensity
        } else {
          avgEstimatedSavingsIntensity = graphData.weightedAverageEstimatedSavingsCarbonIntensity
        }
        actionBasedTPGraphDataSet.data.push({x:graphData.year,y:setMinToZeroForVariousNzcLines(avgEstimatedSavingsIntensity)})
      }
    })

    const propsForGetAssetProgressGraphFunction = {targetPathways, reportingYear, baselineYear, selectedLanguage, assetId, epcRatingLabel, nzcScopeDetails, assetIntensity : assetIntensityValue?.value,chartLegendIcon, featureState}
    const assetProgressGraph=getAssetBauProgressGraph(propsForGetAssetProgressGraphFunction)
    assetProgressGraph && graphDatasets.push(...assetProgressGraph)
    const actionsCountGraphDataSets=booleanToPlot && createActionsCountGraphDataSets(targetPathways, assetId, selectedLanguage, modelSettings, assetIntensityValue?.value) // Create bubble chart to display asset actions count.
    actionsCountGraphDataSets && graphDatasets.push(...actionsCountGraphDataSets)
    // graphDatasets.push(sbtGraphDataset)
    getScientificGHGIntensity(featureState.sieraplus_scienceBasedPathways, sbtGraphDataset, null, 2, graphDatasets, assetIntensityValue?.value) //SBT for Asset

    // action based pathway strandings for Carbon and Energy Intensity
    let actionBasedPathwayStrandingLabel = assetIntensityValue?.value === assetsOrderByColumnChange.energyIntensity?STRANDING_POINT_IDENTIFIER.ACTION_BASED_PATHWAYS.ENERGY:STRANDING_POINT_IDENTIFIER.ACTION_BASED_PATHWAYS.CARBON
    actionBasedTPGraphDataSet.data.length>0 && graphDatasets.push(actionBasedTPGraphDataSet) //action based TP

    if (booleanToPlot && displayStrandingDates) {
      const actionBasedStranding=strandings.filter(strandingDetails => actionBasedPathwayStrandingLabel === strandingDetails.strandingOf)
      if (actionBasedStranding && actionBasedStranding.length>0 && actionsDataNotEmpty()) {
        graphDatasets.push({
          type: 'line',
          order: 4,
          label: ` ${getLocalizedValue(selectedLanguage, 't_action_based_pathway_stranding')} ${Math.round(actionBasedStranding[0].year)}`,
          fill: false,
          pointStyle: chartLegendIcon.ACTION_BASED_TARGET_PATHWAY_STRANDING_YEAR,
          data: [],
          datalabels: {display: false}
        }
        )
      }
    }
    if(isViewOrEditMode() && selectedTPGraphDataSet.data.length>0){
      graphDatasets.push(selectedTPGraphDataSet) //custom (top down TP)
    }
    const assetInterimTargetGraphDataset=getAssetInterimTargetGraph(targetPathways, targetsAndPathwayDetails, selectedLanguage, baselineYear, scopeTargetState, modelSettings?.targetPathway,reportingYear)
    assetInterimTargetGraphDataset.length > 0 && graphDatasets.push(...assetInterimTargetGraphDataset) // Interim target
    if(displayStrandingDates && strandings && strandings.length>0){
      const strandingYearForTP = Math.round(strandings.filter(strandingDetails => TPS_NAMES_MAP[scopeTargetState.selectedTPName] === strandingDetails.strandingOf)[0]?.year)
      if (isViewOrEditMode() && strandingYearForTP) {
        graphDatasets.push({
          type: 'line',
          order: 4,
          label: ` ${getLocalizedValue(selectedLanguage, 't_target_stranding_year')}${strandingYearForTP}`,
          fill: false,
          pointStyle: chartLegendIcon.CUSTOM_BASED_TARGET_PATHWAY_STRANDING_YEAR,
          data: [],
          datalabels:{
            display:false
          },
        }
        )
      }
    }
    const selectedMarketBenchmark = getBenchmarkOptionsData().find((e) => e.value === modelSettings?.selectedMarketBenchmarkID)
    if(selectedMarketBenchmark && modelSettings?.displayMarketBenchmark){ // Benchmark
      graphDatasets.push(
        {
          ...getDatasetForLineChart(),
          order: 4,
          borderColor:  CSS_VARIABLES.deep_ocean,
          backgroundColor: CSS_VARIABLES.deep_ocean,
          pointStyle: chartLegendIcon.SELECTED_BENCHMARK,
          borderWidth: 2,
          borderDash: [10, 10],
          label: `   ${(selectedMarketBenchmark?.label)}`,
          data: (() => {
            let data = []
            const yAxisVal = getBenchmarkGraphValue(Number(selectedMarketBenchmark?.actualValue))
            for (let i = baselineYear; i <= 2050; i++) {
              data.push({
                x: i,
                y: yAxisVal
              })
            }
            return data
          })(),
        }
      )
    }
    if(targetPathways){
      let assetData = targetPathways.assetGraphData.find(graphData=> graphData.assetId === Number(assetId))
      let selectedAssetStranding = assetData.strandingYear
      if(selectedAssetStranding){
        graphDatasets.push({
          type: 'line',
          order: 4,
          label: `  ${getLocalizedValue(selectedLanguage, 't_bau_stranding_year') + Math.floor(selectedAssetStranding.year)}`,
          fill: false,
          pointStyle: getLegendImage('bauStrandingYear'),
          data: [],
          datalabels: {display: false}
        })
        graphDatasets.push(
          {
            type: 'bubble',
            data: [{
              x: selectedAssetStranding.year,
              y: selectedAssetStranding.strandingPoint,
              r: 17,
              label:selectedAssetStranding.strandingOf
            }],
            backgroundColor: 'transparent',
            borderColor: CSS_VARIABLES.pink,
            clip: false
          }
        )
      }
    }
    if(displayStrandingDates){
      const strandsGraphDatasets=getStrandsGraphDatasets(targetPathways.strandings, scopeTargetState, assetIntensityValue?.value, targetsAndPathwayDetails,actionBasedTPData)
      strandsGraphDatasets.length>0 && graphDatasets.push(...strandsGraphDatasets) // Strandings
    }
    return graphDatasets
  }

  const changeTimePeriodHandler=useCallback((event) => {
    if(event.value){
      setPeriod(event.value)
    }
  }, [])

  const datePickerChangeHandler=useCallback((event) => {
    setPickerValue({
      ...pickerValue,
      [event.target.name]: event.target.name,
    })
  }, [])

  const exportActionHandler=useCallback((event) => exportOverlayRef.current.toggle(event), [])

  const assetTooltipConfig = {
    assetNzcPageLink: null,
    showAssetNzcPageLink: false
  }

  const triggerTooltip = (chart, assetId) => {
    chart.options.plugins.tooltip = {...getDefaultTooltipConfig(), external: (context) => externalTooltipHandlerNzc(context, assetTooltipConfig,isEnergyIntensity), externalName: 'externalTooltipHandlerNzc'}
    chart.update()
    const tooltip = chart && chart?.tooltip
    const datasetIndex = chart?.data && chart?.data?.datasets.map((dataset) => dataset.assetIntensityId).indexOf(`${assetId}`)
    tooltip?.setActiveElements([
      {
        datasetIndex: datasetIndex,
        index: 0,
      }],
    {
      x: tooltip.x,
      y: tooltip.y,
    }
    )
    chart.update()
  }

  const getAssetValue = (e, activeElement) => {
    const label = activeElement?.element?.$context?.raw?.label
    if (label && Number(`${label}`.split('_')[0])) {
      setTimeout(() => {
        closeFunction(document.querySelector('.asset-detail-panel'), 'block')
        if (document.getElementById('closeAssetPanel')) {
          document.getElementById('closeAssetPanel').onclick = () => {
            closeFunction(document.querySelector('.asset-detail-panel'), 'none')
          }
        }
      }, 500)
      triggerTooltip(e.chart, label)
    }
  }

  const chartOnClickHandler = (...chartActionParams) => {
    const [actionEvent, activeElements]=chartActionParams
    const firstActiveElement=activeElements[0]
    if(firstActiveElement) {
      getAssetValue(actionEvent, firstActiveElement)
    }
  }

  function chartClickAction(event, activeElements, chart) {
    const datasetIndex=activeElements[0]?.datasetIndex
    const datasets=chart.config?.data?.datasets
    const actionYearDataset=datasets[datasetIndex]
    if(actionYearDataset && 'assetActionsCount'===actionYearDataset.graphName) {
      const assetDetailPanel = document.querySelector('.asset-detail-panel')
      if(assetDetailPanel){
        hideAssetTooltip(chart)
      }
      assetChartActionHandler(activeElements, chart, updateNzcActionOverlayState, assetId, defaultScopeId)
    } else {
      actionOverlayHideAction(updateNzcActionOverlayState, nzcGraphRef)
      chartOnClickHandler(event, activeElements, chart)
    }
  }

  function getGraphOptions(targetPathways, modelSettings) {
    return(
      {
        onClick: chartClickAction,
        responsive: true,
        maintainAspectRatio: false,
        layout: {padding: {top: 21}},
        plugins: {
          tooltip: {
            enabled: false,
            ...getDefaultTooltipConfig(),
          },
          datalabels:{
            backgroundColor: CSS_VARIABLES.white,
            borderColor: CSS_VARIABLES.forest_20,
            borderRadius:3,
            borderWidth	:0.1,
            color: (value) => {
              return (value?.dataset?.label?.includes('BAU')||value?.dataset?.label?.includes(TARGET_PATHWAY)) ?  CSS_VARIABLES.red : CSS_VARIABLES.forest
            },
            clamp:true,
            display:true,
            anchor: 'end',
            align: (value) => {
              let data = value?.dataset?.data?.length > 0 && value?.dataset?.data[0]
              let position = 'right'
              let reduceYears = data.label.includes(TARGET_PATHWAY)?3:2
              if(Math.round(data.x)!==modelSettings.xAxisScaleFrom){
                if((Math.round(data.x,1)>=2043)||((Math.round(data.x,1)>=modelSettings.xAxisScaleTo-reduceYears)&& !(modelSettings.xAxisScaleTo<Math.round(data.x,1)))){
                  position='left'
                }
              }
              return position
            },
            font: {family: CSS_VARIABLES.fontFamilyBase, weight: 400, size: 12},
            formatter: function (value) {
              return ((value?.label?.includes('BAU'))||(value?.label?.includes(TARGET_PATHWAY))||(value?.label?.includes('Action'))) ? null : value.label
            },
            offset: 5,
            padding: {right: 5, left: 5, top: 6, bottom: 5}
          },
          aspectRatio: 5 / 3,
          layout: {padding: 16},
          legend: {
            position: 'bottom',
            align: 'start',
            onClick: null,
            labels: {
              usePointStyle: true,
              padding: 15,
              font: getDefaultFontObjectForChart({weight: 400, size: 9}),
              generateLabels: (chart) => {
                let generatedLabels=[]
                let visibility = []
                for (let i = 0; i < chart.data.datasets.length; i++){
                  if(chart.isDatasetVisible(i)){
                    visibility.push(CSS_VARIABLES.forest)
                  } else {
                    visibility.push(CSS_VARIABLES.forest_50)
                  }
                }
                const reqLegends = [{
                  text: ` ${getLocalizedValue(selectedLanguage, 't_complete_data')}`,
                  fillStyle: `${greenColor}`,
                  strokeStyle: `${greenColor}`,
                  lineWidth:1,
                  pointStyle: 'circle',
                  hidden: false,
                  index: 8,
                  fontColor: CSS_VARIABLES.forest
                }, {
                  text: ` ${getLocalizedValue(selectedLanguage, 't_benchmark_data')}`,
                  lineWidth: 2,
                  pointStyle: chartLegendIcon.BENCHMARK_VALUE,
                  hidden: false,
                  index: 9,
                  fontColor: CSS_VARIABLES.forest
                }, {
                  text: ` ${getLocalizedValue(selectedLanguage, 't_uplifted_estimated_data')}`,
                  fillStyle: `${orangeColor}`,
                  strokeStyle: `${orangeColor}`,
                  lineWidth:2,
                  pointStyle: 'rectRounded',
                  hidden: false,
                  index: 10,
                  fontColor: CSS_VARIABLES.forest
                }]
                if(booleanToPlot && actionsDataNotEmpty()){
                  reqLegends.push({
                    text: ` ${getLocalizedValue(selectedLanguage, 't_actions_per_year')}`,
                    fillStyle: CSS_VARIABLES.green,
                    strokeStyle: CSS_VARIABLES.green,
                    lineWidth:1,
                    pointStyle: 'circle',
                    hidden: false
                  })
                }
                if( chart.data && chart.data.datasets){
                  generatedLabels= generateGraphLabels(chart, visibility)
                }
                generatedLabels = reqLegends.concat(generatedLabels)
                if(chart.data && chart.data.labels){
                  updateGraphLabelsProperties(chart, generatedLabels, visibility, dataPointColors)
                }
                return generatedLabels
              },

              filter:function(label){
                if(label?.text===FUND_BAU_LINE){
                  return false
                }
                return label.text
              }
            }
          }
        },
        scales: {
          xAxis:{
            afterFit: (chart) => {
              chart.paddingBottom += 115
            },
            type:'linear',
            ticks: {
              stepSize: 1,
              color: CSS_VARIABLES.forest,
              autoSkip: false,
              padding: 13,
              maxRotation: 0,
              autoSkipPadding: 0,
              font: getDefaultFontObjectForChart({weight: 400, size: 8.5}),
              callback: function (value, i){
                return value % 5 === 0 || i === 0 ? getXaxisValue(value) : ''
              }
            },
            grid: {
              display: true,
              color:  (chartContext) => {
                if (((baselineYear + 1) % 5 === 0 && chartContext?.tick?.value % 5 === 0) || chartContext?.tick?.value % 5 === 0) {
                  return CSS_VARIABLES.forest_20
                }
              },
              tickColor: CSS_VARIABLES.forest_20,
            },
            min: !(modelSettings.xAxisAutoScale)? modelSettings.xAxisScaleFrom : baselineYear,
            max: !(modelSettings.xAxisAutoScale)? modelSettings.xAxisScaleTo : X_SCALE_YEARS.y2k50
          },
          xAxisAssetIntensities:{type:'linear', display: false, beginAtZero: true},
          y: {
            suggestedMin: 5,
            min: !(modelSettings.yAxisAutoScale)? modelSettings.yAxisScaleMin : 0,
            max: !(modelSettings.yAxisAutoScale)? modelSettings.yAxisScaleMax : estimatedYScaleMax,
            ticks: {
              stepSize:()=> {
                if ((!(modelSettings.yAxisAutoScale)? modelSettings.yAxisScaleMax : estimatedYScaleMax) >= 200) {
                  return ((!(modelSettings.yAxisAutoScale)? modelSettings.yAxisScaleMax : estimatedYScaleMax)/10)
                } else{
                  return 5
                }
              },
              callback: (value)  =>{
                let max = (!(modelSettings.yAxisAutoScale)? modelSettings.yAxisScaleMax : estimatedYScaleMax)
                if(value===0){
                  return 0
                }
                if(max <= 200){
                  return Math.round(value)
                }
                return truncateAndFormatNumWOneDecimal(selectedLanguage,Math.round(value))
              },
              padding: 17,
              color: CSS_VARIABLES.forest,
              font: getDefaultFontObjectForChart({style: 'normal', weight: 400, size: 9})
            },
            grid: {drawTicks: false}
          }
        },
        elements: {point: {radius: 0}},
        animation: {
          duration: 0
        }
      }
    )
  }

  function onTabChange(tabIndex) {
    setOptionsTabActiveIndex(tabIndex)
  }

  function scopeDataLoading() {
    return ((nzcScopeDetailsStatus!=='error' && !createAssetNZCDefaultScopeMutation.isError) && (isLoadingNzcScopeDetails || nzcScopeDetailsDataFetching || isIdleNzcScopeDetails))
  }

  function isNzcDefaultModel() {
    return !!defaultScopeId
  }

  const getDefaultLabel = () => {
    return isNzcDefaultModel() && (defaultModelScopeId === nzcScopeDetails.scopeId) ? <RoundedLabel name={getLocalizedValue(selectedLanguage, 't_default_model_name')}/> : null
  }

  function graphDataLoading() {
    return !!(isLoadingTargetPathways || isLoadingModelSettings || isLoadingNzcScopeDetails || status ==='idle' || isFetchingTargetPathways) && !createAssetNZCDefaultScopeMutation.isError
  }

  const handleModels = (modelOption) => {
    modelsOverlay.current.hide()
    switch(modelOption) {
    case modelsOptions.createNewModel: setModelPopup(modelsOptions.createNewModel); break
    case modelsOptions.duplicateModel: setModelPopup(modelsOptions.duplicateModel); break
    case modelsOptions.openModel: setModelPopup(modelsOptions.openModel); break
    case modelsOptions.exportConfigurationData: setModelPopup(modelsOptions.exportConfigurationData); break // extra feature that might come up in future
    case modelsOptions.importConfigurationData: setModelPopup(modelsOptions.importConfigurationData); break
    default: break
    }
  }

  const onHideModel = () => {
    setModelPopup('')
  }

  function handleModelOverlayOptions(option, i) {
    const adminAccess = isAdmin && (option.value === modelsOptions.exportConfigurationData || option.value === modelsOptions.importConfigurationData)
    const conditionToEnableButton = (option.value.includes(modelsOptions.duplicateModel) || option.value.includes(modelsOptions.exportConfigurationData) || option.value.includes(modelsOptions.importConfigurationData)) && nzcScopeDetails?.scopeAssets?.length === 0
    const conditionToEnableButtonForNotAdmin = nzcScopeDetails?.scopeAssets?.length === 0 && option.value === modelsOptions.duplicateModel && isAdmin
    return adminAccess ? null : (
      <><div role="presentation" className={classnames('overlay-model-option cursor', (conditionToEnableButtonForNotAdmin || conditionToEnableButton) && 'disable-section')} key={i} onClick={() => handleModels(option.value)}>{option.label}</div>
        {!(isAdmin) && option.value === modelsOptions.duplicateModel && <div className={'border-line'}/>}</>
    )
  }

  function getPropsForAssetNzcGraph() {
    return {
      assetNzcGraphData,
      nzcScopeDetails,
      nzcSettingsData: modelSettings,
      chartOptionsFormState,
      nzcGraphRef,
      scopeId: defaultScopeId,
      estimatedYScaleMax,
      reloadData: reloadSettingsAndTargetPathwaysData,
      selectedLanguage,
      updateChartOptionsFormState,
      initialiseChartOptions,
      assetId,
      assetIntensityValue,
      setAssetsIntensityValue,
      onHideActions: ()=>actionOverlayHideAction(updateNzcActionOverlayState, nzcGraphRef),
      getBenchmarkOptionsData: getBenchmarkOptionsData()
    }
  }

  function reloadSettingsAndTargetPathwaysData() {
    void refetchModelSettings()
    void refetchTargetPathways()
  }
    
  const updateScopeId = (newScopeId) => {
    updateDefaultScopeId(newScopeId)
    if(assetId){
      SessionUserState.saveUserDataToSession(`${assetId}`, newScopeId)
    }
  }

  function reloadModelData() {
    refetchGetAssetNZCScopes().catch(error=>{
      console.log(error)
    })
  }

  const createAModelSuccessCallback = (newScopeId) => {
    reloadModelData()
    updateScopeId(newScopeId.data)
  }

  function handleModelContainer(model){
    switch(model) {
    case modelsOptions.createNewModel: return <NzcCreateAModel
      onHideModel={onHideModel}
      assetId={assetId}
      createAModelSuccessCallback={createAModelSuccessCallback}
      assetLevel
    />
    case  modelsOptions.duplicateModel: return <NzcDuplicateModel
      onHideModel={onHideModel}
      assetId={assetId}
      NzcScopeName={nzcScopeDetails.scopeName}
      scopeId={defaultScopeId}
      duplicateSuccessModelCallback={createAModelSuccessCallback}
      assetLevel
    />
    case modelsOptions.openModel: return <NzcOpenAModel
      getNZCScopes={getAssetNZCScopes?.data}
      getNZCScopesLoading={getAssetNZCScopesLoading}
      refetchGetNZCScopes={refetchGetAssetNZCScopes}
      onHideModel={onHideModel}
      defaultModelScopeId={defaultModelScopeId}
      updateScopeId={updateScopeId}
      assetId={assetId}
      currentModelId={defaultScopeId}
    />
    case modelsOptions.exportConfigurationData: return <NzcExportModel
      NzcScopeDetailsData={nzcScopeDetails}
      scopeId={defaultScopeId}
      onHideModel={onHideModel}
      assetOrderByValue={null}
      assetLevel
    />
    case modelsOptions.importConfigurationData: return <NzcImportModel
      onHideModel={onHideModel}
      assetId={assetId}
      scopeId={defaultScopeId}
      updateScopeId={updateScopeId}
      updateNzcDialogsState={updateNzcDialogsState}
      assetName={urlParameterIds?.assetName}
      importModelSuccessHandler={reloadModelData}
      assetLevel
    />
    default: return ''
    }
  }

  function getModelNameNode() {
    if(!nzcScopeDetails){
      return <div className='nzc-model-not-found'>{I18n('t_no_model')}</div>
    }
    return (
      <>
        <div className={'nzc-chart-title'}>
          <Tooltip className={'portfolio-comm-tooltip long-text-tooltip nzc-model-name-tooltip'} target=".override" />
          <span className={'override'} data-ptf-tooltip={` ${nzcScopeDetails?.scopeName}`} data-pr-position="bottom" data-pr-my="left-287 bottom+52" onMouseEnter={(e)=>isTextOverflown(e)}>{I18n('t_model')}:{` ${nzcScopeDetails?.scopeName}`} </span>
        </div>
        <div className="default-label-container">
          {getDefaultLabel()}
        </div>
      </>
    )
  }

  function isFundTPDataLoading() {
    return (!isErrorFundTP && (isFetchingFundTP || isLoadingFundTargetPathways || createAssetNZCDefaultScopeMutation.isLoading || isIdleFundTP))
  }
  function getNzcActionsOverlayProps() {
    return {
      nzcActionOverlayState,
      setNzcActionOverlayState,
      nzcGraphRef,
      onHide: ()=>actionOverlayHideAction(updateNzcActionOverlayState, nzcGraphRef),
      selectedLanguage,
      assetIntensity: assetIntensityValue?.value
    }
  }

  function onShowExportOverlayPanel() {
    setIsOverlayPanelOpen(true)
  }

  function onHideExportOverlayPanel() {
    setIsOverlayPanelOpen(false)
  }
  //endregion

  //region Render elements
  return (
    <div className="grey-bg min-width-1280 asset-nzc-page">
      <div className="container-layout gutter page-header-body-container">
        <BackNavigation
          exportName={EXPORT_AS_PDF}
          onExportClick={exportActionHandler}
          aria-haspopup
          aria-controls={ARIA_CONTROLS_OVERLAY_PANEL}
          period={period}
          options={periodOptions}
          datePickerOnChange={changeTimePeriodHandler}
          itemTemplate={getPeriodMenuItem}
          pickerValue={pickerValue}
          pickerOnChange={datePickerChangeHandler}
          hideTimePeriodFilter={true}
          ref={navExportRef}
          isOverlayPanelOpen={isOverlayPanelOpen}
        />
        <NzcExportOverlay {...{exportOverlayRef, nzcGraphRef, selectedFund: {fundname: nzcScopeDetails?.scopeName}, docname: nzcScopeDetails?.isDataOverridden ? nzcScopeDetails?.overriddenDocumentName : null, selectedLanguage, appendTarget: navExportRef.current,'dataFrom':'asset-nzc', onShowExportOverlayPanel, onHideExportOverlayPanel, intensity: assetIntensityValue?.value}} />
        <div className="asset-nzc-content">
          <div className={'model-name-models-container'} data-testid="asset-table-nzc-chart-header">
            <div className={'model-name-container'}>
              {scopeDataLoading() ? <NZCPageSingleLineSkeleton/> : getModelNameNode()}
            </div>
            <div className={'model-button-container'}>
              <Button label={I18n('t_models')} data-testid='model-button' className="common-yellow-bg-btn models btn-models button-highlight"
                onClick={(e) => modelsOverlay.current.toggle(e)} aria-haspopup aria-controls="models-button" ref={modelsOverlay} disabled={!nzcScopeDetails}
                icon={(nzcScopeDetails) && (isModelsOverlayPanelOpen ? 'pi pi-angle-up' : 'pi pi-angle-down') } iconPos={'right'}
              />
              <OverlayPanel data-testid='models-overlay' ref={modelsOverlay} id='models-button' style={{ width: '16rem' }} className="models-overlay"
                onShow={() => setIsModelsOverlayPanelOpen(true)} onHide={() => setIsModelsOverlayPanelOpen(false)}>
                {modelsOverlayOptions.map((option, i) => (
                  <React.Fragment key={`overlay-option-${i}`}>
                    {handleModelOverlayOptions(option, i)}
                  </React.Fragment>
                ))}
              </OverlayPanel>
            </div>
          </div>
          <div className="tp-settings-graph-container">
            <div className={'asset-options-col'}>
              <TabView className={'asset-tabview'} activeIndex={API_STATUS.error === status  ? 1 : optionsTabActiveIndex} onTabChange={(e) => onTabChange(e.index)}>
                <TabPanel disabled={!assetNzcGraphData} headerClassName={!assetNzcGraphData ? 'asset-nzc-tab-disabled' : ''} header={getLocalizedValue(selectedLanguage, 't_targets_and_pathways')}>
                  <AssetTPComponent
                    nzcScopeId={defaultScopeId}
                    baselineYear={baselineYear}
                    targetPathwayData={targetPathways}
                    refetchTargetPathwayData={refetchTargetPathways}
                    nzcSettingsData={modelSettings}
                    refetchModelSettings={refetchModelSettings}
                    updateScopeTargetState={updateScopeTargetState}
                    scopeTargetState={scopeTargetState}
                    actionBasedTPDetails={actionBasedTPData}
                    updateTargetsAndPathwayDetails={updateTargetsAndPathwayDetails}
                    graphDataLoading={graphDataLoading()}
                    nzcRedirectPopupProps={{beforeTabChangeActionRef, setOptionsTabActiveIndex, apiError: settingsAndTpApiError(), optionsTabActiveIndex}}
                    assetGraphIntensity={assetIntensityValue?.value}
                    onHideActions={updateNzcActionOverlayState}
                    reportingYear={reportingYear}
                  />
                </TabPanel>
                <TabPanel disabled={!assetNzcGraphData} headerClassName={!assetNzcGraphData ? 'asset-nzc-tab-disabled' : ''} header={getLocalizedValue(selectedLanguage, 't_settings')}>
                  {graphDataLoading() ? <NZCPageSingleLineSkeleton/> :
                    <NzcSettingsForm
                      scopeId={defaultScopeId}
                      refetchData={() => {
                        setAssetNzcGraphData(null)
                        refetchModelSettings()
                        refetchTargetPathways()
                        reftechAssetNZCScopeDetails()
                      }}
                      nzcSettingsData={modelSettings}
                      isModelDataOverridden={!!nzcScopeDetails?.isDataOverridden}
                      graphIntensity={assetIntensityValue?.value}
                      reportingYear={reportingYear}
                    />
                  }
                </TabPanel>
              </TabView>
            </div>
            <div className="graph-col">
              { graphDataLoading() ?
                <div className='chart-skeleton'>
                  {getNZCChartSieraPlusLogo()}
                  <ChartSkeleton/>
                  <div className="nzc-chart-legend-sk">
                    <Skeleton width="48rem" height="1.313rem" className="sk-large legend-sk" />
                    <Skeleton width="29.438rem" height="1.313rem" className="sk-medium legend-sk" />
                  </div>
                </div>
                :
                <AssetNzcGraph propsConfig={getPropsForAssetNzcGraph()} />
              }
            </div>
          </div>
        </div>
        <div className={'nzc-graph-summary'} data-testid="asset-nzc-summary-card">
          <NzcCardContainer className={'net-zero-col'} 
            title={getFundNameWithParent(fundTargetPathways, fundKeyfacts, selectedLanguage)} 
            isTooltipRequired={getFundNameWithParent(fundTargetPathways, fundKeyfacts, selectedLanguage).length > 60}
            isDataLoading={fundKeyfacts?.isNZCStaticModel ? isLoadingStaticFundTargetPathways : isFundTPDataLoading()} 
            isError={fundKeyfacts?.isNZCStaticModel ? isErrorStaticFundTargetPathways : isErrorFundTP} 
            isNetZeroCarbonCard={true}>
            <div role="presentation" className='cursor-pointer' onClick={() => history.replace(createNavURLForFundPages({instanceName, orgName, fundName, pathSuffix: FUND_VIEWS_PAGES.netZeroCarbon}))}>
              {fundKeyfacts?.isNZCStaticModel ?
                <StaticNetZeroCarbon 
                  nzcScopeId={fundDefaultScopeId}
                  selectedLanguage={selectedLanguage}
                  targetPathways={staticFundTargetPathways}
                  isLoading={isLoadingStaticFundTargetPathways}
                  assetLevel={true}
                /> 
                : 
                <NetZeroCarbon
                  nzcScopeId={fundDefaultScopeId}
                  selectedLanguage={selectedLanguage}
                  isLoading={isFundTPDataLoading()}
                  targetPathways={fundTargetPathways}
                  assetLevelFlag
                  financialYearStart={fundKeyfacts?.financialYearStart}
                />
              }
            </div>
          </NzcCardContainer>
          <NzcCardContainer className={'performance-card-container'} title={'Performance'}>
            <PerformanceCard scopeId={defaultScopeId} targetPathways={targetPathways} assetLevelFlag={true} reportingYear={reportingYear}/>
          </NzcCardContainer>
          <NzcCardContainer className={'dataQuality-card-container'} title={'Data quality'}>
            <DataQualityCard scopeId={defaultScopeId}/>
          </NzcCardContainer>
        </div>
        <CommonDialog
          modal={true}
          style={{width: '1080px', height: 'fit-content'}}
          visible={modelPopup}
          onHide={onHideModel}
          header={returnHeader(modelPopup)}
          children={handleModelContainer(modelPopup)}
          className={'nzc-model scrollbar-track-hide'}
        />
        {nzcDialogsState.errorDialogVisible && <ErrorDialog
          data={nzcDialogsState.errorDialogData}
          dialogVisible={nzcDialogsState.errorDialogVisible}
          onHideHandler={errorDialogCloseHandler}
          closeHandler={errorDialogCloseHandler}
        />}
        {nzcDialogsState.showConfirmModal && <PopupDialog
          visible={nzcDialogsState.showConfirmModal}
          header={I18n('t_user_creation_confirmation_popup_title')}
          onHide={confirmDialogCloseHandler}
          footer={confirmDialogFooter}
          message={I18n('t_nzc_file_uploaded_success', {0: nzcDialogsState.uploadFileName})}
        />}
        <NzcActionsOverlay overlayProps={getNzcActionsOverlayProps()} />
      </div>
    </div>
  )
  //endregion
}

export default AssetNetZeroCarbon

AssetNetZeroCarbon.displayName = 'AssetNetZeroCarbon'