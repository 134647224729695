import React from 'react'
import {InputText} from 'primereact/inputtext'
import ClearIcon from '../../../resources/images/icon/ClearIcon.svg'

function SearchField(props) {

  function searchInputHandler(e) {
    props.onChange(e)
  }

  function clearSearch() {
    props.clearField()
  }

  function onSubmit(event){
    event.preventDefault()
  }

  function onSearchFieldKeyDown(event) {
    if(event.code === 'Enter') {
      event.preventDefault()
    }
  }
  return (
    <div className="search-field" data-testid={'search-container'}>
      <span className="p-input-icon-left search-field">
        <i className="pi pi-search cursor search-icon"/>
        {props.searchValue !== '' ?
          <img src={ClearIcon} alt="Clear" className="clear-icon cursor highlight-clickable-opacity"
            onClick={clearSearch} role={'clear-search-field'}/> : null
        }
        <form onSubmit={onSubmit} onKeyDown={(e) => onSearchFieldKeyDown(e)}>
          <InputText ref={props.addRef ? userFilter => userFilter && userFilter.focus({preventScroll: true}) : null} className="search-input-text"
            value={props.searchValue} placeholder={props.placeholder}
            onChange={(e) => searchInputHandler(e)} role={'search-field'} />
        </form>

      </span>
    </div>
  )

}

export default SearchField