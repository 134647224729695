// region - imports
import React, {useCallback, useContext, useRef} from 'react'
import './AssetAddAction.scss'
import {useFormik} from 'formik'
import {useMutation} from 'react-query'
import moment from 'moment'
import PropTypes from 'prop-types'
import {Sidebar} from 'primereact/sidebar'
import I18n from '../../../../../utils/i18n/I18n'
import {
  actionFieldsToExclude,
  addAnActionDefaultSelections,
  areaCoveredList,
  costCurrency,
  costTypeList, editAnActionPrefilledSelections, gresbMeasureListV2, handleUserInputChange,
  impactList, renderActionField, renderSectionInfo,
  statusList, utilityTypeList, validationSchema
} from '../../../../common/action-plan/actionPlanTableTemplate'
import {
  CurrencyUnitToLabel, getLocalStorageItem, handleLengthyName,
  unitsConverter
} from '../../../../../utils/helpers/Helper'
import {
  ActionImpactList,
  costMask,
  removeMandatorySymbol,
  removeYearAndMandatorySymbol, UpdateActions
} from '../../../../../utils/helpers/Constants'
import {calendarDateFormat} from '../../../../../utils/helpers/DateUtils'
import {assetActionListHelper} from '../../../../../services/assets/actionplan-service'
import Icon from '../../../../atomic/Icon/Icon'
import InfoOverlayPanelContent from '../../../../atomic/OverlayPanel/OverlayPanelContent/OverlayPanelContent'
import OverlayPanel from '../../../../atomic/OverlayPanel/OverlayPanel'
import useCustomState from '../../../../common/custom-hooks/useCustomState'
import {Text} from '../../../../atomic'
import Button from '../../../../atomic/Button/Button'
import {ToastContext} from '../../../../../Contexts'
// endregion

const setInitialAddActionState = () => {
  return {
    infoOverlayContent: null,
    scrollDisabled: false
  }
}

const AssetAddAction = (props) => {
  const featureState = props.featureState
  const currencyUnit = props.currencyUnit
  const unitSystem = props.unitSystem
  const selectedLanguage = props.selectedLanguage
  const assetId = props.assetId
  const dateFormatType = calendarDateFormat()
  const assetAvailableUtilityMeters = props.assetAvailableUtilityMeters
  const actionMutateSync = useMutation(assetActionListHelper)
  const editFlag = props.flag
  const actionDetailsData = props.actionDetailsData ? props.actionDetailsData : null
  const currencyLabel = editFlag ? actionDetailsData?.currency : CurrencyUnitToLabel(currencyUnit)
  const actionFormCurrency = featureState?.sieraplus_currency ? CurrencyUnitToLabel(currencyUnit) : currencyLabel
  const {customState: addActionState, updateCustomState: updateAddActionState}=useCustomState(() => setInitialAddActionState())
  const infoOverlayRef = useRef(null)
  const deleteActionRef = useRef(null)
  const { toastDispatcher } = useContext(ToastContext)
  const errorMessage = 'ActionName exists'

  // region : formik
  const formik = useFormik({
    initialValues: editFlag ? editAnActionPrefilledSelections(actionDetailsData, actionFormCurrency, selectedLanguage) : addAnActionDefaultSelections(actionFormCurrency),
    validationSchema: validationSchema,
    onSubmit: async (values, {setFieldError }) => {
      await handleSubmit(values, editFlag, setFieldError)
    }
  })
  // endregion

  // region : helper functions

  const selectedImpact = (acceptImpactValues) => {
    return acceptImpactValues.includes(formik.values.impact)
  }
  const isEnergySelected = selectedImpact(ActionImpactList?.Energy)
  const isWaterSelected = selectedImpact(ActionImpactList?.Water)
  const isWasteSelected = selectedImpact(ActionImpactList?.Waste)

  const getActionUtilitySavings = (formikValues) => {
    if (isEnergySelected) {
      const primaryUtility = {
        utilityType: formikValues.primaryUtility,
        estimatedSavings: Number(formikValues.primaryEstimatedSavingsCost.replace(/,/g, '')),
        utilityCostChange: formikValues.primaryUtilityCost || null,
        isPrimaryUtility: true,
      }

      const secondaryUtility = formikValues.secondaryUtility && {
        utilityType: formikValues.secondaryUtility,
        estimatedSavings: Number(formikValues.secondaryEstimatedSavingsCost.replace(/,/g, '')),
        utilityCostChange: formikValues.secondaryUtilityCost || null,
        isPrimaryUtility: false,
      }

      return secondaryUtility ? [primaryUtility, secondaryUtility] : [primaryUtility]
    } else if (isWaterSelected || isWasteSelected) {
      const utilityType = isWaterSelected ? ActionImpactList?.Water : ActionImpactList?.Waste

      return [{
        utilityType,
        estimatedSavings: formikValues.primaryEstimatedSavingsCost,
        utilityCostChange: formikValues.primaryUtilityCost,
        isPrimaryUtility: true,
      }]
    } else {
      return []
    }
  }


  const handleSubmit = async (formikValues, editFlag, setFieldError) => {
    try{
      const {cost, completion, status, dueDate} = formikValues
      const filteredData = Object.fromEntries(
        Object.entries(formikValues).filter(([key]) => !actionFieldsToExclude.includes(key))
      )
      let completionDate
      if(status === 'Completed') {
        completionDate = completion
      } else if(formik?.initialValues.completion !== formik.values.completion) {
        completionDate = completion
      } else {
        completionDate = null
      }
      const actionData = {
        ...filteredData,
        cost: cost ? parseInt(cost.replace(/,/g, ''), 10) : null,
        actionUtilitySavings: getActionUtilitySavings(formikValues),
        unitOfMeasurement: unitSystem,
        dueDate: dueDate && moment(dueDate).format('YYYY-MM-DDTHH:mm:ss'),
        completion: completionDate && moment(completionDate).format('YYYY-MM-DDTHH:mm:ss')
      }
      if(editFlag) {
        await actionMutateSync.mutateAsync({actionType:'editAction', assetId, actionData, actionId: props.actionId})
      } else {
        await actionMutateSync.mutateAsync({actionType:'addAction', assetId, actionData})
      }
      props.refetchActions()
      props.onHideOrCloseOfAddActionSideBar(UpdateActions.CLOSE)
    } catch(error) {
      if(error?.response?.data === errorMessage) {
        setFieldError('actionDescription', I18n('t_action_name_exists'))
      } else {
        console.error('Error submitting form:', error)
      }
    }
  }

  const deleteAssetAction = async () => {
    try {
      await actionMutateSync.mutateAsync({actionType:'deleteAction', assetId, actionId: props.actionId})
      props.goBackToActionPlan()
      toastDispatcher({
        type: 'ERROR',
        title: 't_action_deleted',
        summary: <Text content='t_action_deleted_content'
          contentDynamic={{0: <i>{handleLengthyName(actionDetailsData.actionDescription, 40)}</i>, 1: getLocalStorageItem('userName')}}
        />
      })
    } catch(error) {
      console.error('Error submitting form:', error)
    }
  }

  const renderActionFormField = useCallback((fieldProps, fieldType) => {
    const field = fieldProps.field
    const isFormFieldInvalid = !!(formik.touched[field] && formik.errors[field])

    const handleFieldChange = (e) => {
      if (fieldType === 'dropdown') {
        let selectedOption = fieldProps.options.find(option => option.value === e.value)
        formik.setFieldValue(field, selectedOption.value)
      } else {
        handleUserInputChange(formik, e, field)
      }
    }

    function utilityMeterNotAvailable(utility) {
      return !(assetAvailableUtilityMeters?.includes(utility))
    }

    function getUtilityDropdownItemDisableOption(option) {
      const secondaryUtilityOption = option.value === formik.values.primaryUtility || utilityMeterNotAvailable(option.value)
      return field === 'secondaryUtility' ? secondaryUtilityOption : utilityMeterNotAvailable(option.value)
    }

    const utilityDropdownTemplate = (option) => {
      const {value, label}=option
      const meterNotAvailable=utilityMeterNotAvailable(value)
      const dropdownItemClasses=meterNotAvailable? 'dropdown-item-1 dropdown-action-utility_unavailable':'dropdown-item-1'
      return (
        <>
          <div className={dropdownItemClasses}>
            <div className="dropdown-item-2 dropdown-item-wrap label">{label}</div>
            {meterNotAvailable && <div className="message">{I18n('t_utility_meter_unavailable')} {label}</div>}
          </div>
          <div className={'dropdown-panel-item-horiz-line'} />
        </>
      )
    }

    const actionFieldProps = {
      fieldProps: fieldProps,
      fieldType: fieldType,
      handleFieldChange: handleFieldChange,
      isFormFieldInvalid: isFormFieldInvalid,
      formik: formik,
      dateFormatType: dateFormatType,
      getDropdownItemTemplateForUtility: utilityDropdownTemplate,
      getUtilityDropdownItemDisableOption: getUtilityDropdownItemDisableOption
    }

    let warningMsg
    if(field === 'performanceMeasure') {
      warningMsg = formik.values?.performanceMeasure === null && I18n('t_gresb_not_mandatory')
    } else {
      warningMsg = <>&nbsp;</>
    }

    return (
      <>
        {renderActionField({...actionFieldProps})}
        {isFormFieldInvalid ? <small className="p-error">{formik.errors[field]}</small> :  <small className="p-warning max-w-17rem">{warningMsg}</small>}
      </>
    )
  },[formik.values,formik.touched,formik.errors])

  const hasChanged = () => {
    const original = formik?.initialValues
    const modified = formik?.values
    for (const key in original) {
      if (original[key] !== modified[key]) {
        return false
      }
    }
    return true
  }

  const handleInfoOverlayPanel = (e, label, infoContent) => {
    infoOverlayRef.current.toggle(e)
    updateAddActionState({infoOverlayContent: {label, infoContent}})
  }

  const handleToggleScroll = () => {
    updateAddActionState({scrollDisabled: !addActionState.scrollDisabled})
  }

  // endregion

  // region : render functions
  const renderInfoPanel = (label, infoContent) => (
    <span onClick={(e) => handleInfoOverlayPanel(e, label, infoContent)} className={'cursor-pointer ml-1'}>
      <Icon size={17} colour={'forest_80'} id={'info_solid'} />
    </span>
  )

  const renderAddActionSideBarHeader = () => { return (<span className="add-action-header-title">{I18n('t_add_an_action')}</span>) }

  const renderActionInformation = () => {

    //action title text field
    const titleInputTextProps = {
      value : formik.values.actionDescription ? formik.values.actionDescription : '',
      maxLength: 100,
      field: 'actionDescription',
      keyfilter:null,
    }

    //manager text field
    const managerInputTextProps = {
      value : formik.values.manager ? formik.values.manager : '',
      maxLength: 100,
      field: 'manager',
      keyfilter: null
    }

    //status dropdown
    const statusDropdownProps = {
      value : formik.values.status ? formik.values.status : null,
      options : statusList,
      placeholder : I18n('t_select_status'),
      field: 'status'
    }
    return (
      <>
        <div className="add-action-container__form">
          {renderSectionInfo(I18n('t_action_information'), '')}
          <div className="add-action-container__form--inputs">
            <span className='dropdown-label'>{I18n('t_action_title')}*</span>
            {renderActionFormField(titleInputTextProps, 'inputtext')}
            <span className='dropdown-label'>{I18n('t_manager')}</span>
            {renderActionFormField(managerInputTextProps, 'inputtext')}
            <span className='dropdown-label'>{I18n('t_status')}*</span>
            {renderActionFormField(statusDropdownProps, 'dropdown')}
          </div>
        </div>
        <hr/>
      </>
    )
  }

  const renderActionDetails = () => {

    // impact dropdown
    const impactDropdownProps = {
      value : formik.values.impact ? formik.values.impact : null,
      options : impactList,
      placeholder : I18n('t_select_impact'),
      field: 'impact'
    }

    // area covered dropdown
    const areaCoveredDropdownProps = {
      value: formik.values.areaCovered ? formik.values.areaCovered : null,
      options: areaCoveredList,
      placeholder: I18n('t_select_area_covered'),
      field: 'areaCovered'
    }

    // gresb dropdown
    const acceptImpactValues = [ActionImpactList?.Energy, ActionImpactList?.Waste, ActionImpactList?.Water, ActionImpactList?.Tenant_Engagement]
    const isImpactSelected = selectedImpact(acceptImpactValues)
    const filteredGresbMeasureList = gresbMeasureListV2.filter(measure => {
      return measure.impactTarget.includes(formik.values.impact)
    })
    const gresbDropdownProps = {
      value : formik.values.performanceMeasure ? formik.values.performanceMeasure : null,
      options : filteredGresbMeasureList,
      placeholder : I18n('t_gresb_efficiency_measure_select'),
      field: 'performanceMeasure'
    }

    // scheduled date
    const scheduleDateProps = {
      value : formik.values.dueDate ? formik.values.dueDate : null,
      field: 'dueDate',
      today: null
    }

    // completed date
    const statusCompleteDate = formik.values.status === 'Completed' ? new Date() : null
    const completionDateProps = {
      value : formik.values.completion ? formik.values.completion : statusCompleteDate,
      field: 'completion',
      today: new Date()
    }

    // renderer
    return (
      <>
        <div className="add-action-container__form">
          {renderSectionInfo(I18n('t_action_details'), I18n('t_action_details_description'))}
          <div className="add-action-container__form--inputs">
            <span className='dropdown-label'>{I18n('t_impact')}* {renderInfoPanel('t_impact', 't_impact_info')}</span>
            {renderActionFormField(impactDropdownProps, 'dropdown')}
            {isImpactSelected &&
            <>
              <span className='dropdown-label'>{I18n('t_gresb_efficiency_measure')} {renderInfoPanel('t_gresb_efficiency_measure', 't_gresb_info')}</span>
              {renderActionFormField(gresbDropdownProps, 'dropdown')}
            </>}
            <span className='dropdown-label'>{I18n('t_action_area_covered')}*</span>
            {renderActionFormField(areaCoveredDropdownProps,'dropdown')}
            <span className='dropdown-label'>{I18n('t_action_scheduled_completion_date')}</span>
            {renderActionFormField(scheduleDateProps, 'calendar')}
            <span className='dropdown-label'>{I18n('t_action_completion_date')}</span>
            {renderActionFormField(completionDateProps, 'calendar')}
          </div>
        </div>
        <hr/>
      </>
    )
  }

  const renderCostInputs = (utilityPerYearLabel, utilityUnits, costPerYearLabel, costUnits, estimatedCostFieldProps, costProps) => {
    return (
      <>
        <div className="add-action-container__form--inputs__cost">
          <Text colour={'faded_teal'} className={'mt-2'} content={utilityPerYearLabel} />
          <div className={'d-flex justify-content-between'}>
            <Text colour={'faded_teal'} content={utilityUnits} />
            {renderInfoPanel('t_utility_consumption_year', I18n('t_utility_consumption_year_info', {info_title: msg => <Text className={'mb-2'} content={msg} />,
              0: <Text content='t_utility_per_year_info_content_one' contentDynamic={{0: utilityUnits?.replace(removeMandatorySymbol, ''), 1: utilityUnits?.replace(removeYearAndMandatorySymbol, '')}} />,
              1: <Text content='t_utility_per_year_info_content_two' contentDynamic={{0: utilityUnits?.replace(removeMandatorySymbol, ''), 1: utilityUnits?.replace(removeYearAndMandatorySymbol, '')}} />}))}
          </div>
          {renderActionFormField(estimatedCostFieldProps, 'inputtext')}
        </div>
        <div className="add-action-container__form--inputs__cost">
          <Text className={'mt-2'} colour={'faded_teal'} content={costPerYearLabel} />
          <div className={'d-flex justify-content-between'}>
            <Text colour={'faded_teal'} content={costUnits} />
            {renderInfoPanel('t_utility_cost_year', I18n('t_utility_cost_year_info', {info_title: msg => <Text className={'mb-2'} content={msg} />,
              0: <Text content='t_cost_per_year_info_content_one' contentDynamic={{0: costUnits?.replace(removeYearAndMandatorySymbol, '')}} />,
              1: <Text content='t_cost_per_year_info_content_two' contentDynamic={{0: costUnits?.replace(removeYearAndMandatorySymbol, '')}} />}))}
          </div>
          {renderActionFormField(costProps, 'inputtext')}
        </div>
      </>
    )
  }

  const renderUtilitySection = () => {
    const utilityChangeUnits = unitsConverter(formik.values.impact, unitSystem, false)
    const utilityCurrencyLabel = costMask[formik.values.currency]
    const filteredUtilityList = utilityTypeList.filter(measure => {
      return measure.impactTarget.includes(formik.values.impact)
    })

    //primary utility dropdown
    const primaryUtilityDropdownProps = {
      value : formik.values.primaryUtility ? formik.values.primaryUtility : null,
      options : filteredUtilityList,
      placeholder : I18n('t_select_primary_utility'),
      field: 'primaryUtility'
    }

    //primary utility estimated cost
    const primaryEstimatedSavingsCost = {
      value : formik.values.primaryEstimatedSavingsCost ? formik.values.primaryEstimatedSavingsCost : '',
      field: 'primaryEstimatedSavingsCost',
      keyfilter:'num'
    }

    //primary utility cost
    const primaryUtilityCost = {
      value : formik.values.primaryUtilityCost ? formik.values.primaryUtilityCost : '',
      field: 'primaryUtilityCost',
      keyfilter:'num'
    }

    //secondary utility dropdown
    const secondaryUtilityDropdownProps = {
      value :formik.values.secondaryUtility ? formik.values.secondaryUtility : null,
      options : filteredUtilityList,
      placeholder : I18n('t_select_secondary_utility'),
      field: 'secondaryUtility'
    }

    //secondary utility estimated cost
    const secondaryEstimatedSavingsCost = {
      value : formik.values.secondaryEstimatedSavingsCost ? formik.values.secondaryEstimatedSavingsCost : '',
      field: 'secondaryEstimatedSavingsCost',
      keyfilter:'num'
    }

    //secondary utility cost
    const secondaryUtilityCost = {
      value : formik.values.secondaryUtilityCost ? formik.values.secondaryUtilityCost : '',
      field: 'secondaryUtilityCost',
      keyfilter:'num'
    }
    if(isWaterSelected || isWasteSelected){
      return (
        <div className="add-action-container__form--inputs flex-row">
          {renderCostInputs('t_utility_per_year', `${utilityChangeUnits}/yr*`, 't_cost_per_year', `${utilityCurrencyLabel}/yr*`, primaryEstimatedSavingsCost, primaryUtilityCost)}
        </div>
      )
    } else if(isEnergySelected) {
      const isSecondaryUtilityEnabled = formik.values?.primaryUtility ? 'pointer-events-auto' : 'pointer-events-none opacity-60'
      return (
        <>
          <div className="add-action-container__form--inputs">
            <span className='dropdown-label'>{I18n('t_primary_utility')}*</span>
            {renderActionFormField(primaryUtilityDropdownProps, 'dropdown')}
          </div>
          <div className="add-action-container__form--inputs flex-row">
            {renderCostInputs('t_utility_per_year', `${utilityChangeUnits}/yr*`, 't_cost_per_year', `${utilityCurrencyLabel}/yr*`, primaryEstimatedSavingsCost, primaryUtilityCost)}
          </div>
          <div className={isSecondaryUtilityEnabled}>
            <div className="add-action-container__form--inputs">
              <span className='dropdown-label'>{I18n('t_secondary_utility')}</span>
              {renderActionFormField(secondaryUtilityDropdownProps, 'dropdown')}
            </div>
            <div className="add-action-container__form--inputs flex-row">
              {renderCostInputs('t_utility_per_year', `${utilityChangeUnits}/yr`, 't_cost_per_year', `${utilityCurrencyLabel}/yr`, secondaryEstimatedSavingsCost, secondaryUtilityCost)}
            </div>
          </div>
        </>
      )
    }
  }

  const renderActionCostAndUtility = () => {

    //cost type dropdown
    const costTypeDropdownProps = {
      value : formik.values.costType ? formik.values.costType : null,
      options : costTypeList,
      placeholder : I18n('t_select_cost_type'),
      field: 'costType',
    }

    // cost text field
    const costInputTextProps = {
      value : formik.values.cost ? formik.values.cost : '',
      field: 'cost',
      keyfilter:'pnum'
    }

    // currency dropdown
    const currencyDropdownProps = {
      value : formik.values.currency ? formik.values.currency : null,
      options : costCurrency,
      placeholder : I18n('t_select_currency'),
      field: 'currency'
    }
    return (
      <>
        <div className="add-action-container__form">
          {renderSectionInfo(I18n('t_associated_costs_utility'), I18n('t_associated_costs_utility_desc'))}
          <div>
            <div className="add-action-container__form--inputs">
              <span className='dropdown-label'>{I18n('t_cost_type')} {renderInfoPanel('t_cost_type', 't_cost_type_info')}</span>
              {renderActionFormField(costTypeDropdownProps, 'dropdown')}
            </div>
            <div className="add-action-container__form--inputs flex-row">
              <div className='add-action-container__form--inputs__cost'>
                <span className='dropdown-label'>{I18n('t_cost_of_action')}</span>
                {renderActionFormField(costInputTextProps, 'inputtext')}
              </div>
              <div className='add-action-container__form--inputs__cost'>
                <span className='dropdown-label'>{I18n('t_currency')}</span>
                {renderActionFormField(currencyDropdownProps, 'dropdown')}
              </div>
            </div>
            {renderUtilitySection()}
          </div>
        </div>
        <hr/>
      </>
    )
  }

  const renderAddActionAndCancelButton = () => {
    return (
      <div className="add-action-container__button-container">
        {props.flag ?
          <>
            <Button disabled={hasChanged()} content={'t_save_exit'} type='submit' onClick={formik.handleSubmit}/>
            <Button outlined={true} colour={'red'} content={'t_delete_action_title'} onClick={(e) => deleteActionRef.current.toggle(e)}/>
            <OverlayPanel
              panelRef={deleteActionRef}
              className={'delete-action-overlay-panel'}
              panelChildren={
                <InfoOverlayPanelContent
                  iconId={'warning_solid_circle'}
                  iconColour={'red'}
                  header={'t_delete_action_title'}
                  panelContent={'t_delete_warning'}
                  buttonFlag={true}
                  onAccept={deleteAssetAction}
                  onReject={() => deleteActionRef?.current?.hide()}
                />}
              onShow={handleToggleScroll}
              onHide={handleToggleScroll}
              showCloseIcon={true}
              dismissable={false}
            />
          </> :
          <>
            <Button content={'t_add_action'} type='submit' onClick={formik.handleSubmit}/>
            <Button outlined={true} content={'t_cancel'} onClick={() => props.onHideOrCloseOfAddActionSideBar(UpdateActions.CLOSE)}/>
          </>}
      </div>
    )
  }
  // endregion

  return (
    <Sidebar pt={{ closeButton: {'data-testid': 'close-button'} }} visible={props.isAddActionSideBarVisible} position="right" closeOnEscape={true} blockScroll showCloseIcon={true}
      appendTo="self" onHide={() => props.onHideOrCloseOfAddActionSideBar(UpdateActions.CLOSE)} className={`asset-add-action-sidebar ${addActionState?.scrollDisabled ? 'add-action-sidebar-block-scroll' : ''}`}
      icons={renderAddActionSideBarHeader} role="complementary" data-testid="asset-add-action-sidebar" dismissable={false}>
      <div className="add-action-container">
        {renderActionInformation()}
        {renderActionDetails()}
        {renderActionCostAndUtility()}
        {renderAddActionAndCancelButton()}
      </div>
      <OverlayPanel
        panelRef={infoOverlayRef}
        className={'pl-unset pr-30'}
        panelChildren={
          <InfoOverlayPanelContent
            iconId={'info_solid'}
            header={addActionState?.infoOverlayContent?.label}
            panelContent={addActionState?.infoOverlayContent?.infoContent}
          />}
        onShow={handleToggleScroll}
        onHide={handleToggleScroll}
      />
    </Sidebar>
  )
}

AssetAddAction.propTypes = {
  isAddActionSideBarVisible : PropTypes.bool,
  onHideOrCloseOfAddActionSideBar: PropTypes.func,
  currencyUnit: PropTypes.string,
  featureState: PropTypes.object,
  unitSystem: PropTypes.string,
  assetAvailableUtilityMeters: PropTypes.array,
  selectedLanguage: PropTypes.string,
  assetId: PropTypes.string,
  refetchActions: PropTypes.func,
  actionId: PropTypes.string,
  flag: PropTypes.string,
  actionDetailsData: PropTypes.object,
  goBackToActionPlan: PropTypes.func,
}
export default React.memo(AssetAddAction)
