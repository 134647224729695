import React from 'react'
import {RadioButton} from 'primereact/radiobutton'
import {  getLocalStorageByKey,  getLocalStorageItem,  refetchUpdatedDataByInvalidatingQueries,  setLocalStorageItem} from '../../../../utils/helpers/Helper'
import {useMutation, useQueryClient} from 'react-query'
import {updateSettings} from '../../../../services/settings-service'
import {LOCALES} from '../../../../utils/i18n/constants'
import { QUERYKEYS_TO_INVALIDATE_ON_CURRENCY_OR_UNIT_CHANGE } from '../../../../utils/helpers/Constants'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'
import PropTypes from 'prop-types'

const Units = (props) => {
  const {loginDispatch, loginState} = useLoginAuthContext()
  const userInfo = loginState.userInfo
  const updateLanguageMutation = useMutation(updateSettings)
  const queryClient = useQueryClient()
  
  async function unitSelectHandler(data) {
    let selectedUnitObj = {
      unitSystem: data.target.value,
      languagePreference: Object.keys(LOCALES).find(key => LOCALES[key] === userInfo.languagePreference),
      currencyUnit: userInfo.currencyUnit,
      connectionStep: userInfo.connectionStep // TODO: Need to modify the PUT api for energy star connection step and this needs to discuss with the team
    }
    setLocalStorageItem('userInfo',{...getLocalStorageByKey('userInfo'),unitSystem: data.target.value})
    try {
      await updateLanguageMutation.mutateAsync({
        userId: getLocalStorageItem('userId'),
        updatedData: selectedUnitObj
      })
      refetchUpdatedDataByInvalidatingQueries(queryClient,QUERYKEYS_TO_INVALIDATE_ON_CURRENCY_OR_UNIT_CHANGE)
    } catch (e) {
      console.log(e.toString())
    }
    loginDispatch({ type: 'UPDATE_USER_INFO', payload: { unitSystem: data.target.value}})
  }

  function constructUnitsTemplate() {
    return props.menudata.map((category) => {
      return (
        <React.Fragment key={category.value}>
          <label className="col-8 menuItem-title" data-testid={category.value}>{category.label}</label>
          <div className="col-1"/>
          <div className="col-3 p-field-radiobutton">
            <RadioButton checked={userInfo.unitSystem === category.value} value={category.value}
              onChange={(e) => unitSelectHandler(e)}/>
          </div>
        </React.Fragment>
      )
    })
  }

  return (
    <div className="grid settings-Language-menuItem">
      {constructUnitsTemplate()}
    </div>
  )
}

Units.propTypes = {
  menudata: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
}

export default Units