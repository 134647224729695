import axiosInstance from '../common/axios-config'

export const GetFundReportSummaries = async (keys) => {
  const fundGroupId = keys?.queryKey[1]
  if(!fundGroupId) {
    return
  }

  const { data } = await axiosInstance.get(`/api/v1/FundReports/${fundGroupId}/PAI/Summaries`)
  return data
}

export const GetFundReportSummary = async (keys) => {
  const fundGroupId = keys?.queryKey[1]
  const year = keys?.queryKey[2]
  if(!fundGroupId || !year) {
    return
  }

  const { data } = await axiosInstance.get(`/api/v1/FundReports/${fundGroupId}/PAI/Summary/${year}`)
  return data
}

export const GetFundReportAssetBreakdown = async (keys) => {
  const fundGroupId = keys?.queryKey[1]
  const year = keys?.queryKey[2]
  const PageSize = keys?.queryKey[3]
  const Skip = keys?.queryKey[4]

  if(!fundGroupId || !year) {
    return
  }

  if(!PageSize && !Skip) {
    const { data } = await axiosInstance.get(`/api/v1/FundReports/${fundGroupId}/PAI/AssetBreakdown/${year}`)
    return data
  }

  const { data } = await axiosInstance.get(`/api/v1/FundReports/${fundGroupId}/PAI/AssetBreakdown/${year}?PageSize=${PageSize}&PageCount=${Skip}`)
  return data
}

export const GetFundReportMissingData = async (keys) => {
  const fundId = keys?.queryKey[1]
  const year = keys?.queryKey[2]
  if(!fundId || !year) {
    return
  }
  const { data } = await axiosInstance.get(`/api/v1/FundReports/${fundId}/PAI/MissingData/${year}`)
  return data
}

export const GetFundReportContent = async (keys) => {
  const fundGroupId = keys?.queryKey[1]
  const year = keys?.queryKey[2]
  if(!fundGroupId || !year) {
    return
  }

  const { data } = await axiosInstance.get(`/api/v1/FundReports/${fundGroupId}/PAI/ReportContent/${year}`)
  return data
}

export const GetFundReports = async (keys) => {
  const fundId = keys?.queryKey[1]
  const year = keys?.queryKey[2]
  if(!fundId|| !year) {
    return
  }
  const { data } = await axiosInstance.get(`/api/v1/FundReports/${fundId}/PAI/ExportReport/${year}`)
  return data
}

export const UpdateFundReportContent = async (eventInfo) => {
  const {data} = await axiosInstance.post(`/api/v1/FundReports/${eventInfo.fundId}/PAI/ReportContent/${eventInfo.year}`, eventInfo.requestData)
  return data
}

export const GetFundReportOptionalMetrics = async (keys) => {
  const fundGroupId = keys?.queryKey[1]
  if(!fundGroupId) {
    return
  }
  const { data } = await axiosInstance.get(`/api/v1/FundReports/${fundGroupId}/PAI/OptionalMetrics`)
  return data
}

export const UpdateFundReportOptionalMetrics = async (paiInfo) => {
  const {data} = await axiosInstance.post(`/api/v1/FundReports/${paiInfo.fundId}/PAI/OptionalMetrics`, paiInfo.requestData)
  return data
}

export const CheckFundReportDataReadiness = async (keys) => {
  const assetId = keys?.queryKey[1]
  const year = keys?.queryKey[2]
  const dataFields = keys?.queryKey[3]
  if(!assetId || !year || !dataFields) {
    return
  }
  const { data } = await axiosInstance.post(`/api/v1/FundReports/${assetId}/PAI/DataReadiness/${year}`, dataFields)
  return data
}