import React, { useContext, useRef, useState } from 'react'
import './VerticalEllipsis.scss'
import { OverlayPanel } from 'primereact/overlaypanel'
import { useHistory } from 'react-router-dom'
import { ELLIPSIS } from '../../../utils/helpers/Constants'
import { FeatureFlagContext } from 'Contexts'
import AddEditAssetSidebar from '../../pages/assets/asset-form/AddEditAssetSidebar'
import { getLocalizedValue } from '../../../utils/helpers/Helper'
import PropTypes from 'prop-types'
import { ButtonWrapper } from 'components/atomic'

//TODO: refactor the component to handle more than two options in a single page
export function VerticalEllipsis({ isAssets, keyId, portfolioParameters, routeVia, flag, bulkUploadKeyValue, actionBulkUploadKeyValue, selectedLanguage }) {
  const featureFlag = useContext(FeatureFlagContext)
  const [isSidebarVisible, setIsSidebarVisible] = useState(false)
  const featureState = featureFlag.featureState
  const userSelectionOverlay = useRef(null)
  const navigate = useHistory()
  const showTheOverlay = (e) => userSelectionOverlay.current.toggle(e)
  const addEditAssetAvailable = featureState.sieraplus_AssetModification && (bulkUploadKeyValue['displayAssetAddButton'] || bulkUploadKeyValue['displayAssetEditButton'])
  const hasOverlayContent = addEditAssetAvailable || bulkUploadKeyValue['displayBulkUploadButton'] || (flag === 'all' && actionBulkUploadKeyValue['displayBulkUploadButton'])

  const consumptionNavigation = () => {
    if (isAssets) {
      navigate.push({
        pathname: '/bulk-upload/assets/upload/consumption-upload',
        state: { portfolioParameters, isAssets }
      })
    } else {
      navigate.push({
        pathname: `/${keyId}/${routeVia.routeVia}/upload/consumption-upload`,
        state: { fileName: routeVia.name, from: navigate.location.pathname }
      })
    }
  }

  const actionPlanNavigation = () => {
    if (isAssets) {
      navigate.push({
        pathname: '/bulk-upload/assets/upload/action-upload',
        state: { portfolioParameters, isAssets }
      })
    }
    else {
      navigate.push({
        pathname: `/${keyId}/${routeVia.routeVia}/upload/action-upload`,
        state: { fileName: routeVia.name, from: navigate.location.pathname }
      })
    }
  }

  const getOverlayPanel = () => {
    switch (flag) {
    case 'action': return actionPlanNavigation()
    case 'consumption':
    case 'all': return consumptionNavigation()
    default: return ''
    }
  }
  const handleClick = () => {
    setIsSidebarVisible(true)
  }

  const addAssetOption = () => {
    // openAddAsset previously used method
    return (
      <div className={'v-ellipsis-home__ellipsis-overlay--upload-consumption--title'}>
        <ButtonWrapper onClick={handleClick} testId='add-asset-tab'>
          {getLocalizedValue(selectedLanguage, (bulkUploadKeyValue['addButtonName']))}
        </ButtonWrapper>
      </div>
    )
  }

  const editAssetOption = () => {
    return (
      <div className="v-ellipsis-home__ellipsis-overlay--upload-consumption--title">
        <ButtonWrapper onClick={() => handleClick()}>
          {getLocalizedValue(selectedLanguage, (bulkUploadKeyValue['editAssetButtonName']))}
        </ButtonWrapper>
      </div>
    )
  }

  const overlayTemplate = () => {
    return (
      <div className={'v-ellipsis-home__ellipsis-overlay--upload-consumption'} data-testid='ellipsis-overlay'>
        {featureState.sieraplus_AssetModification && bulkUploadKeyValue['displayAssetAddButton'] && addAssetOption()}
        {bulkUploadKeyValue['displayBulkUploadButton'] && (
          <div className={'v-ellipsis-home__ellipsis-overlay--upload-consumption--title'}>
            <ButtonWrapper onClick={getOverlayPanel} testId='bulk-add-consumptions'>
              {getLocalizedValue(selectedLanguage, (bulkUploadKeyValue['buttonName']))}
            </ButtonWrapper>
          </div>
        )}
        {featureState.sieraplus_AssetModification && bulkUploadKeyValue['displayAssetEditButton'] && editAssetOption()}
        {flag === 'all' && actionBulkUploadKeyValue['displayBulkUploadButton'] && (
          <div className={'v-ellipsis-home__ellipsis-overlay--upload-consumption--title'}>
            <ButtonWrapper onClick={actionPlanNavigation} testId='bulk-addedit-actions'>
              {getLocalizedValue(selectedLanguage, (actionBulkUploadKeyValue['buttonName']))}
            </ButtonWrapper>
          </div>
        )}
      </div>
    )
  }

  return (<div className={`v-ellipsis-home ${routeVia?.routeVia}`} data-testid={'v-ellipsis-home'}>
    <div onClick={showTheOverlay} className={'v-ellipsis-home__ellipsis-box'} data-testid={'ellipsis-box'}>
      <i className="pi pi-ellipsis-v v-ellipsis-home__ellipsis-box--ellipsis" />
    </div>
    <OverlayPanel pt={{ content: { 'data-testid': 'vertical-ellipsis-content' } }} data-testid="vertical-ellipsis" appendTo={document.getElementById(ELLIPSIS)} ref={userSelectionOverlay} className={'v-ellipsis-home__ellipsis-overlay'}>
      {hasOverlayContent && overlayTemplate()}
    </OverlayPanel>
    {isSidebarVisible && (
      <AddEditAssetSidebar isSidebarVisible={isSidebarVisible} setIsSidebarVisible={setIsSidebarVisible} selectedLanguage={selectedLanguage} isAssets ={isAssets} fundName={routeVia?.name}/>
    )}
  </div>)
}

VerticalEllipsis.propTypes = {
  selectedLanguage: PropTypes.string,
  isAssets: PropTypes.bool,
  portfolioParameters: PropTypes.object,
  routeVia: PropTypes.object,
  flag: PropTypes.string,
  keyId: PropTypes.string,
  bulkUploadKeyValue: PropTypes.object,
  actionBulkUploadKeyValue: PropTypes.object,
}