import React from 'react'
import {Skeleton} from 'primereact/skeleton'
import  '../../../pages/admin/users/users-home/UsersHome.scss'

const SignupSkeleton = () => {


  return (
    <div className="custom-skeleton p-rel flex flex-column align-items-center">
      <Skeleton  width="29.375rem" height="3.75rem" className="mb-4"/>
      <Skeleton  width="29.375rem" height="3.75rem" className="mb-4"/>
      <Skeleton  width="29.375rem" height="3.75rem" className="mb-4"/>
      <Skeleton  width="29.375rem" height="3.75rem" className="mb-4"/>
      <Skeleton  width="29.375rem" height="3.75rem" className="mb-4" />
      <div className="flex align-self-end">
        <Skeleton  width="5.688rem" height="3.25rem" className="mb-4" />
      </div>
    </div>
  )
}

export default SignupSkeleton
