import React, {useEffect, useState} from 'react'
import CloseIcon from '../../../../../resources/images/icon/close-gray.svg'
import {useHistory, useParams} from 'react-router-dom'
import I18n from '../../../../../utils/i18n/I18n'
import GroupDetails from '../group-details/GroupDetails'
import GroupAssets from '../group-assets/GroupAssets'
import GroupUsers from '../group-users/GroupUsers'
import PageContainer from '../../Common/page-container/PageContainer'
import './GroupDetailsContainer.scss'
import GroupsHeader from '../group-add/GroupsHeader'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {addGroup, GetAssetGroupsByGroupId, UpdateGroup} from '../../../../../services/admin/groups-service'
import {noRefetchOnWindowFocus} from '../../../../../services/common/useQuery-config'
import PopupDialog from '../../../../common/modal-dialogs/PopupDialog'
import {clearGroupContent, getLocalStorageByKey, tabNavigatable} from '../../../../../utils/helpers/Helper'
import {groupInfo, groupUserDetails, storeGroupAsset, UserActions} from '../../../../../utils/helpers/Constants'
import {Skeleton} from 'primereact/skeleton'
import ErrorDialog from '../../../assets/data-quality/error-dialog/ErrorDialog'

const GroupDetailsContainer = (props) => {
  let groupAction = props.groupAction
  const navigate  = useHistory()
  const MSG_KEY_TITLE_CANNOT_PUT_DATA = 't_title_cannot_put_data_group'
  const MSG_KEY_TITLE_CANNOT_POST_DATA = 't_title_cannot_post_data_group'
  const [errorDialogVisible, setErrorDialogVisible] = useState(false)
  const [errorDialogData, setErrorDialogData] = useState()
  const [tabMenuOptions, setTabMenuOptions] = useState([])
  const [activeIndexTab, setActiveIndexTab]=useState(props.activeIndex ? props.activeIndex : 0)
  const [visited, setVisited] = useState({0: true, 1: false, 2: false})
  let tabMenuOptionsForAdminUser = [
    {label: I18n('t_details'), value: 0,className:getPointerClass(0)},
    {label: I18n('t_asset_s'), value: 1,className:getPointerClass(1)},
    {label: I18n('t_users'), value: 2,className:getPointerClass(2)},
  ]
  const {groupId} = useParams()
  const queryClient=useQueryClient()
  const [showWarningDialog, setShowWarningDialog] = useState(false)
  const editGroupSave = useMutation(UpdateGroup)

  const addGroupSave = useMutation(addGroup)
  const {data: assetGroupData, isLoading} = useQuery(['assetGroupsEdit', groupId], GetAssetGroupsByGroupId, noRefetchOnWindowFocus)


  useEffect(() => {
    setTabMenuOptions(tabMenuOptionsForAdminUser)
  }, [activeIndexTab])

  useEffect(()=>{
    return ()=> clearGroupContent()
  },[])

  function getPointerClass(tab) {
    let pointerClass = tabNavigatable(tab,visited) ? 'tabmenu-add-group-pointer' : 'tabmenu-add-group-default'
    return (groupAction !== UserActions.EDIT_GROUP) ? pointerClass : 'tabmenu-add-group-pointer'
  }

  function changeActiveTabIndex(newIndex) {
    setActiveIndexTab(newIndex)
  }

  async function saveAndExit() {
    const groupDetails = getLocalStorageByKey(groupInfo)?.groupFormDetails
    const groupAssets = getLocalStorageByKey(storeGroupAsset)?.assetsDetails
    const groupUsers = getLocalStorageByKey(groupUserDetails)?.groupUsers

    function createUserAssetGroupsObject(selectedUsers) {
      return selectedUsers ?
        {'authorizationIds': selectedUsers.map((user) => user.authorisationId || user.authorisationID)}
        :
        {'authorizationIds': []}
    }

    function createAssetGroupObject(selectedAssets) {
      return selectedAssets ?
        {'assetIds': selectedAssets.map((asset) => asset.assetId)}
        :
        {'assetIds': []}
    }

    function createObjectForEditGroup() {
      const getUsers = createUserAssetGroupsObject(groupUsers || assetGroupData.users)
      const getAssets = createAssetGroupObject(groupAssets || assetGroupData.assets)
      const getGroupDetails = groupDetails ? groupDetails : assetGroupData

      return {...getGroupDetails, ...getUsers, ...getAssets}
    }

    function createObjectForAddGroup(){
      const getUsers = createUserAssetGroupsObject(groupUsers || [])
      const getAssets = createAssetGroupObject(groupAssets || [])
      const getGroupDetails = groupDetails ? groupDetails : assetGroupData

      return {...getGroupDetails, ...getUsers, ...getAssets}
    }

    //call the api based on condition
    let createGroupObj 
    let errorMsgKey = MSG_KEY_TITLE_CANNOT_POST_DATA
    try{
      if (groupAction === UserActions.EDIT_GROUP) {
        createGroupObj = createObjectForEditGroup()
        errorMsgKey = MSG_KEY_TITLE_CANNOT_PUT_DATA
        if (groupDetails || groupAssets || groupUsers) {
          await editGroupSave.mutateAsync({groupId: groupId, groupData : createGroupObj})
        }
      } else {
        createGroupObj = createObjectForAddGroup()
        if (groupDetails || groupAssets || groupUsers) {
          await addGroupSave.mutateAsync({groupData : createGroupObj})
        }
      }
      clearAndCloseDialog()
    }
    catch (error){
      const errorResponse = (error.response && error.response.data) ? `Error : ${error.response.data.reasonPhrase ? 
        error.response.data.reasonPhrase : error.response.data.title}` : error.toString()
      setErrorDialogData({title: I18n(errorMsgKey), message:errorResponse})
      setErrorDialogVisible(true)
    }

  }

  function closeAddAsset() {
    if(getLocalStorageByKey(groupInfo) || getLocalStorageByKey(groupUserDetails) || getLocalStorageByKey(storeGroupAsset)){
      setShowWarningDialog(true)
    }else{
      clearAndCloseDialog()
    }
  }

  function updateVisitedTabs(tab){
    tab === 1 && setVisited(prevState => {
      return {...prevState, 1: true}
    })
    tab === 2 && setVisited(prevState => {
      return {...prevState, 2: true}
    })
  }
  function getUserTabsViewContent() {
    let dataForGroupsTabsView={
      showTab: changeActiveTabIndex,
      saveAndExit : saveAndExit,
      groupData : isLoading ? true : assetGroupData,
      groupAction: groupAction,
      visited : updateVisitedTabs
    }

    const groupsTabsViewContents = {
      0: <GroupDetails {...dataForGroupsTabsView}/>,
      1: <GroupAssets {...dataForGroupsTabsView}/>,
      2: <GroupUsers {...dataForGroupsTabsView}/>
    }
    return groupsTabsViewContents[activeIndexTab]
  }

  function getTitle(){
    let title
    if(groupId) {
      title = isLoading ?  <Skeleton width={'4.5rem'} height={'1rem'}/> : assetGroupData?.name
    }else {
      title = I18n('t_add_group')
    }
    return title
  }

  function clearAndCloseDialog() {
    clearGroupContent()
    queryClient.removeQueries('assetGroupsEdit')
    navigate.goBack()
  }

  return (
    <div className={'group-container'}>
      <PageContainer>
        <div className={'add-group-content'}>
          <div className={'g-header-close-icon-section'}>
            <img className="cursor" onClick={closeAddAsset} src={CloseIcon} alt="CloseIcon"/>
          </div>
          <div className={'g-header-tab-menu-section'}>
            <GroupsHeader
              userHeaderConfig={{tabMenuOptions, headerTitle: getTitle()}}
              activeIndex={activeIndexTab}
              groupAction={groupAction}
              setActiveIndex={setActiveIndexTab}
              visited={visited}
            />
          </div>
          <div className={'g-body-section'}>
            {getUserTabsViewContent()}
          </div>
        </div>
      </PageContainer>
      {showWarningDialog && <PopupDialog visible={showWarningDialog} header={I18n('t_warning')}
        onHide={() => setShowWarningDialog(false)} accept={() => clearAndCloseDialog()}
        message={I18n('t_user_warning_message')}/>}
      <ErrorDialog
        data={errorDialogData}
        dialogVisible={errorDialogVisible}
        onHideHandler={()=> {
          setErrorDialogVisible(false)
        }}
        closeHandler={()=> {
          setErrorDialogVisible(false)
        }}
      />
    </div>
  )
}

export default GroupDetailsContainer