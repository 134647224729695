import React from 'react'
import PropTypes from 'prop-types'
import {getLocalizedValue} from '../../../utils/helpers/Helper'
import I18n from '../../../utils/i18n/I18n'
import CommonDialog from '../modal-dialogs/ModalDialogCommon'
import {commonDialogStyle} from '../../pages/funds/data-quality-home/data-quality-hooks-&-helpers/fundDataqualityHelper'
import DataCategoriesInformation from '../../pages/funds/data-quality-home/fund-dq-components/DataCategoriesInformation'
import './DataQualityCTA.scss'

DataQualityCTA.propTypes = {
  imageSrc: PropTypes.string,
  selectedLanguage: PropTypes.string,
  onShow: PropTypes.func,
  onHide: PropTypes.func,
  data: PropTypes.string,
  isVisible: PropTypes.bool
}

function DataQualityCTA(props) {

  return (
    <div className={`data-quality-cta-section ${props.className}`}>
      <div className="data-quality-cta-section__data-quality-cta-content">
        <img src={props.imageSrc} alt={getLocalizedValue(props.selectedLanguage, 't_info')} className="i-icon-size"/>
        <span onClick={props.onShow}>{I18n('t_procurement_tabs_explanation_title')}</span>
      </div>
      <CommonDialog
        modal={true}
        style={commonDialogStyle}
        visible={props.isVisbile}
        onHide={props.onHide}
        header={I18n('t_data_categories')}
        className={'fund-perf-scope-info procurement-data-expl'}
      >
        <DataCategoriesInformation data={props.data}/>
      </CommonDialog>
    </div>
  )
}

export default DataQualityCTA