import axiosInstance from '../common/axios-config'

export const GetActionPlanStatusSummary = async (queryInfo) => {
  const fundGroupId=queryInfo.queryKey[1]
  const impact=queryInfo.queryKey[2].replace(/&/g,'%26')
  if(fundGroupId){
    if(impact === 'All actions') {
      const {data} = await axiosInstance.get(`api/v1/FundGroups/${fundGroupId}/Actions/StatusSummary`)
      return data
    } else {
      const {data} = await axiosInstance.get(`api/v1/FundGroups/${fundGroupId}/Actions/StatusSummary?impact=${impact}`)
      return data
    }
  }
  return null
}

export const GetImpacts = async (queryInfo) => {
  const fundGroupId=queryInfo.queryKey[1]
  if(fundGroupId){
    const {data} = await axiosInstance.get(`api/v1/FundGroups/${fundGroupId}/Actions/Impacts`)
    return data
  }
  return null
}

export const GetFundActions = async (queryInfo) => {
  const fundGroupId = queryInfo.queryKey[1]
  const exportActionsByImpact = queryInfo.queryKey[2] ? queryInfo.queryKey[2] : false

  const {data} = await axiosInstance.get(`/api/v1/FundGroups/${fundGroupId}/Actions/ActionPlanExport?ExportActionsByImpact=${exportActionsByImpact}`)
  return data
}
