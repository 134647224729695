import React from 'react'
import { Skeleton } from 'primereact/skeleton'
import './OverviewChartSkeleton.scss'

const OverviewChartSkeleton = () => {
  return (
    <div className="custom-skeleton p-rel data-quality-card-overview-skeleton">
      <div className="flex justify-content-between">
        <Skeleton width="80%" borderRadius="0" height="0.6rem" className="mb-1"/>
      </div>
      <div className="flex justify-content-between">
        <Skeleton width="2.5rem" borderRadius="0" height="1.5rem" className="mt-1 mb-3"/>
      </div>
      <Skeleton width="100%" borderRadius="0" height="1.25rem" className="mb-2"/>
    </div>
  )
}

export default OverviewChartSkeleton