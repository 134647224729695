import { getLocalStorageItem } from '../../utils/helpers/Helper'
export const retryCount = 0

export const noRefetchOnWindowFocus = {
  refetchOnWindowFocus: false,
  retry: retryCount
  // enabled: false // turned off by default, manual refetch is needed
}

export const manualFetch = {
  refetchOnWindowFocus: false,
  enabled: false, // turned off by default, this enables manual fetch
  retry: retryCount
}

export const fetchAfterLongTime = {
  refetchOnWindowFocus: false,
  retry: retryCount,
  staleTime: 900000,
  enabled: true
}
export const fetchAfterLogin = {
  refetchOnWindowFocus: false,
  retry: retryCount,
  enabled: !!getLocalStorageItem('userLoggedIn'),
}

export const fetchWithRetry = {
  retry: 3,
  refetchInterval:2000,
  retryDelay: 5000
}

/**
 * Optional <br>
 * Defaults to 0 <br>
 * The time in milliseconds after data is considered stale. <br>
 * staleTime is set to 5 minutes to match with the default cacheTime
 */
export const STALE_TIME_DEFAULT=300000
