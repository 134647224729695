import {Text, View} from '@react-pdf/renderer'
import React, {useState} from 'react'
import styles from '../style-sheet/Styles'
import I18n from '../../i18n/I18n'
import {truncateDecimal} from '../../helpers/Helper'

const DataQuality = (props) => {
  const [landlord] = useState(props.landlord[0])
  const [tenant] = useState(props.tenant[0])

  return (
    <View style={[styles.assets, styles.borderExceptBottom, styles.secondRowUtilityLarge,styles.left410]}>
      <Text style={[styles.title,styles.topM17]}>{I18n('t_data_quality')}</Text>
      <View style={styles.dqLandlord}>
        <Text style={[styles.assetTextSeven, styles.blackColor,styles.mTM2]}>{I18n('t_landlord_procured')}</Text>
        <View style={[styles.tableRow]}>
          <View style={styles.dqLeft}>
            <Text style={[styles.assetTextEightBold,styles.mT0]}>
              {landlord && landlord.actual ? truncateDecimal(landlord.actual*100,1)+' %' : '-'}
            </Text>
            <Text style={[styles.assetTextSeven, styles.greyColor,styles.mT2]}>{I18n('t_actual')}</Text>
          </View>
          <View style={styles.dqRight}>
            <Text style={[styles.assetTextEightBold,styles.mT0]}>
              {landlord && landlord.estimated ? truncateDecimal((landlord.estimated)*100,1)+' %' : '-'}
            </Text>
            <Text style={[styles.assetTextSeven, styles.greyColor,styles.mT2]}>{I18n('t_estimated')}</Text>
          </View>
        </View>
        <View style={[styles.tableRow,styles.mT4]}>
          <View style={styles.dqLeft}>
            <Text style={[styles.assetTextEightBold,styles.mT0]}>
              {landlord && landlord.missing ? truncateDecimal(landlord.missing*100,1)+' %' : '-'}
            </Text>
            <Text style={[styles.assetTextSeven, styles.greyColor,styles.mT2]}>{I18n('t_incomplete')}</Text>
          </View>
          <View style={styles.dqRight}>
            <Text style={[styles.assetTextEightBold,styles.mT0]}>
              {landlord && landlord.noCoverage ? truncateDecimal((landlord.noCoverage)*100,1)+' %' : '-'}
            </Text>
            <Text style={[styles.assetTextSeven, styles.greyColor,styles.mT2]}>{I18n('t_not_covered')}</Text>
          </View>
        </View>
      </View>
      <View style={[styles.dqTenant]}>
        <Text style={[styles.dqLabel, styles.blackColor]}>{I18n('t_tenant_procured')}</Text>
        <View style={[styles.tableRow]}>
          <View style={styles.dqLeft}>
            <Text style={[styles.assetTextEightBold,styles.mT0]}>
              {tenant && tenant.actual ? truncateDecimal(tenant.actual*100,1)+' %' : '-'}
            </Text>
            <Text style={[styles.assetTextSeven, styles.greyColor,styles.mT2]}>{I18n('t_actual')}</Text>
          </View>
          <View style={styles.dqRight}>
            <Text style={[styles.assetTextEightBold,styles.mT0]}>
              {tenant && tenant.estimated ? truncateDecimal((tenant.estimated)*100,1)+' %' : '-'}
            </Text>
            <Text style={[styles.assetTextSeven, styles.greyColor,styles.mT2]}>{I18n('t_estimated')}</Text>
          </View>
        </View>
        <View style={[styles.tableRow,styles.mT4]}>
          <View style={styles.dqLeft}>
            <Text style={[styles.assetTextEightBold,styles.mT0]}>
              {tenant && tenant.missing ? truncateDecimal(tenant.missing*100,1)+' %' : '-'}
            </Text>
            <Text style={[styles.assetTextSeven, styles.greyColor,styles.mT2]}>{I18n('t_incomplete')}</Text>
          </View>
          <View style={styles.dqRight}>
            <Text style={[styles.assetTextEightBold,styles.mT0]}>
              {tenant && tenant.noCoverage ? truncateDecimal((tenant.noCoverage)*100,1)+' %' : '-'}
            </Text>
            <Text style={[styles.assetTextSeven, styles.greyColor,styles.mT2]}>{I18n('t_not_covered')}</Text>
          </View>
        </View>
      </View>
    </View>
  )
}

export default DataQuality