import React, {useLayoutEffect, useState, useContext, useImperativeHandle, useRef, useEffect} from 'react'
import { Tooltip } from 'primereact/tooltip'
import { Chart } from 'primereact/chart'
import I18n from '../../../../utils/i18n/I18n'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import CommonDialog from '../../../common/modal-dialogs/ModalDialogCommon'
import ChartOptions from './ChartOptions'
import {closeAssetPanel} from '../../assets/performance/performance-home/ChartHelper'
import './FundNzcGraph.scss'
import {Dropdown} from 'primereact/dropdown'
import { dropdownItemTemplate } from '../../../../utils/helpers/Helper'
import { FeatureFlagContext } from 'Contexts'
import { returnDropdownValue, storeGraphIntensity } from './fund-graph-utils/fund-graph-utils'
import { closeFunction } from '../net-zero-carbon-home/nzc-utils/nzc-utils'
import {nzcIntensityGraphOptions} from '../../../../utils/helpers/Constants'
import {nzcErrorMessageTemplate} from '../../../common/net-zero-carbon/nzc-common-utils'
import PropTypes from 'prop-types'

const FundNzcGraph = React.forwardRef((props, ref) => {

  const [chartOptionsModel, setChartOptionsModel] = useState(false)
  const dropdownRef = useRef(null)
  const graphDropDownValue = returnDropdownValue(props.graphIntensity, nzcIntensityGraphOptions)
  const [requiredGraph, setRequiredGraph] = useState(graphDropDownValue || nzcIntensityGraphOptions[0])
  const featureContext = useContext(FeatureFlagContext)
  const featureState = featureContext.featureState

  useLayoutEffect(() => {
    const body = document.querySelector('body') //to avoid background scroll when pop up is open
    body.style.overflow = chartOptionsModel ? 'hidden' : 'auto'
  }, [chartOptionsModel])

  useEffect(() => {
    props.setFundIntensityValue(requiredGraph?.value)
  }, [requiredGraph])

  function handleChartOptions(value) {
    setChartOptionsModel(value)
    props.removeAssetRowClickValue()
    closeAssetPanel()
  }

  useImperativeHandle(ref, () => ({
    hideGraph: () => dropdownRef?.current?.props?.value
  }))

  const handleChange = (e) => {
    setRequiredGraph(e.target)
    props.clearAssetRowClick()
    closeFunction(document.querySelector('.asset-detail-panel'), 'none')
    storeGraphIntensity(e.target.value, props.fundId)
    const event = new CustomEvent('changeIntensity', {
      detail: e.target,
      bubbles: true,
      cancelable: true,
      composed: false
    })
    document.dispatchEvent(event)
    props?.updateTargetsAndPathwayDetails({showTopDownPathways:false})
  }

  return (
    <div className={'nzc-chart-view'}>
      <div className={'nzc-chart-view__nzc-chart-menu-container'}>
        {featureState?.sieraplus_nzcFundEnergyIntensity ?
          <Dropdown
            name="graphName"
            data-testid="fundgraph-dropdown"
            ref={dropdownRef}
            onChange={(e) => handleChange(e)}
            value={requiredGraph?.value}
            className="sDropdown-1 intensity-dropdown"
            panelClassName="dropdown-overlay-panel-white add-action-header-dropdown-panel" 
            options={nzcIntensityGraphOptions}
            itemTemplate={dropdownItemTemplate}
            onFocus={props.clearAssetRowClick}
          />
          : <label className="nzc-chart-view__nzc-chart-menu-container--carbon-intensity-label" data-testid="carbon-intensity-label" htmlFor="binary">{I18n('t_nzc_carbon_intensity')}</label>}
      </div>
      <div className={'nzc-chart-view__nzc-chart-label-top'}>
        <Tooltip target=".nzc-chart-view__nzc-chart-label-top--overridden" className='nzc-chart-view__nzc-chart-label-top--tooltip-underline' />
        <span data-testid="import-model-name">{I18n(requiredGraph?.value === 'energyIntensity' ? 't_kwh_m2_year' : 't_kg_co2_year')} - {I18n('t_data_source')}: <span className="nzc-chart-view__nzc-chart-label-top--overridden" data-pr-position="bottom" data-pr-tooltip={props.importedFileName}>
          {props.importedFileName ? I18n('t_imported') : I18n('t_siera')}
        </span></span>
        <span data-testid="chart-option" onClick={() => handleChartOptions(true)}
          className={`nzc-chart-view__nzc-chart-label-top--nzc-chart-options-label ${props.nzcGraphData && props.scopeAssets?.length > 0 ? '' : 'disabled'} cursor clickable-highlight`}>{I18n('t_chart_options')}</span>
      </div>
      <div className={'nzc-chart-view__nzc-chart-graph'} id={'nzc-graph'}>
        {props.nzcGraphData && props.scopeAssets?.length > 0 ?
          <Chart {...props.nzcGraphData} plugins={[ChartDataLabels]} ref={props.nzcGraphRef}/>: <div data-testid="error-message" className={'nzc-chart-view__nzc-chart-graph--no-nzc-chart-graph-data'}>{nzcErrorMessageTemplate()}</div>}
      </div>
      <CommonDialog
        modal={true}
        style={{width: '1080px', height: 'fit-content'}}
        visible={chartOptionsModel}
        onHide={() => handleChartOptions(false)}
        header={I18n('t_chart_options')}
        children={
          <ChartOptions
            graphIntensity={props.graphIntensity}
            chartOptions={props.chartOptions}
            setChartOptions={props.setChartOptions}
            chartOptionsModel={chartOptionsModel}
            handleChartOptions={() => handleChartOptions(false)}
            nzcSettingsData={props.nzcSettingsData}
            scopeId={props.scopeId}
            maxValueYAxis={props.maxValueYAxis}
            refetchData={props.refetchData}
            getBenchmarkOptionsData={props.getBenchmarkOptionsData}
          />
        }
        className={'nzc-model'}
      />
    </div>
  )
})
FundNzcGraph.displayName = 'FundNzcGraph'
FundNzcGraph.propTypes = {
  updateTargetsAndPathwayDetails: PropTypes.func,
  clearAssetRowClick : PropTypes.func
}

export default FundNzcGraph