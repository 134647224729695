import React, {useEffect, useMemo, useReducer, useRef, useState} from 'react'
import './AssetActionPlanTable.scss'
import PropTypes from 'prop-types'
import {useQuery} from 'react-query'
import {useDebounce} from 'use-debounce'
import buildQuery from 'odata-query'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Badge} from 'primereact/badge'
import {InputText} from 'primereact/inputtext'
import {Paginator} from 'primereact/paginator'
import I18n from '../../../../../utils/i18n/I18n'
import ActionPlanGridSkeleton from '../../../../common/skeletons/action-plan-grid-skeleton/ActionPlanGridSkeleton'
import {emptyDataMessage, noData} from '../../../../../utils/helpers/MessageUtility'
import {
  createNavURLForFundPages,
  getLocalizedValue,
  onSorting
} from '../../../../../utils/helpers/Helper'
import {assetActionColumns} from '../../../../../utils/helpers/DataTableColumns'
import AssetActionDetailsFilter, {  ACTION_FILTER_TYPES,  filterTypesAndInitialValues} from '../asset-action-details-filter/AssetActionDetailsFilter'
import {actionDataV2} from '../../../../../services/assets/actionplan-service'
import {noRefetchOnWindowFocus} from '../../../../../services/common/useQuery-config'
import {ASSET_PAGES} from '../../../../../utils/helpers/Constants'
import {filterQueryParams} from '../../../../../utils/query-params/QueryParams'
import {
  actionFilterQueryKeyValueMapper,
  getValidFilters,
  currentPageReport
} from '../../../../common/action-plan/actionPlanTableTemplate'
import {Icon, Text} from '../../../../atomic'
import { getSortIcons } from 'utils/helpers/DataTableUtils'

export const actionFilterDropdownSelectionsInitialValue = {
  [ACTION_FILTER_TYPES.STATUS]: null,
  [ACTION_FILTER_TYPES.IMPACT]:  null,
  [ACTION_FILTER_TYPES.COST_TYPE]: null,
  [ACTION_FILTER_TYPES.SCOPE]: null,
  [ACTION_FILTER_TYPES.PROCURED_BY]: null,
}

const setInitialActionTableState = (queryValues) => {
  const searchAction = !queryValues.get('searchAction') ? '' : queryValues.get('searchAction')
  const selectedStatus = !queryValues.get(ACTION_FILTER_TYPES.STATUS) ? queryValues.get(ACTION_FILTER_TYPES.STATUS):
    {label:actionFilterQueryKeyValueMapper[queryValues.get(ACTION_FILTER_TYPES.STATUS)],value:queryValues.get(ACTION_FILTER_TYPES.STATUS)}
  const selectedImpact = !queryValues.get(ACTION_FILTER_TYPES.IMPACT) ? queryValues.get(ACTION_FILTER_TYPES.IMPACT) :
    {label:actionFilterQueryKeyValueMapper[queryValues.get(ACTION_FILTER_TYPES.IMPACT)],value:queryValues.get(ACTION_FILTER_TYPES.IMPACT)}
  const selectedCostType = !queryValues.get(ACTION_FILTER_TYPES.COST_TYPE) ? queryValues.get(ACTION_FILTER_TYPES.COST_TYPE) :
    {label:actionFilterQueryKeyValueMapper[queryValues.get(ACTION_FILTER_TYPES.COST_TYPE)],value:queryValues.get(ACTION_FILTER_TYPES.COST_TYPE)}
  const selectedScope = !queryValues.get(ACTION_FILTER_TYPES.SCOPE) ? queryValues.get(ACTION_FILTER_TYPES.SCOPE) :
    {label:actionFilterQueryKeyValueMapper[queryValues.get(ACTION_FILTER_TYPES.SCOPE)],value:queryValues.get(ACTION_FILTER_TYPES.SCOPE)}
  const selectedProcurement = !queryValues.get(ACTION_FILTER_TYPES.PROCURED_BY) ? queryValues.get(ACTION_FILTER_TYPES.PROCURED_BY) :
    {label:actionFilterQueryKeyValueMapper[queryValues.get(ACTION_FILTER_TYPES.PROCURED_BY)],value:queryValues.get(ACTION_FILTER_TYPES.PROCURED_BY)}


  return {
    findAction: searchAction,
    filterAction: {
      [ACTION_FILTER_TYPES.STATUS]: selectedStatus,
      [ACTION_FILTER_TYPES.IMPACT]: selectedImpact,
      [ACTION_FILTER_TYPES.COST_TYPE]: selectedCostType,
      [ACTION_FILTER_TYPES.SCOPE]: selectedScope,
      [ACTION_FILTER_TYPES.PROCURED_BY]: selectedProcurement,
    },
    first: !queryValues.get('page') ? 0 : parseInt(queryValues.get('page')),
    rows: 10,
    columnSort: {
      sortField: 'lastModified',
      sortOrder: -1,
      oDataSortOrder: 'desc',
    },
  }
}

const reducer = (state, action) => {
  switch(action.type) {
  case 'TABLE_SEARCH': return {...state, findAction: action.findAction}
  case 'TABLE_FIRST_PAGE': return {...state, first: action.first}
  case 'TABLE_ROWS': return {...state, rows: action.rows}
  case 'TABLE_SORTING': return {...state, columnSort: action.columnSort}
  case 'UPDATE_FILTER_SELECTIONS': {
    const selectedFilterOption = filterTypesAndInitialValues(action.filterAction.selectedLanguage)[action.filterAction.selectedFilterCategory].dropdownOptions.filter(option => option.label === action.filterAction.currentSelection)[0]
    return {...state , filterAction: {...state.filterAction, [action.filterAction.selectedFilterCategory]: selectedFilterOption}}
  }
  case 'CLEAR_FILTER_SELECTIONS' : return {...state, filterAction :actionFilterDropdownSelectionsInitialValue}
  case 'ON_HIDE_OR_CLOSE_OF_FILTER': return {...state, filterAction : action.filterAction.defaultSelections}
  }
}

const AssetActionPlanTable = (props) => {
  const queryValues = new URLSearchParams(new URL(window.location.href).search)
  const featureState = props.featureState
  const selectedLanguage = props.selectedLanguage
  const unitSystem = props.unitSystem
  const currencyUnit = props.currencyUnit
  const rowData = props.rowData
  const history = props.history
  const {instanceName, orgName, fundName, assetId} = props.urlParams
  const [state, dispatch] = useReducer(reducer, setInitialActionTableState(queryValues))
  const [isFilterSideBarVisible,setFilterSideBarVisibility] = useState(false)
  const findActionPlaceholder = getLocalizedValue(selectedLanguage, 't_find_action')
  const [debouncedSearchString] = useDebounce(state.findAction, 500)
  const actionFiltersRef = useRef(state.filterAction)
  const sortingColumns = state.columnSort.sortField || state.columnSort.oDataSortOrder ? `${state.columnSort.sortField} ${state.columnSort.oDataSortOrder}` : ''
  const oDataQuery = buildQuery({top: state.rows, skip: state.first, orderBy: sortingColumns})
  const actionQueryKey = ['actionPlan', assetId, debouncedSearchString, actionFiltersRef.current,oDataQuery, currencyUnit, unitSystem]
  const numberOfFiltersApplied = useRef(Object.values(state.filterAction).filter(filter => filter !== null).length)
  const {isLoading : actionPlanTableDataLoading, data : actionPlanTableData} = useQuery(actionQueryKey, actionDataV2, {...noRefetchOnWindowFocus, enabled: !!(assetId)})
  const {data: exportActionPlanTableData} = useQuery(['exportActionPlan', assetId], actionDataV2, {...noRefetchOnWindowFocus, enabled: !!(assetId)})
  const actionsDataLength =  Array.isArray(actionPlanTableData) && actionPlanTableData?.headers['x-total-count'] ? parseInt(actionPlanTableData[0]?.headers['x-total-count']) : actionPlanTableData?.headers && parseInt(actionPlanTableData?.headers['x-total-count'])
  const sortIconToggle = state.columnSort.sortOrder === 1 ? 'sort-up' : 'sort-down'

  // region useEffects
  useEffect(() => {
    const urlQueryParams = {
      searchAction: state.findAction,
      ...getValidFilters(state.filterAction),
      page: state.first
    }

    const actionPlanPage = createNavURLForFundPages({instanceName, orgName, fundName, assetId, pathSuffix: ASSET_PAGES.actionPlanHome})
    if(history.location.pathname === actionPlanPage) {
      filterQueryParams(history, urlQueryParams, rowData)
    }
  }, [state.findAction,isFilterSideBarVisible, state.first])

  useEffect(() => {
    props.updateActionPlanState({assetActionPlanData: exportActionPlanTableData})
  }, [exportActionPlanTableData])
  //endregion

  // region : helper functions

  const onClickOfApplyButtonForFilter = (filterSelections) => {
    numberOfFiltersApplied.current = Object.values(filterSelections).filter(filter => filter !== null).length
    setFilterSideBarVisibility(false)
    actionFiltersRef.current = state.filterAction
  }

  const onFilterSelectionChange = (e, selectedFilterCategory) => {
    dispatch({type: 'UPDATE_FILTER_SELECTIONS', filterAction: {currentSelection:e.value,selectedFilterCategory,selectedLanguage} })
  }

  const onFilterSelectionsClear = () => {
    dispatch({type: 'CLEAR_FILTER_SELECTIONS' , filterAction:null})
  }

  const onHideOrCloseOfFilter = (defaultSelections) => {
    dispatch({type: 'ON_HIDE_OR_CLOSE_OF_FILTER' , filterAction: {defaultSelections}})
    setFilterSideBarVisibility(false)
  }

  const onFindActionKeyDown = (event) => {
    if(event.code === 'Enter') {
      event.preventDefault()
    }
  }

  const onFindActionChange = (event) => {
    dispatch({type: 'TABLE_FIRST_PAGE', first: 0})
    dispatch({type: 'TABLE_SEARCH', findAction: event.target.value})
  }

  const onClearFindAction = () => {
    dispatch({type: 'TABLE_SEARCH', findAction: ''})
  }

  const onPageChange = (event) => {
    dispatch({type: 'TABLE_FIRST_PAGE', first: event.first})
    dispatch({type: 'TABLE_ROWS', rows: event.rows})
  }

  const onSort = (e) => {
    let orderRev = onSorting(e, state.columnSort.sortOrder, state.columnSort.sortField, '')
    let sortOData = orderRev === 1 ? 'asc' : 'desc'
    dispatch({type: 'TABLE_SORTING', columnSort: {
      sortField: e.sortField,
      sortOrder: orderRev,
      oDataSortOrder: sortOData
    }})
  }

  const onActionRowClick = (e) => {
    const actionData = e.data
    const actionId = actionData.actionId
    const actionPlanDetailPage = createNavURLForFundPages({instanceName, orgName, fundName, assetId, pathSuffix: ASSET_PAGES.actionPlanHome, actionId})
    history.push({pathname: actionPlanDetailPage})
  }
  // endregion

  // region : render functions

  const renderAssetActionDetailsFilter = () => {
    return (
      <AssetActionDetailsFilter isFilterVisible={isFilterSideBarVisible}
        onHideOrCloseOfFilter={onHideOrCloseOfFilter}
        handleFiltersApplyButtonClick={onClickOfApplyButtonForFilter}
        actionFilterDropdownSelections={state.filterAction}
        handleFilterSelectionChange={onFilterSelectionChange}
        handleFilterClearButtonClick={onFilterSelectionsClear}/>
    )
  }

  const renderActionHeader = useMemo(() => {
    const dropdownFilterSectionClassName = `actions-header__filter--label cursor ${!actionPlanTableData?.data && queryValues.size === 0 && 'disable-dropdown-filters'}`
    const actionWord = actionsDataLength > 1 ? 't_actions' : 't_action'
    return (
      <div className='actions-header flex justify-content-between'>
        <span role={'actions-count'} className='actions-header__count' >{actionsDataLength} <Text content={actionWord} size='s'/></span>
        <div className='actions-header__filter flex'>
          <div onClick={() => setFilterSideBarVisibility(true)} className={dropdownFilterSectionClassName}>
            <Icon testId={'filter_icon'} id='filter_icon' size='13' />
            <Text content='t_filters' size='xs'/>{numberOfFiltersApplied.current !== 0 && <Badge value={numberOfFiltersApplied.current}/>}
          </div>
          <span role={'find-action-filter'} className="actions-header__filter--input p-input-icon-right">
            <InputText role={'find-action-input'} value={state.findAction} onKeyDown={(e) => onFindActionKeyDown(e)} onChange={onFindActionChange} placeholder={findActionPlaceholder} disabled={!actionPlanTableData?.data && queryValues.size === 0} />
            {state.findAction.length > 0 ? <i role={'clear-icon'} onClick={onClearFindAction} className="pi pi-times cursor-pointer" /> : <i role={'search-icon'} className="pi pi-search" /> }
          </span>
        </div>
      </div>
    )
  },[numberOfFiltersApplied.current,state.findAction,actionsDataLength])

  const renderActionListTable = (actions) => {
    const dataTableParameters = {
      unitSystem: unitSystem,
      selectedLanguage: selectedLanguage,
      currencyUnit: currencyUnit,
      featureState: featureState
    }

    const actionDataTableClassname = actionsDataLength === 0 ? 'asset-action-plan-table__no-data-table' : 'asset-action-plan-table__data-table'

    const headerClassName = (field) => {
      const importantFields = ['dueDate', 'cost', 'lastModified', 'dateCreated']
      if(importantFields.includes(field)) {
        return `table-header-style ${sortIconToggle}`
      }
    }

    const paginatorTemplate = {
      layout: 'PrevPageLink CurrentPageReport NextPageLink',
      CurrentPageReport: currentPageReport
    }

    return (
      <>
        <DataTable
          value={actions}
          emptyMessage={state.findAction || numberOfFiltersApplied.current !==0 ? emptyDataMessage(): noData()}
          className={actionDataTableClassname}
          totalRecords={actionsDataLength}
          sortField={state.columnSort.sortField}
          sortOrder={state.columnSort.sortOrder}
          onSort={onSort}
          onRowClick={onActionRowClick}
          scrollable
          sortIcon={getSortIcons()}
        >
          {assetActionColumns(selectedLanguage).map(action => <Column
            key={action.key}
            field={action.field}
            header={I18n(action.header)}
            body={(rowData) => action.bodyTemplate(rowData, dataTableParameters)}
            style={action.style}
            sortable={action.sortable}
            headerClassName={headerClassName(action.field)}
          />)}
        </DataTable>
        {/*using external paginator because in the dataTable paginator template total records count were not getting based on criteria after adding top in to query parameters*/}
        {actionsDataLength>state.rows &&
          <Paginator first={state.first} className={'data-table-paginator '} rows={state.rows} totalRecords={actionsDataLength} onPageChange={onPageChange} template={paginatorTemplate} />
        }
      </>
    )
  }

  const renderAssetListLoadingTable = () => {
    return <div className="action-plan-grid-before-load">
      <ActionPlanGridSkeleton/>
    </div>
  }

  // endregion

  return (
    <div className='asset-action-plan-table'>
      {renderActionHeader}
      {actionPlanTableDataLoading ? renderAssetListLoadingTable() : renderActionListTable(actionPlanTableData?.data)}
      {isFilterSideBarVisible && renderAssetActionDetailsFilter()}
    </div>
  )
}

AssetActionPlanTable.propTypes = {
  featureState: PropTypes.object,
  selectedLanguage: PropTypes.string,
  unitSystem: PropTypes.string,
  currencyUnit: PropTypes.string,
  urlParams: PropTypes.object,
  rowData: PropTypes.object,
  history: PropTypes.object,
  updateActionPlanState: PropTypes.func,
}

export default React.memo(AssetActionPlanTable)