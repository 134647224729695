import { Skeleton } from 'primereact/skeleton'
import React from 'react'
import {Tooltip} from 'primereact/tooltip'
import classnames from 'classnames'
import {nzcErrorMessageTemplate} from '../net-zero-carbon/nzc-common-utils'

const NzcCardContainer = (props) => {
  const getChildren = () => {
    return props.isNetZeroCarbonCard && props.isError ? nzcErrorMessageTemplate() : props.children
  }
  return (
    <div className={`nzc-card-container ${props.className}`}>
      {props.isDataLoading ? (<div className='loader-skeleton'><Skeleton width='15rem' /></div>) :
        <>
          <Tooltip target=".override-card-Name" />
          <span className={classnames('summary-heading large-text-bold-2 nzc-card-title', props.isTooltipRequired ? 'override-card-Name' : '')} data-pr-position="bottom" data-pr-tooltip={props.title}>{props.title}</span>
        </>
      }
      <div className={'nzc-card-content'}>
        {getChildren()}
      </div>
    </div>
  )
}

export default NzcCardContainer


