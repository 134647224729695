import React, {useEffect, useState} from 'react'
import I18n from '../../../../../../utils/i18n/I18n'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import '../user-funds/UserFunds.scss'
import SearchField from '../../../../../common/search/SearchField'
import UserFooter from '../user-footer/UserFooter'
import {ADMIN_PAGES, UserActions, userAssetDetails, userFundDetails, userGroupDetails, userInfo}
  from '../../../../../../utils/helpers/Constants'
import {
  createAssetsIdsObject,
  createNavURLForUserPages,
  createUserAssetGroupsObject, getInstance,
  getLocalizedValue,
  getLocalStorageByKey,
  getStructuredFund,
  messageCheck, onSelection, setLocalStorageItem
} from '../../../../../../utils/helpers/Helper'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {noRefetchOnWindowFocus} from '../../../../../../services/common/useQuery-config'
import UserFundSkeleton from '../../../../../common/skeletons/users-grid-skeleton/UserFundSkeleton'
import {getSelectAllIconNode, getSelectAllIconNodeContainer, setSelectAllIcon}
  from '../../../../../../utils/helpers/DataTableUtils'
import {getAllUserFundGroups, AddUser, UpdateUser, addAssetGroups, updateAssetGroups,
  AddAssetAccess, UpdateAssetAccess, GetGroupsListById, addRemoveFundsFromUser}
  from '../../../../../../services/admin/users-service'
import ErrorDialog from '../../../../assets/data-quality/error-dialog/ErrorDialog'
import {useHistory} from 'react-router-dom/cjs/react-router-dom'
import {useParams} from 'react-router-dom'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

function UserFunds(props) {
  const [userFunds, setUserFunds] = useState([])
  const [searchText, setSearchText] = useState('')
  const [selectedFunds, setSelectedFunds] = useState([])
  const [filteredHiddenSelectedFunds,setFilteredHiddenSelectedFunds]=useState([])
  const [filteredSelectedFunds,setFilteredSelectedFunds]=useState([])
  const {data : userFundsData , isLoading} = useQuery('userFunds', getAllUserFundGroups,noRefetchOnWindowFocus)
  const {loginState} = useLoginAuthContext()
  const selectedLanguage = loginState.userInfo.languagePreference
  const emptyStr=''
  const [unSavedData,setUnSavedData] = useState(false)
  const [totalActiveClass, setTotalActiveClass] = useState(emptyStr)
  const [selectedActiveClass, setSelectedActiveClass] = useState('op-5')
  const [activeIndex,setActiveIndex] =useState(0)
  const addRemoveFundUserMutation = useMutation(addRemoveFundsFromUser)
  const selectAllIconSelectors='.user-funds-container .user-funds-grid .p-datatable-thead div.p-checkbox-box span.p-checkbox-icon'
  const isEditUserAction = () => props.userAction === UserActions.EDIT_USER
  const [errorDialogVisible, setErrorDialogVisible] = useState(false)
  const [errorDialogData, setErrorDialogData] = useState()
  const addUserMutation = useMutation(AddUser)
  const updateUserMutation = useMutation(UpdateUser)
  const addUserAssetGroupsMutation = useMutation(addAssetGroups)
  const updateUserAssetGroupsMutation = useMutation(updateAssetGroups)
  const addAssetAccessMutation = useMutation(AddAssetAccess)
  const updateAssetAccessMutation = useMutation(UpdateAssetAccess)
  const users='users'

  const MSG_KEY_TITLE_CANNOT_POST_DATA = 't_title_cannot_post_data'
  const MSG_KEY_TITLE_CANNOT_PUT_DATA = 't_title_cannot_put_data'
  const pathToUsersPage=createNavURLForUserPages({instanceName: getInstance(),pathSuffix: ADMIN_PAGES.users })
  const history = useHistory()
  const queryClient=useQueryClient()
  const urlParams = useParams()
  const userId=isEditUserAction() ? urlParams.userId : null
  const {data: userSelectedFundData}  = useQuery(['userAssetGroups',userId], GetGroupsListById, noRefetchOnWindowFocus)

  useEffect(()=>{
    props.visited && props.visited(1)
  },[])
  useEffect(()=>{
    if(userFundsData){
      let selectedFunds=[]
      if(userSelectedFundData && isEditUserAction()){
        userFundsData.map((individualFunds)=>{
          userSelectedFundData.funds.map(selectedFund=>{
            if(selectedFund.fundGroupID === individualFunds.assetGroupID){
              selectedFunds.push(individualFunds)
            }
          })
        })
      }
      const userFundDetailsObject=getLocalStorageByKey(userFundDetails)
      setSelectedFunds(userFundDetailsObject && userFundDetailsObject.fundDetails ? userFundDetailsObject.fundDetails : selectedFunds)
      setUserFunds(userFundsData)
    }
  },[userFundsData,userSelectedFundData])

  useEffect(() => {
    const selectAllIconNode = getSelectAllIconNode(selectAllIconSelectors)
    if(unSavedData){
      saveAssetIdsOfSelectedFunds(selectedFunds)
    }
    if (searchText === '') {
      setSelectAllIcon(selectedFunds, userFunds, selectAllIconNode)
    } else {
      setSelectAllIcon(filteredSelectedFunds, userFunds, selectAllIconNode)
    }
  }, [selectedFunds, filteredSelectedFunds, userFunds])

  function clearSearch() {
    setSearchText('')
    activeIndex === 0 ? setUserFunds(userFundsData) : setUserFunds(selectedFunds)
  }

  function onFundsSelectionChange(e) {
    let newSelectedFunds = e.value
    props.unSavedData()
    setUnSavedData(true)
    const thNode = getSelectAllIconNodeContainer(e)
    onSelection(users,newSelectedFunds, searchText, thNode, setSelectedFunds, setFilteredSelectedFunds, filteredHiddenSelectedFunds)
    if (activeIndex === 1) {
      setUserFunds(newSelectedFunds)
    }
  }

  function saveAssetIdsOfSelectedFunds(selectedFunds){
    let fundsAssetIds=[]
    if(selectedFunds){
      selectedFunds.forEach(fund=>{
        if(fund.assetCount>0){
          fundsAssetIds=[...fundsAssetIds, ...fund.assetIds]
        }
      })
      const fundDetails={isFundsNavigatable: true, fundDetails: selectedFunds, assetIdsOfSelectedFunds: fundsAssetIds}
      setLocalStorageItem(userFundDetails, fundDetails)
    }
  }

  function searchFunds(e) {
    let searchText = e.target.value
    setSearchText(searchText)
    let searchContainer = activeIndex === 0 ? userFundsData : selectedFunds
    let searchLC = searchText.toLowerCase()
    let filteredList = searchContainer.filter((key) => {
      if ((key.name?.toLowerCase().includes(searchLC)) ||
                (key.assetCount?.toString().toLowerCase().includes(searchLC)) ||
                (key.memberCount?.toString().toLowerCase().includes(searchLC))) {
        return true
      }
    })

    setFilteredHiddenSelectedFunds(() => {
      return selectedFunds.filter((selectedFund) => !filteredList.find(filteredFund => (selectedFund.assetGroupID === filteredFund.assetGroupID)))
    })
    setFilteredSelectedFunds(() => {
      return selectedFunds.filter((selectedFund) => filteredList.find(filteredFund => (selectedFund.assetGroupID === filteredFund.assetGroupID)))
    })
    setUserFunds(filteredList)
  }
  function getChooseFundsLabel() {
    let chooseAssetsLabel=I18n('t_choose_funds_for_user')
    if(isEditUserAction()){
      let userName=''
      if(props.userDetails && props.userDetails.userName){
        userName=props.userDetails.userName
      }
      chooseAssetsLabel=I18n('t_choose_funds_for_update_user', {0: userName})
    }
    return chooseAssetsLabel
  }

  function NoDataFound({message}) {
    return <div className="no-result-msg">
      <span className="no-result-text">{message}</span>
    </div>
  }

  function allFundsHandler() {
    setActiveIndex(0)
    setTotalActiveClass(emptyStr)
    setSelectedActiveClass('op-5')
    setUserFunds(userFundsData)
    setSearchText('')
  }

  function selectedFundsHandler() {
    setActiveIndex(1)
    setTotalActiveClass('op-5')
    setSelectedActiveClass(emptyStr)
    setUserFunds(selectedFunds)
    setSearchText('')
  }

  async function getUserAuthId() {
    const userDetails = (isEditUserAction() ? props.userDetails : await postNewUserData(getLocalStorageByKey(userInfo)))
    return (userDetails && userDetails ? userDetails : null)
  }

  const postNewUserData = async (userData) => {
    let errorMsg=MSG_KEY_TITLE_CANNOT_POST_DATA
    let newUser = await addUserMutation.mutateAsync(userData.userDetails)
    if (newUser.hasOwnProperty('userId')) {
      props.updateNewUser(newUser)
    } else {
      setErrorDialogData({title: I18n(errorMsg), message: `${messageCheck(newUser)}`})
      setErrorDialogVisible(true)
    }
    return newUser
  }

  async function addRemoveUserFunds() {
    let errorMsgKey = MSG_KEY_TITLE_CANNOT_POST_DATA
    let userInfo= await getUserAuthId()
    if(userInfo.hasOwnProperty('userId')){
      try {
        if (isEditUserAction()) {
          errorMsgKey = MSG_KEY_TITLE_CANNOT_PUT_DATA
          const structuredFundObject =getStructuredFund(selectedFunds)
          await handleEditUser(structuredFundObject,userInfo)
          history.goBack()
        }else{
          await handleNewUser(userInfo)
          history.push({pathname: pathToUsersPage, state: {...userInfo, isAddUser: true}})
        }
        await props.refetchUser()
      } catch (error) {
        const errorResponse = (error.response && error.response.data) ? `Error: ${error.response.data.title}` : error.toString()
        setErrorDialogData({title: I18n(errorMsgKey), message:errorResponse})
        setErrorDialogVisible(true)
      }
    }
    //Add funds to user
  }

  async function handleNewUser(newUser) {
    //Add funds adding api
    //Saves Groups data
    if (getLocalStorageByKey(userGroupDetails) && getLocalStorageByKey(userGroupDetails).assetGroups?.length > 0) {
      await postGroupsData(newUser)
    }
    //Saves Assets data
    if (getLocalStorageByKey(userAssetDetails) && getLocalStorageByKey(userAssetDetails).assetsDetails?.length > 0) {
      await postAssetsData(newUser)
    }
  }

  async function handleEditUser(fundObject,values) {
    //handle funds edit and delete
    queryClient.removeQueries('fundGroupData')
    await addRemoveFundUserMutation.mutateAsync({funds:fundObject,authorisationId:values.authorisationId})
    //Saves Details data
    if (getLocalStorageByKey(userInfo)) {
      const userData = getLocalStorageByKey(userInfo).userDetails
      await updateUserMutation.mutateAsync({userId: values.userId, userData: userData})
    }
    //Saves Assets data
    if (getLocalStorageByKey(userAssetDetails)) {
      await postAssetsData(values)
    }
    //Saves Groups data
    if (getLocalStorageByKey(userGroupDetails)) {
      await postGroupsData(values)
    }
  }

  async function postGroupsData(userData) {
    let assetGroupIdsObject = createUserAssetGroupsObject(getLocalStorageByKey(userGroupDetails).assetGroups)
    let userAuthIdForGroups = userData.userId
    const userAssetGroupsObject = {userId: userAuthIdForGroups, assetGroups: assetGroupIdsObject}
    if(isEditUserAction()){
      await updateUserAssetGroupsMutation.mutateAsync(userAssetGroupsObject)
    } else {
      if(assetGroupIdsObject.assetGroupIds.length > 0)
      {
        await addUserAssetGroupsMutation.mutateAsync(userAssetGroupsObject)
      }
    }
  }

  async function postAssetsData(userData) {
    let userAssetDetailsObj = getLocalStorageByKey(userAssetDetails)
    if(userAssetDetailsObj && userAssetDetailsObj.isUnchanged) {
      return
    }
    let assetIdsObject = createAssetsIdsObject(userAssetDetailsObj.assetsDetails)
    let userAuthIdForAssets = userData.authorisationId
    const userAssetIdsObject = {userAuthId: userAuthIdForAssets, assetIds: assetIdsObject}
    if(isEditUserAction()){
      await updateAssetAccessMutation.mutateAsync(userAssetIdsObject)
    } else {
      await addAssetAccessMutation.mutateAsync(userAssetIdsObject)
    }
  }


  return (
    <div className={'user-funds-container'} data-testid={'users-funds'}>
      <div className="flex justify-content-between funds-header-section">
        <div className={'title-description'}>{getChooseFundsLabel()}</div>
        <div className="count-selection-container">
          <div className={`total-count count-status ${totalActiveClass}`} onClick={allFundsHandler}>
            {userFundsData ? userFundsData.length : 0} {I18n('t_funds')}
          </div>
          <div className={`selected-count count-status ${selectedActiveClass}`} onClick={selectedFundsHandler}>
            {selectedFunds.length}
          </div>
          <div className={`selected-label count-status ${selectedActiveClass}`} onClick={selectedFundsHandler}>
            {I18n('t_selected')}
          </div>
        </div>
      </div>
      <div className="flex">
        <div className={'search-field-mt'}>
          <SearchField placeholder={getLocalizedValue(selectedLanguage, 't_find_fund')} searchValue={searchText}
            onChange={searchFunds} clearField={clearSearch} addRef={true}/>
        </div>
      </div>
      <div className={'user-funds-grid-container'}>
        {!isLoading ?
          <div>
            {userFunds.length > 0 ?
              <DataTable value={userFunds} className={'user-funds-grid'}
                selectionMode="single" onSelectionChange={(e) => onFundsSelectionChange(e)}
                tableClassName={props.hasPermissionForEditUser ? null : 'disable-section'}
                selection={searchText === ''?selectedFunds:filteredSelectedFunds} stripedRows scrollHeight='850px' scrollable>
                <Column field="name" header={I18n('t_name')}
                  headerClassName="fund-grid-header name-column" bodyClassName="name-column"/>
                <Column field="assetCount" header={I18n('t_asset_s')}
                  headerClassName="fund-grid-header assets-column" bodyClassName="assets-column"/>
                <Column field="memberCount" header={I18n('t_users')}
                  headerClassName="fund-grid-header members-column" bodyClassName="members-column"/>
                <Column selectionMode="multiple" headerClassName="selection-asset"
                  style={{width: '4.5%'}}/>
              </DataTable> :
              <NoDataFound message={I18n('t_funds_none')}/>
            }
          </div> :
          <div className="users-grid-before-load">
            <UserFundSkeleton/>
          </div>
        }
      </div>
      <div className={'footer-divider'}>
        <hr/>
      </div>
      <div className={'footer-container'}>
        <UserFooter submitHandler={addRemoveUserFunds} handleNextTabBtnText="t_next_choose_groups"
          handleNextTab={() => props.showTab(2)} userAction={props.userAction}
          hasPermissionForEditUser={props.hasPermissionForEditUser}
        />
      </div>
      {errorDialogVisible && <ErrorDialog data={errorDialogData} dialogVisible={errorDialogVisible}
        onHideHandler={()=>setErrorDialogVisible(false)} closeHandler={()=>setErrorDialogVisible(false)}
      />}
    </div>
  )
}

export default UserFunds