import {useContext} from 'react'
import {FeatureFlagContext} from 'Contexts'
import {getLocalizedValue, getPerformanceUtilitiesList,} from '../../../../../utils/helpers/Helper'
import {GAP_FILLING, UTILITY_LABELS_OBJECT_TITLE_CASE} from '../../../../../utils/helpers/Constants'
import {getMultiSelectOptions} from '../fund-performance-hooks-&-helpers/fundPerformanceHelper'
import './FundPerformanceFilterHeader.scss'
import buildQuery from 'odata-query'
import MultiSelectDropdown from '../../../../common/multi-select/MultiSelectDropdown'
import {Dropdown} from 'primereact/dropdown'
import PropTypes from 'prop-types'
import ScopesInformation from '../../../../common/performance/ScopesInformation'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const FundPerformanceFilterHeader = (props) => {

  // CONTEXTS
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const {featureState} = useContext(FeatureFlagContext)

  const fundUtilitiesList = getPerformanceUtilitiesList(selectedLanguage)
  const multiSelectDDLData = getMultiSelectOptions(props.fundPerformanceState?.selectedUtility.name, selectedLanguage, featureState)

  const onUtilityChange = (e) => {
    let defaultSortField
    if (e.value === UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE) {
      defaultSortField = 'waste/total'
    } else {
      defaultSortField = `${e.value.toLowerCase()}/consumption`
    }
    const odataQueryString= buildQuery({orderBy: `${defaultSortField} desc`})
    props.updateFundPerformanceState({selectedUtility: {name: e.value}, oDataSortOrder: 'desc', sortField: defaultSortField, odataQueryString})
    props.updateDataSelectionFilters([]) // resetting
    props.updateFundPerformanceState({selectedBreakdown: []}) // resetting
  }

  const onChangeMultiSelectHandler = (selectedOptions) => {
    const dataSelectionFilters=selectedOptions.filter(option=>GAP_FILLING!==option.key)
    props.updateDataSelectionFilters(dataSelectionFilters)
    props.updateFundPerformanceState({selectedBreakdown: selectedOptions})
  }

  function disableOptionItem(option) {
    const fundPerformancesData = props.getFundPerformancesData()
    if(option.key === GAP_FILLING) {
      return !fundPerformancesData
    }
    let disableOptionItemFlag
    if(fundPerformancesData){
      disableOptionItemFlag = !(fundPerformancesData?.some(datum => {
        const selectedDatum = datum[option.key]
        return (selectedDatum?.actualBelowTarget > 0 || selectedDatum?.actualAboveTarget > 0 || selectedDatum?.estimatedBelowTarget > 0 || selectedDatum?.estimatedAboveTarget > 0)
      }))
    }
    return (!fundPerformancesData || !!disableOptionItemFlag)
  }
  
  return (
    <div className="filterSection">
      <Dropdown 
        appendTo="self"
        panelClassName="utility-dropdown-panel"
        options={fundUtilitiesList}
        value={props.fundPerformanceState?.selectedUtility.name}
        placeholder={getLocalizedValue(selectedLanguage, 't_all_actions')}
        className={'filterSection__dropdown filterSection__dropdown--utility'}
        onChange={(e) => {
          onUtilityChange(e)
        }}
        id={'fund-dropdown'}
        data-testid={'utility-dropdown'}
      />
      {multiSelectDDLData?.showMultiDropdown &&
          <MultiSelectDropdown
            dataInfo={multiSelectDDLData}
            onChangeHandler={onChangeMultiSelectHandler}
            multiSelectedOptions={props.fundPerformanceState?.selectedBreakdown}
            disableOptionItemHandler={disableOptionItem}
            className={'filterSection__dropdown filterSection__dropdown--carbonScopes'}
          />
      }
      <ScopesInformation performanceState={props.fundPerformanceState} className={'filterSection__scopesInfo scopesInfo-fundPerformance'}/>
    </div>
  )
}

FundPerformanceFilterHeader.propTypes = {
  updateFundPerformanceState: PropTypes.func,
  fundPerformanceState: PropTypes.object,
  getFundPerformancesData: PropTypes.func,
  updateDataSelectionFilters: PropTypes.func,
}

export default FundPerformanceFilterHeader
FundPerformanceFilterHeader.displayName = 'FundPerformanceFilterHeader'