import PropTypes from 'prop-types'
import {Page, View, Document, Text, Image} from '@react-pdf/renderer'
import React from 'react'
import PdfLogo from '../../../../../utils/data-exports/fixed-components/logo/PdfLogo'
import PdfPagination from '../../../../../utils/data-exports/fixed-components/footer/PdfPagination'
import PdfHeader from '../../../../../utils/data-exports/fixed-components/header/PdfHeader'
import styles from '../../../../../utils/data-exports/style-sheet/Styles'
import TablePdf from './TablePdf'
import I18n from '../../../../../utils/i18n/I18n'
import {I18nProvider} from '../../../../../utils/i18n'
import {
  CurrencyUnitToLabel,
  truncateDecimal,
  unitsConverter
} from '../../../../../utils/helpers/Helper'
import {costMask, eventDuration} from '../../../../../utils/helpers/Constants'
import missingDataIcon from '../../../../../resources/images/ExportPdf/DataMissingIcon.png'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const ChartPdf = (props) => {
  const language=props.language
  const {loginState: {userInfo}} = useLoginAuthContext()
  const unitSystem = userInfo.unitSystem
  const currencyUnit = userInfo.currencyUnit

  const overPercent = (data) => {
    let value = formatUnits(Math.abs(data))
    return value +'%'
  }

  const overTarget = (data, utility) => {
    let value = formatUnits(Math.abs(data))
    return value + ' ' + unitsConverter(utility,unitSystem)
  }

  const estSignDecider = (data) => {
    return formatUnits(Math.abs(data))
  }

  const formatUnits = (value) => {
    if (value != null) {
      return value.toLocaleString(language)
    }
    return value
  }

  const utilityName = (value) => {
    let name
    if(value==='Carbon'){
      name=I18n('t_carbon')
    }else if(value==='Electricity'){
      name=I18n('t_electricity')
    }else if(value==='Water'){
      name=I18n('t_water')
    }else if(value==='GasFuelsAndThermals'){
      name=I18n('t_gas_fuel_thermal')
    }else{
      name=I18n('t_waste_recycling')
    }
    return name
  }

  const performanceChartImage = (props) => (
    <View style={[styles.planBorder, styles.perfGraph]} wrap={false}>
      <View style={[styles.tableRow,styles.mT11]}>
        <View style={[styles.performanceLegend, styles.left13]}>
          <Text style={[styles.assetTextEightRegular]}>{props.utility.name !== 'Waste' ?
            overPercent(truncateDecimal(props.overview[0].value,1)) :
            overPercent(truncateDecimal(props.overview[0].value,2))}
          </Text>
          <Text style={[styles.assetTextSeven, styles.greyColor,styles.mT4, {}]}>
            {props.overview[0].title.length > 0 ? I18n(props.overview[0].title) : props.overview[0].title}
          </Text>
        </View>

        <View style={[styles.performanceLegend, styles.left20]}>
          <Text style={[styles.assetTextEightRegular]}>{props.utility.name !== 'Waste' ?
            overTarget(truncateDecimal(props.overview[1].value,1),props.utility.name) :
            overTarget(truncateDecimal(props.overview[1].value,2),props.utility.name) }</Text>
          <Text style={[styles.assetTextSeven, styles.greyColor,styles.mT4, {}]}>
            {props.overview[1].title.length > 0 ? I18n(props.overview[1].title) : props.overview[1].title}
          </Text>
        </View>

        {props.utility.name !== 'Waste' ?
          <View style={[styles.left27]}>
            {props.utility.name !== 'Carbon' ?
              <View>
                <Text style={[styles.assetTextEightRegular]}>
                  {costMask[CurrencyUnitToLabel(currencyUnit)]} {estSignDecider(truncateDecimal(props.overview[2].value,0))}
                </Text>
                <Text style={[styles.assetTextSeven, styles.greyColor, styles.mT4, {}]}>
                  {props.overview[2].title.length > 0 ? I18n(props.overview[2].title) : props.overview[2].title}
                </Text>
              </View>: null
            }
          </View> : null
        }

        <View style={[styles.mL40,styles.mT10]}>
          {renderLegend()}
        </View>
      </View>
      <Image src={props.image} style={[styles.performanceImage]}/>
    </View>
  )

  const renderLegend = () => {
    return (
      <View style={[styles.tableRow]}>
        { props.utility.name === 'Waste' ?
          <View style={[styles.tableRow,styles.mR6]}>
            <View style={styles.actualLegendWaste}/>
            <View style={styles.width54}>
              <Text style={[styles.assetTextSix,styles.actualTextWaste]}>{I18n('t_landfill')}</Text>
            </View>
          </View>: null
        }

        <View style={[styles.tableRow,styles.mR6]}>
          <View style={styles.actualLegend}/>
          <View style={styles.width36}>
            <Text style={[styles.assetTextSix,styles.actualText]}>{I18n('t_actual')}</Text>
          </View>
        </View>

        <View style={[styles.tableRow,styles.mR6]}>
          <View style={styles.targetLegend}/>
          <View style={styles.width36}>
            <Text style={[styles.assetTextSix,styles.targetText]}>
              {props.showTarget ? I18n('t_target') : I18n('t_previous')}
            </Text>
          </View>
        </View>

        <View style={[styles.tableRow,styles.mR6]}>
          <View>
            <Image src={missingDataIcon} style={styles.dataMissingLegend}/>
          </View>
          <View style={styles.width48}>
            <Text style={[styles.assetTextSix,styles.targetText]}>{I18n('t_pop_up_data_missing')}</Text>
          </View>
        </View>
      </View>
    )
  }

  return (
    <Document>
      <I18nProvider locale={language}>
        <Page size="A4" object-fit="fill" style={styles.page}>
          <View>
            <View style={styles.tableRow} fixed>
              <View>
                <PdfLogo header={I18n('t_esg_performance')}/>
              </View>
              <View>
                <PdfHeader asset={props.asset} language={language}/>
              </View>
            </View>

            <View style={[styles.title,styles.tableRow]} fixed>
              <View style={[styles.perfHeader]}>
                <Text>
                  {I18n('t_req_utility')}: {utilityName(props.utility.name)}
                </Text>
                <Text style={styles.marginAuto}>
                  {I18n('t_date_range')}: {eventDuration(props.timePeriod,language)}
                </Text>
              </View>
            </View>

            <View>
              {performanceChartImage(props)}
            </View>

            <View>
              <Text style={[styles.title, styles.mB10, {marginTop: 32}]}>{I18n('t_events_pfg_msg')}</Text>
              {props.performanceData?.length > 0 ?
                <TablePdf performanceData={props.performanceData} asset={props.asset} language={language}/>
                :
                <View style={[styles.borderExceptBottom, styles.tableWidth]}>
                  <Text style={[styles.title,styles.noRecords]} break>{I18n('t_no_events_found')}</Text>
                </View>
              }
            </View>
            <PdfPagination/>
          </View>
        </Page>
      </I18nProvider>
    </Document>
  )
}

export default ChartPdf
ChartPdf.displayName = 'ChartPdf'

ChartPdf.propTypes = {
  asset: PropTypes.object,
  image: PropTypes.string,
  overview: PropTypes.array,
  language: PropTypes.string,
  performanceData: PropTypes.array,
  showTarget: PropTypes.bool,
  timePeriod: PropTypes.object,
  utility: PropTypes.object
}