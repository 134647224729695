import PropTypes from 'prop-types'
import React, {useLayoutEffect, useState, memo, useContext, useRef} from 'react'
import I18n from '../../../../utils/i18n/I18n'
import {Tooltip} from 'primereact/tooltip'
import {Chart} from 'primereact/chart'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import {displayAssetActionsCount} from './asset-nzc-utils/asset-nzc-utils'
import CommonDialog from '../../../common/modal-dialogs/ModalDialogCommon'
import NzcChartOptions from '../../../common/net-zero-carbon/nzc-chart-options/NzcChartOptions'
import { FeatureFlagContext } from 'Contexts'
import {Dropdown} from 'primereact/dropdown'
import {dropdownItemTemplate} from '../../../../utils/helpers/Helper'
import {nzcIntensityGraphOptions} from '../../../../utils/helpers/Constants'
import {storeAssetGraphIntensity} from '../../funds/nzc-line-graph/fund-graph-utils/fund-graph-utils'
import {nzcErrorMessageTemplate} from '../../../common/net-zero-carbon/nzc-common-utils'

const AssetNzcGraph = ({propsConfig}) => {
  const featureContext            = useContext(FeatureFlagContext)
  const featureState              = featureContext.featureState
  const {assetNzcGraphData, nzcScopeDetails, nzcSettingsData, chartOptionsFormState, nzcGraphRef, scopeId, estimatedYScaleMax, reloadData, selectedLanguage, updateChartOptionsFormState, initialiseChartOptions, assetId, assetIntensityValue, setAssetsIntensityValue, onHideActions, getBenchmarkOptionsData}=propsConfig

  const [showChartOptionsModel, setShowChartOptionsModel] = useState(false)
  const dropdownRef = useRef(null)

  useLayoutEffect(() => {
    const body = document.querySelector('body') //to avoid background scroll when pop up is open
    body.style.overflow = showChartOptionsModel ? 'hidden' : 'auto'
  }, [showChartOptionsModel])

  function handleChartOptions(value) {
    setShowChartOptionsModel(value)
    if(!value){
      initialiseChartOptions(nzcSettingsData)
    }
  }

  function getPropsForNzcChartOptionsForm() {
    return {
      nzcSettingsData,
      chartOptionsFormState,
      selectedLanguage,
      estimatedYScaleMax,
      reloadData,
      scopeId,
      handleChartOptions,
      updateChartOptionsFormState,
      graphIntensity: assetIntensityValue?.value,
      getBenchmarkOptionsData
    }
  }

  /**
   * Change the intensity value on selection
   *
   * @param {object} e to get the target value from the element
   */
  function handleChange (e){
    setAssetsIntensityValue(e.target)
    storeAssetGraphIntensity(e.target.value, assetId)
  }

  return (
    <div className={'asset-nzc-graph'}>
      <div data-testid={'intensity-dropdown'} className="nzc-graph-title">
        {featureState?.sieraplus_nzcAssetEnergyIntensity ?
          <Dropdown
            name="graphName"
            ref={dropdownRef}
            onChange={(e) => handleChange(e)}
            value={assetIntensityValue?.value}
            className="sDropdown-1 intensity-dropdown"
            panelClassName="dropdown-overlay-panel-white add-action-header-dropdown-panel"
            options={nzcIntensityGraphOptions}
            itemTemplate={dropdownItemTemplate}
            id={'intensity-dropdown'}
            onFocus={() => onHideActions()}
          />
          : <label className="carbon-intensity-label" data-testid="carbon-intensity-label"
            htmlFor="binary">{I18n('t_nzc_carbon_intensity')}</label>
        }
      </div>
      <div className="graph-source-options-container">
        <Tooltip target=".overridden" className='tooltip-underline'/>
        <span data-testid="import-model-name">
          {I18n(assetIntensityValue?.value === 'energyIntensity' ? 't_kwh_m2_year' : 't_kg_co2_year')} - {I18n('t_data_source')}:
          <span className="overridden ml-2" data-pr-position="bottom" data-pr-tooltip={nzcScopeDetails?.overriddenDocumentName}>{nzcScopeDetails?.overriddenDocumentName ? I18n('t_imported') : I18n('t_siera')}</span>
        </span>
        <span
          data-testid="chart-option"
          className={`nzc-chart-options-label ${assetNzcGraphData && nzcScopeDetails?.scopeAssets?.length > 0 ? '' : 'disabled'} cursor clickable-highlight`}
          onClick={() => handleChartOptions(true)}>
          {I18n('t_chart_options')}
        </span>
      </div>
      <div className="graph-area-container">
        { assetNzcGraphData ?
          <>
            <Chart {...assetNzcGraphData} plugins={[ChartDataLabels, featureState.sieraplus_actionBasedPathways ? displayAssetActionsCount: {}]} ref={nzcGraphRef} />
            <p className={assetNzcGraphData ? 'graph-border-bottom' : ''} />
          </> :
          <div data-testid="error-message" className={'no-nzc-chart-graph-data'}>{nzcErrorMessageTemplate()}</div>}
      </div>
      <CommonDialog
        className={'asset-nzc-model'}
        modal={true}
        style={{width: '1080px', height: 'fit-content'}}
        visible={showChartOptionsModel}
        onHide={() => handleChartOptions(false)}
        header={I18n('t_chart_options')}
        children={<NzcChartOptions propsConfig={getPropsForNzcChartOptionsForm()} />}
      />
    </div>
  )
}

export default memo(AssetNzcGraph)
AssetNzcGraph.displayName = 'AssetNzcGraph'

AssetNzcGraph.propTypes = {
  assetNzcGraphData: PropTypes.object,
  nzcScopeDetails: PropTypes.object,
  nzcSettingsData: PropTypes.object,
  chartOptionsFormState: PropTypes.object,
  nzcGraphRef: PropTypes.object,
  scopeId: PropTypes.string,
  estimatedYScaleMax: PropTypes.number,
  reloadData: PropTypes.bool,
  returnBenchmarkOptions: PropTypes.func,
  selectedLanguage: PropTypes.string,
  updateChartOptionsFormState: PropTypes.func,
  initialiseChartOptions: PropTypes.func,
  assetIntensityValue: PropTypes.object,
  setAssetsIntensityValue: PropTypes.object,
  graphDataLoading: PropTypes.func,
}