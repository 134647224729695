import React from 'react'
import { Skeleton } from 'primereact/skeleton'
import './DataQualityCardOverviewSkeleton.scss'

const DataQualityCardOverviewSkeleton = () => {
  return (
    <div className="custom-skeleton rel data-quality-card-overview-skeleton">
      <div className="flex justify-content-between">
        <Skeleton width="1.5625rem" borderRadius="0" height="0.6875rem" className="mt-1 mb-1"/>
        <Skeleton width="1.5625rem" borderRadius="0" height="0.6875rem" className="mt-1 mb-1"/>
      </div>
      <div className="flex justify-content-between">
        <Skeleton width="2.8125rem" borderRadius="0" height=".5rem" className="mt-1 mb-3"/>
        <Skeleton width="2.8125rem" borderRadius="0" height=".5rem" className="mt-1 mb-3"/>
      </div>
      <Skeleton width="100%" borderRadius="0" height="1.5rem" className="mb-2"/>
    </div>
  )
}

export default DataQualityCardOverviewSkeleton