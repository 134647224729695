// region - imports
import React, {useContext, useRef} from 'react'
import './AssetActionPlanHome.scss'
import {useQuery, useQueryClient} from 'react-query'
import {useParams} from 'react-router-dom'
import {getAssetActionSummaryDetails} from '../../../../services/assets/actionplan-service'
import {noRefetchOnWindowFocus} from '../../../../services/common/useQuery-config'
import {FeatureFlagContext, PortfolioContext} from 'Contexts'
import BackNavigation from '../../../common/back-nav/BackNavigation'
import ActionStatusCard from '../../../common/action-plan/action-card/ActionStatusCard'
import AssetActionPlanTable from './asset-action-plan-table/AssetActionPlanTable'
import {ACTIONS_STATUS, ASSET_ACTION_PLAN, DATA_FROM} from '../../../../utils/helpers/Constants'
import ActionStatusCardsSkeleton from '../../../common/skeletons/action-status-cards-skeleton/ActionStatusCardsSkeleton'
import AssetAddAction from './asset-add-action/AssetAddAction'
import {useHistory} from 'react-router-dom/cjs/react-router-dom'
import {disableExcelExport} from '../../../../utils/helpers/Helper'
import {useAssetAvailableUtilityMeters} from '../../../../utils/custom-hooks/api-queries/actions-api-queries-hooks'
import useCustomState from '../../../common/custom-hooks/useCustomState'
import AssetExportSelection from '../../../../utils/data-exports/AssetExportSelection'
import {OverlayPanel} from 'primereact/overlaypanel'
import {assetDashboardAddAction} from '../../../../utils/signals/CommonSignals'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'
// endregion

const setInitialActionPlanState = () => {
  const isAddActionVisible = assetDashboardAddAction.value
  return {
    showAddActionSideBar: isAddActionVisible,
    isOverlayPanelOpen: false,
    assetActionPlanData: []
  }
}

const AssetActionPlanHome = () => {
  const {instanceName, orgName, fundName, assetId} = useParams()
  const history = useHistory()
  const {loginState: {userInfo}} = useLoginAuthContext()
  const pfContext = useContext(PortfolioContext)
  const featureContext            = useContext(FeatureFlagContext)
  const featureState              = featureContext.featureState
  const selectedLanguage = userInfo.languagePreference
  const unitSystem = userInfo.unitSystem
  const currencyUnit = userInfo.currencyUnit
  const rowData = pfContext.pfState.data
  const queryClient=useQueryClient()
  const {customState: actionPlanState, updateCustomState: updateActionPlanState}=useCustomState(() => setInitialActionPlanState())
  const exportOverlay = useRef(null)
  const {isLoading:assetActionsSummaryLoading, data: assetActionSummaryData, refetch: assetActionSummaryRefetch} = useQuery(['AssetActionSummary', assetId, currencyUnit], getAssetActionSummaryDetails, {...noRefetchOnWindowFocus, enabled: !!(assetId)} )
  const {data: assetAvailableUtilityMeters}=useAssetAvailableUtilityMeters(assetId)

  // region : helper functions
  const handleAddActions = (action) => {
    if(action === 'Add') {
      updateActionPlanState({showAddActionSideBar: true})
    } else {
      updateActionPlanState({showAddActionSideBar: false})
      assetDashboardAddAction.value = false
    }
  }

  const handleExportOverlay = (exportAction) => {
    if(exportAction === 'Show') {
      updateActionPlanState({isOverlayPanelOpen: true})
    } else {
      updateActionPlanState({isOverlayPanelOpen: false})
    }
  }

  const closeOverlay = () => {
    exportOverlay.current.hide()
  }

  const refetchActions = () => {
    assetActionSummaryRefetch()
    queryClient.removeQueries('actionPlan')
    queryClient.removeQueries('exportActionPlan')
  }
  // endregion

  // region : props for child components
  const propsForBackNavigation = {
    addAction:true,
    propsComingFrom:DATA_FROM.ASSET.ASSET_ACTION_PLAN,
    hideTimePeriodFilter:true,
    onAddActionButtonClick : handleAddActions,
    exportName: 'Export',
    onExportClick: (e) => exportOverlay.current.toggle(e),
    disableExportButton: actionPlanState?.assetActionPlanData?.data?.length === 0,
    v2Flag: true,
    componentName : ASSET_ACTION_PLAN,
    showVerticalEllipsis : true,
    bulkUploadConsumptionId: assetId,
    routeVia : ASSET_ACTION_PLAN,
  }

  const assetActionPlanExportInfoProps = {
    'closeOverlay':closeOverlay, 'actionPlan':true, 'rowData':rowData, // not used anywhere
    'actionData':actionPlanState?.assetActionPlanData?.data, 'language':selectedLanguage,
    'disableExcelExport' :disableExcelExport(actionPlanState?.assetActionPlanData?.data, 'assetActionPlan'),
    'exportFrom' : {'page-type':'asset-page','from':'asset-actionPlan'},
  }

  const propsForAssetActionTable = {
    featureState: featureState,
    selectedLanguage: selectedLanguage,
    unitSystem: unitSystem,
    currencyUnit: currencyUnit,
    urlParams: {assetId, instanceName, orgName, fundName},
    rowData: rowData,
    history: history,
    updateActionPlanState: updateActionPlanState
  }

  const propsForAssetAddActionSideBar = {
    isAddActionSideBarVisible : actionPlanState?.showAddActionSideBar,
    onHideOrCloseOfAddActionSideBar : handleAddActions,
    featureState: featureState,
    currencyUnit: currencyUnit,
    unitSystem,
    assetAvailableUtilityMeters: assetAvailableUtilityMeters,
    selectedLanguage: selectedLanguage,
    assetId: assetId,
    refetchActions: refetchActions,
    flag: null
  }
  //endregion

  return(
    <div className="asset-action-plan" data-testid='asset-action-plan-home'>
      <div className='asset-action-plan__content container-layout gutter'>
        <div className='back-navigation'>
          <BackNavigation {...propsForBackNavigation} />
        </div>
        <OverlayPanel ref={exportOverlay} appendTo={document.getElementById('export')} className="export-overlay export-overlay-v2"
          onShow={() => handleExportOverlay('Show')} onHide={() => handleExportOverlay('Hide')}>
          <AssetExportSelection exportDetails = {assetActionPlanExportInfoProps}/>
        </OverlayPanel>
        <div className='action-cards-container'>
          {assetActionsSummaryLoading ? <ActionStatusCardsSkeleton/> : <>
            <ActionStatusCard type={ACTIONS_STATUS.OVERDUE} data={assetActionSummaryData?.overdue} dataFrom={DATA_FROM.ASSET.ASSET_ACTION_PLAN}/>
            <ActionStatusCard type={ACTIONS_STATUS.AGREED} data={assetActionSummaryData?.agreed} dataFrom={DATA_FROM.ASSET.ASSET_ACTION_PLAN}/>
            <ActionStatusCard type={ACTIONS_STATUS.APPLICABLE} data={assetActionSummaryData?.applicable} dataFrom={DATA_FROM.ASSET.ASSET_ACTION_PLAN}/>
            <ActionStatusCard type={ACTIONS_STATUS.UNDER_REVIEW} data={assetActionSummaryData?.underReview} dataFrom={DATA_FROM.ASSET.ASSET_ACTION_PLAN}/>
            <ActionStatusCard type={ACTIONS_STATUS.COMPLETED} data={assetActionSummaryData?.completed} dataFrom={DATA_FROM.ASSET.ASSET_ACTION_PLAN}/>
          </>}
        </div>
        <div className='asset-action-plan__table-container'>
          <AssetActionPlanTable {...propsForAssetActionTable} />
        </div>
        {!assetActionsSummaryLoading && actionPlanState?.showAddActionSideBar && <AssetAddAction {...propsForAssetAddActionSideBar}/>}
      </div>
    </div>
  )
}

export default React.memo(AssetActionPlanHome)