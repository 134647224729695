import PropTypes from 'prop-types'
import './Action.scss'
import React, {useContext, useEffect, useState, useRef} from 'react'
import {Dialog} from 'primereact/dialog'
import {Panel} from 'primereact/panel'
import {Dropdown} from 'primereact/dropdown'
import {InputText} from 'primereact/inputtext'
import { InputNumber } from 'primereact/inputnumber'
import {FeatureFlagContext} from 'Contexts'
import I18n from '../../../../../utils/i18n/I18n'
import {useMutation} from 'react-query'
import {helperMethod} from '../../../../../services/assets/actionplan-service'
import {Button} from 'primereact/button'
import {CurrencyUnitToLabel, getLocalizedValue, getLocalStorageByKey, getLocalStorageItem, unBlockDialogScroll, unitsConverter,} from '../../../../../utils/helpers/Helper'
import moment from 'moment'
import { TabView, TabPanel } from 'primereact/tabview'
import CommonNote from '../../../../common/action-plan/CommonNote'
import {areaCoveredList, costCurrency, costTypeList, gresbMeasureList, impactList, procuredByList, scopeList, statusList, utilityTypeList} from '../../../../common/action-plan/actionPlanTableTemplate'
import deleteIcon from '../../../../../resources/images/dialog/delete-highlight.svg'
import {UNITS} from '../../../../../utils/i18n/constants'
import {ENERGY, WATER} from '../../../../../utils/helpers/Constants'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const Action = (props) => {
  /*
    notes are handled through arrays, It hits DB on save, selectedNoteData-- all note from back end, updatedNotes-- notes to be updated
    deletedNotes-- notes to be deleted,newNotesAdded-- new notes to be added
 */
  const featureContext = useContext(FeatureFlagContext)
  const featureState = featureContext.featureState
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const unitSystem = userInfo.unitSystem
  const currencyUnit = userInfo.currencyUnit
  const addActionCurrency = featureState?.sieraplus_currency ? CurrencyUnitToLabel(currencyUnit) : props?.selection?.currency
  const editActionCurrency = featureState?.sieraplus_currency ? CurrencyUnitToLabel(currencyUnit) : props?.selection?.action?.currency
  const addAction = {...props?.selection, currency: addActionCurrency}
  const editAction = {...props?.selection?.action, currency: editActionCurrency}
  const [selectedData, setEditedData] = useState(props.edit ? editAction : addAction)
  const [selectedNoteData, setSelectedNoteData] = useState(props.edit ? props.selection?.actionNotes : [])
  const [newNotesAdded] = useState([])
  const [deletedNotes] = useState([])
  const [updatedNotes] = useState([])
  const [assetId] = useState(props.assetId)
  const [isValidated, setValidate] = useState(null)
  const [completionDay, setCompletionDay] = useState(selectedData.completion ? new Date(selectedData.completion).getDate() : '')
  const [completionMonth, setCompletionMonth] = useState(selectedData.completion ? new Date(selectedData.completion).getMonth()+1 : '')
  const [completionYear, setCompletionYear] = useState(selectedData.completion ? new Date(selectedData.completion).getFullYear() : new Date().getFullYear())
  const [dueDay, setDueDay] = useState(selectedData.dueDate ? new Date(selectedData.dueDate).getDate() : '')
  const [dueMonth, setDueMonth] = useState(selectedData.dueDate ? new Date(selectedData.dueDate).getMonth()+1 : '')
  const [dueYear, setDueYear] = useState(selectedData.dueDate ? new Date(selectedData.dueDate).getFullYear() : new Date().getFullYear())
  const [edit, setEdit] = useState(false)
  const {mutateAsync} = useMutation(helperMethod)
  const [isSubmit, setSubmit] = useState(false)
  const [fields]= useState(new Map())
  const CLASS_ACTION_HIGHLIGHT_CLICKABLE_OPACITY='action-highlight-clickable-opacity'
  const [utilityList, setUtilityList] = useState(utilityTypeList)
  const [gresbList, setGresbList] = useState(gresbMeasureList)
  const impactDropdown = useRef(null)
  const utilityDropdown = useRef(null)
  const performanceDropdown = useRef(null)
  const [utilityDropdownDisabled, setUtilityDropdownDisabled] = useState(true)
  const [gresbMeasureDropdownDisabled, setGresbMeasureDropdownDisabled] = useState(true)
  const [activeIndex, setActiveIndex] = useState(null)
  const [isUnSavedNote, setNoteUnsaved] = useState(false)
  const [isDelete, setDelete] = useState(false)
  const [hideClick, setHideClick] = useState(false)
  const [noteState, setNoteState] = useState(null)
  const [dummyNoteId, setDummyNoteId] = useState(0)
  const reverseDayAndMonth = unitSystem === UNITS.IMPERIAL ? 'reverse' : 'stable'
  const dropdownPanel = 'dropdown-overlay-panel-white add-action-header-dropdown-panel'
  const userInfoStorage = getLocalStorageByKey('userInfo')
  //endregion

  //region useEffect Implementation
  useEffect(()=>{
    return unBlockDialogScroll
  }, [])

  useEffect(() => {
    if(isSubmit){
      validate(selectedData)
      setValidate(false)
    }
  },[dueDay,dueMonth,dueYear,completionDay,completionMonth,completionYear,isValidated])

  useEffect(() => {
    onShow()
    if(props){
      setEditedData(props.edit ? editAction : addAction)
      setSelectedNoteData(props.edit ? props.selection?.actionNotes : [])
      if(activeIndex === null){
        setActiveIndex(props.activeIndex)
      }
    }
  }, [props.edit, props.selection?.actionNotes, props.activeIndex])

  useEffect(() => {
    if(props.dlgActionCleanupHandler){
      return props.dlgActionCleanupHandler
    }
  },[])

  useEffect(() => {
    if(Array.isArray(props?.fetchNotesData) && props?.selection?.action?.actionId){
      const fetchCurrentNotes = props?.fetchNotesData.filter(item => item.action.actionId === props.selection.action.actionId)
      if(fetchCurrentNotes.length){
        setSelectedNoteData(fetchCurrentNotes[0].actionNotes || selectedNoteData)
      }
    }
  }, [props?.fetchNotesData])

  //endregion

  //region Helper methods
  const onValueChange = (e, name) => {
    let value = e.value || null
    let editedData = {...selectedData}
    editedData[`${name}`] = value === null ? value : value.toString()

    if (name === 'impact') {
      editedData['utilityType'] = null
      editedData['performanceMeasure'] = null
      let filterUtilityList = utilityTypeList.filter(utility => {
        return utility.impactTarget.includes(e.target.value)
      })

      if (filterUtilityList.length === 1) {
        editedData['utilityType'] = filterUtilityList[0].value
        setUtilityDropdownDisabled(true)
      } else {
        setUtilityDropdownDisabled(false)
      }

      let filterGresbMeasureList = gresbMeasureList.filter(measure => {
        return measure.impactTarget.includes(e.target.value)
      })

      if (filterGresbMeasureList.length === 1) {
        editedData['performanceMeasure'] = filterGresbMeasureList[0].value
        setGresbMeasureDropdownDisabled(true)
      } else {
        setGresbMeasureDropdownDisabled(false)
      }

      setUtilityList(filterUtilityList)
      setGresbList(filterGresbMeasureList)
    }
    if((name === 'utilityType' || name === 'procuredBy') && (editedData['utilityType'] && editedData['procuredBy'])){
      editedData['scope'] = getScopeData(editedData['utilityType'], editedData['procuredBy'])
    }
    setEditedData(editedData)
    if (isSubmit) {
      validate(editedData)
    }
    setEdit(true)
  }

  function getScopeData(utilityType, procuredBy) {
    let scope
    if (procuredBy === 'Landlord') {
      switch (utilityType) {
      case 'Electricity' :
        scope = scopeList[1].value
        break
      case 'Gas' :
        scope = scopeList[0].value
        break
      case 'DistrictHeating' :
        scope = scopeList[1].value
        break
      case 'DistrictCooling' :
        scope = scopeList[1].value
        break
      case 'Oil' :
        scope = scopeList[0].value
        break
      default:
        scope = null
      }
    } else if (procuredBy === 'Tenant') {
      scope = scopeList[2].value
    }
    return scope
  }

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || ''
    let editedData = {...selectedData}
    editedData[`${name}`] = val
    setEditedData(editedData)
    if(isSubmit){
      validate(editedData)
    }
    setEdit(true)
  }

  const onDateChange = (e, name) => {
    const val = (e.target && e.target.value) || ''
    if(name==='cDay'){
      setCompletionDay(val)
    }if(name==='cMonth'){
      setCompletionMonth(val)
    }if(name==='cYear'){
      setCompletionYear(val)
    }if(name==='dDay'){
      setDueDay(val)
    }if(name==='dMonth'){
      setDueMonth(val)
    }if(name==='dYear'){
      setDueYear((val))
    }
    setEdit(true)
    if(isSubmit){
      validate(selectedData,val,name)
    }
  }

  const onSaveNote = async (noteData, edit,toUpdate,noteId) => {
    try {
      if (toUpdate === 'delete') {
        deleteNote(noteData)
      } else if (toUpdate) {
        if(noteId.noteId){
          let noteIndex = selectedNoteData.findIndex(note => note.noteId === noteId.noteId)
          selectedNoteData[noteIndex].content = noteData
          updatedNotes.push(selectedNoteData[noteIndex])
          await mutateAsync({...selectedNoteData[noteIndex], assetId, actionId: selectedData.actionId,noteId: noteId.noteId, 'action': 'updateNote'})
          reLoad(props)
        }else{
          let newNoteUpdatingIndex = newNotesAdded.findIndex(note => note.dummyNoteId === noteId.dummyNoteId)
          newNotesAdded[newNoteUpdatingIndex].content = noteData
          let noteIndex = selectedNoteData.findIndex(note => note.dummyNoteId === noteId.dummyNoteId)
          selectedNoteData[noteIndex].content = noteData
        }
      } else {
        save(noteData, edit, toUpdate)
      }
    } catch {
      console.log('unable to perform Note Action')
    }
  }

  async function deleteNote(noteData) {
    if(noteData.noteId){
      let noteIndex = selectedNoteData.findIndex(note => note.noteId === noteData.noteId)
      if (noteIndex !== -1) {
        selectedNoteData.splice(noteIndex, 1)
        deletedNotes.push(noteData)
      }
    }else{
      let newNoteIndex = newNotesAdded.findIndex(note => note.dummyNoteId === noteData.dummyNoteId)
      newNotesAdded.splice(newNoteIndex, 1)
      let noteIndex = selectedNoteData.findIndex(note => note.dummyNoteId === noteData.dummyNoteId)
      selectedNoteData.splice(noteIndex, 1)
    }
    await mutateAsync({...noteData, assetId, actionId: selectedData.actionId ,noteId: noteData.noteId, 'action': 'deleteNote'})
    reLoad(props)
  }

  const deleteAction = async () => {
    let actionId = selectedData.actionId
    closeActionDialog(props)
    await mutateAsync({...selectedData,assetId, actionId, 'action': 'delete'})
    reLoad(props)
  }

  const addActionHandler = async () => {
    if (!isUnSavedNote) {
      if (edit) {
        await onSaveClick()
      } else {
        closeActionDialog(props)
      }
    }
  }

  const onClickDelete = () => {
    setDelete(true)
  }

  const save = async (noteData, edit,toUpdate) => {
    const nodeItem = {'dateCreated': new Date(), 'content': noteData,'userName' :getLocalStorageItem('userName'),toUpdate,dummyNoteId}
    selectedNoteData.push(nodeItem)
    newNotesAdded.push(nodeItem)
    setDummyNoteId(dummyNoteId+1)
    await mutateAsync({...nodeItem, assetId, actionId: selectedData.actionId, 'action': ''})
    reLoad(props)
  }

  const reLoad = (props) => {
    props.reLoad(true)
  }

  const onSaveClick = async () => {
    setSubmit(true)
    validate(selectedData)
    let actionId = selectedData.actionId
    if(fields.size===0 && !isUnSavedNote){
      closeActionDialog(props)
      if (props.edit) {
        if (edit) {
          await mutateAsync({...selectedData, assetId, actionId, 'action': 'updateAction'})
          reLoad(props)
        }
      } else {
        const actionId=await mutateAsync({...selectedData, assetId, 'action': 'newAction'})
        if (newNotesAdded.length > 0) {
          for (const individualNote of newNotesAdded) {
            await mutateAsync({...individualNote, assetId, actionId, 'action': ''})
          }
        }
        reLoad(props)
      }
    }
  }

  const validate = (selectedData) => {
    fields.clear()
    if (selectedData.actionDescription === '') {
      fields.set('action', 'empty')
    }
    if(selectedData.areaCovered === '' || selectedData.areaCovered === null){
      fields.set('areaCovered', 'empty')
    }
    if(selectedData.costType === '' || selectedData.costType === null)
    {
      fields.set('costType', 'empty')
    }
    if(selectedData.impact === '' || selectedData.impact === null)
    {
      fields.set('impact', 'empty')
    }
    if(selectedData.procuredBy === '' || selectedData.procuredBy === null){
      fields.set('procuredBy', 'empty')
    }
    if(!utilityDropdownDisabled){
      if(selectedData.utilityType === '' || selectedData.utilityType === null)
      {
        fields.set('utilityType', 'empty')
      }
    }
    if(!gresbMeasureDropdownDisabled){
      if(selectedData.performanceMeasure === '' || selectedData.performanceMeasure === null)
      {
        fields.set('performanceMeasure', 'empty')
      }
    }
    if (completionDay !== '' || completionMonth !== '') {
      if (completionDay === '' || completionMonth === '' || completionMonth > 12 || completionDay >31) {
        fields.set('day', 'Error')
        fields.set('month', 'Error')
        fields.set('year', 'Error')
      }
      if (!fields.has('month') && !fields.has('day') && !fields.has('year')) {
        onCompletionValidation(completionDay, completionMonth, completionYear)
      }
    } else {
      selectedData['completion'] = null
    }
    if(dueDay !== '' || dueMonth !== ''){
      if(dueDay === '' || dueMonth === '' || dueDay >31 || dueMonth >12){
        fields.set('dueDay', 'Error')
        fields.set('dueMonth', 'Error')
        fields.set('dueYear', 'Error')
      }
      if(!fields.has('dueMonth') && !fields.has('dueDay') && !fields.has('dueYear')){
        onDueDateValidation(dueYear,dueMonth,dueDay)
      }
    }else{
      selectedData['dueDate'] = null
    }
    setValidate(true)
    if(fields.size===0){
      setHideClick(false)
    }
  }

  const onDueDateValidation = (y, m, d) => {
    let date = covertDateFormat(d,m,y)
    if (date !== 'Invalid Date') {
      selectedData['dueDate'] = date
    }
  }

  const covertDateFormat = (d, m, y) => {
    let date = new Date(y, m-1, d,0,0,0)
    date=moment(date).format()
    date=date.toString().substr(0,19)
    return date
  }

  const onCompletionValidation = (d, m, y) => {
    let date = covertDateFormat(d,m,y)
    if (date !== 'Invalid Date') {
      selectedData['completion'] =date
    }
  }

  const onShow = () => {
    const selectedImpact = selectedData.impact

    let filterUtilityList = utilityTypeList.filter(utility => {
      return utility.impactTarget.includes(selectedImpact)
    })

    if(filterUtilityList.length === 1) {
      setUtilityDropdownDisabled(true)
    } else {
      setUtilityDropdownDisabled(false)
    }

    let filterGresbMeasureList = gresbMeasureList.filter(measure => {
      return measure.impactTarget.includes(selectedImpact)
    })

    if(filterGresbMeasureList.length === 1) {
      setGresbMeasureDropdownDisabled(true)
    } else {
      setGresbMeasureDropdownDisabled(false)
    }

    setUtilityList(filterUtilityList)
    setGresbList(filterGresbMeasureList)

    if(!selectedImpact) {
      setUtilityList([{label: I18n('t_not_available'), value: 'NotApplicable'}])
      setGresbList([{label: I18n('t_not_available'), value: 'NotApplicable'}])

      let editedData = {...selectedData}

      editedData['utilityType'] = 'NotApplicable'
      editedData['performanceMeasure'] = 'NotApplicable'
      setEditedData(editedData)
      setUtilityDropdownDisabled(true)
      setGresbMeasureDropdownDisabled(true)
    }
  }

  const onHide = async () => {
    closeActionDialog(props)
  }

  function currentUnit(data){
    let unit=' '
    const energyUtility = data === ENERGY ? unitsConverter(ENERGY, unitSystem) : ' '
    if(data){
      unit= data === WATER ? unitsConverter(data, unitSystem) : energyUtility
    }
    return unit.toString()
  }

  const isTextExist = (field,content) => {
    if (!field) {
      setHideClick(false)
    }
    setNoteState(content)
    setNoteUnsaved(field)
  }

  //endregion

  //region Custom templates

  const getDropdownItemTemplate = (option) => {
    return (
      <>
        <div className="dropdown-item-1">
          <div className="dropdown-item-2 dropdown-item-wrap">{option.label}</div>
        </div>
        <div className={'dropdown-panel-item-horiz-line'} />
      </>
    )
  }

  function utilityMeterNotAvailable(utility) {
    return !(props.assetAvailableUtilityMeters?.includes(utility))
  }

  const getDropdownItemTemplateForUtility = (option) => {
    const {value}=option
    const meterNotAvailable=utilityMeterNotAvailable(value)
    const dropdownItemClasses=meterNotAvailable? 'dropdown-item-1 dropdown-action-utility_unavailable':'dropdown-item-1'
    return (
      <>
        <div className={dropdownItemClasses}>
          <div className="dropdown-item-2 dropdown-item-wrap label">{option.label}</div>
          {meterNotAvailable && <div className="message">{I18n('t_utility_meter_unavailable')} {option.label}</div>}
        </div>
        <div className={'dropdown-panel-item-horiz-line'} />
      </>
    )
  }

  function getNotesValue(value){
    props.getActionNotesValue(value)
  }
  const CommonNoteErrorMessage = ()=>{
    return(
      <div className="action-footer">
        <div className="pt-4 float-right">
          <div className="col-12 no-padding" style={{display: isUnSavedNote  ? 'block' : 'none'}}>
            <div className="larger-text-bold p-error-message">{getLocalizedValue(selectedLanguage, 't_note_post_warning')}</div>
          </div>
        </div>
      </div>
    )
  }

  function errorMessage(){
    let message
    if(fields.has('dueDay') || fields.has('day'))
    {
      message=<div>{getLocalizedValue(selectedLanguage, 't_date_format_check')}</div>
    }
    if(fields.has('impact') || fields.has('areaCovered') || fields.has('performanceMeasure') ||
        fields.has('utilityType') || fields.has('action') || fields.has('procuredBy')
        || fields.has('costType'))
    {
      message=<div>{I18n('t_action_error_message')}</div>
    }
    if(isDelete){
      message= <div className="flex p-align-center p-error-message__delete">
        <div>{I18n('t_delete_warning')}</div>
        <div className="flex">
          <div>
            <Button label={getLocalizedValue(selectedLanguage, 't_yes')} onClick={deleteAction}
              className={`delete-yes p-button-link ${CLASS_ACTION_HIGHLIGHT_CLICKABLE_OPACITY}`}
            />
          </div>
          <span className="col-1">/</span>
          <div>
            <Button label={getLocalizedValue(selectedLanguage, 't_no')} onClick={()=>setDelete(false)}
              className={`delete-yes delete-no p-button-link ${CLASS_ACTION_HIGHLIGHT_CLICKABLE_OPACITY}`}
            />
          </div>
        </div>
      </div>
    }

    return <div className={isDelete? 'larger-text-bold p-error-message':'p-pt-2 larger-text-bold p-error-message'}>
      {message}
    </div>
  }

  const header = () => {
    return (
      <div className="flex a-header">
        <div className="header-text">
          <InputText className={fields.has('action') ? 'a-action-error-description p-invalid' : 'a-action-description-initial'}
            rows={2} cols={12} maxLength="66" placeholder={getLocalizedValue(selectedLanguage, 't_add_action_title') + ' *'}
            autoFocus={!props.edit} value={selectedData.actionDescription}
            onChange={(e) => onInputChange(e, 'actionDescription')}
            data-testid={'action-description'}
          />
        </div>
        <div className="header-status">
          <div className="flex" role={'header-status'}>
            <div className="a-status-text">{I18n('t_status')}:</div>
            <Dropdown value={selectedData.status} options={statusList} scrollHeight="285px" className="a-status"
              itemTemplate={getDropdownItemTemplate} appendTo={'self'}
              onChange={(e) => onValueChange(e, 'status')}
              panelClassName={'dropdown-overlay-panel-white p-dropdown-panel-no-top-border'}
            />
          </div>
        </div>
      </div>
    )
  }

  function actionItemCrud() {
    return <>
      {props.edit ?
        <div className={'flex'}>
          <Button label={getLocalizedValue(selectedLanguage, 't_btn_update_and_add')}
            className={'update-actionItem btn-action p-button'} onClick={addActionHandler} disabled={!edit}/>
          <div className={`${CLASS_ACTION_HIGHLIGHT_CLICKABLE_OPACITY}`} onClick={onClickDelete}>
            <img src={deleteIcon} alt={getLocalizedValue(selectedLanguage, 't_delete')}/>
            <Button label={getLocalizedValue(selectedLanguage, 't_delete_action_title')} className="delete-action p-button-link"/>
          </div>
        </div>
        :
        <>
          <Button className={'add-actionItem btn-action p-button'} label={getLocalizedValue(selectedLanguage, 't_btn_add')}
            disabled={!edit} onClick={addActionHandler} data-testid={'add-action-button'}/>
        </>
      }
    </>
  }

  //endregion

  //region Dialog & Overlay handlers
  const closeActionDialog = (props) => {
    setEdit(false)
    props.closeActionDialog(false)
  }

  function getDropdownClassName(name) {
    return fields.has(name) ? 'a-drop-down a-drop-down-p-invalid' : 'a-drop-down'
  }

  function getDateClassName(completionDay, day) {
    return completionDay !=='' && fields.has(day) ? 'a-completion-date p-invalid xx-small-text ' : 'a-completion-date xx-small-text'
  }

  function getYearClassName(completionYear, year) {
    return completionYear !=='' && fields.has(year) ? 'a-completion-date year p-invalid xx-small-text' : 'a-completion-date year xx-small-text'
  }

  function getUtilityDropdownItemDisableOption(option) {
    return utilityMeterNotAvailable(option.value)
  }

  //endregion

  //region component division
  function getLeftPanel() {
    return <Panel className="action-panel mr-4" data-testid={'action-dialog-left-panel'}>
      <div className="flex col-12 border-t-b" data-testid={'impact-field'}>
        <div className="col-5 grey-text">{I18n('t_impact')} *</div>
        <div>
          <Dropdown ref={impactDropdown} value={selectedData.impact} options={impactList}  appendTo={'self'}
            placeholder={getLocalizedValue(selectedLanguage, 't_select')}  panelClassName={dropdownPanel}
            onChange={(e) => onValueChange(e, 'impact')}
            className={getDropdownClassName('impact')} itemTemplate={getDropdownItemTemplate}
          />
        </div>
      </div>
      {!utilityDropdownDisabled &&
          <div className="flex col-12 border-b">
            <div className="col-5 grey-text">{I18n('t_utility_type')}{utilityDropdownDisabled ? '' : ' *'}</div>
            <div>
              <Dropdown
                disabled={utilityDropdownDisabled}
                ref={utilityDropdown}
                value={selectedData.utilityType}
                options={utilityList}
                appendTo={'self'}
                panelClassName={dropdownPanel}
                onChange={(e) => onValueChange(e, 'utilityType')}
                className={`dropdown-action-utility ${getDropdownClassName('utilityType')}`}
                itemTemplate={getDropdownItemTemplateForUtility}
                placeholder={getLocalizedValue(selectedLanguage, 'select')}
                optionDisabled={getUtilityDropdownItemDisableOption}
              />
            </div>
          </div>
      }
      {!gresbMeasureDropdownDisabled &&
          <div className="flex col-12 border-b">
            <div className="col-5 grey-text justify-content">
              {I18n('t_gresb_measure')}{gresbMeasureDropdownDisabled ? '' : ' *'}
            </div>
            <Dropdown disabled={gresbMeasureDropdownDisabled} ref={performanceDropdown} options={gresbList}
              p-dropdown-label= {gresbList} value={selectedData.performanceMeasure}  panelClassName={dropdownPanel}
              appendTo={'self'} placeholder={getLocalizedValue(selectedLanguage, 'select')}
              onChange={(e) => onValueChange(e, 'performanceMeasure')}
              className={getDropdownClassName('performanceMeasure')} itemTemplate={getDropdownItemTemplate}
            />
          </div>
      }
      <div className="flex col-12 border-b" data-testid={'estimated-saving-field'}>
        <div className="col-5 grey-text">{I18n('t_estimated_savings')}</div>
        <div className="p-input-icon-right">
          <i>{currentUnit(selectedData.impact)}</i>
          <InputNumber value={selectedData.target} locale={selectedLanguage}
            onChange={(e) => onValueChange(e, 'target')}
            placeholder={getLocalizedValue(selectedLanguage, 't_annual_expected_consumption')}
            className={'add-action xx-small-text'}
          />
        </div>
      </div>
      <div className="flex col-12 border-b" data-testid={'procured-by-field'}>
        <div className="col-5 grey-text">{I18n('t_procured_by')} *</div>
        <div>
          <Dropdown value={selectedData.procuredBy} options={procuredByList}   scrollHeight="17.813rem" appendTo={'self'}
            placeholder={getLocalizedValue(selectedLanguage, 't_select')} panelClassName={dropdownPanel}
            className={getDropdownClassName('procuredBy')}  itemTemplate={getDropdownItemTemplate}
            onChange={(e) => onValueChange(e, 'procuredBy')}
          />
        </div>
      </div>
      <div className="flex col-12 border-b" data-testid={'scope-field'}>
        <div className="col-5 grey-text">{I18n('t_scope')}</div>
        <div>
          <Dropdown value={selectedData.scope} options={scopeList} p
            laceholder={getLocalizedValue(selectedLanguage, 't_select')}
            scrollHeight="285px" appendTo={'self'} className={getDropdownClassName('scope')}
            onChange={(e) => onValueChange(e, 'scope')}
            itemTemplate={getDropdownItemTemplate} panelClassName={dropdownPanel}
          />
        </div>
      </div>
      <div className="flex col-12 border-b" data-testid={'add-manager-field'}>
        <div className="col-5 grey-text">{I18n('t_manager')}</div>
        <div>
          <InputText value={selectedData.manager ? selectedData.manager : ''}
            onChange={(e) => onInputChange(e, 'manager')}  className={'a-manager-edit xx-small-text'}
            placeholder={getLocalizedValue(selectedLanguage, 't_add_manager')}
          />
        </div>
      </div>
    </Panel>
  }

  function getRightPanel() {
    return <Panel className="action-panel p-ml-3" data-testid={'action-dialog-right-panel'}>
      <div className="flex col-12 border-t-b" data-testid={'currency-field'}>
        <div className="col-5 grey-text">{I18n('t_cost')}</div>
        <div className="flex">
          <div className="a-c-field">
            <InputNumber value={selectedData.cost} className="a-cost xx-small-text"
              onChange={(e) => onValueChange(e, 'cost')} locale={selectedLanguage}
              placeholder={getLocalizedValue(selectedLanguage, 't_add_cost_estimate')}
              data-testid={'currency-input'} min={0}
            />
          </div>
          <div data-testid={'gbp-cost'}>
            <Dropdown disabled={userInfoStorage?.userRole !== 'Admin'} value={selectedData.currency} options={costCurrency} panelClassName={dropdownPanel}
              onChange={(e) => onInputChange(e, 'currency')}
              appendTo={'self'} className="gbp-cost" itemTemplate={getDropdownItemTemplate}
              id={'currency-dropdown'}
            />
          </div>
        </div>
      </div>
      <div className="flex col-12 border-b" data-testid={'cost-type-field'}>
        <div className="col-5 grey-text">{I18n('t_cost_type')} *</div>
        <div>
          <Dropdown value={selectedData.costType} options={costTypeList}
            placeholder={getLocalizedValue(selectedLanguage, 't_select')}
            onChange={(e) => onValueChange(e, 'costType')}
            className={getDropdownClassName('costType')}
            appendTo={'self'}  itemTemplate={getDropdownItemTemplate} panelClassName={dropdownPanel}
          />
        </div>
      </div>
      <div className="flex col-12 border-b" data-testid={'area-covered-field'}>
        <div className="col-5 grey-text">{I18n('t_area_covered')} *</div>
        <div>
          <Dropdown value={selectedData.areaCovered} options={areaCoveredList}
            placeholder={getLocalizedValue(selectedLanguage, 't_select')}
            scrollHeight="17.813rem" appendTo={'self'} className={getDropdownClassName('areaCovered')}
            onChange={(e) => onValueChange(e, 'areaCovered')}
            itemTemplate={getDropdownItemTemplate} panelClassName={dropdownPanel}
          />
        </div>
      </div>
      <div className="flex col-12 border-b" data-testid={'due-date-field'}>
        <div className="col-5 grey-text">{I18n('t_due_date')}</div>
        <div className="flex">
          <div className={reverseDayAndMonth}>
            <div>
              <InputText id="dueDay" value={dueDay} onChange={(e) => onDateChange(e,'dDay')} max="31"
                keyfilter="num" placeholder="DD" maxLength="2" className={getDateClassName(dueDay,'dueDay')}
              />
            </div>
            <div>
              <InputText id="dueMonth" value={dueMonth} onChange={(e) => onDateChange(e,'dMonth')} max="12"
                keyfilter="num" placeholder="MM" maxLength="2" className={getDateClassName(dueMonth,'dueMonth')}
              />
            </div>
          </div>
          <div>
            <InputText id="dueYear" value={dueYear} onChange={(e) => onDateChange(e,'dYear')}
              keyfilter="num" placeholder="YYYY" maxLength="4" className={getYearClassName(dueYear,'dueYear')}
            />
          </div>
          <div className="placeholder p-date">({getLocalizedValue(selectedLanguage, 't_action_happen')})</div>
        </div>
      </div>
      <div className="flex col-12 border-b" data-testid={'completion-date-field'}>
        <div className="col-5 grey-text">{I18n('t_completion_date')}</div>
        <div className="flex">
          <div className={reverseDayAndMonth}>
            <div>
              <InputText id="day" value={completionDay} onChange={(e) => onDateChange(e,'cDay')}
                maxLength="2"  keyfilter="num" placeholder="DD" className={getDateClassName(completionDay,'day')}
              />
            </div>
            <div>
              <InputText id="month" value={completionMonth} onChange={(e) => onDateChange(e,'cMonth')}
                maxLength="2" placeholder="MM" keyfilter="num" className={getDateClassName(completionMonth,'month')}
              />
            </div>
          </div>
          <div>
            <InputText  id="year"  value={completionYear} style={{width:60}} onChange={(e) => onDateChange(e,'cYear')}
              maxLength="4" keyfilter="num"  placeholder="YYYY"
              className={getYearClassName(completionYear,'year')}
            />
          </div>
          <div className="placeholder p-date">({getLocalizedValue(selectedLanguage, 't_action_completed')})</div>
        </div>
      </div>
    </Panel>
  }

  //endregion
  return (
    <Dialog header={header} className="av-Dlg" visible={props.showDialog} onHide={onHide} onShow={onShow} draggable={false}
      blockScroll>
      <div data-testid={'action-dialog'} className="mt-2">
        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
          <TabPanel header={getLocalizedValue(selectedLanguage, 't_details')} headerClassName={fields.size!==0 ? 'red-font' :''}
            disabled={isUnSavedNote} data-testid={'action-tab'}>
            <div className="flex panel-height">
              {getLeftPanel()}
              {getRightPanel()}
            </div>
            <div className="action-footer flex justify-content-between pt-4">
              {actionItemCrud()}
              <div className="float-left">
                <div className="col-12 no-padding" style={{display: fields.size !== 0 || isDelete ? 'block' : 'none'}}>
                  {errorMessage()}
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel header={I18n('t_notes')} headerClassName={hideClick && isUnSavedNote ? 'red-font' :''} disabled={!props?.edit}>
            <CommonNote notesData={selectedNoteData} onSaveNote={onSaveNote} isTextExist={isTextExist} getNotesValue={getNotesValue}
              isNotesValue={props.isNotesValue} errorMsgComponent={hideClick ? <CommonNoteErrorMessage/> : null}
              content={noteState}
            />
          </TabPanel>
        </TabView>
      </div>
    </Dialog>
  )
}

export default Action

Action.displayName = 'Action'

Action.propTypes = {
  activeIndex: PropTypes.number,
  assetId: PropTypes.string,
  closeActionDialog: PropTypes.func,
  dlgActionCleanupHandler: PropTypes.func,
  edit: PropTypes.bool,
  reLoad: PropTypes.func,
  showDialog: PropTypes.bool,
  selection: PropTypes.object,
  fetchNotesData: PropTypes.func
}