import {useQueries} from 'react-query'
import {noRefetchOnWindowFocus, retryCount} from '../../../services/common/useQuery-config'
import {getFundCompleteness, getFundCoverage} from '../../../services/funds/fund-data-quality-service'

const useFundCoverageCompletenessData = (fundGroupID, apiParams, unitSystem, sieraplus_fundDataQualityLayoutV2) => {

  const {
    timePeriod,
    timeNoOfMonthsPeriod: noOfMonths,
    timeEndDatePeriod: endMonth,
    utilitySelected,
    selectedView,
    selectedAgent
  } = apiParams

  const [
    fundCompleteness,
    fundCoverage
  ] = useQueries([
    {
      queryKey: ['fund-completeness', fundGroupID, timePeriod, noOfMonths, endMonth, utilitySelected, selectedView, selectedAgent, unitSystem],
      queryFn: getFundCompleteness,
      retry: retryCount,
      ...getQueryConfig(!!fundGroupID && !!timePeriod && sieraplus_fundDataQualityLayoutV2)
    },
    {
      queryKey: ['fund-coverage', fundGroupID, timePeriod, noOfMonths, endMonth, utilitySelected, selectedView, selectedAgent, unitSystem],
      queryFn: getFundCoverage,
      retry: retryCount,
      ...getQueryConfig(!!fundGroupID && !!timePeriod && sieraplus_fundDataQualityLayoutV2)
    }
  ])

  function getQueryConfig(fetchOnMount) {
    return {...noRefetchOnWindowFocus, staleTime: 999999, enabled: fetchOnMount}
  }

  return {
    fundCompleteness,
    fundCoverage
  }
}

export default useFundCoverageCompletenessData