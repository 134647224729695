import React, {useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import '../asset-form/AssetForm.scss'
import {InputText} from 'primereact/inputtext'
import {Dropdown} from 'primereact/dropdown'
import {InputTextarea} from 'primereact/inputtextarea'
import {Checkbox} from 'primereact/checkbox'
import {FileUpload} from 'primereact/fileupload'
import {Formik} from 'formik'
import AssetFooter from '../asset-footer/AssetFooter'
import QuestionMarkIcon from '../../../../../resources/images/icon/question-mark.svg'
import {GetSchemeOptions} from '../../../../../services/admin/add-asset-service'
import {GetPortfolioData} from '../../../../../services/assets/portfolio-service'
import {noRefetchOnWindowFocus} from '../../../../../services/common/useQuery-config'

const AssetKeyDates = (props) => {

  // Formik properties.
  const initialValues= {
    datePurchased: '',
    dateOfSale: '',
    yearBuilt: '',
    yearLast: ''
  }

  function onSubmit(values) {
    // TODO: Actual implementations for form submission.
    console.log(JSON.stringify(values))
  }

  const questionMarkIcon = (className) => <><img className={className} src={QuestionMarkIcon} alt="QuestionMarkIcon"/></>

  const handleTabChange = () => {
    props.setActiveIndexTab(2)
    window.scroll(0, 0)
  }

  // Asset form columns.
  const columnLeft= formik => <>
    <div className="flex field-date">
      <div className="col-6 from-date" data-testid={'from-date-input'}>
        <div className='flex label-date'>
          <span>Date purchased</span>
        </div>
        <div className="flex input-date">
          <InputText id="fromDate" maxLength="2" keyfilter="num" />
          <InputText id="fromMonth" maxLength="2" keyfilter="num" />
          <InputText id="fromYear" maxLength="4" keyfilter="num" />
        </div>
        <span className="example-date">eg. 06 / 12 / 2018</span>
      </div>
      <div className="col-6 to-date" data-testid={'to-date-input'}>
        <div className='flex label-date'>
          <span>Date of sale</span>
        </div>
        <div className="flex input-date">
          <InputText id="toDate" maxLength="2" keyfilter="num" />
          <InputText id="toMonth" maxLength="2" keyfilter="num" />
          <InputText id="toYear" maxLength="4" keyfilter="num" />
        </div>
        <span className="example-date">eg. 28 / 03 / 2003</span>
      </div>
    </div>
    <div className="grid mb-0 field-mini-input" data-testid={'yr-built-input'}>
      <div className='input-mini'>
        <div className={'input-container year-built col'}>
          <div className={'p-float-label'}>
            <InputText maxLength="4" keyfilter="num" id="yearBuilt" name="yearBuilt" {...formik.getFieldProps('yearBuilt')} />
            <label htmlFor="assetRef">Year built</label>
          </div>
        </div>
        {questionMarkIcon('icon-question-mark')}
      </div>
      <div className='input-mini' data-testid={'yr-last-input'}>
        <div className={'input-container year-last col'}>
          <div className={'p-float-label'}>
            <InputText maxLength="4" keyfilter="num" id="epcSco" name="epcScore" {...formik.getFieldProps('epcScore')} />
            <label htmlFor="assetRef">Year last refurbished</label>
          </div>
        </div>
        {questionMarkIcon('icon-question-mark')}
      </div>
    </div>
  </>

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {formik => (
        <>
          <div className={'asset-form'}>
            <form onSubmit={formik.handleSubmit}>
              <div className={'form-table grid'}>
                <div className={'l-column'}>
                  {columnLeft(formik)}
                </div>
              </div>
            </form>
          </div>
          <div className={'footer-divider'}>
            <hr/>
          </div>
          <div className={'footer-container'}>
            <AssetFooter
              submitHandler={formik.handleSubmit}
              handleNextTabBtnText={'EPCs'}
              handleNextTab={handleTabChange}
            />
          </div>
        </>
      )}
    </Formik>
  )
}

export default AssetKeyDates