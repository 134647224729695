import React from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import { Textarea, Button } from 'components/atomic'
import I18n, { useI18n } from 'utils/i18n/I18n'
import { Text, Icon } from 'components/atomic/index'
import { formatNumWODecimal, formatNumWOneDecimal } from 'services/common/formatter'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const maxTextLength = 4000

const impactTranslations = {
  FossilFuels: {
    key: 't_fossil_fuels',
    adverse: 't_adverse_impact_fossil_fuels',
    impact: 't_impact_value_fossil_fuels',
  },
  EnergyEfficiency: {
    key: 't_energy_efficiency',
    adverse: 't_adverse_impact_energy_efficiency',
    impact: 't_impact_value_energy_efficiency',
  },
  GreenhouseGasEmissions: {
    key: 't_ghg_emissions',
    adverse: 't_adverse_impact_ghg_emissions',
    impact: 't_impact_value_ghg_emissions',
  },
  EnergyConsumption: {
    key: 't_energy_consumption',
    adverse: 't_adverse_impact_energy_consumption',
    impact: 't_impact_value_energy_consumption',
  },
  Waste: {
    key: 't_waste_utility',
    adverse: 't_adverse_impact_waste',
    impact: 't_impact_value_waste',
  },
  ResourceConsumption: {
    key: 't_resource_consumption',
    adverse: 't_adverse_impact_resource_consumption',
    impact: 't_impact_value_resource_consumption',
  },
  Biodiversity: {
    key: 't_biodiversity',
    adverse: 't_adverse_impact_biodiversity',
    impact: 't_impact_value_biodiversity',
  },
}

const getTextForMetric = (text, impactType) => {
  const translationKey = impactTranslations[text]?.[impactType]
  if (!translationKey) {
    throw new Error('Unknown metric: ' + text)
  }
  return translationKey
}

const FundReportContentForm = (props) => {
  const {
    fundReportContentData,
    year,
    setIsVisible,
    submitNewReportData
  } = props
    
  const intl = useI18n()
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const initialValues = React.useMemo(() => {
    const values = {}
    fundReportContentData?.metrics.forEach((item, index) => {
      values[`explanation${index}`] = item.explanation ?? ''
      values[`actionsTaken${index}`] =  item.actionsTaken ?? ''
    })
    return values
  }, [fundReportContentData])

  const createFundReportContentDataObject = (values) => {
    const fundReportContentDataTemp = { ...fundReportContentData }
    fundReportContentDataTemp.metrics.forEach((metric, index) => {
      metric.explanation = values[`explanation${index}`] ?? ''
      metric.actionsTaken = values[`actionsTaken${index}`] ?? ''
      delete metric.currentYearImpact
      delete metric.previousYearImpact
    })
    return fundReportContentDataTemp
  }

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      try {
        await submitNewReportData(createFundReportContentDataObject(values))
        setIsVisible(false)
        formik.resetForm()
      }
      catch(error) {
        console.log(error)
      } 
    }
  })
    
  if (!fundReportContentData) {
    return
  }

  return (
    <>
      <div data-testid={'fund-report-content-sidebar-' + year}>
        {fundReportContentData?.metrics?.map((item, index) => {
          return (
            <div className="create-report-wrap" key={item.metric}>
              <div className="create-report-col-small-gap">
                <Text weight='bold' content={getTextForMetric(item.metric, 'key')}/><br></br>
                <div className="create-report-row">
                  <Icon id='warning_solid_triangle_rounded' /><Text content='t_adverse_impact'/>
                </div>
                <Text content={getTextForMetric(item.metric, 'adverse')} testId={'fund-reports-adverse-text-' + item.metric} className='mb-3'/>
                <div className="create-report-row">
                  <Icon id='signal' /><Text content='t_metric'/>
                </div>
                <Text content={getTextForMetric(item.metric, 'impact')} testId={'fund-reports-impact-text-' + item.metric} className='mb-3'/>
                <div className="create-report-row">
                  <Icon id='arrow_up_outlined' /><Text content='t_impact_value'/>
                </div>
                {item.metric == 'GreenhouseGasEmissions' || item.metric == 'EnergyConsumption' ? (
                  <Text content={`${item.currentYearImpact ? formatNumWODecimal(selectedLanguage, item.currentYearImpact) : (item.currentYearImpact?.toFixed(1) ?? 0)} ${item.metric == 'GreenhouseGasEmissions' ? 'tCO₂e' : 'GWh/m²'}`} testId={'fund-reports-impact-value-' + item.metric} />
                ):(
                  <Text content={`${item.currentYearImpact ? formatNumWOneDecimal(selectedLanguage, item.currentYearImpact) : (item.currentYearImpact?.toFixed(1) ?? 0)}%`} testId={'fund-reports-impact-value-' + item.metric} className={item.metric == 'FossilFuels' || item.metric == 'EnergyEfficiency' ? 'mb-3' : ''}/>
                )}
              </div>
              {(item.metric == 'FossilFuels' || item.metric == 'EnergyEfficiency') && (
                <div className="create-report-col">
                  <Textarea
                    labelText={I18n('t_explanation')}
                    editable='true'
                    data-testid={'fund-reports-explanation-' + item.metric}
                    id='explanation-info'
                    className='create-report-textBox'
                    name={`explanation${index}`}
                    value={formik.values[`explanation${index}`]}
                    placeholder={intl.formatMessage({ id: 't_explanation_placeholder' })}
                    autoResize
                    onChange={formik.handleChange}
                    maxLength={maxTextLength}
                  />
                  <Textarea
                    labelText={I18n('t_actions_taken')}
                    editable='true'
                    data-testid={'fund-reports-actions-taken-' + item.metric}
                    id='actions-taken-info'
                    className='create-report-textBox'
                    name={`actionsTaken${index}`}
                    value={formik.values[`actionsTaken${index}`]}
                    placeholder={intl.formatMessage({ id: 't_actions_taken_placeholder' })}
                    autoResize
                    onChange={formik.handleChange}
                    maxLength={maxTextLength}
                  />
                </div>
              )}
            </div>
          )
        })}
      </div>
      <div className="buttons-wrap">
        <Button 
          type='submit' 
          onClick={formik.handleSubmit} 
          content='t_reports_save' 
          testId='fund-reports-save'
        />
        <Button 
          outlined={true} 
          onClick={() => {setIsVisible(false)}} 
          content='t_cancel'
          testId='fund-reports-cancel'
        />
      </div>
    </>
  )
}

FundReportContentForm.propTypes = {
  fundReportContentData: PropTypes.object,
  submitNewReportData: PropTypes.func,
  setIsVisible: PropTypes.func,
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
  
export default FundReportContentForm
FundReportContentForm.displayName = 'FundReportContentForm'