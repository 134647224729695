import './ProgressBarWrapper.scss'
import {useContext, useId} from 'react'
import {NavLink} from 'react-router-dom'
import {
  Action,
  ASSET_PAGES,
  EPS,
  LOCAL_STORAGE_KEYS
} from '../../../../../../utils/helpers/Constants'
import {ProgressBar} from 'primereact/progressbar'
import { Skeleton } from 'primereact/skeleton'
import PropTypes from 'prop-types'
import {
  CachedUserState,
  createNavURLForFundPages,
  getInstance, handleLeastValues,
} from '../../../../../../utils/helpers/Helper'
import {portfolioStateInit} from '../../../../../../utils/reducers/PortfolioReducer'
import {PortfolioContext} from 'Contexts'

function ProgressBarWrapper({percentageData, label, bgColor, metric,percent,isLoading,id, isExpiry= false, className}) {
  const getFullWidthClass = label ? 'progress-bar' : 'expire-progress-bar'
  const defaultId = useId()
  const pfContext = useContext(PortfolioContext)
  const epsExpireMetric = `${EPS}-expire`

  const getRatingClass=()=>{
    const classForLabels = `rating-label ${(percentageData > 0 && id !== epsExpireMetric) ? 'rating-label-active' : ''}`
    return label === 'n/c'? 'rating-label m-left' : classForLabels
  }

  const getProgressTemplate = () => {
    const colorClass = (percentageData > 0 && id !== epsExpireMetric) ? `${bgColor} pointer-cursor` : bgColor
    return isLoading ? <Skeleton className={'action-skeleton'} height={metric === EPS ? '1.8755rem' : '1.25rem'}/> :
      <ProgressBar className={`${colorClass} ${className ?? ''}`} value={percentageData} showValue={false} data-testid={id||defaultId} />
  }

  const epcGraphComponent = () => <div className="epc-graph flex" key="epc-graph">
    { label && <label className={getRatingClass()}>{label}</label> }
    <div className={getFullWidthClass}>
      {getProgressTemplate()}
    </div>
    {!isExpiry && !isLoading ? <div className={'percentage-label'}>{percent||percentageData}%</div> : null}
  </div>

  function actionGraphComponent() {
    return <div className="action-graph" key="action-graph">
      <div className={'impact-label'}>{label}</div>
      <div className="progress-bar">
        {getProgressTemplate()}
      </div>
    </div>
  }

  function epsGraphComponent() {
    return (percentageData > 0 && id !== epsExpireMetric) ? <NavLink className="eps-graph flex" key="eps-graph" onClick={()=>setAssetData(label)}
      to={() => gotoAssetsPage(label)}>
      {getEpsChildren()}
    </NavLink> : <div className="eps-graph flex" key="eps-graph">{getEpsChildren()}</div>
  }

  function getEpsChildren(){
    return <>
      {label && <label id={'es-label'} className={getRatingClass()}>{label}</label>}
      <div className={getFullWidthClass} aria-labelledby={'es-label'}>
        {getProgressTemplate()}
      </div>
      {label && <div className={`impact-label ${(percentageData > 0 && id !== epsExpireMetric) ? 'pointer-cursor' : ''}`}
        data-testid={`percentage-value-${percentageData}`}>{handleLeastValues(percentageData)}%</div> }
    </>
  }

  const getGraphComponent = () => {
    if (metric === Action)
      return actionGraphComponent()
    else if (metric === EPS)
      return epsGraphComponent()
    else
      return epcGraphComponent()
  }

  function gotoAssetsPage(selectedRange) {
    const selectedOrgAndFund=CachedUserState.getUserDataFromCache(LOCAL_STORAGE_KEYS.selectedOrgAndFund)
    return {pathname:createNavURLForFundPages({instanceName: getInstance(), pathSuffix: ASSET_PAGES.assets}),
      search: `fund=${selectedOrgAndFund?.fundName}&esScoreRange=${selectedRange}`}
  }

  function setAssetData(selectedRange){
    const selectedOrgAndFund=CachedUserState.getUserDataFromCache(LOCAL_STORAGE_KEYS.selectedOrgAndFund)

    const updatedPortfolioState = {
      ...portfolioStateInit, ...{
        filterState: {
          ...portfolioStateInit.filterState,
          ...{
            filterFundSelected: selectedOrgAndFund?.fundName,
            filterEsScoreSelected: selectedRange
          }
        }
      }
    }
    pfContext.pfDispatcher({...updatedPortfolioState})
  }

  return <div className={'stack-graph'} data-testid={'progress-wrapper'}>
    {getGraphComponent()}
  </div>
}

ProgressBarWrapper.propTypes = {
  percentageData: PropTypes.number,
  label: PropTypes.string,
  bgColor: PropTypes.string,
  metric: PropTypes.string,
  percent: PropTypes.number,
  isLoading: PropTypes.bool,
  id:PropTypes.any,
  className: PropTypes.string
}

ProgressBarWrapper.displayName = 'ProgressBarWrapper'

export default ProgressBarWrapper
