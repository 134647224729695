import PropTypes from 'prop-types'
import SearchField from '../../../../common/search/SearchField'
import {completenessDaysToPercentages, getLocalizedValue, unBlockDialogScroll} from '../../../../../utils/helpers/Helper'
import './DataQualityContent.scss'
import DataQualityCoverageGraph from './DataQualityCoverageGraph'
import DataQualityCompletenessGraph from './DataQualityCompletenessGraph'
import {areaListV2, CATEGORIES_LIST_V2, isLandlord, utilityListV2} from '../data-quality-hooks-&-helpers/dataQualityHelper'
import MeterTable from './MeterTable'
import I18n from '../../../../../utils/i18n/I18n'
import { useContext, useRef, useState } from 'react'
import OverlayPanel from 'components/atomic/OverlayPanel/OverlayPanel'
import InfoOverlayPanelContent from 'components/atomic/OverlayPanel/OverlayPanelContent/OverlayPanelContent'
import DataCategoriesInformation from 'components/pages/funds/data-quality-home/fund-dq-components/DataCategoriesInformation'
import { Button, Dropdown } from 'components/atomic/index'
import MeterTableTableSkeleton from './skeletons/MeterTableSkeleton'
import RequestDataDialogV2 from '../../performance/dialogs/RequestDataDialogV2'
import useErrorHandling from 'components/common/error-handling/ErrorHandling'
import { PortfolioContext } from 'Contexts'

const DataQualityContent = ({
  meterData,
  meterDataLoading,
  completenessData,
  completenessDataLoading,
  coverageData,
  coverageDataLoading,
  selectedLanguage,
  dataQualityState,
  updateDataQualityState,
  responsibility,
  areaUnit,
  sendDataHandler,
  onEnterDataHandler,
}) => {
  const [showRequestDataDlg, setShowRequestDataDlg] = useState(false)
  const dataCoverageOverlayRef = useRef(null)
  const dataCompletenessOverlayRef = useRef(null)
  const { pfState: {data: rowData} } = useContext(PortfolioContext)
  const { ShowErrorDialog, errorDialogVisible } = useErrorHandling()

  const {
    searchMeterNameLandlord,
    searchMeterNameTenant,
    categorySelectedLandlord,
    utilitySelectedLandlord,
    areaSelectedLandlord,
    categorySelectedTenant,
    utilitySelectedTenant,
    areaSelectedTenant,
    metersSelectedLandlord,
    metersSelectedTenant,
    tenantFirst,
    landlordFirst,
    tenantRows,
    landlordRows,
  } = dataQualityState

  function getMeterTableProps() {
    return {
      data: meterData,
      sortField: 'meterName',
      sortOrder: -1,
      metersSelected: isLandlord(responsibility) ? metersSelectedLandlord: metersSelectedTenant,
      meterSelectedFieldName: isLandlord(responsibility) ? 'metersSelectedLandlord': 'metersSelectedTenant',
      updateDataQualityState,
      first: isLandlord(responsibility) ? landlordFirst : tenantFirst,
      rows: isLandlord(responsibility) ? landlordRows : tenantRows,
      responsibility
    }
  }

  function dataCompletenessInfoOverlay(e){
    dataCompletenessOverlayRef.current.toggle(e)
  }
  function dataCoverageInfoOverlay(e){
    dataCoverageOverlayRef.current.toggle(e)
  }

  function isMeterSelectionEmpty() {
    const meterSelectedFieldName=isLandlord(responsibility)? 'metersSelectedLandlord': 'metersSelectedTenant'
    return dataQualityState?.[meterSelectedFieldName]?.length===0
  }

  function onDialogActionHandler() {
    setShowRequestDataDlg(false)
    unBlockDialogScroll()
    deselectCheckboxes()
  }

  function getUpdatedObject(fieldName, eventValue) {
    const updateObject = { [fieldName]: eventValue }
    if (isLandlord(responsibility)) {
      updateObject.landlordFirst = 0
      updateObject.landlordRows = 5
    } else {
      updateObject.tenantFirst = 0
      updateObject.tenantRows = 5
    }
    return updateObject
  }

  function searchMetersHandler(event) {
    const searchMeterNameField = isLandlord(responsibility) ? 'searchMeterNameLandlord': 'searchMeterNameTenant'
    updateDataQualityState(getUpdatedObject(searchMeterNameField, event.target?.value))
  }

  function categoryChangeHandler(event) {
    const categoryFieldName = isLandlord(responsibility) ? 'categorySelectedLandlord' : 'categorySelectedTenant'
    updateDataQualityState(getUpdatedObject(categoryFieldName, event.value))
  }

  function utilityChangeHandler(event) {
    const utilityFieldName = isLandlord(responsibility) ? 'utilitySelectedLandlord' : 'utilitySelectedTenant'
    updateDataQualityState(getUpdatedObject(utilityFieldName, event.value))
  }

  function areaChangeHandler(event) {
    const areaFieldName = isLandlord(responsibility) ? 'areaSelectedLandlord' : 'areaSelectedTenant'
    updateDataQualityState(getUpdatedObject(areaFieldName, event.value))
  }
  
  function clearSearchField() {
    const searchMeterNameField = isLandlord(responsibility) ? 'searchMeterNameLandlord': 'searchMeterNameTenant'
    updateDataQualityState(getUpdatedObject(searchMeterNameField, ''))
  }

  function deselectCheckboxes() {
    isLandlord(responsibility) ? updateDataQualityState({metersSelectedLandlord: []}) : updateDataQualityState({metersSelectedTenant: []})
  }

  function onAddDataHandler() {
    onEnterDataHandler(isLandlord(responsibility) ? metersSelectedLandlord : metersSelectedTenant, onDialogActionHandler)
  }

  return (
    <div className="dq-view">
      <div className="dq-view__title">{I18n(isLandlord(responsibility) ? 't_landlord_procured' : 't_tenant_procured')}</div>
      <div className="dq-view__menu-section flex align-items-center">
        <div className="dq-view__menu-section__container">
          <Dropdown
            value={isLandlord(responsibility) ? categorySelectedLandlord : categorySelectedTenant}
            onChange={categoryChangeHandler}
            options={CATEGORIES_LIST_V2}
            optionLabel="label"
            placeholderKey='t_all_data_categories'
            colour='light'
          />
          <Dropdown
            value={isLandlord(responsibility) ? utilitySelectedLandlord : utilitySelectedTenant}
            onChange={utilityChangeHandler}
            options={utilityListV2}
            optionLabel="label"
            placeholderKey='t_alu'
            colour='light'
          />
          <Dropdown
            value={isLandlord(responsibility) ? areaSelectedLandlord : areaSelectedTenant}
            onChange={areaChangeHandler}
            options={areaListV2}
            optionLabel="label"
            placeholderKey='t_aas'
            colour='light'
          />
        </div>
        <SearchField
          placeholder={getLocalizedValue(selectedLanguage, 't_search_meter_placeholder')}
          searchValue={isLandlord(responsibility) ? searchMeterNameLandlord: searchMeterNameTenant}
          onChange={searchMetersHandler}
          clearField={clearSearchField}
        />
      </div>
      <div className="dq-view__graph-section flex align-items-center">
        <div className="dq-view__graph-section__coverage">
          <OverlayPanel
            panelRef={dataCoverageOverlayRef}
            className='coverage-overlay-panel'
            appendTo='self'
            panelChildren={
              <InfoOverlayPanelContent
                iconId='info_solid'
                header='t_data_coverage'
                headerClassName='text-base'
                panelContent={<DataCategoriesInformation data='t_fund_data_coverage' className={'text-sm'}/>}
              />
            }
          />
          <DataQualityCoverageGraph
            coverageData={coverageData}
            selectedLanguage={selectedLanguage}
            areaUnit={areaUnit}
            actionHandler={dataCoverageInfoOverlay}
            isLoading={coverageDataLoading}
          />
        </div>
        <div className="dq-view__graph-section__completeness-wrapper">
          <div className="dq-view__graph-section__completeness">
            <DataQualityCompletenessGraph 
              completenessData={completenessDaysToPercentages(completenessData)} 
              selectedLanguage={selectedLanguage} 
              actionHandler={dataCompletenessInfoOverlay} 
              isLoading={completenessDataLoading}
            />
          </div>
          <OverlayPanel
            panelRef={dataCompletenessOverlayRef}
            className='completeness-overlay-panel'
            appendTo='self'
            panelChildren={
              <InfoOverlayPanelContent
                iconId='info_solid'
                header='t_data_completeness'
                headerClassName='text-base'
                panelContent={<DataCategoriesInformation data='t_fund_data_completeness' className={'text-sm'}/>}
              />
            }
          />
        </div>
      </div>
      <div className="dq-view__meter-table-section">
        {meterDataLoading ? <MeterTableTableSkeleton /> : <MeterTable {...getMeterTableProps()} />}
      </div>
      <div className="dq-view__footer-section">
        <Button
          content="t_add_consumption"
          onClick={() => onAddDataHandler()}
          outlined
          disabled={isMeterSelectionEmpty()}
        />
        <Button
          content="t_btn_request_consumption"
          onClick={() => setShowRequestDataDlg(true)}
          outlined
          disabled={isMeterSelectionEmpty()}
        />
      </div>
      {showRequestDataDlg &&  
          <RequestDataDialogV2
            showDialog={showRequestDataDlg}
            onEventActionHideHandler={onDialogActionHandler}
            data={isLandlord(responsibility) ? metersSelectedLandlord : metersSelectedTenant}
            assetAddress={rowData.assetAddress}
            sendDataHandler={sendDataHandler}
          />
      }
      {errorDialogVisible && <ShowErrorDialog />}
    </div>
  )
}

DataQualityContent.propTypes = {
  meterData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  meterDataLoading: PropTypes.bool,
  completenessData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  completenessDataLoading: PropTypes.bool,
  coverageData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  coverageDataLoading: PropTypes.bool,
  selectedLanguage: PropTypes.string,
  dataQualityState: PropTypes.object,
  updateDataQualityState: PropTypes.func,
  responsibility: PropTypes.string,
  areaUnit: PropTypes.string,
  sendDataHandler: PropTypes.func,
  onEnterDataHandler: PropTypes.func,
}

DataQualityContent.displayName = 'DataQualityContent'

export default DataQualityContent