import PropTypes from 'prop-types'
import './DashboardCoverageChart.scss'
import {Chart} from 'primereact/chart'
import {getCoverageChartData, getCoverageChartOptions} from '../../../pages/assets/data-quality/data-quality-hooks-&-helpers/dataQualityHelper'
import {Icon, Text} from '../../../atomic'
import {getLocalizedValue, formatPercentage} from '../../../../utils/helpers/Helper'
import { isEmptyObject } from 'utils/helpers/Utils'
import DashboardCoverageSkeleton from './skeleton/DashboardCoverageSkeleton'
import { getMinOrRoundedValue } from 'services/common/formatter'

const DashboardCoverageChart = ({coverageData, selectedLanguage, areaUnit, assetDashboard = false, isLoading}) => {
  if(isLoading) {
    return <DashboardCoverageSkeleton assetDashboard={assetDashboard} />
  }

  if(!coverageData || isEmptyObject(coverageData)) {
    return <Text content="t_msg_no_data" size="xs" />
  }

  const {coveragePercentage, areaCovered, areaNotCovered} = coverageData
  const className = !assetDashboard ? 'db-coverage-chart' : 'db-coverage-chart-assets'
  
  return (
    <div className={className}>
      <div className={`${className}__percent`}>
        <Text content={`${formatPercentage(coveragePercentage)}% ${getLocalizedValue(selectedLanguage, 't_data_coverage')}`} size="xs" weight="bold"/>
      </div>
      <div className={assetDashboard ? `${className}__chart-container`: ''}>
        {(areaCovered !== 0 || areaNotCovered !== 0) && (
          <>
            <div className={`${className}__chart`}>
              <Chart type="doughnut" data={getCoverageChartData(coverageData)} options={getCoverageChartOptions()}/>
            </div>
            <div className={assetDashboard ? `${className}__values-container`: ''}>
              <div className={`${className}__coverage`}>
                <Icon id="square_solid" colour="blue" size={10}/>
                <Text content={`${getMinOrRoundedValue(areaCovered).toLocaleString(selectedLanguage)}${areaUnit} ${getLocalizedValue(selectedLanguage, 't_coverage_by_floor_area')}`} size="xs"/>
              </div>
              <div className={`${className}__unaccounted`}>
                <Icon id="square_solid" colour="forest_20" size={10}/>
                <Text content={`${getMinOrRoundedValue(areaNotCovered).toLocaleString(selectedLanguage)}${areaUnit} ${getLocalizedValue(selectedLanguage, 't_floor_area_unaccounted_for')}`} size="xs"/>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

DashboardCoverageChart.propTypes = {
  coverageData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  selectedLanguage: PropTypes.string,
  areaUnit: PropTypes.string,
  assetDashboard: PropTypes.bool,
  isLoading: PropTypes.bool
}

DashboardCoverageChart.displayName = 'DashboardCoverageChart'

export default DashboardCoverageChart