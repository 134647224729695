import { InputTextarea } from 'primereact/inputtextarea'
import PropTypes from 'prop-types'
import './Textarea.scss'

const Textarea = ({
  placeholder,
  size,
  value,
  required,
  disabled,
  onChange,
  invalid,
  errorMessage,
  labelText,
  className,
  ...otherProps
}) => {
  const labelClassName = disabled ? 'p-disabled label-textarea' : 'label-textarea'
  const heightInRows = size === 'small' ? 3 : 5

  const getLabelText = () => {
    return labelText && (required ? `${labelText} *` : labelText)
  }

  const getClassName = () => {
    const sizeText = size === 'small' ? 'p-inputtextarea-sm' : 'p-inputtextarea-md'
    return (invalid ? `${sizeText} p-invalid ${className}` : `${sizeText} ${className}`).trim()
  }

  return (
    <div className="siera-text-area-container">
      {labelText && <label className={labelClassName}>{getLabelText()}</label>}
      <InputTextarea
        className={getClassName()}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChange}
        rows={heightInRows}
        {...otherProps}
      />
      {errorMessage && invalid && (
        <span className="textarea-error-message">{errorMessage}</span>
      )}
    </div>
  )
}

Textarea.propTypes = {
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['regular', 'small']),
  value: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  invalid: PropTypes.bool,
  errorMessage: PropTypes.string,
  labelText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
}

Textarea.defaultProps = {
  className: '',
}

export default Textarea