import React from 'react'
import './Tooltip.scss'
import { Tooltip } from 'primereact/tooltip'

const ToolTip = (props) => {
  /*
    props.target ? Uses primereact tooltip : Custom tooltip
   */
  return (
    props.target ?
      <Tooltip className={props.toolTipClassName ? props.toolTipClassName : 'common-tooltip'}
        position={props.position} target={props.target} autoHide={false}>
        {props.children}
      </Tooltip> :
      <div className={props.toolTipClassName ? `${props.toolTipClassName} tooltiptext` : 'common-tooltip tooltiptext'}>
        {props.children}
      </div>
  )
}

export default ToolTip