import XlsxPopulate from 'xlsx-populate'
import FileSaver from 'file-saver'
import {getLocalizedValue} from '../../../../../../utils/helpers/Helper'
import {signal} from '@preact/signals'

const propsForExcel = signal(null)
export async function generateTopDownPathwaysTemplate(props){
  propsForExcel.value = props
  const intensityType = propsForExcel.value.history.location.search.includes('carbon') ? 'carbon' : 'energy'
  const templateSheetName = intensityType === 'carbon' ? 't_top_down_carbon_intensity' : 't_top_down_energy_intensity'
  const blob = await XlsxPopulate.fromBlankAsync().then(workbook => {
    const sheetNames = [{label : getLocalizedValue(propsForExcel.value.selectedLanguage, 't_instructions'), value : 'instructions'},
      {label : getLocalizedValue(propsForExcel.value.selectedLanguage, templateSheetName), value : 'template' }]
    sheetNames.forEach((sheetName, index) => {
      const excelSheet = index === 0 ? workbook.sheet(0).name(sheetName.label) : workbook.addSheet(sheetName.label)
      const excelData = sheetData(sheetName,excelSheet)
      excelData.forEach((row, rowIndex) => {
        Object.keys(row).forEach((col, colIndex) => {
          excelSheet.cell(rowIndex + 1, colIndex + 1).value(row[col])
        })
      })
    })
    workbook.activeSheet(sheetNames[0].label)
    return workbook.outputAsync(workbook)
  })
  FileSaver.saveAs(blob, getLocalizedValue(propsForExcel.value.selectedLanguage, 't_top_down_pathways_template_filename'))
}

function sheetData(sheetName,excelSheet){
  return sheetName.value === 'instructions' ? getInstructions(excelSheet) : getTemplate(excelSheet)
}

function getInstructions(excelSheet){
  const sheetData = [propsForExcel.value.history.location.search.includes('carbon') ? 't_top_down_pathways_carbon_template_title' : 't_top_down_pathways_energy_template_title', 't_how_to_use_this_template','t_top_down_template_instruction_1', 't_top_down_template_instruction_2',
    't_top_down_template_instruction_3', 't_top_down_template_instruction_4', 't_top_down_template_instruction_5', 't_top_down_template_instruction_6', 't_top_down_template_instruction_7']

  const instructionsData = sheetData.map(item => ({
    A: getLocalizedValue(propsForExcel.value.selectedLanguage, item)
  }))

  excelSheet.cell('A1').style({bold:true, fontSize : 16})
  excelSheet.cell('A2').style({bold:true, fontSize : 16})
  return instructionsData

}
function getTemplate(excelSheet){

  const header = {A:getLocalizedValue(propsForExcel.value.selectedLanguage, 't_pathway_name'), B:2019, C: 2020, D:2021,
    E:2022,F:2023,G:2024,H:2025,I:2026,J:2027,K:2028,L:2029,M:2030,N:2031,O:2032,P:2033,Q:2034,R:2035,S:2036,T:2037,U:2038,V:2039,W:2040,
    X:2041,Y:2042,Z:2043,AA:2044,AB:2045,AC:2046,AD:2047,AE:2048,AF:2049,AG:2050}

  const pathway = getLocalizedValue(propsForExcel.value.selectedLanguage, 't_pathway')

  let templateData = Array.from({ length: 7 }, (obj, i) => ({ A: `${pathway} ${i+1}` }))
  templateData.unshift(header)

  excelSheet.range('A1:AG1').style({bold:true, fontSize:14})
  excelSheet.range('A1:AG8').style({horizontalAlignment:'center'})
  return templateData
}
