import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown as PrimeDropdown } from 'primereact/dropdown'
import './Dropdown.scss'
import Text from '../Text/Text'

const Dropdown = ({
  colour,
  size,
  value,
  options,
  onChange,
  placeholderKey,
  optionLabel,
  disabled,
  testId,
  error,
  errorMessageKey,
  filter,
  name
}) => {
  return (
    <>
      <PrimeDropdown
        value={value}
        options={options}
        onChange={onChange}
        name={name}
        placeholder={placeholderKey && <Text content={placeholderKey} colour={colour === 'light' ? 'teal' : 'white'} testId={testId ? `${testId}-placeholder` : null}/>}
        optionLabel={optionLabel}
        disabled={disabled}
        data-testid={testId}
        className={`siera-dropdown--${colour} siera-dropdown--${size} ${error ? 'p-invalid' : ''}`}
        panelClassName={`siera-dropdown-panel--${colour} siera-dropdown-panel--${size}`}
        filter={filter}
      />
      {error && errorMessageKey && (
        <Text content={errorMessageKey} className="red mt-1" testId={testId ? `${testId}-error` : null}/>
      )}
    </>
  )
}

Dropdown.propTypes = {
  colour: PropTypes.oneOf(['light', 'dark']),
  size: PropTypes.oneOf(['sm', 'lg']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
      ]).isRequired,
      value: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  placeholderKey: PropTypes.string,
  optionLabel: PropTypes.string,
  testId: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorMessageKey: PropTypes.string,
  filter: PropTypes.bool,
  name: PropTypes.string
}

Dropdown.defaultProps = {
  colour: 'light',
  size: 'sm',
  optionLabel: 'label',
}

export default Dropdown