import I18n from '../../../utils/i18n/I18n'
import {Chip} from 'primereact/chip'
import * as Yup from 'yup'
import {
  ActionImpactList,
  areaCoveredMask,
  costMask,
  costTypeMask,
  leadingZeroRegex,
  regexNumbers
} from '../../../utils/helpers/Constants'
import commentBlue from '../../../resources/images/chart/comment-blue.svg'
import {
  CurrencyUnitToLabel,
  dropdownItemTemplate,
  formatUnits,
  getLocalizedValue,
  handleLengthyName,
  unitDateFormatter,
  unitsConverter
} from '../../../utils/helpers/Helper'
import {Dropdown} from 'primereact/dropdown'
import {Calendar, Icon, Text, Tooltip} from '../../atomic'
import {InputText} from 'primereact/inputtext'

export const statusList = [
  {label: I18n('t_agreed'), value: 'AgreedActions'},
  {label: I18n('t_applicable'), value: 'Applicable'},
  {label: I18n('t_under_review'), value: 'ActionsUnderReview'},
  {label: I18n('t_completed'), value: 'Completed'},
  {label: I18n('t_non_applicable'), value: 'NotApplicable'},
]


export const actionTemplate = (rowData) => {
  return <div className="perf-  col-text5">{rowData.action.actionDescription}</div>
}

export const impactTemplate = (rowData) => {
  return <div className="perf-col-text5">{rowData.action.impact ? I18n('t_' + rowData.action.impact.replace('&', 'and').replace(' ', '_').toLowerCase()) : '-'}</div>
}

export const managerTemplate = (rowData) => {
  return <div className="perf-col-text5">{rowData.action.manager ? rowData.action.manager : '-'}</div>
}

const actionStatusLabel = statusList.reduce((acc, item) => {
  acc[item.value] = item.label
  return acc
}, {})

export const actionTemplateV2 = (rowData) => {
  let statusClassName
  let statusLabel
  const currentDate = new Date(rowData.dueDate)
  const isPastDate = currentDate < new Date()
  if(rowData.status === 'Completed' || rowData.completion) {
    statusClassName = 'status-completed'
    statusLabel = I18n('t_completed')
  } else if(rowData.dueDate && isPastDate) {
    statusClassName = 'status-overdue'
    statusLabel = I18n('t_overdue')
  } else {
    statusLabel = actionStatusLabel[rowData.status]
  }
  const tooltipClassName = rowData?.actionDescription?.length > 50 ? `action-${rowData?.actionId}` : ''
  return <>
    {rowData?.actionDescription?.length > 50 &&
    <Tooltip className={`action-title-${rowData?.actionId}`} target={`.action-${rowData?.actionId}`} padding="small" width={'350'}>
      <Text content={rowData?.actionDescription} />
    </Tooltip>
    }
    <div className={`perf-col-text5 ${tooltipClassName}`}>{handleLengthyName(rowData.actionDescription, 50)}</div>
    <Chip className={statusClassName} role={'action-status'} label={statusLabel} />
  </>
}

export const impactTemplateV2 = (rowData) => {
  return <div className="perf-col-text5">{rowData.impact ? I18n('t_' + rowData.impact.replace('&', 'and').replace(' ', '_').toLowerCase()) : '-'}</div>
}

export const scheduledDateTemplateV2 = (rowData, dataTableParams) => {
  let date = ''
  if (rowData.dueDate) {
    date = unitDateFormatter(rowData.dueDate, dataTableParams.unitSystem, dataTableParams.selectedLanguage)
  }
  return <div>{date !== '' ? <div className='perf-col-text3'>{date}</div> : '-'}</div>
}

export const estimatedChangeTemplateV2 = (rowData, dataTableParams) => {
  function currentUnit(data) {
    const unit = unitsConverter(data, dataTableParams.unitSystem)
    return unit.toString()
  }
  
  let estimatedChange = '-'
  if (rowData.estimatedChange) {
    estimatedChange = formatUnits(rowData.estimatedChange) + currentUnit(rowData.impact)
  }
  return <div className="perf-col-text3">{estimatedChange === '-' ? '-' : estimatedChange}</div>
}

export const costTemplateV2 = (rowData, dataTableParams,selectedLanguage) => {
  let costValue = '-'
  let val = '-'
  let currencyLabel
  if(dataTableParams.featureState.sieraplus_currency){
    currencyLabel = costMask[CurrencyUnitToLabel(dataTableParams.currencyUnit)]
  } else {
    currencyLabel = rowData.currency ? costMask[rowData.currency] : costMask[CurrencyUnitToLabel(dataTableParams.currencyUnit)]
  }
  if (rowData.cost) {
    costValue = Number(rowData.cost).toLocaleString(selectedLanguage,{maximumFractionDigits: 0 })
    val = <div>{currencyLabel}{costValue}</div>
  }
  return val
}

export const costTypeTemplateV2 = (rowData) => {
  return <div className="perf-col-text5">{costTypeMask[rowData.costType]}</div>
}

export const areaCoveredTemplateV2 = (rowData) => {
  return <div className="perf-col-text5">
    {rowData.areaCovered ? areaCoveredMask[rowData.areaCovered] : '-' }
  </div>
}

export const scopeTemplateV2 = (rowData) => {
  let scope = '-'
  if(rowData.scope.length > 0) {
    scope = rowData.scope.join(', ')
  }
  return <div className="perf-col-text5">{scope}</div>
}

export const modifiedDateTemplateV2 = (rowData, dataTableParams) => {
  let date = ''
  if (rowData.lastModified) {
    date = unitDateFormatter(rowData.lastModified, dataTableParams.unitSystem, dataTableParams.selectedLanguage)
  }
  return <div>{date !== '' ? <div className='perf-col-text3'>{date}</div> : '-'}</div>
}

export const createdByTemplateV2 = (rowData) => {
  return <div className="perf-col-text5">{rowData.createdBy ? rowData.createdBy : '-'}</div>
}

export const dateCreatedTemplateV2 = (rowData, dataTableParams) => {
  let date = ''
  if (rowData.dateCreated) {
    date = unitDateFormatter(rowData.dateCreated, dataTableParams.unitSystem, dataTableParams.selectedLanguage)
  }
  return <div>{date !== '' ? <div className='perf-col-text3'>{date}</div> : '-'}</div>
}

export const notesTemplateV2 = (rowData) => {
  const notesCountLengthBelowTen = 9
  const notesCountMoreThanHundred = 99
  return (
    <div className='relative'>
      {rowData?.notes?.length > 0 ?
        <img src={commentBlue} alt="note-icon" data-id={rowData.actionId} className="note-icon"/> : '-'}
      {rowData?.notes?.length > 0 &&
            <span className={rowData?.notes?.length > notesCountLengthBelowTen ?  'column-note-count-more' : 'column-note-count'}>
              {rowData?.notes?.length > notesCountMoreThanHundred ? '99+' : rowData.notes?.length}
            </span>
      }
    </div>
  )
}

export const statusTemplate = (rowData) => {
  let colorDot = 'red-dot'
  if (rowData.status === 'Yellow') {
    colorDot = 'yellow-dot'
  } else if (rowData.status === 'Green') {
    colorDot = 'green-dot'
  }
  else if (rowData.status === 'Red') {
    colorDot = 'red-dot'
  }else if (rowData.status === 'White') {
    colorDot = 'white-dot'
  }
  return (
    <div className="center-dot" style={{fontSize:'medium'}}>
      <div className={colorDot}/>
    </div>
  )
}

export const actionStatusList = [
  {label: I18n('t_agreed'), value: 'Agreed', msgKey: 'agreed'},
  {label: I18n('t_overdue'), value: 'Overdue', msgKey: 'overdue'},
  {label: I18n('t_completed'), value: 'Completed', msgKey: 'completed'},
  {label: I18n('t_applicable'), value: 'Applicable', msgKey: 'applicable'},
  {label: I18n('t_under_review'), value: 'UnderReview', msgKey: 'under_review'},
]

export const costTypeList = [
  {label: I18n('t_revenue_service'), value: 'RevenueServiceCharge'},
  {label: I18n('t_capital_non_recover'), value: 'CapitalNonRecoverable'},
  {label: I18n('t_capital_recover'), value: 'CapitalRecoverable'},
  {label: I18n('t_none'), value: 'None'},
]

export const areaCoveredList = [
  {label: I18n('t_exterior'), value: 'Exterior'},
  {label: I18n('t_tenant_services'), value: 'TenantServices'},
  {label: I18n('t_wholebuilding'), value: 'WholeBuilding'},
  {label: I18n('t_commonareas'), value: 'CommonAreas'},
  {label: I18n('t_shared_services'), value: 'SharedServices'},
]

export const impactList = [
  {label: I18n('t_biodiversity'), value: 'Biodiversity', msgKey: 'bio_diversity', apiResponseKey: 'biodiversity'},
  {label: I18n('t_building_performance'), value: 'Building Performance', msgKey: 'building_performance', apiResponseKey: 'buildingPerformance'},
  {label: I18n('t_community_engagement'), value: 'Community Engagement', msgKey: 'community_engagement', apiResponseKey: 'communityEngagement'},
  {label: I18n('t_energy'), value: 'Energy', msgKey: 'energy', apiResponseKey: 'energy'},
  {label: I18n('t_governance_recommendations'), value: 'Governance Recommendations', msgKey: 'governance_recommended', apiResponseKey: 'governanceRecommendations'},
  {label: I18n('t_health_and_wellbeing'), value: 'Health & Wellbeing', msgKey: 'health_wellbeing', apiResponseKey: 'healthAndWellbeing'},
  {label: I18n('t_social_value'), value: 'Social Value', msgKey: 'social_value', apiResponseKey: 'socialValue'},
  {label: I18n('t_tenant_engagement'), value: 'Tenant Engagement', msgKey: 'tenant_engagement', apiResponseKey: 'tenantEngagement'},
  {label: I18n('t_waste'), value: 'Waste', msgKey: 'waste', apiResponseKey: 'waste'},
  {label: I18n('t_water'), value: 'Water', msgKey: 'water', apiResponseKey: 'water'},
  {label: I18n('t_other'), value: 'Other', msgKey: 'other', apiResponseKey: 'other'}
]

export const utilityTypeList = [
  {label: I18n('t_electricity'), value: 'Electricity', impactTarget: ['Energy']},
  {label: I18n('t_gas'), value: 'Gas', impactTarget: ['Energy']},
  {label: I18n('t_district_heating'), value: 'DistrictHeating', impactTarget: ['Energy']},
  {label: I18n('t_district_cooling'), value: 'DistrictCooling', impactTarget: ['Energy']},
  {label: I18n('t_oil'), value: 'Oil', impactTarget: ['Energy']},
  {label: I18n('t_water'), value: 'Water', impactTarget: ['Water']},
  {label: I18n('t_waste_utility'), value: 'Waste', impactTarget: ['Waste']},
  {label: I18n('t_not_available'), value: 'NotApplicable', impactTarget: ['Biodiversity', 'Building Performance', 'Community Engagement', 'Governance Recommendations', 'Health & Wellbeing', 'Social Value', 'Tenant Engagement', 'Other']}
]

export const gresbMeasureList = [
  {label: I18n('t_auto_meter_readings'), value: 'AMR', impactTarget: ['Energy', 'Water']},
  {label: I18n('t_auto_system_upgrades'), value: 'AutomationSystemUpgradesOrReplacements', impactTarget: ['Energy']},
  {label: I18n('t_installation_equipment'), value: 'InstallationOfHighEfficiencyEquipment', impactTarget: ['Energy']},
  {label: I18n('t_installation_renewable'), value: 'InstallationOfOnSiteRenewableEnergy', impactTarget: ['Energy']},
  {label: I18n('t_management_sys_upgrade'), value: 'ManagementSystemsUpgradesOrReplacements', impactTarget: ['Energy']},
  {label: I18n('t_occupier_eng_tech'), value: 'OccupierEngagementOrInformationalTechnologies', impactTarget: ['Energy']},
  {label: I18n('t_smart_grid_tech'), value: 'SmartGridOrSmartBuildingTechnologies', impactTarget: ['Energy']},
  {label: I18n('t_sys_commissioning'), value: 'SystemsCommissioningOrRetroCommissioning', impactTarget: ['Energy']},
  {label: I18n('t_wall_roof_inst'), value: 'WallOrRoofInsulation', impactTarget: ['Energy']},
  {label: I18n('t_window_replacement'), value: 'WindowReplacements', impactTarget: ['Energy']},
  {label: I18n('t_composting_landscape'), value: 'CompostingLandscapeOrFoodWaste', impactTarget: ['Waste']},
  {label: I18n('t_ongoing_waste_monitoring'), value: 'OngoingWastePerformanceMonitoring', impactTarget: ['Waste']},
  {label: I18n('t_recycling'), value: 'Recycling', impactTarget: ['Waste']},
  {label: I18n('t_waste_management'), value: 'WasteManagement', impactTarget: ['Waste']},
  {label: I18n('t_waste_stream_audit'), value: 'WWasteStreamAudit', impactTarget: ['Waste']},
  {label: I18n('t_cooling_tower'), value: 'CoolingTower', impactTarget: ['Water']},
  {label: I18n('t_drip_irrigation'), value: 'DripOrSmartIrrigation', impactTarget: ['Water']},
  {label: I18n('t_drought_tolerant_landscaping'), value: 'DroughtTolerantOrNativeLandscaping', impactTarget: ['Water']},
  {label: I18n('t_high_efficiency_fixtures'), value: 'HighEfficiencyOrDryFixtures', impactTarget: ['Water']},
  {label: I18n('t_leak_detection_system'), value: 'LeakDetectionSystem', impactTarget: ['Water']},
  {label: I18n('t_meter_water_subsys'), value: 'MeteringOfWaterSubsystems', impactTarget: ['Water']},
  {label: I18n('t_onsite_waste_water_treatment'), value: 'OnSiteWasteWaterTreatment', impactTarget: ['Water']},
  {label: I18n('t_reuse_storm_water'), value: 'ReuseOfStormWaterOrGreyWater', impactTarget: ['Water']},
  {label: I18n('t_not_available'), value: 'NotApplicable', impactTarget: ['Biodiversity', 'Building Performance', 'Community Engagement', 'Governance Recommendations', 'Health & Wellbeing', 'Social Value', 'Tenant Engagement', 'Other']},
  {label: I18n('t_gresb_measure_water'), value: 'PerformedATechnicalBuildingAssessmentToIdentifyWaterEfficiencyImprovements', impactTarget: ['Water']},
  {label: I18n('t_gresb_measure_waste'), value: 'PerformedATechnicalBuildingAssessmentToIdentifyWasteEfficiencyImprovements', impactTarget: ['Waste']},
  {label: I18n('t_gresb_measure_energy'), value: 'PerformedATechnicalBuildingAssessmentToIdentifyEnergyEfficiencyImprovements', impactTarget: ['Energy']}
]

export const gresbMeasureListV2 = [
  {label: I18n('t_auto_meter_readings'), value: 'AMR', impactTarget: [ActionImpactList?.Energy, ActionImpactList?.Water]},
  {label: I18n('t_auto_system_upgrades'), value: 'AutomationSystemUpgradesOrReplacements', impactTarget: [ActionImpactList?.Energy]},
  {label: I18n('t_installation_equipment'), value: 'InstallationOfHighEfficiencyEquipment', impactTarget: [ActionImpactList?.Energy]},
  {label: I18n('t_installation_renewable'), value: 'InstallationOfOnSiteRenewableEnergy', impactTarget: [ActionImpactList?.Energy]},
  {label: I18n('t_management_sys_upgrade'), value: 'ManagementSystemsUpgradesOrReplacements', impactTarget: [ActionImpactList?.Energy]},
  {label: I18n('t_occupier_eng_tech'), value: 'OccupierEngagementOrInformationalTechnologies', impactTarget: [ActionImpactList?.Energy]},
  {label: I18n('t_smart_grid_tech'), value: 'SmartGridOrSmartBuildingTechnologies', impactTarget: [ActionImpactList?.Energy]},
  {label: I18n('t_sys_commissioning'), value: 'SystemsCommissioningOrRetroCommissioning', impactTarget: [ActionImpactList?.Energy]},
  {label: I18n('t_wall_roof_inst'), value: 'WallOrRoofInsulation', impactTarget: [ActionImpactList?.Energy]},
  {label: I18n('t_window_replacement'), value: 'WindowReplacements', impactTarget: [ActionImpactList?.Energy]},
  {label: I18n('t_composting_landscape'), value: 'CompostingLandscapeOrFoodWaste', impactTarget: [ActionImpactList?.Waste]},
  {label: I18n('t_ongoing_waste_monitoring'), value: 'OngoingWastePerformanceMonitoring', impactTarget: [ActionImpactList?.Waste]},
  {label: I18n('t_recycling'), value: 'Recycling', impactTarget: [ActionImpactList?.Waste]},
  {label: I18n('t_waste_management'), value: 'WasteManagement', impactTarget: [ActionImpactList?.Waste]},
  {label: I18n('t_waste_stream_audit'), value: 'WWasteStreamAudit', impactTarget: [ActionImpactList?.Waste]},
  {label: I18n('t_cooling_tower_drip'), value: 'CoolingTowerDripSmartIrrigation', impactTarget: [ActionImpactList?.Water]},
  {label: I18n('t_drought_tolerant_landscaping'), value: 'DroughtTolerantOrNativeLandscaping', impactTarget: [ActionImpactList?.Water]},
  {label: I18n('t_high_efficiency_fixtures'), value: 'HighEfficiencyOrDryFixtures', impactTarget: [ActionImpactList?.Water]},
  {label: I18n('t_leak_detection_system'), value: 'LeakDetectionSystem', impactTarget: [ActionImpactList?.Water]},
  {label: I18n('t_meter_water_subsys'), value: 'MeteringOfWaterSubsystems', impactTarget: [ActionImpactList?.Water]},
  {label: I18n('t_onsite_waste_water_treatment_reuse_of_storm_water'), value: 'OnSiteWasteWaterTreatment', impactTarget: [ActionImpactList?.Water]},
  {label: I18n('t_not_available'), value: 'NotApplicable', impactTarget: [ActionImpactList?.Biodiversity, ActionImpactList?.Building_Performance, ActionImpactList?.Community_Engagement, ActionImpactList?.Governance_Recommendations, ActionImpactList?.Health_And_Wellbeing, ActionImpactList?.Social_Value, ActionImpactList?.Other]},
  {label: I18n('t_gresb_measure_energy'), value: 'PerformedATechnicalBuildingAssessmentToIdentifyEnergyEfficiencyImprovements', impactTarget: [ActionImpactList?.Energy]},
  {label: I18n('t_building_specific_communication'), value: 'BuildingSpecificCommunication', impactTarget: [ActionImpactList?.Tenant_Engagement]},
  {label: I18n('t_feedback_sessions_individual_tenants'), value: 'FeedbackSessionsWithIndividualTenants', impactTarget: [ActionImpactList?.Tenant_Engagement]},
  {label: I18n('t_other'), value: 'Other', impactTarget: [ActionImpactList?.Tenant_Engagement]},
  {label: I18n('t_social_media_online_platform'), value: 'SocialMediaOnlinePlatform', impactTarget: [ActionImpactList?.Tenant_Engagement]},
  {label: I18n('t_tenant_meetings_which_includes'), value: 'TenantMeetingsWhichIncludesSustainability', impactTarget: [ActionImpactList?.Tenant_Engagement]},
  {label: I18n('t_tenant_sustainability_events'), value: 'TenantSustainabilityEvents', impactTarget: [ActionImpactList?.Tenant_Engagement]},
  {label: I18n('t_tenant_sustainability_guide'), value: 'TenantSustainabilityGuide', impactTarget: [ActionImpactList?.Tenant_Engagement]},
  {label: I18n('t_tenant_sustainability_training'), value: 'TenantSustainabilityTraining', impactTarget: [ActionImpactList?.Tenant_Engagement]},
  {label: I18n('t_Tenants_feedback_energy_water_waste'), value: 'TenantsFeedbackEnergyWaterWaste', impactTarget: [ActionImpactList?.Tenant_Engagement]},
]

export const costCurrency = [
  {label: '£ GBP', value: 'GBP'},
  {label: '€ EUR', value: 'EUR'},
  {label: '$ USD', value: 'USD'}
]

export const procuredByList = [
  {label: I18n('t_landlord'), value: 'Landlord'},
  {label: I18n('t_tenant'), value: 'Tenant'},
]

export const scopeList = [
  {label: I18n('t_scope_index',{0:1}), value: 'Scope1'},
  {label: I18n('t_scope_index',{0:2}), value: 'Scope2'},
  {label: I18n('t_scope_index',{0:3}), value: 'Scope3'},
]

export const getValidFilters = (filters) => {
  return {
    ...Object.fromEntries(
      Object.entries(filters)
        .filter(([_, value]) => {
          return !!value
        })
        .map(([key, value]) => {
          return [key, value.value]
        })
    )
  }
}

export const actionFilterQueryKeyValueMapper = {
  'AgreedActions': I18n('t_agreed'),
  'Applicable': I18n('t_applicable'),
  'ActionsUnderReview': I18n('t_under_review'),
  'Completed': I18n('t_completed'),
  'NotApplicable': I18n('t_non_applicable'),
  'RevenueServiceCharge': I18n('t_revenue_service'),
  'CapitalNonRecoverable': I18n('t_capital_non_recover'),
  'CapitalRecoverable': I18n('t_capital_recover'),
  'None': I18n('t_none'),
  'Biodiversity': I18n('t_biodiversity'),
  'Building Performance': I18n('t_building_performance'),
  'Community Engagement': I18n('t_community_engagement'),
  'Energy': I18n('t_energy'),
  'Governance Recommendations': I18n('t_governance_recommendations'),
  'Health & Wellbeing': I18n('t_health_and_wellbeing'),
  'Social Value': I18n('t_social_value'),
  'Tenant Engagement': I18n('t_tenant_engagement'),
  'Waste': I18n('t_waste'),
  'Water': I18n('t_water'),
  'Other': I18n('t_other'),
  'Scope1': I18n('t_scope_index', {0: 1}),
  'Scope2': I18n('t_scope_index', {0: 2}),
  'Scope3': I18n('t_scope_index', {0: 3}),
  'Landlord': I18n('t_landlord'),
  'Tenant': I18n('t_tenant'),
}
export const currentPageReport = (props) => {
  return <span>{props.first} - {props.last} of {props.totalRecords}</span>
}

// region : initial values
export const addAnActionDefaultSelections = (actionFormCurrency) => {
  return {
    actionDescription:null,
    manager:null,
    status:null,
    impact: null,
    performanceMeasure: null,
    costType:null,
    currency: actionFormCurrency,
    areaCovered:null,
    cost:null,
    dueDate: null,
    completion: null,
    primaryUtility:null,
    primaryEstimatedSavingsCost:null,
    primaryUtilityCost: null,
    secondaryUtility:null,
    secondaryEstimatedSavingsCost:null,
    secondaryUtilityCost: null,
  }
}

export const editAnActionPrefilledSelections = (actionDetailsData, actionFormCurrency,selectedLanguage) => {
  return {
    ...actionDetailsData,
    currency: actionFormCurrency,
    dueDate: actionDetailsData?.dueDate ? new Date(actionDetailsData.dueDate) : null,
    completion: actionDetailsData?.completion ? new Date(actionDetailsData.completion) : null,
    cost: actionDetailsData?.cost ? Number(actionDetailsData?.cost).toLocaleString(selectedLanguage,{maximumFractionDigits: 0 }) : '',
    primaryUtility:actionDetailsData?.utilitySavings[0]?.utilityType ? actionDetailsData?.utilitySavings[0]?.utilityType : null,
    primaryEstimatedSavingsCost:actionDetailsData?.utilitySavings[0]?.estimatedSavings ? formatUnits(actionDetailsData?.utilitySavings[0]?.estimatedSavings,selectedLanguage) : null,
    primaryUtilityCost: actionDetailsData?.utilitySavings[0]?.utilityCostChange ? actionDetailsData?.utilitySavings[0]?.utilityCostChange : null,
    secondaryUtility:actionDetailsData?.utilitySavings[1]?.utilityType ? actionDetailsData?.utilitySavings[1]?.utilityType : null,
    secondaryEstimatedSavingsCost:actionDetailsData?.utilitySavings[1]?.estimatedSavings ? formatUnits(actionDetailsData?.utilitySavings[1]?.estimatedSavings,selectedLanguage) : null,
    secondaryUtilityCost: actionDetailsData?.utilitySavings[1]?.utilityCostChange ? actionDetailsData?.utilitySavings[1]?.utilityCostChange : null,
  }
}

export const actionFieldsToExclude = [
  'utilitySavings',
  'primaryEstimatedSavingsCost',
  'primaryUtility',
  'primaryUtilityCost',
  'secondaryEstimatedSavingsCost',
  'secondaryUtility',
  'secondaryUtilityCost',
]
// endregion

export const validationSchema = Yup.object().shape({
  impact: Yup.string().nullable().required(I18n('t_action_impact_error_message')),
  areaCovered: Yup.string().nullable().required(I18n('t_action_area_covered_error_message')),
  actionDescription: Yup.string().nullable().required(I18n('t_action_title_error_msg')),
  status: Yup.string().nullable().required(I18n('t_action_status_error_message')),
  primaryUtility: Yup.string().when(['impact'], {
    is: (impactValue) => {
      const acceptedValues = [ActionImpactList?.Energy]
      return acceptedValues.includes(impactValue)
    },
    then: Yup.string().nullable().required(I18n('t_primary_utility_error_message')),
    otherwise: Yup.string().nullable()
  }),
  primaryEstimatedSavingsCost: Yup.string().when(['impact'], {
    is: (impactValue) => {
      const acceptedValues = [ActionImpactList?.Energy, ActionImpactList?.Waste, ActionImpactList?.Water]
      return acceptedValues.includes(impactValue)
    },
    then: Yup.string().nullable().required(I18n('t_primary_utility_cost_error_msg')),
    otherwise: Yup.string().nullable()
  }),
  primaryUtilityCost: Yup.string().when(['impact'], {
    is: (impactValue) => {
      const acceptedValues = [ActionImpactList?.Energy, ActionImpactList?.Waste, ActionImpactList?.Water]
      return acceptedValues.includes(impactValue)
    },
    then: Yup.string().nullable().required(I18n('t_primary_utility_cost_error_msg')),
    otherwise: Yup.string().nullable()
  })
})

export const renderActionField = (actionFieldProps) => {
  const {fieldProps, fieldType, handleFieldChange, isFormFieldInvalid, formik, dateFormatType, getDropdownItemTemplateForUtility, getUtilityDropdownItemDisableOption} = actionFieldProps
  const field = fieldProps.field
  let dropdownTemplate
  let dropdownOptionsDisabled
  if(field === 'primaryUtility' || field === 'secondaryUtility'){
    dropdownTemplate = getDropdownItemTemplateForUtility
    dropdownOptionsDisabled = getUtilityDropdownItemDisableOption
  } else {
    dropdownTemplate = dropdownItemTemplate
    dropdownOptionsDisabled = false
  }
  if(fieldType === 'dropdown') {
    return (
      <Dropdown
        inputId={field}
        name={field}
        value={fieldProps.value}
        options={fieldProps.options}
        itemTemplate={dropdownTemplate}
        placeholder={fieldProps.placeholder}
        className={`${field}-dropdown ${isFormFieldInvalid && 'validation-error'}`}
        onChange={handleFieldChange}
        onBlur={() => !fieldProps.value && formik.setFieldTouched(field, true)}
        data-testid={`action-${fieldProps.field}`}
        panelClassName={`${field}-dropdown-panel`}
        optionDisabled={dropdownOptionsDisabled}
      />
    )
  } else if(fieldType === 'calendar') {
    return (
      <Calendar
        id={field}
        name={field}
        value={fieldProps.value}
        className={isFormFieldInvalid ? 'p-error-border' : ''}
        onChange={handleFieldChange}
        dateFormat={dateFormatType}
        maxDate={fieldProps.today}
      />
    )
  } else {
    return (
      <InputText
        id={field}
        name={field}
        value={fieldProps.value}
        keyfilter={fieldProps.keyfilter}
        className={`action-inputtext ${field}-input ${isFormFieldInvalid && 'validation-error'}`}
        {...(fieldProps.maxLength>0? {maxLength : `${fieldProps.maxLength}`}:{})}
        onChange={handleFieldChange}
        data-testid={`action-${field}`}
        aria-label={field}
        autoComplete='off'
      />
    )
  }
}

export const renderSectionInfo = (title, description = '') => {
  return (
    <div className="add-action-container__form--info">
      <span className='section-title'>{title}</span>
      {description && <span className='section-description'>{description}</span>}
    </div>
  )
}

export const handleUserInputChange = (formik, e, field) => {
  let userInput
  const actionFieldsToInclude = [
    'primaryEstimatedSavingsCost',
    'primaryUtilityCost',
    'secondaryEstimatedSavingsCost',
    'secondaryUtilityCost',
  ]
  if(actionFieldsToInclude.includes(field)) {
    const newValue = e.target.value
    const leadingZeros = newValue.replace(leadingZeroRegex, '')
    userInput = leadingZeros.replace(regexNumbers, '')
    if ((userInput.match(/-/g) || []).length > 1 || userInput.indexOf('-') > 0) {
      return
    }

    if ((userInput.match(/\./g) || []).length > 1) {
      return
    }
  } else {
    let value = e.target.value
    const check = ['actionDescription', 'manager'].includes(field)
    if (check && value.startsWith(' ')) {
      value = value.trimStart()
    }
    const inputValue = check ? value.replace(/\s{2,}/g, ' ') : value
    userInput = field !== 'cost' || e.target.value === '' ? inputValue : Number(e.target.value.replace(/,/g, '')).toLocaleString('en-US')
  }
  formik.setFieldValue(field, userInput)
}

export const actionDetailsCard = ( label, value, infoIcon, infoContent, handleOverlayPanel, tooltipTarget ) => {
  const informationIcon = infoIcon ? <span onClick={(e) => handleOverlayPanel(e, label, infoContent)}><Icon id='info_solid' /></span> : ''
  const actionValue = value || '-'
  const actionValueTooltip = tooltipTarget || ''
  return (
    <>
      <span className='asset-action-details__cards--card__label'><Text className={'faded_teal'} content={label}/> {informationIcon}</span>
      <Text className={`asset-action-details__cards--card__value ${actionValueTooltip}`} content={actionValue}/>
    </>
  )
}

export const getPaybackPeriod = (paybackPeriod,selectedLanguage) => {
  const zeroPaybackPeriod = paybackPeriod === 0
  const yearLabel = paybackPeriod !== 1 || zeroPaybackPeriod ? getLocalizedValue(selectedLanguage,'t_multiple_years') : getLocalizedValue(selectedLanguage,'t_single_year')
  return paybackPeriod || zeroPaybackPeriod ? Math.round(paybackPeriod) + ` ${yearLabel}` : paybackPeriod
}

export const ACTION_PLAN_COLUMNS = {
  asset : {
    actionId : 'Action ID (Recommendation ID)',
    actionTitle : 'Action Title',
    manager : 'Manager',
    status : 'Status',
    impact : 'Impact',
    gresbEfficiencyMeasure : 'GRESB Efficiency measure',
    areaCovered : 'Area Covered',
    scheduleDate : 'Schedule date',
    completionDate : 'Completion date',
    costType : 'Cost type',
    currency : 'Currency',
    costOfAction : 'Cost of action',
    estimatedChange : 'Estimated Change',
    primaryUtility: 'Primary Utility',
    primaryUtilityPerYear: 'Primary Utility per year',
    primaryCostPerYear: 'Primary Cost per year',
    secondaryUtility:'Secondary Utility',
    secondaryUtilityPerYear: 'Secondary Utility per year',
    secondaryCostPerYear: 'Secondary Cost per year',
    paybackPeriod : 'Payback Period',
    scope : 'Scope',
    procuredBy : 'Procured by',
    createdDate : 'Created Date',
    modifiedDate : 'Modified Date',
    createdBy : 'Created by'

  }
}

export const statusListForExport = (selectedLanguage) => {
  return [
    {label: getLocalizedValue(selectedLanguage,'t_agreed'), value: 'AgreedActions'},
    {label: getLocalizedValue(selectedLanguage,'t_applicable'), value: 'Applicable'},
    {label: getLocalizedValue(selectedLanguage,'t_under_review'), value: 'ActionsUnderReview'},
    {label: getLocalizedValue(selectedLanguage,'t_completed'), value: 'Completed'},
    {label: getLocalizedValue(selectedLanguage,'t_non_applicable'), value: 'NotApplicable'},
    {label: getLocalizedValue(selectedLanguage,'t_overdue'), value: 'Overdue'},
  ]
}
export const areaCoveredListForExport = (selectedLanguage) => {
  return [
    {label: getLocalizedValue(selectedLanguage,'t_exterior'), value: 'Exterior'},
    {label: getLocalizedValue(selectedLanguage,'t_tenant_services'), value: 'TenantServices'},
    {label: getLocalizedValue(selectedLanguage,'t_wholebuilding'), value: 'WholeBuilding'},
    {label: getLocalizedValue(selectedLanguage,'t_commonareas'), value: 'CommonAreas'},
    {label: getLocalizedValue(selectedLanguage,'t_shared_services'), value: 'SharedServices'},
    {label: getLocalizedValue(selectedLanguage,'t_landlord'), value: 'Landlord'},

  ]
}

export const impactListForExport = (selectedLanguage) => {
  return [
    {label: getLocalizedValue(selectedLanguage,'t_biodiversity'), value: 'Biodiversity', msgKey: 'bio_diversity', apiResponseKey: 'biodiversity'},
    {label: getLocalizedValue(selectedLanguage,'t_building_performance'), value: 'Building Performance', msgKey: 'building_performance', apiResponseKey: 'buildingPerformance'},
    {label: getLocalizedValue(selectedLanguage,'t_community_engagement'), value: 'Community Engagement', msgKey: 'community_engagement', apiResponseKey: 'communityEngagement'},
    {label: getLocalizedValue(selectedLanguage,'t_energy'), value: 'Energy', msgKey: 'energy', apiResponseKey: 'energy'},
    {label: getLocalizedValue(selectedLanguage,'t_governance_recommendations'), value: 'Governance Recommendations', msgKey: 'governance_recommended', apiResponseKey: 'governanceRecommendations'},
    {label: getLocalizedValue(selectedLanguage,'t_health_and_wellbeing'), value: 'Health & Wellbeing', msgKey: 'health_wellbeing', apiResponseKey: 'healthAndWellbeing'},
    {label: getLocalizedValue(selectedLanguage,'t_social_value'), value: 'Social Value', msgKey: 'social_value', apiResponseKey: 'socialValue'},
    {label: getLocalizedValue(selectedLanguage,'t_tenant_engagement'), value: 'Tenant Engagement', msgKey: 'tenant_engagement', apiResponseKey: 'tenantEngagement'},
    {label: getLocalizedValue(selectedLanguage,'t_waste'), value: 'Waste', msgKey: 'waste', apiResponseKey: 'waste'},
    {label: getLocalizedValue(selectedLanguage,'t_water'), value: 'Water', msgKey: 'water', apiResponseKey: 'water'},
    {label: getLocalizedValue(selectedLanguage,'t_other'), value: 'Other', msgKey: 'other', apiResponseKey: 'other'}
  ]
}

export const gresbMeasureListV2ForExport = (selectedLanguage) => {
  return [
    {label: getLocalizedValue(selectedLanguage,'t_auto_meter_readings'), value: 'AMR', impactTarget: [ActionImpactList?.Energy, ActionImpactList?.Water]},
    {label: getLocalizedValue(selectedLanguage,'t_auto_system_upgrades'), value: 'AutomationSystemUpgradesOrReplacements', impactTarget: [ActionImpactList?.Energy]},
    {label: getLocalizedValue(selectedLanguage,'t_installation_equipment'), value: 'InstallationOfHighEfficiencyEquipment', impactTarget: [ActionImpactList?.Energy]},
    {label: getLocalizedValue(selectedLanguage,'t_installation_renewable'), value: 'InstallationOfOnSiteRenewableEnergy', impactTarget: [ActionImpactList?.Energy]},
    {label: getLocalizedValue(selectedLanguage,'t_management_sys_upgrade'), value: 'ManagementSystemsUpgradesOrReplacements', impactTarget: [ActionImpactList?.Energy]},
    {label: getLocalizedValue(selectedLanguage,'t_occupier_eng_tech'), value: 'OccupierEngagementOrInformationalTechnologies', impactTarget: [ActionImpactList?.Energy]},
    {label: getLocalizedValue(selectedLanguage,'t_smart_grid_tech'), value: 'SmartGridOrSmartBuildingTechnologies', impactTarget: [ActionImpactList?.Energy]},
    {label: getLocalizedValue(selectedLanguage,'t_sys_commissioning'), value: 'SystemsCommissioningOrRetroCommissioning', impactTarget: [ActionImpactList?.Energy]},
    {label: getLocalizedValue(selectedLanguage,'t_wall_roof_inst'), value: 'WallOrRoofInsulation', impactTarget: [ActionImpactList?.Energy]},
    {label: getLocalizedValue(selectedLanguage,'t_window_replacement'), value: 'WindowReplacements', impactTarget: [ActionImpactList?.Energy]},
    {label: getLocalizedValue(selectedLanguage,'t_composting_landscape'), value: 'CompostingLandscapeOrFoodWaste', impactTarget: [ActionImpactList?.Waste]},
    {label: getLocalizedValue(selectedLanguage,'t_ongoing_waste_monitoring'), value: 'OngoingWastePerformanceMonitoring', impactTarget: [ActionImpactList?.Waste]},
    {label: getLocalizedValue(selectedLanguage,'t_recycling'), value: 'Recycling', impactTarget: [ActionImpactList?.Waste]},
    {label: getLocalizedValue(selectedLanguage,'t_waste_management'), value: 'WasteManagement', impactTarget: [ActionImpactList?.Waste]},
    {label: getLocalizedValue(selectedLanguage,'t_waste_stream_audit'), value: 'WWasteStreamAudit', impactTarget: [ActionImpactList?.Waste]},
    {label: getLocalizedValue(selectedLanguage,'t_cooling_tower_drip'), value: 'CoolingTowerDripSmartIrrigation', impactTarget: [ActionImpactList?.Water]},
    {label: getLocalizedValue(selectedLanguage,'t_drought_tolerant_landscaping'), value: 'DroughtTolerantOrNativeLandscaping', impactTarget: [ActionImpactList?.Water]},
    {label: getLocalizedValue(selectedLanguage,'t_high_efficiency_fixtures'), value: 'HighEfficiencyOrDryFixtures', impactTarget: [ActionImpactList?.Water]},
    {label: getLocalizedValue(selectedLanguage,'t_leak_detection_system'), value: 'LeakDetectionSystem', impactTarget: [ActionImpactList?.Water]},
    {label: getLocalizedValue(selectedLanguage,'t_meter_water_subsys'), value: 'MeteringOfWaterSubsystems', impactTarget: [ActionImpactList?.Water]},
    {label: getLocalizedValue(selectedLanguage,'t_onsite_waste_water_treatment_reuse_of_storm_water'), value: 'OnSiteWasteWaterTreatment', impactTarget: [ActionImpactList?.Water]},
    {label: getLocalizedValue(selectedLanguage,'t_not_available'), value: 'NotApplicable', impactTarget: [ActionImpactList?.Biodiversity, ActionImpactList?.Building_Performance, ActionImpactList?.Community_Engagement, ActionImpactList?.Governance_Recommendations, ActionImpactList?.Health_And_Wellbeing, ActionImpactList?.Social_Value, ActionImpactList?.Other]},
    {label: getLocalizedValue(selectedLanguage,'t_gresb_measure_energy'), value: 'PerformedATechnicalBuildingAssessmentToIdentifyEnergyEfficiencyImprovements', impactTarget: [ActionImpactList?.Energy]},
    {label: getLocalizedValue(selectedLanguage,'t_building_specific_communication'), value: 'BuildingSpecificCommunication', impactTarget: [ActionImpactList?.Tenant_Engagement]},
    {label: getLocalizedValue(selectedLanguage,'t_feedback_sessions_individual_tenants'), value: 'FeedbackSessionsWithIndividualTenants', impactTarget: [ActionImpactList?.Tenant_Engagement]},
    {label: getLocalizedValue(selectedLanguage,'t_other'), value: 'Other', impactTarget: [ActionImpactList?.Tenant_Engagement]},
    {label: getLocalizedValue(selectedLanguage,'t_social_media_online_platform'), value: 'SocialMediaOnlinePlatform', impactTarget: [ActionImpactList?.Tenant_Engagement]},
    {label: getLocalizedValue(selectedLanguage,'t_tenant_meetings_which_includes'), value: 'TenantMeetingsWhichIncludesSustainability', impactTarget: [ActionImpactList?.Tenant_Engagement]},
    {label: getLocalizedValue(selectedLanguage,'t_tenant_sustainability_events'), value: 'TenantSustainabilityEvents', impactTarget: [ActionImpactList?.Tenant_Engagement]},
    {label: getLocalizedValue(selectedLanguage,'t_tenant_sustainability_guide'), value: 'TenantSustainabilityGuide', impactTarget: [ActionImpactList?.Tenant_Engagement]},
    {label: getLocalizedValue(selectedLanguage,'t_tenant_sustainability_training'), value: 'TenantSustainabilityTraining', impactTarget: [ActionImpactList?.Tenant_Engagement]},
    {label: getLocalizedValue(selectedLanguage,'t_Tenants_feedback_energy_water_waste'), value: 'TenantsFeedbackEnergyWaterWaste', impactTarget: [ActionImpactList?.Tenant_Engagement]},
  ]
}

export const procuredByListForExport = (selectedLanguage) => {
  return [
    {label: getLocalizedValue(selectedLanguage,'t_landlord'), value: 'Landlord'},
    {label: getLocalizedValue(selectedLanguage,'t_tenant'), value: 'Tenant'},
  ]
}