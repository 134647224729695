import I18n from '../../../../utils/i18n/I18n'

export const SwitchInstance = () => {
  // For example: https://ares.sieraplus.com/switch-instance?requestedOrg=siera&allowedOrg=ares
  const queryParams = new URLSearchParams(window.location.search)
  const allowedOrg = queryParams.get('allowedOrg')
  const requestedOrg = queryParams.get('requestedOrg')

  return (
    <div className="grey-bg min-width-760">
      <div className="display gutter">
        <div className="container-box">
          <h1>{I18n('t_wrong_instance')}</h1>
          <p>{I18n('t_wrong_instance_explanation')}</p>
          <a className="blue-btn p-button p-component" href={`https://${process.env.REACT_APP_AUTH0_DOMAIN}/continue${window.location.search}`}>{I18n('t_switch_to')} {requestedOrg}</a>
          <a className="white-btn p-button p-component" href={`https://${allowedOrg}.${process.env.REACT_APP_AUTH0_WWW_LESS_LOGIN_HOSTNAME}/login`}>{I18n('t_continue_to')} {allowedOrg}</a>
        </div>
      </div>
    </div>
  )
}
