import {UserActions} from '../../../../../utils/helpers/Constants'
import GroupDetailsContainer from '../group-details-container/GroupDetailsContainer'

const GroupDetailsEdit = (props) => {
  const data=props.location?.state
  //Call Api using groupId and pass as props

  const editGroupConfigData={
    activeIndex: data && data.activeIndex ? data.activeIndex : 0,
    groupAction: UserActions.EDIT_GROUP,
  }

  return(
    <div className='edit-user-home'>
      <GroupDetailsContainer {...editGroupConfigData} />
    </div>
  )
}

export default GroupDetailsEdit
