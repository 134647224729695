import {ENERGY, METRIC_TONNES_UTILITY_UNIT, UTILITY_LABELS_OBJECT_TITLE_CASE} from '../../../../utils/helpers/Constants'
import {getDivisor, getLocalStorageItem, getUtilityUnits, unitsConverter} from '../../../../utils/helpers/Helper'

export const getSuffix = (utility, value) => {
  const unitSystem = getLocalStorageItem('unitSystem')
  switch(utility) {
  case UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON:
    if(value > 1000) {
      return METRIC_TONNES_UTILITY_UNIT.Carbon
    }
    return unitsConverter(utility,unitSystem)

  case UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY:
  case UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL:
    if(value > 1000000) {
      return getUtilityUnits(true, utility)
    }
    return unitsConverter(utility,unitSystem)

  case UTILITY_LABELS_OBJECT_TITLE_CASE.WATER:
  case UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE:
    return unitsConverter(utility,unitSystem)

  case UTILITY_LABELS_OBJECT_TITLE_CASE.RECYCLED_WASTE:
    return '%'

  case UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY_INTENSITY:
    if(value > 1000000) {
      return getUtilityUnits(true, ENERGY)
    }
    return unitsConverter(utility,unitSystem)+'/yr'

  default:
    return ''
  }
}

export const getConsumtionSuffix = (utility, value) => {
  const unitSystem = getLocalStorageItem('unitSystem')
  switch(utility) {
  case UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON:
    return METRIC_TONNES_UTILITY_UNIT.Carbon

  case UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY:
  case UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL:
    return unitsConverter(utility, unitSystem, true)

  case UTILITY_LABELS_OBJECT_TITLE_CASE.WATER:
  case UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE:
    return unitsConverter(utility,unitSystem)

  case UTILITY_LABELS_OBJECT_TITLE_CASE.RECYCLED_WASTE:
    return '%'

  default:
    return ''
  }
}

export const AdjustValue = (value, utility) => {
  let divisor = getDivisor(utility, value)
  return value/divisor
}

