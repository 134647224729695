import React, {useContext, useEffect, useMemo, useState} from 'react'
import './FundActionHome.scss'
import { CachedAssetIdAndValueState, CachedUserState, getFiller, getFillerImage, getFundViewsNavLinkConfigsForFlags,
  getLocalizedDateMessage,  getPaginatorTemplate,  onSorting, getLocalizedValue, getUserRole} from '../../../../utils/helpers/Helper'
import {GetFundAssetActions} from '../../../../services/funds/fund-group-service'
import {FeatureFlagContext} from 'Contexts'
import FundCommonHeader from '../common-header/FundCommonHeader'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import I18n from '../../../../utils/i18n/I18n'
import {useQuery} from 'react-query'
import {noRefetchOnWindowFocus} from '../../../../services/common/useQuery-config'
import ActionStatusCardsSkeleton
  from '../../../common/skeletons/action-status-cards-skeleton/ActionStatusCardsSkeleton'
import ActionStatusCard from '../../../common/action-plan/action-card/ActionStatusCard'
import {GetActionPlanStatusSummary, GetImpacts} from '../../../../services/funds/action-plan-service'
import {useParams} from 'react-router-dom'
import buildQuery from 'odata-query'
import { CacheKeyFundGroups,  EXPORT,  DATA_FROM,  FLOOR_MEASUREMENT_TYPES,  FUND_ACTION_PLAN,
  FUND_VIEWS_PAGES,  TOP,  ACTIONS_STATUS} from '../../../../utils/helpers/Constants'
import UseUrlParameterIds from '../../../../utils/custom-hooks/UseUrlParameterIds'
import AssetTableHeaderSkeleton from '../../../common/skeletons/asset-table-header-skeleton/AssetTableHeaderSkeleton'
import AssetRowSkeleton from '../../../common/skeletons/asset-row-skeleton/AssetRowSkeleton'
import HyperLink from '../../../common/HyperLink/HyperLink'
import { Tooltip } from 'primereact/tooltip'
import { isTextOverflown } from '../../../../utils/UtilsFunctions'
import PropTypes from 'prop-types'
import CustomBadge from '../../../common/custom-badge/CustomBadge'
import {emptyDataMessage} from '../../../../utils/helpers/MessageUtility'
import { getSortIcons } from 'utils/helpers/DataTableUtils'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const FundActionHome = (props) => {

  //region Initialization
  const {instanceName, orgName, fundName} = useParams()
  const urlParameterIds = UseUrlParameterIds({instanceName, orgName, fundName})
  const [fundGroupId, setFundGroupId] = useState(null)
  const {loginState} = useLoginAuthContext()
  const selectedLanguage = loginState.userInfo.languagePreference
  const unitSystem = loginState.userInfo.unitSystem
  const currencyUnit = loginState.userInfo.currencyUnit
  const [selectedFund, setSelectedFund] = useState({})
  const [impact, setImpact] = useState('All actions')
  const [impactOptions, setImpactOptions] = useState([])
  const [first, setFirst] = useState(0)
  const [rows, setRows] = useState(20)
  const [sortField, setSortField] = useState('agreedActions')
  const [sortOrder, setSortOrder] = useState(-1)
  const [oDataSortOrder, setODataSortOrder] = useState('desc')
  const oDataQuery = buildQuery({orderBy: `${sortField} ${oDataSortOrder}`})
  const [noData, setNoData] = useState(false)
  const {isLoading: assetActionQueryLoadStatus, data: fundAssetActionsQuery} = useQuery(['FundAssetActions', fundGroupId, oDataQuery, impact, unitSystem], GetFundAssetActions, {...noRefetchOnWindowFocus,enabled:!!fundGroupId && !!oDataQuery})
  const { isLoading, data } = useQuery(['fundActionStatusSummary', fundGroupId, impact, currencyUnit], GetActionPlanStatusSummary, noRefetchOnWindowFocus)
  const impactsList = useQuery(['actionImpactOptions', fundGroupId], GetImpacts, noRefetchOnWindowFocus)
  const featureContext = useContext(FeatureFlagContext)
  const featureState = featureContext.featureState
  const navLinkConfigs = getFundViewsNavLinkConfigsForFlags(selectedFund, selectedLanguage, featureState)
  const translateImpactValue = I18n('t_' + (impact === 'Health & Wellbeing' ? impact.toLowerCase().replace(/ & /g, '_and_') : impact.toLowerCase().replace(/ /g, '_')))
  const sortIconToggle = sortOrder === 1 ? 'sort-up' : 'sort-down'
  // endregion

  // region UseEffect Implementation
  useEffect(() => {
    if (urlParameterIds) {
      if (urlParameterIds.fundGroupId) {
        setFundGroupId(urlParameterIds.fundGroupId)
      }
    }
  }, [urlParameterIds])

  useEffect(() => {
    if (props.location && props.location.state) {
      setSelectedFund(props.location.state)
    } else {
      setSelectedFund(CachedUserState.getUserDataFromCache(CacheKeyFundGroups))
    }
  }, [])

  useEffect(() => {
    if (fundAssetActionsQuery && fundAssetActionsQuery?.data) {
      setNoData(false)
      const fetchAssetIdAndName = fundAssetActionsQuery?.data.reduce(function (accumulator, currentValue) {
        return {
          ...accumulator,
          [currentValue.assetId]: currentValue.assetName,
        }
      }, {})
      CachedAssetIdAndValueState.saveAssetIdAndValueToCache(fetchAssetIdAndName)
    } else {
      setNoData(true)
    }
  }, [fundAssetActionsQuery?.data, first, rows, sortField, oDataSortOrder, sortOrder, oDataQuery])

  useEffect(() => {
    let options
    if (impactsList && impactsList.data) {
      options = impactsList.data?.map(option => {
        const allOptions = (
          <>{I18n('t_' + (option.impact === 'Health & Wellbeing' ?
            option.impact.toLowerCase().replace(/ & /g, '_and_') :
            option.impact.toLowerCase().replace(/ /g, '_')))}&nbsp;({option.totalActionsCount})
          </>)
        return {
          label: option.impact === 'All' ? <>{I18n('t_all_actions')}&nbsp;({option.totalActionsCount})</> : allOptions,
          value: option.impact === 'All' ? 'All actions' : option.impact,
          count: option.totalActionsCount
        }
      })
    }
    const sortedImpactOptions = options?.sort((a, b) => a.value > b.value ? 1 : -1)
    sortedImpactOptions?.push(sortedImpactOptions.splice(sortedImpactOptions.findIndex(v => v.value === 'Other'), 1)[0])
    setImpactOptions(sortedImpactOptions)
  }, [impactsList?.data])

  useEffect(() => {
    setFirst(0)
    setRows(20)
  }, [impact])

  // end region

  // region Table
  function newTabNavigation(columnName, rowData) {

    function navLinkFundPerformanceProps(rowData) {
      return {
        event: rowData,
        performanceQueryParams: '',
        pathSuffix: FUND_VIEWS_PAGES.actionPlan,
        assetName: rowData.assetName + ', ' + rowData.city
      }
    }

    function assetNameTemplate(rowData) {
      const {
        dateOfSale,
        assetImageThumbnailUri,
        sector,
        assetName,
        city,
        country
      } = rowData
      
      return (
        <div className='flex flex-column p-3'>
          <Tooltip className={'portfolio-comm-tooltip long-text-tooltip'} target=".fund-action-tooltip" position={TOP}/>
          <div className="asset-name-img-container">      
            <div className="asset-image-col">
              <img
                src={getFillerImage(rowData, true)}
                alt="Asset-Image"
                className={`fund-asset-image ${assetImageThumbnailUri ? 'image-actual' : 'image-filler'}`}
                onError={({currentTarget}) => {
                  currentTarget.onerror = null
                  currentTarget.src = getFiller(sector)
                }}
              />
            </div>
            <div className="asset-address-col align-self-center text-wrapper">
              <div className="asset-address text-wrapper fund-action-tooltip" data-ptf-tooltip={assetName}
                onMouseEnter={(e)=>isTextOverflown(e)}>{assetName}
              </div>
              <div className="asset-location text-wrapper fund-action-tooltip" data-ptf-tooltip={`${city}, ${country}`}
                onMouseEnter={(e)=>isTextOverflown(e)}>{`${city}, ${country}`}
              </div>
            </div>
          </div>
          <div className="w-full flex align-content-start p-0">
            { dateOfSale ? (
              <CustomBadge message={`${getLocalizedValue(selectedLanguage, 't_sold_label')} ${getLocalizedDateMessage(dateOfSale, 'MONTH_YEAR', selectedLanguage)}`} styling={'mt-3 text-xs font-normal'}></CustomBadge>
            ): null }
          </div>
        </div>
        
      )
    }

    function totalFloorAreaTemplate(rowData) {
      let floorUnit = rowData.measurementUnit === 'FT2' ?
        <span className="total-floor-unit">{FLOOR_MEASUREMENT_TYPES.imperial}</span> :
        <span className="total-floor-unit">{FLOOR_MEASUREMENT_TYPES.metric}</span>
      return <div className="total-floor-area-values">
        {rowData.totalFloorArea === null ? 0 : rowData.totalFloorArea.toLocaleString()} {floorUnit}
      </div>
    }

    function agreedActionsTemplate(rowData) {
      return <div className={'cell-value'}>
        {rowData.agreedActions}
      </div>

    }

    function overdueActionsTemplate(rowData) {
      return <div className={'cell-value'}>
        {rowData.overdueActions}
      </div>

    }

    function completedActionsTemplate(rowData) {
      return <div className={'cell-value'}>
        {rowData.completedActions}
      </div>
    }

    function applicableActionsTemplate(rowData) {
      return <div className={'cell-value'}>
        {rowData.applicableActions}
      </div>

    }

    function underReviewActionsTemplate(rowData) {
      return <div className={'cell-value'}>
        {rowData.underReviewActions}
      </div>
    }

    function totalActionsTemplate(rowData) {
      return <div className={'cell-value'}>
        {rowData.totalActions}
      </div>
    }

    return <HyperLink {...navLinkFundPerformanceProps(rowData)}>
      {columnName === 'assetName' && assetNameTemplate(rowData)}
      {columnName === 'totalFloorArea' && totalFloorAreaTemplate(rowData)}
      {columnName === 'agreedActions' && agreedActionsTemplate(rowData)}
      {columnName === 'overdueActions' && overdueActionsTemplate(rowData)}
      {columnName === 'completedActions' && completedActionsTemplate(rowData)}
      {columnName === 'applicableActions' && applicableActionsTemplate(rowData)}
      {columnName === 'underReviewActions' && underReviewActionsTemplate(rowData)}
      {columnName === 'totalActions' && totalActionsTemplate(rowData)}
    </HyperLink>
  }

  function onPageChange(event) {
    setFirst(event.first)
    setRows(event.rows)
  }

  function onImpactChange(e) {
    setImpact(e.value)
  }

  const onSort = (e) => {
    let orderRev = onSorting(e, sortOrder, sortField, 'assetName')
    let sortOData = orderRev === 1 ? 'asc' : 'desc'
    setSortField(e.sortField)
    setSortOrder(orderRev)
    setODataSortOrder(sortOData)
  }

  function getFundActionStatusCards() {
    return  <>
      <ActionStatusCard type={ACTIONS_STATUS.OVERDUE} data={data?.overdue} dataFrom={DATA_FROM.FUND.FUND_ACTION_PLAN}/>
      <ActionStatusCard type={ACTIONS_STATUS.AGREED} data={data?.agreed} dataFrom={DATA_FROM.FUND.FUND_ACTION_PLAN}/>
      <ActionStatusCard type={ACTIONS_STATUS.APPLICABLE} data={data?.applicable} dataFrom={DATA_FROM.FUND.FUND_ACTION_PLAN}/>
      <ActionStatusCard type={ACTIONS_STATUS.UNDER_REVIEW} data={data?.underReview} dataFrom={DATA_FROM.FUND.FUND_ACTION_PLAN}/>
      <ActionStatusCard type={ACTIONS_STATUS.COMPLETED} data={data?.completed} dataFrom={DATA_FROM.FUND.FUND_ACTION_PLAN}/>
    </>


  }

  function getFundActionTableSkeleton() {
    return (
      <div className='table-skeleton'>
        <AssetTableHeaderSkeleton/>
        <AssetRowSkeleton/>
        <AssetRowSkeleton/>
        <AssetRowSkeleton/>
      </div>
    )
  }

  function getFundActionDataTable() {
    return (
      <>
        <div className={'assets-table-wrapper'} data-testid="fund-action-plan-asset-table">
          <div className={`${fundAssetActionsQuery?.data?.length ? '' : 'no-data-msg'}`}>
            <DataTable
              value={fundAssetActionsQuery?.data}
              className="asset-actions-table"
              stripedRows
              sortField={sortField}
              sortOrder={sortOrder}
              emptyMessage={emptyDataMessage()}
              onSort={onSort}
              defaultSortOrder={-1}
              paginator={fundAssetActionsQuery?.data?.length > 20}
              first={first}
              rows={rows}
              paginatorTemplate={getPaginatorTemplate('items-per-page-options-white')}
              paginatorClassName={'data-table-paginator fund-ap-paginator'}
              onPage={e => onPageChange(e)}
              totalRecords={fundAssetActionsQuery?.data?.length}
              sortIcon={getSortIcons()}
            >
              <Column sortable style={{width: '27.625%'}} field="assetName" header={I18n('t_name')}
                headerClassName={`table-header-asset-name ${sortIconToggle}`}
                body={(rowData) => newTabNavigation('assetName', rowData)}
                bodyClassName={'table-body-asset-name'}/>
              <Column sortable field="totalFloorArea" header={I18n('t_floor_area')}
                headerClassName={`${sortIconToggle}`}
                body={(rowData) => newTabNavigation('totalFloorArea', rowData)}/>
              <Column sortable field="agreedActions" header={I18n('t_agreed')}
                headerClassName={`${sortIconToggle}`}
                body={(rowData) => newTabNavigation('agreedActions', rowData)}/>
              <Column sortable field="overdueActions" header={I18n('t_overdue')}
                headerClassName={`${sortIconToggle}`}
                body={(rowData) => newTabNavigation('overdueActions', rowData)}/>
              <Column sortable field="completedActions" header={I18n('t_completed')}
                headerClassName={`${sortIconToggle}`}
                body={(rowData) => newTabNavigation('completedActions', rowData)}/>
              <Column sortable field="applicableActions" header={I18n('t_applicable')}
                headerClassName={`${sortIconToggle}`}
                body={(rowData) => newTabNavigation('applicableActions', rowData)}/>
              <Column sortable field="underReviewActions" header={I18n('t_under_review')}
                headerClassName={`${sortIconToggle}`}
                body={(rowData) => newTabNavigation('underReviewActions', rowData)}/>
              <Column sortable field="totalActions" header={I18n('t_total')}
                headerClassName={`${sortIconToggle}`}
                body={(rowData) => newTabNavigation('totalActions', rowData)}/>
            </DataTable>
          </div>
        </div>
      </>
    )
  }

  const excelExportInfo = useMemo(() => {
    return {
      'options': {
        'enableExport': !assetActionQueryLoadStatus && !noData && fundAssetActionsQuery?.data?.length !== 0 ? 'enabled' : 'disabled',
        'hasOverlay': true,
        'dataFrom': DATA_FROM.FUND.FUND_ACTION_PLAN,
      },
      'requiredData': {
        'assetInfo': fundAssetActionsQuery?.data // might have to be updated
      },
      'fundAssetListQueryOptions': {
        'fundGroupId': fundGroupId,
      }
    }
  }, [fundAssetActionsQuery?.data, noData, assetActionQueryLoadStatus])
  // end region

  return (
    <div data-page_identifier="fund_action_plan" className="grey-bg fund-action min-width-1280">
      <div className={'container-layout fund-action-content gutter'}>
        {/* Fund action header */}
        <div className={'fund-header'}>
          <FundCommonHeader
            hasDatePicker={false}
            hasBlueBackground={false}
            hasDropdownFilter={true}
            navLinkConfigs={navLinkConfigs}
            dropdownValue={impact}
            onChange={onImpactChange}
            options={impactOptions}
            action={EXPORT}
            componentName={FUND_ACTION_PLAN}
            excelExportInfo={excelExportInfo}
            isActionPlanBulkUploadEnabled={getUserRole() && featureState.sieraplus_actionPlanBulkUpload}
            bulkUploadConsumptionId={urlParameterIds.fundGroupId}
          />
        </div>

        {/* Fund action status cards */}
        <div className={'action-cards-container'}>
          {isLoading ? <ActionStatusCardsSkeleton/> : getFundActionStatusCards()}
        </div>

        {/* Fund action table */}
        <div className={'assets-table-container'}>
          <div className={'assets-table-title'}>{I18n('t_actions_by_assets', {0: translateImpactValue})}</div>
          {assetActionQueryLoadStatus || !fundGroupId ? getFundActionTableSkeleton() : getFundActionDataTable()}
        </div>
      </div>
    </div>
  )
}
FundActionHome.propTypes = {
  location: PropTypes.object,
}

FundActionHome.displayName = 'FundActionHome'

export default FundActionHome