import {useState} from 'react'
import {InputText} from 'primereact/inputtext'
import I18n from '../../../../utils/i18n/I18n'
import './DocumentsHeader.scss'
import PropTypes from 'prop-types'
import { Button, LinkButton, Icon } from 'components/atomic'
import {DocumentSidebar} from '../add-document/document-sidebar/DocumentSidebar'

export const DocumentsHeader = ({title, showAdd = false, showFilterSidebar, numberOfFiltersApplied,refetch,
  addDisable,searchString, setSearchString,handleFilterClearButtonClick, documentNames, setSelectedRows, isDataAvailable = false}) => {
  const filterText = numberOfFiltersApplied > 1 ? 't_filters_applied' : 't_filter_applied'
  const [showSidebar,setShowSidebar] = useState(false)
  const appliedFilters = Object.values(numberOfFiltersApplied).filter(filter => filter !== null)?.length
  const isSelectionOrderEmpty = numberOfFiltersApplied.orderSelection === null
  const filterCount = isSelectionOrderEmpty ? appliedFilters : appliedFilters -1

  const clearSearch = () => {
    !showAdd ? setSearchString({...searchString, consumptionEvidenceSearch:''}):
      setSearchString({...searchString, documentsCertsSearch:''})
  }

  const onSearchInput = (e) => {
    !showAdd ? setSearchString({...searchString, consumptionEvidenceSearch: e.target.value}):
      setSearchString({...searchString, documentsCertsSearch:e.target.value})
  }

  const onClearKeyDown = (e) => {
    if(e.code === 'Enter') {
      clearSearch()
    }
  }

  return (
    <div role={'documents-header'} className={'documents-header-container'}>
      <div className={'documents-header-container__title'}>{title}</div>
      <div className={'documents-header-container__actions'}>
        {appliedFilters > 0 && <>
          <div>{I18n(filterText, {0: appliedFilters !==0 ? filterCount : 0})}</div>

          <LinkButton
            className={'header-actions__clear-all'}
            content={'t_clear_all'}
            testId={'clear-filters'}
            onClick={handleFilterClearButtonClick} />
        </>}

        <Button
          size={'small'}
          content='t_filter'
          outlined={true}
          icon={<Icon id='funnel' />}
          testId={'filter-document'}
          onClick={showFilterSidebar}
          disabled={isDataAvailable}
        />

        <span className="p-input-icon-right header-actions__search-document" role={'search-document'}>
          <InputText role={'search-document-input'} value={!showAdd ? searchString.consumptionEvidenceSearch: searchString.documentsCertsSearch} placeholder="Search document"
            onChange={(e) => onSearchInput(e)} disabled={isDataAvailable}/>
          {searchString.length > 0
            ? <i role={'clear-icon'} onClick={clearSearch} onKeyDown={(e) => onClearKeyDown(e)} className="pi pi-times cursor-pointer" />
            : <i role={'search-icon'} className="pi pi-search" /> }
          
        </span>

        {showAdd &&
          <Button
            size={'small'}
            content='t_btn_add'
            icon={<Icon id='plus' />}
            testId={'add-document'}
            disabled={!addDisable}
            onClick={()=>setShowSidebar(true)}
          />
        }
        {showAdd && showSidebar &&
            <DocumentSidebar setShowSidebar={setShowSidebar} showSidebar={showSidebar} refetch={refetch} documentNames={documentNames} setSelectedRows={setSelectedRows}/>}
      </div>
    </div>)
}

DocumentsHeader.propTypes = {
  title : PropTypes.object.isRequired,
  showAdd : PropTypes.bool,
  showFilterSidebar: PropTypes.func.isRequired,
  numberOfFiltersApplied: PropTypes.number.isRequired,
  handleFilterClearButtonClick: PropTypes.func,
  refetch:PropTypes.func,
  addDisable:PropTypes.bool,
  searchString:PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  setSearchString:PropTypes.func,
  documentNames:PropTypes.array,
  isDataAvailable:PropTypes.bool,
  setSelectedRows:PropTypes.func
}