import React from 'react'
import PropTypes from 'prop-types'
import './NzcModel.scss'
import {InputText} from 'primereact/inputtext'
import {getLocalizedValue} from '../../../../../utils/helpers/Helper'
import I18n from '../../../../../utils/i18n/I18n'
import {InputTextarea} from 'primereact/inputtextarea'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const CommonCreateModel = (props) => {
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const formik = props.formik

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name])
  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <div className="p-error">{formik.errors[name]}</div>
  }
  const getDuplicateNameErrorMessage= () =>{
    return props.duplicateModelNameErrorFlag && <div className="p-error">{I18n('t_model_with_same_name_exists')}</div>
  }

  const modelNameChangeHandler = (event)=>{
    props.resetError()
    formik.handleChange(event)
  }
  return (
    <>
      <p className={'create-model-para'}>{props.explanationTitle}</p>
      <div className="nzc-model-content__nzc-create-model--create-model-input">
        <div className={'nzc-model-content__nzc-create-model--create-model-input--input-name'}>
          <div className="nzc-model-content__nzc-create-model--create-model-input--input-name--input-name-label">{I18n('t_name')}</div>
          <InputText
            data-testid="model-name-field"
            value={formik.values.name}
            id='name'
            name='name'
            onChange={modelNameChangeHandler}
            className={isFormFieldValid('name') === true ? 'nzc-model-content__nzc-create-model--create-model-input--input-text-name nzc-model-content__nzc-create-model--create-model-input--input-error' : 'nzc-model-content__nzc-create-model--create-model-input--input-text-name'}
          />
          {getFormErrorMessage('name')}
          {getDuplicateNameErrorMessage()}
        </div>
        <InputTextarea
          value={formik.values.description}
          id='description'
          name='description'
          placeholder={getLocalizedValue(selectedLanguage, 't_notes')}
          className="nzc-model-content__nzc-create-model--create-model-input--input-text-notes"
          onChange={formik.handleChange}
          rows={5}
          cols={30}
          autoResize
        />
      </div>
    </>
  )
}

CommonCreateModel.propTypes = {
  formik: PropTypes.object.isRequired,
  explanationTitle: PropTypes.object.isRequired,
  resetError: PropTypes.func.isRequired,
  duplicateModelNameErrorFlag: PropTypes.bool.isRequired,
}

CommonCreateModel.displayName = 'CommonCreateModel'

export default CommonCreateModel