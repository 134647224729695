import React, {useState, useEffect} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import CloseIcon from '../../../../../resources/images/icon/close-gray.svg'
import UserDetailsHeader from '../users-add/UserDetailsHeader'
import UserDetails from '../users-add/user-details/UserDetails'
import './UserDetailsContainer.scss'
import UserAssets from '../users-add/user-assets/UserAssets'
import I18n from '../../../../../utils/i18n/I18n'
import {
  clearUserContent,
  getLoggedInUserRole,
  hasPermission, tabNavigatable
} from '../../../../../utils/helpers/Helper'
import UserGroups from '../users-add/user-groups/UserGroups'
import {UserActions} from '../../../../../utils/helpers/Constants'
import PopupDialog from '../../../../common/modal-dialogs/PopupDialog'
import UserFunds from '../users-add/user-funds/UserFunds'
import {useQuery} from 'react-query'
import {GetUserById} from '../../../../../services/admin/users-service'
import {noRefetchOnWindowFocus} from '../../../../../services/common/useQuery-config'

// This component is a common template for add, edit user pages.
function UserDetailsContainer(props) {
  const navigate  = useHistory()
  const [visited, setVisited] = useState({0: true, 1: false, 2: false, 3: false})
  const [activeIndexTab, setActiveIndexTab]=useState(props.activeIndex)
  const [createdNewUser, setCreatedNewUser]=useState()
  const [isUnSavedData, setUnsavedData]=useState(false)
  const hasPermissionForEditUser=hasPermission(UserActions.EDIT_USER, getLoggedInUserRole())
  const [showWarningDialog, setShowWarningDialog] = useState(false)
  const [paramsForUserTabsView, setParamsForUserTabsView] = useState({})
  const urlParams = useParams()
  const {data: selectedUser,refetch,isLoading}  = useQuery(urlParams.userId, GetUserById, noRefetchOnWindowFocus)

  useEffect(()=>{
    let dataForUserTabsView={
      userAction: props.userAction,
      setActiveIndexTab: activeIndexTab,
      hasPermissionForEditUser: hasPermissionForEditUser,
      showTab: changeActiveTabIndex,
      createdNewUser: createdNewUser,
      unSavedData : unSavedData,
      userDetails : selectedUser ? selectedUser : null,
      isUserDataLoading : props.userAction === UserActions.ADD_USER ? false :isLoading ,
      updateNewUser: updateCreatedNewUser,
      refetchUser:refetch,
      visited:updateVisitedTabs
    }
    setParamsForUserTabsView(dataForUserTabsView)

  },[selectedUser])

  function updateVisitedTabs(tab){
    tab === 1 && setVisited(prevState => {
      return {...prevState, 1: true}
    })
    tab === 2 && setVisited(prevState => {
      return {...prevState, 2: true}
    })
    tab === 3 && setVisited(prevState => {
      return {...prevState, 3: true}
    })
  }
  function getPointerClass(tab) {
    let pointerClass  = tabNavigatable(tab,visited) ? 'tabmenu-add-user-pointer' : 'tabmenu-add-user-default'
    return (props.userAction === UserActions.ADD_USER) ? pointerClass  : 'tabmenu-add-user-pointer'
  }

  let tabMenuOptionsForAdminUser = [
    {label: I18n('t_details'), value: 0,className:getPointerClass(0)},
    {label: I18n('t_funds'), value: 1,className:getPointerClass(1)},
    {label: I18n('t_groups'), value: 2,className:getPointerClass(2)},
    {label: I18n('t_asset_s'), value: 3,className:getPointerClass(3)}
  ]

  const tabMenuOptions = tabMenuOptionsForAdminUser

  function changeActiveTabIndex(newIndex) {
    setActiveIndexTab(newIndex)
  }

  function updateCreatedNewUser(newUser) {
    setCreatedNewUser(newUser)
  }

  function unSavedData() {
    if(isUnSavedData === false){
      setUnsavedData(true)
    }
  }

  const userDetails=selectedUser?selectedUser:null

  function getUserTabsViewContent() {
    const userTabsViewContents={
      0: <UserDetails {...paramsForUserTabsView} />,
      1: <UserFunds {...paramsForUserTabsView} />,
      2: <UserGroups {...paramsForUserTabsView} />,
      3: <UserAssets {...paramsForUserTabsView} />
    }
    return userTabsViewContents[activeIndexTab]
  }

  function closeAddAsset() {
    if(isUnSavedData){
      setShowWarningDialog(true)
    }else{
      clearUserContent()//Clears the user local storage
      navigate.goBack()
    }
  }

  return (
    <div className={'user-details-container add-user-home grey-bg'}>
      <div className={'container-layout grey-bg gutter'}>
        <div className={'form-view'}>
          <div  className={'close-icon-container'}><img className="cursor" onClick={closeAddAsset} src={CloseIcon} alt="CloseIcon" /></div>
          <div className={'u-details-header-container header-container'}>
            <UserDetailsHeader
              userHeaderConfig={{tabMenuOptions, headerTitle: props.headerTitle, userDetails: userDetails}}
              activeIndex={activeIndexTab}
              setActiveIndex={setActiveIndexTab}
              userAction={props.userAction}
              visited={visited}
            />
          </div>
          <div className={'u-details-tabs-contents-container form-container'}>
            {getUserTabsViewContent()}
          </div>
        </div>
      </div>
      {showWarningDialog && <PopupDialog visible={showWarningDialog} header={I18n('t_warning')}
        onHide={()=>setShowWarningDialog(false)} accept={()=>navigate.goBack()}
        message={I18n('t_user_warning_message')}/>}
    </div>
  )
}

export default UserDetailsContainer
