import {useEffect, useRef, useState} from 'react'
import I18n from '../../../../../utils/i18n/I18n'
import Chart from 'chart.js/auto'
import './FundPerformanceGraph.scss'
import ChartLegend from '../../../../common/charts/performance-legend/ChartLegend'
import CommonDialog from '../../../../common/modal-dialogs/ModalDialogCommon'
import PropTypes from 'prop-types'
import {Button} from 'primereact/button'
import {getMergeScopesDatasets, isScopeOrSplitSelected, mergeScopesDatasets, setMinBarLength} from '../../../../../utils/helpers/Helper'

const aboutScopesInfo = () => {
  return (
    <>
      <p className={'ex-large-text-light'}>{I18n('t_about_scopes_info')}</p>
      <a className={'ex-large-text-light more-details-link'} target={'_blank'} 
        href={'https://sieraglobal.zendesk.com/hc/en-gb/articles/4404141442961'} 
        rel="noreferrer"
      >{I18n('t_more_details')}</a>
    </>
  )
}

const FundPerformanceGraph = ({performanceChartData, updateFundPerformanceState, periodView, timePeriod, selectedUtility, selectedLanguage, fundPerformanceSummaryData, dataSelectionFilters, disableQuarterlyView}) => {

  const [scopesInfoDialog, setScopesInfoDialog] = useState(false)
  const performanceChartRef=useRef()

  useEffect(()=>{
    if(performanceChartData?.data?.datasets){
      if (window.bar) {
        window.bar.destroy()
        window.bar=null
      }
      // code for merging scopes datasets to avoid faint separator line between stacks in bar chart.
      if(isScopeOrSplitSelected(dataSelectionFilters?.[0]?.key)){
        const mergedDatasetsContainer = []
        let datasetsNew =[...performanceChartData?.data?.datasets ?? []]

        // merging of chart datasets is done per scope/split
        dataSelectionFilters?.forEach((scope) => {
          const selectedScopeDatasets = datasetsNew?.filter(dataset => scope.key === dataset.dataSelectionFilterKey) // get datasets only for selected scope/split
          if(selectedScopeDatasets?.length===4){
            mergeScopesDatasets(selectedScopeDatasets)
            mergedDatasetsContainer.push(...selectedScopeDatasets)
          } else {
            mergedDatasetsContainer.push(...getMergeScopesDatasets(selectedScopeDatasets))
          }
        })

        // use merged-datasets
        if(mergedDatasetsContainer?.length>0){
          performanceChartData.data.datasets= mergedDatasetsContainer
        }
      }

      setMinBarLength(performanceChartData, dataSelectionFilters)
      const ctx = performanceChartRef.current?.getContext('2d')
      window.bar = new Chart(ctx, performanceChartData)
    }
  }, [performanceChartData])

  return (
    <div className="fund-performance-chart">
      {!performanceChartData?.data?.datasets?.length>0 ?
        <div className="no-chart-data-message">
          <span>{I18n('t_msg_no_data')}</span>
        </div>
        :
        <>
          <div className='flex justify-content-end'>
            <div className="p-buttonset">
              {performanceChartData?.data?.datasets.length >= 3 || timePeriod !== 'YTD' || periodView !== 'Monthly' ? (
                <Button label={I18n('t_quarterly_data')}
                  className={`${periodView === 'Quarterly' ? 'quarterly_btn selected-period' : 'unSelected-period'}`}
                  data-view="Quarterly"
                  onClick={() => updateFundPerformanceState({periodView: 'Quarterly'})} disabled={disableQuarterlyView}/>
              ) : null}
              <Button label={I18n('t_monthly_data')}
                className={`${periodView === 'Monthly' ? 'monthly_btn selected-period' : 'unSelected-period'}`}
                data-view="Monthly"
                onClick={() => updateFundPerformanceState({periodView: 'Monthly'})}/>
            </div>
          </div>
          <div className="fund-performance-chart-container">
            <canvas id="fund-performance-chart" ref={performanceChartRef} />
          </div>
          <div className="chart-legend-section">
            <div className="chart-legend-container">
              <ChartLegend 
                selectedLanguage={selectedLanguage}
                targetCalculatedAgainstPreviousPeriod={fundPerformanceSummaryData?.targetCalculatedAgainstPreviousPeriod}
                selectedUtility={selectedUtility}
                dataSelectionFilters={dataSelectionFilters}
                isGapFillingSelected={performanceChartData?.isGapFillingSelected}
              />
            </div>
          </div>

          { selectedUtility === 'Carbon' && dataSelectionFilters.length === 0 &&
            <>
              <div className="chart-explanation-section">
                <p>{I18n('t_fund_graph_carbon_explanation')} <span onClick={() => setScopesInfoDialog(true)}>{I18n('t_how_we_calculate')}</span></p>
              </div>
              <CommonDialog
                modal={false}
                style={{width: '43.75rem', height: '18.125rem'}}
                visible={scopesInfoDialog}
                onHide={() => setScopesInfoDialog(false)}
                header={I18n('t_about_scopes')}
                children={aboutScopesInfo()}
                className={'fund-perf-scope-info'}
              />
            </>
          }
        </>
        
      }
    </div>
  )
}

FundPerformanceGraph.propTypes = {
  performanceChartData: PropTypes.object,
  updateFundPerformanceState: PropTypes.func,
  periodView: PropTypes.string,
  timePeriod: PropTypes.string,
  selectedUtility: PropTypes.string,
  selectedLanguage: PropTypes.string,
  fundPerformanceSummaryData: PropTypes.object,
  dataSelectionFilters: PropTypes.array,
  disableQuarterlyView: PropTypes.bool
}

export default FundPerformanceGraph
FundPerformanceGraph.displayName = 'FundPerformanceGraph'