import React, {useState} from 'react'
import './ModalDialogHome.scss'
import {Dialog} from 'primereact/dialog'
import {TabView, TabPanel} from 'primereact/tabview'

const ModalDialogCommercialEvent = (props) => {
  const [activeIndex, setActiveIndex] = useState(props.activeTabIndex ? props.activeTabIndex : 0 )

  return (
    <Dialog className="modal-dialog-container" visible={props.showDialog} header={props.header} onHide={props.onModalDialogCloseHandler} blockScroll>
      <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
        {props.tabMenuList.map((item, index) => {
          return <TabPanel header={item.title} key={index}>
            {item.component}
          </TabPanel>
        })}
      </TabView>
    </Dialog>
  )
}

export default ModalDialogCommercialEvent