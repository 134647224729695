import {useContext, useEffect, useMemo, useRef} from 'react'
import {useHistory} from 'react-router-dom'
import {Dropdown} from 'primereact/dropdown'
import {InputText} from 'primereact/inputtext'
import {OverlayPanel} from 'primereact/overlaypanel'
import '../../../../common/paginator/Paginator.scss'
import './PortfolioHomeV3.scss'
import {getTimeperiodLabel, periodOptions} from '../../../../../services/common/datepicker-service'
import I18n from '../../../../../utils/i18n/I18n'
import ClearIcon from '../../../../../resources/images/icon/clear-search-icon.svg'
import DatePicker from '../../../../common/date-picker/DatePicker'
import {datePickerQueryValues, filterQueryParams} from '../../../../../utils/query-params/QueryParams'
import BreadCrumbNavigation from '../../../../common/bread-crumb/BreadCrumbNavigation'
import {createEndMonthParam, createNavURLForFundPages, getInstance, getLocalizedValue, getUserRole} from '../../../../../utils/helpers/Helper'
import {ASSET_PAGES, PERIOD_TYPES, DROP_DOWN_HEIGHT, ELLIPSIS, verticalEllipsisJson} from '../../../../../utils/helpers/Constants'
import AssetExportSelection from '../../../../../utils/data-exports/AssetExportSelection'
import {VerticalEllipsis} from '../../../../common/ellipsis-v/VerticalEllipsis'
import { BULK_UPLOAD } from '../../../../../utils/i18n/constants'
import usePortfolioData from '../hooks-and-helpers/usePortfolioData'
import useCustomState from '../../../../common/custom-hooks/useCustomState'
import {getCurrentOrderValue, getPortfolioPaginationParams, getUpdatedOrder, hasUserAssetInUSOrCanada, LABEL_ALL, setInitialPortfolioState} from '../hooks-and-helpers/PortfolioHelper'
import {FeatureFlagContext} from '../../../../../Contexts'
import {Button, ButtonWrapper, Icon, Tooltip, Text} from '../../../../atomic'
import {useDebounce} from 'use-debounce'
import PortfolioDataTableV3 from '../portfolio-components/PortfolioDataTableV3'
import PortfolioAssetsTableSkeleton from '../skeleton/PortfolioAssetsTableSkeleton'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

function PortfolioHomeV3() {

  //region DECLARATIONS/INITIALISATIONS
  const history = useHistory()
  const queryValues = new URLSearchParams(new URL(window.location.href).search)
  const {featureState} = useContext(FeatureFlagContext)
  const OPTION_ALL = 'All'
  const {customState: portfolioState, updateCustomState: updatePortfolioState} = useCustomState(() => setInitialPortfolioState(queryValues ))
  const infoIconOverlay = useRef(null)
  const varianceOverlayTarget = useRef(null)
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const unitSystem = userInfo.unitSystem
  const currencyUnit = userInfo.currencyUnit

  const {
    first,
    rows,
    page,
    sortOrderText,
    timePeriod,
    pickerValue,
    isOverlayOpen,
    filterStateV3
  } = portfolioState

  let {
    searchTerm,
    filterFundSelected,
    filterLocationSelected,
    filterSectorSelected,
    filterEsScoreSelected,
    fundOrder,
    locationOrder,
    typeOrder,
    esScoreOrder
  } = filterStateV3

  const allLabel = {label:getLocalizedValue(selectedLanguage, 't_all'), value:OPTION_ALL}
  const searchBoxPlaceholder = getLocalizedValue(selectedLanguage, 't_find_asset')
  const paginationParams = useMemo(()=> getPortfolioPaginationParams(filterStateV3, rows, page, sortOrderText), [filterStateV3, rows, page, sortOrderText])

  const filterOptionsParams = useMemo(()=> ({
    filterFundSelected,
    filterLocationSelected,
    filterSectorSelected,
    filterEsScoreSelected,
    fundOrder,
    locationOrder,
    typeOrder,
    esScoreOrder
  }), [filterFundSelected, filterLocationSelected, filterSectorSelected, filterEsScoreSelected])

  const [deferredFilterOptionsParams] = useDebounce(filterOptionsParams, 500)
  const [deferredPaginationParams] = useDebounce(paginationParams, 500)

  const {
    userDropdownFiltersLoading,
    userDropdownFilters,
    portfolioDataPaginatedIsLoading,
    portfolioDataPaginated,
    portfolioExportData
  } = usePortfolioData(timePeriod, pickerValue, deferredPaginationParams, deferredFilterOptionsParams, unitSystem)

  const {data: portfolioViewData}=portfolioDataPaginated || {}
  const isEsScoreDropdownVisible = featureState.sieraplus_energyStarIntegration && hasUserAssetInUSOrCanada()

  const dropDownNames ={
    fund : 'fundDropdown',
    location : 'locationDropdown',
    type : 'typeDropdown',
    esScore: 'esScoreDropdown'
  }
  //endregion

  useEffect(() => {
    const datePickerValues = datePickerQueryValues(timePeriod, pickerValue)
    const queryParams = {
      asset: searchTerm,
      fund: filterFundSelected,
      location: filterLocationSelected,
      type: filterSectorSelected,
      esScoreRange: filterEsScoreSelected,
      timePeriod: datePickerValues.timePeriodSelected,
      noOfMonths: datePickerValues.noOfMonths,
      endMonth: datePickerValues.endMonth,
      endYear: datePickerValues.endYear,
      filterOrder: `${fundOrder}${locationOrder}${typeOrder}${esScoreOrder}`
    }

    if(history.location.pathname === createNavURLForFundPages({instanceName: getInstance(), pathSuffix: ASSET_PAGES.assets})){
      filterQueryParams(history, queryParams, [])
    }
  }, [pickerValue, timePeriod, filterFundSelected, filterLocationSelected, filterSectorSelected, searchTerm, filterEsScoreSelected])

  //region HANDLERS/FUNCTIONS
  const openAddAsset   = () => {
    history.push('/admin/add-asset-form')
  }

  const onFundChange = (event) => {
    const newFund = event.value.includes(OPTION_ALL) ? OPTION_ALL : event.value
    const selectedFilters=[filterLocationSelected, filterSectorSelected, filterEsScoreSelected]
    const otherOrdersTotalValue=locationOrder+typeOrder+esScoreOrder

    if(newFund === LABEL_ALL){
      // update existing order values
      locationOrder =  getUpdatedOrder(filterLocationSelected, locationOrder, selectedFilters, otherOrdersTotalValue)
      typeOrder = getUpdatedOrder(filterSectorSelected, typeOrder, selectedFilters, otherOrdersTotalValue)
      esScoreOrder = getUpdatedOrder(filterEsScoreSelected, esScoreOrder, selectedFilters, otherOrdersTotalValue)
      fundOrder=0
    } else {
      fundOrder=getCurrentOrderValue(selectedFilters)
      fundOrder +=1
    }

    updatePortfolioState({
      first: 0,
      page: 0,
      filterStateV3: {
        ...filterStateV3,
        filterFundSelected: event.target?.name === dropDownNames.fund ? newFund : filterFundSelected,
        fundOrder,
        locationOrder,
        typeOrder,
        esScoreOrder
      }
    })
  }

  function onLocationChange(event) {
    const newLocation = event.value.includes(OPTION_ALL) ? OPTION_ALL : event.value
    const selectedFilters=[filterFundSelected, filterSectorSelected, filterEsScoreSelected]
    const otherOrdersTotalValue=fundOrder+typeOrder+esScoreOrder

    if(newLocation === LABEL_ALL){
      // update existing order values
      fundOrder = getUpdatedOrder(filterFundSelected, fundOrder, selectedFilters, otherOrdersTotalValue)
      typeOrder = getUpdatedOrder(filterSectorSelected, typeOrder, selectedFilters, otherOrdersTotalValue)
      esScoreOrder = getUpdatedOrder(filterEsScoreSelected, esScoreOrder, selectedFilters, otherOrdersTotalValue)
      locationOrder=0
    } else {
      locationOrder=getCurrentOrderValue(selectedFilters)
      locationOrder +=1
    }

    updatePortfolioState({
      first: 0,
      page: 0,
      filterStateV3: {
        ...filterStateV3,
        filterLocationSelected: event.target?.name === dropDownNames.location ? newLocation : filterLocationSelected,
        fundOrder,
        locationOrder,
        typeOrder,
        esScoreOrder
      }
    })
  }

  function onSectorChange(event) {
    const newSector = event.value.includes(OPTION_ALL) ? OPTION_ALL : event.value
    const selectedFilters=[filterFundSelected, filterLocationSelected, filterEsScoreSelected]
    const otherOrdersTotalValue=fundOrder+locationOrder+esScoreOrder

    if(newSector === LABEL_ALL){
      // update existing order values
      fundOrder =  getUpdatedOrder(filterFundSelected, fundOrder, selectedFilters, otherOrdersTotalValue)
      locationOrder = getUpdatedOrder(filterLocationSelected, locationOrder, selectedFilters, otherOrdersTotalValue)
      esScoreOrder =  getUpdatedOrder(filterEsScoreSelected, esScoreOrder, selectedFilters, otherOrdersTotalValue)
      typeOrder=0
    } else {
      typeOrder=getCurrentOrderValue(selectedFilters)
      typeOrder +=1
    }

    updatePortfolioState({
      first: 0,
      page: 0,
      filterStateV3: {
        ...filterStateV3,
        filterSectorSelected: event.target?.name === dropDownNames.type ? newSector : filterSectorSelected,
        fundOrder,
        locationOrder,
        typeOrder,
        esScoreOrder
      }
    })
  }

  function onEsScoreChange(event) {
    const newScore = event.value.includes(OPTION_ALL) ? OPTION_ALL : event.value
    const selectedFilters=[filterFundSelected, filterLocationSelected, filterSectorSelected]
    const otherOrdersTotalValue=fundOrder+locationOrder+typeOrder

    if(newScore === LABEL_ALL){
      // update existing order values
      fundOrder = getUpdatedOrder(filterFundSelected, fundOrder, selectedFilters, otherOrdersTotalValue)
      locationOrder = getUpdatedOrder(filterLocationSelected, locationOrder, selectedFilters, otherOrdersTotalValue)
      typeOrder = getUpdatedOrder(filterSectorSelected, typeOrder, selectedFilters, otherOrdersTotalValue)
      esScoreOrder=0
    } else {
      esScoreOrder=getCurrentOrderValue(selectedFilters)
      esScoreOrder +=1
    }

    updatePortfolioState({
      first: 0,
      page: 0,
      filterStateV3: {
        ...filterStateV3,
        filterEsScoreSelected: event.target?.name === dropDownNames.esScore ? newScore : filterEsScoreSelected,
        fundOrder,
        locationOrder,
        typeOrder,
        esScoreOrder
      }
    }
    )
  }

  const onFindAssetFilterChange = (event) => {
    updatePortfolioState({
      filterStateV3: {
        ...filterStateV3,
        searchTerm: event.target.value
      }
    })
  }

  const onFindAssetKeyDown = (event) => {
    if(event.code === 'Enter') {
      event.preventDefault()
    }
  }
  const resetFilter = () => {
    updatePortfolioState({
      filterStateV3: {
        ...filterStateV3,
        filterFundSelected: allLabel.value,
        filterLocationSelected: allLabel.value,
        filterSectorSelected: allLabel.value,
        filterEsScoreSelected: allLabel.value,
        fundOrder: 0,
        locationOrder: 0,
        typeOrder: 0,
        esScoreOrder: 0
      }
    })
  }

  const clearSearch = () => {
    updatePortfolioState({
      filterStateV3: {
        ...filterStateV3,
        searchTerm: '',
      }
    })
  }

  const dropdownItemTemplate  = (option) => {
    return (
      <>
        <div className="dropdown-item-1">
          <div className="dropdown-item-2 dropdown-item-wrap">{option}</div>
        </div>
        <div className={'dropdown-panel-item-horiz-line'} />
      </>
    )
  }

  const fundFilterElements =
    <Dropdown
      appendTo="self"
      panelClassName="fund-dropdown-panel"
      options= {userDropdownFiltersLoading ? [allLabel.label] : [allLabel.label].concat((userDropdownFilters?.data.funds)?.sort())}
      value={filterStateV3.filterFundSelected === '' ? allLabel.label :filterFundSelected}
      name={dropDownNames.fund}
      className={'sDropdown portfolio-dropdown'}
      placeholder={allLabel.label}
      onChange={onFundChange}
      itemTemplate={dropdownItemTemplate}
      scrollHeight={DROP_DOWN_HEIGHT}
      role={'fund-filter'}
    />

  const locationFilterElements =
    <Dropdown
      appendTo="self"
      options={userDropdownFiltersLoading ? [allLabel.label] : [allLabel.label].concat((userDropdownFilters?.data.locations)?.sort())}
      value={filterLocationSelected === '' ? allLabel.label : filterLocationSelected}
      name={dropDownNames.location}
      className={'sDropdown portfolio-dropdown'}
      placeholder={allLabel.label}
      onChange={onLocationChange}
      itemTemplate={dropdownItemTemplate}
      scrollHeight={DROP_DOWN_HEIGHT}
      role={'location-filter'}
    />

  const sectorFilterElements =
    <Dropdown
      appendTo="self"
      options={userDropdownFiltersLoading ? [allLabel.label] : [allLabel.label].concat((userDropdownFilters?.data.types)?.sort())}
      value={filterSectorSelected === '' ? allLabel.label : filterSectorSelected}
      name={dropDownNames.type}
      className={'sDropdown portfolio-dropdown'}
      placeholder={allLabel.label}
      onChange={onSectorChange}
      itemTemplate={dropdownItemTemplate}
      scrollHeight={DROP_DOWN_HEIGHT}
      role={'sector-filter'}
    />

  const esScoreFilterElements =
      <Dropdown
        appendTo="self"
        options={userDropdownFiltersLoading ? [allLabel.label] : [allLabel.label].concat((userDropdownFilters?.data.ranges)?.sort())}
        value={filterEsScoreSelected?.toString().includes(OPTION_ALL)  || filterEsScoreSelected === '' ? allLabel.label : filterEsScoreSelected}
        name={dropDownNames.esScore}
        className={'sDropdown portfolio-dropdown'}
        placeholder={allLabel.label}
        onChange={onEsScoreChange}
        itemTemplate={dropdownItemTemplate}
        scrollHeight={DROP_DOWN_HEIGHT}
        role={'es-score-filter'}
      />

  const getPeriodMenuItem = (option) => (option === PERIOD_TYPES.YTD) ?
    <span className='p-button-label p-c period-menu-ytd-tooltip' data-pr-position="top">{option}</span>
    :
    <span className='p-button-label p-c'>{option}</span>

  const exportOverlay = useRef(null)
  const closeOverlay = () => {
    exportOverlay.current.hide()
  }
  const getExportButtonStatus = () => {
    let status
    status = portfolioDataPaginatedIsLoading || (!portfolioViewData) || (portfolioViewData === '')
    return status
  }

  const portfolioPageExportInfoProps = {
    'closeOverlay': closeOverlay,
    'portfolio': true,
    'language': selectedLanguage,
    'excelData': portfolioExportData,
    'filterSelection': filterStateV3,
    'exportFrom': {'page-type': 'asset-page', 'from': 'asset-portfolio'},
    'timePeriod': timePeriod,
    'pickerValue': pickerValue,
    'period': getTimeperiodLabel(timePeriod),
    'noOfMonths': pickerValue?.pickerValueMonths,
    'endDate': createEndMonthParam(pickerValue.pickerValueEndYear, pickerValue.pickerValueEndMonth),
    'fundName': filterFundSelected,
  }

  function getColumnWidth() {
    return isEsScoreDropdownVisible ? 'p-col-6_6' : 'p-col-6_5'
  }

  function getDropdown(){
    const parentClassName= `${isEsScoreDropdownVisible ? 'col-3 no-padding container' : 'col-4 no-padding container'}`
    return  <div className={'flex no-padding filter-dropdown'} role={'dropdown-container'}>
      <div className={parentClassName}>{fundFilterElements}</div>
      <div className={parentClassName}>{locationFilterElements}</div>
      <div className={parentClassName}>{sectorFilterElements}</div>
      {isEsScoreDropdownVisible && <div className="col-3 no-padding container">{esScoreFilterElements}</div>}
    </div>
  }

  function getDropdownLabel(){
    const labelClassName= `${isEsScoreDropdownVisible ? 'col-3 no-padding filter-text' : 'col-4 no-padding filter-text'}`
    return  <div className={'flex no-padding filter-label-container mb-1'} role={'dropdown-label-container'}>
      <div className={labelClassName}>{I18n('t_fund')}</div>
      <div className={labelClassName}>{I18n('t_location')}</div>
      <div className={labelClassName}>{I18n('t_type')}</div>
      {isEsScoreDropdownVisible && <div className="col-3 no-padding filter-text">{I18n('t_energy_rating')}</div>}
    </div>
  }

  const verticalEllipsisKeyValue = {
    ...verticalEllipsisJson,
    'consumption': {
      ...verticalEllipsisJson['consumption'],
      'displayBulkUploadButton': getUserRole() && featureState?.sieraplus_consumptionBulkUpload,
      'displayAssetAddButton': featureState?.sieraplus_userAndAssetAdmin,
      'addButtonName': 't_add_asset',
      'editAssetButtonName': 't_edit_asset',
      'displayAssetEditButton': false,
    },
    'action': {
      ...verticalEllipsisJson['action'],
      'displayBulkUploadButton': getUserRole() && featureState?.sieraplus_actionPlanBulkUpload,
      'buttonName': 't_upload_bulk_action_plan',
    }
  }

  function getDataTableProps() {
    return {
      portfolioDataPaginated,
      updatePortfolioState,
      first,
      rows,
      selectedLanguage,
      filterStateV3,
      unitSystem,
      isEsScoreDropdownVisible
    }
  }
  //endregion

  return (
    <>
      <BreadCrumbNavigation />
      <div data-page_identifier="asset_portfolio" className="portfolio_v3 min-width-1280" data-testid={'asset-home'}>
        <div className="display gutter">
          <div className="portfolio-wrapper">
            <div className="table-header grid">
              <div className="col-12 portfolio-logo">
                <span>{I18n('t_asset_s')}</span>
              </div>
              <div className="col-12">
                <div className="filter-dropdown header-filter-menu-container">
                  <div className={getColumnWidth()}>
                    {getDropdownLabel()}
                    {getDropdown()}
                  </div>
                  <div className="col-1 no-padding clear-filters">
                    <Button
                      content={getLocalizedValue(selectedLanguage, 't_clear_filters')}
                      onClick={resetFilter}
                      outlined
                      size="regular"
                      type="secondary"
                      disabled={!!(filterFundSelected === allLabel.value && filterLocationSelected === allLabel.value && filterSectorSelected === allLabel.value && filterEsScoreSelected === allLabel.value)}
                      additions="no-icon"
                    />
                  </div>
                  <div className={'action-buttons-container flex'}>
                    <DatePicker
                      value={timePeriod}
                      options={periodOptions}
                      onChange={(e) => updatePortfolioState({timePeriod: e.value})}
                      customDatePickerOnClick={() => {}}
                      itemTemplate={getPeriodMenuItem}
                      pickerValue={pickerValue}
                      pickerOnChange={e => {
                        const pickerValueUpdated = {...pickerValue, [e?.target?.name]: e?.target?.value}
                        updatePortfolioState({
                          timeNoOfMonthsPeriod: pickerValueUpdated.pickerValueMonths,
                          timeEndDatePeriod: createEndMonthParam(pickerValueUpdated.pickerValueEndYear, pickerValueUpdated.pickerValueEndMonth),
                          timePeriod: PERIOD_TYPES.CUSTOM,
                          pickerValue: pickerValueUpdated
                        })
                      }}
                    />

                    <Tooltip target={getExportButtonStatus() ? '.nav-export' : ''} >
                      <Text content={getLocalizedValue(selectedLanguage,'t_no_data_to_export')} />
                    </Tooltip>
                    <div id="export" className={'nav-export export-button-container'} >
                      <Button
                        content={getLocalizedValue(selectedLanguage, 't_export')}
                        size="regular"
                        testId="export-button"
                        disabled={getExportButtonStatus()}
                        onClick={(e) => exportOverlay.current.toggle(e)}
                        icon={<Icon id={isOverlayOpen ? 'arrow_up' : 'arrow_down'} />}
                      />
                    </div>
                    <OverlayPanel ref={exportOverlay} appendTo={document.getElementById('export')} className="export-overlay portfolio-home"
                      onShow={() => updatePortfolioState({isOverlayOpen: true})} onHide={() => updatePortfolioState({isOverlayOpen: false})}>
                      <AssetExportSelection exportDetails = {portfolioPageExportInfoProps}/>
                    </OverlayPanel>
                    {getUserRole() && (featureState.sieraplus_consumptionBulkUpload || featureState?.sieraplus_userAndAssetAdmin) &&
                        <div className={'ml-3 relative add-asset-options'} id={ELLIPSIS}>
                          <VerticalEllipsis
                            isAssets
                            openAddAsset={openAddAsset}
                            portfolioParameters={{
                              paginationParams,
                              period: getTimeperiodLabel(timePeriod),
                              noOfMonths: pickerValue?.pickerValueMonths,
                              endDate: createEndMonthParam(pickerValue.pickerValueEndYear, pickerValue.pickerValueEndMonth),
                              currency: currencyUnit,
                              unit: unitSystem,
                            }}
                            flag={BULK_UPLOAD.ALL}
                            bulkUploadKeyValue={verticalEllipsisKeyValue[BULK_UPLOAD.CONSUMPTION]}
                            actionBulkUploadKeyValue={verticalEllipsisKeyValue[BULK_UPLOAD.ACTION_PLAN]}
                            selectedLanguage={selectedLanguage}
                          />
                        </div>
                    }
                  </div>
                </div>
              </div>
              <div className="col-12 variance-info-bar">
                <div className="icon-container">
                  <div className="variance-info" ref={varianceOverlayTarget} id='overlay-variance-info'>
                    <p>{I18n('t_variance_previous_period_or_target')}</p>
                    <ButtonWrapper onClick={(e) => infoIconOverlay.current.toggle(e)}>
                      <Icon id='info_solid' size={15} />
                    </ButtonWrapper>
                  </div>
                  <OverlayPanel ref={infoIconOverlay}
                    className="variane-info-overlay"
                    showCloseIcon={true}
                    dismissable={false}
                    appendTo={varianceOverlayTarget.current}>
                    <Text content="t_variance_explanation_title" weight="bold"/>
                    <Text content="t_variance_explanation" className='overlay-title-description'/>
                  </OverlayPanel>
                </div>
                <div className="find-asset-root">
                  <div className="p-input-icon-right search-asset find-asset-container">
                    <div className="seach-root">
                      <InputText
                        ref={gf => gf && gf.focus({preventScroll: true})}
                        value={searchTerm}
                        onKeyDown={(e) => onFindAssetKeyDown(e)}
                        onChange={onFindAssetFilterChange}
                        className="search-input-text"
                        placeholder={searchBoxPlaceholder}
                      />
                    </div>
                    {searchTerm !== ''? <img src={ClearIcon} alt="Clear" className="cursor highlight-clickable-opacity clear-search-icon" onClick={clearSearch} role="presentation" /> : <i className="pi pi-search cursor"/>}
                  </div>
                </div>
              </div>
            </div>
            {portfolioDataPaginatedIsLoading ?
              <PortfolioAssetsTableSkeleton />
              :
              <PortfolioDataTableV3 {...getDataTableProps()} />
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default PortfolioHomeV3
