import I18n from '../i18n/I18n'
import {Tooltip} from 'chart.js'
import {
  ADMIN,
  ADMINISTRATOR,
  AREA,
  ASSET_PAGES, BTUUnitNames,
  CacheIdAndValueState,
  CacheKeyAsset,
  CacheKeyFundGroups,
  CacheKeyOrganisation,
  CacheKeyUserState,
  CARBON, CO2UnitNames,
  CSS_LINK_ACTIVE,
  CSS_LINK_ACTIVE_GREY,
  CURRENCY_UNITS,
  CUSTOM,
  DD_MM_YYYY,
  DISTRICT_COOLING,
  DISTRICT_HEATING,
  DISTRICT_SPACE_COOLING,
  DISTRICT_SPACE_HEATING,
  ELECTRICITY,
  ENERGY, EnergyUnitNames,
  FUND_VIEWS_PAGES,
  FY_START_FOR_ASSET_ACCESS, GAS_FUELS,
  GAS_FUELS_THERMALS,
  getColumnName,
  groupInfo,
  groupUserDetails,
  IMPERIAL_TONNES_UTILITY_UNIT,
  LINK_TRANSFER,
  LOCAL_STORAGE_KEYS,
  METRIC_TONNES_UTILITY_UNIT,
  MM_DD_YYYY,
  monthList,
  OIL,
  PERIOD_TYPES,
  regexValidFileName,
  ScopeTypes, SplitTypes,
  storeGroupAsset,
  TONNE_OR_MEGAWATT, UNIT_1K, UNIT_1M,
  USER_AUTHORISED_MODULES,
  USER_FUND_ACCESSES,
  userAssetDetails,
  userFundDetails,
  userGroupDetails,
  userInfo,
  UserPermissions,
  UserRoles,
  UTILITY_LABELS_OBJECT_TITLE_CASE,
  UtilityTypes, Views,
  WASTE,
  WATER
} from './Constants'
import {trucateNumWOneDecimal, trucateNumWTwoDecimal} from '../../services/common/formatter'
import fillerOffice from '../../resources/images/icon/filler/office.svg'
import fillerHotel from '../../resources/images/icon/filler/hotel.svg'
import fillerRetail from '../../resources/images/icon/filler/retail.svg'
import fillerLeisure from '../../resources/images/icon/filler/leisure.svg'
import fillerIndustrial from '../../resources/images/icon/filler/industrial.svg'
import fillerResidential from '../../resources/images/icon/filler/residential.svg'
import fillerMixed from '../../resources/images/icon/filler/mixed.svg'
import fillerEducation from '../../resources/images/icon/filler/education.svg'
import fillerHealthcare from '../../resources/images/icon/filler/healthcare.svg'
import fillerTechnology from '../../resources/images/icon/filler/technology.svg'
import fillerOther from '../../resources/images/icon/filler/other.svg'
import fillerOfficePng from '../../resources/images/icon/filler/office-png.png'
import fillerHotelPng from '../../resources/images/icon/filler/hotel-png.png'
import fillerRetailPng from '../../resources/images/icon/filler/retail-png.png'
import fillerLeisurePng from '../../resources/images/icon/filler/leisure-png.png'
import fillerIndustrialPng from '../../resources/images/icon/filler/industrial-png.png'
import fillerResidentialPng from '../../resources/images/icon/filler/residential-png.png'
import fillerMixedPng from '../../resources/images/icon/filler/mixed-png.png'
import fillerEducationPng from '../../resources/images/icon/filler/education-png.png'
import fillerHealthcarePng from '../../resources/images/icon/filler/healthcare-png.png'
import fillerTechnologyPng from '../../resources/images/icon/filler/technology-png.png'
import fillerOtherPng from '../../resources/images/icon/filler/other-png.png'
import messages from '../../resources/messages/lang'
import {getFundGroupData} from '../../services/organisation-service'
import {getFundGroups} from '../../services/funds/fund-group-service'
import {setDefaultDate} from './DatePickerUtils'
import PaginatorDropdown from '../../components/common/paginator/PaginatorDropdown'
import RowInfo from '../../components/common/paginator/RowInfo'
import React from 'react'
import moment from 'moment'
import {UNITS} from '../i18n/constants'
import Countdown, {zeroPad} from 'react-countdown'
import scienceBased from '../../resources/images/icon/science-based-target-legend-icon.svg'
import rectBusiness from '../../resources/images/icon/business-as-usual-line-legend-icon.svg'
import rectTp from '../../resources/images/icon/rectangle-6A33F9.svg'
import enclosedIcon from '../../resources/images/icon/benchmark-line-legend-icon.svg'
import dashedGreen from '../../resources/images/icon/dashedGreen.svg'
import triangleImage from '../../resources/images/icon/rounded-triangle.svg'
import orangeEllipse from '../../resources/images/icon/ellipse.svg'
import yellowEllipse from '../../resources/images/icon/yellowEllipse.svg'
import bauStranding from '../../resources/images/icon/bau-stranding-legend-icon.svg'
import circleGreen from '../../resources/images/icon/circle-green.svg'
import selectedAsset from '../../resources/images/icon/dashedYellow.svg'
import targetPathwaySvg from '../../resources/images/icon/dashedOrange.svg'
import agreedActionsPathway from '../../resources/images/icon/agreedActionsPathway.svg' 
import applicableActionsPathway from '../../resources/images/icon/applicableActionsPathway.svg' 
import {DomHandler} from 'primereact/utils'
import {
  convertMonthToNumber,
  getFirstLastDateISO,
  monthsNumber
} from '../../services/assets/dataquality-service'
import {uuid} from 'uuidv4'
import CSS_VARIABLES from '../../resources/css/_variables.scss'
import { formatDateToDdMonYy as formatDateToDdMonYyNew } from './DateUtils'
import { getLocalizedValue as getLocalizedValueNew } from './LanguageUtils'
import {
  excelHelpers as excelHelpersNew,
  columnIndexToLetter as columnIndexToLetterNew  
} from './ExcelUtils'
import {getDataset} from '../../components/pages/funds/fund-dashboard/fund-dashboard-hooks-&-helpers/fundDashboardHelper'
import actualDataIcon from '../../resources/images/icon/dataQuality/Rectangle-255056.svg'
import estimatedDataIcon from '../../resources/images/icon/dataQuality/Rectangle-8AA0A3.svg'
import inCompleteDataIcon from '../../resources/images/icon/dataQuality/Rectangle-CE6D2B.svg'
import notCoveredDataIcon from '../../resources/images/icon/dataQuality/Rectangle-DAD2C1.svg'
import {Icon, Text} from '../../components/atomic'
import {removeBarMinHeight} from '../../components/pages/assets/performance/performance-home/ChartHelper'

export const getNotificationTemplate = (event) => {
  if (event.eventType === 'PerformanceVariance' && !event.isPlaceholder) {
    if (event.content.find(x => x.key === 'UtilityType').value === 'Waste') {
      return I18n('t_performance_event_waste_template', {
        0: I18n('t_' + event.content.find(x => x.key === 'UtilityType').value.toLowerCase()),
        1: I18n('t_' + monthList[event.content.find(x => x.key === 'Month').value].toLowerCase()),
        2: trucateNumWOneDecimal(Number(event.content.find(x => x.key === 'DifferencePercentage').value)),
        3: I18n('t_' + event.content.find(x => x.key === 'UnderOrOver').value)
      })
    } else {
      return I18n('t_performance_event_template', {
        0: I18n('t_' + event.content.find(x => x.key === 'UtilityType').value.toLowerCase()),
        1: I18n('t_' + monthList[event.content.find(x => x.key === 'Month').value].toLowerCase()),
        2: trucateNumWOneDecimal(Number(event.content.find(x => x.key === 'DifferencePercentage').value)),
        3: I18n('t_' + event.content.find(x => x.key === 'UnderOrOver').value)
      })
    }
  } else if (event.eventType === 'DataQualityIssue') {
    return I18n('t_performance_data_missing_event_template', {
      0: I18n('t_' + monthList[event.content.find(x => x.key === 'Month').value].toLowerCase()),
      1: Array.from(event.content.find(x => x.key === 'ElectricityMPAN').value)
    })
  } else if (event.eventType === 'CompletedAction') {
    return I18n('t_performance_completed_action_template', {
      0: event.content.find(x => x.key === 'Description').value
    })
  } else if (event.eventType === 'CommercialEvent') {
    return I18n('t_performance_commerial_event_template', {
      0: event.content.find(x => x.key === 'UnitName').value,
      1: I18n('t_' + event.content.find(x => x.key === 'LeaseBreakDescription').value)
    })
  }

  return ''
}

export const getFiller = (sector,area) => {
  let propertyImage
  if (sector.includes('Office')) {
    propertyImage = area ? fillerOfficePng: fillerOffice
  } else if (sector.includes('Hotel')) {
    propertyImage = area ? fillerHotelPng : fillerHotel
  } else if (sector.includes('Retail')) {
    propertyImage = area ? fillerRetailPng : fillerRetail
  } else if (sector.includes('Leisure')) {
    propertyImage = area ? fillerLeisurePng : fillerLeisure
  } else if (sector.includes('Industrial')) {
    propertyImage = area ? fillerIndustrialPng : fillerIndustrial
  } else if (sector.includes('Residential')) {
    propertyImage = area ? fillerResidentialPng : fillerResidential
  } else if (sector.includes('Mixed')) {
    propertyImage = area ? fillerMixedPng : fillerMixed
  } else if (sector.includes('Education')) {
    propertyImage = area ? fillerEducationPng : fillerEducation
  } else if (sector.includes('Healthcare')) {
    propertyImage = area ? fillerHealthcarePng : fillerHealthcare
  } else if (sector.includes('Technology')) {
    propertyImage = area ? fillerTechnologyPng : fillerTechnology
  } else {
    propertyImage = area ? fillerOtherPng : fillerOther
  }
  return propertyImage
}

export const getFillerImage = (rowData,thumbnail,area) => {
  let propertyImage = area ? fillerOtherPng : fillerOther
  const sector = rowData.sector
  let imageUri = (thumbnail || rowData.assetImageThumbnailUri) ? rowData.assetImageThumbnailUri : rowData.assetImageUri
  if (imageUri) {
    propertyImage = imageUri
  } else if(sector) {
    propertyImage=getFiller(sector, area)
  }
  return propertyImage
}

/*
  If Asset image ? image else filler image is used
  New function is written to avoid issues during the release
 */
export const getFillerImageForPassport = (rowData,area) => {
  let propertyImage = area ? fillerOtherPng : fillerOther
  if (rowData.assetImageUri) {
    propertyImage =  rowData.assetImageUri
  } else if(rowData.sector) {
    propertyImage=getFiller(rowData.sector, area)
  }
  return propertyImage
}
//Obsolete, please use the new function in LanguageUtils.js
export const getLocalizedValue = (locale, key) => {
  return getLocalizedValueNew(locale, key)
}

export const getLocalizedValueUsingIntl = (intl, id , placeholders) => {
  return intl.formatMessage({id},placeholders)
}

export const getLocalizedValueUppercase = (locale, key) => {
  return messages[locale][key].toUpperCase()
}

export const smallFirstLetterInKey = (key) => {
  return 't_' + key.charAt(0).toLowerCase() + key.slice(1)
}

//region Helper methods for getting localized date message.
export const getLocalizedDateMessage=(dateCreatedStr, unitSystem, selectedLanguage)=>{
  let dateCreated=new Date(dateCreatedStr)
  let dateCreatedDateOnly=new Date(dateCreated.getFullYear(), dateCreated.getMonth(), dateCreated.getDate())

  return unitDateFormatter(dateCreatedDateOnly, unitSystem, selectedLanguage)
}

//endregion

//Helper method to remove decimal point.
export function truncateDecimal(number, decimal) {
  let newNumber = number ? number.toString(): number
  let indexOfDecimal= newNumber? newNumber.indexOf('.') : -1
  if((indexOfDecimal!==-1)){
    newNumber = newNumber.slice(0, indexOfDecimal+(decimal+1))
  }
  return isNaN(parseFloat(newNumber)) ?  0: parseFloat(newNumber)
}

export function selectedUtilityKeyNames(utilityName) {
  let utilityKey
  switch (utilityName) {
  case 'totalPercentage':
    utilityKey = 'All'
    break
  case 'electricityPercentage':
    utilityKey = 'Electricity'
    break
  case 'fuelsPercentage':
    utilityKey = 'GasFuelsAndThermals'
    break
  case 'waterPercentage':
    utilityKey = 'Water'
    break
  case 'wastePercentage':
    utilityKey = 'Waste'
    break
  case 'carbonPercentage':
    utilityKey = 'Carbon'
    break
  case 'energyIntensity':
    utilityKey = 'EnergyIntensity'
    break
  case 'recycledWaste':
    utilityKey = 'RecycledWaste'
    break
  default:
    utilityKey = 'Electricity'
  }
  return utilityKey
}

export const formatUnits = (value,language) => {
  if (value) {
    return Number(value).toLocaleString(language,{maximumFractionDigits: 2 })
  }
  return value
}

export const formatPercentage = (value, title) => {
  let percentage = ''

  if (value !== undefined && value !== null) {
    percentage = trucateNumWOneDecimal(value * 100)
  }

  return percentage
}

export const formatValue = (value, selectedUtility, title, isBiggerUnit) => {
  const unitSystem = getLocalStorageItem('unitSystem')
  //let formattedValue = '0'
  let unit = unitsConverter(selectedUtility, unitSystem, isBiggerUnit)
  
  if (selectedUtility === 'Water')
    unit = unitsConverter(WATER, unitSystem, isBiggerUnit)
  else if (selectedUtility === 'Carbon')
    unit = unitsConverter(CARBON, unitSystem, isBiggerUnit)
  else if (selectedUtility === 'Waste')
    unit = unitsConverter(WASTE, unitSystem, isBiggerUnit)
  if (value !== undefined && value !== null) {
    value = (selectedUtility === 'Waste') ?  value.toFixed(2) : trucateNumWOneDecimal(value)

    value = (title === 'Variance') ? Math.abs(value) : value
  }
  // formattedValue = value !== null ? formatUnits(value) + unit : '-'
  return value !== null ? formatUnits(value) + unit : '-'
}

export function getHeaderForPerformanceModal(performanceDataMonthly, isTargetAvailable, isAssetGapFillingFlagEnabled) {
  let utilityName = smallFirstLetterInKey(performanceDataMonthly.meterUtility)
  let costLabel=''
  if (utilityName !== 'Waste') {
    if ((performanceDataMonthly.variancePercentage * 100) > 5) {
      costLabel = 't_overspend'
    } else {
      costLabel = 't_savings'
    }
  }
  let prevTargetText = (isTargetAvailable || performanceDataMonthly.hasTarget) ? 't_target' : 't_previous'
  return {
    utility: utilityName,
    month: performanceDataMonthly.month,
    hasTarget: performanceDataMonthly.hasTarget,
    actual:  isAssetGapFillingFlagEnabled ? {
      label: 't_total',
      value: formatValue(performanceDataMonthly.actualAbsolute, performanceDataMonthly.meterUtility,'t_total'),
      percentage: formatPercentage(performanceDataMonthly.actualPercentage, 't_total')
    } : {
      label: 't_current',
      value: formatValue(performanceDataMonthly.actualAbsolute, performanceDataMonthly.meterUtility,'t_current'),
      percentage: formatPercentage(performanceDataMonthly.actualPercentage, 't_current')
    },
    target: {
      label: performanceDataMonthly.hasTarget ? 't_target' : 't_previous',
      value: performanceDataMonthly.hasTarget ?  formatValue(performanceDataMonthly.targetAbsolute,performanceDataMonthly.meterUtility,prevTargetText) : formatValue(performanceDataMonthly.previousAbsolute,performanceDataMonthly.meterUtility,prevTargetText),
      percentage: formatPercentage(performanceDataMonthly.targetPercentage/100, prevTargetText)
    },
    variance: {'label': 't_variance', 'value': formatPercentage(performanceDataMonthly.variancePercentage, 't_variance')},
    overspend: {'label': costLabel, 'value': performanceDataMonthly.overspend},
    gapFilledData: {label: 't_gap_filled', value: formatValue(trucateNumWTwoDecimal(performanceDataMonthly.filledData), performanceDataMonthly?.meterUtility, 't_gapfilled'),}
  }
}

export function getPerformanceModalHeaderData(perfModalData, isTargetAvailable, perfGraphData, assetLevelFeatureFlag) {
  let utilityName = smallFirstLetterInKey(perfModalData.data.meterUtility)
  let costLabel
  if (utilityName !== 'Waste') {
    if ((perfModalData.data.variancePercentage * 100) > 5) {
      costLabel = 't_overspend'
    } else {
      costLabel = 't_savings'
    }
  }
  let prevTargetText = (isTargetAvailable || perfModalData.data.hasTarget) ? 't_target' : 't_previous'
  let monthlyConsumptionPerf = perfGraphData?.data[0].monthlyConsumptionPerformances[convertMonthToNumber(perfModalData.data.month)]

  return  {
    utility: utilityName,
    'month': perfModalData.data.month,
    'hasTarget': perfModalData.data.hasTarget,
    'actual':  assetLevelFeatureFlag ? {
      'label': 't_total',
      'value': formatValue(perfModalData.data.actualAbsolute, perfModalData.data.meterUtility,'t_total'),
      'percentage': formatPercentage(perfModalData.data.actualPercentage, 't_total')
    } : {
      'label': 't_current',
      'value': formatValue(perfModalData.data.actualAbsolute, perfModalData.data.meterUtility,'t_current'),
      'percentage': formatPercentage(perfModalData.data.actualPercentage, 't_current')
    },
    'target': {
      'label': perfModalData.data.hasTarget ? 't_target' : 't_previous',
      'value': perfModalData.data.hasTarget ?  formatValue(perfModalData.data.targetAbsolute,perfModalData.data.meterUtility,prevTargetText) : formatValue(perfModalData.data.previousAbsolute,perfModalData.data.meterUtility,prevTargetText),
      'percentage': formatPercentage(perfModalData.data.targetPercentage/100, prevTargetText)
    },
    'variance': {'label': 't_variance', 'value': formatPercentage(perfModalData.data.variancePercentage, 't_variance')},
    'overspend': {'label': costLabel, 'value': perfModalData.data.overspend},
    gapFilledData: {
      label: 't_gap_filled', 
      value: monthlyConsumptionPerf?.filledData ? formatValue(trucateNumWTwoDecimal(monthlyConsumptionPerf?.filledData), perfModalData.data.meterUtility, 't_gapfilled') : '-',
    }
  }
}

export function createEndMonthParam(yearPicked, monthPicked) {
  // Expected format: endMonth=2019-3
  // Currently Custom-date-picker has year value from 2001 to current year.
  return `20${yearPicked}-${monthPicked}`
}

export function hasPermission(userAction, userRole) {
  let userPermission=false
  if(userRole && userAction){
    userPermission=UserPermissions[userAction].includes(userRole)
  }
  return userPermission
}

export function getLoggedInUserRole() {
  // Currently expecting user role name not id.
  let loggedInUserRole=getLocalStorageItem('userRole')
  if(loggedInUserRole){
    loggedInUserRole=loggedInUserRole.toUpperCase()
  }
  return loggedInUserRole
}

export function getLocalStorageItem(key) {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  return userInfo ? userInfo[key] : null
}

export function getLocalStorageByKey(key) {
  return JSON.parse(localStorage.getItem(key))
}

export function isLoggedInSelectedUserSame(selectedUser) {
  return (selectedUser && getLocalStorageItem('userId') === selectedUser.userId)
}

export function isLoggedInUserAdmin(loggedInUserRole) {
  return [UserRoles.ADMIN, UserRoles.ADMINISTRATOR].some(i => i === loggedInUserRole?.toUpperCase())
}

export function clearUserContent(){
  localStorage.removeItem(userInfo)
  localStorage.removeItem(userGroupDetails)
  localStorage.removeItem(userAssetDetails)
  localStorage.removeItem(userFundDetails)
}

export function clearGroupContent(){
  localStorage.removeItem(groupInfo)
  localStorage.removeItem(groupUserDetails)
  localStorage.removeItem(storeGroupAsset)
}

export function setLocalStorageItem(key, value) {
  localStorage.setItem(key, JSON.stringify(value))
}

export const bigNumberFormatter = (num,selectedLanguage='en-US') => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  let item = lookup.slice().reverse().find(function(item) {
    return num >= item.value
  })
  return item ? (num / item.value).toLocaleString(selectedLanguage, {maximumFractionDigits: 2 }).replace(rx, '$1') + item.symbol : '0'
}

export function getUnitLabel(unitOfMeasure) {
  return unitOfMeasure === 'Metric' ? 'm²' : 'ft²'
}

export function getTabText(selectedLanguage, messageKey) {
  return getLocalizedValue(selectedLanguage, messageKey)
}

export function getNavLinkConfigs(selectedFund, selectedLanguage ) {
  return [
    {state: selectedFund, path: null, activeClassName:  CSS_LINK_ACTIVE, tabText: getTabText(selectedLanguage,'t_dashboard')},
    {state: selectedFund, path: FUND_VIEWS_PAGES.performance, activeClassName: CSS_LINK_ACTIVE_GREY, tabText: getTabText(selectedLanguage, 't_performance')},
    {state: selectedFund, path: FUND_VIEWS_PAGES.dataQuality, activeClassName: CSS_LINK_ACTIVE_GREY, tabText: getTabText(selectedLanguage, 't_data_quality')},
    {state: selectedFund, path: FUND_VIEWS_PAGES.actionPlan, activeClassName: CSS_LINK_ACTIVE_GREY, tabText: getTabText(selectedLanguage, 't_action_plan')}
  ]
}

export function getFundViewsNavLinkConfigsForFlags(selectedFund, selectedLanguage, featureState) {
  let navLinks = getNavLinkConfigs(selectedFund, selectedLanguage)
  if (navLinks && featureState && featureState.sieraplus_nzc && isNZCModuleAccessible()) {
    navLinks.push({ state: selectedFund, path: FUND_VIEWS_PAGES.netZeroCarbon, activeClassName: CSS_LINK_ACTIVE_GREY, tabText: getTabText(selectedLanguage, 't_net_zero_carbon') })
  }

  // Add Reports link if SieraPlus Reports feature is enabled
  if (navLinks && featureState && featureState.sieraplus_sfdr) {
    navLinks.push( {state: selectedFund, path: FUND_VIEWS_PAGES.fundReports, activeClassName: CSS_LINK_ACTIVE_GREY, tabText: getTabText(selectedLanguage, 't_fund_reports')})
  }
  return navLinks
}

export function tabNavigatable(tab,visitedTab){
  let isNewTabNavigatable = false
  if(tab === 0){
    isNewTabNavigatable = visitedTab[tab]
  }
  if(tab === 1){
    isNewTabNavigatable = visitedTab[tab]
  }
  if(tab === 2){
    isNewTabNavigatable = visitedTab[tab]
  }
  if(tab === 3){
    isNewTabNavigatable = visitedTab[tab]
  }
  return isNewTabNavigatable
}

export function messageCheck(message){
  return message === 'InvalidCredentials' || 'Invalid user' || 'Invalid User' || 'InvalidUser' ?
    'E-mail address / User name already exists.' : message
}

export function createAssetsIdsObject(selectedAssets) {
  return selectedAssets ? {'assetIds': selectedAssets.map((selectedAsset) => selectedAsset.assetId)} : {}
}

export function createUserAssetGroupsObject(selectedGroups) {
  return selectedGroups ? {'assetGroupIds': selectedGroups.map((selectedGroup) => selectedGroup.assetGroupID)} : {}
}

export function getStructuredFund(selectedFunds){
  return selectedFunds ? {'assetGroupIds': selectedFunds.map((individualFund) => individualFund.assetGroupID)} : {}
}

export const CachedUserState={
  //Cache Key: CacheUserState
  saveUserDataToCache: function(key, value) {
    let userData=JSON.parse(localStorage.getItem(CacheKeyUserState))
    if(userData){
      userData[key]=value
    } else {
      userData={[key]:value}
    }
    localStorage.setItem(CacheKeyUserState, JSON.stringify(userData))
  },
  getUserDataFromCache: function(key) {
    let userDataFromCache=JSON.parse(localStorage.getItem(CacheKeyUserState))
    if(userDataFromCache){
      userDataFromCache=userDataFromCache[key]
    }
    return userDataFromCache
  },
  clearUserDataCache: function() {
    localStorage.removeItem(CacheKeyUserState)
  }
}

export const saveCustomPickerValues = (pickerValue) => {
  localStorage.setItem(LOCAL_STORAGE_KEYS.dpCustomTimePeriod, JSON.stringify(pickerValue))
  localStorage.removeItem(LOCAL_STORAGE_KEYS.dpTimePeriod)
}

export function createAndSaveCustomDefaultDate(financialYearStart) {
  setDefaultDate(financialYearStart)
  const defaultDatePickerValue=CachedUserState.getUserDataFromCache(LOCAL_STORAGE_KEYS.defaultDatePickerValue)
  if(defaultDatePickerValue){
    saveCustomPickerValues(defaultDatePickerValue)
  }
}

export async function getHomePagePathV2(featureFlagFundView, userFundAccess, instanceUrlName, fundGroupData) {
  const organisationList = fundGroupData ? [fundGroupData] : await getFundGroupData()
  const dpCustomTime = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.dpCustomTimePeriod))
  const dpTime = localStorage.getItem(LOCAL_STORAGE_KEYS.dpTimePeriod)
  let homePage=createNavURLForFundPages({instanceName: instanceUrlName, pathSuffix: ASSET_PAGES.assets})
  if ( organisationList.length === 0 || !featureFlagFundView) {
    return homePage
  }
  
  switch(userFundAccess) {
  case USER_FUND_ACCESSES.NoFundAccess:
    return homePage
  case USER_FUND_ACCESSES.MultipleOrganisationAccess:
    return {pathname: createNavURLForFundPages({instanceName: instanceUrlName})}
  case USER_FUND_ACCESSES.MultipleFundAccess:
    if(organisationList){
      const fundList = CachedUserState.getUserDataFromCache(LOCAL_STORAGE_KEYS.selectedOrgAndFund)
      CachedUserState.saveUserDataToCache(CacheKeyOrganisation, organisationList[0])
      CachedUserState.saveUserDataToCache(LOCAL_STORAGE_KEYS.selectedOrgAndFund, {orgName: organisationList[0]?.name, orgUrlName: organisationList[0]?.urlName, fundName: fundList?.fundName, fundUrlName: fundList?.fundUrlName})
      return {pathname: createNavURLForFundPages({instanceName: instanceUrlName, orgName: organisationList[0]?.urlName}), state: organisationList[0]}
    }
    break
  case USER_FUND_ACCESSES.SingleFundAccess:
    CachedUserState.saveUserDataToCache(CacheKeyOrganisation, organisationList[0])
    if(organisationList){
      const orgId=organisationList[0].id
      const fundList = await getFundGroups(orgId)
      const funds=fundList?.funds
      const fundHomePath=createNavURLForFundPages({instanceName: instanceUrlName, orgName: organisationList[0].urlName, fundName: funds[0].fundUrlName})
      CachedUserState.saveUserDataToCache(CacheKeyFundGroups, funds[0])
      CachedUserState.saveUserDataToCache(LOCAL_STORAGE_KEYS.selectedOrgAndFund, {orgName: organisationList[0]?.name, orgUrlName: organisationList[0]?.urlName, fundName: funds[0]?.fundname, fundUrlName: funds[0]?.fundUrlName})
      if(!dpTime && !dpCustomTime){
        createAndSaveCustomDefaultDate(funds[0]?.financialYearStart)
      }
      return {pathname: fundHomePath, state: funds[0]}
    }
  }
}

//region Generate Home path
export async function getHomePagePath(featureFlagFundView, userFundAccess, instanceUrlName) {
  const dpCustomTime = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.dpCustomTimePeriod))
  const dpTime = localStorage.getItem(LOCAL_STORAGE_KEYS.dpTimePeriod)
  let homePage = {pathname: createNavURLForFundPages({instanceName: instanceUrlName, pathSuffix: ASSET_PAGES.assets})}
  if(userFundAccess===USER_FUND_ACCESSES.NoFundAccess){
    if(!dpTime && !dpCustomTime){
      createAndSaveCustomDefaultDate(FY_START_FOR_ASSET_ACCESS)
    }
  } else {
    if(featureFlagFundView){
      if(userFundAccess===USER_FUND_ACCESSES.MultipleOrganisationAccess){
        const organisationsHomePath=createNavURLForFundPages({instanceName: instanceUrlName})
        homePage= {pathname: organisationsHomePath}
      } else if(userFundAccess===USER_FUND_ACCESSES.MultipleFundAccess){
        const organisationList = await getFundGroupData()
        const fundList = CachedUserState.getUserDataFromCache(LOCAL_STORAGE_KEYS.selectedOrgAndFund)
        if(organisationList){
          CachedUserState.saveUserDataToCache(CacheKeyOrganisation, organisationList[0])
          CachedUserState.saveUserDataToCache(LOCAL_STORAGE_KEYS.selectedOrgAndFund, {orgName: organisationList[0]?.name, orgUrlName: organisationList[0]?.urlName, fundName: fundList?.fundName, fundUrlName: fundList?.fundUrlName})
          const fundsHomePath=createNavURLForFundPages({instanceName: instanceUrlName, orgName: organisationList[0]?.urlName})
          homePage= {pathname: fundsHomePath, state: organisationList[0]}
        }
      } else if(userFundAccess===USER_FUND_ACCESSES.SingleFundAccess){
        const organisationList = await getFundGroupData()
        if(organisationList){
          CachedUserState.saveUserDataToCache(CacheKeyOrganisation, organisationList[0])
          const orgId=organisationList[0].id
          const fundList = await getFundGroups(orgId)
          const funds=fundList?.funds
          if(funds){
            const fundHomePath=createNavURLForFundPages({instanceName: instanceUrlName, orgName: organisationList[0].urlName, fundName: funds[0].fundUrlName})
            CachedUserState.saveUserDataToCache(CacheKeyFundGroups, funds[0])
            CachedUserState.saveUserDataToCache(LOCAL_STORAGE_KEYS.selectedOrgAndFund, {orgName: organisationList[0]?.name, orgUrlName: organisationList[0]?.urlName, fundName: funds[0]?.fundname, fundUrlName: funds[0]?.fundUrlName})
            if(!dpTime && !dpCustomTime){
              createAndSaveCustomDefaultDate(funds[0]?.financialYearStart)
            }
            homePage= {pathname: fundHomePath, state: funds[0]}
          }
        }
      }
    } else {
      createAndSaveCustomDefaultDate(FY_START_FOR_ASSET_ACCESS)
    }
  }
  return homePage
}

//endregion

export function prefixSlash(pageName) {
  return `/${pageName}`
}

export function createNavURLForFundPages(urlParamsConfig) {
  let urlParams = ''
  if(urlParamsConfig.instanceName){
    urlParams=`/${urlParamsConfig.instanceName}`
  }
  if(urlParamsConfig.orgName){
    urlParams=`${urlParams}/${urlParamsConfig.orgName}`
  }
  if(urlParamsConfig.fundName){
    urlParams=`${urlParams}/${urlParamsConfig.fundName}`
  }
  if(urlParamsConfig.assetId){
    urlParams=`${urlParams}/${urlParamsConfig.assetId}`
  }
  if(urlParamsConfig.pathSuffix){
    urlParams=`${urlParams}/${urlParamsConfig.pathSuffix}`
  }
  if(urlParamsConfig.actionId){
    urlParams=`${urlParams}/${urlParamsConfig.actionId}`
  }
  urlParams=urlParams?.toLowerCase()
  return urlParams
}

export function createNavURLForUserPages({pathPrefix, instanceName, userId, groupId, pathSuffix}) {
  // Expected object template={instanceName, userId, pathSuffix}
  let urlParams
  if(pathPrefix){
    urlParams=`/${pathPrefix}`
  }
  if(instanceName){
    urlParams=`/${instanceName}`
  }
  if(userId){
    urlParams=`${urlParams}/${userId}`
  } else if(groupId){
    urlParams=`${urlParams}/${groupId}`
  }
  if(pathSuffix){
    urlParams=`${urlParams}/${pathSuffix}`
  }
  urlParams=urlParams?.replace(/ /g, '-')
  return urlParams
}

export const dropdownItemTemplate  = (option) => {
  return (
    <>
      <div className='dropdown-item-1'>
        <div className='dropdown-item-2 dropdown-item-wrap'>{option.label}</div>
      </div>
      <div className={'dropdown-panel-item-horiz-line'} />
    </>
  )
}

export function getInstance() {
  const userInfo = getLocalStorageByKey(LOCAL_STORAGE_KEYS.userInfo)
  return userInfo?.instanceUrlName
}

export function isUserLoggedIn() {
  return localStorage.hasOwnProperty(LOCAL_STORAGE_KEYS.userInfo)
}

// Add custom position for chart.js tooltip
Tooltip.positioners.custom = function(elements, position) {
  if (!elements.length) {
    return {
      x: 0,
      y: 0
    }
  }
  let offset
  if (elements[0].element.width / 2 > position.x) {
    offset = 20
  } else {
    offset = -20
  }
  return {
    x: position.x + offset,
    y: position.y
  }
}

export function onSelection(currentSection,newSelectedItem, searchText, thNode, setSelectedItems, setFilteredSelectedItems, filteredHiddenSelectedItems) {
  if (searchText === '') {
    if (thNode) {
      const checkboxIconPiMinus = thNode.querySelector('.p-checkbox-icon.pi.pi-minus')
      if (checkboxIconPiMinus) {
        newSelectedItem = []
      }
    }
    setSelectedItems(newSelectedItem)
  } else {
    if (thNode) {
      const checkboxIconPiMinus = thNode.querySelector('.p-checkbox-icon.pi.pi-minus')
      const checkboxIconPiCheck = thNode.querySelector('.p-checkbox-icon.pi.pi-check')
      if (checkboxIconPiMinus) {
        setFilteredSelectedItems([])
        setSelectedItems(filteredHiddenSelectedItems)
      } else if (checkboxIconPiCheck) {
        setFilteredSelectedItems([])
        setSelectedItems([...filteredHiddenSelectedItems])
      } else {
        setFilteredSelectedItems(newSelectedItem)
        setSelectedItems([...filteredHiddenSelectedItems, ...newSelectedItem])
      }
    } else {
      let newlySelectedIndividualItem
      if(currentSection==='users'){
        newlySelectedIndividualItem = newSelectedItem.filter((newItem) => !filteredHiddenSelectedItems.find(hiddenFilteredGroup => (newItem.assetGroupID === hiddenFilteredGroup.assetGroupID)))
      }
      if(currentSection === 'groups'){
        newlySelectedIndividualItem = newSelectedItem.filter((newItem) => !filteredHiddenSelectedItems.find(hiddenFilteredGroup => (newItem.userId === hiddenFilteredGroup.userId)))
      }
      setSelectedItems([...filteredHiddenSelectedItems, ...newlySelectedIndividualItem])
      setFilteredSelectedItems([...newlySelectedIndividualItem])
    }
  }
}

export function getPaginatorTemplate(requiredClassName, paginationOptions){
  return  {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      return (
        <>
          <PaginatorDropdown options={options} panelClassName={requiredClassName} paginationOptions={paginationOptions} />
        </>
      )
    },
    'CurrentPageReport': (options) => {
      return (
        <RowInfo options={options}/>
      )
    }
  }
}

export function getUserAuthorisedModules() {
  return getLocalStorageByKey(LOCAL_STORAGE_KEYS.userInfo) ? getLocalStorageByKey(LOCAL_STORAGE_KEYS.userInfo).authorisedModules : null
}

export function isNZCModuleAccessible() {
  let userAuthorisedModules = getUserAuthorisedModules()
  return (userAuthorisedModules && userAuthorisedModules.includes(USER_AUTHORISED_MODULES.NZC))
}
export function onSorting(e,sortOrder,sortField,columnName) {

  let orderRev = sortOrder

  if (orderRev === 1) {
    orderRev = -1
  } else {
    orderRev = 1
  }
  if (e.sortField !== sortField) {
    orderRev = -1
  }
  if (e.sortField === columnName && e.sortField !== sortField) {
    orderRev = 1
  }
  return orderRev
}
export function savePickerDateInLS(timePeriod, pickerValue) {
  localStorage.setItem(LOCAL_STORAGE_KEYS.dpTimePeriod, timePeriod)
  if ([PERIOD_TYPES.CUSTOM, CUSTOM].some(i => i === timePeriod)) {
    localStorage.setItem(LOCAL_STORAGE_KEYS.dpCustomTimePeriod, JSON.stringify(pickerValue))
  }
}

export const energyRating = (asset) => {
  let energy = '-'
  if(asset.epcScheme !== null && asset.epcRating != null) {
    energy = asset.epcRating + ' - ' + asset.epcScheme
  }
  return energy
}

export function getDateTimeTemplate(rowData, selectedLanguage, unitSystem) {
  const options = {}

  if (unitSystem === getLocalizedValue(selectedLanguage, 't_imperial')) {
    options.year = 'numeric'
    options.month = 'long'
    options.day = 'numeric'
    options.second = undefined
  } else { //Metric will be default case
    options.year = '2-digit'
    options.month = 'short'
    options.day = 'numeric'
    options.second = undefined
  }

  const updatedLanguage = unitSystem === getLocalizedValue(selectedLanguage, 't_imperial') && selectedLanguage === 'en-GB' ? 'en-us' : selectedLanguage
  let dateTime
  if (rowData.lastLogin) {
    let date = rowData.lastLogin ? new Date(rowData.lastLogin).toLocaleDateString(updatedLanguage, options) : rowData.lastLogin
    let time = new Date(rowData.lastLogin).getHours() + ':' + ('0' + new Date(rowData.lastLogin).getMinutes()).slice(-2)
    dateTime = date + ' - ' + time
  }
  return dateTime
}

export function saveUrlParamsIds({instanceName, instanceId, organisationName, organisationId, fundName, fundGroupId, fundId, assetName, assetId,actualAssetName,actualFundName, actionId}) {
  const urlParamsIdsObject={
    'instanceName': null,
    'instanceId': null,
    'organisationName': null,
    'organisationId': null,
    'fundName': null,
    'fundGroupId': null,
    'fundId': null,
    'assetName': null,
    'assetId': null,
    'actualAssetName': null,
    'actualFundName': null,
    'actionId': null
  }
  if (instanceId > 0) {
    urlParamsIdsObject.instanceName=instanceName
    urlParamsIdsObject.instanceId=instanceId
  }
  if (organisationId > 0) {
    urlParamsIdsObject.organisationName=organisationName
    urlParamsIdsObject.organisationId=organisationId
  }
  if (fundId > 0) {
    urlParamsIdsObject.fundName=fundName
    urlParamsIdsObject.fundId=fundId
    urlParamsIdsObject.fundGroupId=fundGroupId
    urlParamsIdsObject.actualFundName = actualFundName
  }
  if (assetName && assetId > 0) {
    urlParamsIdsObject.assetName=assetName
    urlParamsIdsObject.assetId=assetId
    urlParamsIdsObject.actualAssetName = actualAssetName
  }
  if(actionId > 0) {
    urlParamsIdsObject.actionId=actionId
  }
  CachedUserState.saveUserDataToCache(LOCAL_STORAGE_KEYS.cacheUrlParamsIdsData, urlParamsIdsObject)
  return urlParamsIdsObject
}

export const momentDate = (date, format, selectedLanguage) => {
  return moment(date).locale(selectedLanguage).format(format)
}

export const IMPERIAL_SHORT_DATE = 'MMM D, YYYY'
export const METRIC_SHORT_DATE = 'D MMM YYYY'
export const MONTH_YEAR = 'MMM YYYY'

export const unitDateFormatter = (date, unitSystem, selectedLanguage) => {
  let formattedDate = ''
  if(date){
    if(unitSystem === UNITS.IMPERIAL){
      formattedDate = momentDate(date, IMPERIAL_SHORT_DATE, selectedLanguage)
    } else if(unitSystem === UNITS.METRIC){
      formattedDate = momentDate(date, METRIC_SHORT_DATE, selectedLanguage)
    } else if (unitSystem === 'MONTH_YEAR'){
      formattedDate = momentDate(date, MONTH_YEAR, selectedLanguage)
    }
  }
  return formattedDate
}

export const UNIT_IMPERIAL_ENERGY = ' kBtu'
export const UNIT_IMPERIAL_ENERGY_MEGA = ' MBtu'
export const UNIT_METRIC_ENERGY = ' kWh'
export const UNIT_METRIC_ENERGY_MEGA = ' MWh'
export const UNIT_IMPERIAL_ENERGY_INTENSITY = ' kBtu/ft²'
export const UNIT_METRIC_ENERGY_INTENSITY = ' kWh/m²'
export const UNIT_IMPERIAL_WATER = ' gal'
export const UNIT_METRIC_WATER = ' m³'
export const UNIT_EMISSION = 't'
export const UNIT_CARBON = ' kgCO₂e'
export const UNIT_CARBON_NORMAL = ' kgCO2e'
export const UNIT_CARBON_TONNE = ' tCO₂e'
export const UNIT_CARBON_TONNE_NORMAL = ' tCO2e'
export const UNIT_IMPERIAL_AREA = ' ft²'
export const UNIT_METRIC_AREA = ' m²'
export const UNIT_METRIC_CARBON_INTENSITY = 'kg/m²'
export const UNIT_METRIC_WATER_INTENSITY = ' m³/m²'
export const UNIT_IMPERIAL_WATER_INTENSITY = 'gal/ft²'
export const UNIT_IMPERIAL_CARBON_INTENSITY = 'kg/ft²'
export const UNIT_KILO_GRAMS = 'kg'

export function unitsConverter(name, unitSystem, isBiggerUnit = false, useSubscript = true) {
  if(unitSystem === UNITS.IMPERIAL){
    return getImperialUnits(name, isBiggerUnit, useSubscript)
  } else {
    return getMetricUnits(name, isBiggerUnit, useSubscript)
  }
}

export function getUnitName(utility, unitSystem, isBiggerUnit = false) {
  if(unitSystem === UNITS.IMPERIAL){
    return getImperialUnits(utility, isBiggerUnit)
  }
  return getMetricUnits(utility, isBiggerUnit)
}

export function utilityIntensityMetricUnits(utility){
  let unit
  switch (utility){
  case UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON :
    unit = UNIT_METRIC_CARBON_INTENSITY
    break
  case UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY:
    unit =UNIT_METRIC_ENERGY_INTENSITY
    break
  case UTILITY_LABELS_OBJECT_TITLE_CASE.THERMALS :
  case UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL:
    unit = UNIT_METRIC_ENERGY_INTENSITY
    break
  case UTILITY_LABELS_OBJECT_TITLE_CASE.WATER:
    unit = UNIT_METRIC_WATER_INTENSITY
    break
  }
  return unit
}

export function utilityIntensityImperialUnits(utility){
  let unit
  switch (utility){
  case UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON :
    unit = UNIT_IMPERIAL_CARBON_INTENSITY
    break
  case UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY:
    unit =UNIT_IMPERIAL_ENERGY_INTENSITY
    break
  case UTILITY_LABELS_OBJECT_TITLE_CASE.THERMALS :
  case UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL:
    unit = UNIT_IMPERIAL_ENERGY_INTENSITY
    break
  case UTILITY_LABELS_OBJECT_TITLE_CASE.WATER:
    unit = UNIT_IMPERIAL_WATER_INTENSITY
    break
  }
  return unit
}

function getMetricUnits(name, isBiggerUnit, useSubscript) {
  let unit
  switch (name) {
  case ENERGY:
  case OIL:
  case DISTRICT_COOLING:
  case DISTRICT_HEATING:
  case DISTRICT_SPACE_COOLING:
  case DISTRICT_SPACE_HEATING:
  case UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY:
  case UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL:
  case GAS_FUELS:
    unit = isBiggerUnit ? UNIT_METRIC_ENERGY_MEGA : UNIT_METRIC_ENERGY
    break

  case UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY_INTENSITY:
    unit = UNIT_METRIC_ENERGY_INTENSITY
    break

  case UTILITY_LABELS_OBJECT_TITLE_CASE.WATER:
    unit = UNIT_METRIC_WATER
    break

  case UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE:
    unit = UNIT_EMISSION
    break

  case UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON:
    if (isBiggerUnit) {
      unit = useSubscript ? UNIT_CARBON_TONNE : UNIT_CARBON_TONNE_NORMAL
    } else {
      unit = useSubscript ? UNIT_CARBON : UNIT_CARBON_NORMAL
    }
    break

  case AREA:
    unit = UNIT_METRIC_AREA
    break

  default:
    unit = ' '
  }
  return unit
}

function getImperialUnits(name, isBiggerUnit, useSubscript) {
  let unit
  switch (name) {
  case OIL:
  case ENERGY:
  case DISTRICT_COOLING:
  case DISTRICT_HEATING:
  case DISTRICT_SPACE_COOLING:
  case DISTRICT_SPACE_HEATING:
  case UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY:
  case UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL:
  case GAS_FUELS:
    unit = isBiggerUnit ? UNIT_IMPERIAL_ENERGY_MEGA : UNIT_IMPERIAL_ENERGY
    break

  case UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY_INTENSITY:
    unit = UNIT_IMPERIAL_ENERGY_INTENSITY
    break

  case UTILITY_LABELS_OBJECT_TITLE_CASE.WATER:
    unit = UNIT_IMPERIAL_WATER
    break

  case UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE:
    unit = UNIT_EMISSION
    break

  case UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON:
    if (isBiggerUnit) {
      unit = useSubscript ? UNIT_CARBON_TONNE : UNIT_CARBON_TONNE_NORMAL
    } else {
      unit = useSubscript ? UNIT_CARBON : UNIT_CARBON_NORMAL
    }
    break

  case AREA:
    unit = UNIT_IMPERIAL_AREA
    break

  default:
    unit = ' '
  }
  return unit
}

export const CachedAssetIdAndValueState={
  saveAssetIdAndValueToCache: function(value) {
    SessionUserState.saveUserDataToSession(CacheIdAndValueState, JSON.stringify(value))
  },
  getAssetIdAndValueFromCache: function() {
    return SessionUserState.getUserDataFromSession(CacheIdAndValueState)
  },
  clearAssetIdAndValueCache: function() {
    SessionUserState.removeUserDataFromSession(CacheIdAndValueState)
  }
}

// Session storage
export const SessionUserState = (function () {
  const sessionStorageKey = 'userSessionState'
  const storage = sessionStorage

  return {
    saveUserDataToSession: function (key, value) {
      let userData = JSON.parse(storage.getItem(sessionStorageKey))
      if (userData) {
        userData[key] = value
      } else {
        userData = {[key]: value}
      }
      storage.setItem(sessionStorageKey, JSON.stringify(userData))
    },
    getUserDataFromSession: function (key) {
      let userDataFromSession = JSON.parse(storage.getItem(sessionStorageKey))
      if (userDataFromSession) {
        userDataFromSession = userDataFromSession[key]
      }
      return userDataFromSession
    },
    removeUserDataFromSession: function (key) {
      let userDataFromSession = JSON.parse(storage.getItem(sessionStorageKey))
      if (userDataFromSession) {
        delete userDataFromSession[key]
      }
      storage.setItem(sessionStorageKey, JSON.stringify(userDataFromSession))
    },
    clearUserDataFromSession: function () {
      storage.removeItem(sessionStorageKey)
    }
  }
})()

export const countDown = (startCountDown, countDownDelta) => {
  return startCountDown && <Countdown
    date={Date.now() + countDownDelta}
    intervalDelay={0}
    renderer={({minutes, seconds}) => <div className='count-down-wrapper'> {minutes}m {zeroPad(seconds)}s</div>}
  />
}

export function getUtilityUnits(isFund,selectedUtilityObject) {
  const unitSystem = getLocalStorageItem('unitSystem')
  if(isFund === true){
    if(unitSystem === UNITS.IMPERIAL){
      return IMPERIAL_TONNES_UTILITY_UNIT[selectedUtilityObject]
    }else{
      return METRIC_TONNES_UTILITY_UNIT[selectedUtilityObject]
    }
  }else{
    return unitsConverter(selectedUtilityObject, unitSystem)
  }
}

export function getRoundOffValueDivisbleBy5(value) {
  return ((Math.floor(value / 5)) * 5)
}

export const CurrencyUnitToLabel = (currencyUnit) => {
  let currencyLabel
  switch (currencyUnit) {
  case CURRENCY_UNITS.BRITISHPOUND:
    currencyLabel = 'GBP'
    break
  case CURRENCY_UNITS.EUROS:
    currencyLabel = 'EUR'
    break
  case CURRENCY_UNITS.USDOLLAR:
    currencyLabel = 'USD'
    break
  default:
    currencyLabel = 'GBP'
    break
  }
  return currencyLabel
}

export const getDropdownItemTemplate = (option) => {
  return (
    <>
      <div className='dropdown-item-1'>
        <div className='dropdown-item-2 dropdown-item-wrap'>{option}</div>
      </div>
      <div className={'dropdown-panel-item-horiz-line'} />
    </>
  )
}

export function selectedItemTemplate(option, multiSelectedOptions) {
  let selectedItem = option

  if (option) {
    if (multiSelectedOptions.length === 1
        || (multiSelectedOptions.slice(-1)[0] === option
        || multiSelectedOptions.slice(-1)[0].label === option)) {
      return typeof option === 'string' ? option : option.name
    } else if (multiSelectedOptions.length > 1) {
      selectedItem = typeof option === 'string' ? `${option}, ` : `${option.name}, `
    } else {
      return typeof option === 'string' ? option : option.name
    }
  }

  return selectedItem
}

export function isAlpha(text) {
  const alphaPattern = /^[A-Za-z]+$/
  return !!(text && text.match(alphaPattern))
}
export function getQueryValuesForNZCAssets(filterType,defaultValue) {
  const queryValues = new URLSearchParams(new URL(window.location.href).search)

  if (filterType === 'column') {
    return queryValues.get(filterType) === null ? defaultValue : {
      label: getColumnName(queryValues.get(filterType)),
      value: queryValues.get(filterType)
    }
  } else if (filterType === 'sort') {
    return queryValues.get(filterType) === null ? defaultValue : queryValues.get(filterType)
  } else {
    return queryValues.get(filterType) === null ? defaultValue : {value: queryValues.get(filterType)}
  }
}
export const saveCustomDateInCache = (pickerValue) => {
  return CachedUserState.saveUserDataToCache(LOCAL_STORAGE_KEYS.cacheCustomDateValue, pickerValue)
}

export const removeRequiredSessionStorageCache = () => {
  SessionUserState.removeUserDataFromSession('epc')
  SessionUserState.removeUserDataFromSession('strandingYear')
}

export function getUserRole(){
  //Remove Administrator once the roles are changed to Admin
  return (getLocalStorageItem('userRole') === ADMINISTRATOR || getLocalStorageItem('userRole') === ADMIN)
}

export function getUtilityName(key) {
  let utilityName
  switch (key) {
  case 'electricityPercentage':
    utilityName = UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY
    break
  case 'fuelsPercentage':
    utilityName = UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL
    break
  case 'waterPercentage':
    utilityName = UTILITY_LABELS_OBJECT_TITLE_CASE.WATER
    break
  case 'wastePercentage':
    utilityName = UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE
    break
  case 'carbonPercentage':
    utilityName = UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON
    break
  default:
    utilityName = ''
  }
  return utilityName
}

export function energyScore(src) {
  return src.energyStarScore ? src.energyStarScore : '-'
}

export function getDataFromCache(key) {
  //Backwards compatibility
  if(key===CacheKeyAsset) {
    return SessionUserState.getUserDataFromSession(key)
  }
  return CachedUserState.getUserDataFromCache(key)
}

export function getAssetIdAndValueFromCache() {
  return CachedAssetIdAndValueState.getAssetIdAndValueFromCache()
}

export const targetPathwayFormObject = (placeholderString) => {
  return {
    tab1Class: placeholderString,
    tab2Class: placeholderString,
    tab3Class: placeholderString,
    scopeId:null,
    showConfirmModal: false,
    selectedScopeTPData:null,
    tabIndex: null,
    strandingYearTP1:placeholderString,
    strandingYearTP2:placeholderString,
    strandingYearTP3:placeholderString,
    showAddTargetForm: false,
    scopeTP1: null,
    scopeTP2: null,
    scopeTP3: null,
    scopeTPCreate: false,
    tabExpanded: false,
  }
}

// Legend images
export function getLegendImage(key){
  let image = new Image()
  switch(key) {
  case 'science' : image.src = scienceBased; break
  case 'bau': image.src = rectBusiness; break
  case 'tp': image.src = rectTp; break
  case 'enclosed': image.src = enclosedIcon; break
  case 'action-carbon-tp': image.src = dashedGreen; break
  case 'curvyTriangle': image.src = triangleImage; break
  case 'strandingYear' : image.src = orangeEllipse; break
  case 'bauStrandingYear' : image.src = bauStranding; break
  case 'targetPathwayStrandingSvg' : image.src = orangeEllipse; break
  case 'actionBasedStrandingYear' : image.src = circleGreen; break
  case 'selectedAsset' : image.src = selectedAsset; break
  case 'targetPathwaySvg' : image.src = targetPathwaySvg; break
  case 'assetStranding': image.src = yellowEllipse; break
  case 'agreedActionsPathway': image.src = agreedActionsPathway; break
  case 'applicableActionsPathway': image.src = applicableActionsPathway; break
  default: image.src = ''; break
  }
  return image
}

export function getPercentValueSign(percentValue) {
  return percentValue > 100 || percentValue === 0 ? '+ ' : '- '
}

export function getFromSession(key){
  return SessionUserState.getUserDataFromSession(key)
}

export const isBooleanValue = value => 'boolean' === typeof value

export function disableExcelExport(data,dataFrom){
  switch (dataFrom){
  case 'assetDataQuality': return (data.filteredTenantData === null && data.filteredLandlordData ===null? true :
    data.filteredLandlordData?.length === 0 && data.filteredTenantData?.length === 0)
  case 'assetActionPlan' : return (data === undefined ? true : data?.length === 0)
  }
}

export const setAssetData = (assetName) => {
  SessionUserState.saveUserDataToSession(CacheKeyAsset, assetName)
}

export function getCurrentYear() {
  return new Date().getFullYear()
}

export function handleClickOutsideHidePanel (hidePanel, setPanel) {
  function handleClickOutside(event) {
    if (hidePanel.current && !hidePanel.current.contains(event.target) && event.target.className !== 'p-datatable-wrapper') {
      setPanel(null)
    }
  }

  document.addEventListener('mousedown', handleClickOutside)
  return () => {
    document.removeEventListener('mousedown', handleClickOutside)
  }
}

export function fileNameChecker(value) {
  return value.replace(regexValidFileName, '')
}

//Primereact Dialog: Scrolling remains disabled for document even after dialog unmount
// https://github.com/primefaces/primereact/issues/2981
export function unBlockDialogScroll() {
  if(DomHandler.hasClass(document.body, 'p-overflow-hidden')){
    DomHandler.removeClass(document.body, 'p-overflow-hidden')
  }
}
//Obsolete, please use the new function in DateUtils.js
export const formatDateToDdMonYy = () => {
  return formatDateToDdMonYyNew()
}
export const getDivisor = (utility, value) => {
  switch (utility) {
  case UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON:
    if (value >= 1000) {
      return 1000
    }
    break
  case UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY:
  case UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL:
  case UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY_INTENSITY:
  case UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY:
    if (value >= 1000000) {
      return 1000
    }
    break
  default:
    break
  }
  return 1
}

export const createEmptyArray = (numberOfDuplicates, emptyObject) => Array.from({ length: numberOfDuplicates }, () => emptyObject)

export const TransferLinkData = () => {
  // If there is a tab_id in the session storage, then the current
  // tab has already processed any incoming data.
  let tabId= sessionStorage.getItem(LINK_TRANSFER.TAB_ID)
  if(!tabId) {
    // TODO:
    // decide if using the LTS value would be more useful,
    // default to Date.now() if not available.
    sessionStorage.setItem(LINK_TRANSFER.TAB_ID, Date.now().toString())

    const params = new URLSearchParams(window.location.search)
    // LINK_TIME_STAMP is added by the HyperLink code and enables the new tab
    // to identify its incoming data held in localstorage.
    const lts = params.get(LINK_TRANSFER.LINK_TIME_STAMP)
    if(lts) {
      let transferredData =  localStorage.getItem(lts)
      if(transferredData) {
        // This is all that is currently being transferred.
        // Perhaps using BroadcastChannel might be more appropriate
        // for certain scenarios. The LTS value would probably still
        // be useful there.
        setAssetData(transferredData)
      }
      // Remove the data, it has been processed.
      localStorage.removeItem(lts)
    }
  }
}

export function calculateLabelPosition(chartData){
  let indexLabelOverlap = ''
  if(chartData) {
    const firstLabel =  document.querySelectorAll('.labels > div')[0]
    const secondLabel =  document.querySelectorAll('.labels > div')[1]
    const thirdLabel =  document.querySelectorAll('.labels > div')[2]
    const fourthLabel =  document.querySelectorAll('.labels > div')[3]

    const firstBar =  document.querySelectorAll('.bars > span')[0]
    const secondBar =  document.querySelectorAll('.bars > span')[1]
    const thirdBar =  document.querySelectorAll('.bars > span')[2]
    const fourthBar =  document.querySelectorAll('.bars > span')[3]

    const firstLabelWidth = firstLabel?.querySelector('div').offsetWidth + firstLabel?.querySelector('span').offsetWidth + 40 //40 is the total margins
    const secondLabelWidth = secondLabel?.querySelector('div').offsetWidth + secondLabel?.querySelector('span').offsetWidth + 40
    const thirdLabelWidth = thirdLabel?.querySelector('div').offsetWidth + thirdLabel?.querySelector('span').offsetWidth + 40
    const fourthLabelWidth = fourthLabel?.querySelector('div').offsetWidth + fourthLabel?.querySelector('span').offsetWidth + 40

    const firstBarPercentage = firstBar && parseInt(firstBar.dataset.percentage)
    const secondBarPercentage = secondBar && parseInt(secondBar.dataset.percentage)
    const thirdBarPercentage = thirdBar && parseInt(thirdBar.dataset.percentage)

    const firstBarWidth = firstBar?.offsetWidth
    const secondBarWidth = secondBar?.offsetWidth
    const thirdBarWidth = thirdBar?.offsetWidth
    const fourthBarWidth = fourthBar?.offsetWidth

    if(firstBarWidth < firstLabelWidth) {
      indexLabelOverlap = 'child-2'
    }
    if(secondBarWidth < secondLabelWidth) {
      indexLabelOverlap = 'child-3'
    }
    if(thirdBarWidth < thirdLabelWidth) {
      indexLabelOverlap = 'child-4'
    }

    if(firstLabel) {
      firstLabel.style.marginLeft = 0
    }
    if(secondLabel) {
      secondLabel.style.marginLeft = 0
    }
    if(thirdLabel) {
      thirdLabel.style.marginLeft = 0
    }
    if(fourthLabel) {
      fourthLabel.style.marginLeft = 0
    }

    if(firstBar && secondBar && thirdBar && fourthBar) {
      if(firstBarWidth < firstLabelWidth && secondBarWidth > secondLabelWidth && thirdBarWidth > thirdLabelWidth && fourthBarWidth > fourthLabelWidth) {
        thirdLabel.style.marginLeft = `calc(${(firstBarPercentage + secondBarPercentage)}% - ${(firstLabelWidth + secondLabelWidth)}px)`
        fourthLabel.style.marginLeft = `calc(${(thirdBarPercentage)}% - ${(thirdLabelWidth)}px)`
      }
      if(firstBarWidth < firstLabelWidth && secondBarWidth > secondLabelWidth && thirdBarWidth < thirdLabelWidth && fourthBarWidth > fourthLabelWidth) {
        thirdLabel.style.marginLeft = `calc(${firstBarPercentage}% - ${firstLabelWidth}px)`
      }

      if(firstBarWidth > firstLabelWidth && secondBarWidth < secondLabelWidth && thirdBarWidth > thirdLabelWidth && fourthBarWidth > fourthLabelWidth) {
        fourthLabel.style.marginLeft = `calc(${(secondBarPercentage + thirdBarPercentage)}% - ${(secondLabelWidth + thirdLabelWidth)}px)`
      }

      if(firstBarWidth < firstLabelWidth && secondBarWidth < secondLabelWidth && thirdBarWidth > thirdLabelWidth && fourthBarWidth > fourthLabelWidth) {
        fourthLabel.style.marginLeft = `calc(${(firstBarPercentage + secondBarPercentage + thirdBarPercentage)}% - ${(firstLabelWidth + secondLabelWidth + thirdLabelWidth)}px)`
      }

      if(firstBarWidth > firstLabelWidth && secondBarWidth > secondLabelWidth && thirdBarWidth < thirdLabelWidth && fourthBarWidth < fourthLabelWidth) {
        secondLabel.style.flex = '0 0 0'
      }
    }

    if(firstBar && secondBar && thirdBar && !fourthBar) {
      if(firstBarWidth < firstLabelWidth && secondBarWidth > secondLabelWidth && thirdBarWidth > thirdLabelWidth) {
        thirdLabel.style.marginLeft = `calc(${(firstBarPercentage + secondBarPercentage)}% - ${(firstLabelWidth + secondLabelWidth)}px)`
      }
    }
  }
  return indexLabelOverlap
}
export const dateFormatter = (rowData, dateType = '') => {
  let selectedDate

  if (rowData.dataGapDate) {
    return dateType ? rowData.startDate : rowData.dateSummary
  }

  if (rowData.isPartial) {
    selectedDate = dateType === 'START' ? rowData.partialStart : rowData.partialEnd

  } else {
    selectedDate = dateType === 'START' ? rowData.fromDate : rowData.toDate
  }
  return selectedDate
}

export const dateFormatterConsumption = (rowData, dateType = '') => {
  let selectedDate

  if (rowData.isPartial) {
    selectedDate = dateType === 'START' ? rowData.partialStart : rowData.partialEnd

  } else {
    selectedDate = dateType === 'START' ? rowData.fromDate : rowData.toDate
  }
  return selectedDate
}

export const dateRangeFormatter = (rowData) => {
  let fromDate
  let toDate
  const dateFormatType = getLocalStorageItem('unitSystem') === UNITS.IMPERIAL ? MM_DD_YYYY : DD_MM_YYYY

  if (rowData.dataGapDate) {
    return dateFormatter(rowData)
  }

  fromDate = momentDate(dateFormatter(rowData, 'START'),dateFormatType,'en-GB')
  toDate = momentDate(dateFormatter(rowData, 'END'),dateFormatType, 'en-GB')
  if(fromDate && toDate) {
    return `${fromDate} - ${toDate}`
  }
  return '-'
}

export const createMissingDataReqObject = (missingData) => {
  let missingDataReqObject = new Array(missingData.length)

  missingData.forEach((meter, index) => {
    if(meter.partialDateStart) {
      let partialDateStart = meter.partialDateStart
      let partialDateEnd = meter.partialDateEnd
      let partialDateStartArray = partialDateStart.split('/')
      let partialDateEndArray = partialDateEnd.split('/')
      let partialStartDateFormated = new Date(partialDateStartArray[2] + '-' +  partialDateStartArray[1] + '-' + partialDateStartArray[0]).toISOString()
      let partialEndDateFormated = new Date(partialDateEndArray[2] + '-' +  partialDateEndArray[1] + '-' + partialDateEndArray[0]).toISOString()

      missingDataReqObject[index]= {'meterID': meter.meterId, 'dateStart': partialStartDateFormated, 'dateEnd': partialEndDateFormated}
    } else if(meter.isPartial) {
      missingDataReqObject[index]= {'meterID': meter.meterId, 'dateStart': meter.partialStart, 'dateEnd': meter.partialEnd}
    } else {
      const firstLastDate=getFirstLastDateISO(meter.year,(monthsNumber[meter.dataGapDate.toLowerCase()]-1))
      missingDataReqObject[index]= {'meterID': meter.meterId, 'dateStart': firstLastDate.firstDay, 'dateEnd': firstLastDate.lastDay}
    }
  })
  return missingDataReqObject
}

export const createConsumptionRequestData = (data, requestId) => {
  return data.map((item) => {
    return {
      comment: item.comment,
      consumptionType: item.estimate,
      dateEnd: moment(item.toDate).utcOffset(0, true).format(), // handle timezone offset
      dateStart: moment(item.fromDate).utcOffset(0, true).format(),
      meterId: item.meterId.toString(),
      requestId,
      utilityType: item.unitOfMeasurement,
      value: item.consumption,
      origin: item.source,
      rangeId: item.rangeId,
      utility:item.utility
    }
  })
}

export function handleLeastValues(number){
  if(number === 0){
    return 0
  }else{
    return number < 1 ? '< 1' : truncateDecimal(number,1)
  }
}

export function getChartBackgroundColor(stackName, dataSelectionFilterKey, bgColor, selectedUtilityName) {
  let selectedColor
  const ESTIMATED_BELOW_TARGET_STACK = 'estimatedBelowTarget'
  const ESTIMATED_ABOVE_TARGET_STACK = 'estimatedAboveTarget'
  const checkStakeName = stackName === ESTIMATED_ABOVE_TARGET_STACK ||  stackName === ESTIMATED_BELOW_TARGET_STACK
  const defaultBackgroundColorsMap={
    actualBelowTarget: CSS_VARIABLES.forest,
    actualAboveTarget: selectedUtilityName === UtilityTypes.Waste? CSS_VARIABLES.green: (bgColor ?? CSS_VARIABLES.forest),
    estimatedBelowTarget:  CSS_VARIABLES.forest_50,
    estimatedAboveTarget: selectedUtilityName === UtilityTypes.Waste? CSS_VARIABLES.green_50 : CSS_VARIABLES.forest_50,
    scope1:   checkStakeName ? CSS_VARIABLES.forest_50 : CSS_VARIABLES.forest,
    scope2:   checkStakeName ? CSS_VARIABLES.yellow_50 : CSS_VARIABLES. yellow,
    scope3:   checkStakeName ? CSS_VARIABLES.foliage_50 : CSS_VARIABLES.foliage,
    landlord: checkStakeName ? CSS_VARIABLES.forest_50 : CSS_VARIABLES.forest,
    tenant:   checkStakeName ? CSS_VARIABLES.foliage_50 : CSS_VARIABLES.foliage,
  }
  selectedColor=defaultBackgroundColorsMap[stackName]
  if(dataSelectionFilterKey && dataSelectionFilterKey !== ScopeTypes.all){
    selectedColor=defaultBackgroundColorsMap[dataSelectionFilterKey]
  }
  return selectedColor
}

export function getBarSize(consumptionData, dataSelectionFilters) {
  const BAR_SIZE_LOOKUP_TABLE = {
    quarterly: {
      1: { barPercentage: 0.16, categoryPercentage: 1 },
      2: { barPercentage: 0.9, categoryPercentage: 0.25 },
      3: { barPercentage: 0.9, categoryPercentage: 0.35 },
      default: { barPercentage: 0.16, categoryPercentage: 1 }
    },
    monthly: {
      1: { barPercentage: 0.5, categoryPercentage: 1 },
      2: { barPercentage: 0.9, categoryPercentage: 0.6 },
      3: { barPercentage: 0.9, categoryPercentage: 0.75 },
      default: { barPercentage: 0.5, categoryPercentage: 1 }
    }
  }

  const periodView = consumptionData?.length > 6 ? 'monthly' : 'quarterly'
  const lookupKey = dataSelectionFilters?.length || 'default'
  const { barPercentage, categoryPercentage } = BAR_SIZE_LOOKUP_TABLE[periodView][lookupKey]

  return { barPercentage, categoryPercentage }
}

export const refetchUpdatedDataByInvalidatingQueries = (queryClient,arrayOfQueryKeys) =>{
  arrayOfQueryKeys.map(queryKey => queryClient.removeQueries(queryKey))
}

//Obsolete, please use the new function in ExcelUtils.js
export function columnIndexToLetter(index) {
  return columnIndexToLetterNew(index)
}

//Obsolete, please use the new function in ExcelUtils.js
export const excelHelpers = (excelDataArray, excelSheet, error, selectedLanguage) => {
  return excelHelpersNew(excelDataArray, excelSheet, error, selectedLanguage)
}

export function getDivisorForTonneOrMegawatt(selectedUtility) {
  return [CARBON, ELECTRICITY, GAS_FUELS_THERMALS].includes(selectedUtility) ? TONNE_OR_MEGAWATT: 1
}

export function getMergeScopesDatasets(scopeDatasets) {
  const datasets = scopeDatasets
  const newDatasets = []
  const length = datasets.length
  let i
  for (i = 0; i < datasets.length; i++) {
    if (length === 3) {
      // Merge actualBelow and actualAbove
      if (datasets[0].stackName === 'actualBelow' && datasets[1].stackName === 'actualAbove') {
        newDatasets.push({
          ...datasets[0],
          stackName: 'actualBelow_actualAbove',
          data: datasets[0]?.data?.map((value, index) => value + datasets[1].data[index])
        },
        datasets[2]
        )
        break
      } else if (datasets[1].stackName === 'estimatedBelow' && datasets[2].stackName === 'estimatedAbove') {
        // Merge estimatedBelow and estimatedAbove
        newDatasets.push(
          datasets[0],
          {
            ...datasets[1],
            stackName: 'estimatedBelow_estimatedAbove',
            data: datasets[1]?.data?.map((value, index) => value + datasets[2].data[index])
          }
        )
        break
      }
    } else if (length === 2) {
      if (datasets[0].stackName === 'actualBelow' && datasets[1].stackName === 'actualAbove') {
        // Merge actualBelow and actualAbove
        newDatasets.push({
          ...datasets[0],
          stackName: 'actualBelow_actualAbove',
          data: datasets[0]?.data?.map((value, index) => value + datasets[1].data[index])
        })
        break
      } else if (datasets[0].stackName === 'estimatedBelow' && datasets[1].stackName === 'estimatedAbove') {
        // Merge estimatedBelow and estimatedAbove
        newDatasets.push({
          ...datasets[0],
          stackName: 'estimatedBelow_estimatedAbove',
          data: datasets[0]?.data?.map((value, index) => value + datasets[1].data[index])
        })
        break
      }
    }
  }
  return newDatasets?.length > 0 ? newDatasets : scopeDatasets
}

export function mergeScopesDatasets(scopeDatasets) {
  const length = scopeDatasets.length
  if (length === 4) {
    // merge scopes bar stacks having 0 as a value to avoid faint separator line between them
    const [actualBelow, estimatedBelow, actualAbove, estimatedAbove] = scopeDatasets
    actualBelow.data.forEach((value, index) => {
      if (estimatedBelow?.data[index] === 0) {
        actualBelow.data[index] = (actualBelow?.data[index] + actualAbove?.data[index]) // merge actualBelow & actualAbove
        actualAbove.data[index] = 0
      } else if (actualAbove?.data[index] === 0) {
        estimatedBelow.data[index] = (estimatedBelow?.data[index] + estimatedAbove?.data[index]) // merge estimatedBelow & estimatedAbove
        estimatedAbove.data[index] = 0
      }
    })
  }
}

export function getDataSelectionKeyOrder(dataSelectionFilterKey) {
  const scopesSelectionKeyOrder=[ScopeTypes.scope1, ScopeTypes.scope2, ScopeTypes.scope3]
  const splitSelectionKeyOrder=[SplitTypes.landlord, SplitTypes.tenant]

  if(scopesSelectionKeyOrder.includes(dataSelectionFilterKey)){
    return scopesSelectionKeyOrder
  } else if(splitSelectionKeyOrder.includes(dataSelectionFilterKey)){
    return splitSelectionKeyOrder
  }
}

export function sortDataSelectionKeyOrder(dataSelectionFilters) {
  const dataSelectionFilterKey=dataSelectionFilters?.[0]?.key
  if(dataSelectionFilterKey){
    const dataSelectionKeyOrder=getDataSelectionKeyOrder(dataSelectionFilterKey)
    dataSelectionFilters?.sort((a, b) => dataSelectionKeyOrder.indexOf(a.key) - dataSelectionKeyOrder.indexOf(b.key))
  }
}

export function isScopeOrSplitSelected(dataSelectionFilterKey) {
  return [ScopeTypes.scope1, ScopeTypes.scope2, ScopeTypes.scope3, SplitTypes.landlord, SplitTypes.tenant].includes(dataSelectionFilterKey)
}

export function generateHtmlForId(labelText){
  if(labelText){
    const stringWithUnderscores = labelText.replace(/ /g, '_')
    return `${stringWithUnderscores} ${uuid()}`
  }
  return `htmlFor${uuid()}`
}

export function getFeatureFlagStatus(featureState, path, state){
  const featureFlagForPaths = {
    '/:consumptionId/:routeVia/upload/:type' : featureState.sieraplus_nzcUploadTargetPathway
  }
  if(path in featureFlagForPaths && state === undefined){
    return featureFlagForPaths[path]
  }else{
    return true
  }
}


// For utility tile charts in Dashboard pages
export function getYMaxValueForTileChart(chartData) {
  const sumOfChartData = chartData?.filter(data => !(data.stackName === 'previousPeriod' || data.stackName === 'target'))?.map(data => data.value)?.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
  const targetOrPreviousPeriodValue = chartData?.find(data => (data.stackName === 'previousPeriod' || data.stackName === 'target'))?.value
  return Math.max(sumOfChartData, targetOrPreviousPeriodValue)
}

export function getChartDatasets(utilityData, view, utility, wasteData) {
  let chartData = []
  if (!utilityData) {
    return null
  }

  if (UtilityTypes.RecycledWaste !== utility) {
    const {
      actualValue,
      gapFilledValue,
      previousPeriodOrTargetValue,
      hasTarget
    } = getChartDataValuesForNonWaste(utilityData, view)

    if (actualValue > 0) {
      chartData.push({stackName: 'actual', value: actualValue})
    }
    if (gapFilledValue > 0) {
      chartData.push({stackName: 'gapFilled', value: gapFilledValue})
    }
    chartData.push({stackName: hasTarget ? 'target' : 'previousPeriod', value: previousPeriodOrTargetValue})
  } else {
    const {
      currentRecycledTotal,
      currentNonRecycledTotal,
      previousRecycledTotal
    } = getChartDataValuesForWaste(utilityData, view, utility, wasteData)

    chartData.push({stackName: 'actual', value: currentRecycledTotal})
    chartData.push({stackName: 'currentNonRecycledTotal', value: currentNonRecycledTotal})
    chartData.push({stackName: 'previousPeriod', value: previousRecycledTotal})
  }

  return {
    chartDatasets: chartData?.map(data => getDataset(data)),
    yMax: getYMaxValueForTileChart(chartData) ?? 0
  }
}

export function getChartDataValuesForNonWaste(utilityData, view) {
  let actualValue
  let gapFilledValue
  let previousPeriodOrTargetValue
  let hasTarget

  if (view === Views.AssetDashboard) {
    const actualDataPercentage = 100 - (utilityData.gapFilledPercentage ?? 0)
    const totalData = utilityData.amountUptoTarget + utilityData.amountOverTarget
    const actualData = (totalData * actualDataPercentage) / 100
    actualValue = actualData
    gapFilledValue = totalData - actualData
    previousPeriodOrTargetValue = utilityData.targetAmount // if target present, target value or the previous period value
    hasTarget = utilityData.hasTarget
  } else {
    const {
      useTarget,
      actualBelowTarget,
      actualAboveTarget,
      estimatedBelowTarget,
      estimatedAboveTarget,
      previousActual,
      previousEstimated,
      target
    } = utilityData
    hasTarget = useTarget
    actualValue = actualBelowTarget + actualAboveTarget
    gapFilledValue = estimatedBelowTarget + estimatedAboveTarget
    previousPeriodOrTargetValue = useTarget ? target : previousActual + previousEstimated
  }

  return {
    actualValue,
    gapFilledValue,
    previousPeriodOrTargetValue,
    hasTarget
  }
}

export function getChartDataValuesForWaste(utilityData, view, utility, wasteData) {
  let currentRecycledTotal = 0
  let currentNonRecycledTotal = 0
  let previousRecycledTotal = 0
  if (view === Views.AssetDashboard) {
    currentRecycledTotal = utilityData.amountUptoTarget + utilityData.amountOverTarget
    currentNonRecycledTotal = utilityData.amountUptoNonRecycledTarget
    previousRecycledTotal = utilityData.targetAmount
  } else if (utilityData && wasteData) {
    const currentYearTotalWaste = wasteData.value
    currentRecycledTotal = currentYearTotalWaste * utilityData.value
    currentNonRecycledTotal = currentYearTotalWaste - currentRecycledTotal
    previousRecycledTotal = currentRecycledTotal / (utilityData.variance + 1) // currently api does not provide it, so needs to be derived at ui
  }

  return {
    currentRecycledTotal,
    currentNonRecycledTotal,
    previousRecycledTotal
  }
}

export const toastMessageHelper = (severity) => {
  switch(severity) {
  case 'error': return {
    severity: 'error',
    imagePath: <Icon id='warning_solid_circle' colour='red' size='17' />,
    textColor: 'red'
  }
  case 'info': return {
    severity: 'info',
    imagePath: <Icon id='info_solid' size='17' />,
    textColor: 'forest'
  }
  case 'success': return {
    severity: 'success',
    imagePath: <Icon id='check_solid_circle' colour='green' size='17' />,
    textColor: 'green'
  }
  default: return null
  }
}

export const showToastMessage = (severity, toast, toastMessages) => {
  const toastObject = toastMessageHelper(severity)
  if (toastObject) {
    toast.current.show({
      severity: toastObject.severity,
      life: toastMessages.life || 3000,
      summary: toastMessages.summary,
      content: (props) => (
        <div className='col-12 p-0 align-items-left'>
          <div className="flex col-12 p-0 mb-3">
            {toastObject.imagePath}
            <Text className='ml-2' colour={toastObject.textColor} weight='bold' content={toastMessages.title} />
          </div>
          {props.message.summary}
        </div>
      )
    })
  }
}

export function getDQTileBarChartLegends() {
  return [
    {label: I18n('t_actual'), apikey: 'actualPercentage', assetApiKey: 'actual', className: 'actual-data', icon: actualDataIcon},
    {label: I18n('t_estimated'), apikey: 'estimatedPercentage', assetApiKey: 'estimated', className: 'estimated-data', icon: estimatedDataIcon},
    {label: I18n('t_incomplete'), apikey: 'incompletePercentage', assetApiKey: 'missing', className: 'incomplete-data', icon: inCompleteDataIcon},
    {label: I18n('t_not_covered'), apikey: 'notCoveredPercentage', assetApiKey: 'noCoverage', className: 'not-covered-data', icon: notCoveredDataIcon},
  ]
}

export function getUrlParams(search) {
  return  new URLSearchParams(search)
}

export function handleLengthyName(fileName, characterCount) {
  return fileName?.length > characterCount ? fileName?.substring(0,characterCount) + '...' : fileName
}

// Encodes special characters in the URL
export function encodeSieraURL(url) {
  return encodeURIComponent(url)
}

export const getPerformanceUtilitiesList = (selectedLanguage)  => [
  { label: getLocalizedValue(selectedLanguage, 't_carbon'), value: UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON },
  { label: getLocalizedValue(selectedLanguage, 't_electricity'), value: UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY },
  { label: getLocalizedValue(selectedLanguage, 't_gas_fuel_thermal'), value: UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL },
  { label: getLocalizedValue(selectedLanguage, 't_water'), value: UTILITY_LABELS_OBJECT_TITLE_CASE.WATER },
  { label: getLocalizedValue(selectedLanguage, 't_waste_recycling'), value: UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE }
]

export function convertDateToUTCFormat(date){
  return moment(date).utcOffset(0, true).format()
}

//region EnergyUnits/BTUUnits/CO2Units converters
/**
 * Converts value to kilowatt-hours (kWh), megawatt-hours (MWh), or gigawatt-hours (GWh) based on the input unit
 * @param unitValue
 * @returns {{name: string, value: number}}
 */
export function convertToEnergyUnits(unitValue) {
  let value
  let name

  if (unitValue < UNIT_1K) {
    value=unitValue
    name=EnergyUnitNames.KWH
  } else if (unitValue < UNIT_1M) {
    value=unitValue / UNIT_1K
    name=EnergyUnitNames.MWH
  } else {
    value=unitValue / UNIT_1M
    name=EnergyUnitNames.GWH
  }

  return {value, name}
}

/**
 * Converts a value to kiloBTU (kBtu) and megaBTU (MBtu) based on its unit size
 * @param value
 * @returns {{name: string, value: number}}
 */
export function convertToBTUUnits(value) {
  if (value < UNIT_1K) {
    return {value, name: BTUUnitNames.KBTU}
  }
  return {value: (value / UNIT_1K), name: BTUUnitNames.MBTU}
}

/**
 * To convert values to kilograms of CO2e (kgCO2e) and metric tons of CO2e (tCO2e) based on their unit size
 * @param value
 * @returns {{name: string, value: number}}
 */
export function convertToCO2Units(value) {
  if (value < UNIT_1K) {
    return { value, name: CO2UnitNames.KGCO2}
  }
  return {value: (value / UNIT_1K), name: CO2UnitNames.TCO2}
}
//endregion

// Data-Quality V2
export function completenessDaysToPercentages(completenessDays) {

  if(!completenessDays){
    return {
      actualPercentage: 0,
      supplierEstimatePercentage: 0,
      calculatedEstimatePercentage: 0,
      missingPercentage: 0,
      overallPercentage: 0
    }
  }

  const {actualPercentage, supplierEstimatePercentage, calculatedEstimatePercentage, missingPercentage, overallPercentage} = completenessDays
  const multiplier = 100

  return {
    actualPercentage: actualPercentage * multiplier,
    supplierEstimatePercentage: supplierEstimatePercentage * multiplier,
    calculatedEstimatePercentage: calculatedEstimatePercentage * multiplier,
    missingPercentage: missingPercentage * multiplier,
    overallPercentage: overallPercentage * multiplier
  }
}

// Setting min visible BarLength as Bar graph is not visible and does not show tooltip for very small values.
export function setMinBarLength(chartData, dataSelectionFilters) {
  chartData?.plugins?.push(removeBarMinHeight)
  dataSelectionFilters?.forEach(dataSelectionFilter => {
    const scopeData = chartData?.data?.datasets.filter(value => value.dataSelectionFilterKey === dataSelectionFilter.key)
    if (scopeData?.length > 0) {
      scopeData[scopeData?.length - 1].minBarLength = 3
      scopeData[scopeData?.length - 1].yMax = chartData?.options?.scales?.y?.max
    }
  })
}

export function setMinToZeroForVariousNzcLines(value){
  return value < 0 ? 0 : value
}