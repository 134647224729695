import PropTypes from 'prop-types'
import React from 'react'
import './UtilityPerformanceTile.scss'
import {Card} from 'primereact/card'
import exclamationIcon from '../../../../../../resources/images/icon/exclamation-orange.svg'
import I18n from '../../../../../../utils/i18n/I18n'
import FeedbackRating from '../../../../../common/feedback-icon/FeedbackRating'
import {formatNumWODecimal, roundAndFormatNumberWODecimal, formatPercentage} from '../../../../../../services/common/formatter'
import {AdjustValue, getSuffix} from '../../../performance/FundPerformanceUtilitySuffixes'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'


const getUtilityCard = (utility, utilityData, selectedLanguage, reportingPeriodSelected) => {

  let formatedValue
  switch(utility) {
  case 'Waste':
    formatedValue = <p className='font-medium mt-0 utility-performance-tile__value'>
      {roundAndFormatNumberWODecimal(selectedLanguage, AdjustValue(parseFloat(utilityData?.value), utility))}
      <span className='utility-performance-tile__unit'>{getSuffix(utility, parseInt(utilityData?.value))?.replace('m2', 'm²')}</span>
    </p>
    break
  case 'RecycledWaste':
    formatedValue = <p className='font-medium mt-0 utility-performance-tile__value'>
      {formatPercentage(selectedLanguage, utilityData?.value)}
      <span className='utility-performance-tile__unit'>{getSuffix(utility, parseInt(utilityData?.value))?.replace('m2', 'm²')}</span>
    </p>
    break
  default: 
    formatedValue = <p className='font-medium mt-0 utility-performance-tile__value'>
      {formatNumWODecimal(selectedLanguage, AdjustValue(parseInt(utilityData?.value), utility))}
      <span className='utility-performance-tile__unit'>{getSuffix(utility, parseInt(utilityData?.value))?.replace('m2', 'm²')}</span>
    </p>
  }

  if (utility === 'EnergyIntensity' && reportingPeriodSelected && parseInt(reportingPeriodSelected) < 11) {
    return <span className='fund-dashboard-card-value-no-data'>{I18n('t_only_for_annual_data')}</span>
  } else {
    return (
      <Card>
        {formatedValue}
        <FeedbackRating variance={utilityData?.variance} target={utilityData?.useTarget} utility={utility} displayIcon={typeof utilityData?.variance === 'number'} />
        {
          utility !== 'RecycledWaste' && utility !== 'Waste' ?
            <div className='mt-3 flex align-center utility-performance-tile__gap-filled-data'>
              <img className='mr-2' src={exclamationIcon} alt={'exclamation icon'} />
              <span>{formatPercentage(selectedLanguage, utilityData?.filledData) + '% '} {I18n('t_gap_filled_data')}</span>
            </div>
            :
            <div className='mt-3 flex align-center utility-performance-tile__gap-filled-data'>
              <img src={exclamationIcon} className='mr-2 perf-icon' alt='exclamation icon' />
              <span className='gap-filled-data'>{I18n('t_gap_filled_data_NA')}</span>
            </div>
        }
      </Card>
    )
  }
  
} 


const UtilityPerformanceTile = ({ utility, utilityData, reportingPeriodSelected }) => {
  const {loginState: {userInfo}} = useLoginAuthContext()
  const language = userInfo.languagePreference

  return (
    <div className='utility-performance-tile'>
      {
        !utilityData?.value && !utilityData?.variance ? 
          <div className='utility-performance-tile__no-data'><p>No data</p></div> :
          getUtilityCard(utility, utilityData, language, reportingPeriodSelected)
      }
    </div>
  )
}
  
UtilityPerformanceTile.propTypes = {
  utility: PropTypes.string,
  utilityData: PropTypes.any,
  isLoadingData: PropTypes.bool,
  reportingPeriodSelected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

}

UtilityPerformanceTile.displayName = 'UtilityPerformanceTile'

export default UtilityPerformanceTile