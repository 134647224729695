import {
  applyStylesForSummaryTable,
  convertToExcelExportFormat, EXPORT_TYPES,
  formatDateToDDMMYY,
  getDateRangeAndTimePeriodInfo,
  getEmptyRows, getLocalizedColumnNamesWithUnits,
  getRequiredUnit,
  getTableHeadersAfterLocalizationArray,
  getTitleObject,
  getValidData, UNIT_TYPE_FOR_EXPORT
} from '../../ExportHelper'
import I18n from '../../../i18n/I18n'
import { getFundDataQualityUtilitiesList, UTILITY_LABELS_OBJECT_TITLE_CASE,
  UTILITY_OTHER_NAME_MAP} from '../../../helpers/Constants'
import { excelHelpers, formatDateToDdMonYy, getLocalizedValue } from '../../../helpers/Helper'
import XlsxPopulate from 'xlsx-populate'
import FileSaver from 'file-saver'

export const fundDataQualityExportHandler = async (props,selectedLanguage,dqExportData, intl,fundHomeExportSelections) => {

  // execution starts here ...
  const data = dqExportData?.data
  let selectedUtility = fundHomeExportSelections[EXPORT_TYPES.FUND.FUND_DATA_QUALITY].UTILITY?.value

  await fundDataQualityAllUtilitiesExport(props, selectedLanguage, intl, data, selectedUtility)
}

function appendSheetHeaderInformationToExcelDataArray(utility, selectedLanguage, props) {
  let headerInfo = {
    fundName: props?.selectedFund?.fundname,
    utilityName: getFundDataQualityUtilitiesList(utility, selectedLanguage),
    dateRange: getDateRangeAndTimePeriodInfo(props?.timePeriod,props?.pickerValue,selectedLanguage).dateRange
  }

  return getSheetHeaderInformationForFundDataQuality(headerInfo, selectedLanguage)
}

const createFundDataQualitySheet = (selectedUtility, selectedLanguage, props, data, intl) => {
  function populateExcelExportDataForAllUtilities(utility,requiredUtilityInfo) {
    let excelDataArray = []
    excelDataArray.push(...appendSheetHeaderInformationToExcelDataArray(utility, selectedLanguage, props))

    const listOfSummaryTables = utility === UTILITY_LABELS_OBJECT_TITLE_CASE.SUMMARY ? getListOfSummaryTableNames().summary : getListOfSummaryTableNames().otherUtilities
    let summaryTableDetails = {}
    listOfSummaryTables.forEach(table => {
      switch (table) {
      case SUMMARY_TABLE_NAMES.fundSummary:
      case SUMMARY_TABLE_NAMES.landlordSummary:
      case SUMMARY_TABLE_NAMES.tenantSummary:
        summaryTableDetails = getSummaryTableHeaderNames(getSummaryTableTitle(table), selectedLanguage, true)
        excelDataArray.push(...summaryTableDetails.summaryTableHeaderNames)
        excelDataArray.push(...getSummaryTableRowData(requiredUtilityInfo[table], selectedLanguage,true))
        break
      default :
        summaryTableDetails = getSummaryTableHeaderNames(getSummaryTableTitle(table), selectedLanguage, false)
        excelDataArray.push(...summaryTableDetails.summaryTableHeaderNames)
        excelDataArray.push(...getSummaryTableRowData(requiredUtilityInfo[table], selectedLanguage,false))
        break
      }
    })
    let {assetTableHeaderNames, assetTableColLength} = getAssetTableHeaderNames('t_all_assets', 'all', selectedLanguage,intl)
    excelDataArray.push(...assetTableHeaderNames)
    excelDataArray.push(...getAssetTableRowData(requiredUtilityInfo.assets, selectedLanguage))

    return {excelDataArray,assetTableColLength,assetTableRowLength: requiredUtilityInfo.assets.length}
  }
  return XlsxPopulate.fromBlankAsync()
    .then(workbook => {
      getSheetNamesForFundDataQualityExcelExport(selectedUtility).forEach((sheet, index) => {
        let utilityName = sheet.value === UTILITY_OTHER_NAME_MAP.OtherFuelsAndThermals ? sheet.value.charAt(0).toLowerCase() + sheet.value.slice(1) : sheet.value.toLowerCase() // need some modification
        const excelSheet = index === 0 ? workbook.sheet(0).name(sheet.value) : workbook.addSheet(sheet.value)
        const { excelDataArray } = populateExcelExportDataForAllUtilities(sheet.value,data[utilityName])
        const error = 'No Error'
        excelHelpers(excelDataArray, excelSheet, error)
      })
      workbook.activeSheet(selectedUtility === UTILITY_OTHER_NAME_MAP.All ? UTILITY_LABELS_OBJECT_TITLE_CASE.SUMMARY : selectedUtility)
      return workbook.outputAsync(workbook)
    })
}

async function fundDataQualityAllUtilitiesExport(props, selectedLanguage, intl, data, selectedUtility){
  const blob = await createFundDataQualitySheet(selectedUtility, selectedLanguage, props, data, intl)
  FileSaver.saveAs(blob, getLocalizedValue(selectedLanguage, 't_fund_data_quality_excel')+'.xlsx')
}

export function getListOfSummaryTableNames() {
  return {
    summary:[SUMMARY_TABLE_NAMES.fundSummary, SUMMARY_TABLE_NAMES.landlordSummary, SUMMARY_TABLE_NAMES.tenantSummary],
    otherUtilities:[SUMMARY_TABLE_NAMES.total, SUMMARY_TABLE_NAMES.landlord, SUMMARY_TABLE_NAMES.tenant]
  }
}
export const SUMMARY_TABLE_NAMES = Object.freeze({
  fundSummary: 'fundSummary',
  landlordSummary: 'landlordSummary',
  tenantSummary: 'tenantSummary',
  total : 'total',
  tenant : 'tenant',
  landlord : 'landlord'

})
export function getFontStyleInfoForTitles(sheet) {
  switch (sheet) {
  case UTILITY_LABELS_OBJECT_TITLE_CASE.SUMMARY.toLowerCase() : return [{cell: 'A1', size: 16}, {cell: 'A9', size: 12}, {cell: 'A18', size: 12},
    {cell: 'A27', size: 12}, {cell: 'A36', size: 12}]
  default : return [{cell: 'A1', size: 16}, {cell: 'A9', size: 12}, {cell: 'A15', size: 12},
    {cell: 'A21', size: 12}, {cell: 'A27', size: 12}]
  }
}

export function getStylesForSummaryAndAssetTable(sheet, exportingData,exportedExcelData) {
  switch (sheet) {
  case UTILITY_LABELS_OBJECT_TITLE_CASE.SUMMARY.toLowerCase() :
    exportingData = applyStylesForSummaryTable(10, 4, 6, exportingData, true)
    exportingData = applyStylesForSummaryTable(19, 4, 6, exportingData, true)
    exportingData = applyStylesForSummaryTable(28, 4, 6, exportingData, true)
    exportingData = applyStylesForSummaryTable(37, exportedExcelData.assetTableRowLength, exportedExcelData.assetTableColLength, exportingData)

    return exportingData
  default :
    exportingData = applyStylesForSummaryTable(10, 1, 5, exportingData)
    exportingData = applyStylesForSummaryTable(16, 1, 5, exportingData)
    exportingData = applyStylesForSummaryTable(22, 1, 5, exportingData)
    exportingData = applyStylesForSummaryTable(28, exportedExcelData.assetTableRowLength, exportedExcelData.assetTableColLength, exportingData)

    return exportingData
  }
}
export function getSummaryTableTitle(table){
  switch (table){
  case SUMMARY_TABLE_NAMES.fundSummary : return 't_fund_level_summary'
  case SUMMARY_TABLE_NAMES.landlordSummary : return 't_landlord_summary'
  case SUMMARY_TABLE_NAMES.tenantSummary : return 't_tenant_summary'
  case SUMMARY_TABLE_NAMES.total : return 't_total'
  case SUMMARY_TABLE_NAMES.landlord : return 't_landlord'
  case SUMMARY_TABLE_NAMES.tenant : return 't_tenant'
  }
}
export const getSheetNamesForFundDataQualityExcelExport = (selectedUtility) => {
  switch(selectedUtility){
  case UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY:
    return [{label: I18n('t_electricity'), value: UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY}]
  case UTILITY_LABELS_OBJECT_TITLE_CASE.GAS:
    return [{label: I18n('t_gas'), value: UTILITY_LABELS_OBJECT_TITLE_CASE.GAS}]
  case UTILITY_LABELS_OBJECT_TITLE_CASE.OTHER_FUELS_THERMALS:
    return [{label: I18n('t_otherfuelsandthermals'), value: UTILITY_LABELS_OBJECT_TITLE_CASE.OTHER_FUELS_THERMALS}]
  case UTILITY_LABELS_OBJECT_TITLE_CASE.WATER:
    return [{label: I18n('t_water'), value: UTILITY_LABELS_OBJECT_TITLE_CASE.WATER}]
  default:
    return [  {label: I18n('t_title_summary'), value: UTILITY_LABELS_OBJECT_TITLE_CASE.SUMMARY},
      {label: I18n('t_electricity'), value: UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY},{label: I18n('t_gas'), value: UTILITY_LABELS_OBJECT_TITLE_CASE.GAS},{label: I18n('t_otherfuelsandthermals'), value: UTILITY_LABELS_OBJECT_TITLE_CASE.OTHER_FUELS_THERMALS},{label: I18n('t_water'), value: UTILITY_LABELS_OBJECT_TITLE_CASE.WATER},]
  }
}
export const getSheetHeaderInformationForFundDataQuality = (headerInfo,selectedLanguage) => {
  return [
    getTitleObject('t_fund_data_quality_report',selectedLanguage),
    {A: `${getLocalizedValue(selectedLanguage, 't_fund_name')} : ${headerInfo.fundName}`},{A: `${getLocalizedValue(selectedLanguage, 't_type')} : ${headerInfo.utilityName}`},{A: `${getLocalizedValue(selectedLanguage, 't_date_range')} : ${headerInfo.dateRange}`},
    {A: `${getLocalizedValue(selectedLanguage, 't_date_of_export')}: ${formatDateToDdMonYy()}`},
    ...getEmptyRows(3)
  ]
}
export const getSummaryTableHeaderNames = (tableTitle, selectedLanguage,isFundSummaryTable) => {
  let tableHeadersToBeLocalized = [
    't_floor_area_not_covered_percentage','t_actual_percentage', 't_supplier_estimated_percentage', 't_incomplete_percentage','t_total_missing_percentage'
  ]
  let tableHeadersWithLocalization = getTableHeadersAfterLocalizationArray(tableHeadersToBeLocalized, selectedLanguage)
  isFundSummaryTable && tableHeadersWithLocalization.unshift('')
  return {
    summaryTableHeaderNames: [getTitleObject(tableTitle, selectedLanguage), convertToExcelExportFormat(tableHeadersWithLocalization)],
    summaryTableColLength: tableHeadersWithLocalization.length
  }
}
export const getAssetTableHeaderNames = (tableTitle, type, selectedLanguage, intl) => {
  function getFloorAreaTableHeader() {
    switch (type) {
    case UTILITY_OTHER_NAME_MAP.All.toLowerCase() :
      return 't_dq_floor_area_gia'
    case SUMMARY_TABLE_NAMES.landlord :
      return 't_dq_floor_area_landlord'
    case SUMMARY_TABLE_NAMES.tenant :
      return 't_dq_floor_area_tenant'
    default :
      return 't_dq_floor_area'
    }
  }

  let floorAreaType = getFloorAreaTableHeader()
  let tableHeadersToBeLocalized = [
    {key: 't_asset_ref'}, {key: 't_asset_name'}, { key: 't_country'},{key: 't_sector'}, {key: 't_managing_agent'}, { key: 't_purchase_date'},{key: 't_sale_date'}, {key: 't_missing_fund_data_weighted'},
    {key: floorAreaType, unit: getRequiredUnit(UNIT_TYPE_FOR_EXPORT.AREA, null, null)},
    {key: 't_floor_area_for_no_data', unit: getRequiredUnit(UNIT_TYPE_FOR_EXPORT.AREA, null, null)},{key: 't_floor_area_not_covered_percentage'},
    {key: 't_actual_percentage'},{key: 't_supplier_estimated_percentage'}, { key: 't_incomplete_percentage'},{key: 't_total_missing_percentage'}
  ]

  let tableHeadersWithLocalization = getLocalizedColumnNamesWithUnits(tableHeadersToBeLocalized, intl)

  return {
    assetTableHeaderNames: [getTitleObject(tableTitle, selectedLanguage), convertToExcelExportFormat(tableHeadersWithLocalization)],
    assetTableColLength: tableHeadersWithLocalization.length
  }
}
export const getSummaryTableRowData = (summaryData, selectedLanguage, isFundSummaryTable) => {
  let summaryTableRowDataArray = []
  summaryData.forEach(summary => {
    summaryTableRowDataArray.push(
      convertToExcelExportFormat([
        ...(isFundSummaryTable ? [getValidData(summary.utility, selectedLanguage)] : []),
        getValidData(summary.notCoveredPercentage, selectedLanguage,  true),getValidData(summary.actualPercentage, selectedLanguage,  true),getValidData(summary.estimatedPercentage, selectedLanguage,  true),getValidData(summary.incompletePercentage, selectedLanguage,  true),getValidData(summary.totalMissingPercentage, selectedLanguage,  true)])
    )
  })
  return [...summaryTableRowDataArray, ...getEmptyRows(3)]
}
export const getAssetTableRowData = (assetData, selectedLanguage) => {
  let requiredAssetTableRowDataArray = []
  assetData.map(asset => requiredAssetTableRowDataArray.push(convertToExcelExportFormat([
    asset.assetRef,
    getValidData(asset.assetName, selectedLanguage),getValidData(asset.assetCountry, selectedLanguage),getValidData(asset.assetSector, selectedLanguage),
    getValidData(asset.assetAgent, selectedLanguage),formatDateToDDMMYY(asset.assetPurchaseDate, selectedLanguage),formatDateToDDMMYY(asset.assetSaleDate, selectedLanguage),
    getValidData(asset.portionOfMissingData, selectedLanguage, true),getValidData(asset.floorArea, selectedLanguage),getValidData(asset.floorAreaNoData, selectedLanguage),getValidData(asset.notCovered, selectedLanguage, true),
    getValidData(asset.actual, selectedLanguage, true),getValidData(asset.estimated, selectedLanguage, true),getValidData(asset.inComplete, selectedLanguage, true),getValidData(asset.totalMissing, selectedLanguage)
  ]
  )))
  return requiredAssetTableRowDataArray
}