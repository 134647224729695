import React from 'react'
import {Skeleton} from 'primereact/skeleton'
import  '../../../pages/admin/users/users-home/UsersHome.scss'

const UserDetailsSkeleton = () => {

  return (
    <div className="custom-skeleton p-rel p-d-flex p-flex-column">
      <div className={'user-create-edit-section'}>
        <div className={'userDetails-form'}>
          <Skeleton  width="11rem" height="10.94rem" className="mb-5"/>
          <Skeleton  width="33.677rem" height="3.75rem" className="mb-5"/>
          <Skeleton  width="33.677rem" height="3.75rem" className="mb-5"/>
          <Skeleton  width="33.677rem" height="3.75rem" className="mb-6"/>
          <Skeleton  width="77.677rem" height="0.4rem" className="mb-6"/>
          <Skeleton  width="7.45rem" height="2.625rem" className="mb-4"/>
        </div>
        <div className={'user-actions-block'}>
          <Skeleton  width="36.19rem" height="15.68rem" className="mb-4"/>
        </div>
      </div>
    </div>
  )
}

export default UserDetailsSkeleton
