import { formatDateToDDMMYY } from 'utils/helpers/DateUtils'
import { applyMultipleFundReportPAITableHeaderStyles, formatData, getEmptyRows, mergeMultipleCellRanges, setRowHeight, textAlignCenter } from 'utils/helpers/ExcelUtils'
import { generatePrincipleMetricsRow, generateQuarterlySummaryRow, getPrincipleMetricsTableHeader, getQuarterlySummaryTableHeader, getfundReportSheetHeaderInformation } from './fundReportPAIExcelExport'

export const createFundReportPAIGhgSheet = (
  fundName,
  reportingYear,
  ghgBreakdown,
  assets,
  localize,
  selectedLanguage,
  excelSheet
) => {
  let excelDataArray = []
  excelDataArray.push(...getfundReportSheetHeaderInformation('t_ghg_impact_report', fundName, reportingYear, localize))
  excelDataArray.push(...getEmptyRows(2))
  excelDataArray = getGhgQuarterlySummaryTable(excelDataArray, ghgBreakdown.quarterlySummary, reportingYear, localize)
  excelDataArray.push(...getEmptyRows(1))
  excelDataArray = getGhgPrincipleMetricsTable(excelDataArray, ghgBreakdown.principleMetrics, reportingYear, localize)
  excelDataArray.push(...getEmptyRows(2))
  excelDataArray = getAssetInformationTable(excelDataArray, assets, reportingYear, localize, selectedLanguage, excelSheet)
  return excelDataArray
}

const getGhgQuarterlySummaryTable = (currentExcelData, quarterlySummaryData, reportingYear, localize) => {
  const {q1, q2, q3, q4} = quarterlySummaryData.ghgEmissions
  const data = [...currentExcelData]
  data.push(...getQuarterlySummaryTableHeader(localize, reportingYear))
  
  data.push(...[generateQuarterlySummaryRow('t_total_scope1_emissions', q1.scope1, q2.scope1, q3.scope1, q4.scope1, localize),
    generateQuarterlySummaryRow('t_total_scope2_emissions', q1.scope2, q2.scope2, q3.scope2, q4.scope2, localize),
    generateQuarterlySummaryRow('t_total_scope3_emissions', q1.scope3, q2.scope3, q3.scope3, q4.scope3, localize),
    generateQuarterlySummaryRow('t_total_ghg_emissions', q1.total, q2.total, q3.total, q4.total, localize)])
  
  return data
}

const getGhgPrincipleMetricsTable = (currentExcelData, principleMetricsData, reportingYear, localize) => {
  const {currentYear, previousYear} = principleMetricsData.ghgEmissions
  const data = [...currentExcelData]
  data.push(...getPrincipleMetricsTableHeader(localize, reportingYear))
  
  data.push(...[ generatePrincipleMetricsRow('t_total_scope1_emissions', currentYear.scope1, previousYear.scope1, localize),
    generatePrincipleMetricsRow('t_total_scope2_emissions', currentYear.scope2, previousYear.scope2, localize),
    generatePrincipleMetricsRow('t_total_scope3_emissions', currentYear.scope3, previousYear.scope3, localize),
    generatePrincipleMetricsRow('t_total_ghg_emissions', currentYear.total, previousYear.total, localize)])
  
  return data
}

const getAssetInformationTable = (currentExcelData, assetsArray, reportingYear, localize, selectedLanguage, excelSheet) => {
  const data = [...currentExcelData]
  data.push(...getAssetInformationTableHeader(localize, reportingYear))
  assetsArray.forEach(asset => {
    const { 
      assetReference, 
      assetName, 
      location, 
      constructionYear, 
      purchaseDate,
      saleDate, 
      GreenhouseGasEmissions
    } = asset
    data.push(...[{
      A: assetReference,
      B: assetName,
      C: location,
      D: formatDateToDDMMYY(purchaseDate, selectedLanguage),
      E: formatDateToDDMMYY(saleDate, selectedLanguage),
      F: constructionYear,
      G: formatData(GreenhouseGasEmissions.actualGhgEmissionsPerScope.scope1),
      H: formatData(GreenhouseGasEmissions.actualGhgEmissionsPerScope.scope2),
      I: formatData(GreenhouseGasEmissions.actualGhgEmissionsPerScope.scope3),
      J: formatData(GreenhouseGasEmissions.actualGhgEmissionsPerScope.total),
      K: formatData(GreenhouseGasEmissions.totalGhgEmissionsPerScope.scope1),
      L: formatData(GreenhouseGasEmissions.totalGhgEmissionsPerScope.scope2),
      M: formatData(GreenhouseGasEmissions.totalGhgEmissionsPerScope.scope3),
      N: formatData(GreenhouseGasEmissions.totalGhgEmissionsPerScope.total),
    }])
    excelSheet.row(data.length).height(31)
  })

  return data
}

const getAssetInformationTableHeader = (localize, reportingYear) => {
  return [{
    A: `${localize('t_asset_information')} (${localize('t_actual_data_only')})`,
  },
  {
    A: localize('t_asset_reference'),
    B: localize('t_asset_name_tc'),
    C: localize('t_country'),
    D: localize('t_purchase_date'),
    E: localize('t_sale_date'),
    F: localize('t_construction_year'),
    G: `${localize('t_ghg_emissions')} (${localize('t_actual_only')})`,
    H: '',
    I: '',
    J: '',
    K: `${localize('t_ghg_emissions')} (${localize('t_including_estimates')})`,
    L: '',
    M: '',
    N: '',
  },
  {
    A: '',
    B: '',
    C: '',
    D: '',
    E: '',
    F: '',
    G: `${reportingYear}, ${localize('t_scope1')}`,
    H: `${reportingYear}, ${localize('t_scope2')}`,
    I: `${reportingYear}, ${localize('t_scope3')}`,
    J: `${reportingYear}, ${localize('t_total')}`,
    K: `${reportingYear}, ${localize('t_scope1')}`,
    L: `${reportingYear}, ${localize('t_scope2')}`,
    M: `${reportingYear}, ${localize('t_scope3')}`,
    N: `${reportingYear}, ${localize('t_total')}`,
  }]
}

export const applyStylingToGhgSheet = (excelSheet) => {
  excelSheet.usedRange().forEach(cell => {
    if (cell.address() !== 'A1') {
      cell.style({fontSize: 12,  horizontalAlignment: 'left', verticalAlignment: 'center'})
    }
    if(cell.address() === 'A15' || cell.address() === 'A8') {
      cell.style({topBorder: false})
    }
  })
  applyMultipleFundReportPAITableHeaderStyles(['A23:N23', 'G24:N24', 'B8:E8', 'B15:C15'], excelSheet)
  mergeMultipleCellRanges(excelSheet, ['A23:A24', 'B23:B24', 'C23:C24', 'D23:D24', 'E23:E24', 'F23:F24', 'G23:J23', 'K23:N23'])
  textAlignCenter(excelSheet, 'G23:N23')
  setRowHeight(excelSheet, 23, 30)
}