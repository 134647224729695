import PropTypes from 'prop-types'

function PaginatorCurrentPageReport({options}) {
  return (
    <div className="current-page-report">
      {options.first} - {options.last} of {options.totalRecords}
    </div>
  )
}

PaginatorCurrentPageReport.propTypes = {
  options: PropTypes.object
}

PaginatorCurrentPageReport.displayName = 'PaginatorCurrentPageReport'

export default PaginatorCurrentPageReport