import {useQueries, useQueryClient} from 'react-query'
import {GetAssetHeaderData} from '../../../../../services/assets/portfolio-service'
import {manualFetch, noRefetchOnWindowFocus} from '../../../../../services/common/useQuery-config'
import {GetDQResponsibility} from '../../../../../services/assets/dataquality-service'
import {getConsumptionYTDTotals} from '../../../../../services/assets/performance-service'
import {actionData} from '../../../../../services/assets/actionplan-service'
import {GetPassportData} from '../../../../../services/assets/passport-service'
import {getAssetKeyFacts} from '../../../../../services/common/keyFacts-service'
import { useEffect } from 'react'

const useAssetDashboardData = (assetId, variablesDQResponsibility, variablesDPData, unitSystemDependency, sieraplus_assetDataQualityLayoutV2) => {
  const queryClient = useQueryClient()
  const [
    assetHeaderInfo,
    dataqualityData,
    performanceData,
    {data: adbActions, isLoading: isLoadingActionData},

    assetData,
    assetKeyFactsData
  ] = useQueries([
    {
      queryKey: ['assetHeaderData', assetId],
      queryFn: GetAssetHeaderData,
      ...manualFetch
    },
    {
      queryKey: [...variablesDQResponsibility, unitSystemDependency],
      queryFn: GetDQResponsibility,
      enabled: !sieraplus_assetDataQualityLayoutV2,
      ...noRefetchOnWindowFocus
    },
    {
      queryKey: [...variablesDPData, unitSystemDependency],
      queryFn: getConsumptionYTDTotals,
      ...noRefetchOnWindowFocus
    },
    {
      queryKey: ['adbActions', assetId, unitSystemDependency],
      queryFn: actionData,
      ...noRefetchOnWindowFocus
    },
    {
      queryKey: ['passportData', assetId, unitSystemDependency],
      queryFn: GetPassportData,
      ...noRefetchOnWindowFocus
    },
    {
      queryKey: ['keyfactsData', assetId, unitSystemDependency],
      queryFn: getAssetKeyFacts,
      ...noRefetchOnWindowFocus
    },
  ])
  
  useEffect(() => {
    refetchKeyFacts()
  }, [unitSystemDependency])

  const refetchKeyFacts = () => {
    queryClient.invalidateQueries(['keyfactsData', assetId, unitSystemDependency])
  }

  return {assetHeaderInfo, dataqualityData, performanceData, adbActions, isLoadingActionData, assetData, assetKeyFactsData}
}

export default useAssetDashboardData