import {BTUUnitNames, CARBON, CO2UnitNames, EnergyUnitNames, WASTE} from '../../utils/helpers/Constants'

export const VARIANCE_MIN_VALUE_THRESHOLD_0_09 = 0.09
export const MIN_VALUE_INDICATOR = '< 0.1'

export const  MASS_MIN_THRESHOLD_10 = 10
export const MASS_MIN_THRESHOLD_0_05 = 0.05
export const MASS_MIN_INDICATOR = '< 0.1'
export const ENERGY_AREA_VOLUME_MIN_THRESHOLD_1 = 1
export const ENERGY_AREA_VOLUME_MIN_INDICATOR = '< 1'

export const formatNumWODecimal = (selectedLanguage, value) => {
  return value.toLocaleString(selectedLanguage, {maximumFractionDigits: 0})
}

export const formatNumWOneDecimal = (selectedLanguage, value) => {
  return value.toLocaleString(selectedLanguage, {maximumFractionDigits: 1})
}

export const formatNumWTwoDecimal = (selectedLanguage, value) => {
  return value.toLocaleString(selectedLanguage, {maximumFractionDigits: 2})
}

export const trucateNumWTwoDecimal = (value) => {
  let newValue = value*100
  newValue = Math.trunc(newValue)
  return newValue/100
}

export const trucateNumWOneDecimal = (value) => {
  let newValue = value*10
  newValue = Math.trunc(newValue)
  return newValue/10
}

export const truncateAndFormatNumWOneDecimal = (language,value) => {
  let newValue = value*10
  newValue = Math.trunc(newValue)
  return (newValue/10).toLocaleString(language)
}

export const trucateNumWODecimal = (value) => {
  return Math.trunc(value)
}

export const formatDate = (selectedLanguage, value) => {
  return value.toLocaleString(selectedLanguage, {dateStyle: 'medium'})
}

export const formatCurrency = (selectedLanguage, value) => {
  return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
}

export const formatPercentage = (selectedLanguage, value, precision) => {
  if(!isNaN(value)){
    value = value * 100
    let multiplier = Math.pow(10, precision || 0)
    let truncatedValue = Math.round(value * multiplier) / multiplier
    return roundAndFormattedVarianceWithOneDP(Math.abs(truncatedValue)).toLocaleString(selectedLanguage)
  }
}

export function roundToTwo(number) {
  return +Number.parseFloat(number).toFixed(2)
}

export function roundAndFormatNumberWODecimal(selectedLanguage, value) {
  if(!isNaN(value)){
    let roundedValue = Math.round(value)
    return roundedValue.toLocaleString(selectedLanguage, {maximumFractionDigits: 0})
  }
}

export const roundBaseTen = (number) => {
  let formattedNumber

  if(number > 10 && number < 100) {
    formattedNumber = Math.round(number / 10) * 10
  } else if (number > 100 && number < 1000) {
    formattedNumber  = Math.round(number / 100) * 100
  } else if (number > 1000) {
    formattedNumber  = Math.round(number / 1000) * 1000
  } else {
    formattedNumber = number
  }

  return formattedNumber
}

export function roundVarianceWithOneDP(variance) {
  // Rule: Target % should be rounded up or down to the nearest % except for values less than 10%, when 1.d.p. is used.
  // Base: 10

  let varianceWithOneDP = 0
  if (isNaN(variance ?? undefined)) {
    return varianceWithOneDP
  }

  if (variance < 10) {
    varianceWithOneDP = Number(variance.toFixed(1))
  } else {
    varianceWithOneDP = Math.round(variance)
  }
  return varianceWithOneDP
}

export function roundUtilityUnitValue(variance, utilityType) {
  // Rule: Round to nearest whole unit. For values less than 1 show “< 1”.

  let varianceWithOneDP = 0
  if (isNaN(variance ?? undefined)) {
    return varianceWithOneDP
  }
  if (variance < 10 && [WASTE, CARBON].includes(utilityType)) {
    // Larger units like tonnes, tCO₂e and US Tons, shown to 1 decimal place for values below 10.
    varianceWithOneDP = Number(variance.toFixed(1))
  } else {
    varianceWithOneDP = Math.round(variance)
  }
  return varianceWithOneDP
}

export function roundAndFormattedVarianceWithOneDP(variance) {
  if (variance !== 0 && variance < VARIANCE_MIN_VALUE_THRESHOLD_0_09) {
    return MIN_VALUE_INDICATOR
  }
  const roundedVariance = roundVarianceWithOneDP(variance)
  return Math.abs(roundedVariance)
}


export function roundUnitValue(unitValue, utilityType, unitName) {
  return [WASTE, CARBON].includes(utilityType) ? roundMassUnit(unitValue, utilityType, unitName) : roundEnergyAreaVolumeUnit(unitValue, utilityType, unitName)
}

export function roundMassUnit(unitValue, utilityType, unitName) {

  // Rule: tonnes, tCO₂e and US Tons are shown to 1 decimal place for values below 10.
  // Example: WASTE, CARBON

  let unitValueFormatted = unitValue
  if (isNaN(unitValue ?? undefined) || ![WASTE, CARBON].includes(utilityType)) {
    return unitValueFormatted
  }

  if(unitValue !==0){
    if (Math.abs(unitValue) < MASS_MIN_THRESHOLD_0_05) {
      unitValueFormatted = MASS_MIN_INDICATOR
    } else if (CO2UnitNames.KGCO2 !== unitName && Math.abs(unitValue) < MASS_MIN_THRESHOLD_10) {
      unitValueFormatted = Number.parseFloat(unitValue).toFixed(1)
    } else {
      unitValueFormatted = Math.round(unitValue)
    }
  }

  return unitValueFormatted
}

export function getMinOrRoundedValue(unitValue) {
  if (unitValue !== 0 && Math.abs(unitValue) < ENERGY_AREA_VOLUME_MIN_THRESHOLD_1) {
    return ENERGY_AREA_VOLUME_MIN_INDICATOR
  }
  return Math.round(unitValue)
}

export function roundEnergyAreaVolumeUnit(unitValue, utilityType, unitName) {
  // Rule: Round to nearest whole unit.
  // For values less than 1 show “< 1”.
  // Example: ELECTRICITY, GAS_FUELS, WATER .etc

  if (isNaN(unitValue ?? undefined) || [WASTE, CARBON].includes(utilityType)) {
    return unitValue
  }

  if((EnergyUnitNames.MWH === unitName || EnergyUnitNames.GWH === unitName || BTUUnitNames.MBTU === unitName) && (Math.abs(unitValue) > 0.9 && Math.abs(unitValue) < MASS_MIN_THRESHOLD_10)){
    return Number.parseFloat(unitValue).toFixed(1)
  }
  return getMinOrRoundedValue(unitValue)
}