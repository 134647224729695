import axiosInstance from '../common/axios-config'
import {getValidFilters} from '../../components/common/action-plan/actionPlanTableTemplate'

export const actionData = async (keys) => {
  const assetId=keys?.queryKey[1]
  if(assetId) {
    const {data} = await axiosInstance.get(`/api/v1/Assets/${assetId}/Actions`) // /api/v1/Assets/{assetId}/Actions
    return data
  }
}

export const activeSurveyAsset = async (keys) => {
  const assetId=keys?.queryKey[1]
  if(assetId) {
    const {data} = await axiosInstance.get(`/api/v1/Assets/${assetId}/AssetHasActiveSurvey`) // /api/v1/Assets/{assetId}/AssetHasActiveSurvey
    return data
  }
}

export const activeSurveyAssetNotifications = async (assetId) => {
  const {data} = await axiosInstance.get(`/api/v1/Assets/${assetId}/AssetHasActiveSurvey/`)
  return data
}

export const actionsDataNotifications = async (assetId) => {
  const {data} = await axiosInstance.get(`/api/v1/Assets/${assetId}/Actions`)
  return data
}

export const helperMethod = async ({assetId,action,actionId,noteId, ...editedData}) => {
  let helperId
  if(action==='updateAction'){
    await updateActionData({assetId,actionId,...editedData})
  }else if(action==='updateNote'){
    await updateNoteData({assetId,actionId,noteId,...editedData})
  }else if(action==='newAction'){
    helperId= await addAction({assetId, ...editedData})
  }else if(action==='delete'){
    helperId= await deleteAction({assetId,actionId})
  }else if(action==='deleteNote'){
    helperId= await deleteNote({assetId,actionId,noteId,...editedData})
  }else{
    await addNote({assetId,actionId,...editedData})
  }
  return helperId
}

export const updateActionData = async ({assetId,actionId,...editedData}) => {
  const {data} = await axiosInstance.put(`/api/v1/Assets/${assetId}/Actions/${actionId}`, editedData)
  return data
}

export const deleteAction = async ({assetId,actionId}) => {
  const {data} = await axiosInstance.delete(`/api/v1/Assets/${assetId}/Actions/${actionId}`)
  return data
}

export const updateNoteData = async ({assetId,actionId,noteId,...editedNoteData}) => {
  const {data} = await axiosInstance.put(`/api/v1/Assets/${assetId}/Actions/${actionId}/Notes/${noteId}`,editedNoteData)
  return data
}

export const deleteNote = async ({assetId,actionId,noteId,...editedNoteData}) => {
  const {data} = await axiosInstance.delete(`/api/v1/Assets/${assetId}/Actions/${actionId}/Notes/${noteId}`,editedNoteData)
  return data
}

export const addAction = async ({assetId,...actionData}) => {
  const {data} = await axiosInstance.post(`/api/v1/Assets/${assetId}/Actions`,actionData)
  return data
}

export const addNote = async ({assetId,actionId,...noteData}) => {
  const {data} = await axiosInstance.post(`/api/v1/Assets/${assetId}/Actions/${actionId}/Notes`,noteData)
  return data
}

export const getAssetAvailableUtilityMeters= async (keyInfo)=>{
  const assetId = keyInfo?.queryKey[1]
  const {data} = await axiosInstance.get(`api/v1/Assets/${assetId}/actions/availableutilities`)
  return data
}

export const actionDataV2 = async (keys) => {
  let data
  const assetId=keys?.queryKey[1]
  const searchAction = keys?.queryKey[2] ? keys?.queryKey[2].split(' ').join('%20') : ''
  const oDataQuery=keys.queryKey[4] ? keys.queryKey[4] : ''
  if (assetId) {
    const searchQueryParam = searchAction.length > 0 ? `&SearchAction=${searchAction}` : ''
    const requiredValidFilters = getValidFilters(keys.queryKey[3] ? keys.queryKey[3] : {})
    let filterQueryParams = Object.entries(requiredValidFilters)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&')
    filterQueryParams = filterQueryParams.length>0 ? `&${filterQueryParams}`:''
    const url = `/api/v1/Assets/${assetId}/ActionList${oDataQuery}${searchQueryParam}${filterQueryParams}`

    data  = await axiosInstance.get(url)
  }
  return data
}

export const getAssetActionSummaryDetails = async (keys) => {
  const assetId=keys?.queryKey[1]
  if(assetId) {
    const {data} = await axiosInstance.get(`/api/v1/Assets/${assetId}/Actions/StatusSummary`)
    return data
  }
}

export const addActionV2 = async ({assetId,actionData}) => {
  const {data} = await axiosInstance.post(`/api/v1/Assets/${assetId}/Actions/WithUtilities`,actionData)
  return data
}

export const editActionV2 = async ({assetId,actionData, actionId}) => {
  const {data} = await axiosInstance.put(`/api/v1/Assets/${assetId}/Actions/WithUtilities/${actionId}`,actionData)
  return data
}

export const deleteActionV2 = async ({assetId,actionId}) => {
  const {data} = await axiosInstance.delete(`/api/v1/Assets/${assetId}/Actions/${actionId}`)
  return data
}

export const assetActionListHelper = async ({actionType, assetId, actionData, actionId}) => {
  if(actionType === 'addAction') {
    return await addActionV2({assetId, actionData})
  } else if(actionType === 'editAction') {
    return await editActionV2({assetId, actionData, actionId})
  } else if(actionType === 'deleteAction') {
    return await deleteActionV2({assetId, actionId})
  }
}

export const actionDataDetails = async(keys) => {
  const assetId=keys?.queryKey[1]
  const actionId=keys?.queryKey[2]
  if(assetId && actionId) {
    const {data} = await axiosInstance.get(`/api/v1/Assets/${assetId}/Actions/Detail/${actionId}`)
    return data
  }
}
