import { formatDateToDDMMYY } from 'utils/helpers/DateUtils'
import { applyMultipleFundReportPAITableHeaderStyles, formatData, getEmptyRows, mergeMultipleCellRanges, setRowHeight, textAlignCenter } from 'utils/helpers/ExcelUtils'
import { generatePrincipleMetricsRow, getPrincipleMetricsTableHeader, getfundReportSheetHeaderInformation } from './fundReportPAIExcelExport'

export const createFundReportPAIResourceConsumptionSheet = (
  fundName,
  reportingYear,
  resourceConsumptionBreakdown,
  assets,
  localize,
  selectedLanguage,
  excelSheet
) => {
  let excelDataArray = []
  excelDataArray.push(...getfundReportSheetHeaderInformation('t_resource_consumption_impact_report', fundName, reportingYear, localize))
  excelDataArray.push(...getEmptyRows(2))
  excelDataArray = getResourceConsumptionPrincipleMetricsTable(excelDataArray, resourceConsumptionBreakdown.principleMetrics, reportingYear, localize)
  excelDataArray.push(...getEmptyRows(1))
  excelDataArray = getAssetInformationTable(excelDataArray, assets, reportingYear, localize, selectedLanguage, excelSheet)
  return excelDataArray
}

const getResourceConsumptionPrincipleMetricsTable = (currentExcelData, principleMetricsData, reportingYear, localize) => {
  const {totalRawMaterial, sustainableRawMaterial, sustainableRawMaterialShare} = principleMetricsData
  const data = [...currentExcelData]
  data.push(...getPrincipleMetricsTableHeader(localize, reportingYear))
  data.push(...[generatePrincipleMetricsRow('t_total_raw_material_usage', totalRawMaterial.currentYear, totalRawMaterial.previousYear, localize),
    generatePrincipleMetricsRow('t_sustainable_raw_material_usage', sustainableRawMaterial.currentYear, sustainableRawMaterial.previousYear, localize),
    generatePrincipleMetricsRow('t_sustainable_raw_material_usage_share', sustainableRawMaterialShare.currentYear, sustainableRawMaterialShare.previousYear, localize)])
  return data
}

const getAssetInformationTable = (currentExcelData, assetsArray, reportingYear, localize, selectedLanguage, excelSheet) => {
  const data = [...currentExcelData]
  data.push(...getAssetInformationTableHeader(localize, reportingYear))
  assetsArray.forEach(asset => {
    const { 
      assetReference, 
      assetName, 
      location, 
      constructionYear, 
      purchaseDate,
      saleDate, 
      ResourceConsumption
    } = asset
    data.push(...[{
      A: assetReference,
      B: assetName,
      C: location,
      D: formatDateToDDMMYY(purchaseDate, selectedLanguage),
      E: formatDateToDDMMYY(saleDate, selectedLanguage),
      F: constructionYear,
      G: formatData(ResourceConsumption.totalRawMaterialUsage),
      H: formatData(ResourceConsumption.sustainableRawMaterialUsage),
    }])
    excelSheet.row(data.length).height(31)
  })

  return data
}

const getAssetInformationTableHeader = (localize, reportingYear) => {
  return [{
    A: localize('t_asset_information'),
  },
  {
    A: localize('t_asset_reference'),
    B: localize('t_asset_name'),
    C: localize('t_country'),
    D: localize('t_purchase_date'),
    E: localize('t_sale_date'),
    F: localize('t_construction_year'),
    G: `${localize('t_total_raw_material_usage')} (${localize('t_tonnes')})`,
    H: `${localize('t_sustainable_raw_material_usage')} (${localize('t_tonnes')})`,
  },
  {
    A: '',
    B: '',
    C: '',
    D: '',
    E: '',
    F: '',
    G: reportingYear,
    H: reportingYear,
  }]
}

export const applyStylingToResourceConsumptionSheet = (excelSheet) => {
  excelSheet.usedRange().forEach(cell => {
    if (cell.address() !== 'A1') {
      cell.style({fontSize: 12,  horizontalAlignment: 'left', verticalAlignment: 'center'})
    }

    if(cell.address() === 'A8') {
      cell.style({topBorder: false})
    }
  })
  applyMultipleFundReportPAITableHeaderStyles(['A14:H14', 'G15:H15', 'B8:C8'], excelSheet)
  mergeMultipleCellRanges(excelSheet, ['A14:A15', 'B14:B15', 'C14:C15', 'D14:D15', 'E14:E15', 'F14:F15'])
  setRowHeight(excelSheet, 14, 30)
  textAlignCenter(excelSheet, 'G14:H14')
}