import {Skeleton} from 'primereact/skeleton'
import './MeterTableSkeleton.scss'

const MeterTableSkeleton = () => {

  function getSkeletonRow() {
    const bodyRow = (index) => (
      <div className="meter-sk__body__row flex align-items-center justify-content-center" key={index}>
        <div className="sk-meter-wrapper name flex align-items-center justify-content-center"><Skeleton width="182px" borderRadius="0" height="50px" className="sk-asset-ref"/></div>
        <div className="sk-meter-wrapper category flex align-items-center justify-content-center"><Skeleton width="112px" borderRadius="0" height="50px"/></div>
        <div className="sk-meter-wrapper type flex align-items-center justify-content-center"><Skeleton width="112px" borderRadius="0" height="50px"/></div>
        <div className="sk-meter-wrapper supplier flex align-items-center justify-content-center"><Skeleton width="180px" borderRadius="0" height="50px"/></div>
        <div className="sk-meter-wrapper covered flex align-items-center justify-content-center"><Skeleton width="166px" borderRadius="0" height="50px"/></div>
        <div className="sk-meter-wrapper served flex align-items-center justify-content-center"><Skeleton width="130px" borderRadius="0" height="50px"/></div>
        <div className="sk-meter-wrapper served flex align-items-center justify-content-center"><Skeleton width="130px" borderRadius="0" height="50px"/></div>
      </div>
    )

    return new Array(5).fill(0).map((_, index)=> bodyRow(index))
  }

  return (
    <div className="meter-sk">
      <div className="meter-sk__header flex align-items-center justify-content-center">
        <div className="sk-meter-wrapper name flex align-items-center justify-content-center"><Skeleton width="182px" borderRadius="0" height="17px" className="sk-asset-ref"/></div>
        <div className="sk-meter-wrapper category flex align-items-center justify-content-center"><Skeleton width="112px" borderRadius="0" height="17px"/></div>
        <div className="sk-meter-wrapper type flex align-items-center justify-content-center"><Skeleton width="112px" borderRadius="0" height="17px"/></div>
        <div className="sk-meter-wrapper supplier flex align-items-center justify-content-center"><Skeleton width="180px" borderRadius="0" height="17px"/></div>
        <div className="sk-meter-wrapper covered flex align-items-center justify-content-center"><Skeleton width="166px" borderRadius="0" height="17px"/></div>
        <div className="sk-meter-wrapper served flex align-items-center justify-content-center"><Skeleton width="130px" borderRadius="0" height="17px"/></div>
        <div className="sk-meter-wrapper served flex align-items-center justify-content-center"><Skeleton width="130px" borderRadius="0" height="17px"/></div>
      </div>
      <div className="meter-sk__body">{getSkeletonRow()}</div>
    </div>

  )
}

export default MeterTableSkeleton