import XlsxPopulate from 'xlsx-populate'
import FileSaver from 'file-saver'
import { formatDateToDdMonYy } from 'utils/helpers/DateUtils'
import { excelHelpers, formatData } from 'utils/helpers/ExcelUtils'
import PropTypes from 'prop-types'
import { applyStylingToOverviewSheet, createFundReportPAIOverviewSheet } from './overviewSheet'
import { applyStylingToFossilFuelSheet, createFundReportPAIFossilFuelSheet } from './fossilFuelSheet'
import { applyStylingToInefficientRESheet, createFundReportPAIInefficientRESheet } from './inefficientRESheet'
import { applyStylingToGhgSheet, createFundReportPAIGhgSheet } from './ghgSheet'
import { applyStylingToEnergySheet, createFundReportPAIEnergyConsumptionSheet } from './energyConsumptionSheet'
import { applyStylingToWasteSheet, createFundReportPAIWasteSheet } from './wasteSheet'
import { applyStylingToResourceConsumptionSheet, createFundReportPAIResourceConsumptionSheet } from './resourceConsumptionSheet'
import { applyStylingToBiodiversitySheet, createFundReportPAIBiodiversitySheet } from './biodiversitySheet'

export const fundReportPAIExcelExport = async ({ fundReportsData, formatMessage, selectedLanguage }) => {
  const {
    fundName,
    reportingYear,
    adverseImpactMetrics,
    adverseSustainabilityImpactMetrics,
    reportBreakdown
  } = fundReportsData
  const localize = (id) => formatMessage({ id })
  const blob = await XlsxPopulate.fromBlankAsync().then(workbook => {
    const excelSheetOverview = workbook.sheet(0).name(localize('t_overview'))
    let requiredDataToGenerateExcel = createFundReportPAIOverviewSheet(
      fundName,
      reportingYear,
      adverseImpactMetrics,
      adverseSustainabilityImpactMetrics,
      localize,
      excelSheetOverview
    )
    excelHelpers(requiredDataToGenerateExcel, excelSheetOverview)
    applyStylingToOverviewSheet(excelSheetOverview, localize)

    if(reportBreakdown?.FossilFuels) {
      const excelSheetFossilFuel = workbook.addSheet(localize('t_fossil_fuels'))
      let requiredDataToGenerateExcelFossilFuel = createFundReportPAIFossilFuelSheet(
        fundName,
        reportingYear,
        reportBreakdown.FossilFuels,
        reportBreakdown.Assets,
        localize,
        selectedLanguage,
        excelSheetFossilFuel
      )
      excelHelpers(requiredDataToGenerateExcelFossilFuel, excelSheetFossilFuel)
      applyStylingToFossilFuelSheet(excelSheetFossilFuel)
    }

    if(reportBreakdown?.EnergyEfficiency) {
      const excelSheetInefficientRE = workbook.addSheet(localize('t_inefficient_re'))
      let requiredDataToGenerateExcelInefficientRE = createFundReportPAIInefficientRESheet(
        fundName,
        reportingYear,
        reportBreakdown.EnergyEfficiency,
        reportBreakdown.Assets,
        localize,
        selectedLanguage,
        excelSheetInefficientRE
      )
      excelHelpers(requiredDataToGenerateExcelInefficientRE, excelSheetInefficientRE)
      applyStylingToInefficientRESheet(excelSheetInefficientRE)
    }

    if(reportBreakdown?.GreenhouseGasEmissions) {
      const excelSheetGhg = workbook.addSheet(localize('t_ghg'))
      let requiredDataToGenerateExcelGhg = createFundReportPAIGhgSheet(
        fundName,
        reportingYear,
        reportBreakdown.GreenhouseGasEmissions,
        reportBreakdown.Assets,
        localize,
        selectedLanguage,
        excelSheetGhg
      )
      excelHelpers(requiredDataToGenerateExcelGhg, excelSheetGhg)
      applyStylingToGhgSheet(excelSheetGhg)
    }

    if(reportBreakdown?.EnergyConsumption) {
      const excelSheetEnergy = workbook.addSheet(localize('t_energy'))
      let requiredDataToGenerateExcelEnergy = createFundReportPAIEnergyConsumptionSheet(
        fundName,
        reportingYear,
        reportBreakdown.EnergyConsumption,
        reportBreakdown.Assets,
        localize,
        selectedLanguage,
        excelSheetEnergy
      )
      excelHelpers(requiredDataToGenerateExcelEnergy, excelSheetEnergy)
      applyStylingToEnergySheet(excelSheetEnergy)
    }

    if(reportBreakdown?.Waste) {
      const excelSheetWaste = workbook.addSheet(localize('t_waste_utility'))
      let requiredDataToGenerateExcelWaste = createFundReportPAIWasteSheet(
        fundName,
        reportingYear,
        reportBreakdown.Waste,
        reportBreakdown.Assets,
        localize,
        selectedLanguage,
        excelSheetWaste
      )
      excelHelpers(requiredDataToGenerateExcelWaste, excelSheetWaste)
      applyStylingToWasteSheet(excelSheetWaste)
    }

    if(reportBreakdown?.ResourceConsumption) {
      const excelSheetResourceConsumption = workbook.addSheet(localize('t_resource_consumption'))
      let requiredDataToGenerateExcelResourceConsumption = createFundReportPAIResourceConsumptionSheet(
        fundName,
        reportingYear,
        reportBreakdown.ResourceConsumption,
        reportBreakdown.Assets,
        localize,
        selectedLanguage,
        excelSheetResourceConsumption
      )
      excelHelpers(requiredDataToGenerateExcelResourceConsumption, excelSheetResourceConsumption)
      applyStylingToResourceConsumptionSheet(excelSheetResourceConsumption)
    }

    if(reportBreakdown?.Biodiversity) {
      const excelSheetBiodiversity = workbook.addSheet(localize('t_biodiversity'))
      let requiredDataToGenerateExcelBiodiversity = createFundReportPAIBiodiversitySheet(
        fundName,
        reportingYear,
        reportBreakdown.Biodiversity,
        reportBreakdown.Assets,
        localize,
        selectedLanguage,
        excelSheetBiodiversity
      )
      excelHelpers(requiredDataToGenerateExcelBiodiversity, excelSheetBiodiversity)
      applyStylingToBiodiversitySheet(excelSheetBiodiversity)
    }
    workbook.activeSheet(localize('t_overview'))

    return workbook.outputAsync(workbook)
  })
  FileSaver.saveAs(blob, `${fundName} ${localize('t_pai_report')} ${reportingYear} ${formatDateToDdMonYy()}`)
}

export const getfundReportSheetHeaderInformation = (titleStringBeforeLocalization, fundName, reportingYear, localize) => {
  return [
    {A: localize(titleStringBeforeLocalization)},
    {A: `${localize('t_fund_name')}: ${fundName}`},
    {A: `${localize('t_reporting_year')}: ${reportingYear}`},
    {A: `${localize('t_date_of_export')}: ${formatDateToDdMonYy()}`}
  ]
}

export const getQuarterlySummaryTableHeader = (localize, reportingYear) => {
  return [{
    A: localize('t_quarterly_summary'),
  },
  {
    A: '',
    B: `Q1 ${reportingYear}`,
    C: `Q2 ${reportingYear}`,
    D: `Q3 ${reportingYear}`,
    E: `Q4 ${reportingYear}`,
  }]
}

export const getPrincipleMetricsTableHeader = (localize, reportingYear) => {
  return [{
    A: localize('t_principle_metrics'),
  },
  {
    A: '',
    B: reportingYear,
    C: reportingYear - 1,
  }]
}

export const getAssetInformationTableHeaderShared = (localize, reportingYear) => {
  return [
    {
      A: localize('t_asset_information'),
    },
    {
      A: localize('t_asset_reference'),
      B: localize('t_asset_name'),
      C: localize('t_country'),
      D: localize('t_purchase_date'),
      E: localize('t_sale_date'),
      F: localize('t_construction_year'),
      G: localize('t_gross_asset_value'),
      H: '',
      I: '',
      J: '',
      K: `${localize('t_exposed')}?`,
      L: '',
      M: '',
      N: '',
    },
    {
      A: '',
      B: '',
      C: '',
      D: '',
      E: '',
      F: '',
      G: `Q1 ${reportingYear}`,
      H: `Q2 ${reportingYear}`,
      I: `Q3 ${reportingYear}`,
      J: `Q4 ${reportingYear}`,
      K: `Q1 ${reportingYear}`,
      L: `Q2 ${reportingYear}`,
      M: `Q3 ${reportingYear}`,
      N: `Q4 ${reportingYear}`,
    }]
}

export const generateQuarterlySummaryRow = (label, q1, q2, q3, q4, localize) => {
  return {
    A: localize(label),
    B: formatData(q1),
    C: formatData(q2),
    D: formatData(q3),
    E: formatData(q4),
  }
}

export const generatePrincipleMetricsRow = (label, currentYearData, previousYearData, localize) => {
  return {
    A: localize(label),
    B: formatData(currentYearData),
    C: formatData(previousYearData),
  }
}

fundReportPAIExcelExport.propTypes = {
  fundName: PropTypes.string.isRequired,
  reportingYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  adverseImpactMetrics: PropTypes.object.isRequired,
  adverseSustainabilityImpactMetrics: PropTypes.object.isRequired,
  formatMessage: PropTypes.func.isRequired,
  reportBreakdown: PropTypes.object.isRequired,
}