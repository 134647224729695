import React, {memo, useRef} from 'react'
import PropTypes from 'prop-types'
import {UTILITY_LABELS_OBJECT_TITLE_CASE} from '../../../utils/helpers/Constants'
import infoBlack from '../../../resources/images/icon/info.black.svg'
import {getLocalizedValue} from '../../../utils/helpers/Helper'
import {OverlayPanel} from 'primereact/overlaypanel'
import DataCategoriesInformation from '../../pages/funds/data-quality-home/fund-dq-components/DataCategoriesInformation'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

function ScopesInformation(props) {
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const infoIconOverlay = useRef(null)

  return (
    <div className={props.className}>
      {props.performanceState?.selectedUtility.name === UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON &&
        <>
          <div id={'tooltip-scope-info'}>
            <img
              src={infoBlack}
              alt={getLocalizedValue(selectedLanguage, 't_info')}
              className="cursor scope-info-icon"
              onClick={(e) => infoIconOverlay.current.toggle(e)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === 'Space') {
                  infoIconOverlay.current.toggle(e)
                }
              }}
            />
          </div>
          <OverlayPanel ref={infoIconOverlay}
            className="scope-overlay"
            showCloseIcon={true}
            dismissable={false}
            appendTo={document.getElementById('tooltip-scope-info')}
            style={{marginTop: '-2.313rem'}}
          >
            <DataCategoriesInformation data={'t_carbon_scope_info'}/>
          </OverlayPanel>
        </>}
    </div>
  )
}

ScopesInformation.propTypes = {
  className: PropTypes.string,
  performanceState: PropTypes.object,
}
export default memo(ScopesInformation)