import {consumptionUpload, getHeaderNamesForPrePopulatedTemp} from '../../../../../utils/helpers/Constants'
import {getLocalizedValue} from '../../../../../utils/helpers/Helper'

export const TRUE = true

export const LIST = 'list'

export const ANY_VALUE = 'anyValue'
export const GRAY_COLOR = 'e7e6e6'
export const WHITE_COLOR = 'FFFFFF'
export const GREEN_COLOR = 'a9d08e'
export const BLUE_COLOR = 'd9e1f2'
export const BLACK_COLOR = '000000'
export const CENTER = 'center'
export const LEFT = 'left'

export const bulkUploadFileGenerationStyles = {
  instructionsHeader: {
    fontFamily: 'Calibri',
    fontSize: 18
  },
  instructionsPleaseNote: {
    bold: TRUE,
    fontFamily: 'Calibri',
    underline: TRUE
  },
  topBorder: {
    topBorder: TRUE
  },
  prePopulateGreen: {
    fill: GREEN_COLOR,
    verticalAlignment: 'center',
    border: TRUE,
    borderColor: BLACK_COLOR
  },
  prePopulateBlue: {
    fill: BLUE_COLOR,
    verticalAlignment: 'center',
    border: TRUE,
    borderColor: BLACK_COLOR
  },
  rightBorder: {
    rightBorder: TRUE
  },
  bottomBorder: {
    bottomBorder: TRUE
  },
  wrapText: {
    wrapText: TRUE
  },
  instructionsPoint:{
    horizontalAlignment:  CENTER
  },
  instructionsMessage:{
    horizontalAlignment: LEFT
  },
  commentValidation:{
    type: 'textLength',
    operator: 'lessThan',
    formula1: '100',
    showDropDown: false,
    errorTitle: 'Character Limit Exceeded',
    error: 'The text must be less than 200 characters.',
    errorStyle: 'stop',
    showErrorMessage:true
  }
}

export const ACTION_UPLOAD_HEADERS = {
  assetRef: 't_required_asset_ref',
  assetName: 't_required_asset_name',
  impactType: 't_impact_type',
  gresbEfficiency: 't_gresb_efficiency_measure',
  status: 't_required_status',
  actionPlanTitle: 't_action_plan_title',
  areaCover: 't_required_area_covered',
  cost: 't_cost',
  costType: 't_cost_type',
  currency: 't_required_currency',
  primaryUtilityType: 't_required_primary_utility_type',
  primaryUtilityChange: 't_required_primary_utility_change',
  primaryUtilityCostChange: 't_required_primary_utility_cost_change',
  secondaryUtilityType: 't_secondary_utility_type',
  secondaryUtilityChange: 't_secondary_utility_change',
  manager: 't_manager',
  dueDate: 't_due_date',
  dateCompleted: 't_date_completed',
  notes: 't_notes'
}

export const excelParams = (isEdit) => ({
  'action-upload': {
    borderBottom: 'A10:B10',
    primarySheetName: 't_instructions',
    secondarySheetName: isEdit ? 't_bulk_edit_template' : 't_bulk_add_template',
    header: isEdit ? 't_bulk_action_plan_edit_instruction_header' : 't_bulk_action_plan_instruction_header',
    excelHeaders: ACTION_UPLOAD_HEADERS,
    isBorder: true
  },
  'consumption-upload': {
    borderBottom: 'A11:B11',
    primarySheetName: 't_instructions',
    secondarySheetName: 't_consumption_loader',
    header: 't_bulk_consumption_instruction_header',
    excelHeaders: getHeaderNamesForPrePopulatedTemp,
    isBorder: false
  }
})

export const instructionJSON = (selectedLanguage, isEdit) => { return {
  'action-upload': [
    {label: 1, value: getLocalizedValue(selectedLanguage, isEdit ? 't_bulk_action_plan_edit_instruction_one' : 't_bulk_action_plan_instruction_one')},
    {label: 2, value: getLocalizedValue(selectedLanguage, isEdit ? 't_bulk_action_plan_edit_instruction_two' : 't_bulk_action_plan_instruction_two')},
    {label: 3, value: getLocalizedValue(selectedLanguage, 't_bulk_action_plan_instruction_three')},
    {label: 4, value: getLocalizedValue(selectedLanguage, 't_bulk_action_plan_instruction_four')},
    {label: 5, value: getLocalizedValue(selectedLanguage, 't_bulk_action_plan_instruction_five')},
    {label: 6, value: getLocalizedValue(selectedLanguage, 't_bulk_action_plan_instruction_six')},
    {label: 7, value: getLocalizedValue(selectedLanguage, 't_bulk_action_plan_instruction_seven')},
  ],
  'consumption-upload': [
    {label: 1, value: getLocalizedValue(selectedLanguage, 't_bulk_consumption_instruction_one')},
    {label: 2, value: getLocalizedValue(selectedLanguage, 't_bulk_consumption_instruction_two')},
    {label: 3, value: getLocalizedValue(selectedLanguage, 't_bulk_consumption_instruction_three')},
    {label: 4, value: getLocalizedValue(selectedLanguage, 't_bulk_consumption_instruction_four')},
    {label: 5, value: getLocalizedValue(selectedLanguage, 't_bulk_consumption_instruction_five')},
    {label: 6, value: getLocalizedValue(selectedLanguage, 't_bulk_consumption_instruction_six')},
    {label: 7, value: getLocalizedValue(selectedLanguage, 't_bulk_consumption_instruction_seven')},
    {label: 8, value: getLocalizedValue(selectedLanguage, 't_bulk_consumption_instruction_eight')},
  ]
} }
export function getInstructionsData(selectedLanguage, type, isEdit) {
  return instructionJSON(selectedLanguage, isEdit)[type]
}

export const emptyExcelObj = {
  'assetRef': '', 'assetName': '', 'utility': '', 'areaCovered': '', 'meterStatus': '',
  'meterSerialNumber': '', 'meterMPAN': '', 'meterId': null, 'fromDate': null, 'toDate': null,
  'consumption': null, 'consumptionType': null, 'origin': null, 'meterComment': ''
}

export const ACTION_ID_HEADER = { 'actionId' : 't_required_action_id' }

const ASSET_NAME = 'assetName'
const METER_NAME = 'meterName'
const GRESB_EFFICIENCY = 'gresbEfficiency'
const ASSET_REF = 'assetRef'
const IMPACT_TYPE = 'impactType'
const ACTION_PLAN_TITLE = 'actionPlanTitle'
const CURRENCY = 'currency'
const PRIMARY_UTILITY_TYPE = 'primaryUtilityType'
const PRIMARY_UTILITY_CHANGE = 'primaryUtilityChange'
const PRIMARY_UTILITY_COST_CHANGE = 'primaryUtilityCostChange'
const SECONDARY_UTILITY_TYPE = 'secondaryUtilityType'
const SECONDARY_UTILITY_CHANGE = 'secondaryUtilityChange'
const COST = 'cost'

export const TOOLTIP_COL = ['utility', 'consumptionType', 'origin', 'assetName',
  'areaCovered', 'meterMPAN', 'fromDate', 'toDate', 'consumption', 'varianceType',
  'assetRef', 'gresbEfficiency', 'cost', 'currency', 'primaryUtilityType', 'primaryUtilityChange',
  'primaryUtilityCostChange', 'secondaryUtilityChange', 'actionId']

export function getTooltipLocale(selectedLanguage, key) {
  const getTooltipNamesForPrePopulatedTemp = {
    assetName: getLocalizedValue(selectedLanguage, 't_bulk_consumption_tooltip_asset_name'),
    utility: getLocalizedValue(selectedLanguage, 't_bulk_consumption_tooltip_utility'),
    areaCovered: getLocalizedValue(selectedLanguage, 't_bulk_consumption_tooltip_area_covered'),
    meterMPAN: getLocalizedValue(selectedLanguage, 't_bulk_consumption_tooltip_meter_name'),
    fromDate: getLocalizedValue(selectedLanguage, 't_bulk_consumption_tooltip_from_date'),
    toDate: getLocalizedValue(selectedLanguage, 't_bulk_consumption_tooltip_to_date'),
    origin: getLocalizedValue(selectedLanguage, 't_bulk_consumption_tooltip_origin'),
    consumption: getLocalizedValue(selectedLanguage, 't_bulk_consumption_tooltip_consumption'),
    consumptionType: getLocalizedValue(selectedLanguage, 't_bulk_consumption_tooltip_estimated'),
    meterStatus: getLocalizedValue(selectedLanguage, 't_bulk_consumption_tooltip_is_active'),
    assetRef: getLocalizedValue(selectedLanguage, 't_bulk_action_plan_tooltip_assetReference'),
    gresbEfficiency: getLocalizedValue(selectedLanguage, 't_bulk_action_plan_tooltip_gresbefficiency'),
    cost: getLocalizedValue(selectedLanguage, 't_bulk_action_plan_tooltip_cost'),
    currency: getLocalizedValue(selectedLanguage, 't_bulk_action_plan_tooltip_currency'),
    primaryUtilityType: getLocalizedValue(selectedLanguage, 't_bulk_action_plan_tooltip_primaryUtilityType'),
    primaryUtilityChange: getLocalizedValue(selectedLanguage, 't_bulk_action_plan_tooltip_primaryUtilityChange'),
    primaryUtilityCostChange: getLocalizedValue(selectedLanguage, 't_bulk_action_plan_tooltip_primaryUtilityCostChange'),
    secondaryUtilityChange: getLocalizedValue(selectedLanguage, 't_bulk_action_plan_tooltip_secondaryUtilityChange'),
    actionId: getLocalizedValue(selectedLanguage, 't_bulk_action_plan_edit_tooltip_actionId'),
  }
  return getTooltipNamesForPrePopulatedTemp[key]
}

export const AREA_COVERED_MASK = {
  TenantSpace: 'Tenant Space',
  CommonAreas: 'Common Areas',
  SharedServices: 'Shared Services',
  WholeBuilding: 'Whole Building',
  OutdoorExteriorAreaParking: 'Outdoor/Exterior Area/Parking',
  Exterior: 'Exterior',
  TenantServices: 'Tenant Space',
  null:'','':''
}

export const utilityMask = {
  DistrictCooling: 'District Cooling',
  DistrictHeating: 'District Heating',
  Electricity: 'Electricity',
  Gas: 'Gas',
  Oil: 'Oil',
  Water: 'Water',
  null: '',
  '': ''
}

export const colsToBeFilled = ['consumption','consumptionType','origin','meterComment', 'actionPlanTitle', 'cost', 'primaryUtilityChange',
  'primaryUtilityCostChange', 'secondaryUtilityChange', 'manager', 'notes', 'dueDate', 'dateCompleted', 'actionId']

export const BG_COLOR = ['meterMPAN', 'meterId', 'fromDate', 'toDate', 'consumption', 'origin', 'consumptionType']

export const colsNotToBeFilled = ['assetName','assetRef', 'gresbEfficiency']

export const UTILITY_DROPDOWN = {
  'consumptionType': ['Estimate', 'Actual'],
  'origin': ['AMR', 'Invoices', 'Provided by tenant', 'Meter reading', 'Calculated estimate', 'Supplier estimate'],
  'gresbEfficiency': [
    'Energy > Installation of on-site renewable energy',
    'Energy > Automatic meter readings (AMR)',
    'Energy > Automation system upgrades/replacements',
    'Energy > Installation of high-efficiency equipment and appliances',
    'Energy > Management systems upgrades/replacements',
    'Energy > Occupier engagement/informational technologies',
    'Energy > Performed a technical building assessment to identify energy efficiency improvements',
    'Energy > Smart grid/smart building technologies',
    'Energy > Systems commissioning or retro-commissioning',
    'Energy > Wall/Roof Insulation',
    'Energy > Window Replacements',
    'Water > Automatic meter readings (AMR)',
    'Water > Cooling tower Drip/smart irrigation',
    'Water > Drought tolerant/native landscaping',
    'Water > High efficiency/dry fixtures',
    'Water > Leak detection system',
    'Water > Metering of water subsystems',
    'Water > On-site waste water treatment Reuse of storm water and/or grey water',
    'Waste > Composting landscape and/or food waste', 'Waste > Ongoing waste performance monitoring',
    'Waste > Recycling',
    'Waste > Waste management', 'Waste > Waste stream audit',
    'Tenant Engagement > Building specific communication (i.e. posters, emails or newsletters)',
    'Tenant Engagement > Feedback sessions with individual tenants - e.g. discussing sustainability requirements',
    'Tenant Engagement > Other',
    'Tenant Engagement > Social media/online platform',
    'Tenant Engagement > Tenant meetings which includes sustainability',
    'Tenant Engagement > Tenant sustainability events',
    'Tenant Engagement > Tenant sustainability guide',
    'Tenant Engagement > Tenant sustainability training',
    'Tenant Engagement > Tenants receive feedback on energy, water or waste performance'
  ],
  'impactType': ['Biodiversity', 'Building Performance', 'Community Engagement', 'Energy', 'Governance Recommendations',
    'Health & Wellbeing', 'Social Value', 'Tenant Engagement', 'Waste Recycling', 'Water', 'Other'],
  'status': ['Agreed', 'Applicable', 'Under Review', 'Completed', 'Not applicable'],
  'primaryUtilityType': ['Electricity', 'Gas', 'District Heating', 'District Cooling', 'Oil', 'Energy'],
  'secondaryUtilityType': ['Electricity', 'Gas', 'District Heating', 'District Cooling', 'Oil', 'Energy'],
  'areaCover': ['Common Areas', 'Exterior', 'Tenant Space', 'Shared Services', 'Whole Building'],
  'costType': ['Capital - Non-Recoverable', 'Capital - Recoverable', 'Revenue - Service Charge', 'None'],
  'currency': ['British Pound', 'Euro', 'US Dollar'],
}
export const DROPDOWN_COL = ['consumptionType', 'origin', 'impactType', 'gresbEfficiency', 'status', 'primaryUtilityType',
  'secondaryUtilityType', 'areaCover', 'costType', 'currency', 'assetRef', 'assetName']
export const DATE_COL = ['fromDate', 'toDate', 'dateCompleted', 'dueDate']
export const ALPHANUMERIC_COLUMNS = ['meterSerialNumber', 'meterMPAN', 'meterComment']
export const ALPHANUMERIC_STYLE = {numberFormat: '0', horizontalAlignment: 'left'}

export const EMPTY_ACTION_PLAN_OBJECT = {
  assetName: '',
  assetRef: '',
  actions: [
    {
      impactType: '',
      gresbEfficiency: '',
      status: '',
      actionPlanTitle: '',
      areaCover: '',
      cost: '',
      costType: '',
      currency: '',
      primaryUtilityType: '',
      primaryUtilityChange: '',
      primaryUtilityCostChange: '',
      secondaryUtilityType: '',
      secondaryUtilityChange: '',
      manager: '',
      dueDate: '',
      dateCompleted: '',
      notes: ''
    }
  ]
}

export const getWidth = (param) => {
  switch(param) {
  case PRIMARY_UTILITY_TYPE:
  case PRIMARY_UTILITY_CHANGE:
  case PRIMARY_UTILITY_COST_CHANGE:
  case CURRENCY:
  case SECONDARY_UTILITY_TYPE:
  case SECONDARY_UTILITY_CHANGE:
  case ASSET_NAME: return 30
  case ASSET_REF:
  case IMPACT_TYPE:
  case METER_NAME: return 20
  case ACTION_PLAN_TITLE: return 50
  case GRESB_EFFICIENCY: return 100
  default: return 17
  }
}
export const flattenedData = (actionUploadArray, isEdit) => actionUploadArray?.flatMap(item =>
  (!isEdit ? EMPTY_ACTION_PLAN_OBJECT : item).actions?.map(action => ({
    assetName: item.assetName,
    assetRef: item.assetRef,
    ...action
  }))
)

export const disableButton = (additionalParams, fileName, type) => {
  switch(type) {
  case 'action-upload': return !(additionalParams.typeOfAction && fileName)
  case consumptionUpload: return !fileName
  default: return false
  }
}

const GRESBEFFICIENCY_VALUE = {
  SystemsCommissioningOrRetroCommissioning: 'Systems commissioning or retro-commissioning',
  AutomationSystemUpgradesOrReplacements: 'Automation system upgrades/replacements',
  ManagementSystemsUpgradesOrReplacements: 'Management systems upgrades/replacements',
  WallOrRoofInsulation: 'Wall/roof insulation',
  SmartGridOrSmartBuildingTechnologies: 'Smart grid/smart building technologies',
  WindowReplacements: 'Window replacements',
  InstallationOfHighEfficiencyEquipment: 'Installation of high-efficiency equipment and appliances',
  InstallationOfOnSiteRenewableEnergy: 'Installation of on-site renewable energy',
  OccupierEngagementOrInformationalTechnologies: 'Occupier engagement/informational technologies',
  AMR: 'Automatic meter readings (AMR)',
  PerformedATechnicalBuildingAssessmentToIdentifyEnergyEfficiencyImprovements: 'Performed a technical building assessment to identify energy efficiency improvements',
  HighEfficiencyOrDryFixtures: 'High efficiency/dry fixtures',
  MeteringOfWaterSubsystems: 'Metering of water subsystems',
  ReuseOfStormWaterOrGreyWater: 'Reuse of storm water and/or grey water',
  OnSiteWasteWaterTreatment: 'On-site waste water treatment',
  CoolingTower: 'Cooling tower',
  LeakDetectionSystem: 'Leak detection system',
  DripOrSmartIrrigation: 'Drip/smart irrigation',
  DroughtTolerantOrNativeLandscaping: 'Drought tolerant/native landscaping',
  PerformedATechnicalBuildingAssessmentToIdentifyWaterEfficiencyImprovements: 'Performed a technical building assessment to identify water efficiency improvements',
  OngoingWastePerformanceMonitoring: 'Ongoing waste performance monitoring',
  Recycling: 'Recycling',
  WasteManagement: 'Waste management',
  WasteStreamAudit: 'Waste stream audit',
  CompostingLandscapeOrFoodWaste: 'Composting landscape and/or food waste',
  PerformedATechnicalBuildingAssessmentToIdentifyWasteEfficiencyImprovements: 'Performed a technical building assessment to identify waste efficiency improvements',
  BuildingSpecificCommunication: 'Building specific communication (i.e. posters, emails or newsletters)',
  FeedbackSessions: 'Feedback sessions with individual tenants - e.g. discussin sustainability requirements',
  SocialMediaOnlinePlatform: 'Social media/online platform',
  TenantMeetingsIncludingSustainability: 'Tenant meetings which includes sustainability',
  TenantSustainabilityEvents: 'Tenant sustainability events',
  TenantSustainabilityGuide: 'Tenant sustainability guide',
  TenantSustainabilityTraining: 'Tenant sustainability training',
  TenantsReceiveFeedbackOnPerformance: 'Tenants received feedback on energy, water or waste performance',
  Other: 'Other',
}

const ACTION_STATUS = {
  Applicable: 'Applicable',
  AgreedActions: 'Agreed',
  ActionsUnderReview: 'Under Review',
  Completed: 'Completed',
  NotApplicable: 'Not applicable'
}

const ACTION_COST_TYPE = {
  CapitalNonRecoverable: 'Capital - Non-Recoverable',
  CapitalRecoverable: 'Capital - Recoverable',
  RevenueServiceCharge: 'Revenue - Service Charge',
}

const ACTION_CURRENCY_TYPES = {
  EUR: 'Euro',
  GBP: 'British Pound',
  USD: 'US Dollar'
}
export const transformedData = (originalData) => originalData?.map(item => ({
  ...item,
  actions: item.actions?.map(actionItem => ({
    ...actionItem.action,
    impactType: actionItem.action.impact,
    gresbEfficiency: actionItem.action.performanceMeasure && actionItem.action.performanceMeasure !== 'NotApplicable' ? `${actionItem.action.impact} > ${GRESBEFFICIENCY_VALUE[actionItem.action.performanceMeasure]}` : '',
    actionPlanTitle: actionItem.action.actionDescription,
    areaCover: AREA_COVERED_MASK[actionItem.action.areaCovered],
    primaryUtilityType: actionItem.action.utilityType,
    currency: ACTION_CURRENCY_TYPES[actionItem.action.currency],
    status: ACTION_STATUS[actionItem.action.status],
    costType: actionItem.action.costType ? ACTION_COST_TYPE[actionItem.action.costType] : 'None',
    primaryUtilityChange: '',
    primaryUtilityCostChange: '',
    secondaryUtilityType: '',
    secondaryUtilityChange: '',
    dateCompleted: actionItem.action.completion,
    notes: actionItem.action.actionNotes
  }))
}))