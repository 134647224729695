import React from 'react'
import {TabMenu} from 'primereact/tabmenu'
import I18n from '../../../../../utils/i18n/I18n'
import {UserActions} from '../../../../../utils/helpers/Constants'
import {tabNavigatable} from '../../../../../utils/helpers/Helper'

function UserDetailsHeader(props) {

  let userName
  function updateActiveIndex(evt){
    const userSelectingTab =evt.value
    if (props.userAction !== UserActions.ADD_USER || tabNavigatable(userSelectingTab.value,props.visited)) {
      props.setActiveIndex(evt.index)
    }
  }

  if(props.userHeaderConfig && props.userHeaderConfig.userDetails && props.userHeaderConfig.userDetails.userName){
    userName=props.userHeaderConfig.userDetails.userName
  }

  function getMousePointerClass() {
    return (props.userAction === UserActions.ADD_USER) ? 'tabmenu-add-user' : 'tabmenu-edit-user'
  }

  return (
    <div className={'user-header'}>
      <div className={'addUser-title'}>{userName? userName : I18n(props.userHeaderConfig.headerTitle.toLowerCase())}</div>
      <div className={'addUser-menu p-d-flex p-jc-between'}>
        <TabMenu
          activeIndex={props.activeIndex}
          model={props.userHeaderConfig.tabMenuOptions}
          onTabChange={(e)=>updateActiveIndex(e)}
          className={`user-details-header-tabmenu ${getMousePointerClass()}`}
        />
        {/*{props.activeIndex === 1 ? <SearchField placeholder={'Find groups'}/> : null}*/}
      </div>
    </div>
  )
}

export default UserDetailsHeader