import React, {useEffect, useRef, useState} from 'react'
import { Skeleton } from 'primereact/skeleton'
import '../asset-row-skeleton/AssetRowSkeleton.scss'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import Chart from 'chart.js/auto'

export const NZCAssetTableSkeleton = () => {
  const gridValue = Array.from({ length:7 })
  const bodyTemplate = () => {
    return (
      <div className="custom-skeleton p-rel mb-3">
        <div className="flex mt-2 ml-2 p-abs contour">
          <Skeleton shape="square" size="3.75rem" className="mr-3"/>
          <div>
            <Skeleton width="11rem" className="mb-2"/>
            <Skeleton width="16rem" className="mb-2"/>
          </div>
        </div>
        <Skeleton width="100%" borderRadius="0" height="4rem" animation="none"/>
      </div>
    )
  }
  return (
    <DataTable value={gridValue}>
      <Column field="code" body={bodyTemplate}/>
    </DataTable>
  )
}

export function NZCPageSingleLineSkeleton(){
  return(
    <div className="col-12 no-padding-top">
      <Skeleton width="20.188rem" borderRadius="0.125rem" height="1.875rem" />
    </div>
  )
}
export function NZCPageDoubleLineSkeleton(){
  return(
    <div className="col-12 no-padding-top">
      <Skeleton width="48rem" borderRadius="0.125rem" height="1.313rem"/>
      <br/>
      <Skeleton width="29.438rem" borderRadius="0.125rem" height="1.313rem" />
    </div>
  )
}
export function ChartSkeleton(){
  const chartRef=useRef()
  useEffect(()=>{
    const configData = {
      type: 'bar',
      data: basicData,
      options: getLightTheme(),
    }
    if (window.bar) {
      window.bar.destroy()
    }
    window.bar = new Chart(chartRef.current, configData)
  }, [])

  const [basicData] = useState({
    labels: ['', '', '', '', '', ''],
    datasets: []
  })

  const getLightTheme = () => {
    return {
      maintainAspectRatio: false,
      aspectRatio: .8,
      plugins: {
        legend: {
          display:false,
        }
      },
      scales: {
        x: {
          ticks:{
            display:false
          }
        },
        y: {
          ticks: {
            display:false,
          },
        }
      }
    }
  }
  return <div className="nzc-sk-chart-container"><canvas ref={chartRef} id="nzcSkeletonChart" /></div>
}