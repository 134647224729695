import PropTypes from 'prop-types'
import { Redirect, useRouteMatch } from 'react-router-dom'
import { getLocalStorageByKey } from '../../../utils/helpers/Helper'
import { LOCAL_STORAGE_KEYS, USER_FUND_ACCESSES, COMMON_PAGES } from '../../../utils/helpers/Constants'
import FeatureRoute from '../feature-flags/FeatureFlagRoute'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const FundAccessRoute = (props) => {
  const loginContext = useLoginAuthContext()
  const { params: { instanceName } = {} } = useRouteMatch('/:instanceName/:orgName?') ?? {}
  const { instanceUrlName, userFundAccessType } = getLocalStorageByKey(LOCAL_STORAGE_KEYS.userInfo) ?? {}

  if (instanceUrlName !== instanceName) {
    console.warn(`SIERA. FundAccessRoute.js: user's 'userInfo.instanceUrlName' doesn't match 'match.params.instanceName':
- userInfo.instanceUrlName: ${instanceUrlName}
- match.params.instanceName: ${instanceName}`)
    return <Redirect to={`/${COMMON_PAGES.notfound}`} />
  }
  if ([USER_FUND_ACCESSES.MultipleOrganisationAccess, USER_FUND_ACCESSES.MultipleFundAccess].some(i => i === userFundAccessType) && props.component)
    return <FeatureRoute {...props} />
  if (USER_FUND_ACCESSES.SingleFundAccess === userFundAccessType && props.singleFund && props.component)
    return <FeatureRoute {...props} />

  return <Redirect to={loginContext.loginState?.homePath} />
}

export default FundAccessRoute

FundAccessRoute.propTypes = {
  component: PropTypes.func,
  singleFund: PropTypes.bool
}
