
import {getTimeperiodLabel} from '../../../services/common/datepicker-service'
import {getAssetCompleteness, getAssetCoverage} from '../../../services/assets/dataquality-service'
import {noRefetchOnWindowFocus, retryCount} from '../../../services/common/useQuery-config'
import {useQueries} from 'react-query'


const useAssetCoverageCompletenessData = (assetId, apiParams, responsibilities, sieraplus_assetDataQualityLayoutV2, unitSystem, dashboardLevel = false) => {
  const {
    timePeriod,
    timeNoOfMonthsPeriod: noOfMonths,
    timeEndDatePeriod: endMonth,
    utilitySelectedLandlord,
    areaSelectedLandlord,
    utilitySelectedTenant,
    areaSelectedTenant,
    categorySelectedLandlord,
    categorySelectedTenant,
    utilitySelectedBoth,
    areaSelectedBoth,
    categorySelectedBoth
  } = apiParams

  const {
    landlord,
    tenant,
    both
  }=responsibilities

  const time = getTimeperiodLabel(timePeriod)

  const [
    assetCompletenessLandlord,
    assetCoverageLandlord,
    assetCompletenessTenant,
    assetCoverageTenant,
    assetCompletenessBoth,
    assetCoverageBoth
  ] = useQueries([
    {
      queryKey: ['asset-completeness-landlord', assetId, time, noOfMonths, endMonth, utilitySelectedLandlord, areaSelectedLandlord, categorySelectedLandlord, landlord, unitSystem],
      queryFn: getAssetCompleteness,
      retry: retryCount,
      ...getQueryConfig(!!assetId && !!time && !!landlord && !!sieraplus_assetDataQualityLayoutV2)
    },
    {
      queryKey: ['asset-coverage-landlord', assetId, time, noOfMonths, endMonth, utilitySelectedLandlord, areaSelectedLandlord, landlord, unitSystem],
      queryFn: getAssetCoverage,
      retry: retryCount,
      ...getQueryConfig(!!assetId && !!time && !!landlord && !dashboardLevel)
    },
    {
      queryKey: ['asset-completeness-tenant', assetId, time, noOfMonths, endMonth, utilitySelectedTenant, areaSelectedTenant, categorySelectedTenant, tenant, unitSystem],
      queryFn: getAssetCompleteness,
      retry: retryCount,
      ...getQueryConfig(!!assetId && !!time && !!tenant && !!sieraplus_assetDataQualityLayoutV2)
    },
    {
      queryKey: ['asset-coverage-tenant', assetId, time, noOfMonths, endMonth, utilitySelectedTenant, areaSelectedTenant, tenant, unitSystem],
      queryFn: getAssetCoverage,
      retry: retryCount,
      ...getQueryConfig(!!assetId && !!time && !!tenant && !dashboardLevel )
    },
    {
      queryKey: ['asset-completeness-both', assetId, time, noOfMonths, endMonth, utilitySelectedBoth, areaSelectedBoth, categorySelectedBoth, both, unitSystem],
      queryFn: getAssetCompleteness,
      retry: retryCount,
      ...getQueryConfig(!!assetId && !!time && !!both && !!sieraplus_assetDataQualityLayoutV2)
    },
    {
      queryKey: ['asset-coverage-both', assetId, time, noOfMonths, endMonth, utilitySelectedBoth, areaSelectedBoth, both, unitSystem],
      queryFn: getAssetCoverage,
      retry: retryCount,
      ...getQueryConfig(!!assetId && !!time && !!both && !!sieraplus_assetDataQualityLayoutV2)
    }
  ])

  function getQueryConfig(fetchOnMount) {
    return {...noRefetchOnWindowFocus, staleTime: 999999, enabled: fetchOnMount}
  }

  return {
    assetCompletenessLandlord,
    assetCoverageLandlord,
    assetCompletenessTenant,
    assetCoverageTenant,
    assetCompletenessBoth,
    assetCoverageBoth
  }
}

export default useAssetCoverageCompletenessData