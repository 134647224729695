import React, { useEffect, useState } from 'react'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import I18n from '../../../../../utils/i18n/I18n'
import { Chips } from 'primereact/chips'
import {
  sameDayDatesCheck,
} from '../../../../../services/assets/dataquality-service'
import {
  getLocalizedValue,
} from '../../../../../utils/helpers/Helper'
import {
  dqEmailRegex,
} from '../../../../../utils/helpers/Constants'
import CSS_VARIABLES from '../../../../../resources/css/_variables.scss'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'
import PropTypes from 'prop-types'
import { Icon } from 'components/atomic/index'

const RequestDataDialogV2 = ({
  data,
  onEventActionHideHandler,
  showDialog,
  assetAddress,
  sendDataHandler
}) => {
  const [emails, setEmails] = useState([])
  const [invalidEmails, setInvalidEmails] = useState([])
  const {
    loginState: { userInfo },
  } = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const emailPattern = new RegExp(dqEmailRegex)

  useEffect(() => {
    setInvalidEmailColor()
  }, [emails])

  function isEmailValid() {
    return emails?.length > 0 && invalidEmails?.length < 1
  }

  function removeEmailFromInvalidEmails(emailToRemove) {
    if (emailToRemove) {
      setInvalidEmails((prevInvalidEmails) =>
        prevInvalidEmails.filter((email) => email !== emailToRemove)
      )
    }
  }

  function removeEmailFromEmails(emailToRemove) {
    if (emailToRemove) {
      setEmails((prevEmails) =>
        prevEmails.filter((email) => email !== emailToRemove)
      )
    }
  }

  function emailChipIconRemoveHandler(evt) {
    let currTarget = evt.currentTarget
    if (
      currTarget?.parentElement?.textContent
    ) {
      let emailToRemove = currTarget?.parentElement?.textContent
      removeEmailFromEmails(emailToRemove)
      removeEmailFromInvalidEmails(emailToRemove)
    }
  }

  function setInvalidEmailColor() {
    let element = document.querySelector(
      'div.p-chips ul.p-inputtext.p-chips-multiple-container'
    )
    if (element) {
      Array.from(element.children).forEach((child) => {
        if (emailPattern.test(child.textContent)) {
          child.style.color = CSS_VARIABLES.forest
        } else {
          child.style.color = CSS_VARIABLES.red
        }
      })
    }
  }

  const emailChangeHandler = (evt) => setEmails(evt.value)

  const emailAddHandler = (evt) => {
    let newEmail = evt.value
    if (!emailPattern.test(newEmail)) {
      let prevInvalidEmails = [...invalidEmails]
      prevInvalidEmails.push(newEmail)
      setInvalidEmails(prevInvalidEmails)
    }
  }

  const customChip = (item) => {
    return (
      <div className="label-chip-container">
        <span className="chip-label">{item}</span>
        <Icon id='close_solid_circle' onClickHandler={emailChipIconRemoveHandler} size={17} />
      </div>
    )
  }

  function onRemoveEmailHandler(evt) {
    removeEmailFromInvalidEmails(evt.value[0])
  }

  const emailChips = (
    <Chips
      value={emails}
      onChange={(e) => emailChangeHandler(e)}
      itemTemplate={customChip}
      separator=","
      onAdd={(e) => emailAddHandler(e)}
      onRemove={onRemoveEmailHandler}
      placeholder={getLocalizedValue(selectedLanguage, 't_email_hint')}
    />
  )

  const renderFooter = (
    <div className="dq-Dlg-footer" data-testid={'dialog-footer'}>
      <div className="footer-send">
        <Button
          className="send-mail req-dialog-highlight-clickable-opacity"
          onClick={() => onRequestButtonClick(emails, data)}
          disabled={!isEmailValid()}
        >
          {I18n('t_request_data')}
        </Button>
      </div>
    </div>
  )

  function getDialogTitle() {
    let dialogTitle = getLocalizedValue(selectedLanguage, 't_request_data')
    if (assetAddress) {
      dialogTitle = `${dialogTitle} - ${assetAddress}`
    }
    return dialogTitle
  }

  const dgHeaderComponent = () => (
    <span className='p-dialog-title' data-testid={'dialog-header'}>
      {getDialogTitle()}
    </span>
  )

  const onRequestButtonClick = (emails, data) => {
    sendDataHandler(emails, data, onEventActionHideHandler)
  }

  const providerTemplate = (rowData) => {
    if (rowData?.supplier) {
      return rowData.supplier
    }
    return '-'
  }

  const meterNameTemplate = (rowData) => {
    return <span>{rowData?.meterName || '-'}</span>
  }

  const utilTemplate = (rowData) => {
    let utilMsg = ''
    if (rowData?.utility) {
      utilMsg = (
        <span>
          {I18n('t_' + rowData.utility.toLowerCase().replace(/\s+/g, ''))}
        </span>
      )
    }
    return utilMsg
  }

  const dataGapNameTemplate = (rowData) => {
    const startDate = new Date(rowData.dateRange.start)
    const endDate = new Date(rowData.dateRange.end)
    const startDateFormatted = startDate.toLocaleDateString('en-GB', {
      day: '2-digit',
    })
    const endDateFormatted = endDate.toLocaleDateString('en-GB', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    })
    const dataGapTemplate = sameDayDatesCheck(startDate, endDate) ? (
      <span>{endDateFormatted}</span>
    ) : (
      <span>
        {startDateFormatted} - {endDateFormatted}
      </span>
    )

    return dataGapTemplate
  }

  return (
    <Dialog
      className="dq-Dlg"
      header={dgHeaderComponent}
      footer={renderFooter}
      onHide={onEventActionHideHandler}
      icons={<Icon id='close' onClickHandler={onEventActionHideHandler} size={24} />}
      closable={false}
      visible={showDialog}
      blockScroll
    >
      <div className="header-recipient">
        <div className="recipient-label">{I18n('t_req_recipient')}:</div>
        <div className="recipient-input">{emailChips}</div>
      </div>
      <div
        className={`mail-content${data?.length > 5 ? ' padding-right' : ''
        }`}
      >
        <DataTable
          value={data}
          className="dq-Dlg-table"
          onSort={() => false}
          data-testid={'dialog-table'}
        >
          <Column
            field="meterName"
            body={meterNameTemplate}
            header={I18n('t_req_meter')}
            className="meterColumn"
          />
          <Column
            field="utility"
            body={utilTemplate}
            header={I18n('t_req_utility')}
            className="utilityColumn"
          />
          <Column
            field="dateRange"
            body={dataGapNameTemplate}
            header={I18n('t_req_missing_data_period')}
            className="missingDataColumn"
          />
          <Column
            field="supplier"
            body={providerTemplate}
            header={I18n('t_req_supplier')}
            className="missingDataColumn"
          />
        </DataTable>
      </div>
    </Dialog>
  )
}

export default RequestDataDialogV2
RequestDataDialogV2.displayName = 'RequestDataDialogV2'

RequestDataDialogV2.propTypes = {
  data: PropTypes.array,
  onEventActionHideHandler: PropTypes.func,
  showDialog: PropTypes.bool,
  assetAddress: PropTypes.string,
  sendDataHandler: PropTypes.func,
}