import axiosInstancePaneer from './common/axios-config-paneer'
import axiosInstance from './common/axios-config'

export const GetOrganisationsList = async () => {
  const {data} = await axiosInstancePaneer.get('/api/v1/Organisations')
  return data
}

export const getFundGroupData = async () => {
  const { data } = await axiosInstance.get('/api/v1/fundgroups/organisations')
  return data
}