import {Page, Text, View, Document, Font} from '@react-pdf/renderer'
import React from 'react'
import PdfLogo from '../../../../../utils/data-exports/fixed-components/logo/PdfLogo'
import PdfPagination from '../../../../../utils/data-exports/fixed-components/footer/PdfPagination'
import PdfHeader from '../../../../../utils/data-exports/fixed-components/header/PdfHeader'
import styles from '../../../../../utils/data-exports/style-sheet/Styles'
import AgreedAction from './AgreedAction'
import I18n from '../../../../../utils/i18n/I18n'
import {I18nProvider} from '../../../../../utils/i18n'
import { getLocalizedValue, unitDateFormatter } from '../../../../../utils/helpers/Helper'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

Font.registerHyphenationCallback(word => (
  [word]
))

const ActionPdf = (props) => {
  const selectedLanguage=props.language
  const today = new Date(), currentYear = today.getFullYear(), previousYear = today.getFullYear() - 1
  const {loginState: {userInfo}} = useLoginAuthContext()
  const unitSystem = userInfo.unitSystem

  const NoRecords = ({title}) => (
    <View>
      <View>
        <Text style={[styles.title,styles.mB8]}>{title}</Text>
      </View>
      <View style={[styles.planBorder, styles.tableWidth]}>
        <Text style={[styles.title,styles.noRecords]} break>
          {getLocalizedValue(selectedLanguage,'t_no_action_data')}
        </Text>
      </View>
    </View>
  )

  return (
    <Document>
      <I18nProvider locale={props.language}>
        <Page size="A4" style={styles.page}>
          <View>
            <View style={styles.tableRow} fixed>
              <View>
                <PdfLogo header={I18n('t_esg_action_plan')}/>
              </View>
              <View>
                <PdfHeader asset={props.asset} language={props.language}/>
              </View>
            </View>

            <View style={[styles.title,styles.dateRangePosition,styles.mB10]} fixed>
              <Text style={styles.textAlignRight}>{getLocalizedValue(selectedLanguage,'t_date_range')}: {''}
                {unitDateFormatter(new Date(`${previousYear}-04-1`), unitSystem, props.language)} -
                {unitDateFormatter(new Date(`${currentYear}-03-31`), unitSystem, props.language)}
              </Text>
            </View>

            <View style={[styles.mB20]}>
              {props.agreedAction.length !== 0 ?
                <AgreedAction agreedAction={props.agreedAction} language={props.language}
                  title={getLocalizedValue(selectedLanguage,'t_agreed_actions')} featureState={props.featureState}/> :
                <NoRecords title={getLocalizedValue(selectedLanguage,'t_agreed_actions')}/>
              }
            </View>

            <View style={[styles.mB20]}>
              {props.applicableAction.length !== 0 ?
                <AgreedAction agreedAction={props.applicableAction}  language={props.language}
                  title={getLocalizedValue(selectedLanguage,'t_applicable')} featureState={props.featureState}/> :
                <NoRecords title={getLocalizedValue(selectedLanguage,'t_applicable')}/>
              }
            </View>

            <View style={[styles.mB20]}>
              {props.actionReview.length !== 0 ?
                <AgreedAction agreedAction={props.actionReview} language={props.language}
                  title={getLocalizedValue(selectedLanguage,'t_under_review')} featureState={props.featureState}/> :
                <NoRecords title={getLocalizedValue(selectedLanguage,'t_under_review')}/>
              }
            </View>

            <View style={[styles.mB20]}>
              {props.completedAction.length !== 0 ?
                <AgreedAction agreedAction={props.completedAction} completed={true} language={props.language}
                  title={getLocalizedValue(selectedLanguage,'t_completed')} featureState={props.featureState}/> :
                <NoRecords title={getLocalizedValue(selectedLanguage,'t_completed')}/>
              }
            </View>
            <PdfPagination/>
          </View>
        </Page>
      </I18nProvider>
    </Document>
  )
}

export default ActionPdf