import {  getLocalizedValue,  getPercentValueSign,  setMinToZeroForVariousNzcLines} from '../../../../../utils/helpers/Helper'
import CSS_VARIABLES from '../../../../../resources/css/_variables.scss'
import {
  SCOPE_TARGET_MODE,
  TARGET_PATHWAY,
  targetPathwaysMilestoneYears,
  modelsOptions,
  assetsOrderByColumnChange, CURRENT_YEAR_FULL
} from '../../../../../utils/helpers/Constants'
import I18n from '../../../../../utils/i18n/I18n'
import React from 'react'
import {YEAR_2030} from '../../../../common/net-zero-carbon/nzc-constants'
import {fontString} from 'chart.js/helpers'
import {actionsDataNotEmpty} from '../../../../common/net-zero-carbon/nzc-common-utils'


export const dataPointColors= {red: CSS_VARIABLES.red, green: CSS_VARIABLES.green, yellow: CSS_VARIABLES.yellow}

// Default dataset for line Chart
export function getDatasetForLineChart(){
  return {
    'type': 'line',
    'data': [],
    'label': '',
    'borderColor': '',
    'backgroundColor': '',
    'borderWidth': 2,
    'fill': false,
    'pointStyle': {},
    'segment': {},
    'datalabels': {'display': false}
  }
}

export function getDatasetForDashLineChart(){
  return {
    ...getDatasetForLineChart(),
    borderWidth: 3,
    borderDash: [10, 10],
    borderCapStyle: 'round',
  }
}

// Default dataset for bubble Chart
export function getDatasetForBubbleChart(){
  return  {
    'type': 'bubble',
    'data': [],
    'backgroundColor': '',
    'borderColor': '',
    'pointStyle': '',
    'datalabels': {'display': false},
    'language': 'en-GB'
  }
}

export const greenColor = CSS_VARIABLES.green
export const redColor = CSS_VARIABLES.red
export const orangeColor = CSS_VARIABLES.nature

export const getShape = (value) => {
  switch (value) {
  case 1: return {shape: 'circle', color: greenColor}
  case 2: return {shape: 'rectRounded', color: orangeColor}
  default: return {shape: 'triangle', color: redColor}
  }
}

const getSolidOrDottedLine = (lineContext, assetDataPoint, value) => {
  const assetIndividualPointData = assetDataPoint.find((e) => lineContext.p1.parsed.x === e.year)
  return (assetIndividualPointData?.pointStyle === 'triangle') || assetDataPoint.length === 1 ? value : [6, 0]
}

let dataIndex = 0
const getBorderDash = (assetDataPoint, lineContext, value, currentYear, baselineYear) => {
  dataIndex = lineContext.p1.parsed.x === currentYear && dataIndex === 0 ? lineContext.p1DataIndex : dataIndex
  return (lineContext.p1DataIndex <= dataIndex || dataIndex === 0) && currentYear !== baselineYear ? value : getSolidOrDottedLine(lineContext, assetDataPoint, value)
}

/**
 * Returns the asset bau value
 *
 * @param {object} graphData to get intensity BAU value based on selected intensity
 */
function assetBauAvgIntensity(graphData) {
  let bauIntensityValue
  if(graphData.assetIntensity === assetsOrderByColumnChange.energyIntensity){
    bauIntensityValue = graphData?.energyIntensity
  } else {
    bauIntensityValue = graphData?.carbonIntensity
  }
  return bauIntensityValue
}

// For progress graphs(data points) for the Asset
export function getAssetBauProgressGraph(propsForGetAssetProgressGraphFunction){
  const {targetPathways, reportingYear, baselineYear, selectedLanguage, assetId, epcRatingLabel, nzcScopeDetails, assetIntensity,chartLegendIcon, featureState} = propsForGetAssetProgressGraphFunction
  if(!(targetPathways?.graphData) || !reportingYear || !baselineYear){
    return null
  }
  const {assetGraphData}=targetPathways
  let assetProgressGraph=getDatasetForBubbleChart()
  let assetBauLineAndDQGraphDatasets
  if(assetGraphData){
    const assetData=assetGraphData.find(assetGraphDataElement=> assetGraphDataElement.assetId === Number(assetId))
    if(assetData?.graphData?.length>0){
      assetBauLineAndDQGraphDatasets=[]
      assetData.graphData.sort((assetA, assetB) => assetB.year - assetA.year)
      for (let yearStart = reportingYear; yearStart >= baselineYear; yearStart--) {
        const yearlyAssetGraphData = assetData.graphData.find(graphDataElement => graphDataElement.year === yearStart)
        const assetPanelData = { ...yearlyAssetGraphData, strandingYear: assetData?.strandingYear?.year , asset: nzcScopeDetails?.fundAssets?.find((assetObj) => assetObj.asset.assetId === assetData?.assetId)?.asset, epcRating: epcRatingLabel, featureStateSoldAndPurchased: featureState?.sieraplus_nzcAssetSoldAndPurchased }
        const dataAvailablePercentage=yearlyAssetGraphData?.dataAvailablePercentage
        let valueForShape=1
        if (dataAvailablePercentage < 0.50) {
          valueForShape = 3
        } else if(dataAvailablePercentage >= 0.50 && dataAvailablePercentage < 1){
          valueForShape = 2
        }
        assetProgressGraph={
          ...assetProgressGraph,
          data: [{
            x: yearStart,
            y: assetBauAvgIntensity({...yearlyAssetGraphData, assetIntensity}),
            r: 8,
            datasets: assetPanelData,
            label: reportingYear === yearStart ? assetId : assetId.concat(`_${yearStart}`)
          }],
          backgroundColor: getShape(valueForShape).color,
          borderColor: getShape(valueForShape).color,
          pointStyle: getShape(valueForShape).shape,
          xAxisID: 'xAxis',
          language: selectedLanguage,
          year: yearStart,
          assetIntensityId: reportingYear === yearStart ? assetId : assetId.concat(`_${yearStart}`),
          clip: false,
          order: 2
        }
        assetBauLineAndDQGraphDatasets.push(assetProgressGraph)
      }

      const bauDataset={
        type: 'line',
        label: `    ${getLocalizedValue(selectedLanguage, 't_bau_ghg_intensity')}`,
        borderColor: CSS_VARIABLES.pink,
        backgroundColor: CSS_VARIABLES.pink,
        borderWidth: 2,
        fill: false,
        pointStyle: chartLegendIcon.BAU_LINE,
        data: [],
        segment: {borderDash: context => getBorderDash(assetBauLineAndDQGraphDatasets.filter((e) => e.assetIntensityId), context, [4, 3], reportingYear, baselineYear) || [4, 3]},
        datalabels:{display:false},
        order: 5
      }
      assetData.graphData.forEach((graphData)=>{
        bauDataset.data.push({x:graphData.year,y:assetBauAvgIntensity({...graphData, assetIntensity})})
      })
      assetBauLineAndDQGraphDatasets.push(bauDataset)
    }
  }
  return assetBauLineAndDQGraphDatasets
}

function isViewOrEditMode(scopeTargetState) {
  return (scopeTargetState.tpMode===SCOPE_TARGET_MODE.view || scopeTargetState.tpMode===SCOPE_TARGET_MODE.edit) && scopeTargetState.selectedTPName
}

export function getBAUStrandingYearDetails(BAUStrandingsArray) {
  let BAUStrandingDetailsObject = null
  let strandingYearDifference = Infinity

  BAUStrandingsArray.forEach(stranding => {
    const yearDifference = Math.abs(stranding.year - CURRENT_YEAR_FULL)
    if (stranding.year > CURRENT_YEAR_FULL && yearDifference <= strandingYearDifference) {
      BAUStrandingDetailsObject = stranding
      strandingYearDifference = yearDifference
    }
  })

  if (!BAUStrandingDetailsObject) {
    BAUStrandingsArray.forEach (stranding => {
      const yearDifference = Math.abs(stranding.year - CURRENT_YEAR_FULL)
      if (yearDifference <= strandingYearDifference) {
        BAUStrandingDetailsObject = stranding
        strandingYearDifference = yearDifference
      }
    })
  }

  return BAUStrandingDetailsObject
}

export const STRANDING_POINT_IDENTIFIER = Object.freeze({
  ACTION_BASED_PATHWAYS:{
    CARBON:'Action Based Pathway',
    ENERGY:'EnergyActionBasedPathway'
  },
  BAU:{
    CARBON:'BAU',
    ENERGY:'EnergyBAU'
  }
})

export function getStrandsGraphDatasets(strandings, scopeTargetState, intensity,targetsAndPathwayDetails,actionBasedTPData) {
  if(!strandings){
    return null
  }

  const strandsGraphDatasets=[]
  const strandingDataset= {
    type: 'bubble',
    backgroundColor: 'transparent',
    datalabels: { display: true},
    borderColor: CSS_VARIABLES.red,
    clip: false
  }

  let actionBasedPathwayStrandingLabel = intensity === assetsOrderByColumnChange.energyIntensity?STRANDING_POINT_IDENTIFIER.ACTION_BASED_PATHWAYS.ENERGY:STRANDING_POINT_IDENTIFIER.ACTION_BASED_PATHWAYS.CARBON
  strandings.forEach((stranding)=>{
    if(stranding.strandingOf.replace(TARGET_PATHWAY, 'tp') === scopeTargetState.selectedTPName && isViewOrEditMode(scopeTargetState)){
      strandsGraphDatasets.push(
        {
          ...strandingDataset,
          data: [{
            x: stranding.year,
            y: stranding.strandingPoint,
            r: 17,
            label: stranding.strandingOf
          }],
          borderColor: CSS_VARIABLES.nature ,
          graphName: 'targetPathwayStrandingYear'
        }
      )
    }
    if(stranding.strandingOf === actionBasedPathwayStrandingLabel && targetsAndPathwayDetails.energySavingActions && actionsDataNotEmpty(actionBasedTPData)){
      strandsGraphDatasets.push(
        {
          ...strandingDataset,
          data: [{
            x: stranding.year,
            y: stranding.strandingPoint,
            r: 10,
            label: stranding.strandingOf
          }],
          borderColor: CSS_VARIABLES.green,
          borderWidth:3,
          hitRadius:0,
          hoverBorderWidth:3,
          datalabels: { display: true},
        }
      )
    }
  })
  return strandsGraphDatasets
}

export function getInterimPercentValue(targetPathways, targetYearDataIndex, selectedTPName, currentYearIndex) {
  const targetYearCarbonIntensity = targetPathways?.graphData[targetYearDataIndex][selectedTPName]
  const baselineYearCarbonIntensity = targetPathways.graphData[currentYearIndex][selectedTPName]
  return ((targetYearCarbonIntensity / baselineYearCarbonIntensity) * 100) > 0 ? ((targetYearCarbonIntensity / baselineYearCarbonIntensity) * 100).toFixed(0) : 0
}

const returnYAxisValue = (graphValue, targetValue) => {
  return graphValue < targetValue ? targetValue : graphValue
}

export function createInterimTargetYearLine(targetYearGraphData, targetPathways, selectedTPName, interimTargetProgressGraph, progressTargetYear, selectedLanguage, reportingYear) {
  const targetYearDataIndex = targetPathways.graphData.findIndex((graphDataElement) => graphDataElement.year === Number(progressTargetYear))
  const currentYearIndex = targetPathways.graphData.findIndex((graphDataElement) => graphDataElement.year === reportingYear)
  const percentValue =getInterimPercentValue(targetPathways, targetYearDataIndex, selectedTPName, currentYearIndex)
  const returnSign = getPercentValueSign(percentValue)
  const actualPercent = Math.abs((percentValue - 100)).toFixed(0)
  const displayLabel = `${returnSign} ${actualPercent} %`
  let min = 0
  let max = 0
  interimTargetProgressGraph.push({
    type: 'line',
    fill: false,
    data: (() => {
      let data = []
      for (let i = 0; i <= targetYearDataIndex; i++) {
        data.push({
          x: reportingYear,
          y: displayLabel.includes('-') ? returnYAxisValue(targetPathways.graphData[i][selectedTPName], targetYearGraphData[selectedTPName]) : targetPathways.graphData[i][selectedTPName],
        })
      }
      min = Math.min.apply(null, data.map((e) => e['y']))
      max = Math.max.apply(null, data.map((e) => e['y']))
      return data
    })(),
    backgroundColor: CSS_VARIABLES.red,
    borderColor: CSS_VARIABLES.red,
    borderWidth: 3,
    datalabels: {display: false},
    language: selectedLanguage,
  })
  const midValue = Number((min + max) / 2).toFixed(0)
  interimTargetProgressGraph.push({
    type: 'line',
    data: [
      {
        x: reportingYear,
        y: midValue,
        label: displayLabel
      }
    ],
    backgroundColor: CSS_VARIABLES.forest,
    borderColor: CSS_VARIABLES.red,
    language: selectedLanguage,
  })
}

export function getAssetInterimTargetGraph(targetPathways, targetsAndPathwayDetails, selectedLanguage, baselineYear, scopeTargetState, targetPathway, reportingYear) {
  if(!(targetPathways?.graphData) || !targetsAndPathwayDetails){
    return null
  }
  const progressTargetYear=targetsAndPathwayDetails?.progressTowardsTargetYear
  const showTargetProgress=targetsAndPathwayDetails?.showProgressTowardsTargetYear
  const selectedTPName=scopeTargetState.selectedTPName
  const interimTargetProgressGraph=[]
  const selectedTPFromAPI=`tp${targetPathway}`
  const isSelectedTPSame=(selectedTPName===targetsAndPathwayDetails?.selectedTP && selectedTPName===selectedTPFromAPI)
  const viewOrEditMode=(scopeTargetState?.tpMode === SCOPE_TARGET_MODE.view || scopeTargetState?.tpMode === SCOPE_TARGET_MODE.edit)
  if (showTargetProgress && selectedTPName && viewOrEditMode && progressTargetYear && isSelectedTPSame) {
    const targetYearGraphData = targetPathways.graphData?.find((graphDataElement) => graphDataElement.year === Number(progressTargetYear))
    if (targetYearGraphData) {
      interimTargetProgressGraph.push({
        type: 'line',
        fill: false,
        data: (() => {
          let data = []
          for (let i = reportingYear; i <= Number(progressTargetYear); i++) {
            data.push({
              x: i,
              y: targetYearGraphData[selectedTPName],
            })
          }
          return data
        })(),
        backgroundColor: CSS_VARIABLES.red,
        borderColor: CSS_VARIABLES.red,
        datalabels: {display: false},
        language: selectedLanguage,
      })
      createInterimTargetYearLine(targetYearGraphData, targetPathways, selectedTPName, interimTargetProgressGraph, progressTargetYear, selectedLanguage, reportingYear)
      interimTargetProgressGraph.push({
        type: 'bubble',
        data: [
          {
            x: progressTargetYear,
            y: targetYearGraphData[selectedTPName],
            r: 20,
            label: progressTargetYear + ' interim target'
          }
        ],
        backgroundColor: 'transparent',
        borderColor: CSS_VARIABLES.red,
        datalabels: {display: true},
        language: selectedLanguage,
      })
    }
  }
  return interimTargetProgressGraph
}

export function generateGraphLabels(chart, visibility){
  return  chart.data.datasets.map((dataset, index) => ({
    text: dataset.label,
    fillStyle: dataset.backgroundColor,
    strokeStyle: dataset.backgroundColor,
    lineWidth:dataset.borderWidth,
    pointStyle: dataset.pointStyle,
    hidden: dataset.datalabels?.display,
    index: index,
    fontColor: visibility[index]
  }))
}

export function updateGraphLabelsProperties(chart, generatedLabels, visibility, dataPointColors){
  chart.data.labels.forEach((labelObject,index)=>{
    const color = index === 0 ? dataPointColors.red : dataPointColors.green
    generatedLabels.push({
      text: labelObject.label,
      fillStyle: color,
      strokeStyle: color,
      lineWidth:2,
      pointStyle: 'rect',
      hidden: false,
      index: labelObject.index,
      fontColor: visibility[labelObject.index]
    }
    )
  })
}

export function updateIsEditStateValue(setIsEditValue, targetPathwayDetailsObj, nzcSettingsData) {
  let targetAndPathwayTabDataFromDB={
    energySavingActions: nzcSettingsData?.energySavingActions,
    agreedActions: nzcSettingsData?.agreedActions,
    applicableActions: nzcSettingsData?.applicableActions,
    scienceBasedTargetOption: nzcSettingsData?.scienceBasedTarget,
    progressTowardsTargetYear: nzcSettingsData?.progressTargetYear,
    showProgressTowardsTargetYear: nzcSettingsData?.showTargetProgress,
    selectedTP:nzcSettingsData?.targetPathway !== null  ?`tp${nzcSettingsData?.targetPathway}`: null,
    showTopDownPathways: nzcSettingsData?.showTopDownPathways
  }
  if(JSON.stringify(targetPathwayDetailsObj) !== JSON.stringify(targetAndPathwayTabDataFromDB)){
    setIsEditValue(prevState => {return{
      ...prevState, targetPathwayPanelEdited: true
    }})
  } else{
    setIsEditValue(prevState => {return{
      ...prevState, targetPathwayPanelEdited: false, customTargetPathwayEdited : false
    }})  }
}

export function getCustomTPNameErrorState(customTargetPathwayName, tpNumber, selectedLanguage, nzcSettingsData, inputTextTouched) {
  let isError=false
  let errors=[]
  let regex = new RegExp(/^[a-zA-Z0-9 ]+$/)

  if (inputTextTouched) {
    let tpName = customTargetPathwayName[`tp${tpNumber}`]
    if (tpName?.length > 30) {
      isError=true
      errors.push(getLocalizedValue(selectedLanguage,'t_max_length_30'))
    }
    if (tpName?.length > 0 && !regex.test(tpName)) {
      isError=true
      errors.push(getLocalizedValue(selectedLanguage,'t_tp_name_validation'))
    }
    if (nzcSettingsData?.targetPathwayNames) {
      let newTpObject =  JSON.parse(nzcSettingsData?.targetPathwayNames)
      delete newTpObject[`tp${tpNumber}`]
      if (Object.values(newTpObject).includes(tpName)) {
        isError=true
        errors.push(getLocalizedValue(selectedLanguage,'t_unique_custom_target_pathway_name'))
      }
    }
    if (tpName?.length === 0) {
      isError= true
      errors.push(getLocalizedValue(selectedLanguage,'t_tp_name_empty'))
    }
  }
  return { isTPNameError: isError, errors: errors }
}

export  function getScopeDataObject(startYear, yearIncrement, tpNumber, nzcScopeId) {
  return {
    ScopeId: nzcScopeId,
    targetYear: startYear,
    targetYearIncrement: yearIncrement,
    percentage: 0,
    targetPathwayNumber: tpNumber
  }
}

export function getMissingYearsReduction(baselineYear, firstTargetYear, scopeTarget){
  let startYear = baselineYear
  const missingScopeTargets = []
  let yearIncrement = 1
  while(startYear < firstTargetYear) {
    if (startYear === YEAR_2030 || startYear === targetPathwaysMilestoneYears.year2035){
      yearIncrement = 5
    } else if (startYear === targetPathwaysMilestoneYears.year2040){
      yearIncrement = 10
    }
    missingScopeTargets.push(getScopeDataObject(startYear, yearIncrement, scopeTarget[0].targetPathwayNumber))
    startYear += yearIncrement
  }
  return missingScopeTargets
}

export function getScopePathwayTotal(currentScopeTPData){
  let filteredScopeTP = currentScopeTPData.filter(scopeTP => !!scopeTP.percentage)
  const scopeTPPercentages=filteredScopeTP.map(scopeTP=>scopeTP.percentage)
  let totalScopeTPPercentages = 0
  if(scopeTPPercentages && scopeTPPercentages.length > 0) {
    totalScopeTPPercentages = scopeTPPercentages.reduce((previousValue, currentValue) => previousValue + currentValue)
  }
  return totalScopeTPPercentages
}

export function getCumulativeInputElement(targetFormItemsRef, inputFieldName) {
  return  targetFormItemsRef?.current.querySelector(`#cumulative-input-${inputFieldName}`)
}

export function getEventValue(blurEvent){
  let value = blurEvent.target.value
  value = value.replace('%.', '')
  if (!value) {
    value = '0%'
  }
  return value
}

export function onBlurAction (blurEvent, scopeParams, targetFormItemsRef, updateValidateTotalReductionState, updateTargetPathwayFormState, disabledRef) {
  const cumulativeInputElement =getCumulativeInputElement(targetFormItemsRef, scopeParams.inputFieldName)
  blurEvent.target.value = getEventValue(blurEvent)
  let fieldValue = scopeParams.selectedScopeTP.percentage
  let totalReductions = getScopePathwayTotal(scopeParams.selectedScopeTPData)
  let diffValue = fieldValue
  if(fieldValue && totalReductions >= 100) {
    diffValue = 100 - (totalReductions - fieldValue)
    scopeParams.selectedScopeTP.percentage = diffValue
    scopeParams.selectedScopeTPData[scopeParams.scopeTPIndex] = scopeParams.selectedScopeTP
    updateValidateTotalReductionState(true)
    updateTargetPathwayFormState({ selectedScopeTPData: scopeParams.selectedScopeTPData })
    disabledRef.current.show(blurEvent, cumulativeInputElement)
  }
}

export function preventEvent(event) {
  event.preventDefault()
  event.stopPropagation()
}

export function getScopeTPName(tabIndex) {
  return(`tp${tabIndex+1}`)
}

export  function tpIconEditAction(event, tabIndex, updateValidateTotalReductionStateValue, targetPathwayFormState, updateScopeTargetActions, updateTargetPathwayFormState, filterAndCreateScopeData) {
  updateValidateTotalReductionStateValue(true)
  const selectedTP=getScopeTPName(tabIndex)
  let selectedTPData=targetPathwayFormState[`scopeTP${tabIndex+1}`]
  selectedTPData=selectedTPData.map(filterAndCreateScopeData)
  preventEvent(event)
  updateScopeTargetActions(SCOPE_TARGET_MODE.edit, tabIndex, selectedTP)
  updateTargetPathwayFormState({selectedScopeTPData:[...selectedTPData]})
}

export const returnHeader = (model) => {
  switch(model) {
  case modelsOptions.createNewModel: return I18n('t_create_new_model')
  case modelsOptions.openModel: return I18n('t_open_model')
  case modelsOptions.duplicateModel: return I18n('t_duplicate_model')
  case modelsOptions.exportConfigurationData: return I18n('t_export_config_data')
  case modelsOptions.importConfigurationData: return I18n('t_import_config_data')
  default: return
  }
}

const GRAPH_NAME_ASSET_ACTIONS_COUNT = 'assetActionsCount'

export const displayAssetActionsCount = {
  id: 'displayAssetActionsCount',
  afterDatasetsDraw: function(chart) {
    const ctx = chart.ctx
    chart.data.datasets.forEach(function (dataset, i) {
      const meta = chart.getDatasetMeta(i)
      if (!meta.hidden && meta.type==='bubble' && dataset.graphName===GRAPH_NAME_ASSET_ACTIONS_COUNT) {
        ctx.fillStyle = CSS_VARIABLES.white
        const fontSize = 12
        const fontStyle = 'normal'
        const fontFamily = CSS_VARIABLES.fontFamilyBase
        ctx.font = fontString(fontSize, fontStyle, fontFamily)
        ctx.textAlign = 'center'
        ctx.textBaseline = 'middle'
        const position = meta.data[0]
        ctx.fillText(dataset.actionsCount, position.x, position.y)
      }
    })
  }
}

function getAssetActionsCount(assetNzcDataHavingActions, year, modelSettings) {
  let actionsCount = 0
  if (assetNzcDataHavingActions && year && modelSettings) {
    const assetNzcActionsForYear = assetNzcDataHavingActions.filter(action => action.year === year)
    if (modelSettings.agreedActions && modelSettings.applicableActions) {
      assetNzcActionsForYear.forEach(assetNzcAction => {actionsCount += (assetNzcAction.agreedActions + assetNzcAction.applicableActions)})
    } else if(modelSettings.agreedActions){
      assetNzcActionsForYear.forEach(assetNzcAction => {actionsCount += assetNzcAction.agreedActions})
    } else if(modelSettings.applicableActions){
      assetNzcActionsForYear.forEach(assetNzcAction => {actionsCount += assetNzcAction.applicableActions})
    }
  }
  return actionsCount
}

export function createActionsCountGraphDataSets(targetPathways, assetId, selectedLanguage, modelSettings, intensityValue) {
  const currentYear = new Date().getFullYear()
  if (!targetPathways) {
    return null
  }
  const actionsCountGraphDataSets=[]
  let selectedAssetData = targetPathways.assetGraphData.filter(assetData => assetData.assetId === Number(assetId))[0]
  const assetNzcDataHavingActions = selectedAssetData?.graphData.filter(graphData => graphData.agreedActions > 0 || graphData.applicableActions > 0).sort((a, b) => a.year - b.year)
  let avgEstimatedSavingsIntensity
  if(intensityValue === assetsOrderByColumnChange.energyIntensity){
    avgEstimatedSavingsIntensity = 'weightedAverageEstimatedSavingsEnergyIntensity'
  } else {
    avgEstimatedSavingsIntensity = 'weightedAverageEstimatedSavingsCarbonIntensity'
  }
  assetNzcDataHavingActions?.forEach(nzcData => {
    if (nzcData.year >= currentYear) {
      const actionsCount=getAssetActionsCount(assetNzcDataHavingActions, nzcData.year, modelSettings)
      if(actionsCount > 0){
        actionsCountGraphDataSets.push({
          ...getDatasetForBubbleChart(),
          data: [{
            x: nzcData.year,
            y: setMinToZeroForVariousNzcLines(targetPathways.graphData?.find(data => data.year === nzcData.year)[avgEstimatedSavingsIntensity]),
            r: 11,
          }],
          backgroundColor: CSS_VARIABLES.green,
          borderColor: CSS_VARIABLES.green,
          pointStyle: 'circle',
          language: selectedLanguage,
          clip: false,
          actionsCount: actionsCount,
          graphName: GRAPH_NAME_ASSET_ACTIONS_COUNT,
          actionYear: nzcData.year,
          order: 1
        })
      }
    }
  })
  return actionsCountGraphDataSets.length > 0 ? actionsCountGraphDataSets : null
}

// nzc action overlay
const annotationShadow = {
  type: 'point',
  backgroundColor: CSS_VARIABLES.green,
  backgroundShadowColor: CSS_VARIABLES.green,
  borderColor: CSS_VARIABLES.white,
  borderWidth: 2,
  scaleID: 'y',
  shadowBlur: 17,
  radius: 14,
  shadowOffsetX: -7,
  shadowOffsetY: 3,
  xValue: 0,
  yValue: 0
}

export function actionOverlayHideAction(updateNzcActionOverlayState, nzcGraphRef) {
  updateNzcActionOverlayState({showNzcActionOverlay: 'none'})
  const chart=nzcGraphRef.current?.getChart()
  if(chart) {
    chart.options.plugins.annotation.annotations = {}
    chart.update()
  }
}

export function assetChartActionHandler(activeElements, chart, updateNzcActionOverlayState, assetId, defaultScopeId) {
  const datasetIndex = activeElements[0].datasetIndex
  const dataset = chart.config.data.datasets[datasetIndex]
  const annotations = chart.options.plugins.annotation.annotations
  const datasets = chart.config?.data?.datasets
  const actionYearDataset = datasets[datasetIndex]
  if (Object.keys(annotations).length > 0) {
    if (annotationShadow.datasetIndex !== datasetIndex) {
      annotationShadow.xValue = dataset.data[0].x
      annotationShadow.yValue = dataset.data[0].y
      chart.options.plugins.annotation.annotations = {annotationShadow}
      annotationShadow.datasetIndex = datasetIndex
    }
  } else {
    annotationShadow.xValue = dataset.data[0].x
    annotationShadow.yValue = dataset.data[0].y
    chart.options.plugins.annotation.annotations = {annotationShadow}
    annotationShadow.datasetIndex = datasetIndex
  }
  chart.update()
  updateNzcActionOverlayState({
    showNzcActionOverlay: 'block',
    actionYear: actionYearDataset.actionYear,
    assetId,
    defaultScopeId,
    actionOverlayPageNumber: 0
  })
}

export const getStaticIntensityValueFromDefault = (intensity) => {
  return intensity === assetsOrderByColumnChange.energyIntensity ? 'Energy' : 'Carbon'
}