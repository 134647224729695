import {formatPercentage, formatValue, getDivisorForTonneOrMegawatt, getLocalizedValue, getUtilityUnits} from '../../../utils/helpers/Helper'
import downArrowRedImage from '../../../resources/images/chart/down-arrow-red.svg'
import downArrowGreenImage from '../../../resources/images/chart/down-arrow-green.svg'
import upArrowGreenImage from '../../../resources/images/chart/up-arrow-green.svg'
import upArrowRedImage from '../../../resources/images/chart/up-arrow-red.svg'
import arrowImage from '../../../resources/images/icon/rectangle.svg'
import {monthListShort, SCOPES_MESSAGE_KEYMAP, ScopeTypes, SPLIT_TYPES_MESSAGE_KEYMAP, SplitTypes, UtilityTypes, WASTE} from '../../../utils/helpers/Constants'
import {
  formatNumWOneDecimal,
  roundAndFormattedVarianceWithOneDP,
  trucateNumWOneDecimal
} from '../../../services/common/formatter'

function getFundTooltipHeaderScopeContent(barCharTooltipData, dataSelectionFilterKey, selectedLanguage, selectedUtility, isFundView) {
  if(dataSelectionFilterKey===ScopeTypes.all || !barCharTooltipData || !dataSelectionFilterKey){
    return null
  }

  const divisor = getDivisorForTonneOrMegawatt(selectedUtility)
  const monthlyTotalAll=barCharTooltipData?.barChartAllTotalEstimated
  let monthlyTotalInTonne=monthlyTotalAll/divisor

  monthlyTotalInTonne=formatNumWOneDecimal(selectedLanguage, monthlyTotalInTonne)
  let monthlyTotalPercentage=(barCharTooltipData.barChartTotalEstimated*100)/monthlyTotalAll
  monthlyTotalPercentage=trucateNumWOneDecimal(monthlyTotalPercentage)

  let dataSelectionMessageKeyMap={}
  if(isScopeSelected(dataSelectionFilterKey)){
    dataSelectionMessageKeyMap=SCOPES_MESSAGE_KEYMAP
  } else if(isSplitTypeSelected(dataSelectionFilterKey)){
    dataSelectionMessageKeyMap=SPLIT_TYPES_MESSAGE_KEYMAP
  }

  const titleMessageKey = getLocalizedValue(selectedLanguage, dataSelectionMessageKeyMap[dataSelectionFilterKey])
  const monthName = monthListShort[barCharTooltipData.barChartMonthNumber]

  let value=`${monthlyTotalPercentage}% of ${monthName} total (${monthlyTotalInTonne} ${getUtilityUnits(true,selectedUtility)})`
  if(isFundView){
    value=`(${monthlyTotalPercentage}% of ${monthName} total) ${monthlyTotalInTonne} ${getUtilityUnits(true,selectedUtility)}`
  }
  return {title: titleMessageKey, value}
}

export const FundPerformanceChartToolTip = (context) => {
  let data = FundPerformanceChartAdapter(context, true)
  tooltipHandler(data, true, true)
}

export function FundPerformanceChartAdapter(context, isFundView) {
  const { chart, tooltip } = context

  const firstChartDatapoint=tooltip.dataPoints?.[0]
  const chartConfig=chart.config?._config

  const selectedUtility = chartConfig.utility
  const dataIndex = firstChartDatapoint.dataIndex
  const filterKeyIndexes=firstChartDatapoint?.dataset?.filterKeyIndexes

  const chartBars = selectedUtility === WASTE? chartConfig?.fundPerformanceTargetLines?.[0]: chartConfig?.fundPerformanceTargetLines?.[filterKeyIndexes?.dataSelectionIndex]
  const chartBar = chartBars?.[dataIndex]
  const hasTarget = chartBar?.hasTarget
  const carbonMonthlyDataAllMain = chartConfig.carbonMonthlyDataAllMain
  const chartBarAll = carbonMonthlyDataAllMain?.[dataIndex]
  const isGapFillingSelected = chart.config?._config?.isGapFillingSelected

  if (tooltip._active.length < 1 || !chartBar) {
    return {
      showTooltip: false,
      chart: chart,
    }
  }

  let tooltipData = []
  const divisor = getDivisorForTonneOrMegawatt(selectedUtility)
  if (selectedUtility !== WASTE) {
    let varianceContent
    if(isGapFillingSelected){
      let gapFilledVarianceValue, gapFilledVariancePercentage
      if(hasTarget){
        tooltipData.push({title: 't_target', percentage: '', value: chartBar['target']}) // 'target' is already converted in the getTarget function
        const target = selectedUtility !== UtilityTypes.Water ? chartBar['target'] * 1000 : chartBar['target'] // 'target' is converted that's why * 1000
        gapFilledVarianceValue = chartBar['totalEstimated'] - target
        gapFilledVariancePercentage = target !== 0 ? (chartBar['totalEstimated'] - target) / target : 0
      } else {
        tooltipData.push({title: 't_previous_period', percentage: '', value: chartBar['previousTotalEstimated'] / divisor})
        gapFilledVarianceValue = chartBar['totalEstimated'] - chartBar['previousTotalEstimated']
        gapFilledVariancePercentage = chartBar['previousTotalEstimated'] !== 0 ? (chartBar['totalEstimated'] - chartBar['previousTotalEstimated']) / chartBar['previousTotalEstimated'] : 0
      }
      tooltipData.push({title: 't_this_period', percentage: '', value: chartBar['totalEstimated'] / divisor})
      tooltipData.push({title: 't_actual', percentage: '', value: chartBar['actual'] / divisor})
      tooltipData.push({title: 't_gap_Filled', percentage: chartBar?.['filledData'], value: chartBar['estimated'] / divisor })
      
      varianceContent= gapFilledVariancePercentage !== 0 ? 
        {title: 't_variance', percentage: gapFilledVariancePercentage, value: gapFilledVarianceValue / divisor} :
        {title: 't_variance', percentage: '', value: gapFilledVarianceValue / divisor}
    } else {
      // gap-fill off
      tooltipData.push({title: 't_previous_period', percentage: '', value: chartBar['previousActual']}) // 'previousActual' is already converted in the getPreviousActual function
      tooltipData.push({title: 't_this_period', percentage: '', value: chartBar['actual'] / divisor})
      const previousActual = selectedUtility !== UtilityTypes.Water ? chartBar['previousActual'] * 1000 : chartBar['previousActual'] // 'previousActual' is converted that's why * 1000
      const varianceAbsolute = chartBar['actual'] - previousActual
      const variancePercentage = previousActual !== 0 ? (chartBar['actual'] - previousActual) / previousActual : 0
      varianceContent = variancePercentage !== 0 ? {
        title: 't_variance',
        percentage: variancePercentage,
        value: varianceAbsolute / divisor
      } : {
        title: 't_variance',
        percentage: '',
        value: varianceAbsolute / divisor
      }
    }
    tooltipData.push(varianceContent)
  } else {
    tooltipData.push({title: 't_previous_period', value: chartBar['previousRecycled'] })
    tooltipData.push({ title: 't_this_period', value: chartBar['recycled'] })
    tooltipData.push({title: 't_variance', percentage: chartBar['variance'], value: 0 - (chartBar['previousRecycled'] - chartBar['recycled'])})
  }

  const barCharTooltipData={
    barChartAllTotalEstimated: chartBarAll?.totalEstimated,
    barChartTotalEstimated: chartBar.totalEstimated,
    barChartMonthNumber: chartBar.startMonth
  }

  return {
    showTooltip: true,
    chart: chart,
    tooltip: tooltip,
    selectedLanguage: tooltip.dataPoints[0].dataset.selectedLanguage,
    selectedUtility: selectedUtility,
    hasTarget: hasTarget,
    tooltipData: tooltipData,
    tooltipHeaderScopeContent: getFundTooltipHeaderScopeContent(barCharTooltipData, filterKeyIndexes?.dataSelectionFilterKey, tooltip.dataPoints[0]?.dataset?.selectedLanguage, selectedUtility, isFundView),
    dataSelectionFilterKey: filterKeyIndexes?.dataSelectionFilterKey,
    isGapFillingSelected
  }
}

function getTooltipHeader(titleLines, img, tooltipHeaderScopeContent, showScopeHeaderSection) {
  const header = document.createElement('div')
  header.setAttribute('class', 'tooltip-header')
  if(tooltipHeaderScopeContent && showScopeHeaderSection){
    const headerScopeSection = document.createElement('div')
    headerScopeSection.setAttribute('class', 'header-scope-section')
    const headerTitle = document.createElement('span')
    headerTitle.setAttribute('class', 'header-scope-title')
    headerTitle.innerText=tooltipHeaderScopeContent.title
    const headerValue = document.createElement('span')
    headerValue.setAttribute('class', 'header-scope-value')
    headerValue.innerText=tooltipHeaderScopeContent.value
    headerScopeSection.appendChild(headerTitle)
    headerScopeSection.appendChild(headerValue)
    header.appendChild(headerScopeSection)
  }
  const targetTypeSection = document.createElement('div')
  targetTypeSection.setAttribute('class', 'header-target-type-section')
  titleLines.forEach((title) => {
    const span = document.createElement('span')
    span.setAttribute('class', 'font-bold')
    span.innerText = title
    targetTypeSection.appendChild(img)
    targetTypeSection.appendChild(span)
  })
  header.appendChild(targetTypeSection)
  return header
}
export const AssetChartToolTip = (context) => {
  let data = AssetPerformanceChartAdapter(context)
  tooltipHandler(data, false, true)
}

export function AssetPerformanceChartAdapter(context) {
  const { chart, tooltip } = context

  const showTooltip = tooltip._active?.length > 0 && chart.data?.datasets[tooltip._active[0]?.datasetIndex]
  const tooltipDataset=tooltip.dataPoints?.[0].dataset
  const dataIndex = tooltip.dataPoints[0]?.dataIndex
  const dataSelectionIndex=tooltipDataset?.filterKeyIndexes?.dataSelectionIndex
  const chartConfig=chart.config?._config

  const targetLineDataset = chartConfig?.targetLineDatasets?.[dataSelectionIndex]
  const barChartData = targetLineDataset?.[dataIndex]
  const barChartDataAll = chartConfig?.utilityConsumptionsAll?.[dataIndex]
  const isGapFillingSelected = chart.config?._config?.isGapFillingSelected

  if (!showTooltip || !barChartData) {
    return {showTooltip: false, chart: chart,}
  }

  let hasTarget = chartConfig?.options?.isTargetAvailable
  let selectedUtility = barChartData?.utilityName
  let tooltipData = []
  const divisor = getDivisorForTonneOrMegawatt(selectedUtility)

  if (selectedUtility !== WASTE) {
    if(isGapFillingSelected){
      getAssetGapfillingTooltipData(hasTarget, tooltipData, barChartData, divisor)
    } else {
      // gap-fill OFF
      getAssetTooltipData(tooltipData, barChartData, divisor)
    }
  } else {
    tooltipData.push({title: 't_previous_period', value: barChartData['previous']})
    tooltipData.push({ title: 't_this_period', value: barChartData['current'] })
    tooltipData.push({title: 't_variance', percentage: barChartData['targetDifference'], value: typeof barChartData['variance'] === 'number' ? barChartData['variance'] : '-'})
  }

  const barCharTooltipData={
    barChartAllTotalEstimated: barChartDataAll?.current, //current=currentAbsolute
    barChartTotalEstimated: barChartData.current,
    barChartMonthNumber: barChartData.monthNumber
  }

  return {
    showTooltip: true,
    chart: chart,
    tooltip: tooltip,
    selectedLanguage: tooltip.dataPoints[0]?.dataset?.selectedLanguage,
    selectedUtility: selectedUtility,
    hasTarget: hasTarget,
    tooltipData: tooltipData,
    tooltipHeaderScopeContent: getFundTooltipHeaderScopeContent(barCharTooltipData, tooltipDataset.filterKeyIndexes?.dataSelectionFilterKey, tooltip.dataPoints[0]?.dataset?.selectedLanguage, selectedUtility),
    dataSelectionFilterKey: tooltipDataset?.filterKeyIndexes?.dataSelectionFilterKey,
    isGapFillingSelected
  }
}

function getAssetGapfillingTooltipData(hasTarget, tooltipData, barChartData, divisor) {
  let gapFilledVarianceValue
  let gapFilledVariancePercentage
  if(hasTarget){
    tooltipData.push({title: 't_target', percentage: '', value: barChartData['target'] / divisor})
    gapFilledVarianceValue = barChartData['totalEstimated'] - barChartData['target']
    gapFilledVariancePercentage = barChartData['target'] !== 0 ? (barChartData['totalEstimated'] - barChartData['target'] ) / barChartData['target']  : 0
  } else {
    tooltipData.push({title: 't_previous_period', percentage: '', value: barChartData['previous'] / divisor})
    gapFilledVarianceValue = barChartData['totalEstimated'] - barChartData['previous']
    gapFilledVariancePercentage = barChartData['previous'] !== 0 ? (barChartData['totalEstimated'] - barChartData['previous']) / barChartData['previous'] : 0
  }
  tooltipData.push({title: 't_this_period', percentage: '', value: barChartData['totalEstimated'] / divisor})
  tooltipData.push({title: 't_actual', percentage: '', value:  (barChartData['actualAboveTarget'] + barChartData['actualBelowTarget']) / divisor})
  tooltipData.push({title: 't_gap_Filled', percentage: barChartData['filledDataPercentage']*100, value: barChartData['gapFilled'] / divisor })
  let varianceContent= {title: 't_variance', percentage: gapFilledVariancePercentage !== 0 ? gapFilledVariancePercentage : '', value: gapFilledVarianceValue / divisor}
  tooltipData.push(varianceContent)
}

function getAssetTooltipData(tooltipData, barChartData, divisor) {
  tooltipData.push({title: 't_previous_period', percentage: '', value: barChartData['previousActual'] / divisor})
  tooltipData.push({title: 't_this_period', percentage: '', value: (barChartData['actualAboveTarget'] + barChartData['actualBelowTarget'])/ divisor})
  const actual = barChartData['actualAboveTarget'] + barChartData['actualBelowTarget']
  const varianceValue = actual - barChartData['previousActual']
  const variancePercentage = barChartData['previousActual'] !== 0 ? (actual - barChartData['previousActual']) / barChartData['previousActual'] : 0
  let varianceContent =  {title: 't_variance', percentage: variancePercentage !== 0 ? variancePercentage : '', value: varianceValue / divisor}
  tooltipData.push(varianceContent)
}

function isScopeSelected(dataSelectionFilterKey) {
  return [ScopeTypes.scope1, ScopeTypes.scope2, ScopeTypes.scope3].includes(dataSelectionFilterKey)
}

function isSplitTypeSelected(dataSelectionFilterKey) {
  return [SplitTypes.landlord, SplitTypes.tenant].includes(dataSelectionFilterKey)
}

function getTextColourClass(selectedUtility, value) {
  let colourText
  if (selectedUtility !== WASTE) {
    colourText = value > 5 ? 'red-text' : value < -5 ? 'green-text' : 'grey-text'
  } else {
    colourText = value > 5 ? 'green-text' : value < -5 ? 'red-text' : 'grey-text'
  }
  return colourText
}

function getPlusOrMinusSign(percentageValue) {
  let sign
  if (percentageValue > 5) {
    sign = '+'
  } else if (percentageValue < -5) {
    sign = '-'
  } else {
    sign = ''
  }
  return sign
}

function getOrCreateTooltip(data) {
  let tooltipEl = data.chart.canvas.parentNode.querySelector('div')

  if (!tooltipEl) {
    tooltipEl = document.createElement('div')
    tooltipEl.style.opacity = 1
    tooltipEl.setAttribute('class', 'bar-month-tooltip')

    tooltipEl.innerHTML =
        `<img src="${arrowImage}" class='tooltip-arrow'/>` +
        '<div class="bar-month-tooltip-content"></div>'

    data.chart.canvas.parentNode.appendChild(tooltipEl)
  }

  return tooltipEl
}

function formatAndAddValue(item, data, spanValue, isBiggerUnit, sign) {
  if (item.title === 't_gap_Filled') {
    if (isScopeSelected(data?.dataSelectionFilterKey) || isSplitTypeSelected(data?.dataSelectionFilterKey)) {
      spanValue.innerText += `(${trucateNumWOneDecimal(item.percentage)}%) ${formatValue(item.value, data.selectedUtility, item.title, isBiggerUnit)}`
    } else {
      spanValue.innerText += formatValue(item.value, data.selectedUtility, item.title, isBiggerUnit)
    }
  } else if(item.title === 't_variance'){
    spanValue.innerText += `${sign === '+' ? sign : ''}${formatValue(item.value, data.selectedUtility, item.title, isBiggerUnit)}`
  } else {
    spanValue.innerText += formatValue(item.value, data.selectedUtility, item.title, isBiggerUnit)
  }
}

function getSpanValueNode() {
  const spanValue = document.createElement('span')
  spanValue.setAttribute('class', 'target-value')
  return spanValue
}

function addScopeOrSplitValue(data, targetContainer, valueContainer) {
  if (data.tooltipHeaderScopeContent) {
    const targetWrapperBlock = ('<div class="target-wrapper">' + `<span class="target-title">${data.tooltipHeaderScopeContent?.title}</span>` + '</div>')
    targetContainer.innerHTML += targetWrapperBlock
    const spanValue = getSpanValueNode()
    spanValue.innerText = data.tooltipHeaderScopeContent?.value
    valueContainer.appendChild(spanValue)
  }
}

function getFormattedPercentageValue(item) {
  let percentageValue = ''
  if (item.percentage !== '' && item.percentage !== undefined && item.percentage !== null) {
    percentageValue = formatPercentage(item.percentage, item.title)
  }
  return percentageValue
}

function addTooltipValueItems(data, targetContainer, isBiggerUnit, valueContainer, isFundView, selectedUtility) {
  addScopeOrSplitValue(data, targetContainer, valueContainer)

  data?.tooltipData.forEach((item) => {
    let percentageClass = ''
    let boldClass = ''
    let percentageValue = getFormattedPercentageValue(item)
    const sign= getPlusOrMinusSign(percentageValue)
    
    if (item.title === 't_variance') {
      percentageClass = getTextColourClass(data.selectedUtility, percentageValue)
      percentageValue = (percentageValue !== null && percentageValue !== undefined && percentageValue !== '') ? Math.abs(Number(percentageValue)).toLocaleString(data.selectedLanguage) : ''
    }
    const targetTitleClass = `${boldClass} target-title`
    let targetWrapperBlock = ('<div class="target-wrapper">' + `<span class="${targetTitleClass} ${percentageClass}">${getLocalizedValue(data.selectedLanguage, item.title?.toLowerCase())}</span>` + '</div>')

    targetContainer.innerHTML += targetWrapperBlock
    const spanValue = getSpanValueNode()
    const percentageValueFormatted = (percentageValue !== null && percentageValue !== undefined && percentageValue !== '') ? `(${sign}${roundAndFormattedVarianceWithOneDP(Math.abs(item.percentage*100)).toLocaleString(data.selectedLanguage)}%) ` : ''
    if (item.title === 't_variance') {
      spanValue.innerText = percentageValueFormatted
    }

    formatAndAddValue(item, data, spanValue, isBiggerUnit, sign)

    if (item.title === 't_variance') {
      spanValue.setAttribute('class', percentageClass)
    }
    valueContainer.appendChild(spanValue)
  })
}

export const tooltipHandler = (data, isFundView, isBiggerUnit) => {
  // Tooltip Element
  // show custom tooltip only for missing data and landfill data
  if (data.showTooltip) {
    const tooltipEl = getOrCreateTooltip(data)
    // Hide if no tooltip
    if (data.tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0
      return
    }
    // Set Text
    if (data.tooltip.body) {
      const titleLines = []
      let varianceData = data.tooltipData.filter((item) => item.title === 't_variance')[0]
      let varianceValue = varianceData?.percentage
      // Target beaten(Clap): Better performance
      // Target missed(thumbDown) : Not Better Performance
      const img = document.createElement('img')
      if (varianceValue > 0) {
        if (data.selectedUtility !== WASTE) {
          titleLines.push((data.hasTarget && data?.isGapFillingSelected) ? getLocalizedValue(data.selectedLanguage, 't_target_missed') : getLocalizedValue(data.selectedLanguage, 't_funds_performance_cost_higher'))
          img.src = upArrowRedImage
        } else {
          titleLines.push(data.hasTarget ? getLocalizedValue(data.selectedLanguage, 't_recycling_target_beaten') : getLocalizedValue(data.selectedLanguage, 't_more_recycled_than_previous_year'))
          img.src = upArrowGreenImage
        }
      } else {
        if (data.selectedUtility !== WASTE) {
          titleLines.push((data.hasTarget && data?.isGapFillingSelected) ? getLocalizedValue(data.selectedLanguage, 't_target_beaten') : getLocalizedValue(data.selectedLanguage, 't_funds_performance_cost_lower'))
          img.src = downArrowGreenImage
        } else {
          titleLines.push(data.hasTarget ? getLocalizedValue(data.selectedLanguage, 't_recycling_target_missed') : getLocalizedValue(data.selectedLanguage, 't_less_recycled_than_previous_year'))
          img.src = downArrowRedImage
        }
      }

      // Creating Tooltip Header
      const header = getTooltipHeader(titleLines, img, data.tooltipHeaderScopeContent, false)
      const dataContainer = document.createElement('div')
      const targetContainer = document.createElement('div')
      const valueContainer = document.createElement('div')
      const sectionDivider = document.createElement('div')

      dataContainer.setAttribute('class', 'data-container')
      targetContainer.setAttribute('class', 'target-container')
      valueContainer.setAttribute('class', 'value-container')
      sectionDivider.setAttribute('class', 'section-divider')

      addTooltipValueItems(data, targetContainer, isBiggerUnit, valueContainer, isFundView, data?.selectedUtility)

      dataContainer.appendChild(targetContainer)
      dataContainer.appendChild(sectionDivider)
      dataContainer.appendChild(valueContainer)
      const tableRoot = tooltipEl.querySelector('div')

      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove()
      }
      // Add new children
      tableRoot.appendChild(header)
      tableRoot.appendChild(dataContainer)
    }
    getTooltipPosition(data, tooltipEl, isFundView)
  } else if (data.chart.canvas.parentNode.querySelector('div')) {
    data.chart.canvas.parentNode.querySelector('div').remove()
  }
}

function getTooltipPosition(data, tooltipEl, isFundView) {
  let viewport = data.chart.canvas
  let viewportPosition = viewport.getBoundingClientRect()
  const tooltipElPosition = tooltipEl.getBoundingClientRect()

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1
  tooltipEl.style.position = 'absolute'
  tooltipEl.style.top = isFundView ? viewportPosition.top + window.scrollY + data.tooltip.caretY + 'px' : data.tooltip.caretY + 'px'
  tooltipEl.style.padding = data.tooltip.padding + 'px ' + data.tooltip.padding + 'px'
  tooltipEl.style.pointerEvents = 'none'

  if (viewportPosition.width - data.tooltip._eventPosition.x < tooltipElPosition.width) {
    tooltipEl.style.right = isFundView ? viewportPosition.right - window.scrollX - data.tooltip.caretX + 'px' : viewportPosition.width - data.tooltip.caretX + 'px'
  } else {
    tooltipEl.style.left = isFundView ? viewportPosition.left + window.scrollX + data.tooltip.caretX + 'px' : data.tooltip.caretX + 'px'
  }
}