const toastInitState = {
  toast: '',
  title: '',
  summary: ''
}

const toastReducer = (state, action) => {
  switch (action.type) {
  case 'SUCCESS':
    return {
      ...state,
      toast: 'success',
      title: action.title,
      summary: action.summary
    }
  case 'ERROR':
    return {
      ...state,
      toast: 'error',
      title: action.title,
      summary: action.summary
    }
  case 'INFO':
    return {
      ...state,
      toast: 'info',
      title: action.title,
      summary: action.summary
    }
  default:
    return state
  }
}
  
export { toastInitState, toastReducer }
  