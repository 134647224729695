import React, {useState} from 'react'
import './EditAsset.scss'
import {
  useParams
} from 'react-router-dom'

const EditAsset = () => {
  const { assetId } = useParams()
  return(
    <div className='edit-asset-page min-width-1280 full-height-minus-header'>
      <h1 style={{textAlign: 'center'}}>
        {assetId}
      </h1>
    </div>
        
  )
}

export default EditAsset