
import PropTypes from 'prop-types'
import './MeterGraphMonthsLegend.scss'
import {monthList, monthListShort} from '../../../../../utils/helpers/Constants'
import {Text} from '../../../../atomic'

const MeterGraphMonthsLegend = ({meterData}) => {
  const {gapsPerMonth}= meterData
  const noOfMonths=gapsPerMonth.length
  const gridColumns = {gridTemplateColumns: `repeat(${noOfMonths}, 1fr)`}

  let fyStartMonth = meterData.clientFundStartMonth
  let qtrMonthMarker

  const getQtrBorderClass = (index) => {
    let qtrBorderClass = ''
    if ((index + 1) === fyStartMonth || (index + 1) === (qtrMonthMarker + 3)) {
      qtrMonthMarker = index + 1
      qtrBorderClass = 'qtr-border'
    }
    return qtrBorderClass
  }

  function getLegendTopCols() {
    return gapsPerMonth.map((gap, index)=> <span className={`months-legend__col-top qtr-top ${(index + 1 === gapsPerMonth.length)? 'qtr-border-top-r' : ''}`}  key={gap.position} />)
  }

  function getMonthsLegendCols() {
    return gapsPerMonth.map((gap, index)=>{
      let monthNames = gapsPerMonth.length < 4 ? monthList : monthListShort
      return <Text content={'t_' + monthNames[gap.month].toLowerCase()} className={`months-legend__col-month ${getQtrBorderClass(index)}`} key={gap.position} />
    })
  }

  function getLegendBtmCols() {
    return gapsPerMonth.map((gap, index)=> <span className={`months-legend__col-bottom ${getQtrBorderClass(index)}`} key={gap.position} />)
  }

  function getLegendYearCols() {
    return gapsPerMonth.map((gap, index)=> <div className="months-legend__col-year" key={gap.position}><Text content={(index === 0 || gap.month === 1) ?gap.year: ''} /></div>)
  }

  return (
    <div className={'months-legend'} style={gridColumns}>
      {getLegendTopCols()}
      {getMonthsLegendCols()}
      {getLegendBtmCols()}
      {getLegendYearCols()}
    </div>
  )
}

MeterGraphMonthsLegend.propTypes = {
  meterData: PropTypes.object
}
MeterGraphMonthsLegend.displayName = 'MeterGraphMonthsLegend'

export default MeterGraphMonthsLegend