import React from 'react'
import { Skeleton } from 'primereact/skeleton'
import './FundPerformanceCardSkeleton.scss'

function getKeyFactsSkeleton() {
  return <div className="p-rel fund-performance-card-skeleton-V3">
    <div className="flex justify-content-between align-items-center">
      <Skeleton width="10rem" borderRadius="0" height="1rem" className="mr-5 bg-white"/>
      <Skeleton width="10rem" borderRadius="0" height="1rem" className="mr-5 bg-white"/>
      <Skeleton width="10rem" borderRadius="0" height="1rem" className="mr-5 bg-white"/>
      <Skeleton width="10rem" borderRadius="0" height="1rem" className="mr-5 bg-white"/>
      <Skeleton width="10rem" borderRadius="0" height="1rem" className="mr-5 bg-white"/>
    </div>
  </div>
}

const FundPerformanceCardSkeleton = ({isFundDashboardLayoutV3}) => {
  return <>
    {isFundDashboardLayoutV3 ? getKeyFactsSkeleton() :
      <div className="p-rel fund-performance-card-skeleton">
        <div className="flex justify-content-between">
          <Skeleton width="4rem" borderRadius="0" height="1rem" className="mt-1 mb-3"/>
          <Skeleton width="5rem" borderRadius="0" height=".5rem" className="mt-1 mb-3"/>
        </div>
        <Skeleton width="100%" borderRadius="0" height="0.5rem" className="mt-3 mb-3"/>
      </div>
    }
  </>
}

export default FundPerformanceCardSkeleton