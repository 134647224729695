import React from 'react'
import { Tooltip as PrimeTooltip } from 'primereact/tooltip'
import PropTypes from 'prop-types'
import './Tooltip.scss'

const getCssClass = (className, padding) => {
  let cssClass = ['custom-tooltip']
  if(className) {
    cssClass.push(className)
  }

  if(padding) {
    cssClass.push(padding)
  }

  return cssClass.join(' ')
}

const Tooltip = ({
  children,
  target,
  position,
  className,
  width,
  padding,
  testId,
  autoHide,
  hideDelay,
  ...otherProps
}) => {
  
  return (
    <PrimeTooltip 
      target={target}
      position={position}
      className={getCssClass(className, padding)}
      style={{width: `${width}px`}}
      autoHide={autoHide}
      data-testid={testId}
      hideDelay={hideDelay}
      {...otherProps}
    >
      {children}
    </PrimeTooltip>
  )
}
  
Tooltip.propTypes = {
  target: PropTypes.string.isRequired,
  position: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  padding: PropTypes.oneOf(['small', 'medium', 'large']),
  width: PropTypes.number,
  testId: PropTypes.string,
  autoHide: PropTypes.bool,
  hideDelay: PropTypes.number
}

Tooltip.defaultProps = {
  position: 'top',
  padding: 'small',
  autoHide: true,
}
  
export default Tooltip