import React from 'react'
import {useQuery} from 'react-query'
import {getAssetNzcActionDetails} from '../../../services/funds/nzc-service'
import {noRefetchOnWindowFocus} from '../../../services/common/useQuery-config'
import {QueryKeyPrefix} from '../../helpers/Constants'

const useAssetNzcActionData = (assetId, scopeId, nzcActionYear, assetIntensity) => {
  const queryKey=[QueryKeyPrefix.ASSET_NZC_ACTIONDETAILS, assetId, scopeId, nzcActionYear, assetIntensity]
  const queryConfig={...noRefetchOnWindowFocus, enabled: !!(assetId && scopeId && nzcActionYear && assetIntensity)}
  const {
    data,
    isLoading,
    isError,
    isFetching
  } = useQuery(queryKey, getAssetNzcActionDetails, queryConfig)

  return (
    {
      data,
      isLoading,
      isError,
      isFetching
    }
  )
}

export {useAssetNzcActionData}