import React, { useEffect, useState, useContext, memo, forwardRef, useRef } from 'react'
import './BackNavigation.scss'
import { Button } from 'primereact/button'
import { NavLink, useParams } from 'react-router-dom'
import I18n from '../../../utils/i18n/I18n'
import { createNavURLForFundPages, isNZCModuleAccessible, getUserRole, getLocalizedValue, CachedUserState } from '../../../utils/helpers/Helper'
import DatePicker from '../date-picker/DatePicker'
import { setTooltipForAddressFundName } from '../../../utils/UtilsFunctions'
import {
  ASSET_PAGES,
  ELLIPSIS,
  FUND_VIEWS_PAGES,
  ASSET_DASHBOARD,
  verticalEllipsisJson,
  ASSET_ACTION_PLAN,
  LOCAL_STORAGE_KEYS,
} from '../../../utils/helpers/Constants'
import { FeatureFlagContext } from 'Contexts'
import { VerticalEllipsis } from '../ellipsis-v/VerticalEllipsis'
import { Tooltip } from 'primereact/tooltip'
import { BULK_UPLOAD } from '../../../utils/i18n/constants'
import UseUrlParameterIds from '../../../utils/custom-hooks/UseUrlParameterIds'
import PropTypes from 'prop-types'
import hrLine from '../../../resources/images/icon/rectangle-hr-line.svg'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'
import { useQuery } from 'react-query'
import { GetAssetFunds } from 'services/assets/asset-details-service'
import { noRefetchOnWindowFocus } from 'services/common/useQuery-config'
import { Tag, ButtonWrapper, LinkButton } from 'components/atomic/index'
import OverlayPanel from 'components/atomic/OverlayPanel/OverlayPanel'
import InfoOverlayPanelContent from 'components/atomic/OverlayPanel/OverlayPanelContent/OverlayPanelContent'

const BackNavigation = forwardRef(function BackNavigation(props, ref) {
  const { addAction, exportName, onExportClick, onAddActionButtonClick, period, options, datePickerOnChange, itemTemplate,
    pickerValue, customDatePickerOnClick, pickerOnChange, hideTimePeriodFilter, customTime, disableExportButton, showVerticalEllipsis, bulkUploadConsumptionId, routeVia, isOverlayPanelOpen, v2Flag } = props
  const featureContext = useContext(FeatureFlagContext)
  const featureState = featureContext.featureState
  const { loginState: { userInfo } } = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const [deviceWidth, setDeviceWidth] = useState()
  const textWrapperCssClass = 'text-wrapper'
  const { instanceName, orgName, fundName, assetId } = useParams()
  const urlParameterIds = UseUrlParameterIds({ instanceName, orgName, fundName, assetId })
  const assetName = urlParameterIds?.actualAssetName
  const jvTooltipRef = useRef(null)

  const {
    isFetching: assetFundsDataLoading,
    data: assetFundsData,
  } = useQuery({
    queryKey: ['GetAssetFunds', assetId],
    queryFn: GetAssetFunds,
    retry: false,
    refetchOnWindowFocus: noRefetchOnWindowFocus.refetchOnWindowFocus,
  })

  useEffect(() => {
    setTextTooltip()
  })

  function setTextTooltip() {
    let addressFundNameNavEle = document.querySelector('.back-navigation .nav-address-fund-name')
    if (addressFundNameNavEle) {
      setTooltipForAddressFundName(addressFundNameNavEle, textWrapperCssClass)
    }
  }
  useEffect(() => {
    setDeviceWidth(window.innerWidth)
  }, [deviceWidth])

  const verticalEllipsisKeyValue = {
    ...verticalEllipsisJson,
    'action': {
      ...verticalEllipsisJson['action'],
      'displayBulkUploadButton': true,
      'buttonName': 't_upload_bulk_action_plan',
    },
    'consumption': {
      ...verticalEllipsisJson['consumption'],
      'displayBulkUploadButton': getUserRole() && featureState.sieraplus_consumptionBulkUpload,
    }
  }

  const featureFlagState = (featureState?.sieraplus_consumptionBulkUpload || featureState?.sieraplus_AssetModification)

  const verticalEllipsisCondition = () => {
    switch (props.componentName) {
    case ASSET_DASHBOARD: return { isEnabled: getUserRole() && showVerticalEllipsis && featureFlagState, flag: BULK_UPLOAD.CONSUMPTION }
    case ASSET_ACTION_PLAN: return { isEnabled: getUserRole() && showVerticalEllipsis && featureState.sieraplus_actionPlanBulkUpload, flag: BULK_UPLOAD.ACTION_PLAN }
    default: return { isEnabled: false, flag: false }
    }
  }

  const verticalEllipsis = verticalEllipsisCondition()

  const saveFundToCache = (fundProps) => {
    let orgAndFundNames=CachedUserState.getUserDataFromCache(LOCAL_STORAGE_KEYS.selectedOrgAndFund)
    if(!orgAndFundNames) {
      orgAndFundNames = {}
    }
    orgAndFundNames.fundName = fundProps.fundName
    orgAndFundNames.fundUrlName = fundProps.fundUrlName
    CachedUserState.saveUserDataToCache(LOCAL_STORAGE_KEYS.selectedOrgAndFund, orgAndFundNames)
  }

  const assetFundsTooltipValues = (
    <div className='flex flex-column align-items-start gap-2'>
      {assetFundsData?.map((fund) => (
        <NavLink key={fund.fundId} to={createNavURLForFundPages({ instanceName, orgName, fundName: fund.fundUrlName, fundId: fund.fundId, fundGroupId: fund.fundGroupId})}>
          <LinkButton
            content={fund.fundName}
            onClick={() => saveFundToCache(fund)}
            className='cursor'
          />
        </NavLink>
      ))}
    </div>
  )

  return (
    <div className="back-navigation">
      <div className="justify-content-between flex col-12 back-nav-container">
        <div className='flex gap-2 align-items-center pt-1'>
          <div className={'fund-name-section pr-2'} data-testid='back-navigation-asset-name'>{assetName}</div>
          {!assetFundsDataLoading && assetName && assetFundsData?.length > 1 && 
          <>
            <ButtonWrapper onClick={(e) => jvTooltipRef.current.toggle(e)}>
              <Tag contentKey='Joint Venture' iconLeftId='info_solid' className='jv-tag'/>
            </ButtonWrapper>
            <OverlayPanel
              panelRef={jvTooltipRef}
              className='jv-overlay-panel'
              appendTo='self'
              panelChildren={
                <InfoOverlayPanelContent
                  header='jv_between_funds'
                  panelContent={assetFundsTooltipValues}
                />
              }
            />
          </>}
        </div>
        <div className="flex">
          {!hideTimePeriodFilter && (
            <DatePicker
              value={period}
              options={options}
              onChange={datePickerOnChange}
              itemTemplate={itemTemplate}
              pickerValue={pickerValue}
              pickerOnChange={pickerOnChange}
              customDatePickerOnClick={customDatePickerOnClick}
              dateActive={customTime && false}
            />
          )}
          {addAction && <div className={'nav-add-action'}>
            <Button label={I18n('t_add_action')} className="add-action-button" onClick={() => onAddActionButtonClick('Add')} />
          </div>
          }
          <Tooltip className={'portfolio-comm-tooltip long-text-tooltip'} target=".nav-export" position='top' />
          <div id="export" className='nav-export' style={window.location.pathname.includes('net-zero-carbon') ? { position: 'relative' } : {}} ref={ref}
            data-pr-tooltip={disableExportButton ? getLocalizedValue(selectedLanguage, 't_no_data_to_export') : ''}>
            {exportName ? <Button label={I18n('t_export')} className={v2Flag ? 'export-button' : 'export-btn nav-export-btn'} onClick={onExportClick} disabled={disableExportButton}
              icon={!disableExportButton && routeVia !== ASSET_DASHBOARD && (isOverlayPanelOpen ? 'pi pi-angle-up' : 'pi pi-angle-down')} iconPos={'right'}
            /> : <></>}
          </div>
          {verticalEllipsis.isEnabled &&
            <div className={'ml-3 relative'} id={ELLIPSIS}>
              <VerticalEllipsis
                keyId={bulkUploadConsumptionId}
                routeVia={{ name: assetName?.split(',').shift(), routeVia: props.routeVia }}
                flag={verticalEllipsis.flag}
                bulkUploadKeyValue={verticalEllipsisKeyValue[verticalEllipsis.flag]}
                selectedLanguage={selectedLanguage}
                actionBulkUploadKeyValue={verticalEllipsisKeyValue[verticalEllipsis.flag]}
              />
            </div>
          }
        </div>
      </div>
      <div className="col-12 back-nav-items">
        <ul className="nav-component nav-comp-txt-container">
          <NavLink exact={true} to={createNavURLForFundPages({ instanceName, orgName, fundName, assetId, pathSuffix: FUND_VIEWS_PAGES.dashboard })} activeClassName="link-active">
            <li className="tab-text"> {I18n('t_dashboard')}</li>
          </NavLink>
          <NavLink exact={true} to={createNavURLForFundPages({ instanceName, orgName, fundName, assetId, pathSuffix: FUND_VIEWS_PAGES.performance })} activeClassName="link-active">
            <li className="tab-text">{I18n('t_performance')}</li>
          </NavLink>
          <NavLink exact={true} to={createNavURLForFundPages({ instanceName, orgName, fundName, assetId, pathSuffix: FUND_VIEWS_PAGES.dataQuality })} activeClassName="link-active">
            <li className="tab-text">{I18n('t_data_quality')} </li>
          </NavLink>
          <NavLink exact={true} to={createNavURLForFundPages({ instanceName, orgName, fundName, assetId, pathSuffix: FUND_VIEWS_PAGES.actionPlan })} activeClassName="link-active-grey">
            <li className="tab-text">{I18n('t_action_plan')}</li>
          </NavLink>
          {(featureState?.sieraplus_assetnzc && isNZCModuleAccessible()) &&
            <NavLink exact={true} to={createNavURLForFundPages({ instanceName, orgName, fundName, assetId, pathSuffix: FUND_VIEWS_PAGES.netZeroCarbon })} activeClassName="link-active">
              <li className="tab-text tab-asset-nzc">{I18n('t_net_zero_carbon')}</li>
            </NavLink>
          }
          <NavLink exact={true} to={createNavURLForFundPages({ instanceName, orgName, fundName, assetId, pathSuffix: ASSET_PAGES.passport })} activeClassName="link-active">
            <li className="tab-text">{I18n('t_passport')}</li>
          </NavLink>
        </ul>
        {props.isNewLayout && <img src={hrLine} alt="siera-hr-line" className='hr-line'/> }
      </div>
    </div>
  )
})

BackNavigation.propTypes = {
  v2Flag: PropTypes.bool,
  pickerValue: PropTypes.object,
  customDatePickerOnClick: PropTypes.func,
  pickerOnChange: PropTypes.func,
  hideTimePeriodFilter: PropTypes.bool,
  customTime: PropTypes.bool,
  disableExportButton: PropTypes.bool,
  showVerticalEllipsis: PropTypes.bool,
  bulkUploadConsumptionId: PropTypes.string,
  routeVia: PropTypes.string,
  isOverlayPanelOpen: PropTypes.bool,
  isNewLayout: PropTypes.bool,
}

export default memo(BackNavigation)

