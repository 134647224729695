import React from 'react'
import {Skeleton} from 'primereact/skeleton'
import  '../../../pages/admin/users/users-home/UsersHome.scss'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'

const UserOverviewAssetsSkeleton = () => {
  const dummyArray = Array.from({ length: 4 })

  const bodyTemplate = () => {
    return <Skeleton width={'16rem'}/>
  }

  function assetNameTemplate() {
    return <div className="flex">
      <div className="pr-3">
        <Skeleton size="4rem" className="mr-1"/>
      </div>
      <div className="align-self-center">
        <div className="asset-address mb-2"><Skeleton width={'15rem'}/></div>
        <div className="asset-location"><Skeleton width={'8rem'}/></div>
      </div>
    </div>
  }

  const headerTemplate = () => {
    return <div className="ml-1">
      <Skeleton width={'4rem'}/>
    </div>
  }

  return (
    <div className="custom-skeleton p-rel flex flex-column users-page-skeleton">
      <DataTable value={dummyArray} className="user-asset-grid no-blue">
        <Column field="code" header={headerTemplate()} headerClassName="user-grid-header" body={assetNameTemplate}/>
        <Column field="name" header={headerTemplate()} headerClassName="user-grid-header" body={bodyTemplate}/>
        <Column field="category" header={headerTemplate()} headerClassName="user-grid-header" body={bodyTemplate}/>
      </DataTable>
    </div>
  )
}

export default UserOverviewAssetsSkeleton
