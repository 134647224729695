import React, {useEffect, useState} from 'react'
import {Dialog} from 'primereact/dialog'
import iconErrorType from '../../../resources/images/icon/icon-error-red.svg'
import I18n from '../../../utils/i18n/I18n'
import {getLocalizedValue} from '../../../utils/helpers/Helper'
import './InfoModal.scss'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const InfoModal = (props) => {
  const { loginState: { userInfo } } = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const [visible, setVisible] = useState(props.visibleActiveSurveyDialog)
  
  useEffect(() => {
    setVisible(props.visibleActiveSurveyDialog)
  },[props.visibleActiveSurveyDialog])

  const onHideSurveyActiveModal = () => {
    setVisible(false)
    props.onCloseActiveSurveyDialog()
  }

  const activeSurveyWarningHeader = (
    <div>
      <div className="flex info-text">
        <img className="info-icon" src={iconErrorType} alt={getLocalizedValue(selectedLanguage, 't_warning')} />
        <span>{I18n('t_warning')}</span>
      </div>
    </div>
  )

  const activeSurveyWarningContent = <>
    <div className="info-modal-content">
      <p>{I18n('t_warning_asset_review')}</p>
    </div>
  </>

  return (
    <Dialog 
      header={activeSurveyWarningHeader} 
      className="info-modal" 
      visible={visible}
      onHide={onHideSurveyActiveModal}
      dismissableMask={true}
    >
      {activeSurveyWarningContent}
    </Dialog>
  )
}

export default InfoModal