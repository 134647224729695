import React from 'react'
import './EnergyStarAnalysis.scss'
import ProgressBarWrapper from '../progress-bar-epc-action/ProgressBarWrapper'
import {
  EPS,
  epsRating
} from '../../../../../../utils/helpers/Constants'
import I18n from '../../../../../../utils/i18n/I18n'
import {
  getLocalStorageItem,
  handleLeastValues,
  truncateDecimal,
  unitDateFormatter
} from '../../../../../../utils/helpers/Helper'
import {NavLink} from 'react-router-dom'
import { noDataMessage } from '../../../../../../utils/helpers/MessageUtility'
import PropTypes from 'prop-types'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const EnergyStarAnalysis = (props) => {
  const esData = props?.esData?.data
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const esConnectionStep = userInfo.connectionStep
  const esRating = esData?.energyStarRatingList ? Object.entries(esData.energyStarRatingList) : []
  const esTotalFloorAreaPercentage = esData?.totalFloorAreaPercentage ? handleLeastValues(esData?.totalFloorAreaPercentage) : 0
  const gavCoverage = esData?.currentAssetValuePercentage ? handleLeastValues(esData?.currentAssetValuePercentage) : 0
  const esCertifiedAssetsPercentage = esData?.energyStarCertifiedAssetsPercentage ? truncateDecimal(esData?.energyStarCertifiedAssetsPercentage, 1) : 0
  const noEs = 'No Energy Star'
  const nc = 'n/c'
  const featureState = props.featureState
  const validateLocationUS = getLocalStorageItem('validateLocationUS')?.hasUserAssetInUSOrCanada

  function ratingTemplate(rowData,index) {
    const label = rowData[0] === noEs ? nc : rowData[0]
    return <ProgressBarWrapper percentageData={rowData[1] || 0} label={label}
      bgColor={epsRating[label]} metric={EPS} id={`${EPS}-${epsRating[label]}`} key={index}/>
  }

  function ratingGrid(rating) {
    return rating.length > 0 ? rating.map((es,index) => ratingTemplate(es,index)) : noDataMessage()
  }

  function showManageESIntegrationLink() {
    return featureState.sieraplus_energyStarIntegration && validateLocationUS && esConnectionStep > 0
  }

  function energyStarNavigationHandler() {
    return '/energy-star-integration'
  }

  function getLastRow() {
    const isNewConnection = esConnectionStep === null || esConnectionStep === 0
    return <div className={'es-card-integration'}>
      {showManageESIntegrationLink() &&
          <NavLink data-testid={'es-integration-link'} to={energyStarNavigationHandler}
            className={'es-integration-link mb-0_5'}>{I18n('t_manage_es_integration_link')}</NavLink>
      }
      {esData?.lastSync && esConnectionStep > 4 && <label className={'eps-sync-label'} data-testid={'energy-star-rating-sync'}>
        {I18n('t_ratings_last_sync_on', {0: unitDateFormatter(esData?.lastSync, userInfo.unitSystem, selectedLanguage)})}
      </label>}
      {featureState.sieraplus_energyStarIntegration && isNewConnection && <div className={'es-card-integration'}>
        <NavLink className={'es-integration-link bottom-align'} data-testid={'energy-star-creation-link'} to={energyStarNavigationHandler}>
          {I18n('t_energy_star_set')}
        </NavLink>
      </div>}
    </div>
  }

  return (
    <div className={'eps-analysis default-cursor'} data-testid={'EPS-analysis'}>
      <div className={'mb-3'} data-testid={'assets-score'}>
        <label data-testid={'assets-score-label'}>{I18n('t_assets_with_scores')}</label>
      </div>
      <div className={'coverage flex justify-content-between'} data-testid={'floor-coverage'}>
        <div className={'mb-2'} data-testid={'floor-coverage-left'}>
          <label className={'c-grey'} id={'floor-coverage-left-label'}>{I18n('t_coverage_by_floor_area')}</label><br/>
          <span className={'flex-row-reverse'} aria-labelledby={'floor-coverage-left-label'}>{esTotalFloorAreaPercentage + '%'}</span>
        </div>
        <div className={'mb-2'} data-testid={'floor-coverage-right'}>
          <label className={'c-grey'} id={'floor-coverage-right-label'}>{I18n('t_gav_coverage')}</label><br/>
          <span aria-labelledby={'floor-coverage-right-label'}>{gavCoverage + '%'}</span>
        </div>
      </div>
      <div className={'valid-scores mt-3'} data-testid={'eps-area-score'}>
        <div className={'mb-2'} id={'eps-area-score-label'}>
          <label className={'c-grey'} aria-labelledby={'eps-area-score-label'}>{I18n('t_valid_ratings_by_floor_area')}</label>
        </div>
        <div data-testid={'eps-area-score-bar'}>
          {ratingGrid(esRating)}
        </div>
      </div>
      <div className={'eps-expiration'} data-testid={'eps-expiration'}>
        <label className={'c-grey'} id={'eps-expiration-label'}>{I18n('t_certified_assets_by_floor_area')}</label>
        <div className={'mt-2'} aria-labelledby={'eps-expiration-label'}>
          <ProgressBarWrapper percentageData={esCertifiedAssetsPercentage} bgColor={'d-blue'}
            metric={EPS} id={`${EPS}-expire`}
          />
          <div className={'p-certify-label'} data-testid={'eps-certs'} id={'eps-certs-label-val'}>
            <label className={'pr-5 flex'} aria-labelledby={'eps-certs-label-val'}>
              {esCertifiedAssetsPercentage + '%'}&nbsp;
              {I18n('t_certified')}
            </label>
          </div>
          {getLastRow()}
        </div>
      </div>
    </div>
  )
}

EnergyStarAnalysis.propTypes = {
  esData: PropTypes.object,
  featureState: PropTypes.object
}

EnergyStarAnalysis.displayName = 'EnergyStarAnalysis'

export default EnergyStarAnalysis
