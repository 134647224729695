import axiosInstance from '../common/axios-config'
import axiosInstancePaneer from '../common/axios-config-paneer'

export const AddAsset = async (asset) => {
  const {data} = await axiosInstancePaneer.post('/api/v1/Assets/', asset)
  return data
}

export const AddNewAssetEPC = async (newAssetEPC) => {
  const {data} = await axiosInstance.post('/api/v1/EPCs/', newAssetEPC)
  return data
}

export const getFunds = async () => {
  const {data} = await axiosInstancePaneer.get('/api/v1/Funds')
  return data
}