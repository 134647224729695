import React, {useContext, useMemo, useState} from 'react'
import {Button} from 'primereact/button'
import {Dropdown} from 'primereact/dropdown'
import {Checkbox} from 'primereact/checkbox'
import { EXPORT_TYPES,LEVEL_OF_DETAILS_EXCEL_VALUE, LEVEL_OF_DETAILS, LEVEL_OF_DETAILS_FOR_EXPORT, TIME_PERIOD_GRANULARITY, TIME_PERIOD_GRANULARITY_EXCEL_VALUE, TIME_PERIOD_GRANULARITY_FOR_EXPORT } from './ExportHelper'
import { GetFundPerformanceExportAssetData, GetFundPerformanceCardsDataForAllUtilities } from '../../services/funds/fund-performance-service'
import {fundPerformanceExportHandler} from './fund-exports/excel-exports/FundPerformanceExcelHelper'
import {  fundPerformanceUtilitiesList,  DATA_FROM,  fundDataQualityUtilitiesList} from '../helpers/Constants'
import { getDropdownItemTemplate, getLocalizedValue } from '../helpers/Helper'
import {GetFundActions} from '../../services/funds/action-plan-service'
import {FeatureFlagContext} from 'Contexts'
import I18n from '../i18n/I18n'
import './ExportOverlayPanel.scss'
import {useQueries} from 'react-query'
import {noRefetchOnWindowFocus} from '../../services/common/useQuery-config'
import {fundDataQualityExportHandler} from './fund-exports/excel-exports/FundDataQualityExcelHelper'
import { GetFundDataQualityExportData} from '../../services/funds/fund-data-quality-service'
import {fundActionPlanExportHandler} from './fund-exports/excel-exports/FundActionPlanExcelHelper'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const FundExportSelection = (props) => {
  const { loginState: { userInfo } } = useLoginAuthContext()
  const featureContext = useContext(FeatureFlagContext)
  const featureState = featureContext.featureState
  const selectedLanguage = userInfo.languagePreference
  const intl = useIntl()

  const [listOfExportedItems, setListOfExportedItems] = useState([])
  const DEFAULT_EXPORT_SELECTION = {
    FUND_PERFORMANCE:
      {
        UTILITY: {label: I18n('t_alu'), value: 'All'},
        UTILITY_DROPDOWN_OPTIONS: fundPerformanceUtilitiesList,
        LEVEL_OF_DETAIL:  {label: I18n('t_level_of_details_by_asset'), value: LEVEL_OF_DETAILS.asset, excelValue : LEVEL_OF_DETAILS_EXCEL_VALUE.asset},
        LEVEL_OF_DETAIL_DROPDOWN_OPTIONS : LEVEL_OF_DETAILS_FOR_EXPORT.filter((item) => item.value === LEVEL_OF_DETAILS.asset),
        TIME_PERIOD_GRANULARITY : {label: I18n('t_time_period_granularity_annual'), value: TIME_PERIOD_GRANULARITY.ANNUAL , excelValue : TIME_PERIOD_GRANULARITY_EXCEL_VALUE.ANNUAL},
        TIME_PERIOD_GRANULARITY_DROPDOWN_OPTIONS:TIME_PERIOD_GRANULARITY_FOR_EXPORT.filter((item) => item.value === TIME_PERIOD_GRANULARITY.ANNUAL),
      },
    FUND_DATA_QUALITY:
        {
          UTILITY: {label: I18n('t_alu'), value: 'All'},
          UTILITY_DROPDOWN_OPTIONS: fundDataQualityUtilitiesList,
        }
  }

  const [ fundHomeExportSelections, setFundHomeExportSelections ] = useState(DEFAULT_EXPORT_SELECTION)
  const utilitySelectedForFundExports = useMemo(()=>{
    let selectedUtility = 'Summary'
    if(props.dataFrom === DATA_FROM.FUND.FUND_PERFORMANCE){
      if (fundHomeExportSelections[EXPORT_TYPES.FUND.FUND_PERFORMANCE].UTILITY?.value !== 'All') {
        selectedUtility= fundHomeExportSelections[EXPORT_TYPES.FUND.FUND_PERFORMANCE].UTILITY?.value
      }
    }
    if(props.dataFrom === DATA_FROM.FUND.FUND_DATA_QUALITY){
      selectedUtility = fundHomeExportSelections[EXPORT_TYPES.FUND.FUND_DATA_QUALITY].UTILITY?.value
    }

    return selectedUtility
  },[fundHomeExportSelections])

  const perfAssetListUseQueryParams = {...props?.fundAssetListQueryOptions, utility: utilitySelectedForFundExports}
  const dqExportDataQueryParams = {...props?.fundAssetListQueryOptions, utility: utilitySelectedForFundExports}
  const isExport = true
  const fundExportQueries= useQueries([
    {
      queryKey: ['FundPerformanceAssetList', perfAssetListUseQueryParams],
      queryFn: GetFundPerformanceExportAssetData,
      refetchOnWindowFocus: noRefetchOnWindowFocus,
      enabled: props.dataFrom === DATA_FROM.FUND.FUND_PERFORMANCE,
    },
    {
      queryKey: ['fundPerformanceData', props?.fundAssetListQueryOptions, isExport],
      queryFn: GetFundPerformanceCardsDataForAllUtilities,
      options: noRefetchOnWindowFocus,
      enabled: props.dataFrom === DATA_FROM.FUND.FUND_PERFORMANCE
    },
    {
      queryKey: ['FundDQData', dqExportDataQueryParams],
      queryFn: GetFundDataQualityExportData,
      options: noRefetchOnWindowFocus,
      enabled: props.dataFrom === DATA_FROM.FUND.FUND_DATA_QUALITY
    },
    {
      queryKey: ['fundActionsByStatusData', props?.fundAssetListQueryOptions?.fundGroupId, false],
      queryFn: GetFundActions,
      options:noRefetchOnWindowFocus,
      enabled: listOfExportedItems.indexOf(EXPORT_TYPES.FUND.ACTIONS_BY_STATUS) !== -1
    },
    {
      queryKey: ['fundActionsByImpactData', props?.fundAssetListQueryOptions?.fundGroupId, true],
      queryFn: GetFundActions,
      options: noRefetchOnWindowFocus,
      enabled: listOfExportedItems.indexOf(EXPORT_TYPES.FUND.ACTIONS_BY_IMPACT) !== -1
    },
  ])
  
  const {
    isLoading: completeAssetListInfoLoading,
    data: completeAssetListInfo
  } = fundExportQueries[0]

  const {
    isLoading: completeFundSummaryInfoIsLoading,
    data: completeFundSummaryInfo
  } = fundExportQueries[1]

  const {
    isLoading: dqExportDataIsLoading,
    data: dqExportData
  } = fundExportQueries[2]

  const {
    isFetching: isActionsByStatusFetching,
    data: actionsByStatusInfo
  } = fundExportQueries[3]

  const {
    isFetching: isActionsByImpactFetching,
    data: actionsByImpactInfo
  } = fundExportQueries[4]
  const propsItem = {
    timePeriod: props.timePeriod,
    pickerValue: props.pickerValue,
    fundName: props.selectedFund?.fundname,
  }

  const exportButtonClickHandler = async (e) => {
    let fundExportFunctionCommonParams = {props, selectedLanguage, listOfExportedItems}
    switch (props.dataFrom) {
    case DATA_FROM.FUND.FUND_PERFORMANCE:
      await fundPerformanceExportHandler(propsItem,selectedLanguage,fundHomeExportSelections,completeFundSummaryInfo, completeAssetListInfo, intl)
      break
    case DATA_FROM.FUND.FUND_DATA_QUALITY:
      await fundDataQualityExportHandler(props, selectedLanguage, dqExportData,intl, fundHomeExportSelections)
      break
    case DATA_FROM.FUND.FUND_ACTION_PLAN:
      await fundActionPlanExportHandler({
        ...fundExportFunctionCommonParams,
        actionsByStatusInfo,
        actionsByImpactInfo,featureState
      })
      break
    }
    props?.overlayRef?.current.toggle(e)
  }

  const exportBtnDisableForActionPlan = () => {
    switch(listOfExportedItems.length) {
    case 0: return listOfExportedItems.length === 0
    case 1: {
      let boolValue = false
      if (listOfExportedItems.indexOf(EXPORT_TYPES.FUND.ACTIONS_BY_STATUS) !== -1 && isActionsByStatusFetching) {
        boolValue = isActionsByStatusFetching
      } else if (listOfExportedItems.indexOf(EXPORT_TYPES.FUND.ACTIONS_BY_IMPACT) !== -1 && isActionsByImpactFetching) {
        boolValue = isActionsByImpactFetching
      }
      return boolValue }
    case 2: return isActionsByStatusFetching || isActionsByImpactFetching
    default: return listOfExportedItems.length === 0
    }
  }

  function exportButtonStatus() {
    switch (props?.dataFrom) {
    case DATA_FROM.FUND.FUND_PERFORMANCE :
      return completeFundSummaryInfoIsLoading || completeAssetListInfoLoading
    case DATA_FROM.FUND.FUND_DATA_QUALITY:
      return dqExportDataIsLoading
    case DATA_FROM.FUND.FUND_ACTION_PLAN :
      return (listOfExportedItems.indexOf(EXPORT_TYPES.FUND.ACTIONS_BY_IMPACT) === -1 &&
          listOfExportedItems.indexOf(EXPORT_TYPES.FUND.ACTIONS_BY_STATUS) === -1)
    }
  }

  function getDropdown(value, options, placeholder, onchangeFunction, label) {
    return <div className="asset-performance-additional-options-container" data-testid={label}>
      <label className="dropdown-label">{label}</label>
      <Dropdown
        value={value}
        options={options}
        itemTemplate={getDropdownItemTemplate}
        placeholder={placeholder}
        onChange={(e) => onchangeFunction(e)}
        className="portfolio-export-utility-dropdown"
        panelClassName={'fund-export-selection-dropdown-panel'}
        role='utility-levelOfDetail-timePeriodGranularity-dropdown'
      />
    </div>
  }

  function getAdditionalSelectionOptions(exportType){
    let utility_dropdown_value = fundHomeExportSelections[exportType].UTILITY.label
    let utility_dropdown_options = fundHomeExportSelections[exportType].UTILITY_DROPDOWN_OPTIONS.map((utility) => utility.label)
    let utility_dropdown_placeholder = getLocalizedValue(selectedLanguage, 't_alu')

    function handleUtilitySelections(e) {
      let utility = fundHomeExportSelections[exportType].UTILITY_DROPDOWN_OPTIONS.filter((utility) => utility.label === e.value)[0]
      setFundHomeExportSelections((prevState) => {
        return {...prevState, [exportType]: {...prevState[exportType], UTILITY: utility}}
      })
    }
    function handleLevelOfDetailSelections(e,) {
      let level_of_detail_value = fundHomeExportSelections[exportType].LEVEL_OF_DETAIL_DROPDOWN_OPTIONS.filter((levelOfDetail) => levelOfDetail.label === e.value)[0]
      setFundHomeExportSelections((prevState) => {
        return {...prevState, [exportType]: {...prevState[exportType], LEVEL_OF_DETAIL: level_of_detail_value}}
      })
    }
    function handleTimePeriodGranularitySelections(e) {
      let time_period_granularity =  fundHomeExportSelections[exportType].TIME_PERIOD_GRANULARITY_DROPDOWN_OPTIONS.filter((timePeriodGranularity) => timePeriodGranularity.label === e.value)[0]
      setFundHomeExportSelections((prevState) => {
        return {...prevState, [exportType]: {...prevState[exportType], TIME_PERIOD_GRANULARITY: time_period_granularity}}
      })

    }
    switch (exportType){
    case EXPORT_TYPES.FUND.FUND_PERFORMANCE : return <div>
      <div>
        {getDropdown(
          utility_dropdown_value,
          utility_dropdown_options,
          utility_dropdown_placeholder,
          handleUtilitySelections,
          getLocalizedValue(selectedLanguage,'t_req_utility'))
        }
      </div>
      <div>
        {getDropdown(
          fundHomeExportSelections[EXPORT_TYPES.FUND.FUND_PERFORMANCE].LEVEL_OF_DETAIL.label,
          fundHomeExportSelections[EXPORT_TYPES.FUND.FUND_PERFORMANCE].LEVEL_OF_DETAIL_DROPDOWN_OPTIONS.map(levelOfDetail => levelOfDetail.label),
          getLocalizedValue(selectedLanguage, 't_level_of_details_by_asset'),
          handleLevelOfDetailSelections,
          getLocalizedValue(selectedLanguage,'t_level_of_detail'))
        }
      </div>
      <div>
        {getDropdown(
          fundHomeExportSelections[EXPORT_TYPES.FUND.FUND_PERFORMANCE].TIME_PERIOD_GRANULARITY.label,
          fundHomeExportSelections[EXPORT_TYPES.FUND.FUND_PERFORMANCE].TIME_PERIOD_GRANULARITY_DROPDOWN_OPTIONS.map(timePeriodGranularity => timePeriodGranularity.label),
          getLocalizedValue(selectedLanguage, 't_time_period_granularity_annual'),
          handleTimePeriodGranularitySelections,
          getLocalizedValue(selectedLanguage,'t_time_period_granularity'))
        }
      </div>
    </div>
    case EXPORT_TYPES.FUND.FUND_DATA_QUALITY : return <div>
      <div className='popup-title'>{I18n('t_export_data_quality_excel')}</div>
      {getDropdown(
        utility_dropdown_value,
        utility_dropdown_options,
        utility_dropdown_placeholder,
        handleUtilitySelections,
        getLocalizedValue(selectedLanguage,'t_req_utility'))
      }
    </div>
    }
  }

  const onSelectionChange = (e) => {
    let selection = [...listOfExportedItems]
    e.checked ? selection.push(e.value) : selection.splice(selection.indexOf(e.value), 1)
    setListOfExportedItems(selection)
  }

  function getExportToExcelOption1() {

    // execution starts here...
    const actionsByImpact = EXPORT_TYPES.FUND.ACTIONS_BY_IMPACT
    const actionsByImpactIndex = listOfExportedItems.indexOf(actionsByImpact)

    switch (props.dataFrom) {
    case DATA_FROM.FUND.FUND_PERFORMANCE:
    case DATA_FROM.FUND.FUND_DATA_QUALITY:
      return null
    case DATA_FROM.FUND.FUND_ACTION_PLAN:
      return (
        <div className="export-checkbox">
          <Checkbox value={actionsByImpact} onChange={onSelectionChange}
            checked={actionsByImpactIndex !== -1}
            data-testid={'actions_by_impact_checkbox'}/>
          <label className="label-checkbox"
            data-testid={'actions_by_impact_label'}>{I18n('t_action_by_impact_excel')}</label>
        </div>
      )
    }
  }

  function getExportToExcelOption2() {
    switch (props.dataFrom) {
    case DATA_FROM.FUND.FUND_PERFORMANCE:
      return getAdditionalSelectionOptions(EXPORT_TYPES.FUND.FUND_PERFORMANCE)
    case DATA_FROM.FUND.FUND_DATA_QUALITY:
      return getAdditionalSelectionOptions(EXPORT_TYPES.FUND.FUND_DATA_QUALITY)
    case DATA_FROM.FUND.FUND_ACTION_PLAN:
      return <div className="export-checkbox">
        <Checkbox value={EXPORT_TYPES.FUND.ACTIONS_BY_STATUS} onChange={onSelectionChange}
          checked={listOfExportedItems.indexOf(EXPORT_TYPES.FUND.ACTIONS_BY_STATUS) !== -1}
          data-testid={'actions_by_status_checkbox'}/>
        <label className="label-checkbox"
          data-testid={'actions_by_status_label'}>{I18n('t_action_by_status_excel')}</label>
      </div>
    }
  }

  return (
    <div className="p-flex-column p-pt-1" data-testid='export-overlay-panel'>
      {getExportToExcelOption1()}
      {getExportToExcelOption2()}
      <Button type="button" label={I18n('t_export')} onClick={exportButtonClickHandler} className="export-option"
        disabled={props?.dataFrom === DATA_FROM.FUND.FUND_PERFORMANCE || props?.dataFrom === DATA_FROM.FUND.FUND_DATA_QUALITY ? exportButtonStatus() : exportBtnDisableForActionPlan()} data-testid='confirm-export-btn'/>
    </div>
  )
}
FundExportSelection.propTypes = {
  dataFrom: PropTypes.string,
}

FundExportSelection.displayName = 'FundExportSelection'

export default React.memo(FundExportSelection)