import React, {useState} from 'react'
import PropTypes from 'prop-types'
import './ChartOptions.scss'
import {Checkbox} from 'primereact/checkbox'
import I18n from '../../../../utils/i18n/I18n'
import {Button} from 'primereact/button'
import {useMutation} from 'react-query'
import {UpdateNZCConfigChartOptions} from '../../../../services/funds/nzc-service'
import {InputNumber} from 'primereact/inputnumber'
import {minMaxValidationCondition} from '../../../common/net-zero-carbon/nzc-common-utils'
import NzcBenchmarkOptions from '../../../common/nzc/nzc-benchmark/NzcBenchmarkOptions'
import {useNzcBenchmarkOptions} from '../../../../utils/custom-hooks/nzc-hooks/useNzcBenchmarkOptions'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const ChartOptions = (props) => {
  const scopeId = props.scopeId
  const [plotIndividualAssets, setPlotIndividualAssets] = useState(props.chartOptions?.assetsPlotData)
  const updateNzcSettingsMutation = useMutation(UpdateNZCConfigChartOptions)
  const { graphIntensity, getBenchmarkOptionsData } = props
  const [xAxisEditable, setXAxisEditable] = useState(props.chartOptions?.xAxisAutoScale)
  const [yAxisEditable, setYAxisEditable] = useState(props.chartOptions?.yAxisAutoScale)
  const [fromXAxisValue, setfromXAxisValue] = useState(props.chartOptions?.xAxisAutoScale ? props.nzcSettingsData?.baselineYear : props.chartOptions?.xAxisScaleFrom)
  const [toXAxisValue, setToXAxisValue] = useState(props.chartOptions?.xAxisScaleTo)
  const [minYAxisValue, setMinYAxisValue] = useState(props.chartOptions?.yAxisScaleMin)
  const [maxYAxisValue, setMaxYAxisValue] = useState(props.chartOptions?.yAxisAutoScale ? getYAutoScaleMaxValue() : props.chartOptions?.yAxisScaleMax)
  const [applyState, setApplyState] = useState(false)
  const [showStrandingDates, setShowStrandingDates] = useState(props.chartOptions?.displayStrandingDates)
  const [formErrors, setFormErrors] = useState({fromToYear:false, minMax: false})
  const {benchmarkOption, benchmarkOptionsPropsMemoised}=useNzcBenchmarkOptions(props.chartOptions)
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference

  function getYAutoScaleMaxValue() {
    return props.maxValueYAxis
  }

  function updateFormErrors(newFormErrors) {
    setFormErrors({...formErrors, ...newFormErrors})
  }

  function handlePlotData(e) {
    setPlotIndividualAssets(e.checked)
  }

  function handleXAxis(e) {
    setXAxisEditable(e.checked)
    updateFormErrors({fromToYear: fromAndToYearsValidationCondition(props.nzcSettingsData.baselineYear, 2050)})
    if (!xAxisEditable) {
      setfromXAxisValue(props.nzcSettingsData.baselineYear)
      setToXAxisValue(2050)
      if (yAxisEditable) {
        setApplyState(false)
      }
    }
  }

  function handleYAxis(e) {
    setYAxisEditable(e.checked)
    updateFormErrors({minMax: minMaxValidationCondition(0, getYAutoScaleMaxValue())})
    if (!yAxisEditable) {
      setMinYAxisValue(0)
      setMaxYAxisValue(getYAutoScaleMaxValue())
      if (xAxisEditable) {
        setApplyState(false)
      }
    }
  }

  function fromAndToYearsValidationCondition(fromYear, toYear) {
    return !!((fromYear >= toYear) || (fromYear < 1000) || (fromYear > 9999) || (toYear < 1000) || (toYear > 9999))
  }

  function handleOnBlurOfXAxisValue() {
    updateFormErrors({fromToYear: fromAndToYearsValidationCondition(fromXAxisValue, toXAxisValue)})
  }

  function handleOnBlurOfYAxisValue() {
    updateFormErrors({minMax: minMaxValidationCondition(minYAxisValue, maxYAxisValue)})
  }

  function handleApply() {
    const settings = {
      plotIndividualAssets: plotIndividualAssets,
      xAxisScaleFrom: fromXAxisValue,
      xAxisScaleTo: toXAxisValue,
      xAxisAutoScale: xAxisEditable,
      yAxisScaleMin: minYAxisValue,
      yAxisScaleMax: maxYAxisValue,
      yAxisAutoScale: yAxisEditable,
      selectedMarketBenchmarkID: benchmarkOption.selectedMarketBenchmarkID,
      displayMarketBenchmark: benchmarkOption.displayMarketBenchmark,
      displayStrandingDates: showStrandingDates,
    }
    updateNzcSettingsMutation.mutateAsync({scopeId, settings, graphIntensity}).then(() => {
      props.setChartOptions(prevChartOptions => {
        return {
          ...prevChartOptions,
          assetsPlotData: plotIndividualAssets,
          loadChartOptions: false
        }
      })
      props.refetchData()
    })
    props.handleChartOptions()
  }

  function handleStrandingDatesCheckbox(event) {
    setShowStrandingDates(event.checked)
  }

  function getYearsValidationMessage() {
    return (formErrors.fromToYear ? <div className="chart-form-error">{I18n('t_year_from_to_4_digits')}</div> : '')
  }

  function getMinMaxValidationMessage() {
    return (formErrors.minMax ? <div className="chart-form-error">{I18n('t_min_max_numeric')}</div> : '')
  }

  const disableApplyButton=()=> !!(applyState || formErrors.fromToYear || formErrors.minMax)

  return (
    <div className='nzc-chart-options'>
      <div className='chart-option-block'>
        <div className='chart-options-block-one'>
          <div className="p-field-checkbox" data-testid="plot-asset-checkbox">
            <Checkbox inputId="binary" checked={plotIndividualAssets} onChange={handlePlotData}/>
            <label data-testid="plot-asset" htmlFor="binary" className='block-one-label'>{I18n('t_plot_data_assets')}</label>
          </div>
          <div className='top-border-line'/>
          <div className="p-field-checkbox" data-testid="show-stranding-dates-checkbox">
            <Checkbox inputId="stranding-dates" checked={showStrandingDates}
              onChange={e => handleStrandingDatesCheckbox(e)}/>
            <label data-testid="stranding-label" htmlFor="stranding-dates" className='block-one-label'>{I18n('t_show_stranding_dates')}</label>
          </div>
          <div className='top-border-line'/>
          <div className="benchmark-options-wrapper">
            <NzcBenchmarkOptions benchmarkOptionsProps={benchmarkOptionsPropsMemoised} fundNzc getBenchmarkOptionsData={getBenchmarkOptionsData}/>
          </div>
        </div>
        <div className='chart-options-block-two'>
          <div className="nzc-input-values">
            <div className="graph-input-container x-axis-scale">{I18n('t_x_axis_scale')}</div>
            <div className="graph-input-container">
              <label data-testid="x-axis-from-label" className='input-label'>{I18n('t_from')}</label>
              <InputNumber inputId="from-x-axis" onKeyDown={(e) => e.code === 'Minus' && e.preventDefault()} className="reduction-input-number" min={0}
                value={fromXAxisValue} useGrouping={false} disabled={xAxisEditable}
                onBlur={handleOnBlurOfXAxisValue} onChange={(e) => {
                  setfromXAxisValue(e.value)
                }}/>
              <label data-testid="x-axis-to-label" className='input-label'>{I18n('t_to')}</label>
              <InputNumber inputId="to-x-axis" className="reduction-input-number" min={0}
                onKeyDown={(e) => e.code === 'Minus' && e.preventDefault()} value={toXAxisValue}
                useGrouping={false} disabled={xAxisEditable} onBlur={handleOnBlurOfXAxisValue}
                onChange={(e) => {
                  setToXAxisValue(e.value)
                }}/>
              <div data-testid="x-axis-auto-scale-checkbox" className="p-field-checkbox axis-checkbox">
                <Checkbox inputId="x-axis-checkbox" checked={xAxisEditable} onChange={handleXAxis}/>
                <label data-testid="x-axis-scale-label" htmlFor="x-axis-checkbox" className='autoscale'>{I18n('t_autoscale')}</label>
              </div>
            </div>
            {getYearsValidationMessage()}
            <div data-testid="y-axis-scale-label" className="graph-input-container y-axis-scale">{I18n('t_y_axis_scale')}</div>
            <div className="graph-input-container">
              <label data-testid="y-axis-min-label" className='input-label'>{I18n('t_min')}</label>
              <InputNumber
                className="reduction-input-number"
                value={minYAxisValue}
                disabled={yAxisEditable}
                onBlur={handleOnBlurOfYAxisValue}
                onChange={(e) => setMinYAxisValue(e.value)}
                locale={selectedLanguage}
              />
              <label data-testid="y-axis-max-label" className='input-label' >{I18n('t_max')}</label>
              <InputNumber
                className="reduction-input-number"
                value={maxYAxisValue}
                onKeyDown={(e) => e.code === 'Minus' && e.preventDefault()}
                disabled={yAxisEditable}
                onBlur={handleOnBlurOfYAxisValue}
                onChange={(e) => setMaxYAxisValue(e.value)}
                locale={selectedLanguage}
              />
              <div className="p-field-checkbox axis-checkbox" data-testid="y-axis-auto-scale-checkbox">
                <Checkbox inputId="y-axis-checkbox" checked={yAxisEditable} onChange={handleYAxis} disabled={false} />
                <label htmlFor="y-axis-checkbox" className='autoscale'>{I18n('t_autoscale')}</label>
              </div>
            </div>
            {getMinMaxValidationMessage()}
          </div>
        </div>
      </div>
      <div className='border-line'/>
      <div className='padding-bottom-3'>
        <Button label={I18n('t_apply')} data-testid="apply-button" onClick={handleApply} className="common-blue-bg-btn apply-button button-highlight" disabled={disableApplyButton()}/>
      </div>
    </div>
  )
}

ChartOptions.propTypes = {
  scopeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  chartOptions: PropTypes.object,
  maxValueYAxis: PropTypes.number,
  setChartOptions: PropTypes.func,
  refetchData: PropTypes.func,
  handleChartOptions: PropTypes.func,
  returnBenchmarkOptions: PropTypes.array,
  chartOptionsModel: PropTypes.bool,
  nzcSettingsData: PropTypes.object,
}

ChartOptions.displayName = 'ChartOptions'

export default ChartOptions