import {useCallback, useState} from 'react'

/**
 * useCustomState is a helper custom-hook to simplify the state management of the UI component.
 */
const useCustomState = (stateInitialiser) => {
  const [customState, setCustomState] = useState(stateInitialiser)


  const updateCustomState=useCallback(function(newState) {
    setCustomState(previousState => {
      return {
        ...previousState,
        ...newState
      }
    })
  }, [setCustomState])

  return {customState, updateCustomState}
}

export default useCustomState