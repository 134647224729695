import axiosInstance from './common/axios-config'


export const GetNotifications = async (keys) => {
  const queryKeys = keys.queryKey
  const {data} = await axiosInstance.get(`/api/v1/Assets/${queryKeys[1]}/Notifications/`)
  return data
}


export const GetAllNotifications = async () => {
  const {data} = await axiosInstance.get('/api/v1/Assets/Notifications')
  return data
}

export const UpdateNotifications = async (updatedData) => {
  const {data} = await axiosInstance.put('/api/v1/Assets/Notifications', updatedData)
  return data
}

export const UpdateNotification = async (editData) => {
  const {data} = await axiosInstance.put(`/api/v1/Assets/Notifications/${editData.notificationId}`, editData.updatedData)
  return data
}

export const GetAssetHeader = async (assetId) => {
  const {data} = await axiosInstance.get(`/api/v1/Assets/${assetId}/AssetHeader`)
  return data
}