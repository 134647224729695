import React, {useContext, useEffect, useState} from 'react'
import I18n from '../../../../../utils/i18n/I18n'
import {useHistory} from 'react-router-dom/cjs/react-router-dom'
import PageContainer from '../../Common/page-container/PageContainer'
import {Card} from 'primereact/card'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import './GroupDetailsHome.scss'
import {useQuery} from 'react-query'
import {noRefetchOnWindowFocus} from '../../../../../services/common/useQuery-config'
import {GetAssetGroupsByGroupId} from '../../../../../services/admin/groups-service'
import {FeatureFlagContext} from 'Contexts'
import GroupsOverviewMembersSkeleton
  from '../../../../common/skeletons/groups-grid-skeleton/GroupsOverviewMembersSkeleton'
import {useParams} from 'react-router-dom'
import {Skeleton} from 'primereact/skeleton'
import AssetsContainer from '../../Common/asset/AssetsContainer'
import {
  createNavURLForUserPages,
  getLocalizedValue,
  getLoggedInUserRole,
  getUserRole
} from '../../../../../utils/helpers/Helper'
import {ADMIN_PAGES, ELLIPSIS, GROUP_DETAILS, verticalEllipsisJson} from '../../../../../utils/helpers/Constants'
import {ADMIN_EMAIL} from '../../../../../utils/config/Config'
import {Button} from 'primereact/button'
import {VerticalEllipsis} from '../../../../common/ellipsis-v/VerticalEllipsis'
import { BULK_UPLOAD } from '../../../../../utils/i18n/constants'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const GroupDetailsHome = () => {
  //region Declarations/Initializations
  const history = useHistory()
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const [selectedGroup] = useState(useParams())
  const [users,setUsers] = useState([])
  const [GroupDetails,setGroupDetails] = useState([])
  const [filteredGroupDetails, setFilteredGroupDetails] = useState([])
  const options = {year: 'numeric', month: 'short', day: 'numeric'}
  const hasPermissionForEditGroup = getLoggedInUserRole() === 'ADMIN' || getLoggedInUserRole() === 'ADMINISTRATOR'
  const getSelectedGroupId = () => selectedGroup && selectedGroup.groupId ? selectedGroup.groupId : null
  const {data: assetGroupData, isLoading} = useQuery(['assetGroups', getSelectedGroupId()], GetAssetGroupsByGroupId, noRefetchOnWindowFocus)
  const CLASS_ACTION_HIGHLIGHT_CLICKABLE_OPACITY = 'action-highlight-clickable-opacity'
  const MY_GROUPS = 'my-groups'
  const featureContext = useContext(FeatureFlagContext)
  const featureState = featureContext.featureState
  //endregion

  //region useEffect Implementation
  useEffect(() => {
    if (assetGroupData) {
      setUsers(assetGroupData.users)
      setGroupDetails(assetGroupData.assets)
      setFilteredGroupDetails(assetGroupData.assets)
    }
  }, [assetGroupData])

  //endregion

  //region Helper methods

  //endregion

  //region Custom templates
  function NoDataFound({message}) {
    return <div className="no-result-msg">
      <span className="no-result-text">{message}</span>
    </div>
  }

  function groupDetailsTemplate(heading,value){
    return <div className="pb-2">
      <div className="user-header pb-1">{isLoading ? headingSkeleton() : heading}</div>
      <div className="user-value pb-1">{isLoading ? valueSkeleton() : getHeadingValue(value) }</div>
    </div>
  }

  function getHeadingValue(value) {
    return value ? value : '-'
  }

  const headingSkeleton=()=>{
    return <div className={'groups-page-skeleton'}>
      <Skeleton width={'4.5rem'} height={'0.9rem'}/>
    </div>
  }

  const GetGroupDetails = () => {
    return <div data-testid={'group-details'} className="group-details">
      <div className="pb-2">
        <div data-testid={'group-details-label'} className="title">{I18n('t_details')}</div>
      </div>
      <Card className="group-details-card">
        <div data-testid={'group-details-card'}>
          {groupDetailsTemplate(I18n('t_name'), assetGroupData ? assetGroupData.name : null)}
          {groupDetailsTemplate(I18n('t_asset_s'), assetGroupData ? assetGroupData.assetCount : null)}
          {groupDetailsTemplate(I18n('t_created'), assetGroupData ? new Date(assetGroupData.createdDate).toLocaleDateString(selectedLanguage, options) : null)}
          {groupDetailsTemplate(I18n('t_description'), assetGroupData ? assetGroupData.description : null)}
        </div>
      </Card>
    </div>
  }

  const valueSkeleton=()=>{
    return <div className={'users-page-skeleton'}>
      <Skeleton className={'users-page-skeleton'} width={'14rem'} height={'1.2rem'}/>
    </div>
  }

  function getRole(rowData) {
    return rowData.role ? rowData.role : 'Users'
  }

  const GroupUsers=()=>{
    return <div data-testid={'group-users'} className="group-users">
      <div className="pb-2">
        <div data-testid={'group-users-header'} className={hasPermissionForEditGroup ? 'title' : 'title no-cursor'} onClick={()=>toRespectiveTab(2)}>
          {getHeaderForUser()}
        </div>
      </div>
      <Card className="group-users-card">
        {!isLoading ?
          <div>
            {users?.length > 0 ?
              <DataTable role={'group-users-grid'} value={users} className={hasPermissionForEditGroup ? 'group-users-grid' : 'group-users-grid no-blue'}
                onRowClick={(e)=> navigateToUserDetails(e)} selectionMode="single" scrollable scrollHeight="285px">
                <Column field="userName" header={I18n('t_name')} bodyClassName="groups-body" headerClassName="user-grid-header"
                  style={{width:'30%'}}/>
                <Column field="role" header={I18n('t_role')} headerClassName="user-grid-header"
                  body={getRole} bodyClassName="groups-body grey" style={{width:'15%'}}/>
              </DataTable> :
              <NoDataFound message={I18n('t_groups_none')}/>
            }
          </div> :
          <div className="groups-grid-before-load">
            <GroupsOverviewMembersSkeleton/>
          </div>
        }
      </Card>
    </div>
  }
  //endregion

  function toRespectiveTab(ai) {
    if(hasPermissionForEditGroup){
      const groupEditPath=createNavURLForUserPages({pathPrefix: ADMIN_PAGES.groups, groupId: selectedGroup.groupId, pathSuffix: 'edit'})
      history.push({
        pathname: groupEditPath,
        state: {activeIndex: ai}
      })
    }
  }

  function navigateToUserDetails(event) {
    if(hasPermissionForEditGroup){
      const userDetailsPath = createNavURLForUserPages({
        pathPrefix: ADMIN_PAGES.users,
        userId: event.data.userID,
      })
      history.push({
        pathname: userDetailsPath,
      })
    }
  }

  function getHeaderForAsset() {
    const lengthAsset = filteredGroupDetails?.length +' '+getLocalizedValue(selectedLanguage,'t_assets')
    const lengthAssets = filteredGroupDetails?.length +' '+getLocalizedValue(selectedLanguage,'t_asset_s')

    return (filteredGroupDetails?.length < 2 && filteredGroupDetails.length !==0) ? lengthAsset : lengthAssets
  }

  function getHeaderForUser() {
    const lengthUser= users?.length +' '+getLocalizedValue(selectedLanguage,'t_user')
    const lengthUsers = users?.length +' '+getLocalizedValue(selectedLanguage,'t_users')

    return (users?.length < 2 && users?.length !==0) ? lengthUser : lengthUsers
  }

  const verticalEllipsisKeyValue = {
    ...verticalEllipsisJson,
    'consumption': {
      ...verticalEllipsisJson['consumption'],
      'displayBulkUploadButton': getUserRole() && featureState.sieraplus_consumptionBulkUpload,
    }
  }

  return (
    <PageContainer>
      <div className={'group-overview'}>
        <div className="flex justify-content-between pb-5">
          <div data-testid={'asset-group-name'} className="group-name">{assetGroupData ? assetGroupData.name : ''}</div>
          <div className={'flex'}>
            {hasPermissionForEditGroup && <div className={'edit-item-button flex'}>
              <Button
                label={I18n('t_edit')}
                className={`add-item ${CLASS_ACTION_HIGHLIGHT_CLICKABLE_OPACITY}`}
                onClick={() => toRespectiveTab(0)}
                style={{visibility: (hasPermissionForEditGroup) ? 'visible' : 'hidden'}}
                data-testid={'element-edit'}
              />
            </div>}
            {getUserRole() && featureState.sieraplus_consumptionBulkUpload && window.location.href.toString().includes(MY_GROUPS) &&
                <div className={'ml-3 relative'} id={ELLIPSIS}>
                  <VerticalEllipsis
                    keyId={getSelectedGroupId()}
                    routeVia={{name: assetGroupData ? assetGroupData.name : '', routeVia: GROUP_DETAILS}}
                    flag={BULK_UPLOAD.CONSUMPTION}
                    bulkUploadKeyValue={verticalEllipsisKeyValue[BULK_UPLOAD.CONSUMPTION]}
                    selectedLanguage={selectedLanguage}
                  />
                </div>
            }
          </div>
        </div>
        <div className="group-container">
          <GetGroupDetails/>
          <GroupUsers/>
        </div>
        <div className="pb-6">
          <AssetsContainer
            filteredAssetDetails={filteredGroupDetails}
            edit={false}
            header={getHeaderForAsset()}
            hasPermissionForEditUser={hasPermissionForEditGroup}
            assetDetails={GroupDetails}
            isLoading={isLoading}
            groupRowClickHandler={() => hasPermissionForEditGroup ? toRespectiveTab(1) : null}
          />
          {assetGroupData?.isGroupWithLoggedInUser && <div className={'flex justify-content-end pt-4'}>
            <span>{I18n('t_email_leave_group')}</span>
            <span className={'link-text pl-2'}>{ADMIN_EMAIL}</span>
          </div>}
        </div>
      </div>
    </PageContainer>
  )
}

export default GroupDetailsHome