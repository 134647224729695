import React from 'react'
import {Button} from 'primereact/button'
import I18n from '../../../../../utils/i18n/I18n'
import {UserActions} from '../../../../../utils/helpers/Constants'


const GroupFooter = (props) => {

  function getCssStylesForSaveButton() {
    //New condition should be added
    return `${!props.lastPage && props.groupAction !== UserActions.EDIT_GROUP? 'button-save' : 'button-save button-save-editUser'}`
  }
  return (
    <div className={'asset-footer'}>
      <div className={'footer-buttons-container p-grid'}>
        <div className={'buttons-container p-col-4'}>
          <div className={'buttons-save-next'}>
            <div className={'button-container container-save'}>
              <Button
                type="submit"
                className={getCssStylesForSaveButton()}
                onClick={props.submitHandler}
              >
                {I18n('t_save_exit')}
              </Button>
            </div>
            <div className={'button-container container-next'}>
              {!props.lastPage && props.groupAction !== UserActions.EDIT_GROUP ?
                <Button onClick={props.handleNextTab} className={'button-next'} disabled={props.isNextButtonDisabled}>      {I18n(props.handleNextTabBtnText)}
                </Button> :
                null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GroupFooter