import PropTypes from 'prop-types'
import {View, Text} from '@react-pdf/renderer'
import React from 'react'
import styles from '../../../../../utils/data-exports/style-sheet/Styles'
import I18n from '../../../../../utils/i18n/I18n'
import { unitDateFormatter } from '../../../../../utils/helpers/Helper'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const TablePdf = (props) => {
  const {loginState: {userInfo}} = useLoginAuthContext()
  const unitSystem = userInfo.unitSystem

  const formatDate = (value) => {
    if (props.language) {
      return unitDateFormatter(new Date(value),unitSystem,props.language)
    }
    return unitDateFormatter(new Date(value),unitSystem,'en-GB')
  }

  const tableToPrint = props.performanceData.map(function (src, index) {
    return (
      <View key={index} style={[styles.tableRowFlex,{}]} wrap={false}>
        <View style={[styles.tableBorderPerfLeftAlign, styles.tablePadding, index % 2 === 0 ? styles.bgColorGrey : null, styles.perfDate]}>
          <Text style={[styles.dqText, {}]}>{formatDate(src.date)}</Text>
        </View>
        <View style={[styles.tableBorderPerfLeftAlign, styles.tablePadding,styles.perfEvent,index % 2 === 0 ? styles.bgColorGrey : '']}>
          <Text style={[styles.dqText, {}]}>{src.description}</Text>
        </View>
        <View
          style={[styles.tableBorderPerfLeftAlign, styles.tablePadding, index % 2 === 0 ? styles.bgColorGrey : null, styles.perfPerson]}>
          <Text style={[styles.dqText, {}]}>{src.person ? src.person : '-'}</Text>
        </View>
        <View style={[styles.tableBorderPerfNote, (index % 2) === 0 ? styles.bgColorGrey : null, styles.perfNotes]}>
          {src.notes && src.notes.length !== 0 ?
            src.notes.map(function (note,id) {
              return (
                <View key={id} style={[styles.tableNotePadding]} wrap={false}>
                  <Text style={[styles.dqText,styles.greyLightColor, {}]}>
                    {note.userName} - {formatDate(note.dateCreated)}{'\n'}
                  </Text>
                  <Text style={[styles.dqText, {}]}>{Array.from(note.content)}</Text>
                  <Text style={[styles.dqText,styles.mT4, {}]}> {src.notes.length-1 !== id ? '-' : null}</Text>
                </View>
              )
            }) :
            <View style={[styles.tableNotePadding]}>
              <Text style={[styles.dqText, {}]}>-</Text>
            </View>}
        </View>
      </View>
    )
  })

  return (
    <View>
      <View style={[styles.tableTitle, styles.columnHeader,styles.greyColor]}>
        <View style={styles.perfDate}><Text style={styles.mL2}>{I18n('t_date')}</Text></View>
        <View style={styles.perfEvent}><Text style={styles.mL2}>{I18n('t_event')}</Text></View>
        <View style={styles.perfPerson}><Text style={styles.mL2}>{I18n('t_building_manager')}</Text></View>
        <View style={styles.perfNotes}><Text style={styles.mL2}>{I18n('t_notes')}</Text></View>
      </View>
      <View style={[styles.tableWidth]}>
        {tableToPrint}
      </View>
    </View>
  )
}

export default TablePdf

TablePdf.displayName = 'TablePdf'

TablePdf.propTypes = {
  performanceData: PropTypes.array,
  asset: PropTypes.object,
  language: PropTypes.string,
}