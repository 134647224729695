import React from 'react'
import {Skeleton} from 'primereact/skeleton'
import './DashboardCompletenessSkeleton.scss'
import PropTypes from 'prop-types'

const DashboardCompletenessSkeleton = ({ assetDashboard }) => {
  const className = !assetDashboard ? 'db-dq-skeleton' : 'db-asset-dq-skeleton'

  const getSkeletonLegend = (index) => (
    <div className={`${className}__legends--legend`} key={index}>
      <Skeleton width="10px" borderRadius="3px" height="10px" />
      <Skeleton width="150px" borderRadius="3px" height="12px" />
    </div>
  )

  return (
    <div className='dashboard-completeness-skeleton'>
      <Skeleton width="150px" borderRadius="3px" height="12px" />
      {!assetDashboard && <Skeleton width="165px" borderRadius="3px" height="12px" />}
      <Skeleton width='100%' borderRadius="3px" height="15px" />
      <div className={`${className}__legends`}>
        {Array.from({ length: 4 }).map((_, index) => (
          getSkeletonLegend(index)
        ))}
      </div>
    </div>
  )
}

DashboardCompletenessSkeleton.propTypes = {
  assetDashboard: PropTypes.bool,
}

export default DashboardCompletenessSkeleton