import './DataQualityContainerSmall.scss'
import PropTypes from 'prop-types'
import Tooltip from '../../atomic/Tooltip/Tooltip'
import TooltipTwoColumnsLayout from '../../atomic/Tooltip/TooltipContent/TooltipTwoColumnsLayout'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'
import {Chart} from 'primereact/chart'
import { getCoverageChartData, getCoverageChartOptions } from 'components/pages/assets/data-quality/data-quality-hooks-&-helpers/dataQualityHelper'
import { ButtonWrapper, Text } from 'components/atomic/index'
import { formatPercentage } from 'utils/helpers/Helper'

const DataQualityCoverageContainerV3 = ({dqChartData, noDataElement, onClick, containerClass, tooltipClass, getDqTooltipItems}) => {
  const { loginState: { userInfo } } = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference

  if (!dqChartData || (dqChartData?.areaCovered === 0 && dqChartData?.areaNotCovered === 0 && dqChartData?.coveragePercentage === 0)) {
    return noDataElement
  }
  const {areaCovered, areaNotCovered, coveragePercentage} = dqChartData

  const chartData = [
    { value: areaCovered, name: 'dq-covered' },
    { value: areaNotCovered, name: 'dq-unaccounted' },
    { value: coveragePercentage, name: 'dq-coverage-percentage'}
  ]

  return (
    <>
      <ButtonWrapper className={`data-quality-charts cursor ${containerClass}`} testId="data-quality-charts" onClick={onClick}>
        {dqChartData && (
          <div className='doughnut-wrapper-v3'>
            <Chart type="doughnut" data={getCoverageChartData(dqChartData)} options={getCoverageChartOptions()} className='dq-doughnut'/>
            <Text content={`${formatPercentage(coveragePercentage)}%`} />
          </div>
        )}
      </ButtonWrapper>
      <Tooltip target={`.${tooltipClass}`} padding="medium" width='400' showDelay={100}>
        <TooltipTwoColumnsLayout items={getDqTooltipItems(chartData, userInfo.unitSystem, selectedLanguage)} />
      </Tooltip>
    </>
  )
}

DataQualityCoverageContainerV3.propTypes = {
  dqChartData: PropTypes.object,
  noDataElement: PropTypes.object,
  onClick: PropTypes.func,
  containerClass: PropTypes.string,
  tooltipClass: PropTypes.string,
  getDqTooltipItems: PropTypes.func
}

DataQualityCoverageContainerV3.displayName = 'DataQualityCoverageContainerV3'

export default DataQualityCoverageContainerV3