import {Text, View} from '@react-pdf/renderer'
import React, {useState} from 'react'
import styles from '../style-sheet/Styles'
import I18n from '../../i18n/I18n'
import {areaCoveredMask, costMask} from '../../helpers/Constants'
import {CurrencyUnitToLabel, unitDateFormatter} from '../../helpers/Helper'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const ActionPlan = (props) => {
  const [action] = useState(props.data)
  const {loginState: {userInfo}} = useLoginAuthContext()
  const unitSystem = userInfo.unitSystem
  const currencyUnit = useInfo.currencyUnit

  const formatDate = (value) => {
    if (props.language) {
      unitDateFormatter(new Date(value),unitSystem,props.language)
    }
    return unitDateFormatter(new Date(value),unitSystem,'en-GB')
  }

  function currentUnit(data){
    let unit='kWh'
    if(data){
      unit= data ==='Water' ? ' m³' : ' kWh'
    }
    return unit.toString()
  }

  /*
        Using this since word break is not working
        Handled for two line(26 character)
    */
  String.prototype.InsertAt=function(CharToInsert,Position){
    return this.slice(0,Position) + CharToInsert + this.slice(Position)
  }

  const formatUnits = (value) => {
    if (value != null) {
      if(value.toString().length>11)
        return Number(value).toLocaleString(props.language).InsertAt(' ',13)
      return Number(value).toLocaleString(props.language)
    }
    return value
  }


  const tableToPrint = action.sort((first, next) => first.completion < next.completion ? 1 : -1).map(function (src, index) {
    return (
      <View key={index} style={[styles.tableRowFlex, {}]} wrap={false}>
        <View style={[styles.tableBorder, styles.tablePadding,styles.box, (index % 2 === 0) ? styles.bgColorGrey : null]}>
          <Text style={[styles.dqText, {}]}>
            {src.action.completion ? formatDate(src.action.completion) : '-'}
          </Text>
        </View>

        <View style={[styles.tableBorder, styles.tablePadding, (index % 2) === 0 ? styles.bgColorGrey : null,styles.pAction]}>
          <Text style={[styles.dqText, {}]}>
            {src.action.actionDescription ? src.action.actionDescription : '-'}
          </Text>
        </View>

        <View style={[styles.tableBorder, styles.tablePadding, styles.box, (index % 2 === 0) ? styles.bgColorGrey : null]}>
          <Text style={[styles.dqText, {}]}>
            {src.action.impact ? src.action.impact : '-'}
          </Text>
        </View>

        <View style={[styles.tableBorder, styles.tablePadding, index % 2 === 0 ? styles.bgColorGrey : null, styles.pReduction]}>
          <Text style={[styles.dqText]}>
            {src.action.targetUsagePercentage ? formatUnits(src.action.targetUsagePercentage) + ' %' :  '-'}
          </Text>

          {
            src.action.targetUsagePercentage && src.action.target ?
              <Text style={styles.dqText}>
                {'(' + formatUnits(src.action.target) + currentUnit(src.action.impact) + ')'}
              </Text> : null
          }
        </View>

        <View style={[styles.tableBorder,styles.tablePadding, index % 2 === 0 ? styles.bgColorGrey : null, styles.pCost]}>
          <Text style={[styles.dqText]}>
            {src.action.currency && src.action.cost ? costMask[CurrencyUnitToLabel(currencyUnit)] : ''}
            {src.action.cost === null  || src.action.cost === '' ? '-' : ' '+formatUnits(src.action.cost)}
          </Text>
        </View>

        <View style={[styles.tableBorder, styles.tablePadding, index % 2 === 0 ? styles.bgColorGrey : null, styles.pScope]}>
          <Text style={[styles.dqText, {}]}>{areaCoveredMask[src.action.areaCovered]}</Text>
        </View>
      </View>
    )
  })
  return (
    <View>
      <View style={[styles.tableTitle, styles.tableRow,styles.greyColor,props.isDashboard ? styles.top390 : styles.top310]}>
        <View style={styles.box}><Text style={styles.mL2}>{I18n('t_date')}</Text></View>
        <View style={styles.pAction}><Text style={styles.mL2}>{I18n('t_action')}</Text></View>
        <View style={styles.box}><Text style={styles.mL2}>{I18n('t_impact')}</Text></View>
        <View style={styles.pReduction}><Text style={styles.mL2}>{I18n('t_reduction')}</Text></View>
        <View style={styles.pCost}><Text style={styles.mL2}>{I18n('t_cost')}</Text></View>
        <View style={styles.pScope}><Text style={styles.mL2}>{I18n('t_scope')}</Text></View>
      </View>
      <View style={[props.isDashboard ? styles.dashboardTable : styles.passportTable]}>
        {tableToPrint}
      </View>
    </View>
  )
}

export default ActionPlan