import {
  addAdditionalStylesAndData,
  applyFontStylesForTitlesAndTableHeader,
  applyStylesForSummaryTable,
  convertToExcelExportFormat,
  formatDateToDDMMYY,
  generateExcel,
  getDateRangeAndTimePeriodInfo,
  getEmptyRows,
  getFundUtilitiesList,
  getSheetName,
  getTableHeadersForExcelObject,
  getTitleObject,
  getValidData,
  LEVEL_OF_DETAILS,
  TIME_PERIOD_GRANULARITY,
  UTILITY_MAP_FOR_PERFORMANCE_EXPORT,
  getFundExcelTitleName,
  LIST_OF_TYPES_FOR_THERMAL_UTILITY,
  getNumberOfMonthsAndEndMonth,
  getRequiredUnit,
  getLocalizedColumnNamesWithUnits,
  UNIT_TYPE_FOR_EXPORT,
  EXPORT_TYPES,
  excelSummaryTableData,
} from '../../ExportHelper'
import { excelHelpers, formatDateToDdMonYy, getLocalizedValue } from '../../../helpers/Helper'
import {  monthsRepresentedByNumbers,  UTILITY_LABELS_OBJECT_TITLE_CASE,  UTILITY_OTHER_NAME_MAP,} from '../../../helpers/Constants'
import {
  getVarianceString,
  getHasNumberToString,
} from '../../../../components/pages/funds/performance/FundPerformanceSummary/FundPerformanceSummary'
import XlsxPopulate from 'xlsx-populate'
import FileSaver from 'file-saver'

function thermalsData(type, data) {
  const defaultDataType = {
    consumption: null,
    currentCarbonEmission: null,
    intensity: null,
    missing: null,
    monthlyConsumption: null,
    previousCarbonEmission: null,
    previousConsumption: null,
    previousMissing: null,
    reportingPeriod: null,
    targetToDelta: null,
    totalIntensity: null,
    usesTarget: null,
    utility: type,
  }
  if (Array.isArray(data) && data.length) {
    const item = data[0]
    item.utility = type
    return item
  }
  return defaultDataType
}

function mappedAssetData(completeAssetListInfo) {
  const listThermalUtility = LIST_OF_TYPES_FOR_THERMAL_UTILITY.slice(0, 4)
  const assetDataList = completeAssetListInfo?.data || []
  return assetDataList.map((asset) => {
    let hasThermalData = false
    const thermalItem = listThermalUtility.map((utilName) => {
      if (asset[utilName]) {
        hasThermalData = true
      }
      return thermalsData(utilName, asset[utilName])
    })
    if (hasThermalData) {
      asset.thermals = thermalItem
    } else {
      asset.thermals = []
    }
    return asset
  })
}

function fundBenchmarkAndStandardBenchmarkTitle(fundNumberOfMonths,hasWater=false){
  if(Number(fundNumberOfMonths) !== 12){
    return []
  }
  const unitType = hasWater ? UNIT_TYPE_FOR_EXPORT.WATER_INTENSITY : UNIT_TYPE_FOR_EXPORT.ENERGY_INTENSITY
  return [
    {key: 't_industry_standard_benchmark',unit:getRequiredUnit(unitType, null, null)},
    {key: 't_variance_against_industry_standard_benchmark'},
  ]
}

function fundBenchmarkAndStandardBenchmarkData(dataItem, selectedLanguage, fundNumberOfMonths){
  if(Number(fundNumberOfMonths) !== 12) {
    return []
  }
  return [
    getValidData(dataItem?.benchmarkValue, selectedLanguage) === '-' ? getLocalizedValue(selectedLanguage, 't_not_available')  : getValidData(dataItem?.benchmarkValue, selectedLanguage),
    getValidData(dataItem?.benchmarkVariance, selectedLanguage)=== '-' ? getLocalizedValue(selectedLanguage, 't_not_available')  : getValidData(dataItem?.benchmarkVariance, selectedLanguage),
  ]
}

function getFundColumnNamesForAnnualExport(utility, fundNumberOfMonths) {
  let annualCommonTableHeaders = [
    {key: 't_asset_ref'}, {key: 't_name'}, {key: 't_fund'}, { key: 't_country'}, {key: 't_city'},
    {key: 't_sector'}, {key: 't_gresb_sector'}, { key: 't_purchase_date'},
    {key: 't_sale_date'}, {key: 't_total_asset_floor_area', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.AREA, null, null)},
    {key: 't_occupancy_excel'},{key: 't_is_the_asset_tenant'},
  ]
  switch (utility) {
  case UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE:

    return [...annualCommonTableHeaders,{key: 't_reporting_period'},
      {key: 't_hazardous_waste', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_non_hazardous_waste', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_total_waste_generated', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_landfill_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_incinerated', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_reuse_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_waste_to_energy_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_recycled_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_unknown_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)}]
  case UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY:

    return  [...annualCommonTableHeaders,{key: 't_current_energy_consumption_with_gapFilled_data', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)},
      {key: 't_previous_energy_consumption_kwh_with_gapFilled_data',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)}, //NOTE
      {key: 't_current_total_carbon_emissions',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_previous_period_total_carbon_emissions',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_current_energy_consumption_with_gapFilled_data', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)},
      {key: 't_previous_energy_consumption_kwh_with_gapFilled_data',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)}, //NOTE
      {key: 't_current_total_carbon_emissions',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_previous_period_total_carbon_emissions',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_over_under_target_excel'},
      {key: 't_intensity_excel',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_INTENSITY, null, null)}]

  case UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL:

    return  [...annualCommonTableHeaders,{key: 't_utility_type'},{key: 't_current_consumption_with_gapFilled_data', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)},
      {key: 't_previous_period_consumption_with_gapFilled_data',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)},
      {key: 't_current_total_carbon_emissions',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_previous_period_total_carbon_emissions',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_current_consumption_with_gapFilled_data', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)},
      {key: 't_previous_period_consumption_with_gapFilled_data',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)},
      {key: 't_current_total_carbon_emissions',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_previous_period_total_carbon_emissions',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_over_under_target_excel'},
      {key: 't_intensity_excel',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_INTENSITY, null, null)},
      ...fundBenchmarkAndStandardBenchmarkTitle(fundNumberOfMonths)]
  case UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON:

    return [...annualCommonTableHeaders,{key: 't_reporting_period'},
      {key: 't_scope_1_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_scope_2_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_scope_3_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_total_carbon_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_scope_1_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_scope_2_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_scope_3_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_total_carbon_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_carbon_intensity_excel', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.CARBON_INTENSITY, null, null)},
      {key: 't_over_under_target_excel'}]
  case UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY:

    return  [...annualCommonTableHeaders,{key: 't_current_period_consumption_with_gapFilled_data', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)},
      {key: 't_previous_period_consumption_with_gapFilled_data',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)},
      {key: 't_current_total_carbon_emissions',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_previous_period_total_carbon_emissions',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_current_period_consumption_with_gapFilled_data', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)},
      {key: 't_previous_period_consumption_with_gapFilled_data',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)},
      {key: 't_current_total_carbon_emissions',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_previous_period_total_carbon_emissions',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_over_under_target_excel'},
      {key: 't_intensity_excel',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_INTENSITY, null, null)},
      ...fundBenchmarkAndStandardBenchmarkTitle(fundNumberOfMonths),
    ]
  case UTILITY_LABELS_OBJECT_TITLE_CASE.WATER:

    return  [...annualCommonTableHeaders,{key: 't_current_consumption_with_gapFilled_data', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.WATER_CONSUMPTION, null, null)},
      {key: 't_previous_period_consumption_with_gapFilled_data', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.WATER_CONSUMPTION, null, null)},
      {key: 't_current_consumption_with_gapFilled_data', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.WATER_CONSUMPTION, null, null)},
      {key: 't_previous_period_consumption_with_gapFilled_data', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.WATER_CONSUMPTION, null, null)},
      {key: 't_over_under_target_excel'},
      {key: 't_water_intensity_excel', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.WATER_INTENSITY, null, null)},
      ...fundBenchmarkAndStandardBenchmarkTitle(fundNumberOfMonths,true),
    ]

  }
}

function getFundTableInfoForAnnualExport(  utility,  fundTableDataItem,  selectedLanguage, fundNumberOfMonths) {
  function setFirstColumnTableValue(fundItem) {
    return [
      getValidData(fundItem.assetReference, selectedLanguage),
      getValidData(fundItem.name, selectedLanguage),
      getValidData(fundItem.fundName, selectedLanguage),
      getValidData(fundItem.assetLocation, selectedLanguage),
      getValidData(fundItem.assetCity, selectedLanguage),
      getValidData(fundItem.sector, selectedLanguage),
      getValidData(fundItem.gresbSector, selectedLanguage),
      formatDateToDDMMYY(fundItem.assetPurchaseDate, selectedLanguage),
      formatDateToDDMMYY(fundItem.dateOfSale, selectedLanguage),
      getValidData(fundItem.floorArea, selectedLanguage),
      getValidData(fundItem.occupancy, selectedLanguage),
      getValidData(fundItem.isTenantControlled, selectedLanguage),
    ]
  }

  function setSecondColumnValue(rowItem) {
    return [
      getValidData(rowItem?.consumption, selectedLanguage),
      getValidData(rowItem?.previousConsumption, selectedLanguage),
      getValidData(rowItem?.currentCarbonEmission, selectedLanguage),
      getValidData(rowItem?.previousCarbonEmission, selectedLanguage),
      getValidData(rowItem?.actualConsumption, selectedLanguage),
      getValidData(rowItem?.actualPreviousConsumption, selectedLanguage),
      getValidData(rowItem?.actualCurrentCarbonConsumption, selectedLanguage),
      getValidData(rowItem?.actualPreviousCarbonConsumption, selectedLanguage),
      getHasNumberToString(rowItem?.targetToDelta, selectedLanguage),
      getValidData(rowItem?.totalIntensity, selectedLanguage),
      ...fundBenchmarkAndStandardBenchmarkData(rowItem, selectedLanguage, fundNumberOfMonths)
    ]
  }

  function getAssetTableRowData(rowItem, utility, fundItem) {
    const firstColumnValue = setFirstColumnTableValue(fundItem)
    const secondColumnValue = setSecondColumnValue(rowItem)
    switch (utility) {
    case UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON:
      return [
        ...firstColumnValue,
        getValidData(rowItem?.reportingPeriod, selectedLanguage),
        getValidData(rowItem?.scope1, selectedLanguage),
        getValidData(rowItem?.scope2, selectedLanguage),
        getValidData(rowItem?.scope3, selectedLanguage),
        getValidData(rowItem?.consumption, selectedLanguage),
        getValidData(rowItem?.actualScope1, selectedLanguage),
        getValidData(rowItem?.actualScope2, selectedLanguage),
        getValidData(rowItem?.actualScope3, selectedLanguage),
        getValidData(rowItem?.actualConsumption, selectedLanguage),
        getValidData(rowItem?.totalIntensity, selectedLanguage),
        getHasNumberToString(rowItem?.targetToDelta, selectedLanguage),
      ]

    case UTILITY_LABELS_OBJECT_TITLE_CASE.WATER:
      return [...firstColumnValue, getValidData(rowItem?.consumption, selectedLanguage),
        getValidData(rowItem?.previousConsumption, selectedLanguage),
        getValidData(rowItem?.actualConsumption, selectedLanguage),
        getValidData(rowItem?.actualPreviousConsumption, selectedLanguage),
        getHasNumberToString(rowItem?.targetToDelta, selectedLanguage),
        getValidData(rowItem?.totalIntensity, selectedLanguage),...fundBenchmarkAndStandardBenchmarkData(rowItem, selectedLanguage, fundNumberOfMonths)]

    case UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE:
      return [
        ...firstColumnValue,
        getValidData(rowItem?.reportingPeriod, selectedLanguage),
        getValidData(rowItem?.hazardous, selectedLanguage),
        getValidData(rowItem?.nonHazardous, selectedLanguage),
        getValidData(rowItem?.total, selectedLanguage),
        getValidData(rowItem?.landfill, selectedLanguage),
        getValidData(rowItem?.incinerated, selectedLanguage),
        getValidData(rowItem?.reuse, selectedLanguage),
        getValidData(rowItem?.wasteToEnergy, selectedLanguage),
        getValidData(rowItem?.recycled, selectedLanguage),
        getValidData(rowItem?.unknown, selectedLanguage),
      ]

    case UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY:
      return [...firstColumnValue, ...secondColumnValue]

    case UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY:
      return [
        ...firstColumnValue,
        getValidData(rowItem?.consumption, selectedLanguage),
        getValidData(rowItem?.previousConsumption, selectedLanguage),
        getValidData(rowItem?.currentCarbonEmission, selectedLanguage),
        getValidData(rowItem?.previousCarbonEmission, selectedLanguage),
        getValidData(rowItem?.actualConsumption, selectedLanguage),
        getValidData(rowItem?.actualPreviousConsumption, selectedLanguage),
        getValidData(rowItem?.actualCurrentCarbonConsumption, selectedLanguage),
        getValidData(rowItem?.actualPreviousCarbonConsumption, selectedLanguage),
        getHasNumberToString(rowItem?.targetToDelta, selectedLanguage),
        getValidData(rowItem?.intensity, selectedLanguage),
      ]

    case UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL:
      return [
        ...firstColumnValue,
        getValidData(rowItem.utility, selectedLanguage),
        ...secondColumnValue,
      ]
    }
  }

  // execution starts here...
  let fundTableData = []
  let fundAssetTableNumberOfRow = 0
  fundTableDataItem.forEach((fundItem) => {

    const utilityConsumptionInfoOfFund =
        fundItem[UTILITY_MAP_FOR_PERFORMANCE_EXPORT[utility]]
    if (utilityConsumptionInfoOfFund && utilityConsumptionInfoOfFund?.length > 0) {
      utilityConsumptionInfoOfFund.forEach((rowItem) => {
        fundAssetTableNumberOfRow += 1
        if (rowItem.utility === undefined || fundItem[UTILITY_MAP_FOR_PERFORMANCE_EXPORT[rowItem.utility]]) {
          const fundTableRowData = getAssetTableRowData(
            rowItem,
            utility,
            fundItem
          )
          fundTableData.push(convertToExcelExportFormat(fundTableRowData))
        }
      })
    }
  })
  return { fundTableData, fundAssetTableNumberOfRow }
}

function getFundTableColumnNamesForAnnualByMeterExport(utility) {

  let firstCommonHeader = [
    {key: 't_asset_ref'}, {key: 't_asset_name'}, {key: 't_fund'}, { key: 't_country'}, {key: 't_city'},
    {key: 't_sector'}, {key: 't_gresb_sector'}, { key: 't_purchase_date'},
    {key: 't_sale_date'}]

  let secondCommonHeader = [
    {key: 't_meter_name'}, {key: 't_serial_number'}, {key: 't_comment'}, { key: 't_area_covered'},
    {key: 't_procured_by'}, {key: 't_status'}, { key: 't_active_from'},
    {key: 't_active_to'}, {key: 't_is_this_a_submeter'}]
  switch (utility) {
  case UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE:

    return [...firstCommonHeader,  {key: 't_waste_disposal_route_reference'},  {key: 't_contractor'},
      {key: 't_description'},  {key: 't_reporting_period'},  {key: 't_waste_type'},
      {key: 't_hazardous_waste', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_non_hazardous_waste', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_total_waste_generated', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_landfill_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_incinerated', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_reuse_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_waste_to_energy_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_recycled_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_unknown_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)}]
  case UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON:

    return [...firstCommonHeader,  {key: 't_meter_name'},
      {key: 't_serial_number'},  {key: 't_utility_type'},...secondCommonHeader.slice(2),
      {key: 't_floor_area_served',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.AREA, null, null)},
      {key: 't_green_tariff'}, {key: 't_scope'},
      {key: 't_current_total_carbon_emissions', unit : getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_current_period_gapFilled_data'},
      {key: 't_previous_period_carbon_emissions', unit : getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_previous_period_gapFilled_data'}]
  case UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY:


    return  [...firstCommonHeader, ...secondCommonHeader,
      {key: 't_floor_area_served', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.AREA, null, null)},
      {key: 't_green_tariff'},
      {key: 't_current_annual_consumption_includes_gap_filled_data',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)},
      {key: 't_current_year_gap'},
      {key: 't_previous_year_total_consumption_includes_gap_filled_data',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)},
      {key: 't_previous_period_gapFilled_data'},
      {key: 't_current_total_carbon_emissions',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_scope'}]
  case UTILITY_LABELS_OBJECT_TITLE_CASE.WATER:

    return  [...firstCommonHeader, ...secondCommonHeader,
      {key: 't_floor_area_served', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.AREA, null, null)},
      {key: 't_current_annual_consumption_includes_gap_filled_data',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.WATER_CONSUMPTION, null, null)},
      {key: 't_current_period_gapFilled_data'},
      {key: 't_previous_periods_total_consumption_includes_gap_filled_data',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.WATER_CONSUMPTION, null, null)},
      {key: 't_previous_period_gapFilled_data'}]
  case UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY:

    return  [...firstCommonHeader,
      {key: 't_total_asset_floor_area', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.AREA, null, null)},
      {key: 't_utility_type'}, ...secondCommonHeader,
      {key: 't_floor_area_served_by_meter',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.AREA, null, null)},
      {key: 't_green_tariff'},
      {key: 't_current_annual_consumption_includes_gap_filled_data', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)},
      {key: 't_current_year_gap'},
      {key: 't_previous_year_total_consumption_includes_gap_filled_data',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)},
      {key: 't_previous_year_gap'},
      {key: 't_current_total_carbon_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},{key: 't_scope'}]

  case UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL:

    return  [...firstCommonHeader,{key: 't_meter_name'},{key: 't_serial_number'},{key: 't_utility_type'},
      ...secondCommonHeader.slice(2), {key: 't_floor_area_served', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.AREA, null, null)},
      {key: 't_green_tariff'},{key: 't_current_annual_consumption_includes_gap_filled_data',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)},
      {key: 't_current_period_gapFilled_data'},
      {key: 't_previous_periods_total_consumption_include_gap_filled_data',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)},
      {key: 't_previous_period_gapFilled_data'},
      {key: 't_current_total_carbon_emissions',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_scope'}]
  }
}

function fundTableAnnualByMeterExport(  utility,  assetTableData,  selectedLanguage) {
  function firstFundTableRowData(asset) {
    return [
      getValidData(asset?.assetReference, selectedLanguage),
      getValidData(asset?.name, selectedLanguage),
      getValidData(asset?.fundName, selectedLanguage),
      getValidData(asset?.assetLocation, selectedLanguage),
      getValidData(asset.assetCity, selectedLanguage),
      getValidData(asset?.sector, selectedLanguage),
      getValidData(asset?.gresbSector, selectedLanguage),
      formatDateToDDMMYY(asset?.assetPurchaseDate, selectedLanguage),
      formatDateToDDMMYY(asset?.dateOfSale, selectedLanguage),
    ]
  }
  function secondFundTableRowData(utilityConsumptionInfo) {
    return [
      getValidData(utilityConsumptionInfo?.meterName, selectedLanguage),
      getValidData(utilityConsumptionInfo?.serialNumber, selectedLanguage),
      getValidData(utilityConsumptionInfo?.meterComment, selectedLanguage),
      getValidData(utilityConsumptionInfo?.areaCovered, selectedLanguage),
      getValidData(utilityConsumptionInfo?.procuredBy, selectedLanguage),
      getValidData(utilityConsumptionInfo?.meterStatus, selectedLanguage),
      getValidData(utilityConsumptionInfo?.activeFrom, selectedLanguage),
      getValidData(utilityConsumptionInfo?.activeTo, selectedLanguage),
      getValidData(utilityConsumptionInfo?.isSubMeter, selectedLanguage),
    ]
  }
  function thirdFundTableData(utilityConsumptionInfo) {
    return [
      getValidData(utilityConsumptionInfo.nlaServed, selectedLanguage),
      getValidData(utilityConsumptionInfo.greenTariff, selectedLanguage),
      getValidData(utilityConsumptionInfo.currentConsumption, selectedLanguage),
      getValidData(utilityConsumptionInfo.currentGapFilled, selectedLanguage),
      getValidData(
        utilityConsumptionInfo.previousConsumption,
        selectedLanguage
      ),
      getValidData(utilityConsumptionInfo.previousGapFilled, selectedLanguage),
      getValidData(
        utilityConsumptionInfo.currentCarbonEmission,
        selectedLanguage
      ),
      getValidData(utilityConsumptionInfo.scope, selectedLanguage),
    ]
  }
  function getAssetTableRowData(utilityConsumptionInfo, utility, asset) {
    const getFirstFundRowData = firstFundTableRowData(asset)
    const getSecondFundTableRowData = secondFundTableRowData(
      utilityConsumptionInfo
    )
    const getThirdFundTableData = thirdFundTableData(utilityConsumptionInfo)

    switch (utility) {
    case UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON:
      return [
        ...getFirstFundRowData,
        ...getSecondFundTableRowData.slice(0, 2),
        getValidData(utilityConsumptionInfo.utilityType, selectedLanguage),
        ...getSecondFundTableRowData.slice(2),
        ...getThirdFundTableData.slice(0, 2),
        getValidData(utilityConsumptionInfo.scope, selectedLanguage),
        getValidData(    utilityConsumptionInfo.currentCarbonEmission,    selectedLanguage),
        getValidData(    utilityConsumptionInfo.currentGapFilled,    selectedLanguage),
        getValidData(    utilityConsumptionInfo.previousCarbonEmission,    selectedLanguage),
        getValidData(    utilityConsumptionInfo.previousGapFilled,    selectedLanguage),
      ]
    case UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY:
      return [
        ...getFirstFundRowData,
        ...getSecondFundTableRowData,
        ...getThirdFundTableData,
      ]
    case UTILITY_LABELS_OBJECT_TITLE_CASE.WATER:
      return [
        ...getFirstFundRowData,
        ...getSecondFundTableRowData,...getThirdFundTableData.slice(0, 1),
        ...getThirdFundTableData.slice(2, 6),
      ]
    case UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE:
      return [
        ...getFirstFundRowData,
        getValidData(    utilityConsumptionInfo.wasteDestination,    selectedLanguage),
        getValidData(    utilityConsumptionInfo.wasteContractor,    selectedLanguage),
        getValidData(    utilityConsumptionInfo.wasteCollectionDescription,    selectedLanguage),
        getValidData(    utilityConsumptionInfo.reportingPeriod,    selectedLanguage),
        getValidData(utilityConsumptionInfo.wasteStream, selectedLanguage),
        getValidData(utilityConsumptionInfo?.hazardous, selectedLanguage),
        getValidData(utilityConsumptionInfo?.nonHazardous, selectedLanguage),
        getValidData(utilityConsumptionInfo?.tonnes, selectedLanguage),
        getValidData(utilityConsumptionInfo?.landfill, selectedLanguage),
        getValidData(utilityConsumptionInfo?.incinerated, selectedLanguage),
        getValidData(utilityConsumptionInfo?.reuse, selectedLanguage),
        getValidData(    utilityConsumptionInfo?.incineratedWer,    selectedLanguage),
        getValidData(utilityConsumptionInfo?.recycled, selectedLanguage),
        getValidData(utilityConsumptionInfo?.mrfUnknown, selectedLanguage),
      ]
    case UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY:
      return [
        ...getFirstFundRowData,
        getValidData(asset.floorArea, selectedLanguage),
        getValidData(utilityConsumptionInfo.utilityType, selectedLanguage),
        ...getSecondFundTableRowData,
        ...getThirdFundTableData,
      ]
    case UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL:
      return [
        ...getFirstFundRowData,
        ...getSecondFundTableRowData.slice(0, 2),
        getValidData(utilityConsumptionInfo.utilityType, selectedLanguage),
        ...getSecondFundTableRowData.slice(2),
        ...getThirdFundTableData,
      ]
    }
  }

  let fundTableData = []
  let fundAssetTableNumberOfRow = 0
  if (assetTableData) {
    // to check if the asset list is empty or not
    assetTableData.forEach((asset) => {
      let utilityConsumptionInfoOfAssetArray =
          asset[UTILITY_MAP_FOR_PERFORMANCE_EXPORT[utility]]
      if (utilityConsumptionInfoOfAssetArray.length > 0) {
        // to check if the utility has data or not
        utilityConsumptionInfoOfAssetArray.forEach((utilityConsumptionInfo) => {
          fundAssetTableNumberOfRow += 1
          let assetTableRowData = getAssetTableRowData(    utilityConsumptionInfo,    utility,    asset)
          fundTableData.push(convertToExcelExportFormat(assetTableRowData))
        })
      }
    })
  }

  return { fundTableData, fundAssetTableNumberOfRow }
}

function fundTableColumnForMonthlyExport(utility, fundNumberOfMonths) {

  let firstCommonTableHeader = [
    {key: 't_asset_ref'}, {key: 't_asset_name'}, {key: 't_fund'}, { key: 't_country'}, {key: 't_city'},
    {key: 't_sector'}, {key: 't_gresb_sector'}, { key: 't_purchase_date'},
    {key: 't_sale_date'}, {key: 't_total_asset_floor_area', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.AREA, null, null)},
    {key: 't_occupancy_excel'},{key: 't_is_the_asset_tenant'}, { key: 't_reporting_period'}
  ]

  let secondCommonTableHeader = [
    {key: 't_total_consumption', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)},
    {key: 't_reporting_period_gap_filler_data_percentage'}, {key: 't_over_under_target_excel'},
    { key: 't_intensity_excel', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_INTENSITY, null, null)},
  ]

  switch (utility) {
  case UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON:

    return [...firstCommonTableHeader,
      {key: 't_current_total_carbon_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_reporting_period_gap_filler_data_percentage'},
      {key: 't_carbon_intensity_excel', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.CARBON_INTENSITY, null, null)},
      {key: 't_over_under_target_excel'},
      {key: 't_scope_1_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_scope_2_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_scope_3_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
    ]
  case UTILITY_LABELS_OBJECT_TITLE_CASE.WATER:

    return  [...firstCommonTableHeader,{key: 't_total_consumption', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.WATER_CONSUMPTION, null, null)},
      {key: 't_reporting_period_gap_filler_data_percentage'}, {key: 't_over_under_target_excel'},
      {key: 't_water_intensity_excel', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.WATER_INTENSITY, null, null)}, ...fundBenchmarkAndStandardBenchmarkTitle(fundNumberOfMonths,true)]

  case UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE:

    return [...firstCommonTableHeader,{key: 't_month'},
      {key: 't_hazardous_waste', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_non_hazardous_waste', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_total_waste_generated', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_landfill_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_incinerated', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_reuse_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_waste_to_energy_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_recycled_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_unknown_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)}]
  case UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY:
    return [...firstCommonTableHeader, ...secondCommonTableHeader, ...fundBenchmarkAndStandardBenchmarkTitle(fundNumberOfMonths),
      {key: 't_reporting_period_carbon_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)}]
  case UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY:
    return [...firstCommonTableHeader, ...secondCommonTableHeader, {key: 't_reporting_period_carbon_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)}]
  case UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL:
    return [...firstCommonTableHeader,{key: 't_utility_type'}, ...fundBenchmarkAndStandardBenchmarkTitle(fundNumberOfMonths), ...secondCommonTableHeader, {key: 't_reporting_period_carbon_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)}]
  }
}

function attachMonthColumnsToAssetTable(  utility,  assetTableColumnNamesLocalized,  selectedLanguage,  timePeriod,  pickerValue) {
  const monthAndMonthsObject = getNumberOfMonthsAndEndMonth(
    timePeriod,
    pickerValue
  )
  const monthRepresentingNumbersArray = Array.from(
    { length: monthAndMonthsObject?.numberOfMonths },
    (_, index) => (monthAndMonthsObject.endMonth - index + 12) % 12
  ).reverse()
  let monthColumns
  switch (utility) {
  case UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON:
    monthColumns = monthRepresentingNumbersArray.map((monthNumber) =>`${getLocalizedValue(selectedLanguage, 't_emissions')} ${monthsRepresentedByNumbers[monthNumber]}  (${getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION_KG, null, null)})`)
    assetTableColumnNamesLocalized.splice(12, 0, ...monthColumns)
    break
  case UTILITY_LABELS_OBJECT_TITLE_CASE.WATER:
    monthColumns = monthRepresentingNumbersArray.map((monthNumber) =>`${getLocalizedValue(selectedLanguage, 't_consumption')} ${monthsRepresentedByNumbers[monthNumber]}  (${getRequiredUnit(UNIT_TYPE_FOR_EXPORT.WATER_CONSUMPTION, null, null)})`)
    assetTableColumnNamesLocalized.splice(12, 0, ...monthColumns)
    break
  case UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY:
  case UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY:
    monthColumns = monthRepresentingNumbersArray.map((monthNumber) =>`${getLocalizedValue(selectedLanguage, 't_consumption')} ${monthsRepresentedByNumbers[monthNumber]}  (${getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)})`)
    assetTableColumnNamesLocalized.splice(12, 0, ...monthColumns)
    break
  case UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL:
    monthColumns = monthRepresentingNumbersArray.map((monthNumber) =>`${getLocalizedValue(selectedLanguage, 't_consumption')} ${ monthsRepresentedByNumbers[monthNumber]}  (${getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)})`)
    assetTableColumnNamesLocalized.splice(13, 0, ...monthColumns)
    break
  }
  return assetTableColumnNamesLocalized
}

function fundRowInfoForMonthlyDataExport(    utility,    assetTableData,    selectedLanguage,    timePeriod,    pickerValue, fundNumberOfMonths) {
  function getAssetTableRowData(utilityConsumptionInfo, utility, asset) {
    function getMonthlyConsumption() {
      const monthAndMonthsObject = getNumberOfMonthsAndEndMonth(    timePeriod,    pickerValue)
      const monthRepresentingNumbersArray = Array.from(
        { length: monthAndMonthsObject.numberOfMonths },
        (_, index) => (monthAndMonthsObject.endMonth - index + 12) % 12
      ).reverse()
      let monthlyConsumptionArray = []
      monthRepresentingNumbersArray.forEach((monthNumber) => {
        let monthlyConsumption = 0
        utilityConsumptionInfo.monthlyConsumption[monthNumber + 1] !== undefined
          ? monthlyConsumptionArray.push(
            utilityConsumptionInfo.monthlyConsumption[monthNumber + 1]
          )
          : monthlyConsumptionArray.push(monthlyConsumption)
      })
      return monthlyConsumptionArray
    }

    let commonTableColumnValuesSet1 = [
      asset.assetReference,
      getValidData(asset.name, selectedLanguage),
      getValidData(asset.fundName, selectedLanguage),
      getValidData(asset.assetLocation, selectedLanguage),
      getValidData(asset.assetCity, selectedLanguage),
      getValidData(asset.sector, selectedLanguage),
      getValidData(asset.gresbSector, selectedLanguage),
      formatDateToDDMMYY(asset.assetPurchaseDate, selectedLanguage),
      formatDateToDDMMYY(asset.dateOfSale, selectedLanguage),
      getValidData(asset.floorArea, selectedLanguage),
      getValidData(asset.occupancy, selectedLanguage),
      getValidData(asset.isTenantControlled, selectedLanguage),
      getValidData(utilityConsumptionInfo.reportingPeriod, selectedLanguage),
    ]
    let commonTableColumnValuesSet2 = [
      getValidData(utilityConsumptionInfo.consumption, selectedLanguage),
      '-',
      getValidData(utilityConsumptionInfo.targetToDelta, selectedLanguage),
      getValidData(utilityConsumptionInfo.totalIntensity, selectedLanguage),
      ...fundBenchmarkAndStandardBenchmarkData(utilityConsumptionInfo, selectedLanguage, fundNumberOfMonths),
      getValidData(    utilityConsumptionInfo.currentCarbonEmission,    selectedLanguage),
    ]

    switch (utility) {
    case UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON:
      return [
        ...commonTableColumnValuesSet1,
        ...getMonthlyConsumption(),
        getValidData(utilityConsumptionInfo.consumption, selectedLanguage),
        '-',
        getValidData(utilityConsumptionInfo.totalIntensity, selectedLanguage),
        getHasNumberToString(utilityConsumptionInfo.targetToDelta, selectedLanguage),
        getValidData(utilityConsumptionInfo.scope1, selectedLanguage),
        getValidData(utilityConsumptionInfo.scope2, selectedLanguage),
        getValidData(utilityConsumptionInfo.scope3, selectedLanguage),
      ]

    case UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY:
      return [
        ...commonTableColumnValuesSet1,
        ...getMonthlyConsumption(),
        ...commonTableColumnValuesSet2,
      ]

    case UTILITY_LABELS_OBJECT_TITLE_CASE.WATER:
      return [
        ...commonTableColumnValuesSet1,
        ...getMonthlyConsumption(),
        getValidData(utilityConsumptionInfo.consumption, selectedLanguage),
        '-',
        getValidData(utilityConsumptionInfo.targetToDelta, selectedLanguage),
        getValidData(utilityConsumptionInfo.totalIntensity, selectedLanguage),
        ...fundBenchmarkAndStandardBenchmarkData(utilityConsumptionInfo, selectedLanguage, fundNumberOfMonths),
      ]

    case UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE:
      return [
        ...commonTableColumnValuesSet1,
        getValidData(utilityConsumptionInfo.month, selectedLanguage),
        getValidData(utilityConsumptionInfo.hazardous, selectedLanguage),
        getValidData(utilityConsumptionInfo.nonHazardous, selectedLanguage),
        getValidData(utilityConsumptionInfo.total, selectedLanguage),
        getValidData(utilityConsumptionInfo.landfill, selectedLanguage),
        getValidData(utilityConsumptionInfo.incinerated, selectedLanguage),
        getValidData(utilityConsumptionInfo.reuse, selectedLanguage),
        getValidData(utilityConsumptionInfo.wasteToEnergy, selectedLanguage),
        getValidData(utilityConsumptionInfo.recycled, selectedLanguage),
        getValidData(utilityConsumptionInfo.unknown, selectedLanguage),
      ]

    case UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY:
      return [
        ...commonTableColumnValuesSet1,
        ...getMonthlyConsumption(),
        getValidData(utilityConsumptionInfo.consumption, selectedLanguage),
        '-',
        getValidData(utilityConsumptionInfo.targetToDelta, selectedLanguage),
        getValidData(utilityConsumptionInfo.intensity, selectedLanguage),
        getValidData(    utilityConsumptionInfo.currentCarbonEmission,    selectedLanguage),
      ]

    case UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL:
      return [
        ...commonTableColumnValuesSet1,
        getValidData(utilityConsumptionInfo.utility, selectedLanguage),
        ...getMonthlyConsumption(),
        ...commonTableColumnValuesSet2,
      ]
    }
  }

  let fundTableData = []
  let fundAssetTableNumberOfRow = 0
  assetTableData.forEach((asset) => {
    let utilityConsumptionInfoOfAssetArray =
        asset[UTILITY_MAP_FOR_PERFORMANCE_EXPORT[utility]]
    utilityConsumptionInfoOfAssetArray.forEach((utilityConsumptionInfo) => {
      fundAssetTableNumberOfRow += 1
      let assetTableRowData = getAssetTableRowData(    utilityConsumptionInfo,    utility,    asset)
      fundTableData.push(convertToExcelExportFormat(assetTableRowData))
    })
  })
  return { fundTableData, fundAssetTableNumberOfRow }
}

function getSheetHeaderInformation(    utility,    getDateInfo,    selectedLanguage,    props) {
  let dateRange = getLocalizedValue(selectedLanguage, 't_date_range') +' : ' + getDateInfo.dateRange
  return [
    { A: getLocalizedValue(selectedLanguage, getFundExcelTitleName(utility)) },
    { A: `${getLocalizedValue(selectedLanguage, 't_fund')}: ${props?.fundName}`},
    { A: dateRange },
    {A: `${getLocalizedValue(selectedLanguage, 't_date_of_export')}: ${formatDateToDdMonYy()}` }
  ]
}

function getSummaryTableTitle(utility) {
  switch (utility) {
  case UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY:
    return 't_energy_summary'
  default:
    return 't_summary'
  }
}

function summaryTableColumnNames(utility, summaryRowData) {

  switch (utility) {
  case UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON:
    return [
      {key: 't_current_reporting_period_total_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},{key: 't_current_reporting_period_gap_filled_data_percentage'},
      {key: 't_previous_reporting_period_total_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},{key: 't_previous_reporting_period_gap_filled_data_percentage'},
      {key: 't_over_under_target_excel'}
    ]
  case UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY:
  case UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL:

    return [
      {key: 't_current_reporting_period_total_consumption', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, summaryRowData.value, utility, true)},
      {key: 't_current_reporting_period_gap_filled_data_percentage'},
      {key: 't_previous_reporting_period_total_consumption', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, summaryRowData.previousValue, utility, true)},
      {key: 't_previous_reporting_period_gap_filled_data_percentage'},{key: 't_over_under_target_excel'}
    ]
  case UTILITY_LABELS_OBJECT_TITLE_CASE.WATER:
    return [
      {key: 't_current_reporting_period_total_consumption', unit: getRequiredUnit(UNIT_TYPE_FOR_EXPORT.WATER_CONSUMPTION, null, null)},
      {key: 't_current_reporting_period_gap_filled_data_percentage'},
      {key: 't_previous_reporting_period_total_consumption', unit: getRequiredUnit(UNIT_TYPE_FOR_EXPORT.WATER_CONSUMPTION, null, null)},
      {key: 't_previous_reporting_period_gap_filled_data_percentage'},
      {key: 't_over_under_target_excel'}
    ]
  case UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE:
    return [
      {key: 't_current_reporting_period_total_waste', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_current_reporting_period_recycled_waste', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_current_percentage_recycled'},
      {key: 't_previous_reporting_period_total_waste', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_previous_reporting_period_recycled_waste', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_recycled_percentage_change_excel'}
    ]
  case UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY:
    return [
      {key: 't_current_reporting_period_total_energy_consumption', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, summaryRowData.value, utility, true)},
      {key: 't_current_reporting_gap_filled_energy_data_percentage'},
      {key: 't_previous_reporting_period_total_energy_consumption', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, summaryRowData.previousValue, utility, true)},
      {key: 't_previous_reporting_gap_filled_energy_data_percentage'},{key: 't_over_under_target_excel'}
    ]
  }
}

function getSummaryTableRowInfo(utility, summaryDataItem, selectedLanguage) {
  let percentageChangeInReCycledWaste =
      utility === UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE &&
      getVarianceString(summaryDataItem?.variance, selectedLanguage)
  switch (utility) {
  case UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE:
    return [
      excelSummaryTableData(summaryDataItem?.value, utility),
      excelSummaryTableData(summaryDataItem?.totalRecycled, utility),
      getValidData(summaryDataItem?.totalRecycledPercentage,    selectedLanguage,    true),
      excelSummaryTableData(summaryDataItem?.previousValue, utility),
      excelSummaryTableData(summaryDataItem?.previousRecycledWaste, utility),
      percentageChangeInReCycledWaste,
    ]
  default:
    return [
      excelSummaryTableData(summaryDataItem?.value,utility),
      getHasNumberToString(    summaryDataItem?.filledData || null,    selectedLanguage,    false),
      excelSummaryTableData(summaryDataItem?.previousValue, utility),
      getHasNumberToString(    summaryDataItem?.previousFilledData || null,    selectedLanguage,    false),
      getValidData(summaryDataItem?.variance, selectedLanguage, true),
    ]
  }
}

function getFundAssetTableInfo(utility, intl, levelOfDetailValue, timePeriodGranularityValue,selectedLanguage, props, fundCompleteListData) {
  let getFundTableColumnName
  let getFundTableRowInformation
  const fundNumberOfMonths = props?.pickerValue?.pickerValueMonths
  if (timePeriodGranularityValue === TIME_PERIOD_GRANULARITY.ANNUAL) {
    if (levelOfDetailValue === LEVEL_OF_DETAILS.asset) {
      const getFundColumnNameForAnnual = getFundColumnNamesForAnnualExport(utility, fundNumberOfMonths)
      getFundTableColumnName = getLocalizedColumnNamesWithUnits(  getFundColumnNameForAnnual, intl)
      getFundTableRowInformation = getFundTableInfoForAnnualExport(  utility,  fundCompleteListData,  selectedLanguage, fundNumberOfMonths)
    } else {
      const getFundColumnNameForAnnualByMeter =    getFundTableColumnNamesForAnnualByMeterExport(utility)
      getFundTableColumnName = getLocalizedColumnNamesWithUnits(  getFundColumnNameForAnnualByMeter, intl)
      getFundTableRowInformation = fundTableAnnualByMeterExport(  utility,  fundCompleteListData,  selectedLanguage)
    }
  } else if (levelOfDetailValue === LEVEL_OF_DETAILS.asset) {
    const getFundTableColumnForMonthly = fundTableColumnForMonthlyExport(utility, fundNumberOfMonths)
    const getFundHeaderName = getLocalizedColumnNamesWithUnits(  getFundTableColumnForMonthly,intl)
    getFundTableColumnName = attachMonthColumnsToAssetTable(  utility,  getFundHeaderName,  selectedLanguage,  props?.timePeriod,  props?.pickerValue)
    getFundTableRowInformation = fundRowInfoForMonthlyDataExport(  utility,  fundCompleteListData,  selectedLanguage,  props?.timePeriod,  props?.pickerValue, fundNumberOfMonths)
  }
  return {
    getFundTableColumnName,
    fundTableData: getFundTableRowInformation.fundTableData,
    fundTableNumberOfColumns: getFundTableColumnName.length,
    fundAssetTableNumberOfRow:
    getFundTableRowInformation.fundAssetTableNumberOfRow,
  }
}

export async function fundPerformanceExportHandler(props,  selectedLanguage,  fundHomeExportSelections,  completeFundSummaryInfo,  completeAssetListInfo , intl) {
  const fundCompleteListData = mappedAssetData(completeAssetListInfo)

  function populateDataForExcelExport(  utility,  timePeriodGranularityValue,  levelOfDetailValue) {

    let fundSheetData = []
    const getDateInfo = getDateRangeAndTimePeriodInfo(props?.timePeriod,  props?.pickerValue,  selectedLanguage)
    fundSheetData.push(...getSheetHeaderInformation(utility,getDateInfo,selectedLanguage,props))
    fundSheetData.push(...getEmptyRows(2))
    const grtSummaryTitle = getSummaryTableTitle(utility)
    fundSheetData.push(getTitleObject(grtSummaryTitle, selectedLanguage))
    const summaryDataItem = completeFundSummaryInfo?.[UTILITY_MAP_FOR_PERFORMANCE_EXPORT[utility]]
    const getSummaryTableColumnNames = summaryTableColumnNames(utility,   summaryDataItem )
    let summaryTableColumnNamesWithLocalization = getLocalizedColumnNamesWithUnits(getSummaryTableColumnNames, intl)
    fundSheetData.push(convertToExcelExportFormat(summaryTableColumnNamesWithLocalization))
    const summaryTableDataValue = getSummaryTableRowInfo(  utility,  summaryDataItem,  selectedLanguage)
    fundSheetData.push(getTableHeadersForExcelObject(summaryTableDataValue))
    fundSheetData.push(...getEmptyRows(2))
    fundSheetData.push(getTitleObject('t_asset_information', selectedLanguage))
    const fundTableAssetInfo = getFundAssetTableInfo(utility, intl, levelOfDetailValue, timePeriodGranularityValue, selectedLanguage,props,fundCompleteListData )
    fundSheetData.push(  getTableHeadersForExcelObject(fundTableAssetInfo.getFundTableColumnName))
    fundSheetData.push(...fundTableAssetInfo.fundTableData)
    return {
      fundSheetData,
      summaryTableNumberOfColumns: getSummaryTableColumnNames.length,
      summaryTableNumberOfRows: 1,
      assetTableNumberOfColumns:
      fundTableAssetInfo.getFundTableColumnName.length,
      fundAssetTableNumberOfRow: fundTableAssetInfo.fundAssetTableNumberOfRow,
    }
  }

  async function generateFundPerformanceSheet(utilityArray) {
    const timePeriodGranularityValue =
        fundHomeExportSelections[EXPORT_TYPES.FUND.FUND_PERFORMANCE].TIME_PERIOD_GRANULARITY.value
    const levelOfDetailValue = fundHomeExportSelections[EXPORT_TYPES.FUND.FUND_PERFORMANCE].LEVEL_OF_DETAIL.value
    const rangeObject = {
      [UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON]: {range_including: 'N12:Q12', range_excluding: 'R12:U12' },
      [UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY]: {range_including: 'M12:P12', range_excluding: 'Q12:T12' },
      [UTILITY_LABELS_OBJECT_TITLE_CASE.WATER]: {range_including: 'M12:N12', range_excluding: 'O12:P12' },
      [UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE]: {range_including: 'N12:P12', range_excluding: 'Q12:V12' },
      [UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY]: {range_including: 'M12:P12', range_excluding: 'Q12:T12' },
      [UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL]: {range_including: 'N12:Q12', range_excluding: 'R12:U12' }
    }
    const blob = await XlsxPopulate.fromBlankAsync()
      .then(workbook => {
        utilityArray.forEach((sheet, index) => {
          const sheetName = getSheetName(sheet.value, selectedLanguage)
          const excelSheet = index === 0 ? workbook.sheet(0).name(sheetName) : workbook.addSheet(sheetName)
          const populateSheetPerformanceData = populateDataForExcelExport(sheet.value, timePeriodGranularityValue, levelOfDetailValue)
          const errorMesage = getLocalizedValue(selectedLanguage, 't_no_export_data_available')
          if (!populateSheetPerformanceData?.fundAssetTableNumberOfRow || populateSheetPerformanceData?.fundAssetTableNumberOfRow?.length === 0) {
            populateSheetPerformanceData.fundSheetData.push({
              A: errorMesage
            })
          }
          const styleObject = { horizontalAlignment: 'center', border: true }
          if (sheet.value !== UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE) {
            excelSheet.range(rangeObject[sheet.value].range_including).value(getLocalizedValue(selectedLanguage, 't_including_gap_filled')).merged(true).style(styleObject)
            excelSheet.range(rangeObject[sheet.value].range_excluding).value(getLocalizedValue(selectedLanguage, 't_excluding_gap_filled')).merged(true).style(styleObject)
          } else {
            excelSheet.range(rangeObject[sheet.value].range_including).value(getLocalizedValue(selectedLanguage, 't_waste_generated')).merged(true).style(styleObject)
            excelSheet.range(rangeObject[sheet.value].range_excluding).value(getLocalizedValue(selectedLanguage, 't_destination')).merged(true).style(styleObject)
          }
          excelHelpers(populateSheetPerformanceData.fundSheetData, excelSheet, errorMesage)
        })

        workbook.activeSheet(getSheetName(utilityArray[0].value, selectedLanguage))
        return workbook.outputAsync(workbook)
      })
    FileSaver.saveAs(blob, getLocalizedValue(selectedLanguage, 't_fund_performance_excel'))

  }

  const exportSelectionType = fundHomeExportSelections[EXPORT_TYPES.FUND.FUND_PERFORMANCE].UTILITY.value
  if (exportSelectionType === UTILITY_OTHER_NAME_MAP.All) {
    const utilityDataItems = getFundUtilitiesList(selectedLanguage)
    utilityDataItems.unshift({
      label: getLocalizedValue(selectedLanguage, 't_energy_summary'),
      value: UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY,
    })
    await generateFundPerformanceSheet(utilityDataItems)
  } else {
    await generateFundPerformanceSheet([fundHomeExportSelections[EXPORT_TYPES.FUND.FUND_PERFORMANCE].UTILITY])
  }
}
