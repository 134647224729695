import React from 'react'
import {Skeleton} from 'primereact/skeleton'
import  '../../../pages/admin/users/users-home/UsersHome.scss'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'

const GroupsOverviewMembersSkeleton = () => {
  const gridValue = Array.from({ length:5 })

  const bodyTemplate = () => {
    return <Skeleton width={'15rem'}/>
  }

  return (
    <div className="custom-skeleton p-rel p-d-flex p-flex-column p-ai-center users-page-skeleton">
      <DataTable value={gridValue} className="group-users-grid">
        <Column field="code" body={bodyTemplate}/>
        <Column field="code" body={bodyTemplate}/>
      </DataTable>
    </div>
  )
}

export default GroupsOverviewMembersSkeleton
