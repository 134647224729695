import React from 'react'
import {Skeleton} from 'primereact/skeleton'
import './PerformanceHeadersSkeleton.scss'

const PerformanceHeadersSkeleton = () => {
  return (
    <div className="custom-skeleton rel flex flex-column ai-center performance-headers-skeleton">
      <div className="flex">
        <span className="performance-headers-skeleton-divider"/>
        <div className="mr-4 ml-3">
          <Skeleton width="75%" borderRadius="0" height="1.5rem"/>
          <Skeleton width="100%" borderRadius="0" height=".5rem" className="mt-2"/>
        </div>
        <span className="performance-headers-skeleton-divider"/>
        <div className="mr-4  ml-3">
          <Skeleton width="75%" borderRadius="0" height="1.5rem"/>
          <Skeleton width="100%" borderRadius="0" height=".5rem" className="mt-2"/>
        </div>
        <span className="performance-headers-skeleton-divider"/>
        <div className="mr-4  ml-3">
          <Skeleton width="75%" borderRadius="0" height="1.5rem"/>
          <Skeleton width="100%" borderRadius="0" height=".5rem" className="mt-2"/>
        </div>
      </div>
    </div>
  )
}

export default PerformanceHeadersSkeleton
