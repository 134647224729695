import {Text, View} from '@react-pdf/renderer'
import React, {useState} from 'react'
import styles from '../../../../../utils/data-exports/style-sheet/Styles'
import I18n from '../../../../../utils/i18n/I18n'
import {AREA, GAS, PDF_LABEL, UTILITY_OTHER_NAME_MAP} from '../../../../../utils/helpers/Constants'
import { unitsConverter } from '../../../../../utils/helpers/Helper'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const MissingData = (props) => {
  const [areaData] = useState(props.data)
  const months = Object.freeze({
    'Jan': 'January',
    'Feb': 'February',
    'Mar': 'March',
    'Apr': 'April',
    'May': 'May',
    'Jun': 'June',
    'Jul': 'July',
    'Aug': 'August',
    'Sep': 'September',
    'Oct': 'October',
    'Nov': 'November',
    'Dec': 'December'
  })

  const {loginState: {userInfo}} = useLoginAuthContext()
  const unitSystem = userInfo.unitSystem

  function floorUnit() {
    return unitsConverter(AREA,unitSystem)
  }


  function currentUnit(data) {
    return unitsConverter(data === GAS ? UTILITY_OTHER_NAME_MAP.Gas : data,unitSystem)
  }


  const formatUnits = (value) => {
    if (value != null) {
      return Number(value).toLocaleString(props.language)
    }
    return value
  }

  const insertSpace = (value) => {
    String.prototype.InsertAt=function(CharToInsert,Position){
      return this.slice(0,Position) + CharToInsert + this.slice(Position)
    }
    if (value != null && value.toString().length>13) {
      return value.toString().InsertAt(' ',13)
    }
    return value
  }

  const tableToPrint = () => areaData.map(function (src, index) {
    return (
      <View key={index} style={[styles.tableRowFlex, {}]} wrap={false}>
        <View style={[styles.tableBorder,styles.tablePadding,(index % 2 === 0) ? styles.bgColorWhite : styles.bgColorGrey,styles.dqMeter]}>
          <Text style={[styles.dqText]}>{src.electricityMPAN ? Array.from(src.electricityMPAN) : '-'}</Text>
        </View>

        <View style={[styles.tableBorder, styles.tablePadding,(index % 2) === 0 ? styles.bgColorWhite : styles.bgColorGrey,styles.dqType]}>
          <Text style={[styles.dqText, {}]}>{src.dateSummary ? src.dateSummary : '-'}</Text>
        </View>

        <View style={[styles.tableBorder,styles.tablePadding, (index % 2 === 0) ? styles.bgColorWhite : styles.bgColorGrey,styles.dqType]}>
          <Text style={[styles.dqText, {}]}>
            {src.utility ? I18n('t_' + src.utility.toLowerCase()) : '-'}
          </Text>
        </View>

        <View style={[styles.tableBorder,styles.tablePadding,(index % 2) === 0 ? styles.bgColorWhite : styles.bgColorGrey,styles.dqType]}>
          <Text style={[styles.dqText, {}]}>{src.provider ? insertSpace(src.provider) : '-'}</Text>
        </View>

        <View style={[styles.tableBorder,styles.tablePadding, (index % 2 === 0) ? styles.bgColorWhite : styles.bgColorGrey,styles.dqDate]}>
          <Text style={[styles.dqText]}>
            {(src.location ?  I18n('t_' +src.location.toLowerCase()) : '-')}
          </Text>
        </View>

        <View style={[styles.tableBorder,styles.tablePadding, (index % 2) === 0 ? styles.bgColorWhite : styles.bgColorGrey, styles.dqProvider]}>
          <Text style={[styles.dqText, {}]}>
            {src.floorArea ? formatUnits(src.floorArea) +' '+ floorUnit() : '-'}
          </Text>
        </View>

        <View style={[styles.tableBorder,styles.tablePadding,(index % 2 === 0) ? styles.bgColorWhite : styles.bgColorGrey,styles.dqProvider]}>
          <Text style={[styles.dqText, {}]}>{src.source ? src.source : '-'}</Text>
        </View>

        <View style={[styles.tableBorder,styles.tablePadding, (index % 2) === 0 ? styles.bgColorWhite : styles.bgColorGrey,styles.dqProvider]}>
          <Text style={[styles.dqText, {}]}>
            {src.consumption ? formatUnits(src.consumption) + '' + currentUnit(src.utility): '-'}
          </Text>
        </View>
      </View>
    )
  })

  const breakLine = () =>{
    return (
      <View style={[styles.mT20]}>
        <View style={[styles.dqBorderBottom,{}]}/>
      </View>
    )
  }

  return (
    <View style={styles.mT35}>
      <View tyle={[styles.mT5]} wrap={false}>
        {props.year !== null ?
          <Text style={[styles.title, styles.mB5]}>{PDF_LABEL[props.colorLabel]} - {props.year}</Text>
          : null
        }
        <Text style={[styles.dqSubTitle, styles.mB5]}>{areaData[0] ? months[areaData[0].date] ?  months[areaData[0].date]
          :props.data[0].dateSummary: null}</Text>
        <View style={[styles.tableTitle, styles.tableRow,styles.mT4,styles.mB5,styles.greyColor]}>
          <View style={styles.dqMeter}><Text style={styles.mL2}>{I18n('t_missing_data_meter')}</Text></View>
          <View style={styles.dqType}><Text style={styles.mL2}>{I18n('t_date')}</Text></View>
          <View style={styles.dqType}><Text style={styles.mL2}>{I18n('t_req_utility')}</Text></View>
          <View style={styles.dqType}><Text style={styles.mL2}>{I18n('t_provider')}</Text></View>
          <View style={styles.dqDate}><Text style={styles.mL2}>{I18n('t_location')}</Text></View>
          <View style={styles.dqProvider}><Text style={styles.mL2}>{I18n('t_floor_area')}</Text></View>
          <View style={styles.dqProvider}><Text style={styles.mL2}>{I18n('t_source')}</Text></View>
          <View style={styles.dqProvider}><Text style={styles.mL2}>{I18n('t_consumption')}</Text></View>
        </View>
      </View>
      <View>
        {tableToPrint()}
      </View>
      {props.isLastArray === false ? breakLine() : null}
    </View>
  )
}

export default MissingData