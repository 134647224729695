import { Icon, Text } from 'components/atomic'
import PropTypes from 'prop-types'
import Button from '../../Button/Button'

const InfoOverlayPanelContent = (props) => {
  const {iconId, header, panelContent, iconColour, buttonFlag, onAccept, onReject, headerClassName} = props
  return (<div>
    <div className={'d-flex mb-3'}>
      {iconId && <Icon size={17} id={iconId} colour={iconColour} />}
      <Text weight='bold' className={`${iconId ? 'ml-2' : ''} ${headerClassName}`} content={header} />
    </div>
    <div>
      {typeof panelContent=== 'string' ? <Text content={panelContent} /> : panelContent }
    </div>
    {buttonFlag && <div className='mt-2'>
      <Button onClick={onAccept} className='accept mr-2' content={'t_delete_yes'}/>
      <Button onClick={onReject} className='reject' content={'t_cancel'}/>
    </div>}
  </div>)
}

InfoOverlayPanelContent.propTypes = {
  iconId: PropTypes.string,
  header: PropTypes.string,
  panelContent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  iconColour: PropTypes.string,
  buttonFlag: PropTypes.bool,
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
  headerClassName: PropTypes.string
}

export default InfoOverlayPanelContent