import React from 'react'
import { Card } from 'primereact/card'
import { AuthCardFooter } from '../auth-card-footer/AuthCardFooter'
import { NavLink } from 'react-router-dom'
import I18n from '../../../../utils/i18n/I18n'

function ResetPasswordSuccess() {
  
  return (
    <div className="forgot-pwd-success min-width-760 full-height-minus-header">
      <div className="forgot-pwd-success-page">
        <div className="auth-card-container">
          <Card className={'auth-card'} title={I18n('t_done')} footer={AuthCardFooter}>
            <div className="content-auth-card-content">
              <div className="">
                <div className="forgot-msg">
                  {I18n('t_password_reset_success')}
                </div>
                <div className="content-sign-in-link">
                  <NavLink to="/login" className="login-nav">
                    {I18n('t_back_sign_in')}
                  </NavLink>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default ResetPasswordSuccess