import {alphaNumericRegex, SBT_VERSIONS, SCOPE_TARGET_MODE, specialCharacterRegex} from '../../../utils/helpers/Constants'
import {getLocalizedValue} from '../../../utils/helpers/Helper'
import {INT32_MAX_VALUE, MODEL_NAME_MAX_ALLOWED_CHARS, T_TARGET_PATHWAY_1, T_TARGET_PATHWAY_2, T_TARGET_PATHWAY_3, TARGET_PATHWAYS} from './nzc-constants'
import {Button} from 'primereact/button'
import I18n from '../../../utils/i18n/I18n'
import React from 'react'
import infoCircleMRed from '../../../resources/images/icon/info/info-circle-m-red.svg'
import CSS_VARIABLES from '../../../resources/css/_variables.scss'

export const isCreateOrEditAction = (scopeTargetState) => {
  return (scopeTargetState?.tpMode === SCOPE_TARGET_MODE.create || scopeTargetState?.tpMode === SCOPE_TARGET_MODE.edit)
}

export const isScopeTargetsNotEmpty = (targetPathwayData) => {
  return !!(targetPathwayData && targetPathwayData.scopeTargets?.filter(scopeTarget => TARGET_PATHWAYS.tp_1 === scopeTarget.targetPathwayNumber || TARGET_PATHWAYS.tp_2 === scopeTarget.targetPathwayNumber || TARGET_PATHWAYS.tp_3 === scopeTarget.targetPathwayNumber).length > 0)
}

export function getTPNumberForCreate(targetPathwayFormState) {
  let tpNumber
  if (!(targetPathwayFormState?.scopeTP3)) {
    tpNumber = TARGET_PATHWAYS.tp_3
  }
  if (!(targetPathwayFormState?.scopeTP2)) {
    tpNumber = TARGET_PATHWAYS.tp_2
  }
  if (!(targetPathwayFormState?.scopeTP1)) {
    tpNumber = TARGET_PATHWAYS.tp_1
  }
  return tpNumber
}

export function getTPNumber(scopeTargetState, targetPathwayFormState) {
  let tpNumber
  if (scopeTargetState?.tpMode === SCOPE_TARGET_MODE.edit) {
    tpNumber = scopeTargetState.activeIndex+1
  } else if (scopeTargetState?.tpMode === SCOPE_TARGET_MODE.create) {
    tpNumber = getTPNumberForCreate(targetPathwayFormState)
  }
  return tpNumber
}

export function getDefaultTPName(tpNumber, selectedLanguage) {
  let defaultTPName = ''
  switch (tpNumber) {
  case TARGET_PATHWAYS.tp_1:
    defaultTPName = getLocalizedValue(selectedLanguage, T_TARGET_PATHWAY_1)
    break
  case TARGET_PATHWAYS.tp_2:
    defaultTPName = getLocalizedValue(selectedLanguage, T_TARGET_PATHWAY_2)
    break
  case TARGET_PATHWAYS.tp_3:
    defaultTPName = getLocalizedValue(selectedLanguage, T_TARGET_PATHWAY_3)
    break
  default:
    break
  }
  return defaultTPName
}

export function getCustomTPNames(t1Data, t2Data, t3Data, customTPNames, selectedLanguage) {
  let tpNames = {}
  const TP1 = 'tp1'
  const TP2 = 'tp2'
  const TP3 = 'tp3'
  if (t1Data.length > 0) {
    tpNames[TP1] = customTPNames && customTPNames[TP1] ? customTPNames[TP1] : getLocalizedValue(selectedLanguage, T_TARGET_PATHWAY_1)
  }
  if (t2Data.length > 0) {
    tpNames[TP2] = customTPNames && customTPNames[TP2] ? customTPNames[TP2] : getLocalizedValue(selectedLanguage, T_TARGET_PATHWAY_2)
  }
  if (t3Data.length > 0) {
    tpNames[TP3] = customTPNames && customTPNames[TP3] ? customTPNames[TP3] : getLocalizedValue(selectedLanguage, T_TARGET_PATHWAY_3)
  }
  return tpNames
}

export function applyNoEnergySavingsActionFilter(updateUrlQueryParams, getQueryValuesForNZCAssets, selectedLanguage) {
  const queryParams= {
    asset: '',
    sector: getQueryValuesForNZCAssets('sector', {label : getLocalizedValue(selectedLanguage, 't_all_sectors'), value : 'All sectors'}).value,
    country: getQueryValuesForNZCAssets('country',{label : getLocalizedValue(selectedLanguage, 't_all_countries'), value : 'All countries'}).value,
    assetStatus: getLocalizedValue(selectedLanguage, 't_asset_without_energy_saving_action'),
    column: getQueryValuesForNZCAssets('column',{label: getLocalizedValue(selectedLanguage, 't_nzc_data_availability'), value: 'dataAvailablePercentage'}).value,
    sort: getQueryValuesForNZCAssets('sort','highestFirst')
  }
  updateUrlQueryParams(queryParams)
}

export function fromAndToYearsValidationCondition(fromYear, toYear) {
  return !!((fromYear >= toYear) || (fromYear < 1000) || (fromYear > 9999) || (toYear < 1000) || (toYear > 9999))
}

export function minMaxValidationCondition(minValue, maxValue) {
  const minMaxValueOverflow=minValue > INT32_MAX_VALUE || maxValue > INT32_MAX_VALUE
  return !!((minValue >= maxValue) || (minValue === '-') || (maxValue === '-') || (minValue === null) || (maxValue === null)) || minMaxValueOverflow
}

export function agreedActionsNotEmpty(actionBasedTPDetails) {
  return (actionBasedTPDetails?.agreedActions > 0)
}

export function applicableActionsNotEmpty(actionBasedTPDetails) {
  return (actionBasedTPDetails?.applicableActions > 0)
}

export function actionsDataNotEmpty(actionBasedTPDetails) {
  return !!(actionBasedTPDetails?.agreedActions > 0 || actionBasedTPDetails?.applicableActions > 0)
}

// For nzc page redirect popup
export function getUnsavedChangesPopupFooter(leaveWithoutSavingHandler, saveAndLeaveHandler){
  return(
    <div className="nzc-redirect-footer">
      <div className="footer-btns">
        <Button label={I18n('t_save_and_leave')} className={'save-and-leave'} onClick={saveAndLeaveHandler}/>
        <Button label={I18n('t_leave_without_saving')} className={'leave-without-saving'} onClick={leaveWithoutSavingHandler}/>
      </div>
    </div>
  )
}

export function getNzcRedirectPopupProps(unsavedChangesPopupState, leaveWithoutSavingHandler, saveAndLeaveHandler, updateUnsavedChangesPopupState) {
  return {
    className: 'no-footer nzc-page-redirect-popup',
    visible: unsavedChangesPopupState.showUnsavedChangesPopup,
    header: <><span className='icon-container'><img className='header-icon-l' src={infoCircleMRed} /></span><span className='header-title'>{I18n('t_unsaved_changes')}</span></>,
    message: I18n('t_unsaved_changes_message'),
    footer: () => getUnsavedChangesPopupFooter(leaveWithoutSavingHandler, saveAndLeaveHandler),
    onHide: () => updateUnsavedChangesPopupState({showUnsavedChangesPopup: false}),
  }
}

export function beforeTabChangeHandler(showPopup, tabIndex, updateUnsavedChangesPopupState) {
  if(showPopup) {
    updateUnsavedChangesPopupState({
      showUnsavedChangesPopup: true,
      nextTabIndex: tabIndex,
      isTabChangeAction: true
    })
    return false
  } else {
    return true
  }
}

export function resetRedirectPopupState(updateUnsavedChangesPopupState, beforeTabChangeActionRef) {
  beforeTabChangeActionRef.current = null
  updateUnsavedChangesPopupState({
    showUnsavedChangesPopup: false,
    nextTabIndex: 0,
    isTabChangeAction: false
  })
}

function sortObject(obj) {
  if (obj === null || typeof obj !== 'object') {
    return {}
  }

  return Object.keys(obj).sort().reduce((acc, key) => {
    acc[key] = obj[key]
    return acc
  }, {})
}


export function newSettingsChangesExist(targetPathwayDetailsObj, nzcSettingsData, customTargetPathwayName, selectedScopeTPData, targetPathWayData) {
  let targetAndPathwayTabDataFromDB={
    energySavingActions:nzcSettingsData?.energySavingActions,
    agreedActions:  nzcSettingsData?.agreedActions,
    applicableActions: nzcSettingsData?.applicableActions,
    scienceBasedTargetOption: nzcSettingsData?.scienceBasedTarget,
    progressTowardsTargetYear: nzcSettingsData?.progressTargetYear,
    showProgressTowardsTargetYear: nzcSettingsData?.showTargetProgress,
    selectedTP:nzcSettingsData?.targetPathway !== null ?`tp${nzcSettingsData?.targetPathway}`: null,
    showTopDownPathways: nzcSettingsData?.showTopDownPathways
  }
  const scopeTargetSelectedTp = targetPathWayData?.current?.filter(obj1 =>
    selectedScopeTPData?.some(obj2 => obj2.targetPathwayNumber === obj1.targetPathwayNumber)
  )
  const selectedScopePercentage = selectedScopeTPData?.map(percentage => percentage.percentage)
  const scopeTargetPercentage = scopeTargetSelectedTp?.map(percentage => percentage.percentage)
  const customTargetPathwayNameLength = customTargetPathwayName && Object.keys(customTargetPathwayName).length
  const hasCustomPathwayNameChanged = customTargetPathwayNameLength > 0 && nzcSettingsData?.targetPathwayNames && JSON.stringify(sortObject(customTargetPathwayName)) !== JSON.stringify(sortObject(JSON.parse(nzcSettingsData?.targetPathwayNames)))
  const hasTargetPathwayDetailsChanged = JSON.stringify(targetPathwayDetailsObj) !== JSON.stringify(targetAndPathwayTabDataFromDB)
  const haveScopePercentagesChanged = JSON.stringify(selectedScopePercentage) !== JSON.stringify(scopeTargetPercentage)

  return hasCustomPathwayNameChanged || hasTargetPathwayDetailsChanged || haveScopePercentagesChanged
}

export function nzcModelNameValidator(data) {
  let errors = {}
  if (!data.name) {
    errors.name = I18n('t_name_required')
  } else if (!alphaNumericRegex.test(data.name) && specialCharacterRegex.test(data.name)) {
    errors.name = I18n('t_model_name_not_valid')
  } else if((data.name.length) > MODEL_NAME_MAX_ALLOWED_CHARS){
    errors.name = I18n('t_name_must_not_exceed_50_characters')
  }
  return errors
}

export function hasModelNameValidationError(formik) {
  return !!(formik?.touched['name'] && formik?.errors?.name)
}

// Helper function to provide the default font-family (with other font options) for the chart.
export function getDefaultFontObjectForChart(fontOptionsObject) {
  let baseFontObject = {family: CSS_VARIABLES.fontFamilyBase}
  if (fontOptionsObject) {
    baseFontObject = {...baseFontObject, ...fontOptionsObject}
  }
  return baseFontObject
}

export function nzcExportModelData(item) {
  return {
    'Asset Ref': item.assetRef,
    'Asset ID': item.assetId,
    'Asset Name': item.assetName,
    'Fund': item.fund,
    'Country': item.country,
    'Sector': item.sector,
    'Year': item.year,
    'Utility Type': item.utilityType,
    'Actual Consumption (kWh)': item.totalConsumption,
    'Actual Carbon (kg CO2)': item.totalCarbon,
    'Uplifted Carbon (kg CO2)': item.upliftedCarbon,
    'Benchmarked Carbon (kg CO2)': item.benchmarkedCarbon,
    'wholeBuildingFloorArea (m2)': item.gia,
    'Occupancy ( % )': item.occupancy,
    'Actual Carbon Intensity (kg CO2/m2)': item.totalCarbonIntensity,
    'Data completeness of actual data ( % )': item.actualCompleteDataPercentage,
    'Floor area coverage with actual data (m2)': item.floorAreaCoverage,
    'Uplifted Carbon Intensity (kg CO2/m2)': item.upliftedCarbonIntensity,
    'Benchmarked Carbon Intensity (kg CO2/m2)': item.benchmarkedCarbonIntensity,
    'Actual Energy Intensity (kWh/m2)' : item.actualEnergyIntensity,
    'Uplifted Energy Intensity (kWh/m2)': item.upliftedEnergyIntensity,
    'Benchmarked Energy Intensity (kWh/m2)': item.benchmarkedEnergyIntensity,
    'Notes': item.notes,
  }
}

export function nzcErrorMessageTemplate(){
  return <div className={'fund-dashboard-nzc-errors'}>
    <span className={'error-header'}>{I18n('t_unable_to_plot_NZC_chart')}</span>
    <p className={'error-text'}>{I18n('t_unable_to_plot_NZC_chart_Info')}</p>
    <p className={'error-contact-evora'}>{I18n('t_unable_to_plot_require_help', {contactEvora: <a className={'contact-link'} href={'mailto:support@sieraglobal.com'}>{I18n('t_contact_Evora')}</a>})}</p>
  </div>
}

export const getActionCheckboxStatus = (dbActionCheckboxSelectionStatus, actionCount, actionBasedTPDetails, isAgreedAction) => {
  if (dbActionCheckboxSelectionStatus) {
    return actionCount > 0
  } else if (actionCount > 0) {
    if (isAgreedAction) {
      return actionBasedTPDetails?.applicableActions < 1
    } else {
      return actionBasedTPDetails?.agreedActions < 1
    }
  } else {
    return false
  }
}

export function getYearForFinancialDate(dateString) {
  function getMonthAndDay(dateString) {
    const [firstPart, secondPart] = dateString?.split(' ') || []
    const day = isNaN(parseInt(firstPart)) ? secondPart : firstPart
    const month = isNaN(parseInt(firstPart)) ? firstPart : secondPart
    return [ day, month ]
  }
  
  const currentDate = new Date()
  const [day, month] = getMonthAndDay(dateString) || []
  const monthIndex = new Date(`${month} 1, ${currentDate.getFullYear()}`).getMonth()
  const startDateYear = currentDate.getFullYear() - 1
  const endDate = new Date(currentDate.getFullYear(), monthIndex, parseInt(day) - 1)

  return endDate > currentDate ? startDateYear - 1 : startDateYear
}


