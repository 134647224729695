import {uuid} from 'uuidv4'
import { NavLink } from 'react-router-dom'
import UtilityPerformanceTile from '../fund-dashboard-components/utility-performance-tile/UtilityPerformanceTile'
import FundPerformanceCardSkeleton from '../../../../common/skeletons/fund-performance-cards/FundPerformanceCardSkeleton'
import EnergyStarAnalysis from '../fund-dashboard-components/energy-star-analysis/EnergyStarAnalysis'
import {FUND_VIEWS_PAGES, PERIOD_TYPES, USE_QUERY_STATUS, UtilityTypes, UTILITY_LABELS_OBJECT_TITLE_CASE, roundAndMultiply100, dataQualityObject} from '../../../../../utils/helpers/Constants'
import {createEndMonthParam, createNavURLForFundPages, getLocalizedValue} from '../../../../../utils/helpers/Helper'
import I18n from '../../../../../utils/i18n/I18n'
import {getTimeperiodLabel, getInitialTimePeriod, getInitialPickerValue} from '../../../../../services/common/datepicker-service'
import CSS_VARIABLES from '../../../../../resources/css/_variables.scss'
import EpcAnalysisV3 from '../fund-dashboard-components/epc-analysis/EpcAnalysisV3'

const UTILITY_PERCENTAGE = {	
  CARBON: 'carbonPercentage',	
  RECYCLED_WASTE: 'wastePercentage', 	
  ELECTRICITY: 'electricityPercentage', 	
  GAS_FUEL_THERMAL:'fuelsPercentage', 	
  WATER: 'waterPercentage', 	
  WASTE:'wastePercentage', 	
}

const utilities = {
  energyIntensity:  { label: 'ENERGY_INTENSITY', title: 't_energy_intensity'},
  electricity:  { label: 'ELECTRICITY', title: 't_electricity'},
  gasFuelsAndThermals:  { label: 'GAS_FUEL_THERMAL', title: 't_gas_fuels_thermals'},
  water:  { label: 'WATER', title: 't_water'},
  waste:  { label: 'WASTE', title: 't_waste'},
  recycledWaste:  { label: 'RECYCLED_WASTE', title: 't_recycled_waste'},
}

export const utilitiesV3 = {
  carbonEmission:  { label: 'CARBON_EMISSION', title: 't_carbon_emissions'},
  energyIntensity:  { label: 'ENERGY_INTENSITY', title: 't_energy_intensity'},
  electricity:  { label: 'ELECTRICITY', title: 't_electricity'},
  gasFuelsAndThermals:  { label: 'GAS_FUEL_THERMAL', title: 't_gas_fuels_thermals'},
  water:  { label: 'WATER', title: 't_water'},
  recycledWaste:  { label: 'RECYCLED_WASTE', title: 't_waste_recycling'},
}

export function isNzcGraphDataLoading(fundKeyfactsData, nzcTargetPathwaysData, staticNzcTargetPathwaysData, createDefaultNzcScopeMutation, nzcScopeId, statusScopes) {
  return (
    (fundKeyfactsData?.isLoading || nzcTargetPathwaysData?.isLoading || staticNzcTargetPathwaysData?.isLoading) || (createDefaultNzcScopeMutation?.isLoading && !createDefaultNzcScopeMutation?.isError) ||
      !!((nzcScopeId &&  statusScopes === USE_QUERY_STATUS.idle) || (nzcScopeId && nzcTargetPathwaysData?.statusTPathways === USE_QUERY_STATUS.idle))
  )
}

export function createDefaultNzcScope(fundGroupId, createDefaultNzcScopeMutation, setNzcScopeId) {
  createDefaultNzcScopeMutation.mutateAsync({fundGroupId}).then(newScopeId => {
    setNzcScopeId(newScopeId)
  })
}

export const infoHeader = (
  <div className="grid">
    <div className="col-12 iconContainer">
      <span className="medium-text col-12">{I18n('t_energy_intensity_popup_header')}</span>
    </div>
  </div>   
)

export const epcGetInformation = (
  <>
    {
      I18n('t_epc_analysis_popup_body',
        {
          p: msg => (<p className="info-text-1">{msg}</p>),
          a: msg => (<a className= "info-link underline" href="https://www.sieraglobal.com/#epc" target="_blank" rel="noopener noreferrer">
            {msg} </a>)
        }
      )}
  </>
)
export const getUtilityPerformanceTiles = (
  performanceData, 
  fundGroupID, 
  reportingPeriod, 
  isLoading,
  history, 
  pfState, 
  pfDispatcher,
  instanceName, 
  orgName, 
  fundName,
  modalState,
  updateFundDashboardState,
) => {

  if(isLoading || fundGroupID === undefined) {
    return Object.keys(utilities).map(() => {
      return (
        <div key={uuid()} className='h-full bg-white p-4'>
          <FundPerformanceCardSkeleton />
        </div>
      )
    })
  } else if (performanceData?.data) {
    return Object.keys(performanceData?.data).map((data) => {
      const utilityKey = data.charAt(0).toUpperCase() + data.slice(1)
      const cardContent = <><h6 className="font-medium text-lg mb-2 mt-0 line-height-4 tile-header">{I18n(`${utilities[data]?.title}`)}</h6>
        <UtilityPerformanceTile
          utilityData={performanceData?.data[data]}
          utility={UTILITY_LABELS_OBJECT_TITLE_CASE[utilities[data]?.label]}
          isLoadingData={isLoading || fundGroupID === null}
          reportingPeriodSelected={data === 'energyIntensity' ? reportingPeriod : null}/>
      </>
      if (utilities[data] !== undefined && data !== 'energyIntensity') {
        return (
          <div className='fund-dashboard__small-tile fund-dashboard__content navlink' key={uuid()}>
            <NavLink onClick={() => {
              pfDispatcher({...pfState, ...{data: pfState?.data, colFieldFund: UTILITY_PERCENTAGE[utilities[data]?.label]}})	
            }} to={{pathname: createNavURLForFundPages({instanceName, orgName, fundName, pathSuffix: FUND_VIEWS_PAGES.performance}), search: `?utility=${UtilityTypes[utilityKey]}`}}>
              {cardContent}
            </NavLink>
          </div>
        )
      }
      if (data === 'energyIntensity') {
        return (
          <div className='fund-dashboard__small-tile fund-dashboard__content navlink'
            key={uuid()}
            onClick={() => {
              updateFundDashboardState({ modalState: {energyIntensity: true}})
            }}
          >
            {cardContent}
          </div>
        )
      }
    })
  }
}

export const getEpcOrEsTile = (esEpcData, featureState, isLoading) => {
  return esEpcData?.data?.isEnergyStarEnabled ?
    <div>
      <EnergyStarAnalysis
        esData={esEpcData}
        featureState={featureState}
      />
    </div> :
    <div>
      <EpcAnalysisV3 epcData={esEpcData} isDependentLoading={isLoading} featureState={featureState}/>
    </div>
}

export const setInitialDashboardState = (fundId, fundGroupId, fundName, queryValues) => {
  const timePeriod = getInitialTimePeriod(queryValues)
  const pickerValue = getInitialPickerValue(queryValues)
  const timeNoOfMonthsPeriod = pickerValue?.pickerValueMonths
  const timeEndDatePeriod = createEndMonthParam(pickerValue.pickerValueEndYear, pickerValue.pickerValueEndMonth)

  return {
    timePeriod,
    timeNoOfMonthsPeriod,
    timeEndDatePeriod,
    pickerValue,
    time: getTimeperiodLabel(timePeriod),
    customDateSummary: false,
    selectedFund: {fundname: fundName},
    modalState: {epc: false},
    fundPerformanceVariables: ['fundPerformanceData', fundGroupId, getTimeperiodLabel(timePeriod), timeNoOfMonthsPeriod, timeEndDatePeriod],
    fundsKeyFactsVariables: ['FundKeyfacts', fundGroupId],
    dataQualityVariables: ['fundDataQuality', fundGroupId, getTimeperiodLabel(timePeriod), timeNoOfMonthsPeriod, timeEndDatePeriod, false], //False: isTimePeriodChanged
    assetRankingDataVariables: ['assetRankingData', {
      fundId: fundId,
      pageSize: 10,
      orderBy: 'carbonEmission',
      sortOrder: 'asc'
    }, getTimeperiodLabel(timePeriod), timeNoOfMonthsPeriod, timeEndDatePeriod],
    actionDataVariables: ['fundAction', fundGroupId],
    epcesDataVariables: ['epcData', fundGroupId],
    isTimePeriodChanged: false,
  }
}

export function getQueryParamsForFundDashboard(periodType, pickerValue, isTimePeriodChanged=false) {
  let queryParams={timePeriod: periodType, isTimePeriodChanged: isTimePeriodChanged}
  if (periodType === PERIOD_TYPES.CUSTOM) {
    queryParams={...queryParams,
      noOfMonths: pickerValue.pickerValueMonths,
      endMonth: pickerValue.pickerValueEndMonth,
      endYear: pickerValue.pickerValueEndYear,
      isTimePeriodChanged: isTimePeriodChanged
    }
  }
  return queryParams
}

const chartColorsMap = Object.freeze({
  actual: CSS_VARIABLES.forest,
  gapFilled: CSS_VARIABLES.forest_50,
  target: 'transparent',
  previousPeriod: 'transparent',
  currentNonRecycledTotal: CSS_VARIABLES.sand
})

export function getDataset({stackName, value}) {
  return {
    label: stackName,
    backgroundColor: chartColorsMap[stackName],
    hoverBackgroundColor: chartColorsMap[stackName],
    minBarLength: 5,
    data: [value],
    borderRadius: 0,
    borderSkipped: false,
    borderWidth: {left: 0, top: 0, right: 0, bottom: 0},
    borderColor: CSS_VARIABLES.white,
    stackName: stackName,
    barThickness: 10,
    maxBarThickness: 10
  }
}

const scaleOptionsCommon={
  stacked: true,
  display: false,
  ticks: {display: false},
  grid: {display: false},
  border: {display: false}
}
export function getDefaultOptions(yMax) {
  return {
    indexAxis: 'x',
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    layout: {
      padding: 0,
    },
    scales: {
      x: {
        ...scaleOptionsCommon
      },
      y: {
        max: yMax,
        stepSize: 0,
        ...scaleOptionsCommon
      }
    },
    plugins: {
      tooltip: {
        enabled: false
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: false
      }
    },
    onHover: (event, chartElement) => {
      if (chartElement.length > 0) {
        event.native.target.style.cursor = 'pointer'
      }
    },
  }
}

export function getBarChartData(dataQualityData) {
  const actualData = dataQualityData?.actual ? dataQualityData?.actual : dataQualityData?.actualPercentage
  const estimatedData = dataQualityData?.estimated ? dataQualityData?.estimated : dataQualityData?.estimatedPercentage
  const incompleteData = dataQualityData?.missing ? dataQualityData?.missing : dataQualityData?.incompletePercentage
  const noCoverageData = dataQualityData?.noCoverage ? dataQualityData?.noCoverage : dataQualityData?.notCoveredPercentage

  const barData = {}
  let yMax=0
  if (actualData && actualData > 0) {
    barData.actualPercentage = roundAndMultiply100(actualData)
    yMax+=barData.actualPercentage
  }
  if (estimatedData && estimatedData > 0) {
    barData.estimatedPercentage = roundAndMultiply100(estimatedData)
    yMax+=barData.estimatedPercentage
  }
  if (incompleteData && incompleteData > 0) {
    barData.incompletePercentage = roundAndMultiply100(incompleteData)
    yMax+=barData.incompletePercentage
  }
  if (noCoverageData && noCoverageData > 0) {
    barData.notCoveredPercentage = roundAndMultiply100(noCoverageData)
    yMax+=barData.notCoveredPercentage
  }
  return {barData, yMax}
}

export function getDQBarChartOptions(yMax, tooltipConfig) {
  const tooltipDefaultConfig = tooltipConfig ?? {enabled: false}
  return {
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    layout: {padding: 0},
    scales: {
      x: {max: yMax, ...scaleOptionsCommon},
      y: {stepSize: 0, ...scaleOptionsCommon}
    },
    plugins: {
      tooltip: tooltipDefaultConfig,
      legend: {display: false,},
      datalabels: {display: false}
    },
    onHover: (event, chartElement) => {
      if (chartElement.length > 0) {
        event.native.target.style.cursor = 'pointer'
      }
    }
  }
}

export function getDQBarchartData(barDataValue, selectedLanguage) {
  return {
    labels: [''],
    datasets: Object.keys(barDataValue).map((dataKey) => {
      return {
        label: `${getLocalizedValue(selectedLanguage, dataQualityObject[dataKey].translationKey)}`,
        backgroundColor: dataQualityObject[dataKey].color,
        hoverBackgroundColor: dataQualityObject[dataKey].color,
        data: [barDataValue[dataKey]],
        borderRadius: 0,
        borderSkipped: false,
        borderWidth: {left: 0, top: 0, right: 0, bottom: 0},
        borderColor: CSS_VARIABLES.white,
        barThickness: 20,
      }
    })
  }
}