import React, {useContext, useEffect, useRef} from 'react'
import './AssetActionDetails.scss'
import {Button} from 'primereact/button'
import {Card} from 'primereact/card'
import { InputTextarea } from 'primereact/inputtextarea'
import {useParams} from 'react-router-dom'
import {useHistory} from 'react-router-dom/cjs/react-router-dom'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import UseUrlParameterIds from '../../../../../utils/custom-hooks/UseUrlParameterIds'
import {
  createNavURLForFundPages,
  CurrencyUnitToLabel, formatUnits,
  getLocalizedValue,
  getLocalStorageItem, handleLengthyName,
  unitDateFormatter,
  unitsConverter,
} from '../../../../../utils/helpers/Helper'
import {costMask, FUND_VIEWS_PAGES, UpdateActions, UtilityTypes} from '../../../../../utils/helpers/Constants'
import {
  actionDetailsCard, areaCoveredList, costTypeList, getPaybackPeriod, gresbMeasureListV2, impactList,
  procuredByList, statusList, utilityTypeList
} from '../../../../common/action-plan/actionPlanTableTemplate'
import I18n from '../../../../../utils/i18n/I18n'
import {actionDataDetails, addNote} from '../../../../../services/assets/actionplan-service'
import {noRefetchOnWindowFocus} from '../../../../../services/common/useQuery-config'
import {FeatureFlagContext} from 'Contexts'
import ActionCardSkeleton
  from '../../../../common/skeletons/fund-performance-cards/FundPerformanceCardSkeleton'
import AssetEditAction from '../asset-add-action/AssetAddAction'
import {useAssetAvailableUtilityMeters} from '../../../../../utils/custom-hooks/api-queries/actions-api-queries-hooks'
import useCustomState from '../../../../common/custom-hooks/useCustomState'
import PopupDialog from '../../../../common/modal-dialogs/PopupDialog'
import infoCircleMRed from '../../../../../resources/images/icon/info/info-circle-m-red.svg'
import {getUnsavedChangesPopupFooter} from '../../../../common/net-zero-carbon/nzc-common-utils'
import {unsavedChanges} from '../../../../../utils/signals/CommonSignals'
import InfoOverlayPanelContent from '../../../../atomic/OverlayPanel/OverlayPanelContent/OverlayPanelContent'
import OverlayPanel from '../../../../atomic/OverlayPanel/OverlayPanel'
import {Text, Tooltip} from '../../../../atomic'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const setInitialActionDetailsState = () => {
  return {
    showEditActionSideBar: false,
    actionNotes: null,
    showDataNotSavedWarningPopup : false,
    navigateTo : null,
    infoOverlayContent: null
  }
}

const AssetActionDetails = () => {
  const {instanceName, orgName, fundName, assetId, actionId} = useParams()
  const history = useHistory()
  const { logOutOfAuthAndApp, loginState: {userInfo}  } = useLoginAuthContext()
  const featureContext            = useContext(FeatureFlagContext)
  const featureState              = featureContext.featureState
  const selectedLanguage = userInfo.languagePreference
  const unitSystem = userInfo.unitSystem
  const currencyUnit = userInfo.currencyUnit
  UseUrlParameterIds({instanceName, orgName, fundName, assetId, actionId})
  const actionDetailsQueryKey = ['actionDetails', assetId, actionId]
  const {isLoading: actionDetailsLoading, data: actionDetailsData, refetch: refetchActionDetails} = useQuery(actionDetailsQueryKey, actionDataDetails, {...noRefetchOnWindowFocus, enabled: !!(actionId)})
  const {data: assetAvailableUtilityMeters}=useAssetAvailableUtilityMeters(assetId)
  const {customState: actionDetailsState, updateCustomState: updateActionDetailsState}=useCustomState(() => setInitialActionDetailsState())
  const addActionNotes = useMutation(addNote)
  const currencyLabel = actionDetailsData?.currency ? actionDetailsData?.currency : CurrencyUnitToLabel(currencyUnit)
  const actionDetailsCurrency = featureState?.sieraplus_currency ? CurrencyUnitToLabel(currencyUnit) : currencyLabel
  const cardSkeleton = <div className='w-29rem h-30rem bg-white p-5'><ActionCardSkeleton /></div>
  const unsavedChangedWarningDismissed = useRef(false)
  const infoOverlayRef = useRef(null)
  const queryClient = useQueryClient()

  // region : helper functions
  const goBackToActionPlan = () => {
    const actionPlanPage = createNavURLForFundPages({
      instanceName,
      orgName,
      fundName,
      assetId,
      pathSuffix: FUND_VIEWS_PAGES.actionPlan
    })
    history.push(actionPlanPage)
  }

  useEffect(() => {
    const unblock = history.block((location) => {
      if (actionDetailsState.actionNotes) {
        updateActionDetailsState({showDataNotSavedWarningPopup: true, navigateTo : location})
        return false
      }
      unsavedChanges.value = false
      return true
    })
    unsavedChangedWarningDismissed.current=false
    return () => {
      unblock()
      !unsavedChangedWarningDismissed.current && actionDetailsState.showDataNotSavedWarningPopup && history.push(actionDetailsState.navigateTo.pathname)
    }
  }, [actionDetailsState.actionNotes, actionDetailsState.showDataNotSavedWarningPopup])

  const handleEditActions = (action) => {
    if(action === UpdateActions.EDIT) {
      updateActionDetailsState({showEditActionSideBar: true})
    } else {
      updateActionDetailsState({showEditActionSideBar: false})
    }
  }

  const handleChange = (e) => {
    const newActionNotesValue = e.target.value
    unsavedChanges.value = true
    if(!actionDetailsState.actionNotes) {
      const sanitizedValue = newActionNotesValue.replace(/[^a-zA-Z0-9]/g, '')
      updateActionDetailsState({actionNotes: sanitizedValue})
    } else {
      updateActionDetailsState({actionNotes: newActionNotesValue})
    }
  }

  const handleNotesClear = () => {
    updateActionDetailsState({actionNotes: ''})
  }
  function leave(){
    updateActionDetailsState({showDataNotSavedWarningPopup: false})
    unsavedChanges.value = false
    actionDetailsState.navigateTo.pathname === '/login' && logOutOfAuthAndApp()
  }

  const handleAddNotes = async () => {
    try {
      const actionNotesData = {
        dateCreated: new Date(),
        content: actionDetailsState.actionNotes,
        userName: getLocalStorageItem('userName'),
        toUpdate: false,
        dummyNoteId: 0
      }
      await addActionNotes.mutateAsync({assetId, actionId, ...actionNotesData})
      handleNotesClear()
      refetchActionDetails()
      queryClient.invalidateQueries('asset-actions')
      actionDetailsState.showDataNotSavedWarningPopup && leave()
    } catch(error) {
      console.error('Error submitting notes:', error)
    }
  }

  const handleOverlayPanel = (e, label, infoContent) => {
    infoOverlayRef.current.toggle(e)
    updateActionDetailsState({infoOverlayContent: {label, infoContent}})
  }
  // endregion

  // region : render action cards
  const getActionDetail = () => {
    const statusValue = statusList.find((status) => status.value === actionDetailsData?.status)?.label
    const impactValue = impactList.find((impact) => impact.value === actionDetailsData?.impact)?.label
    const areaCoveredValue = areaCoveredList.find((area) => area.value === actionDetailsData?.areaCovered)?.label
    const procuredByValue = procuredByList.find((procuredBy) => procuredBy.value === actionDetailsData?.procuredBy)?.label
    const gresbMeasureValue = gresbMeasureListV2.find((gresb) => gresb.value === actionDetailsData?.performanceMeasure)?.label
    const actionDetailsCardData = [
      {label: 't_manager', value:handleLengthyName(actionDetailsData?.manager, 50), tooltipTarget: 'manager-field-tooltip'},
      {label: 't_status', value: statusValue},
      {label: 't_impact', value: impactValue},
      {label: 't_action_area_covered', value: areaCoveredValue},
      {label: 't_scope', value: actionDetailsData?.scope?.join(', ')},
      {label: 't_procured_by', value: procuredByValue},
      {label: 't_gresb_measure', value: gresbMeasureValue, infoIcon: true, infoContent: 't_gresb_info'}
    ]
    return (
      <Card>
        {actionDetailsData?.manager?.length > 50 &&
        <Tooltip className={`action-manager-${actionDetailsData?.actionId}`} target={'.manager-field-tooltip'} padding="small" width={'400'}>
          <Text content={actionDetailsData?.manager} />
        </Tooltip>
        }
        {actionDetailsCardData.map(item => actionDetailsCard(item.label, item.value, item.infoIcon, item.infoContent, handleOverlayPanel, item.tooltipTarget))}
      </Card>
    )
  }

  const getEffectOnUtilities = () => {
    const effectOnUtilities = actionDetailsData?.utilitySavings
      .sort((a, b) => b.isPrimaryUtility - a.isPrimaryUtility)
      .map((item) => ({
        isUtility: true,
        utility:item.utilityType,
        estimatedSavings: item.estimatedSavings,
        utilityCostChange: item.utilityCostChange,
        impact : actionDetailsData?.impact
      }))
    const netChange = {
      isUtility: false,
      utility: 'netChange',
      estimatedSavings: actionDetailsData?.estimatedChange,
      utilityCostChange: actionDetailsData?.estimatedCost,
      impact: actionDetailsData?.impact
    }

    const getValidValue = (value,unit) => {
      let roundedNumber = Math.round(value)
      let formattedNumber = String(roundedNumber).slice(0, 9)
      if(value !==null){
        return value >= 0 ? `+${formatUnits(formattedNumber,selectedLanguage)} ${unit}/yr` : `${formatUnits(formattedNumber,selectedLanguage)} ${unit}/yr`
      }
      return '-'
    }
    const getEffectOnUtilitiesContent = (item, key=1) => {
      const field = utilityTypeList.find(utility => utility.value === item.utility)
      return <div className='asset-action-details__cards--card__value--effect-on-utilities__content' key={key}>
        <Text className='utility' content={item.isUtility && field.label || 't_net_change'} />
        <Text className='estimated-savings' content={getValidValue(item.estimatedSavings, unitsConverter(item.impact,unitSystem ))} />
        <Text className='utility-cost-change' content={getValidValue(item.utilityCostChange, costMask[actionDetailsCurrency])} />
      </div>
    }

    return <div className='asset-action-details__cards--card__value--effect-on-utilities'>
      {effectOnUtilities.map((item,key) =>{
        return getEffectOnUtilitiesContent(item,key)
      })}
      <hr/>
      {getEffectOnUtilitiesContent(netChange)}
    </div>
  }

  const getActionKeyMetrics = () => {
    const costValue = actionDetailsData?.cost ? `${costMask[actionDetailsCurrency]} ${Number(actionDetailsData?.cost).toLocaleString(selectedLanguage,{maximumFractionDigits: 0 })}` : '-'
    const costTypeValue = costTypeList.find((costType) => costType.value === actionDetailsData?.costType)?.label
    const scheduledCompletionDate = unitDateFormatter(actionDetailsData?.dueDate, unitSystem, selectedLanguage)
    const completionDate = unitDateFormatter(actionDetailsData?.completion, unitSystem, selectedLanguage)
    const isEffectOnUtilitiesVisible = [UtilityTypes.Energy , UtilityTypes.Water, UtilityTypes.Waste].includes(actionDetailsData?.impact)
    const actionKeyMetricsCardData = [
      {label: 't_cost', value:costValue},
      {label: 't_cost_type', value: costTypeValue},
      {label: 't_action_scheduled_completion_date', value: scheduledCompletionDate},
      {label: 't_action_completion_date', value: completionDate},
      {label: 't_payback_period', value: getPaybackPeriod(actionDetailsData?.paybackPeriod, selectedLanguage), infoIcon: true, infoContent: 't_payback_period_info'},
      ...(isEffectOnUtilitiesVisible ? [{label: 't_effect_on_utilities', value : getEffectOnUtilities() , infoIcon :true, infoContent: 't_effect_utilities'}] :[])
    ]
    return (
      <Card>
        {actionKeyMetricsCardData.map(item => actionDetailsCard(item.label, item.value, item.infoIcon, item.infoContent, handleOverlayPanel))}
      </Card>
    )
  }

  const getActionNotes = () => {
    let notesData
    if(actionDetailsData?.notes?.length > 0) {
      const sortedNotes = actionDetailsData?.notes?.sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated))
      notesData = sortedNotes?.map(item => {
        const userName = item.userName ? `- ${item.userName}` : ''
        const dateStamp = unitDateFormatter(item.dateCreated, unitSystem, selectedLanguage)
        return actionDetailsCard(`${dateStamp} ${userName}`, item.content)})
    } else {
      notesData = <Text className={'faded_teal'} content={'t_no_notes'}/>
    }
    const notesTextArea = <InputTextarea role='notes-text-area' placeholder={getLocalizedValue(selectedLanguage,'t_add_note_dots')} autoResize className='asset-action-details__cards--card__value--text-area' value={actionDetailsState.actionNotes} onChange={handleChange} />
    return (
      <Card>
        <div className='asset-action-details__cards--card__notes p-card-content'>{notesData}</div>
        <hr className='asset-action-details__cards--card__divider'/>
        {actionDetailsCard('t_post_note', notesTextArea)}
        <div className='d-flex'>
          <Button disabled={!actionDetailsState.actionNotes} onClick={handleAddNotes} label={I18n('t_post')} className='action-positive' />
          <Button onClick={handleNotesClear} label={I18n('t_clear_note')} className='action-negative ml-3' />
        </div>
      </Card>
    )
  }

  function unsavedChangesPopup() {
    function dismissWarningPopup(){
      unsavedChangedWarningDismissed.current=true
      updateActionDetailsState({showDataNotSavedWarningPopup: false})
    }
    return <PopupDialog visible={actionDetailsState.showDataNotSavedWarningPopup}
      header={<><span className='icon-container'><img className='header-icon-l' src={infoCircleMRed} alt='warning-img'/>  </span><span className='header-title'>{I18n('t_unsaved_changes')}</span></>}
      message={I18n('t_notes_in_actions_not_saved')}
      className={'no-footer nzc-page-redirect-popup'}
      onHide={dismissWarningPopup}
      dismissable={false}
      footer = {() => getUnsavedChangesPopupFooter(leave, handleAddNotes)}
    />
  }

  // endregion

  const propsForAssetAddActionSideBar = {
    isAddActionSideBarVisible : actionDetailsState?.showEditActionSideBar,
    onHideOrCloseOfAddActionSideBar : handleEditActions,
    featureState: featureState,
    currencyUnit: currencyUnit,
    unitSystem,
    assetAvailableUtilityMeters: assetAvailableUtilityMeters,
    selectedLanguage: selectedLanguage,
    assetId: assetId,
    refetchActions: refetchActionDetails,
    actionId: actionId,
    flag: 'ACTION-EDIT',
    actionDetailsData: actionDetailsData,
    goBackToActionPlan: goBackToActionPlan
  }

  return(
    <div className='asset-action-details container-layout gutter'>
      <div className='asset-action-details__header'>
        {actionDetailsData?.actionDescription?.length > 50 &&
        <Tooltip className={`action-detail-title-${actionDetailsData?.actionId}`} target={'.action-title'} padding="small">
          <Text content={actionDetailsData?.actionDescription} />
        </Tooltip>}
        <h1 className={'action-title'}>{handleLengthyName(actionDetailsData?.actionDescription, 50)}</h1>
        <div className='my-3'>
          <Button label={I18n('t_back_action_plan')} className='action-negative' icon="pi pi-angle-left" onClick={goBackToActionPlan} />
          <Button label={I18n('t_edit')} className='action-positive ml-3' onClick={() => handleEditActions(UpdateActions.EDIT)} />
        </div>
      </div>
      <div className='asset-action-details__cards'>
        <div className='asset-action-details__cards--card'>
          <Text weight={'bold'} className={'mt-4 mb-4'} content={'t_details'} />
          {actionDetailsLoading ? cardSkeleton : getActionDetail()}
        </div>
        <div className='asset-action-details__cards--card'>
          <Text weight={'bold'} className={'mt-4 mb-4'} content={'t_key_metrics'} />
          {actionDetailsLoading ? cardSkeleton : getActionKeyMetrics()}
        </div>
        <div className='asset-action-details__cards--card'>
          <Text weight={'bold'} className={'mt-4 mb-4'} content={'t_notes'} />
          {actionDetailsLoading ? cardSkeleton : getActionNotes()}
        </div>
        <OverlayPanel
          panelRef={infoOverlayRef}
          panelChildren={
            <InfoOverlayPanelContent
              iconId={'info_solid'}
              header={actionDetailsState?.infoOverlayContent?.label}
              panelContent={actionDetailsState?.infoOverlayContent?.infoContent}
            />}
        />
      </div>
      {actionDetailsState?.showEditActionSideBar && <AssetEditAction {...propsForAssetAddActionSideBar}/>}
      {unsavedChangesPopup()}
    </div>
  )
}

export default AssetActionDetails