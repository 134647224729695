import {useContext, useEffect, useRef, useState} from 'react'
import {periodOptions, getInitialPickerValue, getInitialTimePeriod} from '../../../../../services/common/datepicker-service'
import useAssetTimePeriodState from '../../../../../utils/custom-hooks/useAssetTimePeriodState'
import {datePickerQuery, datePickerQueryValues, filterQueryParams} from '../../../../../utils/query-params/QueryParams'
import {
  createEndMonthParam, createNavURLForFundPages, getPerformanceUtilitiesList, getUtilityName,
  saveCustomDateInCache, setAssetData
} from '../../../../../utils/helpers/Helper'
import {
  DROP_DOWN_HEIGHT,
  FUND_VIEWS_PAGES,
  GAP_FILLING,
  PERIOD_TYPES,
  QueryKeyPrefix,
  ScopeTypes,
  UTILITY_LABELS_OBJECT_TITLE_CASE,
  UtilityTypes
} from '../../../../../utils/helpers/Constants'
import {PortfolioContext} from 'Contexts'
import {useHistory, useParams} from 'react-router-dom'
import {getTimeperiodLabel} from './ChartHelper'
import {getMonthlyPerformancesForChartEvents, getMultiSelectDropdownOptions, getUtilityConsumptionData, getUtilityPerformanceSummaryData} from '../performance-hooks-&-helpers/performanceHelper'
import useAssetPerformanceData from '../performance-hooks-&-helpers/useAssetPerformanceData'
import I18n from '../../../../../utils/i18n/I18n'
import AssetLayout from '../../../../../layouts/asset/AssetLayout'
import {Dropdown} from 'primereact/dropdown'
import PerformanceHeadersSkeleton from '../../../../common/skeletons/performance-headers-skeleton/PerformanceHeadersSkeleton'
import PerformanceChartSummary from '../performance-components/PerformanceChartSummary'
import './PerformanceHome.scss'
import EventActions from '../eventds/EventActions'
import usePerformanceDataMonthly from '../performance-hooks-&-helpers/usePerformanceDataMonthly'
import AssetPerformanceChart from '../performance-components/AssetPerformanceChart'
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import {ActionEventType, UtilitySummaryItemKey} from '../performance-hooks-&-helpers/performanceConstants'
import PerformanceChartSkeleton from './performance-chart-skeleton/PerformanceChartSkeleton'
import MultiSelectDropdown from '../../../../common/multi-select/MultiSelectDropdown'
import ScopesInformation from '../../../../common/performance/ScopesInformation'
import AssetExportSelection from '../../../../../utils/data-exports/AssetExportSelection'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'
Chart.register(ChartDataLabels)

const PerformanceHome = () => {

  //region Declarations/Initialisations
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const currencyUnit = userInfo.currencyUnit
  const unitSystem = userInfo.unitSystem
  const {pfState, pfDispatcher} = useContext(PortfolioContext)
  const {instanceName, orgName, fundName, assetId} = useParams()
  const history = useHistory()
  const queryValues = new URLSearchParams(new URL(window.location.href).search)
  const QUERY_KEY_UTILITY = 'utility'

  const rowData = (Object.keys(pfState.data).length > 0) ? pfState.data : null
  const dpCustomTime = JSON.parse(localStorage.getItem('dpCustomTimePeriod'))
  const {timePeriodState, updateTimePeriodState} = useAssetTimePeriodState(getInitialTimePeriodState)
  const time = getTimeperiodLabel(timePeriodState.timePeriod)
  const tableDataPdfRef = useRef()
  const exportOverlay = useRef(null)
  const selectedUtilityLabel = getUtilityName(pfState.colField)
  const isTargetAvailable = pfState.showTarget
  const [performanceState, setPerformanceState] = useState(()=>getInitialPerformanceState(timePeriodState))
  const [isOverlayPanelOpen, setIsOverlayPanelOpen] = useState(false)

  const {
    assetHeaderInfo,
    utilitySummaryExportData,
    assetEventsData,
    refetchAssetEventsData,
    assetCarbonPerformanceData,
    assetUtilityPerformanceData,
    assetCarbonPerformanceDataLoading,
    assetUtilityPerformanceDataLoading,
    refetchAssetCarbonPerformanceData,
    refetchAssetUtilityPerformanceData
  } = useAssetPerformanceData(assetId, rowData, getAssetPerformanceQueryKey(), performanceState.selectedUtility.name, time, timePeriodState)

  const {performanceDataMonthlyIsLoading,performanceDataMonthly} = usePerformanceDataMonthly(assetId, performanceState.selectedUtility.name, performanceState.yearMonth)

  const assetUtilitiesList = getPerformanceUtilitiesList(selectedLanguage)
  const multiSelectDDLData = getMultiSelectDropdownOptions(performanceState?.selectedUtility.name, selectedLanguage)

  function isGapFillingSelected() {
    return !!performanceState.selectedBreakdown?.find(breakdownOption=>breakdownOption.key===GAP_FILLING)
  }

  //endregion

  //region useEffects
  useEffect(() => {
    if (assetHeaderInfo && !rowData) {
      setAssetData(assetHeaderInfo.assetAddress) // Needed in BackNavigation
      pfDispatcher({...pfState, data: assetHeaderInfo})
    }
  }, [assetHeaderInfo])

  useEffect(() => {
    if(performanceState.selectedEvent && assetEventsData) {
      const currentListOfNotes = assetEventsData.filter(x => x.eventID === performanceState.selectedEvent.eventID)
      updatePerformanceState({events: currentListOfNotes[0]})
    }
    if (performanceDataMonthly && getAssetUtilConsumptions()) {
      updatePerformanceState({meterSummary: performanceDataMonthly.assetMeterDataQualitySummaries})
    }
  }, [assetEventsData, performanceDataMonthly, assetCarbonPerformanceData, assetUtilityPerformanceData])

  useEffect(() => {
    updateQueryParams()
  }, [ queryValues.get(QUERY_KEY_UTILITY), performanceState.selectedUtility.name, time]) //queryValues.get(QUERY_KEY_UTILITY), perfState.selectedUtility.name,
  //endregion

  //region Helper functions
  function getAssetPerformanceQueryKey() {
    let queryKey=[QueryKeyPrefix.ASSET_PERFORMANCE_DATA, assetId, time, performanceState.selectedUtility.name]
    if (timePeriodState.timePeriod === PERIOD_TYPES.CUSTOM) {
      queryKey=[QueryKeyPrefix.ASSET_PERFORMANCE_DATA, assetId, time, performanceState.selectedUtility.name, timePeriodState.timeNoOfMonthsPeriod, timePeriodState.timeEndDatePeriod]
    }
    return queryKey
  }
  function updateQueryParams() {
    let utilitySelected = performanceState.selectedUtility.name
    let datePickerValues = datePickerQueryValues(timePeriodState.timePeriod, timePeriodState.pickerValue)
    saveCustomDateInCache(timePeriodState.pickerValue)
    if(periodOptions.length>0){
      datePickerQuery(periodOptions, datePickerValues, updateDatePickerQuery)
      if(datePickerValues.timePeriodSelected === PERIOD_TYPES.CUSTOM){
        updateTimePeriodState({timeNoOfMonthsPeriod: datePickerValues.noOfMonths, timeEndDatePeriod:createEndMonthParam(datePickerValues.endYear, datePickerValues.endMonth)})
      }
      updateTimePeriodState({timePeriod: dpCustomTime ? PERIOD_TYPES.CUSTOM : datePickerValues.timePeriodSelected})
    }
    // handling valid and invalid values from URL
    if(assetUtilitiesList.length>0){
      utilitySelected = assetUtilitiesList.some(element => element.value === performanceState.selectedUtility.name) === true ? performanceState.selectedUtility.name : selectedUtilityLabel
      updatePerformanceState({selectedUtility: {name: utilitySelected}})
    }
    // handling valid and invalid values from URL
    const queryParams = {
      utility: utilitySelected,
      timePeriod: dpCustomTime ? PERIOD_TYPES.CUSTOM : datePickerValues.timePeriodSelected,
      noOfMonths: datePickerValues.noOfMonths,
      endMonth: datePickerValues.endMonth,
      endYear: datePickerValues.endYear
    }
    const performancePage = createNavURLForFundPages({instanceName, orgName, fundName, assetId, pathSuffix: FUND_VIEWS_PAGES.performance})
    if(history.location.pathname === performancePage) {
      filterQueryParams(history, queryParams, rowData)
    }
  }

  function updateDatePickerQuery(customPickerValues) {
    updateTimePeriodState( {pickerValue: customPickerValues })
  }

  function updatePerformanceState(newState) {
    setPerformanceState(previousState => {
      return {
        ...previousState,
        ...newState
      }
    })
  }

  function getInitialPerformanceState(timePeriodState) {
    let utilityName = queryValues.get(QUERY_KEY_UTILITY)
    utilityName = utilityName ?? selectedUtilityLabel
    if (!utilityName) {
      utilityName = UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY // Default utility name
    }
    return (
      {
        selectedUtility: {name: utilityName},
        showAddNoteDlg: false,
        meterSummary: null,
        selectedEvent: null,
        events: [],
        dataSelectionFilters: [],
        selectedBreakdown: [],
      }
    )
  }

  function getInitialTimePeriodState() {
    const pickerValue = getInitialPickerValue(queryValues)
    return {
      timePeriod: getInitialTimePeriod(queryValues),
      pickerValue,
      timeNoOfMonthsPeriod: pickerValue.pickerValueMonths,
      timeEndDatePeriod: createEndMonthParam(pickerValue.pickerValueEndYear, pickerValue.pickerValueEndMonth),
    }
  }

  const changeTimePeriodHandler = (value) => {
    if (value) {
      updateTimePeriodState({timePeriod: value})
      localStorage.setItem('dpTimePeriod', value)
      saveCustomDateInCache(timePeriodState.pickerValue)
      localStorage.removeItem('dpCustomTimePeriod')
    }
  }

  const clickCustomPeriodHandler = () => {
    updateTimePeriodState({
      timeNoOfMonthsPeriod: timePeriodState.pickerValue.pickerValueMonths,
      timeEndDatePeriod: createEndMonthParam(timePeriodState.pickerValue.pickerValueEndYear, timePeriodState.pickerValue.pickerValueEndMonth),
      timePeriod: PERIOD_TYPES.CUSTOM
    })
  }
  const getTimePeriodMenuItem = (option) => (option === PERIOD_TYPES.YTD) ?
    <span className='p-button-label p-c period-menu-ytd-tooltip' data-pr-position='top'>{option}</span> :
    <span className='p-button-label p-c'>{option}</span>
  const changeDatePickerHandler = (name, value) => {
    saveCustomDateInCache({...(timePeriodState.pickerValue), [name]: value})
    const newPickerValue = {...(timePeriodState.pickerValue), [name]: value}
    updateTimePeriodState({pickerValue: newPickerValue})
  }

  function getBackNavigationProps() {
    return {
      exportName: 'Export',
      onExportClick: (e) => exportOverlay.current.toggle(e),
      period: timePeriodState.timePeriod,
      options: periodOptions,
      datePickerOnChange: (e) => changeTimePeriodHandler(e.value),
      customDatePickerOnClick: () => clickCustomPeriodHandler(),
      itemTemplate: getTimePeriodMenuItem,
      pickerValue: timePeriodState.pickerValue,
      pickerOnChange: (e) => changeDatePickerHandler(e.target.name, e.target.value),
      customTime: false,
      isOverlayPanelOpen: isOverlayPanelOpen,
      hideTimePeriodFilter:false,
    }
  }

  function getOverlayPanelProps() {
    return {
      ref: exportOverlay,
      appendTo: document.getElementById('export'),
      className: 'export-overlay',
      onShow: () => setIsOverlayPanelOpen(true),
      onHide: () => setIsOverlayPanelOpen(false),
    }
  }

  const onUtilityChange = (e) => {
    updatePerformanceState({selectedUtility: {name: e.value}, dataSelectionFilters: [], selectedBreakdown: []})
  }

  const multiSelectOnChangeHandler = (selectedOptions) => {
    const dataSelectionFilters=selectedOptions.filter(option=>GAP_FILLING!==option.key)
    updatePerformanceState({dataSelectionFilters: dataSelectionFilters})
    updatePerformanceState({selectedBreakdown: selectedOptions})
  }

  const closeOverlay = () => {
    exportOverlay.current.hide()
  }

  function getUtilitySummaryData() {
    const assetUtilConsumptions=getAssetUtilConsumptions()
    const selectedUtilityName=performanceState.selectedUtility?.name
    return getUtilityPerformanceSummaryData(assetUtilConsumptions, isGapFillingSelected(), selectedUtilityName, selectedLanguage, isTargetAvailable)
  }

  function getPerformancesEventsData() {
    const consumptionsDataForAll=getAssetUtilConsumptions()?.consumptions.all
    let assetEvents = []
    consumptionsDataForAll?.forEach(({events}) => {
      events?.length > 0 && assetEvents.push(...events)
    })
    assetEvents = assetEvents.filter(event => event.eventType === ActionEventType.COMMERCIAL_EVENT || event.eventType === ActionEventType.COMPLETED_ACTION)
    return assetEvents.length > 0 ? assetEvents : null
    // return eventsData  // For testing locally
  }

  function getMonthlyPerformancesDataForSelectedUtil() {
    const assetUtilConsumptions=getAssetUtilConsumptions()
    const utilConsumptionsData=assetUtilConsumptions?.consumptions?.[ScopeTypes.all]
    return getUtilityConsumptionData(utilConsumptionsData, utilConsumptionsData?.length)
  }

  const getUtilColorClass = (value, isWasteUtility) => {
    let colorClass
    if (value < -5) {
      colorClass = isWasteUtility ? 'red' : 'green'
    } else if (value > 5) {
      colorClass = isWasteUtility ? 'green' : 'red'
    } else {
      colorClass = 'black'
    }
    return colorClass
  }

  function getUtilitySummaryColorClass() {
    const utilityPerformanceSummaryData = getUtilitySummaryData()
    let summaryColorClass
    utilityPerformanceSummaryData?.forEach((item) => {
      let value = item.value
      if (item.key === UtilitySummaryItemKey.usagePercentage || item.key === UtilitySummaryItemKey.percentageRecycled) {
        summaryColorClass = getUtilColorClass(value, false)
        if (performanceState.selectedUtility.name === UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE) {
          summaryColorClass = getUtilColorClass(value, true)
        }
      }
    })
    return summaryColorClass
  }

  const assetPerformanceExportInfoProps = {
    'closeOverlay': closeOverlay,
    'performanceData': tableDataPdfRef.current,
    'performance': true,
    'showTarget': isTargetAvailable,
    'utility': performanceState.selectedUtility,
    'overview': getUtilitySummaryData(),
    'pickerValue': timePeriodState.pickerValue,
    'language': selectedLanguage,
    'excelData': utilitySummaryExportData,
    'exportFrom': {'page-type': 'asset-page', 'from': 'asset-performance'},
    'time': time
  }

  function getPerformanceChartProps() {
    const apiData = {
      performanceDataMonthly,
      assetEventsData,
      performanceDataMonthlyIsLoading,
      assetCarbonPerformanceData,
      assetUtilityPerformanceData
    }
    const actionHandlers = {
      refetchAssetEventsData,
      refetchAssetCarbonPerformanceData,
      refetchAssetUtilityPerformanceData,
      updatePerformanceState
    }
    const performanceStates = {
      performanceState,
      monthlyPerformancesForChartEvents: getMonthlyPerformancesForChartEvents(getMonthlyPerformancesDataForSelectedUtil(), performanceState.selectedUtility.name),
      isGapFillingSelected: isGapFillingSelected(),
      showTargets: isTargetAvailable,
      selectedLanguage,
      timePeriod: timePeriodState.timePeriod,
    }
    return {apiData, actionHandlers, performanceStates}
  }

  function disableOptionItem(option) {
    const assetUtilConsumptions=getAssetUtilConsumptions()
    if(option.key === GAP_FILLING) {
      return !assetUtilConsumptions
    }
    let disableOptionItemFlag
    const consumptionsData=assetUtilConsumptions?.consumptions?.[option.key]
    if(consumptionsData){
      disableOptionItemFlag = !(consumptionsData.some(consumption => (consumption.actualBelowTarget > 0 || consumption.actualAboveTarget > 0 || consumption.estimatedBelowTarget > 0 || consumption.estimatedAboveTarget > 0)))
    }
    return (!assetUtilConsumptions || !!disableOptionItemFlag)
  }

  function getAssetUtilConsumptions() {
    return performanceState?.selectedUtility?.name === UtilityTypes.Carbon? assetCarbonPerformanceData: assetUtilityPerformanceData
  }

  function renderAssetExport() {
    return <AssetExportSelection exportDetails={assetPerformanceExportInfoProps}/>
  }
  //endregion

  return (
    <AssetLayout getBackNavigationProps={getBackNavigationProps} getOverlayPanelProps={getOverlayPanelProps} renderAssetExport={renderAssetExport}  pageContainerClass={'performance-page-container'}>
      <div className='performance-page-body page-content'>
        <div className='page-body-sections'>
          <div data-testid={'utility-list'} className="flex mb-4 asset-filter-section">
            <Dropdown
              appendTo='self'
              panelClassName="utility-dropdown-panel"
              className='sDropdown asset-filter-section__dropdown asset-filter-section__dropdown--utility'
              value={performanceState.selectedUtility.name}
              options={assetUtilitiesList}
              onChange={onUtilityChange}
              scrollHeight={DROP_DOWN_HEIGHT}
              data-testid={'utility-dropdown'}
            />
            {multiSelectDDLData?.showMultiDropdown &&
                <MultiSelectDropdown
                  dataInfo={multiSelectDDLData}
                  onChangeHandler={multiSelectOnChangeHandler}
                  multiSelectedOptions={performanceState.selectedBreakdown}
                  disableOptionItemHandler={disableOptionItem}
                  className={'asset-filter-section__dropdown asset-filter-section__dropdown--breakdown'}
                />
            }
            <ScopesInformation performanceState={performanceState} className={'scopesInfo-assetPerformance'}/>
          </div>
          <div className="col-12 grid-nogutter pGraph-section utility-summary-section">
            <div className='col-12 grid-nogutter header-container'>
              {assetCarbonPerformanceDataLoading || assetUtilityPerformanceDataLoading ?
                <div className='col-9 utility-overview'>
                  <PerformanceHeadersSkeleton/>
                </div> :
                <div className='col-6 utility-overview'>
                  <PerformanceChartSummary
                    summaryItems={getUtilitySummaryData()}
                    selectedUtilityName={performanceState.selectedUtility.name}
                    selectedLanguage={selectedLanguage}
                    summaryColorClass={getUtilitySummaryColorClass()}
                    currencyUnit={currencyUnit}
                  />
                </div>
              }
            </div>
          </div>
          <div className="col-12 grid-nogutter pGraph-section mt-5 performance-chart-section">
            {assetCarbonPerformanceDataLoading || assetUtilityPerformanceDataLoading ?
              <div className="asset-performance-chart-skeleton-wrapper"><PerformanceChartSkeleton/></div>
              :
              <AssetPerformanceChart {...getPerformanceChartProps()} />
            }
          </div>
          <div className='col-12 divider'>
            <hr className='horizontal-line '/>
          </div>
          <span className='eventsGrid-title'>{I18n('t_events')}</span>
          <div className='col-12 pTable-section events-table-section'>
            <EventActions
              performancesEventsData={getPerformancesEventsData()}
              isLoadingAssetPerformanceData={assetCarbonPerformanceDataLoading || assetUtilityPerformanceDataLoading}
              tableDataPdfRef={tableDataPdfRef}
              selectedLanguage={selectedLanguage}
              unitSystem={unitSystem}
            />
          </div>
        </div>
      </div>
    </AssetLayout>
  )
}

PerformanceHome.displayName = 'PerformanceHome'

export default PerformanceHome
