import downArrowGreenImage from '../../../../../resources/images/chart/down-arrow-green.svg'
import missingDataImage from '../../../../../resources/images/icon/warning-red.svg'
import Chart from 'chart.js/auto'
import CloseGray from '../../../../../resources/images/icon/close-gray.svg'
import {formatNumWODecimal, trucateNumWTwoDecimal} from '../../../../../services/common/formatter'
import {
  bigNumberFormatter,
  getDivisorForTonneOrMegawatt,
  getLocalizedValue,
  getUtilityUnits,
  momentDate,
  SessionUserState
} from '../../../../../utils/helpers/Helper'
import {AdjustValue} from '../../../funds/performance/FundPerformanceUtilitySuffixes'
import {costMask, FLOOR_MEASUREMENT_TYPES, UTILITY_LABELS_OBJECT_TITLE_CASE, assetsOrderByColumnChange, ScopeTypes} from '../../../../../utils/helpers/Constants'
import triangleIcon from '../../../../../resources/images/icon/rounded-triangle.svg'
import SquareIcon from '../../../../../resources/images/icon/square.svg'
import dot from '../../../../../resources/images/dialog/dot.svg'
import CSS_VARIABLES from '../../../../../resources/css/_variables.scss'
import {getDefaultFontObjectForChart} from '../../../../common/net-zero-carbon/nzc-common-utils'
import { returnValueAvailableOrZero } from '../../../funds/net-zero-carbon-home/nzc-utils/nzc-utils'

export const removeBarMinHeight = {
  id: 'removeBarMinHeight',
  beforeDatasetDraw: function(chart, args) {
    const _dataset=args?.meta?._dataset
    const yMax=_dataset?.yMax
    const minBarLength=3
    if(_dataset?.minBarLength>0 && yMax>0){
      const scopeDatasets=chart.data?.datasets.filter(value => value.dataSelectionFilterKey === _dataset.dataSelectionFilterKey)
      const scopeBarsHeight = Array(args?.meta?.data?.length).fill(0)
      const visibleBarMinHeightPercent=0.25
      scopeDatasets?.forEach((scopeDataset)=>{
        scopeDataset?.data?.forEach((barStackHeight, index)=>{
          scopeBarsHeight[index]=(scopeBarsHeight[index]+barStackHeight)
        })
      })
      args?.meta?.data?.forEach((barElement, index) => {
        const scopeBarHeight=scopeBarsHeight[index]
        const scopeBarHeightPercent=(scopeBarHeight*100/yMax)
        if(scopeBarHeightPercent>visibleBarMinHeightPercent){
          const stackBarHeight=barElement.base-barElement.y
          if((stackBarHeight)===minBarLength){
            barElement.y=(barElement.y+minBarLength)
          }
        }
      })
    }
  }
}

//Draw target line and show clap icons in the bar chart
export const horizontalLinePlugin = {
  id: 'PerformanceChart_target_line',
  renderHorizontalLine: function (chartInstance, line, convertToTonneOrMegawatt) {
    let datasetMeta = chartInstance.getDatasetMeta(0)
    let context = chartInstance.ctx
    //=== Coordinates ===

    // x-range (depending on which bar to draw the line for) :
    let datasetModel = datasetMeta.data[line.dataIndex]
    if(datasetModel){
      const xBarCenter = datasetModel.x
      const barWidth = datasetModel.width
      const xStart = xBarCenter - ((barWidth / 2) + 10)
      const xEnd = xBarCenter + ((barWidth / 2) + 10)

      // y-coordinate (line's value) :
      const yAxisID = datasetMeta.yAxisID
      const yCoordinate = chartInstance.scales[yAxisID].getPixelForValue(line.yCoordinate)
      let stackedValue = line.uptoTarget + line.overTarget + line.additionalAmount + line.missingData

      if (convertToTonneOrMegawatt) {
        const divisor = getDivisorForTonneOrMegawatt(line.utilityName)
        stackedValue = stackedValue > 0 ? stackedValue / divisor : 0
      }

      const stackCoordinate = chartInstance.scales[yAxisID].getPixelForValue(stackedValue)

      // === Drawing the line ===
      context.beginPath()
      context.lineWidth = 2
      context.strokeStyle = CSS_VARIABLES.deep_ocean
      context.moveTo(xStart, yCoordinate)
      context.lineTo(xEnd, yCoordinate)
      context.stroke()

      // === Writing label under line's end ===
      let resultIcon = new Image()
      resultIcon.src = downArrowGreenImage
      if (typeof line.percentageLabel !== typeof undefined) {
        context.textAlign = 'center'
        context.fillStyle = line.color
        context.lineWidth = 10
        context.font = line.timePeriod === '2Y' ? `80% ${CSS_VARIABLES.fontFamilyBase}` : `112.5% ${CSS_VARIABLES.fontFamilyBase}`

        // Show missingData icon if missing data available
        if (line.missingData !== 0 && line.isPdf === false) {
          resultIcon.src = missingDataImage
          resultIcon.onload = function () {
            context.drawImage(resultIcon, xBarCenter - 10, stackCoordinate - 30, 20, 20)
          }
        }
      }
    }
  },

  renderVerticalLine: function (chartInstance, line, labelPosition, targetLastValue, selectedLanguage) {

    let datasetMeta = chartInstance.getDatasetMeta(0)
    let context = chartInstance.ctx

    //===Coordinates===

    //x-range(depending on which bar to draw the line for):
    let datasetModel = datasetMeta.data[line.dataIndex]
    const yBarCenter = datasetModel.y
    const barHeight = 20
    const yStart = yBarCenter - ((barHeight / 2) + 10)
    const yEnd = yBarCenter + ((barHeight / 2) + 10)

    //y-coordinate(line's value):
    const xAxisID = datasetMeta.xAxisID
    const yCoordinate = chartInstance.scales[xAxisID].getPixelForValue(line.yCoordinate)
    const tonnesUtilityWidth = 28
    const kilogramsUtilityWidth = 45
    const tonnesLabelPart = 'tCO'
    const kgLabelPart = 'kg CO'
    const subscriptTwo = '2'

    const targetLabel = parseInt(line.yCoordinate) > 0 &&  parseInt(line.yCoordinate) >= 1000 ? tonnesLabelPart : kgLabelPart
    const targetLabelSubscript = parseInt(line.yCoordinate) > 0 &&  parseInt(line.yCoordinate) >= 1000 ? tonnesUtilityWidth : kilogramsUtilityWidth

    if(yCoordinate) {

      //===Drawing the line===
      context.beginPath()
      context.lineWidth = 2
      context.strokeStyle = CSS_VARIABLES.deep_ocean
      context.moveTo(targetLastValue ? yCoordinate - 1 : yCoordinate, yStart)
      context.lineTo(targetLastValue ? yCoordinate - 1 : yCoordinate, yEnd - 10)
      context.stroke()

      //===Writing label at line's top===
      context.fillStyle = line.color
      context.lineWidth = 10
      context.font = `87.5% ${CSS_VARIABLES.fontFamilyBase}`
      context.fontWeight = '500'
      context.textAlign = labelPosition
      const completeLabel = `${formatNumWODecimal(selectedLanguage, AdjustValue(parseInt(line.yCoordinate), UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON))} ${targetLabel}`
      const totalWidth = context.measureText(completeLabel).width
      const percentageValue = (((totalWidth - targetLabelSubscript) / totalWidth) * 100) / 100

      let gradient = context.createLinearGradient(yCoordinate, 0, yCoordinate + totalWidth, 0)
      let subscriptPositionLeft = yCoordinate + (totalWidth / 2) + 3
      let subscriptPositionTop = yEnd - 48
      let mainTargetTextPositionLeft = yCoordinate

      if(labelPosition === 'left') {
        subscriptPositionLeft = yCoordinate + totalWidth
      }

      if(labelPosition === 'center') {
        gradient = context.createLinearGradient(yCoordinate - (totalWidth / 2), 0, (yCoordinate - (totalWidth / 2)) + totalWidth, 0)
      }

      if(labelPosition === 'right') {
        gradient = context.createLinearGradient((yCoordinate - totalWidth), 0, yCoordinate - 6, 0)
        mainTargetTextPositionLeft = yCoordinate - 6
        subscriptPositionLeft = yCoordinate
      }

      gradient.addColorStop(0, CSS_VARIABLES.forest)
      gradient.addColorStop(percentageValue, CSS_VARIABLES.forest)
      gradient.addColorStop(percentageValue, CSS_VARIABLES.forest)
      gradient.addColorStop(1, CSS_VARIABLES.forest)

      context.fillStyle = gradient
      context.fillText(completeLabel, mainTargetTextPositionLeft, yEnd - 50)
      context.font = `53.125% ${CSS_VARIABLES.fontFamilyBase}`
      context.fillStyle = CSS_VARIABLES.forest
      context.fillText(subscriptTwo, subscriptPositionLeft, subscriptPositionTop)
    }
  },

  drawHorizontalLine: function (context, yCoordinate, xStart, xEnd){
    // === Drawing the line ===
    context.beginPath()
    context.lineWidth = 2
    context.strokeStyle = CSS_VARIABLES.deep_ocean
    context.moveTo(xStart, yCoordinate)
    context.lineTo(xEnd, yCoordinate)
    context.stroke()
  },

  drawTargetOrPreviousLine: function(chartInstance, lineValue, index) {
    let datasetMeta =chartInstance?._metasets?.[index]
    let context = chartInstance.ctx

    //=== Coordinates ===
    // x-range (depending on which bar to draw the line for) :
    let datasetModel = datasetMeta?.data[0]
    if(datasetModel){
      const xBarCenter = datasetModel.x
      const barWidth = datasetModel.width
      const barHeight = datasetModel.height
      const yAxisSize = datasetModel.y
      const xStart = xBarCenter - ((barWidth / 2) + 5)
      const xEnd = xBarCenter + ((barWidth / 2) + 5)

      const yAxisID = datasetMeta.yAxisID
      const yCoordinate = chartInstance.scales[yAxisID].getPixelForValue(lineValue)

      // === Drawing the line ===
      context.beginPath()
      context.lineWidth = Math.abs(trucateNumWTwoDecimal(yAxisSize)) === trucateNumWTwoDecimal(barHeight) ? 3 : 6
      context.strokeStyle = CSS_VARIABLES.deep_ocean
      context.moveTo(xStart, yCoordinate)
      context.lineTo(xEnd, yCoordinate)
      context.stroke()
    }
  },

  renderHorizontalLineSimplifiedV2: function(chartInstance, barData, index, isGapFillingSelected) {
    // For multiple bars
    const barStacks=chartInstance?._metasets
    let targetLine = barData?.previousActual
    if (isGapFillingSelected) {
      targetLine = barData?.target
    }
    const barStack=barStacks[0]
    if(barStack && barData?.scopeId === ScopeTypes.all){ // SPR-5360: Do not draw target line when Scopes/SplitTypes are selected.
      let context = chartInstance.ctx
      const xBarCenter = barStack.data?.[index]?.x
      const barWidth =  barStack.data?.[index]?.width

      const xStart = xBarCenter - ((barWidth / 2) + 10)
      const xEnd = xBarCenter + ((barWidth / 2) + 10)

      // y-coordinate (line's value) :
      const yAxisID = barStack.yAxisID
      const yCoordinate = chartInstance.scales[yAxisID].getPixelForValue(targetLine)
      horizontalLinePlugin.drawHorizontalLine(context, yCoordinate, xStart, xEnd)
    }
  },

  renderHorizontalLineSimplified: function(chartInstance, line, index) {
    let datasetMeta = chartInstance.getDatasetMeta(0)
    let context = chartInstance.ctx

    //=== Coordinates ===
    // x-range (depending on which bar to draw the line for) :
    let datasetModel = datasetMeta.data[index]
    const xBarCenter = datasetModel.x

    const barWidth = datasetModel.width

    const xStart = xBarCenter - ((barWidth / 2) + 10)
    const xEnd = xBarCenter + ((barWidth / 2) + 10)

    // y-coordinate (line's value) :
    const yAxisID = datasetMeta.yAxisID
    const yCoordinate = chartInstance.scales[yAxisID].getPixelForValue(line)

    horizontalLinePlugin.drawHorizontalLine(context, yCoordinate, xStart, xEnd)
  },

  afterDatasetsDraw: function (chart) {
    if (chart.data.datasets.length < 1) {
      // No dataset, no line :
      return
    }

    if (chart.config._config.lines) {
      chart.config._config.lines.map(line => {
        if (chart.config._config.isFundDashboard && chart.config._config.isFundDashboard === true) {
          horizontalLinePlugin.renderVerticalLine(chart, line, chart.config._config.targetLabelPosition, chart.config._config.targetLastValue, chart.config._config.selectedLanguage)
        } else {
          horizontalLinePlugin.renderHorizontalLine(chart, line, false)
        }
      })
    }

    // For target/previousPeriod line in asset/fund dashboard
    const lineDataIndex=chart?.data?.datasets?.findIndex(data=> (data.stackName==='previousPeriod' || data.stackName==='target'))
    const prevOrTargetLineData=chart?.data?.datasets?.[lineDataIndex]
    if (prevOrTargetLineData) {
      horizontalLinePlugin.drawTargetOrPreviousLine(chart, prevOrTargetLineData?.data?.[0], lineDataIndex)
    }

    // For Asset Performance chart
    const targetLineDatasets=chart.config?._config?.targetLineDatasets
    if (targetLineDatasets) {
      targetLineDatasets.forEach(targetLine => {
        targetLine.forEach((lineData)=>{
          if('all'===lineData.dataSelectionKey){
            horizontalLinePlugin.renderHorizontalLine(chart, lineData, true)
          }
        })
      })
    }

    const chartConfig=chart.config?._config
    if (chartConfig?.fundPerformanceTargetLines) {
      chartConfig.fundPerformanceTargetLines.forEach((scopeData) => {
        scopeData.forEach((barData, index) => {
          horizontalLinePlugin.renderHorizontalLineSimplifiedV2(chart, barData, index, chartConfig.isGapFillingSelected)
        })
      })
    }

  }
}

Chart.register(horizontalLinePlugin)

export function getTimeperiodLabel(value) {
  let timePeriodLabel
  switch (value) {
  case 'YTD':
    timePeriodLabel = 'YTD'
    break
  case '1Y':
    timePeriodLabel = 'OneYear'
    break
  case '3M':
    timePeriodLabel = 'ThreeMonths'
    break
  case '6M':
    timePeriodLabel = 'SixMonths'
    break
  case '2Y':
    timePeriodLabel = 'TwoYears'
    break
  case 'CUS':
    timePeriodLabel = 'Custom'
    break
  default:
    timePeriodLabel = 'OneYear'
  }
  return timePeriodLabel
}

export function createDiagonalPattern(color = CSS_VARIABLES.forest) {
  let shape = document.createElement('canvas')
  shape.width = 10
  shape.height = 10
  let c = shape.getContext('2d')
  c.strokeStyle = color
  c.beginPath()
  c.lineWidth = 2
  c.moveTo(10, 8)
  c.lineTo(2, 0)
  c.stroke()
  c.beginPath()
  c.moveTo(2, 10)
  c.lineTo(0, 8)
  c.stroke()
  return c.createPattern(shape, 'repeat')
}

export function createReverseDiagonalPattern(color = CSS_VARIABLES.forest) {
  let shape = document.createElement('canvas')
  shape.width = 10
  shape.height = 10
  let c = shape.getContext('2d')
  c.strokeStyle = color
  c.beginPath()
  c.lineWidth = 2
  c.moveTo(0, 10)
  c.lineTo(10, 0)
  c.stroke()
  c.beginPath()
  c.moveTo(10, 10)
  c.lineTo(10, 9)
  c.stroke()
  c.beginPath()
  c.moveTo(0, 1)
  c.lineTo(0, 0)
  c.stroke()
  return c.createPattern(shape, 'repeat')
}

export const chartAreaBorder = {
  id: 'chartAreaBorder',
  afterDatasetsDraw(chart) {
    const {ctx, chartArea: {top, right, height}} = chart
    ctx.save()
    ctx.strokeStyle = CSS_VARIABLES.forest_20
    ctx.lineWidth = 2
    ctx.strokeRect(right, top, 0, height)
    ctx.restore()
  }
}

export const getChartOptions = (
  isTargetAvailable,
  isFund,
  yScaleTicks, 
  selectedLanguage, 
  selectedUtilityObject,
  addNoteHandlerRef,
  tooltipObject,
  displayVerticalGridLines = true
) => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    drawOnChartArea: true,
    animation: false,
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: CSS_VARIABLES.forest,
          padding: 15,
          font: getDefaultFontObjectForChart({style: 'bold', size: 14})
        },
        grid: {
          drawTicks: false,
          display: displayVerticalGridLines
        }
      },
      y: {
        stacked: true,
        max: yScaleTicks.max,
        min: yScaleTicks.min,
        beginAtZero: true,
        ticks: {
          color: CSS_VARIABLES.forest,
          padding: 15,
          font: getDefaultFontObjectForChart({size: 12}),
          callback: function (value, index, values) {
            if (value === yScaleTicks.max && yScaleTicks.stepSize !== 0) {
              return getUtilityUnits(isFund,selectedUtilityObject.name)
            } else if (values[index - 1] === 0) {
              return getUtilityUnits(isFund,selectedUtilityObject.name)
            } else if (yScaleTicks.stepSize <= 1) {
              return value
            }
            
            return formatNumWODecimal(selectedLanguage, parseInt(value.toFixed(2)))
          }
        },
        grid: {
          drawTicks: false,
        }
      }
    },
    onHover: (event, chartElement) => {
      if (chartElement.length > 0) {
        event.native.target.style.cursor = 'pointer'
      } else if (chartElement.length === 0) {
        event.native.target.style.cursor = 'default'
      }
    },

    isTargetAvailable: isTargetAvailable,
    plugins: {
      legend: {
        display: false,
        labels: {font: getDefaultFontObjectForChart()}
      },
      datalabels: {display: false},
      tooltip: tooltipObject
    },
    onClick: (evt)=> {
      const points = window.bar.getElementsAtEventForMode(evt, 'nearest', {intersect: true}, true)
      if (points.length) {
        const firstPoint = points[0]
        if(addNoteHandlerRef)
          addNoteHandlerRef(evt, window.bar.config._config.lines[firstPoint.index])
      }
    }
  }
}

export function removeNzcActionOverlay(chart) {
  const nzcOverlayRoot=chart.canvas.parentNode.querySelector('.nzc-overlay-root')
  if(nzcOverlayRoot){
    nzcOverlayRoot.remove()
  }
}

// triggering tooltip through JS
const getOrCreateTooltipNzc = (chart) => {
  removeNzcActionOverlay(chart)
  let tooltipEl = chart.canvas.parentNode.querySelector('div')

  if (!tooltipEl) {
    tooltipEl = document.createElement('div')
    tooltipEl.style.opacity = 1
    tooltipEl.setAttribute('class', 'asset-detail-panel')
    tooltipEl.innerHTML = '<div class="asset-panel-tooltip-content"></div>'
    chart.canvas.parentNode.appendChild(tooltipEl)
  }

  return tooltipEl
}

function getAssetNzcPageLink(selectedLanguage, assetTooltipConfig) {
  return assetTooltipConfig.showAssetNzcPageLink ? `<a href=${assetTooltipConfig?.assetNzcPageLink}>${getLocalizedValue(selectedLanguage, 't_view_nzc_tp_for_asset')}</a>` : ''
}

const getMeasureUnit = (bodyLines) => {
  return bodyLines?.asset?.measurementUnit === 'M2' ? FLOOR_MEASUREMENT_TYPES.metric : FLOOR_MEASUREMENT_TYPES.imperial
}

const getImage = (roundedPercentage) => {
  let image
  if(roundedPercentage>=50 && roundedPercentage<=99){
    image = { 'imageClass': 'square',
      'imageSrc': SquareIcon }
  }
  else if(roundedPercentage<=49){
    image =  { 'imageClass': 'triangle',
      'imageSrc': triangleIcon }
  }
  else{
    image = { 'imageClass': 'dot',
      'imageSrc': dot }
  }
  return image
}

const getEnergyIntensity = (isEnergyIntensity, bodyLines, selectedLanguage) => {
  return isEnergyIntensity ? `<div class="asset-panel-body__list-group-item--list-item"><span class="asset-panel-body__list-group-item--list-item--asset-key">${getLocalizedValue(selectedLanguage, 't_energy_intensity')} ${'(' + (getLocalizedValue(selectedLanguage, 't_kwh_m2_yr')) + ')'}</span> <span class="asset-panel-body__list-group-item--list-item--asset-value">${(bodyLines.energyIntensity)?.toLocaleString(selectedLanguage, {maximumFractionDigits: 2 })} </span></div>` :
    `<div class="asset-panel-body__list-group-item--list-item"><span class="asset-panel-body__list-group-item--list-item--asset-key">${getLocalizedValue(selectedLanguage, 't_carbon_intensity')} ${'(' + (getLocalizedValue(selectedLanguage, 't_kg_co2_m2_yr')) + ')'}</span> <span class="asset-panel-body__list-group-item--list-item--asset-value">${(bodyLines.carbonIntensity)?.toLocaleString(selectedLanguage, {maximumFractionDigits: 2 })} </span></div>`
}

const removeFunc = (condition) => {
  if (condition)
    condition.remove()
}

const makeToolTipOpaque = (tooltip, tooltipEl) => {
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0
    return ''
  }
}

const getEpcRating = (bodyLines) => {
  return SessionUserState.getUserDataFromSession('epc') || bodyLines.epcRating
}

const checkDataset = (chart, datasets, datasetIndex) => {
  return chart.tooltip._active.length > 0 && datasets[datasetIndex].data[0].datasets !== undefined
}

const getClassName = (assetTooltipConfig) => {
  return assetTooltipConfig?.assetNzcPageLink !== null ? 'asset-nzc-page-link' : 'd-none'
}

const getGavValue = (bodyLines,selectedLanguage) => {
  return `${costMask[bodyLines?.asset?.gavCurrency ?? 'GBP']}${bigNumberFormatter(bodyLines?.asset?.gav,selectedLanguage)}`
}

export const externalTooltipHandlerNzc = (context, assetTooltipConfig, isEnergyIntensity) => {
  // Tooltip Element
  const {chart, tooltip} = context
  const tooltipEl = getOrCreateTooltipNzc(chart)
  let datasets = chart.data.datasets
  let datasetIndex

  chart.tooltip._active.forEach((dataIndex) => datasetIndex = dataIndex.datasetIndex)

  if (checkDataset(chart, datasets, datasetIndex)) {
    let selectedLanguage = context.tooltip.dataPoints[0].dataset.language

    // Hide if no tooltip
    makeToolTipOpaque(tooltip, tooltipEl)

    // Set Text
    if (tooltip.body) {
      const bodyLines = datasets[chart.tooltip._active[0].datasetIndex].data[0].datasets

      if(bodyLines) {
        let roundedPercentage = Math.trunc(bodyLines.dataAvailablePercentage * 100)
        const imageSrc = getImage(roundedPercentage).imageSrc
        const imageClass = getImage(roundedPercentage).imageClass

        // asset-panel-header
        const header = document.createElement('div')
        header.setAttribute('class', 'asset-panel-header')
        // asset-panel-body
        const measurementUnit = getMeasureUnit(bodyLines)
        const body = document.createElement('div')
        let sold = ''
        let purchased = ''
        if(bodyLines?.featureStateSoldAndPurchased){
          sold = bodyLines?.asset?.dateOfSale ? `Sold ${momentDate(bodyLines?.asset?.dateOfSale, 'YYYY', selectedLanguage)}` : ''
          purchased = bodyLines?.asset?.dateOfPurchase ? `Purchased ${momentDate(bodyLines?.asset?.dateOfPurchase, 'YYYY', selectedLanguage)}` : ''
        }
        header.innerHTML = `<div class="asset-panel-header__header-panel-content">
                          <span class="asset-panel-header__header-panel-content--asset-ref">${getLocalizedValue(selectedLanguage, 't_asset_ref')}: ${bodyLines.asset.assetReference} 
                          <img src="${CloseGray}" id="closeAssetPanel" class='asset-panel-header__header-panel-content--asset-ref--panel-close cursor'/></span>
                          <span class="asset-panel-header__header-panel-content--asset-title"> ${bodyLines?.asset?.assetName}
                          </span>
                          <span class="asset-panel-header__header-panel-content--asset-address">${bodyLines.asset.city}, ${bodyLines.asset.country}</span>
                          <div class="asset-panel-body__list-group-item--list-item">
                            <span class="asset-panel-header__header-panel-content--asset-address">${purchased}</span>
                            <span class="asset-panel-header__header-panel-content--asset-address">${sold}</span>
                          </div>`
        body.innerHTML = `
                          <div style="display: block" class="asset-panel-body">
                          <div class="asset-panel-body__list-group-item">
                            <div class="asset-panel-body__list-group-item--list-item"><span class="asset-panel-body__list-group-item--list-item--asset-key">${getLocalizedValue(selectedLanguage, 't_year')}</span> <span class="asset-panel-body__list-group-item--list-item--asset-value">${bodyLines.year} </span></div>
                            <div class="asset-panel-body__list-group-item--list-item"><span class="asset-panel-body__list-group-item--list-item--asset-key">${getLocalizedValue(selectedLanguage, 't_nzc_data_availability')}</span><span class="asset-panel-body__list-group-item--list-item--asset-value"><img src="${imageSrc}" class="${imageClass} data-missing-icon" alt="data-missing-icon" /> ${roundedPercentage}%</span></div>
                            <div class="asset-panel-body__list-group-item--list-item"><span class="asset-panel-body__list-group-item--list-item--asset-key">${getLocalizedValue(selectedLanguage, 't_stranding_year')}</span> <span class="asset-panel-body__list-group-item--list-item--asset-value">${returnValueAvailableOrZero(bodyLines?.strandingYear, '-')} </span></div>
                            <div class="asset-panel-body__list-group-item--list-item"><span class="asset-panel-body__list-group-item--list-item--asset-key">${getLocalizedValue(selectedLanguage, 't_asset_agree_actions')}</span> <span class="asset-panel-body__list-group-item--list-item--asset-value">${returnValueAvailableOrZero(bodyLines?.agreedActions, 0)} </span></div>
                            <div class="asset-panel-body__list-group-item--list-item"><span class="asset-panel-body__list-group-item--list-item--asset-key">${getLocalizedValue(selectedLanguage, 't_applicable_actions')}</span> <span class="asset-panel-body__list-group-item--list-item--asset-value">${returnValueAvailableOrZero(bodyLines?.applicableActions, 0)}</span></div>
                            ${getEnergyIntensity(isEnergyIntensity, bodyLines, selectedLanguage)}
                          <div class="asset-panel-body__list-group-item--list-item"><span class="asset-panel-body__list-group-item--list-item--asset-key">${getLocalizedValue(selectedLanguage, 't_co2_emissions')} ${'(' + (getLocalizedValue(selectedLanguage, 't_kg_co2_yr')) + ')'}</span> <span class="asset-panel-body__list-group-item--list-item--asset-value">${Math.trunc(bodyLines.co2Emission).toLocaleString(selectedLanguage, {maximumFractionDigits: 2 })} </span></div>
                          <div class="asset-panel-body__list-group-item--list-item"><span class="asset-panel-body__list-group-item--list-item--asset-key">${getLocalizedValue(selectedLanguage, 't_Asset_EPC_rating')}</span> <span class="asset-panel-body__list-group-item--list-item--asset-value">${getEpcRating(bodyLines)} </span></div>
                          <div class="asset-panel-body__list-group-item--list-item"><span class="asset-panel-body__list-group-item--list-item--asset-key">${getLocalizedValue(selectedLanguage, 't_gross_asset_value')}</span> <span class="asset-panel-body__list-group-item--list-item--asset-value">${getGavValue(bodyLines,selectedLanguage)}</span></div>
                          <div class="asset-panel-body__list-group-item--list-item"><span class="asset-panel-body__list-group-item--list-item--asset-key">${getLocalizedValue(selectedLanguage, 't_gia')} ${'('+ measurementUnit +')'}</span> <span class="asset-panel-body__list-group-item--list-item--asset-value">${bodyLines?.asset?.gia?.toLocaleString(selectedLanguage, {maximumFractionDigits: 2 })} </span></div>
                          </div>
                          <div class=${getClassName(assetTooltipConfig)}>${getAssetNzcPageLink(selectedLanguage, assetTooltipConfig)}</div>`


        const tableRoot = tooltipEl.querySelector('div')

        // Remove old children
        while (tableRoot.firstChild) {
          tableRoot.firstChild.remove()
        }

        // Add new children
        tableRoot.appendChild(header)
        tableRoot.appendChild(body)
      }
    }

    let viewport = context.chart.canvas
    let viewportPosition = viewport.getBoundingClientRect()
    const tooltipElPosition = tooltipEl.getBoundingClientRect()

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1
    tooltipEl.style.position = 'absolute'
    tooltipEl.style.top = '10%'
    tooltipEl.style.padding = tooltip.padding + 'px ' + tooltip.padding + 'px'

    if (viewportPosition.width - tooltip._eventPosition.x < tooltipElPosition.width) {
      tooltipEl.style.right = viewportPosition.width - tooltip.caretX + 'px'
    } else {
      tooltipEl.style.left = tooltip.caretX + 100 + 'px'
    }
  } else removeFunc(chart.canvas.parentNode.querySelector('div'))
}

export const closeAssetPanel = () => {
  const assetPanel = document.querySelector('.asset-detail-panel')
  if(assetPanel) {
    assetPanel.style.display = 'none'
  }
}

// NZC asset and fund bau tooltip handlers
function getLabel(language, labelKey) {
  return getLocalizedValue(language, labelKey)
}

function getFundBauTooltipElements(context, fundBauChartData) {
  const circleIcon=dot
  const squareIcon=SquareIcon
  const language = context.tooltip.dataPoints[0].dataset.language
  let intensityLabel
  let intensityValue
  if(fundBauChartData.intensity === assetsOrderByColumnChange.energyIntensity){
    intensityLabel = getLabel(language, 't_average_energy_intensity')
    intensityValue = `${Math.round(fundBauChartData.bauGhgIntensity ?? 0).toLocaleString(language, {maximumFractionDigits: 2 })} ${getLabel(language, 't_kWh_m2_yr')}`
  } else {
    intensityLabel = getLabel(language, 't_average_carbon_intensity')
    intensityValue = `${Math.round(fundBauChartData.bauGhgIntensity ?? 0).toLocaleString(language, {maximumFractionDigits: 2 })} ${getLabel(language, 't_kg_co2_m2_yr')}`
  }
  return(
    `
              <div class="fund-bau-intensity-popup">
                  <div class="popup-header">
                      <img src=${CloseGray} class="popup-close-icon cursor" id="img-popup-close-icon" />
                      <span class="fund-name">${fundBauChartData.fundName}</span>
                  </div>
                  <div class="popup-content">
                      <div class="content-year">
                          <span class="year-label">${getLabel(language, 't_year')}</span>
                          <br/>
                          <span class="year-value">${fundBauChartData.year}</span>
                      </div>
                      <div class="content-carbon-intensity">
                          <span class="carbon-intensity-label">${intensityLabel}</span>
                          <br/>
                          <span class="carbon-intensity-value">${intensityValue}</span>
                      </div>
                      <div class="content-data-availability">
                          <span class="data-availability-label">${getLabel(language, 't_data_availability_assets')}</span>
                          <div class="data-availability-value">
                              <img class="assets-actual-icon data-icon" src=${circleIcon} alt={'Actual'}/>
                              <span class="assets-actual-value data-value">${fundBauChartData.dataAvailabilityOfAssets?.actualData?.length || 0} ${getLabel(language, 't_actual')}</span>
                              <img class="assets-uplifted-icon data-icon" src=${squareIcon} alt={'Uplifted'}/>
                              <span class="assets-uplifted-value data-value">${fundBauChartData.dataAvailabilityOfAssets?.upliftedData?.length || 0} ${getLabel(language, 't_uplifted')}</span>
                              <img class="assets-benchmarked-icon data-icon" src=${triangleIcon} alt="Benchmarked"/>
                              <span class="assets-benchmarked-value data-value">${fundBauChartData.dataAvailabilityOfAssets?.benchmarkedData?.length || 0} ${getLabel(language, 't_benchmark')}</span>
                          </div>
                      </div>
                  </div>
              </div>
              `
  )
}

export function getDefaultTooltipConfig() {
  return {
    position: 'average',
    mode: 'dataset',
    events: [''],
    enabled: false
  }
}

export function hideAssetTooltip(chart) {
  chart.options.plugins.tooltip = getDefaultTooltipConfig()
  chart.update()
  removeAssetTooltipNode(chart)
  chart.tooltip.setActiveElements([], {x: 0, y: 0})
  chart.update()
}

export function showFundBauTooltip(chart, activeElementDatasetIndex) {
  chart.options.plugins.tooltip = {...getDefaultTooltipConfig(), external: externalFundBauTooltipHandler, externalName: 'externalFundBauTooltipHandler'}
  chart.update()
  chart.tooltip?.setActiveElements([{datasetIndex: activeElementDatasetIndex, index: 0}], {x: chart.tooltip.x, y: chart.tooltip.y,})
  chart.update()
}

export function hideFundBauTooltip(chart) {
  chart.options.plugins.tooltip = getDefaultTooltipConfig()
  chart.update()
  removeFundBauTooltipNode()
  chart.tooltip.setActiveElements([], {x: 0, y: 0})
  chart.update()
}
export function removeAssetTooltipNode(chart) {
  const assetTooltipNode=chart.canvas?.parentNode.querySelector('.asset-detail-panel')
  if(assetTooltipNode){
    assetTooltipNode.remove()
  }
}

export const FUND_BAU_TOOLTIP_ROOT='fund-bau-tooltip-root'

export function removeFundBauTooltipNode() {
  const tooltipNode=document.getElementById(FUND_BAU_TOOLTIP_ROOT)
  if(tooltipNode){
    tooltipNode.remove()
  }
}

export const externalFundBauTooltipHandler = (context) => {
  const {chart, tooltip} = context
  const firstActiveElement = tooltip.getActiveElements()[0]
  let datasets = chart.data.datasets
  const fundBauChartData = datasets && firstActiveElement ? datasets[firstActiveElement.datasetIndex]?.fundBAUData : null
  if (fundBauChartData) {
    let fundBauPopupRoot = chart.canvas.parentNode.querySelector('div')
    if (!fundBauPopupRoot) {
      fundBauPopupRoot = document.createElement('div')
      fundBauPopupRoot.style.opacity = 1
      fundBauPopupRoot.id = FUND_BAU_TOOLTIP_ROOT
      fundBauPopupRoot.datasetIndex=firstActiveElement.datasetIndex
      fundBauPopupRoot.setAttribute('class', FUND_BAU_TOOLTIP_ROOT)
      fundBauPopupRoot.innerHTML = getFundBauTooltipElements(context, fundBauChartData)
      chart.canvas.parentNode.appendChild(fundBauPopupRoot)
    }
    if (tooltip.opacity === 0) {
      fundBauPopupRoot.style.opacity = 0
      return
    }
    fundBauPopupRoot.style.opacity = 1
    fundBauPopupRoot.style.position = 'absolute'
    fundBauPopupRoot.style.top = '23%'
    fundBauPopupRoot.style.left = '18%'
  } else {
    removeFundBauTooltipNode()
  }
}