import React, {memo, useEffect, useRef} from 'react'
import {Paginator} from 'primereact/paginator'
import './NzcActionsOverlay.scss'
import i18n from '../../../../utils/i18n/I18n'
import {Skeleton} from 'primereact/skeleton'
import {useAssetNzcActionData} from '../../../../utils/custom-hooks/api-queries/useAssetNzcActionData'
import {costMask, regexActionStatus} from '../../../../utils/helpers/Constants'
import {Text, Tooltip} from '../../../atomic'
import {handleLengthyName} from '../../../../utils/helpers/Helper'

const NzcActionsOverlay = ({overlayProps}) => {

  const {nzcActionOverlayState, setNzcActionOverlayState, nzcGraphRef, onHide, selectedLanguage, assetIntensity}=overlayProps
  let {showNzcActionOverlay, actionYear, assetId, defaultScopeId, actionOverlayPageNumber}=nzcActionOverlayState
  const nzcOverlayRootRef=useRef()
  const {
    data,
    isLoading,
    isError,
    isFetching
  } = useAssetNzcActionData(assetId, defaultScopeId, actionYear, assetIntensity)

  useEffect(()=>{
    if(nzcGraphRef.current){
      const nzcOverlayRoot = nzcOverlayRootRef.current
      const parentPchart = nzcGraphRef.current.getElement()
      if(nzcOverlayRoot && parentPchart){
        parentPchart.append(nzcOverlayRoot)
      }
    }
  }, [showNzcActionOverlay])

  function getMessageKey(key) {
    let msgKey
    if(!key){
      return null
    }
    if('dueDate'===key){
      msgKey='t_due_date'
    } else if('estimatedSavings'===key){
      msgKey='t_estimated_savings'
    }
    else {
      msgKey=`t_${key}`
    }
    return msgKey
  }

  const onPageChange = (event) => {
    setNzcActionOverlayState({
      ...nzcActionOverlayState,
      actionOverlayPageNumber: event.first
    })
  }

  const formatDate=(dateString)=> {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = date.toLocaleDateString('en', { month: 'short' })
    const day = date.getDate()   
    return `${year}-${month}-${day}`
  }


  const getValue=(key,actionDetails)=>{
    if(key==='cost'){
      return `${costMask.GBP}${Number(actionDetails[key]).toLocaleString(selectedLanguage)}`
    }else if(key==='dueDate'){
      return formatDate(actionDetails[key])
    }else if(key==='status'){
      const str=actionDetails[key]
      const match = str.match(regexActionStatus)
      return match ? match[1] : ''
    }
    return actionDetails[key]
  }

  function getContentTable(actionDetails) {
    return(
      <div className='content-table'>
        {actionDetails && Object.keys(actionDetails).filter(key=>key!=='title').map((key)=>{

          return(
            <div className='t-row' key={`${key}-${actionDetails[key]}`}>
              <span className='col-header' key={key}>{i18n(getMessageKey(key))} {key==='estimatedSavings'? '(kWh/yr)':''}</span>
              <span className='col-value' key={`${actionDetails[key]}`}>{getValue(key,actionDetails)}</span>
            </div>
          )
        })}
      </div>
    )
  }

  function getSkeletonRow() {
    const skeletonRow=[]
    for(let i=1; i<=5;i++){
      skeletonRow.push(
        <div className="row-sk">
          <div className="col-sk col-sk-odd"> <Skeleton width="4rem" /></div>
          <div className="col-sk col-sk-even"> <Skeleton width="5rem" /></div>
        </div>
      )
    }
    return skeletonRow
  }

  function getSkeleton() {
    return (
      <div className="action-overlay-content-sk">
        <div className='nzc-overlay-header-sk'>
          <span className='header-text'><Skeleton width="14.3125rem" /></span>
        </div>
        <div className='nzc-overlay-body-sk'>
          <div className='body-title-sk'><Skeleton width="4rem" /></div>
          {getSkeletonRow()}
        </div>
        <div className='nzc-overlay-footer'>
          <Skeleton width="3.4375rem" />
        </div>
      </div>
    )
  }

  function getContent() {
    let actionContent
    if(isError || !data){
      actionContent=<div>{i18n('t_msg_no_data')}</div>
    } else {
      actionContent=<>
        {data[actionOverlayPageNumber]?.title?.length > 50 &&
        <Tooltip target='.body-title' padding="small" width={'350'}>
          <Text content={data[actionOverlayPageNumber]?.title} />
        </Tooltip>
        }
        <div className='body-title' data-testid="overlay-body-title">{handleLengthyName(data[actionOverlayPageNumber]?.title, 50)}</div>
        {getContentTable(data[actionOverlayPageNumber])}
      </>
    }
    return actionContent
  }

  return (
    <div className='nzc-overlay-root' style={{display: showNzcActionOverlay, top: '31%', left: '31%'}} id="nzc-overlay-root" ref={nzcOverlayRootRef}>
      {isLoading || isFetching ?
        getSkeleton()
        :
        <>
          <div className='nzc-overlay-header'>
            <span
              className='header-text' data-testid="overlay-header-text">{`${actionYear}: `}{i18n('t_agreed_and_applicable_actions')}</span>
            <i className="pi pi-times close-icon cursor" onClick={onHide}/>
          </div>
          <div className='nzc-overlay-body'>{getContent()}</div>
          <div className='nzc-overlay-footer'>
            {(!!data && !isError) &&
                            <Paginator
                              first={actionOverlayPageNumber}
                              rows={1}
                              totalRecords={data ? data.length : 0}
                              onPageChange={onPageChange}
                              template={{layout: 'PrevPageLink CurrentPageReport NextPageLink'}}
                              currentPageReportTemplate={`${actionOverlayPageNumber + 1}/${data ? data.length : 0}`}
                            />}
          </div>
        </>
      }
    </div>
  )
}

export default memo(NzcActionsOverlay)