import React, {useRef, useState} from 'react'
import I18n from '../../../utils/i18n/I18n'
import {FileUpload} from 'primereact/fileupload'
import {ProgressSpinner} from 'primereact/progressspinner'
import {OverlayPanel} from 'primereact/overlaypanel'
import CloseIcon from '../../../resources/images/icon/close-gray.svg'
import {getLocalizedValue} from '../../../utils/helpers/Helper'
import plusIcon from '../../../resources/images/icon/plus.svg'
import './ImageUploader.scss'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const ImageUploader = (props) => {
  const addUserPhotoRef = useRef(null)
  const [selectedUserImage, setSelectedUserImage] = useState(props.userImage)
  const [showPSpinner, setShowPSpinner] = useState(false)
  const { loginState: { userInfo } } = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference

  function getAddUserPhotoOverlayPanel() {
    const chooseOptions = {label:  <u>{I18n('t_upload_select_or_drop_file')}<br/>{I18n('t_upload_here_jpg')}</u>, icon: ' '}
    const uploadOptions = {label: '', icon: ' ', className: ' '}
    let userSelectedImgFile

    function getEmptyTemplate() {
      return <div className="upload-img-txt">{I18n('t_upload_image')}</div>
    }

    function getHeaderTemplate(options){
      const {chooseButton } = options
      return (
        <div className={'user-img-file-chooser'}>
          <span className="img-choose-button">{chooseButton}</span>
        </div>
      )
    }

    const customItemTemplate = (file, props) => {
      console.log(file,props)
      return('')
    }

    function setUserImageSelected() {
      if(userSelectedImgFile){
        const urlObjOfImg = URL.createObjectURL(userSelectedImgFile)
        setSelectedUserImage(urlObjOfImg)
        props.getSelectedImage(selectedUserImage)
        setShowPSpinner(false)
        addUserPhotoRef.current.hide()
      }
    }

    const onSelect = (event) => {
      userSelectedImgFile=event.files[0]
      setShowPSpinner(true)
      setTimeout(setUserImageSelected, 2000)
    }

    function getUserImgUploadElement() {
      return (
        <div className="upload-img-drop-section">
          <FileUpload
            className={'user-img-fileupload'}
            name="asetImgFile"
            url=""
            multiple={false}
            accept="image/*"
            maxFileSize={1000000}
            emptyTemplate={getEmptyTemplate}
            chooseOptions={chooseOptions}
            uploadOptions={uploadOptions}
            headerTemplate={getHeaderTemplate}
            // onRemove={onRemoveHandler}
            itemTemplate={customItemTemplate}
            onSelect={onSelect}
          />
        </div>
      )
    }

    function getUserImgSelectPSpinnerElement() {
      return(
        <div className="user-img-p-spinner-container">
          <ProgressSpinner className="user-img-select-p-spinner" />
        </div>
      )
    }

    function closeIconHandler() {
      if(addUserPhotoRef){
        addUserPhotoRef.current.hide()
      }
    }

    return(
      <OverlayPanel className={'upload-user-photo-overlay-panel'} ref={addUserPhotoRef} dismissable={false}>
        <div className="o-panel-header">
          <div className="close-icon-container">
            <img className="close-icon" src={CloseIcon} onClick={closeIconHandler} alt="close icon" />
          </div>
          <div className="upload-title">{I18n('t_upload')}</div>
        </div>
        <div className="o-panel-content">
          {showPSpinner?getUserImgSelectPSpinnerElement():getUserImgUploadElement()}
        </div>
        <div className="o-panel-footer">{I18n('t_upload_footer_info')}</div>
      </OverlayPanel>
    )
  }

  function addUserImageHandler(evt) {
    addUserPhotoRef.current.toggle(evt)
  }

  return (
    <div className="image-container">
      {selectedUserImage ?
        <img className="user-profile-img" src={selectedUserImage} alt={getLocalizedValue(selectedLanguage, 't_add_photo')} /> :
        <span className="addPhoto-title">{I18n('t_add_photo')}</span>
      }
      <div className={'add-photo move-left'} onClick={(e) => addUserImageHandler(e)}>
        <img className="add-photo-icon" src={plusIcon} alt={getLocalizedValue(selectedLanguage, 't_add_photo')} />
      </div>
      <div className={'upload-user-photo-overlay-panel-container'}>{getAddUserPhotoOverlayPanel()}</div>
    </div>
  )
}

export default ImageUploader