
// This file holds all common utils and helper functions.

export function setTooltipForAddressFundName(addressFundNameWrapperEle, textWrapperCssClass){
  let addressWrapperEle = addressFundNameWrapperEle.firstChild
  let fundNameWrapperEle = addressFundNameWrapperEle.lastChild
  let tooltipAddressEle=addressWrapperEle.firstChild
  let tooltipFundNameEle=fundNameWrapperEle.firstChild

  if (addressWrapperEle.clientHeight < tooltipAddressEle.clientHeight &&
    !(tooltipAddressEle.classList.contains(textWrapperCssClass))) {
    tooltipAddressEle.classList.add(textWrapperCssClass)
    tooltipAddressEle.dataset.prTooltip=tooltipAddressEle.dataset.ptfTooltip
  }
  if (fundNameWrapperEle.clientHeight < tooltipFundNameEle.clientHeight &&
    !(tooltipFundNameEle.classList.contains(textWrapperCssClass))) {
    tooltipFundNameEle.classList.add(textWrapperCssClass)
    tooltipFundNameEle.dataset.prTooltip=tooltipFundNameEle.dataset.ptfTooltip
  }
}

export function setTooltipForFirstChild(parentEle, textWrapperCssClass){
  let firstChild = parentEle.firstChild
  if (firstChild.clientHeight > parentEle.clientHeight && !(firstChild.classList.contains(textWrapperCssClass))) {
    firstChild.classList.add(textWrapperCssClass)
    firstChild.dataset.prTooltip=firstChild.dataset.ptfTooltip
  }
}

export function isTextOverflown(e){
  if(e.target.clientWidth < e.target.scrollWidth){
    e.target.dataset.prTooltip=e.target.dataset.ptfTooltip
  }else{
    e.target.dataset.prTooltip=''
  }
}

export function isDropDownTextOverflown(e){  
  e.target.parentElement.parentElement.dataset.prTooltip = e.target.textContent  
  if(e.target.clientWidth <  e.target.scrollWidth){    
    e.target.parentElement.parentElement.dataset.prTooltip = e.target.parentElement.parentElement.dataset.ptfTooltip
  }else{
    e.target.parentElement.parentElement.dataset.prTooltip=''
  }
}