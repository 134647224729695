
import PropTypes from 'prop-types'
import './DashboardSummaryCard.scss'
import I18n from '../../../../../../utils/i18n/I18n'
import {memo} from 'react'

const DashboardSummaryCard = ({children, isDataLoading, skeleton, isDataEmpty}) => {

  function getCardContent() {
    let cardContent
    if(isDataLoading){
      cardContent = skeleton
    } else if(isDataEmpty){
      cardContent=<span>{I18n('t_msg_no_data')}</span>
    } else {
      cardContent=children
    }
    return cardContent
  }

  return (
    <div className="dashboard-summary-card">
      {getCardContent()}
    </div>
  )
}

DashboardSummaryCard.propTypes = {
  children: PropTypes.object,
  isDataLoading: PropTypes.bool,
  skeleton: PropTypes.object,
  isDataEmpty: PropTypes.bool,
}

DashboardSummaryCard.displayName = 'DashboardSummaryCard'

export default memo(DashboardSummaryCard)