import GroupDetailsContainer from '../group-details-container/GroupDetailsContainer'
import {UserActions} from '../../../../../utils/helpers/Constants'


const GroupDetailsAdd = ()=>{
  const addGroupConfigData = {
    groupAction : UserActions.ADD_GROUP
  }
  return (
    <div>
      <GroupDetailsContainer {...addGroupConfigData} />
    </div>
  )
}
export default GroupDetailsAdd
