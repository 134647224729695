import I18n from 'utils/i18n/I18n'
import {Column} from 'primereact/column'
import {DataTable} from 'primereact/datatable'
import { TabView, TabPanel } from 'primereact/tabview'
import { createNavURLForUserPages, getLoggedInUserRole, isLoggedInUserAdmin } from 'utils/helpers/Helper'
import { ADMIN_PAGES } from 'utils/helpers/Constants'
import { getSortIcons, utilDateTemplate, utilTextLinkTemplate, utilTextTemplate } from 'utils/helpers/DataTableUtils'
import './CertificateHome.scss'
import { CertificatesHeader } from '../certificates-header/CertificatesHeader'
import { DocumentsFooter } from 'components/pages/documents/documents-footer/DocumentsFooter'
import { currentPageReport } from 'components/common/action-plan/actionPlanTableTemplate'
import certificateJson from '../../../../resources/test-data/certificates/certficate-test-data.json'
import { Text } from 'components/atomic/index'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const CertificateHome = () => {
  const { loginState: { userInfo } } = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const unitSystem = userInfo.unitSystem

  const getPaginatorTemplate = () => {
    return {
      layout: 'PrevPageLink CurrentPageReport NextPageLink',
      'CurrentPageReport': currentPageReport
    }
  }

  const uploadedByTemplate = (rowData, field) => {
    const navigationLink = isLoggedInUserAdmin(getLoggedInUserRole()) && rowData.userId
      ? createNavURLForUserPages({pathPrefix: ADMIN_PAGES.users, userId: rowData.userId})
      : null

    return navigationLink
      ? utilTextLinkTemplate(rowData, field, navigationLink)
      : utilTextTemplate(rowData, field)
  }

  const getHeaderName = (key, className, size, weight) => {
    return <Text content={I18n(key)} size={size} weight={weight} className={className} />
  }
  return (<div data-page_identifier="documents_home" className="min-width-1280">
    <div className="display gutter">
      <div className="certificates-wrapper grid">
        <div className="col-12 justify-content-between">
          {getHeaderName('t_certificates', 'certificates-header')}
        </div>

        <div className="col-12">
          <TabView
            activeIndex={0} 
          >
            <TabPanel header={<Text content={I18n('t_green_building_certificates')} />}>
              <>
                <CertificatesHeader
                  title={I18n('t_green_building_certificates')}
                />

                <div>
                  <DataTable
                    value={certificateJson.certificatesDataList}
                    className={'p-datatable-gridlines'}
                    rows={10}
                    totalRecords={certificateJson.certificatesDataList?.length}
                    paginatorClassName={'data-table-paginator'}
                    paginator
                    paginatorTemplate={getPaginatorTemplate()}
                    paginatorLeft={<DocumentsFooter showEdit showDelete />}
                    scrollable
                    sortIcon={getSortIcons()}
                  >
                    <Column selectionMode="multiple" className='certificate-checkbox' />
                    <Column className='certificate-assetref' sortable field="assetReference" header={getHeaderName('t_asset_reference', '', 'md', 'bold')}
                      body={(rowData) => utilTextTemplate(rowData, 'assetReference')}/>
                    <Column sortable field="assetName" header={getHeaderName('t_asset_name', '', 'md', 'bold')} className='certficate-asset-name'
                      body={(rowData) => utilTextTemplate(rowData, 'assetName')} />
                    <Column sortable field="fundName" header={getHeaderName('t_fund_name', '', 'md', 'bold')} className='certificate-fund-name'
                      body={(rowData) => utilDateTemplate(rowData, 'fundName', selectedLanguage, unitSystem)} />
                    <Column sortable field="sector" header={getHeaderName('t_sector', '', 'md', 'bold')} className='certificate-sector'
                      body={(rowData) => utilDateTemplate(rowData, 'sector', selectedLanguage, unitSystem)} />
                    <Column sortable field="investmentStage" header={getHeaderName('t_investment_stage', '', 'md', 'bold')} className='certificate-invest-stage'
                      body={(rowData) => uploadedByTemplate(rowData, 'investmentStage')} />
                    <Column sortable field="scheme" header={getHeaderName('t_scheme', '', 'md', 'bold')} className='certificate-scheme'
                      body={(rowData) => utilTextTemplate(rowData, 'scheme')} />
                    <Column sortable field="referenceNo" header={getHeaderName('t_reference_no', '', 'md', 'bold')} className='certificate-ref-no'
                      body={(rowData) => utilTextTemplate(rowData, 'referenceNo')} />
                    <Column sortable field="rating" header={getHeaderName('t_rating', '', 'md', 'bold')} className='certificate-rating'
                      body={(rowData) => utilTextTemplate(rowData, 'rating')} />
                    <Column sortable field="score" header={getHeaderName('t_score', '', 'md', 'bold')} className='certificate-score'
                      body={(rowData) => utilTextTemplate(rowData, 'score')} />
                    <Column sortable field="scope" header={getHeaderName('t_scope', '', 'md', 'bold')} className='certificate-scope'
                      body={(rowData) => utilTextTemplate(rowData, 'scope')} />
                    <Column sortable field="unit" header={getHeaderName('t_unit', '', 'md', 'bold')} className='certificate-unit'
                      body={(rowData) => utilTextTemplate(rowData, 'unit')} />
                    <Column sortable field="certifiedFloorArea" header={getHeaderName('t_certified_floor_area', '', 'md', 'bold')} className='certificate-floor-area'
                      body={(rowData) => utilTextTemplate(rowData, 'certifiedFloorArea')} style={{minWidth: '13rem'}}/>
                    <Column sortable field="validFromDate" header={getHeaderName('t_valid_from_date', '', 'md', 'bold')} className='certificate-valid-date'
                      body={(rowData) => utilTextTemplate(rowData, 'validFromDate')} />
                    <Column sortable field="expiryDate" header={getHeaderName('t_expiry_date', '', 'md', 'bold')} className='certificate-valid-date'
                      body={(rowData) => utilTextTemplate(rowData, 'expiryDate')} />
                    <Column sortable field="comment" header={getHeaderName('t_comment', '', 'md', 'bold')} className='certificate-comment'
                      body={(rowData) => utilTextTemplate(rowData, 'comment')} />
                    <Column sortable field="fileUpload" header={getHeaderName('t_file_upload', '', 'md', 'bold')} className='certificate-file-upload'
                      body={(rowData) => utilTextTemplate(rowData, 'fileUpload')} />
                    <Column sortable field="uploadedBy" header={getHeaderName('t_uploaded_by', '', 'md', 'bold')} className='certificate-uploaded-by'
                      body={(rowData) => utilTextTemplate(rowData, 'uploadedBy')} />
                    <Column sortable field="uploadedDate" header={getHeaderName('t_uploaded_date', '', 'md', 'bold')} className='certificate-uploaded-date'
                      body={(rowData) => utilTextTemplate(rowData, 'uploadedDate')} />
                  </DataTable>
                </div>
              </>

            </TabPanel>
          </TabView>
        </div>
      </div>
    </div>
  </div>
  )}

export default CertificateHome