import React, {useEffect, useRef, useState} from 'react'
import './AssetsContainer.scss'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import SearchField from '../../../../common/search/SearchField'
import {getFiller, getFillerImage, getLocalizedValue, getPaginatorTemplate} from '../../../../../utils/helpers/Helper'
import DropdownAsset from './DropdownAsset'
import I18n from '../../../../../utils/i18n/I18n'
import UserOverviewAssetsSkeleton from '../../../../common/skeletons/users-grid-skeleton/UserOverviewAssetsSkeleton'
import {
  getSelectAllIconNode,
  getSelectAllIconNodeContainer,
  setSelectAllIcon
} from '../../../../../utils/helpers/DataTableUtils'
import { Tooltip } from 'primereact/tooltip'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

function AssetsContainer(props) {

  //region Declarations/Initialisations
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const [isEdit] = useState(props.edit || false )
  const [assetDetails, setAssetDetails] = useState([...props.assetDetails])
  const [filteredAssetDetails, setFilteredAssetDetails] = useState([])
  const [selectedAssetList, setSelectedAssetList] = useState(props.selectedAssets)
  const allTranslated = 'All'
  const [fundLists, setFundLists] = useState([allTranslated])
  const [locationList, setLocationList] = useState([allTranslated])
  const [sectorList, setSectorList] = useState([allTranslated])
  const [fund, setFund] = useState(null)
  const [location, setLocation] = useState(null)
  const [sector, setSector] = useState(null)
  const [searchText, setSearchText] = useState('')
  const selectAllIconSelectors = getSelectIconSelectors()
  const assetTooltipRef = useRef(null)
  const assetsTableRef = useRef(null)
  const groupMemberAssetRowClass = 'group-member-asset-row'
  const [userSelectedAssets,setUserSelectedAssets] = useState([])
  const [filteredSelectedAssets,setFilteredSelectedAssets] = useState([])
  const [filteredHiddenSelectedAssets,setFilteredHiddenSelectedAssets] = useState([])
  const [filteredDefaultSelectedAsset,setFilteredDefaultSelectedAsset]= useState([])
  const [first, setFirst] = useState(0)
  const [rows, setRows] = useState(20)
  const assetsViaGroupsAndFunds = props?.assetsViaGroupsAndFunds
  const [filteredUserSelectableAsset,setFilteredUserSelectableAsset] = useState([])
  const [filteredUserSelectedAsset, setFilteredUserSelectedAsset] = useState([])
  const totalSelectableAssets = props?.assetDetails?.filter(item => !props?.assetsViaGroupsAndFunds?.find(asset => asset.assetId === item.assetId))
  //endregion

  //region Side effects.
  useEffect(() => {
    setSelectedAssetList(props.selectedAssets)
    setAssetDetails(props.assetDetails)
    setFilteredAssetDetails(props.filteredAssetDetails)
    let userSelectedAssets = props?.selectedAssets?.filter((asset) => !assetsViaGroupsAndFunds.find(defaultSelectedAsset => (asset.assetId === defaultSelectedAsset.assetId)))
    setUserSelectedAssets(userSelectedAssets)
  }, [props])

  useEffect(() => {
    if (assetTooltipRef && assetTooltipRef.current && assetsTableRef.current && assetsTableRef.current.container) {
      let tbody = assetsTableRef.current.container.children[0].querySelector('.p-datatable-tbody')
      if (tbody) {
        Array.from(tbody.children).forEach((el) => {
          if (el.classList.contains(groupMemberAssetRowClass)) {
            let assetMemberCheckbox = el.querySelector('.p-checkbox')
            if (assetMemberCheckbox.dataset.prDisabled) {
              assetMemberCheckbox.dataset.prDisabled = 'false'
            }
          }
        })
      }
    }
  }, [filteredAssetDetails])

  useEffect(() => {
    if (assetTooltipRef && assetTooltipRef.current) {
      assetTooltipRef.current.updateTargetEvents()
    }
    const groupMemberAssetRowClickHandler = (event) => {
      if (event.currentTarget.classList.contains(groupMemberAssetRowClass)) {
        event.stopPropagation()
      }
    }
    if (assetsTableRef.current && assetsTableRef.current.container) {
      const tbody = assetsTableRef.current.container.children[0].querySelector('.p-datatable-tbody')
      if (tbody) {
        Array.from(tbody.children).forEach((rowEl) => {
          if (rowEl.classList.contains(groupMemberAssetRowClass)) {
            rowEl.addEventListener('click', groupMemberAssetRowClickHandler)
          }
        })
      }
    }
  }, [filteredAssetDetails])

  useEffect(() => {
    setSearchText('')
    resetFilter()

  }, [props.isTabSwitched])

  useEffect(() => {
    if ((searchText !== '')) {
      filterDropdownList(searchText)
    } else {
      filterDropdownList('')
    }
  }, [fund, location, sector,searchText])

  useEffect(() => {
    // This side effect is used for setting/resetting of selection and partial selection icons in the DataTable.
    const selectAllIconNode = getSelectAllIconNode(selectAllIconSelectors)
    if ((!fund && !location && !sector) && (searchText === '')) {
      if (props.isGroup) {
        setSelectAllIcon(userSelectedAssets, filteredAssetDetails, selectAllIconNode)
      } else {
        if(props.currentTab===1){
          setSelectAllIcon(selectedAssetList, filteredAssetDetails, selectAllIconNode)
        }
        else{
          setSelectAllIcon(userSelectedAssets, totalSelectableAssets, selectAllIconNode)
        }
      }
    } else {
      if(props.isGroup){
        setSelectAllIcon(filteredSelectedAssets, filteredAssetDetails, selectAllIconNode)
      }
      else{
        if(props.currentTab === 1){
          setSelectAllIcon(filteredSelectedAssets, filteredAssetDetails, selectAllIconNode)
        }
        else {
          setSelectAllIcon(filteredUserSelectedAsset, filteredUserSelectableAsset, selectAllIconNode)
        }
      }
    }
  }, [selectedAssetList, userSelectedAssets, filteredAssetDetails, filteredSelectedAssets])

  useEffect(() => {
    let sortedFundList = fundListNew(assetDetails).sort()
    const sortedLocationList = locationListNew(assetDetails).sort()
    const sortedSectorList = sectorListNew(assetDetails).sort()

    sortedFundList = sortedFundList.filter(fund => (fund !== '-' && fund !== undefined))
    setFundLists([allTranslated, ...sortedFundList])
    setLocationList([allTranslated, ...sortedLocationList])
    setSectorList([allTranslated, ...sortedSectorList])

  }, [assetDetails,props])

  //endregion

  //region Helper methods

  function getSelectIconSelectors(){
    return  props.isGroup ? '.user-group-asset .p-datatable-thead div.p-checkbox-box span.p-checkbox-icon' :
      '.user-asset .asset-details .p-datatable-thead div.p-checkbox-box span.p-checkbox-icon'
  }

  function filterDropdownList(searchText) {
    let filteredResult = []
    let searchContainer
    let searchLC = searchText.toLowerCase()
    const search = (data) => {
      return data?.fundName?.toLowerCase().includes(searchLC) || data?.country?.toLowerCase().includes(searchLC) ||
                data?.sector?.toLowerCase().includes(searchLC) || data?.city?.toLowerCase().includes(searchLC) ||
                data?.assetName?.toLowerCase().includes(searchLC)
    }
    if (isEdit) {
      searchContainer = props.currentTab === 0 ? assetDetails : selectedAssetList
    } else {
      searchContainer = assetDetails
    }
    searchContainer.filter(data => {
      if ((sector !== null && sector !== allTranslated ? sector === data?.sector : true)
                && (fund !== null && fund !== allTranslated ? fund === data?.fundName : true)
                && (location !== null && location !== allTranslated ? location === data?.country : true)
                && (searchText !== '' ? search(data) : true)) {
        filteredResult.push(data)
      }

    })
    setFilteredAssetDetails(filteredResult)
    if (isEdit && ((searchText !== '') || (fund || location || sector))) {
      let filteredSelectedAssetsList = filteredResult.filter((assetFiltered) => selectedAssetList?.find(assetSelected => (assetSelected.assetId === assetFiltered.assetId)))
      let filteredHiddenSelectedAssetsList = selectedAssetList?.filter((assetSelected) => !filteredSelectedAssetsList.find((filteredSelectedAssets) => (assetSelected.assetId === filteredSelectedAssets.assetId)))
      let filteredDefaultSelectedAssetList = assetsViaGroupsAndFunds.filter((item) => filteredSelectedAssetsList?.find((filteredSelectedAssets) => (item.assetId === filteredSelectedAssets.assetId)))
      let filteredUserSelectableAsset = filteredResult.filter((item) => !assetsViaGroupsAndFunds?.find((filteredSelectedAssets) => (item.assetId === filteredSelectedAssets.assetId)))
      let filteredUserSelectedAsset = filteredResult.filter((asset) => userSelectedAssets.find(selectedAsset => (asset.assetId === selectedAsset.assetId )) )
      setFilteredUserSelectedAsset(filteredUserSelectedAsset)
      setFilteredUserSelectableAsset(filteredUserSelectableAsset)
      setFilteredDefaultSelectedAsset([...filteredDefaultSelectedAssetList])
      setFilteredSelectedAssets([...filteredSelectedAssetsList])
      setFilteredHiddenSelectedAssets([...filteredHiddenSelectedAssetsList])
    }

    return filteredResult
  }

  function locationListNew(assetDetails) {
    return assetDetails.map(record => {
      if (record.country !== '' && record.country !== null) {
        return record.country
      }
    }).reduce((unique, item) => {
      return unique.includes(item) ? unique : [...unique, item]
    }, [])
  }

  function fundListNew(assetDetails) {
    return assetDetails.map(record => {
      if (record.fundName !== '' && record.fundName !== null) {
        return record.fundName
      }
    }).reduce((unique, item) => {
      return unique.includes(item) ? unique : [...unique, item]
    }, [])
  }

  function sectorListNew(assetDetails) {
    return assetDetails.map(record => {
      if (record.sector !== '' && record.sector !== null) {
        return record.sector
      }
    }).reduce((unique, item) => {
      return unique.includes(item) ? unique : [...unique, item]
    }, [])
  }

  function onFundFilterChange(selection, type) {
    if (type === 'Fund') {
      setFund(selection)
    } else if (type === 'Location') {
      setLocation(selection)
    } else if (type === 'Sector') {
      setSector(selection)
    } else {
      resetFilter()
    }
  }

  function selection(e) {
    if (isEdit) {
      let newSelectedAssets = e.value
      const thNode = getSelectAllIconNodeContainer(e)
      const checkboxIconPiMinus = thNode?.querySelector('.p-checkbox-icon.pi.pi-minus')
      const checkboxIconPiCheck = thNode?.querySelector('.p-checkbox-icon.pi.pi-check')
      if (!fund && !location && !sector && searchText === '') {
        if (thNode) {
          if (checkboxIconPiMinus) {
            setSelectedAssetList(assetsViaGroupsAndFunds)
            setUserSelectedAssets([])
            props.selection(assetsViaGroupsAndFunds,assetsViaGroupsAndFunds)
          } else if (checkboxIconPiCheck) {
            setUserSelectedAssets([])
            setSelectedAssetList(assetsViaGroupsAndFunds)
            props.selection(assetsViaGroupsAndFunds,assetsViaGroupsAndFunds)
          } else {
            let userSelectedAsset = newSelectedAssets.filter((newAsset) => !assetsViaGroupsAndFunds.find(defaultSelectedAsset => (newAsset.assetId === defaultSelectedAsset.assetId)))
            setUserSelectedAssets([...userSelectedAsset, ...assetsViaGroupsAndFunds])
            setSelectedAssetList([...userSelectedAsset, ...assetsViaGroupsAndFunds])
            props.selection([...userSelectedAsset,...assetsViaGroupsAndFunds], assetsViaGroupsAndFunds)
          }
        } else {
          let userSelectedAsset = newSelectedAssets.filter((newAsset) => !assetsViaGroupsAndFunds.find(defaultSelectedAsset => (newAsset.assetId === defaultSelectedAsset.assetId)))
          setUserSelectedAssets([...userSelectedAsset, ...assetsViaGroupsAndFunds])
          props.selection(props.isGroup ? newSelectedAssets : [...userSelectedAsset,...assetsViaGroupsAndFunds],props.isGroup ? [...newSelectedAssets]:[...userSelectedAsset,...assetsViaGroupsAndFunds])
          setSelectedAssetList(newSelectedAssets)
        }
      } else {
        if (thNode) {
          if (checkboxIconPiMinus) {
            setFilteredSelectedAssets([ ...filteredDefaultSelectedAsset])
            setFilteredUserSelectedAsset([])
            setSelectedAssetList([...filteredHiddenSelectedAssets, ...filteredDefaultSelectedAsset])
            props.selection([...filteredHiddenSelectedAssets, ...filteredDefaultSelectedAsset],[...filteredDefaultSelectedAsset])
          } else if (checkboxIconPiCheck) {
            setFilteredUserSelectedAsset([])
            setFilteredSelectedAssets([ ...filteredDefaultSelectedAsset])
            setSelectedAssetList([...filteredHiddenSelectedAssets, ...filteredDefaultSelectedAsset])
            props.selection([...filteredHiddenSelectedAssets, ...filteredDefaultSelectedAsset],[...filteredDefaultSelectedAsset])
          } else {
            setFilteredUserSelectedAsset([...filteredUserSelectableAsset])
            setFilteredSelectedAssets([...newSelectedAssets])
            setSelectedAssetList([...filteredHiddenSelectedAssets, ...newSelectedAssets])
            props.selection([...filteredHiddenSelectedAssets, ...newSelectedAssets],[...newSelectedAssets])
          }
        } else {

          let newlySelectedIndividualAsset = newSelectedAssets.filter((newAsset) => !filteredHiddenSelectedAssets.find(hiddenAsset => (newAsset.assetId === hiddenAsset.assetId)))
          setFilteredUserSelectedAsset(newSelectedAssets.filter((assetSelected)=> !assetsViaGroupsAndFunds.find(defaultSelected => (assetSelected.assetId === defaultSelected.assetId))))
          setSelectedAssetList([...filteredHiddenSelectedAssets, ...newlySelectedIndividualAsset])
          //SPR-4483 -- Global check box fix on filter
          const addAssetsAddedViaGroupsAndFundsIfMissed = filteredAssetDetails.filter((asset)=> assetsViaGroupsAndFunds.find(defaultSelectedAsset => asset.assetId === defaultSelectedAsset.assetId && !newSelectedAssets.includes(defaultSelectedAsset)))
          setFilteredSelectedAssets([...newlySelectedIndividualAsset,...addAssetsAddedViaGroupsAndFundsIfMissed])
          props.selection([...filteredHiddenSelectedAssets, ...newlySelectedIndividualAsset],[...newlySelectedIndividualAsset])
        }
      }
      props.currentTab !==1 && props.updatedFilteredList([...filteredAssetDetails])
    }
  }

  function NoDataFound({message}) {
    return <div className="no-result-msg">
      <span className="no-result-text">{message}</span>
    </div>
  }

  function resetFilter() {
    setFund(null)
    setSector(null)
    setLocation(null)
  }

  function assetNameTemplate(rowData) {
    return <div className="flex">
      <div className="pr-3">
        <img
          src={getFillerImage(rowData)}
          alt="Asset-Image"
          className={rowData.assetImageThumbnailUri ? 'image-actual' : 'image-filler'}
          onError={({currentTarget}) => {
            currentTarget.onerror = null
            currentTarget.src = getFiller(rowData.sector)
          }}
        />
      </div>
      <div className="align-self-center">
        <div className="asset-name">{rowData.assetName}</div>
        <div className="asset-location">{`${rowData.city}, ${rowData.country}`}</div>
      </div>
    </div>
  }

  function searchAssets(e) {
    let searchText = e.target.value
    setSearchText(searchText)
    let filteredList = filterDropdownList(e.target.value)
    setFilteredAssetDetails(filteredList)
  }

  function clearSearch() {

    setSearchText('')
    let filteredList = filterDropdownList('')
    if(isEdit){
      props.currentTab === 0 ? setFilteredAssetDetails(filteredList) : setFilteredAssetDetails(selectedAssetList)
    }else{
      setFilteredAssetDetails(filteredList)
    }
  }

  function getRowClassName(rowData) {
    let groupMemberRowClassCondition = false
    if (props.isAssetBelongToUserGroup) {
      groupMemberRowClassCondition = props.isAssetBelongToUserGroup(rowData.assetId)
    }
    return {'group-member-asset-row': groupMemberRowClassCondition}
  }

  function onBeforeShowTooltip(evt) {
    const groupMemberAssetRow = evt.target.parentElement.parentElement
    const isGroupMemberAssetRow = groupMemberAssetRow.classList.contains(groupMemberAssetRowClass)
    if (isGroupMemberAssetRow) {
      evt.target.dataset.prDisabled = 'false'
    } else {
      evt.target.dataset.prDisabled = 'true'
    }
  }

  function onShowTooltipHandler() {
    let viewport = document.getElementsByClassName('asset-details')[0]
    let viewportPosition = viewport.getBoundingClientRect()

    let tooltip = document.getElementsByClassName('group-member-asset-tooltip')[0]
    if (tooltip) {
      let tooltipPosition = tooltip.getBoundingClientRect()
      tooltip.style.left = tooltipPosition.left - (tooltipPosition.right - viewportPosition.right) + 'px'

      let tooltipArrow = tooltip.firstChild
      let tooltipArrowPosition = tooltipArrow.getBoundingClientRect()
      tooltipArrow.style.left = (tooltipPosition.right - tooltipArrowPosition.right + 5) + 'px'
    }
  }

  function rowClickHandler(evt) {
    if (props.groupRowClickHandler) {
      props.groupRowClickHandler(evt)
    }
  }

  const isRowSelectable = (event) => {
    if (event.data && props.assetsViaGroupsAndFunds?.length) {
      return !props.assetsViaGroupsAndFunds.some((asset) => asset.assetId === event.data.assetId)
    }
    return true
  }

  const onPageChange = (event) => {
    setFirst(event.first)
    setRows(event.rows)
  }

  //endregion

  function getFundName(rowData) {
    //Change from back end
    return rowData.fundName ? rowData.fundName : rowData.fund
  }

  function getHeader(){
    return <div data-testid={'assets-header'} className="flex justify-content-between pb-2">
      <span className="title">{props.header}</span>
    </div>
  }

  function getDropdownClass(){
    return isEdit ? 'user-asset-header justify-content-between flex' : 'user-asset-header-w-p justify-content-between flex'
  }

  function getHomeClass(){
    return !isEdit ? 'pt-6' : 'pt-4'
  }

  function getSearchBarClass() {
    return isEdit ? 'search-field-w-top' : 'user-Search p-2'
  }

  function setClassNameForTheCheckbox(rowData) {
    const ADD_TOOL_TIP = 'add-tooltip'
    if (props.assetsViaGroupsAndFunds?.length && props.assetsViaGroupsAndFunds.some((asset) => asset.assetId === rowData.assetId)) {
      return ADD_TOOL_TIP
    }
  }

  function getAssetTable() {

    function getTableClass() {
      return props.hasPermissionForEditUser || !isEdit || props.isGroup ? null : 'disable-section'
    }

    return filteredAssetDetails?.length > 0 ?
      <>
        <Tooltip target=".add-tooltip .p-checkbox" position={'top'} onBeforeShow={onBeforeShowTooltip}
          ref={assetTooltipRef} className="group-member-asset-tooltip" onShow={onShowTooltipHandler} autoHide={true}>
          {I18n('t_asset_existing_group')}
        </Tooltip>
        <DataTable role={'user-asset-grid'} onRowClick={(e) => rowClickHandler(e)}
          tableClassName={getTableClass()}
          value={filteredAssetDetails} selection={!fund && !location && !sector && searchText === '' ? selectedAssetList:filteredSelectedAssets} ref={assetsTableRef}
          className={props.hasPermissionForEditUser ? 'user-asset-grid' : 'user-asset-grid no-blue'}
          stripedRows scrollable
          isDataSelectable={isRowSelectable}
          onSelectionChange={selection}
          rowClassName={getRowClassName}
          paginator={filteredAssetDetails.length > 20}
          first={first}
          rows={rows}
          paginatorTemplate={getPaginatorTemplate('items-per-page-options-white')}
          paginatorClassName={'data-table-paginator group-assets-paginator'}
          onPage={e => onPageChange(e)}
          totalRecords={filteredAssetDetails.length}>
          <Column field="assetAddress" header={I18n('t_name')}
            headerClassName="user-grid-header"
            body={assetNameTemplate}/>
          <Column field="fundName" header={I18n('t_fund')} headerClassName="user-grid-header"
            body={getFundName} bodyClassName="user-grid-body"/>
          <Column field="sector" header={I18n('t_type')} headerClassName="user-grid-header"
            bodyClassName="user-grid-body"/>
          {isEdit ?
            <Column selectionMode="multiple" headerClassName="selection-asset" bodyClassName={setClassNameForTheCheckbox}
              style={{width: '4.5%'}}/> : null
          }
        </DataTable>
      </>
      :
      <NoDataFound message={I18n('t_assets_none')}/>
  }

  return (
    <div data-testid={'assets-container'} className={getHomeClass()}>
      {!isEdit ? getHeader() : null}
      <div className={getDropdownClass()}>
        <DropdownAsset fundList={fundLists} sectorList={sectorList} locationList={locationList} fund={fund}
          sector={sector} location={location} onFundFilterChange={onFundFilterChange}/>
        <div className={getSearchBarClass()}>
          <SearchField placeholder={getLocalizedValue(selectedLanguage, 't_find_asset')} addRef={true}
            searchValue={searchText} onChange={searchAssets} clearField={clearSearch} isAsset={true}/>
        </div>
      </div>
      <div className={`asset-details ${filteredAssetDetails?.length > 20 && 'more-assets'}`}>
        {!props.isLoading ?
          <div>
            {getAssetTable()}
          </div> :
          <div className="users-grid-before-load">
            <UserOverviewAssetsSkeleton/>
          </div>
        }
      </div>
    </div>
  )
}

export default AssetsContainer
