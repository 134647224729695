import {Font, StyleSheet} from '@react-pdf/renderer'
import atkinsonHyperlegibleRegular from '../../../resources/fonts/font-atkinson-hyperlegible/AtkinsonHyperlegible-Regular.ttf'
import atkinsonHyperlegibleBold from '../../../resources/fonts/font-atkinson-hyperlegible/AtkinsonHyperlegible-Bold.ttf'
import CSS_VARIABLES from '../../../resources/css/_variables.scss'
import {ATKINSON_HYPERLEGIBLE_BOLD, ATKINSON_HYPERLEGIBLE_REGULAR} from '../../helpers/Constants'

Font.register({family: ATKINSON_HYPERLEGIBLE_REGULAR, src: atkinsonHyperlegibleRegular, fontStyle: 'normal'})
Font.register({family: ATKINSON_HYPERLEGIBLE_BOLD, src: atkinsonHyperlegibleBold, fontStyle: 'normal'})

const styles = StyleSheet.create({
  page: {
    margin: 2,
    paddingTop: 30,
    paddingBottom: 64,
    paddingHorizontal: 28
  },
  header: {
    fontSize: 9,
    color: CSS_VARIABLES.forest,
    left: 35,
    top:-5
  },
  text: {
    marginLeft: 6,
    marginTop: 13,
    paddingBottom:2,
    paddingRight:4,
    fontSize: 7,
    fontFamily: ATKINSON_HYPERLEGIBLE_REGULAR
  },
  performanceText: {
    marginTop: 10,
    fontSize: 7,
    fontFamily: ATKINSON_HYPERLEGIBLE_REGULAR,
    marginLeft: 10,
    marginRight:4,
    marginBottom:-11
  },
  assetText: {
    marginTop: 14,
    marginLeft: 5,
    fontSize: 9,
    fontFamily: ATKINSON_HYPERLEGIBLE_REGULAR
  },
  assetTextNine: {
    fontSize: 9,
    fontFamily: ATKINSON_HYPERLEGIBLE_REGULAR
  },
  assetTextEightRegular: {
    fontSize: 8,
    fontFamily: ATKINSON_HYPERLEGIBLE_REGULAR
  },
  performanceLegend:{
    borderRight: `1 solid ${CSS_VARIABLES.forest}`,
    borderWidth: '0.5',
    borderBottom:'none',
    borderTop: 'none',
    borderLeft:'none',
    width:107,
    padding:2
  },
  assetTextEightBold: {
    fontSize: 8,
    fontFamily: ATKINSON_HYPERLEGIBLE_BOLD
  },
  textSevenHalfBold: {
    fontSize: 7.5,
    fontFamily: ATKINSON_HYPERLEGIBLE_BOLD
  },
  textSevenHalfRegular: {
    fontSize: 7.5,
    fontFamily: ATKINSON_HYPERLEGIBLE_REGULAR
  },
  assetTextSeven: {
    fontSize: 7,
    fontFamily: ATKINSON_HYPERLEGIBLE_REGULAR
  },
  assetTextSevenBold: {
    fontSize: 7,
    fontFamily: ATKINSON_HYPERLEGIBLE_BOLD
  },
  assetTextSix: {
    fontSize: 6,
    fontFamily: ATKINSON_HYPERLEGIBLE_REGULAR
  },
  width50:{
    width:50,
    padding:2
  },
  width68NoPadding:{
    width:68,
    top:-1,
  },
  width60NoPadding:{
    width:60
  },
  width50NoPadding:{
    width:55,
  },
  width68:{
    width:68,
    top:-1,
    padding:2
  },
  width52NoPadding:{
    width:52,
  },
  greyColor: {
    color: CSS_VARIABLES.forest,
    opacity: 0.8
  },
  top390:{
    top: 390
  },
  top310:{
    top: 310
  },
  greyDarkColor: {
    color: CSS_VARIABLES.forest,
    opacity: 0.6
  },
  greyLightColor: {
    color: CSS_VARIABLES.forest,
    opacity: 0.4
  },
  title: {
    fontSize: 8,
    textAlign: 'justify',
    fontFamily: ATKINSON_HYPERLEGIBLE_BOLD
  },
  titleReg: {
    fontSize: 8,
    textAlign: 'justify',
    fontFamily: ATKINSON_HYPERLEGIBLE_REGULAR
  },
  dqTitle: {
    fontSize: 9,
    textAlign: 'justify',
    fontFamily: ATKINSON_HYPERLEGIBLE_REGULAR
  },
  dqSubTitle: {
    fontSize: 7.5,
    textAlign: 'justify',
    fontFamily: ATKINSON_HYPERLEGIBLE_REGULAR
  },
  tableTitle: {
    fontSize: 7,
    fontFamily: ATKINSON_HYPERLEGIBLE_REGULAR
  },
  image: {
    height: 181,
    width: 211,
    left: 13
  },
  tableRow: {
    flexDirection: 'row',
    borderStyle: 'solid',
    borderColor: CSS_VARIABLES.forest,
  },
  dqHeader:{
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginTop: 30,
    marginBottom: 10
  },
  tableRowFlex: {
    flexDirection: 'row',
    display: 'table',
    flexWrap: 'nowrap',
  },
  mL40:{
    marginLeft:35,
    padding:2
  },
  mT2:{
    marginTop:2
  },
  tableBorder:{
    minHeight:31,
    borderStyle: 'solid',
    borderColor: CSS_VARIABLES.forest,
    borderWidth: 0.5,
    margin:-0.3,
    justifyContent: 'center',
    alignContent: 'left',
  },
  tableBorderPerfLeftAlign:{
    minHeight:33,
    borderStyle: 'solid',
    borderColor: CSS_VARIABLES.forest,
    borderWidth: 0.5,
    margin:-0.3
  },
  tableBorderPerfNote:{
    minHeight:21,
    borderStyle: 'solid',
    borderColor: CSS_VARIABLES.forest,
    borderWidth: 0.5,
    margin:-0.3
  },
  tablePadding:{
    paddingTop:7,
    paddingBottom:7,
    paddingLeft:5,
    paddingRight:5
  },
  tableNotePadding:{
    padding:5
  },
  portfolioTablePadding:{
    padding:7,
    paddingTop:4,
    paddingBottom:0,
  },
  box: {
    maxWidth: 77,
    width: 76
  },
  semiBox: {
    width: 108
  },
  blueColor: {
    backgroundColor: CSS_VARIABLES.forest,
    height: '4',
    width: 120,
    left: '-1'
  },
  smallBox: {
    width: 75
  },
  assets: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column'
  },
  charts: {
    border: `1 solid ${CSS_VARIABLES.forest}`,
    borderWidth: '0.7',
    display: 'flex',
    flexDirection: 'column'
  },
  bgColorGrey: {
    backgroundColor: CSS_VARIABLES.grey
  },
  bgColorWhite: {
    backgroundColor: CSS_VARIABLES.white
  },
  moveDown: {
    marginTop: 13,
    fontFamily: ATKINSON_HYPERLEGIBLE_REGULAR
  },
  moveUp: {
    marginTop: 10,
    fontFamily: ATKINSON_HYPERLEGIBLE_REGULAR
  },
  borderExceptBottom: {
    border: `1 solid ${CSS_VARIABLES.forest}`,
    borderWidth: '0.6',
    borderBottomColor: CSS_VARIABLES.forest,
    borderBottomWidth: '3',
    borderBRadius: '0px',
    boxSizing: 'border-box'
  },
  planBorder: {
    border: `1 solid ${CSS_VARIABLES.forest}`,
    borderWidth: '0.5',
    boxSizing: 'border-box'
  },
  borderExceptBottomRight: {
    border: `1 solid ${CSS_VARIABLES.forest}`,
    borderWidth: '0.6',
    borderBottomColor: `1 solid ${CSS_VARIABLES.forest}`,
    borderBottomWidth: '3',
    borderBRadius: '0px',
    borderRight:'none',
    boxSizing: 'border-box'
  },
  borderRegular: {
    border: `1 solid ${CSS_VARIABLES.forest}`,
    borderWidth: '0.6',
    borderBRadius: '0px',
    boxSizing: 'border-box'
  },
  dqBorderBottom: {
    borderBottom: `1 solid ${CSS_VARIABLES.forest}`,
    borderBottomWidth: '2.5',
    borderBRadius: '0px',
    width: 76.50
  },
  dqBorder: {
    border: `1 solid ${CSS_VARIABLES.forest}`,
    width:535.4,
    boxSizing: 'border-box'
  },
  noBorder: {
    border: 'none'
  },
  secondRowUtility: {
    width: '126',
    height: '105.7',
    top: 240,
  },
  secondRowUtilityLarge: {
    width: '126',
    height: '164.7',
    top: 240,
  },
  left410:{
    left: 410
  },
  firstRowUtility: {
    width: '126',
    height: '105.7',
    top: 100
  },
  thermalPosition:{
    left:273
  },
  electricityPosition:{
    left: 137
  },
  waterPosition:{
    left:410
  },
  dateRangePosition:{
    marginTop: 28,
    width: 536,
  },
  dateLeft:{
    width:280
  },
  dateRight:{
    marginLeft:40,
    width:220
  },
  carbonPosition:{
    left: 273
  },
  wastePosition:{
    left:137
  },
  assetImage: {
    marginTop: 1,
    padding: 13,
    backgroundColor: CSS_VARIABLES.white,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    height:70,
    top: -10,
    width: 125,
    objectFit: 'contain'
  },
  dashboardAssetImage: {
    padding: 13,
    backgroundColor: CSS_VARIABLES.white,
    height:89,
    width: 125,
    top: -10,
    objectFit: 'contain'
  },
  portfolioAssetActualImage:{
    height: 50
  },
  portfolioAssetImage: {
    borderRadius: 1,
    height:35,
    width:35
  },
  portfolioAssetImageBWhite: {
    padding: 8,
    paddingLeft:5,
    paddingRight:5,
    backgroundColor: CSS_VARIABLES.white,
    borderRadius: 3,
    height:35,
    width:35
  },
  portfolioAssetImageBGrey:{
    padding: 8,
    paddingLeft:5,
    paddingRight:5,
    backgroundColor: CSS_VARIABLES.white,
    borderRadius: 3,
    height:35,
    width:35
  },
  assetActualImage: {
    marginTop: 1,
    width: 125,
    height: 70,
    objectFit: 'cover'
  },
  dashboardAssetActualImage: {
    width: 125,
    height: 89,
    top: -10,
    objectFit: 'cover'
  },
  blackColor: {
    fontFamily: ATKINSON_HYPERLEGIBLE_REGULAR,
    color: CSS_VARIABLES.forest,
    opacity: '0.9'
  },
  actionsBlueColor: {
    backgroundColor: CSS_VARIABLES.forest,
    height: '4',
    width: 539,
    top: -24,
    left: '-1'
  },
  performanceImage: {
    height: '73%',
    width: '93%',
    marginLeft: 13,
    marginTop: 17
  },
  customBorderBox: {
    height: 37.7,
    borderRight: `1 solid ${CSS_VARIABLES.forest}`,
    borderRightWidth: '0.5',
    borderBottom: `1 solid ${CSS_VARIABLES.forest}`,
    borderBottomWidth: '0.3'
  },
  performanceBorderBox: {
    borderRight: `1 solid ${CSS_VARIABLES.forest}`,
    borderRightWidth: '0.5',
    borderBottom: `1 solid ${CSS_VARIABLES.forest}`,
    borderBottomWidth: '0.3'
  },
  perfNotesBox:{
    height:37.3,
    maxHeight:37.3
  },
  actionCell: {
    maxWidth: 57,
    width: 57,
  },
  noRecords:{
    height:40,
    padding:14,
    paddingTop:10
  },
  completionCell: {
    maxWidth: 55.5,
    width: 55.5,
  },
  costTypeCell: {
    maxWidth: 62,
    width: 62,
  },
  dqText: {
    textAlign:'left',
    fontSize: 7,
    fontFamily: ATKINSON_HYPERLEGIBLE_REGULAR
  },
  dqTextBold: {
    fontSize: 7,
    fontFamily: ATKINSON_HYPERLEGIBLE_BOLD
  },
  passportLegendFirst: {
    width: '18',
    height: '3',
    backgroundColor: CSS_VARIABLES.forest
  },
  passportLegendSecond: {
    width: '18',
    height: '3',
    backgroundColor: CSS_VARIABLES.deep_ocean
  },
  passportLegendWaste: {
    width: '18',
    height: '3',
    backgroundColor: CSS_VARIABLES.sand
  },
  passportChartSize: {
    width: '259',
    height: '235'
  },
  passportChartTitle: {
    top: -24
  },
  passportChartLeft: {
    left: 19
  },
  passportTableTitle: {
    top: 300
  },
  dashboardTableTitle: {
    top: 359
  },
  passportChartFirst: {
    marginTop: '15'
  },
  passportChartSecond: {
    marginTop: '30'
  },
  mTop3:{
    marginTop: 3
  },
  mTop2:{
    marginTop: 2
  },
  mTop4:{
    marginTop: 4
  },
  mTop7:{
    marginTop: 7
  },
  mTop9:{
    marginTop:9
  },
  mTop14:{
    marginTop: 14
  },
  mTop6:{
    marginTop: 6
  },
  ellipsisMax1:{
    textOverflow:'ellipsis',
    maxLines:1
  },
  ellipsisMax2:{
    textOverflow:'ellipsis',
    maxLines:2
  },
  ellipsisMax3:{
    textOverflow:'ellipsis',
    maxLines:3
  },
  textAlignRight:{
    textAlign:'right'
  },
  textAlignCenter:{
    textAlign:'center'
  },
  textAlignLeft:{
    textAlign:'left'
  },
  headerOuterView:{
    width:399,
    maxWidth:399,
    minHeight:41,
    maxHeight:48,
  },
  headerOuterViewShort:{
    justifyContent: 'center',
    alignContent: 'left'
  },
  headerAssetAddress:{
    borderRight: `1 solid ${CSS_VARIABLES.forest}`,
    width:235,
    padding:5,
    borderRightWidth:0.5,
    justifyContent: 'center',
    alignContent: 'left',
  },
  headerAssetAddressShort:{
    borderRight: `1 solid ${CSS_VARIABLES.forest}`,
    width:150,
    top: '7',
    height:25,
    borderRightWidth:0.5,
    justifyContent: 'center',
    alignContent: 'left',
  },
  top3:{
    top:3
  },
  top4:{
    top:4
  },
  top5:{
    top:5
  },
  top15:{
    top:15
  },
  top10:{
    top:10
  },
  hP10:{
    padding: 5
  },
  hADetails:{
    paddingLeft: 4,
    paddingRight: 2,
    width:57
  },
  assetDetails:{
    opacity: '0.1',
    paddingLeft:5,
    paddingRight:5,
    paddingTop:3,
    paddingBottom:3
  },
  border:{
    border: `1 solid ${CSS_VARIABLES.forest}`,
    borderBottomWidth:0,
    borderRightWidth:0,
    borderLeftWidth:0,
    borderTopWidth:0.3,
    borderTopColor: CSS_VARIABLES.forest,
    width:100,
    opacity:0.2,
    marginLeft:12
  },
  assetDetailsResults:{
    top: '-77',
    marginLeft: 60
  },
  assetOuterHeader:{
    width: '126',
    height: '245.6',
    top: 100,
  },
  dashboardAssetOuterHeader: {
    width: '126',
    height: '304.6',
    top: 100
  },
  topM14:{
    top: -14
  },
  topM16:{
    top: -16
  },
  topM17:{
    top: -17
  },
  topM10:{
    top: -10
  },
  dqMeterImg:{
    top: -2,
    width:540
  },
  topM13:{
    top: -13
  },
  assetInnerView:{
    backgroundColor: CSS_VARIABLES.grey,
    height: '178',//159
    width: 125,
    // top: '-13'
  },
  assetAddressResult:{
    marginTop: 7,
    marginBottom: 6,
    marginLeft: 6,
    marginRight:8
  },
  assetFundResults:{
    marginLeft: 6,
    marginBottom: 8,
    marginRight: 5
  },
  utilityPrevious:{
    borderBottom: `1 solid ${CSS_VARIABLES.grey}`,
    borderBottomWidth:'0.5',
    opacity:'1',
    height: '26',
    left: 8
  },
  utilityCurrent:{
    height: '34',
    left: 8
  },
  largeUtilityCurrent:{
    height: '93',
    left: 8
  },
  utilityPercent:{
    backgroundColor: CSS_VARIABLES.grey,
    height: '35',
    width: 124.8
  },
  mTM4:{
    marginTop: -4
  },
  mT4:{
    marginTop: 4
  },
  mTM6:{
    marginTop: -6
  },
  mT0:{
    marginTop: 0
  },
  mT6:{
    marginTop: 6
  },
  mT8:{
    marginTop: 8
  },
  top8:{
    top:8
  },
  mL8:{
    marginLeft: 8
  },
  passportTable:{
    width: '536',
    marginTop: 315
  },
  dashboardTable:{
    width: '536',
    marginTop: 374
  },
  mTM1:{
    marginTop: -1
  },
  mTM2:{
    marginTop: -2
  },
  mTM15:{
    marginTop: -15
  },
  dqLandlord:{
    height: '70',
    width:106,
    marginLeft:9,
    borderBottom: `1 solid ${CSS_VARIABLES.grey}`,
    borderBottomWidth:'0.7'
  },
  dqTenant:{
    width:106,
    marginLeft:9
  },
  dqPercent:{
    backgroundColor: CSS_VARIABLES.grey,
    height: '35',
    width: 125.5,
    flexDirection: 'row'
  },
  dqLabel:{
    fontSize: 7,
    marginTop:8
  },
  dqLeft:{
    marginTop:3,
    width:50
  },
  dqRight:{
    marginTop:3,
    left: 18,
    width:40
  },
  tableWidth:{
    width: '536'
  },
  columnHeader:{
    flexDirection: 'row',
    marginBottom:6
  },
  mB10:{
    marginBottom:10
  },
  mB20:{
    marginBottom:20
  },
  mB8:{
    marginBottom:8
  },
  actionTitle:{
    marginBottom:10,
    fontFamily: ATKINSON_HYPERLEGIBLE_REGULAR,
    fontSize:9
  },
  mT18:{
    marginTop:18
  },
  mT19:{
    marginTop:19
  },
  pAction:{
    width: 171
  },
  pReduction:{
    width: 80
  },
  pCost:{
    width: 59.5
  },
  pScope:{
    width: 76
  },
  mL2:{
    marginLeft:2
  },
  aAction:{
    width:128.5
  },
  aTarget:{
    width:62
  },
  perfDate:{
    width:68
  },
  perfEvent:{
    width: 165
  },
  perfEventHeight:{
    paddingBottom:20,
    paddingRight:5
  },
  perfPerson:{
    width: 89
  },
  perfNotes:{
    width: 213.5
  },
  pB10:{
    paddingBottom: 10
  },
  pB4:{
    paddingBottom: 5
  },
  mL45:{
    marginLeft: 37
  },
  headerAsset:{
    width: 100,
    paddingLeft:2,
    paddingRight:2
  },
  dqImage:{
    width: 536,
    height: 90
  },
  mL24:{
    marginLeft:24
  },
  mT40:{
    marginTop: 40
  },
  mT5:{
    marginTop:5
  },
  mB5:{
    marginBottom:5
  },
  missingDataWidth:{
    width: 256.5
  },
  mT20:{
    marginTop:20
  },
  mT10:{
    marginTop:7
  },
  mT11:{
    marginTop:11
  },
  mT13:{
    marginTop:13
  },
  mT15:{
    marginTop:15
  },
  perfGraph:{
    width: 532,
    height: 290,
    top: 7
  },
  perfHeader:{
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginTop: 30,
    marginBottom: 10
  },
  marginAuto:{
    marginLeft: 'auto'
  },
  left13:{
    left: 13
  },
  left20:{
    left: 20,
    padding:2
  },
  left27:{
    left: 27,
    padding:2,
    width:85
  },
  perfTitle:{
    marginBottom: 7,
    fontFamily: ATKINSON_HYPERLEGIBLE_REGULAR,
    fontSize: 9
  },
  actualLegend:{
    width: 15,
    height: 2.5,
    backgroundColor: CSS_VARIABLES.forest,
    marginRight:4
  },
  actualLegendWaste:{
    width: 15,
    height: 2.5,
    backgroundColor: CSS_VARIABLES.sand,
    marginRight:4
  },
  actualText:{
    top: -2,
    textTransform:'uppercase'
  },
  actualTextWaste:{
    top: -2,
    textTransform:'uppercase'
  },
  targetText:{
    top: -2,
    textTransform:'uppercase'
  },
  targetTextWaste:{
    top: -2,
    textTransform:'uppercase'
  },
  targetLegend:{
    width: 18,
    height: 3,
    backgroundColor: CSS_VARIABLES.forest,
    marginRight:4
  },
  targetLegendWaste:{
    width: 15,
    height: 2.5,
    backgroundColor: CSS_VARIABLES.forest,
  },
  dataMissingLegend:{
    width: 15,
    height: 2.5,
    marginRight:4
  },
  dqMeter:{
    width:113.8
  },
  dqDate:{
    width:70
  },
  dqType:{
    width:60
  },
  dqProvider:{
    width:58
  },
  mT35:{
    marginTop:17
  },
  dqImagepadding:{
    paddingLeft:10,
    marginTop:-7,
    paddingRight:10
  }
  ,dqMeterHeader:{
    width:109.8
  },
  dqDateHeader:{
    width:71
  },
  dqTypeHeader:{
    width:61
  },
  dqProviderHeader:{
    width:59
  },
  mR6:{
    marginRight:6
  },
  mR8:{
    marginRight:8
  },
  width54:{
    width:54
  },
  width36:{
    width:36
  },
  width48:{
    width:48
  },
  borderGreen:{
    width: '45.1',
    height: '4',
    backgroundColor: CSS_VARIABLES.green
  },
  borderYellow:{
    width: '45.1',
    height: '4',
    backgroundColor: CSS_VARIABLES.yellow

  },
  borderOrange:{
    width: '45.1',
    height: '4',
    backgroundColor: CSS_VARIABLES.nature
  },
  borderBottomBlack:{
    minHeight:31,
    borderStyle: 'solid',
    borderColor: CSS_VARIABLES.forest,
    margin:-0.3,
    justifyContent: 'center',
    alignContent: 'left',
    border: `1 solid ${CSS_VARIABLES.forest}`,
    borderWidth: 0.5,
    borderBottomColor: CSS_VARIABLES.forest,
    borderBottomWidth:3,
  },
  borderGrey:{
    width: '45.1',
    height: '4',
    backgroundColor: CSS_VARIABLES.grey
  },
  hazard:{
    width: 59.4
  },
  floorArea:{
    paddingLeft:4,
    width: 188.4
  },
  floorImage:{
    width: '24%'
  },
  floorDetails:{
    width: '76%'
  },
  mB4:{
    marginBottom:4
  },
  center:{
    textAlign:'center'
  },
  targetPortfolio:{
    height:6,
    width:7,
    paddingRight:2
  },
  pL8:{
    paddingLeft:8
  },
  pL5:{
    paddingLeft:5
  },
  topM2:{
    top:-2
  },
  withWaste: {
    top: 10,
    left:58
  },
  withOutWaste:{
    top: 10,
    left:125
  }
})

export default styles