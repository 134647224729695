import React from 'react'
import { FormattedMessage, FormattedDate, useIntl } from 'react-intl'

const I18n = (id, value = {}) => <FormattedMessage id={id} values={{ ...value }} />

export const I18nDate = ({ date, day, month, year }) =>
  <FormattedDate
    value={date}
    day={day}
    month={month}
    year={year} />

export const useI18n = () => useIntl()

export default I18n