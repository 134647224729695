import {memo, useEffect, useState} from 'react'
import {noDataMessage} from '../../../../utils/helpers/MessageUtility'
import {getFillerImage, getLocalizedDateMessage, getLocalizedValue, getPaginatorTemplate} from '../../../../utils/helpers/Helper'
import {Column} from 'primereact/column'
import I18n from '../../../../utils/i18n/I18n'
import {DataTable} from 'primereact/datatable'
import {FUND_VIEWS_PAGES, sortDown, sortUp, TOP} from '../../../../utils/helpers/Constants'
import HyperLink from '../../../common/HyperLink/HyperLink'
import {Tooltip} from 'primereact/tooltip'
import {isTextOverflown} from '../../../../utils/UtilsFunctions'
import CustomBadge from '../../../common/custom-badge/CustomBadge'
import EmptyCellIcon from '../../../../resources/images/icon/EmptyCellIcon.svg'
import {formatNumWODecimal} from '../../../../services/common/formatter'
import PropTypes from 'prop-types'
import { getSortIcons } from 'utils/helpers/DataTableUtils'

const FundAssetsTable = ({data, loading, sortField, sortOrder, selectedUtility, onSort, selectedLanguage}) => {

  const sortIconToggle = sortOrder === 1 ? sortUp : sortDown
  const [first, setFirst] = useState(0)
  const [rows, setRows] = useState(20)

  useEffect(() => {
    setFirst(0)
    setRows(20)
  }, [selectedUtility])

  const onPageChange = (event) => {
    setFirst(event.first)
    setRows(event.rows)
  }

  const propertyDetailsTemplate = (rowData) => {
    const { assetAddress, assetLocation, dateOfSale, assetImageThumbnailUri } = rowData
    const propertyImage = getFillerImage(rowData, true)

    return (
      <HyperLink {...navLinkProps(rowData)}>
        <Tooltip className={'portfolio-comm-tooltip long-text-tooltip'} target=".fund-data-quality-tooltip" position={TOP} />
        <div className="flex flex-column p-3">
          <div className="asset-image-address-wrapper cursor flex">
            <img src={propertyImage} alt="Property"
              className={assetImageThumbnailUri ? 'image-actual' : 'image-filler'}/>
            <div className="asset-address-txt-wrapper text-wrapper">
              <div className="text-wrapper fund-data-quality-tooltip" onMouseEnter={(e)=>isTextOverflown(e)}
                data-ptf-tooltip={assetAddress}>{assetAddress}
              </div>
              <div className="regular-weight-font text-wrapper fund-data-quality-tooltip" onMouseEnter={(e)=>isTextOverflown(e)}
                data-ptf-tooltip={assetLocation}>{assetLocation}
              </div>
            </div>
          </div>
          <div className="w-full flex align-content-start p-0">
            { dateOfSale ? (<CustomBadge message={`${getLocalizedValue(selectedLanguage, 't_sold_label')} ${getLocalizedDateMessage(dateOfSale, 'MONTH_YEAR', selectedLanguage)}`} styling={'mt-3 text-xs font-normal'}></CustomBadge>): null }
          </div>
        </div>
      </HyperLink>
    )
  }

  const percentageTemplate = (rowData, colData) => {
    let rowDataValue = rowData[colData.field]
    if (rowDataValue !== null) {
      return (
        <HyperLink {...navLinkProps(rowData)}>
          <div className="hyper-link-cell-value">
            {(rowDataValue * 100).toFixed(1)} <span className="grey-suffix">%</span>
          </div>
        </HyperLink>
      )
    } else {
      return (
        <HyperLink {...navLinkProps(rowData)}>
          <div className={'empty-cell tooltip-grid-empty-carbon-' + rowData.assetId}>
            <div className={'padding-no-data'}><img src={EmptyCellIcon} alt="EmptyCellIcon"/></div>
          </div>
        </HyperLink>
      )
    }
  }

  const floorAreaTemplate = (rowData) => {
    let floorAreaMeasurement = rowData.measurementUnit
    let FloorAreaSymbol = floorAreaMeasurement === 'M2' ? 'm²' : 'ft²'
    let floorAreaValue = rowData.floorArea
    let floorAreaValueFormatted = formatNumWODecimal(selectedLanguage, floorAreaValue)

    if (floorAreaValue !== null) {
      return (
        <HyperLink {...navLinkProps(rowData)}>
          <div>
            {floorAreaValueFormatted} <span className="grey-suffix">{FloorAreaSymbol}</span>
          </div>
        </HyperLink>
      )
    } else {
      return (
        <HyperLink {...navLinkProps(rowData)}>
          <div className={'empty-cell tooltip-grid-empty-carbon-' + rowData.assetId}>
            <div className={'padding-no-data'}><img src={EmptyCellIcon} alt="EmptyCellIcon"/></div>
          </div>
        </HyperLink>
      )
    }
  }

  function navLinkProps(rowData) {
    return {
      event: rowData,
      pathSuffix: FUND_VIEWS_PAGES.dataQuality,
      assetName: rowData.assetAddress + ', ' + rowData.assetLocation
    }
  }

  return (
    <>
      <DataTable
        value={data}
        emptyMessage={noDataMessage()}
        className="p-datatable-gridlines"
        dataKey="id"
        loading={loading}
        onSort={onSort}
        sortField={sortField}
        sortOrder={sortOrder}
        defaultSortOrder={-1}
        paginator={data?.length > 20}
        first={first}
        rows={rows}
        paginatorTemplate={getPaginatorTemplate('items-per-page-options-white')}
        paginatorClassName={'data-table-paginator fund-dq-paginator'}
        onPage={e => onPageChange(e)}
        totalRecords={data?.length}
        sortIcon={getSortIcons()}
      >
        <Column
          field="assetAddress"
          header={I18n('t_name')}
          headerClassName={`table-header-style ${sortIconToggle}`}
          body={propertyDetailsTemplate}
          style={{width: '27.3%'}}
          sortable
        />
        <Column
          field="portionOfMissingData"
          header={I18n('t_proportion_missing_data')}
          headerClassName={`table-header-style ${sortIconToggle}`}
          body={percentageTemplate}
          style={{width: '10%', textAlign: 'right'}}
          sortable
        />
        <Column
          field="floorArea"
          header={I18n('t_floor_area')}
          headerClassName={`table-header-style ${sortIconToggle}`}
          body={floorAreaTemplate}
          style={{width: '11%', textAlign: 'right'}}
          sortable
        />
        <Column
          field="actual"
          header={I18n('t_actual')}
          headerClassName={`table-header-style ${sortIconToggle}`}
          body={percentageTemplate}
          style={{width: '10%', textAlign: 'right'}}
          sortable
        />
        <Column
          field="estimated"
          header={I18n('t_estimated')}
          headerClassName={`table-header-style ${sortIconToggle}`}
          body={percentageTemplate}
          style={{width: '10%', textAlign: 'right'}}
          sortable
        />
        <Column
          field="inComplete"
          header={I18n('t_incomplete')}
          headerClassName={`table-header-style ${sortIconToggle}`}
          body={percentageTemplate}
          style={{width: '10%', textAlign: 'right'}}
          sortable
        />
        <Column
          field="notCovered"
          header={I18n('t_not_covered')}
          headerClassName={`table-header-style ${sortIconToggle}`}
          body={percentageTemplate}
          style={{width: '10%', textAlign: 'right'}}
          sortable
        />
        <Column
          field="totalMissing"
          header={I18n('t_total_missing')}
          headerClassName={`table-header-style ${sortIconToggle}`}
          body={percentageTemplate}
          style={{width: '10%', textAlign: 'right'}}
          sortable
        />
      </DataTable>
    </>
  )
}

FundAssetsTable.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  loading: PropTypes.bool,
  sortField: PropTypes.string,
  sortOrder: PropTypes.number,
  selectedUtility: PropTypes.array,
  onSort: PropTypes.func,
  selectedLanguage: PropTypes.string
}

FundAssetsTable.displayName = 'FundAssetsTable'

export default memo(FundAssetsTable)