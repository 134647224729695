import React, {memo} from 'react'
import './ChartAxisOptions.scss'
import I18n from '../../../../../utils/i18n/I18n'
import {InputNumber} from 'primereact/inputnumber'
import {Checkbox} from 'primereact/checkbox'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const ChartAxisOptions = ({propsConfig}) => {
  const {chartOptionsFormState, formErrors, handleYAxis, handleXAxis, handleOnBlurOfXAxisValue, handleOnBlurOfYAxisValue, updateChartOptionsFormState}=propsConfig
  const { loginState: { userInfo } } = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference

  function getYearsValidationMessage() {
    return (formErrors.fromToYear ? <div className="chart-form-error">{I18n('t_year_from_to_4_digits')}</div> : '')
  }

  function getMinMaxValidationMessage() {
    return (formErrors.minMax ? <div className="chart-form-error">{I18n('t_min_max_numeric')}</div> : '')
  }

  return (
    <div className="chart-axis-options">
      <div className="graph-input-container x-axis-scale">{I18n('t_x_axis_scale')}</div>
      <div className="graph-input-container">
        <label data-testid="x-axis-from-label" className='input-label'>{I18n('t_from')}</label>
        <InputNumber
          inputId="from-x-axis"
          onKeyDown={(e) => e.code === 'Minus' && e.preventDefault()}
          className="reduction-input-number"
          min={0}
          value={chartOptionsFormState.xAxisScaleFrom}
          useGrouping={false}
          disabled={chartOptionsFormState.xAxisAutoScale}
          onBlur={handleOnBlurOfXAxisValue}
          onChange={(e) => {updateChartOptionsFormState({xAxisScaleFrom: e.value})
          }}/>
        <label data-testid="x-axis-to-label" className='input-label'>{I18n('t_to')}</label>
        <InputNumber inputId="to-x-axis"
          className="reduction-input-number"
          min={0}
          onKeyDown={(e) => e.code === 'Minus' && e.preventDefault()}
          value={chartOptionsFormState.xAxisScaleTo}
          useGrouping={false}
          disabled={chartOptionsFormState.xAxisAutoScale}
          onBlur={handleOnBlurOfXAxisValue}
          onChange={(e) => updateChartOptionsFormState({xAxisScaleTo: e.value})}/>
        <div data-testid="x-axis-auto-scale-checkbox" className="p-field-checkbox axis-checkbox">
          <Checkbox inputId="x-axis-checkbox" checked={chartOptionsFormState.xAxisAutoScale} onChange={handleXAxis}/>
          <label data-testid="x-axis-scale-label" htmlFor="x-axis-checkbox" className='autoscale'>{I18n('t_autoscale')}</label>
        </div>
      </div>
      {getYearsValidationMessage()}
      <div data-testid="y-axis-scale-label" className="graph-input-container y-axis-scale">{I18n('t_y_axis_scale')}</div>
      <div className="graph-input-container">
        <label data-testid="y-axis-min-label" className='input-label'>{I18n('t_min')}</label>
        <InputNumber
          className="reduction-input-number"
          value={chartOptionsFormState.yAxisScaleMin}
          mode="decimal"
          disabled={chartOptionsFormState.yAxisAutoScale}
          onBlur={handleOnBlurOfYAxisValue}
          onChange={(e) => updateChartOptionsFormState({yAxisScaleMin: e.value})}
          locale={selectedLanguage}

        />
        <label data-testid="y-axis-max-label" className='input-label' >{I18n('t_max')}</label>
        <InputNumber
          className="reduction-input-number"
          value={chartOptionsFormState.yAxisScaleMax}
          mode="decimal"
          disabled={chartOptionsFormState.yAxisAutoScale}
          onBlur={handleOnBlurOfYAxisValue}
          onChange={(e) => updateChartOptionsFormState({yAxisScaleMax: e.value})}
          locale={selectedLanguage}
        />
        <div className="p-field-checkbox axis-checkbox" data-testid="y-axis-auto-scale-checkbox">
          <Checkbox inputId="y-axis-checkbox" checked={chartOptionsFormState.yAxisAutoScale} onChange={handleYAxis} disabled={false}/>
          <label htmlFor="y-axis-checkbox" className='autoscale'>{I18n('t_autoscale')}</label>
        </div>
      </div>
      {getMinMaxValidationMessage()}
    </div>
  )
}

export default memo(ChartAxisOptions)