import React, {useState, useEffect, useContext} from 'react'
import {Route, Redirect, useLocation} from 'react-router-dom'

import {getFeatureFlagStatus, getHomePagePathV2, isLoggedInUserAdmin} from '../../../utils/helpers/Helper'
import { useLoginAuthContext } from '../../pages/login/auth0/UserInfoProvider'
import {FeatureFlagContext} from 'Contexts'

const UserAdminPageRoute = ({ component: Component, ...rest }) => {
  const { loginState } = useLoginAuthContext()
  const [ homePath, setHomePath ] = useState()
  const featureContext            = useContext(FeatureFlagContext)
  const featureState              = featureContext.featureState
  const location = useLocation()

  useEffect(() => {
    (async () => {
      if (!homePath && loginState?.userFundAccess && loginState?.user?.instanceName)
        setHomePath(await getHomePagePathV2({}, loginState.userFundAccess, loginState.user.instanceName))
    })()
  }, [loginState])

  if (Component && isLoggedInUserAdmin(loginState.userInfo.userRole)){
    return getFeatureFlagStatus(featureState,rest.path, location.state) ? <Route render={props => <Component {...props} />} {...rest} /> : <Redirect to="/notfound" />
  }

  if (!homePath)
    return null


  return <Redirect to={homePath} {...rest} />
}

export default UserAdminPageRoute