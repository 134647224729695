import React, {useEffect, useState} from 'react'
import '../../users/user-overview/UserDetailsHome.scss'
import {Dropdown} from 'primereact/dropdown'
import refresh from '../../../../../resources/images/icon/refreshGrey.svg'
import {getLocalizedValue} from '../../../../../utils/helpers/Helper'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

function DropdownAsset(props) {
  const [fundLists, setFundLists]           = useState([])
  const [locationList, setLocationList]     = useState([])
  const [sectorList, setSectorList]         = useState([])
  const [fund,setFund] = useState(null)
  const [location,setLocation] = useState(null)
  const [sector,setSector] = useState(null)
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference

  useEffect(() => {
    setFundLists(props.fundList)
    setLocationList(props.locationList)
    setSectorList(props.sectorList)
    setFund(props.fund)
    setLocation(props.location)
    setSector(props.sector)
  })

  function onFundFilterChange(event,type){
    if(type==='Fund'){
      props.onFundFilterChange(event.value,'Fund')
    }else if(type==='Location'){
      props.onFundFilterChange(event.value,'Location')
    }else if(type==='Sector'){
      props.onFundFilterChange(event.value,'Sector')
    }
  }

  function resetFilter() {
    props.onFundFilterChange(null,'Reset')
  }

  const dropdownItemTemplate  = (option) => {
    return (
      <>
        <div className="dropdown-item-1">
          <div className="dropdown-item-2 dropdown-item-wrap">{option}</div>
        </div>
        <div className={'dropdown-panel-item-horiz-line'} />
      </>
    )
  }

  const fundFilterElements      = <Dropdown role={'fund-dropdown'} appendTo="self"  className="sDropdown" placeholder={getLocalizedValue(selectedLanguage, 't_fund')}
    onChange={e => onFundFilterChange(e,'Fund')} value={fund} options={fundLists}
    itemTemplate={dropdownItemTemplate} scrollHeight="24em"/>
  const locationFilterElements  = <Dropdown role={'location-dropdown'} appendTo="self"  className="sDropdown" placeholder={getLocalizedValue(selectedLanguage, 't_location')}
    onChange={e => onFundFilterChange(e,'Location')} value={location} options={locationList}
    itemTemplate={dropdownItemTemplate} scrollHeight="24em"/>
  const sectorFilterElements    = <Dropdown role={'sector-dropdown'} appendTo="self"  className="sDropdown" placeholder={getLocalizedValue(selectedLanguage, 't_type')}
    onChange={e => onFundFilterChange(e,'Sector')} value={sector} options={sectorList}
    itemTemplate={dropdownItemTemplate} scrollHeight="24em"/>


  return (
    <div className="col-7 flex filter-dropdown">
      <div className="col-4 no-padding">{fundFilterElements}</div>
      <div className="col-4 no-padding">{locationFilterElements}</div>
      <div className="col-4 no-padding">{sectorFilterElements}</div>
      <div className="no-padding flex align-items-center">
        <img src={refresh} alt={getLocalizedValue(selectedLanguage, 't_refresh')} className="cursor highlight-clickable-opacity"
          onClick={resetFilter}/>
      </div>
    </div>
  )
}

export default DropdownAsset