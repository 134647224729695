import axiosInstance from '../common/axios-config'
import axios from 'axios'
import {
  getFileNameFromPath,
  getParsedConsumptionData
} from '../../components/pages/documents/DocumentsUtil'
import {uuid} from 'uuidv4'
import {convertDateToUTCFormat} from '../../utils/helpers/Helper'

function constructFilterString(queryParam) {
  function generateQueryString(field, filterArray) {
    if((field === 'startDate' || field === 'endDate') && filterArray){
      return `${field}=${encodeURIComponent(convertDateToUTCFormat(filterArray))}`
    }
    else if (filterArray && filterArray.length > 0) {
      return filterArray.map(value => `${field}=${encodeURIComponent(value)}`).join('&')
    }
    return ''
  }

  const queryParams = [
    generateQueryString('fund', queryParam.queryKey[2]?.fund || []),
    generateQueryString('asset', queryParam.queryKey[2]?.asset || []),
    generateQueryString('type', queryParam.queryKey[2]?.sector || []),
    generateQueryString('location', queryParam.queryKey[2]?.country || []),
    generateQueryString('documentType', queryParam.queryKey[2]?.documentType || []),
    generateQueryString('startDate', queryParam.queryKey[2]?.validWithin?.[0] || null),
    generateQueryString('endDate', queryParam.queryKey[2]?.validWithin?.[1] || null),
    generateQueryString('uploadedBy', queryParam.queryKey[2]?.uploadedBy || [])
  ]

  return queryParams.filter(part => part !== '').join('&')
}

export const getDocumentsAndCertificatesList = async (queryParam) => {
  const searchString = queryParam.queryKey[1]
  const filterString = constructFilterString(queryParam)

  //uuid :  session token to keep changing
  const {data} = await axiosInstance.get(`api/v1/GetAllDocuments?sessionToken=${uuid()}&searchString=${searchString}&${filterString}`)

  return data?.map((item) => {
    item.fileName = getFileNameFromPath(item.filePath)
    return item
  }).sort((a, b) => {
    return new Date(b.dateOfUpload) - new Date(a.dateOfUpload)
  })
}

export const getConsumptionEvidenceList = async (queryParam) => {
  const searchString = queryParam.queryKey[1]
  const filterString = constructFilterString(queryParam)

  const {data} = await axiosInstance.get(`/api/v1/ConsumptionEvidenceDocuments?sessionToken=${uuid()}&searchString=${searchString}&${filterString}`)
  return getParsedConsumptionData(data)
}

export const getFilterOptions = async (filterDetails) => {
  const getOrder = (field) => filterDetails.filterData.filterData.orderSelection?.find(order=>{
    return order.hasOwnProperty(field)
  })

  function setOrderValue(fieldValue,field){
    return fieldValue.map(individualField=>{
      return {order:getOrder(field)[field] , value:individualField}
    })
  }

  const constructBody = ()=>{
    const body = {
      fund:setOrderValue(filterDetails.filterData.filterData?.fund || [],'fund'),
      location:setOrderValue(filterDetails.filterData.filterData?.country||[],'country'),
      asset:setOrderValue(filterDetails.filterData.filterData?.asset||[],'asset')
    }
    if(filterDetails.tabIndex === 1 || filterDetails.tabIndex === 0){
      //TODO : Type should be there only for the consumption, Remove tab === 0
      body.type= setOrderValue(filterDetails.filterData.filterData?.type||[],'type')
    }
    body.isDocumentFilterOptions = filterDetails.tabIndex === 0
    return body
  }

  const {data} = await axiosInstance.post(`/api/v1/FilterOptions?sessionToken=${uuid()}`,constructBody())

  return data
}

export const fetchFile = async (fileUrl) => {
  return await axios.get(fileUrl,{
    responseType: 'blob'
  })
}

export const downloadDocuments = async (requestData) => {
  const {data} = await axiosInstance.post('/api/v1/Documents',requestData)
  return data
}

export const availableCapacity = async () => {
  const {data} = await axiosInstance.get('/api/v1/TotalStorageCapacity')
  return data
}

export const deleteDocument = async (selectedList) => {
  const {data} = await axiosInstance.delete('/api/v1/Documents',{data:selectedList})
  return data
}

export  const getPresignedURL = async (path) => {
  const {data} = await axiosInstance.put(`/api/v1/Document?filePath=${encodeURIComponent(path)}`)
  return data
}

export  const editDocument = async (body) => {
  const {data} = await axiosInstance.put('/api/v1/Documents',body)
  return data
}

export const uploadDocumentUsingPresignedURL = async (file, signedUrl) => {
  try {
    return  await axios.put(signedUrl, file, {
      headers: {
        'Content-Type': file.type
      }})
  } catch (error) {
    console.error('Error uploading file:', error)
  }
}
