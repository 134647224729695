import I18n from '../../../utils/i18n/I18n'
import {getLocalizedValue} from '../../../utils/helpers/Helper'

export const DOCUMENT_FILTER_TYPES = {
  VALID_WITHIN: 'validWithin',
  EVIDENCE_DATE_RANGE: 'evidenceDateRange',
  DOCUMENT_TYPE: 'documentType',
  FUND: 'fund',
  COUNTRY: 'country',
  ASSET: 'asset',
  MANAGING_AGENT: 'managingAgent',
  UPLOADED_BY: 'uploadedBy',
  SECTOR: 'Sector',
  METER: 'Meter',
  ORDER_SELECTION: 'orderSelection'
}

export const documentFilterTypes = (selectedLanguage, tabIndex,data,isLoading) => {
  switch (tabIndex) {
  case 0: return {
    [DOCUMENT_FILTER_TYPES.VALID_WITHIN]: {
      label: 't_valid_within',
      dropdownOptions: null,
      placeholder: getLocalizedValue(selectedLanguage, 't_pick_date_range'),
      filterPlaceholder: null,
    },
    [DOCUMENT_FILTER_TYPES.DOCUMENT_TYPE]: {
      label: I18n('t_doc_type'),
      dropdownOptions: isLoading ? [] : data?.documentType,
      placeholder: getLocalizedValue(selectedLanguage, 't_select_doc_type'),
      filterPlaceholder: getLocalizedValue(selectedLanguage, 't_search_document'),
    },
    [DOCUMENT_FILTER_TYPES.FUND]: {
      label: I18n('t_fund'),
      dropdownOptions: isLoading ? [] : data?.funds,
      placeholder: getLocalizedValue(selectedLanguage, 't_select_doc_fund'),
      filterPlaceholder: getLocalizedValue(selectedLanguage, 't_search_fund'),
    },
    [DOCUMENT_FILTER_TYPES.COUNTRY]: {
      label: I18n('t_country'),
      dropdownOptions: isLoading ? [] : data?.locations,
      placeholder: getLocalizedValue(selectedLanguage, 't_select_doc_country'),
      filterPlaceholder: getLocalizedValue(selectedLanguage, 't_search_country'),
    },
    [DOCUMENT_FILTER_TYPES.ASSET]: {
      label: I18n('t_assets'),
      dropdownOptions: isLoading ? [] : data?.assets,
      placeholder: getLocalizedValue(selectedLanguage, 't_select_asset'),
      filterPlaceholder: getLocalizedValue(selectedLanguage, 't_search_asset'),
    },
    [DOCUMENT_FILTER_TYPES.UPLOADED_BY]: {
      label: I18n('t_uploaded_by'),
      dropdownOptions: isLoading ? [] : data?.uploadedBy,
      placeholder: getLocalizedValue(selectedLanguage, 't_select_user'),
      filterPlaceholder: getLocalizedValue(selectedLanguage, 't_search_name'),
    },
  }
  case 1: return {
    [DOCUMENT_FILTER_TYPES.EVIDENCE_DATE_RANGE]: {
      label: 't_evidence_date_range',
      dropdownOptions: null,
      placeholder: getLocalizedValue(selectedLanguage, 't_pick_evidence_date_range'),
      filterPlaceholder: null,
    },
    [DOCUMENT_FILTER_TYPES.FUND]: {
      label: I18n('t_fund'),
      dropdownOptions: isLoading ? [] : data.funds,
      placeholder: getLocalizedValue(selectedLanguage, 't_select_doc_fund'),
      filterPlaceholder: getLocalizedValue(selectedLanguage, 't_search_fund'),
    },
    [DOCUMENT_FILTER_TYPES.SECTOR]: {
      label: I18n('t_sector'),
      dropdownOptions: isLoading ? [] : data.types,
      placeholder: getLocalizedValue(selectedLanguage, 't_select_sector'),
      filterPlaceholder: getLocalizedValue(selectedLanguage, 't_search_sector'),
    },
    [DOCUMENT_FILTER_TYPES.COUNTRY]: {
      label: I18n('t_country'),
      dropdownOptions: isLoading ? [] : data.locations,
      placeholder: getLocalizedValue(selectedLanguage, 't_select_doc_country'),
      filterPlaceholder: getLocalizedValue(selectedLanguage, 't_search_country'),
    },
    [DOCUMENT_FILTER_TYPES.ASSET]: {
      label: I18n('t_assets'),
      dropdownOptions: isLoading ? [] : data.assets,
      placeholder: getLocalizedValue(selectedLanguage, 't_select_asset'),
      filterPlaceholder: getLocalizedValue(selectedLanguage, 't_search_asset'),
    },
    [DOCUMENT_FILTER_TYPES.METER]: {
      label: I18n('t_req_meter'),
      dropdownOptions: isLoading ? [] : data?.meters,
      placeholder: getLocalizedValue(selectedLanguage, 't_select_meter'),
      filterPlaceholder: getLocalizedValue(selectedLanguage, 't_search_meter'),
    },
    [DOCUMENT_FILTER_TYPES.UPLOADED_BY]: {
      label: I18n('t_uploaded_by'),
      dropdownOptions: isLoading ? [] : data?.uploadedBy,
      placeholder: getLocalizedValue(selectedLanguage, 't_select_user'),
      filterPlaceholder: getLocalizedValue(selectedLanguage, 't_search_name'),
    },
  }
  }
}

export const documentsFilterInitialValue = {
  [DOCUMENT_FILTER_TYPES.VALID_WITHIN]: null,
  [DOCUMENT_FILTER_TYPES.DOCUMENT_TYPE]: null,
  [DOCUMENT_FILTER_TYPES.FUND]:  null,
  [DOCUMENT_FILTER_TYPES.COUNTRY]: null,
  [DOCUMENT_FILTER_TYPES.ASSET]: null,
  [DOCUMENT_FILTER_TYPES.MANAGING_AGENT]: null,
  [DOCUMENT_FILTER_TYPES.UPLOADED_BY]: null,
  [DOCUMENT_FILTER_TYPES.ORDER_SELECTION]:null
}

export const consumptionEvidenceFilterInitialValue = {
  [DOCUMENT_FILTER_TYPES.EVIDENCE_DATE_RANGE]: null,
  [DOCUMENT_FILTER_TYPES.FUND]:  null,
  [DOCUMENT_FILTER_TYPES.SECTOR]: null,
  [DOCUMENT_FILTER_TYPES.COUNTRY]: null,
  [DOCUMENT_FILTER_TYPES.ASSET]: null,
  [DOCUMENT_FILTER_TYPES.METER]: null,
  [DOCUMENT_FILTER_TYPES.MANAGING_AGENT]: null,
  [DOCUMENT_FILTER_TYPES.UPLOADED_BY]: null,
  [DOCUMENT_FILTER_TYPES.ORDER_SELECTION]:null
}

function maintainFieldOrder(selectedFields,currentSelection, field) {
  const order = currentSelection.length + 1
  const existingFieldIndex = currentSelection.findIndex(item => item[field])
  if (existingFieldIndex === -1) {
    selectedFields.push({ [field]: order })
  }
  return [...selectedFields,...currentSelection]
}

export const documentsReducer = (state, action) => {
  switch(action.type) {
  case 'UPDATE_FILTER_SELECTIONS': {
    if([DOCUMENT_FILTER_TYPES.VALID_WITHIN, DOCUMENT_FILTER_TYPES.EVIDENCE_DATE_RANGE]
      .includes(action.filterData.selectedFilterCategory)) {
      return {...state, filterData: {...state.filterData, [action.filterData.selectedFilterCategory]: action.filterData.currentSelection}}
    } else {

      const currentSelections = []
      action.filterData.currentSelection.forEach((item) => {
        if(typeof(item) === 'object' && item.hasOwnProperty('label')) {
          currentSelections.push(item.label||item)
        } else {
          currentSelections.push(item)
        }
      })
      const order = maintainFieldOrder([],action.order||[],action.filterData.selectedFilterCategory)

      const selectedFilterOptions = currentSelections.length ? documentFilterTypes(action.filterData.selectedLanguage, action.filterData.tabIndex,action.data,false)[action.filterData.selectedFilterCategory]
        .dropdownOptions.filter(option => currentSelections.includes(option.label||option)) : null

      return {...state , filterData: {...state.filterData, [action.filterData.selectedFilterCategory]: selectedFilterOptions , ['orderSelection']: order}}
    }
  }
  case 'CLEAR_FILTER_SELECTIONS' : return {...state,
    filterData: action.filterData.tabIndex === 0 ? documentsFilterInitialValue : consumptionEvidenceFilterInitialValue}
  case 'ON_HIDE_OR_CLOSE_OF_FILTER': return {...state, filterData: action.filterData.defaultSelections}
  }
}

export const setInitialDocumentsState = (tabIndex) => {
  switch(tabIndex) {
  case 0:
    return {
      filterData: documentsFilterInitialValue
    }

  case 1:
    return {
      filterData: consumptionEvidenceFilterInitialValue
    }
  }
}

export const downloadDocumentsRequestData = (selectedDocuments, tabIndex) => {
  if(tabIndex === 0) {
    return selectedDocuments.map((item) => item.filePath.split('/').slice(2).join('/'))
  } else {
    let allPaths = []
    selectedDocuments.forEach((item) => {
      allPaths = allPaths.concat([...item.filePath.map((path) => path.split('/').slice(2).join('/'))])
    })

    return allPaths
  }
}

export const getFileNameFromPath = (filePath) => filePath?.length && filePath.split('/').slice(-1).join()

function bytesToSize(bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB']
  if (bytes === 0) return bytes
  const i= parseInt(Math.floor(Math.log(bytes) / Math.log(1000)).toString(), 10)

  if (i === 0) return `${bytes} ${sizes[i]}`
  return `${(bytes / Math.pow(1000, i)).toFixed(2)} ${sizes[i]}`
}

export function bytesToGB(bytes) {
  const gigabyte = 1024 * 1024 * 1024 // 1 GB in bytes
  return bytes / gigabyte
}

export function fileSizeToBytes(value) {
  if(value){
    const [n, abbreviation] = value.split(/\s+/)

    switch (abbreviation) {
    case 'KB':
      return n * Math.pow(1024, 1)
    case 'MB':
      return n * Math.pow(1024, 2)
    case 'GB':
      return n * Math.pow(1024, 3)
    default:
      return n
    }
  }
}

export function formatFileSize(bytes) {
  const BYTES = ' bytes'
  const KB = ' KB'
  const MB = ' MB'
  const GB = ' GB'
  const BASE_SIZE = 1024

  if (bytes < BASE_SIZE) {
    return bytes + BYTES
  } else if (bytes < BASE_SIZE * BASE_SIZE) {
    return (bytes / 1024).toFixed(2) + KB
  } else if (bytes < BASE_SIZE * BASE_SIZE * BASE_SIZE) {
    return (bytes / (BASE_SIZE * BASE_SIZE)).toFixed(2) + MB
  } else {
    return (bytes / (BASE_SIZE * BASE_SIZE * BASE_SIZE)).toFixed(2) + GB
  }
}

export const getParsedConsumptionData = (consumptionData) => {
  const parsedTableData = consumptionData.map((item) => {
    const noOfFiles = item.fileDocument.length

    if(noOfFiles) {
      item.dateOfUpload = new Date(Math.max(...item.fileDocument.map(e => new Date(e.dateOfUpload))))
      item.fileNames = item.fileDocument.map((documentData) => getFileNameFromPath(documentData.filePath))
      item.filePath = item.fileDocument.map((documentData) => documentData.filePath)

      let totalSize = 0
      item.fileDocument.forEach((elem) => {
        totalSize += fileSizeToBytes(elem.fileSize)
      })
      item.fileSize = bytesToSize(totalSize)
    }
    return item
  })
  return parsedTableData
}

export function getFileName(selectedRows,index){
  const fileName = selectedRows[index].split('/').pop()
  const isFileNameExist = selectedRows.filter(row => row.split('/').pop() === fileName)

  let updatedFileName = fileName
  if (isFileNameExist.length > 1) {
    const parts = fileName.split('.')
    const name = parts.slice(0, -1).join('.')
    const extension = parts.slice(-1)[0]
    updatedFileName = `${name}_${index}.${extension}`
  }
  return updatedFileName
}