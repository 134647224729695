import { formatDateToDDMMYY } from 'utils/helpers/DateUtils'
import { applyMultipleFundReportPAITableHeaderStyles, formatData, getEmptyRows, mergeMultipleCellRanges, setRowHeight, textAlignCenter } from 'utils/helpers/ExcelUtils'
import { generatePrincipleMetricsRow, generateQuarterlySummaryRow, getAssetInformationTableHeaderShared, getPrincipleMetricsTableHeader, getQuarterlySummaryTableHeader, getfundReportSheetHeaderInformation } from './fundReportPAIExcelExport'

export const createFundReportPAIFossilFuelSheet = (
  fundName,
  reportingYear,
  fossilFuelsBreakdown,
  assets,
  localize,
  selectedLanguage,
  excelSheet
) => {
  let excelDataArray = []
  excelDataArray.push(...getfundReportSheetHeaderInformation('t_fossil_fuel_impact_report', fundName, reportingYear, localize))
  excelDataArray.push(...getEmptyRows(2))
  excelDataArray = getFFQuarterlySummaryTable(excelDataArray, fossilFuelsBreakdown.quarterlySummary, reportingYear, localize)
  excelDataArray.push(...getEmptyRows(1))
  excelDataArray = getFFPrincipleMetricsTable(excelDataArray, fossilFuelsBreakdown.principleMetrics, reportingYear, localize)
  excelDataArray.push(...getEmptyRows(1))
  excelDataArray = getAssetInformationTable(excelDataArray, assets, reportingYear, localize, selectedLanguage, excelSheet)
  return excelDataArray
}

const getFFQuarterlySummaryTable = (currentExcelData, quarterlySummaryData, reportingYear, localize) => {
  const {exposedValue, totalPortfolioValue, exposedValueShare} = quarterlySummaryData
  const newQuarterlySummaryData = [...currentExcelData]
  newQuarterlySummaryData.push(...getQuarterlySummaryTableHeader(localize, reportingYear))
  newQuarterlySummaryData.push(...[
    generateQuarterlySummaryRow('t_exposed_value', exposedValue.q1, exposedValue.q2, exposedValue.q3, exposedValue.q4, localize),
    generateQuarterlySummaryRow('t_total_portfolio_value', totalPortfolioValue.q1, totalPortfolioValue.q2, totalPortfolioValue.q3, totalPortfolioValue.q4, localize),
    generateQuarterlySummaryRow('t_exposed_value_share', exposedValueShare.q1, exposedValueShare.q2, exposedValueShare.q3, exposedValueShare.q4, localize)
  ])
  
  return newQuarterlySummaryData
}

const getFFPrincipleMetricsTable = (currentExcelData, principleMetricsData, reportingYear, localize) => {
  const {exposedValueShare} = principleMetricsData
  const newPrincipleMetricsData = [...currentExcelData]
  newPrincipleMetricsData.push(...getPrincipleMetricsTableHeader(localize, reportingYear))
  newPrincipleMetricsData.push(...[generatePrincipleMetricsRow('t_average_share_exposed_value', exposedValueShare.currentYear, exposedValueShare.previousYear, localize)])
  return newPrincipleMetricsData
}

const getAssetInformationTable = (currentExcelData, assetsArray, reportingYear, localize, selectedLanguage, excelSheet) => {
  const newAssetData = [...currentExcelData]
  newAssetData.push(...getAssetInformationTableHeaderShared(localize, reportingYear))
  assetsArray.forEach(asset => {
    const { 
      assetReference, 
      assetName, 
      location, 
      constructionYear, 
      purchaseDate,
      saleDate, 
      gavPerQuarter,
      FossilFuels
    } = asset
    newAssetData.push(...[{
      A: assetReference,
      B: assetName,
      C: location,
      D: formatDateToDDMMYY(purchaseDate, selectedLanguage),
      E: formatDateToDDMMYY(saleDate, selectedLanguage),
      F: constructionYear,
      G: formatData(gavPerQuarter.q1),
      H: formatData(gavPerQuarter.q2),
      I: formatData(gavPerQuarter.q3),
      J: formatData(gavPerQuarter.q4),
      K: formatData(FossilFuels.exposed.q1, localize),
      L: formatData(FossilFuels.exposed.q2, localize),
      M: formatData(FossilFuels.exposed.q3, localize),
      N: formatData(FossilFuels.exposed.q4, localize),
    }])
    excelSheet.row(newAssetData.length).height(31)
  })

  return newAssetData
}

export const applyStylingToFossilFuelSheet = (excelSheet) => {
  excelSheet.usedRange().forEach(cell => {
    if (cell.address() !== 'A1') {
      cell.style({fontSize: 12,  horizontalAlignment: 'left', verticalAlignment: 'center'})
    }
    if(cell.address() === 'A8' || cell.address() === 'A14') {
      cell.style({topBorder: false})
    }
  })

  applyMultipleFundReportPAITableHeaderStyles(['A18:N18', 'G19:N19', 'B8:E8', 'B14:C14'], excelSheet)
  mergeMultipleCellRanges(excelSheet, ['A18:A19', 'B18:B19', 'C18:C19', 'D18:D19', 'E18:E19', 'F18:F19', 'G18:J18', 'K18:N18'])
  textAlignCenter(excelSheet, 'G18:N18')
  setRowHeight(excelSheet, 18, 30)
}