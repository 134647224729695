import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

export const Auth0Login = () => {
  const { loginWithRedirect } = useAuth0()
  const queryParams = new URLSearchParams(window.location.search)

  useEffect(() => {
    const organizationName = queryParams.get('organization_name')
    const defaultLoginHostname = process.env.REACT_APP_AUTH0_DEFAULT_LOGIN_HOSTNAME
    const defaultWwwLessLoginHostname = process.env.REACT_APP_AUTH0_WWW_LESS_LOGIN_HOSTNAME
    if (window.location.hostname === defaultLoginHostname && window.location.pathname === '/login' && organizationName) {
      window.location.href = `https://${organizationName}.${defaultWwwLessLoginHostname}/login${window.location.search}`
      return null
    }

    loginWithRedirect({
      appState: { ...(!window.location.pathname.includes('/login') && {returnTo: window.location.pathname + window.location.search + window.location.hash })},
      authorizationParams: {
        prompt: 'login',
        ...(queryParams.has('invitation') && { invitation: queryParams.get('invitation') })
      },
    })
  }, [])

  return null
}
