import { Skeleton } from 'primereact/skeleton'
import './FundNzcCardSkeleton.scss'

function FundNzcCardSkeletonV3() {
  return (
    <div className="nzc-chart-skeleton">
      <div className={'fund-dashboard-nzc-card'}>
        <Skeleton width="120px" borderRadius="3px" height="17px" className="mb-2" />
        <div className={'nzc-card-body'}>
          <div className="flex justify-content-around">
            <Skeleton width="0.01rem" borderRadius="3px 3px 0 0" height="240px" />
          </div>
          <Skeleton width="160px" borderRadius="4px" height="15px" className="mb-1" />
          <Skeleton width="160px" borderRadius="4px" height="15px" className="mb-3" />
        </div>
        <div className={'nzc-card-footer'}>
          <Skeleton width="160px" borderRadius="4px" height="15px" className="mb-5" />
          <Skeleton width="160px" borderRadius="4px" height="15px" />
        </div>
      </div>
    </div>
  )
}

export default FundNzcCardSkeletonV3
