import {
  convertToExcelExportFormat,
  formatDateToDDMMYY,
  getValidData,
  UTILITY_MAP_FOR_PERFORMANCE_EXPORT,
  getRequiredUnit,
  UNIT_TYPE_FOR_EXPORT,
} from '../../ExportHelper'
import { UTILITY_LABELS_OBJECT_TITLE_CASE } from '../../../helpers/Constants'
import { carbonEmissionGapHeading, electricityAndGasFuelThermalGapHeading, waterConsumptionGapHeading, 
  energyConsumptionGapHeading, mappedAnnualCurrentConsumption, benchmarkAndStandardBenchmarkData, annualRangeSelection, 
  benchmarkAndStandardBenchmarkTitle, hasCheckConsumptionData, hasCheckCarbonConsumption,
  commonConsumptionGapFieldValue, getConsumptionTableData,
} from './assetPerformanceCommonExportHelper'

export function getAssetTableColumnNamesForAnnualDataV2Export(utility, getNumberOfMonths) {
  const commonTableHeaders = [
    {key: 't_asset_ref'}, {key: 't_name'}, {key: 't_fund'}, { key: 't_country'}, { key: 't_city'},{key: 't_sector'}, {key: 't_gresb_sector'}, { key: 't_purchase_date'},
    {key: 't_sale_date'}, {key: 't_total_asset_floor_area', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.AREA, null, null)},{key: 't_occupancy_excel'},{key: 't_is_the_asset_tenant'},
  ]

  switch (utility) {
  case UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON:
    return [...commonTableHeaders,{key: 't_reporting_period'},
      ...carbonEmissionGapHeading(),
      ...carbonEmissionGapHeading(),
      {key: 't_carbon_intensity_excel', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.CARBON_INTENSITY, null, null)},{key: 't_over_under_target_excel'}]
  case UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY:
    return  [...commonTableHeaders,
      ...electricityAndGasFuelThermalGapHeading(),
      ...electricityAndGasFuelThermalGapHeading(),
      {key: 't_over_under_target_excel'},{key: 't_intensity_excel',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_INTENSITY, null, null)},...benchmarkAndStandardBenchmarkTitle(getNumberOfMonths),
    ]
  case UTILITY_LABELS_OBJECT_TITLE_CASE.WATER:
    return  [...commonTableHeaders,
      ...waterConsumptionGapHeading(), ...waterConsumptionGapHeading(),{key: 't_over_under_target_excel'},
      {key: 't_water_intensity_excel', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.WATER_INTENSITY, null, null)},...benchmarkAndStandardBenchmarkTitle(getNumberOfMonths, true)
    ]

  case UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE:
    return [...commonTableHeaders,{key: 't_reporting_period'},
      {key: 't_hazardous_waste', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},{key: 't_non_hazardous_waste', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},{key: 't_total_waste_generated', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},{key: 't_landfill_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_incinerated', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},{key: 't_reuse_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_waste_to_energy_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},{key: 't_recycled_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},{key: 't_unknown_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)}]
  case UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY:
    return  [...commonTableHeaders,
      ...energyConsumptionGapHeading(),
      ...energyConsumptionGapHeading(),{key: 't_over_under_target_excel'},{key: 't_intensity_excel',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_INTENSITY, null, null)},
    ]

  case UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL:
    return  [...commonTableHeaders,{key: 't_utility_type'},
      ...electricityAndGasFuelThermalGapHeading(),...electricityAndGasFuelThermalGapHeading(),{key: 't_over_under_target_excel'},{key: 't_intensity_excel',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_INTENSITY, null, null)},...benchmarkAndStandardBenchmarkTitle(getNumberOfMonths)]
  }
}

export function getAssetTableRowInfoForAnnualDataV2Export(utility, assetTableData, selectedLanguage, getNumberOfMonths, assetTableColumnNamesWithoutLocalization, intl) {
  function getAssetTableRowData(utilityConsumptionInfo, utility, asset) {
    const commonTableColumnValues = [
      getValidData(asset.assetReference, selectedLanguage, false, false),getValidData(asset.name, selectedLanguage, false, false),
      getValidData(asset.fundName, selectedLanguage, false, false),getValidData(asset.assetLocation, selectedLanguage), getValidData(asset.assetCity, selectedLanguage),
      getValidData(asset.sector, selectedLanguage),getValidData(asset.gresbSector, selectedLanguage),
      formatDateToDDMMYY(asset.assetPurchaseDate, selectedLanguage),formatDateToDDMMYY(asset.assetSaleDate, selectedLanguage),getValidData(asset.floorArea, selectedLanguage),getValidData(asset.occupancy, selectedLanguage),getValidData(asset.isTenantControlled, selectedLanguage),
    ]

    function getCarbonScopeData(){
      if(hasCheckCarbonConsumption(utilityConsumptionInfo, utility)){
        return mappedAnnualCurrentConsumption(null, selectedLanguage, intl, 't_no_export_data_available_for_single_asset')
      }
      return getValidData(utilityConsumptionInfo?.scope1, selectedLanguage)
    }

    switch (utility) {
    case UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON:
      return [...commonTableColumnValues,
        getValidData(utilityConsumptionInfo?.reportingPeriod, selectedLanguage),
        getCarbonScopeData(),getValidData(utilityConsumptionInfo?.scope2, selectedLanguage),getValidData(utilityConsumptionInfo?.scope3, selectedLanguage),getValidData(utilityConsumptionInfo?.consumption, selectedLanguage),getValidData(utilityConsumptionInfo?.actualScope1, selectedLanguage),
        getValidData(utilityConsumptionInfo?.actualScope2, selectedLanguage),getValidData(utilityConsumptionInfo?.actualScope3, selectedLanguage),getValidData(utilityConsumptionInfo?.actualConsumption, selectedLanguage),getValidData(utilityConsumptionInfo?.totalIntensity, selectedLanguage),getValidData(utilityConsumptionInfo?.targetToDelta, selectedLanguage)]

    case UTILITY_LABELS_OBJECT_TITLE_CASE.WATER:
      return [...commonTableColumnValues, getConsumptionTableData(utilityConsumptionInfo, selectedLanguage, intl),
        getValidData(utilityConsumptionInfo?.previousConsumption, selectedLanguage),getValidData(utilityConsumptionInfo?.actualConsumption, selectedLanguage),
        getValidData(utilityConsumptionInfo?.actualPreviousConsumption, selectedLanguage),getValidData(utilityConsumptionInfo?.targetToDelta, selectedLanguage),getValidData(utilityConsumptionInfo?.totalIntensity, selectedLanguage),...benchmarkAndStandardBenchmarkData(utilityConsumptionInfo, selectedLanguage, getNumberOfMonths),
      ]

    case UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE:
      return [...commonTableColumnValues,
        getValidData(utilityConsumptionInfo?.reportingPeriod, selectedLanguage),getValidData(utilityConsumptionInfo?.hazardous, selectedLanguage),getValidData(utilityConsumptionInfo?.nonHazardous, selectedLanguage),
        getValidData(utilityConsumptionInfo?.tonnes, selectedLanguage),getValidData(utilityConsumptionInfo?.landfill, selectedLanguage),
        getValidData(utilityConsumptionInfo?.incinerated, selectedLanguage),getValidData(utilityConsumptionInfo?.reuse, selectedLanguage),
        getValidData(utilityConsumptionInfo?.incineratedWer, selectedLanguage),getValidData(utilityConsumptionInfo?.recycled, selectedLanguage),getValidData(utilityConsumptionInfo?.mrfUnknown, selectedLanguage),]

    case UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY:
      return [...commonTableColumnValues, ...commonConsumptionGapFieldValue(utilityConsumptionInfo, selectedLanguage, intl),
        getValidData(utilityConsumptionInfo?.targetToDelta, selectedLanguage),
        getValidData(utilityConsumptionInfo?.totalIntensity, selectedLanguage),
        ...benchmarkAndStandardBenchmarkData(utilityConsumptionInfo, selectedLanguage, getNumberOfMonths),
      ]

    case UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY:
      return [...commonTableColumnValues, ...commonConsumptionGapFieldValue(utilityConsumptionInfo, selectedLanguage, intl),
        getValidData(utilityConsumptionInfo?.targetToDelta, selectedLanguage),
        getValidData(utilityConsumptionInfo?.intensity, selectedLanguage), 
      ]

    case UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL:
      return [
        ...commonTableColumnValues,
        getValidData(utilityConsumptionInfo.utility, selectedLanguage), 
        ...commonConsumptionGapFieldValue(utilityConsumptionInfo, selectedLanguage, intl),
        getValidData(utilityConsumptionInfo?.targetToDelta, selectedLanguage),
        getValidData(utilityConsumptionInfo?.totalIntensity, selectedLanguage),
        ...benchmarkAndStandardBenchmarkData(utilityConsumptionInfo, selectedLanguage, getNumberOfMonths),
      ]
    }
  }

  // execution starts here...
  let assetTableRows = []
  let assetTableNumberOfRows = 0
  const rangeDataSelection = []
  const consumptionKeys = ['t_current_consumption_with_gapFilled', 't_current_energy_consumption_with_gapFilled_data', 't_current_period_consumption_with_gapFilled_data', 't_scope_1_emissions']
  if (assetTableData)
    assetTableData.forEach((asset) => {
      let utilityConsumptionInfoOfAssetArray = asset[UTILITY_MAP_FOR_PERFORMANCE_EXPORT[utility]]
      if(utilityConsumptionInfoOfAssetArray?.length>0){
        utilityConsumptionInfoOfAssetArray?.forEach(utilityConsumptionInfo => {
          assetTableNumberOfRows+=1
          let assetTableRowData = getAssetTableRowData(utilityConsumptionInfo, utility, asset)
          if(hasCheckConsumptionData(utilityConsumptionInfo) || hasCheckCarbonConsumption(utilityConsumptionInfo, utility)){
            const getRangeSelection = annualRangeSelection(utilityConsumptionInfo.consumption, assetTableRows.length, assetTableColumnNamesWithoutLocalization, consumptionKeys)
            if(getRangeSelection){
              rangeDataSelection.push(getRangeSelection)
            }
          }
          assetTableRows.push(convertToExcelExportFormat(assetTableRowData))
        })
      }
    })
  return {assetTableRows,assetTableNumberOfRows, rangeDataSelection}
}

