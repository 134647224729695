import {useEffect, useState, useMemo, memo, useContext} from 'react'
import PropTypes from 'prop-types'
import { Chart } from 'primereact/chart'
import {useQuery} from 'react-query'
import I18n from '../../../../../utils/i18n/I18n'
import {unitsConverter} from '../../../../../utils/helpers/Helper'
import FundNzcCardSkeleton from '../../../skeletons/fund-nzc-card-skeleton/FundNzcCardSkeleton'
import {CARBON, AREA, weightedAverageCarbonIntensity} from '../../../../../utils/helpers/Constants'
import {GetNZCSettings} from '../../../../../services/funds/nzc-service'
import {noRefetchOnWindowFocus} from '../../../../../services/common/useQuery-config'
import {getAggregatedFundPerformance} from '../../../../pages/funds/net-zero-carbon-home/nzc-utils/nzc-utils'
import {UNITS} from '../../../../../utils/i18n/constants'
import './NetZeroCarbon.scss'
import {
  chartDefaultBaseYears,
  createNZCGraphDataSets,
  getAssetsWithCurrentYear,
  getNzcChartOptions,
  getNzcChartPlugins
} from './netZeroCarbonHelper'
import {getYearForFinancialDate} from '../../../net-zero-carbon/nzc-common-utils'
import { FeatureFlagContext } from '../../../../../Contexts'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const NetZeroCarbon = ({ nzcScopeId, selectedLanguage, isLoading, targetPathways, assetLevelFlag, isDependentLoading = false, financialYearStart}) => {

  const featureContext            = useContext(FeatureFlagContext)
  const featureState              = featureContext.featureState
  const [nzcGraphData, setNZCGraphData] = useState()
  const [strandingYear, setStrandingYear] = useState('')

  const getNzcSettingsData = useQuery(['GetNZCSettings', nzcScopeId], GetNZCSettings, noRefetchOnWindowFocus)
  const nzcBaseLineYear = getNzcSettingsData?.data?.baselineYear

  const {loginState: {userInfo}} = useLoginAuthContext()
  const unitSystem = userInfo.unitSystem
  const FT2_PER_M2=10.764
  const reportingYear = featureState.sieraplus_nzc_reportingYear ? getYearForFinancialDate(financialYearStart) : new Date().getFullYear() - 1
  const solidLineYear = targetPathways?.graphData?.filter((graphDataForEachYear) => graphDataForEachYear.isActualConsumptionData).reverse()[0]?.year

  useEffect(() => {
    let datasets = createNZCGraphDataSets(targetPathways, selectedLanguage, solidLineYear, setStrandingYear, nzcBaseLineYear)
    if (datasets){
      setNZCGraphData({ labels: chartDefaultBaseYears.map(x => (x % 2 === 0 || x === nzcBaseLineYear) ? x : null), datasets })
    }
  }, [nzcScopeId, targetPathways, nzcBaseLineYear])

  const fundAssetsWithCurrentYear = useMemo(() => {
    return getAssetsWithCurrentYear(targetPathways, reportingYear)
  }, [targetPathways?.assetPerformance, targetPathways?.assetGraphData, reportingYear])

  function getConvertedFundPerformance() {
    let convertedFundPerformance=getAggregatedFundPerformance(fundAssetsWithCurrentYear, weightedAverageCarbonIntensity, reportingYear)
    if(unitSystem === UNITS.IMPERIAL){
      convertedFundPerformance=convertedFundPerformance*FT2_PER_M2
    }
    return convertedFundPerformance
  }

  function fetchGraphChart(){
    return (
      <>
        <Chart
          height='360px'
          width='100%'
          data={nzcGraphData}
          options={getNzcChartOptions(assetLevelFlag, nzcBaseLineYear)}
          plugins={ getNzcChartPlugins(assetLevelFlag, nzcBaseLineYear)}
        />
      </>
    )
  }

  function fetchGraphFooter(){
    return (
      <>
        <div className={'footer-label'} data-testid="footer-label">
          {I18n('t_average_carbon_intensity')}
        </div>
        <div className={'footer-intensity'} data-testid="averageValue">
          {Math.round(getConvertedFundPerformance() ?? 0).toLocaleString(selectedLanguage, {maximumFractionDigits: 2 })} <label className={'units'}>{unitsConverter(CARBON, unitSystem)+'/'+unitsConverter(AREA, unitSystem)+'/yr'}</label>
        </div>
        <div data-testid="previousYear" className={'footer-baseline'}>
          {I18n('t_reporting_year')}: {reportingYear}
        </div>
      </>
    )
  }
  return (
    <>
      {isLoading || isDependentLoading || !targetPathways ?
        <FundNzcCardSkeleton /> :
        <>
          <div data-testid="NZC-fund-graph" className={'fund-dashboard-nzc-card'}>
            <div className={'nzc-card-header'}>
              <div className={'header-label'}>
                {I18n('t_bau_stranding')}
              </div>
              <div className={'header-year'}>
                {strandingYear}
              </div>
            </div>
            <div className={'nzc-card-body'}>
              <div className={'nzc-chart'}>
                {fetchGraphChart()}
              </div>
            </div>
            <div className={'nzc-card-footer'}>
              {fetchGraphFooter()}
            </div>
          </div>
        </>
      }
    </>
  )
}

NetZeroCarbon.propTypes = {
  selectedLanguage: PropTypes.string,
  nzcScopeId: PropTypes.string,
  isLoading: PropTypes.bool,
  targetPathways: PropTypes.object,
  isDependentLoading: PropTypes.bool,
  financialYearStart: PropTypes.string,
  assetLevelFlag: PropTypes.bool
}

export default memo(NetZeroCarbon)
