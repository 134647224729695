import React from 'react'
import {useQueries} from 'react-query'
import {noRefetchOnWindowFocus} from '../../../../../services/common/useQuery-config'
import {actionData, activeSurveyAsset} from '../../../../../services/assets/actionplan-service'
import {QueryKeyPrefix} from '../../../../../utils/helpers/Constants'

const useAssetActionsAndActiveSurveyData = (assetId) => {
  const queryConfig={
    ...noRefetchOnWindowFocus,
    staleTime: 999999,
    enabled: !!assetId
  }

  const [{data: actionsData, refetch: actionsDataRefetch},{data: assetHasActiveSurvey}] = useQueries([
    {
      queryKey: [QueryKeyPrefix.ASSET_ACTIONS, assetId],
      queryFn: actionData,
      ...queryConfig
    },
    {
      queryKey: [QueryKeyPrefix.ASSET_ACTIVE_SURVEY, assetId],
      queryFn: activeSurveyAsset,
      ...queryConfig
    }
  ])
  return {actionsData, actionsDataRefetch, assetHasActiveSurvey}
}
export default useAssetActionsAndActiveSurveyData