
import PropTypes from 'prop-types'
import './DashboardCardsGridV2.scss'
import DashboardCompletenessChart from '../../../../../common/charts/dashboard-dq/DashboardCompletenessChart'
import DashboardCoverageChart from '../../../../../common/charts/dashboard-dq/DashboardCoverageChart'
import { NavLink, useParams } from 'react-router-dom'
import { createNavURLForFundPages } from 'utils/helpers/Helper'
import { FUND_VIEWS_PAGES } from 'utils/helpers/Constants'

const DashboardCardsGridV2 = ({
  completenessData, 
  completenessDataLoading, 
  coverageData, 
  coverageDataLoading, 
  timePeriod, 
  pickerValue,
  selectedLanguage, 
  areaUnit
}) => {
  const { instanceName, orgName, fundName, assetId } = useParams()

  return (
    <div className="db-cards-grid flex">
      {coverageData && <div className="db-cards-grid__card-coverage">
        <NavLink key={'nav-link-dq'} exact={true} to={{
          pathname: createNavURLForFundPages({
            instanceName,
            orgName,
            fundName,
            assetId,
            pathSuffix: FUND_VIEWS_PAGES.dataQuality
          })
        }} className="cursor">
          <DashboardCoverageChart
            coverageData={coverageData}
            selectedLanguage={selectedLanguage}
            areaUnit={areaUnit}
            assetDashboard
            isLoading={coverageDataLoading}
          />
        </NavLink>
      </div>
      }
      {completenessData && <div className="db-cards-grid__card-completeness">
        <NavLink key={'nav-link-dq'} exact={true} to={{
          pathname: createNavURLForFundPages({
            instanceName,
            orgName,
            fundName,
            assetId,
            pathSuffix: FUND_VIEWS_PAGES.dataQuality
          })
        }} className="cursor">
          <DashboardCompletenessChart
            completenessData={completenessData}
            timePeriod={timePeriod}
            pickerValue={pickerValue}
            selectedLanguage={selectedLanguage}
            assetDashboard
            isLoading={completenessDataLoading}
          />
        </NavLink>
      </div>
      }
    </div>
  )
}

DashboardCardsGridV2.propTypes = {
  completenessData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  completenessDataLoading: PropTypes.bool,
  coverageData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  coverageDataLoading: PropTypes.bool,
  timePeriod: PropTypes.object,
  pickerValue: PropTypes.object,
  selectedLanguage: PropTypes.string,
  areaUnit: PropTypes.string
}

DashboardCardsGridV2.displayName = 'DashboardCardsGridV2'

export default DashboardCardsGridV2