import React, {useContext, useEffect, useMemo, useRef, useState} from 'react'
import './AssetPathway.scss'
import {FeatureFlagContext} from 'Contexts'
import {QueryKeyPrefix, SBT_VERSIONS, SCOPE_TARGET_MODE} from '../../../../utils/helpers/Constants'
import PopupDialog from '../../../common/modal-dialogs/PopupDialog'
import {OverlayPanel} from 'primereact/overlaypanel'
import questionMarkIcon from '../../../../resources/images/icon/question-mark.svg'
import plusIcon from '../../../../resources/images/icon/plus.svg'
import {Panel} from 'primereact/panel'
import ActionBasedTargetPathways from './ActionBaseTargetPathways'
import AddOrEditCustomTP from './AddOrEditCustomTP'
import CustomTPViews from './CustomTPViews'
import {InputNumber} from 'primereact/inputnumber'
import {Button} from 'primereact/button'
import I18n from '../../../../utils/i18n/I18n'
import ErrorDialog from '../data-quality/error-dialog/ErrorDialog'
import {useMutation, useQueryClient} from 'react-query'
import {CreateTargetPathway, DeleteScopeTarget, UpdateNZCConfigTargetPathways} from '../../../../services/funds/nzc-service'
import {  getDropdownItemTemplate,  getLocalizedValue,  SessionUserState,  removeRequiredSessionStorageCache,  getQueryValuesForNZCAssets,  targetPathwayFormObject,  refetchUpdatedDataByInvalidatingQueries} from '../../../../utils/helpers/Helper'
import {getCustomTPNameErrorState, getScopePathwayTotal, getScopeTPName, onBlurAction, preventEvent, tpIconEditAction, updateIsEditStateValue} from '../net-zero-carbon/asset-nzc-utils/asset-nzc-utils'
import {  applyNoEnergySavingsActionFilter, getActionCheckboxStatus,  getCustomTPNames,  getDefaultTPName,  getTPNumber,
  getTPNumberForCreate,  isCreateOrEditAction,  isScopeTargetsNotEmpty,  newSettingsChangesExist} from '../../../common/net-zero-carbon/nzc-common-utils'
import {STRANDING_YEAR_TP1, STRANDING_YEAR_TP2, STRANDING_YEAR_TP3, YEAR_2030} from '../../../common/net-zero-carbon/nzc-constants'
import AssetNzcTargetPathwaySkeleton from '../../../common/skeletons/asset-nzc/AssetNzcTargetPathwaySkeleton'
import {noDataAvailableMessage} from '../../../../utils/helpers/MessageUtility'
import PropTypes from 'prop-types'
import AssetSBT from './AssetSBT'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const AssetTPComponent = (props) => {

  const nzcRedirectPopupProps=props.nzcRedirectPopupProps
  const nzcSettingsData=props?.nzcSettingsData
  const cssClassHideTab='hide-a-tab'
  const targetFormItemsRef=useRef({})
  const actionBasedTargetPathwayOP = useRef(null)
  const scienceBasedTargetPathwayOP = useRef(null)
  const customBasedTargetPathwayOP = useRef(null)

  const [targetPathwayDetailsObj,setTargetPathwayDetailsObj] = useState({})
  const [errorDialogVisible,setErrorDialogVisible] = useState(false)
  const [errorDialogData,setErrorDialogData] = useState()
  const buttonRef = useRef(null)
  const disabledRef = useRef(null)
  const addTargetPathWayMutation = useMutation(CreateTargetPathway)
  const deleteScopeTargetMutation = useMutation(DeleteScopeTarget)
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const placeholderString = ''
  const scopeId = props?.nzcScopeId
  let targetPathwayNumbersArray = [...new Set(props?.targetPathwayData?.scopeTargets.map((item) => item.targetPathwayNumber))].sort().filter((number) => number !== 0)
  const [targetPathwayFormState, setTargetPathwayFormState]=useState(targetPathwayFormObject(placeholderString))
  const [scienceBasedTargetOption, setScienceBasedTargetOption] = useState(props?.nzcSettingsData?.scienceBasedTarget)
  const [targetPathwayEditStatus,setTargetPathwayEditStatus] = useState({targetPathwayPanelEdited : false, customTargetPathwayEdited : false})
  const updateNzcSettingsMutation = useMutation(UpdateNZCConfigTargetPathways)
  const [deletingTPTabIndex,setDeletingTPTabIndex]= useState(null)
  let newCreatedOrEditedTPNumber= useRef(null)
  const featurecontext = useContext(FeatureFlagContext)
  const featurestate = featurecontext.featureState
  const assetCountWithNoAgreedActions = props?.actionBasedTPDetails?.totalAssets - props?.actionBasedTPDetails?.assetCountOfAssetsWithAgreedActions
  const [inputTextTouched,setInputTextTouched] = useState(false)

  const [customTargetPathwayName,setCustomTPName] = useState(null)
  const actionBasedPathwayId = 'action-based-title-icon'
  const scienceBasedPathwayId = 'science-based-target'
  const customBasedPathwayId = 'custom-target-pathways-title-icon'
  const validateTotalReductionsRef=useRef(false)
  const progressTowardsTargetOptions = [2025, 2030, 2035, 2040, 2045, 2050]
  const targetPathWayData = useRef(null)
  const previousActiveIndex = useRef(null)
  const settingsChangesExist=useMemo(()=>newSettingsChangesExist(targetPathwayDetailsObj, props.nzcSettingsData, customTargetPathwayName, targetPathwayFormState?.selectedScopeTPData, targetPathWayData), [targetPathwayDetailsObj, props.nzcSettingsData, nzcRedirectPopupProps.apiError, customTargetPathwayName, targetPathwayFormState])
  const queryClient = useQueryClient()


  useEffect(()=>{
    const settingsData=props?.nzcSettingsData
    if(settingsData){
      setScienceBasedTargetOption(settingsData.scienceBasedTarget)
    }
  }, [props?.nzcSettingsData])

  useEffect( ()=>{
    const initialTargetPathwayDetailsObj=getInitialTargetPathwayDetailsObj()
    updateTargetPathwayDetailsObj(initialTargetPathwayDetailsObj)
    checkAndUpdateActionBasedPathway()
  }, [nzcSettingsData, props?.actionBasedTPDetails])


  useEffect(()=>{
    cancelTargetPathwayViewDetailsUpdate() // reset state on component exit
  },[])

  useEffect(() => {
    if(nzcRedirectPopupProps.apiError){
      setTargetPathwayEditStatus(prevState => {return{
        ...prevState, targetPathwayPanelEdited: false, customTargetPathwayEdited : false
      }})
    }
  }, [nzcRedirectPopupProps.apiError])


  useEffect(()=>{
    updateIsEditStateValue(setTargetPathwayEditStatus, targetPathwayDetailsObj, nzcSettingsData)
    props.updateTargetsAndPathwayDetails(targetPathwayDetailsObj)
    getScopeTargetState()
  },[targetPathwayDetailsObj, targetPathwayFormState[`scopeTP${props.scopeTargetState.activeIndex+1}`]])

  useEffect(()=>{
    updateNZCTPData()
  }, [props.targetPathwayData])

  const checkAndUpdateActionBasedPathway = () => {
    const checkIfActionBasedPathwayGotUpdated = async () => {
      let agreedActionCheckboxStatus = getActionCheckboxStatus(nzcSettingsData?.agreedActions, props?.actionBasedTPDetails?.agreedActions,props?.actionBasedTPDetails, true)
      let applicableActionCheckboxStatus = getActionCheckboxStatus(nzcSettingsData?.applicableActions, props?.actionBasedTPDetails?.applicableActions,props?.actionBasedTPDetails, false)
      if ((nzcSettingsData?.agreedActions !== agreedActionCheckboxStatus) || (nzcSettingsData?.applicableActions !== applicableActionCheckboxStatus)) {
        let updatedActionBasedPathwayCheckboxSelections = {
          energySavingActions: (agreedActionCheckboxStatus || applicableActionCheckboxStatus) && nzcSettingsData?.energySavingActions,
          agreedActions: agreedActionCheckboxStatus && nzcSettingsData?.energySavingActions,
          applicableActions: applicableActionCheckboxStatus && nzcSettingsData?.energySavingActions
        }
        updateTargetPathwayDetailsObj(updatedActionBasedPathwayCheckboxSelections)
      }
    }

    nzcSettingsData && props?.actionBasedTPDetails && checkIfActionBasedPathwayGotUpdated()
  }
  function getScopeTargetState() {
    if (props.scopeTargetState.tpMode !== SCOPE_TARGET_MODE.edit) {
      updateTargetPathwayFormState({selectedScopeTPData:[...getNewTPDataObject()]})
      targetPathWayData.current = [...getNewTPDataObject()]
    } else {
      if (targetPathwayFormState[`scopeTP${props.scopeTargetState.activeIndex+1}`]) {
        let selectedTPData=targetPathwayFormState[`scopeTP${props.scopeTargetState.activeIndex+1}`]
        selectedTPData=selectedTPData.map(filterAndCreateScopeData)
        updateTargetPathwayFormState({selectedScopeTPData:[...selectedTPData]})
      }
    }
  }

  function getInitialTargetPathwayDetailsObj() {
    let selectedTPFromBackend = nzcSettingsData?.targetPathway !== null ? `tp${nzcSettingsData?.targetPathway}` : null
    return  {
      energySavingActions: nzcSettingsData?.energySavingActions,
      agreedActions: nzcSettingsData?.agreedActions,
      applicableActions: nzcSettingsData?.applicableActions,
      scienceBasedTargetOption: nzcSettingsData?.scienceBasedTarget,
      progressTowardsTargetYear: nzcSettingsData?.progressTargetYear,
      showProgressTowardsTargetYear: nzcSettingsData?.showTargetProgress,
      selectedTP: isCreateOrEditMode() ? (`tp${getAssetTPNumber()}`) :selectedTPFromBackend,
      showTopDownPathways: nzcSettingsData?.showTopDownPathways,
    }
  }

  function getAssetTPNumber() {
    return getTPNumber(props?.scopeTargetState, targetPathwayFormState)
  }

  const customTargetPathwayNameError = useMemo(() => {
    return getCustomTPNameErrorState(customTargetPathwayName, getAssetTPNumber(), selectedLanguage, props?.nzcSettingsData, inputTextTouched)
  }, [customTargetPathwayName, inputTextTouched, nzcSettingsData?.targetPathwayNames])

  function updateTargetPathwayDetailsObj(newState) {
    setTargetPathwayDetailsObj(prevState=>{
      return {
        ...prevState,
        ...newState
      }
    })
  }

  const getAssetCustomTPNames = (t1Data, t2Data, t3Data) => {
    const targetPathwayNames=props?.nzcSettingsData?.targetPathwayNames
    let customTPNames
    customTPNames = targetPathwayNames && JSON.parse(targetPathwayNames)
    let tpNames = getCustomTPNames(t1Data, t2Data, t3Data, customTPNames, selectedLanguage)
    setCustomTPName(tpNames)
  }

  const getStrandingYear = (targetPathwayNumber) => {
    return Math.round(props?.targetPathwayData.strandings.filter(strandingDetails => targetPathwayNumber === strandingDetails.strandingOf)[0]?.year)
  }

  const updateNZCTPData = () => {
    let t1Data
    let t2Data
    let t3Data
    const targetPathwayData = props.targetPathwayData
    if (targetPathwayData && targetPathwayData.scopeTargets) {
      targetPathwayData.scopeTargets.sort((a, b) => a.targetYear - b.targetYear)
      const scopeTargets = targetPathwayData.scopeTargets.filter(scopeTarget => scopeTarget.targetYear >= props?.reportingYear + 1)
      t2Data = scopeTargets.filter(scopeTP=> 2 === scopeTP.targetPathwayNumber).map(filterAndCreateScopeData)
      t3Data = scopeTargets.filter(scopeTP=> 3 === scopeTP.targetPathwayNumber).map(filterAndCreateScopeData)
      t1Data = scopeTargets.filter(scopeTP=> 1 === scopeTP.targetPathwayNumber).map(filterAndCreateScopeData)
      updateTargetPathwayFormState(
        {
          strandingYearTP2: getStrandingYear(STRANDING_YEAR_TP2),
          strandingYearTP3: getStrandingYear(STRANDING_YEAR_TP3),
          strandingYearTP1: getStrandingYear(STRANDING_YEAR_TP1),
          scopeId: targetPathwayData.scopeId,
          scopeTP1: t1Data.length > 0 ? t1Data : null,
          scopeTP2: t2Data.length > 0 ? t2Data : null,
          scopeTP3: t3Data.length > 0 ? t3Data : null,
        })
      getAssetCustomTPNames(t1Data,t2Data,t3Data)
    }
  }

  const updateTargetPathwayFormState = (newTargetPathwayFormState) => {
    setTargetPathwayFormState(prevTargetPathwayFormState => {
      return {
        ...prevTargetPathwayFormState,
        ...newTargetPathwayFormState
      }
    })
  }

  const isReductionDataNotEmpty = () => {
    return !!(targetPathwayFormState.selectedScopeTPData)
  }

  const filterAndCreateScopeData = (scopeData) => {
    if (scopeData.percentage === null || scopeData.percentage === undefined) {
      scopeData.percentage = 0
    }
    return { ...scopeData }
  }

  const createTargetPathway = async () => {
    let errorKey = 't_title_cannot_create_target_pathway'
    let selectedFormScopeData = targetPathwayFormState.selectedScopeTPData
    let showTargetProgress = false
    let progressTargetYear = YEAR_2030
    if (selectedFormScopeData){
      selectedFormScopeData = selectedFormScopeData.map(filterAndCreateScopeData)
    }

    if(props?.scopeTargetState.tpMode === SCOPE_TARGET_MODE.edit && getAssetTPNumber()-1 === previousActiveIndex.current){
      showTargetProgress = props?.nzcSettingsData?.showTargetProgress
      progressTargetYear = props?.nzcSettingsData?.progressTargetYear
    }
    const tpRequestObject={targetPathwayData: [{'nzcTargetPlanTargetData': selectedFormScopeData, 'targetPathwayNames': customTargetPathwayName,showTargetProgress,progressTargetYear}], nzcScopeId: props.nzcScopeId, intensity: props.assetGraphIntensity}
    addTargetPathWayMutation.mutateAsync(tpRequestObject).then(newTargetCreated => {
      if (newTargetCreated) {
        props.refetchTargetPathwayData().then(() => {
          newCreatedOrEditedTPNumber.current = getAssetTPNumber()
          setInputTextTouched(false)
          updateScopeTargetMode({
            tpMode:  SCOPE_TARGET_MODE.view,
            selectedTPName: `tp${getAssetTPNumber()}`,
            activeIndex: getAssetTPNumber()-1,
          })
          updateTargetPathwayDetailsObj({ selectedTP:`tp${getAssetTPNumber()}`, progressTowardsTargetYear: progressTargetYear, showProgressTowardsTargetYear: showTargetProgress })
        })
        props.refetchModelSettings()
        props.updateTargetsAndPathwayDetails({selectedTP:`tp${getAssetTPNumber()}`})
      }
    }).catch(error => {
      const errorValue = (error?.response?.data) ? `Error: ${error?.response?.data?.title}` : error.toString()
      setErrorDialogData({title: I18n(errorKey), message: errorValue})
      setErrorDialogVisible(true)
    }).finally(()=>{
      resetTabHide()
    })
  }

  function updateSettingsForDeletedTP() {
    const settings = {
      ...getSettingsState(),
      progressTargetYear: YEAR_2030,
      baseLineYear: props?.baselineYear,
      showTargetProgress: false
    }
    updateNzcSettingsMutation.mutateAsync({scopeId, settings, graphIntensity: props.assetGraphIntensity}).then(() => {
      props.refetchModelSettings()
      props.refetchTargetPathwayData()
    })
  }

  async function deleteScope(targetPathwayNumber) {
    let errorKey = 't_title_cannot_create_target_pathway'
    updateTargetPathwayFormState({showConfirmModal: false})
    deleteScopeTargetMutation.mutateAsync({scopeId: targetPathwayFormState.scopeId, targetPathwayNumber, intensity: props.assetGraphIntensity}).then(()=>{
      const selectedTP=getScopeTPName(targetPathwayNumber - 1)
      updateScopeTargetActions(SCOPE_TARGET_MODE.delete, targetPathwayNumber - 1, selectedTP)
      props.refetchTargetPathwayData().finally(()=>{
        updateTargetPathwayFormState({tabExpanded: false, tab1Class: placeholderString,tab2Class:placeholderString, tab3Class:placeholderString})
      })
      updateSettingsForDeletedTP()
    }).catch((error)=>{
      const errorValue = (error?.response?.data) ? `Error: ${error?.response?.data?.title}` : error.toString()
      setErrorDialogData({title: I18n(errorKey), message: errorValue})
      setErrorDialogVisible(true)
      props.refetchTargetPathwayData()
    })
    let selectedTP, showProgressTowardsTargetYear, progressTowardsTargetYear
    if (props.nzcSettingsData.targetPathway - 1 === deletingTPTabIndex) {
      selectedTP = null
      showProgressTowardsTargetYear = false
      progressTowardsTargetYear = YEAR_2030
    } else {
      selectedTP = targetPathwayDetailsObj.selectedTP
      showProgressTowardsTargetYear = targetPathwayDetailsObj.showProgressTowardsTargetYear
      progressTowardsTargetYear = targetPathwayDetailsObj.progressTowardsTargetYear
    }
    updateTargetPathwayDetailsObj({ selectedTP, showProgressTowardsTargetYear, progressTowardsTargetYear})
    props.updateTargetsAndPathwayDetails({
      selectedTP: selectedTP,
      showProgressTowardsTargetYear: showProgressTowardsTargetYear,
      progressTowardsTargetYear: progressTowardsTargetYear
    })
    props.refetchModelSettings()
  }

  const onSubmit = async () => {
    if (buttonRef?.current) {
      let classList = buttonRef.current.classList.value
      classList = `${classList} button-disabled`
      buttonRef.current.classList = classList
    }
    setDeletingTPTabIndex(null)
    await createTargetPathway()
  }

  function resetTabHide() {
    updateTargetPathwayFormState({
      tab1Class: '',
      tab2Class: '',
      tab3Class: '',
      tabExpanded: false
    })
  }

  function resetScopeTargetState() {
    updateScopeTargetMode({
      tpMode: props?.nzcSettingsData?.targetPathway ? SCOPE_TARGET_MODE.view : null,
      selectedTPName: props?.nzcSettingsData?.targetPathway ? `tp${Number(props?.nzcSettingsData?.targetPathway)}` : null,
      activeIndex: props?.nzcSettingsData?.targetPathway ? Number(props?.nzcSettingsData?.targetPathway) - 1 : null
    })
  }

  function cancelHandler() {
    resetTabHide()
    resetScopeTargetState()
    setInputTextTouched(false)
    updateNZCTPData()
    let revertedTPDetails = {
      showProgressTowardsTargetYear: props?.nzcSettingsData?.showTargetProgress,
      progressTowardsTargetYear: props?.nzcSettingsData?.progressTargetYear,
      selectedTP: props?.nzcSettingsData?.targetPathway !== null ? `tp${props?.nzcSettingsData?.targetPathway}` : null,
    }
    updateTargetPathwayDetailsObj(revertedTPDetails)
    props.updateTargetsAndPathwayDetails(revertedTPDetails)
    updateIsEditStateValue(setTargetPathwayEditStatus, targetPathwayDetailsObj, nzcSettingsData)
  }

  function updateValidateTotalReductionState(state){
    validateTotalReductionsRef.current = state
  }

  function onChange(event, selectedScopeTPData, scopeTPIndex, selectedScopeTP){
    if (selectedScopeTPData) {
      selectedScopeTP.percentage = event.value
      selectedScopeTPData[scopeTPIndex] = selectedScopeTP
      updateTargetPathwayFormState({ selectedScopeTPData: selectedScopeTPData })
    }
    updateValidateTotalReductionState(false)
    setTargetPathwayEditStatus(prevState => {return{
      ...prevState, customTargetPathwayEdited : true
    }})
  }

  function onBlur (blurEvent, selectedScopeTPData, inputFieldName, selectedScopeTP, scopeTPIndex) {
    const scopeParams={selectedScopeTPData, inputFieldName, selectedScopeTP, scopeTPIndex}
    onBlurAction(blurEvent, scopeParams , targetFormItemsRef, updateValidateTotalReductionState, updateTargetPathwayFormState, disabledRef)
  }

  function inputFieldDisable(selectedScopeTP,selectedScopeTPData) {
    let inputFieldValue = selectedScopeTP.percentage
    let totalReductions = getScopePathwayTotal(selectedScopeTPData)
    return (!inputFieldValue && totalReductions >= 100 && validateTotalReductionsRef.current)
  }

  function getBorderStyle (selectedScopeTP,selectedScopeTPData) {
    let borderStyle = 'input-border'
    if (inputFieldDisable(selectedScopeTP,selectedScopeTPData)) {
      borderStyle = 'input-error-border'
    }
    return borderStyle
  }

  function getCumulativeReductionField(selectedScopePTData, endYear) {
    const initial = 0
    let cumulativeSumValue = initial
    const [fieldVal] = selectedScopePTData.filter(scopeTP=>scopeTP.targetYear === endYear)
    if(fieldVal?.percentage){
      cumulativeSumValue = (selectedScopePTData?.filter(scopeTP => scopeTP.targetYear <= endYear).map(scopeTP => scopeTP.percentage)
        .reduce((previousValue, currentValue) => previousValue + currentValue, initial))
    }
    return cumulativeSumValue
  }

  function getInputFieldProps(selectedScopeTPData, fieldname, selectedScopeTP, scopeTPIndex) {
    return {
      name: fieldname,
      maxFractionDigits: 1,
      key: `reduction-${fieldname}`,
      className: `reduction-input-number ${getBorderStyle(selectedScopeTP, selectedScopeTPData)}`,
      mode: 'decimal',
      suffix: '%',
      onBlur: (e) => onBlur(e, selectedScopeTPData, fieldname, selectedScopeTP, scopeTPIndex),
      onValueChange: (e) => onChange(e, selectedScopeTPData, scopeTPIndex, selectedScopeTP),
      onChange: (e) => onChange(e, selectedScopeTPData, scopeTPIndex, selectedScopeTP),
      min: 0,
      max: 100,
      disabled: inputFieldDisable(selectedScopeTP, selectedScopeTPData),
      value: selectedScopeTP.percentage,
    }
  }

  function getDisabledInputFieldProps(className, value, name, inputFieldId) {
    return {
      min: 0,
      max: 100,
      value: value,
      disabled: true,
      id: inputFieldId,
      name: name,
      key: `cumulative-${name}`,
      className: className,
      mode: 'decimal',
      maxFractionDigits: 1,
      suffix: '%'
    }
  }

  const isCreateOrEditMode = () => {
    return isCreateOrEditAction(props?.scopeTargetState)
  }

  function getFieldElements(selectScopeData, startYear, index, yearIncrement) {
    let currentData=selectScopeData[index]
    let inputField = currentData?.targetYear
    let targetYearEnd = parseInt((startYear).toString().slice(2))
    return(
      <div className={'target-input-container'} key={`target-input-container-${inputField}`}>
        <div className={'input-year'} key={`input-year-${inputField}`}>{`${startYear} - ${targetYearEnd+yearIncrement}`}</div>
        <div className={'input-reduction'} key={`input-reduction-${inputField}`}>
          {isCreateOrEditMode()? <InputNumber {...getInputFieldProps(selectScopeData, inputField, currentData, index)} />:
            <InputNumber {...getDisabledInputFieldProps('reduction-value input-number-disabled', currentData.percentage, inputField, `reduction-input-${inputField}`)} />
          }
        </div>
        <OverlayPanel  className="info-cumulative" ref={disabledRef} showCloseIcon style={{width: '14em'}}>
          <div className="help-block">{getLocalizedValue(selectedLanguage, 't_warning')}</div>
          <div className="help-text">{getLocalizedValue(selectedLanguage, 't_target_warning')}</div>
        </OverlayPanel>
        <div className={'input-cumulative'} key={`input-cumulative-${inputField}`}>
          <InputNumber {...getDisabledInputFieldProps('input-number-cumulative input-number-disabled', getCumulativeReductionField(selectScopeData, startYear), inputField, `cumulative-input-${inputField}`)} />
        </div>
      </div>
    )
  }

  const addFieldsForMilestoneYears = (inputField, selectScope, yearStart, i) => {
    const milestoneIncrement = 5
    inputField.push(<div className={'milestone-years'}>{I18n('t_milestone_years')}</div>)
    inputField.push(getFieldElements(selectScope, yearStart, i, milestoneIncrement))
    yearStart += milestoneIncrement
    i++
    inputField.push(getFieldElements(selectScope, yearStart, i, milestoneIncrement))
    yearStart += milestoneIncrement
    i++
    inputField.push(getFieldElements(selectScope, yearStart, i, 10))
    return inputField
  }

  function getTPDataTab(selectScopeData) {
    let inputField = []
    if(selectScopeData && selectScopeData.length>0){
      let i = 0
      let yearStart = selectScopeData[i].targetYear
      const yearEnd = getTPEndYear(yearStart)
      const incrementYear=1
      while (yearStart <= yearEnd) {
        inputField.push(getFieldElements(selectScopeData, yearStart, i, incrementYear))
        yearStart++
        i++
      }
      // For milestone years
      inputField=addFieldsForMilestoneYears(inputField, selectScopeData, yearStart, i)
    }
    return inputField
  }

  function closeHandler() {
    setErrorDialogVisible(false)
  }

  function getFormContentViewElements(selectedScopePathwayData) {
    const tpNumber=getAssetTPNumber()
    if (tpNumber && customTargetPathwayName && customTargetPathwayName[`tp${tpNumber}`] === undefined) {
      let defaultTPName = getDefaultTPName(tpNumber, selectedLanguage)
      let updatedTPNameObject = customTargetPathwayName
      updatedTPNameObject[`tp${tpNumber}`] = defaultTPName
      setCustomTPName({...updatedTPNameObject})
    }

    function handleTPCustomNameInput(e,tabIndex) {
      e.preventDefault()
      let updatedTPName = customTargetPathwayName
      updatedTPName[`tp${tabIndex}`] = e.target.value
      setCustomTPName({...updatedTPName})
      setTargetPathwayEditStatus(prevState => {return{
        ...prevState, customTargetPathwayEdited : true
      }})

    }

    return (
      <AddOrEditCustomTP
        isCreateOrEditMode={isCreateOrEditMode}
        customTPNameError={customTargetPathwayNameError}
        customTargetPathwayName={customTargetPathwayName}
        handleTPCustomNameInput={handleTPCustomNameInput}
        setInputTextTouched={setInputTextTouched}
        getTPDataTable={getTPDataTab}
        selectedScopePathwayData={selectedScopePathwayData}
        targetFormItemsRef={targetFormItemsRef}
        buttonRef={buttonRef}
        onSubmit={onSubmit}
        isReductionDataNotEmpty={isReductionDataNotEmpty}
        cancelHandler={cancelHandler}
        tpNumber={getAssetTPNumber()}
        targetPathwayEditStatus={targetPathwayEditStatus}
      />
    )
  }

  function addAnotherTargetHandler() {
    updateValidateTotalReductionState(false)
    props.updateScopeTargetState({tpMode:SCOPE_TARGET_MODE.create})
    updateTargetPathwayFormState({selectedScopeTPData:[...getNewTPDataObject()]})
    setTargetPathwayEditStatus(prevState => {return{
      ...prevState, customTargetPathwayEdited : true
    }})
    targetPathWayData.current = [...getNewTPDataObject()]
  }

  function updateScopeTargetMode(newScopeTargetMode) {
    props.updateScopeTargetState(newScopeTargetMode)
  }

  const updateStateForTarget = (prevTpMode, prevIndex, newTPAction, tabIndex, selectedTP, isDeleteAction) => {
    if(isDeleteAction){
      props.updateScopeTargetState({
        tpMode: prevIndex !== tabIndex ? prevTpMode : null,
        selectedTPName:prevIndex !== tabIndex ?  `tp${prevIndex+1}`: null,
        activeIndex: prevIndex !== tabIndex ? prevIndex : null
      })
    }
    else{
      props.updateScopeTargetState({
        tpMode: (!(prevTpMode === newTPAction && prevIndex === tabIndex) ? newTPAction : null),
        selectedTPName: selectedTP,
        activeIndex: tabIndex
      })
    }
  }

  const updateScopeTargetActions = (tpAction, tabIndex, selectTP) => {
    const prevTpMode=props?.scopeTargetState?.tpMode
    const prevIndex=props.scopeTargetState.activeIndex
    previousActiveIndex.current = prevIndex
    const isDeleteAction = tpAction === 'delete'
    if ((prevTpMode === tpAction && prevIndex === tabIndex)) {
      updateTargetPathwayDetailsObj({
        selectedTP: null,
        showProgressTowardsTargetYear: nzcSettingsData.showTargetProgress,
        progressTowardsTargetYear: YEAR_2030
      })
      SessionUserState.saveUserDataToSession('strandingYear','-')
    } else if (isDeleteAction && getActiveIndex() ===  tabIndex + 1) {
      updateStateForTarget(prevTpMode, prevIndex, tpAction, tabIndex, selectTP, isDeleteAction)
      return
    } else {
      let showProgressTowardsTargetYear,progressTowardsTargetYear
      if (tpAction !== SCOPE_TARGET_MODE.edit) {
        showProgressTowardsTargetYear = false
        progressTowardsTargetYear = YEAR_2030
      } else {
        if(tabIndex+1 !==  props.nzcSettingsData.targetPathway ){
          showProgressTowardsTargetYear= false
          progressTowardsTargetYear= YEAR_2030
        } else {
          showProgressTowardsTargetYear= props.nzcSettingsData.showTargetProgress
          progressTowardsTargetYear= props.nzcSettingsData.progressTargetYear
        }
      }
      updateTargetPathwayDetailsObj({ selectedTP: selectTP, showProgressTowardsTargetYear, progressTowardsTargetYear})
    }
    props.updateTargetsAndPathwayDetails({showProgressTowardsTargetYear: false, progressTowardsTargetYear: YEAR_2030})
    updateStateForTarget(prevTpMode, prevIndex, tpAction, tabIndex, selectTP, isDeleteAction)
  }

  function tpIconView(event, tabIndex) {
    const selectTP = getScopeTPName(tabIndex)
    preventEvent(event)
    updateScopeTargetActions(SCOPE_TARGET_MODE.view, tabIndex, selectTP)
  }

  function tpIconEdit(event, tabIndex) {
    tpIconEditAction(event, tabIndex, updateValidateTotalReductionState, targetPathwayFormState, updateScopeTargetActions, updateTargetPathwayFormState, filterAndCreateScopeData)
    targetPathWayData.current = props?.targetPathwayData?.scopeTargets?.filter(scopeTarget=>scopeTarget.targetYear>= props?.reportingYear + 1)
    const settingsTP = props?.nzcSettingsData?.targetPathway !== null  ?`tp${props?.nzcSettingsData?.targetPathway}`: null
    const selectTP = getScopeTPName(tabIndex)
    setTargetPathwayEditStatus(prevState => {return{
      ...prevState, customTargetPathwayEdited : selectTP !== settingsTP
    }})
  }

  function tpIconDelete(event, tabIndex) {
    setDeletingTPTabIndex(tabIndex)
    preventEvent(event)
    updateTargetPathwayFormState({showConfirmModal: true, tabIndex})
  }

  function getViewIconClassName(tabIndex) {
    let viewIconClassName='eye-off-lined'
    if((props?.scopeTargetState?.tpMode===SCOPE_TARGET_MODE.view && (props.scopeTargetState.activeIndex===tabIndex))){
      viewIconClassName='eye-on-lined'
    }
    return viewIconClassName
  }

  function handleProgressTargetDropdown(e) {
    updateTargetPathwayDetailsObj({progressTowardsTargetYear: e.value})
  }

  function tabOpenHandler(tabOpenEvent) {
    let tabHeaderHide={}
    switch(tabOpenEvent) {
    case 0: tabHeaderHide={ tab2Class:cssClassHideTab, tab3Class:cssClassHideTab }; break
    case 1: tabHeaderHide={ tab1Class:cssClassHideTab, tab3Class:cssClassHideTab }; break
    case 2: tabHeaderHide={ tab1Class:cssClassHideTab, tab2Class:cssClassHideTab }; break
    default: break
    }
    updateTargetPathwayFormState({ tabExpanded: true, activeIndex: tabOpenEvent, ...tabHeaderHide })
  }

  function tabCloseHandler() {
    updateTargetPathwayFormState({ tabExpanded: false, activeIndex: null, tab1Class:placeholderString,tab2Class:placeholderString, tab3Class:placeholderString })
  }

  function getTPPanelElements() {

    function progressTargetYearForTP(show, tabIndex) {
      const progressYear = (<CustomTPViews
        value={2}
        handleShowProgressTowardsTargetCheckboxSelection={handleShowProgressTargetCheckboxSelection}
        progressTowardsTargetYearOptions={progressTowardsTargetOptions}
        handleProgressTowardsTargetDropdown={handleProgressTargetDropdown}
        getDropdownItemTemplate={getDropdownItemTemplate}
        year={targetPathwayDetailsObj?.progressTowardsTargetYear}
        checkboxStatus={targetPathwayDetailsObj.showProgressTowardsTargetYear}
      />)
      if (show === null || show === undefined ) {
        if (tabIndex + 1 === props?.nzcSettingsData?.targetPathway) {
          return progressYear
        }
        return null
      } else {
        return progressYear
      }
    }

    const customTPView = (options, tabIndex, selectedTP, strandingYear) => {
      return (
        <CustomTPViews
          tpIconViewHandler={tpIconView}
          getViewIconClassName={getViewIconClassName}
          isCreateOrEditMode={isCreateOrEditMode}
          tabExpanded={targetPathwayFormState?.tabExpanded}
          targetPathwayNumbersArray={targetPathwayNumbersArray}
          value={1}
          options={options}
          tabIndex={tabIndex}
          progressTargetYearFunction={progressTargetYearForTP(selectedTP,tabIndex)}
          strandingYear={strandingYear}
          tpName={customTargetPathwayName[`tp${tabIndex+1}`]}
          tpIconEditHandler={tpIconEdit}
          tpIconDeleteHandler={tpIconDelete}
          selectedTP={selectedTP}
        />
      )
    }

    function getATabHeaderTemplate0(options,tabIndex) {
      let strandingYear = targetPathwayFormState.strandingYearTP1
      return customTPView(options, tabIndex, targetPathwayDetailsObj.selectedTP, strandingYear)
    }

    function getATabHeaderTemplate1(options, tabIndex) {
      let strandingYear = targetPathwayFormState.strandingYearTP2
      return customTPView(options, tabIndex, targetPathwayDetailsObj.selectedTP, strandingYear)
    }
    function getATabHeaderTemplate2(options,tabIndex) {
      let strandingYear = targetPathwayFormState.strandingYearTP3
      return customTPView(options, tabIndex, targetPathwayDetailsObj.selectedTP, strandingYear)
    }

    return(
      <>
        <div className={'existing-target-pathways'} role="current-model-target-pathways">
          {targetPathwayFormState?.scopeTP1?.length > 0 &&
                <Panel className={targetPathwayFormState?.tab1Class} toggleable
                  onExpand={()=> tabOpenHandler(0)}
                  headerTemplate={(options) => getATabHeaderTemplate0(options, 0)}
                  onCollapse={()=> tabCloseHandler()}
                  collapsed={true}
                >
                  {getFormContentViewElements(targetPathwayFormState?.scopeTP1)}
                </Panel>
          }
          {targetPathwayFormState?.scopeTP2?.length > 0 &&
                <Panel className={targetPathwayFormState?.tab2Class} toggleable
                  onExpand={()=> tabOpenHandler(1)}
                  headerTemplate={(options) => getATabHeaderTemplate1(options, 1)}
                  onCollapse={()=> tabCloseHandler()}
                  collapsed={true}
                >
                  {getFormContentViewElements(targetPathwayFormState?.scopeTP2)}
                </Panel>
          }
          {targetPathwayFormState?.scopeTP3?.length > 0 &&
                <Panel className={targetPathwayFormState.tab3Class} toggleable
                  onExpand={()=> tabOpenHandler(2)}
                  headerTemplate={(options) => getATabHeaderTemplate2(options, 2)}
                  onCollapse={()=> tabCloseHandler()}
                  collapsed={true}
                >
                  {getFormContentViewElements(targetPathwayFormState?.scopeTP3)}
                </Panel>
          }
        </div>
      </>
    )
  }

  function getTPEndYear(yearStart) {
    return (yearStart + 5)
  }

  function getScopeDataObject(startYear, yearIncrement, tpNumber) {
    return {
      ScopeId: props.nzcScopeId,
      targetYear: startYear,
      targetYearIncrement: yearIncrement,
      percentage: 0,
      targetPathwayNumber: tpNumber
    }
  }

  function getNewTPDataObject() {
    const tpNumber=getTPNumberForCreate(targetPathwayFormState)
    let newTPDataObject = []
    if (props.nzcScopeId) {
      let yearStart = props?.reportingYear + 1
      const endYear = getTPEndYear(yearStart)
      while (yearStart <= endYear) {
        newTPDataObject.push(getScopeDataObject(yearStart, 1, tpNumber))
        yearStart++
      }
      // For milestone years
      newTPDataObject.push(getScopeDataObject(yearStart, 5, tpNumber))
      yearStart+=5
      newTPDataObject.push(getScopeDataObject(yearStart, 5, tpNumber))
      yearStart+=5
      newTPDataObject.push(getScopeDataObject(yearStart, 10, tpNumber))
    }
    return newTPDataObject
  }

  function getAssetTargetBodyContentElements() {
    let targetBodyContent
    const tpMode=props.scopeTargetState?.tpMode
    if(isCreateOrEditMode()){
      targetBodyContent=getFormContentViewElements(targetPathwayFormState.selectedScopeTPData)
    } else if(isScopeTargetsNotEmpty(props.targetPathwayData) && (tpMode !== SCOPE_TARGET_MODE.edit && tpMode !== SCOPE_TARGET_MODE.create)){
      targetBodyContent=getTPPanelElements()
    }
    return targetBodyContent
  }

  function closeDialog() {
    setDeletingTPTabIndex(null)
    updateTargetPathwayFormState({showConfirmModal: false})
  }

  function getActiveIndex() {
    return targetPathwayFormState.tabIndex + 1
  }

  function confirmFooter(){
    return(
      <div className="delete-footer">
        <div className="flex justify-content-center pt-0">
          <div className="pr-2">
            <Button label={I18n('t_no')} className={'dialog-no'} onClick={()=>closeDialog()}/>
          </div>
          <div>
            <Button label={I18n('t_yes')} className={'dialog-yes'} onClick={() => deleteScope(getActiveIndex())}/>
          </div>
        </div>
      </div>
    )
  }

  function getDeleteModalTitle() {
    return `${getLocalizedValue(selectedLanguage, 't_delete_target_pathway_title')} ${getActiveIndex()}`
  }

  function handleEnergySavingsActionCheckbox(event) {
    let agreedActionsCheckboxStatus = event.checked && props?.actionBasedTPDetails.agreedActions > 0
    let applicableActionsCheckboxStatus = event.checked && props?.actionBasedTPDetails.agreedActions < 1 &&  props?.actionBasedTPDetails.applicableActions > 0
    updateTargetPathwayDetailsObj({ applicableActions: applicableActionsCheckboxStatus, agreedActions: agreedActionsCheckboxStatus, energySavingActions: event.checked})
    props.updateTargetsAndPathwayDetails({energySavingActions: event.checked})
  }

  function handleSelectedActionBasedPathway(event) {
    if (event.target.name === 'agreed-action') {
      setTargetPathwayDetailsObj(prevState => {
        return {
          ...prevState,
          agreedActions: event.checked,
          energySavingActions: (event.checked || prevState.applicableActions)
        }
      })
    }
    if (event.target.name === 'applicable-action') {
      setTargetPathwayDetailsObj(prevState => {
        return {
          ...prevState,
          applicableActions: event.checked,
          energySavingActions: (event.checked || prevState.agreedActions)
        }
      })
    }
  }

  function handleSelectedScienceBasedTarget(event) {
    updateTargetPathwayDetailsObj({scienceBasedTargetOption: event.target.name==='SBT-option-1' ? 1.5 : 2.0,})
  }

  function getSettingsState() {
    let targetPathwayNumber
    const storedTargetProgressYear = targetPathwayDetailsObj.progressTowardsTargetYear ? targetPathwayDetailsObj.progressTowardsTargetYear : YEAR_2030
    if (targetPathwayEditStatus.customTargetPathwayEdited && getAssetTPNumber()) {
      targetPathwayNumber = parseInt(getAssetTPNumber())
    } else if (targetPathwayDetailsObj.selectedTP) {
      targetPathwayNumber = parseInt(targetPathwayDetailsObj.selectedTP.substring(2))
    } else {
      targetPathwayNumber = null
    }

    return {
      baseLineYear: props?.baselineYear,
      energySavingActions: targetPathwayDetailsObj.energySavingActions,
      applicableActions:targetPathwayDetailsObj.applicableActions,
      agreedActions:targetPathwayDetailsObj.agreedActions,
      showTargetProgress: targetPathwayEditStatus.customTargetPathwayEdited && getAssetTPNumber() ? false : targetPathwayDetailsObj.showProgressTowardsTargetYear,
      progressTargetYear: targetPathwayEditStatus.customTargetPathwayEdited && getAssetTPNumber() ? YEAR_2030 : storedTargetProgressYear,
      targetPathway: targetPathwayNumber,
      scienceBasedTarget: (targetPathwayDetailsObj.scienceBasedTargetOption && targetPathwayDetailsObj.scienceBasedTargetOption > 0) ? targetPathwayDetailsObj.scienceBasedTargetOption : SBT_VERSIONS.sbt_1_5,
      targetPathwayNames: JSON.stringify(customTargetPathwayName)
    }
  }

  async function submitTargetPathwayData() {
    const tempSettings=getSettingsState()
    await updateNzcSettingsMutation.mutateAsync({scopeId, settings: tempSettings, graphIntensity: props.assetGraphIntensity}).then(() => {
      props.refetchTargetPathwayData()
      removeRequiredSessionStorageCache()
      refetchUpdatedDataByInvalidatingQueries(queryClient,[QueryKeyPrefix.ASSET_NZC_ACTIONDETAILS])
      props.refetchModelSettings()
      props.updateTargetsAndPathwayDetails(targetPathwayDetailsObj)
      setTargetPathwayEditStatus(prevState => {return{
        ...prevState, targetPathwayPanelEdited: false, customTargetPathwayEdited : false
      }})
    })
    props.onHideActions({showNzcActionOverlay: 'none'})
  }

  function cancelTargetPathwayViewDetailsUpdate(){
    removeRequiredSessionStorageCache()
    let revertedBackTPDetails = {
      showProgressTowardsTargetYear: props?.nzcSettingsData?.showTargetProgress,
      energySavingActions: props?.nzcSettingsData?.energySavingActions,
      agreedActions: props?.nzcSettingsData?.agreedActions,
      applicableActions: props?.nzcSettingsData?.applicableActions,
      progressTowardsTargetYear: props?.nzcSettingsData?.progressTargetYear,
      selectedTP: props?.nzcSettingsData?.targetPathway !== null ?`tp${props?.nzcSettingsData?.targetPathway}`: null,
      scienceBasedTargetOption: props?.nzcSettingsData?.scienceBasedTarget
    }
    updateTargetPathwayDetailsObj(revertedBackTPDetails)
    props.updateScopeTargetState({
      tpMode: props?.nzcSettingsData?.targetPathway ? SCOPE_TARGET_MODE.view : null,
      selectedTPName:props?.nzcSettingsData?.targetPathway ? `tp${Number(props?.nzcSettingsData?.targetPathway)}`:null,
      activeIndex: props?.nzcSettingsData?.targetPathway ?Number(props?.nzcSettingsData?.targetPathway) -1:null
    })
    props.updateTargetsAndPathwayDetails(revertedBackTPDetails)
    updateIsEditStateValue(setTargetPathwayEditStatus, targetPathwayDetailsObj, nzcSettingsData)
    props.onHideActions({showNzcActionOverlay: 'none'})
  }

  function handleQuestionMarkClick(event, tpType) {
    switch(tpType) {
    case 'action-based-tp':  actionBasedTargetPathwayOP.current.toggle(event); break
    case 'science-based-tp': scienceBasedTargetPathwayOP.current.toggle(event); break
    case 'custom-based-tp': customBasedTargetPathwayOP.current.toggle(event); break
    default: break
    }

  }

  function handleShowProgressTargetCheckboxSelection(e) {
    updateTargetPathwayDetailsObj({showProgressTowardsTargetYear: e.checked, progressTowardsTargetYear: targetPathwayDetailsObj.progressTowardsTargetYear})
  }

  function applyAssetNoEnergySavingsActionFilter() {
    props.setNoEnergySavingsActionFilter(true)
    applyNoEnergySavingsActionFilter(props?.updateUrlQueryParams, getQueryValuesForNZCAssets, selectedLanguage)
  }

  function getOverlayPanel(tpRef, id, message) {
    return <>
      <OverlayPanel className='target-pathway-info-popup' appendTo={document.getElementById(id)} ref={tpRef} showCloseIcon>
        <div className={`target-pathway-info-popup-text ${assetCountWithNoAgreedActions > 0 && id}-1`}>
          <span data-testid='tp-details-overlaypanel'>{message}</span>
        </div>
      </OverlayPanel>
    </>
  }


  const  getCustomBasedTargetPathways = () =>{
    const text = I18n('t_asset_nzc_custom_based_target_pathway_info')
    return <div className='custom-target-pathways-title-icon' id={customBasedPathwayId}>
      <label className={'target-pathway-sub-titles'}>{I18n('t_custom_target_pathways')}</label>
      <img src={questionMarkIcon} alt="" className='question-mark-icon' onClick={(event) => handleQuestionMarkClick(event, 'custom-based-tp')} aria-label='custom-based-tp-question-mark'/>
      {getOverlayPanel(customBasedTargetPathwayOP, customBasedPathwayId, text)}
    </div>
  }

  if(props.graphDataLoading){
    return <AssetNzcTargetPathwaySkeleton />
  }

  if(!nzcSettingsData){
    return  <div className={'nzc-target-pathway-form'}>{noDataAvailableMessage()}</div>
  }

  return (
    <div className={'nzc-target-pathway-form'}>
      {!isCreateOrEditMode() && !targetPathwayFormState?.tabExpanded &&
            <>
              {featurestate.sieraplus_actionBasedPathways &&
                  <ActionBasedTargetPathways
                    actionBasedPathwayId={actionBasedPathwayId}
                    handleQuestionMarkClick={handleQuestionMarkClick}
                    targetPathwayDetailsObj={targetPathwayDetailsObj}
                    handleEnergySavingsActionCheckbox={handleEnergySavingsActionCheckbox}
                    handleSelectedActionBasedPathway={handleSelectedActionBasedPathway}
                    actionBasedTPDetails={props?.actionBasedTPDetails}
                    getOverlayPanel={getOverlayPanel}
                    actionBasedTargetPathwayOP={actionBasedTargetPathwayOP}
                  />
              }
              {featurestate.sieraplus_scienceBasedPathways &&
                  <AssetSBT
                    scienceBasedPathwayId={scienceBasedPathwayId}
                    handleQuestionMarkClick={handleQuestionMarkClick}
                    scienceBasedTargetOption={scienceBasedTargetOption}
                    handleSelectedScienceBasedTarget={handleSelectedScienceBasedTarget}
                    getOverlayPanel={getOverlayPanel}
                    scienceBasedTargetPathwayOP={scienceBasedTargetPathwayOP}
                    targetPathwayDetailsObj={targetPathwayDetailsObj}
                    graphIntensity={props.assetGraphIntensity}
                  />
              }
              {getCustomBasedTargetPathways()}
            </>
      }

      <div className={'target-body'}>
        {getAssetTargetBodyContentElements()}
      </div>

      {!isCreateOrEditMode() && !targetPathwayFormState?.tabExpanded && targetPathwayNumbersArray.length<3 &&
            <div className='custom-target-pathways'>
              <img src={plusIcon} alt="" className='plus-icon' onClick={addAnotherTargetHandler} data-testid='create-new-tp'/>
              <label htmlFor="custom-target-pathway-sub-titles" className='target-pathway-sub-titles' role='add-new-target-pathway-label'>{I18n('t_define_target_pathway')}</label>
            </div>}
      {!isCreateOrEditMode() && !targetPathwayFormState?.tabExpanded &&
            <div className={'asset-nzc-apply-cancel'} role='apply-cancel-container'>
              <div className="footer-btn-apply" role="apply-target-pathways-updates"><Button className={'btn-apply button-highlight'} onClick={submitTargetPathwayData} disabled={!settingsChangesExist}>{I18n('t_apply')}</Button></div>
              <div className="footer-btn-cancel" role="cancel-target-pathways-updates"><label className={`btn-cancel button-highlight ${!settingsChangesExist}`} onClick={cancelTargetPathwayViewDetailsUpdate} >{I18n('t_cancel')}</label></div>
            </div>
      }

      {errorDialogVisible && <ErrorDialog
        data={errorDialogData}
        dialogVisible={errorDialogVisible}
        onHideHandler={closeHandler}
        closeHandler={closeHandler} />}
      {targetPathwayFormState?.showConfirmModal && <PopupDialog
        visible={targetPathwayFormState.showConfirmModal}
        onHide={closeDialog}
        message={getLocalizedValue(selectedLanguage, 't_delete_target_pathway_message')}
        footer={confirmFooter}
        className={'no-footer'}
        header={getDeleteModalTitle()}
      />}
    </div>
  )
}
AssetTPComponent.propTypes={
  actionBasedTPDetails: PropTypes.object,
  assetGraphIntensity: PropTypes.string,
  nzcSettingsData: PropTypes.shape({
    scienceBasedTarget: PropTypes.number,
    targetPathwayNames: PropTypes.string,
    showTargetProgress: PropTypes.bool,
    energySavingActions: PropTypes.bool,
    targetPathway:PropTypes.object,
    agreedActions: PropTypes.bool,
    applicableActions: PropTypes.bool,

  }),
  reportingYear : PropTypes.number,
  nzcRedirectPopupProps: PropTypes.object,
}
export default AssetTPComponent