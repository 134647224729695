import React, {useEffect, useRef, useState} from 'react'
import './UsersHome.scss'
import '../../../../common/search/SearchField.scss'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import Avatar from 'react-avatar'
import moreIcon from '../../../../../resources/images/icon/verticalDots.svg'
import {useMutation, useQuery} from 'react-query'
import {noRefetchOnWindowFocus} from '../../../../../services/common/useQuery-config'
import {DeleteUser, GetUsersList, SendInvitationToUser} from '../../../../../services/admin/users-service'
import UsersGridSkeleton from '../../../../common/skeletons/users-grid-skeleton/UsersGridSkeleton'
import {Button} from 'primereact/button'
import {useHistory} from 'react-router-dom/cjs/react-router-dom'
import I18n from '../../../../../utils/i18n/I18n'
import {ProgressSpinner} from 'primereact/progressspinner'
import {
  clearUserContent, createNavURLForUserPages, getDateTimeTemplate, getLocalizedValue,
  getLoggedInUserRole, handleClickOutsideHidePanel,
  hasPermission,
  isLoggedInSelectedUserSame
} from '../../../../../utils/helpers/Helper'
import {
  actionOptions,
  ADMIN,
  ADMIN_PAGES,
  ADMINISTRATOR,
  UserActions,
  USERS
} from '../../../../../utils/helpers/Constants'
import HeaderContainer from '../../Common/HeaderContainer'
import PopupDialog from '../../../../common/modal-dialogs/PopupDialog'
import {useParams} from 'react-router-dom'
import CSS_VARIABLES from '../../../../../resources/css/_variables.scss'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

function UsersHome() {

  //region Declarations/Initializations
  const {instanceName} = useParams()

  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const unitSystem = userInfo.unitSystem

  const userActionsList = [
    { actionName: actionOptions.OPTION_RESEND_INVITE,  actionLabel: getLocalizedValue(selectedLanguage, 't_resend_invite')},
    { actionName: actionOptions.OPTION_REVOKE_ACCESS, actionLabel: getLocalizedValue(selectedLanguage, 't_revoke_access')},
    { actionName: actionOptions.OPTION_EDIT_USER, actionLabel: getLocalizedValue(selectedLanguage, 't_edit_user')}
  ]

  const [showDeleteUserDlg, setShowDeleteUserDlg] = useState(false)
  const [showResendDlg, setShowResendDlg] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const hideUserActionsOverlayPanel = useRef(null)
  const [userActionsOverlayPanel, setUserActionsOverlayPanel] = useState(null)
  const [usersGridData, setUsersGridData] = useState([])
  const history = useHistory()
  const [searchText, setSearchText] = useState('')
  const {data: usersData, refetch: refetchUsers, isLoading}  = useQuery(['usersInfoList'], GetUsersList, noRefetchOnWindowFocus)
  const warningMessageToDelete = getLocalizedValue(selectedLanguage, 't_user_revoke_confirm_message')
  const [warningMessage,setWarningMessage] = useState(warningMessageToDelete)
  const [userActions,setUserActions] = useState(userActionsList)
  const historyState = history.location.state

  //endregion

  //region useEffect Implementation
  useEffect(() => {
    clearUserContent()
  }, [])

  useEffect(() => {
    if (usersData) {
      setUsersGridData(usersData)
    }

  }, [usersData])

  useEffect(  () => {
    if (historyState && historyState.fromSingleUserPage) {
      setShowDeleteUserDlg(true)
      let message = <div>{I18n('t_user_revocation_successful', {0: history.location.state.email})}</div>
      setWarningMessage(message)

      delete historyState.email
      delete historyState.fromSingleUserPage
      history.replace({...history, historyState})
    }

    if (historyState && historyState.isAddUser) {
      setSelectedUser(historyState)
      //TODO: Send email notification to added user .
      // TODO: Need to get userId from addUser api
      //await sendInvitationToUser();
      setShowResendDlg(true)

      delete historyState.userName
      delete historyState.isAddUser
      history.replace({...history, historyState})
    }
  }, [history.location?.state])

  useEffect(() => {
    handleClickOutsideHidePanel(hideUserActionsOverlayPanel, setUserActionsOverlayPanel)
  }, [hideUserActionsOverlayPanel])

  //endregion

  //region Custom templates
  const userNameTemplate = (rowData) => {
    return (
      <div className='userName-template'>
        <span className="useImage-avatar">
          <Avatar
            className="user-image"
            name={getFieldValueOrPlaceholder(rowData.userName)}
            src={rowData.image}
            round={true}
            textSizeRatio={3.6}
            color={CSS_VARIABLES.forest_10}
            fgColor={CSS_VARIABLES.forest}
            size="30"
          />
        </span>
        <label>{getFieldValueOrPlaceholder(rowData.userName)}</label>
      </div>)
  }

  const companyTemplate = (rowData) => {
    return <label>{getFieldValueOrPlaceholder(rowData.company)}</label>  // should be mapped with comapny name
  }

  const roleTemplate = (rowData) => {
    const role = rowData.role  === ADMIN || rowData.role === ADMINISTRATOR ? ADMIN : USERS
    return <label>{role? I18n(`t_${role.toLowerCase()}`): '-'}</label> // it should be mapped with Role
  }

  const assetTemplate = (rowData) => {
    return <label>{rowData.numberOfAssets}</label> // it should be mapped with role
  }

  const lastLoginTemplate = (rowData) => {
    let dateTime = getDateTimeTemplate(rowData,selectedLanguage, unitSystem)

    function moreOptionsHandler(e, rowData) {
      setSelectedUser(rowData)
      //TODO: Below Condition has to be updated as per lastlogin date to enable "Resend Invite"
      let actionsList=userActionsList
      if (rowData.lastLogin !== null) {
        actionsList = actionsList.splice(1, userActionsList.length)
      }
      if (isLoggedInSelectedUserSame(rowData)) {
        actionsList = actionsList.filter(action=>!(actionOptions.OPTION_REVOKE_ACCESS===action.actionName))
      }
      setUserActions(actionsList)
      setUserActionsOverlayPanel(rowData.userId)
      e.stopPropagation()
    }

    // {nextReviewDate should be mapped with lastLogin }
    return (
      <div className='lastLogin-template relative'>
        <span className='lastLogin-label'>{getFieldValueOrPlaceholder(dateTime)}</span>
        {(usersGridData && hasPermission(UserActions.EDIT_USER, getLoggedInUserRole())) &&
        <span className="user-actions-more-btn" onClick={(e) => moreOptionsHandler(e, rowData)}
          data-testid={'more-options'}>
          <img src={moreIcon} alt="User action options button"/>
          {/*The reason to remove the overlaypanel here is to set position as per the design, new primereact version overlaypanel doesn't have style attribute and position*/}
          {rowData.userId === userActionsOverlayPanel && (
            <div ref={hideUserActionsOverlayPanel} className="userActions-overlay" data-testid={'more-options-click'}>
              <div className='userActions-List'>
                <DataTable value={userActions} selectionMode="single" className="userActions-overlay-grid" stripedRows
                  onRowClick={(e) => opItemClickHandler(e)}>
                  <Column field="actionLabel" className="userAction-title" />
                </DataTable>
              </div>
            </div>
          )}
        </span>}
      </div>
    )
  }

  function confirmDialogFooter(){
    let message= <div className="delete-footer">
      <div className="flex justify-content-center pt-0">
        <div className="pr-2">
          <Button label={getLocalizedValue(selectedLanguage, 't_no')} className={'dialog-no'} onClick={()=>hideTheDialog()}/>
        </div>
        <div>
          <Button label={getLocalizedValue(selectedLanguage, 't_yes')} className={'dialog-yes'} onClick={deleteUser}/>
        </div>
      </div>
    </div>

    message = warningMessage !== warningMessageToDelete? <div className="delete-footer-no-height" /> : message

    return <div>
      {message}
    </div>
  }

  //endregion

  //region Dialog & overlay handlers
  function hideTheDialog() {
    setShowDeleteUserDlg(false)
    setWarningMessage(warningMessageToDelete)
    setSelectedUser(null)
    setUserActionsOverlayPanel(null)
  }

  function closeResendDialog() {
    setShowResendDlg(false)
    setSelectedUser(null)
  }

  //endregion

  //region Helper methods
  function getFieldValueOrPlaceholder(fieldValue) {
    return fieldValue?fieldValue:'-'
  }

  async function opItemClickHandler(e) {
    let actionName = e.data.actionName
    if (actionName === actionOptions.OPTION_REVOKE_ACCESS) {
      setShowDeleteUserDlg(true)
    }
    if (actionName === actionOptions.OPTION_EDIT_USER) {
      const userEditPath=createNavURLForUserPages({pathPrefix: ADMIN_PAGES.users, userId: selectedUser.userId, pathSuffix: ADMIN_PAGES.edit})
      history.push({
        pathname: userEditPath,
        state: {userDetails: selectedUser}
      })
    }
    if ((actionName === actionOptions.OPTION_RESEND_INVITE)) {
      setShowResendDlg(true)
      await sendInvitationToUser()
    }
    setUserActionsOverlayPanel(null)
  }

  function usersRowClickHandler(e) {
    const userProfilePath=createNavURLForUserPages({pathPrefix: ADMIN_PAGES.users, userId: e.data.userId})
    history.push({pathname: userProfilePath})
  }

  const sendUserInvitationMutation = useMutation(SendInvitationToUser, {
    onSuccess: () => {
      refetchUsers()
    }
  })

  async function sendInvitationToUser() {
    await sendUserInvitationMutation.mutate({
      userId: selectedUser.userId,
    })
  }

  const deleteUserMutation = useMutation(DeleteUser, {
    onSuccess: () => {
      refetchUsers()
    }
  })

  async function deleteUser() {
    // const sleep = (milliseconds) => {
    //   return new Promise(resolve => setTimeout(resolve, milliseconds))
    // }
    try {
      clearSearch()
      let message=<div className="warning-dialog"><ProgressSpinner style={{width: '60px', height: '60px'}} animationDuration=".8s"/></div>
      setWarningMessage(message)
      await deleteUserMutation.mutate({
        userId: selectedUser.userId,
      })
      message = <div>{I18n('t_user_revocation_successful', {0: selectedUser.email})}</div>
      setWarningMessage(message)
    } catch (e) {
      console.log(e.toString())
    }
  }

  function clearSearch() {
    let searchText = ''
    setSearchText(searchText)
    let filteredList = usersData.filter((key) => {
      if (key.userName.toLowerCase().indexOf(searchText.toLowerCase()) > -1) {
        return true
      }
    })
    setUsersGridData(filteredList)
  }

  function searchUsers(e) {

    let searchText = e.target.value
    setSearchText(searchText)

    let filteredList = usersData.filter((key) => {
      if (key.userName.toLowerCase().indexOf(searchText.toLowerCase()) > -1) {
        return true
      }
    })

    setUsersGridData(filteredList)
  }

  function addUserHandler(){
    if(hasPermission(UserActions.ADD_USER, getLoggedInUserRole())){
      const userAddPath=createNavURLForUserPages({instanceName, userId: ADMIN_PAGES.users, pathSuffix: ADMIN_PAGES.add})
      history.push({pathname: userAddPath},
        {activeIndex : 0}
      )
    }
  }

  //endregion
  return (
    <div className="grey-bg min-width-1280" data-testid={'users-home-wrap'}>
      <div className={'container-layout users-homePage-wrapper gutter'} data-testid={'users-home'}>
        <HeaderContainer title={'t_users'} placeholder={'t_find_user'} addButtonLabel={'t_add_new_user'}
          searchValue={searchText} searchHandler={searchUsers} addItemHandler={addUserHandler} clearField={clearSearch}/>

        {isLoading ?
          <div className="users-grid-before-load users-list-container">
            <UsersGridSkeleton/>
          </div> :
          <div className="users-list-container">
            {
              usersGridData.length > 0 ?
                <DataTable
                  value={usersGridData}
                  selectionMode="single"
                  className="users-list-table"
                  stripedRows
                  selection={selectedUser}
                  onRowClick={(e) => usersRowClickHandler(e)}
                  scrollable
                >
                  <Column field="userName" header={I18n('t_name')} body={userNameTemplate} className="userNameColumn" style={{width: '22%'}} />
                  <Column field="company" header={I18n('t_company')} body={companyTemplate} className="companyColumn" style={{width: '22%'}} />
                  <Column field="role" header={I18n('t_role')} body={roleTemplate} className="roleColumn" style={{width: '22%'}} />
                  <Column field="numberOfAssets" header={I18n('t_asset_s')} body={assetTemplate} className="assetsColumn" style={{width: '12%'}} />
                  <Column field="lastLogin" header={I18n('t_last_login')} body={lastLoginTemplate} className="lastLoginColumn" style={{width: '22%'}} />
                </DataTable> :

                <div className="no-result-msg">
                  <span className="no-result-text">{I18n('t_users_not_found')}</span>
                </div>
            }
          </div>
        }
      </div>
      {showDeleteUserDlg && <PopupDialog
        visible={showDeleteUserDlg}
        onHide={hideTheDialog}
        message={warningMessage}
        footer={confirmDialogFooter}
        className={'no-footer'}
        header={warningMessage === warningMessageToDelete ? I18n('t_revoke_access') : I18n('t_user_creation_confirmation_popup_title')}
      />}
      {showResendDlg && <PopupDialog
        visible={showResendDlg}
        header={I18n('t_user_creation_confirmation_popup_title')}
        onHide={closeResendDialog}
        footer={<div/>}
        message={I18n('t_user_invited_to_join_siera', {0: selectedUser.email})}
      />}
    </div>
  )
}

export default UsersHome