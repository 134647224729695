import {memo, useContext, useEffect, useMemo, useRef} from 'react'
import './FundDataQualityHome.scss'
import {CachedUserState, createEndMonthParam, getFundViewsNavLinkConfigsForFlags} from '../../../../utils/helpers/Helper'
import {updateQueryParamsForFundViews} from '../../../../utils/helpers/DatePickerUtils'
import {FeatureFlagContext} from 'Contexts'
import buildQuery from 'odata-query'
import {useHistory, useParams} from 'react-router-dom'
import {
  BOTH,
  CacheKeyFundGroups,
  DATA_FROM,
  EXPORT,
  FUND_VIEWS_PAGES,
  PERIOD_TYPES,
} from '../../../../utils/helpers/Constants'
import FundCommonHeader from '../common-header/FundCommonHeader'
import {getTimeperiodLabel, periodOptions} from '../../../../services/common/datepicker-service'
import UseUrlParameterIds from '../../../../utils/custom-hooks/UseUrlParameterIds'
import infoTeal from '../../../../resources/images/icon/info.teal.svg'
import DataQualityAssetList from './asset-list/DataQualityAssetList'
import FundProcurementTabs from './procurement-tabs/FundProcurementTabs'
import useFundDataQualityData from './data-quality-hooks-&-helpers/useFundDataQualityData'
import {
  fundDataQualityStateInitialiser,
  getMultiSelectUtilityOptions,
  getQueryParams
} from './data-quality-hooks-&-helpers/fundDataqualityHelper'
import useFundDataQualityState from './data-quality-hooks-&-helpers/useFundDataQualityState'
import DataQualityCTA from '../../../common/data-quality-cta/DataQualityCTA'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'


const FundDataQualityHome = () => {

  //region Initialization
  const querySearchParams = new URLSearchParams(document.location.search)
  const {instanceName, orgName, fundName} = useParams()
  const urlParameterIds = UseUrlParameterIds({instanceName, orgName, fundName})
  const history = useHistory()

  const {loginState} = useLoginAuthContext()
  const selectedLanguage = loginState.userInfo.languagePreference
  const {featureState} = useContext(FeatureFlagContext)
  const selectedFundRef=useRef(CachedUserState.getUserDataFromCache(CacheKeyFundGroups))
  const {
    fundDataQualityState: {
      periodType,
      pickerValue,
      sortField,
      sortOrder,
      oDataSortOrder,
      selectedUtility,
      procurementTabInfoDialogVisible,
      isTimePeriodChanged,
      multiSelectedUtility,
      availableUtilities
    }, updateFundDataQualityState
  } = useFundDataQualityState(() => fundDataQualityStateInitialiser(querySearchParams, selectedLanguage))

  const oDataQuery = buildQuery({orderBy: `${sortField} ${oDataSortOrder}`})
  const time = getTimeperiodLabel(periodType)
  const timeEndDate =  createEndMonthParam(pickerValue.pickerValueEndYear, pickerValue.pickerValueEndMonth)
  // API Data
  const apiParamsForAssetsQuery={
    fundGroupId: urlParameterIds?.fundGroupId,
    oDataQuery,
    selectedUtility,
    time,
    timeNoOfMonths: pickerValue.pickerValueMonths,
    timeEndDate,
    isTimePeriodChanged,
    selectedView: BOTH,
    featureFundDQLayoutV2: featureState?.sieraplus_fundDataQualityLayoutV2,
  }
  const {fundDQAssetsDataLoading, fundDQAssetsData, fundDQSummaryDataLoading, fundDQSummaryData}=useFundDataQualityData(apiParamsForAssetsQuery)
  // endregion
  
  useEffect(() => {
    updateQueryParamsForFundViews(getQueryParams(periodType, pickerValue, isTimePeriodChanged), null, history, instanceName, orgName, fundName, FUND_VIEWS_PAGES.dataQuality)
  }, [periodType, pickerValue, multiSelectedUtility])

  useEffect(() => {
    const updatedAvailableUtilities = fundDQSummaryData?.filter(data=> BOTH === data.responsibility)?.[0]?.availableUtilities
    if(updatedAvailableUtilities?.length > 0 && updatedAvailableUtilities?.length !== availableUtilities?.length &&
        (updatedAvailableUtilities?.some(value => !availableUtilities?.includes(value)) ||
            availableUtilities?.some(value => !updatedAvailableUtilities?.includes(value)))) {
      let updatedUtilities
      if(multiSelectedUtility?.length > 0) {
        updatedUtilities = multiSelectedUtility?.filter(el =>
          updatedAvailableUtilities?.includes(el.key)
        )
      } else {
        updatedUtilities = getMultiSelectUtilityOptions(selectedLanguage)?.options?.filter(el =>
          updatedAvailableUtilities?.includes(el.key))
      }
      updateFundDataQualityState({multiSelectedUtility: updatedUtilities, availableUtilities: updatedAvailableUtilities})

    }
  }, [fundDQSummaryData, pickerValue])

  const onUtilityChange = (e) => {
    updateFundDataQualityState({selectedUtility: e, isTimePeriodChanged: false,multiSelectedUtility: e})
  }

  const onHideDropdown = () => {
    // updateFundDataQualityState({selectedUtility: multiSelectedUtility})
  }

  function disableOptionItem(option) {
    const data = fundDQSummaryData?.filter(data=> BOTH === data.responsibility)
    if(data?.[0]?.availableUtilities) {
      const utilities = data?.[0]?.availableUtilities
      return !utilities.includes(option.key)
    }
    return false
  }


  // region dialog actions
  const onShowProcurementTabsInfoDialog = () => {
    updateFundDataQualityState({procurementTabInfoDialogVisible: true})
  }

  const onHideProcurementTabsInfoDialog = () => {
    updateFundDataQualityState({procurementTabInfoDialogVisible: false})
  }

  // endregion
  const excelExportInfo = useMemo(()=>{
    return {
      'options': {
        'enableExport': !fundDQAssetsDataLoading && fundDQAssetsData && fundDQAssetsData?.length !==0? 'enabled':'disabled',
        'hasOverlay': true,
        'dataFrom': DATA_FROM.FUND.FUND_DATA_QUALITY,
      },
      'requiredData':{
        'assetInfo':fundDQAssetsData
      },
      'fundAssetListQueryOptions':{
        'fundGroupId': urlParameterIds?.fundGroupId,
        'odataQueryString': oDataQuery,
        'time':time,
        'timeNoOfMonths': pickerValue?.pickerValueMonths,
        'timeEndDate':timeEndDate,
      }
    }
  },[fundDQAssetsData])
  
  function periodChangeAction(changeEvent) {
    const newPeriod=changeEvent.value
    updateFundDataQualityState({periodType: newPeriod, isTimePeriodChanged: true})
  }

  function datePickerChangeAction(changeEvent) {
    const newPickerValue={...pickerValue, [changeEvent?.target?.name]: changeEvent?.target?.value}
    updateFundDataQualityState({pickerValue: newPickerValue, isTimePeriodChanged: true})
  }

  function customPeriodAction() {
    updateFundDataQualityState({periodType: PERIOD_TYPES.CUSTOM})
  }

  function getFundAssetsTableProps() {
    return {
      fundAssetsTableData : {data:fundDQAssetsData, loading :fundDQAssetsDataLoading},
      sortField,
      sortOrder,
      updateFundDataQualityState,
      selectedUtility,
      selectedLanguage,
      fundGroupId: urlParameterIds?.fundGroupId
    }
  }

  return (
    <div data-page_identifier="fund_data_quality" className="grey-bg bbl min-width-1280 fund-dq-page ">
      <div className={'container-layout fund-dq-content gutter'}>
        <div className={'fund-header-section'}>
          <FundCommonHeader
            hasDatePicker={true}
            hasBlueBackground={false}
            hasDropdownFilter={true}
            navLinkConfigs={getFundViewsNavLinkConfigsForFlags(selectedFundRef.current, selectedLanguage, featureState)}
            selectedFund={selectedFundRef.current}
            multiSelectDQData={getMultiSelectUtilityOptions(selectedLanguage)}
            multiSelectOnChangeHandler={onUtilityChange}
            multiSelectedOptions={multiSelectedUtility}
            onHideDropdown={onHideDropdown}
            disableOptionItemHandler={disableOptionItem}
            period={periodType}
            periodOptions={periodOptions}
            pickerValue={pickerValue}
            datePickerOnChange={periodChangeAction}
            customDatePickerOnClick={customPeriodAction}
            pickerOnChange={datePickerChangeAction}
            action={EXPORT}
            excelExportInfo={excelExportInfo}
          />
        </div>
        {/* Fund data quality procurement tabs */}
        <div className='fund-dq-tabview-section'>
          <FundProcurementTabs
            isLoading={fundDQSummaryDataLoading}
            fundDQSummaryData={fundDQSummaryData}
            selectedUtility={selectedUtility}
            fundGroupId={urlParameterIds?.fundGroupId}
            selectedLanguage={selectedLanguage}
          />
        </div>
        <DataQualityCTA isVisbile={procurementTabInfoDialogVisible} onShow={onShowProcurementTabsInfoDialog} onHide={onHideProcurementTabsInfoDialog} className={'fund_dataQuality-cta'} selectedLanguage={selectedLanguage} imageSrc={infoTeal} data={'t_about_procurement_data_categories'}/>
        {/* Fund data quality table */}
        <div className={'fund-datatable-container'}>
          <DataQualityAssetList {...getFundAssetsTableProps()} />
        </div>
      </div>
    </div>
  )
}

FundDataQualityHome.displayName = 'FundDataQualityHome'

export default memo(FundDataQualityHome)