import React from 'react'
import I18n from '../../../utils/i18n/I18n'

const Cookies = (props) => {

  return (
    <div className="grey-bg min-width-1280">
      <div className="display gutter">
        <div className="container-box">
          {I18n('t_cookies_page',
            {
              h1: msg => (<h1>{msg}</h1>),
              h3: msg => (<h3>{msg}</h3>),
              p: msg => (<p>{msg}</p>),
              aboutcookies: msg => (
                <a href="https://www.aboutcookies.org/" target="_blank" rel="noopener noreferrer">
                  {msg}
                </a>
              ),
              emailevora: msg => (
                <a href="mailto:info@evoraglobal.com">
                  {msg}
                </a>
              )
            }
          )}

          
        </div>
      </div>
    </div>

  )
}

export default Cookies