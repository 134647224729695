import {
  getInitialPickerValue,
  getInitialTimePeriod,
  getTimeperiodLabel
} from '../../../../../services/common/datepicker-service'
import {createEndMonthParam, createNavURLForFundPages} from '../../../../../utils/helpers/Helper'
import I18n from '../../../../../utils/i18n/I18n'

export const setInitialAssetDashboardState = (assetId, queryValues) => {
  const timePeriod = getInitialTimePeriod(queryValues)
  const pickerValue = getInitialPickerValue(queryValues)
  const timeNoOfMonthsPeriod = pickerValue?.pickerValueMonths
  const timeEndDatePeriod = createEndMonthParam(pickerValue.pickerValueEndYear, pickerValue.pickerValueEndMonth)
  const time = getTimeperiodLabel(timePeriod)
  return {
    certifications: [],
    lastRefurbished: '-',
    dashboardFile: 'SIERA+ ESG Dashboard.pdf',
    timePeriod,
    pickerValue,
    timeNoOfMonthsPeriod,
    timeEndDatePeriod,
    time,
    variablesDQResponsibility: ['dqGraphOverviewData', assetId, time, timeNoOfMonthsPeriod, timeEndDatePeriod],
    variablesDPData: ['dashboardPerformanceData', assetId, time, timeNoOfMonthsPeriod, timeEndDatePeriod],
    modalState: {epc: false}
  }
}

export const dashboardActionsStatusList = [
  {label: I18n('t_completed'), key: 'completed', className: 'completed'},
  {label: I18n('t_agreed'), key: 'agreed', className: 'agreed'},
  {label: I18n('t_applicable'),key: 'applicable', className: 'applicable'},
  {label: I18n('t_under_review'),key: 'underReview', className: 'under-review'},
  {label: I18n('t_overdue'),key: 'overdue', className: 'overdue'}
]

export const asset_Keyfacts_List = [
  {label: 't_total_floor_area_gia', apikey: 'totalFloorArea', className: 'totalFloorArea'},
  {label: 't_last_refurbished', apikey: 'lastRefurbished', className: 'lastRefurbished'},
  {label: 't_financial_year_starts', apikey: 'financialYearStart', className: 'financialYearStart'},
]

export function getAssetPagesNavUrl(pathParams) {
  return {pathname: createNavURLForFundPages(pathParams)}
}