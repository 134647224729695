import React from 'react'
import { Skeleton } from 'primereact/skeleton'
import './FundNzcCardSkeleton.scss'
function FundNzcCardSkeleton() {
  return (
    <div>
      <div className={'fund-dashboard-nzc-card'}>
        <div className={'nzc-card-header'}>
          <div className={'header-label'}>
            <Skeleton width="160px" borderRadius="4px" height="15px" />
          </div>
          <div className={'header-year'}>
            <Skeleton width="120px" borderRadius="3px" height="50px" />
          </div>
        </div>
        <div className={'nzc-card-body'}>
          <div className={'nzc-chart'}>
            <div className="flex justify-content-around">
              <Skeleton width="0.01rem" borderRadius="3px 3px 0 0" height="17rem" />
            </div>
            <Skeleton width="160px" borderRadius="4px" height="15px" />
            <Skeleton width="160px" borderRadius="4px" height="15px" />

          </div>
        </div>
        <div className={'nzc-card-footer'}>
          <div className={'footer-label'}>
            <Skeleton width="160px" borderRadius="4px" height="15px" />
          </div>
          <div className={'footer-intensity'}>
            <Skeleton width="160px" borderRadius="4px" height="15px" />
          </div>
          <div className={'footer-baseline'}>
            <Skeleton width="160px" borderRadius="4px" height="15px" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FundNzcCardSkeleton
