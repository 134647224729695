import {useEffect, useState} from 'react'
import './DataQualityOverviewGraph.scss'
import I18n from '../../../../../utils/i18n/I18n'
import {prepareDQGraphData, removeOverlappingGraphLabel} from '../../../../../services/assets/dataquality-service'
import {GRAPH_LABELS} from '../../../../../utils/helpers/Constants'
import infoFull from '../../../../../resources/images/icon/info/info-full.svg'
import DataQualityCTA from '../../../../common/data-quality-cta/DataQualityCTA'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

function DataQualityOverviewGraph(props) {

  const {graphData, area } = props
  let graphDataItem
  const  [ selectedConsumptionTypeLandlord, setSelectedConsumptionTypeLandlord] = useState()
  const [showCTADialog, setShowCTADialog] = useState(false)
  const  expandGraphBarConfig=props.expandGraphBarConfig

  const  labels = GRAPH_LABELS
  const cssSelectorsForGraphLabels=`.overview-graph-container .dq-graph-labels .${area}`
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const CLASS_GRAPH_SEPARATOR='graph-separator'
  const CLASS_GRAPH_LABEL='graph-label'
  const CLASS_GRAPH='graph'
  let convertedPercent=[]
  let graphLabels=[]

  useEffect(() => {
    if(graphDataItem && expandGraphBarConfig){
      setSelectedConsumptionTypeLandlord(expandGraphBarConfig.graphColorLabel)
    }else{
      setSelectedConsumptionTypeLandlord(getMeterConsumptionType(graphDataItem))
    }
  }, [graphDataItem, expandGraphBarConfig])

  //For effects
  useEffect(()=>{
    removeOverlappingGraphLabel(cssSelectorsForGraphLabels)
  },[selectedLanguage])

  if(graphData?.length>0){
    graphDataItem=graphData.find(({responsbility}) => responsbility === area)
  }

  if(graphDataItem){
    prepareDQGraphData(graphDataItem, convertedPercent, graphLabels, labels)
  }

  function getMeterConsumptionType(dqGraphData) {
    let meterConsumptionType
    if (dqGraphData) {
      if (dqGraphData.missing > 0) {
        meterConsumptionType = 'Missing'
      } else if (dqGraphData.actual > 0) {
        meterConsumptionType = 'Actual'
      } else if (dqGraphData.estimated > 0) {
        meterConsumptionType = 'Estimate'
      } else if (dqGraphData.noCoverage > 0) {
        meterConsumptionType = 'nc'
      }
    }
    return meterConsumptionType
  }

  function onShowCTADialogHandler(){
    setShowCTADialog(true)
  }

  function onHideCTADialogHandler(){
    setShowCTADialog(false)
  }

  function getGraphGridUnits(){
    return convertedPercent.map((unit)=>{
      let unitValue='0%'
      if(unit){
        unitValue=`${unit}%`
      }
      return unitValue
    }).join(' ')
  }

  function getSingleBarGridStyle() {
    return {
      display: 'grid',
      gridTemplateColumns: getGraphGridUnits()
    }
  }

  const cssClassBuilder=(colorClass)=>`${CLASS_GRAPH} ${colorClass}`

  function getGraphLabelsKey(label){
    let msgKey=label
    if(label==='NC'){
      msgKey='not_covered_uc'
    }
    return msgKey.toLowerCase()
  }

  const getTrackLineSeparatorElements = () => convertedPercent.map((fr, index) => {
    let lineSeparatorNodeClass = fr === 0 ? '' : CLASS_GRAPH_SEPARATOR
    return <div key={index} className={lineSeparatorNodeClass} />
  })

  const getTrackElements = () => convertedPercent.map((fr, index) => {
    let graphNodeClasses
    let activeGraph = selectedConsumptionTypeLandlord?.toUpperCase() === getGraphLabelsKey(graphLabels[index])?.toUpperCase() ? 'active' : ''

    graphNodeClasses = fr === 0 ? CLASS_GRAPH : cssClassBuilder(graphLabels[index])
    return <div key={index+'-'+fr} className={`${graphNodeClasses} ${activeGraph}`} />
  })

  function removeFraction(number){
    return Math.trunc(number)
  }

  const getLegendElements = () => convertedPercent.map((fr, index) => {
    return (
      <div style={{flex: `1 1 ${fr}%`}} key={'legend-items-' +fr+index} className='dq-legends-wrap'>
        <span className={`dq-legend-item ${graphLabels[index]}`}></span>
        <div>
          <div className="percentage">{removeFraction(fr)}%</div>
          <div className={CLASS_GRAPH_LABEL} key={index}>
            <div className="dq-graph-label-inner-txt">{I18n('t_' + getGraphLabelsKey(graphLabels[index]))}</div>
          </div>
        </div>

      </div>
    )
  })

  const graphComponent=()=><div className="overview-graph-container">
    <div className="landlord-graph" key="graph-lablandlord" >
      <div className="legend-labels" key="lablandlord-missing-legend-labels" data-testid={'data-quality-legend-label'}>
        {getLegendElements()}
      </div>
      <div className="graph-separators" style={getSingleBarGridStyle()} key="lablandlord-graph-separators" data-testid={'data-quality-graph-separator'}>
        {getTrackLineSeparatorElements()}
      </div>
      <div className="graphs" style={getSingleBarGridStyle()} key="lablandlord-graphs" data-testid={'data-quality-track-element'} >
        {getTrackElements()}
      </div>
    </div>
    <DataQualityCTA isVisbile={showCTADialog} onShow={onShowCTADialogHandler} onHide={onHideCTADialogHandler} className={'asset_dataQuality_cta'} selectedLanguage={selectedLanguage} imageSrc={infoFull} data={'t_asset_procurement_data_categories'}/>
  </div>

  return graphDataItem ? graphComponent() : ''
}

export default DataQualityOverviewGraph