import { getEmptyRows, formatData, applyFundReportPAITableHeaderStyles } from 'utils/helpers/ExcelUtils'
import { getfundReportSheetHeaderInformation } from './fundReportPAIExcelExport'

export const createFundReportPAIOverviewSheet = (
  fundName,
  reportingYear,
  adverseImpactMetrics,
  adverseSustainabilityImpactMetrics,
  localize,
  excelSheet
) => {
  let excelDataArray = []
  excelDataArray.push(...getfundReportSheetHeaderInformation('t_fund_sfdr_pai_report', fundName, reportingYear, localize))
  excelDataArray.push(...getEmptyRows(1))
  excelDataArray = getAdverseImpactTable(excelDataArray, adverseImpactMetrics, reportingYear, localize, excelSheet)
  excelDataArray.push(...getEmptyRows(2))
  excelDataArray = getAdverseSustainabilityImpactTable(excelDataArray, adverseSustainabilityImpactMetrics, reportingYear, localize, excelSheet)
  return excelDataArray
}
  
export const applyStylingToOverviewSheet = (excelSheet, localize) => {
  const adverseImpactTableHeaderCellData = localize('t_adverse_impact')
  const adverseSustainabilityImpactTableHeaderCellData = localize('t_adverse_sustainability_impact')
  
  excelSheet.usedRange().forEach(cell => {
    if (cell.address() !== 'A1') {
      cell.style({fontSize: 12,  horizontalAlignment: 'left', verticalAlignment: 'center'})
    }
  
    if(cell.value() === adverseImpactTableHeaderCellData) {
      applyFundReportPAITableHeaderStyles(`A${cell.rowNumber()}:I${cell.rowNumber()}`, excelSheet)
      excelSheet.range(`B${cell.rowNumber()}:C${cell.rowNumber()}`).merged(true)
    }
  
    if(cell.value() === adverseSustainabilityImpactTableHeaderCellData) {
      applyFundReportPAITableHeaderStyles(`A${cell.rowNumber()}:E${cell.rowNumber()}`, excelSheet)
      excelSheet.range(`B${cell.rowNumber()}:C${cell.rowNumber()}`).merged(true)
    }
  })
}
    
export const getAdverseImpactTable = (currentExcelData, adverseImpactData, reportingYear, localize, excelSheet) => {
  if(!adverseImpactData) return currentExcelData
  
  const data = [...currentExcelData]
  data.push(...getAdverseImpactTableHeaderNames(localize, reportingYear))
  
  if(adverseImpactData.fossilFuels) {
    data.push(...[{
      A: `${localize('t_fossil_fuels')}: ${localize('t_adverse_impact_fossil_fuels')}`,
      B: localize('t_impact_value_fossil_fuels'),
      C: '',
      D: localize('t_percentage'),
      E: formatData(adverseImpactData.fossilFuels.currentYearImpact, localize),
      F: formatData(adverseImpactData.fossilFuels.previousYearImpact, localize),
      G: formatData(adverseImpactData.fossilFuels.yearOnYearTrend, localize),
      H: formatData(adverseImpactData.fossilFuels.explanation, localize),
      I: formatData(adverseImpactData.fossilFuels.actionsTaken, localize)
    }])
    excelSheet.range(`B${data.length}:C${data.length}`).merged(true)
  }
  
  if(adverseImpactData.energyEfficiency) {
    data.push(...[{
      A: `${localize('t_energy_efficiency')}: ${localize('t_adverse_impact_energy_efficiency')}`,
      B: localize('t_impact_value_energy_efficiency'),
      C: '',
      D: localize('t_percentage'),
      E: formatData(adverseImpactData.energyEfficiency.currentYearImpact, localize),
      F: formatData(adverseImpactData.energyEfficiency.previousYearImpact, localize),
      G: formatData(adverseImpactData.energyEfficiency.yearOnYearTrend, localize),
      H: formatData(adverseImpactData.energyEfficiency.explanation, localize),
      I: formatData(adverseImpactData.energyEfficiency.actionsTaken, localize)
    }])
    excelSheet.range(`B${data.length}:C${data.length}`).merged(true)
  }
  
  return data
}
  
export const getAdverseSustainabilityImpactTable = (currentExcelData, adverseSustainabilityImpactData, reportingYear, localize, excelSheet) => {
  if(!adverseSustainabilityImpactData) return currentExcelData
  
  const data = [...currentExcelData]
  data.push(...getAdverseSustainabilityImpactTableHeaderNames(localize, reportingYear))
    
  if(adverseSustainabilityImpactData.greenhouseGasEmissions) {
    const startRow = data.length + 1
    adverseSustainabilityImpactData.greenhouseGasEmissions.forEach((item, index) => {
      data.push(...[{
        A: index === 0 ? `${localize('t_adverse_impact_ghg_emissions')}: ${localize('t_adverse_impact_ghg_emissions_by_scope')}` : '',
        B: localize(getScopeValue(item.scope)),
        C: localize(getEstimatesValue(item.includingEstimates)),
        D: localize('t_tonnes_co2e'),
        E: formatData(item.currentYearImpact, localize)
      }])
    })
    excelSheet.range(`A${startRow}:A${data.length}`).merged(true).style({ horizontalAlignment: 'center', verticalAlignment: 'center' })
  }
    
  if(adverseSustainabilityImpactData.energyConsumption) {
    data.push(...[{
      A: `${localize('t_energy_consumption')}: ${localize('t_adverse_impact_energy_consumption')}`,
      B: localize('t_impact_value_energy_consumption'),
      C: '',
      D: 'GWh/m2',
      E: formatData(adverseSustainabilityImpactData.energyConsumption.currentYearImpact, localize)
    }])
    excelSheet.range(`B${data.length}:C${data.length}`).merged(true)
  }
  
  if(adverseSustainabilityImpactData.waste) {
    data.push(...[{
      A: `${localize('t_waste_utility')}: ${localize('t_adverse_impact_waste')}`,
      B: localize('t_impact_value_waste'),
      C: '',
      D: localize('t_percentage'),
      E: formatData(adverseSustainabilityImpactData.waste.currentYearImpact, localize)
    }])
    excelSheet.range(`B${data.length}:C${data.length}`).merged(true)
    excelSheet.row(data.length).height(55)
  }
    
  if(adverseSustainabilityImpactData.resourceConsumption) {
    data.push(...[{
      A: `${localize('t_resource_consumption')}: ${localize('t_adverse_impact_resource_consumption')}`,
      B: localize('t_impact_value_resource_consumption'),
      C: '',
      D: localize('t_percentage'),
      E: formatData(adverseSustainabilityImpactData.resourceConsumption.currentYearImpact, localize)
    }])
    excelSheet.range(`B${data.length}:C${data.length}`).merged(true)
  }
    
  if(adverseSustainabilityImpactData.biodiversity) {
    data.push(...[{
      A: `${localize('t_biodiversity')}: ${localize('t_adverse_impact_biodiversity')}`,
      B: localize('t_impact_value_biodiversity'),
      C: localize('t_vertical'),
      D: localize('t_percentage'),
      E: formatData(adverseSustainabilityImpactData.biodiversity.vertical, localize),
    },
    {
      A: '',
      B: '',
      C: localize('t_horizontal'),
      D: localize('t_percentage'),
      E: formatData(adverseSustainabilityImpactData.biodiversity.horizontal, localize),
    }])
    excelSheet.range(`A${data.length - 1}:A${data.length}`).merged(true)
    excelSheet.range(`B${data.length - 1}:B${data.length}`).merged(true)
    excelSheet.row(data.length - 1).height(70)
    excelSheet.row(data.length).height(70)
  }
  
  return data
}
  
const getAdverseImpactTableHeaderNames = (localize, reportingYear) => {
  return [{
    A: localize('t_adverse_impact'),
    B: localize('t_metric'),
    C: '',
    D: localize('t_units'),
    E: `${localize('t_impact')} ${reportingYear}`,
    F: `${localize('t_impact')} ${reportingYear - 1}`,
    G: localize('t_y_o_y_trend'),
    H: localize('t_explanation'),
    I: localize('t_actions_taken'),
  }]
}
  
export const getAdverseSustainabilityImpactTableHeaderNames = (localize, reportingYear) => {
  return [{
    A: localize('t_adverse_sustainability_impact'),
    B: localize('t_metric'),
    C: '',
    D: localize('t_units'),
    E: `${localize('t_impact')} ${reportingYear}`
  }]
}
  
const getScopeValue = (scope) => {
  if (scope) {
    switch (scope) {
    case 'Scope1':
      return 't_ghg_scope1'
    case 'Scope2':
      return 't_ghg_scope2'
    case 'Scope3':
      return 't_ghg_scope3'
    case 'All':
      return 't_ghg_scope_total'
    default:
      break
    }
  }
}
  
const getEstimatesValue = (includingEstimates) => (includingEstimates ? 't_including_estimates' : 't_actual_only')