import PropTypes from 'prop-types'
import {Accordion, AccordionTab} from 'primereact/accordion'
import {dateRangeFormatter} from '../../../utils/helpers/Helper'
import './CustomAccordion.scss'
import {useState} from 'react'
import I18n from '../../../utils/i18n/I18n'

const CustomAccordion = ({children, data, activeIndex,setActiveIndex, isAccordionDisabled=false}) => {
  const [accordionActiveIndex, setAccordionActiveIndex] = useState(activeIndex)

  function onAccordionTabChange(e) {
    setAccordionActiveIndex(e.index)
    setActiveIndex(e.index)
  }

  const setValidationMessages=(item,index)=>{
    let errorMessage = ''
    if(index!==activeIndex && item.isErrors && item.isDirty){
      errorMessage = <span className='p-error'>{I18n('t_validation_errors')}</span>
    }else if(index!==activeIndex && item.isWarnings && item.isDirty){
      errorMessage = <span className='p-error p-warning'>{I18n('t_validation_warning')}</span>
    }
    return <div className={'mt-1'}>
      {errorMessage}
    </div>
  }

  function getDateRange(item){
    return item.fromDate && item.toDate ?  dateRangeFormatter(item) : '-'
  }

  return <Accordion
    activeIndex={accordionActiveIndex}
    onTabChange={(e) => onAccordionTabChange(e)}
    className={'settings-accordion custom-sidebar-accordion'}
    role={'custom-sidebar-accordion'}>
    {data?.map((item, index) => {
      return (
        <AccordionTab disabled={isAccordionDisabled} tabIndex={index}
          header={<div className={'accordion-title-section'} role={'accordion-title'}>
            <span className="p-badge p-badge-info perf-col-text5">{index+1}</span><div>
              <div className={'accordion-title'}><span>{item.electricityMPAN}</span>
                <div className="mt-0_3">
                  <span className={'medium-text-3'}>{getDateRange(item)}</span>
                  {setValidationMessages(item,index)}
                </div>
              </div>
            </div>
          </div>
          }
          contentClassName={isAccordionDisabled ? '': 'accordion-border-bottom'}
          data-testid={`sidebar-accordion-${index}`}
          key={`${data.meterId}-${data.rangeId}`}>
          <div data-testid={`content-${index}`}>{children[index]}</div>
        </AccordionTab>
      )
    })}
  </Accordion>
}

CustomAccordion.propTypes = {
  children: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  activeIndex: PropTypes.any.isRequired,
  setActiveIndex: PropTypes.func.isRequired,
  isAccordionDisabled: PropTypes.bool,
}
CustomAccordion.displayName = 'CustomAccordion'

export default CustomAccordion