import {View, Text} from '@react-pdf/renderer'
import React, {useState} from 'react'
import styles from '../style-sheet/Styles'
import I18n from '../../i18n/I18n'
import { truncateDecimal, unitsConverter } from '../../helpers/Helper'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const UtilityForChart = (props) => {
  const [utility] = useState(props.utility)
  const {loginState: {userInfo}} = useLoginAuthContext()
  const unitSystem = userInfo.unitSystem

  const today = new Date(), currentYear = today.getFullYear(), previousYear = today.getFullYear()-1
  const formatUnits = (value) => {
    if(value!=null){
      return Number(value).toLocaleString(props.language)
    }
    return value
  }
  function units(utility){
    return unitsConverter(utility,unitSystem)
  }

  return (
    <View style={styles.tableRow}>
      <View style={[styles.smallBox,styles.mL45]}>
        <Text style={[styles.textSevenHalfRegular,styles.mT6]}>
          {formatUnits(truncateDecimal(utility.previousYearTotalConsumption,1))}{units(utility.utility)}
        </Text>
        <Text style={[styles.textSevenHalfRegular, styles.greyColor, {}]}>
          {previousYear} {utility.utility === 'Waste' ? 'Total recycling' : I18n('t_total')}
        </Text>
      </View>
      <View style={[styles.smallBox,styles.mL8, {}]}>
        <Text style={[styles.textSevenHalfRegular,styles.mT6]}>
          {formatUnits(truncateDecimal(utility.currentYearTotalConsumption,1))}{units(utility.utility)}
        </Text>
        <Text style={[styles.textSevenHalfRegular, styles.greyColor, {}]}>
          {currentYear} {utility.utility === 'Waste' ? 'Total recycling' : I18n('t_total')}
        </Text>
      </View>
      <View style={[styles.smallBox,styles.mL8, {}]}>
        <Text style={[styles.textSevenHalfRegular,styles.mT6]}>
          {truncateDecimal(utility.percentageChange*100,1)}%
        </Text>
        <Text style={[styles.textSevenHalfRegular, styles.greyColor, {}]}>{I18n('t_y_o_y')}</Text>
      </View>
    </View>
  )
}

export default UtilityForChart