import React from 'react'
import {
  Accordion as PrimeAccordion,
  AccordionTab,
} from 'primereact/accordion'
import PropTypes from 'prop-types'
import Icon from '../Icon/Icon'
import './Accordion.scss'
import Tooltip from '../Tooltip/Tooltip'
import Text from '../Text/Text'

const Accordion = ({ items, activeIndex, testId }) => {
  const accordionItems = items.map((item, index) => {
    const { title, leftContent, rightContent, warning, error, warningTextElement } =
      item
    const key = `tab_${index}`
    const headerContent = (
      <>
        {warning && (
          <>
            <Icon
              id='warning_solid_circle'
              colour='orange'
              size={17}
              testId={`accordion-tab-warning-${index}`}
              className={`accordion-tab-warning-${index}`}
            />
            {warningTextElement &&(<Tooltip target={`.accordion-tab-warning-${index}`}>
              {warningTextElement}
            </Tooltip>)}
          </>
        )}
        <Text 
          testId={`accordion-title-${index}`} 
          content={title} 
          weight='bold' 
          colour={error ? 'red' : 'teal'} />
      </>
    )

    return (
      <AccordionTab
        key={key}
        header={headerContent}
        data-testid={`accordion-tab-${index}`}
      >
        <div className="accordion-left" data-testid={`accordion-left-${index}`}>
          {leftContent}
        </div>
        <div
          className="accordion-right"
          data-testid={`accordion-right-${index}`}
        >
          {rightContent}
        </div>
      </AccordionTab>
    )
  })

  return (
    <PrimeAccordion
      className="siera-accordion"
      expandIcon="pi pi-chevron-down"
      collapseIcon="pi pi-chevron-up"
      activeIndex={activeIndex}
      data-testid={testId}
    >
      {accordionItems}
    </PrimeAccordion>
  )
}

export default Accordion

Accordion.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      /** If the title needs translated use the translation key.<br />
      * Entire list of keys can be found here: src\resources\messages\en-GB.js.<br />
      * For scenarios where translation is not necessary we just pass the raw data as the title.
      */
      title: PropTypes.string.isRequired,
      leftContent: PropTypes.node,
      rightContent: PropTypes.node,
      warning: PropTypes.bool,
      warningTextElement: PropTypes.node,
      error: PropTypes.bool,
    })
  ).isRequired,
  activeIndex: PropTypes.number,
  testId: PropTypes.string,
}