import {useContext, useEffect, useRef, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import './DataQualityHome.scss'
import I18n from '../../../../../utils/i18n/I18n'
import AssetExportSelection from '../../../../../utils/data-exports/AssetExportSelection'
import {PortfolioContext} from 'Contexts'
import {getInitialTimePeriod, getInitialPickerValue, getTimeperiodLabel, periodOptions} from '../../../../../services/common/datepicker-service'
import {createEndMonthParam, createNavURLForFundPages, disableExcelExport, setAssetData} from '../../../../../utils/helpers/Helper'
import {FUND_VIEWS_PAGES, monthListShortWithLocale, PERIOD_TYPES, MENU_ITEM_ALL,} from '../../../../../utils/helpers/Constants'
import {datePickerQueryValues, filterQueryParams,} from '../../../../../utils/query-params/QueryParams'
import useDataQualityHomeData from '../data-quality-hooks-&-helpers/useDataQualityHomeData'
import useAssetTimePeriodState from '../../../../../utils/custom-hooks/useAssetTimePeriodState'
import AssetLayout from '../../../../../layouts/asset/AssetLayout'
import {utilityListsDefault, areaListsDefault, isMissingDataLoading, resetExpandGraphBarConfigObject, getDataGapLandlord, getDataGapTenant, preloadMeterDataGaps, prepareMeterDataGapMenuItems, categoriesListsDefault, CATEGORIES_LIST, handleURLValues, handleDatePicker} from '../data-quality-hooks-&-helpers/dataQualityHelper'
import DataQualityHomeTabContent from './data-quality-home-tab-content/DataQualityHomeTabContent'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const DataQualityHome = () => {

  //region Declarations/Initializations
  const {instanceName, orgName, fundName, assetId} = useParams()
  const history = useHistory()
  const queryValues = new URLSearchParams(new URL(window.location.href).search)
  const exportOverlay = useRef(null)

  //Context values
  const pfContext = useContext(PortfolioContext)
  const rowData = pfContext.pfState.data
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const unitSystem = userInfo.unitSystem

  //For time period
  const {timePeriodState, updateTimePeriodState} = useAssetTimePeriodState(timePeriodInitializer)
  const time = getTimeperiodLabel(timePeriodState.timePeriod)

  // SET THE INITIAL STATE
  const [dataQualityHomeState, setDataQualityHomeState] = useState(setInitialDataQualityHomeState)

  //For query
  const {
    assetHeaderInfo,
    missingData,
    meterData,
    dqOverviewDataForLandlord,
    dqOverviewDataForTenant
  } = useDataQualityHomeData(assetId, dataQualityHomeState, time, timePeriodState)

  //PDF
  const selectedTimePeriod=I18n(timePeriodState.pickerValue.pickerValueMonths === '1' ? 't_picker_filter_value_single_month' : 't_picker_filter_value', {
    0: timePeriodState.pickerValue?.pickerValueMonths,
    1: monthListShortWithLocale[timePeriodState.pickerValue?.pickerValueEndMonth],
    2: timePeriodState.pickerValue?.pickerValueEndYear
  })
  //End

  //endregion

  // setting the initial states
  function setInitialDataQualityHomeState() {
    const selectedUtility =  queryValues.get('meterUtility') === null ? utilityListsDefault : {value: queryValues.get('meterUtility')}
    const selectedArea = queryValues.get('meterArea') === null ? areaListsDefault : {value: queryValues.get('meterArea')}

    return {
      qtrTextLabelLandlord: '',
      qtrTextLabelTenant: '',
      activeTabIndex: queryValues.get('view') === 'meter' ? 2 : 0,
      utilityListsLandlordMeterDataGap: [utilityListsDefault],
      utilityListsTenantMeterDataGap: [utilityListsDefault],
      selectedUtility,
      selectedUtilityLandlord: queryValues.get('landlordUtility') === null ? utilityListsDefault : {value: queryValues.get('landlordUtility')},
      selectedUtilityTenant: queryValues.get('tenantUtility') === null ? utilityListsDefault : {value: queryValues.get('tenantUtility')},
      areaListsMeterDataGap: [areaListsDefault],
      selectedArea,
      selectedAreaLandlord: queryValues.get('landlordArea') === null ? areaListsDefault : {value: queryValues.get('landlordArea')},
      selectedAreaTenant: queryValues.get('tenantArea') === null ? areaListsDefault : {value: queryValues.get('tenantArea')},
      selectedCategoryLandlord: queryValues.get('landlordCategory') === null ? categoriesListsDefault : {value: queryValues.get('landlordCategory')},
      selectedCategoryTenant: queryValues.get('tenantCategory') === null ? categoriesListsDefault : {value: queryValues.get('tenantCategory')},
      variablesMDList: [
        'missingDataList',
        assetId,
        time,
        timePeriodState.timeNoOfMonthsPeriod,
        timePeriodState.timeEndDatePeriod
      ],
      variablesMeterData: [
        'meterData',
        assetId,
        selectedUtility.value,
        selectedArea.value,
        time,
        timePeriodState.timeNoOfMonthsPeriod,
        timePeriodState.timeEndDatePeriod
      ],
      colorLabelLandlord: 'missing',
      colorLabelTenant: 'missing',
      isOverlayPanelOpen: false,
      customDateSummary: undefined,
      expandGraphBarConfigLandlord: null,
      expandGraphBarConfigTenant: null,
      dataGapLandlordSorted: [],
      dataGapTenantSorted: [],
    }
  }

  function timePeriodInitializer() {
    const pickerValue = getInitialPickerValue(queryValues)
    return {
      timePeriod: getInitialTimePeriod(queryValues),
      pickerValue,
      timeNoOfMonthsPeriod: pickerValue?.pickerValueMonths,
      timeEndDatePeriod: createEndMonthParam(pickerValue.pickerValueEndYear, pickerValue.pickerValueEndMonth),
    }
  }

  //region useEffects
  useEffect(()=>{
    if((!isMissingDataLoading(missingData) && missingData.data)){
      prepareMeterDataGapMenuItems(missingData.data, updateDataQualityHomeState)
      const dataGapLandlord = getDataGapLandlord(missingData)
      const dataGapTenant = getDataGapTenant(missingData)
      //No filter should be applied for Excel
      updateDataQualityHomeState({dataGapLandlordSorted: dataGapLandlord, dataGapTenantSorted: dataGapTenant})
    }
  }, [missingData.data])

  useEffect(() => {
    missingData.refetch()
  }, [unitSystem])

  useEffect(() => {
    if (assetHeaderInfo && !rowData) {
      setAssetData(assetHeaderInfo.assetAddress)
      pfContext.pfDispatcher({...pfContext.pfState, ...{data: assetHeaderInfo.data}})
    } else if (Object.keys(rowData).length === 1) {
      assetHeaderInfo.refetch()
    }
  }, [assetHeaderInfo])

  useEffect(() => {
    if(timePeriodState.timePeriod) {
      if(timePeriodState.timePeriod === PERIOD_TYPES.CUSTOM) {
        updateDataQualityHomeState({
          variablesMDList: [
            'missingDataList',
            assetId,
            time,
            timePeriodState.timeNoOfMonthsPeriod,
            timePeriodState.timeEndDatePeriod
          ],
          variablesMeterData: [
            'meterData',
            assetId,
            dataQualityHomeState.selectedUtility?.value,
            dataQualityHomeState.selectedArea?.value,
            time,
            timePeriodState.timeNoOfMonthsPeriod,
            timePeriodState.timeEndDatePeriod
          ]
        })
      } else {
        updateDataQualityHomeState({
          variablesMDList: [
            'missingDataList',
            assetId,
            time,
          ],
          variablesMeterData: [
            'meterData',
            assetId,
            dataQualityHomeState.selectedUtility?.value,
            dataQualityHomeState.selectedArea?.value,
            time,
          ]
        })
      }
    }
  },[
    time,
    timePeriodState.timePeriod,
    timePeriodState.timeNoOfMonthsPeriod,
    timePeriodState.timeEndDatePeriod,
    timePeriodState.pickerValue,
    missingData.data
  ])

  useEffect(()=>{
    if(dataQualityHomeState.activeTabIndex===0){
      preloadMeterDataGaps(dqOverviewDataForLandlord, dqOverviewDataForTenant, missingData, dataQualityHomeState, updateDataQualityHomeState)
    }
  }, [missingData.data, dataQualityHomeState.activeTabIndex, dqOverviewDataForLandlord.data, dqOverviewDataForTenant.data])

  useEffect(() => {
    let landlordUtility = dataQualityHomeState.selectedUtilityLandlord?.value
    let landlordArea = dataQualityHomeState.selectedAreaLandlord?.value
    let tenantUtility = dataQualityHomeState.selectedUtilityTenant?.value
    let tenantArea = dataQualityHomeState.selectedAreaTenant?.value
    let meterUtility = dataQualityHomeState.selectedUtility?.value
    let meterArea = dataQualityHomeState.selectedArea?.value
    let landlordCategory = dataQualityHomeState.selectedCategoryLandlord?.value
    let tenantCategory = dataQualityHomeState.selectedCategoryTenant?.value
    
    // datepicker
    let datePickerValue = datePickerQueryValues(timePeriodState.timePeriod, timePeriodState.pickerValue)
    handleDatePicker(datePickerValue, updateDatePickerQuery, updateTimePeriodState, timePeriodState)

    // datepicker
    // handling valid and invalid values from URL
    if(dataQualityHomeState.utilityListsLandlordMeterDataGap.length > 1 ||
        dataQualityHomeState.areaListsMeterDataGap.length>1 ||
        dataQualityHomeState.utilityListsTenantMeterDataGap.length > 1){
      landlordUtility = handleURLValues(
        dataQualityHomeState.utilityListsLandlordMeterDataGap,
        dataQualityHomeState.selectedUtilityLandlord.value
      )
      
      landlordArea = handleURLValues(
        dataQualityHomeState.areaListsMeterDataGap,
        dataQualityHomeState.selectedAreaLandlord.value
      )

      landlordCategory =  handleURLValues(
        CATEGORIES_LIST,
        dataQualityHomeState.selectedCategoryLandlord.value
      )

      tenantUtility = handleURLValues(
        dataQualityHomeState.utilityListsTenantMeterDataGap,
        dataQualityHomeState.selectedUtilityTenant.value
      )

      tenantArea = handleURLValues(
        dataQualityHomeState.areaListsMeterDataGap,
        dataQualityHomeState.selectedAreaTenant.value
      )

      tenantCategory =  handleURLValues(
        CATEGORIES_LIST,
        dataQualityHomeState.selectedCategoryTenant.value
      )

      updateDataQualityHomeState({
        selectedUtilityLandlord:  {value: landlordUtility},
        selectedAreaLandlord: {value: landlordArea},
        selectedUtilityTenant: {value: tenantUtility},
        selectedAreaTenant: {value: tenantArea},
        selectedCategoryLandlord: {value: landlordCategory},
        selectedCategoryTenant: {value: tenantCategory},
      })
    }
    // handling valid and invalid values from URL
    const queryParams = {
      view: dataQualityHomeState.activeTabIndex === 0 ? 'summary' : 'meter',
      landlordUtility: dataQualityHomeState.activeTabIndex === 0 ? landlordUtility : MENU_ITEM_ALL,
      landlordArea: dataQualityHomeState.activeTabIndex === 0 ? landlordArea : MENU_ITEM_ALL,
      tenantUtility: dataQualityHomeState.activeTabIndex === 0 ? tenantUtility : MENU_ITEM_ALL,
      tenantArea: dataQualityHomeState.activeTabIndex === 0 ? tenantArea : MENU_ITEM_ALL,
      meterUtility: dataQualityHomeState.activeTabIndex === 2 ? meterUtility : MENU_ITEM_ALL,
      meterArea: dataQualityHomeState.activeTabIndex === 2 ? meterArea : MENU_ITEM_ALL,
      landlordCategory:  dataQualityHomeState.activeTabIndex === 0 ? landlordCategory : MENU_ITEM_ALL,
      tenantCategory:  dataQualityHomeState.activeTabIndex === 0 ? tenantCategory : MENU_ITEM_ALL,
      timePeriod: datePickerValue.timePeriodSelected,
      noOfMonths: datePickerValue.noOfMonths,
      endMonth: datePickerValue.endMonth,
      endYear: datePickerValue.endYear
    }
    updateDataQualityHomeState({
      variablesMeterData: [
        'meterData', assetId, meterUtility, meterArea, time,
        timePeriodState.timeNoOfMonthsPeriod,
        timePeriodState.timeEndDatePeriod
      ]
    })
   
    const dqPage = createNavURLForFundPages({instanceName, orgName, fundName, assetId, pathSuffix: FUND_VIEWS_PAGES.dataQuality})
    if(history.location.pathname === dqPage) {
      filterQueryParams(history, queryParams, rowData)
    }
  }, [
    dataQualityHomeState.utilityListsLandlordMeterDataGap,
    dataQualityHomeState.areaListsMeterDataGap,
    queryValues.get('view'),
    dataQualityHomeState.activeTabIndex,
    dataQualityHomeState.selectedUtilityLandlord.value,
    dataQualityHomeState.selectedUtilityTenant.value,
    dataQualityHomeState.selectedAreaLandlord.value,
    dataQualityHomeState.selectedAreaTenant.value,
    dataQualityHomeState.selectedCategoryLandlord.value,
    dataQualityHomeState.selectedCategoryTenant.value,
    dataQualityHomeState.selectedUtility.value,
    dataQualityHomeState.selectedArea.value,
    timePeriodState.timePeriod,
    timePeriodState.pickerValue.pickerValueEndMonth,
    timePeriodState.pickerValue.pickerValueMonths,
    timePeriodState.pickerValue.pickerValueEndYear
  ])
  //endregion

  //region custom template
  const getPeriodMenuItem = (option) => (option === PERIOD_TYPES.YTD) ?
    <span className='p-button-label p-c period-menu-ytd-tooltip' data-pr-position="top">{option}
    </span> : <span className='p-button-label p-c'>{option}</span>
  //endregion

  //region of event handler and helper methods
  function updateDataQualityHomeState(newState) {
    setDataQualityHomeState(previousState => {
      return {
        ...previousState,
        ...newState
      }
    })
  }

  function updateDatePickerQuery(customPickerValues) {
    updateTimePeriodState( {pickerValue: customPickerValues })
  }

  function tabsClickHandler(index){
    updateDataQualityHomeState({activeTabIndex: index})
  }

  const closeOverlay = () => {
    exportOverlay.current.hide()
  }
  //endregion

  //region Datepicker handlers
  const periodChangeAction = (e) => {
    const value=e.value
    resetExpandGraphBarConfigObject(updateDataQualityHomeState)
    updateDataQualityHomeState({customDateSummary: undefined})
    if (value) {
      updateTimePeriodState({timePeriod: value})
    }
  }

  const clickCustomPeriodHandler = () => {
    resetExpandGraphBarConfigObject(updateDataQualityHomeState)
    updateTimePeriodState({
      timeNoOfMonthsPeriod: timePeriodState.pickerValue.pickerValueMonths,
      timeEndDatePeriod: createEndMonthParam(timePeriodState.pickerValue.pickerValueEndYear, timePeriodState.pickerValue.pickerValueEndMonth),
      timePeriod: PERIOD_TYPES.CUSTOM
    })
    updateDataQualityHomeState({customDateSummary: false})
  }

  const datePickerChangeAction = (e) => {
    const name=e.target?.name
    const value=e.target?.value
    const newPickerValue = {...(timePeriodState.pickerValue), [name]: value}
    updateTimePeriodState({pickerValue: newPickerValue})
  }
  //endregion

  // region props
  const dataQualityExportInfoProps = {
    'closeOverlay': closeOverlay, 'landlord': dataQualityHomeState.dataGapLandlordSorted, 'tenant': dataQualityHomeState.dataGapTenantSorted, 'asset': rowData, // not required
    'qtrLabelTenant': dataQualityHomeState.qtrTextLabelTenant, 'qtrLabelLandlord': dataQualityHomeState.qtrTextLabelLandlord,  'tenantExcel': getDataGapTenant(missingData),
    'period': timePeriodState.timePeriod === PERIOD_TYPES.CUSTOM ? selectedTimePeriod : timePeriodState.timePeriod, // need to be standardized
    'dataQuality': true, 'utilityL': dataQualityHomeState.selectedUtilityLandlord?.value, 'areaL': dataQualityHomeState.selectedAreaLandlord.value,
    'utilityT': dataQualityHomeState.selectedUtilityTenant?.value, 'areaT': dataQualityHomeState.selectedAreaTenant.value, 'utilityM': dataQualityHomeState.variablesMeterData[2],
    'areaM': dataQualityHomeState.variablesMeterData[3], 'landlordExcel': getDataGapLandlord(missingData), 'colorLabelTenant': dataQualityHomeState.colorLabelTenant,
    'colorLabelLandlord': dataQualityHomeState.colorLabelLandlord, 'exportFrom' : {'page-type':'asset-page','from':'asset-dataQuality'},
    'disableExcelExport':disableExcelExport({
      filteredLandlordData: dataQualityHomeState.dataGapLandlordSorted,
      filteredTenantData: dataQualityHomeState.dataGapTenantSorted
    }, 'assetDataQuality'),
  }

  function getDataQualityTabContentProps() {
    return {
      dataQualityHomeState,
      updateDataQualityHomeState,
      meterData,
      missingData,
      timePeriodState,
      time,
      assetId,
      selectedLanguage,
      rowData,
      selectedTimePeriod,
      dqOverviewDataForLandlord,
      dqOverviewDataForTenant
    }
  }

  function getBackNavigationProps() {
    return {
      exportName: 'Export',
      onExportClick: (e) => exportOverlay.current.toggle(e),
      disableExportButton: false,
      period: timePeriodState.timePeriod,
      options: periodOptions,
      datePickerOnChange: periodChangeAction,
      customDatePickerOnClick: clickCustomPeriodHandler,
      pickerOnChange: datePickerChangeAction,
      customDateSummary: dataQualityHomeState.customDateSummary,
      itemTemplate: getPeriodMenuItem,
      pickerValue: timePeriodState.pickerValue,
      customTime: false,
      isOverlayPanelOpen: dataQualityHomeState.isOverlayPanelOpen
    }
  }

  function getOverlayPanelProps() {
    return {
      ref: exportOverlay,
      appendTo: document.getElementById('export'),
      className: 'export-overlay',
      onShow: () => updateDataQualityHomeState({isOverlayPanelOpen: true}),
      onHide: () => updateDataQualityHomeState({isOverlayPanelOpen: false})
    }
  }
  //endregion

  function renderAssetExport() {
    return <AssetExportSelection exportDetails={dataQualityExportInfoProps}/>
  }

  return (
    <AssetLayout getBackNavigationProps={getBackNavigationProps} getOverlayPanelProps={getOverlayPanelProps}
      renderAssetExport={renderAssetExport} pageContainerClass={'dquality-page-container'}>
      <div className='dquality-home grey-bg'>
        <div className={'dq-tabview'}>
          <div className={'dq-tabview-panel'}>
            <div className={`dq-tab summary ${dataQualityHomeState.activeTabIndex === 0 ? 'dq-tab-active' : ''}`}
              onClick={() => tabsClickHandler(0)}>{I18n('t_title_summary')}</div>
            <div className={'dq-tab vline'}/>
            <div className={`dq-tab meter-view ${dataQualityHomeState.activeTabIndex === 2 ? 'dq-tab-active' : ''}`}
              onClick={() => tabsClickHandler(2)}>{I18n('t_title_meter_view')}</div>
          </div>
        </div>
        <DataQualityHomeTabContent {...getDataQualityTabContentProps()} />
      </div>
    </AssetLayout>
  )
}

export default DataQualityHome
