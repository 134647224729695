import React, {memo} from 'react'
import './OptionsFormFieldCheckbox.scss'
import {Checkbox} from 'primereact/checkbox'

const OptionsFormFieldCheckbox = ({propsConfig}) => {
  const {checkboxLabel, checkedState, onChangeHandler, inputId}=propsConfig

  return (
    <div className="options-form-field-checkbox p-field-checkbox" data-testid="show-stranding-dates-checkbox">
      <Checkbox className="osf-field-checkbox" inputId={inputId} checked={checkedState} onChange={onChangeHandler} />
      <label className="osf-field-label" htmlFor={inputId} data-testid="stranding-label">{checkboxLabel}</label>
    </div>
  )
}

export default memo(OptionsFormFieldCheckbox)