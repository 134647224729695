import React from 'react'
import PropTypes from 'prop-types'
import { Badge } from 'primereact/badge'
import './CustomBadge.scss'

const CustomBadge = ({ message, styling }) => {
  return <Badge value={message} className={`custom-badge text-xs font-normal border-round-xl ${styling}`} />
}

CustomBadge.propTypes = {
  message: PropTypes.string.isRequired,
  styling: PropTypes.string.isRequired,
}
CustomBadge.displayName = 'CustomBadge'
export default CustomBadge