import {Field} from 'formik'
import './NumberfieldFormik.scss'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { InputNumber } from 'primereact/inputnumber'

const NumberfieldFormik = ({ name, label, errors, touched, dataTestId, tooltipElement, suffix, placeholder, min}) => {
  const isTouched = touched ? touched[name] : true
  return (
    <div className={cn(errors[name] && isTouched ? 'p-invalid' : 'text-field', 'flex flex-column my-2 gap-1')}>
      <label className="relative" htmlFor={name}>
        {label}
        {tooltipElement ? <span className="ml-2">{tooltipElement}</span> : null}
      </label>
      <Field name={name}>
        {({ field, form: { setFieldValue } }) => (
          <InputNumber
            {...field}
            className={`${errors[name] && isTouched ? 'p-invalid' : ''}`} 
            data-testid={dataTestId} 
            value={field.value}
            suffix={suffix}
            onChange={(e) => setFieldValue(`${name}`, e.value)}
            placeholder={placeholder}
            min={min ?? null}
          />
        )}
      </Field>
      {errors[name] && isTouched && (
        <span className="error medium-text my-0" data-testid={`${dataTestId}-error`}>
          {errors[name]}
        </span>
      )}
    </div>
  )
}

NumberfieldFormik.propTypes = {
  label: PropTypes.string,
  errors: PropTypes.object,
  touched: PropTypes.object,
  tooltipElement: PropTypes.node,
  dataTestId: PropTypes.string,
  name: PropTypes.string,
  suffix: PropTypes.string,
  placeholder: PropTypes.string,
  min: PropTypes.number
}

export default NumberfieldFormik