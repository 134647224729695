import React, {} from 'react'
import {ConfirmDialog} from 'primereact/confirmdialog'
import './PopupDialog.scss'
import I18n from '../../../utils/i18n/I18n'

const PopupDialog = (props) => {

  /*
   * Pass classname as props to use default buttons
   */
  return (
    <ConfirmDialog
      visible={props.visible}
      header={props.header}
      footer={props.footer}
      onHide={props.onHide}
      className={props.className ? `p-confirm-dlg ${props.className} ` : 'p-confirm-dlg'}
      accept={props.accept}
      message={props.message}
      dismissableMask={props.dismissable}
      rejectLabel={props.rejectLabel ? props.rejectLabel : I18n('t_no')}
      acceptLabel={props.acceptLabel ? props.acceptLabel : I18n('t_yes')}
      blockScroll={props.blockScroll}
    />
  )
}

export default PopupDialog