import { useQueries } from 'react-query'
import {getAssetMetersV2, getMeterDetailsDataV2} from '../../../../../services/assets/dataquality-service'
import { noRefetchOnWindowFocus, retryCount } from '../../../../../services/common/useQuery-config'
import {getTimeperiodLabel} from '../../../../../services/common/datepicker-service'

const useDataQualityHomeDataV2 = (assetId, apiParams, responsibilities, unitSystem) => {
  const {
    timePeriod,
    timeNoOfMonthsPeriod: noOfMonths,
    timeEndDatePeriod: endMonth,
    meterUtility,
    meterArea,
    categorySelectedLandlord,
    categorySelectedTenant,
    utilitySelectedLandlord,
    utilitySelectedTenant,
    areaSelectedLandlord,
    areaSelectedTenant,
    tenantFirst,
    landlordFirst,
    tenantRows,
    landlordRows,
    searchMeterNameLandlord,
    searchMeterNameTenant,
  } = apiParams

  const {
    landlord,
    tenant,
  } = responsibilities

  const time = getTimeperiodLabel(timePeriod)

  const [
    {data: dqAssetMetersLandlord, isLoading: dqAssetMetersLandlordLoading},
    {data: dqAssetMetersTenant, isLoading: dqAssetMetersTenantLoading}, 
    {data: assetMetersDetailsData, isLoading: assetMetersDetailsLoading}] = useQueries([
    {
      queryKey: [
        'asset-meters-landlord', 
        assetId, 
        time, 
        noOfMonths, 
        endMonth, 
        utilitySelectedLandlord, 
        areaSelectedLandlord, 
        categorySelectedLandlord, 
        searchMeterNameLandlord, 
        landlordFirst, 
        landlordRows, 
        landlord, 
        unitSystem
      ],
      queryFn: getAssetMetersV2,
      retry: retryCount,
      ...getQueryConfig(!!assetId)
    },
    {
      queryKey: [
        'asset-meters-tenant', 
        assetId, 
        time, 
        noOfMonths, 
        endMonth, 
        utilitySelectedTenant, 
        areaSelectedTenant, 
        categorySelectedTenant, 
        searchMeterNameTenant, 
        tenantFirst, 
        tenantRows, 
        tenant, 
        unitSystem],
      queryFn: getAssetMetersV2,
      retry: retryCount,
      ...getQueryConfig(!!assetId)
    },
    {
      queryKey: [
        'asset-meters-details', 
        assetId, 
        time, 
        noOfMonths, 
        endMonth, 
        meterUtility, 
        meterArea,
        unitSystem],
      queryFn: getMeterDetailsDataV2,
      retry: retryCount,
      ...getQueryConfig(!!assetId)
    }
  ])

  function getQueryConfig(fetchOnMount) {
    return {...noRefetchOnWindowFocus, staleTime: 999999, enabled: fetchOnMount}
  }

  return {
    assetMetersLandlord: dqAssetMetersLandlord,
    assetMetersLandlordLoading: dqAssetMetersLandlordLoading,
    assetMetersTenantLoading: dqAssetMetersTenantLoading,
    assetMetersTenant: dqAssetMetersTenant,
    assetMetersDetails: assetMetersDetailsData,
    assetMetersDetailsLoading: assetMetersDetailsLoading
  }
}

export default useDataQualityHomeDataV2