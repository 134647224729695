import {useState} from 'react'

const useFundDataQualityState = (stateInitialiser) => {
  const [fundDataQualityState, setFundDataQualityState] = useState(stateInitialiser)

  function updateFundDataQualityState(newState) {
    setFundDataQualityState(previousState => {
      return {
        ...previousState,
        ...newState
      }
    })
  }

  return {fundDataQualityState, updateFundDataQualityState}
}

export default useFundDataQualityState