import React from 'react'
import PropTypes from 'prop-types'
import '../../StaticFundNetZeroCarbon.scss'
import { Text } from 'components/atomic/index'
import NZCPerformanceSummaryCardSkeleton from 'components/common/skeletons/NZC-summary-card-skeleton/NZCPerformanceSummaryCardSkeleton'

const StaticFundNZCPerformanceCard = ({ performanceData, isLoading }) => {
  if (isLoading) {
    return (
      <div className="w-10" data-testid='static-nzc-performance-loading'>
        <NZCPerformanceSummaryCardSkeleton />
      </div>
    )
  }

  return (
    <div className="static-net-zero-carbon__container" data-testid='static-nzc-performance'>
      <Text
        content="t_performance_assets_model"
        size="m"
        className="mb-1"
      />
      <div className="static-net-zero-carbon__card">
        {!performanceData ? (
          <Text content="t_model_no_data_available" />
        ) : (
          <>
            <Text
              content="t_reporting_year_value"
              contentDynamic={{ 0: performanceData.year}}
            />
            <div className="mt-4">
              <div className="static-net-zero-carbon__card--performance-item">
                <Text content="t_nzc_carbon_intensity" />
                <Text
                  content="t_carbon_intensity_value"
                  contentDynamic={{ 0: performanceData.carbonIntensity }}
                />
              </div>
              <div className="static-net-zero-carbon__card--performance-item">
                <Text content="t_cost_intensity" />
                <Text
                  content="t_cost_intensity_value"
                  contentDynamic={{ 0: performanceData.costIntensity }}
                />
              </div>
              <div className="static-net-zero-carbon__card--performance-item">
                <Text content="t_energy_intensity" />
                <Text
                  content="t_energy_intensity_value"
                  contentDynamic={{ 0: performanceData.energyIntensity }}
                />
              </div>
              <div className="static-net-zero-carbon__card--performance-item">
                <Text content="t_co2_emissions" />
                <Text
                  content="t_co2_emissions_value"
                  contentDynamic={{ 0: performanceData.co2Emission }}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

StaticFundNZCPerformanceCard.propTypes = {
  performanceData: PropTypes.shape({
    carbonIntensity: PropTypes.number,
    costIntensity: PropTypes.number,
    energyIntensity: PropTypes.number,
    co2Emission: PropTypes.number,
    year: PropTypes.number,
  }),
  isLoading: PropTypes.bool,
}

export default StaticFundNZCPerformanceCard