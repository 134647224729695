
import {noRefetchOnWindowFocus} from '../../../../../services/common/useQuery-config'
import {useQueries} from 'react-query'
import {GetAssetHeaderData} from '../../../../../services/assets/portfolio-service'
import {fetchPerformanceUtilitySummaryExport, getAssetCarbonPerformance, GetAssetEventsData, getAssetUtilityPerformance} from '../../../../../services/assets/performance-service'

const useAssetPerformanceData = (assetId, rowData, assetPerformanceDataQueryKey, selectedUtilityName, time, timePeriodState) => {

  const [
    {data: assetHeaderInfo},
    {data: utilitySummaryExportData},
    {data: assetEventsData, refetch: refetchAssetEventsData},
    {data: assetCarbonPerformanceData, isLoading: assetCarbonPerformanceDataLoading, refetch: refetchAssetCarbonPerformanceData},
    {data: assetUtilityPerformanceData, isLoading: assetUtilityPerformanceDataLoading, refetch: refetchAssetUtilityPerformanceData}
  ] = useQueries([
    {
      queryKey: ['asset-header-data', assetId],
      queryFn: GetAssetHeaderData,
      ...getQueryConfig(!!(assetId && (!rowData)))
    },
    {
      queryKey: ['asset-performance-data', assetId, time, 'All', timePeriodState.timeNoOfMonthsPeriod, timePeriodState.timeEndDatePeriod],
      queryFn: fetchPerformanceUtilitySummaryExport,
      ...getQueryConfig(!!assetId)
    },
    {
      queryKey: ['events-data', assetId],
      queryFn: GetAssetEventsData,
      ...getQueryConfig(!!assetId)
    },
    {
      queryKey: ['asset-carbon-performance', {assetId, time,selectedUtilityName, noOfMonthsPeriod: timePeriodState.timeNoOfMonthsPeriod, timeEndDate:timePeriodState.timeEndDatePeriod }],
      queryFn: getAssetCarbonPerformance,
      ...getQueryConfig(!!assetId)
    },
    {
      queryKey: ['asset-utility-performance', {assetId, time,selectedUtilityName, noOfMonthsPeriod: timePeriodState.timeNoOfMonthsPeriod, timeEndDate:timePeriodState.timeEndDatePeriod }],
      queryFn: getAssetUtilityPerformance,
      ...getQueryConfig(!!assetId)
    }
  ])


  function getQueryConfig(fetchOnMount) {
    return {...noRefetchOnWindowFocus, staleTime: 999999, enabled: fetchOnMount}
  }

  return {
    assetHeaderInfo,
    utilitySummaryExportData,
    assetEventsData,
    refetchAssetEventsData,
    assetCarbonPerformanceData,
    assetUtilityPerformanceData,
    assetCarbonPerformanceDataLoading,
    assetUtilityPerformanceDataLoading,
    refetchAssetCarbonPerformanceData,
    refetchAssetUtilityPerformanceData
  }
}

export default useAssetPerformanceData