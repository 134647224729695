import '../../pages/assets/action-plan/dialogs/Action.scss'
import React, {useEffect, useState} from 'react'
import {Panel} from 'primereact/panel'
import {InputTextarea} from 'primereact/inputtextarea'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Button} from 'primereact/button'
import I18n from '../../../utils/i18n/I18n'
import deleteIcon from '../../../resources/images/dialog/delete-highlight.svg'
import './CommonNote.scss'
import { getLocalizedValue } from '../../../utils/helpers/Helper'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const CommonNote = ({notesData, onSaveNote,isTextExist,errorMsgComponent,content, getNotesValue, isNotesValue = false}) => {
  const [edit, setEdit] = useState(false)
  const [count, setCount] = useState('')
  const [noteData, setNoteData] = useState(content ? content : '')
  const { loginState: { userInfo } } = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const options = {year: 'numeric', month: 'short', day: 'numeric'}
  const [selectedNote, setSelectedNote] = useState(null)
  const [noteId, setNoteId] = useState(null)
  const [noteToEdit, setNoteToEdit] = useState(false)
  const [hasNotesValue, setHasNotesValue] = useState(false)

  useEffect(()=>{
    //uncomment once noteId comes from back end
    noteSelection(selectedNote)
  },[noteToEdit])

  useEffect(()=>{
    if(notesData && notesData.length>0)
    {
      notesData.sort((first, next) => first.dateCreated > next.dateCreated ? 1 : -1)
    }
  },[])

  useEffect(()=>{
    if(selectedNote !== null){
      document.getElementById('noteText').focus()
    }
  })

  useEffect(()=>{
    if(!noteToEdit){
      const objDiv = document.getElementById('messageWindow')
      objDiv.scrollTop = objDiv.scrollHeight
    }
  },[onSaveNote])

  useEffect(() => {
    setHasNotesValue(isNotesValue)
  }, [isNotesValue])

  function fetchNotesValue(val){
    if(getNotesValue){
      getNotesValue(val)
    }
  }

  const onAddNote = (e) => {
    const val = (e.target && e.target.value) || ''
    setCount(val)
    setNoteData(val)
    fetchNotesValue(val)
    //add condition
    isTextExist(val.length>0,val)
    setHasNotesValue(false)
    if (val) {
      setEdit(true)
    } else {
      setEdit(false)
    }
  }

  const notificationPanel = (rowData) => {
    function deleteNote() {
      //uncomment when noteId comes from back end
      onSaveNote(rowData, edit,'delete')
      genericOperations()
    }

    return (
      <div className="note-content flex" onClick={()=>setNoteToEdit(!noteToEdit)}>
        <div className="pb-3 pt-3">
          <div className="post-text grey-text pb-2">
            {new Date(rowData.dateCreated).toLocaleDateString(selectedLanguage, options)}
                        - {rowData.userName} {rowData.dateEdited ? '(' +getLocalizedValue(selectedLanguage, 't_last_edit') + ' : '
                        + new Date(rowData.dateEdited).toLocaleDateString(selectedLanguage, options) + ')' : ''}
          </div>
          <div className={selectedNote === rowData ? 'bg-blue p-p-2 flex' : ' p-p-2 flex'}>
            <div className="content-text black">{rowData.content}</div>
            <div>{selectedNote === rowData ?
              <div className="p-opera flex edit_hover_class">
                {/*<img src={editIcon} alt="Edit" onClick={()=>setNoteToEdit(!noteToEdit)} className="p-pr-2"/>*/}
                <img src={deleteIcon} onClick={()=>deleteNote()} alt={getLocalizedValue(selectedLanguage, 't_delete')}/>
              </div> : null}
            </div>
          </div>
        </div>
      </div>
    )
  }

  function postNote() {
    //noteData-- content only
    //noteId-- noteId
    //edit-- is there any change in the form
    //selectedNote-- if null--new note, else update note
    onSaveNote(noteData, edit,!!selectedNote,noteId)
    if(selectedNote === null){
      setNoteToEdit(false)
    }
    genericOperations()
  }

  function genericOperations(){
    setNoteData('')
    setCount('')
    setSelectedNote(null)
    fetchNotesValue('')
    isTextExist(false, '')
  }

  function clear() {
    genericOperations()
  }

  function keyPress(e) {
    if(e.ctrlKey && e.keyCode === 13 && noteData !== ''){
      postNote()
      genericOperations()
    }
  }

  const renderFooter = () => {
    return (
      <div className="notes-footer">
        <div>
          <InputTextarea id="noteText" rows={2} value={noteData} cols={12} className="large-text" maxLength="240"
            placeholder={getLocalizedValue(selectedLanguage, 't_add_note_dots')} onChange={(e) => onAddNote(e)}
            onKeyDown={(e) => keyPress(e)} autoResize autoFocus/>
        </div>
        <div className="flex justify-content-between pt-4">
          <div>
            {count.length > 0 ? <span className="character-count">{count.length}/240</span> : null}
          </div>
          <div className={'flex'}>
            <Button className="cancel p-confirm-cancel action-highlight-clickable-opacity"
              onClick={clear} label={getLocalizedValue(selectedLanguage, 't_clear')} disabled={noteData === ''}/>
            <Button className="ml-3 save action-highlight-clickable-opacity" onClick={postNote} label={getLocalizedValue(selectedLanguage, 't_post')}
              disabled={noteData === ''}/>
          </div>
        </div>
        <div className='notes-error-block'>
          {hasNotesValue && !!noteData && <span className='notes-error-message'>{I18n('t_post_note_warning_message')}</span>}
        </div>
      </div>
    )
  }

  function noteSelection(rowData) {
    //set note id to update
    if(selectedNote) {
      setNoteId(rowData)
      setNoteData(rowData.content)
      fetchNotesValue(rowData.content)
      setCount(rowData.content)
      isTextExist(false)
    }
  }

  return (
    <div>
      <div className="flex">
        <Panel className="a-notes">
          <DataTable id="messageWindow" emptyMessage={getLocalizedValue(selectedLanguage, 't_no_notes')}
            value={notesData} className="body-notes" selectionMode="single" selection={selectedNote}
            onSelectionChange={(e)=>setSelectedNote(e.value)} metaKeySelection={true}>
            <Column field="notes" body={notificationPanel} bodyClassName="notes-action" style={{width: '100%'}}/>
          </DataTable>
        </Panel>
        <Panel className="a-notes post-note">
          {renderFooter()}
        </Panel>
      </div>
      {errorMsgComponent}
    </div>
  )
}

export default CommonNote
