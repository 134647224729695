import Text from '../Text/Text'
import PropTypes from 'prop-types'
import './Link.scss'

const LinkButton = (props) => {
  return (
    <button
      className={props.className ? `siera-link ${props.className}` : 'siera-link'}
      onClick={props.onClick}
      data-testid={props.testId}
      type={props.type}
    >
      <Text colour={props.colour} content={props.content} contentDynamic={props.contentDynamic} />
    </button>
  )
}

export default LinkButton

LinkButton.propTypes = {
  /** The translation id (ie. 't_assets').<br />
   * Entire list of keys can be found here: src\resources\messages\en-GB.js.<br />
   * For scenarios where translation is not necessary (email addresses, asset/meter/fund/user names, etc) we just pass the raw data as the content.
   */
  content: PropTypes.string,
  /**
   * Accepts an object with any number of items. <br /> 
   * The key/value pairs will then be inserted in the specified place in the string.
   */
  contentDynamic: PropTypes.object,
  colour: PropTypes.oneOf(['teal', 'red', 'blue']),
  /**
     * Callback function that gets invoked when the user clicks the button
     */
  onClick: PropTypes.func,
  /**
     * Optional: The testId to add to the button
     */
  testId: PropTypes.string,
  /**
     * Optional: The additional css class name to add to the button
     */
  className: PropTypes.string,
  /**
     * Optional: The type of the button
     */
  type: PropTypes.string
}

LinkButton.defaultProps = {
  colour: 'teal'
}

LinkButton.displayName = 'LinkButton'