
import { excelHelpers, formatDateToDdMonYy, getLocalizedValue } from '../../../helpers/Helper'
import { ActionImpactList } from '../../../helpers/Constants'
import { actionStatusList, impactList } from '../../../../components/common/action-plan/actionPlanTableTemplate'
import {  convertToExcelExportFormat,  EXPORT_TYPES,  formatDateToDDMMYY,  getEmptyRows,  getTableHeadersAfterLocalizationArray,
  getTableHeadersForExcelObject,  getTitleObject,  getValidData} from '../../ExportHelper'
import XlsxPopulate from 'xlsx-populate'
import FileSaver from 'file-saver'

const actionsByImpactKey = 'actionsByImpact'
export const fundActionPlanExportHandler = async ({props,selectedLanguage,listOfExportedItems,actionsByStatusInfo,actionsByImpactInfo,featureState}) => {
  function getActionPlanSummaryAndAssetTableByStatus(requiredAssetData, requiredFundSummaryData, statusMsgKey, fundId, sheetName) {

    function appendSheetHeaderInformationToExcelDataArray() {
      let headerInfo = {fundName: props?.selectedFund?.fundname,}
      return getfundAPSheetHeaderInformation(headerInfo, selectedLanguage)
    }

    let excelDataArray = []
    excelDataArray.push(...appendSheetHeaderInformationToExcelDataArray())
    let {
      summaryTableHeaderNames,
      summaryTableColLength
    } = getFundAPSummaryTableHeaderNames(`t_fund_${statusMsgKey}_actions_summary`, selectedLanguage, null, featureState)
    excelDataArray.push(...summaryTableHeaderNames)
    excelDataArray.push(...getFundAPSummaryTableRowData(requiredFundSummaryData, selectedLanguage,featureState))
    let {
      assetTableHeaderNames,
      assetTableColLength
    } = getFundAPAssetTableHeaderNames(`t_${statusMsgKey}_actions`, selectedLanguage)
    excelDataArray.push(...assetTableHeaderNames)
    excelDataArray.push(...getFundAPAssetTableRowData(requiredAssetData, selectedLanguage, sheetName))
    return { excelDataArray, summaryTableColLength, assetTableColLength, assetTableRowLength: requiredAssetData.length, summaryTableRowLength: 1}
  }

  function getActionPlanSummaryAndAssetTableByImpact(requiredAssetData, requiredFundSummaryData, impactMsgKey, sheetName, actionFilterKey) {

    function appendSheetHeaderInformationToExcelDataArray() {
      let headerInfo = {fundName: props?.selectedFund?.fundname,}
      return getfundAPSheetHeaderInformation(headerInfo, selectedLanguage)
    }

    let excelDataArray = []
    excelDataArray.push(...appendSheetHeaderInformationToExcelDataArray())
    let {summaryTableHeaderNames, summaryTableColLength} = getFundAPSummaryTableHeaderNames(`t_fund_actions_${impactMsgKey}_impact_summary`, selectedLanguage,actionFilterKey,featureState)
    excelDataArray.push(...summaryTableHeaderNames)
    excelDataArray.push(...getFundAPSummaryTableRowData(requiredFundSummaryData, selectedLanguage,featureState))
    let {assetTableHeaderNames, assetTableColLength} = getFundAPAssetTableHeaderNames(`t_actions_with_${impactMsgKey}_impact_title`, selectedLanguage, sheetName, actionFilterKey)
    excelDataArray.push(...assetTableHeaderNames)
    excelDataArray.push(...getFundAPAssetTableRowData(requiredAssetData, selectedLanguage, sheetName, actionsByImpactKey))
    return {excelDataArray, summaryTableColLength, assetTableColLength, assetTableRowLength: requiredAssetData.length, summaryTableRowLength: 1 }
  }

  const searchString = getLocalizedValue(selectedLanguage, 't_no_fund_actions_with_status')
  listOfExportedItems.map(async exportType => {

    if (exportType === EXPORT_TYPES.FUND.ACTIONS_BY_STATUS) {
      const blob = await XlsxPopulate?.fromBlankAsync().then(workbook => {
        actionStatusList.forEach((status, index) => {
          let filteredStatusKey = actionsByStatusInfo && Object.keys(actionsByStatusInfo).find(statusKey => statusKey.toLowerCase().includes(status.value.toLowerCase()))
          let requiredActionData = filteredStatusKey && actionsByStatusInfo[filteredStatusKey]['actionPlanDetails'] ? actionsByStatusInfo[filteredStatusKey]['actionPlanDetails'] : []
          let requiredActionSummaryData = filteredStatusKey && actionsByStatusInfo[filteredStatusKey]['actionPlanSummary'] ? actionsByStatusInfo[filteredStatusKey]['actionPlanSummary'] : {}
          let requiredDataToGenerateExcel = getActionPlanSummaryAndAssetTableByStatus(requiredActionData, requiredActionSummaryData, status.msgKey, props?.fundAssetListQueryOptions?.fundGroupId, status.value)
          const found = requiredDataToGenerateExcel.excelDataArray.some(obj => obj.hasOwnProperty('B') && obj['B'] === searchString)
          const excelSheet = index === 0 ? workbook.sheet(0).name(status.value) : workbook.addSheet(status.value)
          excelHelpers(requiredDataToGenerateExcel.excelDataArray, excelSheet, found ? searchString : 'No error')
        })
        workbook.activeSheet(getLocalizedValue(selectedLanguage, 't_agreed'))
        return workbook.outputAsync(workbook)
      })
      FileSaver.saveAs(blob, getLocalizedValue(selectedLanguage, 't_fund_action_plan_status_excel'))

    }
    if (exportType === EXPORT_TYPES.FUND.ACTIONS_BY_IMPACT) {
      const blob = await XlsxPopulate?.fromBlankAsync().then(workbook => {
        impactList.forEach((impact, index) => {
          let filteredImpactKey = actionsByImpactInfo && Object.keys(actionsByImpactInfo).find(impactKey => impactKey.toLowerCase().includes(impact.apiResponseKey.toLowerCase()))
          let requiredActionData = filteredImpactKey && actionsByImpactInfo[filteredImpactKey]['actionPlanDetails'] ? actionsByImpactInfo[filteredImpactKey]['actionPlanDetails'] : []
          let requiredActionSummaryData = filteredImpactKey && actionsByImpactInfo[filteredImpactKey]['actionPlanSummary'] ? actionsByImpactInfo[filteredImpactKey]['actionPlanSummary'] : {}
          let requiredDataToGenerateExcel = getActionPlanSummaryAndAssetTableByImpact(requiredActionData, requiredActionSummaryData, impact.msgKey, impact.value , actionsByImpactKey)
          const found = requiredDataToGenerateExcel.excelDataArray.some(obj => obj.hasOwnProperty('B') && obj['B'] === searchString)
          const excelSheet = index === 0 ? workbook.sheet(0).name(impact.value) : workbook.addSheet(impact.value)
          excelHelpers(requiredDataToGenerateExcel.excelDataArray, excelSheet, found ? searchString : 'No Error')
        })
        workbook.activeSheet(getLocalizedValue(selectedLanguage, 't_biodiversity'))
        return workbook.outputAsync(workbook)
      })
      FileSaver.saveAs(blob, getLocalizedValue(selectedLanguage, 't_fund_action_plan_impact_excel'))
    }
  })
}

export const getfundAPSheetHeaderInformation = (headerInfo, selectedLanguage) => {
  return [
    getTitleObject('t_fund_action_plan_report', selectedLanguage),
    {A: `${getLocalizedValue(selectedLanguage, 't_fund_name')} : ${headerInfo.fundName}`},
    {A: `${getLocalizedValue(selectedLanguage, 't_date_of_export')}: ${formatDateToDdMonYy()}`},
    ...getEmptyRows(2)
  ]
}

export const getFundAPSummaryTableHeaderNames = (tableTitle, selectedLanguage, actionFilterKey,featureState) => {
  let tableHeadersToBeLocalized
  if (!actionFilterKey) {
    tableHeadersToBeLocalized = [
      't_total_agreed_actions', 't_total_costs', 't_non_recoverable',
      't_un_costed_actions', ...( featureState.sieraplus_assetActionPlan ? ['t_net_change'] : [])
    ]
  } else {
    tableHeadersToBeLocalized = [
      't_total_actions_for_category', 't_total_costs', 't_non_recoverable',
      't_un_costed_actions', ...( featureState.sieraplus_assetActionPlan ? ['t_net_change'] : [])
    ]
  }
  let tableHeadersWithLocalization = getTableHeadersAfterLocalizationArray(tableHeadersToBeLocalized, selectedLanguage)
  return {
    summaryTableHeaderNames: [getTitleObject(tableTitle, selectedLanguage),
      getTableHeadersForExcelObject(tableHeadersWithLocalization, selectedLanguage)],
    summaryTableColLength: tableHeadersWithLocalization.length
  }
}

function getGresbMeasureColumnForExport(sheetName, isHeader) {
  switch (sheetName) {
  case ActionImpactList.Energy :
  case ActionImpactList.Tenant_Engagement :
  case ActionImpactList.Water :
  case ActionImpactList.Waste :
    return isHeader ? ['t_gresb_measure'] : true
  default:
    return isHeader? []: false
  }
}

// Es: Estimated Savings, Uom: Unit of Measurement
function getEsAndUomColumnsForExport(sheetName, isHeader) {
  switch (sheetName) {
  case ActionImpactList.Biodiversity :
  case ActionImpactList.Community_Engagement:
  case ActionImpactList.Health_And_Wellbeing:
  case ActionImpactList.Social_Value:
  case ActionImpactList.Tenant_Engagement:
    return isHeader ? [] : true
  default:
    return isHeader ? ['t_estimated_savings', 't_unit_of_measurement'] : false
  }
}

export const getFundAPAssetTableHeaderNames = (tableTitle, selectedLanguage, sheetName, actionFilterKey) => {
  let tableHeadersToBeLocalized
  if (!actionFilterKey) {
    tableHeadersToBeLocalized = [
      't_assets', 't_action', 't_due_date', ...(tableTitle === 't_completed_actions' ? ['t_completion_date'] : []), 't_impact', 't_utility_type', 't_estimated_savings', 't_unit_of_measurement',
      't_cost', 't_currency', 't_cost_type', 't_procured_by', 't_area_covered', 't_scope', 't_manager','t_notes'
    ]
  } else {
    tableHeadersToBeLocalized = [
      't_assets', 't_action', 't_status', 't_due_date', 't_completion_date', ...(sheetName === ActionImpactList.Energy ? ['t_utility_type'] : []),
      ...(getGresbMeasureColumnForExport(sheetName,true)),...(getEsAndUomColumnsForExport(sheetName, true)),
      't_cost', 't_currency', 't_cost_type', 't_procured_by', 't_area_covered', 't_scope', 't_manager', 't_notes'
    ]
  }

  let tableHeadersWithLocalization = getTableHeadersAfterLocalizationArray(tableHeadersToBeLocalized, selectedLanguage)
  return {
    assetTableHeaderNames: [getTitleObject(tableTitle, selectedLanguage),
      getTableHeadersForExcelObject(tableHeadersWithLocalization, selectedLanguage)],
    assetTableColLength: tableHeadersWithLocalization.length
  }
}
export const getFundAPSummaryTableRowData = (summaryData, selectedLanguage,featureState) => {
  return [{
    A: getValidData( summaryData.totalActionsCount,selectedLanguage),
    B: getValidData( summaryData.totalCost,selectedLanguage),
    C: getValidData( summaryData.nonRecoverable,selectedLanguage),
    D: getValidData( summaryData.unCosted,selectedLanguage),
    ...(featureState.sieraplus_assetActionPlan ? { E: getValidData(summaryData.netChange, selectedLanguage) } : {})
  }, ...getEmptyRows(2)]
}

export const getFundAPAssetTableRowData = (assetData, selectedLanguage, sheetName, actionFilterKey) => {
  let requiredAssetTableRowDataArray = []

  if (assetData.length > 0) {
    assetData.map(asset => requiredAssetTableRowDataArray.push(convertToExcelExportFormat([
      getValidData(asset.action.assetName,selectedLanguage),
      getValidData(asset.action.actionDescription, selectedLanguage),

      ...(!actionFilterKey ? [
        formatDateToDDMMYY(asset.action.dueDate, selectedLanguage),
        ...(sheetName === 'Completed' ? [formatDateToDDMMYY(asset.action.completion, selectedLanguage)] : []),
        getValidData(asset.action.impact, selectedLanguage),
        getValidData(asset.action.utilityType, selectedLanguage),
      ] : [
        getValidData(asset.action.status, selectedLanguage),
        formatDateToDDMMYY(asset.action.dueDate, selectedLanguage),
        formatDateToDDMMYY(asset.action.completion, selectedLanguage),
      ]),
      ...(sheetName === ActionImpactList.Energy ? [getValidData(asset.action.utilityType, selectedLanguage)] : []),
      ...(getGresbMeasureColumnForExport(sheetName, false) ? [getValidData(asset.action.performanceMeasure, selectedLanguage)] : []),
      ...(!actionFilterKey ? [
        getValidData(asset.action.target, selectedLanguage),
        getValidData(asset.action.unitOfMeasurement, selectedLanguage)
      ] :
        getEsAndUomColumnsForExport(sheetName, false) ? [] : [
          getValidData(asset.action.target, selectedLanguage),
          getValidData(asset.action.unitOfMeasurement, selectedLanguage)]
      ),
      getValidData(asset.action.cost, selectedLanguage),
      getValidData(asset.action.currency, selectedLanguage),
      getValidData(asset.action.costType, selectedLanguage),
      getValidData(asset.action.procuredBy, selectedLanguage),
      getValidData(asset.action.areaCovered, selectedLanguage),
      getValidData(asset.action.scope, selectedLanguage),
      getValidData(asset.action.manager, selectedLanguage),
      getValidData(asset.actionNotes.length > 0 ? asset.actionNotes.sort((item1, item2) => (item1.noteId < item2.noteId) ? 1 : -1)[0].content : '-', selectedLanguage),
    ]
    )))
  } else {
    requiredAssetTableRowDataArray.push({B: getLocalizedValue(selectedLanguage, 't_no_fund_actions_with_status')})
  }
  return requiredAssetTableRowDataArray
}