import React from 'react'
import { Skeleton } from 'primereact/skeleton'
import '../asset-row-skeleton/AssetRowSkeleton.scss'

const NZCModelSkeleton = () => {
  return (
    <div className="custom-skeleton p-rel mb-1">
      <div className="flex mt-4 ml-3 p-abs contour">
        <div>
          <Skeleton width="55rem" height="2rem" className="mb-2"/>
          <Skeleton width="52rem" height="2rem" className="mb-2"/>
          <Skeleton width="49rem" height="2rem" className="mb-2"/>
          <Skeleton width="46rem" height="2rem" className="mb-2"/>
        </div>
      </div>
      <Skeleton width="100%" borderRadius="0" height="8.25rem" animation="none"/>
    </div>
  )
}

export default NZCModelSkeleton