import React, {useEffect, useState} from 'react'
import {
  getUtilConsumptionTemplate,
  utilSubmittedByTemplate,
  utilTextTemplate
} from '../../../../../../utils/helpers/DataTableUtils'
import CustomAvatar from '../../../../../common/custom-avatar/CustomAvatar'
import I18n from '../../../../../../utils/i18n/I18n'
import {DataTable} from 'primereact/datatable'
import {noData} from '../../../../../../utils/helpers/MessageUtility'
import {Column} from 'primereact/column'
import {ConsumptionOperations} from '../../../data-quality/missing-data/table-footer/ConsumptionOperations'
import {MASK_DATA_QUALITY_KEYS} from '../../../../../../utils/helpers/Constants'
import './MeterViewAddEditConsumpiton.scss'
import ConsumptionNavigator from '../../../data-quality/consumption/ConsumptionNavigator'
import {createMissingDataReqObject} from '../../../../../../utils/helpers/Helper'
import {useParams} from 'react-router-dom'
import {useMutation} from 'react-query'
import {addNewMissingDataRequest, DeleteConsumption} from '../../../../../../services/assets/dataquality-service'
import PropTypes from 'prop-types'
import errorDialog from '../../../data-quality/error-dialog/ErrorDialog'

export const MeterViewAddEditConsumption = ({
  gapsPerMonth,
  dqMeterData,
  selectedLanguage,
  unitSystem,
  colorLabel,
  refetch,
  allData
}) => {
  const tableData = gapsPerMonth
  const [selectedRecords, setSelectedRecords] = useState([])
  const [isSidebarVisible, setIsSidebarVisible] = useState({
    isAddConsumptionBar: false,
    isEditConsumptionBar: false,
    isRequestConsumptionBar: false,
  })
  const [emailAddresses, setEmailAddresses] = useState([])
  const {assetId} = useParams()
  const missingDataRequestMutation = useMutation(addNewMissingDataRequest)
  const deleteConsumption = useMutation(DeleteConsumption)

  useEffect(() => {
    if (![isSidebarVisible.isAddConsumptionBar,
      isSidebarVisible.isEditConsumptionBar,
      isSidebarVisible.isRequestConsumptionBar].some(Boolean)) {
      setSelectedRecords([])
    }
  }, [isSidebarVisible])

  const sendDataHandler = async () => {
    try {
      await missingDataRequestMutation.mutateAsync({
        assetId,
        emails: emailAddresses,
        dataGap: createMissingDataReqObject(selectedRecords),
      })

      setShowRequestConfirmation(true)
    } catch (error) {
      console.log(error.toString())
    }
  }

  function onRowSelectionChange(e) {
    let newSelectedRecords = e.value
    setSelectedRecords(newSelectedRecords)
  }

  const utilConsumptionTemplate = (rowData) => {
    return getUtilConsumptionTemplate(rowData, selectedLanguage, unitSystem, colorLabel)
  }

  const utilEstimateTemplate = (rowData) => {
    const backgroundClass = rowData.estimate.toUpperCase()
    return <CustomAvatar styling={`avatar-size ${backgroundClass}`} shape={'circle'}/>
  }

  const meterInfoTemplate = (name, value) => {
    return <div className={'meter-data__row'}>
      <span className={'meter-data__row-row-label'}>{name}:</span>
      <span className={'meter-data__row-value comment__value'}>{value}</span>
    </div>
  }

  function updateConsumptionState(type) {
    setIsSidebarVisible({
      isAddConsumptionBar: type === 'ADD',
      isEditConsumptionBar: type === 'EDIT',
      isRequestConsumptionBar: type === 'REQUEST',
    })
  }

  const [showRequestConfirmation, setShowRequestConfirmation] = useState(false)

  const meterInfo = () => {
    return <div className={'meter-data'} role={'meter-data'}>
      {meterInfoTemplate(I18n('t_additional_information'), dqMeterData.meterComment?.length ? dqMeterData.meterComment : '-')}
      {meterInfoTemplate(I18n('t_serial_number_consumption'), dqMeterData.meterId)}
      {meterInfoTemplate(I18n('t_utility_type_consumption'), dqMeterData.utility)}
      {meterInfoTemplate(I18n('t_area_covered_consumption'), dqMeterData.areaCovered ? I18n('t_' + dqMeterData.areaCovered.toLowerCase()) : '-')}
      {meterInfoTemplate(I18n('t_meter_status'), dqMeterData.meterStatus ?? '-')}
      {meterInfoTemplate(I18n('t_submeter'), dqMeterData.isSubMeter ? I18n('t_yes') : I18n('t_no'))}
    </div>
  }

  function hideSideBar() {
    refetch()
    gapsPerMonth.map(resetData => {
      resetData.isErrors = false
      resetData.isDirty = false
      resetData.isWarnings = false
      resetData.isWarnAndError = false
      return resetData
    })
    setIsSidebarVisible({isRequestConsumptionBar: false, isAddConsumptionBar: false, isEditConsumptionBar: false})
  }

  async function refetchData(updatedData, response) {

    function isMissing(refetchedData, index, sourceData) {
      sourceData[index] = refetchedData.data.assetMeterDataQualitySummaries.filter(updatedData => {
        if (updatedData.isPartial) {
          return response.some(data =>
            new Date(updatedData.partialStart).toString() == new Date(data.dateStart.toString().replace('Z', '')) &&
                        new Date(updatedData.partialEnd).toString() == new Date(data.dateEnd).toString().replace('Z', '') &&
                        data.meterId === updatedData.meterId)
        } else {
          return response.some(data => new Date(updatedData.startDate).getMonth() == new Date(data.dateStart).getMonth() &&
            data.utility === updatedData.utility)
        }
      })[0]
    }

    function thanMissing(sourceData, index, refetchedData, data) {
      sourceData[index] = refetchedData.data.assetMeterDataQualitySummaries.filter(updatedData => updatedData.rangeId === data.rangeId)[0]
    }

    await allData.refetch().then((refetchedData) => {
      //Updating table data
      tableData.map((data, index) => {
        if (data.estimate !== 'Missing') {
          thanMissing(tableData, index, refetchedData, data)
        } else {
          isMissing(refetchedData, index, tableData)
        }
      })

      //updating selected Data
      selectedRecords.map((data, index) => {
        if (data.estimate !== 'Missing') {
          thanMissing(selectedRecords, index, refetchedData, data)
        } else {
          isMissing(refetchedData, index, selectedRecords)
        }
      })
    })
  }

  async function refetchAfterDelete(deletedArray) {
    async function updateDeletedRow(sourceData) {
      await allData.refetch().then((refetchedData) => {
        deletedArray.map(deletedData => {
          const updatingIndex = sourceData.findIndex(dataTogetIndex => dataTogetIndex.rangeId === deletedData.rangeId)
          const updatingData = sourceData.filter(dataTogetIndex => dataTogetIndex.rangeId === deletedData.rangeId)[0]
          const getUpdatedRecord = refetchedData.data.assetMeterDataQualitySummaries.filter(updatedData =>
            updatingData.startDate === updatedData.startDate && updatingData.meterId === updatedData.meterId)[0]
          if (updatingIndex !== -1) {
            sourceData[updatingIndex] = getUpdatedRecord
          }
        })
      })
    }

    await updateDeletedRow(tableData)
    await updateDeletedRow(selectedRecords)
  }

  async function deleteSelectedConsumption() {
    const constructObj = selectedRecords.map(data => ({
      rangeId: data.rangeId,
      meterId: data.meterId
    }))
    try {
      await deleteConsumption.mutateAsync([{assetId: assetId, data: constructObj}])
      await refetchAfterDelete(constructObj)
      refetch()
    } catch (e) {
      errorDialog({title: I18n('t_error_title'), message: 'Unable to delete consumption.'})
    }
  }

  return <div className={'dq-meter-sidebar-container'}>
    {meterInfo()}
    {tableData.length > 0 && <div className={'meter-datatable'} role={'meter-datatable'}>
      <DataTable emptyMessage={noData()} value={tableData} scrollHeight='350px' scrollable
        selectionMode="single" selection={selectedRecords}
        onSelectionChange={onRowSelectionChange}>
        <Column selectionMode="multiple" bodyClassName={'medium-text-3 meter-datatable__row'}
          headerClassName={'meter-datatable__header'} style={{width: '4.5%'}}/>
        <Column field="estimate" body={utilEstimateTemplate}
          bodyClassName={'medium-text-3 meter-datatable__row'}
          headerClassName={'meter-datatable__header'} style={{width: '4.5%'}}/>
        <Column field="dateSummary" header={I18n('t_date')}
          bodyClassName={'medium-text-3 meter-datatable__row'}
          headerClassName={'meter-datatable__header'} style={{width: '25%'}}/>
        <Column field="consumption" header={I18n('t_consumption')} body={utilConsumptionTemplate}
          bodyClassName={'medium-text-3 meter-datatable__row'}
          headerClassName={'meter-datatable__header'}
          style={{width: '20%'}}/>
        <Column field="source" header={I18n('t_origin')}
          body={(rowData) => utilTextTemplate(rowData, 'source')}
          bodyClassName={'medium-text-3 meter-datatable__row'}
          headerClassName={'meter-datatable__header'}
          style={{width: '20%'}}/>
        <Column field="submittedBy" header={I18n('t_submitted_by')}
          bodyClassName={'medium-text-3 meter-datatable__row'}
          body={(rowData) => utilSubmittedByTemplate(rowData, selectedLanguage, unitSystem, 'meter-datatable__submittedby')}
          headerClassName={'meter-datatable__header'} style={{width: '35%'}}/>
      </DataTable>
      <div className={'ml-3'}>
        <ConsumptionOperations selectedRecords={selectedRecords}
          allRecords={gapsPerMonth} addConsumption={(type) => updateConsumptionState(type)}
          filter={MASK_DATA_QUALITY_KEYS.All}
          editRecord={(type) => updateConsumptionState(type)}
          language={selectedLanguage}
          requestConsumption={(type) => updateConsumptionState(type)}
          deleteConsumption={deleteSelectedConsumption} isMeter={true}
        />
      </div>
    </div>
    }
    {((isSidebarVisible.isRequestConsumptionBar || isSidebarVisible.isAddConsumptionBar || isSidebarVisible.isEditConsumptionBar)
                && selectedRecords?.length > 0) &&
            <ConsumptionNavigator assetId={assetId} selectedData={selectedRecords} isSidebarVisible={isSidebarVisible}
              meterInfo={meterInfo()}
              setIsSidebarVisible={hideSideBar} setEmailAddresses={setEmailAddresses}
              sendDataHandler={sendDataHandler}
              isMeter={true} isMeterAndIsRequestConsumption={true}
              showRequestConfirmation={showRequestConfirmation}
              setShowRequestConfirmation={setShowRequestConfirmation} refetch={refetchData}/>
    }
  </div>
}

MeterViewAddEditConsumption.propTypes = {
  gapsPerMonth: PropTypes.array.isRequired,
  dqMeterData: PropTypes.object.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
  unitSystem: PropTypes.string.isRequired,
  colorLabel: PropTypes.string,
  refetch: PropTypes.func,
  allData: PropTypes.object
}