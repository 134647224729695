
import PropTypes from 'prop-types'
import I18n from '../../../../../utils/i18n/I18n'

const CommercialEventModalDialogHeader = ({eventActionsState, selectedLanguage}) => {
  const options = {year: '2-digit', month: 'short', day: 'numeric'}
  const unitName = eventActionsState.selectedRowEventData?.content?.filter(eventContent => eventContent.key === 'UnitName')[0]?.value
  const leaseBreakDesc = eventActionsState.selectedRowEventData?.content?.filter(eventContent => eventContent.key === 'LeaseBreakDescription')[0]?.value
  return (
    <div className="commercialEvent-ModalHeader">
      <span className="event-title">{`${unitName}, `} <span>{I18n('t_lease')}</span> {leaseBreakDesc}</span>
      <span className="event-date"> {new Date(eventActionsState.selectedRowEventData?.impactDate).toLocaleDateString(selectedLanguage, options)}</span>
    </div>
  )
}

export default CommercialEventModalDialogHeader

CommercialEventModalDialogHeader.displayName = 'CommercialEventModalDialogHeader'

CommercialEventModalDialogHeader.propTypes = {
  eventActionsState: PropTypes.object,
  selectedLanguage: PropTypes.string,
}