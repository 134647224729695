import { useContext, useEffect, useState } from 'react'
import buildQuery from 'odata-query'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import {getFillerImage, getPaginatorTemplate, CachedAssetIdAndValueState, unitsConverter, utilityIntensityMetricUnits, getLocalizedDateMessage, getLocalizedValue, onSorting, getDivisor, utilityIntensityImperialUnits, getUtilityUnits, createEndMonthParam,} from '../../../../../utils/helpers/Helper'
import I18n from '../../../../../utils/i18n/I18n'
import FeedbackRating from '../../../../common/feedback-icon/FeedbackRating'
import EmptyCellIcon from '../../../../../resources/images/icon/EmptyCellIcon.svg'
import AssetTableHeaderSkeleton from '../../../../common/skeletons/asset-table-header-skeleton/AssetTableHeaderSkeleton'
import AssetRowSkeleton from '../../../../common/skeletons/asset-row-skeleton/AssetRowSkeleton'
import {formatNumWODecimal, formatNumWOneDecimal, formatPercentage, trucateNumWOneDecimal} from '../../../../../services/common/formatter'
import './FundPerformanceAssetList.scss'
import {UTILITY_LABELS_OBJECT_TITLE_CASE, FUND_VIEWS_PAGES, AREA, TOP, ScopeTypes, UtilityTypes, SplitTypes} from '../../../../../utils/helpers/Constants'
import {getConsumtionSuffix} from '../FundPerformanceUtilitySuffixes'
import HyperLink from '../../../../common/HyperLink/HyperLink'
import {isTextOverflown} from '../../../../../utils/UtilsFunctions'
import { Tooltip } from 'primereact/tooltip'
import {UNITS} from '../../../../../utils/i18n/constants'
import CustomBadge from '../../../../common/custom-badge/CustomBadge'
import PropTypes from 'prop-types'
import {emptyDataMessage} from '../../../../../utils/helpers/MessageUtility'
import {Text} from '../../../../atomic'
import { getSortIcons } from 'utils/helpers/DataTableUtils'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'
import { FeatureFlagContext } from 'Contexts'
import { GetFundPerformanceAssetData } from 'services/funds/fund-performance-service'
import { noRefetchOnWindowFocus } from 'services/common/useQuery-config'
import { useQuery } from 'react-query'

const FundPerformanceAssetList = ({
  selectedUtility, 
  sortOrder, 
  fundGroupID, 
  performanceQueryParams, 
  isGapFillingSelected, 
  updateFundPerformanceState, 
  sortField, 
  fundUtilitiesList, 
  dataSelectionFilters,
  time,
  pickerValue,
  odataQueryString,
}) => {
  const {loginState: {userInfo}} = useLoginAuthContext()
  const {featureState} = useContext(FeatureFlagContext)
  const fundAssetPaginationFlag = featureState?.sieraplus_fundassetspagination
  const [first, setFirst] = useState(0)
  const [rows, setRows] = useState(20)
  const [page, setPage] = useState(0)
  const {
    isFetching: fundPerformanceAssetListLoading,
    data: fundPerformanceAssetListData,
  } = useQuery(['FundPerformanceAssetList', {
    'fundGroupId': fundGroupID,
    'odataQueryString': odataQueryString,
    'time': time,
    'timeNoOfMonths': pickerValue.pickerValueMonths,
    'timeEndDate': createEndMonthParam(pickerValue.pickerValueEndYear, pickerValue.pickerValueEndMonth),
    utility: selectedUtility,
    'page': fundAssetPaginationFlag ? page : null,
    'pageSize': fundAssetPaginationFlag ? rows : null,
    unitSystem: userInfo.unitSystem,
  }], GetFundPerformanceAssetData, {
    refetchOnWindowFocus: noRefetchOnWindowFocus.refetchOnWindowFocus,
    enabled: !!fundGroupID
  })
  const selectedLanguage = userInfo.languagePreference
  const unitSystem = userInfo.unitSystem
  const utilityColumn = fundUtilitiesList?.find(element => element.value === selectedUtility)
  const targetHeader = selectedUtility === UtilityTypes.Waste ? I18n('t_recycledwaste') : utilityColumn.label
  const fundAssetListClassName = fundPerformanceAssetListData?.data?.length ? '' : 'no-data-msg'
  const dataSelectionFiltersKeys= dataSelectionFilters?.map(option=>(option.key))
  const assetListCount = !fundAssetPaginationFlag ? fundPerformanceAssetListData?.data?.length : fundPerformanceAssetListData?.headers['x-total-count']

  const totalToActualScopeFieldsMap={
    scope1: 'actualScope1',
    scope2: 'actualScope2',
    scope3: 'actualScope3',
  }

  const totalToActualSplitFieldsMap= {
    landlord: 'landlordActual',
    tenant: 'tenantActual'
  }

  useEffect(() => {
    if (fundPerformanceAssetListData?.data && !fundPerformanceAssetListLoading) {
      const getAssetData = fundPerformanceAssetListData?.data || []
      const fetchAssetIdAndName = getAssetData.reduce(function(accumulator, currentValue){
        return {
          ...accumulator,
          [currentValue.assetId]: currentValue.name,
        }
      }, {})
      CachedAssetIdAndValueState.saveAssetIdAndValueToCache(fetchAssetIdAndName)
    }
  }, [fundPerformanceAssetListData?.data, selectedLanguage])

  useEffect(() => {
    setFirst(0)
    setRows(20)
    setPage(0)
  }, [selectedUtility])

  const onPageChange = (event) => {
    setFirst(event.first)
    setRows(event.rows)
    setPage(event.page)
  }

  const sortIconToggle = () => {
    if(fundAssetPaginationFlag) return 'no-sort'
    return sortOrder === 1 ? 'sort-up' : 'sort-down'
  }
  
  function valueWithSuffixCell (value, suffix, id, cellColor) {
    return value === null || value === undefined ?
      (
        <div className={'empty-cell tooltip-grid-empty-carbon-' + id}>
          <div className={'padding-no-data'}><img src={EmptyCellIcon} alt="EmptyCellIcon" /></div>
        </div>
      )
      :
      (
        <div className={`${cellColor !== undefined ? ' color-' + cellColor : ''}`}>
          <Text content={value} size='s'/> <Text className='suffix-padding' content={suffix} size='s'/>
        </div>
      )
  }

  function navLinkFundPerformanceProps(rowData){
    return {
      event: rowData,
      performanceQueryParams: performanceQueryParams,
      pathSuffix: FUND_VIEWS_PAGES.performance,
      queryParams: selectedUtility,
      assetName: rowData.assetAddress + ', ' + rowData.assetLocation
    }
  }

  const propertyDetailsTemplate = (rowData) => {
    const { assetAddress, assetLocation, dateOfSale, assetImageThumbnailUri } = rowData
    const propertyImage = getFillerImage(rowData, true)
    return (
      <HyperLink {...navLinkFundPerformanceProps(rowData)}>
        <Tooltip className={'portfolio-comm-tooltip long-text-tooltip'} target=".fund-performance-tooltip" position={TOP} />
        <div className="flex flex-column p-3 align-items-start">
          <div className="asset-image-address-wrapper cursor flex">
            <img src={propertyImage} alt="Property" className={assetImageThumbnailUri ? 'image-actual' : 'image-filler'} />
            <div className="asset-address-txt-wrapper">
              <div className="text-wrapper fund-performance-tooltip" onMouseEnter={(e)=>isTextOverflown(e)}
                data-ptf-tooltip={assetAddress}>{assetAddress}</div>
              <div className="regular-weight-font text-wrapper fund-performance-tooltip" onMouseEnter={(e)=>isTextOverflown(e)}
                data-ptf-tooltip={assetLocation}>{assetLocation}</div>
            </div>
          </div>
          <div className="w-full flex align-content-start p-0">
            { dateOfSale ? (
              <CustomBadge message={`${getLocalizedValue(selectedLanguage, 't_sold_label')} ${getLocalizedDateMessage(dateOfSale, 'MONTH_YEAR', selectedLanguage)}`} styling={'mt-3 text-xs font-normal'} />
            ): null }
          </div>
        </div>
      </HyperLink>
    )
  }

  const occupancypercentageTemplate = (rowData, colData) => {
    let rowDataValue = rowData[colData.field]    
    let value = rowDataValue !== null ? trucateNumWOneDecimal(rowDataValue): null
    return <HyperLink {...navLinkFundPerformanceProps(rowData)}>
      {valueWithSuffixCell(value, '%', rowData.assetId, true)}
    </HyperLink>
  } 
  
  function targetToDeltaTemplate (rowData, colData) {
    let utility = selectedUtility === UtilityTypes.GasFuelsAndThermals ? selectedUtility.charAt(0).toLowerCase() + selectedUtility.slice(1) :  selectedUtility.toLowerCase()
    if(!rowData[utility]) {
      return '-'
    }
    let targetToDeltaValue = rowData[utility][colData.field]
    if(!isGapFillingSelected){
      //gap-filled OFF
      if( selectedUtility !== UtilityTypes.Waste){
        const selectedUtilityData=rowData[utility]
        const actualConsumptionValue = selectedUtility === UtilityTypes.Carbon ? selectedUtilityData?.['actualConsumption'] : selectedUtilityData?.['actualTotalConsumption']
        if(actualConsumptionValue > 0 && selectedUtilityData?.previousActualConsumption > 0){
          targetToDeltaValue=(actualConsumptionValue-selectedUtilityData.previousActualConsumption)/selectedUtilityData.previousActualConsumption
        } else {
          targetToDeltaValue=0
        }
      }
    }
    let usesTarget = false

    if (utility !== UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE.toLowerCase()) {
      usesTarget = rowData[utility].usesTarget
    }
    return <HyperLink {...navLinkFundPerformanceProps(rowData)}>
      <div className="assets-performance-cell target-to-delta">
        <FeedbackRating variance={targetToDeltaValue} target={usesTarget} utility={utility} displayIcon={false} />
      </div>
    </HyperLink>

  }

  const percentageInUtilityDataTemplate = (rowData, colData) => {
    let utility = selectedUtility === UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL ?
      selectedUtility.charAt(0).toLowerCase() + selectedUtility.slice(1):  selectedUtility.toLowerCase()

    if(!rowData[utility]) {
      return '-'
    }
    let rowDataValue = rowData[utility][colData.field]
    let value = rowDataValue !== null ? formatPercentage(selectedLanguage, rowDataValue) : null
    let isMissingCol = colData.field === 'missing'
    
    if (isMissingCol && value !== null) {
      return <HyperLink {...navLinkFundPerformanceProps(rowData)}>
        {valueWithSuffixCell(value, '%', rowData.assetId, 'alert')}
      </HyperLink>
    }
    return <HyperLink {...navLinkFundPerformanceProps(rowData)}>
      {valueWithSuffixCell(value, '%', rowData.assetId)}
    </HyperLink>
  }

  function floorAreaTemplate (rowData) {
    let FloorAreaSymbol = unitsConverter(AREA,unitSystem)
    let floorAreaValue = rowData.floorArea !== null && rowData.floorArea !== undefined ? formatNumWODecimal(selectedLanguage, rowData.floorArea) : null
    return <HyperLink {...navLinkFundPerformanceProps(rowData)}>
      <div className="assets-performance-cell">
        {valueWithSuffixCell(floorAreaValue, FloorAreaSymbol, rowData.assetId)}
      </div>
    </HyperLink>
  }

  function getIntensityValue(selectedUtilityData) {
    let intensityValue = selectedUtilityData?.intensity
    
    if (isGapFillingSelected) {
      intensityValue = selectedUtilityData?.totalIntensity
    }

    return trucateNumWOneDecimal(intensityValue) ?? I18n('t_not_available')
  }

  function intensityTemplate (rowData) {
    let utility = selectedUtility === UtilityTypes.GasFuelsAndThermals ?  UtilityTypes.GasFuelsAndThermals:  selectedUtility

    let selectedUtilityInfo = selectedUtility ===UtilityTypes.GasFuelsAndThermals ? rowData[selectedUtility.charAt(0).toLowerCase() + selectedUtility.slice(1)] : rowData[utility.toLowerCase()]
    let intensityUnit = unitSystem === UNITS.METRIC ? utilityIntensityMetricUnits(utility) : utilityIntensityImperialUnits(utility)
    const intensityValue =  getIntensityValue(selectedUtilityInfo)

    if (intensityValue === null || isNaN(intensityValue)) {
      return '-'
    }

    return <HyperLink {...navLinkFundPerformanceProps(rowData)}>
      <div className="assets-performance-cell">
        {valueWithSuffixCell(intensityValue, intensityUnit, rowData.assetId)}
      </div>
    </HyperLink>
  }

  const totalWasteTemplate = (rowData) => {
    if(!rowData[UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE.toLowerCase()]) {
      return '-'
    }
    let rowDataValue = rowData[UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE.toLowerCase()].total

    if (rowDataValue !== null && rowDataValue !== undefined) {
      rowDataValue = formatNumWODecimal(selectedLanguage, rowDataValue)
    }
    return <HyperLink {...navLinkFundPerformanceProps(rowData)}>
      {valueWithSuffixCell(rowDataValue, 't', rowData.assetId)}
    </HyperLink>
  }

  const recycledWasteTemplate = (rowData) => {
    if(!rowData[UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE.toLowerCase()]) {
      return '-'
    }

    let rowDataValue = rowData[UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE.toLowerCase()].recycled

    if (rowDataValue !== null && rowDataValue !== undefined) {
      rowDataValue = formatNumWODecimal(selectedLanguage, rowDataValue)
    }

    return <HyperLink {...navLinkFundPerformanceProps(rowData)}>
      {valueWithSuffixCell(rowDataValue, 't', rowData.assetId)}
    </HyperLink>
  }

  function getUtilFieldName(selectedUtility) {
    let utilFieldName = selectedUtility === UtilityTypes.Carbon ? 'actualConsumption' : 'actualTotalConsumption'
    return isGapFillingSelected ? 'consumption' : utilFieldName
  }

  const consumptionTemplate = (rowData) => {
    let utility = selectedUtility === UtilityTypes.GasFuelsAndThermals ?
      selectedUtility.charAt(0).toLowerCase() + selectedUtility.slice(1)
      :
      selectedUtility.toLowerCase()

    if(!rowData[utility]) {
      return '-'
    }

    let rowDataValue =  rowData[utility][getUtilFieldName(selectedUtility)]
    let suffix = getConsumtionSuffix(selectedUtility, rowDataValue)

    // Override for carbon where API is already dividing by 1000 (used in export SPR-5019)
    if(selectedUtility === UtilityTypes.Carbon && rowDataValue){
      rowDataValue = rowDataValue < 10 ? formatNumWOneDecimal(selectedLanguage, rowDataValue) : formatNumWODecimal(selectedLanguage, rowDataValue)
    } else if(selectedUtility === UtilityTypes.Water && rowDataValue) {
      rowDataValue = formatNumWODecimal(selectedLanguage, rowDataValue * 1000 )
    } else {
      let divisor = getDivisor(selectedUtility, rowDataValue)
      rowDataValue = rowDataValue < 10 ? formatNumWOneDecimal(selectedLanguage, rowDataValue / divisor) : formatNumWODecimal(selectedLanguage, rowDataValue / divisor)
    }
    return <HyperLink {...navLinkFundPerformanceProps(rowData)}>
      {valueWithSuffixCell(rowDataValue, suffix, rowData.assetId)}
    </HyperLink>
  }

  const scopeTemplate = (rowData, colData) => {
    const colDataField=isGapFillingSelected ? colData?.field : totalToActualScopeFieldsMap[colData?.field]
    let rowDataValue = rowData?.carbon?.[colDataField]
    if (!rowDataValue) return '-'
    const suffix =  getUtilityUnits(true,selectedUtility)
    // Override for carbon where API is already dividing by 1000 (used in export SPR-5019)
    if (selectedUtility === UtilityTypes.Carbon && rowDataValue !== null && rowDataValue !== undefined) {
      rowDataValue = formatNumWODecimal(selectedLanguage, rowDataValue)
    } else {
      let divisor = getDivisor(selectedUtility, rowDataValue)
      rowDataValue = formatNumWODecimal(selectedLanguage, rowDataValue ?? 0 / divisor)
    }

    return <>{valueWithSuffixCell(rowDataValue, suffix, rowData.assetId)}</>
  }

  const scopeColumnHeader = (columnName) => {
    if (selectedUtility !== UtilityTypes.Waste){
      return I18n(columnName)
    }
  }

  const sectorTargetTemplate = (data) => {
    return<HyperLink {...navLinkFundPerformanceProps(data)}>
      <div className="assets-performance-cell">
        <span className="asset-sector">{data.sector}</span>
      </div>
    </HyperLink>
  }

  const onSort = (e) => {
    let orderRev = onSorting(e, sortOrder, sortField, 'assetAddress')
    let sortOData = orderRev === 1 ? 'asc' : 'desc'
    updateFundPerformanceState({
      sortField: e.sortField,
      sortOrder: orderRev,
      oDataSortOrder: sortOData,
      odataQueryString: buildQuery({orderBy: `${e.sortField} ${sortOData}`})
    })
  }

  const splitTemplate = (rowData, colData) => {
    const colDataField=isGapFillingSelected ? colData?.field : totalToActualSplitFieldsMap[colData?.field]
    let rowDataValue
    switch (selectedUtility) {
    case UtilityTypes.Electricity:
      rowDataValue = rowData?.electricity?.[colDataField] ?? null
      break
    case UtilityTypes.GasFuelsAndThermals:
      rowDataValue = rowData?.gasFuelsAndThermals?.[colDataField] ?? null
      break
    case UtilityTypes.Water:
      rowDataValue = rowData?.water?.[colDataField] ?? null
      break
    default:
      return null
    }

    const suffix =  getUtilityUnits(true, selectedUtility)
    let divisor = getDivisor(selectedUtility, rowDataValue)
    if (!rowDataValue) return '-'
    rowDataValue = formatNumWODecimal(selectedLanguage, rowDataValue / divisor)
    return <>{valueWithSuffixCell(rowDataValue, suffix, rowData.assetId)}</>
  }

  function isScopeSelected(scope) {
    return selectedUtility === UtilityTypes.Carbon && dataSelectionFiltersKeys?.includes(scope)
  }

  function isSplitTypeSelected(splitType) {
    return [UtilityTypes.Electricity, UtilityTypes.Water, UtilityTypes.GasFuelsAndThermals].includes(selectedUtility) && dataSelectionFiltersKeys?.includes(splitType)
  }

  function getConsumptionHeader() {
    let header
    if(selectedUtility === UtilityTypes.Waste){
      header=I18n('t_recycledwaste')
    } else if(selectedUtility === UtilityTypes.Carbon){
      header=(dataSelectionFilters?.length > 0 ? I18n('t_total_emissions') : I18n('t_carbon_emissions'))
    } else {
      header=utilityColumn.label
    }
    return header
  }

  function getScopeSortField(scope) {
    const colDataField=isGapFillingSelected ? scope : totalToActualScopeFieldsMap[scope]
    return `${selectedUtility?.toLowerCase()}/${colDataField}`
  }

  function getSplitSortField(split) {
    const colDataField=isGapFillingSelected ? split : totalToActualSplitFieldsMap[split]
    return `${selectedUtility?.toLowerCase()}/${colDataField}`
  }

  function getIntensityFieldName() {
    return isGapFillingSelected ? 'totalIntensity' : 'intensity'
  }

  return (
    <div className="performance-asset-list">
      <div className="performance-asset-list-wrapper">
        {fundPerformanceAssetListLoading || !fundGroupID?
          (
            <>
              <AssetTableHeaderSkeleton />
              <AssetRowSkeleton />
              <AssetRowSkeleton />
              <AssetRowSkeleton />
            </>
          ) :
          <div  className={fundAssetListClassName}>
            <DataTable
              value={fundPerformanceAssetListData?.data}
              className={'p-datatable-gridlines'}
              dataKey="id"
              onSort={!fundAssetPaginationFlag && onSort}
              sortField={!fundAssetPaginationFlag && sortField}
              sortOrder={!fundAssetPaginationFlag && sortOrder}
              emptyMessage={emptyDataMessage()}
              lazy={fundAssetPaginationFlag}
              defaultSortOrder={-1}
              paginator={assetListCount > 20}
              first={first}
              rows={rows}
              paginatorTemplate={getPaginatorTemplate('items-per-page-options-white')}
              paginatorClassName={'data-table-paginator'}
              onPage={onPageChange}
              totalRecords={assetListCount}
              sortIcon={!fundAssetPaginationFlag && getSortIcons()}
            >
              <Column
                field="address"
                sortField="assetAddress"
                header={I18n('t_name')}
                headerClassName={`table-header-style ${sortIconToggle()}`}
                body={propertyDetailsTemplate}
                style={{ width: '25%' }}
                sortable={!fundAssetPaginationFlag}
              />
              <Column
                field="total"
                sortField="waste/total"
                header={I18n('t_total_waste')}
                headerClassName={`table-header-style ${sortIconToggle()}`}
                body={totalWasteTemplate}
                style={{ width: '7%', display: selectedUtility !== UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE && 'none' }}
                sortable={!fundAssetPaginationFlag}
              />
              <Column
                field="consumption"
                sortField={`${selectedUtility.toLowerCase()}/${getUtilFieldName(selectedUtility)}`}
                header={getConsumptionHeader}
                headerClassName={`table-header-style ${sortIconToggle()}`}
                body={consumptionTemplate}
                style={{ width: '11%', display: selectedUtility === UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE && 'none'}}
                sortable={!fundAssetPaginationFlag}
              />
              {isScopeSelected(ScopeTypes.scope1) && <Column
                field={ScopeTypes.scope1}
                sortField={getScopeSortField(ScopeTypes.scope1)}
                header={scopeColumnHeader('t_scope1_emissions')}
                headerClassName={`table-header-style ${sortIconToggle()}`}
                body={scopeTemplate}
                sortable={!fundAssetPaginationFlag}
              />}
              {isScopeSelected(ScopeTypes.scope2) &&  <Column
                field={ScopeTypes.scope2}
                sortField={getScopeSortField(ScopeTypes.scope2)}
                header={scopeColumnHeader('t_scope2_emissions')}
                headerClassName={`table-header-style ${sortIconToggle()}`}
                body={scopeTemplate}
                sortable={!fundAssetPaginationFlag}
              />
              }
              {isScopeSelected(ScopeTypes.scope3) &&  <Column
                field={ScopeTypes.scope3}
                sortField={getScopeSortField(ScopeTypes.scope3)}
                header={scopeColumnHeader('t_scope3_emissions')}
                headerClassName={`table-header-style ${sortIconToggle()}`}
                body={scopeTemplate}
                sortable={!fundAssetPaginationFlag}
              />
              }
              { isSplitTypeSelected(SplitTypes.landlord) && <Column
                field={SplitTypes.landlord}
                sortField={getSplitSortField(SplitTypes.landlord)}
                header={scopeColumnHeader('t_landlord')}
                headerClassName={`table-header-style ${sortIconToggle()}`}
                body={splitTemplate}
                sortable={!fundAssetPaginationFlag}
              />
              }
              { isSplitTypeSelected(SplitTypes.tenant) && <Column
                field={SplitTypes.tenant}
                sortField={getSplitSortField(SplitTypes.tenant)}
                header={scopeColumnHeader('t_tenant')}
                headerClassName={`table-header-style ${sortIconToggle()}`}
                body={splitTemplate}
                sortable={!fundAssetPaginationFlag}
              />
              }
              {isGapFillingSelected && <Column
                field="missing"
                sortField={`${selectedUtility.toLowerCase()}/missing`}
                header={I18n('t_gap_filled')}
                headerClassName={`table-header-style ${sortIconToggle()}`}
                body={percentageInUtilityDataTemplate}
                style={{ width: '10%', display: selectedUtility === UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE && 'none' }}
                sortable={!fundAssetPaginationFlag}
              /> }
              <Column
                field="recycled"
                sortField="waste/recycled"
                header={I18n('t_recycledwaste')}
                headerClassName={`table-header-style ${sortIconToggle()}`}
                body={recycledWasteTemplate}
                style={{ width: '8%', display: selectedUtility !== UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE && 'none' }}
                sortable={!fundAssetPaginationFlag}
              />
              <Column
                field="percentageRecycled"
                sortField="waste/percentageRecycled"
                header={<>% {I18n('t_recycled')}</>}
                headerClassName={`table-header-style ${sortIconToggle()}`}
                body={percentageInUtilityDataTemplate}
                style={{ width: '11%', textTransform: 'lowercase', display: selectedUtility !== UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE && 'none' }}
                sortable={!fundAssetPaginationFlag}
              />
              <Column
                field="targetToDelta"
                sortField={`${selectedUtility.toLowerCase()}/targetToDelta`}
                header={<>{targetHeader} {I18n('t_target_delta')}</>}
                headerClassName={`table-header-style ${sortIconToggle()}`}
                body={targetToDeltaTemplate}
                style={{ width: '14%' }}
                sortable={!fundAssetPaginationFlag}
              />
              <Column
                field="floorArea"
                sortField="floorArea"
                header={I18n('t_floor_area')}
                headerClassName={`table-header-style ${sortIconToggle()}`}
                body={floorAreaTemplate}
                style={{ width: '11%' }}
                sortable={!fundAssetPaginationFlag}
              />
              <Column
                field='totalIntensity'
                sortField={`${selectedUtility.toLowerCase()}/${getIntensityFieldName()}`}
                header={I18n('t_intensity')}
                headerClassName={`table-header-style ${sortIconToggle()}`}
                body={intensityTemplate}
                style={{width: '11%',display: selectedUtility === UtilityTypes.Waste && 'none' }}
                sortable={!fundAssetPaginationFlag}
              />
              <Column
                field="occupancy"
                header={I18n('t_occupancy')}
                headerClassName={`table-header-style ${sortIconToggle()}`}
                body={occupancypercentageTemplate}
                style={{ width: '10%'}}
                sortable={!fundAssetPaginationFlag}
              />
              <Column
                field="sector"
                header={I18n('t_sector')}
                headerClassName={`table-header-style ${sortIconToggle()}`}
                style={{ width: '11%'}}
                sortable={!fundAssetPaginationFlag}
                body={(e) => sectorTargetTemplate(e)}
              />
            </DataTable>
          </div>
        }
      </div>
    </div>
  )
}

FundPerformanceAssetList.propTypes = {
  fundGroupID:PropTypes.number,
  selectedBreakdown: PropTypes.array,
  selectedUtility: PropTypes.string,
  isGapFillingSelected: PropTypes.bool,
  dataSelectionFilters: PropTypes.array,
  updateFundPerformanceState: PropTypes.func,
  time: PropTypes.string,
  pickerValue: PropTypes.object,
  odataQueryString: PropTypes.string,
  performanceQueryParams: PropTypes.object,
  sortOrder: PropTypes.number,
  sortField: PropTypes.string,
  fundUtilitiesList: PropTypes.array,
}
export default FundPerformanceAssetList
FundPerformanceAssetList.displayName = 'FundPerformanceAssetList'
