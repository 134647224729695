
export const T_TARGET_PATHWAY_1='t_target_pathway_1'
export const T_TARGET_PATHWAY_2='t_target_pathway_2'
export const T_TARGET_PATHWAY_3='t_target_pathway_3'

export const STRANDING_YEAR_TP1='Target Pathway 1'
export const STRANDING_YEAR_TP2='Target Pathway 2'
export const STRANDING_YEAR_TP3='Target Pathway 3'
export const MODEL_NAME_MAX_ALLOWED_CHARS=50
export const MODEL_FILE_NAME_MAX_ALLOWED_CHARS=50

export const YEAR_2030=2030

export const TARGET_PATHWAYS = Object.freeze({
  tp_1: 1,
  tp_2: 2,
  tp_3: 3
})

export const X_SCALE_YEARS = Object.freeze({
  y2k50: 2050
})

export const TPS_NAMES_MAP = {tp1: STRANDING_YEAR_TP1, tp2: STRANDING_YEAR_TP2, tp3: STRANDING_YEAR_TP3}

export const GRAPH_Y_SCALE_MAX_PADDING=5
export const INT32_MAX_VALUE=2147483647 // Max integer value supported by the ChartOptions API

export const API_STATUS = Object.freeze({
  error: 'error',
  loading: 'loading',
  success: 'success'
})