import React from 'react'
import {TabMenu} from 'primereact/tabmenu'
import './AssetHeader.scss'
import I18n from '../../../../../utils/i18n/I18n'

function AssetHeader(props) {

  function updateActiveIndex(evt){
    props.setActiveIndex(evt.index)
  }

  return (
    <div className={'asset-header'} data-testid={'asset-header'}>
      <div className={'asset-title'}>{I18n(props.assetHeaderConfig.headerTitle)}</div>
      <div className={'asset-menu'}>
        <TabMenu activeIndex={props.activeIndex} model={props.assetHeaderConfig.tabMenuOptions} onTabChange={(e)=>updateActiveIndex(e)}/>
      </div>
    </div>
  )
}

export default AssetHeader