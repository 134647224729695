import {QueryKeyPrefix} from '../../helpers/Constants'
import {noRefetchOnWindowFocus} from '../../../services/common/useQuery-config'
import {useQuery} from 'react-query'
import {getAssetAvailableUtilityMeters} from '../../../services/assets/actionplan-service'

export const useAssetAvailableUtilityMeters = (assetId) => {
  const queryKey=[QueryKeyPrefix.ASSET_AVAILABLE_UTIL_METERS, assetId]
  const queryConfig={...noRefetchOnWindowFocus, enabled: !!(assetId)}
  const {
    data,
    isLoading,
    isError,
    isFetching,
    refetch
  } = useQuery(queryKey, getAssetAvailableUtilityMeters, queryConfig)

  return (
    {
      data,
      isLoading,
      isError,
      isFetching,
      refetch
    }
  )
}