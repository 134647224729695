import PropTypes from 'prop-types'
import { useMemo } from 'react'
import {Checkbox} from 'primereact/checkbox'
import { FORM_FIELD_VARIANT_SIZE } from '../../../utils/helpers/Constants'
import { generateHtmlForId } from '../../../utils/helpers/Helper'
import './CustomCheckbox.scss'

function CustomCheckbox({
  variantSize, // FORM_FIELD_VARIANT_SIZE.SMALL | FORM_FIELD_VARIANT_SIZE.LARGE | FORM_FIELD_VARIANT_SIZE.MEDIUM
  labelText = null,
  disabled = false,
  hasLabel = false,
  checked = false,
  value,
  name = '',
  checkboxClassName,
  onChange,
  invalid = false,
}) {
  const htmlForId = useMemo(() => generateHtmlForId(labelText), [labelText])
  const disabledClass = disabled ? 'p-disabled' : ''
  const hasValidClass = invalid ? 'checkbox-invalid' : 'checkbox-valid'

  const getVariant = useMemo(() => {
    let sizeText = 'checkbox-md'
    if (FORM_FIELD_VARIANT_SIZE.SMALL === variantSize) {
      sizeText = 'checkbox-sm'
    }
    if (FORM_FIELD_VARIANT_SIZE.LARGE === variantSize) {
      sizeText = 'checkbox-lg'
    }
    return sizeText
  }, [variantSize])

  return (
    <div className='custom-checkbox'>
      <Checkbox
        inputId={htmlForId}
        value={value}
        name={name}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
        className={`${checkboxClassName} checkbox-controller ${hasValidClass} ${getVariant}`}
      />
      {hasLabel && labelText && <label htmlFor={htmlForId} className={`${disabledClass} label-checkbox ${hasValidClass} ${getVariant}`}>
        {labelText}
      </label>}
    </div>
  )
}

CustomCheckbox.propTypes = {
  disabled: PropTypes.bool,
  hasLabel: PropTypes.bool,
  labelText: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool,
  checkboxClassName: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  invalid: PropTypes.bool,
  variantSize: PropTypes.string,
}

export default CustomCheckbox
