import React from 'react'
import {Card} from 'primereact/card'
import {AuthCardFooter} from '../auth-card-footer/AuthCardFooter'
import {NavLink} from 'react-router-dom'
import I18n from '../../../../utils/i18n/I18n'
import './ForgotPasswordSuccess.scss'

function ForgotPasswordSuccess() {

  const getForgotPwdSuccessContentElement = ()=>
    <div className="content-auth-card-content">
      <div className="content-ack">
        {I18n('t_req_thanks')}
      </div>
      <div className="content-info">
        {I18n('t_email_reset')}
      </div>
      <div className="content-sign-in-link">
        <NavLink to="/login" className="login-nav">
          {I18n('t_back_sign_in')}
        </NavLink>
      </div>
    </div>

  return (
    <div className="forgot-pwd-success full-height-minus-header min-width-760">
      <div className="forgot-pwd-success-page">
        <div className="auth-card-container">
          <Card className={'auth-card'} title={I18n('t_forgot_password')} footer={AuthCardFooter}>
            {getForgotPwdSuccessContentElement()}
          </Card>
        </div>
      </div>
    </div>
  )
}

export default ForgotPasswordSuccess