import { useCallback, useEffect, useState } from 'react'
import { MultiSelect } from 'primereact/multiselect'
import PropTypes from 'prop-types'

export default function MultiSelectDropDown({ values, onMultiSelect, placeholder, optionList, name, defaultValues = [], isFilterRequired = false }) {
  const [optionValue, setOptionValue] = useState(defaultValues)

  useEffect(() => {
    if(Array.isArray(values)){
      setOptionValue(values)
    }
  }, [values])

  const optionChangeHandler = useCallback((event) => {
    setOptionValue(event.value)
    onMultiSelect(name, event.value)
  }, [onMultiSelect])
  return (
    <div className="card flex justify-content-center">
      <MultiSelect
        value={optionValue}
        onChange={optionChangeHandler}
        options={optionList}
        optionLabel="label"
        filter={isFilterRequired}
        placeholder={placeholder}
        maxSelectedLabels={3}
        className="w-full md:w-20rem common-multiselect"
        panelClassName="common-multiselect-panel"
      />
    </div>
  )
}

MultiSelectDropDown.propTypes = {
  optionList: PropTypes.array,
  placeholder: PropTypes.string,
  onMultiSelect: PropTypes.func,
  values: PropTypes.array,
  name: PropTypes.string,
  defaultValues: PropTypes.array,
  isFilterRequired: PropTypes.bool
}