import XlsxPopulate from 'xlsx-populate'
import FileSaver from 'file-saver'
import { formatDateToDDMMYY, formatDateToDdMonYy } from 'utils/helpers/DateUtils'
import { excelHelpers, formatData, getEmptyRows } from 'utils/helpers/ExcelUtils'
import PropTypes from 'prop-types'
import { getDateRangeAndTimePeriodInfo } from 'utils/data-exports/ExportHelper'

export const dataQualityExportV2 = async ({ exportData, sheetHeaderProps, formatMessage, selectedLanguage, unitSystem, isPortfolio = false }) => {
  const {
    summary,
    electricity,
    water,
    gas,
    otherFuelsAndThermals
  } = exportData
  const localize = (id) => formatMessage({ id })
  const blob = await XlsxPopulate.fromBlankAsync().then(workbook => {
    if(summary) {
      const excelSheetSummary = workbook.addSheet(localize('t_summary'))
      let requiredDataToGenerateExcel = createSummarySheet(
        summary,
        sheetHeaderProps,
        localize,
        selectedLanguage,
        unitSystem,
        excelSheetSummary,
        isPortfolio
      )
      excelHelpers(requiredDataToGenerateExcel, excelSheetSummary)
      applyStylingToSheet(excelSheetSummary, true)
    }

    if(electricity) {
      const excelSheetElectricity = workbook.addSheet(localize('t_electricity'))
      let requiredDataToGenerateExcel = createUtilitySheet(
        electricity,
        sheetHeaderProps,
        't_electricity',
        localize,
        selectedLanguage,
        unitSystem,
        excelSheetElectricity,
        isPortfolio
      )
      excelHelpers(requiredDataToGenerateExcel, excelSheetElectricity)
      applyStylingToSheet(excelSheetElectricity)
    }

    if(gas) {
      const excelSheetGas = workbook.addSheet(localize('t_gas'))
      let requiredDataToGenerateExcel = createUtilitySheet(
        gas,
        sheetHeaderProps,
        't_gas',
        localize,
        selectedLanguage,
        unitSystem,
        excelSheetGas,
        isPortfolio
      )
      excelHelpers(requiredDataToGenerateExcel, excelSheetGas)
      applyStylingToSheet(excelSheetGas)
    }

    if(otherFuelsAndThermals) {
      const excelSheetOtherFuelsAndThermals = workbook.addSheet(localize('t_otherfuelsandthermals'))
      let requiredDataToGenerateExcel = createUtilitySheet(
        otherFuelsAndThermals,
        sheetHeaderProps,
        't_otherfuelsandthermals',
        localize,
        selectedLanguage,
        unitSystem,
        excelSheetOtherFuelsAndThermals,
        isPortfolio
      )
      excelHelpers(requiredDataToGenerateExcel, excelSheetOtherFuelsAndThermals)
      applyStylingToSheet(excelSheetOtherFuelsAndThermals)
    }

    if(water) {
      const excelSheetWater = workbook.addSheet(localize('t_water'))
      let requiredDataToGenerateExcel = createUtilitySheet(
        water,
        sheetHeaderProps,
        't_water',
        localize,
        selectedLanguage,
        unitSystem,
        excelSheetWater,
        isPortfolio
      )
      excelHelpers(requiredDataToGenerateExcel, excelSheetWater)
      applyStylingToSheet(excelSheetWater)
    }
    //Given that the sheets will be dynamically created, we can't guarantee the order
    //Therefore, we will delete the default sheet
    workbook.deleteSheet('Sheet1')
    return workbook.outputAsync(workbook)
  })
  const fileName = isPortfolio ? 't_portfolio_data_quality_file_name' : 't_fund_data_quality_excel'
  FileSaver.saveAs(blob, localize(fileName))
}

const getFundDQSheetHeaderInformation = (utilityTypeBeforeLocalization, sheetHeaderProps, localize, selectedLanguage, isPortfolio) => {
  const {
    fundName,
    timePeriod,
    pickerValue
  } = sheetHeaderProps
  return [
    {A: localize(isPortfolio ? 't_portfolio_data_quality_file_name' : 't_fund_data_quality_excel')},
    {A: `${localize('t_fund_name')}: ${fundName || '-'}`},
    {A: `${localize('t_type')}: ${localize(utilityTypeBeforeLocalization)}`},
    {A: `${localize('t_date_range')}: ${getDateRangeAndTimePeriodInfo(timePeriod, pickerValue, selectedLanguage).dateRange}`},
    {A: `${localize('t_date_of_export')}: ${formatDateToDdMonYy()}`}
  ]
}

const createSummarySheet = (
  summary,
  sheetHeaderProps,
  localize,
  selectedLanguage,
  unitSystem,
  excelSheet,
  isPortfolio
) => {
  const {fundSummary, landlordSummary, tenantSummary, assets} = summary
  let excelDataArray = []
  excelDataArray.push(...getFundDQSheetHeaderInformation('t_alu', sheetHeaderProps, localize, selectedLanguage, isPortfolio))
  excelDataArray.push(...getEmptyRows(2))
  if(fundSummary) {
    excelDataArray = getSummaryTable(excelDataArray, fundSummary, 't_fund_level_summary', localize, excelSheet)
    excelDataArray.push(...getEmptyRows(3))
  }
  if(landlordSummary) {
    excelDataArray = getSummaryTable(excelDataArray, landlordSummary, 't_landlord_summary', localize, excelSheet)
    excelDataArray.push(...getEmptyRows(3))
  }
  if(tenantSummary) {
    excelDataArray = getSummaryTable(excelDataArray, tenantSummary, 't_tenant_summary', localize, excelSheet)
    excelDataArray.push(...getEmptyRows(3))
  }
  if(assets) {
    excelDataArray = getAssetsTable(excelDataArray, assets, localize, selectedLanguage, unitSystem, excelSheet, isPortfolio)
  }
  return excelDataArray
}

const createUtilitySheet = (
  utilityData,
  sheetHeaderProps,
  utilityString,
  localize,
  selectedLanguage,
  unitSystem,
  excelSheet,
  isPortfolio
) => {
  const {total, landlord, tenant, assets} = utilityData
  let excelDataArray = []
  excelDataArray.push(...getFundDQSheetHeaderInformation(utilityString, sheetHeaderProps, localize, selectedLanguage, isPortfolio))
  excelDataArray.push(...getEmptyRows(2))
  if(total) {
    excelDataArray = getUtilityTable(excelDataArray, total, 't_total', localize, excelSheet)
    excelDataArray.push(...getEmptyRows(2))
  }
  if(landlord) {
    excelDataArray = getUtilityTable(excelDataArray, landlord, 't_landlord', localize, excelSheet)
    excelDataArray.push(...getEmptyRows(2))
  }
  if(tenant) {
    excelDataArray = getUtilityTable(excelDataArray, tenant, 't_tenant', localize, excelSheet)
    excelDataArray.push(...getEmptyRows(2))
  }
  if(assets) {
    excelDataArray = getAssetsTable(excelDataArray, assets, localize, selectedLanguage, unitSystem, excelSheet, isPortfolio)
  }
  return excelDataArray
}

const getUtilityTable = (currentExcelData, data, tableTitleString, localize, excelSheet) => {
  const {
    areaCoveredPercentage, 
    areaNotCoveredPercentage, 
    actualPercentage, 
    supplierEstimatePercentage, 
    calculatedEstimatePercentage, 
    missingPercentage
  } = data
  const internalData = [...currentExcelData]
  internalData.push(...[{A: localize(tableTitleString)},
    {
      A: localize('t_coverage'),
      B: '',
      C: localize('t_completeness'),
      D: '',
      E: '',
      F: '',
    },
    {
      A: localize('t_coverage_by_floor_area') + ' (%)',
      B: localize('t_floor_area_unaccounted_for') + ' (%)',
      C: localize('t_actual_percentage'),
      D: localize('t_supplier_estimated_percentage'),
      E: localize('t_calculated_estimate') + ' (%)',
      F: localize('t_missing') + ' (%)'
    },
    {
      A: formatData(areaCoveredPercentage, localize, true),
      B: formatData(areaNotCoveredPercentage, localize, true),
      C: formatData(actualPercentage, localize, true),
      D: formatData(supplierEstimatePercentage, localize, true),
      E: formatData(calculatedEstimatePercentage, localize, true),
      F: formatData(missingPercentage, localize, true)
    }
  ])
  excelSheet.range(`A${internalData.length - 2}:B${internalData.length - 2}`).merged(true).style({ horizontalAlignment: 'center', verticalAlignment: 'center' })
  excelSheet.range(`C${internalData.length - 2}:F${internalData.length - 2}`).merged(true).style({ horizontalAlignment: 'center', verticalAlignment: 'center' })
  excelSheet.range(`A${internalData.length - 1}:F${internalData.length - 1}`).style({ horizontalAlignment: 'center', verticalAlignment: 'center' })

  return internalData
}

const getSummaryTable = (currentExcelData, summaryData, tableTitleString, localize, excelSheet) => {
  const {electricity, gas, otherFuelsAndThermals, water} = summaryData
  const data = [...currentExcelData]
  data.push(...[{A: localize(tableTitleString)},
    {
      A: '',
      B: localize('t_coverage'),
      C: '',
      D: localize('t_completeness'),
      E: '',
      F: '',
      G: ''
    },
    {
      A: '',
      B: localize('t_coverage_by_floor_area') + ' (%)',
      C: localize('t_floor_area_unaccounted_for') + ' (%)',
      D: localize('t_actual_percentage'),
      E: localize('t_supplier_estimated_percentage'),
      F: localize('t_calculated_estimate') + ' (%)',
      G: localize('t_missing') + ' (%)'
    },
  ])

  excelSheet.range(`B${data.length}:G${data.length}`).style({ horizontalAlignment: 'center', verticalAlignment: 'center' })
  excelSheet.range(`A${data.length-1}:A${data.length}`).merged(true)
  excelSheet.range(`B${data.length - 1}:C${data.length - 1}`).merged(true).style({ horizontalAlignment: 'center', verticalAlignment: 'center' })
  excelSheet.range(`D${data.length - 1}:G${data.length - 1}`).merged(true).style({ horizontalAlignment: 'center', verticalAlignment: 'center' })

  if(electricity) {
    data.push(...[getSummaryTableUtilityRow(electricity, 't_electricity', localize)])
  }
  if(gas) {
    data.push(...[getSummaryTableUtilityRow(gas, 't_gas', localize)])
  }
  if(otherFuelsAndThermals) {
    data.push(...[getSummaryTableUtilityRow(otherFuelsAndThermals, 't_otherfuelsandthermals', localize)])
  }
  if(water) {
    data.push(...[getSummaryTableUtilityRow(water, 't_water', localize)])
  }
  return data
}

const getSummaryTableUtilityRow = (utilityData, utilityName, localize) => {
  const {
    areaCoveredPercentage, 
    areaNotCoveredPercentage, 
    actualPercentage, 
    supplierEstimatePercentage, 
    calculatedEstimatePercentage, 
    missingPercentage
  } = utilityData
  return {
    A: localize(utilityName),
    B: formatData(areaCoveredPercentage, localize, true),
    C: formatData(areaNotCoveredPercentage, localize, true),
    D: formatData(actualPercentage, localize, true),
    E: formatData(supplierEstimatePercentage, localize, true),
    F: formatData(calculatedEstimatePercentage, localize, true),
    G: formatData(missingPercentage, localize, true)
  }
}

const getAssetsTable = (currentExcelData, assetsArray, localize, selectedLanguage, unitSystem, excelSheet, isPortfolio) => {
  const newAssetData = [...currentExcelData]
  const endColumn = isPortfolio ? 'P' : 'O' 
  newAssetData.push(...[{A: localize('t_all_assets')}])
  const headers = isPortfolio ? {
    A: localize('t_asset_reference'),
    B: localize('t_asset_name'),
    C: localize('t_fund'),
    D: localize('t_country'),
    E: localize('t_sector'),
    F: localize('t_managing_agent'),
    G: localize('t_purchase_date'),
    H: localize('t_sale_date'),
    I: localize('t_total_asset_floor_gia') + ` (${unitSystem === 'Metric' ? 'm2' : 'ft2'}) GIA`,
    J: localize('t_floor_area_unaccounted_for') + ` (${unitSystem === 'Metric' ? 'm2' : 'ft2'})`,
    K: localize('t_floor_area_unaccounted_for') + ' (%)',
    L: localize('t_coverage_by_floor_area') + ' (%)',
    M: localize('t_actual_percentage'),
    N: localize('t_supplier_estimated_percentage'),
    O: localize('t_calculated_estimate') + ' (%)',
    P: localize('t_missing_tc') + ' (%)'
  } : {
    A: localize('t_asset_reference'),
    B: localize('t_asset_name'),
    C: localize('t_country'),
    D: localize('t_sector'),
    E: localize('t_managing_agent'),
    F: localize('t_purchase_date'),
    G: localize('t_sale_date'),
    H: localize('t_total_asset_floor_gia') + ` (${unitSystem === 'Metric' ? 'm2' : 'ft2'}) GIA`,
    I: localize('t_floor_area_unaccounted_for') + ` (${unitSystem === 'Metric' ? 'm2' : 'ft2'})`,
    J: localize('t_floor_area_unaccounted_for') + ' (%)',
    K: localize('t_coverage_by_floor_area') + ' (%)',
    L: localize('t_actual_percentage'),
    M: localize('t_supplier_estimated_percentage'),
    N: localize('t_calculated_estimate') + ' (%)',
    O: localize('t_missing_tc') + ' (%)'
  }

  newAssetData.push(headers)
  excelSheet.range(`A${newAssetData.length}:${endColumn}${newAssetData.length}`).style({ horizontalAlignment: 'center', verticalAlignment: 'center' })

  assetsArray.forEach(asset => {
    const {
      actualPercentage,
      agent,
      areaCoveredPercentage,
      areaNotCoveredPercentage,
      assetName,
      assetRef,
      calculatedEstimatePercentage,
      country,
      floorArea,
      floorAreaNoData,
      fund,
      missingPercentage,
      purchaseDate,
      saleDate,
      sector,
      supplierEstimatePercentage,
    } = asset

    const assetRow = isPortfolio ? {
      A: formatData(assetRef),
      B: formatData(assetName),
      C: formatData(fund),
      D: formatData(country),
      E: formatData(sector),
      F: formatData(agent),
      G: formatDateToDDMMYY(purchaseDate, selectedLanguage),
      H: formatDateToDDMMYY(saleDate, selectedLanguage),
      I: formatData(floorArea),
      J: formatData(floorAreaNoData),
      K: formatData(areaNotCoveredPercentage, localize, true),
      L: formatData(areaCoveredPercentage, localize, true),
      M: formatData(actualPercentage, localize, true),
      N: formatData(supplierEstimatePercentage, localize, true),
      O: formatData(calculatedEstimatePercentage, localize, true),
      P: formatData(missingPercentage, localize, true),
    } : {
      A: formatData(assetRef),
      B: formatData(assetName),
      C: formatData(country),
      D: formatData(sector),
      E: formatData(agent),
      F: formatDateToDDMMYY(purchaseDate, selectedLanguage),
      G: formatDateToDDMMYY(saleDate, selectedLanguage),
      H: formatData(floorArea),
      I: formatData(floorAreaNoData),
      J: formatData(areaNotCoveredPercentage, localize, true),
      K: formatData(areaCoveredPercentage, localize, true),
      L: formatData(actualPercentage, localize, true),
      M: formatData(supplierEstimatePercentage, localize, true),
      N: formatData(calculatedEstimatePercentage, localize, true),
      O: formatData(missingPercentage, localize, true),
    }

    newAssetData.push(assetRow)

    excelSheet.range(`A${newAssetData.length}:${endColumn}${newAssetData.length}`).style({ horizontalAlignment: 'center', verticalAlignment: 'center' })
  })

  return newAssetData
}

export const applyStylingToSheet = (excelSheet, isSummarySheet = false) => {
  for (let i = isSummarySheet ? 2 : 1; i <= 16; i++) {
    excelSheet.column(i).width(isSummarySheet ? 15 : 16)
  }
}

dataQualityExportV2.propTypes = {
  exportData: PropTypes.object.isRequired,
  sheetHeaderProps: PropTypes.object.isRequired,
  formatMessage: PropTypes.func.isRequired,
  selectedLanguage: PropTypes.object.isRequired,
  isPortfolio: PropTypes.bool
}