import { formatDateToDDMMYY } from 'utils/helpers/DateUtils'
import { applyMultipleFundReportPAITableHeaderStyles, formatData, getEmptyRows, mergeMultipleCellRanges, setRowHeight, textAlignCenter } from 'utils/helpers/ExcelUtils'
import { generatePrincipleMetricsRow, generateQuarterlySummaryRow, getPrincipleMetricsTableHeader, getQuarterlySummaryTableHeader, getfundReportSheetHeaderInformation } from './fundReportPAIExcelExport'

export const createFundReportPAIEnergyConsumptionSheet = (
  fundName,
  reportingYear,
  energyBreakdown,
  assets,
  localize,
  selectedLanguage,
  excelSheet
) => {
  let excelDataArray = []
  excelDataArray.push(...getfundReportSheetHeaderInformation('t_energy_consumption_impact_report', fundName, reportingYear, localize))
  excelDataArray.push(...getEmptyRows(2))
  excelDataArray = getEnergyQuarterlySummaryTable(excelDataArray, energyBreakdown.quarterlySummary, reportingYear, localize)
  excelDataArray.push(...getEmptyRows(1))
  excelDataArray = getEnergyPrincipleMetricsTable(excelDataArray, energyBreakdown.principleMetrics, reportingYear, localize)
  excelDataArray.push(...getEmptyRows(1))
  excelDataArray = getAssetInformationTable(excelDataArray, assets, reportingYear, localize, selectedLanguage, excelSheet)
  
  return excelDataArray
}

const getEnergyQuarterlySummaryTable = (currentExcelData, quarterlySummaryData, reportingYear, localize) => {
  const {totalEnergyConsumption, totalContributingArea, energyIntensity} = quarterlySummaryData
  const data = [...currentExcelData]
  data.push(...getQuarterlySummaryTableHeader(localize, reportingYear))
  
  data.push(...[generateQuarterlySummaryRow('t_total_energy_consumption_excel_mwh', totalEnergyConsumption.q1, totalEnergyConsumption.q2, totalEnergyConsumption.q3, totalEnergyConsumption.q4, localize),
    generateQuarterlySummaryRow('t_total_contributing_area', totalContributingArea.q1, totalContributingArea.q2, totalContributingArea.q3, totalContributingArea.q4, localize),
    generateQuarterlySummaryRow('t_energyintensity', energyIntensity.q1, energyIntensity.q2, energyIntensity.q3, energyIntensity.q4, localize)])
  
  return data
}

const getEnergyPrincipleMetricsTable = (currentExcelData, principleMetricsData, reportingYear, localize) => {
  const {energyIntensity} = principleMetricsData
  const data = [...currentExcelData]
  data.push(...getPrincipleMetricsTableHeader(localize, reportingYear))
  data.push(...[generatePrincipleMetricsRow('t_average_energy_intensity', energyIntensity.currentYear, energyIntensity.previousYear, localize)])
  return data
}

const getAssetInformationTable = (currentExcelData, assetsArray, reportingYear, localize, selectedLanguage, excelSheet) => {
  const data = [...currentExcelData]
  data.push(...getAssetInformationTableHeader(localize, reportingYear))
  assetsArray.forEach(asset => {
    const { 
      assetReference, 
      assetName, 
      location, 
      constructionYear, 
      purchaseDate,
      saleDate, 
      EnergyConsumption
    } = asset
    data.push(...[{
      A: assetReference,
      B: assetName,
      C: location,
      D: formatDateToDDMMYY(purchaseDate, selectedLanguage),
      E: formatDateToDDMMYY(saleDate, selectedLanguage),
      F: constructionYear,
      G: formatData(EnergyConsumption.floorArea),
      H: formatData(EnergyConsumption.actualEnergyConsumptionPerQuarter.q1),
      I: formatData(EnergyConsumption.actualEnergyConsumptionPerQuarter.q2),
      J: formatData(EnergyConsumption.actualEnergyConsumptionPerQuarter.q3),
      K: formatData(EnergyConsumption.actualEnergyConsumptionPerQuarter.q4),
      L: formatData(EnergyConsumption.totalEnergyConsumptionPerQuarter.q1),
      M: formatData(EnergyConsumption.totalEnergyConsumptionPerQuarter.q2),
      N: formatData(EnergyConsumption.totalEnergyConsumptionPerQuarter.q3),
      O: formatData(EnergyConsumption.totalEnergyConsumptionPerQuarter.q4),
    }])
    excelSheet.row(data.length).height(31)
  })

  return data
}

const getAssetInformationTableHeader = (localize, reportingYear) => {
  return [{
    A: `${localize('t_asset_information')} (${localize('t_actual_data_only')})`,
  },
  {
    A: localize('t_asset_reference'),
    B: localize('t_asset_name'),
    C: localize('t_country'),
    D: localize('t_purchase_date'),
    E: localize('t_sale_date'),
    F: localize('t_construction_year'),
    G: `${localize('t_asset_floor_area')} (m²)`,
    H: `${localize('t_energy_consumption')} (${localize('t_actual_only')})`,
    I: '',
    J: '',
    K: '', 
    L: `${localize('t_energy_consumption')} (${localize('t_including_estimates')})`,
    M: '',
    N: '',
    O: ''
  },
  {
    A: '',
    B: '',
    C: '',
    D: '',
    E: '',
    F: '',
    G: '',
    H: `Q1 ${reportingYear}`,
    I: `Q2 ${reportingYear}`,
    J: `Q3 ${reportingYear}`,
    K: `Q4 ${reportingYear}`,
    L: `Q1 ${reportingYear}`,
    M: `Q2 ${reportingYear}`,
    N: `Q3 ${reportingYear}`,
    O: `Q4 ${reportingYear}`,
  }]
}

export const applyStylingToEnergySheet = (excelSheet) => {
  excelSheet.usedRange().forEach(cell => {
    if (cell.address() !== 'A1') {
      cell.style({fontSize: 12,  horizontalAlignment: 'left', verticalAlignment: 'center'})
    }

    if(cell.address() === 'A8' || cell.address() === 'A14') {
      cell.style({topBorder: false})
    }
  })
  setRowHeight(excelSheet, 18, 30)
  applyMultipleFundReportPAITableHeaderStyles(['A18:O18', 'H19:O19', 'B8:E8', 'B14:C14'], excelSheet)
  mergeMultipleCellRanges(excelSheet, ['A18:A19', 'B18:B19', 'C18:C19', 'D18:D19', 'E18:E19', 'F18:F19', 'G18:G19', 'H18:K18', 'L18:O18'])
  textAlignCenter(excelSheet, 'H18:O18')
}