import {getLegendImage, getLocalizedValue} from '../../../../../utils/helpers/Helper'
import CSS_VARIABLES from '../../../../../resources/css/_variables.scss'
import {getBAUStrandingYearDetails, STRANDING_POINT_IDENTIFIER} from '../../../../pages/assets/net-zero-carbon/asset-nzc-utils/asset-nzc-utils'
import {getDefaultFontObjectForChart} from '../../../net-zero-carbon/nzc-common-utils'
import {X_SCALE_YEARS} from '../../../net-zero-carbon/nzc-constants'
import { STRANDINGS } from 'utils/helpers/Constants'
import { getStaticLatestSolidLineYear } from 'components/pages/funds/static-net-zero-carbon/static-nzc-line-graph/static-fund-graph-utils'

export const chartDefaultBaseYears = [2020, 2025, 2030, 2035, 2040, 2045, 2050]
export const getBorderDashValues = (context, year) => {
  return context.p1.parsed.x <= year ? [6, 0] : [4, 3]
}


export function createNZCGraphDataSets(targetPathways, selectedLanguage, solidLineYear, setStrandingYear, nzcBaseLineYear) {
  if (!targetPathways)
    return null

  if (!targetPathways.graphData || targetPathways.graphData.length===0)
    return null

  const graphDatasets = {
    bauGhgIntensity: {
      type: 'line',
      label: `   ${getLocalizedValue(selectedLanguage, 't_bau_ghg_intensity')}`,
      borderColor: CSS_VARIABLES.pink,
      backgroundColor: CSS_VARIABLES.pink,
      fontColor: CSS_VARIABLES.forest_80,
      borderWidth: 2,
      fill: false,
      pointStyle: getLegendImage('bau'),
      data: [],
      segment: {
        borderDash: context => getBorderDashValues(context, solidLineYear)
      },
      datalabels: {
        display: false
      }
    },
    scientificGHGIntensity: {
      type: 'line',
      label:  `   ${getLocalizedValue(selectedLanguage, 't_scientific_ghg_intensity')}`,
      borderColor: CSS_VARIABLES.blue,
      backgroundColor: CSS_VARIABLES.blue,
      fontColor: CSS_VARIABLES.forest_80,
      borderWidth: 2,
      fill: false,
      pointStyle: getLegendImage('science'),
      data: [],
      datalabels: {
        display: false
      }
    }
  }

  let baseLineDataPoint
  baseLineDataPoint = targetPathways.graphData.sort((a, b) => a.year - b.year)[0]
  chartDefaultBaseYears[0] = nzcBaseLineYear
  targetPathways.graphData.forEach((graphData) => {
    graphDatasets.bauGhgIntensity.data.push({ x: graphData.year, y: graphData.bauGhgIntensity })
    graphDatasets.scientificGHGIntensity.data.push({ x: graphData.year, y: graphData.scientificGHGIntensity })
  })
  const originalData = [graphDatasets.bauGhgIntensity, graphDatasets.scientificGHGIntensity]

  if (!targetPathways.strandings) {
    return originalData
  }

  let bauStandingsArray = targetPathways.strandings.filter(stranding => stranding.strandingOf === STRANDING_POINT_IDENTIFIER.BAU.CARBON)
  if(bauStandingsArray.length> 0){
    let bauStrandingDetails = getBAUStrandingYearDetails(bauStandingsArray)
    setStrandingYear(Math.floor(bauStrandingDetails.year))
    originalData.push(
      {
        type: 'bubble',
        data: [{
          x: Math.floor(bauStrandingDetails.year),
          y: bauStrandingDetails.strandingPoint,
          r: 28,
          label: bauStrandingDetails.strandingOf
        }],
        backgroundColor: 'transparent',
        borderColor: CSS_VARIABLES.pink,
        borderWidth: 2,
        datalabels:{
          display:false
        },
        clip : true
      }
    )
  }else{
    setStrandingYear('pre - ' + baseLineDataPoint.year)
  }

  return originalData
}

export function createStaticNZCGraphDataSets(targetPathways, selectedLanguage, nzcBaseLineYear) {
  if (!targetPathways)
    return null

  if (!targetPathways.targetPathways || targetPathways.targetPathways === 0)
    return null

  const graphDatasets = {
    bauGhgIntensity: {
      type: 'line',
      label: `   ${getLocalizedValue(selectedLanguage, 't_bau_ghg_intensity')}`,
      borderColor: CSS_VARIABLES.pink,
      backgroundColor: CSS_VARIABLES.pink,
      fontColor: CSS_VARIABLES.forest_80,
      borderWidth: 2,
      fill: false,
      pointStyle: getLegendImage('bau'),
      data: [],
      segment: {
        borderDash: context => getBorderDashValues(context, getStaticLatestSolidLineYear(targetPathways.scopeFundPerformance.year, targetPathways.targetPathways))
      },
      datalabels: {
        display: false
      }
    },
    scientificGHGIntensity: {
      type: 'line',
      label:  `   ${getLocalizedValue(selectedLanguage, 't_scientific_ghg_intensity')}`,
      borderColor: CSS_VARIABLES.blue,
      backgroundColor: CSS_VARIABLES.blue,
      fontColor: CSS_VARIABLES.forest_80,
      borderWidth: 2,
      fill: false,
      pointStyle: getLegendImage('science'),
      data: [],
      datalabels: {
        display: false
      }
    }
  }

  chartDefaultBaseYears[0] = nzcBaseLineYear
  targetPathways.targetPathways.forEach((graphData) => {
    graphDatasets.bauGhgIntensity.data.push({ x: graphData.year, y: graphData.businessAsUsual })
    graphDatasets.scientificGHGIntensity.data.push({ x: graphData.year, y: graphData.scienceBasedTarget })
  })
  const originalData = [graphDatasets.bauGhgIntensity, graphDatasets.scientificGHGIntensity]

  if (!targetPathways.strandings || targetPathways.strandings.length === 0) {
    return originalData
  }

  let bauStandingsArray = targetPathways.strandings.filter(stranding => stranding.strandingOf === STRANDINGS.BAU)
  if(bauStandingsArray.length > 0) {
    let bauStrandingDetails = getBAUStrandingYearDetails(bauStandingsArray)
    originalData.push(
      {
        type: 'bubble',
        data: [{
          x: Math.floor(bauStrandingDetails.year),
          y: bauStrandingDetails.strandingPoint,
          r: 23,
          label: bauStrandingDetails.strandingOf
        }],
        backgroundColor: 'transparent',
        borderColor: CSS_VARIABLES.pink,
        borderWidth: 2,
        datalabels:{
          display:false
        },
        clip : true
      }
    )
  } 

  return originalData
}

export function getNzcChartOptions(assetLevelFlag, nzcBaseLineYear) {
  return  {
    responsive: true,
    animation: false,
    maintainAspectRatio: false,
    layout: {
      autoPadding: false,
      padding: {
        bottom: 0,
        top: 2
      }
    },
    plugins: {
      tooltip: {enabled: false},
      legend: {
        position: 'bottom',
        align: 'start',
        labels: {
          usePointStyle: true,
          boxWidth: 15,
          padding: assetLevelFlag ? 25 : 10,
          boxHeight: 8,
          font: getDefaultFontObjectForChart({
            weight: 400,
            size: assetLevelFlag ? 13 : 12,
            lineHeight: '22',
          }),
          generateLabels: (chart) => {
            let generatedLabels = []
            if (chart?.data.datasets) {
              generatedLabels = chart.data.datasets
                .slice(0, 2)
                .map((dataset, index) => ({
                  text: dataset.label,
                  fillStyle: dataset.backgroundColor,
                  strokeStyle: dataset.backgroundColor,
                  lineWidth: dataset.borderWidth,
                  pointStyle: dataset.pointStyle,
                  hidden: dataset.datalabels?.display,
                  fontColor: dataset.fontColor,
                  index: index,
                }))
            }

            return generatedLabels
          }
        }
      },
      datalabels: {display: false}
    },
    scales: {
      x: {
        type: 'linear',
        ticks: {
          stepSize: 5,
          font: getDefaultFontObjectForChart(),
          callback: function () {
            return ''
          },
        },
        grid: {
          color: CSS_VARIABLES.forest_20,
          drawTicks: false,
          lineWidth: 2
        },
        min: nzcBaseLineYear,
        max: X_SCALE_YEARS.y2k50
      },
      y: {
        suggestedMin: 0,
        ticks: {
          display: false,
          font: getDefaultFontObjectForChart()
        },
        grid: {
          color: CSS_VARIABLES.forest_20,
          drawTicks: false,
          lineWidth: 2
        },
      }
    },
    elements: {
      point: {radius: 0}
    }
  }
}


export function getNzcChartPlugins(assetLevelFlag, nzcBaseLineYear) {
  return [
    {
      beforeInit(chart) {
        const marginLeft = chart.legend.adjustHitBoxes
        chart.legend.adjustHitBoxes =  function adjustHitBoxes() {
          marginLeft.bind(chart.legend)()
          return assetLevelFlag ?  15 : 0
        }
      },
      afterDraw: chart => {
        let ctx = chart.ctx
        let xAxis = chart.scales['x']
        let yAxis = chart.scales['y']
        chart.data.labels.forEach((l, i) => {
          let x = xAxis.getPixelForValue(l)
          switch (l) {
          case nzcBaseLineYear:
          case 2020:
            ctx.textAlign = 'left'
            break
          case 2050:
            ctx.textAlign = 'right'
            break
          default:
            ctx.textAlign = 'center'
          }
          ctx.fillStyle = CSS_VARIABLES.forest_80
          ctx.fillText(l + ' ', x, yAxis.bottom + 15)
        })
        ctx.restore()
      }
    }
  ]
}

export function getAssetsWithCurrentYear(targetPathways, reportingYear) {
  return targetPathways?.scopeAssets?.map((e) => {
    const asset = targetPathways?.assetGraphData?.find((f) => f.assetId === e.assetId)
    const reqVal = asset?.graphData?.findIndex((g) => g.year === reportingYear)
    const graphData = asset?.graphData[reqVal] ? asset?.graphData[reqVal] : {}
    return {
      ...e,
      ...asset?.graphData[reqVal],
      actionsUnderReview: graphData?.actionsUnderReview ? graphData.actionsUnderReview : 0,
      agreedActions: graphData?.agreedActions ? graphData.agreedActions : 0,
      applicableActions: graphData?.applicableActions ? graphData.applicableActions : 0,
      carbonIntensity: graphData?.carbonIntensity ? graphData.carbonIntensity : 0,
      co2Emission: graphData?.co2Emission ? graphData.co2Emission : 0,
      costIntensity: graphData?.costIntensity ? graphData.costIntensity : 0,
      dataAvailablePercentage: graphData?.dataAvailablePercentage ? graphData.dataAvailablePercentage : 0,
      energyIntensity: graphData?.energyIntensity ? graphData.energyIntensity : 0,
      missingPercentage: graphData?.missingPercentage ? graphData.missingPercentage : 0,
      year: graphData?.year ? graphData.year : reportingYear,
      baseCurrency: graphData?.baseCurrency ? graphData.baseCurrency : e.baseCurrency,
      weightedAverageCarbonIntensity: graphData?.weightedAverageCarbonIntensity ? graphData.weightedAverageCarbonIntensity : 0,
    }
  })
}