import {useQueries} from 'react-query'
import {GetAssetHeaderData} from '../../../../../services/assets/portfolio-service'
import {manualFetch, noRefetchOnWindowFocus} from '../../../../../services/common/useQuery-config'
import {GetPassportData} from '../../../../../services/assets/passport-service'

const usePassportData = (assetId) => {

  const [
    assetHeaderInfo,
    {data, isLoading},
  ] = useQueries([
    {
      queryKey: ['assetHeaderData', assetId],
      queryFn: GetAssetHeaderData,
      ...manualFetch
    },
    {
      queryKey: ['passportData', assetId],
      queryFn: GetPassportData,
      ...noRefetchOnWindowFocus
    }
  ])

  return {assetHeaderInfo, data, isLoading}
}

export default usePassportData