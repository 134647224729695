import {Text, View, Image} from '@react-pdf/renderer'
import React, {useState} from 'react'
import styles from '../style-sheet/Styles'
import {formatNumWODecimal, trucateNumWODecimal} from '../../../services/common/formatter'
import I18n from '../../i18n/I18n'
import {energyRating, energyScore, unitsConverter} from '../../helpers/Helper'
import {AREA, checkCountries} from '../../helpers/Constants'
import CSS_VARIABLES from '../../../resources/css/_variables.scss'
import PropTypes from 'prop-types'

const Asset = (props) => {
  const [asset] = useState(props.asset)
  const [certs] = useState(props.certifications ? props.certifications : [])
  const unitSystem = props.unitSystem
  const assetCountry = checkCountries.includes(asset.assetCountry)

  function getImageHeight(){
    if(props.isDashboard){
      return asset.assetImageUri ? [styles.dashboardAssetActualImage] : [styles.dashboardAssetImage]
    }else{
      return asset.assetImageUri ? [styles.assetActualImage] : [styles.assetImage]
    }
  }

  function isMaxLineThree(){
    return props.isDashboard ? styles.ellipsisMax3 : styles.ellipsisMax2
  }

  function getEpcClass() {
    if(props.isDashboard){
      return styles.ellipsisMax3
    }else{
      return certs.length > 1 ? styles.ellipsisMax2 : styles.ellipsisMax3
    }
  }

  return (
    <View style={[styles.assets, styles.borderExceptBottom, props.isDashboard ? styles.dashboardAssetOuterHeader : styles.assetOuterHeader,
      {backgroundColor: CSS_VARIABLES.grey}]}>
      <Text style={[styles.title, styles.topM17]}>{I18n('t_assets')}</Text>
      <Image src={props.assetImgSrc} style={getImageHeight()}/>
      <View style={[styles.topM13, styles.assetInnerView]}>
        <Text style={[styles.textSevenHalfBold, isMaxLineThree(), styles.assetAddressResult]}>
          {Array.from(asset.assetAddress)}
        </Text>
        <Text style={[styles.textSevenHalfRegular, isMaxLineThree(), styles.assetFundResults]}>
          {Array.from(asset.fundName)}
        </Text>
        <View style={styles.border}/>
        <View style={[styles.assetDetails,{}]}>
          <View>
            <View style={[styles.tableRow,styles.mT4]}>
              <View style={[styles.assetTextSix,styles.greyDarkColor,styles.width50]}>
                <Text>{I18n('t_floor_area')}</Text>
              </View>

              <View style={[styles.blackColor,styles.ellipsisMax2,styles.width68,styles.assetTextSeven]}>
                <Text>
                  {asset.gia ? formatNumWODecimal(props.language, trucateNumWODecimal(asset.gia)) + unitsConverter(AREA,unitSystem) : '-'}
                </Text>
              </View>
            </View>

            <View style={[styles.tableRow,styles.mT4]}>
              <View style={[styles.assetTextSix,styles.greyDarkColor,styles.width50]}>
                <Text>{assetCountry ? I18n('t_energy_star_score') : I18n('t_energy_rating')}</Text>
              </View>
              <View style={[styles.blackColor,styles.width68,styles.assetTextSeven]}>
                <Text style={[getEpcClass()]}>
                  {assetCountry ? energyScore(asset) : energyRating(asset)}
                </Text>
              </View>
            </View>

            <View style={[styles.tableRow,styles.mT4]}>
              <View style={[styles.assetTextSix,styles.greyDarkColor,styles.width50]}>
                <Text>{I18n('t_certifications')}</Text>
              </View>
              <View style={[styles.blackColor,styles.width68,styles.assetTextSeven]}>
                <Text style={[getEpcClass()]}>{certs[0]? certs[0] : '-'}</Text>
                <Text style={[getEpcClass(),certs[1] ? styles.mT8 : null]}>{certs[1] ? certs[1] : null}</Text>
              </View>
            </View>

            <View style={[styles.tableRow,styles.mT4]}>
              <View style={[styles.assetTextSix,styles.greyDarkColor,styles.width50]}>
                <Text>{I18n('t_last_refurb')}</Text>
              </View>
              <View style={[styles.blackColor,styles.width68,styles.assetTextSeven]}>
                <Text style={styles.ellipsisMax1}>{props.lastRefurbished ? props.lastRefurbished : '-'}</Text>
              </View>
            </View>

            <View style={[styles.tableRow,styles.mT4]}>
              <View style={[styles.assetTextSix,styles.greyDarkColor,styles.width50]}>
                <Text>{I18n('t_occupancy')}</Text>
              </View>
              <View style={[styles.blackColor,styles.width68,styles.assetTextSeven]}>
                <Text style={styles.ellipsisMax1}>{asset.occupancy ? `${formatNumWODecimal(props.language, asset.occupancy)}%` : '-'}</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

Asset.propTypes = {
  unitSystem: PropTypes.string
}
Asset.displayName = 'Asset'

export default Asset