import React, {useContext, useEffect, useRef, useState} from 'react'
import BackNavigation from '../../../../common/back-nav/BackNavigation'
import I18n from '../../../../../utils/i18n/I18n'
import './PassportHome.scss'
import {Card} from 'primereact/card'
import {useHistory, useParams} from 'react-router-dom'
import {Chart} from 'primereact/chart'
import {Dropdown} from 'primereact/dropdown'
import {PortfolioContext} from 'Contexts'
import {formatNumWODecimal, trucateNumWODecimal, trucateNumWOneDecimal} from '../../../../../services/common/formatter'
import AssetExportSelection from '../../../../../utils/data-exports/AssetExportSelection'
import {OverlayPanel} from 'primereact/overlaypanel'
import {
  AREA,
  ASSET_PAGES,
  CacheKeyAsset, checkCountries, DROP_DOWN_HEIGHT,
  monthListShort,
  UTILITY_LABELS_OBJECT_TITLE_CASE
} from '../../../../../utils/helpers/Constants'
import { createNavURLForFundPages, getDataFromCache, getFillerImageForPassport,
  getLocalizedValue, getUtilityName,  truncateDecimal, unitDateFormatter,  unitsConverter} from '../../../../../utils/helpers/Helper'
import { Tooltip } from 'primereact/tooltip'
import moment from 'moment'
import blueRectangle from '../../../../../resources/images/chart/rectangle-blue.svg'
import lightBlueRectangle from '../../../../../resources/images/chart/rectangle-light-blue.svg'
import NonRecycled from '../../../../../resources/images/chart/rectangle-non-recycle.svg'
import {isTextOverflown} from '../../../../../utils/UtilsFunctions'
import {periodOptions} from '../../../../../services/common/datepicker-service'
import {filterQueryParams} from '../../../../../utils/query-params/QueryParams'
import {
  PassportPageChartSkeleton,
  PassportPageConsumptionSummarySkeleton,
  PassportPageSingleLineSkeleton
} from '../../../../common/skeletons/passport-grid-skeleton/PassportPageSkeleton'
import {
  chartOptions,
  exportChartOptionsPdf,
  setGraphType,
  setGraphTypePdf
} from '../hooks-and-helpers/PassportHelper'
import PassportTable from '../passport-table/PassportTable'
import infoIcon from '../../../../../resources/images/icon/info.dark.grey.svg'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'
import usePassportData from '../hooks-and-helpers/usePassportData'

const PassportHome = () => {

  //region Declarations/Initializations
  const {instanceName, orgName, fundName, assetId} = useParams()
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const unitSystem = userInfo.unitSystem
  const currencyUnit = userInfo.currencyUnit
  const history = useHistory()
  const queryValues = new URLSearchParams(new URL(window.location.href).search)
  const pfContext = useContext(PortfolioContext)
  const selectedUtilityLabel = getUtilityName(pfContext.pfState.colField) ? getUtilityName(pfContext.pfState.colField) : UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY
  const [pState, setPState] = useState({
    passportData: [],
    parameter: queryValues.get('performance') === null ? selectedUtilityLabel : queryValues.get('performance'),
    parameterLabel: I18n('t_electricity'),
    percentageChange: null,
    assetAddress: getDataFromCache(CacheKeyAsset),
    assetFundName: '-',
    floorArea: '-',
    pdfPerformanceInfo: null,
    energyRating: '-',
    energyStarScore: null,
    esLastSync: '-',
    assetCountry: '-',
    certifications: [],
    lastRefurbished: '-',
    occupancy: '-',
    currentYearNR: [],
    noData: false,
    months: [],
    currentConsumptionElectricity: [],
    previousConsumptionElectricity: [],
    monthsElectricity: [],
    currentConsumptionThermal: [],
    previousConsumptionThermal: [],
    monthsThermal: [],
    currentConsumptionWater: [],
    previousConsumptionWater: [],
    monthsWater: [],
    currentConsumptionCarbon: [],
    previousConsumptionCarbon: [],
    monthsCarbon: [],
    currentConsumptionWaste: [],
    previousConsumptionWaste: [],
    monthsWaste: [],
    currentNrPdf: [],
    colorText: 'grey-text',
    unit: unitsConverter(UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY, unitSystem),
    labelRecycled: getLocalizedValue(selectedLanguage, 't_recycled'),
    labelNonRecycled: getLocalizedValue(selectedLanguage, 't_non_recycled'),
    first: 0,
    rows: 20,
    currentConsumption: [],
    previousConsumption: [],
    currentYearConsumption: null,
    previousYearConsumption: null,
    assetImage: {
      assetImageUri: null,
      sector: ''
    },
    pdfPerformaceInfo: null,
    isOverlayPanelOpen: false,
    period: getLocalizedValue(selectedLanguage, 't_ytd_uppercase'),
    pickerValue: {
      pickerValueMonths: '1',
      pickerValueEndMonth: moment().format('MMM'),
      pickerValueEndYear: moment().format('YY')
    }
  })
  const exportOverlay       = useRef(null)
  const date                = useRef(new Date().getFullYear())
  const electricityRef      = useRef(null)
  const thermalRef          = useRef(null)
  const waterRef            = useRef(null)
  const cabonRef            = useRef(null)
  const wasteRef            = useRef(null)
  const reduceSizes         = useRef(false)
  const MAX_CERTS_TO_SHOW_3 = 3
  const parameterOptions    = [
    { label: I18n('t_electricity'),     value: UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY },
    { label: I18n('t_water'),           value: UTILITY_LABELS_OBJECT_TITLE_CASE.WATER },
    { label: I18n('t_gas_fuel_thermal'),  value: UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL},
    { label: I18n('t_carbon'),          value: UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON },
    { label: I18n('t_waste'),           value: UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE },
  ]
  const pdfChartArea={width: 900,height:635}

  const {assetHeaderInfo, data, isLoading } = usePassportData(assetId)

  const rowData = pfContext.pfState.data
  const colData = pfContext.pfState.colField
  const assetSummaryContentWRef        = useRef(null)
  const propertyImage     = getFillerImageForPassport(pState.assetImage)
  let propertyImagePdf    = getFillerImageForPassport(pState.assetImage, true)
  let performanceInfos
  let assetInfo = {}
  let actionInfos = []
  const latestYear = date.current.toString().substring(2,4)
  const preOneYear = date.current - 1
  const preTwoYear = date.current - 2
  //endregion

  //region useEffect implementation
  useEffect(() => {
    if (assetHeaderInfo.data) {
      pfContext.pfDispatcher({...pfContext.pfState, ...{data: assetHeaderInfo.data}})
    }
  }, [assetHeaderInfo])

  useEffect(() => {
    if (data) {
      performanceInfos = data.performanceInfos
      actionInfos = data.actionInfos
      assetInfo = data.assetInfo

      const assetFundName = assetInfo?.fundName ? assetInfo.fundName : '-'
      const gia = assetInfo?.gia ? formatNumWODecimal(selectedLanguage, trucateNumWODecimal(assetInfo.gia)) : '-'
      const epcScheme = assetInfo?.epcScheme ? assetInfo.epcScheme + ' ' : ''
      const epcRate = assetInfo?.epcRating ? assetInfo.epcRating : '-'
      const energyStarScore = assetInfo?.energyStarScore ? assetInfo.energyStarScore : '-'
      const esLastSync = assetInfo?.lastSync ? assetInfo.lastSync : '-'
      const assetCountry = assetInfo?.assetCountry ? assetInfo.assetCountry : '-'
      const assetImage = {
        assetImageUri: assetInfo?.assetImageUri ? assetInfo.assetImageUri : '',
        sector: assetInfo?.sector ? assetInfo.sector : ''
      }
      let energyRate = '-'
      const assetCountryUsCanada = checkCountries.includes(assetInfo?.assetCountry)
      if(assetCountryUsCanada){
        energyRate = energyStarScore ? energyStarScore : '-'
      } else {
        energyRate = epcRate && epcScheme ? `${epcRate} - ` + epcScheme : '-'
      }
      let cert=[]
      assetInfo?.certifications.map((certification, index) => {
        if(index < MAX_CERTS_TO_SHOW_3){
          cert.push((certification.rating)?`${certification.name} - ${certification.rating}`:certification.name)
        }
      })

      const refub = assetInfo?.lastRefurbished ? assetInfo.lastRefurbished : '-'
      const occ = assetInfo?.occupancy ? formatNumWODecimal(selectedLanguage, trucateNumWODecimal(assetInfo.occupancy)) : '-'

      let selectedParam = UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY
      let parameter, parameterLabel
      let unit = pState.unit
      if(colData === 'fuelsPercentage') {
        selectedParam = UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL
        parameter = UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL
        parameterLabel = I18n('t_gas_fuel_thermal')
      } else if(colData === 'waterPercentage') {
        selectedParam = UTILITY_LABELS_OBJECT_TITLE_CASE.WATER
        parameter = UTILITY_LABELS_OBJECT_TITLE_CASE.WATER
        parameterLabel = I18n('t_water')
        unit = unitsConverter(UTILITY_LABELS_OBJECT_TITLE_CASE.WATER, unitSystem)
      } else if(colData === 'wastePercentage') {
        selectedParam = UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE
        parameter = UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE
        parameterLabel = I18n('t_waste')
        unit = '%'
      } else if(colData === 'carbonPercentage') {
        selectedParam = UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON
        parameter = UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON
        parameterLabel = I18n('t_carbon')
        unit = unitsConverter(UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON, unitSystem)
      }
      let graphData = getGraphData(selectedParam) // TODO changed to the data selected in Portfolio page

      let passportData, noData
      if (actionInfos && actionInfos.length > 0) {
        passportData = actionInfos
        noData = false
      } else {
        noData = true
      }

      setPState(prevState => {
        return {
          ...prevState,
          assetFundName: assetFundName,
          floorArea: gia,
          energyRating: energyRate,
          certifications: cert,
          lastRefurbished: refub,
          occupancy: occ,
          parameter: parameter,
          parameterLabel: parameterLabel,
          unit: unit,
          noData: noData,
          assetAddress: assetInfo?.assetAddress,
          passportData: passportData,
          esLastSync: esLastSync,
          assetCountry: assetCountry,
          energyStarScore: energyStarScore,
          assetImage: assetImage,
          ...graphData,
          pdfPerformaceInfo: data.performanceInfos
        }
      })
    }
  }, [data])

  useEffect(() => {
    if (data) {
      performanceInfos = data.performanceInfos
      performanceInfos?.forEach(pi => {
        let currentC = []
        let previousC = []
        let nonRecycled = []
        let allMonths = []
        pi.annualPerformance.map(monthData => {
          currentC.push(monthData.currentYearConsumption)
          previousC.push(monthData.previousYearConsumption)
          allMonths.push(monthListShort[monthData.month])
          if (pi.utility === 'Waste') {
            nonRecycled.push(monthData.currentYearNonRecycled)
          }
        })

        let utilityConsumptionData = {}
        if (pi.utility === 'Electricity') {
          utilityConsumptionData = Object.assign({}, {
            currentConsumptionElectricity: currentC,
            previousConsumptionElectricity: previousC,
            monthsElectricity: allMonths
          })
        } else if (pi.utility === 'GasFuelsAndThermals') {
          utilityConsumptionData = Object.assign({}, {
            currentConsumptionThermal: currentC,
            previousConsumptionThermal: previousC,
            monthsThermal: allMonths
          })
        } else if (pi.utility === 'Water') {
          utilityConsumptionData = Object.assign({}, {
            currentConsumptionWater: currentC,
            previousConsumptionWater: previousC,
            monthsWater: allMonths
          })
        } else if (pi.utility === 'Carbon') {
          utilityConsumptionData = Object.assign({}, {
            currentConsumptionCarbon: currentC,
            previousConsumptionCarbon: previousC,
            monthsCarbon: allMonths
          })
        } else if (pi.utility === 'Waste') {
          utilityConsumptionData = Object.assign({}, {
            currentConsumptionWaste: currentC,
            previousConsumptionWaste: previousC,
            currentNrPdf: nonRecycled,
            monthsWaste: allMonths
          })
        }

        setPState(prevState => {
          return {...prevState, ...utilityConsumptionData}
        })
      })
    }
  }, [data])

  useEffect(() => {
    let allMonths = []
    if (data) {
      data.performanceInfos && data.performanceInfos.forEach(pi => {
        if (pi.utility === pState.parameter) {
          pi.annualPerformance.map(monthData => {
            allMonths.push(getLocalizedValue(selectedLanguage, 't_' + monthListShort[monthData.month].toLowerCase()))
          })
        }
      })
    }

    setPState(prevState => {
      return {
        ...prevState,
        months: allMonths,
        labelRecycled: ' ' + getLocalizedValue(selectedLanguage, 't_recycled'),
        labelNonRecycled: ' ' + getLocalizedValue(selectedLanguage, 't_non_recycled')
      }
    })
  }, [selectedLanguage, data])

  useEffect(() => {
    let performanceParameter = pState.parameter
    let parameterLabel = pState.parameterLabel
    let unit = pState.unit
    let parameter = pState.parameter
    // handling valid and invalid values from URL
    if (parameterOptions.length > 0) {
      performanceParameter = parameterOptions.some(element => element.value === pState.parameter) === true ? pState.parameter : queryValues.get('performance')
      parameter = performanceParameter
      const selectedValue = performanceParameter
      if (selectedValue === UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY) {
        parameterLabel = I18n('t_electricity')
        unit = unitsConverter(UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY, unitSystem)
      } else if (selectedValue === UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL) {
        parameterLabel = I18n('t_gas_fuel_thermal')
        unit = unitsConverter(UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL, unitSystem)
      } else if (selectedValue === UTILITY_LABELS_OBJECT_TITLE_CASE.WATER) {
        parameterLabel = I18n('t_water')
        unit = unitsConverter(UTILITY_LABELS_OBJECT_TITLE_CASE.WATER, unitSystem)
      } else if (selectedValue === UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE) {
        parameterLabel = I18n('t_waste')
        unit = '%'
      } else if (selectedValue === UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON) {
        parameterLabel = I18n('t_carbon')
        unit = unitsConverter(UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON, unitSystem)
      }
    }
    // handling valid and invalid values from URL
    const queryParams = {
      performance: performanceParameter
    }
    const passportPage = createNavURLForFundPages({instanceName, orgName, fundName, assetId, pathSuffix: ASSET_PAGES.passport})
    if (history.location.pathname === passportPage) {
      filterQueryParams(history, queryParams, rowData)
    }

    let graphData = {}
    if (data) {
      performanceInfos = data.performanceInfos
      graphData = getGraphData(pState.parameter)
    }

    setPState(prevState => {
      return {...prevState, parameter: parameter, parameterLabel: parameterLabel, unit: unit, first: 0, rows: 20, ...graphData}
    })
  }, [queryValues.get('performance'), pState.parameter])
  //endregion

  //region Helper methods

  const onParameterChange = (e) => {
    setPState({...pState, parameter: e.value})
  }
  const onOverlayPanelChangeHandler = (value) => {
    setPState({...pState, isOverlayPanelOpen: value})
  }

  function setColor(percentage, utility) {
    let colorText, percentageChange
    if (percentage > 5) {
      if (utility === UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE) {
        colorText = 'green-text'
      } else {
        colorText = 'red-text'
      }
    } else if (percentage < -5) {
      if (utility === UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE) {
        colorText = 'red-text'
      } else {
        colorText = 'green-text'
      }
    } else {
      colorText = 'grey-text'
    }
    // set + or -
    if (percentage < 0) {
      percentageChange = trucateNumWOneDecimal(percentage).toLocaleString(selectedLanguage)
    } else {
      percentageChange = '+' + trucateNumWOneDecimal(percentage).toLocaleString(selectedLanguage)
    }

    return {colorText, percentageChange}
  }

  function getGraphData(paramSelected) {
    const isWaste = paramSelected === UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE
    let currentNR = []
    let currentC = []
    let previousC = []
    let percentageInfo
    let currentYearConsumption = pState.currentYearConsumption
    let prevYearConsumption = pState.previousYearConsumption
    performanceInfos && performanceInfos.forEach(pi => {
      let percentage = +pi.percentageChange*100
      if(pi.utility === paramSelected) {
        pi.annualPerformance.map(monthData => {
          currentC.push(monthData.currentYearConsumption)
          previousC.push(monthData.previousYearConsumption)
          if(isWaste) {
            currentNR.push(monthData.currentYearNonRecycled)
          }
        })
        const cytc = trucateNumWODecimal(pi.currentYearTotalConsumption)
        const pytc = trucateNumWODecimal(pi.previousYearTotalConsumption)
        currentYearConsumption = formatNumWODecimal(selectedLanguage,trucateNumWODecimal(pi.currentYearTotalConsumption))
        prevYearConsumption = formatNumWODecimal(selectedLanguage,trucateNumWODecimal(pi.previousYearTotalConsumption))
        if (cytc > 9999 || pytc > 9999) {
          reduceSizes.current = true
        }
        //Applying color to the percentage & + / -
        percentageInfo = setColor(percentage, paramSelected)
      }
    })

    let currentYearNR = pState.currentYearNR
    if(isWaste) {
      currentYearNR = currentNR
    }

    return {
      colorText: percentageInfo ? percentageInfo.colorText : pState.colorText,
      percentageChange: percentageInfo ? percentageInfo.percentageChange : pState.percentageChange,
      currentConsumption: currentC,
      previousConsumption: previousC,
      currentYearNR: currentYearNR,
      currentYearConsumption: currentYearConsumption,
      previousYearConsumption: prevYearConsumption
    }
  }

  const chartData = {
    labels: pState.months,
    datasets: setGraphType(pState, preOneYear, latestYear, pState.labelNonRecycled, pState.labelRecycled, preTwoYear)
  }

  const exportChartData = (utility) => {
    let previousConsumption=[]
    let currentConsumption=[]
    let months=[]
    if(utility==='Electricity'){
      previousConsumption= pState.previousConsumptionElectricity
      currentConsumption= pState.currentConsumptionElectricity
      months= pState.monthsElectricity
    }else if(utility==='Thermal'){
      previousConsumption=pState.previousConsumptionThermal
      currentConsumption=pState.currentConsumptionThermal
      months= pState.monthsThermal
    }else if(utility==='Water'){
      previousConsumption=pState.previousConsumptionWater
      currentConsumption=pState.currentConsumptionWater
      months=pState.monthsWater
    }else if(utility==='Carbon'){
      previousConsumption=pState.previousConsumptionCarbon
      currentConsumption=pState.currentConsumptionCarbon
      months=pState.monthsCarbon
    }else if(utility==='Waste'){
      previousConsumption=pState.previousConsumptionWaste
      currentConsumption=pState.currentConsumptionWaste
      months=pState.monthsWaste
    }
    return {
      labels: months,
      datasets: setGraphTypePdf(utility,previousConsumption,currentConsumption,pState)
    }
  }

  const formatUnits = (value) => {
    return value.toLocaleString(selectedLanguage)
  }

  const onPageChange = (event) => {
    setPState({...pState, first: event.first, rows: event.rows})
  }

  //endregion

  //region Custom templates
  const getPeriodMenuItem = (option) => (option === 'YTD')?
    <span className='p-button-label p-c period-menu-ytd-tooltip' data-pr-position="top">{option}</span> :
    <span className='p-button-label p-c'>{option}</span>

  function classifyUtility(value) {
    let result = ''
    for (const individualUtility of pState.pdfPerformaceInfo) {
      if (individualUtility.utility === value) {
        result = individualUtility
        break
      }
    }

    return <div className="grid p-pl-6">
      <div className="col p-pl-6">
        <div>{formatUnits(result.previousYearTotalConsumption)}  {unitsConverter(value, unitSystem)}</div>
        <div>{preOneYear} {value === 'Waste' ? 'Total Recycling' : 'Total'}</div>
      </div>
      <div className="col">
        <div>{formatUnits(result.currentYearTotalConsumption)} {unitsConverter(value, unitSystem)}</div>
        <div>{date.current.toString()} {value === 'Waste' ? 'Total Recycling' : 'Total'}</div>
      </div>
      <div className="col">
        <div>{truncateDecimal(result.percentageChange * 100, 1)}%</div>
        <div>{I18n('t_y_o_y')}</div>
      </div>
    </div>
  }

  const header = <img alt="Card" className={pState.assetImage.assetImageUri ? 'p-image-actual' : 'p-image-filler'} src={propertyImage}/>

  const dropdownItemTemplate1 = (option) => {
    return (
      <>
        <div className="dropdown-item-1">
          <div className="dropdown-item-2">{option.label}</div>
        </div>
        <div className={'dropdown-panel-item-horiz-line'} />
      </>
    )
  }

  const certTemplate = () => {
    const listItems = pState.certifications.map((cert, i) =>
      <div key={i} className="card-text-value">
        <Tooltip className={'passport-comm-tooltip long-text-tooltip'} target=".pc-long-name-p-tooltip" />
        <div className={'pass-cert-name pc-long-name-p-tooltip text-wrapper'} data-ptf-tooltip={cert}
          data-pr-tooltip={''} onMouseEnter={(e)=>isTextOverflown(e)}>{cert}</div>
      </div>
    )
    return <div>{listItems.length !== 0 ? listItems : '-'}</div>
  }

  const energyRatingTemplate = () => {
    let energyRatingElement
    const energyScoreLastSyncedDate = pState.esLastSync !== '-' ? unitDateFormatter(pState.esLastSync, unitSystem, selectedLanguage) : pState.esLastSync
    const assetCountryUsCanada = checkCountries.includes(pState?.assetCountry)

    if('-'=== pState.energyRating){
      energyRatingElement= <div className={'pass-cert-name text-wrapper'} data-ptf-tooltip={pState.energyRating}
        data-pr-tooltip={''} onMouseEnter={(e)=>isTextOverflown(e)}>
        {pState.energyRating}
      </div>
    } else {
      if(assetCountryUsCanada) {
        energyRatingElement= <div className={'energy-rating'}>
          <Tooltip className={'energy-score-tooltip'} target=".p-energy-star-score-text">
            {I18n('t_energy_star_last_synced', {0 : energyScoreLastSyncedDate})}
          </Tooltip>
          <div className={'pass-cert-name text-wrapper'}>{pState.energyRating}</div>
          <img data-testid={'info-icon'} src={infoIcon} alt={'Info-icon'} className={'p-energy-star-score-text'} />
        </div>
      } else {
        energyRatingElement= <div>
          <Tooltip className={'passport-comm-tooltip long-text-tooltip'} target=".p-tooltip-energy-rating-txt" />
          <div className={'pass-cert-name text-wrapper p-tooltip-energy-rating-txt'} data-ptf-tooltip={pState.energyRating}
            data-pr-tooltip={''} onMouseEnter={(e)=>isTextOverflown(e)}>{pState.energyRating}</div>
        </div>
      }
    }
    return energyRatingElement
  }

  const certLabelTemplate = () => {
    const listItems = pState.certifications.map((cert, index) =>
      <>{(index === 0) ? I18n('t_certifications') : ''}</>
    )
    return <div>{listItems.length === 0 ? I18n('t_certifications') : listItems}<br/></div>
  }

  const legendPDF = (value) => {
    return <div className="p-jc-between p-d-flex">
      <span className="p-pl-3">{value}</span>
      <div className="p-d-flex">
        {value.props.id === 't_waste' ?
          <div className="actual p-pr-3">
            <img src={NonRecycled} className="p-mr-2" alt="currentYear"/>
            <span className="small-text">{getLocalizedValue(selectedLanguage, 't_non_recycled')}</span>
          </div> : null
        }
        <div className="actual p-pr-3">
          <img src={blueRectangle} className="p-mr-2" alt="currentYear"/>
          <span className="small-text">{date.current.toString()-1}/{date.current.toString()}</span>
        </div>
        <div className="target">
          <img src={lightBlueRectangle} className="p-mr-2" alt="preOneYear"/>
          <span className="small-text">{preOneYear-1}/{preOneYear}</span>
        </div>
      </div>
    </div>
  }

  //endregion

  //region Datepicker handler
  const changeTimePeriodHandler = (value) => {
    if(value){
      setPState({...pState, period: value})
    }
  }

  const changeDatePickerHandler = (name, value) => {
    setPState({
      ...pState, ...pState.pickerValue,
      [name]: value,
    })
  }

  const closeOverlay = () => {
    exportOverlay.current.hide()
  }

  //endregion

  //region subComponent
  function AssetSummaryCard(){
    const assetCountryUsCanada = checkCountries.includes(pState?.assetCountry)
    return  <Card className="asset-card" header={header}>
      <div className={'asset-summary-content-wrapper'} ref={assetSummaryContentWRef}>
        <div className={'asset-address-wrapper'}>
          {isLoading ? <PassportPageSingleLineSkeleton/> :
            <>
              <Tooltip className={'passport-comm-tooltip long-text-tooltip'} target=".asset-address"/>
              <div className="ex-large-text asset-common passport-widget-p-tooltip asset-address text-wrapper"
                data-testid={'asset-address'}
                data-ptf-tooltip={pState.assetAddress}
                data-pr-tooltip=""
                data-pr-position="right"
                onMouseEnter={(e)=>isTextOverflown(e)}>
                {pState.assetAddress}
              </div>
            </>
          }
        </div>
        <div className={'fund-name-wrapper'}>
          {isLoading? <PassportPageSingleLineSkeleton/> :
            <>
              <Tooltip className={'passport-comm-tooltip long-text-tooltip'} target=".fund-name"/>
              <div className="medium-text asset-common passport-widget-p-tooltip fund-name text-wrapper"
                data-testid={'asset-fund-name'}
                data-ptf-tooltip={pState.assetFundName}
                data-pr-tooltip=""
                data-pr-position="right" onMouseEnter={(e)=>isTextOverflown(e)}>
                {pState.assetFundName}
              </div>
            </>
          }

        </div>
        <hr className="partition-line"/>
        <div className="asset-common">
          <div className="asset-details-card">
            { isLoading ?
              <>
                <PassportPageSingleLineSkeleton/>
                <PassportPageSingleLineSkeleton/>
                <PassportPageSingleLineSkeleton/>
                <PassportPageSingleLineSkeleton/>
                <PassportPageSingleLineSkeleton/>
              </>
              :
              <>
                <div className='label-value-group'>
                  <div data-testid={'floor-area'} className="card-text">{I18n('t_floor_area')}</div>
                  <div className="card-text-value text-wrapper">{pState.floorArea === '-' ? pState.floorArea : <>{pState.floorArea}
                    {unitsConverter(AREA, unitSystem)}</>}
                  </div>
                </div>
                <div className='label-value-group'>
                  { assetCountryUsCanada ?
                    <div data-testid={'energy-rating'} className="card-text">{I18n('t_energy_star_score')}</div> :
                    <div data-testid={'energy-rating'} className="card-text">{I18n('t_energy_rating')}</div>
                  }
                  <div data-testid={'energy-rating-value'} className="card-text-value text-wrapper">{energyRatingTemplate()}</div>
                </div>
                <div className='label-value-group'>
                  <div data-testid={'last-refurbished'} className="card-text">{I18n('t_last_refurbished')}</div>
                  <div className="card-text-value text-wrapper">{pState.lastRefurbished}</div>
                </div>
                <div className='label-value-group'>
                  <div data-testid={'occupancy'} className="card-text">{I18n('t_occupancy')}</div>
                  <div className="card-text-value text-wrapper">{pState.occupancy === '-' ? pState.occupancy : <>{pState.occupancy}%</>}</div>
                </div>
                <div className='label-value-group'>
                  <div data-testid={'certifications'} className="card-text">{certLabelTemplate()}</div>
                  {certTemplate()}
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </Card>
  }

  function ExportModel(){
    return <div className="charts-pdf" id="passportImages">
      <div className="p-3 black-text ex-large-text-bold">
        <span className="pb-2">{pState.assetAddress}</span><br/>
        <span>{pState.assetFundName}</span>
      </div>
      <div className="flex">
        <Card className="mr-1">
          {legendPDF(I18n('t_electricity'))}
          <Chart type="bar" className="pt-3" data={exportChartData('Electricity')} options={exportChartOptionsPdf}
            style={pdfChartArea} ref={electricityRef}/>
          {pState.pdfPerformaceInfo ? classifyUtility('Electricity') : null}
        </Card>
        <Card className="">
          {legendPDF(I18n('t_gas_fuel_thermal'))}
          <Chart type="bar" className="pt-3" data={exportChartData('Thermal')} options={exportChartOptionsPdf}
            style={pdfChartArea} ref={thermalRef}/>
          {pState.pdfPerformaceInfo ? classifyUtility('GasFuelsAndThermals') : null}
        </Card>
      </div>
      <div className="flex mt-1">
        <Card className="mr-1">
          {legendPDF(I18n('t_water'))}
          <Chart type="bar" className="pt-3" data={exportChartData('Water')} options={exportChartOptionsPdf}
            style={pdfChartArea} ref={waterRef}/>
          {pState.pdfPerformaceInfo ? classifyUtility('Water') : null}
        </Card>
        <Card className="">
          {legendPDF(I18n('t_carbon'))}
          <Chart type="bar" className="pt-3"  data={exportChartData('Carbon')} options={exportChartOptionsPdf}
            style={pdfChartArea} ref={cabonRef}/>
          {pState.pdfPerformaceInfo ? classifyUtility('Carbon') : null}
        </Card>
      </div>
      <div className="flex mt-1">
        <Card className="mr-1">
          {legendPDF(I18n('t_waste'))}
          <Chart type="bar" className="pt-3" data={exportChartData('Waste')} options={exportChartOptionsPdf}
            style={pdfChartArea} ref={wasteRef}/>
          {pState.pdfPerformaceInfo ? classifyUtility('Waste') : null}
        </Card>
      </div>
    </div>
  }
  //endregion

  // region export props
  const passportExportInfoProps = {
    'closeOverlay': closeOverlay, 'lastRefurbished': pState.lastRefurbished, 'assetImage': propertyImagePdf,
    'certificates': pState.certifications, 'passport': true, 'language': selectedLanguage,// probable not required
    'carbon': cabonRef, 'thermal': thermalRef, 'water': waterRef, 'electricity': electricityRef,
    'utility': pState.pdfPerformaceInfo, 'waste': wasteRef, 'data': data?.actionInfos || [], 'asset': rowData // probably not required
    , 'exportFrom' : {'page-type':'asset-page','from':'asset-passport'},
  }
  // endregion

  return (
    <div data-page_identifier="asset_passport" className="passport">
      <div className="container-layout gutter">
        <BackNavigation exportName="Export as PDF" onExportClick={(e) => exportOverlay.current.toggle(e)} disableExportButton = {false} aria-haspopup
          aria-controls="overlay_panel" period={pState.period} options={periodOptions}
          datePickerOnChange={(e) => changeTimePeriodHandler(e.value)}
          itemTemplate={getPeriodMenuItem} pickerValue={pState.pickerValue}
          pickerOnChange={(e) => changeDatePickerHandler(e.target.name, e.target.value)} hideTimePeriodFilter={true}
          isOverlayPanelOpen = {pState.isOverlayPanelOpen}
        />
        <OverlayPanel ref={exportOverlay} appendTo={document.getElementById('export')} className="export-overlay"
          onShow={() => onOverlayPanelChangeHandler(true)} onHide={() => onOverlayPanelChangeHandler(false)}>
          <AssetExportSelection exportDetails = {passportExportInfoProps} />
        </OverlayPanel>
        <div className="grid page-content">
          <div className="col-12 pass-content no-padding">
            <div className="col-3 asset-padding">
              <div data-testid={'asset-summary'} className="asset-summary-title">{I18n('t_asset_summary')}</div>
              <AssetSummaryCard/>
            </div>
            <div className="col-9 only-left-padding">
              <div className="pass-content-align">
                <span data-testid={'performance-label'} className="ex-large-text">{I18n('t_performance')}</span>
              </div>
              <Card className="perf-chart-panel">
                <div className="grid chart-passport">
                  {isLoading ? <PassportPageChartSkeleton/> :
                    <div className="col-9 no-padding">
                      <div data-testid={'parameterLabel'} className="card">
                        <div className="perf-label">{pState.parameterLabel}: <span className="label-month">{I18n('t_past_12_months')}</span></div>
                        <Chart type="bar" style={{borderRadius: '3px', width: '95%', height:'595px'}} data={chartData} options={chartOptions}/>
                      </div>
                    </div>
                  }
                  <div className="col-3 no-padding col-consumption-summary">
                    <div data-testid={'parameter'} className="no-padding">
                      <Dropdown appendTo="self" value={pState.parameter} options={parameterOptions} onChange={onParameterChange}
                        className="sDropdown dropdown-ext"
                        scrollHeight={DROP_DOWN_HEIGHT} itemTemplate={dropdownItemTemplate1} id={'parameter-dropdown'}/>
                    </div>
                    <div className="unit-total-view">
                      {isLoading? <PassportPageConsumptionSummarySkeleton/> :
                        <>
                          <div className="last-year-unit-total">
                            {date.current - 1} {(pState.parameter === UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE) ?
                              <>{I18n('t_total_recycling')}</> : I18n('t_total')
                            }
                          </div>
                          <div className={'last-year-unit'}>
                            {pState.previousYearConsumption}
                            {(pState.parameter === UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE) ? '' : ' '}
                            {pState.unit ? pState.unit :
                              <span className="medium-text-bold no-wrap">kg CO<sub>2</sub></span>
                            }
                          </div>
                        </>
                      }
                    </div>
                    <div className="no-padding">
                      <hr className="partition-line-1"/>
                    </div>
                    <div className="consumption-summary-row">
                      {isLoading ? <PassportPageConsumptionSummarySkeleton/> :
                        <>
                          <div className="current-year-unit-total">{date.current}&nbsp;
                            {(pState.parameter === UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE) ?
                              <>{I18n('t_total_recycling')}</> : I18n('t_total')
                            }
                          </div>
                          <div className={'current-year-unit'}>
                            {pState.currentYearConsumption}
                            {(pState.parameter === UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE) ? '' : ' '}
                            {pState.unit ? pState.unit : <span className="medium-text-bold no-wrap">kg CO<sub>2</sub></span>}
                          </div>
                        </>
                      }
                    </div>
                    <div className="no-padding">
                      <hr className="partition-line-1"/>
                    </div>
                    <div className="consumption-summary-row">
                      {isLoading? <PassportPageConsumptionSummarySkeleton/> :
                        <>
                          <div className="label-yoy">{I18n('t_y_o_y')}</div>
                          <div className={`${pState.colorText} percent-unit`}>
                            {pState.percentageChange}%
                          </div>
                        </>
                      }
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
          <ExportModel/>
          <div data-testid='passport-table' className="col-12 only-top-padding completed-action-table-view">
            <div data-testid={'completed-actions'} className="ca-table-view-title">{I18n('t_completed_actions')}</div>
            <PassportTable isLoading={isLoading} pState={pState} onPageChange={onPageChange}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PassportHome

PassportHome.displayName ='PassportHome'
