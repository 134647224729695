import {useRef, useEffect, memo} from 'react'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Button} from 'primereact/button'
import commentIcon from '../../../../../resources/images/chart/comment-blue.svg'
import I18n from '../../../../../utils/i18n/I18n'
import {getLocalizedDateMessage, getNotificationTemplate, unitDateFormatter, getLocalizedValue} from '../../../../../utils/helpers/Helper'
import PerformanceEventActionsSkeleton from '../../../../common/skeletons/performance-eventActions-skeleton/PerformanceEventActionsSkeleton'
import ModalDialogCommercialEvent from '../../../../common/modal-dialogs/ModalDialogCommercialEvent'
import ActionEdit from '../../action-plan/dialogs/Action'
import CommercialEventModalDialog from '../dialogs/CommercialEventModalDialog'
import InfoModal from '../../../../common/modal-dialogs/InfoModal'
import {useParams} from 'react-router-dom'
import useAssetActionsAndActiveSurveyData from '../performance-hooks-&-helpers/useAssetActionsAndActiveSurveyData'
import useEventActionsState from '../performance-hooks-&-helpers/useEventActionsState'
import './EventActions.scss'
import {ActionEventType} from '../performance-hooks-&-helpers/performanceConstants'
import CommercialEventModalDialogHeader from './CommercialEventModalDialogHeader'
import PropTypes from 'prop-types'

const EventActions = ({performancesEventsData, isLoadingAssetPerformanceData, tableDataPdfRef, selectedLanguage, unitSystem}) => {
  //region Declarations and initialisations
  const defaultMaxRecordsToShow = 10
  const {assetId} = useParams()
  const notesCurrentValueRef = useRef()
  const assetEventsDataTableRef = useRef(null)
  const {eventActionsState, updateEventActionsState} = useEventActionsState()
  const {actionsData, actionsDataRefetch, assetHasActiveSurvey} = useAssetActionsAndActiveSurveyData(assetId)
  const getEventsDataTableRows = () => assetEventsDataTableRef?.current?.container?.getElementsByTagName('tbody')[0]?.childNodes
  //endregion

  //region useEffect
  useEffect(() => {
    let eventsData
    if (performancesEventsData?.length <= defaultMaxRecordsToShow) {
      eventsData = performancesEventsData
    } else {
      eventsData = performancesEventsData?.slice(0, defaultMaxRecordsToShow)
    }
    setSelectedDataForPDFExport(eventsData)
    updateEventActionsState({assetEventsData: eventsData}) //Set table-data
  }, [performancesEventsData])
  //endregion

  //region Dialog & Overlay handlers
  function setSelectedDataForPDFExport(selectedEventsData) {
    tableDataPdfRef.current = selectedEventsData  // Set data for PDF export
  }

  function hasNotesValue() {
    return !!notesCurrentValueRef.current
  }

  const closeDialog = () => {
    updateEventActionsState({selectedRowActionData: null, showActionDlg: !eventActionsState.showActionDlg})
  }

  const closeActionDialog = () => {
    if (!notesCurrentValueRef.current) {
      updateEventActionsState({showActionDlg: false})
      closeDialog()
    }
  }

  const removeHighlightClassFromNode = node => {
    node?.classList?.contains('p-highlight') && node.classList.remove('p-highlight')
  }

  function commercialEventModalDlgCloseHandler() {
    updateEventActionsState({showCommercialEventDlg: !eventActionsState.showCommercialEventDlg})
    let tableRows = getEventsDataTableRows()
    if (tableRows.length > 0) {
      tableRows.forEach(removeHighlightClassFromNode)
    }
  }

  const closeActiveSurveyDialog = () => {
    updateEventActionsState({visibleActiveSurveyDialog: false})
    let tableRows = getEventsDataTableRows()
    if (tableRows.length > 0) {
      tableRows.forEach(removeHighlightClassFromNode)
    }
  }

  function getLatestNotes(selectedActionData) {
    return `${getLocalizedValue(selectedLanguage, 't_latest')}: ${selectedActionData?.actionNotes[0]?.userName}`
  }
  //endregion

  //region Custom templates
  const notesTemplate = (rowData) => {
    let selectedActionData
    if (rowData.eventType === ActionEventType.COMPLETED_ACTION) {
      const rowId = rowData.content?.filter(eventContent => eventContent.key === 'RecommendationId')[0]?.value
      selectedActionData = actionsData?.filter(actionData => actionData.action?.actionId?.toString() === rowId)[0]
      selectedActionData?.actionNotes?.sort((first, next) => first.dateCreated < next.dateCreated ? 1 : -1)
      rowData.notes = selectedActionData?.actionNotes //Notes are added to use in PDF
    }
    return (
      <>
        {selectedActionData?.actionNotes?.length > 0 ?
          <div className="comment-section" id={`row-${selectedActionData?.action?.actionId}`}>
            <div className="note-info">
              <span className="latest-note-date">
                {getLatestNotes(selectedActionData)} - {getLocalizedDateMessage(selectedActionData?.actionNotes[0]?.dateCreated, unitSystem, selectedLanguage)}
              </span>
              <span className="notes-content">{selectedActionData?.actionNotes[0]?.content}</span>
            </div>
            <div className={'comment-number-icon perf-events-highlight-clickable-opacity'}>
              <img src={commentIcon} alt="" data-id={rowData.eventID} className="note-icon"/>
              {selectedActionData?.actionNotes?.length > 0 ? <span className="notes-count column-note">{selectedActionData?.actionNotes.length}</span> : null}
            </div>
          </div>
          :
          null
        }
      </>
    )
  }

  const eventTemplate = (rowData) => <label>{getNotificationTemplate(rowData)}</label>

  const dateTemplate = (rowData) => <label>{unitDateFormatter(rowData.impactDate, unitSystem, selectedLanguage)}</label>

  const typeTemplate = (rowData) => {
    let eventMessageKey
    if(rowData.eventType === ActionEventType.COMPLETED_ACTION){
      eventMessageKey='t_action'
    } else if(rowData.eventType === ActionEventType.COMMERCIAL_EVENT){
      eventMessageKey='t_commercial'
    }
    return eventMessageKey ? <label>{I18n(eventMessageKey)}</label> : ''
  }

  const renderFooter = () => {
    return (performancesEventsData?.length > 10 && eventActionsState.assetEventsData?.length !== performancesEventsData?.length) ?
      <Button label={I18n('t_show_more')} className="p-button-secondary medium-text-bold eventActions-more" onClick={handleShowMore} />
      :
      null
  }
  //endregion

  function handleShowMore() {
    setSelectedDataForPDFExport(performancesEventsData)
    updateEventActionsState({assetEventsData: performancesEventsData})
  }

  const onRowSelect = (e) => {
    const rowEventData = e.data
    if (rowEventData?.eventType === ActionEventType.COMPLETED_ACTION) {
      const rowId = rowEventData?.content.filter(eventContent => eventContent.key === 'RecommendationId')
      const rowActionData = actionsData?.filter(actionData => actionData.action?.actionId?.toString() === rowId[0]?.value)[0]
      const showActionDlg = !eventActionsState.showActionDlg && !assetHasActiveSurvey  //Show action dialog if current asset doesn't have an active survey
      updateEventActionsState({
        selectedRowActionData: rowActionData,
        showActionDlg,
        visibleActiveSurveyDialog: true,
        actionEditMode: true
      })
    } else if (rowEventData?.eventType === ActionEventType.COMMERCIAL_EVENT) {
      updateEventActionsState({
        selectedRowEventData: rowEventData,
        showCommercialEventDlg: !eventActionsState.showCommercialEventDlg
      })
    }
  }

  const reLoad = () => {
    actionsDataRefetch().catch(error => {
      console.log('Error while reloading actions data', error)
    })
  }

  function setActionNotesValue(value) {
    notesCurrentValueRef.current = value
  }

  function getEventsDataTable() {
    if( eventActionsState.assetEventsData?.length > 0){
      return(
        <DataTable
          value={eventActionsState.assetEventsData}
          footer={renderFooter()}
          ref={assetEventsDataTableRef}
          selectionMode="single"
          onRowSelect={onRowSelect}
          className="event-actions-grid"
        >
          <Column field="date" header={I18n('t_date')} body={dateTemplate} className="dateColumn"></Column>
          <Column field="type" header={I18n('t_type')} body={typeTemplate} className="typeColumn"></Column>
          <Column field="action" header={I18n('t_event')} body={eventTemplate} className="actionColumn"></Column>
          <Column field="notes" header={I18n('t_notes')} body={notesTemplate} className="commentColumn"></Column>
        </DataTable>
      )
    } else {
      return <div className="no-result-msg"><span className="no-result-msg-text">{I18n('t_no_events_found')}</span></div>
    }
  }

  function getEventActionsSkeleton() {
    return(
      <div className="events-grid-before-load">
        <PerformanceEventActionsSkeleton/>
        <PerformanceEventActionsSkeleton/>
        <PerformanceEventActionsSkeleton/>
      </div>
    )
  }
  //endregion

  return (
    <div className="chart-event-actions-table grid-nogutter">
      {isLoadingAssetPerformanceData ? getEventActionsSkeleton() : getEventsDataTable()}
      {eventActionsState.showActionDlg && <ActionEdit
        edit={eventActionsState.actionEditMode}
        activeIndex={1}
        assetId={assetId}
        closeActionDialog={closeActionDialog}
        showDialog={eventActionsState.showActionDlg}
        selection={eventActionsState.selectedRowActionData}
        getActionNotesValue={setActionNotesValue}
        isNotesValue={hasNotesValue()}
        reLoad={reLoad}
        actionsList={actionsData}
        onEventActionHideHandler={() => {closeDialog()}}
      />
      }
      {eventActionsState.showCommercialEventDlg && (
        <ModalDialogCommercialEvent
          showDialog={eventActionsState.showCommercialEventDlg}
          onModalDialogCloseHandler={commercialEventModalDlgCloseHandler}
          header={<CommercialEventModalDialogHeader eventActionsState={eventActionsState} selectedLanguage={selectedLanguage} />}
          tabMenuList={[{title: getLocalizedValue(selectedLanguage, 't_details'), component: <CommercialEventModalDialog data={eventActionsState.selectedRowEventData}/>}]}
        />
      )}
      {assetHasActiveSurvey && <InfoModal onCloseActiveSurveyDialog={closeActiveSurveyDialog} visibleActiveSurveyDialog={eventActionsState.visibleActiveSurveyDialog}/>}
    </div>
  )
}
export default memo(EventActions)

EventActions.displayName = 'EventActions'

EventActions.propTypes = {
  performancesEventsData: PropTypes.array,
  isLoadingAssetPerformanceData: PropTypes.bool,
  tableDataPdfRef: PropTypes.object,
  selectedLanguage: PropTypes.string,
  unitSystem: PropTypes.string
}
