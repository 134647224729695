import {Text, View} from '@react-pdf/renderer'
import React, {useState} from 'react'
import styles from '../style-sheet/Styles'
import I18n from '../../i18n/I18n'
import {ELECTRICITY, UTILITY_LABELS_OBJECT_TITLE_CASE, WATER} from '../../helpers/Constants'
import {calcTitleAndPosition, firstRow, secondRow, thirdRow} from '../pdf-utils/PdfHelper'

const UtilityCard = (props) => {
  const [utility] = useState(props.utility)
  const  isAllDataNotNull = props.dashboard ? utility.targetAmount !== null && utility.amountUptoTarget !== null &&
                         utility.differencePercentage !== null : utility.previousYearTotalConsumption !== null &&
                         utility.currentYearTotalConsumption !== null && utility.percentageChange !== null

  const calcRow = (value) => {
    let row
    if (value === ELECTRICITY || value === UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL || value === WATER ) {
      row=styles.firstRowUtility
    }else {
      row=styles.secondRowUtility
    }
    return row
  }

  return (
    <>
      {isAllDataNotNull ?
        <View style={[styles.assets, styles.borderExceptBottom, calcRow(props.utilityName), calcTitleAndPosition(props.utilityName).position]}>
          <Text style={[styles.title, styles.topM17]}>
            {calcTitleAndPosition(props.utilityName).title}
          </Text>
          {firstRow(props,utility)}
          {secondRow(props,utility)}
          {thirdRow(props,utility)}
        </View>
        :
        <View style={[styles.assets, styles.planBorder, calcRow(props.utilityName), calcTitleAndPosition(props.utilityName).position]}>
          <Text style={[styles.title, styles.topM17]}>
            {calcTitleAndPosition(props.utilityName).title}
          </Text>
          <Text style={[styles.assetTextSeven,styles.mL8]}>
            {I18n('t_msg_no_data')}
          </Text>
        </View>
      }
    </>
  )
}

export default UtilityCard