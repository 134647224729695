import {
  applyStylesForSummaryTable,
  convertToExcelExportFormat,
  formatDateToDDMMYY,
  getLocalizedColumnNamesWithUnits,
  getDateRangeAndTimePeriodInfo,
  getEmptyRows,
  getRequiredUnit,
  getTableHeadersAfterLocalizationArray,
  getTitleObject,
  getValidData, UNIT_TYPE_FOR_EXPORT
} from '../../ExportHelper'
import I18n from '../../../i18n/I18n'
import {
  DATA_FROM, UTILITY_LABELS_OBJECT_TITLE_CASE,
  UTILITY_OTHER_NAME_MAP
} from '../../../helpers/Constants'
import { excelHelpers, formatDateToDdMonYy, getLocalizedValue } from '../../../helpers/Helper'
import XlsxPopulate from 'xlsx-populate'
import FileSaver from 'file-saver'
  
export const AssetHomeDataQualityExportHelper = async (props,selectedLanguage, dqExportData, selectedUtility, intl) => {
  function appendSheetHeaderInformationToExcelDataArray(selectedUtility) {
    let headerInfo = {
      fundName: props?.filterSelection?.filterFundSelected,
      location:  props?.filterSelection?.filterLocationSelected,
      type: props?.filterSelection?.filterSectorSelected,
      dateRange: getDateRangeAndTimePeriodInfo(props?.timePeriod,props?.pickerValue,selectedLanguage).dateRange
    }
    return getSheetHeaderInformationForFundDataQuality(headerInfo, selectedLanguage)
  }
  
  function populateExcelExportDataForAllUtilities(selectedUtility,requiredUtilityInfo) {
    let excelDataArray = []
    excelDataArray.push(...appendSheetHeaderInformationToExcelDataArray(selectedUtility))

    let summaryTablesList
    switch (selectedUtility) {
    case UTILITY_LABELS_OBJECT_TITLE_CASE.SUMMARY :
      summaryTablesList = getSummaryTableNamesList().summary
      break
    default :
      summaryTablesList = getSummaryTableNamesList().otherUtilities
      break
    }
    let summaryTableInfo = {}
    summaryTablesList.forEach(table => {
      switch (table) {
      case Export_SUMMARY_TABLE_NAMES.fundSummary:
      case Export_SUMMARY_TABLE_NAMES.landlordSummary:
      case Export_SUMMARY_TABLE_NAMES.tenantSummary:
        summaryTableInfo = getTableHeaderNamesForSummary(getSummaryTableTitle(table), selectedLanguage,true)
        excelDataArray.push(...summaryTableInfo.summaryTableHeaderNames)
        excelDataArray.push(...getSummaryTableRowData(requiredUtilityInfo[table], selectedLanguage,true))
        break
      default :
        summaryTableInfo = getTableHeaderNamesForSummary(getSummaryTableTitle(table), selectedLanguage,false)
        excelDataArray.push(...summaryTableInfo.summaryTableHeaderNames)
        excelDataArray.push(...getSummaryTableRowData(requiredUtilityInfo[table], selectedLanguage,false))
        break
      }
    })
    let {assetTableHeaderNames, assetTableColumnLength} = getAssetTableHeaderNames('t_all_assets', 'all', selectedLanguage,intl)
    excelDataArray.push(...assetTableHeaderNames)
    excelDataArray.push(...getAssetTableRowData(requiredUtilityInfo?.assets, selectedLanguage))

    return {excelDataArray,assetTableColumnLength,assetTableRowLength: requiredUtilityInfo?.assets.length}
  }
  
  async function assetHomeDataQualityAllUtilitiesExport(){
  
    // execution starts here ...
    const blob = await XlsxPopulate.fromBlankAsync()
      .then(workbook => {
        getSheetNamesForPortfolioDataQualityExcelExport().forEach((sheet, index) => {
          if(selectedUtility === 'All' || sheet.value === selectedUtility){
            const utilityName = sheet.value === UTILITY_OTHER_NAME_MAP.OtherFuelsAndThermals ? sheet.value.charAt(0).toLowerCase() + sheet.value.slice(1) : sheet.value.toLowerCase() // need some modification
            const excelSheet = index === 0 || sheet.value === selectedUtility ? workbook.sheet(0).name(sheet.value) : workbook.addSheet(sheet.value)
            const exportedExcelData = populateExcelExportDataForAllUtilities(sheet.value, data[utilityName])
            const error = getTitleObject('t_no_export_data_available',selectedLanguage)
            if(!exportedExcelData.assetTableRowLength){
              exportedExcelData.excelDataArray.push(error)
            }
            const { excelDataArray } = exportedExcelData
            excelHelpers(excelDataArray, excelSheet, error)
          }
        })
        workbook.activeSheet(selectedUtility === UTILITY_OTHER_NAME_MAP.All ? UTILITY_LABELS_OBJECT_TITLE_CASE.SUMMARY : selectedUtility)
        return workbook.outputAsync(workbook)
      })
    FileSaver.saveAs(blob, getLocalizedValue(selectedLanguage, 't_portfolio_data_quality_file_name')+'.xlsx')
  }
  
  // execution starts here ...
  let data = dqExportData?.data
  await assetHomeDataQualityAllUtilitiesExport()
}
  
export function getSummaryTableNamesList() {
  return {
    otherUtilities:[Export_SUMMARY_TABLE_NAMES.total, Export_SUMMARY_TABLE_NAMES.landlord, Export_SUMMARY_TABLE_NAMES.tenant],
    summary:[Export_SUMMARY_TABLE_NAMES.fundSummary, Export_SUMMARY_TABLE_NAMES.landlordSummary, Export_SUMMARY_TABLE_NAMES.tenantSummary]
  }
}
export const Export_SUMMARY_TABLE_NAMES = Object.freeze({
  total : 'total',
  landlordSummary: 'landlordSummary',
  tenantSummary: 'tenantSummary',
  fundSummary: 'fundSummary',
  tenant : 'tenant',
  landlord : 'landlord'
})
export function getFontStyleInfoForExportTitles(sheet) {
  switch (sheet) {
  case UTILITY_LABELS_OBJECT_TITLE_CASE.SUMMARY.toLowerCase() : return [{cell: 'A1', size: 16}, {cell: 'A8', size: 12}, {cell: 'A17', size: 12},
    {cell: 'A26', size: 12}, {cell: 'A35', size: 12}]
  default : return [{cell: 'A1', size: 16}, {cell: 'A8', size: 12}, {cell: 'A14', size: 12},
    {cell: 'A20', size: 12}, {cell: 'A26', size: 12}]
  }
}
  
export function getStylesForSummaryAndAssetTable(sheet, dataToBeExported,exportedExcelData) {
  switch (sheet) {
  case UTILITY_LABELS_OBJECT_TITLE_CASE.SUMMARY.toLowerCase() :
    dataToBeExported = applyStylesForSummaryTable(10, 4, 6, dataToBeExported)
    dataToBeExported = applyStylesForSummaryTable(19, 4, 6, dataToBeExported)
    dataToBeExported = applyStylesForSummaryTable(28, 4, 6, dataToBeExported)
    dataToBeExported = applyStylesForSummaryTable(37, exportedExcelData.assetTableRowLength, exportedExcelData.assetTableColumnLength, dataToBeExported)
  
    return dataToBeExported
  default :
    dataToBeExported = applyStylesForSummaryTable(10, 1, 5, dataToBeExported)
    dataToBeExported = applyStylesForSummaryTable(16, 1, 5, dataToBeExported)
    dataToBeExported = applyStylesForSummaryTable(22, 1, 5, dataToBeExported)
    dataToBeExported = applyStylesForSummaryTable(28, exportedExcelData.assetTableRowLength, exportedExcelData.assetTableColumnLength, dataToBeExported)
  
    return dataToBeExported
  }
}
export function getSummaryTableTitle(table){
  switch (table){
  case Export_SUMMARY_TABLE_NAMES.fundSummary : return 't_summary'
  case Export_SUMMARY_TABLE_NAMES.landlordSummary : return 't_landlord_summary'
  case Export_SUMMARY_TABLE_NAMES.tenantSummary : return 't_tenant_summary'
  case Export_SUMMARY_TABLE_NAMES.total : return 't_total'
  case Export_SUMMARY_TABLE_NAMES.landlord : return 't_landlord'
  case Export_SUMMARY_TABLE_NAMES.tenant : return 't_tenant'
  }
}
export const getSheetNamesForPortfolioDataQualityExcelExport = () => {
  return [  {label: I18n('t_title_summary'), value: UTILITY_LABELS_OBJECT_TITLE_CASE.SUMMARY},
    {label: I18n('t_electricity'), value: UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY},
    {label: I18n('t_gas'), value: UTILITY_LABELS_OBJECT_TITLE_CASE.GAS},
    {label: I18n('t_otherfuelsandthermals'), value: UTILITY_LABELS_OBJECT_TITLE_CASE.OTHER_FUELS_THERMALS},
    {label: I18n('t_water'), value: UTILITY_LABELS_OBJECT_TITLE_CASE.WATER},]
}
export const getSheetHeaderInformationForFundDataQuality = (headerDetails,selectedLanguage) => {
  return [
    getTitleObject('t_portfolio_data_quality_report',selectedLanguage),
    {A: `Fund: ${headerDetails.fundName}`},
    {A: `Location : ${headerDetails.location}`},
    {A: `Type : ${headerDetails.type}`},
    {A: `Date range : ${headerDetails.dateRange}`},
    {A: `${getLocalizedValue(selectedLanguage, 't_date_of_export')}: ${formatDateToDdMonYy()}` },
    ...getEmptyRows(2)
  ]
}
export const getTableHeaderNamesForSummary = (tableTitle, selectedLanguage,isFundSummaryTable) => {
  let tableHeadersToBeLocalized = [
    't_floor_area_not_covered_percentage','t_actual_percentage', 't_supplier_estimated_percentage', 't_incomplete_percentage',
    't_total_missing_percentage'
  ]
  let tableHeadersWithLocalizedValue = getTableHeadersAfterLocalizationArray(tableHeadersToBeLocalized, selectedLanguage)
  isFundSummaryTable && tableHeadersWithLocalizedValue.unshift('')
  return {
    summaryTableHeaderNames: [getTitleObject(tableTitle, selectedLanguage), convertToExcelExportFormat(tableHeadersWithLocalizedValue)],
    summaryTableColLength: tableHeadersWithLocalizedValue.length
  }
}
const getAssetTableHeaderNames = (tableTitle, type, selectedLanguage,intl) => {
  function getFloorAreaTableHeader() {
    switch (type) {
    case Export_SUMMARY_TABLE_NAMES.tenant :
      return 't_dq_floor_area_tenant'
    case Export_SUMMARY_TABLE_NAMES.landlord :
      return 't_dq_floor_area_landlord'
    case UTILITY_OTHER_NAME_MAP.All.toLowerCase() :
      return 't_dq_floor_area_gia'
    default :
      return 't_dq_floor_area'
    }
  }
  
  let floorAreaType = getFloorAreaTableHeader()

  let tableHeadersToBeLocalized = [
    {key: 't_asset_ref'}, {key: 't_name'}, {key: 't_fund'}, { key: 't_country'},
    {key: 't_sector'}, {key: 't_managing_agent'}, { key: 't_purchase_date'},
    {key: 't_sale_date'}, {key: 't_missing_fund_data_weighted_by_floor_area'},
    {key: floorAreaType, unit: getRequiredUnit(UNIT_TYPE_FOR_EXPORT.AREA, null, null)},
    {key: 't_floor_area_for_no_data', unit: getRequiredUnit(UNIT_TYPE_FOR_EXPORT.AREA, null, null)},
    {key: 't_floor_area_not_covered_percentage'}, {key: 't_actual_percentage'},
    {key: 't_supplier_estimated_percentage'}, { key: 't_incomplete_percentage'},
    {key: 't_total_missing_percentage'}
  ]
  let tableHeadersWithLocalizedValue = getLocalizedColumnNamesWithUnits(tableHeadersToBeLocalized, intl)
  return {
    assetTableHeaderNames: [getTitleObject(tableTitle, selectedLanguage), convertToExcelExportFormat(tableHeadersWithLocalizedValue)],
    assetTableColumnLength: tableHeadersWithLocalizedValue.length
  }
}
const getSummaryTableRowData = (summaryData, selectedLanguage, isFundSummaryTable) => {
  let summaryTableRowDataArray = []
  if (summaryData)
    summaryData.forEach(summary => {
      summaryTableRowDataArray.push(
        convertToExcelExportFormat([
          ...(isFundSummaryTable ? [getValidData(summary.utility, selectedLanguage)] : []),
          getValidData(summary.notCoveredPercentage, selectedLanguage,  true),
          getValidData(summary.actualPercentage, selectedLanguage,  true),
          getValidData(summary.estimatedPercentage, selectedLanguage,  true),
          getValidData(summary.incompletePercentage, selectedLanguage,  true),
          getValidData(summary.totalMissingPercentage, selectedLanguage,  true)])
      )
    })
  return [...summaryTableRowDataArray, ...getEmptyRows(3)]
}
const getAssetTableRowData = (assetData, selectedLanguage) => {
  let requiredAssetTableRowDataArray = []
  assetData?.map(asset => requiredAssetTableRowDataArray.push(convertToExcelExportFormat([
    asset.assetRef,
    getValidData(asset.assetName, selectedLanguage),
    getValidData(asset.fundName, selectedLanguage),
    getValidData(asset.assetCountry, selectedLanguage),
    getValidData(asset.assetSector, selectedLanguage),
    getValidData(asset.assetAgent, selectedLanguage),
    formatDateToDDMMYY(asset.assetPurchaseDate, selectedLanguage),
    formatDateToDDMMYY(asset.assetSaleDate, selectedLanguage),
    getValidData(asset.portionOfMissingData, selectedLanguage, true),
    getValidData(asset.floorArea, selectedLanguage),
    getValidData(asset.floorAreaNoData, selectedLanguage),
    getValidData(asset.notCovered, selectedLanguage, true),
    getValidData(asset.actual, selectedLanguage, true),
    getValidData(asset.estimated, selectedLanguage, true),
    getValidData(asset.inComplete, selectedLanguage, true),
    getValidData(asset.totalMissing, selectedLanguage)
  ]
  )))
  return requiredAssetTableRowDataArray
}

export function getDQItemStatus(status) {
  if (!status) return '-'
  return status === 'Missing' ? 'Incomplete' : status
}

export const prePopulatedSheet = (tenantLandlordArray, sheetName, workbook, sheet, keys, selectedLanguage,exportFrom) => {
  const excelSheet = sheet === 0 ? workbook.sheet(0).name(getLocalizedValue(selectedLanguage, sheetName)) : workbook.addSheet(getLocalizedValue(selectedLanguage, sheetName)) 
  tenantLandlordArray.forEach((value, row) => {
    const styleObject = { horizontalAlignment: 'center' }
    if (row === 0) {
      const headerRow = excelSheet.row(1)
      keys.forEach((key, index) => {
        const cell = headerRow.cell(index + 1)
        excelSheet.column(cell.columnNumber()).width(20)
        headerRow.cell(index + 1).value(key).style(styleObject)
      })
    }
    if (tenantLandlordArray.length === 1 && value.Status && exportFrom !== DATA_FROM.ASSET.ASSET_ACTION_PLAN) {
      excelSheet.range('A2:J2').value(value.Status).merged(true).style(styleObject)
    } else {
      keys.forEach((key, column) => {
        const cell = excelSheet.cell(row + 2, column + 1)
        excelSheet.column(cell.columnNumber()).width(20)
        excelSheet.cell(row + 2, column + 1).value(value[key]).style(styleObject)
      })
    }
    exportFrom === DATA_FROM.ASSET.ASSET_ACTION_PLAN && excelSheet.range('A1:Z1').style({bold:true, fontSize:12})
  })
}