import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Timeline as PrimeTimeline } from 'primereact/timeline'
import { InputNumber } from 'primereact/inputnumber'
import './Timeline.scss'
import { Dropdown } from 'primereact/dropdown'
import { v4 as uuidv4 } from 'uuid'
import { useIntl } from 'react-intl'
import LinkButton from '../Link/Link'
import Text from '../Text/Text'
import Tooltip from '../Tooltip/Tooltip'
import Icon from '../Icon/Icon'
import Button from '../Button/Button'
import Calendar from '../Calendar/Calendar'

const dateFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
}

const Timeline = ({
  values,
  initialValues,
  setters,
  valueDropdownOptions,
  valueLabelKey,
  isValueInput,
  isPercentageInput,
  editModeRecordIds,
  valueErrors,
  dateErrors,
  addNewRecordKey,
  addOldRecordKey,
  startDateLabelKey,
  hideAddOldRecord,
  hideCurrentPrefix,
  hideEndDate,
  testId,
}) => {
  const [hoveredRecord, setHoveredRecord] = useState(null)
  const sortedData = values.filter(record => !record.isDeleted).sort(
    (a, b) => new Date(b.startDate) - new Date(a.startDate)
  )
  const intl = useIntl()
  const disabledDates = hideEndDate
    ? sortedData
      .filter((r) => !r.endDate && r.startDate)
      .map((r) => new Date(r.startDate))
    : []

  useEffect(() => {
    if (sortedData.length === 0) {
      addRecordAbove()
    }
  }, [sortedData.length])

  useEffect(() => {
    if(!hideEndDate) {
    // End date will not be taken from the api, so at first render we need to set the endDate and show it just on frontend
      if (values.length > 0 && !values.some(record => record.hasOwnProperty('endDate'))) {
        setInitialEndDates()
      }
    }
  }, [])

  const setInitialEndDates = () => {
    const updatedValues = [...values].sort(
      (a, b) => new Date(b.startDate) - new Date(a.startDate)
    ).map((record, index) => {
      if (index === 0) {
        return {
          ...record,
          endDate: null,
        }
      }
      const endDate = new Date(new Date(sortedData[index - 1]?.startDate).getTime() - 86400000)
      return {
        ...record,
        endDate: endDate
      }
    })
    setters((prevValues) => ({
      ...prevValues,
      values: updatedValues,
    }))
  }

  const toggleEditMode = (id) => {
    setters((prevValues) => ({
      ...prevValues,
      editModeRecordIds: prevValues.editModeRecordIds.includes(id)  // TODO: rename to id to distinguish uid vs recordid
        ? prevValues.editModeRecordIds.filter((prevValueId) => prevValueId !== id)
        : [...prevValues.editModeRecordIds, id],
    }))
  }

  const validateRecord = (record) => {
    let hasRecordValue = false
    if (record?.value !== null && typeof record.value !== 'undefined') {
      hasRecordValue = typeof record.value === 'boolean' || typeof record.value === 'number' || (typeof record.value === 'string' && record.value.trim() !== '')
    }
    if (!record?.startDate && !hasRecordValue) {
      if (
        !valueErrors.includes(record.id) &&
        !dateErrors.includes(record.id)
      ) {
        setters((prevValues) => ({
          ...prevValues,
          dateErrors: [...prevValues.dateErrors, record.id],
          valueErrors: [...prevValues.valueErrors, record.id],
        }))
      }
      return false
    }

    if (!record?.startDate) {
      if (!dateErrors.includes(record.id)) {
        setters((prevValues) => ({
          ...prevValues,
          dateErrors: [...prevValues.dateErrors, record.id],
        }))
      }
      return false
    }

    if (!hasRecordValue) {
      if (!valueErrors.includes(record.id)) {
        setters((prevValues) => ({
          ...prevValues,
          valueErrors: [...prevValues.valueErrors, record.id],
        }))
      }
      return false
    }

    return true
  }

  const addRecordAbove = () => {
    if (sortedData.length === 0) {
      const newRecord = {
        id: uuidv4(),
        recordId: null,
        value: '',
        startDate: '',
        ...(!hideEndDate && { endDate: null }),
        ...(isValueInput &&
          valueDropdownOptions && {
          valueOption: valueDropdownOptions[0].value,
        }),
      }
      
      setters((prevValues) => {
        return {
          ...prevValues,
          values: values.length > 0 ? [...prevValues.values, newRecord] : [newRecord],
        }
      })
      toggleEditMode(newRecord.id)
    } else {
      const firstRecord = sortedData[0]
      if (!validateRecord(firstRecord)) {
        return
      }
      const newRecord = {
        id: uuidv4(),
        recordId: null,
        value: '',
        startDate: '',
        ...(!hideEndDate && { endDate: null }),
        ...(isValueInput &&
          valueDropdownOptions && {
          valueOption: valueDropdownOptions[0].value,
        }),
      }
      setters((prevValues) => ({
        ...prevValues,
        values: [newRecord, ...prevValues.values],
      }))
      toggleEditMode(newRecord.id)
    }
  }

  const addRecordBelow = () => {
    const lastRecord = sortedData[sortedData.length - 1]
    if (!validateRecord(lastRecord)) {
      return
    }
    let defaultEndDate = null
    if (!hideEndDate) {
      defaultEndDate = lastRecord
        ? new Date(new Date(lastRecord.startDate).getTime() - 86400000)
        : new Date()
    }
    const newRecord = {
      id: uuidv4(),
      recordId: null,
      value: '',
      startDate: '',
      ...(!hideEndDate && { endDate: defaultEndDate }),
      ...(isValueInput &&
        valueDropdownOptions && {
        valueOption: valueDropdownOptions[0].value,
      }),
    }
    setters((prevValues) => ({
      ...prevValues,
      values: [...prevValues.values, newRecord],
    }))
    toggleEditMode(newRecord.id)
  }

  const saveValue = (id, newValue) => {
    if ((newValue === null || newValue === undefined || newValue === '') && !isValueInput) {
      setters((prevValues) => ({
        ...prevValues,
        valueErrors: [...prevValues.valueErrors, id],
      }))
      return
    }
    setters((prevValues) => ({
      ...prevValues,
      values: prevValues.values.map((record) =>
        record.id === id ? { ...record, value: newValue } : record
      ),
      valueErrors: prevValues.valueErrors.filter((prevValueId) => prevValueId !== id),
    }))
  }

  const saveValueOption = (id, newValueOption) => {
    setters((prevValues) => ({
      ...prevValues,
      values: prevValues.values.map((record) =>
        record.id === id
          ? { ...record, valueOption: newValueOption }
          : record
      ),
    }))
  }

  const saveDate = (id, newDate) => {
    const isFirstRecord =
      sortedData.length > 0 && sortedData[0].id === id
    if (newDate.startDate && isFirstRecord && !hideEndDate) {
      if (sortedData.length > 1) {
        const endDate = new Date(newDate.startDate)
        endDate.setDate(endDate.getDate() - 1)
        setters((prevValues) => ({
          ...prevValues,
          values: prevValues.values.map((record, index) =>
            index === 1
              ? {
                ...record,
                endDate: endDate
              }
              : record
          ),
        }))
      }
    }
    setters((prevValues) => ({
      ...prevValues,
      values: prevValues.values.map((record) =>
        record.id === id ? { ...record, ...newDate } : record
      ),
      dateErrors: prevValues.dateErrors.filter((prevValueId) => prevValueId !== id),
    }))
  }

  const setNewEndDate = (prevValues, deletedIndex, sortedData) => {
    if (deletedIndex !== sortedData.length - 1 && deletedIndex > 0) {
      let nextRecordIndex = deletedIndex - 1
      let previousRecordIndex = deletedIndex + 1

      while (prevValues.values[previousRecordIndex]?.isDeleted && previousRecordIndex < prevValues.values.length - 1) {
        previousRecordIndex++
      }
      while (prevValues.values[nextRecordIndex]?.isDeleted && nextRecordIndex > 0) {
        nextRecordIndex--
      }

      const nextRecord = prevValues.values[nextRecordIndex]
      const previousRecord = prevValues.values[previousRecordIndex]

      if (nextRecord && previousRecord && nextRecord.startDate) {
        const newEndDate = new Date(nextRecord.startDate).getTime() - 86400000
        previousRecord.endDate = newEndDate > 0 ? new Date(newEndDate) : null
      }
    } 
  }

  const deleteRecord = (id) => {
    setters((prevValues) => {
      const deletedIndex = prevValues.values.findIndex(
        (record) => record.id === id
      )

      // Check if the deleted record was not the last one and update the previous record's endDate with one day before the next record's startDate
      if (!hideEndDate) {
        setNewEndDate(prevValues, deletedIndex, sortedData)
      }
     
      if (sortedData[0]?.id === id && sortedData.length > 1 && !hideEndDate) {
        prevValues.values[deletedIndex + 1].endDate = null
      }
      const isInitialValue = initialValues?.some(
        (value) => value.id === id
      )
      const updatedValues = prevValues.values.map((record) =>
        record.id === id ? { ...record, isDeleted: true } : record
      )
      const filteredValues = isInitialValue
        ? updatedValues
        : updatedValues.filter(record => record.id !== id)

      return {
        ...prevValues,
        values: filteredValues,
        editModeRecordIds: prevValues.editModeRecordIds.filter((prevValueId) => prevValueId !== id),
        dateErrors: prevValues.dateErrors.filter((prevValueId) => prevValueId !== id),
        valueErrors: prevValues.valueErrors.filter((prevValueId) => prevValueId !== id),
      }
    })
  }

  const isInEditMode = (id) => {
    return editModeRecordIds.includes(id)
  }

  const isFirstRecord = (record) => {
    if (hideEndDate) {
      return sortedData.length === 1 || sortedData[0].id === record.id
    } else {
      return sortedData.length === 1 || (sortedData[0].id === record.id && !record.endDate)
    }
  }

  const renderValueInput = (record, index) => {
    if (valueDropdownOptions && valueDropdownOptions.length > 0) {
      return (
        <div
          className={'timeline-content__edit--status--multi p-inputgroup'}
          data-testid={`timeline-multi-input-dropdown-container-${index}`}
        >
          <InputNumber
            id="value"
            value={record.value}
            onValueChange={(e) => saveValue(record.id, e.target.value)}
            className={valueErrors.includes(record.id) ? ' p-invalid' : ''}
            data-testid={`timeline-multi-status-input-${index}`}
            min={0}
          />
          <div className="timeline-content__edit--status--multi--border" />
          <Dropdown
            value={record.valueOption}
            onChange={(e) => saveValueOption(record.id, e.value)}
            options={valueDropdownOptions}
            optionLabel="name"
            className={valueErrors.includes(record.id) ? 'p-invalid' : ''}
            panelClassName="timeline-content__edit--status--dropdown-panel"
            data-testid={`timeline-multi-status-dropdown-${index}`}
          />
        </div>
      )
    } else {
      return (
        <InputNumber
          value={record.value}
          onValueChange={(e) => saveValue(record.id, e.target.value)}
          className={
            valueErrors.includes(record.id)
              ? ' timeline-content__edit--status--single p-invalid'
              : 'timeline-content__edit--status--single'
          }
          suffix={isPercentageInput ? '%' : null}
          min={0}
          max={isPercentageInput ? 100 : null}
          data-testid={`timeline-single-number-input-${index}`}
        />
      )
    }
  }

  const editModeContent = (record, index) => {
    return (
      <div
        className="timeline-content__edit"
        data-testid={`timeline-edit-container-${index}`}
      >
        <div className="timeline-content__edit--status">
          <label htmlFor="value" className="mb-1 timeline-content-status">
            {isFirstRecord(record) && !hideCurrentPrefix
              ? `${intl.formatMessage({
                id: 't_current',
              })} ${intl.formatMessage({ id: valueLabelKey })}`
              : intl.formatMessage({ id: valueLabelKey })}
          </label>
          {isValueInput ? (
            renderValueInput(record, index)
          ) : (
            <Dropdown
              id="value"
              value={record.value}
              onChange={(e) => saveValue(record.id, e.value)}
              options={valueDropdownOptions}
              optionLabel="name"
              placeholder={intl.formatMessage({ id: 't_select_a_status' })}
              className={valueErrors.includes(record.id) ? 'p-invalid' : ''}
              panelClassName="timeline-content__edit--status--dropdown-panel"
              data-testid={`timeline-status-dropdown-${index}`}
            />
          )}
          {valueErrors.includes(record.id) && (
            <Text
              content="t_cannot_be_empty"
              colour="red"
              className="timeline-error-msg"
            />
          )}
        </div>
        <div className="timeline-content__edit--date">
          <div className="flex flex-column w-full">
            <label htmlFor="startDate" className="mb-1">
              <Text content={startDateLabelKey ?? 't_start_date'} testId={`timeline-start-date-label-${index}`}/>
            </label>
            <Calendar
              id="startDate"
              value={record.startDate ? new Date(record.startDate) : null}
              onChange={(e) => {
                saveDate(record.id, {
                  startDate: e.value
                })
              }}
              className={dateErrors.includes(record.id) ? 'p-error-border' : ''}
              maxDate={record.endDate && !hideEndDate ? new Date(record.endDate) : new Date()}
              minDate={
                sortedData[0].id === record.id && sortedData.length > 1 && !hideEndDate
                  ? new Date(
                    new Date(sortedData[1].startDate).getTime() + 86400000
                  ) // Adding one day in milliseconds
                  : null
              }
              disabledDates={disabledDates}
              testId={`timeline-start-date-calendar-${index}`}
            />
            {dateErrors.includes(record.id) && (
              <Text
                content="t_select_date_error"
                colour="red"
                className="timeline-error-msg"
              />
            )}
          </div>
          {!isFirstRecord(record) && !hideEndDate && (
            <div className="flex flex-column w-full">
              <label htmlFor="endDate" className="mb-1 flex">
                <Text content="t_end_date" />
                <Icon
                  id="info_solid"
                  size={15}
                  className="record-end-date-info ml-1"
                  testId={`timeline-end-date-info-icon-${index}`}
                />
                <Tooltip target=".record-end-date-info" width={230} testId={`timeline-end-date-info-text-${index}`}>
                  <Text content="t_date_set_automatically" />
                </Tooltip>
              </label>
              <Calendar
                id="endDate"
                value={record.endDate ? new Date(record.endDate) : null}
                testId={`timeline-end-date-calendar-${index}`}
                disabled
              />
            </div>
          )}
        </div>
        <LinkButton
          content="t_remove_item"
          onClick={() => deleteRecord(record.id)}
          className="align-self-start"
          testId={`timeline-remove-item-${index}`}
          type='button'
        />
      </div>
    )
  }

  const currentRecordContent = (record) => {
    return (
      <>
        {intl.formatMessage({ id: 't_current' })} {intl.formatMessage({id: valueLabelKey,})}: {getRecordValueText(record)}
      </>
    )
  }

  const getRecordValueText = (record) => {
    if (isValueInput && record) {
      if (
        record.valueOption &&
        ['£', '€', '$'].includes(record.valueOption)
      ) {
        return `${record.valueOption}${record.value.toLocaleString()}`
      } else if (isPercentageInput) {
        return `${record.value}%`
      }
      return record.value !== ''
        ? record.value
        : record.value.toLocaleString()
    }

    if(valueDropdownOptions && valueDropdownOptions.length > 0) {
      return valueDropdownOptions.find(option => option.value === record.value)?.name
    }

    return record.value
  }

  const customizedContent = (record, index) => {
    return (
      <button
        onMouseEnter={() => {
          if (!isInEditMode(record.id)) {
            setHoveredRecord(record)
          }
        }}
        onMouseLeave={() => {
          if (!isInEditMode(record.id)) {
            setHoveredRecord(null)
          }
        }}
        className="timeline-content"
        type="button"
        data-testid={`timeline-record-${index}`}
      >
        {isInEditMode(record.id) ? (
          editModeContent(record, index)
        ) : (
          <div
            className="timeline-content__view"
            data-testid={`timeline-view-container-${index}`}
          >
            <div className="flex flex-column gap-2 align-items-start">
              <span className="timeline-content-status" data-testid={`timeline-status-label-view-${index}`}>
                {isFirstRecord(record) && !hideCurrentPrefix
                  ? currentRecordContent(record)
                  : getRecordValueText(record)}
              </span>
              <div>
                <Text colour="faded_teal" content={new Date(record.startDate).toLocaleDateString('en-GB', dateFormatOptions)} />
                {!hideEndDate && (
                  <>
                    <span className="timeline-date-separator"> - </span>
                    <Text
                      colour="faded_teal"
                      content={record.endDate ? new Date(record.endDate).toLocaleDateString('en-GB', dateFormatOptions) : 't_present'}
                      testId={`timeline-end-date-text-${index}`}
                    />
                  </>
                )}
              </div>
            </div>
            {hoveredRecord === record && (
              <div className="flex align-items-center">
                <Button
                  icon={<Icon id="pencil" />}
                  onClick={() => toggleEditMode(record.id)}
                  type="button"
                  testId={`timeline-edit-button-${index}`}
                />
                <Button
                  icon={<Icon id="trash_can" />}
                  onClick={() => deleteRecord(record.id)}
                  type="button"
                  testId={`timeline-delete-button-${index}`}
                />
              </div>
            )}
          </div>
        )}
      </button>
    )
  }

  const customizedMarker = (record, index) => {
    const isEditMode = isInEditMode(record.id)
    return isEditMode ? (
      <Icon id="circle_outline" size={10} colour="white" testId={`outline-timeline-marker-${index}`}/>
    ) : (
      <Icon id="circle_solid" size={10} testId={`filled-timeline-marker-${index}`}/>
    )
  }

  return (
    <div className="siera-timeline-wrapper" data-testid={testId}>
      <Button
        className="timeline-btn"
        onClick={addRecordAbove}
        content={addNewRecordKey ?? 't_add_new_record'}
        outlined
        size="small"
        type="button"
        testId="timeline-add-new-record"
      />
      <PrimeTimeline
        value={sortedData}
        marker={customizedMarker}
        content={customizedContent}
        dataKey="id"
        className="siera-timeline"
      />
      {!hideAddOldRecord && (
        <Button
          className="timeline-btn"
          onClick={addRecordBelow}
          content={addOldRecordKey ?? 't_add_old_record'}
          outlined
          size="small"
          type="button"
          testId="timeline-add-old-record"
        />
      )}
    </div>
  )
}

Timeline.propTypes = {
  /**
   * Array of objects which represents timeline records.
   */
  values: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        startDate: PropTypes.string,
        endDate: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.oneOf([null]),
        ]),
        /**
         * Value coming with an existing record, this will be initally null
         */
        recordId: PropTypes.number,
        /**
         * Unique element id using uuid
         */
        id: PropTypes.string,
        valueOption: PropTypes.string,
      })
    ),
    PropTypes.array,
  ]).isRequired,
  /**
   * Array of objects which represents initial timeline records.
   * The array passed should match the values prop and it is used mainly for deletion logic.
   */
  initialValues: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        startDate: PropTypes.string,
        endDate: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.oneOf([null]),
        ]),
        /**
         * Value coming with an existing record, this will be initally null
         */
        recordId: PropTypes.number,
        /**
         * Unique element id using uuid
         */
        id: PropTypes.string,
        valueOption: PropTypes.string,
      })
    ),
    PropTypes.array,
  ]).isRequired,
  /**
   * State setter function to set the values, errors and records that are in edit mode.
   * The updating logic will be performed in the Timeline component.
   */
  setters: PropTypes.func.isRequired,
  /**
   * Dropdown options for value.
   * Serves as a dropdown for the number input if isValueInput is true or as a whole dropdown otherwise.
   */
  valueDropdownOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
    })
  ),
  /**
   * Key for the value label that will be translated internally.
   */
  valueLabelKey: PropTypes.string,
  /**
   * Key for the start date label that will be translated internally.
   */
  startDateLabelKey: PropTypes.string,
  /**
   * Boolean indicating if value is input.
   */
  isValueInput: PropTypes.bool,
  /**
   * Boolean indicating if input holds a percentage value.
   */
  isPercentageInput: PropTypes.bool,
  /**
   * Array of record ids that are in edit mode.
   */
  editModeRecordIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  /**
   * Array of record ids that have date errors.
   */
  dateErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
  /**
   * Array of record ids that have value errors
   */
  valueErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
  /**
   * Key string for the add new record button
   */
  addNewRecordKey: PropTypes.string,
  /**
   * Key string for the add new record button
   */
  addOldRecordKey: PropTypes.string,
  /**
   * Boolean indicating if the add old record button should be hidden.
   */
  hideAddOldRecord: PropTypes.bool,
  /**
   * Boolean indicating if the 'Current' prefix from the status label of the first record should be hidden.
   */
  hideCurrentPrefix: PropTypes.bool,
  /**
   * Boolean indicating if the end date input and label text should be hidden.
   */
  hideEndDate: PropTypes.bool,
  /**
   * Test id for the component
   */
  testId: PropTypes.string,
}

Timeline.defaultProps = {
  isValueInput: false,
  isPercentageInput: false,
  hideAddOldRecord: false,
  hideCurrentPrefix: false,
  hideEndDate: false,
}

export default Timeline