import React from 'react'
import useFundUrlValidator from '../../../utils/custom-hooks/UseFundUrlValidator'
import {Redirect, Route} from 'react-router-dom'
import { useLoginAuthContext } from '../../pages/login/auth0/UserInfoProvider'
import NzcFeatureRoute from '../feature-flags/NzcFeatureRoute'
import { COMMON_PAGES } from '../../../utils/helpers/Constants'
import { prefixSlash } from '../../../utils/helpers/Helper'

const getNzcRouteComponent = (props, Component, flag, rest, urlValidated, isUrlValid) => {
  let componentToRender = <NzcFeatureRoute component={Component} flag={flag} {...rest} {...props}  />
  if (urlValidated) {
    if (!isUrlValid) {
      componentToRender = <Redirect to={prefixSlash(COMMON_PAGES.notfound)}/>
    }
  }
  return componentToRender
}

const NzcSingleFundPageRoute = ({ component: Component, flag, ...rest }) => {
  const {loginState} = useLoginAuthContext()
  const matchRoute = rest.computedMatch
  const urlParamsRequest = {
    matchRoute,
    instanceName: matchRoute.params?.instanceName,
    orgName: matchRoute.params?.orgName,
    fundName: matchRoute.params?.fundName
  }

  if (!loginState.isLoggedIn) {
    return <Redirect to={{pathname: '/login', state: {referrer: `${window.location.pathname}${window.location.search}`}}} />
  } else {
    const {urlValidated, isUrlValid}=useFundUrlValidator(urlParamsRequest)
    return <Route render={props=>getNzcRouteComponent(props, Component, flag, rest, urlValidated, isUrlValid)} {...rest} />
  }
}

export default NzcSingleFundPageRoute