import {Text, View} from '@react-pdf/renderer'
import React, {useState} from 'react'
import styles from '../../style-sheet/Styles'
import {formatNumWODecimal, trucateNumWODecimal} from '../../../../services/common/formatter'
import I18n from '../../../i18n/I18n'
import {energyRating, energyScore, unitsConverter} from '../../../helpers/Helper'
import { AREA, checkCountries } from '../../../helpers/Constants'
import PropTypes from 'prop-types'

const PdfHeader = (props) => {
  const [asset] = useState(props.asset)
  const unitSystem = props.unitSystem
  const assetCountry = checkCountries.includes(asset.assetCountry)

  const formatUnits = (value) => {
    if (value != null) {
      return formatNumWODecimal(props.language, value)
    }
    return value
  }

  return (
    <View style={styles.header} fixed>
      {asset ?
        <View style={[styles.assets, styles.borderExceptBottom, styles.tableRow,styles.headerOuterView]} fixed>
          <View style={[styles.headerAssetAddress,{}]}>
            <Text style={[styles.textSevenHalfBold, styles.ellipsisMax2]}>
              {Array.from(asset.assetAddress)}
            </Text>
            <Text style={[styles.textSevenHalfRegular, styles.ellipsisMax2, styles.top3]}>
              {Array.from(asset.fundName)}
            </Text>
          </View>

          <View style={[styles.headerOuterViewShort,{}]}>
            <View style={[styles.tableRow]}>
              <View style={[styles.assetTextSix, styles.greyDarkColor, styles.hADetails]}>
                <Text style={styles.mTop3}>{I18n('t_floor_area')}</Text>
              </View>

              <View style={[styles.assetTextSix, styles.blackColor,styles.headerAsset]}>
                <Text style={[styles.ellipsisMax1, styles.textAlignRight,styles.mTop3]}>
                  {Number(asset.gia) > 0 ?`${formatNumWODecimal(props.language, trucateNumWODecimal(asset.gia))} ${unitsConverter(AREA,unitSystem)}` : '-'}
                </Text>
              </View>
            </View>

            <View style={[styles.tableRow,styles.mTop2]}>
              <View style={[styles.assetTextSix, styles.greyDarkColor, styles.hADetails]}>
                <Text>{assetCountry ? I18n('t_energy_star_score') : I18n('t_energy_rating')}</Text>
              </View>
              <View style={[styles.assetTextSix, styles.blackColor,styles.headerAsset]}>
                <Text style={[styles.ellipsisMax2, styles.textAlignRight]}>
                  {assetCountry ? energyScore(asset) : energyRating(asset)}
                </Text>
              </View>
            </View>

            <View style={[styles.tableRow,styles.mTop2]}>
              <View style={[styles.assetTextSix, styles.greyDarkColor, styles.hADetails]}>
                <Text>{I18n('t_occupancy')}</Text>
              </View>
              <View style={[styles.assetTextSix, styles.blackColor,styles.headerAsset]}>
                <Text style={[styles.ellipsisMax2, styles.textAlignRight]}>
                  {asset.occupancy ? formatUnits(asset.occupancy) + '%' : '-'}
                </Text>
              </View>
            </View>
          </View>
        </View>
        : null
      }
    </View>
  )
}

PdfHeader.propTypes = {
  unitSystem: PropTypes.string
}
PdfHeader.displayName = 'PdfHeader'

export default PdfHeader