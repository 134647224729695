import React, { useState } from 'react'
import './NzcModel.scss'
import {useFormik} from 'formik'
import {Button} from 'primereact/button'
import I18n from '../../../../../utils/i18n/I18n'
import {CreateNZCModel, createAssetNZCModel} from '../../../../../services/funds/nzc-service'
import {useMutation} from 'react-query'
import CommonCreateModel from './CommonCreateModel'
import { Checkbox } from 'primereact/checkbox'
import {hasModelNameValidationError, nzcModelNameValidator} from '../../../../common/net-zero-carbon/nzc-common-utils'

const NzcCreateAModel = (props) => {
  const [checkboxValue, setCheckboxValue] = useState(false)
  const errorMessage =  'ModelName exists'
  const createModel = useMutation(props.assetLevel ? createAssetNZCModel : CreateNZCModel, {
    onSuccess:(newScopeId)=>{
      //TODO
      props.createAModelSuccessCallback(newScopeId, checkboxValue)
    }
  })

  const onCheckboxClicked = (e) => {
    setCheckboxValue(e.checked)
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      description : ''
    },
    validate: nzcModelNameValidator,
    onSubmit: async (data) => {
      let modelInfo = {
        fundGroupId: props.assetLevel ? 0 : props.fundGroupId,
        name: data.name,
        description: data.description,
        isDefault: checkboxValue,
        assetId: props.assetLevel ? Number(props.assetId) : 0
      }
      await createModel.mutateAsync(modelInfo)
      formik.resetForm()
      props.onHideModel()
    }
  })

  const resetError = () => {
    createModel.reset()
  }

  return (
    <div className={'nzc-model-content'}>
      <div className={!props.assetLevel ? 'nzc-model-content__nzc-create-model' : 'nzc-model-content__nzc-asset-create-model'}>
        <CommonCreateModel
          duplicateModelNameErrorFlag={createModel?.error?.response?.data === errorMessage}
          formik={formik}
          resetError={resetError}
          explanationTitle={props.assetLevel ? I18n('t_asset_level_model_message') : I18n('t_create_new_model_para')}
        />
      </div>
      <div className={'nzc-model-content__create-model-checkbox'}>
        <Checkbox inputId="binary"
          onChange={onCheckboxClicked}
          checked ={checkboxValue}
          data-testid="create-model-checkbox"
        />
        <label htmlFor="binary">{I18n('t_set_as_default')}</label>
        <div className='nzc-model-content__create-model-checkbox--nzc-checkbox-recommendation' data-testid="default-info"><p>{props.assetLevel ? I18n('t_set_as_default_asset_info') : I18n('t_set_as_default_info')}</p>
        </div>
      </div>
      <div className={'border-line'} />
      <div className='padding-bottom-3'>
        <Button label={I18n('t_create')} data-testid="submit-button" onClick={formik.handleSubmit} className="common-blue-bg-btn open-button button-highlight" disabled={hasModelNameValidationError(formik)} />
      </div>
    </div>
  )
}

export default NzcCreateAModel