import React from 'react'
import {Skeleton} from 'primereact/skeleton'
import './NotificationsGridSkeleton.scss'

const NotificationsGridSkeleton = () => {
  return (
    <div className="custom-skeleton p-rel">
      <div className="flex p-abs contour">
        <Skeleton shape="square" size="2rem"/>
        <Skeleton width="15%"/>
        <Skeleton shape="square" size="2rem"/>
        <Skeleton width="25%"/>
        <Skeleton width="10%"/>
        <Skeleton width="10%"/>
        <Skeleton shape="square" size="2rem"/>
      </div>
    </div>
  )
}

export default NotificationsGridSkeleton