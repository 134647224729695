import {useContext, useReducer, useRef, useState} from 'react'
import I18n from '../../../../utils/i18n/I18n'
import {Column} from 'primereact/column'
import {DataTable} from 'primereact/datatable'
import { TabView, TabPanel } from 'primereact/tabview'
import './DocumentsHome.scss'
import {DocumentsHeader} from '../documents-header/DocumentsHeader'
import {DocumentsFooter} from '../documents-footer/DocumentsFooter'
import {emptyDataMessage, noData} from '../../../../utils/helpers/MessageUtility'
import DocumentsSkeleton from '../../../common/skeletons/documents-skeleton/DocumentsSkeleton'
import {
  utilDateTemplate,
  utilFileListTemplate,
  utilTextLinkTemplate,
  utilTextTemplate,
  utilTextTemplateWithTooltip
} from '../../../../utils/helpers/DataTableUtils'
import {
  createNavURLForUserPages,
  getLoggedInUserRole,
  isLoggedInUserAdmin, truncateDecimal
} from '../../../../utils/helpers/Helper'
import {ADMIN_PAGES} from '../../../../utils/helpers/Constants'
import {DocumentsFilter} from '../documents-filter/DocumentsFilter'
import {currentPageReport} from '../../../common/action-plan/actionPlanTableTemplate'
import {bytesToGB, documentsReducer, fileSizeToBytes, setInitialDocumentsState} from '../DocumentsUtil'
import useDocumentsData from '../documents-hooks-and-helpers/useDocumentsData'
import {Icon, Text} from '../../../atomic'
import {DOCUMENTS_STORAGE_CAPACITY} from '../../../../utils/config/Config'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'
import {FeatureFlagContext} from '../../../../Contexts'
import {useMutation} from 'react-query'
import {getFilterOptions} from '../../../../services/documents/documents-service'

const DocumentsHome = () => {
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const unitSystem = userInfo.unitSystem
  const [tabIndex, setTabIndex] = useState(0)
  const [documentsFilterState, documentsFilterDispatch] = useReducer(documentsReducer, setInitialDocumentsState(tabIndex))
  const [consumptionEvidenceFilterState, consumptionEvidenceFilterDispatch] = useReducer(documentsReducer, setInitialDocumentsState(tabIndex))
  const numberOfDocumentFiltersApplied = useRef(Object.values(documentsFilterState.filterData).filter(filter => filter !== null).length)
  const numberOfConsumptionEvidenceFiltersApplied = useRef(Object.values(consumptionEvidenceFilterState.filterData).filter(filter => filter !== null).length)
  const [searchString, setSearchString] = useState({documentsCertsSearch:'',consumptionEvidenceSearch:''})
  const [selectedDocumentsCertificates, setSelectedDocumentsCertificates] = useState([])
  const [selectedConsumptionEvidenceDocuments, setSelectedConsumptionEvidenceDocuments] = useState([])
  const featureContext = useContext(FeatureFlagContext)
  const featureState = featureContext.featureState
  const {
    documentsDataLoading,
    documentsDataList,
    documentsDataRefetch,
    consumptionEvidenceDataLoading,
    consumptionEvidenceDataList,
    availableStorageCapacity,
    availableCapacityLoading
  } = useDocumentsData(tabIndex,searchString,tabIndex === 0 ? numberOfDocumentFiltersApplied : numberOfConsumptionEvidenceFiltersApplied)
  const documentNames = documentsDataList?.map(document => document.fileName) ?? []
  const documentsPaginator = <DocumentsFooter 
    showEdit={true} 
    showDownload 
    documentNames={documentNames} 
    showDelete={isLoggedInUserAdmin(getLoggedInUserRole())} 
    selectedRows={selectedDocumentsCertificates} 
    setSelectedRows={setSelectedDocumentsCertificates} 
    refetch={documentsDataRefetch}/>
  const consumptionPaginator = <DocumentsFooter showDownload selectedRows={selectedConsumptionEvidenceDocuments} tabIndex={tabIndex} />
  const documentsCertificatesTableClassname = documentsDataList?.length === 0 ? 'asset-action-plan-table__no-data-table' : 'asset-action-plan-table__data-table'
  const consumptionEvidenceTableClassname = consumptionEvidenceDataList?.length === 0 ? 'asset-action-plan-table__no-data-table' : 'asset-action-plan-table__data-table'
  const [isFilterSideBarVisible, setIsFilterSideBarVisible] = useState(false)
  const getStorageSpace = availableStorageCapacity?.toString().includes('capacity exceeded') ? '2 GB' : availableStorageCapacity
  const getStoragePercentage = truncateDecimal(bytesToGB(fileSizeToBytes(getStorageSpace) * 100 / DOCUMENTS_STORAGE_CAPACITY),1)
  const [filterData, setFilterData] = useState({})
  const documentsFilterData = useMutation(getFilterOptions)
  const getPaginatorTemplate = () => {
    return {
      layout: 'PrevPageLink CurrentPageReport NextPageLink',
      'CurrentPageReport': currentPageReport
    }
  }

  const onDocumentsSelectionChange = (evt) => {
    setSelectedDocumentsCertificates(evt.value)
  }

  const onConsumptionEvidenceSelectionChange = (evt) => {
    setSelectedConsumptionEvidenceDocuments(evt.value)
  }

  const uploadedByTemplate = (rowData, field) => {
    const navigationLink = isLoggedInUserAdmin(getLoggedInUserRole()) && rowData.userId ?
      createNavURLForUserPages({pathPrefix: ADMIN_PAGES.users, userId: rowData.userId})
      : null
    const isConsumptionEvidence = tabIndex ===1 ? rowData.fileDocument[0] : rowData
    return navigationLink
      ? utilTextLinkTemplate(isConsumptionEvidence, field, navigationLink)
      : utilTextTemplate(isConsumptionEvidence, field)
  }

  const onClickOfApplyButtonForFilter = (filterSelections, currentTabIndex) => {
    if (currentTabIndex === 0) {
      numberOfDocumentFiltersApplied.current = filterSelections
    } else {
      numberOfConsumptionEvidenceFiltersApplied.current = filterSelections
    }
    setIsFilterSideBarVisible(false)
  }

  const onFilterSelectionChange = (e, selectedFilterCategory, currentTabIndex) => {
    const stateArguments = {
      type: 'UPDATE_FILTER_SELECTIONS', data: filterData ,
      order: tabIndex === 0 ? documentsFilterState.filterData.orderSelection : consumptionEvidenceFilterState.filterData.orderSelection,
      filterData: {currentSelection: e.value, selectedFilterCategory, selectedLanguage, tabIndex}
    }

    currentTabIndex === 0 ? documentsFilterDispatch(stateArguments) : consumptionEvidenceFilterDispatch(stateArguments)
  }

  const onSelectionDone = async () => {
    await documentsFilterData.mutateAsync({
      tabIndex:tabIndex,
      filterData:tabIndex === 0 ? documentsFilterState : consumptionEvidenceFilterState
    }).then(data => {
      setFilterData(data)
    })
  }

  const onFilterSelectionsClear = () => {
    const stateArguments = {type: 'CLEAR_FILTER_SELECTIONS' , filterData: {tabIndex}}
    if (tabIndex === 0) {
      numberOfDocumentFiltersApplied.current = 0
      documentsFilterDispatch(stateArguments)
    } else {
      numberOfConsumptionEvidenceFiltersApplied.current = 0
      consumptionEvidenceFilterDispatch(stateArguments)
    }
  }

  const onHideOrCloseOfFilter = (defaultSelections, currentTabIndex) => {
    const stateArguments = {type: 'ON_HIDE_OR_CLOSE_OF_FILTER' , filterData: {defaultSelections}}

    currentTabIndex === 0
      ? documentsFilterDispatch(stateArguments)
      : consumptionEvidenceFilterDispatch(stateArguments)

    setIsFilterSideBarVisible(false)
  }

  const renderDocumentsFilter = () => {
    const selectedFilters = tabIndex === 0
      ? documentsFilterState.filterData
      : consumptionEvidenceFilterState.filterData
    return (
      <DocumentsFilter
        isLoading={documentsFilterData.isLoading}
        data={filterData}
        isFilterVisible={isFilterSideBarVisible}
        tabIndex={tabIndex}
        filterSelections={selectedFilters}
        onHideOrCloseOfFilter={onHideOrCloseOfFilter}
        handleFiltersApplyButtonClick={onClickOfApplyButtonForFilter}
        handleFilterSelectionChange={onFilterSelectionChange}
        onSelectionDone={onSelectionDone}
        handleFilterClearButtonClick={() => {onFilterSelectionsClear(); setIsFilterSideBarVisible(false)}}/>
    )
  }

  function getStorageCapacityText() {
    if(!availableCapacityLoading && fileSizeToBytes(getStorageSpace) !== '0'){
      const availableStorage = getStorageSpace || '0 KB'
      const usage = truncateDecimal(availableStorage,1)+availableStorage.split(/\s+/)[1]
      let color = 'forest'
      let icon = 'info_solid'
      if(getStoragePercentage >= 70 && getStoragePercentage < 95){
        icon = 'warning_solid_triangle'
        color = 'orange'
      }else if(getStoragePercentage >= 95){
        icon = 'warning_solid_circle'
        color='red'
      }
      return <div>
        <Icon id={icon} className={'vertical-align-middle mr-1'} colour={color} testId={'storage-info-icon'} size={17}/>
        <Text colour={'faded_teal'} content={`${usage} out of ${DOCUMENTS_STORAGE_CAPACITY}GB (${getStoragePercentage}%) storage capacity used`} testId={'storage-info-message'}/>
      </div>
    }
  }

  const showFilterSidebar=async () => {
    setIsFilterSideBarVisible(true)
    await documentsFilterData.mutateAsync({
      tabIndex:tabIndex,
      filterData:tabIndex === 0 ? documentsFilterState : consumptionEvidenceFilterState
    }).then((data) => {
      setFilterData(data)
    })
  }

  function isHeaderDisabled(path,isData){
    if(path === 'certs'){
      const appliedFilters = Object.values(numberOfDocumentFiltersApplied.current).filter(filter => filter !== null)?.length
      const isSelectionOrderEmpty = numberOfDocumentFiltersApplied.current.orderSelection === null
      const filterCount = isSelectionOrderEmpty ? appliedFilters : appliedFilters -1
      return searchString.documentsCertsSearch !== '' || filterCount !== -1 ?  false : isData
    }else{
      const appliedFilters = Object.values(numberOfConsumptionEvidenceFiltersApplied.current).filter(filter => filter !== null)?.length
      const isSelectionOrderEmpty = numberOfConsumptionEvidenceFiltersApplied.current.orderSelection === null
      const filterCount = isSelectionOrderEmpty ? appliedFilters : appliedFilters -1
      return searchString.consumptionEvidenceSearch !== '' || filterCount !== -1 ?  false : isData
    }
  }

  return (
    <div data-page_identifier="documents_home" className="min-width-1280" data-testid={'documents-home'}>
      <div className="display gutter">
        <div className="documents-wrapper grid">
          <div className="documents-header col-12 justify-content-between">
            <span>{I18n('t_documents')}</span>
            {getStorageCapacityText()}
          </div>
          <div className="col-12">
            <TabView data-testid={'documents-tab-container'}
              activeIndex={tabIndex} onTabChange={(e) => setTabIndex(e.index)}>
              <TabPanel header={I18n('t_documents_n_certificates')}>
                <>
                  <DocumentsHeader
                    isDataAvailable={isHeaderDisabled('certs',documentsDataList?.length === 0)}
                    title={I18n('t_documents_n_certificates')}
                    showAdd={true}
                    showFilterSidebar={showFilterSidebar}
                    addDisable = {getStoragePercentage <= 100}
                    setFilterSideBarVisibility={setIsFilterSideBarVisible}
                    numberOfFiltersApplied={numberOfDocumentFiltersApplied.current}
                    handleFilterClearButtonClick={onFilterSelectionsClear}
                    refetch={documentsDataRefetch}
                    searchString={searchString}
                    setSearchString={setSearchString}
                    documentNames={documentNames} 
                    setSelectedRows={setSelectedDocumentsCertificates}
                  />
                  {documentsDataLoading ? <DocumentsSkeleton/> :
                    <div data-testid={'documents-table-container'}>
                      <DataTable
                        value={documentsDataList}
                        selection={selectedDocumentsCertificates}
                        onSelectionChange={e => onDocumentsSelectionChange(e)}
                        className={'p-datatable-gridlines ' + documentsCertificatesTableClassname}
                        emptyMessage={numberOfDocumentFiltersApplied.current !==0 ? emptyDataMessage(): noData()}
                        paginator
                        rows={10}
                        totalRecords={documentsDataList?.length}
                        paginatorTemplate={getPaginatorTemplate()}
                        paginatorClassName={'data-table-paginator'}
                        paginatorLeft={documentsPaginator}
                        scrollable
                      >
                        <Column selectionMode="multiple" style={{width: '2%'}}/>
                        <Column field="fileName" header={I18n('t_filename')}
                          body={(rowData) => utilTextTemplate(rowData, 'fileName')} style={{minWidth: '18rem'}}/>
                        <Column field="documentType" header={I18n('t_document_type')}
                          body={(rowData) => utilTextTemplate(rowData, 'documentType')} style={{minWidth: '12rem'}}/>
                        <Column field="reportDate" header={I18n('t_implementation_date')}
                          body={(rowData) => utilDateTemplate(rowData, 'reportDate', selectedLanguage, unitSystem)} style={{minWidth: '10rem'}}/>
                        <Column field="dateOfUpload" header={I18n('t_date_of_upload')}
                          body={(rowData) => utilDateTemplate(rowData, 'dateOfUpload', selectedLanguage, unitSystem)} style={{minWidth: '10rem'}}/>
                        <Column field="uploadedBy" header={I18n('t_uploaded_by')}
                          body={(rowData) => uploadedByTemplate(rowData, 'userName')} style={{minWidth: '10rem'}}/>
                        <Column field="fileSize" header={I18n('t_document_size')}
                          body={(rowData) => utilTextTemplate(rowData, 'fileSize')} style={{minWidth: '10rem'}}/>
                        <Column field="fundName" header={I18n('t_fundname')}
                          body={(rowData) => utilTextTemplate(rowData, 'fundName')} style={{minWidth: '12rem'}}/>
                        <Column field="assetReference" header={I18n('t_asset_reference_tc')}
                          body={(rowData) => utilTextTemplate(rowData, 'assetReference')} style={{minWidth: '12rem'}}/>
                        <Column field="assetName" header={I18n('t_asset_name_tc')}
                          body={(rowData) => utilTextTemplate(rowData, 'assetName')} style={{minWidth: '12rem'}}/>
                        <Column field="comment" header={I18n('t_comment')}
                          body={(rowData) => utilTextTemplateWithTooltip(rowData, 'comment', 20, `comment-${rowData.rowIndex}`)}
                          style={{minWidth: '12rem'}}/>
                      </DataTable>
                    </div>
                  }
                </>

              </TabPanel>
              {featureState.sieraplus_consumptionOperations && <TabPanel header={I18n('t_consumption_evidence')}>
                <>
                  <DocumentsHeader
                    isDataAvailable={isHeaderDisabled('consumption',consumptionEvidenceDataList?.length === 0)}
                    title={I18n('t_consumption_evidence')}
                    showFilterSidebar={showFilterSidebar}
                    setFilterSideBarVisibility={setIsFilterSideBarVisible}
                    numberOfFiltersApplied={numberOfConsumptionEvidenceFiltersApplied.current}
                    handleFilterClearButtonClick={onFilterSelectionsClear}
                    refetch={documentsDataRefetch}
                    searchString={searchString}
                    setSearchString={setSearchString}
                    setSelectedRows={setSelectedConsumptionEvidenceDocuments}
                  />

                  {consumptionEvidenceDataLoading ? <DocumentsSkeleton/> :
                    <div data-testid={'consumption-table-container'}>
                      <DataTable
                        value={consumptionEvidenceDataList}
                        selection={selectedConsumptionEvidenceDocuments}
                        onSelectionChange={e => onConsumptionEvidenceSelectionChange(e)}
                        className={'p-datatable-gridlines ' + consumptionEvidenceTableClassname}
                        emptyMessage={numberOfConsumptionEvidenceFiltersApplied.current !== 0 ? emptyDataMessage() : noData()}
                        paginator
                        rows={10}
                        totalRecords={consumptionEvidenceDataList?.length}
                        paginatorTemplate={getPaginatorTemplate()}
                        paginatorClassName={'data-table-paginator'}
                        paginatorLeft={consumptionPaginator}
                        scrollable
                      >
                        <Column selectionMode="multiple" style={{width: '2%'}}/>
                        <Column field="fileNames" header={I18n('t_filenames')}
                          body={(rowData, props) => utilFileListTemplate(
                            rowData,
                            'fileNames',
                            true,
                            `file-list-${props.rowIndex}`,
                            'common-tooltip documents-file-tooltip'
                          )} style={{minWidth: '18rem'}}/>
                        <Column field="assetName" header={I18n('t_asset_name_tc')}
                          body={(rowData) => utilTextTemplate(rowData, 'assetName')}
                          style={{minWidth: '12rem'}}/>
                        <Column field="meterName" header={I18n('t_metername')}
                          body={(rowData) => utilTextTemplate(rowData, 'meterName')}
                          style={{minWidth: '12rem'}}/>
                        <Column field="fromDate" header={I18n('t_from')}
                          body={(rowData) => utilDateTemplate(rowData, 'fromDate', selectedLanguage, unitSystem)}
                          style={{minWidth: '10rem'}}/>
                        <Column field="toDate" header={I18n('t_to_tc')}
                          body={(rowData) => utilDateTemplate(rowData, 'toDate', selectedLanguage, unitSystem)}
                          style={{minWidth: '10rem'}}/>
                        <Column field="dateOfUpload" header={I18n('t_date_of_upload')}
                          body={(rowData) => utilDateTemplate(rowData, 'dateOfUpload', selectedLanguage, unitSystem)}
                          style={{minWidth: '10rem'}}/>
                        <Column field="fileSize" header={I18n('t_document_size')}
                          body={(rowData) => utilTextTemplate(rowData, 'fileSize')}
                          style={{minWidth: '10rem'}}/>
                        <Column field="uploadedBy" header={I18n('t_uploaded_by')}
                          body={(rowData) => uploadedByTemplate(rowData, 'userName')}
                          style={{minWidth: '10rem'}}/>
                        <Column field="fundName" header={I18n('t_fundname')}
                          body={(rowData) => utilTextTemplate(rowData, 'fundName')}
                          style={{minWidth: '12rem'}}/>
                        <Column field="assetReference" header={I18n('t_asset_reference_tc')}
                          body={(rowData) => utilTextTemplate(rowData, 'assetReference')}
                          style={{minWidth: '12rem'}}/>
                      </DataTable>
                    </div>
                  }
                </>
              </TabPanel>}
            </TabView>

            {isFilterSideBarVisible && renderDocumentsFilter()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DocumentsHome