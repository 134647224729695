import React from 'react'
import useFundUrlValidator from '../../../../utils/custom-hooks/UseFundUrlValidator'
import {Redirect, Route} from 'react-router-dom'
import {isNZCModuleAccessible, isUserLoggedIn, prefixSlash} from '../../../../utils/helpers/Helper'
import { COMMON_PAGES } from '../../../../utils/helpers/Constants'
import AssetNzcFeatureRoute from './AssetNzcFeatureRoute'
import {Flags} from '../../../../utils/reducers/FeatureFlagReducer'

const getAssetNzcFeatureRoute = (props, Component, rest, urlValidated, isUrlValid) => {
  let componentToRender = <AssetNzcFeatureRoute component={Component} {...rest} {...props}  flag={Flags.AssetNZC}/>
  if (urlValidated) {
    if (!isUrlValid) {
      componentToRender = <Redirect to={prefixSlash(COMMON_PAGES.notfound)} />
    }
  }
  return componentToRender
}

const getUrlParamsRequest = (matchRoute) => {
  return {
    matchRoute,
    instanceName: matchRoute.params?.instanceName,
    orgName: matchRoute.params?.orgName,
    fundName: matchRoute.params?.fundName,
    assetId: matchRoute.params?.assetId
  }
}

const AssetNzcProtectedRoute = ({component: Component, ...rest}) => {
  const matchRoute = rest.computedMatch
  if (!isUserLoggedIn()) {
    return <Redirect to={{pathname: '/login', state: {referrer: `${window.location.pathname}${window.location.search}`}}}/>
  } else if(!isNZCModuleAccessible()){
    return <Redirect to={prefixSlash(COMMON_PAGES.notfound)} />
  }
  else {
    const {urlValidated, isUrlValid}=useFundUrlValidator(getUrlParamsRequest(matchRoute))
    return <Route render={props=>getAssetNzcFeatureRoute(props, Component, rest, urlValidated, isUrlValid)} {...rest} />
  }
}

export default AssetNzcProtectedRoute
