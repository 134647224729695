import buildQuery from 'odata-query'
import CSS_VARIABLES from '../../../../../resources/css/_variables.scss'
import {chartAreaBorder, getChartOptions} from '../../../assets/performance/performance-home/ChartHelper'
import {GAP_FILLING, monthList, monthListShort, PERIOD_TYPES, PERIOD_VIEWS, ScopeTypes, SplitTypes, TONNE_OR_MEGAWATT, UTILITY_LABELS_OBJECT_TITLE_CASE, UtilityTypes} from '../../../../../utils/helpers/Constants'
import {createEndMonthParam, getBarSize, getChartBackgroundColor, getDivisorForTonneOrMegawatt, getLocalizedValue, smallFirstLetterInKey, sortDataSelectionKeyOrder} from '../../../../../utils/helpers/Helper'
import {FundPerformanceChartToolTip} from '../../../../common/tooltip/ChartHelperPerformanceTooltip'
import {getTimeperiodLabel, periodOptions, getInitialTimePeriod, getInitialPickerValue} from '../../../../../services/common/datepicker-service'

export function getPreviousActual(utilityData, selectedUtility) {
  let previousActual
  if (utilityData.previousActual) {
    if (selectedUtility !== UtilityTypes.Water) {
      previousActual = utilityData.previousActual / 1000
    } else {
      previousActual = utilityData.previousActual
    }
  } else {
    previousActual = 0
  }
  return previousActual
}

export function getTarget(utilityData, selectedUtility, isGapFillingSelected) {
  let targetValue = isGapFillingSelected ? utilityData.target : utilityData.previousActual
  if (targetValue) {
    if (selectedUtility !== UtilityTypes.Water) {
      targetValue = utilityData.target / TONNE_OR_MEGAWATT
    }
  } else {
    targetValue = 0
  }
  return targetValue
}

export function getDefaultChartDataset(selectedLanguage, barPercentage, categoryPercentage) {
  return {
    label: '',
    backgroundColor: 'transparent',
    hoverBackgroundColor: 'transparent',
    data: {},
    barPercentage: barPercentage,
    categoryPercentage: categoryPercentage,
    borderRadius: 0,
    borderSkipped: false,
    key: '',
    selectedLanguage: selectedLanguage ?? 'en-GB',
    datalabels: {display: false}
  }
}


function getFundPerformanceChartTooltip(selectedLanguage) {
  return {
    enabled: false,
    position: 'nearest',
    mode: 'point',
    language: selectedLanguage,
    external: FundPerformanceChartToolTip
  }
}

export function getMultiSelectOptions(selectedUtility, selectedLanguage) {
  if (selectedUtility === UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON) {
    return {
      showMultiDropdown: true,
      placeHolderText: 't_choose_breakdown',
      showPanelHeader: false,
      className: 'breakdown_dropdown',
      panelClassName: 'breakdown_dropdown_panel',
      options: [
        {name: getLocalizedValue(selectedLanguage, 't_scope1_landlord'), key: ScopeTypes.scope1},
        {name: getLocalizedValue(selectedLanguage, 't_scope2_landlord'), key: ScopeTypes.scope2},
        {name: getLocalizedValue(selectedLanguage, 't_scope3_tenant'), key: ScopeTypes.scope3},
        {name: getLocalizedValue(selectedLanguage, 't_gap_filling'), key: GAP_FILLING},
      ]
    }
  } else {
    return {
      showMultiDropdown: selectedUtility !== UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE,
      placeHolderText: 't_choose_breakdown',
      showPanelHeader: false,
      className: 'breakdown_dropdown',
      panelClassName: 'breakdown_dropdown_panel',
      options: [
        {name: getLocalizedValue(selectedLanguage, 't_landlord'), key: SplitTypes.landlord},
        {name: getLocalizedValue(selectedLanguage, 't_tenant'), key: SplitTypes.tenant},
        {name: getLocalizedValue(selectedLanguage, 't_gap_filling'), key: GAP_FILLING},
      ]
    }
  }
}

function chartDataPointsNotEmpty(chartDataPoints) {
  let notEmpty=!!chartDataPoints?.some(chartDataPoint=>chartDataPoint>0)
  return notEmpty
}



export const getChartDataSets = (chartDataSetsParams) => {
  const {chartDataPoints, selectedLanguage, barPercentage, bgColor, stackId, dataSelectionFilterKey, categoryPercentage, selectedUtility, isGapFillingSelected}=chartDataSetsParams

  const dataSets = []
  const {actualBelowData, actualAboveData, estimatedBelowData, estimatedAboveData, actualData, totalComparedToTargetData, nonRecycledData, dataSelectionIndex} = chartDataPoints
  const filterKeyIndexes={dataSelectionFilterKey, dataSelectionIndex}

  const defaultChartDataset = {...getDefaultChartDataset(selectedLanguage, barPercentage, categoryPercentage), dataSelectionFilterKey: dataSelectionFilterKey}
  if(selectedUtility !== UtilityTypes.Waste){
    defaultChartDataset.filterKeyIndexes=filterKeyIndexes
  }

  // Bar: Actual up to target dataset
  if( chartDataPointsNotEmpty(actualBelowData) && (isGapFillingSelected || selectedUtility === UtilityTypes.Waste)){
    const actualBelowDataset = {...defaultChartDataset}
    const actualBelowTargetColor=getChartBackgroundColor('actualBelowTarget', dataSelectionFilterKey, bgColor, selectedUtility)
    actualBelowDataset.label = 'Actual up to target dataset'
    actualBelowDataset.backgroundColor = actualBelowTargetColor
    actualBelowDataset.hoverBackgroundColor = actualBelowTargetColor
    actualBelowDataset.data = actualBelowData
    actualBelowDataset.key = 'belowTargetData'
    actualBelowDataset.stack=stackId
    actualBelowDataset.stackIndex=0
    actualBelowDataset.stackName= 'actualBelow'
    dataSets.push(actualBelowDataset)
  }

  // Bar: Estimated up to target dataset
  if(chartDataPointsNotEmpty(estimatedBelowData) && isGapFillingSelected && selectedUtility !== UtilityTypes.Waste) {
    const estimatedBelowDataset = {...defaultChartDataset}
    const estimatedBelowTargetColor=getChartBackgroundColor('estimatedBelowTarget', dataSelectionFilterKey, bgColor, selectedUtility)
    estimatedBelowDataset.label = 'Estimated up to target dataset'
    estimatedBelowDataset.backgroundColor = estimatedBelowTargetColor
    estimatedBelowDataset.hoverBackgroundColor = estimatedBelowTargetColor
    estimatedBelowDataset.data = estimatedBelowData
    estimatedBelowDataset.key = 'belowTargetData'
    estimatedBelowDataset.stack=stackId
    estimatedBelowDataset.stackIndex=1
    estimatedBelowDataset.stackName= 'estimatedBelow'
    dataSets.push(estimatedBelowDataset)
  }

  // Bar: Actual over target dataset
  if(chartDataPointsNotEmpty(actualAboveData) && (isGapFillingSelected || selectedUtility === UtilityTypes.Waste)){
    const actualAboveDataset = {...defaultChartDataset}
    const actualAboveTargetColor=getChartBackgroundColor('actualAboveTarget', dataSelectionFilterKey, bgColor, selectedUtility)
    actualAboveDataset.label = 'Actual over target dataset'
    actualAboveDataset.backgroundColor = actualAboveTargetColor
    actualAboveDataset.hoverBackgroundColor = actualAboveTargetColor
    actualAboveDataset.data = actualAboveData
    actualAboveDataset.key = 'aboveTargetData'
    actualAboveDataset.stack=stackId
    actualAboveDataset.stackIndex=2
    actualAboveDataset.stackName= 'actualAbove'
    dataSets.push(actualAboveDataset)
  }

  // Bar: Estimated over target dataset
  if(chartDataPointsNotEmpty(estimatedAboveData) && isGapFillingSelected && selectedUtility !== UtilityTypes.Waste){
    const estimatedAboveDataset = {...defaultChartDataset}
    const estimatedAboveTargetColor=getChartBackgroundColor('estimatedAboveTarget', dataSelectionFilterKey, bgColor, selectedUtility)
    estimatedAboveDataset.label = 'Estimated over target dataset'
    estimatedAboveDataset.backgroundColor =estimatedAboveTargetColor
    estimatedAboveDataset.hoverBackgroundColor = estimatedAboveTargetColor
    estimatedAboveDataset.data = estimatedAboveData
    estimatedAboveDataset.key = 'aboveTargetData'
    estimatedAboveDataset.stack=stackId
    estimatedAboveDataset.stackIndex=3
    estimatedAboveDataset.stackName= 'estimatedAbove'
    dataSets.push(estimatedAboveDataset)
  }

  // gap-fill OFF
  if(chartDataPointsNotEmpty(actualData) && !isGapFillingSelected && selectedUtility !== UtilityTypes.Waste){
    const actualBelowDataset = {...defaultChartDataset}
    const actualBelowTargetColor=getChartBackgroundColor('actualBelowTarget', dataSelectionFilterKey, bgColor, selectedUtility)
    actualBelowDataset.label = 'Actual dataset'
    actualBelowDataset.backgroundColor = actualBelowTargetColor
    actualBelowDataset.hoverBackgroundColor = actualBelowTargetColor
    actualBelowDataset.data = actualData
    actualBelowDataset.key = 'belowTargetData'
    actualBelowDataset.stack=stackId
    actualBelowDataset.stackIndex=0
    actualBelowDataset.stackName= 'actualBelow' // actual only
    dataSets.push(actualBelowDataset)
  }

  //Waste recycling only dataset const {actualBelowData, actualAboveData, estimatedBelowData, estimatedAboveData, totalComparedToTargetData, nonRecycledData} = chartDataPoints
  if(chartDataPointsNotEmpty(nonRecycledData) && selectedUtility === UtilityTypes.Waste){
    const wasteRecyclingDataset = {...defaultChartDataset}
    wasteRecyclingDataset.label = 'Non recycled waste dataset'
    wasteRecyclingDataset.backgroundColor =CSS_VARIABLES.sand
    wasteRecyclingDataset.hoverBackgroundColor = CSS_VARIABLES.sand
    wasteRecyclingDataset.data = nonRecycledData
    wasteRecyclingDataset.key = 'nonRecycledData'
    wasteRecyclingDataset.stack = stackId
    dataSets.push(wasteRecyclingDataset)
  }
  
  // Used for showing the tooltip in the space between the stacked bars and target line
  if(dataSelectionFilterKey===ScopeTypes.all){
    const totalComparedToTargetDataset = {...defaultChartDataset}
    totalComparedToTargetDataset.label = 'Target line above stacked bars'
    totalComparedToTargetDataset.data = totalComparedToTargetData
    totalComparedToTargetDataset.key = 'spaceBetweenTargetAndStackedBars'
    totalComparedToTargetDataset.stack=stackId
    totalComparedToTargetDataset.filterKeyIndexes=filterKeyIndexes
    dataSets.push(totalComparedToTargetDataset)
  }
  return dataSets
}

const getStackedBarValues = (data, divisor = 1000) => {
  const actualBelowTarget = data.actualBelowTarget > 0 ? data.actualBelowTarget : 0
  const actualAboveTarget = data.actualAboveTarget > 0 ? data.actualAboveTarget : 0
  const estimatedBelowTarget = data.estimatedBelowTarget > 0 ? data.estimatedBelowTarget : 0
  const estimatedAboveTarget = data.estimatedAboveTarget > 0 ? data.estimatedAboveTarget : 0
  const actual=data.actual > 0 ? data.actual : 0

  const actualBelowValue = actualBelowTarget / divisor
  const actualAboveValue = actualAboveTarget / divisor
  const estimatedBelowValue = estimatedBelowTarget / divisor
  const estimatedAboveValue = estimatedAboveTarget / divisor
  const actualValue = actual / divisor

  return {actualBelowValue, actualAboveValue, estimatedBelowValue, estimatedAboveValue, actualValue}
}

const getMonthLabels = (forMonths, consumptionDataSet, periodView, selectedLanguage, dataSelectionFilterKey, selectedUtility) => {

  const getMonthlyMonthLabel = (monthList, startMonth, short) => {
    const monthKey = smallFirstLetterInKey(monthList[startMonth])
    const shortMonthKey=smallFirstLetterInKey(monthListShort[startMonth])
    return getLocalizedValue(selectedLanguage, short ?  monthKey : shortMonthKey)
  }

  const getQuarterlyMonthLabel = (startMonth, startYear) => {
    let prevYear = ''
    let workingYear = startYear
    let endMonth = startMonth + 2
    if (endMonth > 12) {
      endMonth -= 12
      prevYear = workingYear
      workingYear++
    }
    const monthStart = smallFirstLetterInKey(monthListShort[startMonth])
    const monthEnd = smallFirstLetterInKey(monthListShort[endMonth])
    return `${getLocalizedValue(selectedLanguage, monthStart)}\n${prevYear} - ${getLocalizedValue(selectedLanguage, monthEnd)}\n${workingYear}`
  }

  let monthsSet = []
  if (consumptionDataSet.length) {
    monthsSet = Array(forMonths)
    for (let i = 0; i < forMonths; i++) {
      let  scopeSelectedData=selectedUtility===UtilityTypes.Waste ? consumptionDataSet[i] : consumptionDataSet[i][dataSelectionFilterKey]
      if (periodView === 'Monthly') {
        monthsSet[i] = getMonthlyMonthLabel(monthList,scopeSelectedData.startMonth)
      } else {
        monthsSet[i] = getQuarterlyMonthLabel(scopeSelectedData.startMonth, scopeSelectedData.startYear)
      }
    }
    return monthsSet
  }
}

export const getXAxisLabels = (consumptionData, periodView, selectedLanguage, dataSelectionFilterKey, selectedUtility) => {
  let timePeriodInMonths = consumptionData?.length
  let monthsList = getMonthLabels(timePeriodInMonths, consumptionData, periodView, selectedLanguage, dataSelectionFilterKey, selectedUtility)
  return monthsList
}

export const getYScaleTicksV2 = (consumptionData, selectedUtility, dataSeries, dataSelectionFilters, isGapFillingSelected) => {
  let YScaleMax
  let yMaxCumulative=[]
  if (dataSelectionFilters?.length > 0) {
    dataSelectionFilters?.forEach(({key}, index) => {
      YScaleMax = getYScaleMaxValues(selectedUtility, consumptionData, key, dataSeries[index], isGapFillingSelected)
      const { sumOfConsumptionValues=[], sumOfTargetValues=[], maxValueFromDataSeries }=YScaleMax
      yMaxCumulative.push(Math.max(maxValueFromDataSeries, ...sumOfConsumptionValues, ...sumOfTargetValues))
    })
  } else {
    YScaleMax = getYScaleMaxValues(selectedUtility, consumptionData, ScopeTypes.all, dataSeries[0], isGapFillingSelected)
    const { sumOfConsumptionValues=[], sumOfTargetValues=[], maxValueFromDataSeries=0 }=YScaleMax
    yMaxCumulative.push(Math.max(maxValueFromDataSeries, ...sumOfConsumptionValues, ...sumOfTargetValues))
  }

  let maxValue = Math.max(...yMaxCumulative)
  const maxValueOffsetIfCloseToZero = ((maxValue > 0.5 && maxValue <= 1) || maxValue === 0) ? 1 : 0.5

  // To handle special case where api response for max value is 0 ,and in range from 0.51 to 1
  maxValue = maxValue > 1 ? maxValue : maxValueOffsetIfCloseToZero
  let stepValue = maxValue / 10
  let stepValueOffset = stepValue >= 0.05 ? 0.1 : 0.05
  stepValue = stepValue >= 0.1 ? stepValue : stepValueOffset

  if (stepValue >= 1) { // Handle scenario with max value 0 and in range from 0.51 to 1
    stepValue = Math.trunc(stepValue)
    maxValue = Math.trunc(maxValue)
  }
  if (!consumptionData.length) {
    return {min: 0, max: 1000}
  } else {
    return {min: 0, max: maxValue + (3 * stepValue), stepSize: stepValue}
  }
}

function getTargetValueBasedOnGapFill(isGapFillingSelected, data, dataSelectionFilterKey, selectedUtility) {
  const divisor = getDivisorForTonneOrMegawatt(selectedUtility)
  if (isGapFillingSelected) {
    return (data[dataSelectionFilterKey].target / divisor)
  } else {
    return (data[dataSelectionFilterKey].previousActual / divisor)
  }
}

export const getYScaleMaxValues = (selectedUtility, consumptionData, dataSelectionFilterKey, dataSeries, isGapFillingSelected) => {
  const { actualBelowData, actualAboveData, estimatedBelowData, estimatedAboveData } = dataSeries
  let sumOfConsumptionValues // for Waste
  let sumOfTargetValues // for target-line
  let maxValueFromDataSeries // for other utilities(except Waste)

  if (selectedUtility === UtilityTypes.Waste) {
    sumOfConsumptionValues = consumptionData.map(data => (data.recycledBelowTarget + data.recycledAboveTarget+data.nonRecycled))
  } else {
    if (isGapFillingSelected) {
      maxValueFromDataSeries = Math.max(...actualBelowData) + Math.max(...actualAboveData) + Math.max(...estimatedBelowData) + Math.max(...estimatedAboveData)
    } else {
      maxValueFromDataSeries = Math.max(...actualBelowData) + Math.max(...actualAboveData)
    }
  }

  if (selectedUtility === UtilityTypes.Waste ) {
    sumOfTargetValues = consumptionData.map(data =>  data.target)
  } else {
    sumOfTargetValues = consumptionData.map(data => getTargetValueBasedOnGapFill(isGapFillingSelected, data, dataSelectionFilterKey, selectedUtility))
  }
  return { sumOfConsumptionValues, sumOfTargetValues, maxValueFromDataSeries }
}

function setChartDatasets(paramsForSetChartDatasets) {
  const {performanceChartData, chartConfigData, consumptionData, selectedUtility, dataSelectionFilters, selectedLanguage, isTargetAvailable, isGapFillingSelected}=paramsForSetChartDatasets

  performanceChartData.data.datasets = chartConfigData.datasetsMain
  const yScaleTicksV2 = getYScaleTicksV2(consumptionData, selectedUtility, chartConfigData.chartDataPointsMain, dataSelectionFilters, isGapFillingSelected)
  let options = getChartOptions(isTargetAvailable, true, yScaleTicksV2, selectedLanguage, {name: selectedUtility}, null, getFundPerformanceChartTooltip(selectedLanguage), false)
  performanceChartData.options = options
  performanceChartData.fundPerformanceTargetLines=chartConfigData.targetDSMain
  performanceChartData.carbonMonthlyDataAllMain=chartConfigData.carbonMonthlyDataAllMain
  performanceChartData.barStacksCount=chartConfigData.barStacksCount
}

/**
* Function to get Performance Chart Data based on the selected utility type.
*/
export function getPerformanceChartData(performanceChartDataParams) {
  const {consumptionData, dataSelectionFilters, isTargetAvailable, selectedLanguage, selectedUtility, periodView, isGapFillingSelected}=performanceChartDataParams
  if(!consumptionData){
    return null
  }

  let xLabels = getXAxisLabels(consumptionData, periodView, selectedLanguage, ScopeTypes.all, selectedUtility) // done
  const performanceChartData= {
    type: 'bar',
    utility: selectedUtility,
    data: {
      labels: xLabels,
      datasets: null
    },
    options: {},
    fundPerformanceTargetLines: [],
    plugins: [chartAreaBorder],
    dataSelectionFilters: dataSelectionFilters,
    isGapFillingSelected
  }

  const chartDataParams = {consumptionData, selectedUtility, dataSelectionFilters, isPdf:false, selectedLanguage, isGapFillingSelected}
  let paramsForSetChartDatasets={performanceChartData, chartConfigData: null, consumptionData, selectedUtility, dataSelectionFilters, selectedLanguage, isTargetAvailable, isGapFillingSelected}

  if (selectedUtility === UtilityTypes.Waste) {
    const chartConfigData = getChartData(chartDataParams)
    paramsForSetChartDatasets = {...paramsForSetChartDatasets, chartConfigData: chartConfigData}
    setChartDatasets(paramsForSetChartDatasets)
  } else  {
    sortDataSelectionKeyOrder(dataSelectionFilters)
    const chartConfigData = getChartData(chartDataParams)
    paramsForSetChartDatasets = {...paramsForSetChartDatasets, chartConfigData: chartConfigData}
    setChartDatasets(paramsForSetChartDatasets)
  }

  return performanceChartData
}
function getTargetDataForSelectedData(selectedScopeData, selectedUtility, dataSelectionFilterKey, index, stackId, isGapFillingSelected) {
  // For creating target DS
  let targetData
  if(selectedUtility && selectedUtility !== UtilityTypes.Waste) {
    targetData= {
      ...selectedScopeData,
      dataIndex: index,
      previousActual: getPreviousActual(selectedScopeData, selectedUtility),
      target: getTarget(selectedScopeData, selectedUtility, isGapFillingSelected),
      scopeId: dataSelectionFilterKey,
      stackId: stackId
    }
  } else {
    targetData=  {
      ...selectedScopeData,
      dataIndex: index,
      scopeId: dataSelectionFilterKey,
      stackId: stackId
    }
  }
  return targetData
}

export function getWasteStackedBarValues(selectedScopeData) {
  const recycledBelowTarget = selectedScopeData.recycledBelowTarget > 0 ? selectedScopeData.recycledBelowTarget : 0
  const recycledAboveTarget = selectedScopeData.recycledAboveTarget > 0 ? selectedScopeData.recycledAboveTarget : 0
  const nonRecycled = selectedScopeData.nonRecycled > 0 ? selectedScopeData.nonRecycled : 0

  return {recycledBelowTarget, recycledAboveTarget, nonRecycled}
}

function getBarHeightToTargetDifference(barHeightToTargetDifferenceParams) {
  const {
    actualBelowValue,
    actualAboveValue,
    estimatedBelowValue,
    estimatedAboveValue,
    isGapFillingSelected,
    selectedScopeData,
    divisor,
    actualValue
  } = barHeightToTargetDifferenceParams

  let stackedBarHeight = actualValue // actualBelowValue + actualAboveValue
  let target = selectedScopeData.previousActual
  if (isGapFillingSelected) {
    stackedBarHeight = actualBelowValue + actualAboveValue + estimatedBelowValue + estimatedAboveValue
    target = selectedScopeData.target
  }
  let barHeightToTargetDifference = (target / divisor) - stackedBarHeight

  return barHeightToTargetDifference > 0 ? barHeightToTargetDifference : 0
}

function getDatasetsForDataSelectionFilter(datasetsParams) {
  const {consumptionData, selectedUtility, dataSelectionFilterKey, isPdf, selectedLanguage, stackId, dataSelectionFilters, dataSelectionIndex, isGapFillingSelected}=datasetsParams

  //quarterly/ monthly graph data
  const actualBelowData = []
  const actualAboveData = []
  const estimatedBelowData = []
  const estimatedAboveData = []
  const totalComparedToTargetData = []
  const nonRecycledData = []
  const targetDSForEachDataSelection=[]
  const carbonMonthlyDataAll=[]
  const actualData = []

  // 6 months data
  consumptionData.forEach((carbonData, index) => {
    const selectedScopeData = carbonData[dataSelectionFilterKey]
    carbonMonthlyDataAll.push(carbonData.all)
    if(selectedUtility === UtilityTypes.Waste){
      const  {recycledBelowTarget, recycledAboveTarget, nonRecycled}=getWasteStackedBarValues(carbonData)
      actualBelowData.push(recycledBelowTarget)
      actualAboveData.push(recycledAboveTarget)
      nonRecycledData.push(nonRecycled)
      targetDSForEachDataSelection.push(getTargetDataForSelectedData(carbonData, selectedUtility, dataSelectionFilterKey, index, stackId, isGapFillingSelected))
    } else {
      const divisor = selectedUtility === UtilityTypes.Water ? 1 : 1000
      const {
        actualBelowValue,
        actualAboveValue,
        estimatedBelowValue,
        estimatedAboveValue,
        actualValue
      } = getStackedBarValues(selectedScopeData, divisor)

      const barHeightToTargetDifferenceParams = {
        actualBelowValue,
        actualAboveValue,
        estimatedBelowValue,
        estimatedAboveValue,
        isGapFillingSelected,
        selectedScopeData,
        divisor,
        actualValue
      }

      actualBelowData.push(actualBelowValue)
      actualAboveData.push(actualAboveValue)
      estimatedBelowData.push(estimatedBelowValue)
      estimatedAboveData.push(estimatedAboveValue)
      actualData.push(actualValue)

      totalComparedToTargetData.push(getBarHeightToTargetDifference(barHeightToTargetDifferenceParams))
      // For target dataset
      //dataSelectionFilterKey===ScopeTypes.all && targetDSForEachDataSelection.push(getTargetDataForSelectedData(selectedScopeData, selectedUtility, dataSelectionFilterKey, index, stackId))
      targetDSForEachDataSelection.push(getTargetDataForSelectedData(selectedScopeData, selectedUtility, dataSelectionFilterKey, index, stackId, isGapFillingSelected))
    }
  })

  const { barPercentage, categoryPercentage } = getBarSize(consumptionData, dataSelectionFilters)
  // const barPercentage = consumptionData && consumptionData.length < 6 ? 0.16 : 0.5
  const bgColor = isPdf ? CSS_VARIABLES.forest : null
  const chartDataPoints = {actualBelowData, actualAboveData, estimatedBelowData, estimatedAboveData, actualData, totalComparedToTargetData, nonRecycledData, dataSelectionIndex}
  const chartDataSetsParams={chartDataPoints, selectedLanguage, barPercentage, bgColor, stackId, dataSelectionFilterKey, categoryPercentage, selectedUtility, isGapFillingSelected}
  const datasets = getChartDataSets(chartDataSetsParams)
  return  {chartDataPoints, datasets, targetDSForEachDataSelection, carbonMonthlyDataAll}
}

function getChartData(chartDataParams) {
  const {consumptionData, selectedUtility, dataSelectionFilters, isPdf, selectedLanguage, isGapFillingSelected} = chartDataParams
  let datasetsParams = {
    consumptionData,
    selectedUtility,
    dataSelectionFilterKey: null,
    isPdf,
    selectedLanguage,
    stackId: null,
    dataSelectionFilters,
    dataSelectionIndex: null,
    isGapFillingSelected
  }

  const datasetsMain=[]
  const targetDSMain=[]
  let chartDataPointsMain=[]
  let carbonMonthlyDataAllMain
  const barStacksCount={}
  if(dataSelectionFilters?.length > 0){ // There are user selection
    dataSelectionFilters.forEach((dataSelectionFilter, indexMain) => {
      datasetsParams={...datasetsParams, dataSelectionFilterKey:dataSelectionFilter.key, stackId:indexMain + 1, dataSelectionIndex:indexMain}
      const {chartDataPoints, datasets, targetDSForEachDataSelection, carbonMonthlyDataAll} = getDatasetsForDataSelectionFilter(datasetsParams)
      chartDataPointsMain.push(chartDataPoints)
      barStacksCount[dataSelectionFilter.key]=datasets.length
      datasetsMain.push(...datasets)
      targetDSMain.push(targetDSForEachDataSelection)
      carbonMonthlyDataAllMain=carbonMonthlyDataAll
    })
  } else {
    datasetsParams={...datasetsParams, dataSelectionFilterKey: ScopeTypes.all, stackId: 1, dataSelectionIndex: 0}
    const {chartDataPoints, datasets, targetDSForEachDataSelection} = getDatasetsForDataSelectionFilter(datasetsParams)

    chartDataPointsMain.push(chartDataPoints)
    datasetsMain.push(...datasets)
    targetDSMain.push(targetDSForEachDataSelection)
  }
  return {chartDataPointsMain, datasetsMain, targetDSMain, carbonMonthlyDataAllMain, barStacksCount}
}

export function oneOfPeriodOptions(periodType) {
  const allPeriodOptions = ['CUS', ...periodOptions]
  return allPeriodOptions.includes(periodType)
}

export function oneOfUtilityTypes(utilityType) {
  const utilityTypes = [UtilityTypes.Carbon, UtilityTypes.Electricity, UtilityTypes.GasFuelsAndThermals, UtilityTypes.Water, UtilityTypes.Waste]
  return utilityTypes.includes(utilityType)
}

export const setInitialPerformanceHomeState = (fundGroupId, fundName, selectedUtilityLabel, queryValues) => {
  let utilityName=queryValues.get('utility') ?? selectedUtilityLabel
  if(!oneOfUtilityTypes(utilityName)){
    utilityName=UtilityTypes.Carbon
  }
  const selectedUtility = {name: utilityName}
  let sortField
  if (selectedUtility.name === UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE) {
    sortField = 'waste/total'
  } else {
    sortField = `${selectedUtility.name.toLowerCase()}/consumption`
  }
  const oDataSortOrder = 'desc'
  let odataQueryString = buildQuery({orderBy: `${sortField} ${oDataSortOrder}`})
  const periodView = queryValues.get('periodView') ? queryValues.get('periodView') : PERIOD_VIEWS.QUARTERLY
  const timePeriod = getInitialTimePeriod(queryValues)
  const pickerValue = getInitialPickerValue(queryValues)
  const time = getTimeperiodLabel(timePeriod) // default is 'OneYear'
  const timeNoOfMonthsPeriod = pickerValue?.pickerValueMonths
  const timeEndDatePeriod = createEndMonthParam(pickerValue.pickerValueEndYear, pickerValue.pickerValueEndMonth)

  return {
    selectedFund: {fundname: fundName},
    consumptionData: [],
    sortOrder: -1,
    oDataSortOrder,
    odataQueryString,
    fundPerformanceUtilityGraphDataVariables: [
      'fundPerformanceUtilityGraphData',
      fundGroupId,
      periodView,
      time,
      timeNoOfMonthsPeriod,
      timeEndDatePeriod,
      selectedUtility.name
    ],
    fundPerformanceSummaryVariables: [
      'fundPerformanceSummary',
      fundGroupId,
      selectedUtility.name,
      time,
      timeNoOfMonthsPeriod,
      timeEndDatePeriod
    ],
    selectedUtility,
    periodView,
    timePeriod,
    sortField,
    pickerValue,
    selectedBreakdown: [],
  }
}

export function getQueryParamsForPerformance(periodType, pickerValue, selectedUtilityName, periodView) {
  let queryParams={timePeriod: periodType, utility: selectedUtilityName, periodView,}
  if (periodType === PERIOD_TYPES.CUSTOM) {
    queryParams={...queryParams,
      noOfMonths: pickerValue.pickerValueMonths,
      endMonth: pickerValue.pickerValueEndMonth,
      endYear: pickerValue.pickerValueEndYear
    }
  }
  return queryParams
}