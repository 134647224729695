import React from 'react'
import '../../../resources/css/_common.scss'

const RoundedLabel = ({name}) => {
  return (
    <div className="round-label">
      <span className={'p-name'}>{name}</span>
    </div>
  )
}

export default RoundedLabel
