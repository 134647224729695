import {InputText} from 'primereact/inputtext'
import React, {useEffect, useState} from 'react'
import {Button} from 'primereact/button'
import './SignUp.scss'
import {useFormik} from 'formik'
import {Card} from 'primereact/card'
import hidePassword from '../../../../../resources/images/icon/eye-hide.svg'
import {useMutation, useQuery} from 'react-query'
import {userCreationSignup, userVerify} from '../../../../../services/admin/users-service'
import {noRefetchOnWindowFocus} from '../../../../../services/common/useQuery-config'
import ErrorDialog from '../../../assets/data-quality/error-dialog/ErrorDialog'
import I18n from '../../../../../utils/i18n/I18n'
import SignupSkeleton from '../../../../common/skeletons/signup-skeleton/SignupSkeleton'
import {AuthCardFooter} from '../../../login/auth-card-footer/AuthCardFooter'
import showPassword from '../../../../../resources/images/icon/eye-show.svg'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

function SignUp({parameter}){
  const [signUpData, setSignUpData] = useState(null)
  const urlAfterSignUp = parameter.location.search
  //Fetch tokens
  const query =  new URLSearchParams(urlAfterSignUp)
  const passwordToken = decodeURIComponent(query.get('a'))
  const emailToken = decodeURIComponent(query.get('b'))
  const userToken = decodeURIComponent(query.get('c'))
  const userId = decodeURIComponent(query.get('d'))
  //Verify user
  const {data: isUserValid,isError,isLoading}  = useQuery([userId,userToken], userVerify, noRefetchOnWindowFocus)
  const [errorDialogVisible, setErrorDialogVisible] = useState(false)
  const [errorDialogData, setErrorDialogData] = useState()
  const failed_msg_key = 't_title_cannot_signup'
  const { logOutOfAuthAndApp } = useLoginAuthContext()

  const userCreation = useMutation(userCreationSignup,{
    onError: (error) => {
      setErrorNote(error)
    },
    onSuccess:()=>{
      //TODO Confirm the next page to push
      logOutOfAuthAndApp()
    }
  })

  useEffect(()=>{
    if(isUserValid){
      setSignUpData(isUserValid)
    }
  },[isUserValid])

  //Sets dialog if the user is not verified
  useEffect(()=>{
    if(isError===true){
      setErrorForUnverifiedUser()
    }
  },[isError])

  function setErrorForUnverifiedUser(){
    setErrorDialogData({title: 'Unable to fetch user data', message: 'Link might have been expired'})
    setErrorDialogVisible(true)
  }

  function setErrorNote(error){
    setErrorDialogData({title: I18n(failed_msg_key), message: error.toString()})
    setErrorDialogVisible(true)
  }

  function getInitialValues() {
    return {
      username: signUpData ? signUpData.userName : '',
      company: signUpData ? signUpData.company : '',
      email: signUpData ? signUpData.email :'',
      password: '',
      confirmPassword: ''
    }
  }

  // Formik properties.
  const formik = useFormik({
    enableReinitialize:true,
    initialValues: getInitialValues(),
    validate: (data) => {
      let errors = {}
      if(data.password !== data.confirmPassword) {
        errors.password = I18n('t_password_not_match')
      }
      return errors
    },
    onSubmit: async (data) => {
      data.passwordToken = passwordToken
      data.emailToken = emailToken
      await userCreation.mutate({userId: userId, data: data})
      //formik.resetForm();
    }
  })

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name])
  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) ? <div className="p-error">{formik.errors[name]}</div> : <div/>
  }

  function isAllDataFilled() {
    let isDisabled = formik.values.password && formik.values.confirmPassword && formik.values.username
    return !isDisabled
  }

  function onShowPasswordClick(e,id) {
    const passInput = document.getElementById(id)
    if(passInput.getAttribute('type') === 'password') {
      passInput.setAttribute('type', 'text')
    } else {
      passInput.setAttribute('type', 'password')
    }

    if(e.target.getAttribute('src') === showPassword) {
      e.target.setAttribute('src', hidePassword)
    } else {
      e.target.setAttribute('src', showPassword)
    }
  }

  return(
    <div className="min-width-760" aria-label={'user-signup'}>
      <div className={'p-d-flex p-jc-center p-align-center pt-40 pb-40'}>
        <div>
          <Card className={'auth-card'} footer={AuthCardFooter(isError)}>
            <div className="header-text">{I18n('t_create_siera_account')}</div>
            {isLoading ? <SignupSkeleton/>:
              <form onSubmit={formik.handleSubmit}>
                <div className="field" data-testid={'full-name-input'}>
                  <span className="p-float-label">
                    <InputText id="username" name="username" value={formik.values.username}
                      onChange={formik.handleChange} />
                    <label htmlFor="username">{I18n('t_full_name')}</label>
                  </span>
                </div>

                <div className="field" data-testid={'company-input'}>
                  <span className="p-float-label">
                    <InputText id="company" name="company" className="hide-border" value={formik.values.company}
                      onChange={formik.handleChange} disabled/>
                    <label htmlFor="company">{I18n('t_company')}</label>
                  </span>
                </div>

                <div className="field" data-testid={'email-input'}>
                  <span className="p-float-label">
                    <InputText id="emailAddress" name="emailAddress" className="hide-border" value={formik.values.email}
                      onChange={formik.handleChange} disabled/>
                    <label htmlFor="emailAddress">{I18n('t_email_address')}</label>
                  </span>
                </div>

                <div className="field p-d-flex" data-testid={'password-input'}>
                  <span className="p-float-label">
                    <InputText id="sign-up-password" name="password" value={formik.values.password}
                      onChange={formik.handleChange} type="password"/>
                    <label htmlFor="sign-up-password">{I18n('t_password')}</label>
                  </span>
                  <img alt="Show/Hide password" src={hidePassword} className="sign-up-hide-pass"
                    onClick={(e) => onShowPasswordClick(e,'sign-up-password')} />
                </div>

                <div className="field p-d-flex" data-testid={'confirm-password-input'}>
                  <span className="p-float-label">
                    <InputText id="sign-up-confirm-password" name="confirmPassword" value={formik.values.confirmPassword}
                      onChange={formik.handleChange} type="password" />
                    <label htmlFor="confirmPassword">{I18n('t_confirm_password')}</label>
                  </span>
                  <img alt="Show/Hide password" src={hidePassword} className="sign-up-hide-pass"
                    onClick={(e) => onShowPasswordClick(e,'sign-up-confirm-password')} />
                </div>

                <div className="submit p-jc-between p-d-flex">
                  {getFormErrorMessage('password')}
                  <Button type="submit" label={I18n('t_submit')} className="submit-button common-yellow-bg-btn"
                    disabled={isAllDataFilled()}/>
                </div>
              </form>}
          </Card>
        </div>
        {errorDialogVisible && <ErrorDialog
          data={errorDialogData}
          dialogVisible={errorDialogVisible}
          onHideHandler={()=>setErrorDialogVisible(false)}
          closeHandler={()=>setErrorDialogVisible(false)}
        />}
      </div>
    </div>
  )
}

export default SignUp