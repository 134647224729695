import axiosInstance from '../common/axios-config'
import {CUSTOM} from '../../utils/helpers/Constants'
import {convertDateToUTCFormat} from '../../utils/helpers/Helper'

const CLASS_WST_GRAPH_LABELS = 'wst-graph-labels'

export const GetDQResponsibility = async (keys) => {
  const queryKeys = keys.queryKey
  const [, assetId, period, noOfMonths, endMonth]=queryKeys
  let apiQueryParams='responsibility=both'
  if(period=== CUSTOM){
    apiQueryParams= `${apiQueryParams}&utility=All&areaCovered=All&period=${period}&noOfMonths=${noOfMonths}&endMonth=${endMonth}`
  } else {
    apiQueryParams =`${apiQueryParams}&utility=All&areaCovered=All&period=${period}`
  }
  const {data} = await axiosInstance.get(`/api/v1/Assets/${assetId}/DataQualityResponsibility?${apiQueryParams}`)
  return data?.dataQualityBarList
}

export const getDqOverviewDataV2 = async (queryParams) => {
  const {assetId, period, noOfMonths, endMonth, utility, areaCovered, responsibility}=queryParams
  let apiQueryParams=`responsibility=${responsibility}&utility=${utility}&areaCovered=${areaCovered}`
  if(period=== CUSTOM){
    apiQueryParams= `${apiQueryParams}&period=${period}&noOfMonths=${noOfMonths}&endMonth=${endMonth}`
  } else {
    apiQueryParams =`${apiQueryParams}&period=${period}`
  }
  const {data} = await axiosInstance.get(`/api/v1/Assets/${assetId}/DataQualityResponsibility?${apiQueryParams}`)
  return data?.dataQualityBarList
}

export const getDqOverviewDataForLandlord = async (keys) => {
  const queryKeys = keys.queryKey
  const [, assetId, period, noOfMonths, endMonth, utility, areaCovered]=queryKeys
  const queryParams={assetId, period, noOfMonths, endMonth, utility, areaCovered, responsibility: 'landlord'}
  return getDqOverviewDataV2(queryParams)
}

export const getDqOverviewDataForTenant = async (keys) => {
  const queryKeys = keys.queryKey
  const [, assetId, period, noOfMonths, endMonth, utility, areaCovered]=queryKeys
  const queryParams={assetId, period, noOfMonths, endMonth, utility, areaCovered, responsibility: 'tenant'}
  return getDqOverviewDataV2(queryParams)
}

export const getMeterDetailsDataV2 = async (keys) => {
  const queryKeys = keys.queryKey
  const [, assetId, period, noOfMonths, endMonth, utility, areaCovered] = queryKeys
  let queryParams = `period=${period}`

  if(period=== CUSTOM) {
    queryParams= `${queryParams}&noOfMonths=${noOfMonths}&endMonth=${endMonth}`
  }
  const body = {
    utility: utility,
    areaCovered: areaCovered,
  }

  const {data} = await axiosInstance.post(`/api/v1/DataQuality/${assetId}/MetersView?${queryParams}`, body)
  return data
}

export const getAssetCompleteness = async (keys) => {
  const queryKeys = keys.queryKey
  const [, assetId, period, noOfMonths, endMonth, utility, areaCovered, category, responsbility] = queryKeys

  let queryParams = `period=${period}`
  if(period=== CUSTOM){
    queryParams= `${queryParams}&noOfMonths=${noOfMonths}&endMonth=${endMonth}`
  }

  const body = {
    dataCategory: category || 'All',
    utility: utility,
    areaCovered: areaCovered,
    procuredBy: responsbility || 'Both'
  }

  const {data} = await axiosInstance.post(`/api/v1/DataQuality/asset/${assetId}/completeness?${queryParams}`, body)
  return data
}

export const getAssetCoverage = async (keys) => {
  const queryKeys = keys.queryKey
  const [, assetId, period, noOfMonths, endMonth, utility, areaCovered, responsibility] = queryKeys
  let queryParams = `period=${period}`

  if(period=== CUSTOM){
    queryParams= `${queryParams}&noOfMonths=${noOfMonths}&endMonth=${endMonth}`
  }

  const body = {
    utility: utility,
    areaCovered: areaCovered,
    procuredBy: responsibility || 'Both'
  }

  const {data} = await axiosInstance.post(`/api/v1/DataQuality/asset/${assetId}/coverage?${queryParams}`, body)
  return data
}

export async function getAssetMetersV2(keys) {
  const queryKeys = keys.queryKey
  const [, assetId, period, noOfMonths, endMonth, utility, areaCovered, category, meterName, first, rows, responsibility] = queryKeys
  let queryParams = `period=${period}`

  if(period=== CUSTOM){
    queryParams= `${queryParams}&noOfMonths=${noOfMonths}&endMonth=${endMonth}`
  }
  const body = {
    dataCategory: category,
    utility: utility,
    areaCovered: areaCovered,
    meterName: meterName,
    procuredBy: responsibility || 'Both',
    pageOptions: {
      pageCount: first,
      pageSize: rows || 5
    },
  }

  const {data} = await axiosInstance.post(`/api/v1/DataQuality/${assetId}/MetersSummary?${queryParams}`, body)
  return data
}

export const GetMeterData = async (keys) => {
  const queryKeys = keys.queryKey
  if(!queryKeys[5] || !queryKeys[6]) {
    const {data} = await axiosInstance.get(`/api/v1/Assets/${queryKeys[1]}/MeterDataQualityDetails/?utility=${queryKeys[2]}&areaCovered=${queryKeys[3]}&period=${queryKeys[4]}`)
    return data
  } else {
    const {data} = await axiosInstance.get(`/api/v1/Assets/${queryKeys[1]}/MeterDataQualityDetails/?utility=${queryKeys[2]}&areaCovered=${queryKeys[3]}&period=${queryKeys[4]}&noOfMonths=${queryKeys[5]}&endMonth=${queryKeys[6]}`)
    return data
  }
}

export const GetMissingDataList = async (keys) => {
  const queryKeys = keys.queryKey
  if(!queryKeys[3] || !queryKeys[4]) {
    const {data} = await axiosInstance.get(`/api/v1/Assets/${queryKeys[1]}/MeterDataQuality/?period=${queryKeys[2]}`)
    return data
  } else {
    const {data} = await axiosInstance.get(`/api/v1/Assets/${queryKeys[1]}/MeterDataQuality/?period=${queryKeys[2]}&noOfMonths=${queryKeys[3]}&endMonth=${queryKeys[4]}`)
    return data
  }
}

//Helper util methods/constants for data-quality page.
export function getGraphDataForArea(graphData, area){
  let data
  if(graphData && graphData.length>0){
    data=graphData.find((dqGraphData)=>dqGraphData.responsbility===area)
  }
  return data
}

export const prepareDQGraphData=(data, convertedPercent, graphLabels, labels)=>{
  const WIDTH_100=100
  let dataBound=0
  dataBound=Math.round(data.actual+data.estimated+data.missing+data.noCoverage)
  if(dataBound>0){
    if(data.actual>0){
      convertedPercent.push((Math.round(data.actual*WIDTH_100)))
      graphLabels.push(labels[0])
    }
    if(data.estimated>0){
      convertedPercent.push((Math.round(data.estimated*WIDTH_100)))
      graphLabels.push(labels[1])
    }
    if(data.missing>0){
      convertedPercent.push((Math.round(data.missing*WIDTH_100)))
      graphLabels.push(labels[2])
    }
    if(dataBound<1){
      convertedPercent.push(((1-dataBound)*WIDTH_100))
      graphLabels.push(labels[3])
    } else {
      if(data.noCoverage>0){
        convertedPercent.push((Math.round(data.noCoverage*WIDTH_100)))
        graphLabels.push(labels[3])
      }
    }
  } else {
    convertedPercent.push(`${WIDTH_100}`)
    graphLabels.push(labels[3])
  }
}

export const getFirstLastDateISO=(year, month)=>{
  const date = new Date(year, month)
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
  const firstDayUTC=new Date(Date.UTC(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate()))
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
  const lastDayUTC=new Date(Date.UTC(lastDay.getFullYear(), lastDay.getMonth(), lastDay.getDate()))
  return {firstDay:firstDayUTC.toISOString(), lastDay:lastDayUTC.toISOString()}
}

export const monthsNumber = {
  'january' : 1,
  'february' : 2,
  'march' : 3,
  'april' : 4,
  'may' : 5,
  'june' : 6,
  'july' : 7,
  'august' : 8,
  'september' : 9,
  'october' : 10,
  'november' : 11,
  'december' : 12
}

export function convertMonthToNumber(month) {
  const index = monthNamesArray.indexOf(month)
  return index + 1
}

export const monthNamesArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

//Function to detect and remove overlapping/overflowing graph-label.
export function isGraphLabelOverlapping(dqGraphLabels){
  let labelChild
  let isLabelOverlapping=false
  for (let i = 0; i < dqGraphLabels.children.length; i++) {
    labelChild=dqGraphLabels.children[i]
    let parentHeight = labelChild.getBoundingClientRect().height
    let childHeight = labelChild.firstChild.getBoundingClientRect().height
    if(childHeight>parentHeight){
      isLabelOverlapping=true
      break
    }
  }
  return isLabelOverlapping
}

function isWasteGraphLabelOverlapping(dqGraphLabels){
  let labelChild
  let isLabelOverlapping=false
  for (let i = 0; i < dqGraphLabels.children.length; i++) {
    labelChild=dqGraphLabels.children[i]
    let parentHeight = dqGraphLabels.getBoundingClientRect().height
    let childHeight = labelChild.getBoundingClientRect().height
    if(childHeight>parentHeight){
      isLabelOverlapping=true
      if(labelChild.style){
        break
      }
    }
  }
  return isLabelOverlapping
}

export const removeLabelDisplay=(dqGraphLabels)=>{
  Array.from(dqGraphLabels.children).forEach((el)=> {
    if(isWasteGraphLabels(dqGraphLabels)){
      if(el.style){
        el.style.display = 'none'
      }
    } else {
      if(el.firstChild.style){
        el.firstChild.style.display = 'none'
      }
    }
  })
}

export const addLabelDisplay=(dqGraphLabels)=>{
  Array.from(dqGraphLabels.children).forEach((el)=> {
    if(isWasteGraphLabels(dqGraphLabels)){
      if(el.style){
        el.style.display = 'block'
      }
    } else {
      if(el.firstChild.style){
        el.firstChild.style.display = 'block'
      }
    }
  })
}

const isWasteGraphLabels=(dqGraphLabels)=>dqGraphLabels.classList.contains(CLASS_WST_GRAPH_LABELS)

function isOverlapping(dqGraphLabels) {
  if (isWasteGraphLabels(dqGraphLabels)) {
    return isWasteGraphLabelOverlapping(dqGraphLabels)
  } else {
    return isGraphLabelOverlapping(dqGraphLabels)
  }
}

export function removeOverlappingGraphLabel(cssSelectors){
  let isLabelOverlapping=false
  let dqGraphLabels = document.querySelector(cssSelectors)
  if(dqGraphLabels){
    isLabelOverlapping = isOverlapping(dqGraphLabels)
    if(isLabelOverlapping){
      removeLabelDisplay(dqGraphLabels)
    } else {
      addLabelDisplay(dqGraphLabels)
      isLabelOverlapping = isOverlapping(dqGraphLabels)
      if(isLabelOverlapping){
        removeLabelDisplay(dqGraphLabels)
      }
    }
  }
}

export const addNewMissingDataRequest = async (requestData) => {
  const {data} = await axiosInstance.post(`/api/v1/Assets/${requestData.assetId}/DataQualityGapRequest?emailAddresses=${requestData.emails?.join()}`,
    requestData.dataGap)
  return data
}

export const addNewMissingDataRequestInternal = async (requestData) => {
  const {data} = await axiosInstance.post(`/api/v1/Assets/${requestData.assetId}/DataQualityGapRequest/`, requestData.dataGap)
  return data
}

export const submitDataQualityRequest = async (requestData) => {
  const {data} = await axiosInstance.post('/api/v1/Assets/DataQualityGapRequest/Consumption', requestData)
  return data
}

export const validateConsumptionDetails = async (requestData) => {
  const {data} = await axiosInstance.post(`/api/v1/Assets/DataQualityGapRequest/ValidateConsumption?isEdit=${requestData.isEdit}&isDateModified=${requestData.isDateModified}`, requestData)
  return data
}

export const updateDataQualityRequest = async (requestData) => {
  const {data} = await axiosInstance.put('/api/v1/Assets/DataQualityGapRequest/Consumption', requestData)
  return data
}

export const sameDayDatesCheck = (first, second) => {
  return first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
}

// Helper function to create Request Data Object.
export const getDataGapRequestObj=(selectedData)=>{
  let missingDataReqArray=new Array(selectedData.length)
  selectedData.forEach((meter, index)=>{
    if(meter.isPartial) {
      missingDataReqArray[index]= {'meterID': meter.meterId, 'dateStart': meter.partialStart, 'dateEnd': meter.partialEnd}
    } /*
    Commenting this as there is difference between generating the requestId and user enetered data
     else if (meter.dataGapDate) {
      const firstLastDate=getFirstLastDateISO(meter.year,(monthsNumber[meter.dataGapDate.toLowerCase()]-1))
      missingDataReqArray[index]= {'meterID': meter.meterId, 'dateStart': firstLastDate.firstDay, 'dateEnd': firstLastDate.lastDay}
    }*/ else {
      missingDataReqArray[index]= {'meterID': meter.meterId, 'dateStart': convertDateToUTCFormat(meter.fromDate), 'dateEnd': convertDateToUTCFormat(meter.toDate)}
    }
  })
  return missingDataReqArray
}

export const DeleteConsumption = async (queryKeys)=>{
  const {data} = await axiosInstance.delete(`/api/v1/Assets/${queryKeys[0].assetId}/MeterDataQuality`, queryKeys[0])
  return data
}