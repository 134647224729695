import React from 'react'
import PropTypes from 'prop-types'
import Text  from '../../Text/Text'
import './TooltipTwoColumnsLayout.scss'

function TooltipTwoColumnsLayout({
  titleLeft,
  titleLeftIcon,
  titleRight,
  titleRightColour,
  titleRightIcon,
  items,
  footer
}) {

  const header = (
    (titleLeft || titleRight) &&
    <div className='t-row t-header'>
      { titleLeft && 
        <div className={`${titleRight ? 't-col-left': 't-full-width'}`}>
          {titleLeftIcon}
          <div className='t-title-left'><Text weight='bold' content={titleLeft} /></div>
        </div>
      }
      { titleRight &&
        <div className={`${titleLeft ? 't-col-right': 't-full-width'}`}>
          {titleRightIcon}
          <div className='t-title-right'><Text content={titleRight} colour={titleRightColour} /></div>
        </div>
      }
    </div>
  )

  const tooltipRecords = items.map((item, index) => {
    const { label, labelIcon, value, valueIcon, colour='forest' } = item
    const key = `row_${index}`

    return (
      <div key={key} className='t-row'>
        { label && 
          <div className={`${label ? 't-col-left': 't-full-width'}`}>
            <div className='t-title-left'>
              {labelIcon}
              <Text content={label} colour={colour} />
            </div>
          </div>
        }
        { value &&
          <div className={`${value ? 't-col-right': 't-full-width'}`}>
            <div className='t-title-right'>
              {valueIcon}
              <Text content={value} colour={colour} />
            </div>
          </div>
        }
      </div>
    ) 
  })

  const tooltipFooter = (
    footer &&
    <div className='t-footer'>
      {footer}
    </div>
  )
  
  return (
    <div className='tooltip-two-column-wrapper'> 
      {header}
      {tooltipRecords}
      {tooltipFooter}
    </div>
  )
}
  
TooltipTwoColumnsLayout.propTypes = {
  titleLeft: PropTypes.string,
  titleLeftIcon: PropTypes.node,
  titleRight: PropTypes.string,
  titleRightColour: PropTypes.oneOf(['green', 'red', 'forest']),
  titleRightIcon: PropTypes.node,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      labelIcon: PropTypes.node,
      value: PropTypes.string.isRequired,
      valueIcon: PropTypes.node,
      colour: PropTypes.oneOf(['green', 'red', 'forest'])
    })
  ).isRequired,
  footer: PropTypes.node
}
  
export default TooltipTwoColumnsLayout