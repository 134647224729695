import React, {useEffect, useRef, useState} from 'react'
import {Card} from 'primereact/card'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import I18n from '../../../../../../utils/i18n/I18n'
import UserFooter from '../user-footer/UserFooter'
import './UserGroups.scss'
import SearchField from '../../../../../common/search/SearchField'
import {useMutation, useQuery} from 'react-query'
import {AddAssetAccess, addAssetGroups, addRemoveFundsFromUser, AddUser, GetAllGroupsList, GetGroupsListById, UpdateAssetAccess, updateAssetGroups, UpdateUser} from '../../../../../../services/admin/users-service'
import {noRefetchOnWindowFocus} from '../../../../../../services/common/useQuery-config'
import UsersGridSkeleton from '../../../../../common/skeletons/users-grid-skeleton/UsersGridSkeleton'
import {
  ADMIN_PAGES,
  UserActions,
  userAssetDetails,
  userFundDetails,
  userGroupDetails,
  userInfo
} from '../../../../../../utils/helpers/Constants'
import {getSelectAllIconNode, getSelectAllIconNodeContainer, setSelectAllIcon} from '../../../../../../utils/helpers/DataTableUtils'
import {
  createAssetsIdsObject,
  createNavURLForUserPages, getInstance,
  getLocalizedValue,
  getLocalStorageByKey,
  getStructuredFund,
  messageCheck,
  setLocalStorageItem,
  onSelection,
} from '../../../../../../utils/helpers/Helper'
import ErrorDialog from '../../../../assets/data-quality/error-dialog/ErrorDialog'
import {useHistory} from 'react-router-dom/cjs/react-router-dom'
import {useParams} from 'react-router-dom'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

function UserGroups(props){
  const emptyStr=''
  const MSG_KEY_TITLE_CANNOT_POST_DATA = 't_title_cannot_post_data'
  const MSG_KEY_TITLE_CANNOT_PUT_DATA = 't_title_cannot_put_data'
  const pathToUsersPage=createNavURLForUserPages({instanceName: getInstance(),pathSuffix: ADMIN_PAGES.users })
  const [errorDialogVisible, setErrorDialogVisible] = useState(false)
  const [errorDialogData, setErrorDialogData] = useState()
  const history = useHistory()
  const groupsSelectedTabsActiveIndex=useRef(0)

  const {loginState} = useLoginAuthContext()
  const selectedLanguage = loginState.userInfo.languagePreference
  const [searchText, setSearchText] = useState(emptyStr)
  const [groupsCount, setGroupsCount] = useState(0)
  const [selectedGroups, setSelectedGroups] = useState([])
  const [groupsGridData, setGroupsGridData] = useState([])
  const [filteredHiddenSelectedGroups,setFilteredHiddenSelectedGroups]=useState([])
  const [filteredSelectedGroups,setFilteredSelectedGroups]=useState([])
  const [unSavedData,setUnSavedData] = useState(false)

  const groupsStatusInactiveClass = 'groups-status-inactive'
  const [totalGroupsActiveClass, setTotalGroupsActiveClass] = useState(emptyStr)
  const [memberGroupsActiveClass, setMemberGroupsActiveClass] = useState(groupsStatusInactiveClass)
  const addRemoveFundUserMutation = useMutation(addRemoveFundsFromUser)
  const urlParams = useParams()
  const isEditUserAction = () => props.userAction === UserActions.EDIT_USER
  const userId = isEditUserAction() ? urlParams.userId : null
  const {data: groupsData, isLoading}  = useQuery(['GroupsList'], GetAllGroupsList, noRefetchOnWindowFocus)
  const {data: userSelectedGroupData}  = useQuery(
    ['userAssetGroups', userId], GetGroupsListById, noRefetchOnWindowFocus
  )

  const updateUserMutation = useMutation(UpdateUser)

  const addUserAssetGroupsMutation = useMutation(addAssetGroups)
  const updateUserAssetGroupsMutation = useMutation(updateAssetGroups)

  const addAssetAccessMutation = useMutation(AddAssetAccess)
  const updateAssetAccessMutation = useMutation(UpdateAssetAccess)

  const addUserMutation = useMutation(AddUser)

  const options = {year: 'numeric', month: 'short', day: 'numeric'}
  const selectAllIconSelectors='.groups-details .user-groups-table .p-datatable-thead div.p-checkbox-box span.p-checkbox-icon'
  const users='users'

  useEffect(()=>{
    props.visited && props.visited(2)
  },[])
  useEffect(() => {
    if (groupsData) {
      let selectedGroups=[]
      if(userSelectedGroupData && isEditUserAction()){
        groupsData.map((individualGroups)=>{
          userSelectedGroupData.groups.map(selectedGroup=>{
            if(selectedGroup.assetGroupID === individualGroups.assetGroupID){
              selectedGroups.push(individualGroups)
            }
          })
        })
      }

      const userGroupDetailsObject=getLocalStorageByKey(userGroupDetails)
      setSelectedGroups(userGroupDetailsObject && userGroupDetailsObject.assetGroups? userGroupDetailsObject.assetGroups : selectedGroups)
      setGroupsGridData(groupsData)
      setGroupsCount(groupsData.length)
    }
  }, [groupsData, userSelectedGroupData])

  // This side effect is used for setting/resetting of selection and partial selection icons in the DataTable.
  useEffect(() => {
    const selectAllIconNode = getSelectAllIconNode(selectAllIconSelectors)
    if(unSavedData){
      saveAssetIdsOfSelectedGroups(selectedGroups)
    }
    if(searchText===''){
      setSelectAllIcon(selectedGroups, groupsGridData, selectAllIconNode)
    }
    else{
      setSelectAllIcon(filteredSelectedGroups, groupsGridData, selectAllIconNode)
    }
  }, [selectedGroups,filteredSelectedGroups ,groupsGridData])

  function groupNameTemplate(rowData) {
    return (
      <span>{rowData.name}</span>
    )
  }

  function companyNameTemplate(rowData) {
    return (
      <span>{rowData.organisation}</span>
    )
  }

  function createdDateTemplate(rowData) {
    return (
      <span>{new Date(rowData.createdDate).toLocaleDateString(selectedLanguage, options)}</span>
    )
  }

  function assetsTemplate(rowData) {
    return (
      <span>{rowData.assetCount}</span>
    )
  }

  function groupMembersTemplate(rowData) {
    return (
      <span>{rowData.memberCount}</span>
    )
  }

  function searchGroups(e) {
    let searchText = e.target.value
    setSearchText(searchText)
    let searchContainer = groupsSelectedTabsActiveIndex.current === 0 ? groupsData : selectedGroups
    let searchLC = searchText.toLowerCase()
    let filteredList = searchContainer.filter((key) => {
      if ((key.name.toLowerCase().indexOf(searchLC) > -1) ||
                (key.organisation.toLowerCase().indexOf(searchLC) > -1) ||
                (key.createdDate.toString().toLowerCase().indexOf(searchLC) > -1) ||
                (key.assetCount.toString().toLowerCase().indexOf(searchLC) > -1) ||
                (key.memberCount.toString().toLowerCase().indexOf(searchLC) > -1)) {
        return true
      }
    })
    setFilteredHiddenSelectedGroups(() => {
      return selectedGroups.filter((selectedGroup) => !filteredList.find(filteredGroup => (selectedGroup.assetGroupID === filteredGroup.assetGroupID)))
    })
    setFilteredSelectedGroups(() => {
      return selectedGroups.filter((selectedGroup) => filteredList.find(filteredGroup => (selectedGroup.assetGroupID === filteredGroup.assetGroupID)))
    })
    setGroupsGridData(filteredList)
  }

  function clearSearch() {
    let searchText = ''
    setSearchText(searchText)
    let filteredList = groupsData.filter((key) => {
      if (key.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1) {
        return true
      }
    })
    groupsSelectedTabsActiveIndex.current === 0 ? setGroupsGridData(filteredList) : setGroupsGridData(selectedGroups)
  }

  function saveAssetIdsOfSelectedGroups(selectedGroups){
    let groupAssetIds=[]
    if(selectedGroups){
      selectedGroups.forEach(selectedGroup=>{
        if(selectedGroup.assetCount>0){
          groupAssetIds=[...groupAssetIds, ...selectedGroup.assetIds]
        }
      })
      const groupDetails={isGroupsNavigatable: true, assetGroups: selectedGroups, assetIdsForSelectedGroups: groupAssetIds}
      setLocalStorageItem(userGroupDetails, groupDetails)
    }
  }

  function onGroupsSelectionChange(e) {
    let newSelectedGroups = e.value
    props.unSavedData()
    setUnSavedData(true)
    const thNode = getSelectAllIconNodeContainer(e)
    onSelection(users,newSelectedGroups, searchText, thNode, setSelectedGroups, setFilteredSelectedGroups, filteredHiddenSelectedGroups)
    if (groupsSelectedTabsActiveIndex.current === 1) {
      setGroupsGridData(newSelectedGroups)
    }
  }

  function allGroupsHandler() {
    groupsSelectedTabsActiveIndex.current=0
    setGroupsGridData(groupsData)
    setTotalGroupsActiveClass(emptyStr)
    setMemberGroupsActiveClass(groupsStatusInactiveClass)
    setSearchText('')
  }

  function selectedGroupsHandler() {
    groupsSelectedTabsActiveIndex.current=1
    setGroupsGridData(selectedGroups)
    setTotalGroupsActiveClass(groupsStatusInactiveClass)
    setMemberGroupsActiveClass(emptyStr)
    setSearchText('')
  }

  async function getSelectedOrCreatedUser() {
    return (isEditUserAction() ? props.userDetails : await postNewUserData(getLocalStorageByKey(userInfo)))
  }

  const postNewUserData = async (userData) => {
    let errorMsg=MSG_KEY_TITLE_CANNOT_POST_DATA
    let newUser = await addUserMutation.mutateAsync(userData.userDetails)
    if (newUser.hasOwnProperty('userId')) {
      props.updateNewUser(newUser)
    } else {
      setErrorDialogData({title: I18n(errorMsg), message: `${messageCheck(newUser)}`})
      setErrorDialogVisible(true)
    }
    return newUser
  }

  async function getUserAuthId() {
    const userDetails = await getSelectedOrCreatedUser()
    return (userDetails && userDetails ? userDetails : null)
  }

  function createUserAssetGroupsObject() {
    return {'assetGroupIds': selectedGroups.map((selectedGroup) => selectedGroup.assetGroupID)}
  }

  async function handleEditUser(userAssetGroupsObject,values) {
    await updateUserAssetGroupsMutation.mutateAsync(userAssetGroupsObject)
    //Saves Details data
    if (getLocalStorageByKey(userInfo)) {
      const userData = getLocalStorageByKey(userInfo).userDetails
      await updateUserMutation.mutateAsync({userId: values.userId, userData: userData})
    }
    //Saves Assets data
    if (getLocalStorageByKey(userAssetDetails)) {
      await postAssetsData(values)
    }
    //Saves Funds data
    if (getLocalStorageByKey(userFundDetails)) {
      await postFundsData(values)
    }
  }

  async function postFundsData(userData) {
    await addRemoveFundUserMutation.mutateAsync({
      funds: getStructuredFund(getLocalStorageByKey(userFundDetails).fundDetails)
      , authorisationId: userData.authorisationId
    })
  }

  async function postAssetsData(userData) {
    let userAssetDetailsObj = getLocalStorageByKey(userAssetDetails)
    if(userAssetDetailsObj && userAssetDetailsObj.isUnchanged) {
      return
    }
    let assetIdsObject = createAssetsIdsObject(userAssetDetailsObj.assetsDetails)
    let userAuthIdForAssets = userData.authorisationId
    const userAssetIdsObject = {userAuthId: userAuthIdForAssets, assetIds: assetIdsObject}
    if(isEditUserAction()){
      await updateAssetAccessMutation.mutateAsync(userAssetIdsObject)
    } else {
      await addAssetAccessMutation.mutateAsync(userAssetIdsObject)
    }
  }

  async function handleNewUser(userAssetGroupsObject,values) {
    //Saves Groups data
    if (userAssetGroupsObject.assetGroups.assetGroupIds.length > 0) {
      await addUserAssetGroupsMutation.mutateAsync(userAssetGroupsObject)
    }
    //Saves Assets data
    if (getLocalStorageByKey(userAssetDetails) && getLocalStorageByKey(userAssetDetails).assetsDetails.length > 0) {
      await postAssetsData(values)
    }
    //Saves Funds data
    if (getLocalStorageByKey(userFundDetails) && getLocalStorageByKey(userFundDetails).fundDetails.length > 0) {
      await postFundsData(values)
    }
  }

  const addUpdateAssetGroups = async () => {
    let errorMsgKey = MSG_KEY_TITLE_CANNOT_POST_DATA
    let assetGroupIdsObject = createUserAssetGroupsObject(selectedGroups)
    let userInfo= await getUserAuthId()//User is created, If it is a new user --returns user data including authorisationId
    if(userInfo.hasOwnProperty('userId') || isEditUserAction()){
      const userAssetGroupsObject={userId: userInfo.userId, assetGroups: assetGroupIdsObject}
      try {
        if (isEditUserAction()) {
          errorMsgKey = MSG_KEY_TITLE_CANNOT_PUT_DATA
          await handleEditUser(userAssetGroupsObject,userInfo)
          history.goBack()
        }else{
          await handleNewUser(userAssetGroupsObject,userInfo)
          history.push({pathname: pathToUsersPage, state: {...userInfo, isAddUser: true}})
        }
        await props.refetchUser()
      } catch (error) {
        const errorResponse = (error.response && error.response.data) ? `Error: ${error.response.data.title}` : error.toString()
        setErrorDialogData({title: I18n(errorMsgKey), message:errorResponse})
        setErrorDialogVisible(true)
      }
    }
  }

  const handleTabChange = () => {
    props.showTab(3)
  }

  function getChooseGroupsLabel() {
    let chooseAssetsLabel=I18n('t_choose_groups_for_new_user')
    if(isEditUserAction()){
      let userName=''
      if(props.userDetails && props.userDetails.userName){
        userName=props.userDetails.userName
      }
      chooseAssetsLabel=I18n('t_choose_groups_for_update_user', {0: userName})
    }
    return chooseAssetsLabel
  }

  return(
    <div className={'user-groups'} data-testid={'users-groups'}>
      <div className="flex justify-content-between groups">
        <div>{getChooseGroupsLabel()}</div>
        <div className="count-selection-container">
          <div className={`total-count count-status ${totalGroupsActiveClass}`} onClick={allGroupsHandler}>
            {groupsCount} {I18n('t_groups')}
          </div>
          <div className={`selected-count count-status ${memberGroupsActiveClass}`} onClick={selectedGroupsHandler}>
            {selectedGroups.length}
          </div>
          <div className={`selected-label count-status ${memberGroupsActiveClass}`} onClick={selectedGroupsHandler}>
            {I18n('t_selected')}
          </div>
        </div>
      </div>
      <div className="flex">
        <div className={'search-field-w-top'}>
          <SearchField placeholder={getLocalizedValue(selectedLanguage, 't_find_group')} searchValue={searchText}
            onChange={searchGroups} clearField={clearSearch} addRef={true}/>
        </div>
      </div>
      <div className='pt-4'>
        <Card className="groups-details">
          {isLoading ?
            <div className="groups-grid-before-load">
              <UsersGridSkeleton/>
            </div> :
            groupsGridData.length > 0 ?
              <DataTable
                value={groupsGridData}
                tableClassName={props.hasPermissionForEditUser ? null : 'disable-section'}
                className={'user-groups-grid user-groups-table'}
                onSelectionChange={onGroupsSelectionChange}
                selection={searchText==='' ? selectedGroups : filteredSelectedGroups}
                selectionMode="single"
                stripedRows
                scrollHeight='870px'
                scrollable
              >
                <Column field="name" header={I18n('t_name')} className={'name-column'} body={groupNameTemplate}/>
                <Column field="organisation" header={I18n('t_company')} className={'company-column'} body={companyNameTemplate}/>
                <Column field="createdDate" header={I18n('t_created')} className={'date-column'} body={createdDateTemplate}/>
                <Column field="assetCount" header={I18n('t_asset_s')} className={'assets-column'} body={assetsTemplate}/>
                <Column field="memberCount" header={I18n('t_members')} className={'members-column'} body={groupMembersTemplate}/>
                <Column selectionMode="multiple" className="selection-group" style={{width: '4.5%'}}/>
              </DataTable> :
              <div className="no-result-msg">
                <span className="no-result-text">{I18n('t_could_not_find_groups')}</span>
              </div>
          }
        </Card>
      </div>


      <div className={'footer-divider'}>
        <hr/>
      </div>
      <div className={'footer-container'}>
        <UserFooter
          submitHandler={()=> addUpdateAssetGroups(true)}
          handleNextTabBtnText="t_next_choose_assets"
          handleNextTab={()=> handleTabChange()}
          userAction={props.userAction}
          hasPermissionForEditUser={props.hasPermissionForEditUser}
        />
      </div>
      {errorDialogVisible && <ErrorDialog
        data={errorDialogData}
        dialogVisible={errorDialogVisible}
        onHideHandler={()=>setErrorDialogVisible(false)}
        closeHandler={()=>setErrorDialogVisible(false)}
      />}
    </div>
  )
}

export default UserGroups