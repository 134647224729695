import React, {createElement} from 'react'
import PropTypes from 'prop-types'
import {useIntl} from 'react-intl'
import './Text.scss'

const Text = ({
  content,
  contentDynamic,
  htmlTag,
  size,
  weight,
  colour,
  className,
  testId,
}) => {

  const intl = useIntl()

  const getTextCssClass = (size, weight, colour, className) => {
    return `${size} ${weight} ${colour} ${className}`.trim()
  }

  return (
    <>
      {
        createElement(
          htmlTag,
          {
            className: `siera-text ${getTextCssClass(size, weight, colour, className)}`, 
            'data-testid': testId
          },
          intl.messages.hasOwnProperty(content) ?
            intl.formatMessage({id: `${content}`}, contentDynamic) : 
            content
        )
      }
      
    </>
  )
}

Text.propTypes = {
  /** Most of the times we need to translate text on the app so we need to use the translation key as the content.<br />
   * Entire list of keys can be found here: src\resources\messages\en-GB.js.<br />
   * For scenarios where translation is not necessary (email addresses, asset/meter/fund/user names, etc) we just pass the raw data as the content.
   */
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]).isRequired,
  /**
   * Accepts an object with any number of items. <br /> 
   * The key/value pairs will then be inserted in the specified place in the string.
   */
  contentDynamic: PropTypes.object,
  /**
   * <em>htmlTag</em> is used purely for markup and not for styling, which has to be done with the <em>size</em>, <em>weight</em> and <em>colour</em> props. 
  */
  htmlTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'p', 'span', 'li']),
  /**
   * <em>xs</em> = 14px <br />  
   * <em>s</em> = 16px <br /> 
   * <em>m</em> = 18px <br /> 
   * <em>l</em> = 20px
  */
  size: PropTypes.oneOf(['xs', 's', 'm', 'l']),
  /**
   * <em>regular</em> = 400 <br /> 
   * <em>bold</em> = 700
  */
  weight: PropTypes.oneOf(['regular', 'bold']),
  /**
   * <em>teal</em> = $forest <br /> 
   * <em>faded_teal</em> = $forest_80 <br /> 
   * <em>red</em> = $red <br /> 
   * <em>green</em> = $green <br /> 
   * <em>orange</em> = $nature <br /> 
   * <em>blue</em> = $blue <br /> 
   * <em>deep_ocean</em> = $deep_ocean <br />
   * <em>white</em> = $white 
  */
  colour: PropTypes.oneOf(['teal', 'faded_teal', 'red', 'green', 'orange', 'blue', 'deep_ocean', 'white']),
  /**
   * To be used mostly with predefined utility classes for layout and spacing: https://primeflex.org/ and not for custom styling.
  */
  className: PropTypes.string,
  testId: PropTypes.string,
}

Text.defaultProps = {
  size: 'xs',
  weight: 'regular',
  colour: 'teal',
  className: '',
  htmlTag: 'span'
}

export default Text