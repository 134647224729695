import React from 'react'
import {useParams, NavLink} from 'react-router-dom'
import {createNavURLForFundPages, setAssetData} from '../../../utils/helpers/Helper'
import './HyperLink.scss'
import {LINK_TRANSFER} from '../../../utils/helpers/Constants'

const HyperLink = ({event, queryParams, children: content, pathSuffix,baseClass, assetName }) => {
  const { instanceName, orgName, fundName } = useParams()
  const searchQueryParams = queryParams ? `${queryParams}` : ''
  // LTS is added to the URL and will be used by the opening tab/window
  // to identify any data it might have to transfer if the link was
  // actioned by CTRL-LEFTCLICK or the context menu.
  const linkTimeStamp= Date.now()

  const setSessionTransferAssetData = (linkTimeStamp, assetName) => {
    localStorage.setItem(linkTimeStamp.toString(), assetName)
  }
  return (
    <NavLink
      exact={true}
      to={{
        state: event,
        pathname: createNavURLForFundPages({ instanceName, orgName, fundName, assetId: event?.assetId, pathSuffix: pathSuffix }),
        search: `utility=${searchQueryParams}&${LINK_TRANSFER.LINK_TIME_STAMP}=${linkTimeStamp}`
      }}
      className={baseClass ? `${baseClass}` : 'hyper-link'}
      onContextMenu={(e) => {
        setSessionTransferAssetData(linkTimeStamp, assetName)
      }}
      onClick={(e) => {
        if(e.ctrlKey) {
          setSessionTransferAssetData(linkTimeStamp, assetName)
        } else {
          setAssetData(assetName)
        }
      }}
    >
      {content}
    </NavLink>
  )
}

export default HyperLink