import React, {useState} from 'react'
import PropTypes from 'prop-types'
import './NzcModel.scss'
import {useFormik} from 'formik'
import {InputText} from 'primereact/inputtext'
import {getLocalizedValue} from '../../../../../utils/helpers/Helper'
import {Button} from 'primereact/button'
import I18n from '../../../../../utils/i18n/I18n'
import {InputTextarea} from 'primereact/inputtextarea'
import {DuplicateAssetNZCModel, DuplicateNZCModel} from '../../../../../services/funds/nzc-service'
import {useMutation} from 'react-query'
import { Checkbox } from 'primereact/checkbox'
import {hasModelNameValidationError, nzcModelNameValidator} from '../../../../common/net-zero-carbon/nzc-common-utils'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const NzcDuplicateModel = (props) => {
  const [defaultModelCheckbox, setDefaultModelCheckbox] = useState(false)
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const errorMessage = 'ModelName exists'
  const duplicateModel = useMutation(props.assetLevel ? DuplicateAssetNZCModel : DuplicateNZCModel,{
    onSuccess:(newScopeId)=>{
      //TODO
      props.duplicateSuccessModelCallback(newScopeId, defaultModelCheckbox)
    }
  })
  const formik = useFormik({
    initialValues: {
      name: props.NzcScopeName + ' copy',
      description : ''
    },
    validate: nzcModelNameValidator,
    onSubmit: async (data) => {
      const  scopeId = props.scopeId
      const modelInfo = {
        name: data.name,
        description: data.description,
        isDefault: defaultModelCheckbox,
        fundGroupId: !props.assetLevel ? props.fundGroupId : 0,
        assetId: !props.assetLevel ? 0 : props.assetId
      }
      await duplicateModel.mutateAsync({scopeId, modelInfo})
      formik.resetForm()
      props.onHideModel()
    }
  })
  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name])
  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <div className="p-error">{formik.errors[name]}</div>
  }
  const onDefaultCheckboxClicked = (e) =>{
    setDefaultModelCheckbox(e.checked)
  }
  const getDuplicateModelNameErrorMessage= () =>{
    return duplicateModel?.error?.response?.data === errorMessage && <div className="p-error">{I18n('t_model_with_same_name_exists')}</div>
  }
  const modelNameChangeHandler = (event)=>{
    duplicateModel.reset()
    formik.handleChange(event)
  }

  return (
    <div className={'nzc-model-content scrollbar-track-hide'}>
      <div className={'nzc-model-content__nzc-duplicate-model'}>
        <p data-testid='duplicate-info' className={'duplicate-model-para'}>{props.assetLevel ? I18n('t_asset_level_duplicate_model_para') : I18n('t_duplicate_model_para')}</p>
        <div className="nzc-model-content__nzc-duplicate-model--duplicate-model-input">
          <div className={'nzc-model-content__nzc-duplicate-model--duplicate-model-input--input-name'}>
            <div className="nzc-model-content__nzc-duplicate-model--duplicate-model-input--input-name--input-name-label">{I18n('t_name')}</div>
            <InputText
              value={formik.values.name}
              id='name'
              name='name'
              onChange={modelNameChangeHandler}
              className={isFormFieldValid('name') ? 'nzc-model-content__nzc-duplicate-model--duplicate-model-input--input-text-name nzc-model-content__nzc-duplicate-model--duplicate-model-input--input-error' : 'nzc-model-content__nzc-duplicate-model--duplicate-model-input--input-text-name'}
              onBlur={formik.handleBlur}
            />
            {getFormErrorMessage('name')}
            {getDuplicateModelNameErrorMessage()}
          </div>
          <InputTextarea
            value={formik.values.description}
            id='description'
            name='description'
            placeholder={getLocalizedValue(selectedLanguage, 't_notes')}
            className="nzc-model-content__nzc-duplicate-model--duplicate-model-input--input-text-notes"
            onChange={formik.handleChange}
            rows={5}
            cols={30}
            autoResize
          />
        </div>
      </div>
      <div className={'nzc-model-content__create-model-checkbox'}>
        <Checkbox inputId="default_checkbox"
          data-testid='duplicate-model-checkbox'
          checked ={defaultModelCheckbox}
          onChange={onDefaultCheckboxClicked}
        />
        <label htmlFor="default_checkbox">{I18n('t_set_as_default')}</label>
        <div data-testid='default-info' className='nzc-model-content__create-model-checkbox--nzc-checkbox-recommendation'><p>{props.assetLevel ? I18n('t_set_as_default_asset_info') : I18n('t_set_as_default_info')}</p>
        </div>
      </div>
      <div className={'border-line'} />
      <div className='padding-bottom-3'>
        <Button label={I18n('t_save')} data-testid="submit-button" onClick={formik.handleSubmit} className="common-blue-bg-btn open-button button-highlight" disabled={hasModelNameValidationError(formik)} />
      </div>
    </div>
  )
}

NzcDuplicateModel.propTypes = {
  assetLevel: PropTypes.bool,
  NzcScopeName: PropTypes.string.isRequired,
  duplicateSuccessModelCallback: PropTypes.func.isRequired,
  fundGroupId: PropTypes.number,
  assetId: PropTypes.number,
  onHideModel: PropTypes.func.isRequired,
}

NzcDuplicateModel.displayName = 'NzcDuplicateModel'

export default NzcDuplicateModel