import {memo, useEffect, useRef, useState} from 'react'
import Chart from 'chart.js/auto'
import PropTypes from 'prop-types'
import EventBar from '../eventds/EventBar'
import {createMonthLabelsForXAxis, getChartDataForSelectedKeys, getTooltipConfig, getUtilityConsumptionData, getYScaleTicksValue} from '../performance-hooks-&-helpers/performanceHelper'
import {getChartOptions} from '../performance-home/ChartHelper'
import PerformanceModalDialogHeader from '../dialogs/PerformanceModalDialogHeader'
import ModalDialogHome from '../../../../common/modal-dialogs/ModalDialogHome'
import {getHeaderForPerformanceModal, getMergeScopesDatasets, isScopeOrSplitSelected, mergeScopesDatasets, setMinBarLength} from '../../../../../utils/helpers/Helper'
import {ActionEventType} from '../performance-hooks-&-helpers/performanceConstants'
import I18n from '../../../../../utils/i18n/I18n'
import { useParams} from 'react-router-dom'
import {ScopeTypes, UtilityTypes} from '../../../../../utils/helpers/Constants'
import ChartLegend from '../../../../common/charts/performance-legend/ChartLegend'

const AssetPerformanceChart = ({apiData, actionHandlers, performanceStates}) => {

  const {assetId} = useParams()
  const [chartDataset, setChartDataset]=useState({yScaleTicks:{min: 0, max: 67, stepSize: 5}})
  const performanceChartRef = useRef()

  const {performanceDataMonthly, assetEventsData, assetCarbonPerformanceData, assetUtilityPerformanceData} = apiData
  const { refetchAssetEventsData, updatePerformanceState, refetchAssetCarbonPerformanceData, refetchAssetUtilityPerformanceData} = actionHandlers
  const { performanceState, monthlyPerformancesForChartEvents, isGapFillingSelected, showTargets, selectedLanguage, timePeriod} = performanceStates
  const hasTarget = getAssetUtilConsumptions()?.summary?.hasTarget

  useEffect(()=>{
    const assetUtilConsumptions=getAssetUtilConsumptions()
    if(assetUtilConsumptions){
      if(UtilityTypes.Carbon === performanceState?.selectedUtility?.name){
        const scopesKeyOrder = ['scope1', 'scope2', 'scope3']
        performanceState?.dataSelectionFilters?.sort((a, b) => scopesKeyOrder.indexOf(a.key) - scopesKeyOrder.indexOf(b.key))
      }

      const {
        datasetsMain,
        targetLineDatasets,
        utilConsumptionsDataForSelectedKeys,
        utilityConsumptionsAll,
        barStacksCount
      } = getChartDataForSelectedKeys(assetUtilConsumptions, performanceState.selectedUtility.name, performanceState?.dataSelectionFilters, false, selectedLanguage, isGapFillingSelected, timePeriod)

      const yScaleTicks= getYScaleTicksValue(utilConsumptionsDataForSelectedKeys, performanceState.selectedUtility.name, isGapFillingSelected)
      let chartData=[...datasetsMain]

      // code for merging scopes datasets to avoid faint separator line between stacks in bar chart.
      const mergedDatasetsContainer = []
      if(isScopeOrSplitSelected(performanceState?.dataSelectionFilters?.[0]?.key)){
        let datasetsNew =[...chartData]

        // merging of chart datasets is done per scope/split
        performanceState?.dataSelectionFilters?.forEach((scope) => {
          const selectedScopeDatasets = datasetsNew?.filter(dataset => scope.key === dataset.dataSelectionFilterKey) // get datasets only for selected scope/split
          if(selectedScopeDatasets?.length===4){
            mergeScopesDatasets(selectedScopeDatasets)
            mergedDatasetsContainer.push(...selectedScopeDatasets)
          } else {
            mergedDatasetsContainer.push(...getMergeScopesDatasets(selectedScopeDatasets))
          }
        })

        // use merged-datasets
        if(mergedDatasetsContainer?.length>0){
          chartData= mergedDatasetsContainer
        }
      }
      setChartDataset({chartDataSets: chartData, targetLineDatasets, yScaleTicks, utilityConsumptionsAll, barStacksCount})
    }
  }, [assetCarbonPerformanceData, assetUtilityPerformanceData, selectedLanguage, performanceState, performanceDataMonthly])

  useEffect(() => {
    if (chartDataset?.chartDataSets) {
      if (window.bar !== undefined) {
        window.bar.destroy()
      }
      if(window.pdfChart !== undefined){
        window.pdfChart.destroy()
      }

      const labels=getXAxisLabels() ?? []
      const options={...getChartOptions(showTargets || hasTarget, true, chartDataset.yScaleTicks, selectedLanguage, performanceState.selectedUtility, null, getTooltipConfig(selectedLanguage), false)}
      options.onClick=chartClickAction

      const chartDatasets={
        type: 'bar',
        data:{
          labels: labels,
          datasets:  chartDataset.chartDataSets,
        },
        options: options,
        targetLineDatasets: chartDataset.targetLineDatasets,
        isGapFillingSelected: isGapFillingSelected,
        utilityConsumptionsAll: chartDataset.utilityConsumptionsAll
      }

      setMinBarLength(chartDatasets, performanceState.dataSelectionFilters)
      const ctx = performanceChartRef.current?.getContext('2d')
      window.bar = new Chart(ctx, chartDatasets)
    }
  }, [chartDataset])

  function chartClickAction(evt) {
    const points = window.bar.getElementsAtEventForMode(evt, 'nearest', {intersect: true}, true)
    if (points.length) {
      const firstPoint = points[0]
      const targetLineDatasets=window.bar?.config?._config?.targetLineDatasets
      const targetLineData=targetLineDatasets[0]
      const barChartData=targetLineData?.[firstPoint?.index]
      if(ScopeTypes.all===barChartData.dataSelectionKey){
        addChartNoteHandler(evt, barChartData)
      }
    }
  }
  
  function noteDlgHideHandler(){
    updatePerformanceState({ showAddNoteDlg: false})
  }

  function getAssetUtilConsumptions() {
    return performanceState?.selectedUtility?.name === UtilityTypes.Carbon? assetCarbonPerformanceData: assetUtilityPerformanceData
  }

  function getXAxisLabels() {
    const assetUtilConsumptions=getAssetUtilConsumptions()
    const utilConsumptionsData=assetUtilConsumptions?.consumptions?.[ScopeTypes.all]
    return createMonthLabelsForXAxis(utilConsumptionsData?.length, getMonthlyPerformancesDataForSelectedUtil(), selectedLanguage)
  }

  function getMonthlyPerformancesDataForSelectedUtil() {
    const assetUtilConsumptions=getAssetUtilConsumptions()
    const utilConsumptionsData=assetUtilConsumptions?.consumptions?.[ScopeTypes.all]
    return getUtilityConsumptionData(utilConsumptionsData, utilConsumptionsData?.length)
  }

  const addChartNoteHandler = (e,data) => {
    const currentPerfVarianceEvents = data?.events?.filter(x => x.eventType === ActionEventType.PERFORMANCE_VARIANCE)
    const currentEventUtilityDependent = currentPerfVarianceEvents?.filter(x => x.content.find(y => y.key === 'UtilityType' && y.value === performanceState.selectedUtility.name))
    updatePerformanceState({
      showAddNoteDlg: !performanceState.showAddNoteDlg,
      yearMonth: data.year + ('0' + data.monthNumber).slice(-2),
      selectedEvent: currentEventUtilityDependent[0],
      events: data.events[0] ? currentEventUtilityDependent[0] : []
    })

  }

  const closeDialogEv = (visibilityStatus) => {
    updatePerformanceState({ showAddNoteDlg: visibilityStatus, selectedEvent: null})
  }

  function getPerformanceModalHeader() {
    return (performanceDataMonthly && getAssetUtilConsumptions())? getHeaderForPerformanceModal(performanceDataMonthly, showTargets, isGapFillingSelected):null
  }

  const reLoad = () => {
    refetchAssetEventsData()
    performanceState?.selectedUtility?.name === UtilityTypes.Carbon ? refetchAssetCarbonPerformanceData() : refetchAssetUtilityPerformanceData()
  }

  return (
    <div className='performance-chart-view'>
      {!getAssetUtilConsumptions() || (chartDataset?.chartDataSets?.length <= 0) ?
        <div className="no-chart-data-message">
          <span>{I18n('t_msg_no_data')}</span>
        </div>
        :
        <>
          <div className='col-12 utility-performance-chart'>
            <canvas id="asset-performance-chart" ref={performanceChartRef} />
          </div>
          <EventBar
            assetId={assetId}
            monthlyPerformancesForChartEvents={monthlyPerformancesForChartEvents}
            timePeriod={getAssetUtilConsumptions()?.consumptions?.[ScopeTypes.all]?.length}
            noteDlgHideHandler={noteDlgHideHandler}
            selectedUtilityName={performanceState.selectedUtility.name}
            meterSummaryData={performanceState.meterSummary}
            assetPerformanceData={getAssetUtilConsumptions()?.consumptions?.[ScopeTypes.all]}
            showTarget={showTargets}
            isGapFillingSelected={isGapFillingSelected}
            selectedLanguage={selectedLanguage}
            assetEventsData={assetEventsData}
            reLoad={reLoad}
          />
          <div className='col-12 barColors-section'>
            {chartDataset?.chartDataSets &&
                <ChartLegend
                  selectedLanguage={selectedLanguage}
                  targetCalculatedAgainstPreviousPeriod={(hasTarget || showTargets)}
                  selectedUtility={performanceState?.selectedUtility?.name}
                  dataSelectionFilters={performanceState?.dataSelectionFilters}
                  isGapFillingSelected={isGapFillingSelected}
                />
            }
          </div>
        </>
      }
      <ModalDialogHome
        assetId={assetId}
        showDialog={performanceState.showAddNoteDlg}
        header={<PerformanceModalDialogHeader header={getPerformanceModalHeader()} />}
        meterSummaryData = {performanceState.meterSummary}
        activeTabIndex={0}
        reLoad={reLoad}
        dlgActionCleanupHandler={null}
        edit={true}
        eventsList={performanceState.events}
        selection={performanceState.selectedEvent}
        refetchData={refetchAssetEventsData}
        utility={performanceState.selectedUtility.name}
        yearMonth={performanceState.yearMonth}
        onEventActionHideHandler={closeDialogEv}
      />
    </div>
  )
}

AssetPerformanceChart.displayName = 'AssetPerformanceChart'

AssetPerformanceChart.propTypes = {
  apiData:PropTypes.shape({
    assetPerformanceData: PropTypes.array,
    performanceDataMonthly: PropTypes.object,
    assetEventsData: PropTypes.object,
    performanceDataMonthlyIsLoading:PropTypes.bool,
    assetCarbonPerformanceData: PropTypes.object,
    assetUtilityPerformanceData: PropTypes.object,
  }),
  performanceStates:PropTypes.shape({
    performanceState: PropTypes.object,
    monthlyPerformancesForChartEvents: PropTypes.object,
    isGapFillingSelected: PropTypes.bool,
    showTargets: PropTypes.bool,
    selectedLanguage: PropTypes.string,
    timePeriod: PropTypes.string
  }),
  actionHandlers:PropTypes.shape({
    refetchAssetEventsData: PropTypes.func,
    updatePerformanceState: PropTypes.func,
    refetchAssetCarbonPerformanceData: PropTypes.func,
    refetchAssetUtilityPerformanceData: PropTypes.func,
  })
}
export default memo(AssetPerformanceChart)