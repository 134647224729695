import {memo} from 'react'
import I18n from '../../../utils/i18n/I18n'
import downArrowRedIcon from '../../../resources/images/icon/down-arrow-red.svg'
import downArrowGreenIcon from '../../../resources/images/icon/down-arrow-green.svg'
import upArrowGreenIcon from '../../../resources/images/icon/up-arrow-green.svg'
import upArrowRedIcon from '../../../resources/images/icon/up-arrow-red.svg'
import {roundAndFormattedVarianceWithOneDP} from '../../../services/common/formatter'
import {UtilityTypes} from '../../../utils/helpers/Constants'
import PropTypes from 'prop-types'
import {Icon} from '../../atomic'

const FeedbackRatingV3 = (props) => {
  const variance=props.variance * 100
  const utility=props.utility
  const isTarget=props.target

  const getCssColorClass = () => {
    let className
    const varianceDefaultColorClass='var-default'
    if(variance !== undefined) {
      if (UtilityTypes.RecycledWaste !== utility) {
        className = variance > 0 ? 'red' : (variance < 0 ? 'green' : varianceDefaultColorClass)
      } else {
        className = variance > 0 ? 'green' : (variance < 0 ? 'red' : varianceDefaultColorClass)
      }
    }

    if(variance === 0) {
      className = varianceDefaultColorClass
    }

    return className
  }

  const getUtilityConsumptionText = () => {
    let description = ''
    if (typeof (variance) === 'number' && variance !== 0) {
      description = variance > 0 ? I18n('t_above') : I18n('t_below')
    }

    if(variance === 0){
      description = I18n('t_variance')
    }

    return description
  }

  const getPerformanceRatingIcon = () => {
    let icon
    if (UtilityTypes.RecycledWaste !== utility) {
      icon = variance > 0 ? upArrowRedIcon : downArrowGreenIcon
    } else {
      icon = variance > 0 ? upArrowGreenIcon : downArrowRedIcon
    }

    return icon
  }

  const getTargetOrPreviousIconColorAndId = () => {
    let id=isTarget?'target': 'calendar'
    let colour
    if (isTarget) {
      if (UtilityTypes.RecycledWaste !== utility) {
        colour = variance > 0 ? 'red' : 'green'
      } else {
        colour = variance > 0 ? 'green' : 'red'
      }
    } else if (UtilityTypes.RecycledWaste !== utility) {
      colour = variance > 0 ? 'red' : 'green'
    } else {
      colour = variance > 0 ? 'green' : 'red'
    }
    if (variance === 0) {
      colour = 'forest'
    }
    return {id, colour}
  }

  return (
    <>
      <div className={`utility-consumption-rating-v3 ${getCssColorClass()}`} data-testid={'utility-consumption-rating'}>
        <Icon {...getTargetOrPreviousIconColorAndId()} size={15} />
        <span className={`rating-text ${getCssColorClass()}`}>
          {typeof (variance) === 'number' ? `${roundAndFormattedVarianceWithOneDP(Math.abs(variance))}% ` : '-'}{getUtilityConsumptionText()}
        </span>
        { variance !== 0 && <img src={getPerformanceRatingIcon()} className={'perf-icon'} alt={'feedback-icon'} /> }
      </div>
    </>
  )
}

FeedbackRatingV3.propTypes = {
  utility: PropTypes.string,
  variance: PropTypes.number,
  target: PropTypes.bool,
}
FeedbackRatingV3.displayName = 'FeedbackRatingV3'
export default memo(FeedbackRatingV3)