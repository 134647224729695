import PropTypes from 'prop-types'
import PassportActionsGridSkeleton from '../../../../common/skeletons/passport-grid-skeleton/PassportActionsGridSkeleton'
import I18n from '../../../../../utils/i18n/I18n'
import {DataTable} from 'primereact/datatable'
import {
  CurrencyUnitToLabel, formatUnits, getPaginatorTemplate, unitDateFormatter, unitsConverter
} from '../../../../../utils/helpers/Helper'
import {Column} from 'primereact/column'
import {areaCoveredMask, costMask} from '../../../../../utils/helpers/Constants'
import {formatNumWTwoDecimal, trucateNumWTwoDecimal} from '../../../../../services/common/formatter'
import {FeatureFlagContext} from 'Contexts'
import {useContext} from 'react'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

function PassportTable({isLoading,pState,onPageChange}){
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const unitSystem = userInfo.unitSystem
  const currencyUnit = userInfo.currencyUnit
  const featureContext = useContext(FeatureFlagContext)
  const featureState = featureContext.featureState
  const impactTemplate = (rowData) => {
    return <div className="txt-impact">{rowData.action.impact}</div>
  }
 
  const scopeTemplate = (rowData) => {
    function getScope(){
      let scope = '-'
      if(rowData.action.completedActionsScopes?.length > 0) {
        scope = rowData.action.completedActionsScopes?.join(', ')
      }
      return scope
    }
    const scope = featureState?.sieraplus_assetActionPlan ? getScope() : areaCoveredMask[rowData.action.scope]
    return <div className="txt-scope">{scope}</div>
  }

  const actionTemplate = (rowData) => {
    return <div className="txt-action">{rowData.action.actionDescription}</div>
  }

  const targetTemplate = (rowData) => {
    let tarPer = '-'
    let target = ''
    if(rowData.action.targetUsagePercentage) {
      tarPer = formatNumWTwoDecimal(selectedLanguage, trucateNumWTwoDecimal(rowData.action.targetUsagePercentage)) + '%'
    }
    if(rowData.action.target) {
      target = formatNumWTwoDecimal(selectedLanguage, trucateNumWTwoDecimal(rowData.action.target))
    }
    let colorText = 'grey-text'
    if (rowData.action.targetUsagePercentage > 5) {
      colorText = 'red-text'
    } else if (rowData.action.targetUsagePercentage < 5) {
      colorText = 'green-text'
    }

    let unit = ''
    let impactType = rowData.action.impact
    if(impactType === 'Energy' || impactType === 'Water') {
      unit = unitsConverter(rowData.action.impact, unitSystem)
    }

    function getTarget(){
      return unit ? <>({target} {unit})</>: <>({target})</>
    }
    return (
      <div className="grid">
        <div className="col-12 no-padding"/>
        <div className={`txt-reduction col-12 ${tarPer === '-' ? 'no-padding' : 'no-padding-bottom'} ${colorText}`}>{tarPer}</div>
        {target !== '0' &&
          <div className={`txt-reduction col-12 ${target === '' ? 'no-padding' : 'no-padding-top'}`}>{target === '' ? target : getTarget()}</div>
        }
      </div>
    )
  }

  const costTemplate = (rowData) => {
    let costValue = '-'
    let currencyLabel
    if(featureState?.sieraplus_currency){
      currencyLabel = costMask[CurrencyUnitToLabel(currencyUnit)]
    } else {
      currencyLabel = costMask[rowData.action.currency]
    }
    if(rowData.action.cost) {
      if(isNaN(parseFloat(rowData.action.cost))) {
        costValue = rowData.action.cost
      } else {
        costValue = `${currencyLabel}
                ${formatNumWTwoDecimal(selectedLanguage, trucateNumWTwoDecimal(rowData.action.cost))}`
      }

    }
    return <div className="txt-cost">{costValue}</div>
  }

  const dateTemplate = (rowData) => {
    const date = unitDateFormatter(rowData.action.completion, unitSystem, selectedLanguage)

    return <div className="txt-date">{date}</div>
  }

  function netChangeTemplate(rowData){
    let estimatedChange = '-'
    if (rowData.action?.completedActionsNetChange) {
      estimatedChange = formatUnits(rowData.action?.completedActionsNetChange,selectedLanguage) + unitsConverter(rowData.action.impact,unitSystem ) + '/yr'
    }
    return <span className="txt-date">{estimatedChange}</span>
  }

  function getPassportTable(){
    function getNoDataMessage(){
      return <div className="no-result-msg" style={{margin: '1em 0'}}>
        <span className="no-result-text">{I18n('t_no_results')}</span>
      </div>
    }

    function getTable(){
      return <DataTable value={pState.passportData}
        className="p-datatable-gridlines p-datatable-striped completed-action-table"
        paginator={pState.passportData?.length>20} first={pState.first} rows={pState.rows}
        paginatorTemplate={getPaginatorTemplate('items-per-page-options-white')}
        paginatorClassName={'data-table-paginator'} onPage={e=> onPageChange(e)}
        totalRecords={pState.passportData?.length}>
        <Column field="date" header={I18n('t_date')} body={dateTemplate}
          headerClassName="table-header-style no-padding-left"
          bodyClassName="table-body-style"
          style={{'width': '9%', textAlign: 'center'}}
        />
        <Column field="action" header={I18n('t_action')}
          body={actionTemplate} headerClassName="table-header-style"
          bodyClassName="table-body-style"
          style={{'width': '30%', paddingLeft: '20px'}
          }/>
        <Column field="impact" header={I18n('t_impact')}
          body={impactTemplate} headerClassName="table-header-style no-padding-left"
          bodyClassName="table-body-style"
          style={{'width': '10%', textAlign: ''}}
        />
        <Column field="target" header={featureState?.sieraplus_assetActionPlan ? I18n('t_net_change') : I18n('t_reduction')}
          body={featureState?.sieraplus_assetActionPlan ? netChangeTemplate : targetTemplate} headerClassName="table-header-style"
          bodyClassName="table-body-style"
          style={{'width': '10%', textAlign: 'center'}}
        />
        <Column field="cost" header={I18n('t_cost')}
          body={costTemplate} headerClassName="table-header-style"
          bodyClassName="table-body-style"
          style={{'width': '10%', textAlign: 'center'}}
        />
        <Column field="scope" header={I18n('t_scope')}
          body={scopeTemplate} headerClassName="table-header-style"
          bodyClassName="table-body-style"
          style={{'width': '10%', textAlign: 'center'}}
        />
      </DataTable>


    }
    return pState.noData ? getNoDataMessage() : getTable()
  }

  return(
    <>
      {isLoading ?
        <div className="completedActions-grid-before-load">
          <PassportActionsGridSkeleton/>
          <PassportActionsGridSkeleton/>
          <PassportActionsGridSkeleton/>
        </div> : getPassportTable()
      }
    </>
  )
}

export default PassportTable
PassportTable.displayName = 'PassportTable'

PassportTable.propTypes = {
  isLoading: PropTypes.bool,
  pState: PropTypes.object,
  onPageChange: PropTypes.func
}