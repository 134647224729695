import React from 'react'
import {getLocalizedValue, getLoggedInUserRole, hasPermission} from '../../../../utils/helpers/Helper'
import './HeaderContainer.scss'
import SearchField from '../../../common/search/SearchField'
import {Button} from 'primereact/button'
import {UserActions} from '../../../../utils/helpers/Constants'
import PropTypes from 'prop-types'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'
function HeaderContainer(props) {
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference

  function searchItemHandler(e) {
    props.searchHandler(e)
  }

  function addItemHandler(e) {
    props.addItemHandler(e)
  }

  function clearSearchField(){
    props.clearField()
  }

  return (
    <div className='header-container grid'>
      <div className='col-12'>
        <div className='col-3 header-title-section'>
          <span data-testid={'header-title'} className='header-title'>{getLocalizedValue(selectedLanguage, props?.title)}</span>
        </div>
        <div className='col-9 header-filter-section'>
          <div className="admin-Search">
            <SearchField placeholder={getLocalizedValue(selectedLanguage, props.placeholder)} addRef={true}
              searchValue={props.searchValue} onChange={searchItemHandler} clearField={clearSearchField}/>
          </div>
          <div data-testid={'add-group'} className={'add-item-button'} style={{display: (hasPermission(UserActions.ADD_GROUP, getLoggedInUserRole())) ? 'block' : 'none'}}>
            <Button
              label={getLocalizedValue(selectedLanguage, props.addButtonLabel)}
              className="add-item"
              onClick={(e) => addItemHandler(e)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

HeaderContainer.propTypes = {
  title: PropTypes.string
}

HeaderContainer.displayName = 'HeaderContainer'

export default HeaderContainer