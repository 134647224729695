const queryValues = new URLSearchParams(new URL(window.location.href).search)
export const portfolioStateInit = {
  data: {},
  colField: 'Electricity',
  colFieldFund: 'carbonPercentage',
  filterState: {
    searchTerm: queryValues.get('asset') === null ? '' : queryValues.get('asset'),
    sortByField: 'Electricity',
    sortByFieldOrder: 1,
    sortByParameter: 'Electricity',
    sortEnableIcon: false,
    portfolioTableExpanded: false,
    portfolioTableAutoExpand: false,
    filterFundSelected: queryValues.get('fund') === null ? 'All' : queryValues.get('fund'),
    filterLocationSelected: queryValues.get('location') === null ? 'All' : queryValues.get('location'),
    filterSectorSelected: queryValues.get('type') === null ? 'All' : queryValues.get('type'),
    filterEsScoreSelected: queryValues.get('esScoreRange') === null ? 'All' : queryValues.get('esScoreRange'),
    filterOrder : queryValues.get('filterOrder') === null ? '0000' : queryValues.get('filterOrder')
  },
  showTarget: false
}

export const portfolioReducer = (state, action) => {
  let updatedState = {
    data: {...state.data, ...action.data},
    colField: action.colField,
    colFieldFund: action.colFieldFund,
    filterState: {...state.filterState, ...action.filterState},
    showTarget: action.showTarget
  }
  switch (action) {
  default:
    return {
      ...updatedState
    }
  }
}