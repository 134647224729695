import axiosInstance from '../common/axios-config'
import {CUSTOM, PERIOD_TYPES, PORTFOLIO_MINIMUM_DATA_SET_SIZE, UTILITY_OTHER_NAME_MAP} from '../../utils/helpers/Constants'
import {createEndMonthParam, encodeSieraURL} from '../../utils/helpers/Helper'
import {getTimeperiodLabel} from '../common/datepicker-service'

export const GetPortfolioData = async (keys) => {
  const timePeriod = keys.queryKey[1]
  const noOfMonths = keys.queryKey[2]
  const endDate = keys.queryKey[3]

  if(timePeriod === null) {
    return
  }

  if (!noOfMonths || !endDate) {
    const {data} = await axiosInstance.get(`/api/v1/PortfolioView?period=${timePeriod}`)
    return data
  } else {
    const {data} = await axiosInstance.get(`/api/v1/PortfolioView?period=${timePeriod}&noOfMonths=${noOfMonths}&endMonth=${endDate}`)
    return data
  }
}

export const GetAssetHeaderData = async (keys) => {
  const queryKeys = keys.queryKey
  const {data} = await axiosInstance.get(`/api/v1/Assets/${queryKeys[1]}/AssetHeader`)
  return data
}

export const getEPCRating = async (keys) => {
  const queryKeys = keys.queryKey
  const {data} = await axiosInstance.get(`api/v1/Assets/${queryKeys[1]}/EPCRating`)
  return data
}

export const GetPortfolioViewExportData = async (keys) => {
  const {paginationParameters} = keys.queryKey[1]
  let { sortField, sortOrder, fund, location, sector, searchTerm } = paginationParameters
  fund=encodeSieraURL(fund)
  searchTerm=encodeSieraURL(searchTerm)

  const {data} = await axiosInstance.get(`api/v1/PortfolioView/ExportData?sortField=${sortField}&sortOrder=${sortOrder}&fund=${fund}&location=${location}&sector=${sector}&SearchTerm=${searchTerm}`)
  return data
}

export const GetPortfolioDataPaginated = async (keys) => {
  let data
  const {paginationParameters, period, noOfMonths, endDate, expandedTable} = keys.queryKey[1]
  const queryKeys = keys.queryKey  

  if(!queryKeys || queryKeys.length === 0) {
    return
  }

  let performingPageSize = PORTFOLIO_MINIMUM_DATA_SET_SIZE / 2

  const paginationParametersBestPerforming =  { ...paginationParameters, pageSize: performingPageSize}
  const paginationParametersWorstPerforming = {...paginationParameters, pageSize: performingPageSize, sortOrder: (paginationParameters.sortOrder === 'asc' ? 'desc': 'asc')}

  let expandedQueryString = Object.keys(paginationParameters).map(key => key + '=' + paginationParameters[key]).join('&')
  let bestPerformingQueryString = Object.keys(paginationParametersBestPerforming).map(key => key + '=' + paginationParametersBestPerforming[key]).join('&')
  let worstPerformingQueryString = Object.keys(paginationParametersWorstPerforming).map(key => key + '=' + paginationParametersWorstPerforming[key]).join('&')

  if(!expandedTable) {
    let dataBestFive
    let dataWorstFive
    if(!noOfMonths || !endDate) {
      dataBestFive = await axiosInstance.get(`/api/v1/PortfolioView?${bestPerformingQueryString}&period=${period}`)
      dataWorstFive = await axiosInstance.get(`/api/v1/PortfolioView?${worstPerformingQueryString}&period=${period}`)
    } else {
      dataBestFive = await axiosInstance.get(`/api/v1/PortfolioView?${bestPerformingQueryString}&period=${period}&noOfMonths=${noOfMonths}&endMonth=${endDate}`)
      dataWorstFive = await axiosInstance.get(`/api/v1/PortfolioView?${worstPerformingQueryString}&period=${period}&noOfMonths=${noOfMonths}&endMonth=${endDate}`)
    }

    data = await Promise.all([dataBestFive, dataWorstFive]).then((values) => {
      return values
    })
  } else {
    if (!noOfMonths || !endDate) {
      data = await axiosInstance.get(`/api/v1/PortfolioView?${expandedQueryString}&period=${period}`)
    } else {
      data = await axiosInstance.get(`/api/v1/PortfolioView?${expandedQueryString}&period=${period}&noOfMonths=${noOfMonths}&endMonth=${endDate}`)
    }
  }

  return data
}

export async function getPortfolioViewDataV3(keys) {
  const queryKeys = keys.queryKey
  if(!queryKeys || queryKeys.length === 0) {
    return
  }
  const  [, timePeriod, pickerValue, paginationParams]=queryKeys

  let expandedQueryString = Object.keys(paginationParams).map(key => {
    return key + '=' + encodeSieraURL(paginationParams[key])
  }).join('&')

  let apiQueryParams
  if (timePeriod === PERIOD_TYPES.CUSTOM) {
    const timeEndDate = createEndMonthParam(pickerValue.pickerValueEndYear, pickerValue.pickerValueEndMonth)
    apiQueryParams = `period=${CUSTOM}&noOfMonths=${pickerValue.pickerValueMonths}&endMonth=${timeEndDate}`
  } else {
    apiQueryParams = `period=${getTimeperiodLabel(timePeriod)}`
  }
  apiQueryParams = `${expandedQueryString}&${apiQueryParams}`

  return await axiosInstance.get(`/api/v1/PortfolioView?${apiQueryParams}`)
}

// current
export const GetUserDropdownFilters = async (keys) => {
  if(keys.queryKey[1] === null )
    return null

  let data
  if((keys.queryKey[1] || keys.queryKey[2]) && !keys.queryKey[1].isEnergyStarOptions) {
    const {fund, location, type, esScoreRange} = keys.queryKey[1]
    const fundRank = keys.queryKey[2].fund ?? 0
    const locationRank = keys.queryKey[2].location ?? 0
    const typeRank = keys.queryKey[2].type ?? 0
    const rangesRank = keys.queryKey[2].esScore ?? 0
    data = await axiosInstance.get(`/api/v1/PortfolioView/FilterOptions?Fund.value=${encodeSieraURL(fund)}&Fund.order=${fundRank}&Location.value=${location}&Location.order=${locationRank}&Type.value=${type}&Type.order=${typeRank}&Ranges.value=${esScoreRange}&Ranges.order=${rangesRank}`)
    return data
  } else {
    data = await axiosInstance.get(`/api/v1/PortfolioView/FilterOptions?isEnergyStarOptions=${keys.queryKey[1].isEnergyStarOptions}`)
    return data
  }
}

function getDefaultValue(value) {
  return value === 'All' ? '' : value
}

export const getPortfolioFilterOptionsV3 = async (keys) => {
  if (keys.queryKey[1] === null) {
    return null
  }

  let apiQueryParams
  const isEnergyStarOptions = keys.queryKey?.[1].isEnergyStarOptions
  if (isEnergyStarOptions) {
    apiQueryParams = `isEnergyStarOptions=${isEnergyStarOptions}`
  } else {
    const {
      filterFundSelected: fund,
      filterLocationSelected: location,
      filterSectorSelected: type,
      filterEsScoreSelected: esScoreRange,
      fundOrder,
      locationOrder,
      typeOrder,
      esScoreOrder
    } = keys?.queryKey?.[1] ?? {}
    apiQueryParams = `Fund.value=${encodeSieraURL(getDefaultValue(fund))}&Fund.order=${fundOrder}&Location.value=${getDefaultValue(location)}&Location.order=${locationOrder}&Type.value=${getDefaultValue(type)}&Type.order=${typeOrder}&Ranges.value=${getDefaultValue(esScoreRange)}&Ranges.order=${esScoreOrder}`
  }

  return await axiosInstance.get(`/api/v1/PortfolioView/FilterOptions?${apiQueryParams}`)
}

export const GetAssetPerformanceExportData = async (keys) => {
  if (keys.queryKey[1]) {
    let {
      fundSelected,
      locationSelected,
      sectorSelected,
      searchTerm,
      selectedUtility,
      time,
      noOfMonths,
      endDate,
      time_period_granularity,
      endPointPath
    } = keys.queryKey[1]

    fundSelected = fundSelected === UTILITY_OTHER_NAME_MAP.All ? '' : fundSelected
    locationSelected = locationSelected === UTILITY_OTHER_NAME_MAP.All ? '' : locationSelected
    sectorSelected = sectorSelected === UTILITY_OTHER_NAME_MAP.All ? '' : sectorSelected
    const portFolioEndPointName = endPointPath === 'PortfolioExportDataForMeters' ? 'PerformanceExports' : 'PortfolioView'
    if (!noOfMonths || !endDate) {
      return await axiosInstance.get( `api/v1/${portFolioEndPointName}/${endPointPath}?Fund=${fundSelected}&Location=${locationSelected}&Sector=${sectorSelected}&SearchTerm=${searchTerm}&Period=${time}&utility=${selectedUtility}&MonthlyData=${time_period_granularity}`)
    }
    else{
      return await axiosInstance.get( `api/v1/${portFolioEndPointName}/${endPointPath}?Fund=${fundSelected}&Location=${locationSelected}&Sector=${sectorSelected}&SearchTerm=${searchTerm}&Period=${time}&NoOfMonths=${noOfMonths}&EndMonth=${endDate}&utility=${selectedUtility}&MonthlyData=${time_period_granularity}`)
    }
  }
}

export const GetAssetDataQualityExportData = async (keys) => {
  if (keys.queryKey[1]) {
    let {
      fundSelected,
      locationSelected,
      sectorSelected,
      searchTerm,
      selectedUtility,
      time,
      noOfMonths,
      endDate
    } = keys.queryKey[1]

    fundSelected = fundSelected === UTILITY_OTHER_NAME_MAP.All ? '' : fundSelected
    locationSelected = locationSelected === UTILITY_OTHER_NAME_MAP.All ? '' : locationSelected
    sectorSelected = sectorSelected === UTILITY_OTHER_NAME_MAP.All ? '' : sectorSelected

    return await axiosInstance.get(`api/v1/FundDataQuality/PortfolioDataQualityExportData?Fund=${fundSelected}&Location=${locationSelected}&Sector=${sectorSelected}&SearchTerm=${searchTerm}&Period=${time}&NoOfMonths=${noOfMonths}&EndMonth=${endDate}&Skip=0&utility=${selectedUtility}`)
  }
}

export const GetAssetDataQualityExportDataV2 = async (keys) => {
  if (keys.queryKey[1]) {
    let {
      fundSelected,
      locationSelected,
      sectorSelected,
      searchTerm,
      time,
      noOfMonths,
      endDate
    } = keys.queryKey[1]

    fundSelected = fundSelected === UTILITY_OTHER_NAME_MAP.All ? '' : fundSelected
    locationSelected = locationSelected === UTILITY_OTHER_NAME_MAP.All ? '' : locationSelected
    sectorSelected = sectorSelected === UTILITY_OTHER_NAME_MAP.All ? '' : sectorSelected
    const {data} = await axiosInstance.get(`api/v1/PortfolioView/DataQualityExportData?Fund=${fundSelected}&Location=${locationSelected}&Sector=${sectorSelected}&SearchTerm=${searchTerm}&Period=${time}&NoOfMonths=${noOfMonths}&EndMonth=${endDate}`)
    return data
  }
}

export const GetAssetActionPlanExportData = async (keys) => {
  if (keys.queryKey[1]) {
    let {
      fundSelected,
      locationSelected,
      sectorSelected,
      searchTerm,
      time,
      noOfMonths,
      endDate
    } = keys.queryKey[1]

    fundSelected = fundSelected === UTILITY_OTHER_NAME_MAP.All ? '' : fundSelected
    locationSelected = locationSelected === UTILITY_OTHER_NAME_MAP.All ? '' : locationSelected
    sectorSelected = sectorSelected === UTILITY_OTHER_NAME_MAP.All ? '' : sectorSelected
    return await axiosInstance.get(`api/v1/PortfolioView/ActionPlanExportData?Fund=${fundSelected}&Location=${locationSelected}&Sector=${sectorSelected}&SearchTerm=${searchTerm}&Period=${time}&NoOfMonths=${noOfMonths}&EndMonth=${endDate}&Skip=0`)
  }
}

export const GetAssetNameDetails = async (keys) => {
  const assetName = keys.queryKey[1]
  let validAssetName
  if (assetName) {
    const {data} = await axiosInstance.get(`api/v1/Assets/AssetValidations?AssetName=${assetName}&ValidationFieldName=AssetName`)

    validAssetName = data 
  }
  return validAssetName
}

export const GetAssetReferenceDetails = async (keys) => {
  const assetReference = keys.queryKey[1]
  let validAssetReference

  if (assetReference) {
    const response = await axiosInstance.get(`api/v1/Assets/AssetValidations?AssetReference=${assetReference}&ValidationFieldName=AssetReference`)
    validAssetReference = {
      data: response.data,
      responseType: response.data.responseType, // Add responseType to the return object
    }
  }

  return validAssetReference
}