import React, {useContext, useEffect, useState} from 'react'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import './MissingDataTable.scss'
import RequestDataDialog from '../../../performance/dialogs/RequestDataDialog'
import I18n, { useI18n } from 'utils/i18n/I18n'
import {FeatureFlagContext, PortfolioContext} from 'Contexts'
import {
  AREA,
  DATA_QUALITY_KEYS,
  MASK_DATA_QUALITY_KEYS,
  TOP
} from '../../../../../../utils/helpers/Constants'
import {
  createMissingDataReqObject,
  formatUnits,
  getLocalizedValue,
  unitsConverter
} from '../../../../../../utils/helpers/Helper'
import { useParams } from 'react-router-dom'
import CSS_VARIABLES from '../../../../../../resources/css/_variables.scss'
import { Tooltip } from 'primereact/tooltip'
import {isTextOverflown} from '../../../../../../utils/UtilsFunctions'
import {ConsumptionOperations} from '../table-footer/ConsumptionOperations'
import {
  addNewMissingDataRequest,
  addNewMissingDataRequestInternal,
  DeleteConsumption,
} from '../../../../../../services/assets/dataquality-service'
import {useMutation} from 'react-query'
import useErrorHandling from '../../../../../common/error-handling/ErrorHandling'
import {
  getSortIcons,
  getUtilConsumptionTemplate,
  utilSubmittedByTemplate,
  utilTextTemplate
} from '../../../../../../utils/helpers/DataTableUtils'
import CustomAvatar from '../../../../../common/custom-avatar/CustomAvatar'
import ConsumptionNavigator from '../../consumption/ConsumptionNavigator'
import PropTypes from 'prop-types'
import DataQualityMissingDataSkeleton
  from '../../../../../common/skeletons/data-quality-missing-data-skeleton/DataQualityMissingDataSkeleton'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

function MissingDataTable(props) {

  //region Declarations/Initializations
  const i18n = useI18n()
  const [selectedData, setSelectedData] = useState([])
  const [showRequestDataDlg, setShowRequestDataDlg] = useState(false)
  const qtrThisLabelMskKey = 't_this_quarter'
  const pfContext = useContext(PortfolioContext)
  const rowDataCtx = pfContext.pfState.data
  const {assetId} = useParams()
  const [qtrTextLabel]=useState(I18n(qtrThisLabelMskKey))
  const MSG_KEY_TITLE_CANNOT_REQUEST_DATA = 't_title_cannot_request_data'
  const CLASS_BTN_DISABLE='btn-disable'
  const NO_SPACE = ''
  const [reFetchData, setReFetchData] = useState(false)
  const [sortedData, setSortedData] = useState(props.missingDataList)
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const unitSystem = userInfo.unitSystem
  const featureContext = useContext(FeatureFlagContext)
  const featureState = featureContext.featureState?.sieraplus_consumptionOperations
  const [isSidebarVisible,setIsSidebarVisible]=useState({
    isAddConsumptionBar: false,
    isEditConsumptionBar: false,
    isRequestConsumptionBar: false,
  })
  const [emailAddresses, setEmailAddresses] = useState([])
  const [showRequestConfirmation, setShowRequestConfirmation] = useState(false)
  const deleteConsumption = useMutation(DeleteConsumption)
  const { errorDialog, ShowErrorDialog, errorDialogVisible } = useErrorHandling()
  const [sortOrder,setSortOrder] = useState(1)
  const sortIconToggle = sortOrder === 1 ? 'sort-down': 'sort-up'
  const sortingOrder = (e) => setSortOrder(e.sortOrder)
  const [refetched,setRefetched] = useState(false)

  //endregion

  //region useEffect Implementation
  useEffect(() => {
    if (reFetchData) {
      props.refetch()
      setSelectedData([])
      setReFetchData(false)
    }
    setSortedData(props.missingDataList)
    setIsSidebarVisible({isRequestConsumptionBar:false,isAddConsumptionBar:false,isEditConsumptionBar: false})
  }, [props.missingDataList, reFetchData])

  useEffect(() => {
    props.missingDataPdf(props.missingDataList, props.landlord, qtrTextLabel)
  }, [props.missingDataList, props.landlord, qtrTextLabel])

  //endregion

  //region Helper methods
  const missingDataListReqDataHandler = (showRequestDataDlg) => {
    setShowRequestDataDlg(showRequestDataDlg)
  }

  const onMeterSelectionChange = (evt) => {
    if(!refetched){
      setSelectedData(evt.value)
    }
  }

  function reloadOnSuccess() {
    setReFetchData(true)
  }

  function closeSidebarHandler() {
    if (isSidebarVisible.isAddConsumptionBar || isSidebarVisible.isEditConsumptionBar || isSidebarVisible.isRequestConsumptionBar) {
      setIsSidebarVisible({isRequestConsumptionBar: false, isAddConsumptionBar: false, isEditConsumptionBar: false})
      setRefetched(true)
      props.missingData.refetch().then((response)=>{
        setSelectedData([])
        setRefetched(false)
      })
      props.refetch()
    }
  }

  const onShowSelectionCheckbox = (rowData) => {
    return featureState || DATA_QUALITY_KEYS.MISSING === rowData?.estimate?.toLowerCase() ? true : null
  }

  const missingDataMutation = useMutation(addNewMissingDataRequestInternal, {
    onSuccess: (data) => {
      if(data){
        window.open(data)
      } else {
        errorDialog({ title: i18n.formatMessage({ id: MSG_KEY_TITLE_CANNOT_REQUEST_DATA }),message: getLocalizedValue(selectedLanguage, 't_no_redirect_data')})
      }
      reloadOnSuccess()
    }
  })

  const missingDataRequestMutation = useMutation(addNewMissingDataRequest)

  const sendDataHandler = async () => {
    try {
      await missingDataRequestMutation.mutateAsync({
        assetId,
        emails: emailAddresses,
        dataGap: createMissingDataReqObject(selectedData),
      })

      setShowRequestConfirmation(true)
    } catch (error) {
      console.log(error.toString())
    }
  }

  const onEnterDataHandler = async () => {
    try {
      await missingDataMutation.mutateAsync({assetId, dataGap: createMissingDataReqObject(selectedData)})
    } catch (error) {
      errorDialog({ title: i18n.formatMessage({ id: MSG_KEY_TITLE_CANNOT_REQUEST_DATA }),message: error.toString()})
    }
  }

  const selectedDataNotEmpty=()=>selectedData && selectedData.length>0

  //endregion

  //region Custom templates
  const utilBodyTemplate = (rowData) => {
    return <span key={rowData.utility}>{I18n('t_' + rowData.utility?.toLowerCase())}</span>
  }

  const utilConsumptionTemplate = (rowData) => {
    return getUtilConsumptionTemplate(rowData, selectedLanguage, unitSystem, props.colorLabel)
  }

  const utilFloorAreaTemplate = (rowData) => {
    let floorArea = '-'
    if (rowData.floorArea) {
      floorArea = formatUnits(rowData.floorArea, selectedLanguage)
    }
    return (
      <div>
        <div className="perf-col-text1">
          {floorArea}&nbsp;
          <span data-testid={unitsConverter(AREA, unitSystem)} className="floor-unit-sup-text">
            {floorArea !== '-' && unitsConverter(AREA, unitSystem)}
          </span>
        </div>
      </div>
    )
  }

  const utilFormatLocation = (rowData) => {
    let location = '-'
    if (rowData.location) {
      location = I18n('t_' + rowData.location.toLowerCase())
    }
    return (
      <div>
        <div className="perf-col-text1">{location}</div>
      </div>
    )
  }

  const utilEstimateTemplate = (rowData) => {
    const backgroundClass = rowData.estimate.toUpperCase()
    return <CustomAvatar styling={`avatar-size ${backgroundClass}`} />
  }

  function getMeterHeader() {
    return (
      <span className={'col-meter'}>
        <span className="title">{I18n('t_missing_data_meter')}</span>
      </span>
    )
  }

  function getDateHeader() {
    return (
      <span className={'col-meter'}>
        <span className="title">{I18n('t_date')}</span>
      </span>
    )
  }

  function getFloorAreaHeader() {
    return (
      <span className={'col-meter'}>
        <span className="title">{I18n('t_floor_area')}</span>
      </span>
    )
  }

  function electricityMPANTemplate(rowData) {
    return <div className={'electricity-mpan'}>
      {featureState && utilEstimateTemplate(rowData)}
      <Tooltip className={'portfolio-comm-tooltip long-text-tooltip'} target=".asset-dq-tooltip" position={TOP}/>
      <div className={'text-wrapper asset-dq-tooltip dq-meter'} data-ptf-tooltip={rowData.electricityMPAN}
        onMouseEnter={(e) => isTextOverflown(e)}>{rowData.electricityMPAN}</div>
    </div>
  }

  const getMissingDataTable = () =>
    <DataTable
      value={sortedData}
      sortField='electricityMPAN'
      onSort={sortingOrder}
      sortOrder={sortOrder}
      selection={selectedData}
      onSelectionChange={e => onMeterSelectionChange(e)}
      showSelectionElement={rowData => onShowSelectionCheckbox(rowData)}
      paginator rows={7}
      sortIcon={getSortIcons()}
    >
      {featureState || props.missingDataList?.some(list => list.estimate === 'Missing') ?  <Column selectionMode="multiple" style={{width: '2%'}}/> : null}
      <Column field="electricityMPAN" header={getMeterHeader()} body={electricityMPANTemplate}
        headerClassName={sortIconToggle} sortable
        style={{fontWeight: '500', fontSize: '100%', color: CSS_VARIABLES.forest, width: '17%'}}/>
      <Column field="dateSummary" header={getDateHeader()} style={{width: '15%'}}/>
      <Column field="utility" body={utilBodyTemplate} header={I18n('t_missing_data_type')} style={{width: '9%'}}/>
      <Column field="provider" body={(rowData) => utilTextTemplate(rowData,'provider')} header={I18n('t_provider')} style={{width: '9%'}}/>
      <Column field="location" body={utilFormatLocation} header={I18n('t_location')} style={{width: '10%'}}/>
      <Column field="floorArea" body={utilFloorAreaTemplate} header={getFloorAreaHeader()}
        style={{width: '8%'}}/>
      <Column field="source" body={(rowData) => utilTextTemplate(rowData,'source')} header={I18n('t_source')} style={{width: '8%'}}/>
      <Column field="consumption" body={utilConsumptionTemplate} header={I18n('t_consumption')}
        style={{width: '10%'}}/>
      {featureState && <Column field="submittedBy"
        body={(rowData) => utilSubmittedByTemplate(rowData, selectedLanguage, unitSystem, 'submittedby-value')}
        header={I18n('t_meter_submitted_by')}
        style={{width: '17%'}}/>}
    </DataTable>

  async function deleteSelectedConsumption() {
    const constructObj = selectedData.map(data=>({
      rangeId: data.rangeId,
      meterId: data.meterId
    }))
    try{
      await deleteConsumption.mutateAsync([{assetId : assetId, data : constructObj}])
      props.refetch()
      setSelectedData([])
    }catch(e){
      //Message will be changed after backend changes are done
      errorDialog({title:I18n('t_error_title'),message: 'Unable to delete consumption.'})
    }
  }

  function getMissingDataTableFooter() {
    function updateConsumptionState(type){
      setIsSidebarVisible({
        isAddConsumptionBar: type === 'ADD',
        isEditConsumptionBar: type === 'EDIT',
        isRequestConsumptionBar: type === 'REQUEST',
      })
    }

    const getTableFilter = (selectedCategory) => {
      return selectedCategory === DATA_QUALITY_KEYS.INCOMPLETE ? DATA_QUALITY_KEYS.MISSING : selectedCategory
    }

    return featureState ? <div className={'mt-4'}>
      <ConsumptionOperations allRecords={sortedData} selectedRecords={selectedData || []} requestConsumption={(type)=>updateConsumptionState(type)}
        filter={MASK_DATA_QUALITY_KEYS[props.landlord ? getTableFilter(props.selectedCategoryLandlord) : getTableFilter(props.selectedCategoryTenant)]}
        addConsumption={(type)=>updateConsumptionState(type)} editRecord={(type)=> updateConsumptionState(type)}
        language={selectedLanguage} deleteConsumption={deleteSelectedConsumption}/>
    </div> :
      <div className={`btns-container mt-5 ${sortedData && sortedData.length>4?'btns-margin-right':''}`}>
        <button className={'btn-request pointer-hightlight ' + (!selectedDataNotEmpty()?CLASS_BTN_DISABLE:NO_SPACE)} disabled={!selectedDataNotEmpty()}
          onClick={() => {missingDataListReqDataHandler(!showRequestDataDlg)}}>{I18n( 't_btn_request')}</button>
        <button className={'btn-enter pointer-hightlight ' + (!selectedDataNotEmpty()?CLASS_BTN_DISABLE: NO_SPACE)} onClick={onEnterDataHandler}
          disabled={!selectedDataNotEmpty()} >{I18n( 't_btn_enter')}</button>
      </div>
  }

  const dataTableReqBtnsComponent = () => {
    const tableComponent = sortedData?.length > 0 ? getMissingDataTable() : noMissingDataComponent()

    return <>
      <div className="missing-datatable" role={'missing-datatable'}>
        {props.isLoading ? <DataQualityMissingDataSkeleton/> : tableComponent}
      </div>
      {getMissingDataTableFooter()}
      {((isSidebarVisible.isRequestConsumptionBar || isSidebarVisible.isAddConsumptionBar || isSidebarVisible.isEditConsumptionBar)
              && selectedData?.length > 0) &&
          <ConsumptionNavigator
            selectedData={selectedData}
            isSidebarVisible={isSidebarVisible}
            assetId={assetId}
            setIsSidebarVisible={closeSidebarHandler}
            setEmailAddresses={setEmailAddresses}
            sendDataHandler={sendDataHandler}
            showRequestConfirmation={showRequestConfirmation}
            setShowRequestConfirmation={setShowRequestConfirmation}/>
      }
    </>
  }

  const noMissingDataComponent = () => <div className="no-missing-data-container">
    <span className="data-quality-table-msg">{I18n('t_msg_no_data')}</span>
  </div>
  //endregion

  //region Dialog handlers
  const onDialogActionHandler = () => {
    setShowRequestDataDlg(!showRequestDataDlg)
  }

  //endregion

  return (
    <div className={'missing-datatable-view'}>
      {dataTableReqBtnsComponent()}
      <div>
        <RequestDataDialog
          showDialog={showRequestDataDlg}
          onEventActionHideHandler={onDialogActionHandler}
          data={selectedData}
          reload={reloadOnSuccess}
          assetAddress={rowDataCtx.assetAddress}
          assetId={assetId}
        />
        {errorDialogVisible && <ShowErrorDialog/>}
      </div>
    </div>
  )
}

export default MissingDataTable

MissingDataTable.propTypes={
  refetch:PropTypes.func.isRequired,
  missingDataPdf:PropTypes.func.isRequired,
  missingDataList:PropTypes.array,
  isLoading:PropTypes.bool.isRequired,
  missingData:PropTypes.func
}