import React, {useContext, useEffect} from 'react'
import {Route, useHistory} from 'react-router-dom'
import {isUserLoggedIn} from '../../../../utils/helpers/Helper'
import {updateFeatureFlagsAndContinue} from '../../../pages/funds/net-zero-carbon-home/nzc-utils/nzc-utils'
import {FeatureFlagContext} from 'Contexts'
import useNzcFeature from '../../../../utils/custom-hooks/useNzcFeature'

const AssetNzcFeatureRoute = ({component: Component, flag, ...rest}) => {
  const nzcFeatureFlag = useNzcFeature(flag)
  const history = useHistory()
  const featureFlagContext = useContext(FeatureFlagContext)
  useEffect(() => {
    if (isUserLoggedIn() && !nzcFeatureFlag.current) {
      updateFeatureFlagsAndContinue(history, featureFlagContext, nzcFeatureFlag, flag)
    }
  }, [])
  return <Route render={props => nzcFeatureFlag.current ? <Component {...props} /> : null } {...rest} />
}

export default AssetNzcFeatureRoute