import React from 'react'
import { Skeleton } from 'primereact/skeleton'
import '../asset-row-skeleton/AssetRowSkeleton.scss'

const NZCPerformanceSummaryCardSkeleton = () => {
  return (
    <>
      <div className={'summary-card'}>
        <span className={'card-heading'}/>
        <div>
          <div className={'list-item'}><Skeleton width="6rem" borderRadius="0.125rem" height="1rem"/> <span className={'ex-large-text-light'}><Skeleton width="3rem" borderRadius="0.125rem" height="1rem"/></span></div>
          <div className={'list-item'}><Skeleton width="6rem" borderRadius="0.125rem" height="1rem"/><span className={'ex-large-text-light'}><Skeleton width="3rem" borderRadius="0.125rem" height="1rem"/></span></div>
          <div className={'list-item'}><Skeleton width="6rem" borderRadius="0.125rem" height="1rem"/><span className={'ex-large-text-light'}><Skeleton width="3rem" borderRadius="0.125rem" height="1rem"/></span></div>
          <div className={'list-item'}><Skeleton width="6rem" borderRadius="0.125rem" height="1rem"/><span className={'ex-large-text-light'}><Skeleton width="3rem" borderRadius="0.125rem" height="1rem"/></span></div>
        </div>
      </div>
    </>
  )
}


export default NZCPerformanceSummaryCardSkeleton