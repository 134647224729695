import {memo} from 'react'
import PropTypes from 'prop-types'
import {Icon} from '../../atomic'
import './PeriodView.scss'
import {monthListShortWithLocale, PERIOD_TYPES} from '../../../utils/helpers/Constants'
import Text from '../../atomic/Text/Text'
import {useIntl} from 'react-intl'

const PeriodView = ({timePeriod, pickerValue}) => {
  const intl = useIntl()

  function getSelectedPeriod() {
    if (timePeriod === PERIOD_TYPES.CUSTOM) {
      const periodMessageKey = pickerValue?.pickerValueMonths === '1' ? 't_picker_filter_value_single_month' : 't_picker_filter_value'
      const contentDynamic = {
        0: pickerValue?.pickerValueMonths,
        1: monthListShortWithLocale[pickerValue?.pickerValueEndMonth],
        2: pickerValue?.pickerValueEndYear
      }
      return {content: intl.formatMessage({id: periodMessageKey}, contentDynamic)}
    }
    return {content: timePeriod}
  }

  return (
    <div className="period-view flex justify-content-center align-items-center">
      <Icon id="calendar" size={17} className="period-view__calendar"/>
      <Text  {...getSelectedPeriod()} className="period-view__period teal"/>
    </div>
  )
}

PeriodView.propTypes = {
  timePeriod: PropTypes.string,
  pickerValue: PropTypes.object
}

PeriodView.displayName = 'PeriodView'
export default memo(PeriodView)