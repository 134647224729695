import {Dropdown} from 'primereact/dropdown'
import {dropdownItemTemplate, getLocalizedValue, getLocalStorageItem} from '../../../../utils/helpers/Helper'
import {
  DOCUMENT_TYPE,
  RECORD_TYPE
} from '../../../../utils/helpers/Constants'
import {useFormik} from 'formik'
import './AddDocument.scss'
import {Calendar, Icon, Text, Textarea, Button} from '../../../atomic'
import React, {useEffect, useRef, useState} from 'react'
import {UNITS} from '../../../../utils/i18n/constants'
import '../documents-filter/DocumentsFilter.scss'
import * as Yup from 'yup'
import I18n from '../../../../utils/i18n/I18n'
import PropTypes from 'prop-types'
import {FileUpload} from 'primereact/fileupload'
import {
  DOCUMENTS_ALLOWED_FILE_TYPES,
  DOCUMENTS_FILE_SPECIAL_CHARACTER_NOT_ALLOWED
} from '../../../../utils/config/Config'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'
import Link from '../../../atomic/Link/Link'

const RENEWABLE_ENERGY_CERT = 'Renewable energy certificate'
const ENERGY_RATING_CERTIFICATE = 'Energy Rating Certificate'
const GREEN_BUILDING_CERTIFICATE = 'Green Building Certificate'
const STANDING_INVESTMENT = 'Standing Investment'
const NEW_CONSTRUCTION= 'New Construction'
export const documentTypeCheck = [GREEN_BUILDING_CERTIFICATE,ENERGY_RATING_CERTIFICATE,RENEWABLE_ENERGY_CERT]
const RECORD_TYPE_VAL = 'recordType'
const REPORT_DATE = 'reportDate'
const EXPIRY_DATE = 'expiryDate'
const FUND_TYPE = 'fundType'

function containsSpecialCharacters(fileName) {
  return fileName ? !DOCUMENTS_FILE_SPECIAL_CHARACTER_NOT_ALLOWED.test(fileName) : true
}

const validationSchema = (isTotalSizeExceeded,assetList, documentNames)=>Yup.object().shape({
  documentType: Yup.string().nullable().required(I18n('t_form_group_error_message')),
  recordType: Yup.string().required(I18n('t_form_group_error_message')),
  fundType:Yup.string().nullable().required(I18n('t_form_group_error_message')),
  reportDate:Yup.string().nullable().required(I18n('t_form_group_error_message')),
  selectedFile:Yup.mixed().nullable().required(I18n('t_form_group_error_message'))
    .test('special character', I18n('t_special_character'), (value)=>{
      return containsSpecialCharacters(value?.name)
    })
    .test('storage',I18n('t_documents_space_exceed',{0:<Link content={'get in touch'} colour={'red'}/>}), (value) => {
      return value?.size ? isTotalSizeExceeded : true
    })
    .test('unique filename', I18n('t_unique_file_name'), (value) => {
      const isDuplicate = documentNames?.some(fileName => fileName === value?.name)
      return !isDuplicate
    }),
  expiryDate: Yup.string()
    .when(['documentType','fundType'], {
      is: (documentType,fundType) => documentTypeCheck.includes(documentType) && !assetList?.some(asset=>asset.value=== Number(fundType) && asset.status === NEW_CONSTRUCTION),
      then: Yup.string()
        .required(I18n('t_form_group_error_message')),
      otherwise: Yup.string().nullable().test('is-after-report-date', I18n('t_expiry_date_must_be_after_report_date'), function(expiryDate) {
        const { reportDate } = this.parent
        if (!reportDate || !expiryDate) return true
        return new Date(expiryDate) >= new Date(reportDate)
      }),
    })
})

export function AddDocument({getUserAssetDataQuery,userGroupData,getInitialValues,saveClick,activeIndex,index,isTotalSizeExceeded, documentNames}){
  const isFormFieldValid = (name) => !!((getInitialValues.isTabEdited && getInitialValues.isDirty && getInitialValues.isErrors ? true :
    formik.touched[name]) && formik.errors[name])
  const [assetList, setAssetList] = useState([])
  const [fundList, setFundList] = useState([])
  const [expiryDateHidden,setExpiryDateHidden] = useState(false)
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const dateFormatType = userInfo.unitSystem === UNITS.IMPERIAL ? 'M dd yy' : 'dd M yy'
  const fileUploadRef = useRef(null)
  const ASSET_SEARCH = getLocalizedValue(selectedLanguage,'t_search_asset')
  const SELECT_ASSET = getLocalizedValue(selectedLanguage,'t_select_asset')
  const FUND_SEARCH = getLocalizedValue(selectedLanguage,'t_search_fund_ref')
  const SELECT_FUND = getLocalizedValue(selectedLanguage,'t_select_doc_fund')
  const getUserAccess = getLocalStorageItem('userFundAccessType')
  const chooseOptions = {label:  <u>{I18n('t_document_select_file')}</u>, icon: ' '}
  const formik = useFormik({
    initialValues: getInitialValues,
    validateOnMount:getInitialValues.isErrors && getInitialValues.isDirty,
    validationSchema: validationSchema(isTotalSizeExceeded, assetList, documentNames)
  })
  const fundType=formik.values.recordType === 'Fund'
  const FUND_ASSET_LABEL = fundType ? getLocalizedValue(selectedLanguage,'t_fund') + ' *' : getLocalizedValue(selectedLanguage,'t_assets') + ' *'
  const prevActiveIndex = useRef(null)
  const getValidationBorder = (source)=> isFormFieldValid(source)? 'p-invalid':''
  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <Text className={'mt-2'} colour={'red'} content={formik.errors[name]}/>
  }
  const isEdit = getInitialValues.filePath === ''
  const isDocumentTypeBelongsToAsset = documentTypeCheck.includes(formik.values.documentType)
  const isRecordTypeVisible = !documentTypeCheck.includes(formik.values.documentType)

  useEffect(() => {
    if(getUserAssetDataQuery.data && userGroupData.data){
      const fundList = fundTypeDropdownOption(userGroupData.data.funds||[],'Fund')
      const userAssets= getUserAssetDataQuery.data.filter(asset=>asset.isGroupAccess || asset.isAssetAccess || asset.isFundAccess)
      const assetList = fundTypeDropdownOption(userAssets||[],'Asset')
      setAssetList([...assetList.sort()])
      setFundList([...fundList.sort()])
    }
  }, [getUserAssetDataQuery.data,userGroupData.data])

  useEffect(()=>{
    let data = formik.values
    data = setAccordianData(data, null)
    saveClick({data,activeIndex:index})
    if (prevActiveIndex.current === null) {
      (async() => {
        const error = await formik.validateForm()
        data = setAccordianData(data, error)
        saveClick({data,activeIndex:index})
      } ) ()
      prevActiveIndex.current = activeIndex
    }
    if(!isTotalSizeExceeded){
      (async() => {
        await formik.validateForm()
      } ) ()
    }
  },[formik.values,formik.errors,activeIndex,isTotalSizeExceeded])

  function setAccordianData(data,error) {
    const errorToConsider =  error || formik.errors
    data.isErrors = Object.values(errorToConsider).length !== 0
    data.isDirty =  getInitialValues.isDirty ? getInitialValues.isDirty : formik.dirty
    return data
  }

  function fundTypeDropdownOption(data,type) {
    return data.map(record => {
      const val = type === 'Asset' ? record.assetName : record.fundname
      const id = type === 'Asset' ? record.assetId : record.fundId
      return {label: val, value: id, status: record.assetStatus}
    }).reduce((unique, item) => {
      return unique.includes(item) ? unique : [...unique, item]
    }, [])
  }

  const handleFileSelect = (e) => {
    const file = e.files[0]
    const allowedExtensions = ['docx', 'xlsx','jpg', 'xls', 'csv','pdf','png']
    const extension = file.name.split('.').pop()
    const isValidFile = allowedExtensions.includes(extension.toLowerCase())
    if(isValidFile){
      formik.setFieldValue('selectedFile',file)
    }else{
      fileUploadRef.current.clear()
    }
  }

  const getDocumentTypeOptions = () => {
    if(formik.values.recordType === 'Asset') {
      return DOCUMENT_TYPE.filter(type => type.value !== 'Fund reports')
    }
    return DOCUMENT_TYPE
  }

  const getRecordTypeOptions = () => {
    if(formik.values.documentType === 'Fund reports') {
      return RECORD_TYPE.filter(type => type.value !== 'Asset')
    }
    return RECORD_TYPE
  }

  function isDocumentTypeAssetSelection(event){
    const documentCheck = documentTypeCheck.includes(event.target.value)
    if(documentCheck) {
      formik.setFieldValue(RECORD_TYPE_VAL, 'Asset')
    }
    if(documentCheck && event.target.value !== RENEWABLE_ENERGY_CERT) {
      const getYear = event.target.value === ENERGY_RATING_CERTIFICATE ? 10 : 3
      const tenYearsLater = new Date(formik.values.reportDate)
      tenYearsLater.setFullYear(tenYearsLater.getFullYear() + getYear)
      formik.setFieldValue(EXPIRY_DATE, tenYearsLater)
    } else {
      formik.setFieldValue(EXPIRY_DATE, '')
      if(!formik.touched[REPORT_DATE] && isEdit) {
        formik.setFieldValue(REPORT_DATE, '')
      }
    }
  }

  async function isGreenBuildingAsset(isNewAsset, isGreenBuilding, isAssetOperational) {
    const isAssetOperationalObjEmpty = Object.values(isAssetOperational).length !== 0
    if(isGreenBuilding){
      if (isNewAsset) {
        setExpiryDateHidden(true)
        await formik.setFieldValue(EXPIRY_DATE, '')
      } else {
        setExpiryDateHidden(false)
        if((!isEdit || (formik.touched[REPORT_DATE] && isAssetOperationalObjEmpty) && !formik.touched[EXPIRY_DATE])){
          const threeYearsLater = new Date(formik.values.reportDate)
          threeYearsLater.setFullYear(threeYearsLater.getFullYear() + 3)
          await formik.setFieldValue(EXPIRY_DATE, threeYearsLater)
        }
        if(!formik.touched[EXPIRY_DATE] && !isAssetOperationalObjEmpty){
          await formik.setFieldValue(EXPIRY_DATE, '')
        }
      }
    }else{
      setExpiryDateHidden(false)
    }
  }

  async function isReportDateSelection(event, isAssetOperational) {
    const documentCheck = documentTypeCheck.includes(formik.values.documentType)
    if (documentCheck && formik.values.documentType !== RENEWABLE_ENERGY_CERT) {
      if(formik.values.documentType === GREEN_BUILDING_CERTIFICATE && Object.values(isAssetOperational).length === 0) {
        await formik.setFieldValue(EXPIRY_DATE, '')
      } else {
        const getYear = formik.values.documentType === ENERGY_RATING_CERTIFICATE ? 10 : 3
        const tenYearsLater = new Date(event.target.value)
        tenYearsLater.setFullYear(tenYearsLater.getFullYear() + getYear)
        await formik.setFieldValue(EXPIRY_DATE, tenYearsLater)
      }
    }
  }

  async function onChangeHelper(event) {
    if (event.target.name === RECORD_TYPE_VAL) {
      await formik.setFieldValue(FUND_TYPE, '')
    }
    const getAssetId = typeof event.value === 'number' ? event.value : formik.values.fundType
    const isNewAsset = assetList?.find(asset=>asset.value=== getAssetId && asset.status === NEW_CONSTRUCTION)
    const isAssetOperational = assetList?.find(asset=>asset.value=== getAssetId && asset.status === STANDING_INVESTMENT)
    if (event.target.name === 'documentType') {
      isDocumentTypeAssetSelection(event)
      await isGreenBuildingAsset(isNewAsset,event.target.value === GREEN_BUILDING_CERTIFICATE, isAssetOperational || {})
    } else if (event.target.name === REPORT_DATE) {
      await isReportDateSelection(event,isAssetOperational||{})
    }
    if(event.target.name === FUND_TYPE){
      await isGreenBuildingAsset(isNewAsset, formik.values.documentType === GREEN_BUILDING_CERTIFICATE,isAssetOperational || {})
    }
    formik.handleChange(event)
  }

  const formDropdown = (props) => {
    return (
      <div className={'form-field'}>
        <Text htmlFor={props.name} className={'pb-2'} content={props.label} colour={'faded_teal'}/>
        <Dropdown
          id={props.name} name={props.name}
          value={props.value} options={props.options}
          onChange={props.onChange || formik.handleChange}
          onBlur={formik.handleBlur}
          itemTemplate={dropdownItemTemplate}
          appendTo={'self'} placeholder={props.placeholder}
          className={getValidationBorder(props.name)+' '+'sDropdown'}
          panelClassName={'document-dropdown'}
          disabled={props.disabled} filter={props.filter}
          filterPlaceholder={props.filterPlaceholder}
          data-testid={props.testId}
          virtualScrollerOptions={props.virtualScroller && { itemSize: 38 }}
        />
        {getFormErrorMessage(props.name)}
      </div>
    )
  }

  const onTemplateRemove = (file, callback) => {
    if(callback){
      callback()
    }
    formik.setFieldValue('selectedFile','')
  }

  const itemTemplate = (file, props) => {
    const getClassName = getValidationBorder('selectedFile') + ' '+ 'add-document__file-upload--drop-zone__file-info'
    return (
      <div className={getClassName}>
        <Text content={file?.name||file}/>
        <div className={'choose-file'}>
          {isEdit &&
              <Button
                icon={<Icon id={'trash_can'} size={17}/>}
                onClick={() => onTemplateRemove(file, props?.onRemove)}
              />
          }
        </div>
      </div>
    )
  }

  return <form className={'add-document'} onSubmit={formik.handleSubmit}>
    <div className={'add-document__file-upload'}>
      <Text htmlFor='selectedFile' className={'pb-2'} content={'t_document_upload'} colour={'faded_teal'}/>
      {formik.values.selectedFile ? itemTemplate(formik.values.selectedFile) :
        <FileUpload
          ref={fileUploadRef}
          data-testid='import-file-upload'
          onBlur={formik.handleBlur}
          id={'selectedFile'}
          className={getValidationBorder('selectedFile')+' '+'file-upload'}
          name="selectedFile"
          uploadHandler={handleFileSelect}
          customUpload={true}
          auto accept={DOCUMENTS_ALLOWED_FILE_TYPES}
          itemTemplate={itemTemplate}
          chooseOptions={chooseOptions}
          emptyTemplate={<div className={'choose-file'}>{I18n('t_drag_drop')}</div>}
          multiple={false}
        />
      }
      {formik.errors['selectedFile'] && formik.values.selectedFile && <Text className='mt-2' colour='red' content={formik.errors['selectedFile']}/>}
    </div>
    <div className={'mt-3 add-document__doc-type'}>
      {formDropdown({label:getLocalizedValue(selectedLanguage,'t_doc_type')+' *',
        name:'documentType', value:formik.values.documentType, onChange:onChangeHelper,testId:'documentType',
        options: getDocumentTypeOptions(), placeholder:getLocalizedValue(selectedLanguage,'t_select_document_type')
      })}
    </div>
    {isRecordTypeVisible && <div className={'mt-3 add-document__rec-type'}>
      {formDropdown({
        label: isDocumentTypeBelongsToAsset ? 't_record_type_asset_document' : 't_record_type_document',
        disabled: getUserAccess === 'NoFundAccess', onChange: onChangeHelper, testId:'recordType',
        name: 'recordType', value: formik.values.recordType, options: getRecordTypeOptions(),
        placeholder: getLocalizedValue(selectedLanguage, 't_select_record_type'),
      })}
    </div>}
    {formik.values.recordType && <div className={'mt-3 add-document__fund-type'}>
      {formDropdown({name:'fundType', value:formik.values.fundType, options:fundType ? fundList : assetList,
        label: isDocumentTypeBelongsToAsset ? 't_record_type_asset_document' : FUND_ASSET_LABEL, onChange: onChangeHelper,
        placeholder:fundType ? SELECT_FUND : SELECT_ASSET, disabled:getUserAssetDataQuery.isLoading,testId:'fundType',
        filterPlaceholder:fundType ? FUND_SEARCH : ASSET_SEARCH, filter:fundType ? fundList.length > 6 : assetList.length > 6,
        virtualScroller: fundType ? fundList.length > 20 : assetList.length > 20
      })}
      <div className={'mt-3 add-document__from-date'}>
        <Text className={'pb-2'} content={isDocumentTypeBelongsToAsset ? 't_valid_from_document' : 't_report_date'} colour={'faded_teal'}/>
        <div>
          <Calendar id='reportDate' name={'reportDate'} value={formik.values.reportDate} showIcon dateFormat={dateFormatType}
            onBlur={formik.handleBlur} panelClassName={'custom-datepicker documents-datepicker add-document-cal'}
            onChange={onChangeHelper} placeholder={getLocalizedValue(selectedLanguage,'t_select_report_date')}
            maxDate={new Date()} className={getValidationBorder('reportDate')} />
          {getFormErrorMessage('reportDate')}
        </div>
      </div>
      {!expiryDateHidden && <div className={'mt-3 add-document__expiry-date'}>
        <Text className={'pb-2'} content={isDocumentTypeBelongsToAsset ? 't_expiry_date_mandatory' : 't_expiry_date'}
          colour={'faded_teal'}/>
        <div>
          <Calendar id='expiryDate' name={'expiryDate'} value={formik.values.expiryDate} showIcon
            dateFormat={dateFormatType} minDate={new Date(formik.values.reportDate)} onBlur={formik.handleBlur}
            panelClassName={'custom-datepicker documents-datepicker add-document-cal'} onChange={formik.handleChange}
            placeholder={getLocalizedValue(selectedLanguage, 't_select_expiry_date')}
            className={getValidationBorder('expiryDate')} disabled={formik.values.reportDate === ''}/>
          {getFormErrorMessage('expiryDate')}
        </div>
      </div>}
    </div>}
    <div className={'mt-3 add-document__comment'}>
      <Text htmlFor='comment' className={'pb-2'} content={'t_comment'} colour={'faded_teal'}/>
      <Textarea id='comment' name={'comment'} value={formik.values.comment} onChange={formik.handleChange}
        autoResize className={getValidationBorder('comment')} maxLength='100'/>
      {getFormErrorMessage('comment')}
    </div>
  </form>
}

AddDocument.propTypes = {
  isLoading : PropTypes.bool,
  getUserAssetDataQuery:PropTypes.object.isRequired,
  userGroupData:PropTypes.object.isRequired,
  getInitialValues:PropTypes.object,
  saveClick:PropTypes.func,
  name:PropTypes.string,
  label:PropTypes.string,
  onChange:PropTypes.func,
  placeholder:PropTypes.string,
  filter:PropTypes.bool,
  filterPlaceholder:PropTypes.bool,
  value:PropTypes.string,
  options:PropTypes.array,
  disabled:PropTypes.bool,
  onRemove:PropTypes.func,
  activeIndex:PropTypes.number,
  index:PropTypes.number,
  isTotalSizeExceeded:PropTypes.bool,
  testId:PropTypes.string,
  documentNames:PropTypes.array
}