import React, {useEffect, useState} from 'react'
import '../ESConnectionStep.scss'
import I18n from '../../../../../../utils/i18n/I18n'
import {Button} from 'primereact/button'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {
  dropdownItemTemplate, getLocalizedValue,
  getPaginatorTemplate
} from '../../../../../../utils/helpers/Helper'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {
  esGenerateMetricsReport,
  esMetricsReportStatus,
  esPropertyAddToSieraPlus,
  esPropertyMatchWithSieraPlus,
  resetEsConnection,
  updateEnergyStarMetrics
} from '../../../../../../services/energy-star/energystar-service'
import {noESAssetsMatch} from '../../../../../../utils/helpers/MessageUtility'
import ESConnectionStepFourSkeleton from '../../../../../common/skeletons/energy-star-skeleton/ESConnectionStepFourSkeleton'
import {fetchWithRetry, noRefetchOnWindowFocus} from '../../../../../../services/common/useQuery-config'
import {
  ENERGY_STAR_STEPS,
  METRIC_REPORT_STATUS
} from '../../../../../../utils/helpers/Constants'
import PopupDialog from '../../../../../common/modal-dialogs/PopupDialog'
import warningIcon from '../../../../../../resources/images/icon/warning-red.svg'
import indicationIcon from '../../../../../../resources/images/icon/exclamation-orange.svg'
import { Dropdown } from 'primereact/dropdown'
import PropTypes from 'prop-types'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const constructHeader = () =>  <div className={'flex'}>
  <img src={warningIcon} alt={'Warning'} className={'mr-2'}/>
  <span className={'align-content-center'}>{I18n('t_es_reset_warning_header_message')}</span>
</div>

const constructBody = () =>  <div className={'text-left'}>
  <span>{I18n('t_es_reset_warning_message_one')}</span><br/>
  <span>{I18n('t_es_reset_warning_message_two', {0:<br/>})}</span>
</div>

const getObjectsNotInArray = (array1, array2) => array1.filter(obj1 => !array2.some(obj2 => areObjectsEqual(obj1, obj2)))
const areObjectsEqual = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2)
const getPropertyTemplate = (property)=> property.propertyName +SPACE_WITH_COMA+property.propertyAddress+SPACE_WITH_COMA
    +property.propertyCity+SPACE_WITH_COMA+ property.propertyPostCode+SPACE_WITH_COMA+property.propertyCountry

const getEnergyStarPropertyTemplate = (rowData)=> rowData.energyStarPropertyAddress + SPACE_WITH_COMA +
    rowData.energyStarPropertyCity + SPACE_WITH_COMA + rowData.energyStarPropertyState + SPACE_WITH_COMA +
    rowData.energyStarPropertyPostCode + SPACE_WITH_COMA + rowData.energyStarPropertyCountry

const QUERY_KEY = 'esPropertyMatch'
const SPACE_WITH_COMA = ', '


const ESConnectionStepFour = (props) => {
  const [selectedAssets,setSelectedAssets] = useState([])
  const [first, setFirst] = useState(0)
  const [rows, setRows] = useState(20)
  const [selectedAssetViaDropdown,setSelectedAssetViaDropdown] = useState([])
  const [tableData,setTableData] = useState([])
  const [cancelFlowWarning, setCancelFlowWarning] = useState(false)
  const [submitted,setSubmitted] = useState(false)
  const getDisableRow = (property) => property.sieraPropertyId === null && !property.possibleMatchesOfSieraProperties?.length > 0
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const queryClient=useQueryClient()
  const matchProperties = useQuery(QUERY_KEY,esPropertyMatchWithSieraPlus,noRefetchOnWindowFocus)
  const postProperties = useMutation(esPropertyAddToSieraPlus)
  const resetConnection = useMutation(resetEsConnection)

  const {
    data: reportId,
    mutate: generateMetricsReport,
    isError: isErrorInGeneratingReport
  } = useMutation({
    mutationKey: 'generateReport',
    mutationFn: esGenerateMetricsReport,
  })

  const {
    isLoading: isStatusLoading,
    data: metricStatusData,
    refetch: getMetricStatus,
  } = useQuery(['metricReportStatus', reportId], esMetricsReportStatus, {
    ...fetchWithRetry,
    enabled: !!reportId && !isErrorInGeneratingReport
  })

  const updateESMetrics = useMutation(updateEnergyStarMetrics)

  useEffect(() => {
    if(matchProperties.data){
      tableDataDropdownModification()
    }
  }, [selectedAssets,matchProperties?.data,selectedAssetViaDropdown])

  useEffect(() => {
    if(metricStatusData === METRIC_REPORT_STATUS.INPROCESS && !isErrorInGeneratingReport) {
      if(!isStatusLoading) {
        (async () => {
          await getMetricStatus()
        })()
      }

    } else if (metricStatusData === METRIC_REPORT_STATUS.GENERATED && reportId && !isErrorInGeneratingReport) {
      queryClient.setQueriesData({queryKey: 'metricReportStatus'}, {metricStatusData: METRIC_REPORT_STATUS.FETCHED})
      updateESMetrics.mutateAsync(reportId)
        .then((response) => {
          if(response) {
            setSubmitted(false)
            props.onStepChange(ENERGY_STAR_STEPS.CHOOSE_ASSETS_STEP)
            queryClient.removeQueries({ queryKey: 'generateReport' })
          }
        }, () => {
          queryClient.removeQueries({ queryKey: 'generateReport' })
        })
    }
  }, [metricStatusData])

  function tableDataDropdownModification(){
    /*
      ? Dropdown option creation : Creation and removal of options,
        If the user has selected the same dropdown which is also available in other dropdown
     */
    if(tableData.length === 0 && matchProperties.data.length > 0){
      const alteredData = matchProperties.data.map(obj=>({
        ...obj, dropdownOptions : obj?.possibleMatchesOfSieraProperties ?
          obj?.possibleMatchesOfSieraProperties?.map(property=>{
            return {label : getPropertyTemplate(property), value:property.propertyId , name:property.propertyName}
          }) : [] , selectedValueOfDropdown : ''
      }))
      setTableData(alteredData)
    }else{
      const alteredData = tableData.map((obj)=>{
        obj.dropdownOptions = obj?.possibleMatchesOfSieraProperties ? obj?.possibleMatchesOfSieraProperties?.map(property=>{
          return {label : getPropertyTemplate(property), value:property.propertyId , name:property.propertyName}
        }) : []
        obj.dropdownOptions = obj.dropdownOptions.filter(option=>{
          if(!selectedAssetViaDropdown.some(selectedDropdown=>selectedDropdown.pId === option.value &&
              selectedDropdown.eId !==obj.energyStarPropertyId)){
            return option
          }
        })
        return obj
      })
      setTableData(alteredData)
    }
  }

  async function onConfirm() {
    try {
      const selectedObj = selectedAssets.map(asset => {
        asset.isIncluded = true
        const getSelectedProperty = asset.possibleMatchesOfSieraProperties?.filter(property=>property.propertyId === asset.selectedValueOfDropdown)[0]
        if(getSelectedProperty){
          asset.sieraPropertyAddress = getSelectedProperty.propertyAddress
          asset.sieraPropertyCity = getSelectedProperty.propertyCity
          asset.sieraPropertyCountry = getSelectedProperty.propertyCountry
          asset.sieraPropertyId = getSelectedProperty.propertyId
          asset.sieraPropertyName = getSelectedProperty.propertyName
          asset.sieraPropertyPostCode = getSelectedProperty.propertyPostCode
        }
        return asset
      })
      setSubmitted(true)
      const selectedEnergyStarId = selectedAssets.map(property=>property.energyStarPropertyId)
      await postProperties.mutateAsync(selectedObj)
      await generateMetricsReport(selectedEnergyStarId)

      queryClient.removeQueries(QUERY_KEY)
    } catch (e) {
      console.log(e.response)
      props.isError(`Error : ${e.response}`)
    }
  }

  function backToExistingAssets() {
    props.onStepChange(ENERGY_STAR_STEPS.SUCCESS_STEP)
  }

  function onDropdownSelection(e,rowData){
    checkIfSameOptionAlreadyExist(rowData,e)
    rowData.selectedValueOfDropdown = e.value
    const tableAssetIndex = tableData.findIndex(asset=> asset.energyStarPropertyId === rowData.energyStarPropertyId)
    tableData[tableAssetIndex] = rowData
    const selectedAssetIndex = selectedAssets.findIndex(asset=> asset.energyStarPropertyId === rowData.energyStarPropertyId)
    if(selectedAssetIndex !== -1){
      selectedAssets[selectedAssetIndex] = rowData
      setSelectedAssets([...selectedAssets])
    }else{
      setSelectedAssets([...selectedAssets,rowData])
    }
  }

  function checkIfSameOptionAlreadyExist(rowData,e){
    /*
      Dropdown selection track,
      ? Removed from the array for multiple selection(from dropdown) :  Add
     */
    if(selectedAssetViaDropdown.some(option=>option.eId===rowData.energyStarPropertyId)){
      selectedAssetViaDropdown.some(option=>{
        if(option.eId === rowData.energyStarPropertyId){
          option.pId = e.value
          return true
        }
      })
    }else{
      setSelectedAssetViaDropdown([...selectedAssetViaDropdown, {eId:rowData.energyStarPropertyId,pId:e.value}])
    }
  }

  function sieraPlusTemplate(rowData) {
    const setCSS = getDisableRow(rowData) ? 'pointer-none inline' : 'inline'
    const getDropdownForMatchedProperties = () => rowData.possibleMatchesOfSieraProperties ?
      <Dropdown value={rowData.selectedValueOfDropdown}
        onChange={(e)=>onDropdownSelection(e,rowData)}
        options={rowData.dropdownOptions} filterBy={'name'}
        placeholder={I18n('t_select_available_match',{0:rowData?.dropdownOptions?.length})}
        filter={rowData.dropdownOptions.length > 6} filterPlaceholder={getLocalizedValue(selectedLanguage,'t_search_by_asset')}
        className="sDropdown" itemTemplate={dropdownItemTemplate}
        panelClassName={'es-dropdown-s-four'} scrollHeight={'350px'}
      /> :
      null

    return rowData.sieraPropertyId !== null ? <div className={setCSS}>
      <div className={'asset-name'}>{rowData.sieraPropertyName}</div>
      <div>
        {getEnergyStarPropertyTemplate(rowData)}
      </div>
    </div> : getDropdownForMatchedProperties()
  }

  function esTemplate(rowData) {
    return rowData.energyStarPropertyName + SPACE_WITH_COMA + getEnergyStarPropertyTemplate(rowData)
  }

  const onPageChange = (event) => {
    setFirst(event.first)
    setRows(event.rows)
  }

  function getTableClassName() {
    let className = 'es-asset-table'
    if(matchProperties.data?.length<20 ? 'es-asset-table no-border mb:col-5' : 'es-asset-table' ){
      className = 'es-asset-table no-border mb:col-5'
    }
    return className
  }

  const rowClass = (rowData) => {
    return {'disable-row': getDisableRow(rowData)}
  }

  function resetTheSelectedVal(e){
    /*
      Dropdown value is reset
      Individual checkbox
     */
    if(e.value<selectedAssets){
      const getObjOfUnselectedRow = getObjectsNotInArray(selectedAssets,e.value)
      tableData.some(matchedRecord=>{
        if(getObjOfUnselectedRow[0].energyStarPropertyId === matchedRecord.energyStarPropertyId){
          matchedRecord.selectedValueOfDropdown = ''
          const selectedViaDropdown = selectedAssetViaDropdown.filter(selectedRecord=>selectedRecord.eId!==matchedRecord.energyStarPropertyId)
          setSelectedAssetViaDropdown(selectedViaDropdown)
          return true
        }
      })
    }
  }

  function onSelection(e) {
    resetTheSelectedVal(e)
    setSelectedAssets(e.value)
  }

  function getPropertiesTable() {
    const showCancelAfterConnected = () => props.matchMoreAssets || userInfo.connectionStep === 5
    const showCancelDuringConnection = () => !props.matchMoreAssets && userInfo.connectionStep !==5
    const showCancelWarningDialog = () => setCancelFlowWarning(true)
    const hideCancelWarningDialog = () => setCancelFlowWarning(false)
    const resetESConnection = async () => {
      hideCancelWarningDialog()
      await resetConnection.mutateAsync()
      await props.onStepChange(0)
      queryClient.removeQueries(QUERY_KEY)
    }
    const tableAttributes = {
      value: tableData, onSelectionChange: onSelection, rowClassName: rowClass,
      tableClassName: getTableClassName(), selection: selectedAssets, paginator: matchProperties.data?.length > 20,
      first: first, onPage: e=>onPageChange(e),rows:rows,totalRecords:matchProperties.data?.length,
      paginatorTemplate:getPaginatorTemplate('items-per-page-options-white'),
      paginatorClassName:'data-table-paginator es-connection-paginator',selectionMode:'checkbox',showSelectAll:false
    }
    const isCheckboxDisabled=(rowData)=>{
      return rowData.possibleMatchesOfSieraProperties?.length > 0 && rowData.selectedValueOfDropdown === '' ? 'p-disabled':''
    }

    const getWarningDialogFooter = () =>  <div className={'flex justify-content-center'}>
      <Button className={'button-red step-button'} onClick={resetESConnection} autoFocus label={I18n('t_es_reset_warning_yes')}/>
      <Button className={'grey-bordered-whit-bg-btn step-button'} onClick={hideCancelWarningDialog} label={I18n('t_es_reset_warning_no')}/>
    </div>

    return <>
      {matchProperties?.data?.length > 0 ?
        <DataTable {...tableAttributes}>
          <Column selectionMode="multiple" className={'es-selection'} bodyClassName={(rowData)=>isCheckboxDisabled(rowData)}/>
          <Column field="seiraPlusProperty" header={I18n('t_es_asset_in_siera')} body={sieraPlusTemplate} className={'siera-assets'}/>
          <Column field="esProperty" header={I18n('t_es_asset_in_portfolio_manager')} body={esTemplate} className={'es-assets'}/>
        </DataTable> :
        <>
          {noESAssetsMatch()}
        </>
      }
      <div className={'flex justify-content-end mt-4'}>
        {showCancelDuringConnection() &&
            <Button className={'grey-bordered-whit-bg-btn step-button'}
              data-testid={'connection-cancel'} onClick={showCancelWarningDialog} label={I18n('t_cancel')}/>
        }
        {showCancelAfterConnected() &&
            <Button className={'grey-bordered-whit-bg-btn step-button mr-4'}
              data-testid={'match-more-cancel'} onClick={backToExistingAssets} label={I18n('t_cancel')}
            />
        }
        <Button className={'common-blue-bg-btn step-button mr-0'} onClick={onConfirm} loading={submitted}
          label={I18n('t_es_confirm')} disabled={selectedAssets.length<=0 || submitted}/>

      </div>
      <PopupDialog visible={cancelFlowWarning} message={constructBody} header={constructHeader}
        className={'sessionExpiry-Warning-Dlg es-dlg-warning'} footer={getWarningDialogFooter}
        onHide={hideCancelWarningDialog} blockScroll={true}
      />
    </>
  }

  return (
    <div className={'es-connection-step step-four'} data-testid={'esc-four-info'}>
      <span className={'step-sub-text'} >{I18n('t_es_available_assets')}</span>
      <span className={'step-sub-text step-sub-info'}>
        {I18n('t_es_change_permission_and_support', {
          0: <a className={'step-link'} href={'mailto:support@sieraglobal.com'} target="_blank" rel="noreferrer noopener">
            {I18n('t_es_contact_support')}
          </a>
        })}
      </span>
      <div className={'flex match-prop-header'}>
        <img src={indicationIcon} alt={'Exclamation'} className={'mr-2'} height={17} width={17}/>
        {I18n('t_matched_assets_count',{ 0 :selectedAssets.length+'/'+tableData.length})}
      </div>
      {matchProperties.isLoading ? <ESConnectionStepFourSkeleton/> : getPropertiesTable()}
    </div>
  )
}

ESConnectionStepFour.propTypes={
  onStepChange : PropTypes.func,
  matchMoreAssets : PropTypes.bool,
  isError : PropTypes.func
}

export default ESConnectionStepFour