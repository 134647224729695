import {
  assetsOrderByColumnChange,
  EXPORT_IMAGE_SCALES,
  EXPORT_TYPE_PNG,
  FUND_BAU_LINE
} from '../../../../../utils/helpers/Constants'
import CSS_VARIABLES from '../../../../../resources/css/_variables.scss'

function bauAvgIntensity(graphData) {
  let bauIntensityValue
  if(graphData.fundIntensityValue === assetsOrderByColumnChange.energyIntensity){
    bauIntensityValue = graphData.weightedAverageEnergyIntensity
  } else {
    bauIntensityValue = graphData.bauGhgIntensity
  }
  return bauIntensityValue
}

export function partitionBAUGraphData(nzcGraphData){
  let mainIndex=0
  let mainBAUData=[]
  nzcGraphData.forEach((graphData, index, srcGraphData)=>{
    if(graphData.isActualConsumptionData){
      if(index===0){
        mainBAUData[mainIndex]=[graphData]
      } else {
        if(srcGraphData[index-1].isActualConsumptionData){
          mainBAUData[mainIndex].push(graphData)
        } else {
          mainIndex++
          mainBAUData[mainIndex]=[graphData]
        }
      }
    } else {
      if(index===0){
        mainBAUData[mainIndex]=[graphData]
      } else {
        if(srcGraphData[index-1].isActualConsumptionData){
          mainIndex++
          mainBAUData[mainIndex]=[graphData]
        } else {
          mainBAUData[mainIndex].push(graphData)
        }
      }
    }
  })
  return mainBAUData
}

export function createBAUGraphData(mainGraphDataset, bauGhgIntensity, nzcGraphData, fundIntensityValue, reportingYear) {
  const partitionGraphData = partitionBAUGraphData(nzcGraphData)
  let solidLineData = []
  let dashLineData = []
  partitionGraphData.forEach((bauData, index) => {
    let nextLineData = partitionGraphData[index + 1] && partitionGraphData[index + 1][0]
    if (bauData[0].isActualConsumptionData) { // Plot solid line
      solidLineData = bauData.map(bau => ({x: bau.year, y: bauAvgIntensity({...bau, fundIntensityValue})}))
      if (index === 0) {
        mainGraphDataset.push({...(bauGhgIntensity), data: solidLineData})
      } else {
        mainGraphDataset.push({...(bauGhgIntensity), data: solidLineData, label: FUND_BAU_LINE})
      }
      const lastBauData = bauData[bauData.length - 1]
      if (lastBauData.year === reportingYear) { // skip solid line for the previous year
        mainGraphDataset.push({
          ...(bauGhgIntensity),
          borderDash: [4, 3],
          label: FUND_BAU_LINE,
          data: [{
            x: lastBauData.year,
            y: bauAvgIntensity({...lastBauData, fundIntensityValue})}, {
            x: nextLineData?.year,
            y: bauAvgIntensity({...nextLineData, fundIntensityValue})
          }]
        })
      } else {
        solidLineData.push({x: nextLineData?.year, y: bauAvgIntensity({...nextLineData, fundIntensityValue})})
      }
    } else { // Plot dash line
      dashLineData = bauData.map(bau => ({x: bau.year, y: bauAvgIntensity({...bau, fundIntensityValue})}))
      mainGraphDataset.push({
        ...(bauGhgIntensity),
        data: dashLineData,
        borderDash: [4, 3],
        label: FUND_BAU_LINE
      })
      if(nextLineData){
        dashLineData.push({x: nextLineData?.year, y: bauAvgIntensity({...nextLineData, fundIntensityValue})})
      }
    }
  })
}

export function createBauDataset(originalData, nzcData, rectBau, selectedLanguage, xAxisBaseYear, fundName, fundIntensityValue, reportingYear) {
  const bauGraphData = nzcData?.graphData
  const assetGraphData = nzcData?.assetGraphData
  if(bauGraphData){
    const diamondDatasets=[]
    const fundProgressBauLine=[]
    const bauGhgIntensity={
      type: 'line',
      order: 2,
      borderColor: CSS_VARIABLES.pink,
      backgroundColor: CSS_VARIABLES.pink,
      borderWidth: 2,
      fill: false,
      pointStyle: rectBau,
      data: [],
      datalabels:{display:false},
      graphName: FUND_BAU_LINE,
    }
    const fundProgressGraphData={
      type: 'bubble',
      data: [],
      backgroundColor: CSS_VARIABLES.pink,
      borderColor: CSS_VARIABLES.pink,
      pointStyle: 'rectRot',
      datalabels: {display: false},
      language: selectedLanguage,
      graphName: 'fundBAUBubble',
      clip: false
    }
    bauGraphData.forEach((graphData) => {
      if (graphData.year >= xAxisBaseYear && graphData.year <= reportingYear) {
        const fundNameAndDataAvailability =  {fundName, dataAvailabilityOfAssets: createDataAvailabilityOfAssets(assetGraphData, graphData)}
        const fundBauChartData={bauGhgIntensity: bauAvgIntensity({...graphData, fundIntensityValue}), year: graphData.year, ...fundNameAndDataAvailability, intensity: fundIntensityValue}
        if (xAxisBaseYear === graphData.year) {
          diamondDatasets.push({...fundProgressGraphData, xAxisID: 'xAxisAssetIntensities', data: [{x: 0, y: bauAvgIntensity({
            ...graphData,
            fundIntensityValue
          }), r: 9}], fundBAUData: fundBauChartData})
        } else {
          diamondDatasets.push({...fundProgressGraphData, data: [{x: graphData.year, y: bauAvgIntensity({
            ...graphData,
            fundIntensityValue
          }), r: 9}], fundBAUData: fundBauChartData})
        }
      }
    })
    createBAUGraphData(fundProgressBauLine, bauGhgIntensity, bauGraphData, fundIntensityValue, reportingYear)
    originalData.push(...fundProgressBauLine)
    originalData.push(...diamondDatasets)
  }
}

export function getExportFileName(fundName, scaleSelection, selectedLanguage, exportType) {
  let exportImageName
  if(EXPORT_IMAGE_SCALES.small===scaleSelection){
    exportImageName='Small'
  } else if(EXPORT_IMAGE_SCALES.medium===scaleSelection){
    exportImageName='Medium'
  } else if(EXPORT_IMAGE_SCALES.large===scaleSelection){
    exportImageName='Large'
  }
  const options = {year: 'numeric', month: 'short', day: 'numeric' }
  if (exportType === EXPORT_TYPE_PNG) {
    return (`${fundName}-NZC-${exportImageName}-${new Date().toLocaleDateString(selectedLanguage, options)}.${EXPORT_TYPE_PNG.toLowerCase()}`)    
  }
  else throw new Error('Invalid export type')
    
}

export const overlayPanelErrorHandler=(event) => {
  const errorStack=event?.error?.stack
  if(errorStack && (errorStack.includes('onContentClick') || errorStack.includes('isPanelClicked'))){
    // Suppressing the OverlayPanel Bug-2338 from primereact 6.6.0 version
    // This needs to be tested and removed when primereact version is updated
    // https://github.com/primefaces/primereact/issues/2338
    event.preventDefault()
    event.stopPropagation()
  }
}

function createDataAvailabilityOfAssets(assetGraphData, graphData) {
  const actualData = []
  const upliftedData = []
  const benchmarkedData = []
  const dataAvailabilityOfAssets = { actualData, upliftedData, benchmarkedData }
  const assetData = assetGraphData?.map(data => data?.graphData?.find(asset => asset.year === graphData?.year))
  if (assetData) {
    assetData.forEach(asset => {
      if (asset){
        let roundedPercentage = Math.trunc(asset.dataAvailablePercentage * 100)
        if (roundedPercentage <= 49) {
          benchmarkedData.push(asset)
        } else if (roundedPercentage > 49 && roundedPercentage <= 99) {
          upliftedData.push(asset)
        } else if (roundedPercentage > 99) {
          actualData.push(asset)
        }
      }
    })
  }
  return dataAvailabilityOfAssets
}

export function getNZCChartSieraPlusLogo(){
  return  <div className="nzc-chart-loading-icon-container">
    <svg width="100%" height="100%" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(1,1)">
        <path className="outer-stroke" d="M18.7206 1.8575C17.2641 2.76753 16.0556 4.05019 14.9153 5.89484C13.465 8.24172 11.6455 10.378 9.50829 12.2455C8.29476 13.307 6.98406 14.2706 5.6164 15.1069C2.1562 17.189 0.0564042 20.9358 0 25.1542C0.0580795 29.258 2.40695 33.1183 6.11845 35.2232C7.70782 36.202 9.06599 37.2455 10.2683 38.4125C11.7215 39.8388 13.5582 41.76 14.8734 43.9683C16.905 47.6325 20.8746 50 24.9848 50C25.4014 50 25.8225 49.9754 26.229 49.9274C29.9808 49.5743 33.3991 47.2487 35.3643 43.7208C36.8023 41.4158 38.6134 39.3393 40.7579 37.5427C41.8066 36.6003 43.0213 35.7199 44.5827 34.7713C46.3246 33.7127 47.6905 32.2591 48.6399 30.4591C51.3038 25.691 49.9339 19.175 45.5857 15.9348L44.7391 15.346C42.0161 13.4612 39.4449 11.6802 37.449 9.11153C36.4773 7.97636 35.6999 6.90879 35.0739 5.84792C33.7917 3.67646 32.1755 2.15023 30.1427 1.18712C28.5723 0.392168 26.8199 0 25.0619 0C22.8398 -0.000558647 20.6082 0.626241 18.7206 1.8575Z" fill="none" stroke="url(#paint0_linear_23706_25714)"/>
        <circle className="inner-stroke" cx="25" cy="25" r="15" fill="none" stroke="url(#paint0_linear_23706_25714)"/>
      </g>
      <defs>
        <linearGradient id="paint0_linear_23706_25714" x1="0" y1="0" x2="50.017" y2="49.983" gradientUnits="userSpaceOnUse">
          <stop stopColor={CSS_VARIABLES.green}/>
          <stop offset="1" stopColor={CSS_VARIABLES.green}/>
        </linearGradient>
      </defs>
    </svg>
  </div>

}