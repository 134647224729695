import {memo, useContext, useEffect, useMemo, useRef, useState} from 'react'
import './FundDataQualityHomeV2.scss'
import {CachedUserState, completenessDaysToPercentages, createEndMonthParam, getFundViewsNavLinkConfigsForFlags, unitsConverter} from '../../../../utils/helpers/Helper'
import {updateQueryParamsForFundViews} from '../../../../utils/helpers/DatePickerUtils'
import {FeatureFlagContext} from 'Contexts'
import buildQuery from 'odata-query'
import {useHistory, useParams} from 'react-router-dom'
import {AREA, CacheKeyFundGroups, DATA_FROM, EXPORT, FUND_VIEWS_PAGES, PERIOD_TYPES} from '../../../../utils/helpers/Constants'
import FundCommonHeader from '../common-header/FundCommonHeader'
import {getTimeperiodLabel, periodOptions} from '../../../../services/common/datepicker-service'
import DataQualityAssetList from './asset-list/DataQualityAssetList'
import useFundDataQualityData from './data-quality-hooks-&-helpers/useFundDataQualityData'
import {fundDataQualityStateInitialiser, getQueryParams} from './data-quality-hooks-&-helpers/fundDataqualityHelper'
import useFundDataQualityState from './data-quality-hooks-&-helpers/useFundDataQualityState'
import FundDataQualityFilterBar from './fund-dq-components/FundDataQualityFilterBar'
import {useLoginAuthContext} from '../../login/auth0/UserInfoProvider'
import OverlayPanel from '../../../atomic/OverlayPanel/OverlayPanel'
import DataCategoriesInformation from './fund-dq-components/DataCategoriesInformation'
import InfoOverlayPanelContent from '../../../atomic/OverlayPanel/OverlayPanelContent/OverlayPanelContent'
import useFundCoverageCompletenessData from '../../../../utils/custom-hooks/data-quality/useFundCoverageCompletenessData'
import DataQualityCompletenessGraph from '../../assets/data-quality/dq-components/DataQualityCompletenessGraph'
import { GetFundAgents, GetFundDataQualityExportDataV2 } from 'services/funds/fund-data-quality-service'
import {useQuery} from 'react-query'
import {noRefetchOnWindowFocus} from 'services/common/useQuery-config'
import UseUrlParameterIds from '../../../../utils/custom-hooks/UseUrlParameterIds'
import DataQualityCoverageGraph from 'components/pages/assets/data-quality/dq-components/DataQualityCoverageGraph'
import { useI18n } from 'utils/i18n/I18n'
import { dataQualityExportV2 } from 'utils/data-exports/data-quality-v2/dataQualityExportV2'

const FundDataQualityHomeV2 = () => {

  //region Initialization
  const [shouldExport, setShouldExport] = useState(false)
  const {instanceName, orgName, fundName} = useParams()
  const history = useHistory()
  const {fundGroupId, actualFundName}=UseUrlParameterIds({instanceName, orgName, fundName})
  const querySearchParams = new URLSearchParams(document.location.search)
  const {loginState} = useLoginAuthContext()
  const selectedLanguage = loginState.userInfo.languagePreference
  const unitSystem = loginState.userInfo.unitSystem
  const {featureState} = useContext(FeatureFlagContext)
  const selectedFundRef=useRef(CachedUserState.getUserDataFromCache(CacheKeyFundGroups))
  const areaLabel = unitsConverter(AREA, unitSystem)
  const intl = useI18n()

  const {
    fundDataQualityState: {
      periodType,
      pickerValue,
      sortField,
      sortOrder,
      oDataSortOrder,
      selectedUtility,
      isTimePeriodChanged,
      multiSelectedUtility,
      selectedView,
      multiSelectedAgent,
      selectedAgent,
      first,
      rows
    }, updateFundDataQualityState
  } = useFundDataQualityState(() => fundDataQualityStateInitialiser(querySearchParams, selectedLanguage))
  const oDataQuery = buildQuery({orderBy: `${sortField} ${oDataSortOrder}`})
  const time = getTimeperiodLabel(periodType)
  const timeEndDate =  createEndMonthParam(pickerValue.pickerValueEndYear, pickerValue.pickerValueEndMonth)
  // API Data
  const apiParamsForAssetsQuery={
    fundGroupId,
    oDataQuery,
    selectedUtility,
    time,
    timeNoOfMonths: pickerValue.pickerValueMonths,
    timeEndDate,
    isTimePeriodChanged,
    selectedView,
    featureFundDQLayoutV2: featureState?.sieraplus_fundDataQualityLayoutV2,
    selectedAgent,
    first, 
    rows,
    oDataSortOrder
  }
  const {fundDQAssetsDataLoading, fundDQAssetsData}=useFundDataQualityData(apiParamsForAssetsQuery)
  const timeEndDatePeriod = createEndMonthParam(pickerValue.pickerValueEndYear, pickerValue.pickerValueEndMonth)
  
  const apiParams = {
    timePeriod: time,
    timeNoOfMonthsPeriod: pickerValue?.pickerValueMonths,
    timeEndDatePeriod,
    utilitySelected: selectedUtility?.map(item => item.key),
    selectedView,
    selectedAgent
  }

  const {
    fundCompleteness: {data: fundCompletenessData, isLoading: fundCompletenessLoading},
    fundCoverage: {data: fundCoverageData, isLoading: fundCoverageLoading}
  } = useFundCoverageCompletenessData(fundGroupId, apiParams, unitSystem)
  // endregion

  const dataCoverageOverlayRef = useRef(null)
  const dataCompletenessOverlayRef = useRef(null)

  const {
    isLoading: fundAgentsLoading,
    data: fundAgentsData
  } = useQuery({
    queryKey: ['GetFundAgents', fundGroupId],
    queryFn: GetFundAgents,
    refetchOnWindowFocus: noRefetchOnWindowFocus.refetchOnWindowFocus
  })

  const {
    isLoading: fundExportLoading,
    data: fundExportData,
    isError: fundExportError
  } = useQuery({
    queryKey: ['GetFundDataQualityExportDataV2', fundGroupId, apiParams, unitSystem],
    queryFn: GetFundDataQualityExportDataV2,
    refetchOnWindowFocus: noRefetchOnWindowFocus.refetchOnWindowFocus,
    enabled: shouldExport
  })

  const multiSelectAgentOptions = fundAgentsData ?
    fundAgentsData.map((agent) => {return {label: agent.agentName, value: agent.agentName}}) : []

  useEffect(() => {
    updateQueryParamsForFundViews(getQueryParams(periodType, pickerValue, isTimePeriodChanged), null, history, instanceName, orgName, fundName, FUND_VIEWS_PAGES.dataQuality)
  }, [periodType, pickerValue, multiSelectedUtility, multiSelectedAgent])

  useEffect(() => {
    if(shouldExport && !fundExportError && !fundExportLoading) {
      triggerExportHandler()
    }
  }, [shouldExport, fundExportError, fundExportLoading])

  const onUtilityChange = (e) => {
    if(e.length !== 0) {
      updateFundDataQualityState({selectedUtility: e, isTimePeriodChanged: false, multiSelectedUtility:e, first: 0, rows: 10})
    }
  }

  const onAgentChange = (e) => {
    updateFundDataQualityState({selectedAgent: e, isTimePeriodChanged: false, multiSelectedAgent:e, first: 0, rows: 10})
  }

  const onExportClick = () => {
    setShouldExport(true)
  }

  const triggerExportHandler = async () => {
    if (!fundExportLoading && !fundExportError && fundExportData) {
      const sheetHeaderProps = {
        fundName: actualFundName,
        timePeriod: periodType,
        pickerValue
      }
      await dataQualityExportV2({exportData: fundExportData, sheetHeaderProps, formatMessage: intl.formatMessage, selectedLanguage, unitSystem})
      setShouldExport(false)
    }
  }
  
  function periodChangeAction(changeEvent) {
    const newPeriod=changeEvent.value
    updateFundDataQualityState({periodType: newPeriod, isTimePeriodChanged: true})
  }

  function datePickerChangeAction(changeEvent) {
    const newPickerValue={...pickerValue, [changeEvent?.target?.name]: changeEvent?.target?.value}
    updateFundDataQualityState({pickerValue: newPickerValue, isTimePeriodChanged: true})
  }

  function customPeriodAction() {
    updateFundDataQualityState({periodType: PERIOD_TYPES.CUSTOM})
  }

  function getFundAssetsTableProps() {
    return {
      fundAssetsTableData : {data:fundDQAssetsData?.data, loading :fundDQAssetsDataLoading, totalAssets: fundDQAssetsData?.total},
      sortField,
      sortOrder,
      updateFundDataQualityState,
      selectedLanguage,
      fundGroupId: fundGroupId,
      isFundDataQualityV2: true,
      first,
      rows,
    }
  }

  function dataCompletenessInfoOverlay(e){
    dataCompletenessOverlayRef.current.toggle(e)
  }
  function dataCoverageInfoOverlay(e){
    dataCoverageOverlayRef.current.toggle(e)
  }

  const excelExportInfo = useMemo(() => {
    return {
      'options': {
        'enableExport': !fundDQAssetsDataLoading && fundDQAssetsData && fundDQAssetsData?.length !== 0 ? 'enabled' : 'disabled',
        'hasOverlay': false,
        'dataFrom': DATA_FROM.FUND.FUND_DATA_QUALITY_V2,
        'isLoading': fundExportLoading,
      },
    }
  }, [fundDQAssetsData, fundExportLoading])

  return (
    <div data-page_identifier="fund_data_quality" className="grey-bg bbl min-width-1280 fund-dq-page-v2">
      <div className={'container-layout fund-dq-content gutter'}>
        <div className={'fund-header-section'}>
          <FundCommonHeader
            hasDatePicker={true}
            hasBlueBackground={false}
            navLinkConfigs={getFundViewsNavLinkConfigsForFlags(selectedFundRef.current, selectedLanguage, featureState)}
            selectedFund={selectedFundRef.current}
            period={periodType}
            periodOptions={periodOptions}
            pickerValue={pickerValue}
            datePickerOnChange={periodChangeAction}
            customDatePickerOnClick={customPeriodAction}
            pickerOnChange={datePickerChangeAction}
            action={EXPORT}
            onActionClick={onExportClick}
            excelExportInfo={excelExportInfo}
          />
        </div>
        <FundDataQualityFilterBar
          multiSelectOnChangeHandler={onUtilityChange}
          multiSelectedOptions={multiSelectedUtility}
          multiSelectAgentOnChangeHandler={onAgentChange}
          multiSelectedAgentOptions={multiSelectedAgent}
          multiSelectAgentOptions={multiSelectAgentOptions}
          selectedLanguage={selectedLanguage}
          updateFundDataQualityState={updateFundDataQualityState}
          selectedView={selectedView}
          disableAgentDropDown={fundAgentsLoading || !multiSelectAgentOptions || multiSelectAgentOptions.length === 0}
        />
        <div className={'dq-container'}>
          <div className='fund-dq-tabview-section col-12 flex flex-row pt-4'>
            <div className={'col-5 dq-doughnut-chart flex align-items-center pl-4'}>
              <DataQualityCoverageGraph 
                coverageData={fundCoverageData}
                areaUnit={areaLabel}
                selectedLanguage={selectedLanguage}
                actionHandler={dataCoverageInfoOverlay}
                isLoading={fundCoverageLoading}
              />
              <OverlayPanel
                panelRef={dataCoverageOverlayRef}
                className={'coverage-overlay-panel'}
                appendTo={'self'}
                panelChildren={
                  <InfoOverlayPanelContent
                    iconId={'info_solid'}
                    header={'t_data_coverage'}
                    headerClassName={'text-base'}
                    panelContent={<DataCategoriesInformation data='t_fund_data_coverage' className={'text-sm'}/>}
                  />}
              />
            </div>
            <div className={'col-7 dq-bar-chart flex justify-content-center flex-column'}>
              <OverlayPanel
                panelRef={dataCompletenessOverlayRef}
                className={'completeness-overlay-panel'}
                appendTo={'self'}
                panelChildren={
                  <InfoOverlayPanelContent
                    iconId={'info_solid'}
                    header={'t_data_completeness'}
                    headerClassName={'text-base'}
                    panelContent={<DataCategoriesInformation data='t_fund_data_completeness' className={'text-sm'}/>}
                  />}
              />
              <DataQualityCompletenessGraph
                completenessData={completenessDaysToPercentages(fundCompletenessData)}
                selectedLanguage={selectedLanguage}
                actionHandler={dataCompletenessInfoOverlay}
                isLoading={fundCompletenessLoading}
              />
            </div>
          </div>
          <div className={'fund-datatable-container'}>
            <DataQualityAssetList {...getFundAssetsTableProps()} />
          </div>
        </div>
      </div>
    </div>
  )
}

FundDataQualityHomeV2.displayName = 'FundDataQualityHomeV2'

export default memo(FundDataQualityHomeV2)