import React, {useContext} from 'react'
import {Skeleton} from 'primereact/skeleton'
import './DataQualityMissingDataSkeleton.scss'
import {Column} from 'primereact/column'
import {DataTable} from 'primereact/datatable'
import '../../../pages/assets/data-quality/missing-data/table/MissingDataTable.scss'
import {FeatureFlagContext} from 'Contexts'

const DataQualityMissingDataSkeleton = () => {
  const featureContext = useContext(FeatureFlagContext)
  const featureState = featureContext.featureState

  const dummyArray = Array.from({ length: 7 })
  const bodyTemplate = (length) => {
    return <Skeleton width={length}/>
  }

  const headerTemplate = () => {
    return <div className="p-ml-1">
      <Skeleton width={'4rem'}/>
    </div>
  }

  return (
    <div className="custom-skeleton rel missing-datatable">
      <DataTable value={dummyArray} className="user-funds-grid"  stripedRows scrollHeight='850px' scrollable>
        {featureState && <Column selectionMode="multiple" style={{width: '2%'}}/>}
        <Column field="electricityMPAN" header={headerTemplate()} body={()=>bodyTemplate('87%')} style={{width: '17%'}}/>
        <Column field="dateSummary" header={headerTemplate()} body={()=>bodyTemplate('75%')} style={{width: '15%'}}/>
        <Column field="utility" header={headerTemplate()} body={()=>bodyTemplate('67%')} style={{width: '9%'}}/>
        <Column field="provider" header={headerTemplate()} body={()=>bodyTemplate('77%')} style={{width: '9%'}}/>
        <Column field="location" header={headerTemplate()} body={()=>bodyTemplate('87%')} style={{width: '10%'}}/>
        <Column field="floorArea" header={headerTemplate()} body={()=>bodyTemplate('58%')} style={{width: '8%'}}/>
        <Column field="source" header={headerTemplate()} body={()=>bodyTemplate('44%')} style={{width: '8%'}}/>
        <Column field="consumption" header={headerTemplate()} body={()=>bodyTemplate('60%')} style={{width: '10%'}}/>
        {featureState && <Column field="submittedBy" body={()=>bodyTemplate('87%')}
          header={headerTemplate()} style={{width: '17%'}}/>}
      </DataTable>
    </div>
  )
}

export default DataQualityMissingDataSkeleton
