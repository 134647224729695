import PropTypes from 'prop-types'
import I18n from '../../../../../../utils/i18n/I18n'
import './DataQualityTile.scss'
import {getBarChartData, getDQBarchartData, getDQBarChartOptions} from '../../../../funds/fund-dashboard/fund-dashboard-hooks-&-helpers/fundDashboardHelper'
import {Bar} from 'react-chartjs-2'
import {trucateNumWOneDecimal} from '../../../../../../services/common/formatter'
import {getDQTileBarChartLegends} from '../../../../../../utils/helpers/Helper'
import PeriodView from '../../../../../common/period-view/PeriodView'
import Text from '../../../../../atomic/Text/Text'

function DataQualityTile({dataQualityData, selectedLanguage, timePeriod, pickerValue}) {

  const {actual, estimated, missing, noCoverage} = dataQualityData
  const dataMissingPercentage = trucateNumWOneDecimal(dataQualityData?.dataMissingPercentage ?? 0)
  const {barData: barDataValue, yMax} = getBarChartData(dataQualityData)

  function isDqDataEmpty() {
    return !!(actual === 0 && estimated === 0 && missing === 0 && noCoverage === 0)
  }

  function getDQChartLegends() {
    return getDQTileBarChartLegends().map((item) => {
      return <div className={`dq-tile__footer__legends__legend-item ${item.className}`} key={item.assetApiKey}>
        <img src={item.icon} alt="actual-data3"/>
        <span> {trucateNumWOneDecimal(dataQualityData?.[item.assetApiKey] * 100)}% {item.label}</span>
      </div>
    })
  }

  return (
    <div className="dq-tile" data-testid="data-quality-tile">
      <div className="dq-tile__header">
        <div className="dq-tile__header__row-1 flex">
          <Text className="dq-tile__header__row-1__title teal bold" content="t_data_quality" />
          <PeriodView timePeriod={timePeriod} pickerValue={pickerValue} />
        </div>
        <div className="dq-tile__header__chart-label">
          {!isDqDataEmpty() &&
              <span className="dq-bar__missing-dataxx">{dataMissingPercentage}% {I18n('t_missing_data')}</span>}
        </div>

      </div>
      {!isDqDataEmpty() &&
            <>
              <div className="dq-tile__body">
                <Bar data={getDQBarchartData(barDataValue, selectedLanguage)} options={getDQBarChartOptions(yMax)}
                  className="dq-tile__body__bar-chart"/>
              </div>
              <div className="dq-tile__footer">
                <div className="dq-tile__footer__legends">{getDQChartLegends()}</div>
              </div>
            </>
      }
      { isDqDataEmpty() && <Text content="t_msg_no_data" /> }
    </div>
  )
}

DataQualityTile.propTypes = {
  dataQualityData: PropTypes.object,
  selectedLanguage: PropTypes.string,
  timePeriod: PropTypes.string,
  pickerValue: PropTypes.object
}

DataQualityTile.displayName = 'DataQualityTile'

export default DataQualityTile