export function getLocalStorageItem(key) {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  return userInfo ? userInfo[key] : null
}

export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

export const allValuesZero = (obj) => {
  return Object.values(obj).every(value => value === 0)
}