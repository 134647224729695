import {memo} from 'react'
import PropTypes from 'prop-types'
import {getLocalizedValue} from '../../../../utils/helpers/Helper'

const FundDataQualityChart = ({chartData, labelOverlapClass, selectedLanguage}) => {

  let mapValueToLabel = {
    actualPercentage: {label: 't_actual', cssClass: 'blue'},
    estimatedPercentage: {label: 't_estimated', cssClass: 'yellow'},
    incompletePercentage: {label: 't_incomplete', cssClass: 'orange'},
    notCoveredPercentage: {label: 't_not_covered', cssClass: 'red'}
  }

  function isChartDataNotEmpty() {
    return chartData?.[0]?.length > 0
  }

  return (
    <>
      <div className={`labels ${labelOverlapClass ?? ''}`}>
        { isChartDataNotEmpty() ?
          chartData?.map((bar) => {
            return (
              <div key={bar[0]} style={{flex: `0 1 ${Math.round(bar[1] * 100)}%`}}>
                <span className={`${mapValueToLabel[bar[0]].cssClass}-label chart-label`}></span>
                <div>
                  <span className="value">{Math.round(bar[1] * 100)}% </span>
                  <span className="unit">{getLocalizedValue(selectedLanguage, mapValueToLabel[bar[0]].label).toUpperCase()}</span>
                </div>
              </div>
            )
          }) :
          <div>
            <span className="red-label chart-label"></span>
            <div>
              <span className="value">100% </span>
              <span className="unit">{getLocalizedValue(selectedLanguage, 't_not_covered').toUpperCase()}</span>
            </div>
          </div>
        }
      </div>
      <div className="bars">
        { isChartDataNotEmpty() ?
          chartData?.map((bar) => {
            return (<span data-percentage={Math.round(bar[1] * 100)}  className={`${mapValueToLabel[bar[0]].cssClass}-bar`} style={{width: `${Math.round(bar[1] * 100)}%`}} key={bar[0]}></span>)
          }) :
          <span className="red-bar" style={{width: '100%'}}></span>
        }
      </div>
    </>
  )
}

FundDataQualityChart.propTypes = {
  chartData: PropTypes.array.isRequired,
  labelOverlapClass:  PropTypes.string,
  selectedLanguage: PropTypes.string
}

FundDataQualityChart.displayName = 'FundDataQualityChart'

export default memo(FundDataQualityChart)