import {AddDocument, documentTypeCheck} from '../AddDocument'
import CustomSidebar from '../../../../common/custom-sidebar/CustomSidebar'
import './DocumentSidebar.scss'
import {useQuery} from 'react-query'
import {
  GetGroupsListById,
  getUserAssetData,
  GetUserById
} from '../../../../../services/admin/users-service'
import {noRefetchOnWindowFocus} from '../../../../../services/common/useQuery-config'
import {
  convertDateToUTCFormat,
  getLocalizedValue,
  getLocalStorageItem,
  unitDateFormatter
} from '../../../../../utils/helpers/Helper'
import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import I18n from '../../../../../utils/i18n/I18n'
import {
  availableCapacity, editDocument,
  getPresignedURL,
  uploadDocumentUsingPresignedURL
} from '../../../../../services/documents/documents-service'
import {Accordion, AccordionTab} from 'primereact/accordion'
import {fileSizeToBytes, formatFileSize} from '../../DocumentsUtil'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'
import ErrorDialog from '../../../assets/data-quality/error-dialog/ErrorDialog'
import { Button, ButtonWrapper, Icon, Text } from 'components/atomic/index'

const getInitialValues = (selectedRow,isEdit=false)=> {
  const getUserAccess = getLocalStorageItem('userFundAccessType')
  const recordTypeNew = getUserAccess === 'NoFundAccess' ? 'Asset' : ''
  const recordTypeEdit = selectedRow?.assetName ? 'Asset' : 'Fund'
  const INVALID_DATE = 'Invalid date'
  return {
    documentType : selectedRow?.documentType || null,
    recordType: isEdit ? recordTypeEdit : recordTypeNew,
    expiryDate:selectedRow?.expiryDate && selectedRow?.expiryDate !== INVALID_DATE? new Date(selectedRow?.expiryDate)  : '',
    comment:selectedRow?.comment || '',
    fundType:Number(selectedRow?.assetId) || Number(selectedRow?.fundId) || '',
    reportDate:selectedRow?.reportDate && selectedRow?.reportDate !== INVALID_DATE? new Date(selectedRow?.reportDate)  : '',
    selectedFile: selectedRow?.fileName || null,
    isErrors:selectedRow?.isErrors || false,
    isDirty:selectedRow?.isDirty || false,
    isTabEdited:selectedRow?.isTabEdited || false,
    filePath:selectedRow?.filePath || ''
  }
}

const setNewTab =  (index,selectedRow,isEdit=false)=> {
  return {
    tabIndex: index,
    data: getInitialValues(selectedRow,isEdit)
  }
}

export function DocumentSidebar({showSidebar,setShowSidebar,selectedRows,isEdit,setSelectedRows,refetch, documentNames}){
  const [uploadedFiles] = useState([])
  const getUserId = getLocalStorageItem('userId')
  const [addDocument,setAddDocument] = useState([])
  const [submitted,setSubmitted] = useState(false)
  const [errorDialog, setErrorDialog] = useState({errorDialogVisible: false, error: ''})
  const selectedUser  = useQuery(getUserId, GetUserById, noRefetchOnWindowFocus)
  const userGroupData = useQuery(['userFunds', getUserId], GetGroupsListById, noRefetchOnWindowFocus)
  const userStorageLimit = useQuery('userStorageLimit', availableCapacity, noRefetchOnWindowFocus)
  const getUserAssetDataQuery = useQuery(['assetOptions', selectedUser?.data?.authorisationId], getUserAssetData, {
    enabled: typeof selectedUser?.data?.authorisationId !== 'undefined',...noRefetchOnWindowFocus})
  const [showConfirmationText, setShowConfirmationText] = useState(false)
  const [activeIndex,setActiveIndex] = useState(0)
  const accordionClass = addDocument.length === 1 ? 'document-sidebar__accordion accordion-header-hide' : 'document-sidebar__accordion'
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const unitSystem = userInfo.unitSystem
  const totalSize = addDocument?.reduce((accumulator, document) => {
    const fileSize = document?.data?.selectedFile?.size || 0
    return accumulator + fileSize
  }, 0)
  const TWO_GB = fileSizeToBytes('2 GB')
  const isExceeded = TWO_GB > totalSize+fileSizeToBytes(userStorageLimit.data)

  const isSaveButtonDisabled = () => {
    const isDirty = addDocument.some(document=>document.data.isDirty)
    return addDocument.some(document => {
      return document.data.isErrors || !isDirty
    })
  }

  const isNewButtonLabel = addDocument.length > 1 ? 't_save_documents' :'t_save_document'
  const isEditButtonLabel = addDocument.length > 1 ? 't_update_documents' :'t_update_document'
  const saveButtonLabel = isEdit ? isEditButtonLabel : isNewButtonLabel

  useEffect(()=>{
    if(selectedRows){
      const selectedDocumentRows = selectedRows.map((row,index)=>{
        return setNewTab(index,row,isEdit)
      })
      setAddDocument(selectedDocumentRows)
    }else{
      setAddDocument([setNewTab(0,null)])
    }
  },[selectedRows])

  function accordionStateManagement(document) {
    setAddDocument(prevTabs => {
      return prevTabs.map((tab, index) => {
        if (index === document.activeIndex) {
          if(document.activeIndex!==activeIndex){
            return {...tab,
              data: {
                ...document.data,
                isTabEdited: document.activeIndex!==activeIndex && document.data.isDirty
              }
            }
          }else{
            return {...tab,
              data: {...document.data}
            }
          }
        }
        return tab
      })
    })
  }

  async function handleSaveClick() {
    await setSubmitted(true)
    const getEditDocList = addDocument.filter(document=>document.data.filePath)
    const getNewAddDocList = addDocument.filter(document=>document.data.filePath === '')
    if(getEditDocList.length > 0){
      await editExistingDocument(getEditDocList,!getNewAddDocList.length > 0)
    }
    if(getNewAddDocList.length > 0){
      const status = await uploadNewDocument(getNewAddDocList,false)
      if(status){
        await editExistingDocument(getNewAddDocList,true)
      }
    }
    await new Promise(resolve => setTimeout(resolve, 2000))
    refetch()
    setSelectedRows([])
    setShowSidebar(false)
    setSubmitted(false)
  }

  async function uploadNewDocument(documents,messageDisplay) {
    let documentDataSelectedFileName = ''
    try {
      for (const document of documents) {
        const documentDataFundType = document.data.fundType
        documentDataSelectedFileName = document.data.selectedFile.name
        const setPath = await getPathToUpload(document,documentDataFundType,documentDataSelectedFileName)
        document.data.filePath = setPath
        if (setPath && (uploadedFiles.length === 0 || uploadedFiles.every(file=>file!==setPath))) {
          const presignedUrl = await getPresignedURL(setPath)
          const isUploadSuccess = await uploadDocumentUsingPresignedURL(document.data.selectedFile, presignedUrl.presignedUrl)
          if (isUploadSuccess.statusText === 'OK') {
            uploadedFiles.push(setPath)
            if(messageDisplay){
              setShowConfirmationText(true)
            }
          }else{
            console.error('Failed to upload file:', documentDataSelectedFileName, '-', isUploadSuccess.statusText)
          }
        }
      }
      return true
    } catch (error) {
      setErrorDialog({errorDialogVisible: true, error: {message: `${documentDataSelectedFileName} : ${error.response?.data}`,
        title: I18n('t_error_title')}})
      setSubmitted(false)
      return false
    }
  }

  async function getPathToUpload(document,documentDataFundType,documentDataSelectedFileName) {
    let setPath
    if (document.data.recordType === 'Fund') {
      setPath = `funds/${documentDataFundType}/${documentDataSelectedFileName}`
    } else {
      const selectedData = await getUserAssetDataQuery.data?.find(asset => ((asset.isGroupAccess || asset.isAssetAccess || asset.isFundAccess)
          && asset.assetId === documentDataFundType))
      const selectedDataFundId = selectedData?.fundId
      setPath = `funds/${selectedDataFundId}/assets/${documentDataFundType}/${documentDataSelectedFileName}`
    }
    return setPath
  }

  const createObjectTag = (isNewFile,userName,userId,setPath,document)=>{
    const objectTag = {
      oldKey: document.data.filePath,
      newKey: isEdit ? setPath : document.data.filePath,
      objectTags: {
        DocumentType: document.data.documentType,
        ReportDate:convertDateToUTCFormat(document.data.reportDate),
        ExpiryDate:convertDateToUTCFormat(document.data.expiryDate),
        Comment:document.data.comment
      }
    }
    if(!isEdit || isNewFile ){
      objectTag.objectTags.UserId = userId
      objectTag.objectTags.UserName = userName
      objectTag.objectTags.FileSize = formatFileSize(document.data.selectedFile.size)
      objectTag.objectTags.DateOfUpload = convertDateToUTCFormat(new Date())
    }
    return objectTag
  }

  async function editExistingDocument(documents,messageDisplay){
    const userId = selectedUser.data?.userId
    const userName = selectedUser.data?.userName
    let documentDataSelectedFileName = ''
    try {
      const modalObjectToEdit = await Promise.all(documents.map(async (document) => {
        const documentDataFundType = document.data.fundType
        const isNewFile = typeof document.data?.selectedFile === 'object'
        documentDataSelectedFileName = isNewFile ? document.data.selectedFile.name : document.data.selectedFile
        const setPath = await getPathToUpload(document, documentDataFundType, documentDataSelectedFileName)
        return createObjectTag(isNewFile,userName,userId,setPath,document)
      }))
      const update = await editDocument(modalObjectToEdit)
      if(update.status === 'Success' && messageDisplay){
        setShowConfirmationText(true)
      }
    }catch (error) {
      setErrorDialog({errorDialogVisible: true, error: {message: `${documentDataSelectedFileName} : ${error.response?.data}`,
        title: I18n('t_error_title')}})
      setSubmitted(false)
    }
  }

  const setValidationMessages=(item,index)=>{
    let errorMessage = ''
    if(index!==activeIndex && item.isErrors && item.isDirty){
      errorMessage = <Text colour='red' content={'t_validation_errors'} size={'xs'}/>
    }
    return <div className={'mt-1'}>
      {errorMessage}
    </div>
  }

  function getValidDate(date){
    return unitDateFormatter(date,unitSystem,selectedLanguage)
  }

  function getLocale(key){
    return getLocalizedValue(selectedLanguage,key)
  }

  const createHeader = (index,document)=>{
    const getDateLabel =  documentTypeCheck.includes(document.documentType) ? `${getLocale('t_valid_from_date')}` : `${getLocale('t_document_report_date')}`
    const isReportDate = document?.reportDate ? `${getDateLabel} - ${getValidDate(document.reportDate)}` : '-'
    const date = document?.expiryDate ? `${getLocale('t_document_expiry_date')} - ${getValidDate(document.expiryDate)}` : isReportDate
    const documentType = document?.documentType?? getLocale('t_add_new_document')
    const showThrashIcon = activeIndex===index ? 'delete-icon icon-show ml-2 mr-3' : 'delete-icon ml-2 mr-3'

    return <div className={'accordion-header flex'} data-testid={`tab-${index}`}>
      <div className={'mr-3 align-self-center'}>
        <Text className="p-badge p-badge-info p-badge-text" content={(index+1).toString()}/>
      </div>
      <div className={'accordion-title'}>
        <Text content={documentType} weight={'bold'} testId={'document-type'}/>
        <Text content={date}/>
        {setValidationMessages(document,index)}
      </div>
      <div className={showThrashIcon}>
        <div className={'align-self-center'}>
          <Button icon={<Icon id={'trash_can'}/>} onClick={()=>removeTab(index)} testId={'thrash-icon'}/>
        </div>
      </div>
    </div>
  }

  const removeTab = async (index) => {
    await setAddDocument(prevTabs => {
      const updatedTabs = prevTabs.filter((tab) => tab.tabIndex !== index)
      return updatedTabs.map((tab, newIndex) => ({
        ...tab,
        tabIndex: newIndex,
        header: createHeader(newIndex, tab)
      }))
    })
    setActiveIndex(addDocument.length === 2 ? 0 : null)
  }

  const createDynamicTabs = () =>{
    return addDocument.map((tab,index) => {
      return (
        <AccordionTab key={`${index}-${tab.tabIndex}`} header={addDocument.length > 1 &&  createHeader(index,tab.data)}
          tabIndex={tab.tabIndex}>
          <AddDocument
            getUserAssetDataQuery={getUserAssetDataQuery}
            userGroupData={userGroupData}
            saveClick={accordionStateManagement}
            submitted={submitted}
            index={index}
            getInitialValues={tab.data}
            activeIndex={activeIndex}
            isTotalSizeExceeded={isExceeded}
            documentNames={documentNames}
          />
        </AccordionTab>
      )
    })
  }

  const addNewDocumentForm = async () => {
    await setAddDocument([...addDocument, setNewTab(addDocument.length, null)])
    setActiveIndex(addDocument.length)
  }

  const onTabChange = (e)=>{
    setActiveIndex(e.index)
  }

  const hideSidebar = ()=>{
    setShowSidebar(false)
    if(isEdit){
      setSelectedRows([])
    }
  }

  return <CustomSidebar isSidebarVisible={showSidebar} dismissable={false} onHideSidebar={hideSidebar}
    customClass={'document-sidebar-container'} sidebarHeader={isEdit ? I18n('t_edit_document') : I18n('t_add_document')}>
    <div className={'document-sidebar'} data-testid={'documents-sidebar'}>
      <Accordion activeIndex={activeIndex} onTabChange={onTabChange} className={accordionClass}>
        {createDynamicTabs()}
      </Accordion>
      <ButtonWrapper onClick={addNewDocumentForm} testId={'add-new-accordion'}>
        <div className={'document-sidebar__add-new'}>
          <Icon id={'plus'} className={'p-badge p-badge-info'} size={24} testId={'plus'}/>
          <Text content={'t_add_another_document'} weight={'bold'} className={'document-sidebar__add-new--add-text'}/>
        </div>
      </ButtonWrapper>
      <div className={'document-sidebar__document-footer flex'}>
        <Button size='small' content={saveButtonLabel} className={'ml-5'} loading={submitted}
          onClick={handleSaveClick} disabled={isSaveButtonDisabled()} testId={'save-document'}/>
        {showConfirmationText && <div className={'ml-4 flex'}>
          <Icon id={'success_tick'} className={'mt-3'}/>
          <Text content={isEdit ? 't_document_updated' : 't_saved'} className={'document-sidebar__document-footer--saved'}/>
        </div>}
      </div>
    </div>
    <ErrorDialog
      data={errorDialog.error}
      dialogVisible={errorDialog.errorDialogVisible}
      onHideHandler={()=>setErrorDialog({errorDialogVisible:false,error:''})}
      closeHandler={()=>setErrorDialog({errorDialogVisible:false,error:''})} />
  </CustomSidebar>
}

DocumentSidebar.propTypes = {
  showSidebar : PropTypes.bool.isRequired,
  setShowSidebar : PropTypes.func.isRequired,
  selectedRows: PropTypes.array,
  isEdit:PropTypes.bool,
  setSelectedRows:PropTypes.func,
  refetch:PropTypes.func,
  documentNames:PropTypes.array,
}