import React, {memo, useEffect} from 'react'
import PropTypes from 'prop-types'
import {OverlayPanel} from 'primereact/overlaypanel'
import {RadioButton} from 'primereact/radiobutton'
import I18n from '../../../../../utils/i18n/I18n'
import {Button} from 'primereact/button'
import {useState} from 'react'
import './NzcExportOverlay.scss'
import {EXPORT_IMAGE_SCALES, EXPORT_TYPE_PNG} from '../../../../../utils/helpers/Constants'
import {createExportPNG} from '../nzc-utils/nzc-utils'
import {getExportFileName, overlayPanelErrorHandler} from '../nzc-chart-helper/nzc-chart-helper'

const NzcExportOverlay = ({exportOverlayRef, nzcGraphRef, selectedFund, docname, selectedLanguage, appendTarget,dataFrom, onShowExportOverlayPanel, onHideExportOverlayPanel, intensity}) => {

  const [exportScaleSelection, setExportScaleSelection] = useState()
  useEffect(()=>{
    const errorEvent='error'
    window.addEventListener(errorEvent, overlayPanelErrorHandler)
    return(()=>window.removeEventListener(errorEvent, overlayPanelErrorHandler))
  }, [])

  const onSelectionChange = (e) => {
    setExportScaleSelection(e.value)
  }

  async function exportHandler() {
    await createExportPNG(getExportFileName(selectedFund.fundname, exportScaleSelection, selectedLanguage, EXPORT_TYPE_PNG), selectedLanguage, exportScaleSelection, docname, nzcGraphRef, intensity)
    overlayHideHandler()
  }

  function overlayHideHandler() {
    exportOverlayRef.current.hide()
    setExportScaleSelection(null)
    onHideExportOverlayPanel()
  }

  return (
    <>
      <OverlayPanel ref={exportOverlayRef} onShow={onShowExportOverlayPanel} onHide={overlayHideHandler} className={`nzc-export-overlay ${dataFrom}`} appendTo={appendTarget}>
        <div className="export-overlay-content">
          <div className="overlay-option small" data-testid="export-small">
            <RadioButton className="option-checkbox option-checkbox-small" value={EXPORT_IMAGE_SCALES.small} onChange={onSelectionChange} checked={exportScaleSelection === EXPORT_IMAGE_SCALES.small}/>
            <label className="option-label">{I18n('t_png_small')}</label>
          </div>
          <div className="overlay-option medium">
            <RadioButton className="option-checkbox option-checkbox-medium" value={EXPORT_IMAGE_SCALES.medium} onChange={onSelectionChange} checked={exportScaleSelection === EXPORT_IMAGE_SCALES.medium}/>
            <label className="option-label">{I18n('t_png_medium')}</label>
          </div>
          <div className="overlay-option large">
            <RadioButton className="option-checkbox option-checkbox-large" value={EXPORT_IMAGE_SCALES.large} onChange={onSelectionChange} checked={exportScaleSelection === EXPORT_IMAGE_SCALES.large}/>
            <label className="option-label">{I18n('t_png_large')}</label>
          </div>
          <Button className={'btn-nzc-export'} onClick={exportHandler} disabled={!exportScaleSelection}>{I18n('t_export')} </Button>
        </div>
      </OverlayPanel>
    </>
  )
}

NzcExportOverlay.propTypes = {
  exportOverlayRef: PropTypes.object,
  nzcGraphRef: PropTypes.object,
  selectedFund: PropTypes.object,
  docname: PropTypes.string,
  selectedLanguage: PropTypes.string,
  appendTarget: PropTypes.object,
  dataFrom: PropTypes.string,
  intensity: PropTypes.string,
}

NzcExportOverlay.displayName = 'NzcExportOverlay'

export default memo(NzcExportOverlay)