import axiosInstance from '../common/axios-config'
import {CUSTOM, UTILITY_OTHER_NAME_MAP} from '../../utils/helpers/Constants'

export const getFundDataQualityAssets = async (keyInfo) => {
  const {fundGroupId, odataQueryString, selectedUtilitiesKeys, time: dateType, timeNoOfMonths, timeEndDate}=keyInfo.queryKey[1]
  let data
  let apiURL
  if(dateType=== CUSTOM){
    apiURL=`/api/v1/FundDataQuality/${fundGroupId}/Assets${odataQueryString ?? '?'}${odataQueryString ? '&' : ''}period=${dateType}&noOfMonths=${timeNoOfMonths}&endMonth=${timeEndDate}`
  } else {
    apiURL=`/api/v1/FundDataQuality/${fundGroupId}/Assets${odataQueryString ?? '?'}${odataQueryString ? '&' : ''}period=${dateType}`
  }
  if (!fundGroupId || !apiURL) {
    return
  }
  data = await axiosInstance.post(apiURL, selectedUtilitiesKeys)
  return data
}

export const getFundDataQualityAssetsV2 = async (keyInfo) => {
  const {fundGroupId, oDataSortOrder, selectedUtilitiesKeys, time, timeNoOfMonths, timeEndDate, selectedView, selectedAgentKeys, first, rows}=keyInfo.queryKey[1]
  let queryParams=`period=${time}`

  if(time=== CUSTOM){
    queryParams= `${queryParams}&noOfMonths=${timeNoOfMonths}&endMonth=${timeEndDate}`
  }
  let body = {
    utilities: selectedUtilitiesKeys,
    agents: selectedAgentKeys,
    procuredBy: selectedView,
    pageOptions: {
      pageCount: first,
      pageSize: rows
    },
    missingDataSort: oDataSortOrder
  }

  return await axiosInstance.post(`/api/v1/DataQuality/${fundGroupId}/Assets?${queryParams}`, body) // If any changes in this API(V2) path, it needs to be updated here.
}

export const getFundDataQualityProcurementData = async (keyInfo) => {
  const {fundGroupId, time: dateType, timeNoOfMonths, timeEndDate, selectedUtilitiesKeys, isTimePeriodChanged}=keyInfo.queryKey[1]
  let data
  let apiURL
  if(dateType=== CUSTOM){
    apiURL=`/api/v1/FundDataQuality/${fundGroupId}/FundDataQualityResponsibility/UtilitySummary/Details?period=${dateType}&noOfMonths=${timeNoOfMonths}&endMonth=${timeEndDate}&isTimePeriodChanged=${isTimePeriodChanged}`
  } else {
    apiURL=`/api/v1/FundDataQuality/${fundGroupId}/FundDataQualityResponsibility/UtilitySummary/Details?period=${dateType}&isTimePeriodChanged=${isTimePeriodChanged}`
  }
  if (!fundGroupId || !apiURL) {
    return
  }
  data = await axiosInstance.post(apiURL, selectedUtilitiesKeys)
  return data
}

export const GetFundDQAssetData = async (keys) => {
  let data
  const {fundGroupId, odataQueryString, time, timeNoOfMonths, timeEndDate, utility} = keys.queryKey[1]
  const queryKeys = keys.queryKey
  if(!queryKeys || queryKeys.length === 0) {
    return
  }

  if(!fundGroupId) {
    return
  }

  if(!utility) {
    return
  }
  let typeOfExport = ''
  if(utility === UTILITY_OTHER_NAME_MAP.All){
    typeOfExport = 'AllUtilitiesSummary'
  }
  else{
    typeOfExport = 'UtilitySummary'
  }

  if(!timeNoOfMonths || !timeEndDate) {
    data = await axiosInstance.get(`/api/v1/FundDataQuality/${fundGroupId}/FundDataQualityResponsibility/${typeOfExport}?utility=${utility}${odataQueryString ? '&' : ''}period=${time === 'CUS' ? 'YTD' : time}`)
  } else {
    data = await axiosInstance.get(`/api/v1/FundDataQuality/${fundGroupId}/FundDataQualityResponsibility/${typeOfExport}?utility=${utility}${odataQueryString ? '&' : ''}period=${time === 'CUS' ? 'YTD' : time}&noOfMonths=${timeNoOfMonths}&endMonth=${timeEndDate}`)
  }

  return data
}

export const GetFundDataQualityExportData = async (keyInfo) => {
  const { fundGroupId, time, timeNoOfMonths, timeEndDate, utility } = keyInfo.queryKey[1]
  let data

  if (!fundGroupId) {
    return
  }

  const requestUrl = `/api/v1/FundDataQuality/${fundGroupId}/FundDataQualityResponsibility/AllUtilitiesSummary?utility=${utility}&period=${time === 'CUS' ? 'YTD' : time}`

  if (!timeNoOfMonths || !timeEndDate) {
    data = await axiosInstance.get(requestUrl)
  } else {
    data = await axiosInstance.get(`${requestUrl}&noOfMonths=${timeNoOfMonths}&endMonth=${timeEndDate}`)
  }
  return data
}

export const GetFundDataQualityExportDataV2 = async (keyInfo) => {
  const fundGroupId = keyInfo?.queryKey[1]
  const { timePeriod, timeNoOfMonthsPeriod, timeEndDatePeriod, utilitySelected, selectedView, selectedAgent } = keyInfo.queryKey[2]

  if(!fundGroupId) return

  let queryParams=`period=${timePeriod}`
  if(timePeriod === CUSTOM) {
    queryParams= `${queryParams}&noOfMonths=${timeNoOfMonthsPeriod}&endMonth=${timeEndDatePeriod}`
  }

  const body = {
    utilities: utilitySelected,
    agents: selectedAgent,
    procuredBy: selectedView
  }
  const {data} = await axiosInstance.post(`/api/v1/DataQuality/fund/${fundGroupId}/export?${queryParams}`, body)

  return data
}

export const getFundCompleteness = async (keys) => {
  const queryKeys = keys.queryKey
  const [, fundGroupID, period, noOfMonths, endMonth, utilitySelected, selectedView, selectedAgents] = queryKeys
  if(!fundGroupID) return

  let queryParams=`period=${period}`
  if(period=== CUSTOM) {
    queryParams= `${queryParams}&noOfMonths=${noOfMonths}&endMonth=${endMonth}`
  }

  const body = {
    utilities: utilitySelected,
    agents: selectedAgents,
    procuredBy: selectedView
  }
  const {data} = await axiosInstance.post(`/api/v1/DataQuality/fund/${fundGroupID}/completeness?${queryParams}`, body)

  return data
}

export const getFundCoverage = async (keys) => {
  const queryKeys = keys.queryKey
  const [, fundGroupID, period, noOfMonths, endMonth, utilitySelected, selectedView, selectedAgents] = queryKeys
  if(!fundGroupID) return

  let queryParams=`period=${period}`
  if(period=== CUSTOM) {
    queryParams= `${queryParams}&noOfMonths=${noOfMonths}&endMonth=${endMonth}`
  }

  const body = {
    utilities: utilitySelected,
    agents: selectedAgents,
    procuredBy: selectedView
  }
  
  const {data} = await axiosInstance.post(`/api/v1/DataQuality/fund/${fundGroupID}/coverage?${queryParams}`, body)
  return data
}

export const GetFundAgents = async (keys) => {
  const fundGroupId = keys?.queryKey[1]
  if(!fundGroupId) {
    return
  }
  const { data } = await axiosInstance.get(`/api/v1/DataQuality/fund/${fundGroupId}/agents`)
  return data
}