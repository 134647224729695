import { useQuery } from 'react-query'
import { useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import { GetFundReportAssetBreakdown, GetFundReportSummary, GetFundReportMissingData } from 'services/funds/fund-reports-service'
import FundReportAssetBreakdownContent from './FundReportAssetBreakdownContent'
import { FundReportMissingDataExcelExport } from '../export/FundReportMissingDataExcelExport'
import { useI18n } from 'utils/i18n/I18n'
import { noRefetchOnWindowFocus } from 'services/common/useQuery-config'
import useFundIds from 'utils/custom-hooks/useFundIds'

const FundReportAssetBreakdown = ({ year, onBackToListClick, onCreateReportClick, onClickEditData, onDataCompletenessBarClick, refetchTimeStamp }) => {
  const { fundId, fundGroupID } = useFundIds()
  const [download, setDownload] = useState(false)
  const [skip, setSkip] = useState(0)
  
  const {
    isFetching: fundReportAssetBreakdownLoading,
    data: fundReportAssetBreakdownData,
    refetch: refetchFundReportAssetBreakdownData
  } = useQuery({
    queryKey: ['GetFundReportAssetBreakdown', fundGroupID, year, 10, skip],
    queryFn: GetFundReportAssetBreakdown,
    retry: false,
    refetchOnWindowFocus: noRefetchOnWindowFocus.refetchOnWindowFocus,
  })
  const intl = useI18n()

  const {
    isFetching: fundReportSummaryDataLoading,
    data: fundReportSummaryData,
  } = useQuery({
    queryKey: ['GetFundReportSummary', fundGroupID, year],
    queryFn: GetFundReportSummary,
    retry: false,
    refetchOnWindowFocus: noRefetchOnWindowFocus.refetchOnWindowFocus,
  })

  const {
    isLoading: fundReportMissingDataLoading,
    data: fundReportMissingData,
    isError: fundReportMissingDataError,

  } = useQuery({
    queryKey: ['GetFundReportMissingData', fundId, year],
    queryFn: GetFundReportMissingData,
    retry: false,
    enabled: download
  })

  useEffect(() => {
    if(download && !fundReportMissingDataError && !fundReportMissingDataLoading) {
      triggerExportHandler()
    }
  }, [download, fundReportMissingDataLoading, fundReportMissingDataError])

  useEffect(() => {
    if(!fundReportAssetBreakdownLoading){
      refetchFundReportAssetBreakdownData()
    }
  }, [skip, refetchTimeStamp])

  const onExportClick = () => {
    setDownload(true)
  }

  const triggerExportHandler = async () => {
    if (!fundReportMissingDataLoading && !fundReportMissingDataError && fundReportMissingData) {
      await FundReportMissingDataExcelExport({fundReportMissingData, formatMessage: intl.formatMessage})
      setDownload(false)
    }
  }

  const onRefetchData = (page) => {
    setSkip((page)*10)
  }

  return (
    <FundReportAssetBreakdownContent
      year={year}
      fundReportAssetBreakdownData={fundReportAssetBreakdownData}
      fundReportAssetBreakdownLoading={fundReportAssetBreakdownLoading}
      fundReportSummaryData={fundReportSummaryData}
      fundReportSummaryDataLoading={fundReportSummaryDataLoading}
      onCreateReportClick={onCreateReportClick}
      onClickEditData={onClickEditData}
      onBackToListClick={onBackToListClick}
      onExportClick={onExportClick}
      onRefetchData={onRefetchData}
      download={download}
      onDataCompletenessBarClick={onDataCompletenessBarClick}
    />
  )
}

FundReportAssetBreakdown.propTypes = {
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onBackToListClick: PropTypes.func,
  onCreateReportClick: PropTypes.func,
  onClickEditData: PropTypes.func,
  onDataCompletenessBarClick: PropTypes.func,
  refetchTimeStamp: PropTypes.number
}

FundReportAssetBreakdown.displayName = 'FundReportAssetBreakdown'

export default FundReportAssetBreakdown