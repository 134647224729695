
import IndividualMeterIndicator from '../../../performance/individual-meter-indicator/IndividualMeterIndicator'
import './DataQualityHomeTabContent.scss'
import {updateMeterDataUtility, updateMeterDataParams, updateMeterDataArea, getMeterModalParams} from '../../data-quality-hooks-&-helpers/dataQualityHelper'
import {LANDLORD, TENANT} from '../../../../../../utils/helpers/Constants'
import DataQualityHomeTabPdf from './DataQualityHomeTabPdf'
import SummaryViewLandlordTenant from './SummaryViewLandlordTenant'
import PropTypes from 'prop-types'

const DataQualityHomeTabContent = (props) => {

  const {
    dataQualityHomeState,
    updateDataQualityHomeState,
    meterData,
    missingData,
    timePeriodState,
    time,
    assetId,
    dqOverviewDataForLandlord,
    dqOverviewDataForTenant
  } = props

  // For Meter view Component.
  const getMeterViewElement = () => {
    return (
      <div className="meterview-view">
        <IndividualMeterIndicator
          utility={dataQualityHomeState.selectedUtility}
          area={dataQualityHomeState.selectedArea}
          meterData={meterData}
          updateUtility={(newUtilObj) => updateMeterDataUtility(newUtilObj, updateDataQualityHomeState)}
          updateArea={(newAreaObj) => updateMeterDataArea(newAreaObj, updateDataQualityHomeState)}
          updater={(newUtil, newArea) => 
            updateMeterDataParams(newUtil, newArea,  assetId, updateDataQualityHomeState, timePeriodState, time)
          }
          meterModalParams={getMeterModalParams(assetId, time, timePeriodState)}
          missingDataWrapper={missingData}
        />
      </div>
    )
  }

  const getSummaryView = ()=> {
    const landlordSummary = dqOverviewDataForLandlord?.data?.[0]
    const tenantSummary = dqOverviewDataForTenant?.data?.[0]
    const landlordMeterDataAvailable=!!missingData.data?.assetMeterDataQualitySummaries?.find(meterData => LANDLORD === meterData.responsibility)
    const tenantMeterDataAvailable=!!missingData.data?.assetMeterDataQualitySummaries?.find(meterData => TENANT === meterData.responsibility)
    const showLandlordSection=!!(tenantSummary?.generatedData && !tenantMeterDataAvailable) // to avoid blank page when no data is available

    return (
      <div className={'summary-view'}>
        {(!landlordSummary?.generatedData || landlordMeterDataAvailable || showLandlordSection) && (
          <div className={'summary-view-content landlord'}>
            <SummaryViewLandlordTenant {...props} summaryType={LANDLORD}/>
          </div>
        )}
        {(!tenantSummary?.generatedData || tenantMeterDataAvailable) &&
              <>
                <div className={'center-hoz-line'}/>
                <div className={'summary-view-content tenant'}>
                  <SummaryViewLandlordTenant {...props} summaryType={TENANT}/>
                </div>
              </>
        }
      </div>
    )
  }

  function getSummaryOrMeterView() {
    let contentElement
    if (dataQualityHomeState.activeTabIndex === 0) {
      contentElement = getSummaryView()
    } else if (dataQualityHomeState.activeTabIndex === 2) {
      contentElement = getMeterViewElement()
    }
    return contentElement
  }

  //endregion

  return(
    <div className="content-view dquality-container page-content">
      <div className="dq-pdf-container">
        <DataQualityHomeTabPdf {...props}/>
      </div>
      <div role={'data-quality-table'} className="dq-summary-meter-container">
        {getSummaryOrMeterView()}
      </div>
    </div>
  )
}

export default DataQualityHomeTabContent

DataQualityHomeTabContent.displayName = 'DataQualityHomeTabContent'

DataQualityHomeTabContent.propTypes = {
  dataQualityHomeState: PropTypes.object,
  updateDataQualityHomeState: PropTypes.func,
  meterData: PropTypes.object,
  missingData: PropTypes.object,
  timePeriodState: PropTypes.object,
  time: PropTypes.string,
  assetId: PropTypes.string,
  dqOverviewDataForLandlord: PropTypes.object,
  dqOverviewDataForTenant: PropTypes.object
}