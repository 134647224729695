import { formatDateToDDMMYY } from 'utils/helpers/DateUtils'
import { applyMultipleFundReportPAITableHeaderStyles, formatData, getEmptyRows, mergeMultipleCellRanges, setRowHeight, textAlignCenter } from 'utils/helpers/ExcelUtils'
import { generatePrincipleMetricsRow, generateQuarterlySummaryRow, getPrincipleMetricsTableHeader, getQuarterlySummaryTableHeader, getfundReportSheetHeaderInformation } from './fundReportPAIExcelExport'

export const createFundReportPAIInefficientRESheet = (
  fundName,
  reportingYear,
  energyEfficiencyBreakdown,
  assets,
  localize,
  selectedLanguage,
  excelSheet
) => {
  let excelDataArray = []
  excelDataArray.push(...getfundReportSheetHeaderInformation('t_energy_efficiency_impact_report', fundName, reportingYear, localize))
  excelDataArray.push(...getEmptyRows(2))
  excelDataArray = getEnergyEfficiencyQuarterlySummaryTable(excelDataArray, energyEfficiencyBreakdown.quarterlySummary, reportingYear, localize)
  excelDataArray.push(...getEmptyRows(1))
  excelDataArray = getEnergyEfficiencyPrincipleMetricsTable(excelDataArray, energyEfficiencyBreakdown.principleMetrics, reportingYear, localize)
  excelDataArray.push(...getEmptyRows(1))
  excelDataArray = getREAssetInformationTable(excelDataArray, assets, reportingYear, localize, selectedLanguage, excelSheet)
  return excelDataArray
}

const getEnergyEfficiencyQuarterlySummaryTable = (currentExcelData, quarterlySummaryData, reportingYear, localize) => {
  const {exposedValue, totalApplicableValue, totalExcludedValue, applicableValueShare, excludedValueShare} = quarterlySummaryData
  const data = [...currentExcelData]
  data.push(...getQuarterlySummaryTableHeader(localize, reportingYear))
  
  data.push(...[generateQuarterlySummaryRow('t_exposed_value', exposedValue.q1, exposedValue.q2, exposedValue.q3, exposedValue.q4, localize),
    generateQuarterlySummaryRow('t_total_applicable_value', totalApplicableValue.q1, totalApplicableValue.q2, totalApplicableValue.q3, totalApplicableValue.q4, localize),
    generateQuarterlySummaryRow('t_total_excluded_value', totalExcludedValue.q1, totalExcludedValue.q2, totalExcludedValue.q3, totalExcludedValue.q4, localize),
    generateQuarterlySummaryRow('t_share_applicable_value_exposed', applicableValueShare.q1, applicableValueShare.q2, applicableValueShare.q3, applicableValueShare.q4, localize),
    generateQuarterlySummaryRow('t_share_total_value_excluded', excludedValueShare.q1, excludedValueShare.q2, excludedValueShare.q3, excludedValueShare.q4, localize)])
  
  return data
}

const getEnergyEfficiencyPrincipleMetricsTable = (currentExcelData, principleMetricsData, reportingYear, localize) => {
  const {exposedValueShare, excludedValueShare} = principleMetricsData
  const data = [...currentExcelData]
  data.push(...getPrincipleMetricsTableHeader(localize, reportingYear))
  data.push(...[ generatePrincipleMetricsRow('t_average_share_exposed_value', exposedValueShare.currentYear, exposedValueShare.previousYear, localize),
    generatePrincipleMetricsRow('t_average_share_total_value_excluded', excludedValueShare.currentYear, excludedValueShare.previousYear, localize)])
  return data
}

const getREAssetInformationTable = (currentExcelData, assetsArray, reportingYear, localize, selectedLanguage, excelSheet) => {
  const data = [...currentExcelData]
  data.push(...getAssetInformationTableHeader(localize, reportingYear))
  assetsArray.forEach(asset => {
    const { 
      assetReference, 
      assetName, 
      location, 
      purchaseDate,
      planningPermissionDate,
      saleDate, 
      gavPerQuarter,
      EnergyEfficiency
    } = asset
    data.push(...[{
      A: assetReference,
      B: assetName,
      C: location,
      D: formatDateToDDMMYY(purchaseDate, selectedLanguage),
      E: formatDateToDDMMYY(saleDate, selectedLanguage),
      F: formatDateToDDMMYY(planningPermissionDate, selectedLanguage),
      G: formatData(gavPerQuarter.q1),
      H: formatData(gavPerQuarter.q2),
      I: formatData(gavPerQuarter.q3),
      J: formatData(gavPerQuarter.q4),
      K: EnergyEfficiency.epcRating,
      L: formatData(EnergyEfficiency.pedBelowNzeb, localize),
    }])
    excelSheet.row(data.length).height(31)
  })

  return data
}

const getAssetInformationTableHeader = (localize, reportingYear) => {
  return [{
    A: localize('t_asset_information'),
  },
  {
    A: localize('t_asset_reference'),
    B: localize('t_asset_name'),
    C: localize('t_country'),
    D: localize('t_purchase_date'),
    E: localize('t_sale_date'),
    F: localize('t_planning_permission_date'),
    G: localize('t_gross_asset_value'),
    H: '',
    I: '',
    J: '',
    K: localize('t_EPC_rating'),
    L: localize('t_ped_below_nzeb'),
  },
  {
    A: '',
    B: '',
    C: '',
    D: '',
    E: '',
    F: '',
    G: `Q1 ${reportingYear}`,
    H: `Q2 ${reportingYear}`,
    I: `Q3 ${reportingYear}`,
    J: `Q4 ${reportingYear}`,
    K: '',
    L: '',
  }]
}

export const applyStylingToInefficientRESheet = (excelSheet) => {
  excelSheet.usedRange().forEach(cell => {
    if (cell.address() !== 'A1') {
      cell.style({fontSize: 12,  horizontalAlignment: 'left', verticalAlignment: 'center'})
    }

    if(cell.address() === 'A8' || cell.address() === 'A16') {
      cell.style({topBorder: false})
    }
  })
  applyMultipleFundReportPAITableHeaderStyles(['A21:L21', 'G22:J22', 'B8:E8', 'B16:C16'], excelSheet)
  mergeMultipleCellRanges(excelSheet, ['A21:A22', 'B21:B22', 'C21:C22', 'D21:D22', 'E21:E22', 'F21:F22', 'G21:J21', 'K21:K22', 'L21:L22'])
  setRowHeight(excelSheet, 21, 30)
  textAlignCenter(excelSheet, 'G21:J21')
}