import {filterQueryParams} from '../query-params/QueryParams'
import {CachedUserState, createNavURLForFundPages, isAlpha} from './Helper'
import {LOCAL_STORAGE_KEYS, monthNumbers, PERIOD_TYPES} from './Constants'

export function updateQueryParamsForFundViews(queryParams, state, history, instanceName, orgName, fundName, pathSuffix, assetId) {
  const pageUrl = createNavURLForFundPages({instanceName, orgName, fundName, assetId, pathSuffix})
  if(history?.location?.pathname === pageUrl){
    filterQueryParams(history, queryParams, state)
  }
}

// Helper methods for creating default date period
export function getDaysDifference(currentDate, prevQtrEndDate) {
  // Milliseconds/Day
  const day = 1000 * 60 * 60 * 24
  const differenceTime = currentDate.getTime() - prevQtrEndDate.getTime()
  return Math.round(differenceTime / day)
}

function getQtrForCurrentDate(fyQuarters, monthOfCurrentDate) {
  return fyQuarters ? fyQuarters.filter(quarter => quarter.qtrMonths.includes(monthOfCurrentDate))[0] : null
}

function getPrevQtr(fyQuarters, prevQtrIndex) {
  return fyQuarters ? fyQuarters.filter(quarter => parseInt(quarter.qtrIndex) === parseInt(prevQtrIndex))[0] : null
}

function getPrevQtrEndDate(prevQtrEndYear, fyQtrEndMonth) {
  return new Date(prevQtrEndYear, fyQtrEndMonth, 0) // 0 will return the last day of the month
}

export function getFYQuarters(fyStartMonth, currentYear) {
  let fyQtrEndMonth = fyStartMonth + 2
  let fyQtrStartMonth = fyStartMonth
  let fyQtrEndYear = currentYear
  let fyQtrMidMonth
  const fyQuarters = []
  let quarter = 1
  while (quarter < 5) {
    if (fyQtrEndMonth > 12) {
      fyQtrEndMonth = fyQtrEndMonth - 12
      fyQtrEndYear++
    }
    fyQtrStartMonth = fyQtrStartMonth > 12 ? fyQtrStartMonth - 12 : fyQtrStartMonth
    fyQtrMidMonth = fyQtrStartMonth === 12 ? fyQtrEndMonth - 1 : fyQtrStartMonth + 1

    fyQuarters.push({qtrIndex: quarter, fyQtrStartMonth, fyQtrEndMonth, fyQtrEndYear, qtrMonths: [fyQtrStartMonth, fyQtrMidMonth, fyQtrEndMonth]})
    quarter++
    fyQtrStartMonth = fyQtrEndMonth + 1
    fyQtrEndMonth += 3
  }
  return fyQuarters
}

export function getQuarterEndMonth(fyStartMonth, currentDate) {
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth() + 1
  let bufferWeeks = 0
  let prevQuarter
  let prevQtrEndDate
  let daysDifference
  let prevQtrIndex
  let prevQtrEndYear
  let fyQtrEndMonth
  let fyQuarters = getFYQuarters(fyStartMonth, currentYear)
  const monthOfCurrentDate = currentDate.getMonth()
  let qtrForCurrentDate = getQtrForCurrentDate(fyQuarters, (monthOfCurrentDate + 1))

  if (qtrForCurrentDate) {
    if (qtrForCurrentDate.fyQtrEndYear !== currentYear) {
      fyQuarters = getFYQuarters(fyStartMonth, currentYear - 1)
      qtrForCurrentDate = getQtrForCurrentDate(fyQuarters, (monthOfCurrentDate + 1))
    }
  }
  const qtrIndexForCurrentDate = qtrForCurrentDate ? qtrForCurrentDate.qtrIndex : 0
  if (qtrIndexForCurrentDate === 1) { // monthOfCurrentDate falls under 1st quarter
    prevQtrIndex = 4 // Resetting index to last qtr
    prevQtrEndYear = currentYear - 1
    fyQuarters = getFYQuarters(fyStartMonth, prevQtrEndYear)
  } else {
    prevQtrIndex = qtrIndexForCurrentDate - 1
  }
  while (bufferWeeks < 8) {
    if (prevQtrIndex < 1) {
      prevQtrIndex = 4
      prevQtrEndYear = prevQtrEndYear - 1
      fyQuarters = getFYQuarters(fyStartMonth, prevQtrEndYear)
    }
    prevQuarter = getPrevQtr(fyQuarters, prevQtrIndex)
    prevQtrEndYear = prevQuarter.fyQtrEndYear
    fyQtrEndMonth = prevQuarter.fyQtrEndMonth
    prevQtrEndDate = getPrevQtrEndDate(currentMonth === 12 ? currentYear : prevQtrEndYear, fyQtrEndMonth)
    daysDifference = getDaysDifference(currentDate, prevQtrEndDate)
    bufferWeeks = daysDifference / 7
    prevQtrIndex--
  }
  return prevQuarter
}

export function setDefaultDate(financialYearStart) {
  GetDefaultDateWithCachedData(financialYearStart,true, CachedUserState.getUserDataFromCache(LOCAL_STORAGE_KEYS.loginTimestamp))
}

export function GetDefaultDateWithCachedData(financialYearStart,isCacheRequired, loginTimestamp) {
  if(financialYearStart) {
    const loginDate=new Date(loginTimestamp)
    let dayAndMonth = financialYearStart.split(' ')
    let monthName = dayAndMonth?.[1]
    if(!isAlpha(monthName)){
      monthName = dayAndMonth?.[0]
    }
    monthName = monthName?.substring(0, 3)?.toLowerCase()
    const fyStartMonth=monthNumbers[monthName]
    const prevQuarter=(loginDate && fyStartMonth)? getQuarterEndMonth(fyStartMonth, loginDate):null
    if(prevQuarter){
      let shortYear=prevQuarter?.fyQtrEndYear.toString().substring(2)
      const pickerValue= {
        'timePeriodSelected': PERIOD_TYPES.CUSTOM,
        'pickerValueMonths': '12',
        'pickerValueEndMonth': prevQuarter.fyQtrEndMonth?.toString(),
        'pickerValueEndYear': shortYear
      }
      isCacheRequired &&
        CachedUserState.saveUserDataToCache(LOCAL_STORAGE_KEYS.defaultDatePickerValue, pickerValue)

      return (isCacheRequired ? null : pickerValue)
    }
  }
}
