import React from 'react'
import {Button} from 'primereact/button'
import {useHistory} from 'react-router-dom'
import {UserActions} from '../../../../../../utils/helpers/Constants'
import I18n from '../../../../../../utils/i18n/I18n'


const UserFooter = (props) => {
  const navigate  = useHistory()

  function closeAddAsset() {
    navigate.goBack()
  }

  const isUserEditAction = () => props.userAction === UserActions.EDIT_USER

  function getCssStylesForSaveButton() {
    let saveButtonStyles='button-save'
    if(props.userAction === UserActions.EDIT_USER){
      saveButtonStyles='button-save-exit-edit'
    }

    if(isUserEditAction()){
      saveButtonStyles='button-save button-save-editUser'
    }

    return saveButtonStyles
  }

  return (
    <div className={'asset-footer'}>
      <div className={'footer-buttons-container p-grid'}>
        <div className={'buttons-container p-col-4'}>
          <div className={'buttons-save-next'}>
            <div className={'button-container container-save'}>
              <Button disabled={!props.hasPermissionForEditUser}
                type="submit"
                className={getCssStylesForSaveButton()}
                onClick={props.submitHandler}
              >
                {I18n('t_save_exit')}
              </Button>
            </div>
            {(!isUserEditAction()) && props.handleNextTabBtnText ?
              <div className={'button-container container-next'}>
                <Button onClick={props.handleNextTab} className={'button-next'} disabled={props.isNextButtonDisabled}>
                  {I18n(props.handleNextTabBtnText)}
                </Button>
              </div> : null
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserFooter