import React, { useState} from 'react'
import I18n from '../../../../../utils/i18n/I18n'
import {InputText} from 'primereact/inputtext'
import {Formik} from 'formik'
import * as Yup from 'yup'
import QuestionMarkIcon from '../../../../../resources/images/icon/question-mark.svg'
import './GroupDetails.scss'
import {InputTextarea} from 'primereact/inputtextarea'
import GroupFooter from '../group-footer/GroupFooter'
import GroupsDetailsSkeleton from '../../../../common/skeletons/groups-grid-skeleton/GroupDetailsSkeleton'
import {groupInfo, UserActions} from '../../../../../utils/helpers/Constants'
import {
  clearGroupContent,
  createNavURLForUserPages,
  getInstance,
  getLocalStorageByKey
} from '../../../../../utils/helpers/Helper'
import PopupDialog from '../../../../common/modal-dialogs/PopupDialog'
import {Button} from 'primereact/button'
import {useHistory, useParams} from 'react-router-dom'
import {useMutation} from 'react-query'
import {DeleteGroup} from '../../../../../services/admin/groups-service'

function GroupDetails(props){

  const mandatoryChar = '*'

  // Formik properties.
  const validationConditionRequired='Required'
  const validationSchema=Yup.object({
    name: Yup.string().required(validationConditionRequired),
  })
  const [deleteWarningDialog,setDeleteWarningDialog] = useState(false)
  const navigate  = useHistory()
  let initialValues= {
    name: '',
    description: '',
  }
  const deleteGroup = useMutation(DeleteGroup)
  const {groupId} = useParams()

  if(props.groupData){
    //rearrange name accordingly later
    initialValues = {
      name: props.groupData.name,
      description: props.groupData.description
    }
  }

  function getFormValidationErrorMessage(formik) {
    let touchedFieldHasError
    if (formik && formik.touched) {
      const touchedFields = Object.keys(formik.touched)
      const errorsFields = Object.keys(formik.errors)
      touchedFieldHasError = touchedFields.find((touchedField) => errorsFields.includes(touchedField))
    }
    return touchedFieldHasError ? I18n('t_form_group_error_message') : ''
  }

  function setFormValue(formik) {
    if (formik) {
      if(formik.dirty === true){
        const groupDetails = {isDetailsNavigatable: true, groupFormDetails: formik.values}
        localStorage.setItem(groupInfo, JSON.stringify(groupDetails))
      }
    }
  }

  function isInputError(formik, fieldName){
    return (formik.touched[fieldName] && formik.errors[fieldName])
  }

  function getCssForInputError(formik, fieldName) {
    return isInputError(formik, fieldName) ? 'input-border-error' : ''
  }

  function checkForMandatoryFields(formik) {
    let isVisible = formik.values.name === ''
    if(isVisible === false){
      isVisible = Object.keys(formik.errors).length !== 0
    }
    return isVisible
  }

  const handleTabChange = () => {
    props.showTab(1)
  }

  const questionMarkIcon = (className) => <><img data-testid={'question-mark-icon'} className={className} src={QuestionMarkIcon} alt="QuestionMarkIcon"/></>

  function setInitialValuesForForm(){
    return getLocalStorageByKey(groupInfo) && getLocalStorageByKey(groupInfo).groupFormDetails  ?
      getLocalStorageByKey(groupInfo).groupFormDetails  : initialValues
  }

  function confirmDialogFooter(){
    return <div className="revoke-footer">
      <div className="flex justify-content-center pt-0">
        <div className="pr-2">
          <Button label={I18n('t_no')} className={'dialog-no'} onClick={()=>setDeleteWarningDialog(false)}/>
        </div>
        <div>
          <Button label={I18n('t_yes')} className={'dialog-yes'} onClick={()=>clearAndCloseDialog()}/>
        </div>
      </div>
    </div>
  }

  async function clearAndCloseDialog() {
    setDeleteWarningDialog(false)
    clearGroupContent()
    await deleteGroup.mutateAsync({assetGroupID : groupId})
    const groupsHomePath=createNavURLForUserPages({instanceName: getInstance(),pathSuffix: 'groups' })
    navigate.push(groupsHomePath)
  }

  function groupDetailsTemplate(){
    return <div className={'group-details-content'}>
      <Formik initialValues={setInitialValuesForForm()} onSubmit={props.saveAndExit} validationSchema={validationSchema}
        innerRef={(form)=>setFormValue(form)}>
        {formik => (
          <>
            <div className={'p-jc-end p-d-flex'}>
              <div className="error-message">{getFormValidationErrorMessage(formik)}</div>
            </div>
            <div className={'g-form-wrapper'}>
              <form onSubmit={formik.handleSubmit} className={'g-form p-fluid'}>
                <div className={'g-form-table p-grid'}>
                  <div className={'p-col'}>
                    <div className="field-input">
                      <div className={`input-container group-name ${getCssForInputError(formik, 'name')}`}>
                        <div className={'p-float-label'}>
                          <InputText data-testid={'field-group-name'} id="name" name="name" {...formik.getFieldProps('name')} />
                          <label htmlFor="name">{I18n('t_group_name')} <span>{mandatoryChar}</span></label>
                        </div>
                      </div>
                      {props.hasPermissionForEditUser?questionMarkIcon('icon-question-mark'):''}
                    </div>
                    <div className="field-input">
                      <div className={`input-container group-description ${getCssForInputError(formik, 'description')}`}>
                        <div className={'p-float-label'}>
                          <InputTextarea data-testid={'field-description'} id="description" name="description" {...formik.getFieldProps('description')} autoResize/>
                          <label htmlFor="description">{I18n('t_description')}</label>
                        </div>
                      </div>
                      {questionMarkIcon('icon-question-mark')}
                    </div>
                    <div data-testid={'required-field'} className={'required-fields-indicator'}><span>{mandatoryChar}</span> {I18n('t_required_group_field')}</div>
                  </div>
                </div>
              </form>
              {props.groupAction === UserActions.EDIT_GROUP && <div className={'del-tab revoke-access'}>
                <button type="submit" className={'revoke-access-button'} onClick={()=>setDeleteWarningDialog(true)}>
                  {I18n('t_delete_group')}
                </button>
              </div>}
              {deleteWarningDialog && <PopupDialog visible={deleteWarningDialog} className={'revokeAccess-Warning-Dlg'}
                header={I18n('t_delete_group')} footer={confirmDialogFooter()}
                message={I18n('t_delete_group_msg')}
                onHide={()=>setDeleteWarningDialog(!deleteWarningDialog)}/>
              }
            </div>
            <div className={'g-form-divider'}>
              <hr/>
            </div>
            <div className={'g-form-footer'}>
              <GroupFooter
                submitHandler={formik.handleSubmit}
                handleNextTabBtnText="t_next_choose_assets"
                isNextButtonDisabled={checkForMandatoryFields(formik)}
                handleNextTab={() => handleTabChange()}
                groupAction={props.groupAction}
                lastPage={false}
              />
            </div>
          </>
        )}
      </Formik>
    </div>
  }

  return(props.groupData === true ? <GroupsDetailsSkeleton/> : groupDetailsTemplate())
}

export default GroupDetails