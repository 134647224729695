import {memo, useEffect} from 'react'
import {CachedAssetIdAndValueState, onSorting} from '../../../../../utils/helpers/Helper'
import AssetTableHeaderSkeleton from '../../../../common/skeletons/asset-table-header-skeleton/AssetTableHeaderSkeleton'
import AssetRowSkeleton from '../../../../common/skeletons/asset-row-skeleton/AssetRowSkeleton'
import './DataQualityAssetList.scss'
import './DataQualityAssetListV2.scss'
import {ascending, descending} from '../../../../../utils/helpers/Constants'
import PropTypes from 'prop-types'
import FundAssetsTable from '../../fund-common-components/FundAssetsTable'
import FundAssetsTableV2 from '../../fund-common-components/FundAssetsTableV2'

const DataQualityAssetList = ({fundAssetsTableData, sortField, sortOrder, updateFundDataQualityState, selectedUtility, selectedLanguage, fundGroupId, isFundDataQualityV2, first, rows}) => {
  //region Initialization
  const {data, loading, totalAssets}=fundAssetsTableData
  //endregion

  // region UseEffect Implementation
  useEffect(() => {
    if (data) {
      const fetchQualityAssetsIdAndName = data?.reduce(function (accumulator, currentValue) {
        return {
          ...accumulator,
          [currentValue.assetId]: currentValue.assetAddress,
        }
      }, {})
      CachedAssetIdAndValueState.saveAssetIdAndValueToCache(fetchQualityAssetsIdAndName)
    }
  }, [data, selectedLanguage])
  // endregion

  const onSort = (e) => {
    let orderRev = onSorting(e, sortOrder, sortField, isFundDataQualityV2 ? 'portionOfMissingData' : 'assetAddress')
    let sortOData = orderRev === 1 ? ascending : descending
    updateFundDataQualityState({sortField: e.sortField, sortOrder: orderRev, oDataSortOrder: sortOData})
  }

  function getFundDataQualityTableSkeleton() {
    return (
      <>
        <AssetTableHeaderSkeleton/>
        <AssetRowSkeleton/>
        <AssetRowSkeleton/>
        <AssetRowSkeleton/>
      </>
    )
  }

  function getFundAssetsTablePropsV2() {
    return {data, loading, totalAssets, sortField, sortOrder, onSort, selectedLanguage, first, rows, updateFundDataQualityState}
  }

  function getFundAssetsTableProps() {
    return {data, loading, sortField, sortOrder, onSort, selectedLanguage, selectedUtility}
  }

  function getFundDataQualityDataTable() {
    return (
      <div className={`${data?.length > 0 ? '' : 'no-data-msg'}`}>
        {isFundDataQualityV2 ? <FundAssetsTableV2 {...getFundAssetsTablePropsV2()} /> :
          <FundAssetsTable {...getFundAssetsTableProps()} />}
      </div>
    )
  }
  // endregion

  return (
    <div className={`${isFundDataQualityV2 ? 'data-quality-asset-list-v2 mb-6 pl-4' : 'data-quality-asset-list' }`}>
      <div className="data-quality-asset-list-wrapper">
        {loading || !fundGroupId ? getFundDataQualityTableSkeleton() :getFundDataQualityDataTable() }
      </div>
    </div>
  )
}

DataQualityAssetList.propTypes = {
  fundAssetsTableData: PropTypes.shape({
    data: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object
    ]),
    loading: PropTypes.bool,
    totalAssets: PropTypes.number
  }),
  sortField: PropTypes.string,
  sortOrder: PropTypes.number,
  updateFundDataQualityState: PropTypes.func,
  selectedLanguage: PropTypes.string,
  fundGroupId:PropTypes.number,
  first: PropTypes.number,
  rows: PropTypes.number,
  isFundDataQualityV2: PropTypes.bool,
  selectedUtility: PropTypes.array
}

DataQualityAssetList.displayName = 'DataQualityAssetList'

export default memo(DataQualityAssetList)
