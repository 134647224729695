import I18n from '../../../../../utils/i18n/I18n'
import {
  formatNumWODecimal,
  formatNumWOneDecimal,
  roundAndFormatNumberWODecimal,
  roundAndFormattedVarianceWithOneDP
} from '../../../../../services/common/formatter'
import downArrowGreenImage from '../../../../../resources/images/chart/down-arrow-green.svg'
import downArrowRedImage from '../../../../../resources/images/chart/down-arrow-red.svg'
import {CurrencyUnitToLabel, getDivisor, unitsConverter} from '../../../../../utils/helpers/Helper'
import {costMask, UTILITY_LABELS_OBJECT_TITLE_CASE} from '../../../../../utils/helpers/Constants'
import upArrowRedImage from '../../../../../resources/images/chart/up-arrow-red.svg'
import upArrowGreenImage from '../../../../../resources/images/chart/up-arrow-green.svg'
import {getSuffix} from '../../../funds/performance/FundPerformanceUtilitySuffixes'
import exclamationIcon from '../../../../../resources/images/icon/exclamation-orange.svg'
import CommonDialog from '../../../../common/modal-dialogs/ModalDialogCommon'
import React, {useState} from 'react'
import {UtilitySummaryItemKey} from '../performance-hooks-&-helpers/performanceConstants'
import PropTypes from 'prop-types'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'


const dialogContentPlaceHolder = msg => (<p>{msg}</p>)

const PerformanceChartSummary = ({summaryItems, selectedUtilityName, selectedLanguage, summaryColorClass, currencyUnit}) => {
  const [showGapFilledDialog, setShowGapFilledDialog] = useState(false)
  const { loginState: { userInfo } } = useLoginAuthContext()
  const unitSystem = userInfo.unitSystem

  //region Helper functions
  const getPerformanceConsumption = (value ) => {
    if(!value) {
      return '0'
    }
    const divisor = getDivisor(selectedUtilityName, value)
    return roundAndFormatNumberWODecimal(selectedLanguage, ( value / divisor))
  }

  function gapFilledDialogContent() {
    return (
      <div className={'gap-filled-dialog-content'}>
        {I18n('t_gap_filled_data_dialog_content', { p: dialogContentPlaceHolder} )}
      </div>
    )
  }

  function showGapFilledDataDialog(){
    setShowGapFilledDialog(true)
  }

  function hideGapFilledDataDialog() {
    setShowGapFilledDialog(false)
  }

  function getUtilityUnitValue(summaryItem) {
    let utilityUnitValue
    let summaryItemValue = summaryItem.value
    const absoluteValue = Math.abs(summaryItemValue)
    if(summaryItem.key === UtilitySummaryItemKey.usagePercentage || summaryItem.key === UtilitySummaryItemKey.percentageRecycled){
      summaryItemValue = formatNumWOneDecimal(selectedLanguage, roundAndFormattedVarianceWithOneDP(absoluteValue))
    } else if(summaryItem.key === UtilitySummaryItemKey.usageUnits){
      summaryItemValue =  formatNumWODecimal(selectedLanguage, absoluteValue)
    } else if (summaryItem.key === UtilitySummaryItemKey.usageCost) {
      summaryItemValue = formatNumWODecimal(selectedLanguage, absoluteValue)
    }
    if(summaryItem.key !==  UtilitySummaryItemKey.gapFilledPercentage){
      if(summaryItem.key === UtilitySummaryItemKey.currentTotalConsumption || summaryItem.key === UtilitySummaryItemKey.currentTotalWaste){
        utilityUnitValue=`${getPerformanceConsumption(summaryItemValue)} ${getSuffix(selectedUtilityName, summaryItemValue)}`
      } else {
        utilityUnitValue=summaryItemValue
      }
    } else {
      utilityUnitValue=(
        <>
          <img src={exclamationIcon} height="24x" width="24px" style={{marginRight:10}} className="icon" alt="exclamation icon" />
          {summaryItemValue}
        </>
      )
    }
    return utilityUnitValue
  }

  const getSummaryIcon = (value, selectedUtility) => {
    if(selectedUtility === UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE) {
      return value > 0 ? upArrowGreenImage : downArrowRedImage
    } else {
      return value > 0 ? upArrowRedImage : downArrowGreenImage
    }
  }

  const summaryItemsNodes = summaryItems?.map((summaryItem) => {
    const itemTitle = summaryItem.title?.length > 0 && summaryItem.title.includes('t_') ? I18n(summaryItem.title) : summaryItem.title
    let icon

    if (summaryItem.showIcon) {
      icon = getSummaryIcon(summaryItem.value, selectedUtilityName)
    }

    let utilitySummaryUnits = summaryItem.key === UtilitySummaryItemKey.usageUnits || (selectedUtilityName === UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE && summaryItem.key === UtilitySummaryItemKey.usageCost) ? unitsConverter(selectedUtilityName, unitSystem) : ''

    const summaryBlockClassName=summaryItem.key !== UtilitySummaryItemKey.gapFilledPercentage ? summaryColorClass : 'black'

    return (
      <div className={`summary-item-block ${summaryBlockClassName} col-4`} key={summaryItem.key}>
        <span data-testid={'usage-cost'} className='summary-title'>
          {(summaryItem.showIcon && <img className="usage-icon mr-2" src={icon} alt={'arrow-icon'} />)}
          {(summaryItem.key === UtilitySummaryItemKey.usageCost && selectedUtilityName !== UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE) && costMask[CurrencyUnitToLabel(currencyUnit)]}
          {getUtilityUnitValue(summaryItem)}
          <span className='summary-units'>
            {(summaryItem.key === UtilitySummaryItemKey.usagePercentage || summaryItem.key === UtilitySummaryItemKey.percentageRecycled) && '%'}
            {(summaryItem.key === UtilitySummaryItemKey.usageUnits || summaryItem.key === UtilitySummaryItemKey.usageCost) && selectedUtilityName === UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE ? ' t' : ` ${utilitySummaryUnits}`}
          </span>
        </span>
        {summaryItem.key !== UtilitySummaryItemKey.gapFilledPercentage ? (<span className='summary-caption'>{itemTitle}</span>) : <span onClick={showGapFilledDataDialog} className='card-sub-title underline'>{I18n('t_gap_filled_data')}</span>}
      </div>
    )
  })
  //endregion

  return (
    <div className='utility-overview-content'>
      {!summaryItems? <span>{I18n('t_msg_no_data')}</span>: summaryItemsNodes}
      {showGapFilledDialog && (
        <CommonDialog
          modal={false}
          style={{width: '700px', maxHeight: '60vh'}}
          visible={showGapFilledDialog}
          onHide={hideGapFilledDataDialog}
          header={I18n('t_about_gap_filled_data')}
          children={gapFilledDialogContent()}
          className={'fund-perf-scope-info'}
        />
      )}
    </div>
  )
}

export default PerformanceChartSummary

PerformanceChartSummary.displayName = 'PerformanceChartSummary'

PerformanceChartSummary.propTypes = {
  summaryItems: PropTypes.array,
  selectedUtilityName: PropTypes.string,
  selectedLanguage: PropTypes.string,
  summaryColorClass: PropTypes.string,
  currencyUnit: PropTypes.string,
}