import React from 'react'
import {Skeleton} from 'primereact/skeleton'
import './PassportActionsGridSkeleton.scss'

const PassportActionsGridSkeleton = () => {
  return (
    <div className="custom-skeleton p-rel">
      <div className="flex p-abs contour action-grid-skeleton">
        <Skeleton width="10%"/>
        <Skeleton width="30%"/>
        <Skeleton width="10%"/>
        <Skeleton width="10%"/>
        <Skeleton width="10%"/>
        <Skeleton width="10%"/>
      </div>
    </div>
  )
}

export default PassportActionsGridSkeleton