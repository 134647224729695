
// Contains util functions for DataTable component.
import {GAS_FUELS_THERMALS, TOP} from './Constants'
import {formatUnits, momentDate, unitsConverter} from './Helper'
import {UNITS} from '../i18n/constants'
import {isTextOverflown} from '../UtilsFunctions'
import {Tooltip} from 'primereact/tooltip'
import {NavLink} from 'react-router-dom'
import CustomTooltip from '../../components/common/tooltip/Tooltip'
import {Icon} from 'components/atomic'
import { Text, Tooltip as SieraTooltip } from 'components/atomic/index'

const cssClassPI='pi'
const cssClassPICheck='pi-check'
const cssClassPIMinus='pi-minus'
const MISSING = 'missing'

export function addSelectAllIcon(selectAllIconNode, selectAllIconClass) {
  if (selectAllIconNode && selectAllIconClass) {
    removeSelectAllIcon(selectAllIconNode)
    selectAllIconNode.classList.add(cssClassPI)
    selectAllIconNode.classList.add(selectAllIconClass)
  }
}

export function removeSelectAllIcon(selectAllIconNode) {
  if (selectAllIconNode) {
    if (selectAllIconNode.classList.contains(cssClassPI)) {
      selectAllIconNode.classList.remove(cssClassPI)
    }
    if (selectAllIconNode.classList.contains(cssClassPICheck)) {
      selectAllIconNode.classList.remove(cssClassPICheck)
    }
    if (selectAllIconNode.classList.contains(cssClassPIMinus)) {
      selectAllIconNode.classList.remove(cssClassPIMinus)
    }
  }
}

export function getSelectAllIconNodeContainer(selectionChangeEvent) {
  const parentNode=selectionChangeEvent.originalEvent.target.parentElement.parentElement
  return parentNode.querySelector('th.p-selection-column')
}

export function getSelectAllIconNode(selectAllIconSelectors) {
  return document.querySelector(selectAllIconSelectors)
}

export function setSelectAllIcon(selectedData, data, selectAllIconNode) {
  if (selectedData && selectedData.length > 0) {
    let selectAllIconClass
    if (selectedData?.length === data?.length) {
      selectAllIconClass = cssClassPICheck
    } else {
      selectAllIconClass = cssClassPIMinus
    }
    addSelectAllIcon(selectAllIconNode, selectAllIconClass)
  } else {
    removeSelectAllIcon(selectAllIconNode)
  }
}

export const getUtilConsumptionTemplate = (rowData, selectedLanguage, unitSystem, colorLabel, isV2 = undefined) => {
  let consumption = '-'
  const utility = rowData.utility === 'Gas' ? GAS_FUELS_THERMALS : rowData.utility
  if ((rowData.consumption && colorLabel === MISSING) || rowData.consumption === 0) {
    consumption = rowData.consumption === 0 ? 0 : formatUnits(rowData.consumption, selectedLanguage)
    consumption = consumption + unitsConverter(utility, unitSystem)
  } else if (typeof (rowData.consumption) === 'number') {
    consumption = formatUnits(rowData.consumption, selectedLanguage) + unitsConverter(utility, unitSystem)
  }
  return (
    isV2 ? 
      <Text content={consumption} size="xs" /> : 
      <div>
        <div className="perf-col-text1">{consumption}</div>
      </div>
  )
}

export const utilTextTemplate = (rowData, field) => {
  let inputText = '-'
  if (rowData[field]) {
    inputText = rowData[field]
  }
  return (
    <div>
      <div className="perf-col-text1">{inputText}</div>
    </div>
  )
}

export const utilTextTemplateWithTooltip = (rowData, field, charsToShow, triggerClass) => {
  let inputText = '-'
  if (rowData[field] && rowData[field] !== '') {
    let inputText = rowData[field]
    return (
      <div>
        <div className={`perf-col-text1 ${triggerClass}`}>{inputText.length > charsToShow ? inputText.slice(0, charsToShow).concat('...') : inputText}</div>
        <SieraTooltip target={`.${triggerClass}`}>
          <Text content={inputText} />
        </SieraTooltip>
      </div>
    )
  } else {
    return <div className="perf-col-text1">{inputText}</div>
  }
}

export const utilSubmittedByTemplate = (rowData, selectedLanguage, unitSystem, classes) => {
  const entryDate = rowData.dateAdded || rowData.timeStamp
  let formattedEntryDate = '-'
  if(entryDate) {
    const format = unitSystem === UNITS.IMPERIAL ? 'MMM D, YYYY' : 'D MMM YY'
    formattedEntryDate = momentDate(entryDate, format, selectedLanguage)
  }

  return (
    <div data-testid={'submitted-by-data'}>
      <Tooltip className={'common-tooltip actions-tooltip'} target={`.${classes}`} position={TOP}/>
      <div className={`text-wrapper ${classes}`}
        data-ptf-tooltip={rowData.enteredBy}
        onMouseEnter={(e) => isTextOverflown(e)}>{rowData.enteredBy}</div>
      <div>{formattedEntryDate}</div>
    </div>
  )
}

export const utilTextLinkTemplate = (rowData, field, navigationLink) => {
  return <NavLink className="text-link-blue" to={navigationLink}>{rowData[field]}</NavLink>
}

export const utilDateTemplate = (rowData, field, selectedLanguage, unitSystem) => {
  let inputText = '-'
  if (rowData[field] && rowData[field] !== 'Invalid date') {
    const format = unitSystem === UNITS.IMPERIAL ? 'MMM D, YYYY' : 'D MMM YY'
    inputText = momentDate(rowData[field], format, selectedLanguage)
  }
  return <div className="perf-col-text1">{inputText}</div>
}

export const utilFileListTemplate = (rowData, field, showTooltip, targetClass, toolTipClassName) => {
  let displayText = '-'
  let completeText
  const fileListLength = rowData[field]?.length || 0
  if (fileListLength) {
    displayText = rowData[field][0]

    if(fileListLength > 1) {
      displayText = displayText.concat(`, ... +${fileListLength - 1}`)
      completeText = rowData[field]
    }
  }
  return (
    <div>
      {(showTooltip && fileListLength > 1) &&
        <CustomTooltip position={TOP} target={`.${targetClass}`} toolTipClassName={toolTipClassName}>
          {completeText.map((item) => <p key={item}>{item}</p>)}
        </CustomTooltip>
      }
      <div className={`perf-col-text1 ${targetClass}`}>{displayText}</div>
    </div>
  )
}

export const getSortIcons = () => <>
  <Icon size={17} className='sort-default' id='sort_default'/>
  <Icon size={17} className='sort-ascending' id='sort_ascending'/>
  <Icon size={17} className='sort-descending' id='sort_descending'/>
</>