import React from 'react'
import './ActionStatusCard.scss'
import {actionStatusHeaderColor, actionStatusHeaders, costMask, DATA_FROM} from '../../../../utils/helpers/Constants'
import {  CurrencyUnitToLabel,  formatUnits,  truncateDecimal} from '../../../../utils/helpers/Helper'
import PropTypes from 'prop-types'
import { Text } from '../../../atomic'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const ActionStatusCard = props => {

  const {loginState: {userInfo}} = useLoginAuthContext()
  const currencyUnit = userInfo.currencyUnit

  function GetFormattedNumber(value, toDecimalPoints) {
    const absoluteValue=Math.abs(value)
    if (absoluteValue >= 1000 && absoluteValue < 100000) {
      value = formatUnits(truncateDecimal(value / 1000, toDecimalPoints))
      value = value + 'K'
      return value
    } else if (absoluteValue >= 100000) {
      value = formatUnits(truncateDecimal(value / 1000000, toDecimalPoints))
      value = value + 'M'
      return value
    }else{
      return formatUnits(truncateDecimal(value, toDecimalPoints))
    }
  }

  function getSummaryDataForEachField(field,isCurrencyType){
    const currencySymbol = costMask[CurrencyUnitToLabel(currencyUnit)]
    const fieldValue = isCurrencyType ? currencySymbol + GetFormattedNumber(field, 1) : field
    return (!field || field === 0) ? '-' : fieldValue
  }

  function fixCurrencyFormat(value) {
    if (value?.includes('-')) {
      value = value?.replace('-', '')
      value = '-' + value
    }
    return value
  }

  function getFundActionCards() {
    const data = {}
    if (!props.data  || Object.keys(props.data)?.length === 0) {
      data.totalActionsCount = '-'
      data.totalCost = '-'
      data.nonRecoverable = '-'
      data.unCosted = '-'
      data.netChange = '-'
    } else {
      data.totalCost =getSummaryDataForEachField(props.data.totalCost, true)
      data.nonRecoverable = getSummaryDataForEachField(props.data.nonRecoverable, true)
      data.totalActionsCount =  getSummaryDataForEachField(props.data.totalActionsCount,false)
      data.unCosted =  getSummaryDataForEachField(props.data.unCosted,false)
      data.netChange = getSummaryDataForEachField(props.data.netChange, true)
    }
    return (
      <>
        <div className={`card-action-header ${actionStatusHeaderColor[props.type]}`}
          role={'total-actions-count'}>
          <Text content={actionStatusHeaders[props.type]} size='s' className='header-label'/>
          <span className='header-value'>{data.totalActionsCount}</span>
        </div>
        <div className='card-action-body'>
          <div className='card-body-detail'>
            <span className='label-start'>{data.totalCost}</span>
            <Text content={'t_total_costs'} size='xs' className='label-end'/>
          </div>
          <hr/>
          <div className='card-body-detail'>
            <span className='label-start'>{data.nonRecoverable}</span>
            <Text content={'t_non_recoverable'} size='xs' className='label-end'/>
          </div>
          <hr/>
          <div className='card-body-detail'>
            <span className='label-start'>{data.unCosted}</span>
            <Text content={'t_un_costed'} size='xs' className='label-end'/>
          </div>
          <hr/>
          <div className='card-body-detail'>
            <span className='label-start'>{fixCurrencyFormat(data.netChange)}</span>
            <Text content={'t_net_change'} size='xs' className='label-end'/>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className={'card-action'} data-testid={props.type}>
      {getFundActionCards()}
    </div>
  )
}

ActionStatusCard.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string.isRequired
}

ActionStatusCard.displayName = 'ActionStatusCard'

export default ActionStatusCard
