import React, {useEffect, useState} from 'react'
import {Card} from 'primereact/card'
import {AuthCardFooter} from '../auth-card-footer/AuthCardFooter'
import I18n from '../../../../utils/i18n/I18n'
import './AccountLocked.scss'


function AccountLocked({parameter}) {
  const [accountLockedMessage, setAccountLockedMessage] = useState('')

  useEffect(() => {
    setAccountLockedMessage(parameter.location.state.accountBlockedMessage)
  }, [])
  const getAcLockedContentElement2= ()=>
    <div className="content-auth-card-content">
      <div className="content-error-msg">
        {I18n('t_error')}
      </div>
      <div className="content-error-ac-locked-msg">
        {accountLockedMessage === 'revoked' && I18n('t_error_account_revoked')}
      </div>
    </div>

  return (
    <div className="auth-ac-locked min-width-760 full-height-minus-header">
      <div className="auth-ac-locked-page">
        <div className="auth-card-container">
          <Card className={'auth-card'} title={I18n('t_account_locked' )} footer={AuthCardFooter}>
            {getAcLockedContentElement2()}
          </Card>
        </div>
      </div>
    </div>
  )
}

export default AccountLocked