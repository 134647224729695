import React from 'react'
import I18n from '../../../../utils/i18n/I18n'
import {InputText} from 'primereact/inputtext'
import {Button} from 'primereact/button'

const AddOrEditCustomTP = ({ isCreateOrEditMode, customTPNameError, customTargetPathwayName, tpNumber, handleTPCustomNameInput,
  setInputTextTouched, getTPDataTable, selectedScopePathwayData, targetFormItemsRef, buttonRef, onSubmit, isReductionDataNotEmpty,
  cancelHandler,targetPathwayEditStatus }) => {
  const hasTpPercentageGreaterThanZero = selectedScopePathwayData?.some(item => item.percentage > 0)
  return (
    <div className='target-form-content' data-testid="target-form-content">
      {isCreateOrEditMode() && customTargetPathwayName &&
              <div className={'tp-name-and-validation-errors'}>
                <InputText name='target-pathway-name' className={`tp-name tp-label-border ${customTPNameError.isTPNameError && 'tp-name-error'}`} value={customTargetPathwayName[`tp${tpNumber}`]} onChange={e => handleTPCustomNameInput(e,tpNumber)} onFocus={() => {setInputTextTouched(true)}} />
                {customTPNameError.isTPNameError && customTPNameError.errors.map((err, index) =>
                  <p className='tp-validation-errors' key={customTPNameError.errors[index]}>{err}</p>
                )}
              </div>
      }
      <div className='target-form-input-labels'>
        <div className='form-label-empty' />
        <div className='form-label-reduction'>
          <div className='label-reduction-text'>{I18n('t_reduction')} (%)</div>
        </div>
        <div className='form-label-cumulative'>
          <div className='label-reduction-text'>{I18n('t_cumulative')} (%)</div>
        </div>
      </div>
      <div className='target-form-input-container' ref={targetFormItemsRef}>
        {getTPDataTable(selectedScopePathwayData)}
      </div>
      {isCreateOrEditMode() ?
        <div className='asset-nzc-apply-cancel'>
          <div className={'footer-btn-apply'}>
            <Button 
              className={'btn-apply button-highlight'} 
              ref={buttonRef}
              onClick={() => onSubmit()}
              disabled={!targetPathwayEditStatus.customTargetPathwayEdited || !isReductionDataNotEmpty() || customTPNameError.isTPNameError || (customTargetPathwayName && customTargetPathwayName[`tp${tpNumber}`]?.length === 0) || !hasTpPercentageGreaterThanZero}
              data-testid='add-or-edit-tp'>{I18n('t_apply')}</Button>
          </div>
          <div className={'footer-btn-cancel'}>
            <label
              className={'btn-cancel button-highlight'}
              onClick={cancelHandler}>
              {I18n('t_cancel')}
            </label>
          </div>
        </div> : ''
      }
    </div>
  )
}

export default AddOrEditCustomTP