import React from 'react'
import {RadioButton} from 'primereact/radiobutton'
import {  getLocalStorageByKey,  getLocalStorageItem,  refetchUpdatedDataByInvalidatingQueries,  setLocalStorageItem} from '../../../../utils/helpers/Helper'
import {useMutation, useQueryClient} from 'react-query'
import {updateSettings} from '../../../../services/settings-service'
import {LOCALES} from '../../../../utils/i18n'
import { QUERYKEYS_TO_INVALIDATE_ON_CURRENCY_OR_UNIT_CHANGE } from '../../../../utils/helpers/Constants'
import PropTypes from 'prop-types'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const Currency = (props) => {
  const {loginDispatch, loginState} = useLoginAuthContext()
  const userInfo = loginState.userInfo
  const updateCurrencyMutation = useMutation(updateSettings)
  const queryClient = useQueryClient()

  async function currencySelectHandler(data) {
    let selectedCurrencyObj = {
      currencyUnit: data.target.value,
      languagePreference: Object.keys(LOCALES).find(key => LOCALES[key] === userInfo.languagePreference),
      unitSystem: userInfo.unitSystem,
      connectionStep: userInfo.connectionStep // TODO: Need to modify the PUT api for energy star connection step and this needs to discuss with the team
    }
    setLocalStorageItem('userInfo',{...getLocalStorageByKey('userInfo'),currencyUnit: data.target.value})
    try {
      await updateCurrencyMutation.mutateAsync({
        userId: getLocalStorageItem('userId'),
        updatedData: selectedCurrencyObj
      })
      refetchUpdatedDataByInvalidatingQueries(queryClient,QUERYKEYS_TO_INVALIDATE_ON_CURRENCY_OR_UNIT_CHANGE)
    } catch (e) {
      console.log(e.toString())
    }
    loginDispatch({ type: 'UPDATE_USER_INFO', payload: { currencyUnit: data.target.value}})

  }

  function constructCurrencyTemplate() {
    return props.menudata.map((category) => {
      return (
        <React.Fragment key={category.value}>
          <label className="col-8 menuItem-title" data-testid={category.value}>{category.label}</label>
          <div className="col-1"/>
          <div className="col-3 p-field-radiobutton">
            <RadioButton checked={userInfo.currencyUnit === category.value} value={category.value}
              onChange={(e) => currencySelectHandler(e)}/>
          </div>
        </React.Fragment>
      )
    })
  }

  return (
    <div className="grid settings-Language-menuItem">
      {constructCurrencyTemplate()}
    </div>
  )
}

Currency.propTypes = {
  menudata: PropTypes.array.isRequired,
}

export default Currency