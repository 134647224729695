import React from 'react'
import '../ESConnectionStep.scss'
import I18n from '../../../../../../utils/i18n/I18n'
import {Button} from 'primereact/button'
import {ENERGY_STAR_STEPS} from '../../../../../../utils/helpers/Constants'
import PropTypes from 'prop-types'

const ESConnectionSuccess = (props) => {
  function onConfirm() {
    props.onStepChange(ENERGY_STAR_STEPS.SUCCESS_STEP)
  }


  return (
    <div className={'es-connection-step'} data-testid={'esc-success-inf'}>
      <span className={'step-sub-text step-sub-info'}>{I18n('t_es_success')}</span>
      <Button className={'common-blue-bg-btn step-button'} onClick={onConfirm} label={I18n('t_continue')} />
    </div>
  )
}

ESConnectionSuccess.propTypes={
  onStepChange:PropTypes.func.isRequired
}

export default ESConnectionSuccess