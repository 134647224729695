import React from 'react'
import {Skeleton} from 'primereact/skeleton'
import './DataCoverageSkeleton.scss'

const DataCoverageSkeleton = () => {
  return (
    <div className='dq-coverage-skeleton'>
      <Skeleton shape="circle" size="5.5rem" />
      <div className='dq-coverage-skeleton__legends'>
        <div className='dq-coverage-skeleton__legends--legend'>
          <Skeleton width="120px" borderRadius="3px" height="12px" />
        </div>
        <div className='dq-coverage-skeleton__legends--legend'>
          <Skeleton width="10px" borderRadius="3px" height="10px" />
          <Skeleton width="150px" borderRadius="3px" height="12px" />
        </div>
        <div className='dq-coverage-skeleton__legends--legend'>
          <Skeleton width="10px" borderRadius="3px" height="10px" />
          <Skeleton width="150px" borderRadius="3px" height="12px" />
        </div>
      </div>
    </div>
  )
}

export default DataCoverageSkeleton