import { useFormikContext } from 'formik'
import NumberfieldFormik from '../../../common/formik-elements/number-field/NumberfieldFormik'
import CalendarFormik from '../../../common/formik-elements/date-picker/CalendarFormik'
import './AddEditAssetForm.scss'
import { getLocalizedValue } from '../../../../utils/helpers/LanguageUtils'
import PropTypes from 'prop-types'
import { Accordion } from 'components/atomic'
import { calendarDateFormat } from '../../../../utils/helpers/DateUtils'
import { Icon, LinkButton, Text, Tooltip } from 'components/atomic/index'
import { isDataFieldFailing } from './AddEditAssetSidebar'
import React from 'react'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

export function hasAllValuesInArray(array, valuesToCheck) {
  const dataArray = array.map(item => item.code)
  return valuesToCheck.every(value => dataArray.includes(value))
}
        
const StaticReportingForm = ({ dataReadinessChecks }) => {
  const { loginState: { userInfo } } = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const { values, handleChange, errors, touched } = useFormikContext()

  const getTooltipElement = (triggerId, contentKey, readMore = false) => {
    return (
      <>
        <Icon id='info_solid' size={17} className={`${triggerId}`}/>
        <Tooltip target={`.${triggerId}`} width={300} autoHide={!readMore} hideDelay={readMore ? 200 : 0}>
          <Text content={contentKey} />
          {readMore && <LinkButton content='t_click_to_read'/>}
        </Tooltip>
      </>
    )
  }

  return (
    <Accordion items={[
      {
        title: 't_planning_permission',
        leftContent: (
          <Text content='t_planning_permission_extended' />
        ),
        rightContent: (
          <CalendarFormik value={values.planningPermissionDate ? new Date(values.planningPermissionDate) : null} handleChange={handleChange} label={getLocalizedValue(selectedLanguage, 't_planning_permission_date')} name='planningPermissionDate' errors={errors} placeholder={getLocalizedValue(selectedLanguage, 't_select_date')} dateFormat={calendarDateFormat()} testId='planning-permission-date' />
        ),
        warning: isDataFieldFailing(dataReadinessChecks?.PlanningPermission),
        warningTextElement: dataReadinessChecks?.PlanningPermission ? <Text content={`t_reports_${dataReadinessChecks?.PlanningPermission?.[0]}_msg`} /> : null,
        error: (errors?.planningPermissionDate !== undefined),
      },
      {
        title: 't_ped',
        leftContent: (
          <Text content='t_ped_extended' />
        ),
        rightContent: (
          <>
            <NumberfieldFormik value={values.ped} handleChange={handleChange} dataTestId='ped-test' label={getLocalizedValue(selectedLanguage, 't_ped')} suffix=' kWh/m2' placeholder='kWh/m2' name='ped' errors={errors} touched={touched} tooltipElement={getTooltipElement('ped-info', 't_ped_tooltip_content', true)} />
            <NumberfieldFormik value={values.nzebThreshold} handleChange={handleChange} dataTestId='nzeb-threshold-test' label={getLocalizedValue(selectedLanguage, 't_nzeb_threshold')} suffix=' kWh/m2' placeholder='kWh/m2' name='nzebThreshold' errors={errors} touched={touched} tooltipElement={getTooltipElement('nzeb-info', 't_nzeb_tooltip_content', true)}/>
          </>
        ),
        error: (errors && (errors['ped'] !== undefined || errors['nzebThreshold'] !== undefined)),
        warning: isDataFieldFailing(dataReadinessChecks?.PrimaryEnergyDemand),
        warningTextElement: 
          dataReadinessChecks?.PrimaryEnergyDemand ?
            <Text 
              content='t_reports_ped_not_found_msg' 
              contentDynamic={{fields: <>{dataReadinessChecks?.PrimaryEnergyDemand?.map((value, index)=> <React.Fragment key={value}><Text key={value} content={`t_reports_${value}`} /> {index !== dataReadinessChecks?.PrimaryEnergyDemand?.length - 1 && <span>, </span>}</React.Fragment> )}</>}}/>
            : null,
      },
      {
        title: 't_biodiversity',
        leftContent: (
          <Text content='t_biodiversity_extended' />
        ),
        rightContent: (
          <>
            <p className='mr-3 ml-0 mt-0 mb-0 large-text-3'>{getLocalizedValue(selectedLanguage, 't_horizontal_artificial_areas')}</p>
            <NumberfieldFormik value={values.artificialHorizontalAreaM2} handleChange={handleChange} dataTestId='horizontal-artificial' suffix=' m2' placeholder='m2' label={getLocalizedValue(selectedLanguage, 't_horizontal_artificial')} name='horizontalArtificial' errors={errors} touched={touched} tooltipElement={getTooltipElement('horizontal-artificial-info', 't_horizontal_artificial_tooltip_content')} />
            <NumberfieldFormik value={values.totalHorizontalAreaM2} handleChange={handleChange} dataTestId='total-horizontal-area' suffix=' m2' placeholder='m2' label={getLocalizedValue(selectedLanguage, 't_total_horizontal_area')} name='totalHorizontalArea' errors={errors} touched={touched} tooltipElement={getTooltipElement('total-horizontal-info', 't_total_horizontal_tooltip_content')} />
            <p className='mr-3 ml-0 mt-4 mb-0 large-text-3'>{getLocalizedValue(selectedLanguage, 't_vertical_artificial_areas')}</p>
            <NumberfieldFormik value={values.artificialVerticalAreaM2} handleChange={handleChange} dataTestId='vertical-artificial' suffix=' m2' placeholder='m2' label={getLocalizedValue(selectedLanguage, 't_vertical_artificial')} name='verticalArtificial' errors={errors} touched={touched} tooltipElement={getTooltipElement('vertical-artificial-info', 't_vertical_artificial_tooltip_content')} />
            <NumberfieldFormik value={values.totalVerticalAreaM2} handleChange={handleChange} dataTestId='total-vertical-area' suffix=' m2' placeholder='m2' label={getLocalizedValue(selectedLanguage, 't_total_vertical_area')} name='totalVerticalArea' errors={errors} touched={touched} tooltipElement={getTooltipElement('total-vertical-info', 't_total_vertical_tooltip_content')} />
          </>
        ),
        error: (errors && (errors['horizontalArtificial'] !== undefined || errors['totalHorizontalArea'] !== undefined || errors['verticalArtificial'] !== undefined || errors['totalVerticalArea'] !== undefined)),
        warning: isDataFieldFailing(dataReadinessChecks?.BiodiversityDataField),
        warningTextElement: 
          dataReadinessChecks?.BiodiversityDataField ?
            <Text
              content='t_reports_biodiversity_not_found_msg' 
              contentDynamic={{areas: <>{dataReadinessChecks?.BiodiversityDataField?.map((value, index)=> <React.Fragment key={value}><Text key={value} content={`t_reports_${value}`} />{index !==  dataReadinessChecks?.BiodiversityDataField?.length - 1 && <span>,</span>}</React.Fragment> )} </>}}/>
            : null,
      }
    ]}></Accordion>
  )
}

StaticReportingForm.propTypes = {
  dataReadinessChecks: PropTypes.object
}

export default StaticReportingForm