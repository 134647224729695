import React from 'react'
import I18n from '../../../../utils/i18n/I18n'
import questionMarkIcon from '../../../../resources/images/icon/question-mark.svg'
import {Checkbox} from 'primereact/checkbox'

const ActionBasedTargetPathways = ({ actionBasedPathwayId, handleQuestionMarkClick, targetPathwayDetailsObj, handleEnergySavingsActionCheckbox, handleSelectedActionBasedPathway,
  actionBasedTPDetails, getOverlayPanel, actionBasedTargetPathwayOP}) => {

  const text = I18n('t_asset_nzc_action_based_target_pathway_info')

  function agreedActionsNotEmpty() {
    return (actionBasedTPDetails?.agreedActions > 0)
  }

  function applicableActionsNotEmpty() {
    return (actionBasedTPDetails?.applicableActions > 0)
  }

  function actionsDataNotEmpty() {
    return !!(actionBasedTPDetails?.agreedActions > 0 || actionBasedTPDetails?.applicableActions > 0)
  }

  return <>
    <div className='action-based-pathways'>
      <div className='action-based-title-icon' id={actionBasedPathwayId}>
        <label htmlFor="target-pathway-sub-titles" className='target-pathway-sub-titles'>{I18n('t_action_based_pathways')}</label>
        <img src={questionMarkIcon} alt="" className='question-mark-icon' aria-label='action-based-tp-question-mark' onClick={(event) => handleQuestionMarkClick(event, 'action-based-tp')}/>
      </div>
      <div className='energy-saving-action'>
        <Checkbox
          inputId="energy-saving-action-checkbox"
          checked={!!(targetPathwayDetailsObj.energySavingActions && actionsDataNotEmpty())}
          onChange={handleEnergySavingsActionCheckbox}
          name='energy-saving-action'
          disabled={!actionsDataNotEmpty()}
        />
        <label htmlFor="energy-saving-action-checkbox" className='energy-saving-action-label' role='energy-saving-action-label'>{I18n('t_energy_saving_action')}</label>
      </div>
      {targetPathwayDetailsObj?.energySavingActions && actionsDataNotEmpty() &&
            <>
              <div className='agreed-action' data-testid="agreed-actions-checkbox">
                <Checkbox inputId="agreed-action-checkbox" name='agreed-action' checked={!!(targetPathwayDetailsObj.agreedActions && agreedActionsNotEmpty())} disabled={!agreedActionsNotEmpty()} onChange={handleSelectedActionBasedPathway} />
                <label htmlFor="agreed-action-checkbox" className="agreed-action-label" data-testid="agreed-actions">{I18n('t_agreed_actions_count', {0: actionBasedTPDetails?.agreedActions})}</label>
              </div>
              <div className='applicable-action' data-testid="applicable-action-checkbox">
                <Checkbox inputId="applicable-action-checkbox" name='applicable-action' checked={!!(targetPathwayDetailsObj.applicableActions && applicableActionsNotEmpty())} disabled={!applicableActionsNotEmpty()} onChange={handleSelectedActionBasedPathway} />
                <label htmlFor="applicable-action-checkbox" className="applicable-action-label" data-testid="applicable-actions">{I18n('t_applicable_actions_count', {0: actionBasedTPDetails?.applicableActions})}</label>
              </div>
            </>
      }
      {getOverlayPanel(actionBasedTargetPathwayOP, actionBasedPathwayId, text)}
    </div>
    <div className={'nzc-h-line'} />
  </>
}

export default React.memo(ActionBasedTargetPathways)