import axios from 'axios'
import {getLocalStorageItem} from '../../utils/helpers/Helper'

const basePaneerURL = process.env.REACT_APP_PANEER_API_ENDPOINT

let axiosInstancePaneer = axios.create({
  baseURL: basePaneerURL,
})


axiosInstancePaneer.interceptors.request.use(
  (config) => {
    const token = getLocalStorageItem('token')
    if (token) {
      config.headers['authorization'] = 'Bearer ' + token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axiosInstancePaneer.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Ensure the error is carrying the response message if available.
  if(Boolean(error.response) && Boolean(error?.response?.data?.title)) {
    error.message = error.response.data.title
  }

  return Promise.reject(error)
})


export default axiosInstancePaneer

