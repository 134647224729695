import PropTypes from 'prop-types'
import React, {useContext} from 'react'
import I18n from '../../../../../utils/i18n/I18n'
import {PortfolioContext, FeatureFlagContext} from 'Contexts'
import {CurrencyUnitToLabel} from '../../../../../utils/helpers/Helper'
import {costMask} from '../../../../../utils/helpers/Constants'
import exclamationIcon from '../../../../../resources/images/icon/exclamation-orange.svg'
import './Dialog.scss'
import {Skeleton} from 'primereact/skeleton'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const PerformanceModalDialogHeader = ({header}) => {
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const currencyUnit = userInfo.currencyUnit
  const pfContext = useContext(PortfolioContext)
  const featureContext = useContext(FeatureFlagContext)
  const featureState = featureContext.featureState
  const isTargetAvailable = pfContext.pfState.showTarget

  const formatUnits = (value) => {
    let newValue = ''
    if (value) {
      newValue = Number(value).toLocaleString(selectedLanguage)
    }
    return newValue
  }

  const formatOverspend = (value, selectedUtility) => {
    let formattedValue = '0'
    let unit = costMask[CurrencyUnitToLabel(currencyUnit)]

    if (selectedUtility === 'Carbon')
      return ''
    else if (selectedUtility === 'Waste')
      unit = ' T'

    if (value !== undefined) {
      value = Math.trunc(value)   // remove decimals for cost
    }
    if (selectedUtility === 'Waste') {
      formattedValue = formatUnits(Math.abs(value)) + unit
    } else {
      let newValue = formatUnits(Math.abs(value))
      formattedValue = unit + (newValue !== '' ? newValue : 0)
    }
    return formattedValue
  }
  function getPerfModalHeaderLocalizedLabel(msgKey) {
    if(msgKey){
      msgKey=msgKey?.toLowerCase()
      msgKey=(msgKey === 'gasfuelsandthermals' || msgKey === 't_gasfuelsandthermals') ? 't_gas_fuel_thermal' : msgKey
      if(!(msgKey && msgKey.includes('t_'))){
        msgKey=`t_${msgKey}`
      }
      return I18n(msgKey)
    }else{
      return <div><Skeleton width={'5rem'} height={'0.56rem'}/></div>
    }
  }

  function getVarianceColorClass() {
    let colorClass

    if (header?.utility !== 't_waste')
      colorClass = header?.variance.value < -5 ? 'green' : (header?.variance.value > 5 ? 'red' : '')
    else colorClass = header?.variance.value < -5 ? 'red' : (header?.variance.value > 5 ? 'green' : '')

    return colorClass
  }

  return (
    <>
      {
        featureState?.sieraplus_assetleveldataestimation ?  <div className="perf-modal-dlg-header col-12 grid-nogutter">
          <div className="utility-month"><span> {getPerfModalHeaderLocalizedLabel(header?.utility)}</span>
            <span className={'pl-2 pr-2'}>-</span>
            <span>{getPerfModalHeaderLocalizedLabel(header?.month)}</span>
          </div>
          <div className='utility-consumption-overview grid-nogutter'>
            <div className="consumption-category">
              <span className="category-title">{getPerfModalHeaderLocalizedLabel(header?.actual.label)}</span>
              <span className="category-percentage">{isTargetAvailable ? ((header?.target.percentage !== null && header?.target.percentage !== undefined && header?.target.percentage !== '') && header?.actual.percentage + '%') : ''}</span>
              <span className="category-value">{header?.actual.value}</span>
            </div>
            <div className="consumption-category">
              <span className="category-title" >{getPerfModalHeaderLocalizedLabel(header?.gapFilledData.label)}</span>
              {header?.gapFilledData?.value !== '-' ? <>
                <img src={exclamationIcon} className={'perf-icon'} alt={'exclamation icon'}/>
                <span className="category-value">{header?.gapFilledData?.value}</span>
              </> : '-'}
            </div>
          </div>
          <div  className='utility-consumption-overview grid-nogutter'>
            <div className="consumption-category">
              <span className="category-title">{getPerfModalHeaderLocalizedLabel(header?.target.label)}</span>
              <span className="category-percentage">{isTargetAvailable || header?.hasTarget ?  (
                (header?.target.percentage !== null && header?.target.percentage !== undefined && header?.target.percentage !== '') 
                  && header?.target.percentage + '%') : ''
              }</span>
              <span className="category-value">{header?.target.value}</span>
            </div>
            <div className="consumption-category">
              <span className="category-title"> {getPerfModalHeaderLocalizedLabel(header?.variance.label)}</span>
              <span className={`category-value ${getVarianceColorClass()}`}>{Math.abs(header?.variance.value) + '%'}</span>
            </div>
            <div className="consumption-category">
              <span className="category-title">{getPerfModalHeaderLocalizedLabel(header?.overspend.label)}</span>
              <span className={`category-value ${getVarianceColorClass()}`}>{formatOverspend(header?.overspend.value, header?.utility)}</span>
            </div>
          </div>
        </div> : <div className="perf-modal-dlg-header col-12 grid-nogutter" data-testid={'pref-modal-header'}>
          <div className="utility-month"><span> {getPerfModalHeaderLocalizedLabel(header?.utility)}</span> - <span>{getPerfModalHeaderLocalizedLabel(header?.month)}</span>
          </div>
          <div className='utility-consumption-overview grid-nogutter'>
            <div className="consumption-category">
              <span className="category-title">{getPerfModalHeaderLocalizedLabel(header?.actual.label)}</span>
              <span className="category-percentage">{isTargetAvailable ? ((header?.target.percentage !== null && header?.target.percentage !== undefined && header?.target.percentage !== '') && header?.actual.percentage + '%') : ''}</span>
              <span className="category-value">{header?.actual.value}</span>
            </div>
            <div className="consumption-category">
              <span className="category-title">{getPerfModalHeaderLocalizedLabel(header?.target.label)}</span>
              <span className="category-percentage">{isTargetAvailable ? ((header?.target.percentage !== null && header?.target.percentage !== undefined && header?.target.percentage !== '') && header?.target.percentage + '%') : ''}</span>
              <span className="category-value">{header?.target.value}</span>
            </div>
            <div className="consumption-category">
              <span className="category-title"> {getPerfModalHeaderLocalizedLabel(header?.variance.label)}</span>
              <span className={`category-value ${getVarianceColorClass()}`}>{Math.abs(header?.variance.value) + '%'}</span>
            </div>
            <div className="consumption-category" style={{display: header?.utility !== 'Carbon' ? 'block' : 'none'}}>
              <span className="category-title">{getPerfModalHeaderLocalizedLabel(header?.overspend.label)}</span>
              <span className={`category-value ${getVarianceColorClass()}`}>{formatOverspend(header?.overspend.value, header?.utility)}</span>
            </div>
          </div>
        </div>
      }
    </>
  )
}
export default PerformanceModalDialogHeader

PerformanceModalDialogHeader.displayName = 'PerformanceModalDialogHeader'

PerformanceModalDialogHeader.propTypes = {
  header: PropTypes.shape({
    actual: PropTypes.object,
    gapFilledData: PropTypes.object,
    variance: PropTypes.object,
    month: PropTypes.string,
    utility: PropTypes.string,
    target: PropTypes.object,
    hasTarget: PropTypes.bool
  })
}