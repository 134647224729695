import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Chart } from 'primereact/chart'
import './StaticFundNZCGraph.scss'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'
import { getNZCChartSieraPlusLogo } from '../../net-zero-carbon-home/nzc-chart-helper/nzc-chart-helper'
import { ChartSkeleton } from 'components/common/skeletons/nzc-fund-skeleton/NZCAssetTableSkeleton'
import { createStaticNZCGraphDataSets } from './static-fund-graph-utils'

const StaticFundNZCGraph = ({graphRef, targetPathwaysData, isLoading, showApplicableActions}) => {
  const {loginState} = useLoginAuthContext()
  const selectedLanguage = loginState.userInfo.languagePreference

  if(isLoading) { 
    return <div className='static-nzc-chart-graph-container'>
      {getNZCChartSieraPlusLogo()}
      <ChartSkeleton/>
    </div>
  }

  return (
    <div className='static-nzc-chart-graph-container'>
      <Chart {...createStaticNZCGraphDataSets(targetPathwaysData, showApplicableActions, selectedLanguage)} plugins={[ChartDataLabels]} ref={graphRef}/>
    </div>
  )
}

StaticFundNZCGraph.propTypes = {
  targetPathwaysData: PropTypes.object,
  isLoading: PropTypes.bool,
  graphRef: PropTypes.object,
  showApplicableActions: PropTypes.bool,
}

export default memo(StaticFundNZCGraph)