import { noRefetchOnWindowFocus } from '../../../../services/common/useQuery-config'
import { useQueries } from 'react-query'
import { GetAddAssetLocationFilterDetails } from 'services/assets/asset-details-service'
import { GetAssetNameDetails, GetAssetReferenceDetails } from 'services/assets/portfolio-service'

const addEditQueryData = ({
  regionFilterVariables,
  assetNameVariable,
  assetRefVariable
}) => {
  const [regionFilterData, assetNameData, assetRefData] = useQueries([
    { queryKey: regionFilterVariables, queryFn: GetAddAssetLocationFilterDetails, ...noRefetchOnWindowFocus, enabled: false },
    { queryKey: assetNameVariable, queryFn: GetAssetNameDetails, ...noRefetchOnWindowFocus, enabled: false },
    { queryKey: assetRefVariable, queryFn: GetAssetReferenceDetails, ...noRefetchOnWindowFocus, enabled: false }
  ])

  return {
    regionFilterData, assetNameData, assetRefData
  }
}

export default addEditQueryData