import React, { useContext, useEffect, useRef, useState } from 'react'
import './StaticFundNetZeroCarbon.scss'
import FundCommonHeader from '../common-header/FundCommonHeader'
import {
  CachedUserState,
  getFundViewsNavLinkConfigsForFlags,
} from 'utils/helpers/Helper'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'
import { FeatureFlagContext } from 'Contexts'
import {
  CacheKeyFundGroups,
  DATA_FROM,
  nzcStaticIntensityOptions,
} from 'utils/helpers/Constants'
import { GetStaticNZCTargetPathways } from 'services/funds/nzc-service'
import { useQuery } from 'react-query'
import { noRefetchOnWindowFocus } from 'services/common/useQuery-config'
import StaticNZCFilterHeader from './static-nzc-filter-header/StaticNZCFilterHeader'
import StaticNZCPathwayHeader from './static-nzc-pathway-header/StaticNZCPathwayHeader'
import PropTypes from 'prop-types'
import { Icon, Text } from 'components/atomic/index'
import { Toast } from 'primereact/toast'
import StaticFundNZCGraph from './static-nzc-line-graph/StaticFundNZCGraph'
import StaticFundNZCNotesCard from './static-nzc-cards/notes-card/StaticFundNZCNotesCard'
import StaticFundNZCPerformanceCard from './static-nzc-cards/performance-card/StaticFundNZCPerformanceCard'
import NzcExportOverlay from '../net-zero-carbon-home/nzc-popup/NzcExportOverlay'
import { useI18n } from 'utils/i18n/I18n'

export const chartSpecsToastKeys = ['t_grid_decarbonisation_toast', 't_sbt_toast', 't_bau_toast', 't_agreed_actions_toast']

const StaticFundNetZeroCarbon = (props) => {
  const { fundGroupId } = props
  const intl = useI18n()
  const exportOverlayRef = useRef(null)
  const graphRef = useRef(null)
  const [isNZCExportOverlayPanelOpen, setIsNZCExportOverlayPanelOpen] = useState(false)
  const toastRef = useRef(null)
  const [selectedFund, setSelectedFund] = useState({})
  const [intensity, setIntensity] = useState(nzcStaticIntensityOptions[0].value)
  const [showApplicableActions, setShowApplicableActions] = useState(false)
  const [showToast, setShowToast] = useState(true)
  const { loginState } = useLoginAuthContext()
  const featureContext = useContext(FeatureFlagContext)
  const featureState = featureContext.featureState
  const selectedLanguage = loginState.userInfo.languagePreference
  const navLinkConfigs = getFundViewsNavLinkConfigsForFlags(
    selectedFund,
    selectedLanguage,
    featureState
  )
  const {
    isFetching: targetPathwaysLoading,
    data: targetPathwaysData,
  } = useQuery({
    queryKey: ['GetStaticNZCTargetPathways', fundGroupId, intensity],
    queryFn: GetStaticNZCTargetPathways,
    retry: false,
    refetchOnWindowFocus: noRefetchOnWindowFocus.refetchOnWindowFocus
  })

  const {strandings, totalAssets, totalAgreedActions, totalApplicableActions} = targetPathwaysData || {}

  useEffect(() => {
    const fundState = props.location?.state
    if (fundState) {
      setSelectedFund(fundState)
    } else {
      setSelectedFund(CachedUserState.getUserDataFromCache(CacheKeyFundGroups))
    }
  }, [])

  useEffect(() => {
    if(!targetPathwaysLoading && targetPathwaysData && showToast) {
      toastRef.current.show({
        life: 10000,
        className: 'static-nzc-chart-specs-toast',
        content: (
          <div className="flex flex-row align-self-center" style={{ flex: '1' }}>
            <div className="text-left">
              <div className='flex flex-row align-items-center gap-2 mb-3'> 
                <Icon id='info_solid' size={17}/>
                <Text content='t_chart_specification' weight='bold' size='xs'/>
              </div>
              <Text content='t_chart_preconfigured'/>
              <ul className='p-0'>
                {chartSpecsToastKeys.map((key) => (
                  <Text content={key} key={key} size='xs' htmlTag='li'/>
                ))}
              </ul>
            </div>
          </div>
        )
      })
      setShowToast(false)
    }
  }, [targetPathwaysData, targetPathwaysLoading])

  const handleApplicableActionsChange = (event) => {
    setShowApplicableActions(event.checked)
  }

  const handleIntensityChange = (e) => {
    setIntensity(e.target.value)
  }

  const onShowExportOverlayPanel = () => {
    setIsNZCExportOverlayPanelOpen(true)
  }

  const onHideExportOverlayPanel = () => {
    setIsNZCExportOverlayPanelOpen(false)
  }

  const excelExportInfo = {
    options:{
      enableExport: !!targetPathwaysData,
      hasOverlay: false,
      dataFrom: DATA_FROM.FUND.FUND_NZC
    },
  }

  return (
    <div className="grey-bg static-net-zero-carbon min-width-1280 scrollbar-track-hide container-layout gutter" data-testid='static-fund-nzc'>
      <Toast ref={toastRef} position='top-right' />
      <div className='static-net-zero-carbon__fund-header'>
        <FundCommonHeader
          hasDatePicker={false}
          hasBlueBackground={false}
          hasDropdownFilter={false}
          navLinkConfigs={navLinkConfigs}
          action={intl.formatMessage({ id: 't_export' })}
          onActionClick={(e) => exportOverlayRef.current.toggle(e)}
          excelExportInfo={excelExportInfo}
          isNZCExportOverlayPanelOpen={isNZCExportOverlayPanelOpen}
        />
        <NzcExportOverlay {...{exportOverlayRef, nzcGraphRef: graphRef, selectedFund, selectedLanguage, dataFrom: DATA_FROM.FUND.FUND_NZC, onShowExportOverlayPanel, onHideExportOverlayPanel, intensity}} />
      </div>
      <StaticNZCFilterHeader 
        intensity={intensity}
        totalAssets={totalAssets}
        handleIntensityChange={handleIntensityChange}
        isLoading={targetPathwaysLoading}
        fundName={selectedFund?.fundname}
      />
      <StaticNZCPathwayHeader
        isLoading={targetPathwaysLoading}
        strandings={strandings}
        applicableChecked={showApplicableActions}
        handleApplicableActionsChange={handleApplicableActionsChange}
        totalApplicableActions={totalApplicableActions}
        totalAgreedActions={totalAgreedActions}
      />
      <div className='static-net-zero-carbon__graph-card' data-testid='static-nzc-graph-section'>
        <Text content='t_kg_co2_year' />
        <StaticFundNZCGraph 
          targetPathwaysData={targetPathwaysData} 
          isLoading={targetPathwaysLoading} 
          graphRef={graphRef} 
          showApplicableActions={showApplicableActions}
        />
      </div>
      <div className='flex flex-row gap-3 mb-5'>
        <StaticFundNZCNotesCard />
        <StaticFundNZCPerformanceCard performanceData={targetPathwaysData?.scopeFundPerformance} isLoading={targetPathwaysLoading} />
      </div>
    </div>
  )
}

StaticFundNetZeroCarbon.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.any,
  }),
  fundGroupId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default StaticFundNetZeroCarbon