import React from 'react'
import BackNavigation from '../../components/common/back-nav/BackNavigation'
import {OverlayPanel} from 'primereact/overlaypanel'
import PropTypes from 'prop-types'

const AssetLayout = ({children, getBackNavigationProps, getOverlayPanelProps, renderAssetExport, pageContainerClass}) => {

  const defaultPropsValue = {}

  return (
    <div data-page_identifier='asset_performance' className='grey-bg min-width-1280 asset-layout'>
      <div className={`container-layout gutter ${pageContainerClass ?? ''}`}>
        <div className="nav-tab-section">
          <BackNavigation
            {...(getBackNavigationProps ? getBackNavigationProps() : defaultPropsValue)}
            aria-haspopup
            aria-controls="overlay_panel"
          />
        </div>
        <div className="export-overlay-panel-section">
          <OverlayPanel {...(getOverlayPanelProps ? getOverlayPanelProps() : defaultPropsValue)} >
            {renderAssetExport?.()}
          </OverlayPanel>
        </div>
        <div className='layout-body-section'>
          {children}
        </div>
      </div>
    </div>
  )
}

AssetLayout.propTypes = {
  children: PropTypes.object,
  getBackNavigationProps: PropTypes.func,
  getOverlayPanelProps: PropTypes.func,
  renderAssetExport: PropTypes.func,
  pageContainerClass: PropTypes.string,
}

AssetLayout.displayName = 'AssetLayout'

export default AssetLayout