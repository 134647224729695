import {useEffect, useState} from 'react'
import {calculateLabelPosition} from '../../../../../../utils/helpers/Helper'
import './FundProcurementChart.scss'
import PropTypes from 'prop-types'
import FundDataQualityChart from '../../../fund-common-components/FundDataQualityChart'

const FundProcurementChartV2 = ({data, selectedLanguage}) => {
  const [chartData, setChartData] = useState(data)
  const [labelOverlapClass, setLabelOverlapClass] = useState('')

  useEffect(() => {
    if(data?.length > 0) {
      const chartDataArray = Object.entries(data[0])?.filter(entry => entry[0] !== 'responsibility' && entry[0] !== 'utility' && entry[1] !== 0)
      setChartData(chartDataArray)
      setLabelOverlapClass(calculateLabelPosition(chartData))
    } else {
      setChartData([])
    }
  }, [data])

  function getFundDataQualityChartProps() {
    return {chartData, labelOverlapClass, selectedLanguage}
  }

  return (
    <>
      <div className="chart-wrap">
        <FundDataQualityChart {...getFundDataQualityChartProps()} />
      </div>
    </>
  )
}

FundProcurementChartV2.propTypes = {
  data: PropTypes.array.isRequired,
  selectedLanguage: PropTypes.string
}

FundProcurementChartV2.displayName = 'FundProcurementChartV2'

export default FundProcurementChartV2