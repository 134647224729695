import { useQueries } from 'react-query'
import {GetFundPerformanceUtilityGraphData, GetFundPerformanceUtilityCarbonGraphData, GetFundPerformanceSummary} from '../../../../../services/funds/fund-performance-service'
import { noRefetchOnWindowFocus, retryCount } from '../../../../../services/common/useQuery-config'
import {CARBON, PERIOD_TYPES, PERIOD_VIEWS} from '../../../../../utils/helpers/Constants'

const useFundPerformanceData = (updateFundPerformanceState, state) => {

  const {timePeriod, periodView, fundPerformanceUtilityGraphDataVariables, fundPerformanceSummaryVariables, selectedUtility} = state

  const mapConsumptionUtilityKeysToUtilityLabel = (utilityName) => {
    let utilityKey
    switch (utilityName) {
    case 'electricityPercentage':
      utilityKey = 'electricity'
      break
    case 'EnergyIntensity':
      utilityKey = 'energyIntensity'
      break
    case 'GasFuelsAndThermals':
      utilityKey = 'gasFuelsAndThermals'
      break
    case 'Water':
      utilityKey = 'water'
      break
    case 'Waste':
      utilityKey = 'waste'
      break
    case 'Carbon':
      utilityKey = 'carbonEmission'
      break
    default:
      utilityKey = 'electricity'
    }
    return utilityKey
  }

  const onSuccessFundPerformanceGraphData = (data) => {
    let selectedUtilityData = data[mapConsumptionUtilityKeysToUtilityLabel(selectedUtility.name)]
    if( timePeriod ===PERIOD_TYPES.YTD && periodView === PERIOD_VIEWS.QUARTERLY) {
      if(!selectedUtilityData) {
        updateFundPerformanceState({periodView: PERIOD_VIEWS.QUARTERLY })
      }
    }
  }

  const getConsumptionData = (selectedUtility) => {
    if (selectedUtility.name === CARBON) {
      return {
        queryKey: fundPerformanceUtilityGraphDataVariables,
        queryFn: GetFundPerformanceUtilityCarbonGraphData,
        onSuccess: onSuccessFundPerformanceGraphData,
        refetchOnWindowFocus: noRefetchOnWindowFocus.refetchOnWindowFocus,
        retry: retryCount,
      }
    } else {
      return {
        queryKey: fundPerformanceUtilityGraphDataVariables,
        queryFn: GetFundPerformanceUtilityGraphData,
        onSuccess: onSuccessFundPerformanceGraphData,
        refetchOnWindowFocus: noRefetchOnWindowFocus.refetchOnWindowFocus,
        retry: retryCount,
      }
    }
  }

  const [fundPerformanceUtilityGraphData, fundPerformanceSummaryData] = useQueries([
    getConsumptionData(selectedUtility),
    {
      queryKey: fundPerformanceSummaryVariables,
      queryFn: GetFundPerformanceSummary,
      refetchOnWindowFocus: noRefetchOnWindowFocus.refetchOnWindowFocus,
      retry: retryCount,
    }
  ])

  return {fundPerformanceUtilityGraphData, fundPerformanceSummaryData}
}

export default useFundPerformanceData