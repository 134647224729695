import React, {Suspense, useReducer} from 'react'
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom'
import {QueryClient, QueryClientProvider,} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'

import './App.scss'
import Header from './components/common/header/Header'
import DataQualityHome from './components/pages/assets/data-quality/data-quality-home/DataQualityHome'
import PassportHome from './components/pages/assets/passport/passport-home/PassportHome'
import PerformanceHome from './components/pages/assets/performance/performance-home/PerformanceHome'
import Notification from './components/pages/notification/Notification'
import Terms from './components/common/terms/Terms'
import Cookies from './components/common/cookies/Cookies'
import Privacy from './components/common/privacy/Privacy'
import ExpiredPassword from './components/pages/login/expired-password/ExpiredPassword'
import ForgotPassword from './components/pages/login/forgot-password/ForgotPassword'
import ForgotPasswordSuccess from './components/pages/login/messages/ForgotPasswordSuccess'
import ResetPassword from './components/pages/login/reset-password/ResetPassword'
import ResetPasswordSuccess from './components/pages/login/messages/ResetPasswordSuccess'
import AccountLocked from './components/pages/login/messages/AccountLocked'
import {portfolioReducer, portfolioStateInit} from './utils/reducers/PortfolioReducer'
import { toastReducer, toastInitState } from 'utils/reducers/ToastReducer'
import {featureFlagReducer, featureFlagInit, Flags} from './utils/reducers/FeatureFlagReducer'
import EditAsset from './components/pages/admin/asset/edit-asset/EditAsset'
import AssetAdd from './components/pages/admin/asset/asset-add/AssetAdd'
import UsersHome from './components/pages/admin/users/users-home/UsersHome'
import UserDetailsHome from './components/pages/admin/users/user-overview/UserDetailsHome'
import UserDetailsAdd from './components/pages/admin/users/users-add/UserDetailsAdd'
import UserDetailsEdit from './components/pages/admin/users/user-details-edit/UserDetailsEdit'
import SignUp from './components/pages/admin/users/sign-up/SignUp'
import GroupsHome from './components/pages/admin/groups/groups-home/GroupsHome'
import GroupDetailsHome from './components/pages/admin/groups/group-overview/GroupDetailsHome'
import FundReportsHome from './components/pages/funds/fund-reports/FundReportsHome'
import FundPerformanceHome from './components/pages/funds/performance/FundPerformanceHome'
import FundActionHome from './components/pages/funds/action/FundActionHome'
import FeatureRoute from './components/common/feature-flags/FeatureFlagRoute'
import FundNetZeroCarbonHome from './components/pages/funds/net-zero-carbon-home/FundNetZeroCarbonHome'
import {withFundGroupList} from './components/HigherOrderComponents'
import withBreadCrumb from './components/common/bread-crumb/withBreadCrumb'
import FundAccessRoute from './components/common/access-routes/FundAccessRoute'
import ScrollToTop from './utils/scroll-to-top/ScrollToTop'
import UserAdminPageRoute from './components/common/access-routes/UserAdminPageRoute'
import NzcSingleFundPageRoute from './components/common/access-routes/NzcSingleFundPageRoute'
import GroupDetailsEdit from './components/pages/admin/groups/group-edit/GroupDetailsEdit'
import GroupDetailsAdd from './components/pages/admin/groups/group-add/GroupDetailsAdd'
import SpinningLoader from './components/common/spinning-loader/SpinningLoader'
import ESConnectionHome from './components/pages/energy-star/energy-star-connection/energy-star-home/ESConnectionHome'
import AssetNetZeroCarbon from './components/pages/assets/net-zero-carbon/AssetNetZeroCarbon'
import AssetNzcProtectedRoute from './components/common/access-routes/asset-routes/AssetNzcProtectedRoute'
import Footer from './components/common/footer/Footer'
import ConsumptionUploadHome from './components/pages/consumption-upload/consumption-upload-home/ConsumptionUploadHome'
import NoMatchedRoute from './components/pages/errors/NoMatchedRoute'
import {TransferLinkData} from './utils/helpers/Helper'
import FundDataQualityHome from './components/pages/funds/data-quality-home/FundDataQualityHome'
import AssetActionDetails from './components/pages/assets/action-plan-v2/asset-action-details/AssetActionDetails'
import ActionPlanHome from './components/pages/assets/action-plan/action-plan-home/ActionPlanHome'
import AssetActionPlanHome from './components/pages/assets/action-plan-v2/AssetActionPlanHome'
import DashboardHomeV3 from './components/pages/assets/dashboard/dashboard-home/DashboardHomeV3'

import { Auth0ProviderWithHistory } from './components/pages/login/auth0/Auth0Provider'
import { UserInfoProvider } from './components/pages/login/auth0/UserInfoProvider'
import { Auth0Login } from './components/pages/login/auth0/Login'
import { SwitchInstance } from './components/pages/login/auth0/SwitchInstance'
import DocumentsHome from './components/pages/documents/documents-home/DocumentsHome'
import { PortfolioContext, FeatureFlagContext, ToastContext } from 'Contexts'
import PortfolioHomeV3 from './components/pages/assets/portfolio/portfolio-home/PortfolioHomeV3'
import ToastMessage from 'components/common/toast-messages/ToastMessages'
import CertificateHome from 'components/pages/certificates/certificates-home/CertificatesHome'
import FundDataQualityHomeV2 from './components/pages/funds/data-quality-home/FundDataQualityHomeV2'
import DataQualityHomeV2 from './components/pages/assets/data-quality/data-quality-home/DataQualityHomeV2'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000, // set to 1 second to prevent multiple requests on render https://tkdodo.eu/blog/react-query-as-a-state-manager#customize-staletime
    },
  },
})

function lazyWithPreload(factory) {
  const Component = React.lazy(factory)
  Component.preload = factory
  return Component
}

const OrganisationsHome = lazyWithPreload(() => import('./components/pages/organisations/OrganisationsHome'))
const FundGroup         = lazyWithPreload(() => import('./components/pages/funds/fund-groups/fund-group-home/FundGroupHome'))
const Portfolio         = lazyWithPreload(() => import('./components/pages/assets/portfolio/portfolio-home/PortfolioHome'))
const FundDashboardV3 = lazyWithPreload(() => import('./components/pages/funds/fund-dashboard/FundDashboardV3'))
OrganisationsHome.preload()
FundGroup.preload()
Portfolio.preload()
FundDashboardV3.preload()

const FundReports = withBreadCrumb(FundReportsHome)

function App() {
  const [pfState, pfDispatch] = useReducer(portfolioReducer, portfolioStateInit)
  const [featureState, featureFlagDispatch] = useReducer(featureFlagReducer, featureFlagInit)
  const [toastState, toastDispatch] = useReducer(toastReducer, toastInitState)

  TransferLinkData()

  return (
    <Router>
      <ScrollToTop />
      <QueryClientProvider client={queryClient}>
        <FeatureFlagContext.Provider value={{featureState, featureFlagDispatcher: featureFlagDispatch}}>
          <Auth0ProviderWithHistory>
            <UserInfoProvider>
              <PortfolioContext.Provider value={{pfState: pfState, pfDispatcher: pfDispatch}}>
                <Header/>
                <ToastContext.Provider value={{toastState: toastState, toastDispatcher: toastDispatch}}>
                  <ToastMessage />
                  <div className='min-h-screen'>
                    <Suspense fallback={<SpinningLoader />} >
                      <Switch>
                        <Route path="/login" component={Auth0Login}/>
                        <Route path="/callback"><Redirect to="/" /></Route>
                        <Route path="/switch-instance" component={SwitchInstance}/>
                        <Route path="/notfound" component={NoMatchedRoute}/>
                        <Route path="/expired-password" component={ExpiredPassword}/>
                        <Route path="/signUp" render={(props) => <SignUp parameter={props}/>}/>
                        <Route path="/forgot-password" exact component={ForgotPassword} />
                        <Route path="/forgot-password-success" component={ForgotPasswordSuccess}/>
                        <Route path="/reset-password" exact component={ResetPassword}/>
                        <Route path="/reset-password-success" component={ResetPasswordSuccess}/>
                        <Route path="/account-locked" render={(props) => <AccountLocked parameter={props}/>}/>
                        <Route path="/terms" component={Terms}/>
                        <Route path="/cookies" component={Cookies}/>
                        <Route path="/privacy" component={Privacy}/>
                        <Route path="/notification" exact component={Notification}/>
                        <Route path="/documents" component={withBreadCrumb(DocumentsHome)}></Route>
                        <FeatureRoute path="/certificates" component={withBreadCrumb(CertificateHome)} flag={Flags.GBC} />
                        <Route path="/energy-star-integration" exact component={withBreadCrumb(ESConnectionHome)} flag={Flags.EnergyStarIntegration}/>
                        <Route path="/users/:userId" exact component={withBreadCrumb(UserDetailsHome)}/>
                        <Route path="/users/:userId/edit" exact component={UserDetailsEdit}/>
                        <FeatureRoute path="/admin/add-asset-form" exact component={AssetAdd} flag={Flags.UserAndAssetAdmin}/>
                        <FeatureRoute path="/groups/:groupId/edit" exact component={GroupDetailsEdit} flag={Flags.UserAndAssetAdmin}/>
                        <FeatureRoute path="/groups/:groupId" exact component={withBreadCrumb(GroupDetailsHome)} flag={Flags.UserAndAssetAdmin}/>
                        <FeatureRoute path="/my-groups/:groupId" exact component={withBreadCrumb(GroupDetailsHome)} flag={Flags.UserAndAssetAdmin}/>
                        <FeatureRoute path="/:instanceName/:userId/groups" exact component={withBreadCrumb(GroupsHome)} flag={Flags.UserAndAssetAdmin}/>
                        <Route path="/:assetId/edit-asset" exact component={EditAsset} />
                        <Route path="/:assetId/notification" exact component={Notification}/>
                        <FeatureRoute path="/:instanceName/assets" exact component={PortfolioHomeV3} fallback={Portfolio} flag={Flags.PortfolioV3} />
                        <Route path="/:instanceName/:orgName/:fundName/:assetId/dashboard" exact component={withBreadCrumb(DashboardHomeV3)} />
                        <Route path="/:instanceName/:orgName/:fundName/:assetId/performance" exact component={withBreadCrumb(PerformanceHome)} />
                        <FeatureRoute path="/:instanceName/:orgName/:fundName/:assetId/data-quality" exact component={withBreadCrumb(DataQualityHomeV2)} fallback={withBreadCrumb(DataQualityHome)} flag={Flags.DataQualityLayoutV2} />
                        <FeatureRoute path="/:instanceName/:orgName/:fundName/:assetId/action-plan" exact component={withBreadCrumb(AssetActionPlanHome)} fallback={withBreadCrumb(ActionPlanHome)} flag={Flags.AssetActionPlan}/>
                        <Route path="/:instanceName/:orgName/:fundName/:assetId/passport" exact component={withBreadCrumb(PassportHome)} />
                        <Route path="/:instanceName/:orgName/:fundName/:assetId/action-plan/:actionId" exact component={withBreadCrumb(AssetActionDetails)}/>
                        <AssetNzcProtectedRoute path="/:instanceName/:orgName/:fundName/:assetId/net-zero-carbon" exact component={withBreadCrumb(AssetNetZeroCarbon)} />
                        <UserAdminPageRoute path="/:instanceName/users/add" exact component={UserDetailsAdd} flag={Flags.UserAndAssetAdmin}/>
                        <UserAdminPageRoute path="/:instanceName/users" exact component={withBreadCrumb(UsersHome)} flag={Flags.UserAndAssetAdmin}/>
                        <UserAdminPageRoute path="/:instanceName/groups/add" exact component={GroupDetailsAdd} flag={Flags.UserAndAssetAdmin}/>
                        <UserAdminPageRoute path="/:instanceName/groups" exact component={withBreadCrumb(GroupsHome)} flag={Flags.UserAndAssetAdmin}/>
                        <UserAdminPageRoute path="/" exact />
                        <UserAdminPageRoute path="/:consumptionId/:routeVia/upload/:type" exact component={withBreadCrumb(ConsumptionUploadHome)}/>
                        <NzcSingleFundPageRoute path="/:instanceName/:orgName/:fundName/net-zero-carbon" exact component={withBreadCrumb(FundNetZeroCarbonHome)} flag={Flags.NZC} />
                        <FundAccessRoute path="/:instanceName/:orgName/:fundName/data-quality" exact singleFund component={withBreadCrumb(FundDataQualityHomeV2)} fallback={withBreadCrumb(FundDataQualityHome)} flag={Flags.FundDataQualityV2}/>
                        <FundAccessRoute path="/:instanceName/:orgName/:fundName/reports" exact singleFund component={FundReports} flag={Flags.FundReports} />
                        <FundAccessRoute path="/:instanceName/:orgName/:fundName/performance" exact singleFund component={withBreadCrumb(FundPerformanceHome)} flag={Flags.FundViews}/>
                        <FundAccessRoute path="/:instanceName/:orgName/:fundName/action-plan" exact singleFund component={withBreadCrumb(FundActionHome)} flag={Flags.FundViews}/>
                        <FundAccessRoute path="/:instanceName/:orgName/:fundName" exact singleFund component={withBreadCrumb(FundDashboardV3)} flag={Flags.FundViews}/>
                        <FundAccessRoute path="/:instanceName/:orgName" exact component={withBreadCrumb(FundGroup)} flag={Flags.FundViews}/>
                        <FundAccessRoute path="/:instanceName" exact component={withFundGroupList(OrganisationsHome)} flag={Flags.FundViews} />
                        <Route><Redirect to="/notfound" /></Route>
                      </Switch>
                    </Suspense>
                  </div>
                </ToastContext.Provider>
                <Footer />
                <ReactQueryDevtools initialIsOpen={false}/>
              </PortfolioContext.Provider>
            </UserInfoProvider>
          </Auth0ProviderWithHistory>
        </FeatureFlagContext.Provider>
      </QueryClientProvider>
    </Router>
  )
}

export default App