import {useEffect} from 'react'
import {Dropdown} from 'primereact/dropdown'
import {InputText} from 'primereact/inputtext'
import {InputTextarea} from 'primereact/inputtextarea'
import {
  convertDateToUTCFormat,
  dateFormatterConsumption,
  dropdownItemTemplate,
  getDropdownItemTemplate,
  UNIT_IMPERIAL_ENERGY,
  UNIT_IMPERIAL_WATER,
  UNIT_METRIC_ENERGY,
  UNIT_METRIC_WATER,
} from '../../../../../../utils/helpers/Helper'
import {useFormik} from 'formik'
import './AddEditConsumption.scss'
import {UNITS} from '../../../../../../utils/i18n/constants'
import I18n from '../../../../../../utils/i18n/I18n'
import {
  actualOriginList,
  addConsumptionEstimateList,
  estimatedOriginList
} from '../../../../../../utils/helpers/Constants'
import PropTypes from 'prop-types'
import {useMutation} from 'react-query'
import {validateConsumptionDetails} from '../../../../../../services/assets/dataquality-service'
import {Link} from 'react-router-dom'
import {Calendar} from '../../../../../atomic'
import { calendarDateFormat } from '../../../../../../utils/helpers/DateUtils'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const errorMessages = {'comment':I18n('t_validation_comment'),
  'fromDate': I18n('t_validation_from_date_blank'),
  'toDate':I18n('t_validation_to_date_blank'),
  'source':I18n('t_validation_source'),
  'estimate':I18n('t_validation_consumption_specific'),
  'consumption':I18n('t_validation_consumption_blank')
}

const consumptionWarningMap = {'inactive warning':I18n('t_warning_consumption_inactive'),
  'anomaly warning':I18n('t_warning_consumption_same_period')}

const constructObj = (data)=>{
  const isDateModified = new Date(data.fromDate).getDate() !== new Date(data.unEditedFromDate).getDate() ||
      new Date(data.toDate).getDate() !== new Date(data.unEditedToDate).getDate()
  return {
    'value' : Number(data.consumption),
    'dateStart' : convertDateToUTCFormat(data.fromDate),
    'dateEnd' :  convertDateToUTCFormat(data.toDate),
    'errorCode': 0,
    'errorMessage': '',
    'errorConsumption': '',
    'warnConsumption': '',
    'meterId':data.meterId.toString(),
    'isEdit': !!(data.rangeId && data.rangeId !== 0),
    'isDateModified':  data.rangeId && data.rangeId !== 0 ? isDateModified : false
  }
}

function convertToCustomFormat(errorString) {
  const regex = /From:\s*(.*)/
  return  regex.exec(errorString)[1]
}

const CONSUMPTION = 'consumption'

export function AddEditConsumption({selectedData, index, onNextClick, isSubmitClicked,activeIndex}) {
  const {loginState: {userInfo}} = useLoginAuthContext()
  const unitSystem = userInfo.unitSystem
  const validateData = useMutation(validateConsumptionDetails)
  const isFormFieldValid = (name) =>  selectedData.isDirty && formik.errors[name]?  true : formik.touched[name] && formik.errors[name]

  const getFormErrorMessage = (name) => {
    const consumptionClass = formik.errors['consumption']?.props?.id !== 't_validation_consumption_blank' ? 'p-error p-warning' : 'p-error'
    const getClassName =  name !== CONSUMPTION ? 'p-error' : consumptionClass
    return isFormFieldValid(name) && <div className={getClassName}>{formik.errors[name]}</div>
  }

  const getValidationBorder = (source)=>{
    if(source === CONSUMPTION && isFormFieldValid(CONSUMPTION)){
      return formik.errors[CONSUMPTION]?.props?.id !== 't_validation_consumption_blank' ? 'p-warning-border' : 'p-error-border'
    }
    return isFormFieldValid(source)? 'p-error-border' : ''
  }

  const measurementMap = () => {
    if (selectedData.utility === 'Water') {
      return [UNIT_METRIC_WATER,UNIT_IMPERIAL_WATER]
    } else {
      return [UNIT_METRIC_ENERGY,UNIT_IMPERIAL_ENERGY]
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange:true,
    validateOnBlur:true,
    validateOnMount:selectedData.isDirty,
    initialValues: getInitialValues(),
    validate: async (data) => {
      let errors = {}
      if (!data.estimate) {
        errors.estimate = errorMessages['estimate']
      }
      if (!data.source && data.estimate) {
        errors.source = errorMessages['source']
      }
      if (data.source === 'Calculated estimate' && data.estimate === 'Estimate' &&  !data.comment) {
        errors.comment = errorMessages['comment']
      }
      validateToDate(data,errors)
      validateFromDate(data,errors)
      await validateConsumption(data,errors)
      return errors
    }
  })

  useEffect( () => {
    let data = formik.values
    if (selectedData.isDirty || formik.dirty) {
      (async() => {
        const error = await formik.validateForm()
        data = setAccordianData(data, error)
        onNextClick({data, index})
      } ) ()
    } else {
      data = setAccordianData(data, null)
      onNextClick({data, index})
    }
  }, [isSubmitClicked,activeIndex])

  function setAccordianData(data,error){
    const errorToConsider =  error || formik.errors
    data.isErrors = formik.errors[CONSUMPTION] && formik.errors[CONSUMPTION]?.props.id !== 't_validation_consumption_blank' &&
      Object.values(errorToConsider).length === 1 ? false : Object.values(errorToConsider).length !== 0
    data.isWarnings = formik.errors[CONSUMPTION] && formik.errors[CONSUMPTION]?.props.id !== 't_validation_consumption_blank'
    data.isDirty =  selectedData.isDirty ? selectedData.isDirty : formik.dirty
    return data
  }

  function getInitialValues() {
    return {
      ...selectedData,
      fromDate: selectedData.fromDate ? new Date(dateFormatterConsumption(selectedData, 'START')) : null,
      toDate: selectedData.toDate ? new Date(dateFormatterConsumption(selectedData, 'END')) : null,
      consumption: selectedData?.consumption ? Number(selectedData.consumption).toFixed(0) : '',
      unitOfMeasurement: measurementMap()[unitSystem ===UNITS.IMPERIAL ? 1 : 0],
      estimate: selectedData ? selectedData.estimate : '',
      source: selectedData ? selectedData.source : '',
      comment: selectedData?.comment || '',
      meterId: selectedData?.meterId,
      utility: selectedData?.utility,
      rangeId: selectedData?.rangeId,
      electricityMPAN: selectedData?.electricityMPAN,
      isDirty:selectedData?.isDirty||false,
      isErrors:selectedData?.isErrors||false,
      isWarnings:selectedData?.isWarnings||false
    }
  }

  async function validateConsumption(data,errors) {
    try{
      if (!data.consumption || data.consumption < 0) {
        errors.consumption = errorMessages[CONSUMPTION]
      } else if(data.consumption && data.fromDate && data.toDate) {
        await validateData.mutateAsync(constructObj(data)).then((getResponse)=>{
          if(getResponse.warnConsumption !== ''){
            errors.consumption = consumptionWarningMap[getResponse.warnConsumption]
          }
          if (getResponse.errorMessage !== '') {
            errors.fromDate = I18n('t_validation_date_overlap',{
              0: convertToCustomFormat(getResponse.errorMessage),
              1: <Link to={{pathname : null}}>{I18n('t_learn_more')}</Link>
            })
            errors.toDate = I18n('t_validation_date_overlap',{
              0: convertToCustomFormat(getResponse.errorMessage),
              1: <Link to={{pathname : null}}>{I18n('t_learn_more')}</Link>
            })
          }
        })
      }
    }catch (e) {
      console.log(e)
    }
  }

  function isDateBeforeToday(inputDate) {
    const today = new Date()
    const parsedInputDate = new Date(inputDate)

    return parsedInputDate >= today
  }

  function isFromDateNotGreaterThanToDate(fromDate, toDate) {
    return new Date(fromDate) > new Date(toDate)
  }

  function validateFromDate(data,errors) {
    if (!data.fromDate) {
      errors.fromDate = errorMessages['fromDate']
    }  else if (isFromDateNotGreaterThanToDate(data.fromDate, data.toDate) && data.fromDate) {
      errors.fromDate = I18n('t_validation_from_date_lesser')
    }
  }

  function validateToDate(data,errors){
    if(!data.toDate) {
      errors.toDate = errorMessages['toDate']
    }else if (data.estimate === 'Actual' && isDateBeforeToday(data.fromDate && data.toDate)) {
      errors.toDate = I18n('t_validation_from_date_before_today')
    }
  }

  async function onChangeHelper(event) {
    await formik.setFieldValue('source', '', selectedData.isErrors && selectedData.isDirty)
    formik.handleChange(event)
  }

  return <form onSubmit={formik.handleSubmit} key={selectedData.meterId}>
    <div className={'add-consumption'}>
      <div className={'flex mt-3 add-consumption__period'}>
        <div className={'add-consumption__period--from-date'}>
          <label htmlFor='fromDate' className={'mb-1'}>{I18n('t_required_from_date')}</label>
          <Calendar
            className={getValidationBorder('fromDate')}
            dateFormat={calendarDateFormat()}
            name='fromDate'
            id='fromDate'
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.fromDate} />
          {getFormErrorMessage('fromDate')}
        </div>
        <div className={'add-consumption__period--to-date'}>
          <label htmlFor='toDate' className={'mb-1'}>{I18n('t_required_to_date')}</label>
          <Calendar
            className={getValidationBorder('toDate')}
            dateFormat={calendarDateFormat()}
            name='toDate'
            id='toDate'
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.toDate} />
          {getFormErrorMessage('toDate')}
        </div>
      </div>
      <div className={'mt-3 flex add-consumption__consumption'}>
        <div className={'add-consumption__consumption--consumption'}>
          <label htmlFor='consumption' className={'mb-1'}>{I18n('t_consumption')} *</label>
          <InputText id="consumption" name={'consumption'} value={formik.values.consumption}
            keyfilter='pnum' className={getValidationBorder('consumption')}
            onBlur={formik.handleBlur} onChange={formik.handleChange}/>
          {getFormErrorMessage('consumption')}
        </div>
        <div className={'add-consumption__consumption--measurement'}>
          <label htmlFor='units-measure' className={'mb-1'}>{I18n('t_unit_of_measurement')} *</label>
          <Dropdown value={formik.values.unitOfMeasurement} options={measurementMap()} id='units-measure' onChange={formik.handleChange}
            appendTo={'self'} itemTemplate={getDropdownItemTemplate} name={'unitOfMeasurement'} disabled
            className={'sDropdown'} onBlur={formik.handleBlur}
            panelClassName={'dq-consumption-dropdown'} />
        </div>
      </div>
      <div className={'mt-3 add-consumption__estimate'}>
        <label htmlFor='estimate' className={'mb-1'}>{I18n('t_estimated_actual_required')}</label>
        <Dropdown value={formik.values.estimate} options={addConsumptionEstimateList} id='estimate' onChange={onChangeHelper}
          appendTo={'self'} name={'estimate'} itemTemplate={dropdownItemTemplate}
          className={getValidationBorder('estimate')+' '+'sDropdown'} onBlur={formik.handleBlur}
          panelClassName={'dq-consumption-dropdown'}/>
        {getFormErrorMessage('estimate')}
      </div>
      <div className={'mt-3 add-consumption__origin'}>
        <label htmlFor='origin' className={'mb-1'}>{I18n('t_required_origin')}</label>
        <Dropdown value={formik.values.source} id='source' itemTemplate={getDropdownItemTemplate} appendTo={'self'}
          className={getValidationBorder('source')+' '+'sDropdown'} name={'source'} onChange={formik.handleChange}
          panelClassName={'dq-consumption-dropdown'} disabled={formik.values.estimate === ''}
          options={formik.values.estimate === 'Actual' ? actualOriginList : estimatedOriginList}
          onBlur={formik.handleBlur}/>
        {getFormErrorMessage('source')}
      </div>
      <div className={'mt-3 add-consumption__add-info'}>
        <label htmlFor='consumption-info' className={'mb-1'}>{I18n('t_consumption_information')}</label>
        <InputTextarea id='consumption-info' name={'comment'} value={formik.values.comment} onChange={formik.handleChange}
          autoResize className={getValidationBorder('comment')} maxLength='100' onBlur={formik.handleBlur}/>
        {getFormErrorMessage('comment')}
      </div>
    </div>
  </form>
}

AddEditConsumption.propTypes = {
  selectedData: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  activeIndex:PropTypes.number.isRequired,
  onNextClick:PropTypes.func.isRequired,
  isSubmitClicked: PropTypes.bool.isRequired
}