import axiosInstance from '../common/axios-config'
import {CUSTOM, PERIOD_TYPES} from '../../utils/helpers/Constants'

export const GetFundPerformanceCardsData = async (keys) => {
  const queryKeys = keys.queryKey
  if(!queryKeys || queryKeys.length === 0) {
    return
  }
  const fundGroupID = queryKeys[1]
  const timePeriod = queryKeys[2]
  const noOfMonths = queryKeys[3]
  const endDate = queryKeys[4]

  if(!fundGroupID) {
    return
  }

  if(!noOfMonths || !endDate) {
    const {data} = await axiosInstance.get(`/api/v1/fundperformance/${fundGroupID}?period=${timePeriod === PERIOD_TYPES.CUSTOM ? PERIOD_TYPES.YTD : timePeriod}`)
    return data
  } else {
    const {data} = await axiosInstance.get(`/api/v1/fundperformance/${fundGroupID}?period=${timePeriod}&noOfMonths=${noOfMonths}&endMonth=${endDate}`)
    return data
  }
}
export const GetFundPerformanceCardsDataForAllUtilities = async (keys) => {
  const queryKeys = keys.queryKey
  if(!queryKeys || queryKeys.length === 0) {
    return
  }
  const {fundGroupId, time, timeNoOfMonths, timeEndDate} = keys.queryKey[1]
  const isExport = keys.queryKey[2]

  if(!fundGroupId) {
    return
  }

  if(!timeNoOfMonths || !timeEndDate) {
    const {data} = await axiosInstance.get(`/api/v1/fundperformance/${fundGroupId}/AllUtilitiesSummary?period=${time === PERIOD_TYPES.CUSTOM ? PERIOD_TYPES.YTD : time}&isExport=${isExport}`)
    return data
  } else {
    const {data} = await axiosInstance.get(`/api/v1/fundperformance/${fundGroupId}/AllUtilitiesSummary?period=${time}&noOfMonths=${timeNoOfMonths}&endMonth=${timeEndDate}&isExport=${isExport}`)
    return data
  }
}

export const GetFundPerformanceUtilityCarbonGraphData = async (keys) => {
  const queryKeys = keys.queryKey
  if(!queryKeys || queryKeys.length === 0) {
    return
  }

  const fundGroupID = queryKeys[1]
  const periodView = queryKeys[2]
  const timePeriod = queryKeys[3]
  const noOfMonths = queryKeys[4]
  const endDate = queryKeys[5]

  if(!fundGroupID) {
    return
  }

  if(!noOfMonths || !endDate) {
    const {data} = await axiosInstance.get(`/api/v1/fundperformance/${fundGroupID}/carbon/consumption?periodView=${periodView}&period=${timePeriod === 'Custom' ? 'YTD' : timePeriod}`)
    return data
  } else {
    const {data} = await axiosInstance.get(`/api/v1/fundperformance/${fundGroupID}/carbon/consumption?periodView=${periodView}&period=${timePeriod}&noOfMonths=${noOfMonths}&endMonth=${endDate}`)
    return data
  }
}

export const GetFundPerformanceUtilityGraphData = async (keys) => {
  const queryKeys = keys.queryKey
  if(!queryKeys || queryKeys.length === 0) {
    return
  }

  const fundGroupID = queryKeys[1]
  const periodView = queryKeys[2]
  const timePeriod = queryKeys[3]
  const noOfMonths = queryKeys[4]
  const endDate = queryKeys[5]
  const selectedUtility = queryKeys[6]

  if(!fundGroupID) {
    return
  }

  if(!selectedUtility) {
    return
  }

  if(!noOfMonths || !endDate) {
    const {data} = await axiosInstance.get(`/api/v1/fundperformance/${fundGroupID}/${selectedUtility}/consumption?periodView=${periodView}&period=${timePeriod === 'Custom' ? 'YTD' : timePeriod}`)
    return data
  } else {
    const {data} = await axiosInstance.get(`/api/v1/fundperformance/${fundGroupID}/${selectedUtility}/consumption?periodView=${periodView}&period=${timePeriod}&noOfMonths=${noOfMonths}&endMonth=${endDate}`)
    return data
  }
}

export const GetFundPerformanceSummary = async (keys) => {
  const queryKeys = keys?.queryKey
  const fundGroupID = queryKeys?.[1]
  const utilitySelected = queryKeys?.[2]
  const timePeriod = queryKeys?.[3]
  const noOfMonths = queryKeys?.[4]
  const endDate = queryKeys?.[5]
  let apiQueryParams

  if(!fundGroupID || !utilitySelected || !timePeriod || !noOfMonths || !endDate) {
    return null
  }
  if(timePeriod=== CUSTOM){
    apiQueryParams=`period=${timePeriod}&noOfMonths=${noOfMonths}&endMonth=${endDate}`
  } else {
    apiQueryParams =`period=${timePeriod}`
  }
  const { data } = await axiosInstance.get(`/api/v1/fundperformance/${fundGroupID}/${utilitySelected}/Summary?${apiQueryParams}`)
  return data
}

export const GetFundPerformanceAssetData = async (keys) => {
  const queryKeys = keys.queryKey
  const {fundGroupId, odataQueryString, time, timeNoOfMonths, timeEndDate, utility, page, pageSize} = queryKeys[1]
  const odataQuery=odataQueryString ?? '?'
  let apiQueryParams
  if(queryKeys?.length === 0 || !fundGroupId || !time || !timeNoOfMonths || !timeEndDate || !utility) {
    return null
  }
  if(time=== CUSTOM){
    apiQueryParams= `period=${time}&noOfMonths=${timeNoOfMonths}&endMonth=${timeEndDate}`
  } else {
    apiQueryParams =`period=${time}`
  }
  if(page !== undefined && page !== null && pageSize) {
    const data = await axiosInstance.get(`/api/v1/fundperformance/${fundGroupId}/${utility}/Assets?${apiQueryParams}&page=${page}&pageSize=${pageSize}`)
    return data
  }
  const data = await axiosInstance.get(`/api/v1/fundperformance/${fundGroupId}/${utility}/assets${odataQuery}&${apiQueryParams}`)
  return data
}

export const GetFundPerformanceExportAssetData = async (keys) => {

  let data
  const {fundGroupId, time, timeNoOfMonths, timeEndDate, utility} = keys.queryKey[1]
  const queryKeys = keys.queryKey

  if(!queryKeys || queryKeys.length === 0) {
    return
  }

  if(!fundGroupId) {
    return
  }

  if(!utility) {
    return
  }

  if(!timeNoOfMonths || !timeEndDate) {
    data = await axiosInstance.get(`/api/v1/fundperformance/${fundGroupId}/${utility}/assetsexport?period=${time === 'CUS' ? 'YTD' : time}`)
  } else {
    data = await axiosInstance.get(`/api/v1/fundperformance/${fundGroupId}/${utility}/assetsexport?period=${time === 'CUS' ? 'YTD' : time}&noOfMonths=${timeNoOfMonths}&endMonth=${timeEndDate}`)
  }    

  return data
}
