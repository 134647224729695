import PropTypes from 'prop-types'
import { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { getLocalStorageByKey } from 'utils/helpers/Helper'
import { ASSET_PAGES, LOCAL_STORAGE_KEYS } from 'utils/helpers/Constants'
import { FeatureFlagContext } from 'Contexts'

const FeatureRoute = ({component: Component, fallback: Fallback, ...rest}) => {
  const featureFlagContext = useContext(FeatureFlagContext)
  const userInfo = getLocalStorageByKey(LOCAL_STORAGE_KEYS.userInfo)

  if (featureFlagContext.featureState[rest.flag])
    return <Route render={props => <Component {...props} />} {...rest} />
  
  if (Fallback)
    return <Route render={props => <Fallback {...props} />} {...rest} />

  return <Redirect to={`/${userInfo.instanceUrlName}/${ASSET_PAGES.assets}`} {...rest} />
}

FeatureRoute.propTypes = {
  component: PropTypes.func.isRequired,
  fallback: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
}

export default FeatureRoute