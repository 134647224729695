import React, {useMemo, useState} from 'react'
import {isBooleanValue} from '../../helpers/Helper'
import {BENCHMARK_OPTIONS} from '../../helpers/Constants'

const useNzcBenchmarkOptions = (chartOptions) => {

  const {selectedMarketBenchmarkID, displayMarketBenchmark} = chartOptions
  const [benchmarkOption, setBenchmarkOption] = useState({
    selectedMarketBenchmarkID: selectedMarketBenchmarkID>0 ? selectedMarketBenchmarkID : BENCHMARK_OPTIONS.noBenchmark,
    displayMarketBenchmark: isBooleanValue(displayMarketBenchmark)? displayMarketBenchmark: false
  })

  function updateBenchmarkOption(newState) {
    setBenchmarkOption(prevState=>{
      return {
        ...prevState,
        ...newState
      }
    })
  }

  function benchmarkChangeAction(benchMarkType) {
    updateBenchmarkOption({
      selectedMarketBenchmarkID: benchMarkType,
      displayMarketBenchmark: !!benchMarkType
    })
  }

  const benchmarkOptionsPropsMemoised=useMemo(()=>({
    benchmarkOption,
    benchmarkChangeAction
  }), [benchmarkOption])

  return {benchmarkOption, benchmarkOptionsPropsMemoised}
}

export {useNzcBenchmarkOptions}