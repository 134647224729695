
export const UtilitySummaryItemKey = Object.freeze({
  gapFilledPercentage: 'gapFilledPercentage',
  currentTotalConsumption: 'currentTotalConsumption',
  currentTotalWaste: 'currentTotalWaste',
  percentageRecycled: 'percentageRecycled',
  usagePercentage: 'usagePercentage',
  usageUnits: 'usageUnits',
  usageCost: 'usageCost'
})

export const ActionEventType = Object.freeze({
  COMPLETED_ACTION: 'CompletedAction',
  COMMERCIAL_EVENT: 'CommercialEvent',
  PERFORMANCE_VARIANCE: 'PerformanceVariance',
  DATAQUALITY_ISSUE: 'DataQualityIssue'
})