import en from './en-GB'
import fr from './fr-FR'
import de from './de-DE'
import it from './it-IT'
import es from './es-ES'
import sv from './sv-SE'

export default {
  ...en,
  ...fr,
  ...de,
  ...it,
  ...es,
  ...sv,
}