import {  getCurrentYear,  getLegendImage,  getLocalizedValue,
  getLocalStorageByKey,  getRoundOffValueDivisbleBy5,  prefixSlash,  SessionUserState, setMinToZeroForVariousNzcLines
} from '../../../../../utils/helpers/Helper'
import {
  assetsOrderByColumnChange,
  COMMON_PAGES,
  defaultBaselineYear,
  GRAPH_MAX_YEAR_2050,
  LOCAL_STORAGE_KEYS,
  PATHWAY_NAME,
  SCOPE_TARGET_MODE,
  TARGET_PATHWAY,
  topdownPathwayColors
} from '../../../../../utils/helpers/Constants'
import {saveAs} from 'file-saver'
import {getFeatureFlagsValue} from '../../../../common/feature-flags/FeatureFlagUtil'
import {featureFlagInit} from '../../../../../utils/reducers/FeatureFlagReducer'
import CSS_VARIABLES from '../../../../../resources/css/_variables.scss'
import { createBauDataset } from '../nzc-chart-helper/nzc-chart-helper'
import { storeGraphIntensity } from '../../nzc-line-graph/fund-graph-utils/fund-graph-utils'
import { actionsDataNotEmpty } from '../../../../common/net-zero-carbon/nzc-common-utils'
import {
  getBAUStrandingYearDetails,
  STRANDING_POINT_IDENTIFIER
} from '../../../assets/net-zero-carbon/asset-nzc-utils/asset-nzc-utils'
import I18n from '../../../../../utils/i18n/I18n'

export function setScopeIdForSession(setNzcScopeId, refetchScopes, fundDefaultScopeId, sessionModelKey, fundScopeIdFromSession, updateSessionModelState) {
  refetchScopes()?.then(scopesData=>{
    let doesScopeExist
    if(scopesData.data && fundScopeIdFromSession){
      doesScopeExist=scopesData.data.some(scope=>scope.scopeId===fundScopeIdFromSession)
      if(doesScopeExist){
        setNzcScopeId(fundScopeIdFromSession)
      } else {
        setNzcScopeId(fundDefaultScopeId)
        if(updateSessionModelState){
          updateSessionModelState({showConfirmModalDeleted: true})
        }
        SessionUserState.removeUserDataFromSession(sessionModelKey)
        SessionUserState.saveUserDataToSession(LOCAL_STORAGE_KEYS.deletedModelId, fundScopeIdFromSession)
      }
    }
  }).catch(()=>{
    setNzcScopeId(fundDefaultScopeId)
  })
}

const greenColor = CSS_VARIABLES.green
const orangeColor = CSS_VARIABLES.nature
const redColor = CSS_VARIABLES.red

export const getShape = (value) => {
  switch (value) {
  case 1: return {shape: 'circle', color: greenColor}
  case 2: return {shape: 'rectRounded', color: orangeColor}
  default: return {shape: 'triangle', color: redColor}
  }
}

export function getDeletedModelName() {
  return SessionUserState.getUserDataFromSession(SessionUserState.getUserDataFromSession(LOCAL_STORAGE_KEYS.deletedModelId))
}

export function getAggregatedFundPerformance(assetsPerformance, type, baseLineYear){
  let aggregatedFundPerformance = 0
  if(assetsPerformance && assetsPerformance.length > 0 && type && baseLineYear){
    assetsPerformance=assetsPerformance.filter(assetPerformance=>assetPerformance.year===baseLineYear)
    aggregatedFundPerformance = assetsPerformance.reduce((total, currentItem) => total + currentItem[type], 0)
  }
  return aggregatedFundPerformance
}

export const dataPointColors= {red: CSS_VARIABLES.red, green: CSS_VARIABLES.green, yellow: CSS_VARIABLES.yellow}

export async function createExportPNG(fileName, selectedLanguage, exportScaleSelection, docname, nzcGraphRef, intensity) {

  if (nzcGraphRef.current) {
    const canvas = nzcGraphRef.current.getCanvas()
    const tempCanvas = document.createElement('canvas')
    const tempContext = tempCanvas.getContext('2d')
    const intensityValue = intensity === assetsOrderByColumnChange.energyIntensity ? 't_energy_intensity_nzc' : 't_nzc_carbon_intensity'
    const intensityUnit = intensity === assetsOrderByColumnChange.energyIntensity ? 't_kwh_m2_year' : 't_kg_co2_year'

    const scaleFactor = exportScaleSelection * 1.24

    tempCanvas.width = canvas.width * scaleFactor
    tempCanvas.height = (canvas.height * scaleFactor) + (30 * exportScaleSelection)

    tempContext.fillStyle = CSS_VARIABLES.white
    tempContext.fillRect(0, 0, tempCanvas.width, tempCanvas.height)

    tempContext.font = `${18 * exportScaleSelection}px Arial`
    tempContext.fillStyle = CSS_VARIABLES.forest
    tempContext.fillText(`${getLocalizedValue(selectedLanguage,intensityValue)} ${getLocalizedValue(selectedLanguage,intensityUnit)} - ${getLocalizedValue(selectedLanguage,'t_data_source')} : ${docname ? getLocalizedValue(selectedLanguage,'t_imported') : getLocalizedValue(selectedLanguage,'t_siera')}`, 20, (30 * exportScaleSelection))

    tempContext.drawImage(
      canvas,
      0,
      0,
      canvas.width,
      canvas.height,
      0,
      (30 * exportScaleSelection),
      tempCanvas.width,
      tempCanvas.height - (30 * exportScaleSelection)
    )


    const nzcGraphImage = tempCanvas.toDataURL('image/png')
    saveAs(nzcGraphImage, fileName)
  } else {
    console.error('nzcGraphRef.current is null')
  }
}




export const closeFunction = (querySelected, value) => {
  if (querySelected) {
    switch (value) {
    case 'none': 
    case 'block':  querySelected.style.display = value; break
    case 'remove': querySelected.remove(); break
    default: break
    }
  }
}

export function continueToApp(history, featureFlags, nzcFeatureFlag, flag) {
  nzcFeatureFlag.current = featureFlags[flag]
  if(nzcFeatureFlag.current){
    if (history?.location?.state?.referrer) {
      history.push(history.location.state.referrer)
    } else {
      history.replace(`${window.location.pathname}${window.location.search}`)
    }
  } else {
    history.push(prefixSlash(COMMON_PAGES.notfound))
  }
}

export function updateFeatureFlagsAndContinue(history, featureFlagContext, nzcFeatureFlag, flag){
  getFeatureFlagsValue(featureFlagInit, getLocalStorageByKey(LOCAL_STORAGE_KEYS.userInfo)).then((featureFlags) => {
    featureFlagContext.featureFlagDispatcher({...featureFlagContext.featureState, ...featureFlags})
    continueToApp(history, featureFlags, nzcFeatureFlag, flag)
  })
}

export const plotBauStranding = (getNzcSettingsData, bauStrandingYear) => {
  return getNzcSettingsData?.data?.displayStrandingDates && bauStrandingYear > 0
}

export const openPopUponClick = (params) => {
  const {
    clickedAsset, assetRowClick, assetIntensity, previousAssetRowClick, setAssetValueToReference, clearAssetRowClick
  } = params
  if(clickedAsset && `${assetRowClick}`.includes(assetIntensity.propertyID)) {
    setTimeout(() => {
      if (previousAssetRowClick.current === null) {
        setAssetValueToReference(assetRowClick)
      } else if (previousAssetRowClick.current && previousAssetRowClick.current === assetRowClick){
        previousAssetRowClick.current = null
        closeFunction(document.querySelector('.asset-detail-panel'), 'none')
        clearAssetRowClick()
      } else {
        setAssetValueToReference(assetRowClick)
      }
      if (document.getElementById('closeAssetPanel')) {
        document.getElementById('closeAssetPanel').onclick = () => {
          closeFunction(document.querySelector('.asset-detail-panel'), 'none')
        }
      }
    }, 1000)
  } else if (document.querySelector('.asset-detail-panel')) {
    clearAssetRowClick()
    closeFunction(document.querySelector('.asset-detail-panel'), 'none')
  }
}

const getLabel = (i, reportingYear, splitAssetRowClick) => {
  return i === reportingYear ? splitAssetRowClick : splitAssetRowClick.concat(`_${i}`)
}

const getFundAssets = (nzcScopeAssetData, assetIntensity) => {
  return nzcScopeAssetData.find(element => !!(element.asset.assetId === assetIntensity?.propertyID && element.asset.isScopeAsset))
}

export const returnValueAvailableOrZero = (value, key) => {
  return value ? Math.round(value) : key
}

const pushEnergyIntensityData = (params) => {
  const {
    i, selectedLanguage, requiredYAxisValue, previousAssetRowClick, assetRowClick, assetGraphDatasets, reportingYear, splitAssetRowClick, assetPanelData
  } = params
  const otherValueForShape = requiredYAxisValue?.dataAvailablePercentage >= 0.50 ? 2 : 3
  const valueForShape = requiredYAxisValue?.dataAvailablePercentage === 1 ? 1 : otherValueForShape
  previousAssetRowClick.current !== assetRowClick &&  assetGraphDatasets.push({
    type: 'bubble',
    data: [{
      x: i,
      y: requiredYAxisValue?.energyIntensity,
      r: 8,
      datasets: assetPanelData,
      label: getLabel(i, reportingYear, splitAssetRowClick)
    }],
    backgroundColor: getShape(valueForShape).color,
    datalabels: {display: false},
    xAxisID: 'xAxis',
    language: selectedLanguage,
    borderColor: getShape(valueForShape).color,
    pointStyle: getShape(valueForShape).shape,
    assetIntensityId: getLabel(i, reportingYear, splitAssetRowClick),
    year: i,
    clip: false
  })
}

export const displayLabelOnChart = (chartOptionsYaxis, chartOptionsXaxis, nzcSetting, comparingYAxisValue, comparingXAxisValue, xAxisFrom) => {
  let isShown = true
  if (((chartOptionsYaxis || !chartOptionsYaxis) && !chartOptionsXaxis && (xAxisFrom > comparingXAxisValue)) ||
  (!chartOptionsYaxis && (chartOptionsXaxis || !chartOptionsXaxis) && (nzcSetting > comparingYAxisValue)) ||
  (!chartOptionsYaxis && !chartOptionsXaxis && (nzcSetting > comparingYAxisValue) && (xAxisFrom > comparingXAxisValue))) {
    isShown = false
  }
  return isShown
}

const conditionToPlotTpTarget = (targetsAndPathwayDetails, scopeTargetState) => {
  return targetsAndPathwayDetails?.showProgressTowardsTargetYear && scopeTargetState.selectedTPName && (scopeTargetState?.tpMode === SCOPE_TARGET_MODE.view || scopeTargetState?.tpMode === SCOPE_TARGET_MODE.edit) && targetsAndPathwayDetails?.progressTowardsTargetYear
}

const getPercentValue = (progressTargetYearCarbonIntensity, baselineTargetYearCarbonIntensity) => {
  return ((progressTargetYearCarbonIntensity / baselineTargetYearCarbonIntensity) * 100) > 0 ? ((progressTargetYearCarbonIntensity / baselineTargetYearCarbonIntensity) * 100).toFixed(0) : 0
}

const returnYAxisValue = (graphValue, targetValue) => {
  return graphValue < targetValue ? targetValue : graphValue
}

const returnSign = (percentValue) => percentValue > 100 || percentValue === 0 ? '+ ' : '- '

export const plotTpTargetYear = (params) => {
  const {targetsAndPathwayDetails, scopeTargetState, nzcData, originalData, selectedLanguage, chartOptions, getNzcSettingsData} = params
  const backgroundColorData = {
    backgroundColor: CSS_VARIABLES.red,
    borderColor: CSS_VARIABLES.red,
    datalabels: {display: false},
    language: selectedLanguage,
  }
  if (conditionToPlotTpTarget(targetsAndPathwayDetails, scopeTargetState)) {
    const targetPath = nzcData?.graphData?.find((e) => e.year === Number(targetsAndPathwayDetails?.progressTowardsTargetYear))
    if (targetPath && Object.keys(targetPath).length > 0) {
      originalData.push({
        type: 'line',
        fill: false,
        data: (() => {
          let data = []
          for (let i = getCurrentYear(); i <= Number(targetsAndPathwayDetails?.progressTowardsTargetYear); i++) {
            data.push({
              x: i,
              y: targetPath[scopeTargetState?.selectedTPName],
            })
          }
          return data
        })(),
        ...backgroundColorData
      })
      const index = nzcData?.graphData?.findIndex((e) => e.year === Number(targetsAndPathwayDetails?.progressTowardsTargetYear))
      const currentYearIndex = nzcData?.graphData?.findIndex((e) => e.year === getCurrentYear())
      const progressTargetYearCarbonIntensity = nzcData?.graphData[index][scopeTargetState?.selectedTPName]
      const baselineTargetYearCarbonIntensity = nzcData?.graphData[currentYearIndex][scopeTargetState?.selectedTPName]
      const percentValue = getPercentValue(progressTargetYearCarbonIntensity, baselineTargetYearCarbonIntensity)
      const actualPercent = Math.abs((percentValue - 100)).toFixed(0)
      const displayLabel = `${returnSign(percentValue)} ${actualPercent} %`
      let min = 0
      let max = 0
      originalData.push({
        type: 'line',
        fill: false,
        data: (() => {
          let data = []
          for (let i = 0; i <= index; i++) {
            data.push({
              x: getCurrentYear(),
              y: displayLabel.includes('-') ? returnYAxisValue(nzcData?.graphData[i][scopeTargetState.selectedTPName], targetPath[scopeTargetState.selectedTPName]) : nzcData?.graphData[i][scopeTargetState.selectedTPName],
            })
          }
          min = Math.min.apply(null, data.map((e) => e['y']))
          max = Math.max.apply(null, data.map((e) => e['y']))
          return data
        })(),
        ...backgroundColorData,
        borderWidth: 3,
      })
      const midValue = Number((min + max) / 2).toFixed(0)
      if (displayLabelOnChart((chartOptions.yAxisAutoScale), (chartOptions.xAxisAutoScale), getNzcSettingsData?.data?.yAxisScaleMin, midValue, nzcData?.graphData[0].year, getNzcSettingsData?.data?.xAxisScaleFrom)) {
        originalData.push({
          type: 'line',
          data: [
            {
              x: getCurrentYear(),
              y: midValue,
              label: displayLabel
            }
          ],
          backgroundColor: 'transparent',
          borderColor: CSS_VARIABLES.red,
          language: selectedLanguage,
        })
      }
      originalData.push({
        type: 'bubble',
        data: [
          {
            x: targetsAndPathwayDetails?.progressTowardsTargetYear,
            y: targetPath[scopeTargetState.selectedTPName],
            r: 20,
            label: targetsAndPathwayDetails?.progressTowardsTargetYear + ' interim target'
          }
        ],
        backgroundColor: 'transparent',
        borderColor: CSS_VARIABLES.red,
        datalabels: {display: displayLabelOnChart((chartOptions.yAxisAutoScale), (chartOptions.xAxisAutoScale), getNzcSettingsData?.data?.yAxisScaleMin, targetPath[scopeTargetState.selectedTPName], targetsAndPathwayDetails?.progressTowardsTargetYear, getNzcSettingsData?.data?.xAxisScaleFrom)},
        language: selectedLanguage,
        clip: false
      })
    }
  }
}

const pushActionBasedEnergyIntensity = (params) => {
  const {
    graphDatasets, getNzcSettingsData, featureState, selectedLanguage, nzcData, actionBasedTPData, originalData,ACTION_PATHWAY_STRANDING_YEAR, reportingYear
  } = params
  const dashedLine = getLegendImage('action-carbon-tp')
  const ACTION_BASED_ENERGY_INTENSITY_PATHWAY = getLocalizedValue(selectedLanguage, 't_action_based_carbon_pathway')
  if (getBooleanActionBasedTPToPlot(getNzcSettingsData?.data?.energySavingActions, featureState.sieraplus_actionBasedPathways)) {
    graphDatasets[ACTION_BASED_ENERGY_INTENSITY_PATHWAY] = {
      type: 'line',
      order: 4,
      label: `    ${ACTION_BASED_ENERGY_INTENSITY_PATHWAY}`,
      borderColor: CSS_VARIABLES.green,
      backgroundColor: CSS_VARIABLES.green,
      borderWidth: 2,
      borderDash: [10, 10],
      borderCapStyle: 'round',
      fill: false,
      pointStyle: dashedLine,
      data: [],
      datalabels:{
        display:false
      }
    }
    nzcData?.graphData?.forEach((graphData)=>{
      if (actionsDataNotEmpty(actionBasedTPData) && graphData.year > reportingYear) {
        graphDatasets[ACTION_BASED_ENERGY_INTENSITY_PATHWAY].data.push({ x: graphData.year, y: setMinToZeroForVariousNzcLines(graphData.weightedAverageEstimatedSavingsEnergyIntensity) })
      }
    })
    originalData.push(graphDatasets[ACTION_BASED_ENERGY_INTENSITY_PATHWAY])
    if(getNzcSettingsData?.data?.displayStrandingDates && graphDatasets[`${ACTION_PATHWAY_STRANDING_YEAR}`]){
      originalData.push(graphDatasets[`${ACTION_PATHWAY_STRANDING_YEAR}`])
    }
  }
}

const getAssetGraphDatasetsForEnergyIntensity = (props) => {
  let {
    nzcData, assetRowClick, previousAssetRowClick, selectedLanguage, nzcScopeAssetData, baselineYear, epcRatingLabel, dash, featureState, reportingYear,nzcDialogsState,setNzcDialogsState
  } = props
  const assetGraphDatasets=[]
  if(nzcData && assetRowClick){
    const {assetGraphData, assetIntensities}=nzcData
    const clickedAsset = nzcData?.assetGraphData?.find((f) => f.assetId === assetRowClick)
    const splitAssetRowClick = `${assetRowClick}`.split('_')[0]
    let assetData
    const bauDataset={
      type: 'line',
      borderColor: dataPointColors.yellow,
      backgroundColor: dataPointColors.yellow,
      borderWidth: 2,
      fill: false,
      pointStyle: getLegendImage('selectedAsset'),
      data: [],
      label: `    ${getLocalizedValue(selectedLanguage, 't_selected_asset')}`,
      segment: {
        borderDash: context => dash(assetGraphDatasets.filter((e) => e.assetIntensityId), context, [4, 3], reportingYear) || [4, 3]
      },
      datalabels:{display:false}
    }
    // onClick function tooltip code
    // 1 0 => complete, 1 1 => uplifted, 0 0 => benchmark
    clickedAsset?.graphData?.sort((assetA, assetB) => assetB.year - assetA.year)


    if (!clickedAsset) {
      setNzcDialogsState({...nzcDialogsState, assetError: true ,errorDialogVisible: true, errorDialogData: { message: I18n('t_asset_error_message') }})
    }

    if(clickedAsset && nzcData?.assetIntensities && nzcScopeAssetData.length > 0){
      const assetIntensity=assetIntensities.find(assetIntensity=>`${assetRowClick}`.includes(assetIntensity.propertyID)) ||  {propertyID : assetRowClick}
      let fundAssets = getFundAssets(nzcScopeAssetData, assetIntensity)
      if(!clickedAsset?.graphData?.find((e) => e.year === reportingYear)){
        const latestYear = clickedAsset?.graphData.sort((a, b) => b.year - a.year)
        reportingYear = latestYear[0].year
      }
      
      for (let i = reportingYear; i >= baselineYear; i--) {
        const requiredYAxisValue = clickedAsset?.graphData?.find((e) => e.year === i)
        const assetPanelData = {
          ...fundAssets,
          ...requiredYAxisValue,
          strandingYear: clickedAsset?.strandingYear?.year,
          epcRating: epcRatingLabel,
          dataAvailablePercentage: returnValueAvailableOrZero(requiredYAxisValue?.dataAvailablePercentage, 0),
          featureStateSoldAndPurchased: featureState?.sieraplus_nzcAssetSoldAndPurchased
        }
        pushEnergyIntensityData({ i, selectedLanguage, requiredYAxisValue, previousAssetRowClick, assetRowClick, assetGraphDatasets, reportingYear, splitAssetRowClick, assetPanelData })
      }

      if(assetGraphData){
        assetData=assetGraphData.find(graphData=> graphData.assetId === Number(splitAssetRowClick))
        if (assetData) {
          assetData?.graphData?.forEach((graphData)=>{
            bauDataset.data.push({x: graphData.year, y: graphData.energyIntensity})
          })
        }
        previousAssetRowClick.current !== assetRowClick && assetGraphDatasets.push(bauDataset)
      }
    }

    //Stranding
    const stranding=assetData?.strandingYear
    if(clickedAsset && stranding){
      assetGraphDatasets.push({
        type: 'line',
        order: 4,
        label: getLocalizedValue(selectedLanguage, 't_selected_asset_stranding') + Math.round(stranding.year),
        fill: false,
        pointStyle: getLegendImage('assetStranding'),
        data: [],
        datalabels: {display: false}
      })
      assetGraphDatasets.push(
        {
          type: 'bubble',
          data: [{
            x: stranding.year,
            y: stranding.strandingPoint,
            r: 17,
            label:stranding.strandingOf
          }],
          backgroundColor: 'transparent',
          borderColor: dataPointColors.yellow,
          clip: false
        }
      )
    }
  }
  return assetGraphDatasets
}

function valueScientificIntensity (graphData) {
  let scientificIntensityValue
  if(graphData.intensityValue === assetsOrderByColumnChange.energyIntensity){
    scientificIntensityValue = graphData.scientificEnergyIntensity
  } else {
    scientificIntensityValue = graphData.scientificGHGIntensity
  }
  return scientificIntensityValue
}

export const plotTargetPathway = (isViewOrEditMode, targetPathwayNames, nzcData, scopeTargetState, originalData, reportingYear) => {
  const tpImage = getLegendImage('targetPathwaySvg')
  const graphDatasets = {}
  const condition = isViewOrEditMode()  && targetPathwayNames
  if (condition) {
    graphDatasets[scopeTargetState.selectedTPName] = {
      type: 'line',
      order: 4,
      label: `     ${JSON.parse(targetPathwayNames)[scopeTargetState.selectedTPName]}`,

      borderColor: CSS_VARIABLES.nature,
      backgroundColor: CSS_VARIABLES.nature,
      borderWidth: 3,
      borderDash: [10, 10],
      borderCapStyle: 'round',
      fill: false,
      pointStyle: tpImage,
      data: [],
      datalabels:{
        display:false
      },
      // xAxisID: 'xAxis',
      graphName: 'targetPathway',
    }
  }
  nzcData?.graphData?.forEach((graphData) => {
    if(isViewOrEditMode() && graphDatasets[scopeTargetState.selectedTPName] && graphData.year > reportingYear){
      graphDatasets[scopeTargetState.selectedTPName].data.push({x:graphData.year,y:graphData[scopeTargetState.selectedTPName]===null ? 0 : graphData[scopeTargetState.selectedTPName]})
    }
  })
  if (condition)
    originalData.push(graphDatasets[scopeTargetState.selectedTPName])
}

export const targetpathwayStrandingLegend = (params) => {
  const {
    getNzcSettingsData, isViewOrEditMode, strandingYearForTP, selectedLanguage, graphDatasets, originalData
  } = params
  let targetPathwayStrandingYearIcon = getLegendImage('targetPathwayStrandingSvg')
  const STRANDING_YEAR_TP = 'Target stranding in year'
  if (getNzcSettingsData?.data?.displayStrandingDates && isViewOrEditMode()   &&  !isNaN(strandingYearForTP)) {
    graphDatasets[STRANDING_YEAR_TP]={
      type: 'line',
      order: 4,
      label: ` ${getLocalizedValue(selectedLanguage, 't_target_stranding_year')}${strandingYearForTP}`,
      fill: false,
      pointStyle: targetPathwayStrandingYearIcon,
      data: [],
      datalabels:{
        display:false
      },
    }
    originalData.push(graphDatasets[STRANDING_YEAR_TP])
  }
}

export const plotStrandingPoints = (params) => {
  const {
    nzcData, chartOptions, scopeTargetState, getNzcSettingsData, isViewOrEditMode, originalData, featureState, isEnergyIntensity, actionBasedTPData
  } = params

  if (nzcData?.strandings  && getNzcSettingsData?.data?.displayStrandingDates) {
    let strandings = []
    let bauStrandingLabel = isEnergyIntensity? STRANDING_POINT_IDENTIFIER.BAU.ENERGY : STRANDING_POINT_IDENTIFIER.BAU.CARBON
    let bauStandingsArray = nzcData?.strandings?.filter(stranding => stranding.strandingOf === bauStrandingLabel)
    let bauStrandingObject = getBAUStrandingYearDetails(bauStandingsArray)
    let actionBasedPathwayStrandingLabel = isEnergyIntensity ? STRANDING_POINT_IDENTIFIER.ACTION_BASED_PATHWAYS.ENERGY:STRANDING_POINT_IDENTIFIER.ACTION_BASED_PATHWAYS.CARBON

    bauStandingsArray.length > 0 && strandings.push(
      {
        type: 'bubble',
        data: [{
          x: bauStrandingObject.year,
          y: bauStrandingObject.strandingPoint,
          r:  17,
          label: bauStrandingObject.strandingOf
        }],
        backgroundColor: 'transparent',
        datalabels: { display: displayLabelOnChart((chartOptions.yAxisAutoScale), (chartOptions.xAxisAutoScale), getNzcSettingsData?.data?.yAxisScaleMin, bauStrandingObject.strandingPoint, bauStrandingObject.year, getNzcSettingsData?.data?.xAxisScaleFrom)},
        borderColor: CSS_VARIABLES.pink,
        graphName: 'bauStrandingYear',
        clip: false
      }
    )
    nzcData?.strandings?.forEach((stranding) => {
      if(stranding.strandingOf.replace(TARGET_PATHWAY, 'tp') === scopeTargetState.selectedTPName && isViewOrEditMode()){
        strandings.push(
          {
            type: 'bubble',
            data: [{
              x: stranding.year,
              y: stranding.strandingPoint,
              r: 17,
              label: stranding.strandingOf
            }],
            backgroundColor: 'transparent',
            datalabels: { display: displayLabelOnChart((chartOptions.yAxisAutoScale), (chartOptions.xAxisAutoScale), getNzcSettingsData?.data?.yAxisScaleMin, stranding.strandingPoint, stranding.year, getNzcSettingsData?.data?.xAxisScaleFrom)},
            borderColor: CSS_VARIABLES.nature ,
            graphName: 'targetPathwayStrandingYear',
            clip: false
          }
        )
      }
      if(stranding.strandingOf === actionBasedPathwayStrandingLabel && getBooleanActionBasedTPToPlot(getNzcSettingsData?.data?.energySavingActions, featureState.sieraplus_actionBasedPathways) && actionsDataNotEmpty(actionBasedTPData)){
        strandings.push(
          {
            type: 'bubble',
            data: [{
              x: stranding.year,
              y: stranding.strandingPoint,
              r: 10,
              label: stranding.strandingOf
            }],
            backgroundColor: 'transparent',
            borderColor: CSS_VARIABLES.green,
            borderWidth:3,
            hitRadius:0,
            hoverBorderWidth:3,
            clip: false
          }
        )
      }
    })
    originalData.push(...strandings)
  }
}

export const plotTopDownPathway = (pathway, index, nzcData, originalData, getNzcSettingsData) => {
  let topDownPathwayDataSet = {}
  topDownPathwayDataSet = {
    type: 'line',
    label: `  ${pathway[PATHWAY_NAME]}`,
    order: 4,
    borderColor: topdownPathwayColors[index % topdownPathwayColors.length],
    backgroundColor: topdownPathwayColors[index % topdownPathwayColors.length],
    borderWidth: CSS_VARIABLES.borderWidth,
    borderDash: [10, 7],
    borderCapStyle: 'round',
    fill: false,
    data: [],
    datalabels: {
      display: false
    },
    graphName: `topdownPathway_${index + 1}`,
    tension : 0.2,
    pointStyle:'line',
    pointRadius:0
  }
  if (pathway) {
    let start = getNzcSettingsData?.data?.baseLineYear ?? defaultBaselineYear
    let years = Array.from({ length: GRAPH_MAX_YEAR_2050 - start + 1 }, (_, index) => start + index)
    let trackPathway = {}
    let graphValues = []
    years.forEach(ele => {
      let x = parseInt(ele)
      let y = pathway[`${x}`]
      if (!y) {
        y = trackPathway[`${x - 1}`]
      }
      trackPathway[x] = y
      graphValues.push({ x: parseInt(x), y: (y ? parseInt(y) : null) })
    })
    if (graphValues.filter(x => x.y != null).length > 0)
      topDownPathwayDataSet.data.push(...graphValues)
  }
  if(topDownPathwayDataSet.data?.length>0)
    originalData.push(topDownPathwayDataSet)
}

const getBenchmarkgraphValue = (rebEnclosedValue,yAxisMaxValue) => {
  return yAxisMaxValue < rebEnclosedValue ? rebEnclosedValue + 5 : rebEnclosedValue
}
export const createNZCEnergyIntensityDatasets = (props) => {
  const {
    actionBasedTPData, featureState, NzcScopeDetailsData, baselineYear, selectedLanguage, chartOptions, getNzcSettingsData,
    selectedFund, nzcData, previousAssetRowClick, assetRowClick, epcRatingLabel, dash, clearAssetRowClick, fundIntensityValue,
    isViewOrEditMode, targetPathwayNames, scopeTargetState, targetsAndPathwayDetails, strandingYearForTP, isEnergyIntensity,benchmarkObj,yAxisMaxValue, reportingYear, nzcDialogsState,setNzcDialogsState
  } = props
  let bauStrandingLabel = STRANDING_POINT_IDENTIFIER.BAU.ENERGY
  let bauStandingsArray=nzcData?.strandings?.filter(stranding => stranding.strandingOf === bauStrandingLabel)
  let bauStrandingYear = bauStandingsArray.length> 0 && Math.round(getBAUStrandingYearDetails(bauStandingsArray).year)
  let actionBasedStrandingArray = nzcData?.strandings.filter(strandingDetails => STRANDING_POINT_IDENTIFIER.ACTION_BASED_PATHWAYS.ENERGY === strandingDetails.strandingOf)

  const greenColor = CSS_VARIABLES.green
  const orangeColor = CSS_VARIABLES.nature
  let rect = getLegendImage('science')
  let rectBau = getLegendImage('bau')
  let circleGreen = getLegendImage('actionBasedStrandingYear')
  const BAU_STRANDING_YEAR = 'Bau stranding in year'
  const ACTION_PATHWAY_STRANDING_YEAR = 'ACTION_PATHWAY_STRANDING_YEAR'


  let graphDatasets={
    scientificGHGIntensity:{
      type: 'line',
      order: 3,
      label: `   ${getLocalizedValue(selectedLanguage, 't_scientific_ghg_intensity')}`,
      borderColor: CSS_VARIABLES.blue,
      backgroundColor: CSS_VARIABLES.blue,
      borderWidth: 2,
      fill: false,
      pointStyle: rect,
      data: [],
      datalabels:{
        display:false
      }
    }
  }
  if(plotBauStranding(getNzcSettingsData, bauStrandingYear)) {
    graphDatasets[BAU_STRANDING_YEAR] = {
      type: 'line',
      order: 4,
      label: ` ${getLocalizedValue(selectedLanguage, 't_bau_stranding_year')}${bauStrandingYear}`,
      fill: false,
      pointStyle: getLegendImage('bauStrandingYear'),
      data: [],
      datalabels: {
        display: false
      },
    }
  }
  if (getBooleanActionBasedTPToPlot(getNzcSettingsData?.data?.energySavingActions, featureState.sieraplus_actionBasedPathways) && actionBasedStrandingArray.length>0 ) {
    graphDatasets[`${ACTION_PATHWAY_STRANDING_YEAR}`] = {
      type: 'line',
      order: 4,
      label: ` ${getLocalizedValue(selectedLanguage, 't_action_based_pathway_stranding')} ${Math.round(actionBasedStrandingArray[0].year)}`,
      fill: false,
      pointStyle: circleGreen,
      data: [],
      datalabels: {
        display: false
      }
    }
  }

  let originalData = []
  if (chartOptions?.displayMarketBenchmark && chartOptions?.selectedMarketBenchmarkID) {
    graphDatasets = { ...graphDatasets,
      benchmarkData: {
        type: 'line',
        order: 4,
        label: `   ${(benchmarkObj?.label)}`,
        borderColor:  CSS_VARIABLES.deep_ocean,
        backgroundColor: CSS_VARIABLES.deep_ocean,
        borderWidth: 2,
        borderDash: [10, 10],
        fill: false,
        pointStyle: getLegendImage('enclosed'),
        data: (() => {
          let data = []
          const yAxisVal = getBenchmarkgraphValue(Number(benchmarkObj?.actualValue),yAxisMaxValue)
          for (let i = baselineYear; i <= 2050; i++) {
            data.push({
              x: i,
              y: yAxisVal
            })
          }
          return data
        })(),
        xAxisID: 'xAxis',
        datalabels:{
          display:false
        }
      }
    }
    originalData.push(graphDatasets.benchmarkData)
  }

  nzcData?.graphData?.sort((a,b) => a.year-b.year)
  nzcData?.graphData?.forEach((graphData) => {
    graphDatasets.scientificGHGIntensity.data.push({ x: graphData.year, y: valueScientificIntensity({...graphData, intensityValue: fundIntensityValue}) })
  })
  originalData.push(graphDatasets.scientificGHGIntensity)
  if(plotBauStranding(getNzcSettingsData, bauStrandingYear)){
    originalData.push(graphDatasets[BAU_STRANDING_YEAR])
  }

  createBauDataset(originalData, nzcData, rectBau, selectedLanguage, (!(chartOptions.xAxisAutoScale) ? getNzcSettingsData?.data?.xAxisScaleFrom : baselineYear), selectedFund?.fundname, fundIntensityValue, reportingYear)

  if (chartOptions?.assetsPlotData) {
    nzcData.assetGraphData.forEach((assetIntensity)=>{
      const requiredAsset = NzcScopeDetailsData?.fundAssets?.find(x => x.asset.assetId === assetIntensity.assetId)
      const energyIntensity = assetIntensity?.graphData.find((graph) => graph.year === baselineYear)?.energyIntensity
      const assetIdForLabel = requiredAsset?.asset?.assetId
      let dataAvailablilityPercentage = Math.trunc(requiredAsset?.dataAvailablePercentage* 100)
      let shape = 'circle'
      let color = `${greenColor}`
      if (dataAvailablilityPercentage >= 50 && dataAvailablilityPercentage <= 99){
        shape = 'rectRounded'
        color = `${orangeColor}`
      }
      else if (dataAvailablilityPercentage <= 49){
        shape = 'triangle'
        color = CSS_VARIABLES.red
      }
      originalData.push({
        type: 'bubble',
        data: [{
          r: 8,
          label: assetIdForLabel,
          x: baselineYear,
          y: energyIntensity
        }],
        backgroundColor: color,
        borderColor: color,
        pointStyle: shape,
        datalabels: {display: false},
        order: 1,
        xAxisID: 'xAxis',
        language: selectedLanguage,
        clip: false
      })
    })
  }
  
  plotTargetPathway(isViewOrEditMode, targetPathwayNames, nzcData, scopeTargetState, originalData, reportingYear)
  if(nzcData?.topDownPathways && targetsAndPathwayDetails?.showTopDownPathways){
    nzcData?.topDownPathways?.topdownPathways?.map((pathway, index) => plotTopDownPathway(pathway, index, nzcData, originalData, getNzcSettingsData))
  }
  pushActionBasedEnergyIntensity({
    graphDatasets, getNzcSettingsData, featureState, selectedLanguage, nzcData, actionBasedTPData, originalData,ACTION_PATHWAY_STRANDING_YEAR, reportingYear
  })
  plotTpTargetYear({
    targetsAndPathwayDetails, scopeTargetState, nzcData, originalData,selectedLanguage, chartOptions,
    getNzcSettingsData
  })
  plotStrandingPoints({
    nzcData, chartOptions, scopeTargetState, getNzcSettingsData, isViewOrEditMode, originalData, featureState, isEnergyIntensity, actionBasedTPData
  })
  targetpathwayStrandingLegend({
    getNzcSettingsData, isViewOrEditMode, strandingYearForTP, selectedLanguage, graphDatasets, originalData
  })
  if(assetRowClick){
    let selectedAssetData = getAssetGraphDatasetsForEnergyIntensity({
      nzcData, assetRowClick, previousAssetRowClick, selectedLanguage, nzcScopeAssetData: NzcScopeDetailsData?.fundAssets, baselineYear, epcRatingLabel, dash, clearAssetRowClick, featureState, reportingYear ,nzcDialogsState,setNzcDialogsState
    })
    originalData.push(...selectedAssetData)
  }
  return originalData
}

export const returnGraphIntensity = (sessionIntensityvalue, sessionModelKey, featureState, queryValues) => {
  return sessionIntensityvalue ? sessionIntensityvalue[sessionModelKey] : featureState.sieraplus_nzcFundEnergyIntensity && queryValues.get('graphIntensity')
}

/**
 * Returns the asset intensity
 *
 * @param {string} sessionAssetIntensityValue to get asset intensity value
 * @param {number} sessionModelKey to get the id
 * @param {object} featureState to get the feature flag
 * @param {object} queryValues  to get the query value
 */
export const returnAssetGraphIntensity = (sessionAssetIntensityValue, sessionModelKey, featureState, queryValues) => {
  return sessionAssetIntensityValue ? sessionAssetIntensityValue[sessionModelKey] : featureState.sieraplus_nzcAssetEnergyIntensity && queryValues.get('assetIntensity')
}

export const saveGraphIntensity = (graphIntensity, sessionModelKey) => {
  if (graphIntensity && sessionModelKey) {
    storeGraphIntensity(graphIntensity, sessionModelKey)
  }
}

export const getGraphDataset = (flag, scientificGHGIntensity) => {
  return !flag ? {} : { scientificGHGIntensity }
}

export const getScientificGHGIntensity = (flag, scientificGHGIntensity, graphData, value, originalData, intensityValue) => {
  if (flag) {
    switch(value) {
    case 1: graphData && scientificGHGIntensity.data.push({x:graphData.year,y: valueScientificIntensity({...graphData, intensityValue})}); break
    case 2: originalData.push(scientificGHGIntensity); break
    default: break
    }
  }
}

export const getBooleanActionBasedTPToPlot = (energySavingActions, flag) => {
  return energySavingActions && flag
}

const getMaxEnergyIntensityValue = (nzcData, baselineYear) => {
  let energyIntensityArray = []
  nzcData?.assetGraphData?.forEach((assetIntensity) => {
    const intensity = assetIntensity?.graphData.map((graph) => graph.energyIntensity)
    energyIntensityArray = [...energyIntensityArray, ...intensity]
  })
  return energyIntensityArray.length > 0 ? Math.max(...energyIntensityArray) : 0
}

export const getMaxTopDownPathwayValue = (nzcData) => {
  const topDownPathwayArray = nzcData?.topDownPathways?.topdownPathways?.flatMap(pathway =>
    Object.values({ ...pathway, [PATHWAY_NAME]: 0 })
      .filter((value, index) => !isNaN(value) && index !== pathway.length - 1)
      .map(Number)
  )
  return topDownPathwayArray?.length > 0 ? Math.max(...topDownPathwayArray) : 0
}

export const getYMAxValue = (key, benchmarkObj, getMaxYScale, GRAPH_Y_SCALE_MAX_PADDING, nzcData, baselineYear) => {
  const weightedAverageEnergyIntensity = nzcData?.graphData?.sort((a, b) => b.weightedAverageEnergyIntensity - a.weightedAverageEnergyIntensity)[0]?.weightedAverageEnergyIntensity
  const scientificEnergyIntensity = nzcData?.graphData?.sort((a,b) => b.scientificEnergyIntensity - a.scientificEnergyIntensity)[0]?.scientificEnergyIntensity
  const energyIntensity = getMaxEnergyIntensityValue(nzcData, baselineYear)
  const topDownPathWayValue = getMaxTopDownPathwayValue(nzcData)
  const roundedValue = Math.max(weightedAverageEnergyIntensity, scientificEnergyIntensity, energyIntensity, topDownPathWayValue)
  if (key) {
    return getRoundOffValueDivisbleBy5(roundedValue + (roundedValue / 10))
  }
  return (benchmarkObj?.actualValue && (Number(benchmarkObj?.actualValue) > getMaxYScale(nzcData)) ? getRoundOffValueDivisbleBy5(benchmarkObj?.actualValue) : getMaxYScale(nzcData)) + GRAPH_Y_SCALE_MAX_PADDING
}
