import PropTypes from 'prop-types'
import {memo} from 'react'
import './UtilityPerformanceTileV3.scss'
import {Card} from 'primereact/card'
import I18n from '../../../utils/i18n/I18n'
import FeedbackRatingV3 from '../feedback-icon/FeedbackRatingV3'
import ActualDataIcon from '../../../resources/images/icon/actaul-data.svg'
import GapfilledDataIcon from '../../../resources/images/icon/gap-filled-data.svg'
import RecNonRecycled from '../../../resources/images/icon/rec-non-recycled.svg'
import Target_Previous_Icon from '../../../resources/images/icon/target-Previous-line.svg'
import NoDataIcon from '../../../resources/images/icon/no-data-icon.svg'
import NoDataBar from '../../../resources/images/icon/tile/no-data-bar.svg'
import {getLocalizedValue} from '../../../utils/helpers/Helper'
import {UtilityTypes, Views} from '../../../utils/helpers/Constants'
import {formatNumWOneDecimal} from '../../../services/common/formatter'
import TileStackedBarChart from '../charts/tile/TileStackedBarChart'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'


const UtilityPerformanceTileV3 = ({utilityPerformanceData, utilityName, reportingPeriodSelected, view, chartDatasets, yMax}) => {

  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const utilityToTitleKeyMap = {
    Carbon:  't_carbon_emissions',
    EnergyIntensity:  't_energy_intensity',
    Electricity:  't_electricity',
    GasFuelsAndThermals:  't_asset_gas_fuels_thermals',
    Water:  't_water',
    RecycledWaste:  't_waste_recycling'
  }
  const title= getLocalizedValue(selectedLanguage, utilityToTitleKeyMap[utilityName])

  function getActualValues(utilityData) {
    if (!utilityData) {
      return 0
    }

    const msgKey = (utilityName === UtilityTypes.RecycledWaste) ? 't_recycled' : 't_actual'
    let actualPercentage
    if (Views.AssetDashboard === view) {
      if (utilityName === UtilityTypes.RecycledWaste) {
        const recycledWasteTotal = utilityData.amountUptoTarget + utilityData.amountOverTarget
        const recycledNonRecycledWasteTotal = recycledWasteTotal + utilityData.amountUptoNonRecycledTarget
        actualPercentage = recycledWasteTotal > 0 ? (recycledWasteTotal * 100) / recycledNonRecycledWasteTotal : 0
      } else {
        actualPercentage = 100 - (utilityData.gapFilledPercentage ?? 0)
      }
    } else {
      if (utilityName === UtilityTypes.RecycledWaste) {
        actualPercentage = 100 * utilityData.value
      } else {
        actualPercentage = (100 - (utilityData.filledData * 100))
      }
    }
    return `${formatNumWOneDecimal(selectedLanguage, actualPercentage)}%  ${getLocalizedValue(selectedLanguage, msgKey).toLowerCase()}`
  }

  function getGapFilledValues(utilityName, utilityData) {
    let gapFilledValues
    let gapFilledPercentage = utilityData.filledData * 100
    if (Views.AssetDashboard === view) {
      gapFilledPercentage = utilityData.gapFilledPercentage ?? 0
    }

    if (utilityName === UtilityTypes.RecycledWaste) {
      let nonRecycledPercentage
      if (Views.AssetDashboard === view) {
        const recycledNonRecycledWasteTotal = utilityData.amountUptoTarget + utilityData.amountOverTarget + utilityData.amountUptoNonRecycledTarget
        nonRecycledPercentage = utilityData.amountUptoNonRecycledTarget > 0 ? (utilityData.amountUptoNonRecycledTarget * 100) / recycledNonRecycledWasteTotal : 0
      } else {
        nonRecycledPercentage = 100 * (1 - utilityData.value)
      }
      gapFilledValues = <>
        <img src={RecNonRecycled} alt="RecNonRecycled"/>
        <p>{`${formatNumWOneDecimal(selectedLanguage, nonRecycledPercentage)}% ${getLocalizedValue(selectedLanguage, 't_non_recycled')?.toLowerCase()}`}</p>
      </>
    } else {
      gapFilledValues = <>
        <img src={GapfilledDataIcon} alt="GapfilledDataIcon"/>
        <p>{`${formatNumWOneDecimal(selectedLanguage, gapFilledPercentage)}% ${getLocalizedValue(selectedLanguage, 't_gap_filled_scope')}`}</p>
      </>
    }
    return gapFilledValues
  }

  function getPreviousPeriodValues() {
    let labelKey
    if (utilityName === UtilityTypes.RecycledWaste) {
      labelKey = 't_previous_period_recycled'
    } else {
      labelKey = (utilityPerformanceData?.hasTarget || utilityPerformanceData?.useTarget) ? 't_target' : 't_previous_period'
    }
    return getLocalizedValue(selectedLanguage, labelKey).toLowerCase()
  }

  function getVarianceAndTargetValues(utilityData) {
    let variance = utilityData?.variance
    let target = utilityData?.useTarget
    if (Views.AssetDashboard === view) {
      variance = (utilityData.targetAmount != null) ? utilityData.differencePercentage : null
      target = utilityData.hasTarget
    }
    return {
      variance,
      target
    }
  }

  function isPerformanceDataNotEmpty() {
    return (utilityPerformanceData?.value > 0 || utilityPerformanceData?.amountUptoTarget > 0 || utilityPerformanceData?.amountOverTarget > 0 
      || utilityPerformanceData?.amountUptoNonRecycledTarget > 0
      || utilityPerformanceData?.wasteValue > 0) 
  }

  return (
    <div className='utility-performance-tile-V3'>
      {
        (utilityName === UtilityTypes.EnergyIntensity && reportingPeriodSelected && parseInt(reportingPeriodSelected) < 11) ?
          <div className='overview-card'>
            <span className='overview-card-title'>{title}</span>
            <span className='fund-dashboard-card-value-no-data'>{I18n('t_only_for_annual_data')}</span>
          </div>
          :
          <Card className='overview-card'>
            <span className='overview-card-title'>{title}</span>
            { isPerformanceDataNotEmpty() ?
              <>
                <FeedbackRatingV3 {...getVarianceAndTargetValues(utilityPerformanceData)} utility={utilityName} />
                <div className='utility-bar'>
                  <TileStackedBarChart  chartDatasets={chartDatasets} yMax={yMax} />
                </div>
                <div className='utility-data tile-graph-legends'>
                  <span className='data-category actual-data'>
                    <img src={ActualDataIcon} alt="Actual data icon" />
                    <p>{getActualValues(utilityPerformanceData)} </p>
                  </span>
                  <span className='data-category gap-filled-data'>
                    { getGapFilledValues(utilityName, utilityPerformanceData) }
                  </span>
                  <span className='data-category target-or-previous-data'>
                    <img src={Target_Previous_Icon} className='target-previous-line' alt="Target previous icon" />
                    <p>{ getPreviousPeriodValues() }</p>
                  </span>
                </div>
              </>
              :
              <div className='no-data'><p>{getLocalizedValue(selectedLanguage, 't_msg_no_data')}</p>
                <div className='utility-bar flex justify-content-center'><img src={NoDataBar} alt="NoDataBar" /></div>
                <span className='data-category'>
                  <img src={NoDataIcon} alt="No data icon" />
                  <p>{getLocalizedValue(selectedLanguage, 't_msg_no_data')} </p>
                </span>
              </div>

            }
          </Card>
      }
    </div>
  )
}

UtilityPerformanceTileV3.propTypes = {
  utilityName: PropTypes.string,
  utilityPerformanceData: PropTypes.object,
  reportingPeriodSelected: PropTypes.string,
  view: PropTypes.string,
  chartDatasets: PropTypes.array,
  yMax: PropTypes.number
}

UtilityPerformanceTileV3.displayName = 'UtilityPerformanceTileV3'

export default memo(UtilityPerformanceTileV3)