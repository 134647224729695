import React from 'react'
import {mapResponsibilityToTitle, roundAndMultiply100, dataQualityObject} from '../../../../utils/helpers/Constants'
import {getLocalizedValue} from '../../../../utils/helpers/Helper'
import {Tooltip} from 'primereact/tooltip'
import './OverviewChart.scss'
import OverviewChartSkeleton from './skeleton/OverviewChartSkeleton'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const OverviewChart = (props) => {
  const { loginState: { userInfo } } = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference

  const getBarChart = () => {
    let chartData = {}
    if(props.data) {
      Object.entries(props.data).forEach(([key, value]) => {
        if(key === 'dataMissingPercentage') {
          chartData.percentage = value
        }
        if(key === 'actual') {
          chartData.actual = roundAndMultiply100(value)
        }
        if(key === 'estimated') {
          chartData.estimated = roundAndMultiply100(value)
        }
        if(key === 'missing') {
          chartData.missing = roundAndMultiply100(value)
        }
        if(key === 'noCoverage') {
          chartData.notCovered = roundAndMultiply100(value)
        }
        
      })
    }

    if(props.isLoading === false && props.owner) {
      chartData.title = getLocalizedValue(selectedLanguage, mapResponsibilityToTitle[props.owner])
      if(!props.data) {
        chartData.noData = getLocalizedValue(selectedLanguage, 't_msg_no_data')
      }
    }
    return (
      <div className="overview-chart" data-testid="overview-chart">
        {props.basedOn ?
          <div>
            <div className="chart-title">{getLocalizedValue(selectedLanguage,'t_based_on')}: {props.basedOn}</div>
            <br />
          </div> :
          null}
        <div className="chart-title" data-testid="overview-chart-title">{chartData.title}</div>
        {typeof chartData.percentage === 'number' ? <div className="chart-percentage">{`${chartData.percentage}%`}</div> : null}
        {chartData.noData && <div className="chart-no-data">{chartData.noData}</div>}
        {!chartData.actual && !chartData.estimated && !chartData.missing && !chartData.notCovered ?
          null :
          <div className="chart-bars-wrapper" onClick={props?.clickHandler}>
            <Tooltip className={'dq-metric-tooltip'} target={'.bar-chart-item'} />
            {chartData.actual > 0 &&
              (<span
                style={{flex: `1 1 ${chartData.actual}%`}}
                className={`bar-chart-item ${dataQualityObject.actualPercentage.cssClass}`}
                data-pr-tooltip={`${chartData.actual}% ${getLocalizedValue(selectedLanguage, dataQualityObject.actualPercentage.translationKey)}`}
                data-pr-position={'top'}>
              </span>)
            }
            {chartData.estimated > 0 &&
              (<span
                style={{flex: `1 1 ${chartData.estimated}%`}}
                className={`bar-chart-item ${dataQualityObject.estimatedPercentage.cssClass}`}
                data-pr-tooltip={`${chartData.estimated}% ${getLocalizedValue(selectedLanguage, dataQualityObject.estimatedPercentage.translationKey)}`}
                data-pr-position={'top'}>
              </span>)
            }
            {chartData.missing > 0 &&
              (<span
                style={{flex: `1 1 ${chartData.missing}%`}}
                className={`bar-chart-item ${dataQualityObject.incompletePercentage.cssClass}`}
                data-pr-tooltip={`${chartData.missing}% ${getLocalizedValue(selectedLanguage, dataQualityObject.incompletePercentage.translationKey)}`}
                data-pr-position={'top'}>
              </span>)
            }
            {chartData.notCovered > 0 &&
              (<span
                style={{flex: `1 1 ${chartData.notCovered}%`}}
                className={`bar-chart-item ${dataQualityObject.notCoveredPercentage.cssClass}`}
                data-pr-tooltip={`${chartData.notCovered}% ${getLocalizedValue(selectedLanguage, dataQualityObject.notCoveredPercentage.translationKey)}`}
                data-pr-position={'top'}>
              </span>)
            }
          </div>
        }
        
      </div>
    )
  }

  return (props.isLoading || props.isDependentLoading) ? <OverviewChartSkeleton /> : getBarChart()
}

export default OverviewChart