import {ELECTRICITY, UTILITY_LABELS_OBJECT_TITLE_CASE, WASTE, WATER} from '../../helpers/Constants'
import I18n from '../../i18n/I18n'
import styles from '../style-sheet/Styles'
import {getLocalizedValue, truncateDecimal, unitsConverter} from '../../helpers/Helper'
import {Text, View} from '@react-pdf/renderer'
import React from 'react'

const formatUnits = (value,props) => {
  if (value != null) {
    return Number(value).toLocaleString(props.language)
  }
  return value
}

function currentUnit(data, unitSystem){
  let unit=''
  if(data){
    unit = unitsConverter(data, unitSystem, false, false)
  }
  return unit
}

export  const calcTitleAndPosition = (value) => {
  let data
  switch (value) {
  case ELECTRICITY :
    data = {title: I18n('t_electricity'), position: styles.electricityPosition}
    break

  case UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL :
    data = {title: I18n('t_gas_fuel_thermal'), position: styles.thermalPosition}
    break

  case WATER :
    data = {title: I18n('t_water'), position: styles.waterPosition}
    break

  case WASTE :
    data = {title: I18n('t_waste_recycling'), position: styles.wastePosition}
    break

  default :
    data = {title: I18n('t_carbon'), position: styles.carbonPosition}
  }
  return data
}

export const secondRow = (props,utility,isDashboard) => {
  function getSecondRowDashboardValue() {
    return utility && utility.amountUptoTarget ?
      formatUnits(truncateDecimal(utility.amountUptoTarget+utility.amountOverTarget,1),props) + currentUnit(props.utilityName, props.unitSystem) : '-'
  }

  function getSecondRowValue() {
    return utility && utility.currentYearTotalConsumption  ?
      formatUnits(truncateDecimal(utility.currentYearTotalConsumption,1),props) + currentUnit(props.utilityName, props.unitSystem) : '-'
  }

  return (
    <View style={[styles.semiBox, isDashboard ? styles.largeUtilityCurrent : styles.utilityCurrent]}>
      <Text style={[styles.assetTextEightBold, styles.mT6]}>
        {props.dashboard ? getSecondRowDashboardValue() : getSecondRowValue()}
      </Text>
      {props.dashboard ?
        <Text style={[styles.assetTextSeven, styles.greyColor]}>
          {props.utilityName === 'Waste' ? I18n('t_total_recycling') : I18n('t_current')}
        </Text> :
        <Text style={[styles.assetTextSeven, styles.greyColor]}>
          {props.utilityName === 'Waste' ?  new Date().getFullYear()  +' '+ getLocalizedValue(props.language,'t_total_recycling') : I18n('t_actual')}
        </Text>
      }
    </View>
  )
}

export const firstRow = (props,utility) => {
  function getFirstRowDashboardValue() {
    return utility && utility.targetAmount ?
      formatUnits(truncateDecimal(utility.targetAmount,1),props) + currentUnit(props.utilityName, props.unitSystem): '-'
  }

  function getFirstRowValue() {
    return utility && utility.previousYearTotalConsumption ?
      formatUnits(truncateDecimal(utility.previousYearTotalConsumption,1),props) + currentUnit(props.utilityName, props.unitSystem): '-'
  }

  function getLabelForOtherUtil() {
    return props.showTarget ? I18n('t_target') : I18n('t_previous')
  }

  return (
    <View style={[styles.semiBox, styles.utilityPrevious]}>
      <Text style={[styles.assetTextEightBold, styles.mTM1]}>
        {props.dashboard ? getFirstRowDashboardValue() : getFirstRowValue()}
      </Text>
      {props.dashboard ?
        <Text style={[styles.assetTextSeven, styles.greyColor]}>
          {props.utilityName === 'Waste' ?  I18n('t_total_recycling')  : getLabelForOtherUtil()}
        </Text> :
        <Text style={[styles.assetTextSeven, styles.greyColor]}>
          {props.utilityName === 'Waste' ? new Date().getFullYear() -1 +' '+ getLocalizedValue(props.language,'t_total_recycling') : I18n('t_target')}
        </Text>
      }
    </View>
  )
}

export const thirdRow = (props,utility) => {
  function getThirdRowDashboardValue() {
    return utility && utility.differencePercentage ?
      formatUnits(truncateDecimal(utility.differencePercentage*100,1),props)+' %' : '-'
  }

  function getThirdRowValue() {
    return utility && utility.percentageChange ?
      formatUnits(truncateDecimal(utility.percentageChange*100,1),props)+' %' : '-'
  }

  return (
    <View style={[styles.semiBox, styles.utilityPercent]}>
      <Text style={[styles.assetTextEightBold, styles.mT6, styles.mL8]}>
        {props.dashboard ? getThirdRowDashboardValue() : getThirdRowValue()}
      </Text>
      <Text style={[styles.assetTextSeven, styles.greyDarkColor, styles.mL8]}>
        {props.utilityName === 'Waste' ? I18n('t_y_o_y') : props.time}
      </Text>
    </View>
  )
}
